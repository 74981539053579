import { Select } from 'antd';
import { useEffect } from 'react';

export function LanguageSelectOptions({ currentUser, onSelectLanguage }) {
	const { languageCode } = currentUser;

	return (
		<Select
			showArrow
			className="custom-input"
			style={{ width: '100%' }}
			defaultValue={languageCode === null ? 'US' : languageCode}
			virtual={false}
			onSelect={onSelectLanguage}
		>
			<Select.Option key="US" value="US">
				English
			</Select.Option>
			<Select.Option key="AR" value="AR">
				اَلْفُصْحَىٰ
			</Select.Option>
			<Select.Option key="CS" value="CS">
				Čeština
			</Select.Option>
			<Select.Option key="DE" value="DE">
				Deutsch
			</Select.Option>
			<Select.Option key="ES" value="ES">
				Español
			</Select.Option>
			<Select.Option key="FR" value="FR">
				Français
			</Select.Option>
			<Select.Option key="FR-CA" value="FR-CA">
				Français (Canada)
			</Select.Option>
			<Select.Option key="IT" value="IT">
				Italiano
			</Select.Option>
			<Select.Option key="HU" value="HU">
				Magyar
			</Select.Option>
			<Select.Option key="NL" value="NL">
				Nederlands
			</Select.Option>
			<Select.Option key="PL" value="PL">
				Polski
			</Select.Option>
			<Select.Option key="PT" value="PT">
				Português
			</Select.Option>
			<Select.Option key="PT-BR" value="PT-BR">
				Português (Brasil)
			</Select.Option>
			<Select.Option key="TL" value="TL">
				Tagalog
			</Select.Option>
			<Select.Option key="TR" value="TR">
				Türkçe
			</Select.Option>
			<Select.Option key="RU" value="RU">
				Русский
			</Select.Option>
			<Select.Option key="ZH-CN" value="ZH-CN">
				简体中文
			</Select.Option>
			<Select.Option key="JA" value="JA">
				日本語
			</Select.Option>
		</Select>
	);
}
