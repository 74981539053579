import dayjs from 'dayjs';
import get from 'lodash/get';
import { connect } from 'react-redux';
import JobCardComponent from './JobCardComponent.jsx';

const mapStateToProperties = (state, ownProperties) => {
	const { currentUser } = state.user;
	let bonus = '';
	let id = '';
	let isActive = false;
	const campaignId = get(ownProperties, 'job.campaignId');
	const campaign = get(ownProperties, 'job.campaign');

	if (campaignId) {
		const today = dayjs();
		const startDate = get(campaign, 'startDate');
		const endDate = get(campaign, 'endDate');

		isActive = Boolean(today.isBetween(startDate, endDate, 'day', '[]'));
	}

	if (isActive) {
		id = get(ownProperties, 'job.campaign.tieredBonusId', 'skip');
	} else {
		bonus =
			typeof get(ownProperties, 'job.referralBonus') === 'string'
				? JSON.parse(get(ownProperties, 'job.referralBonus', '{}'))
				: get(ownProperties, 'job.referralBonus');
		id = get(bonus, 'tieredBonusId', 'skip');
	}

	return {
		currentUser,
		currentReferral: get(state, 'referral.currentReferral'),
		referrals: get(state, 'referral.referrals'),
		companyId: get(currentUser, 'companyId'),
		tieredBonusQuery: {
			companyId: get(currentUser, 'companyId'),
			id,
		},
	};
};

const mapDispatchToProperties = () => {
	return {};
};

export default connect(
	mapStateToProperties,
	mapDispatchToProperties
)(JobCardComponent);
