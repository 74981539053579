import { css } from 'emotion';
import { COLORS } from '../../../_shared/styles/colors';

export const linkListContainer = css({
	listStyle: 'none',
	margin: 0,
	padding: 0,
});

export const headerClass = css({
	fontSize: 16,
	textTransform: 'uppercase',
	letterSpacing: 2,
	fontWeight: 300,
	color: `${COLORS.heading} !important`,
	marginLeft: 15,
});

export const quickLinkClass = css({
	fontSize: 14,
	fontWeight: '600 !important',
});

export const linkItemClass = css({
	marginBottom: 15,
});
