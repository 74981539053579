import { Select } from 'antd';
import get from 'lodash/get';
import { Component } from 'react';
import { Link } from 'react-router-dom';
import sad from '../../../_shared/assets/sad.svg';
import { ml } from '../../../_shared/services/utils.js';
import { COLORS } from '../../../_shared/styles/colors';
import { emptyTile, linkStyles } from './leaderBoardStyles.js';

class BaseLeaderBoard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			filterValue: 1,
			referralLeaderboard: true,
			pointsLeaderboard: false,
			filteredData: props.entities ? props.entities : [],
			filteredPointsData: props.entitiesPoints ? props.entitiesPoints : [],
			filter: false,
		};
	}

	handleEmployees = () => {};

	handleFilter = (value) => {
		const { referralLeaderboard, pointsLeaderboard } = this.state;
		const { currentUser, entities, entitiesPoints } = this.props;
		switch (value) {
			case 1: {
				this.setState({
					filter: false,
					filterValue: 1,
					filteredData: entities,
					filteredPointsData: entitiesPoints,
				});

				break;
			}

			case 2: {
				if (referralLeaderboard && !pointsLeaderboard) {
					const index = entities.findIndex(
						(item) => item.id === get(currentUser, 'id')
					);
					const startPoint = index - 5 < 0 ? 0 : index - 5;
					const beforeData = entities.slice(startPoint, index + 1);
					const afterData = entities.slice(index + 1, index + 5);
					const finalData = beforeData.concat(afterData);
					this.setState({
						filter: true,
						filterValue: 2,
						filteredData: finalData,
					});
				} else {
					const index = entitiesPoints.findIndex(
						(item) => item.id === get(currentUser, 'id')
					);
					const startPoint = index - 5 < 0 ? 0 : index - 5;
					const beforeData = entitiesPoints.slice(startPoint, index + 1);
					const afterData = entitiesPoints.slice(index + 1, index + 5);
					const finalData = beforeData.concat(afterData);
					this.setState({
						filter: true,
						filterValue: 2,
						filteredPointsData: finalData,
					});
				}

				break;
			}

			case 3: {
				if (referralLeaderboard && !pointsLeaderboard) {
					let finalData = entities.filter(
						(item) => item.subCompanyId === get(currentUser, 'subCompanyId')
					);
					finalData = finalData.map((u, index) => ({
						name: u.name,
						score: u.score,
						id: u.id,
						subCompanyId: u.subCompanyId,
						sno: index + 1,
					}));
					this.setState({
						filter: true,
						filterValue: 3,
						filteredData: finalData,
					});
				} else {
					let finalData = entitiesPoints.filter(
						(item) => item.subCompanyId === get(currentUser, 'subCompanyId')
					);
					finalData = finalData.map((u, index) => ({
						name: u.name,
						score: u.score,
						id: u.id,
						subCompanyId: u.subCompanyId,
						sno: index + 1,
					}));
					this.setState({
						filter: true,
						filterValue: 3,
						filteredPointsData: finalData,
					});
				}

				break;
			}

			case 4: {
				if (referralLeaderboard && !pointsLeaderboard) {
					let data = entities.filter(
						(item) => item.subCompanyId === get(currentUser, 'subCompanyId')
					);
					data = data.map((u, index) => ({
						name: u.name,
						score: u.score,
						id: u.id,
						subCompanyId: u.subCompanyId,
						sno: index + 1,
					}));
					const index = data.findIndex(
						(item) => item.id === get(currentUser, 'id')
					);
					const startPoint = index - 5 < 0 ? 0 : index - 5;
					const beforeData = data.slice(startPoint, index + 1);
					const afterData = data.slice(index + 1, index + 5);
					const finalData = beforeData.concat(afterData);
					this.setState({
						filter: true,
						filterValue: 4,
						filteredData: finalData,
					});
				} else {
					let data = entitiesPoints.filter(
						(item) => item.subCompanyId === get(currentUser, 'subCompanyId')
					);
					data = data.map((u, index) => ({
						name: u.name,
						score: u.score,
						id: u.id,
						subCompanyId: u.subCompanyId,
						sno: index + 1,
					}));
					const index = data.findIndex(
						(item) => item.id === get(currentUser, 'id')
					);
					const startPoint = index - 5 < 0 ? 0 : index - 5;
					const beforeData = data.slice(startPoint, index + 1);
					const afterData = data.slice(index + 1, index + 5);
					const finalData = beforeData.concat(afterData);
					this.setState({
						filter: true,
						filterValue: 4,
						filteredPointsData: finalData,
					});
				}

				break;
			}
			// No default
		}
	};

	handleTopPoints = () => {
		this.setState({
			filter: false,
			filterValue: 1,
			referralLeaderboard: false,
			pointsLeaderboard: true,
		});
	};

	handleTopReferrals = () => {
		this.setState({
			filter: false,
			filterValue: 1,
			pointsLeaderboard: false,
			referralLeaderboard: true,
		});
	};

	renderEntities(title, entities) {
		const listItems = entities.map((entity, index) => (
			<li key={index}>
				{title === 'Recent Jobs' && (
					<label>
						<Link className={linkStyles} to={`/browsejobs/${entity.id}`}>
							{entity.name}
						</Link>
					</label>
				)}
				{title === 'Recent Referrals' && (
					<label>
						<Link className={linkStyles} to={`/referrals/${entity.id}`}>
							{entity.name}
						</Link>
					</label>
				)}
				{title === 'Your Departments' && (
					<label>
						<Link className={linkStyles} to="#">
							{entity.department.name}
						</Link>
					</label>
				)}
			</li>
		));
		return listItems;
	}

	renderNumberedEntities(entitiesValue) {
		const {
			referralLeaderboard,
			pointsLeaderboard,
			filteredData,
			filteredPointsData,
			filter,
		} = this.state;
		const { entities, entitiesPoints, currentUser } = this.props;
		const entitiesData = [];
		if (filter) {
			if (referralLeaderboard && !pointsLeaderboard) {
				entitiesData.push(...filteredData);
			} else {
				entitiesData.push(...filteredPointsData);
			}
		} else if (referralLeaderboard && !pointsLeaderboard) {
			entitiesData.push(...entities);
		} else {
			entitiesData.push(...entitiesPoints);
		}

		const data = entitiesData.slice(0, 10);
		const listItems = data.map((entity, index) => (
			<li key={index}>
				<label
					style={
						entity.id === get(currentUser, 'id') ? { color: '#018dd3' } : {}
					}
				>
					{entity.sno}
					{'. '}&nbsp;
					{get(entity, 'name', '').split('', 21)}
				</label>
				<span>
					{entity.score
						? entity.score.toString().replaceAll(/\B(?=(\d{3})+(?!\d))/g, ',')
						: ''}
				</span>
			</li>
		));
		return listItems;
	}

	render() {
		const {
			onClick,
			title,
			entities,
			isNumbered,
			allMultiLingualData,
			currentUser,
			entitiesPoints,
			height,
		} = this.props;
		const { referralLeaderboard, pointsLeaderboard } = this.state;
		const showEmptyContainer = entities.length === 0;
		const { Option } = Select;
		const options = [];
		const data = [
			{
				key: 1,
				value: ml('All (Top 10)', currentUser, allMultiLingualData),
			},
			{
				key: 2,
				value: ml('All (My Position)', currentUser, allMultiLingualData),
			},
		];
		options.push(
			data.map((item) => (
				<Option key={item.key} value={item.key}>
					{item.value}
				</Option>
			))
		);
		const subCompanies = get(
			this.props.currentUser,
			'company.subCompanies',
			[]
		);
		const currentUserSubCompany = subCompanies.filter(
			(item) => item.id === get(this.props.currentUser, 'subCompanyId')
		);
		if (subCompanies.length > 0 && currentUserSubCompany.length > 0) {
			const data = [
				{
					key: 3,
					value: `${currentUserSubCompany[0].name} ${ml(
						'(Top 10)',
						currentUser,
						allMultiLingualData
					)}`,
				},
				{
					key: 4,
					value: `${currentUserSubCompany[0].name} ${ml(
						'(My Position)',
						currentUser,
						allMultiLingualData
					)}`,
				},
			];

			options.push(
				data.map((item) => (
					<Option key={item.key} value={item.key}>
						{item.value}
					</Option>
				))
			);
		}

		const pointsSettings = get(currentUser, 'company.pointsSettings');
		let showingPoints = false;
		let wid = '100%';
		if (pointsSettings !== null && pointsSettings !== undefined) {
			const pointsSettingsData = JSON.parse(pointsSettings);
			if (pointsSettingsData.enabled) {
				showingPoints = true;
				wid = '50%';
			}
		}

		return (
			<div
				className={`d-card ${height === 'height-equal' ? 'height-equal' : ''}`}
				onClick={onClick}
			>
				<div className="d-card-head">
					<h4 className="d-title">{title}</h4>

					<div className="leaderboard-select">
						<label>{ml('Showing', currentUser, allMultiLingualData)}: </label>
						<Select
							value={this.state.filterValue}
							dropdownMatchSelectWidth={false}
							onSelect={(value) => this.handleFilter(value)}
						>
							{options}
						</Select>
					</div>
				</div>

				<div className="tabs">
					<span
						className={`${referralLeaderboard ? 'active' : ''}`}
						onClick={this.handleTopReferrals}
					>
						{ml('Referrals', currentUser, allMultiLingualData)}
					</span>
					{showingPoints && (
						<span
							className={`${pointsLeaderboard ? 'active' : ''}`}
							onClick={this.handleTopPoints}
						>
							{ml('Points', currentUser, allMultiLingualData)}
						</span>
					)}
				</div>
				{showEmptyContainer ? (
					<div className={emptyTile}>
						{title === 'Leaderboards' && (
							<div>
								<img
									src={sad}
									height={60}
									width={60}
									alt="Sad Face. No employees yet."
								/>
								<div style={{ marginTop: '10px' }}>
									Uh oh. You haven&#39;t added any employees yet. Don&#39;t
									worry, just
									<a style={{ color: COLORS.blue }}> click here </a> to add
									them!
								</div>
							</div>
						)}
					</div>
				) : (
					<>
						{isNumbered ? (
							<ol className="list-data-wrap">
								{referralLeaderboard && !pointsLeaderboard
									? this.renderNumberedEntities(entities)
									: this.renderNumberedEntities(entitiesPoints)}
							</ol>
						) : (
							<ul className="list-data-wrap">
								{this.renderEntities(title, entities)}
							</ul>
						)}
					</>
				)}
			</div>
		);
	}
}

export const EmpLeaderBoard = BaseLeaderBoard;
