import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { getUser } from '../../graphql/custom/dashboard';

export const withUserDashboard = (Component) => {
	return graphql(gql(getUser), {
		options: (props) => ({
			context: {
				headers: {
					'x-frame-options': 'deny', // This header will reach the server
				},
			},
			variables: { id: props.currentUser.id },
			fetchPolicy: 'cache-and-network',
		}),
		props(props) {
			if (!props.data.loading && (!props.data.getUser || props.data.error)) {
				setTimeout(props.data.refetch, 2000);
				return null;
			}

			return {
				userDashboard: props.data.getUser ? props.data.getUser : undefined,
			};
		},
	})(Component);
};
