export const updateWebNotification = `mutation UpdateWebNotification($input: UpdateWebNotificationInput!) {
  updateWebNotification(input: $input) {
    id
    companyId
    type
    userId
    referralRequestedStatus
    requestingUserId
    status
    contactId
    referralId
    jobId
    matches
    message
    dateCreated
    questionsData
  }
}
`;
