import { css } from 'emotion';
import { COLORS } from '../../../_shared/styles/colors';

export const headerClass = css({
	fontSize: 16,
	textTransform: 'uppercase',
	letterSpacing: 2,
	fontWeight: 300,
	color: `${COLORS.heading} !important`,
	paddingBottom: 20,
});

export const opacity = css({
	opacity: '1',
});

export const buttonActive = css({
	borderColor: COLORS.dashboardBlue,
	background: COLORS.dashboardBlue,
	width: '10px',
	height: '10px',
	borderRadius: '35%',
	paddingBottom: 0,
});

export const buttonBusiness = css({
	borderColor: COLORS.dashboardGreen,
	background: COLORS.dashboardGreen,
	width: '10px',
	height: '10px',
	borderRadius: '35%',
	paddingBottom: 0,
});

export const buttonFirst = css({
	borderColor: COLORS.dashboardLightOrange,
	background: COLORS.dashboardLightOrange,
	width: '10px',
	height: '10px',
	borderRadius: '35%',
	paddingBottom: 0,
});

export const donutChart = css({
	position: 'absolute',
	top: '-36px',
	left: '20px',
});

export const chartTotal = css({
	position: 'absolute',
	top: '29%',
	left: '23.5%',
	background: COLORS.white,
	flexWrap: 'wrap',
	width: '75px',
});

export const numTotal = css({
	fontSize: 20,
	fontWeight: 800,
	marginBottom: -5,
});

export const middleContainer = css({
	height: 80,
	width: 180,
	margin: 'auto',
	marginTop: 40,
	marginBottom: 50,
});

export const middleColumn = css({
	textAlign: 'center',
	width: 85,
});

export const bigNumbers = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 800,
	color: COLORS.black,
	fontSize: 28,
});

export const bigNumbersOpen = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 800,
	color: COLORS.dashboardDarkOrange,
	fontSize: 28,
});

export const numberSubtitles = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 600,
	color: COLORS.white,
	fontSize: 14,
});

export const numberSubtitlesOpen = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 600,
	color: COLORS.lightGray,
	fontSize: 14,
	width: 70,
	margin: 'auto',
});

export const matchBox = css({
	textAlign: 'center',
	width: 80,
	backgroundColor: COLORS.dashboardLightOrange,
	borderRadius: '3%',
});

export const smallIcons = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 600,
	color: COLORS.subHeading,
	fontSize: 14,
	opacity: 0.7,
});

export const divider = css({
	height: 1,
	backgroundColor: COLORS.subHeading,
	opacity: 0.4,
});

export const bottomContainer = css({
	height: 60,
	marginTop: 29,
	marginBottom: 20,
});

export const bottomSectionText = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 600,
	color: COLORS.subHeading,
	fontSize: 14,
	marginLeft: 5,
});

export const bottomSectionValue = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 800,
	color: COLORS.black,
	fontSize: 14,
	float: 'right',
});
