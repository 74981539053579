import { css } from 'emotion';
import get from 'lodash/get';
import { COLORS } from '../_shared/styles/colors';

const breakpoints = [576, 767, 991, 1200];

const mq = breakpoints.map((bp) => `@media (max-width: ${bp}px)`);

export const bonusCampaignContainer = css({
	width: '100%',
	height: '100%',
	padding: 20,
	backgroundColor: COLORS.lightGray2,
	[mq[2]]: {
		padding: '10px 5px',
	},
});
export const tableHeaderContainer = css({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	maxWidth: '100%',
	position: 'relative',
	marginBottom: 20,
});

export const tableHeader = css({
	fontFamily: '"Open Sans", sans-serif !important',
	fontWeight: '600 !important',
});

export const tableContainer = css({
	borderRadius: 10,
	backgroundColor: 'white',
	padding: 20,
	width: '32%',
	maxWidth: '32%',
	minHeight: 200,
	maxHeight: 250,
	overflowY: 'scroll',
	marginBottom: 15,
	'& button:focus': {
		outline: 'none',
	},
	[mq[2]]: {
		padding: 8,
	},
});

export const yourProgressContainer = css({
	borderRadius: 10,
	backgroundColor: 'white',
	minHeight: 251,
	marginTop: 37,
	marginBottom: 15,
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	[mq[1]]: {
		flexDirection: 'column-reverse',
		justifyContent: 'center',
		padding: '20px 0',
		marginTop: 15,
		'.stat-box.complete': {
			alignSelf: 'auto',
			width: 'auto',
		},
	},
});

export const yourApplicationsContainer = css({
	borderRadius: 10,
	height: 251,
	marginTop: 37,
	marginBottom: 15,
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	[mq[1]]: {
		marginTop: 15,
	},
});

export const collapseMenu = css({
	fontFamily: 'Open Sans,sans-serif',
	'& .ant-collapse-item': {
		borderBottom: 0,
		'& .ant-collapse-header': {
			paddingLeft: 0,

			'& .ant-collapse-arrow': {
				color: COLORS.blue,
				paddingLeft: 95,
			},
		},
		'& .ant-collapse-content-box': {
			paddingTop: 0,
			padding: 0,
		},
	},
});

export const bonusScheduleCollapseMenu = css({
	fontFamily: 'Open Sans,sans-serif',
	'& .ant-collapse-item': {
		borderBottom: 0,
		'& .ant-collapse-header': {
			paddingLeft: 0,
			display: 'flex',
			alignItems: 'center',

			'& .ant-collapse-arrow': {
				color: COLORS.text,
				position: 'static',
				transform: 'none',
				marginRight: 10,
			},
		},
		'& .ant-collapse-content-box': {
			paddingTop: 0,
			padding: 0,
		},
	},
});

export const TitleStyles = css({
	fontFamily: 'Open Sans,sans-serif',
	fontSize: 24,
	fontWeight: 600,
	color: COLORS.black,
	marginRight: 10,
});

export const NormalLabelStyles = css({
	verticalAlign: 'middle',
	fontFamily: 'Open Sans,sans-serif',
});

export const SelectedEmployeesContainer = css({
	display: 'table',
});

export const SelectedEmployees = css({
	textAlign: 'center',
	width: '150px',
	backgroundColor: COLORS.dashboardGreen,
	borderRadius: 10,
	height: 100,
	position: 'relative',
	overflow: 'hidden',
});

export const SelectedEmployeesTextContiner = css({
	top: '20%',
	position: 'relative',
});

export const EmployeeCountClass = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 600,
	color: COLORS.green,
	fontSize: '1.50em',
	width: '100%',
});

export const RTEContainer = css({
	display: 'flex',
	height: 215,
	flex: '1 1 auto',
});

export const SubmitBtnContainer = css({
	width: '100%',
	display: 'flex',
	justifyContent: 'center',
	marginBottom: 0,
	'& .ant-form-item-control-wrapper': {
		'@media (max-width: 575px)': {
			width: 'auto',
		},
	},
});

export const SendTestEmailButton = css({
	width: 180,
	height: 40,
	background: COLORS.white,
	color: COLORS.gray,
	fontSize: 14,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	borderRadius: '10px',
	border: '1.5px solid #8d99a3',
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 200,
	lineHeight: '25px',
	'& .ant-btn: hover': {
		color: COLORS.gray,
		borderColor: COLORS.gray,
	},
	'& .ant-btn: focus': {
		color: COLORS.gray,
		borderColor: COLORS.gray,
	},
	[mq[1]]: {
		width: '100%',
	},
});

export const AlertError = css({
	marginBottom: 20,
});

export const PopoverStyle = css({
	'& .ant-popover-placement-topLeft': {
		width: 290,
		left: 424,
		top: 644,
	},
	'& .ant-popover-inner-content': {
		borderColor: 'rgba(0, 0, 0, 0.75)',
		borderRadius: 4,
		color: 'white',
		backgroundColor: 'rgba(0, 0, 0, 0.75)',
		padding: 5,
	},
	'& .ant-popover-arrow': {
		backgroundColor: 'rgba(0, 0, 0, 0.75)',
		borderColor: 'rgba(0, 0, 0, 0.75)',
		border: '0px solid',
		zIndex: -1,
	},
});

export const fieldErrors = css({
	width: 'auto',
	marginLeft: '5%',
});

export const errorText = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 200,
	color: COLORS.red,
	fontSize: '0.85em',
	left: -30,
});

export const bottomButton = css({
	width: '100%',
	paddingTop: 40,
	paddingLeft: 90,
	paddingBottom: 40,
	[mq[1]]: {
		paddingLeft: 0,
	},
});

export const btnLeft = css({
	float: 'left',
	[mq[1]]: {
		float: 'none',
	},
});

export const btnRight = css({
	float: 'right',
	[mq[1]]: {
		float: 'none',
		marginTop: 10,
	},
});

export const autocompleteDropdownContainer = css({
	position: 'absolute',
	width: 350,
	zIndex: 1000,
	boxShadow: 'rgb(32 33 36 / 28%) 0px 1px 6px 0px',
});

export const changeOrientation = css({
	alignItems: 'center',
	[mq[0]]: {
		flexDirection: 'column',
		'& > div': {
			marginBottom: 10,
		},
	},
});

export const inputContainer = (maxWidth) =>
	css({
		maxWidth,
		width: '100%',
		[mq[1]]: {
			maxWidth: 'none',
		},
	});

export const typeaheadRow = (active) => ({
	backgroundColor: active ? COLORS.red : COLORS.lightGray2,
});

export const SelectStylesLocation = css({
	width: 225,
	'& .ant-select-arrow': {
		display: 'none',
	},
	[mq[1]]: {
		width: '100%',
	},
});

export const rightSelect = css({
	marginLeft: '2%',
	marginRight: '2%',
	[mq[1]]: {
		marginRight: 0,
	},
});

export const MinimalEmployees = (selected) =>
	css({
		margin: 5,
		marginTop: 8,
		backgroundColor: selected ? COLORS.lightGray5 : COLORS.white,
		color: selected ? COLORS.white : COLORS.subHeading,
		'&:hover': {
			backgroundColor: COLORS.lightGray5,
			color: COLORS.white,
			borderColor: 'transparent',
		},
		'&:focus': {
			backgroundColor: COLORS.lightGray5,
			color: COLORS.white,
			borderColor: 'transparent',
		},
		height: 30,
		width: 120,
	});

export const MinimalCandidates = (selected) =>
	css({
		margin: 5,
		marginTop: 8,
		backgroundColor: selected ? COLORS.lightGray5 : COLORS.white,
		color: selected ? COLORS.white : COLORS.subHeading,
		'&:hover': {
			backgroundColor: COLORS.lightGray5,
			color: COLORS.white,
			borderColor: 'transparent',
		},
		'&:focus': {
			backgroundColor: COLORS.lightGray5,
			color: COLORS.white,
			borderColor: 'transparent',
		},
		height: 30,
		width: 150,
	});

export const subTitleStyle = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 300,
	color: COLORS.subHeading,
	fontSize: '0.85em',
});

export const LinkStyles = css({
	fontFamily: '"Open Sans", sans-serif',
	color: COLORS.blue,
	fontWeight: '600 !important',
});

export const absoluteBtn = css({
	[mq[1]]: {
		position: 'absolute',
		top: 30,
		right: 20,
	},
});

export const addButtonText = css({
	fontSize: 18,
	fontWeight: 600,
	color: COLORS.black,
});

export const ModalStyles = css({
	'& .ant-modal-content': {
		borderRadius: 10,
		borderWidth: '1px',
		borderStyle: 'solid',
		borderColor: COLORS.blue,
	},
	'& .ant-modal-close': {
		outline: 'none',
		'&:focus .ant-modal-close-icon': {
			outline: 'auto',
		},
	},
	fontFamily: 'Open Sans',
	width: '500px !important',
});

export const ModalStylesMiddle = css({
	'& .ant-modal-content': {
		borderRadius: 10,
		borderWidth: '1px',
		borderStyle: 'solid',
		borderColor: COLORS.blue,
	},
	'& .ant-modal-close': {
		outline: 'none',
		'&:focus .ant-modal-close-icon': {
			outline: 'auto',
		},
	},
	fontFamily: 'Open Sans',
	width: '720px !important',
});

export const ModalTitle = css({
	color: COLORS.black,
	marginTop: -6,
	marginBottom: 20,
	marginLeft: 'auto',
	marginRight: 'auto',
	fontSize: 24,
	fontWeight: 510,
});

export const SubTitleLower = css({
	fontWeight: 510,
	color: COLORS.black,
	display: 'flex',
	justifyContent: 'center',
});

export const InputStyles = css({
	fontSize: 18,
	height: 40,
	marginBottom: 10,
	width: 300,
	borderRadius: '5px 0 0 5px',
	border: 0,
	width: 450,
});

export const mainContainer = css({
	width: '100%',
	padding: 20,
	[mq[1]]: {
		padding: '20px 5px',
		'> .ant-row': {
			flexDirection: 'column',
			alignItems: 'center',
		},
	},
});

export const YourApplicationsAndHistory = css({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
});

export const jobTitle = css({
	fontWeight: 'bold',
	fontSize: '16px',
	paddingLeft: 20,
});

export const hotCardListStyle = (pageSize = 1) =>
	css({
		flexGrow: 1,
		display: 'inline-block',
		overflow: 'hidden',
		'& .ant-pagination': {
			textAlign: 'center',
			marginBottom: 'auto',
			width: '200%',
			display: 'flex',
			justifyContent: 'space-between',
		},
		'& .ant-pagination-item-link': {
			borderRadius: 100,
			height: 50,
			width: 50,
		},
		'& .ant-pagination-next': {
			position: 'relative',
			left: -44,
		},
		'& .ant-pagination::after': {},
		'& .ant-pagination-jump-prev': {
			display: 'none',
		},
		'& .ant-pagination-jump-next': {
			display: 'none',
		},
		'& .ant-pagination-item-ellipsis': {
			display: 'none',
		},
		'& .anticon': {
			fontSize: 20,
			padding: 14,
		},
		'& .ant-list-empty-text': {
			visibility: 'hidden',
		},
		'& .ant-list-pagination': {
			width: '100%',
			marginTop: 0,
			position: 'absolute',
			top: 100,
			zIndex: 300,
			right: 0,
			paddingRight: 10,
		},
		'& .ant-pagination-item': {
			display: 'none!important',
		},
		'& .ant-row': {
			display: 'flex',
			justifyContent: pageSize > 1 ? 'flex-start' : 'center',
		},
		'& .ant-spin-nested-loading': {
			height: '100%',
			'.ant-spin-container': {
				height: '100%',
				'.ant-row': {
					height: '100%',
					'.ant-col > .ant-list-item': {
						display: 'flex',
						justifyContent: 'center',
						marginBottom: '0px',
						[mq[1]]: {
							'> div': {
								height: '210px',
								minHeight: '210px',
							},
							'> div.application': {
								height: '210px',
								minHeight: '240px',
							},
						},
					},
				},
			},
		},
	});
export const BannercontnetSec = css({});
export const BannerSec = css({
	width: '100%',
	height: '100%',
	overflow: 'hidden',
	position: 'relative',
	minHeight: '300px',
});
export const BannerOverlay = css({
	position: 'absolute',
	width: '100%',
	height: '100%',
	display: 'block',
	left: 0,
	top: 0,
	right: 0,
	bottom: 0,
	background: 'rgba(0,0,0,0.4)',
	zIndex: 2,
});
export const BannerBg = css({
	position: 'absolute',
	zIndex: 1,
	objectFit: 'cover',
	width: '100%',
	height: '100%',
});
export const BannerContent = css({
	position: 'relative',
	zIndex: 2,
	textAlign: 'center',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	padding: '50px 30px',
	[mq[1]]: {
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '50px 0',
	},
});
export const EditBg = css({
	position: 'absolute',
	top: '0',
	right: '0',
	backgroundColor: '#ffffff',
	color: '#ff0000',
	zIndex: 2,
});
export const FindJob = css({
	fontSize: 22,
	textAlign: 'left',
	marginTop: 24,
	paddingLeft: 30,
	color: '#ffffff',
	[mq[2]]: {
		display: 'flex',
		flexDirection: 'column',
		maxWidth: '100%',
		alignItems: 'center',
		paddingLeft: 0,
		'.d-flex': {
			maxWidth: '75%',
			justifyContent: 'center',
			'.ant-row.ant-form-item': {
				width: '100%',
			},
		},
		'.ant-input': {
			maxWidth: '100%',
		},
	},
});
export const UpdateCareer = css({
	fontSize: 22,
	textAlign: 'left',
	marginTop: 24,
	paddingRight: 130,
	color: '#ffffff',
	[mq[2]]: {
		display: 'flex',
		flexDirection: 'column',
		paddingRight: 0,
	},
});

export const addButton = css({
	padding: '5px 15px!important',
	height: 40,
	backgroundColor: 'transparent',
	color: `${COLORS.white} !important`,
	border: `1px solid ${COLORS.white}`,
	cursor: 'pointer',
	overflow: 'hidden',
	outline: 'none',
	marginTop: 10,
	'&:hover': {
		backgroundColor: 'transparent',
		color: `${COLORS.white} !important`,
		border: `1px solid ${COLORS.white}`,
	},
	'&:focus': {
		backgroundColor: 'transparent',
		color: `${COLORS.white} !important`,
		border: `1px solid ${COLORS.white}`,
	},
});

export const h1Style = css({
	fontSize: '16px',
});

export const SubmitBtn = (theme) => {
	let buttonColor = COLORS.blue;
	if (get(theme, 'enabled')) {
		buttonColor = get(theme, 'buttonColor', buttonColor);
	}

	return css({
		fontFamily: '"Open Sans", sans-serif',
		textAlign: 'center',
		fontWeight: 600,
		color: `${COLORS.white} !important`,
		background: `${buttonColor} !important`,
		padding: '8px 16px',
		fontSize: 14,
		display: 'flex',
		height: 40,
		borderRadius: '0 5px 5px 0',
		border: `2px solid ${buttonColor} !important`,
		'&:hover': {
			color: buttonColor,
			borderColor: buttonColor,
		},
		'&:focus': {
			color: buttonColor,
			borderColor: buttonColor,
		},
	});
};

export const SubmitBtnContainerSettings = (theme) => {
	let buttonColor = COLORS.blue;
	if (get(theme, 'enabled')) {
		buttonColor = get(theme, 'buttonColor', buttonColor);
	}

	return css({
		justifyContent: 'center',
		'& .ant-btn: hover': {
			color: buttonColor,
			fontWeight: 300,
			border: `2px solid ${buttonColor}`,
		},
	});
};

export const NoSmartReferralsWithLogo = css({
	textAlign: 'center',
	fontSize: 14,
	fontWeight: 400,
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
});

export const NoSmartReferrals = css({
	marginTop: 40,
	textAlign: 'center',
	fontSize: 14,
	fontWeight: 400,
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
});

export const LinkStyle = css({
	color: COLORS.blue,
	cursor: 'pointer',
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: '800 !important',
	fontSize: '1em',
	marginLeft: '5px',
});

export const recommendedJobsContainer = css({
	display: 'flex',
	[mq[1]]: {
		justifyContent: 'space-evenly',
	},
});
