import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { updateUser } from '../../graphql/custom/users';

export const withUpdateUser = (Component) => {
	return graphql(gql(updateUser), {
		props: (props) => ({
			onUpdateUser(input) {
				props.mutate({
					context: {
						headers: {
							'x-frame-options': 'deny', // This header will reach the server
						},
					},
					variables: input,
				});
			},
		}),
	})(Component);
};
