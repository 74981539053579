import React from 'react';
import {
	Form,
	Input,
	InputNumber,
	Button,
	Card,
	Checkbox,
	Empty,
	Select,
	Spin,
} from 'antd';

const { Option } = Select;

const RecognitionSettingsPresentation = ({
	campaigns,
	currentUser,
	snappyCampaigns,
	formVisibleCampaignId,
	setFormVisibleCampaignId,
	onFinish,
	onCreateCampaign,
	handleUpdateCompany,
	showCreateCampaignForm,
	setShowCreateCampaignForm,
	inlineEditingIndex,
	onInlineEditSave,
	onInlineEditCancel,
	form,
	createCampaignForm,
	inlineFormData,
	handleInlineInputChange,
	handleInlineEditClick,
	isSubmitting,
	deletingTierId,
	updatingTierId,
	onDelete,
}) => {
	const campaign = campaigns.length > 0 ? campaigns[0] : null;
	const tiers = campaign?.tiers || [];
	return (
		<div>
			<div className="page-title">
				<h2 className="page-heading-manage-reward">Recognition Settings</h2>
			</div>
			<div className="setting-card">
				<h4 className="setting-card-title">Recognition Customizations</h4>
				<Form
					layout="vertical"
					onFinish={handleUpdateCompany}
					initialValues={{
						hideRecognitionSocialShare:
							currentUser?.company?.hideRecognitionSocialShare,
						hideRecognitionLeaderboard:
							currentUser?.company?.hideRecognitionLeaderboard,
						recognitionCustomTitle:
							currentUser?.company?.recognitionCustomTitle,
						requireBadgeSelection: currentUser?.company?.requireBadgeSelection,
					}}
				>
					<div className="custom-form-group">
						<label className="custom-label">Custom RecHub Menu Label</label>
						<Form.Item
							name="recognitionCustomTitle"
							rules={[{ required: false }]}
						>
							<Input className="custom-input" placeholder="RecHub" />
						</Form.Item>
					</div>
					<div className="custom-form-group left-check flex-wrap">
						<Form.Item name="requireBadgeSelection" valuePropName="checked">
							<Checkbox>
								<label className="custom-label">
									Require Badge Selection for Recognitions
								</label>
							</Checkbox>
						</Form.Item>
						<p className="custom-form-group-note" style={{ flex: '0 0 100%' }}>
							If enabled, users must select a badge before sending recognition.
						</p>
					</div>
					<div className="custom-form-group left-check flex-wrap">
						<Form.Item
							name="hideRecognitionSocialShare"
							valuePropName="checked"
						>
							<Checkbox>
								<label className="custom-label">
									Hide social sharing options on badge overview popups
								</label>
							</Checkbox>
						</Form.Item>
					</div>
					<div className="custom-form-group left-check flex-wrap">
						<Form.Item
							name="hideRecognitionLeaderboard"
							valuePropName="checked"
						>
							<Checkbox>
								<label className="custom-label">Hide Leaderboard</label>
							</Checkbox>
						</Form.Item>
						<p className="custom-form-group-note" style={{ flex: '0 0 100%' }}>
							Hide the leaderboard on the RecHub page.
						</p>
					</div>
					<Form.Item>
						<Button type="primary" size="large" htmlType="submit">
							Save Settings
						</Button>
					</Form.Item>
				</Form>
			</div>
			<div className="page-title">
				<h2 className="page-heading-manage-reward">Snappy Configuration</h2>
			</div>
			{!campaign && !showCreateCampaignForm && (
				<Card>
					<Empty description="No campaign found, would you like to create one?" />
					<div
						style={{
							display: 'flex',
							justifyContent: 'center',
							marginTop: '16px',
						}}
					>
						<Button
							type="primary"
							onClick={() => setShowCreateCampaignForm(true)}
							style={{ width: '200px' }}
						>
							Create Campaign
						</Button>
					</div>
				</Card>
			)}

			{showCreateCampaignForm && (
				<Card title="Create Campaign" style={{ marginTop: '16px' }}>
					<Form
						form={createCampaignForm}
						layout="vertical"
						onFinish={onCreateCampaign}
					>
						<Form.Item
							name="campaignName"
							label="Campaign Name"
							rules={[
								{ required: true, message: 'Please input the campaign name!' },
							]}
						>
							<Input placeholder="Enter campaign name" />
						</Form.Item>

						<Form.Item
							name="snappyAPIKey"
							label="Snappy API Key"
							rules={[
								{
									required: true,
									message: 'Please input the Snappy API Key!',
								},
							]}
						>
							<Input placeholder="Enter Snappy API Key" />
						</Form.Item>

						<Form.Item>
							<Button type="primary" htmlType="submit">
								Create
							</Button>
							<Button
								style={{ marginLeft: '8px' }}
								onClick={() => setShowCreateCampaignForm(false)}
							>
								Cancel
							</Button>
						</Form.Item>
					</Form>
				</Card>
			)}

			{campaign && (
				<div>
					{tiers.length > 0 ? (
						tiers.map((tier) => (
							<Card key={tier.id} style={{ marginBottom: '16px' }}>
								{deletingTierId === tier.id ? (
									<div style={{ textAlign: 'center', padding: '20px' }}>
										<Spin size="large" />
									</div>
								) : updatingTierId === tier.id ? (
									<div style={{ textAlign: 'center', padding: '20px' }}>
										<Spin size="large" />
									</div>
								) : inlineEditingIndex === tier.id ? (
									<div>
										<p>
											<strong>Tier Name:</strong>{' '}
											<Input
												value={inlineFormData.tierName}
												onChange={(e) =>
													handleInlineInputChange('tierName', e.target.value)
												}
												placeholder="Enter the tier name"
											/>
										</p>
										<p>
											<strong>Tier Description:</strong>{' '}
											<Input
												value={inlineFormData.tierDescription}
												onChange={(e) =>
													handleInlineInputChange(
														'tierDescription',
														e.target.value
													)
												}
												placeholder="Enter the tier description"
											/>
										</p>
										<p>
											<strong>Points Required:</strong>{' '}
											<InputNumber
												value={inlineFormData.pointsRequired}
												onChange={(value) =>
													handleInlineInputChange('pointsRequired', value)
												}
												style={{ width: '100%' }}
											/>
										</p>
										<p>
											<strong>Snappy Campaign:</strong>{' '}
											<Select
												value={inlineFormData.snappyCampaignId}
												onChange={(value) =>
													handleInlineInputChange('snappyCampaignId', value)
												}
												style={{ width: '100%' }}
												placeholder="Select Snappy Campaign"
											>
												{snappyCampaigns.map((campaign) => (
													<Option key={campaign.id} value={campaign.id}>
														{campaign.name} - ({campaign.id})
													</Option>
												))}
											</Select>
										</p>
										<Button
											type="primary"
											onClick={() =>
												onInlineEditSave(tier.id, inlineFormData, campaign.id)
											}
											style={{ marginRight: '8px' }}
										>
											Save
										</Button>
										<Button onClick={onInlineEditCancel}>Cancel</Button>
									</div>
								) : (
									<div>
										<p>
											<strong>Tier Name:</strong> {tier.tierName}
										</p>
										<p>
											<strong>Tier Description:</strong> {tier.tierDescription}
										</p>
										<p>
											<strong>Points Required:</strong> {tier.pointsRequired}
										</p>
										<p>
											<strong>Snappy Campaign:</strong> {tier.tierName} (
											{tier.snappyCampaignId})
										</p>
										<Button
											type="link"
											onClick={() => handleInlineEditClick(tier.id, tier)}
											style={{ marginRight: '8px' }}
										>
											Edit
										</Button>
										<Button
											type="link"
											danger
											onClick={() => onDelete(tier.id, campaign.id)}
										>
											Delete
										</Button>
									</div>
								)}
							</Card>
						))
					) : (
						<div>
							<Empty description="No tiers found, would you like to create one?" />
							<div
								style={{
									display: 'flex',
									justifyContent: 'center',
									marginTop: '16px',
								}}
							>
								<Button
									type="primary"
									onClick={() =>
										setFormVisibleCampaignId(
											formVisibleCampaignId === campaign.id ? null : campaign.id
										)
									}
									style={{ width: '200px' }}
								>
									Create Tier
								</Button>
							</div>
						</div>
					)}

					{tiers.length > 0 && (
						<Button
							type="primary"
							onClick={() =>
								setFormVisibleCampaignId(
									formVisibleCampaignId === campaign.id ? null : campaign.id
								)
							}
							style={{ marginTop: '16px' }}
						>
							Add Another Tier
						</Button>
					)}

					{formVisibleCampaignId === campaign.id && (
						<Card title="Create Tier" style={{ marginTop: '16px' }}>
							{isSubmitting ? (
								<div style={{ textAlign: 'center', padding: '20px' }}>
									<Spin size="large" />
								</div>
							) : (
								<Form
									form={form}
									layout="vertical"
									onFinish={(values) => {
										onFinish(values, campaign.id);
										form.resetFields();
									}}
								>
									<Form.Item
										name="tierName"
										label="Tier Name"
										rules={[
											{
												required: true,
												message: 'Please input the tier name!',
											},
										]}
									>
										<Input placeholder="Enter the tier name" />
									</Form.Item>

									<Form.Item
										name="tierDescription"
										label="Tier Description"
										rules={[
											{
												required: true,
												message: 'Please input the tier description!',
											},
										]}
									>
										<Input placeholder="Enter the tier description" />
									</Form.Item>

									<Form.Item
										name="pointsRequired"
										label="Points Required to Unlock"
										rules={[
											{
												required: true,
												message: 'Please input the required points!',
											},
										]}
									>
										<InputNumber
											placeholder="Enter points required"
											style={{ width: '100%' }}
										/>
									</Form.Item>

									<Form.Item
										name="snappyCampaignId"
										label="Snappy Campaign"
										rules={[
											{
												required: true,
												message: 'Please select the Snappy Campaign!',
											},
										]}
									>
										<Select placeholder="Select Snappy Campaign">
											{snappyCampaigns.map((campaign) => (
												<Option key={campaign.id} value={campaign.id}>
													{campaign.name} - ({campaign.id})
												</Option>
											))}
										</Select>
									</Form.Item>

									<Form.Item>
										<Button type="primary" htmlType="submit">
											Submit
										</Button>
										<Button
											style={{ marginLeft: '8px' }}
											onClick={() => setFormVisibleCampaignId(null)}
										>
											Cancel
										</Button>
									</Form.Item>
								</Form>
							)}
						</Card>
					)}
				</div>
			)}
		</div>
	);
};

export default RecognitionSettingsPresentation;
