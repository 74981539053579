export const fees = {
	USD: { percent: 2.9, fixed: 0.3 },
	GBP: { percent: 2.4, fixed: 0.2 },
	EUR: { percent: 2.4, fixed: 0.24 },
	CAD: { percent: 2.9, fixed: 0.3 },
	AUD: { percent: 2.9, fixed: 0.3 },
	NOK: { percent: 2.9, fixed: 2 },
	DKK: { percent: 2.9, fixed: 1.8 },
	SEK: { percent: 2.9, fixed: 1.8 },
	JPY: { percent: 3.6, fixed: 0 },
	MXN: { percent: 3.6, fixed: 3 },
};

export const calculateStripeFee = (amt, currency = 'USD') => {
	const _fee = fees[currency];
	const amount = Number.parseFloat(amt);
	const total =
		(amount + Number.parseFloat(_fee.fixed)) /
		(1 - Number.parseFloat(_fee.percent) / 100);
	const fee = total - amount;

	return {
		amount: amount.toFixed(2),
		fee: fee.toFixed(2),
		total: total.toFixed(2),
	};
};

export const getCardBrand = (brand) => {
	if (brand === 'visa') return 'Visa';
	if (brand === 'mastercard') return 'Mastercard';
	if (brand === 'amex') return 'American Express';
	if (brand === 'discover') return 'Discover';
	if (brand === 'diners_club') return 'Diners Club';
	if (brand === 'unionpay') return 'UnionPay';
	if (brand === 'jcb') return 'Japan Credit Bureau (JCB)';
	if (brand === 'cartes_bancaires') return 'Cartes Bancaires';
	if (brand === 'interac') return 'Interac';
};
