export const listDepartments = `query ListDepartments(
  $filter: TableDepartmentFilterInput
  $limit: Int
  $nextToken: String
) {
  listDepartments(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      companyId
      name
      keywords
      active
      totalUsers
    }
    nextToken
  }
}
`;
