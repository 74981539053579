import { useState, useEffect } from 'react';
import { message } from 'antd';
import { useAllRewardsAdminService } from '../service/AllRewardsAdmin.service.jsx';
import { downloadCSVFile } from '../../rewardsAndRecognitionHelper/utils.js';

const useGetRewardsInfo = () => {

    const [allActivitiesInfo, setAllActivity] = useState();
    const [allRewardsInfo, setAllRewardsInfo] = useState();
    const [loader, setLoader] = useState(false);
    const [selectedFilter, setSelectedFilter] = useState("all");
    const allRewardsAdminAPIService = useAllRewardsAdminService();

    const filterOption = {
        all: "all",
        points: "isPoints",
        bonus: "isBonus",
        physical: "isPhysicalReward",
    }

    const handleAddressError = (error, errorMessage = 'Failed to fetch address! Try again later.') => {
        message.error(error?.response?.data?.message || errorMessage);
    };

    const getAllActivity = async () => {
        setLoader(true);
        try {
            const response = await allRewardsAdminAPIService.getAllActivity({
                [filterOption[selectedFilter]]: true,
            })

            if (response?.code === 200 && response?.data) {
                setAllActivity(response?.data?.data);
            } else {
                handleAddressError(null, 'Failed to fetch! Try again later.');
            }
        } catch (error) {
            handleAddressError(error);
        } finally {
            setLoader(false);
        }
    };

    const getAllRewards = async () => {
        setLoader(undefined);
        try {
            const response = await allRewardsAdminAPIService.getAllRewards();
            if (response?.code === 200 && response?.data) {
                setAllRewardsInfo(response.data);
            } else {
                handleAddressError(null, 'Failed to fetch! Try again later.');
            }
        } catch (error) {
            handleAddressError(error);
        } finally {
            setLoader(false);
        }
    };

    const getAllRewardCSV = () => {
        return allRewardsAdminAPIService.downloadAllRewardCSV({
            [filterOption[selectedFilter]]: true,
        }).then((responce) => {
            downloadCSVFile(responce, "AllRewards")
        }).catch((error) => {
            message.error(error?.response?.data?.message || "Please try again")
        })
    }

    useEffect(() => {
        getAllActivity();
        getAllRewards()
    }, [selectedFilter]);

    return {
        getAllActivity,
        loader,
        allActivitiesInfo,
        allRewardsInfo,
        setSelectedFilter,
        selectedFilter,
        getAllRewardCSV
    };
};

export default useGetRewardsInfo;
