import {
	ContentState,
	EditorState,
	convertFromHTML,
	convertToRaw,
} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { Component } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import TextAreaAutosize from 'react-textarea-autosize';
import DynamicOptionList from './DynamicOptionList.jsx';
import ID from './UUID.js';
import { get } from './stores/requests.js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const toolbar = {
	options: ['inline', 'list', 'textAlign', 'fontSize', 'link', 'history'],
	inline: {
		inDropdown: false,
		className: undefined,
		options: ['bold', 'italic', 'underline', 'superscript', 'subscript'],
	},
};

export default class FormElementsEdit extends Component {
	constructor(props) {
		super(props);
		this.state = {
			element: this.props.element,
			data: this.props.data,
			dirty: false,
		};
	}

	onEditorStateChange(index, property, editorContent) {
		const html = draftToHtml(convertToRaw(editorContent.getCurrentContent()))
			.replaceAll('<p>', '')
			.replaceAll('</p>', '')
			.replaceAll(/\r\n|\r|\n/g, ' ');
		const this_element = this.state.element;
		this_element[property] = html;

		this.setState({
			element: this_element,
			dirty: true,
		});
	}

	addOptions() {
		const optionsApiUrl = document.querySelector('#optionsApiUrl').value;
		if (optionsApiUrl) {
			get(optionsApiUrl).then((data) => {
				this.props.element.options = [];
				const { options } = this.props.element;
				for (const x of data) {
					x.key = ID.uuid();
					options.push(x);
				}

				const this_element = this.state.element;
				this.setState({
					element: this_element,
					dirty: true,
				});
			});
		}
	}

	convertFromHTML(content) {
		const newContent = convertFromHTML(content);
		if (!newContent.contentBlocks || newContent.contentBlocks.length === 0) {
			// To prevent crash when no contents in editor
			return EditorState.createEmpty();
		}

		const contentState = ContentState.createFromBlockArray(newContent);
		return EditorState.createWithContent(contentState);
	}

	editElementProp(elementProperty, targProperty, e) {
		// ElemProperty could be content or label
		// targProperty could be value or checked
		const this_element = this.state.element;
		this_element[elementProperty] = e.target[targProperty];

		this.setState(
			{
				element: this_element,
				dirty: true,
			},
			() => {
				if (targProperty === 'checked') {
					this.updateElement();
				}
			}
		);
	}

	toggleRequired() {}

	updateElement() {
		const this_element = this.state.element;
		// To prevent ajax calls with no change
		if (this.state.dirty) {
			this.props.updateElement.call(this.props.preview, this_element);
			this.setState({ dirty: false });
		}
	}

	render() {
		if (this.state.dirty) {
			this.props.element.dirty = true;
		}

		const this_checked = this.props.element.hasOwnProperty('required')
			? this.props.element.required
			: false;
		const this_read_only = this.props.element.hasOwnProperty('readOnly')
			? this.props.element.readOnly
			: false;
		const this_default_today = this.props.element.hasOwnProperty('defaultToday')
			? this.props.element.defaultToday
			: false;
		const this_show_time_select = this.props.element.hasOwnProperty(
			'showTimeSelect'
		)
			? this.props.element.showTimeSelect
			: false;
		const this_show_time_select_only = this.props.element.hasOwnProperty(
			'showTimeSelectOnly'
		)
			? this.props.element.showTimeSelectOnly
			: false;
		const this_checked_inline = this.props.element.hasOwnProperty('inline')
			? this.props.element.inline
			: false;
		const this_checked_bold = this.props.element.hasOwnProperty('bold')
			? this.props.element.bold
			: false;
		const this_checked_italic = this.props.element.hasOwnProperty('italic')
			? this.props.element.italic
			: false;
		const this_checked_center = this.props.element.hasOwnProperty('center')
			? this.props.element.center
			: false;
		const this_checked_page_break = this.props.element.hasOwnProperty(
			'pageBreakBefore'
		)
			? this.props.element.pageBreakBefore
			: false;
		const this_checked_alternate_form = this.props.element.hasOwnProperty(
			'alternateForm'
		)
			? this.props.element.alternateForm
			: false;

		const {
			canHavePageBreakBefore,
			canHaveAlternateForm,
			canHaveDisplayHorizontal,
			canHaveOptionCorrect,
			canHaveOptionValue,
		} = this.props.element;

		const this_files = this.props.files.length > 0 ? this.props.files : [];
		if (
			this_files.length === 0 ||
			(this_files.length > 0 && this_files[0].id !== '')
		) {
			this_files.unshift({ id: '', file_name: '' });
		}

		let editorState;
		if (this.props.element.hasOwnProperty('content')) {
			editorState = this.convertFromHTML(this.props.element.content);
		}

		if (this.props.element.hasOwnProperty('label')) {
			editorState = this.convertFromHTML(this.props.element.label);
		}

		return (
			<>
				<div className="fb-form-header">
					<h4>{this.props.element.text}</h4>
					<i
						className="icon-cross fb-form-close"
						onClick={this.props.manualEditModeOff}
					/>
				</div>
				{this.props.element.hasOwnProperty('content') && (
					<div className="custom-form-group">
						<label>Text to display:</label>

						<Editor
							stripPastedStyles
							toolbar={toolbar}
							defaultEditorState={editorState}
							onBlur={this.updateElement.bind(this)}
							onEditorStateChange={this.onEditorStateChange.bind(
								this,
								0,
								'content'
							)}
						/>
					</div>
				)}
				{this.props.element.hasOwnProperty('file_path') && (
					<div className="custom-form-group">
						<label htmlFor="fileSelect">Choose file:</label>
						<select
							id="fileSelect"
							className="custom-input"
							defaultValue={this.props.element.file_path}
							onBlur={this.updateElement.bind(this)}
							onChange={this.editElementProp.bind(this, 'file_path', 'value')}
						>
							{this_files.map((file) => {
								const this_key = `file_${file.id}`;
								return (
									<option key={this_key} value={file.id}>
										{file.file_name}
									</option>
								);
							})}
						</select>
					</div>
				)}
				{this.props.element.hasOwnProperty('href') && (
					<div className="custom-form-group">
						<TextAreaAutosize
							type="text"
							className="custom-input"
							defaultValue={this.props.element.href}
							onBlur={this.updateElement.bind(this)}
							onChange={this.editElementProp.bind(this, 'href', 'value')}
						/>
					</div>
				)}
				{this.props.element.hasOwnProperty('src') && (
					<div>
						<div className="custom-form-group">
							<label htmlFor="srcInput">Link to:</label>
							<input
								id="srcInput"
								type="text"
								className="custom-input"
								defaultValue={this.props.element.src}
								onBlur={this.updateElement.bind(this)}
								onChange={this.editElementProp.bind(this, 'src', 'value')}
							/>
						</div>
						<div className="custom-form-group">
							<div className="custom-form-group custom-checkbox left-check">
								<input
									value
									id="do-center"
									type="checkbox"
									checked={this_checked_center}
									onChange={this.editElementProp.bind(
										this,
										'center',
										'checked'
									)}
								/>
								<label htmlFor="do-center">Center?</label>
							</div>
						</div>
						<div className="row">
							<div className="col-sm-3">
								<label className="control-label" htmlFor="elementWidth">
									Width:
								</label>
								<input
									id="elementWidth"
									type="text"
									className="custom-form-group"
									defaultValue={this.props.element.width}
									onBlur={this.updateElement.bind(this)}
									onChange={this.editElementProp.bind(this, 'width', 'value')}
								/>
							</div>
							<div className="col-sm-3">
								<label className="control-label" htmlFor="elementHeight">
									Height:
								</label>
								<input
									id="elementHeight"
									type="text"
									className="custom-input"
									defaultValue={this.props.element.height}
									onBlur={this.updateElement.bind(this)}
									onChange={this.editElementProp.bind(this, 'height', 'value')}
								/>
							</div>
						</div>
					</div>
				)}
				{this.props.element.hasOwnProperty('label') && (
					<div className="custom-form-group">
						<label>Display Label</label>
						<Editor
							stripPastedStyles
							toolbar={toolbar}
							defaultEditorState={editorState}
							onBlur={this.updateElement.bind(this)}
							onEditorStateChange={this.onEditorStateChange.bind(
								this,
								0,
								'label'
							)}
						/>
						<br />
						{this.props.element.hasOwnProperty('readOnly') && (
							<div className="custom-control custom-checkbox">
								<input
									value
									id="is-read-only"
									className="custom-control-input"
									type="checkbox"
									checked={this_read_only}
									onChange={this.editElementProp.bind(
										this,
										'readOnly',
										'checked'
									)}
								/>
								<label className="custom-control-label" htmlFor="is-read-only">
									Read only
								</label>
							</div>
						)}
						{this.props.element.hasOwnProperty('defaultToday') && (
							<div className="custom-control custom-checkbox">
								<input
									value
									id="is-default-to-today"
									className="custom-control-input"
									type="checkbox"
									checked={this_default_today}
									onChange={this.editElementProp.bind(
										this,
										'defaultToday',
										'checked'
									)}
								/>
								<label
									className="custom-control-label"
									htmlFor="is-default-to-today"
								>
									Default to Today?
								</label>
							</div>
						)}
						{this.props.element.hasOwnProperty('showTimeSelect') && (
							<div className="custom-control custom-checkbox">
								<input
									value
									id="show-time-select"
									className="custom-control-input"
									type="checkbox"
									checked={this_show_time_select}
									onChange={this.editElementProp.bind(
										this,
										'showTimeSelect',
										'checked'
									)}
								/>
								<label
									className="custom-control-label"
									htmlFor="show-time-select"
								>
									Show Time Select?
								</label>
							</div>
						)}
						{this_show_time_select &&
							this.props.element.hasOwnProperty('showTimeSelectOnly') && (
								<div className="custom-control custom-checkbox">
									<input
										value
										id="show-time-select-only"
										className="custom-control-input"
										type="checkbox"
										checked={this_show_time_select_only}
										onChange={this.editElementProp.bind(
											this,
											'showTimeSelectOnly',
											'checked'
										)}
									/>
									<label
										className="custom-control-label"
										htmlFor="show-time-select-only"
									>
										Show Time Select Only?
									</label>
								</div>
							)}
						{(this.state.element.element === 'RadioButtons' ||
							this.state.element.element === 'Checkboxes') &&
							canHaveDisplayHorizontal && (
								<div className="custom-control custom-checkbox">
									<input
										value
										id="display-horizontal"
										className="custom-control-input"
										type="checkbox"
										checked={this_checked_inline}
										onChange={this.editElementProp.bind(
											this,
											'inline',
											'checked'
										)}
									/>
									<label
										className="custom-control-label"
										htmlFor="display-horizontal"
									>
										Display horizonal
									</label>
								</div>
							)}
					</div>
				)}

				{this.state.element.element === 'Signature' &&
				this.props.element.readOnly ? (
					<div className="custom-form-group">
						<label htmlFor="variableKey">Variable Key:</label>
						<input
							id="variableKey"
							type="text"
							className="custom-input"
							defaultValue={this.props.element.variableKey}
							onBlur={this.updateElement.bind(this)}
							onChange={this.editElementProp.bind(this, 'variableKey', 'value')}
						/>
						<p className="help-block">
							This will give the element a key that can be used to replace the
							content with a runtime value.
						</p>
					</div>
				) : (
					<div />
				)}

				{this.props.element.hasOwnProperty('step') && (
					<div className="custom-form-group">
						<div className="form-group-range">
							<label className="control-label" htmlFor="rangeStep">
								Step
							</label>
							<input
								id="rangeStep"
								type="number"
								className="custom-input"
								defaultValue={this.props.element.step}
								onBlur={this.updateElement.bind(this)}
								onChange={this.editElementProp.bind(this, 'step', 'value')}
							/>
						</div>
					</div>
				)}
				{this.props.element.hasOwnProperty('min_value') && (
					<div className="custom-form-group">
						<div className="form-group-range">
							<label className="control-label" htmlFor="rangeMin">
								Min
							</label>
							<input
								id="rangeMin"
								type="number"
								className="custom-input"
								defaultValue={this.props.element.min_value}
								onBlur={this.updateElement.bind(this)}
								onChange={this.editElementProp.bind(this, 'min_value', 'value')}
							/>
							<input
								type="text"
								className="custom-input"
								defaultValue={this.props.element.min_label}
								onBlur={this.updateElement.bind(this)}
								onChange={this.editElementProp.bind(this, 'min_label', 'value')}
							/>
						</div>
					</div>
				)}
				{this.props.element.hasOwnProperty('max_value') && (
					<div className="custom-form-group">
						<div className="form-group-range">
							<label htmlFor="rangeMax">Max</label>
							<input
								id="rangeMax"
								type="number"
								className="custom-input"
								defaultValue={this.props.element.max_value}
								onBlur={this.updateElement.bind(this)}
								onChange={this.editElementProp.bind(this, 'max_value', 'value')}
							/>
							<input
								type="text"
								className="custom-input"
								defaultValue={this.props.element.max_label}
								onBlur={this.updateElement.bind(this)}
								onChange={this.editElementProp.bind(this, 'max_label', 'value')}
							/>
						</div>
					</div>
				)}
				{this.props.element.hasOwnProperty('default_value') && (
					<div className="custom-form-group">
						<div className="form-group-range">
							<label className="control-label" htmlFor="defaultSelected">
								Default Selected
							</label>
							<input
								id="defaultSelected"
								type="number"
								className="custom-input"
								defaultValue={this.props.element.default_value}
								onBlur={this.updateElement.bind(this)}
								onChange={this.editElementProp.bind(
									this,
									'default_value',
									'value'
								)}
							/>
						</div>
					</div>
				)}
				{this.props.element.hasOwnProperty('static') &&
					this.props.element.static && (
						<div className="custom-form-group">
							<label>Text Style</label>
							<div className="custom-form-group custom-checkbox left-check">
								<input
									value
									id="do-bold"
									type="checkbox"
									checked={this_checked_bold}
									onChange={this.editElementProp.bind(this, 'bold', 'checked')}
								/>
								<label htmlFor="do-bold">Bold</label>
							</div>
							<div className="custom-form-group custom-checkbox left-check">
								<input
									value
									id="do-italic"
									type="checkbox"
									checked={this_checked_italic}
									onChange={this.editElementProp.bind(
										this,
										'italic',
										'checked'
									)}
								/>
								<label htmlFor="do-italic">Italic</label>
							</div>
						</div>
					)}
				{this.props.element.showDescription && (
					<div className="custom-form-group">
						<label htmlFor="questionDescription">Description</label>
						<TextAreaAutosize
							type="text"
							className="custom-input"
							id="questionDescription"
							defaultValue={this.props.element.description}
							onBlur={this.updateElement.bind(this)}
							onChange={this.editElementProp.bind(this, 'description', 'value')}
						/>
					</div>
				)}
				{this.props.showCorrectColumn &&
					this.props.element.canHaveAnswer &&
					!this.props.element.hasOwnProperty('options') && (
						<div className="custom-form-group">
							<label htmlFor="correctAnswer">Correct Answer</label>
							<input
								id="correctAnswer"
								type="text"
								className="custom-input"
								defaultValue={this.props.element.correct}
								onBlur={this.updateElement.bind(this)}
								onChange={this.editElementProp.bind(this, 'correct', 'value')}
							/>
						</div>
					)}
				{this.props.element.hasOwnProperty('options') && (
					<DynamicOptionList
						key={this.props.element.options.length}
						showCorrectColumn={this.props.showCorrectColumn}
						canHaveOptionCorrect={canHaveOptionCorrect}
						canHaveOptionValue={canHaveOptionValue}
						data={this.props.preview.state.data}
						updateElement={this.props.updateElement}
						preview={this.props.preview}
						element={this.props.element}
					/>
				)}
			</>
		);
	}
}
FormElementsEdit.defaultProps = { className: 'edit-element-fields' };
