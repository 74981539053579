import { forwardRef } from 'react';

export const Action = forwardRef(({ active, cursor, style, ...props }, ref) => {
	return (
		<button
			ref={ref}
			className="report-builder-action"
			{...props}
			tabIndex={0}
			style={{
				...style,
				cursor,
				'--fill': active?.fill,
				'--background': active?.background,
			}}
		/>
	);
});
