import React from "react";
import PhysicalRewardsListContainer from "../PhysicalRewardsListContainer/PhysicalRewardsListContainer.jsx";
import useCommonHooks from '../../../home/container/hooks/useCommonHooks.js';
import { Spin } from "antd";
import { usePhysicalRewardsService } from "../../service/physicalRewards.service.jsx";

const PhysicalRewardsContainer = () => {
  const physicalRewardsServiceAPI = usePhysicalRewardsService();
  const { isLoading, data } = useCommonHooks(
    physicalRewardsServiceAPI.getRewardsStatistics()
  );

  const SummaryStatBox = ({ color, count, label }) => {
    return (
      <div className="color-card" style={{ width: '33.33333%', background: color }}>
        <h4>{count}</h4>
        <p>{label}</p>
      </div>
    )
  };

  return <>
    <div className="contact-card-wrap">
      <div className="contact-card">
        <div className="contact-card-inner-without-white-bg">
          <div className="contact-color-card">
            {
              isLoading ?
                <Spin /> :
                <>
                  {
                    data?.map((reward, index) => <SummaryStatBox key={index} color={reward.color} count={reward.count} label={reward.label} />)
                  }
                </>
            }
          </div>
        </div>
      </div>
    </div>
    <div className="page-title">
      <h2 className="page-heading">Physical Rewards</h2>
    </div>
    <PhysicalRewardsListContainer />
  </>;
};

export default PhysicalRewardsContainer;
