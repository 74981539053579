import { createRoot } from 'react-dom/client';
import { Amplify } from 'aws-amplify';
import App from './app.component';
import { WithApiProvider } from 'src/_shared/api/index.js';
import ErrorHandler from './error-handler.jsx';

Amplify.configure({
	Auth: {
		region: 'us-east-2',
		userPoolId: 'us-east-2_G43SkZOZF',
		userPoolWebClientId: '43lo6tposab52aj6ft6u4c33cb',
	},
});
const rootElement = document.querySelector('#root');
const root = createRoot(rootElement);

root.render(
	<ErrorHandler>
		<WithApiProvider Component={App} />
	</ErrorHandler>
);
