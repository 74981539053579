import { Avatar as AntDAvatar } from 'antd';
import get from 'lodash/get';
import { useEffect, useState } from 'react';
import { downloadFromS3Signed } from '../../../services/utils';

export function Avatar({ currentUser }) {
	const [src, setSource] = useState('');
	useEffect(() => {
		const fetchAvatar = async () => {
			if (currentUser.avatar) {
				const presignedUrl = await downloadFromS3Signed(currentUser.avatar.key);
				setSource(presignedUrl);
			}
		};

		fetchAvatar();
	}, [currentUser.avatar]);

	return (
		<>
			{!currentUser.avatar ? (
				<AntDAvatar className="user-name">
					{`${get(currentUser, 'firstName[0]', '')}${get(
						currentUser,
						'lastName[0]',
						''
					)}`}
				</AntDAvatar>
			) : (
				<AntDAvatar className="user-name" src={src} />
			)}
		</>
	);
}
