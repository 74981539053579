import get from 'lodash/get';
import { Component } from 'react';
import { withApollo } from 'react-apollo';
import Spinner from 'src/_shared/components/spinner/SpinnerComponent.jsx';
import BrandingSettingsForm from './BrandCompanyFormComponent.jsx';

class BrandingComponent extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		const {
			company,
			onDeleteSubCompany,
			onUpdateSubCompany,
			onCreateSubCompany,
			onUpdateCompany,
			currentUser,
			subCompanies,
			updateCurrentUserCompany,
		} = this.props;
		if (!get(currentUser, 'company') || !company) {
			return <Spinner />;
		}

		return (
			<main>
				<BrandingSettingsForm
					company={company}
					currentUser={currentUser}
					companyNameText="Company Name"
					optionalText="optional"
					companyWebsiteText="Company Website"
					companyBrandText="Main Company"
					logoText="Main logo"
					removeLogoText="Remove Logo"
					subCompanies={subCompanies}
					updateCurrentUserCompany={updateCurrentUserCompany}
					onDeleteSubCompany={onDeleteSubCompany}
					onUpdateCompany={onUpdateCompany}
					onUpdateSubCompany={onUpdateSubCompany}
					onCreateSubCompany={onCreateSubCompany}
				/>
			</main>
		);
	}
}

export default withApollo(BrandingComponent);
