import { useState } from 'react'

import NominatedRewardsPresentation from '../../presentation/NominatedRewardsPresentation'
import ManageNominatedRewardContainer from '../ManageNominatedRewardContainer'

import useGetNominatedConfigList from '../../hooks/useGetNominatedConfigList.js'

const NominatedRewardsContainer = () => {

    const [showCreateModal, setShowCreateModal] = useState(false)

    const { nominatedBadgeList, nominatedBadgeListLoader, getNominationConfiguration } = useGetNominatedConfigList({ callBadgeList: true })

    return (
        <>
            <NominatedRewardsPresentation
                setShowCreateModal={setShowCreateModal}
                nominatedBadgeList={nominatedBadgeList}
                nominatedBadgeListLoader={nominatedBadgeListLoader}
                getNominationConfiguration={getNominationConfiguration} />

            {showCreateModal ? <ManageNominatedRewardContainer
                setShowModal={setShowCreateModal}
                getNominationConfiguration={getNominationConfiguration}
                showModal={showCreateModal} create /> : null}
        </>
    )
}

export default NominatedRewardsContainer