import { createContext, useContext } from 'react';
import { useRnRAPIService } from './../../rewardsAndRecognitionHelper/service/RnRAPI.service.jsx'
import { HOME_API_ENDPOINTS } from './../constant/homeApi.constant.js'

const HomeServiceContext = createContext(null);

export const HomeServiceProvider = (props) => {
    const APIService = useRnRAPIService();

    const getLeaderBoardData = () => {
        return APIService.get(HOME_API_ENDPOINTS.GET_LEADERBOARD);
    };

    const getReward = () => {
        return APIService.get(HOME_API_ENDPOINTS.GET_REWARD);
    };

    const createBadgeAssignment = (requestBody) => {
        return APIService.post(HOME_API_ENDPOINTS.CREATE_BADGE_ASSIGNMENT, requestBody);
    };

    const getBadgesDashboard = (request) => {
        return APIService.get(HOME_API_ENDPOINTS.GET_BADGES_DASHBOARD, request);
    };

    const getEmployeeList = (request) => {
        return APIService.get(HOME_API_ENDPOINTS.GET_EMPLOYEE_LIST, request);
    };

    const getIssuedBadgeDashboard = (id) => {
        return APIService.get(HOME_API_ENDPOINTS.GET_ISSUED_BADGE_DASHBOARD + id);
    };

    const getEmployeeRewards = (request) => {
        return APIService.get(HOME_API_ENDPOINTS.GET_EMPLOYEE_REWARDS, request);
    }

    const getRecentActivity = (request) => {
        return APIService.get(HOME_API_ENDPOINTS.GET_RECENT_ACTIVITY, request);
    }

    const createFeedInteraction = (requestBody) => {
        return APIService.post(HOME_API_ENDPOINTS.CREATE_FEED_INTERACTION, requestBody);
    };

    const getFeedInteraction = (request) => {
        return APIService.get(HOME_API_ENDPOINTS.GET_FEED_INTERACTION, request);
    }

    const getRecentActivityProperties = (request) => {
        return APIService.get(HOME_API_ENDPOINTS.GET_RECENT_ACTIVITY_PROPERTIES, request)
    }

    const HomeServiceOperations = {
        getLeaderBoardData,
        getReward,
        createBadgeAssignment,
        getBadgesDashboard,
        getEmployeeList,
        getIssuedBadgeDashboard,
        getEmployeeRewards,
        getRecentActivity,
        createFeedInteraction,
        getFeedInteraction,
        getRecentActivityProperties
    };

    return (
        <HomeServiceContext.Provider
            value={HomeServiceOperations}
        >
            {props.children}
        </HomeServiceContext.Provider>
    );
};

export const useHomeService = () => {
    return useContext(HomeServiceContext);
};
