import { Select, Table, message } from 'antd';
import gql from 'graphql-tag';
import Cookies from 'js-cookie';
import _, { get } from 'lodash';
import { Component } from 'react';
import { withApollo } from 'react-apollo';
import ReactGA from 'react-ga';
import { Link } from 'react-router-dom';
import { getUserById } from 'src/_shared/api/graphql/custom/users/';
import { Spinner, WebIcon } from 'src/_shared/index.js';
import fileIcon from '../_shared/assets/erin_lightgray.png';
import { SearchComponent } from '../_shared/components/search';
import {
	downloadFromS3Signed,
	logout,
	ml,
	searchOpenSearchNetwork,
} from '../_shared/services/utils.js';

const { Option } = Select;
class OpenToNewRoleComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			theme: JSON.parse(get(props, 'currentUser.company.theme', '{}')),
			visible: false,
			lengthError: false,
			query: '',
			statusFilter: 'active',
			success: false,
			loaded: false,
			firstLoad: true,
			activeEmployees: null,
			buttonState: '',
			edit: false,
			editorKey: 4,
			filteredDepartments: [],
			filteredRoles: [],
			company: get(props, 'currentUser.company'),
			filterEmployeeStatus: 'active',
			companyWideSelfReferrals: [
				...props?.referrals.filter(
					(referral) => referral.referralType === 'self'
				),
			],
		};
	}

	async componentDidMount() {
		const host = window.location.hostname;
		if (host === 'referrals.aus.com') {
			ReactGA.initialize('UA-128630809-2');
			ReactGA.pageview(window.location.pathname + window.location.search);
		} else if (host === 'app.erinapp.com') {
			ReactGA.initialize('UA-128630809-3');
			ReactGA.pageview(window.location.pathname + window.location.search);
		}

		await this.getUserDataById();
		const jwt = Cookies.get('jwt');
		const { resultData } = this.state;
		if (resultData !== '' && resultData !== undefined) {
			const expirationDoneByToken = get(
				resultData,
				'expirationDoneByToken',
				null
			);
			const expires = get(resultData, 'expires');
			if (jwt !== undefined && jwt !== expirationDoneByToken && expires) {
				logout(this.props.client);
			}
		}

		await this.setQueryToState(get(this.state, 'searchQuery', ''), 0);

		let errorImageSource;
		const errorImage = get(
			this.props,
			'currentUser.company.errorImage.key',
			false
		);
		if (errorImage) {
			errorImageSource = await downloadFromS3Signed(errorImage, 'erin-images');
		}

		this.setState({ errorImageSrc: errorImageSource });
	}

	async getUserDataById(policy = 'network-only') {
		const { client, currentUser } = this.props;
		try {
			const userId = get(currentUser, 'id', null);
			if (userId !== null) {
				const { data } = await client.query({
					query: gql(getUserById),
					variables: {
						id: userId,
					},
					fetchPolicy: policy,
				});
				const result = get(data, 'getUser', null);
				this.setState({
					resultData: result,
				});
			}
		} catch (error) {
			console.log(error);
		}
	}

	setQueryToState = async (searchQuery = '', timeout = 500) => {
		const {
			company,
			filteredDepartments = [],
			filteredRoles = [],
			filteredStatus = 'all',
		} = this.state;
		this.setState({ searchQuery });
		clearTimeout(this.timer);
		if (searchQuery === '' && filteredDepartments.length <= 0) {
			this.setState({
				loading: false,
				searchedEmployees: [],
			});
		}

		this.setState({ loading: true });
		this.timer = setTimeout(async () => {
			const parameters = {
				query: searchQuery,
				size: 300,
				role: get(this.props, 'currentUser.role'),
				currentUser: get(this.props, 'currentUser.id'),
				filters: {
					companies: get(company, 'id'),
					departments: filteredDepartments,
					roles: filteredRoles,
					active: filteredStatus,
					openToNewRole: true,
				},
			};

			const response = await searchOpenSearchNetwork(parameters, 'erin-users');
			if (get(response, 'query') === get(this.state, 'searchQuery')) {
				const searchedEmployees = await Promise.all(
					[...get(response, 'data', [])].map(async (record) => {
						record.avatarSrc = record.avatar?.key
							? await downloadFromS3Signed(record.avatar.key)
							: null;
						return record;
					})
				);
				this.setState({
					loading: false,
					searchedEmployees,
				});
			}
		}, timeout);
	};

	cancelEdit = () => {
		message.error('Edit Cancelled');
		this.setState({
			edit: false,
		});
	};

	capFirst(value_) {
		const value = value_.split('');
		const V = _.get(value, '[0]', '').toUpperCase();
		const alue = value.slice(1, value.length).join('');
		const Value = V + alue;
		return Value;
	}

	handleFilterDepartment = (department, key) => {
		let { filteredDepartments = [], searchQuery } = this.state;
		if (filteredDepartments.map((dep) => dep.name).includes(department)) return;
		filteredDepartments = [
			...filteredDepartments,
			{ name: department, id: key },
		];
		this.setState(
			{
				filteredDepartments,
			},
			async () => await this.setQueryToState(searchQuery)
		);
	};

	handleFilterRole = (role) => {
		let { filteredRoles = [], searchQuery } = this.state;
		if (filteredRoles.includes(role)) return;
		filteredRoles = [...filteredRoles, role];
		this.setState(
			{
				filteredRoles,
			},
			async () => await this.setQueryToState(searchQuery)
		);
	};

	handleFilterStatus = (key) => {
		const { searchQuery } = this.state;

		this.setState(
			{
				filteredStatus: key,
			},
			async () => await this.setQueryToState(searchQuery)
		);
	};

	handleRemoveDepartmentFilter = (department) => {
		const { filteredDepartments = [], searchQuery } = this.state;
		const index = filteredDepartments
			.map((dep) => dep.name)
			.indexOf(department);
		filteredDepartments.splice(index, 1);
		this.setState(
			{
				filteredDepartments,
			},
			async () => await this.setQueryToState(searchQuery)
		);
	};

	handleRemoveRoleFilter = (role) => {
		const { filteredRoles = [], searchQuery } = this.state;
		const index = filteredRoles.indexOf(role);
		filteredRoles.splice(index, 1);
		this.setState(
			{
				filteredRoles,
			},
			async () => await this.setQueryToState(searchQuery)
		);
	};

	showModal = () => {
		this.setState({
			visible: true,
		});
	};

	renderArrowIcon = () => {
		return (
			<div>
				<WebIcon color="var(--oslo-gray)" size={10} name="sort-down" />
			</div>
		);
	};

	render() {
		const { allMultiLingualData, currentUser, departments } = this.props;
		const {
			searchQuery = '',
			loading,
			loaded,
			filteredDepartments = [],
			searchedEmployees,
			company,
			companyWideSelfReferrals,
			errorImageSrc,
		} = this.state;
		const whiteLabel = get(currentUser, 'company.whiteLabel');
		if (!get(currentUser, 'company') || !this.state.searchedEmployees) {
			return <Spinner />;
		}

		if (this.state.loaded !== true) {
			setTimeout(() => {
				this.setState({ loaded: true });
			}, 2000);
		}

		const sortedDepartments = _.sortBy(departments, ['name']);
		const activeDepartments = (sortedDepartments || []).filter(
			(department) => department.active
		);

		const options = activeDepartments.map((department) => {
			return (
				<Option key={department.id} value={department.name}>
					{department.name}
				</Option>
			);
		});
		const roleOptions = ['admin', 'employee', 'manager'];
		const sort = (a = '', b = '') => {
			if (!a) return -1;
			if (!b) return 1;
			if (typeof a === 'string') {
				a = a.toLowerCase();
			}

			if (typeof b === 'string') {
				b = b.toLowerCase();
			}

			if (a > b) return 1;
			if (a < b) return -1;
			return 0;
		};

		const columns = [
			{
				title: '',
				key: (record) => `avatar${get(record, 'id')}`,
				width: 70,
				visible: true,
				render(record) {
					return (
						<>
							{record.avatar ? (
								<div className="custom-avatar-wrap">
									<img
										src={record.avatarSrc}
										className="custom-img"
										alt={`${record.firstName} ${record.lastName}`}
									/>
								</div>
							) : (
								<div className="custom-image-wrap">
									{record.firstName && record.lastName ? (
										<>
											{record.firstName[0]}
											{record.lastName[0]}
										</>
									) : null}
								</div>
							)}
						</>
					);
				},
			},
			{
				title: ml('Name', currentUser, allMultiLingualData),
				visible: true,
				key: (record) => `name${get(record, 'id')}`,
				render: (record) =>
					record.firstName && record.lastName && record.cognitoId ? (
						<Link
							className="table-link"
							to={{
								pathname: `/openDetails/${record.id}`,
								state: { currentUser: record.currentUser },
							}}
						>
							{record.firstName} {record.lastName}
						</Link>
					) : record.firstName && record.lastName && !record.cognitoId ? (
						<>
							{record.firstName} {record.lastName}
						</>
					) : null,
				showSorterTooltip: false,
				sorter: (a, b) =>
					sort(
						`${get(a, 'firstName')}
              ${get(a, 'lastName')}`,
						`${get(b, 'firstName')}
              ${get(b, 'lastName')}`
					),
			},
			{
				title: ml('Email Address', currentUser, allMultiLingualData),
				visible: false,
				key: (record) => `email${get(record, 'id')}`,
				width: '20%',
				render: (record) =>
					record.emailAddress && record.cognitoId ? (
						<Link
							className="table-link"
							to={{
								pathname: `/openDetails/${record.id}`,
								state: { currentUser: record.currentUser },
							}}
						>
							{record.emailAddress}
						</Link>
					) : (
						<>{record.emailAddress}</>
					),
				showSorterTooltip: false,
				sorter: (a, b) => sort(a.emailAddress, b.emailAddress),
			},
			{
				title: ml('Job Title', currentUser, allMultiLingualData),
				visible: true,
				key: (record) => `title${get(record, 'id')}`,
				render: (record) =>
					get(record, 'title') ? <>{get(record, 'title')}</> : null,
				showSorterTooltip: false,
				sorter(a, b) {
					return sort(get(a, 'title'), get(b, 'title'));
				},
			},
			{
				title: ml('Department', currentUser, allMultiLingualData),
				visible: true,
				key: (record) => `department${get(record, 'id')}`,
				render: (record) => <>{get(record, 'department.name')}</>,
				showSorterTooltip: false,
				sorter: (a, b) =>
					sort(get(a, 'department.name', 'z'), get(b, 'department.name', 'z')),
			},
			{
				title: ml('Applications', currentUser, allMultiLingualData),
				visible: true,
				key: (record) => `applications${get(record, 'id')}`,
				render(record) {
					const applicationCount = companyWideSelfReferrals.filter(
						(referral) => referral.userId === record.id
					).length;
					return <>{applicationCount}</>;
				},
				showSorterTooltip: false,
				sorter: (a, b) =>
					sort(get(a, 'department.name', 'z'), get(b, 'department.name', 'z')),
			},
		];

		const locale = {
			emptyText: <Spinner company={get(currentUser, 'company')} />,
		};

		return (
			<main>
				<div className="page-title">
					<h2 className="page-heading">
						{ml('Open to New Roles', currentUser, allMultiLingualData)}
					</h2>
				</div>
				<div className="page-top-filter">
					<div className="filter-wrap">
						<SearchComponent
							loading={loading}
							searchQuery={searchQuery}
							setQueryToState={this.setQueryToState}
							placeholder={ml('Search', currentUser, allMultiLingualData)}
						/>
						<Select
							showArrow
							mode="multiple"
							placeholder={ml('Department', currentUser, allMultiLingualData)}
							maxTagCount={1}
							maxTagTextLength={15}
							suffixIcon={this.renderArrowIcon(filteredDepartments.length)}
							dropdownMatchSelectWidth={false}
							onSelect={(department, { key }) =>
								this.handleFilterDepartment(department, key)
							}
							onDeselect={(department, { key }) =>
								this.handleRemoveDepartmentFilter(department, key)
							}
						>
							{options}
						</Select>
					</div>
				</div>
				<div className="table-card">
					{searchedEmployees && searchedEmployees.length > 0 ? (
						<Table
							pagination={{ pageSize: 50, showSizeChanger: false }}
							rowKey={(record) => record.id}
							dataSource={searchedEmployees}
							columns={columns}
							locale={locale}
							scroll={{ x: 656 }}
						/>
					) : loaded && !loading ? (
						<div className="no-content">
							{whiteLabel ? (
								<img
									src={errorImageSrc}
									alt="error image"
									className="no-content-icon"
								/>
							) : (
								<img
									alt="erin-logo"
									className="no-content-icon"
									src={fileIcon}
								/>
							)}
							<p className="no-content-text">
								There are not any employees available.
							</p>
						</div>
					) : (
						<Spinner company={company} />
					)}
				</div>
			</main>
		);
	}
}

export default withApollo(OpenToNewRoleComponent);
