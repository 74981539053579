import { connect } from 'react-redux';
import { userActions } from 'src/actions.js';
import { compose } from '../_shared/services/utils.js';
import LoginComponent from './LoginComponent.jsx';
import LogoutComponent from './LogoutComponent.jsx';
import SAMLMobileLoginComponent from './SamlMobileLoginComponent.jsx';
import SAMLWebLoginComponent from './SamlWebLoginComponent.jsx';

const mapStateToProperties = (state, props) => {
	const { id } = props.match.params;
	return {
		id,
		failedLoginAttempts: state.user.failedLoginAttempts,
	};
};

const mapDispatchToProperties = (dispatch) => {
	return {
		setCurrentUser(user) {
			dispatch(userActions.createSetCurrentUserAction(user));
		},
		setFailedLoginAttempts(user) {
			dispatch(userActions.updateFailedLoginAttempts(user));
		},
	};
};

const LoginComponentWithApi = compose()(LoginComponent);
const SAMLMobileLoginComponentWithApi = compose()(SAMLMobileLoginComponent);
const SAMLWebLoginComponentWithApi = compose()(SAMLWebLoginComponent);

export const Login = connect(
	mapStateToProperties,
	mapDispatchToProperties
)(LoginComponentWithApi);

export const SAMLMobileLogin = connect(
	mapStateToProperties,
	mapDispatchToProperties
)(SAMLMobileLoginComponentWithApi);

export const SAMLWebLogin = connect(
	mapStateToProperties,
	mapDispatchToProperties
)(SAMLWebLoginComponentWithApi);

export const Logout = connect(
	mapStateToProperties,
	mapDispatchToProperties
)(LogoutComponent);
