import dayjs from 'dayjs';
import get from 'lodash/get';
import { connect } from 'react-redux';
import { withJobMatchesById } from 'src/_shared/api/components/jobs/';
import { compose, lambda } from '../../_shared/services/utils.js';
import JobCardComponent from './JobCardComponent.jsx';

const fetchReferralCount = async (jobId) => {
	try {
		const endpoint = 'jobDetailReferralCount';
		let referralsCount = 0;
		let acceptedReferralsCount = 0;
		const data = await lambda({ endpoint, variables: { jobId } });
		if (data?.message) {
			referralsCount = data.message?.referralsCount;
			acceptedReferralsCount = data.message?.acceptedReferralsCount;
		}
		return { referralsCount, acceptedReferralsCount };
	} catch (error) {
		console.error('Error fetching referral count:', error);
		return 0;
	}
};

const mapStateToProperties = (state, ownProperties) => {
	const { currentUser } = state.user;
	const { job } = ownProperties;
	const bonus =
		typeof job?.referralBonus === 'string'
			? JSON.parse(job.referralBonus)
			: job?.referralBonus;
	const campaign = job?.campaign;
	let isActive = false;
	if (campaign?.id && !campaign?.archived) {
		const today = dayjs();
		isActive = Boolean(today.isBetween(campaign?.startDate, campaign?.endDate, 'day', '[]'));
	}

	const currentTieredBonus = isActive
		? get(ownProperties, 'job.campaign.tieredBonus', 'skip')
		: get(bonus, 'tieredBonus', 'skip');
	const currentTieredBonusId = isActive
		? get(ownProperties, 'job.campaign.tieredBonusId', 'skip')
		: get(bonus, 'tieredBonusId', 'skip');

	return {
		currentUser,
		referrals: state.referral.referrals,
		companyId: currentUser.companyId,
		jobId: job?.id,
		currentTieredBonus,
		currentTieredBonusId
	};
};

const mapDispatchToProperties = () => {
	return {};
};

export default connect(
	mapStateToProperties,
	mapDispatchToProperties
)(compose(withJobMatchesById)(JobCardComponent));
