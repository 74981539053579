import get from 'lodash/get';
import { compose, graphql } from 'react-apollo';
import { JobMatchesById } from '../../graphql/custom/jobs';

export const withJobMatchesById = (Component, variables) => {
	return compose(
		graphql(JobMatchesById, {
			options(props) {
				const jobId = get(props, 'jobId', 'skip');
				return {
					context: {
						headers: {
							'x-frame-options': 'deny', // This header will reach the server
						},
					},
					variables: { jobId: jobId, first: 25, ...variables },
					fetchPolicy: 'network-only',
				};
			},
			props({ data }) {
				const matches = get(data, 'queryJobMatchesByJobIdIndex.items', []);
				const nextToken = get(data, 'queryJobMatchesByJobIdIndex.nextToken', null);

				const fetchMoreMatches = async () => {
					if (nextToken) {
						await data.fetchMore({
							variables: {
								after: nextToken,
							},
							updateQuery: (previousResult, { fetchMoreResult }) => {
								if (!fetchMoreResult) return previousResult;

								return {
									queryJobMatchesByJobIdIndex: {
										...fetchMoreResult.queryJobMatchesByJobIdIndex,
										items: [
											...previousResult.queryJobMatchesByJobIdIndex.items,
											...fetchMoreResult.queryJobMatchesByJobIdIndex.items,
										],
										nextToken: fetchMoreResult.queryJobMatchesByJobIdIndex.nextToken,
									},
								};
							},
						});
					}
				};

				if (nextToken) {
					fetchMoreMatches();
				}

				return {
					matches,
					loading: data.loading,
					error: data.error,
					matchesNextToken: data.queryJobMatchesByJobIdIndex?.nextToken,
				};
			},
		})
	)(Component);
};
