import gql from 'graphql-tag';
import get from 'lodash/get';
import { compose, graphql } from 'react-apollo';
import { parseJsonFields } from '../../../services/parseApiService';
import { GetJobNoReferrals, updateJobBrowseJobs } from '../../graphql/custom/jobs';
import { updateReferral } from '../../graphql/custom/referrals';
import { listDepartments } from '../../graphql/default';

export const withJobByIdBrowseJobs = (Component, variables) => {
	return compose(
		graphql(GetJobNoReferrals, {
			options: {
				context: {
					headers: {
						'x-frame-options': 'deny', // This header will reach the server
					},
				},
				variables,
				fetchPolicy: 'cache-and-network',
			},
			props(props) {
				let job = get(props, 'data.getJob', undefined);
				job &&= Object.assign(
					job,
					parseJsonFields(['location', 'salary', 'referralBonus'], job)
				);
				return {
					currentJob: job,
				};
			},
		}),
		graphql(gql(listDepartments), {
			options: { variables, fetchPolicy: 'cache-and-network' },
			props: (props) => ({
				departments: props.data.listDepartments
					? props.data.listDepartments.items
					: [],
			}),
		}),

		graphql(gql(updateJobBrowseJobs), {
			props: (props) => ({
				onUpdateJob(input) {
					const optimisticResponseData = {
						...input,
					};
					return props.mutate({
						variables: { input },
						optimisticResponse: {
							__typename: 'Mutation',
							updateJob: {
								...optimisticResponseData,
								__typename: 'updateJob',
							},
						},
					});
				},
			}),
		}),
		graphql(gql(updateReferral), {
			props: (props) => ({
				onUpdateReferral(input) {
					const optimisticResponseData = {
						...input.input,
					};
					props.mutate({
						variables: input,
						optimisticResponse: {
							__typename: 'Mutation',
							updateReferral: {
								...optimisticResponseData,
								__typename: 'updateReferral',
							},
						},
					});
				},
			}),
		})
	)(Component);
};
