import { Modal } from 'antd';
import { Component } from 'react';
import * as ModalStyles from '../../../../employees-dashboard/employees-dashboard-components/employeesModalStyles.js';
import AddContactsChoice from './AddContactsChoiceComponent.jsx';
import AddContactsEmail from './AddContactsEmailComponent.jsx';
import AddContactsGoogle from './AddContactsGoogleComponent.jsx';
import AddContactsLinkedin from './AddContactsLinkedinComponent.jsx';
import AddContactsMicrosoft from './AddContactsMicrosoftComponent.jsx';
import AddContactsNavigation from './AddContactsNavigationComponent.jsx';

class AddContactsModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			error: false,
			size: 'large',
			emailInput: '',
			contactsChoice: 0,
			modalContent: <AddContactsChoice contactsChoice={this.contactsChoice} />,
			modalProps: {
				title: false,
			},
		};
	}

	contactsChoice = (choice) => {
		switch (choice) {
			case 0: {
				this.setState({
					modalProps: {
						title: false,
					},
					modalContent: (
						<AddContactsChoice contactsChoice={this.contactsChoice} />
					),
				});
				break;
			}

			case 1: {
				this.setState({
					modalProps: {
						title: (
							<AddContactsNavigation contactsChoice={this.contactsChoice} />
						),
					},
					modalContent: (
						<AddContactsLinkedin
							contactsChoice={this.contactsChoice}
							currentUser={this.props.currentUser}
							handleCancel={this.props.handleCancel}
							updateUserIncentiveEligibility={
								this.props.updateUserIncentiveEligibility
							}
							onCreateContact={this.props.onCreateContact}
						/>
					),
				});
				break;
			}

			case 2: {
				this.setState({
					modalProps: {
						title: (
							<AddContactsNavigation contactsChoice={this.contactsChoice} />
						),
					},
					modalContent: (
						<AddContactsGoogle
							key="addContactsGoogle"
							contactsChoice={this.contactsChoice}
							currentUser={this.props.currentUser}
							handleCancel={this.props.handleCancel}
							updateUserIncentiveEligibility={
								this.props.updateUserIncentiveEligibility
							}
							onCreateContact={this.props.onCreateContact}
						/>
					),
				});
				break;
			}

			case 3: {
				this.setState({
					modalProps: {
						title: (
							<AddContactsNavigation contactsChoice={this.contactsChoice} />
						),
					},
					modalContent: (
						<AddContactsMicrosoft
							key="addContactsMicrosoft"
							contactsChoice={this.contactsChoice}
							currentUser={this.props.currentUser}
							handleCancel={this.props.handleCancel}
							updateUserIncentiveEligibility={
								this.props.updateUserIncentiveEligibility
							}
							onCreateContact={this.props.onCreateContact}
						/>
					),
				});
				break;
			}

			case 4: {
				this.setState({
					modalProps: {
						title: (
							<AddContactsNavigation contactsChoice={this.contactsChoice} />
						),
					},
					modalContent: (
						<AddContactsEmail
							key="addContactsEmail"
							contactsChoice={this.contactsChoice}
							currentUser={this.props.currentUser}
							handleCancel={this.props.handleCancel}
							updateUserIncentiveEligibility={
								this.props.updateUserIncentiveEligibility
							}
							onCreateContact={this.props.onCreateContact}
						/>
					),
				});
				break;
			}

			default: {
				this.setState({
					modalProps: {
						title: false,
					},
					modalContent: (
						<AddContactsChoice contactsChoice={this.contactsChoice} />
					),
				});
				break;
			}
		}
	};

	handleError = () => {
		this.setState({ error: true });
	};

	render() {
		const { visible, handleCancel } = this.props;
		const { modalProps, modalContent } = this.state;

		return (
			<Modal
				className={ModalStyles}
				open={visible}
				footer={null}
				onCancel={handleCancel}
				{...modalProps}
				width={620}
				bodyStyle={{ border: 'none' }}
				cancelText="Cancel"
			>
				<div>{modalContent}</div>
			</Modal>
		);
	}
}

export default AddContactsModal;
