import React from 'react';
import { Spin, Tabs } from 'antd';
import NewestFeed from './newestFeed.jsx';
import './style.scss';
import store from 'src/state';

const Feed = (props) => {
	const {
		feedListData,
		handleClapCount,
		setClapCount,
		clapCount,
		isFeedLoading,
		fetchMoreData,
		isLoadMoreCalled,
		hasMoreData,

		isMyBranchLoadMoreCalled,
		myBranchHasMoreData,
		myBranchFeedListData,
		isMyBranchFeedLoading,
		setMyBranchClapCount,
		myBranchclapCount,
		handleMyBranchClapCount,
		fetchMyBranchMoreData,

		isTopLoadMoreCalled,
		topHasMoreData,
		topFeedListData,
		isTopFeedLoading,
		fetchTopMoreData,
		setTopClapCount,
		topclapCount,
		handleTopClapCount
	} = props;
	const { user: { currentUser } } = store.getState();
	const teamLabel = currentUser?.department?.name
		|| currentUser?.company?.name
		|| 'My Team';

	const tabItems = [
		{
			key: '1',
			label: 'Newest',
			children: isFeedLoading ? (
				<Spin style={{ position: 'absolute', left: '50%', transform: 'translate(-50%, 50%)' }} />
			) : (
				<div className='scroll-card'>
					<NewestFeed
						fetchMoreData={fetchMoreData}
						feedListData={feedListData}
						setClapCount={setClapCount}
						clapCount={clapCount}
						handleClapCount={handleClapCount}
						isFeedLoading={isFeedLoading}
						hasMoreData={hasMoreData}
						isLoadMoreCalled={isLoadMoreCalled} />
				</div>
			),
		},
		{
			key: '2',
			label: 'Top',
			children: isFeedLoading ? (
				<Spin style={{ position: 'absolute', left: '50%', transform: 'translate(-50%, 50%)' }} />
			) : (
				<div className='scroll-card'>
					<NewestFeed
						fetchMoreData={fetchTopMoreData}
						feedListData={topFeedListData}
						setClapCount={setTopClapCount}
						clapCount={topclapCount}
						handleClapCount={handleTopClapCount}
						isFeedLoading={isTopFeedLoading}
						hasMoreData={topHasMoreData}
						isLoadMoreCalled={isTopLoadMoreCalled} />
				</div>
			),
		},
		{
			key: '3',
			label: teamLabel,
			children: isFeedLoading ? (
				<Spin style={{ position: 'absolute', left: '50%', transform: 'translate(-50%, 50%)' }} />
			) : (
				<div className='scroll-card'>
					<NewestFeed
						fetchMoreData={fetchMyBranchMoreData}
						feedListData={myBranchFeedListData}
						setClapCount={setMyBranchClapCount}
						clapCount={myBranchclapCount}
						handleClapCount={handleMyBranchClapCount}
						isFeedLoading={isMyBranchFeedLoading}
						hasMoreData={myBranchHasMoreData}
						isLoadMoreCalled={isMyBranchLoadMoreCalled} />
				</div>
			),
		},
	];

	return (
		<div style={{ height: '100%' }} className="feed-container">
			<div style={{ height: '100% '}} className="Feed ">
				<div style={{ height: '100%' }} className="d-card feed-wrap">
					<h4 className="feed-title">FEED</h4>
					<Tabs defaultActiveKey="1" destroyInactiveTabPane items={tabItems} />
				</div>
			</div>
		</div>
	);
};

export default Feed;