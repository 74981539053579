import gql from 'graphql-tag';
import get from 'lodash/get';
import { compose, graphql } from 'react-apollo';
import { queryWebNotificationsByJobIdStatusIndex } from 'src/_shared/api/graphql/custom/users/';

export const withListReferralRequestedNotificationsByJobIdStatus = (
	Component
) => {
	return compose(
		graphql(gql(queryWebNotificationsByJobIdStatusIndex), {
			options(props) {
				return {
					pollInterval: 300_000,
					context: {
						headers: {
							'x-frame-options': 'deny', // This header will reach the server
						},
					},
					variables: {
						jobId: get(props, 'jobId'),
						status: 'referred',
						nextToken: null,
					},
					fetchPolicy: 'cache-and-network',
				};
			},
			props(props) {
				let notifications = get(
					props,
					'data.queryWebNotificationsByJobIdStatusIndex.items'
				);
				if (!notifications) return null;
				notifications = notifications.map((notification) => {
					const newNotification = notification;
					if (get(notification, 'type') === 'gdprReferralCreated')
						newNotification.referralDate = get(notification, 'dateCreated');
					if (typeof get(notification, 'job.referralBonus') === 'string') {
						newNotification.job.referralBonus = JSON.parse(
							get(notification, 'job.referralBonus', '{}')
						);
					}

					return newNotification;
				});
				return {
					gdprReferrals: notifications,
				};
			},
		})
	)(Component);
};
