import { Popconfirm, message } from 'antd';

export function SubCompaniesListItem(props) {
	const { subCompanies = [], onDeleteSubCompany } = props;

	const deleteSubCompanyData = async (id) => {
		await onDeleteSubCompany({
			input: {
				id,
			},
		});
		message.success('Successfully Deleted Keyword');
	};

	return (
		<ul className="tag-wrap mb-3">
			{subCompanies
				? subCompanies.map((subCompany) => {
						return (
							<li key={subCompany.id} className="tag green">
								<span className="tag-name"> {subCompany.name} </span>
								<Popconfirm
									placement="left"
									title="Are you sure？"
									okText="Delete"
									cancelText="Cancel"
									onConfirm={() => deleteSubCompanyData(subCompany.id)}
								>
									<i className="icon-cross tag-close" />
								</Popconfirm>
							</li>
						);
					})
				: null}
		</ul>
	);
}
