import { Component } from 'react';
import ID from './UUID.js';

export default class DynamicOptionList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			element: this.props.element,
			data: this.props.data,
			dirty: false,
		};
	}

	_setValue(text) {
		return text.replaceAll(/[^a-z\d]+/gi, '_').toLowerCase();
	}

	addOption(index) {
		const this_element = this.state.element;
		this_element.options.splice(index + 1, 0, {
			value: '',
			text: '',
			key: ID.uuid(),
		});
		this.props.updateElement.call(this.props.preview, this_element);
	}

	editOption(option_index, e) {
		const this_element = this.state.element;
		const value =
			this_element.options[option_index].value ===
			this._setValue(this_element.options[option_index].text)
				? this._setValue(e.target.value)
				: this_element.options[option_index].value;

		this_element.options[option_index].text = e.target.value;
		this_element.options[option_index].value = value;
		this.setState({
			element: this_element,
			dirty: true,
		});
	}

	// eslint-disable-next-line no-unused-vars
	editOptionCorrect(option_index, e) {
		const this_element = this.state.element;
		if (this_element.options[option_index].hasOwnProperty('correct')) {
			delete this_element.options[option_index].correct;
		} else {
			this_element.options[option_index].correct = true;
		}

		this.setState({ element: this_element });
		this.props.updateElement.call(this.props.preview, this_element);
	}

	editValue(option_index, e) {
		const this_element = this.state.element;
		const value =
			e.target.value === ''
				? this._setValue(this_element.options[option_index].text)
				: e.target.value;
		this_element.options[option_index].value = value;
		this.setState({
			element: this_element,
			dirty: true,
		});
	}

	removeOption(index) {
		const this_element = this.state.element;
		this_element.options.splice(index, 1);
		this.props.updateElement.call(this.props.preview, this_element);
	}

	updateOption() {
		const this_element = this.state.element;
		// To prevent ajax calls with no change
		if (this.state.dirty) {
			this.props.updateElement.call(this.props.preview, this_element);
			this.setState({ dirty: false });
		}
	}

	render() {
		if (this.state.dirty) {
			this.state.element.dirty = true;
		}

		return (
			<div className="dynamic-option-list">
				<ul>
					<li>
						<div className="fb-options">
							<label>Options</label>
						</div>
						<div className="fb-value">
							{this.props.canHaveOptionValue && <label>Value</label>}
						</div>
					</li>
					{this.props.element.options.map((option, index) => {
						const this_key = `edit_${option.key}`;
						const value =
							option.value === this._setValue(option.text) ? '' : option.value;
						return (
							<li key={this_key}>
								<div className="fb-options">
									<div className="custom-form-group">
										<input
											tabIndex={index + 1}
											className="custom-input"
											type="text"
											name={`text_${index}`}
											placeholder="Option text"
											value={option.text}
											onBlur={this.updateOption.bind(this)}
											onChange={this.editOption.bind(this, index)}
										/>
									</div>
								</div>
								{this.props.canHaveOptionValue && (
									<div className="fb-value">
										<div className="custom-form-group">
											<input
												className="custom-input"
												type="text"
												name={`value_${index}`}
												value={value}
												onChange={this.editValue.bind(this, index)}
											/>
										</div>
									</div>
								)}
								<div className="fb-btn">
									<button
										className="btn btn-success"
										onClick={this.addOption.bind(this, index)}
									>
										<i className="fas fa-plus-circle" />
									</button>
									{index > 0 && (
										<button
											className="btn btn-danger"
											onClick={this.removeOption.bind(this, index)}
										>
											<i className="fas fa-minus-circle" />
										</button>
									)}
								</div>
							</li>
						);
					})}
				</ul>
			</div>
		);
	}
}
