import { useEffect } from "react"
import { Modal, Table } from "antd"
import './style.scss'
import NoDataState from "../../../RnRCommonComponent/noDataState/NoDataState.jsx"

const VoterListModalModal = ({ showModal, setShowModal, nomineeVoterList, nomineeId, nominationConfigId, getNomineeVoterListById }) => {

    const columns = [
        {
            title: 'Nominator',
            dataIndex: 'nominator',
            key: 'nominator',
        }, {
            title: 'Reason',
            dataIndex: 'nominationReason',
            key: 'nominationReason',
        }
    ]

    const handleCancel = () => {
        setShowModal({ show: false, voterList: [] })
    }

    useEffect(() => {
        nomineeId && getNomineeVoterListById(nominationConfigId, nomineeId)
    }, [nomineeId])

    return (
        <Modal
            open={showModal}
            title={`Nominators`}
            closable={true}
            onCancel={handleCancel}
            footer={null}
            width={"500px"}
            className="winner-list-modal reward-modal"
        >
            <div className="table-card">
                <Table
                    className="w-100"
                    rowKey={(record) => record.id}
                    dataSource={nomineeVoterList}
                    pagination={false}
                    columns={columns}
                    locale={{ emptyText: <NoDataState title={"No data found."} /> }}
                />
            </div>
        </Modal>
    )
}

export default VoterListModalModal