import gql from 'graphql-tag';
import get from 'lodash/get';
import { compose, graphql } from 'react-apollo';
import uuid from 'uuid/v4';
import {
	createCampaign,
	queryCampaignsByCompanyIdIndex,
	updateCampaign,
} from '../../graphql/custom/bonus-campaign';
import { updateJobCampaign } from '../../graphql/custom/jobs';

export const withCreateCampaign = (Component) => {
	return compose(
		graphql(gql(queryCampaignsByCompanyIdIndex), {
			options: (props) => ({
				context: {
					headers: {
						'x-frame-options': 'deny', // This header will reach the server
					},
				},
				variables: {
					companyId: props.currentUser.companyId,
				},
				fetchPolicy: 'cache-and-network',
			}),
			props(response, previous) {
				const campaigns = get(
					response,
					['data', 'queryCampaignsByCompanyIdIndex', 'items'],
					get(previous, 'campaigns', [])
				);

				const campaignsNextToken = get(
					response,
					['data', 'queryCampaignsByCompanyIdIndex', 'nextToken'],
					null
				);
				const onFetchMoreCampaigns = makeOnFetchMoreCampaigns(
					response.data.fetchMore,
					campaignsNextToken
				);

				return { campaigns, onFetchMoreCampaigns, campaignsNextToken };
			},
		}),
		graphql(gql(updateJobCampaign), {
			props: (props) => ({
				onUpdateJob(input) {
					return props.mutate({
						variables: { input },
					});
				},
			}),
		}),
		graphql(gql(createCampaign), {
			props: (props) => ({
				onCreateCampaign(input) {
					const optimisticResponseData = {
						id: uuid(),
						...input.input,
						__typename: 'Campaign',
					};
					return props.mutate({
						variables: input,
						optimisticResponse: {
							__typename: 'Mutation',
							createCampaign: {
								__typename: 'createCampaign',
								...optimisticResponseData,
							},
						},
					});
				},
			}),
		}),
		graphql(gql(updateCampaign), {
			props: (props) => ({
				onUpdateCampaign(input) {
					const optimisticResponseData = {
						...input,
						__typename: 'Campaign',
					};
					return props.mutate({
						variables: { input: { ...input } },
						optimisticResponse: {
							__typename: 'Mutation',
							updateCampaign: {
								__typename: 'updateCampaign',
								...optimisticResponseData,
							},
						},
						refetchQueries: [
							{
								query: gql(queryCampaignsByCompanyIdIndex),
								variables: { companyId: props.ownProps.currentUser.companyId },
							},
						],
					});
				},
			}),
		})
	)(Component);
};

const makeOnFetchMoreCampaigns = (fetchMore, nextToken) => {
	if (!nextToken) {
		return null;
	}

	return () => {
		fetchMore({
			variables: { after: nextToken },
			updateQuery(previous, { fetchMoreResult }) {
				if (!fetchMoreResult) {
					return previous;
				}

				return {
					...previous,
					loading: false,
					queryCampaignsByCompanyIdIndex: {
						...previous.queryCampaignsByCompanyIdIndex,
						...fetchMoreResult.queryCampaignsByCompanyIdIndex,
						items: [
							...previous.queryCampaignsByCompanyIdIndex.items,
							...fetchMoreResult.queryCampaignsByCompanyIdIndex.items,
						],
					},
				};
			},
		});
	};
};
