import { ExclamationCircleOutlined, MailOutlined } from '@ant-design/icons';
import {
	Alert,
	Button,
	Popconfirm,
	Select,
	Table,
	Tooltip,
	message,
} from 'antd';
import getSymbolFromCurrency from 'currency-symbol-map';
import gql from 'graphql-tag';
import Cookies from 'js-cookie';
import _, { get } from 'lodash';
import { Component } from 'react';
import { withApollo } from 'react-apollo';
import ReactGA from 'react-ga';
import { Link } from 'react-router-dom';
import { configMode } from 'src/_shared/api/';
import { getUserById } from 'src/_shared/api/graphql/custom/users/';
import { createUser } from 'src/_shared/api/graphql/default/mutations';
import { ColorCard, Spinner, WebIcon } from 'src/_shared/index.js';
import {
	downloadFromS3Signed,
	formatDate,
	lambda,
	logout,
	ml,
	searchInviteData,
	searchOpenSearchNetwork,
} from 'src/_shared/services/utils.js';
import { COLORS } from 'src/_shared/styles/colors';
import fileIcon from '../_shared/assets/erin_lightgray.png';
import { SearchComponent } from '../_shared/components/search';
import { USER_ROLES } from '../_shared/constants';
import { ExportSelector } from '../_shared/components/export-selector/ExportSelector.jsx';
import EmployeeModal from './employees-dashboard-components/EmployeesModalComponent.jsx';
import {
	EmployeesAccountClaimModal,
	ReferralCount,
} from './employees-dashboard-components/index.js';
import { successStyles } from './employeesDashboardStyles.js';

const { Option } = Select;

class EmployeesDashboardComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			activeEmployees: null,
			currencyRate: 1,
			currencySymbol: '$',
			cycle: 1,
			exportTableData: [],
			filteredDepartments: [],
			filteredRoles: [],
			filterEmployeeStatus: 'active',
			filteredUserGroups: [],
			firstLoad: true,
			headers: {
				name: ml('Name', props.currentUser, props.allMultiLingualData),
				emailAddress: ml(
					'Email Address',
					props.currentUser,
					props.allMultiLingualData
				),
				jobTitle: ml('Job Title', props.currentUser, props.allMultiLingualData),
				group: ml('Group', props.currentUser, props.allMultiLingualData),
				role: ml('Role', props.currentUser, props.allMultiLingualData),
				referrals: ml(
					'Referrals',
					props.currentUser,
					props.allMultiLingualData
				),
				lastWebLogin: ml(
					'Last Web Login',
					props.currentUser,
					props.allMultiLingualData
				),
				lastMobileLogin: ml(
					'Last Mobile Login',
					props.currentUser,
					props.allMultiLingualData
				),
			},
			loaded: false,
			managerEmployees: this.props.currentUser.company
				?.disableManagerPermissionsByType
				? JSON.parse(
						this.props.currentUser.company.disableManagerPermissionsByType
					).managerEmployees
				: 'hidden',
			query: '',
			searchedEmployees: [],
			showInvertedIcon: false,
			sortedInfo: null,
			statusFilter: 'active',
			success: false,
			totalEmployeeCount: undefined,
			theme: JSON.parse(get(props, 'currentUser.company.theme', '{}')),
			unacceptedInvites: get(this.props, 'invites', []).length,
			userGroups: get(props, 'currentUser.company.userGroups', []),
		};
	}

	async componentDidMount() {
		const host = window.location.hostname;
		if (host === 'referrals.aus.com') {
			ReactGA.initialize('UA-128630809-2');
			ReactGA.pageview(window.location.pathname + window.location.search);
		} else if (host === 'app.erinapp.com') {
			ReactGA.initialize('UA-128630809-3');
			ReactGA.pageview(window.location.pathname + window.location.search);
		}

		await this.getUserDataById();
		const jwt = Cookies.get('jwt');
		const { resultData } = this.state;
		if (resultData !== '' && resultData !== undefined) {
			const expirationDoneByToken = get(
				resultData,
				'expirationDoneByToken',
				null
			);
			const expires = get(resultData, 'expires');
			if (jwt !== undefined && jwt !== expirationDoneByToken && expires) {
				logout(this.props.client);
			}
		}

		await this.setQueryToState(get(this.state, 'searchQuery', ''), 0);
		this.getCurrencyRate(
			this.props.currentUser && this.props.currentUser.currency
				? this.props.currentUser.currency
				: 'USD'
		);
		const errorImage = get(this.state, 'company.errorImage.key', false);
		const errorImageSource = await downloadFromS3Signed(
			errorImage,
			'erin-images'
		);
		this.setState({ errorImageSrc: errorImageSource });
	}

	componentDidUpdate(prevProps) {
		if (
			prevProps.currentUser.languageCode !==
				this.props.currentUser.languageCode &&
			this.props.allMultiLingualData.length > 0
		) {
			this.props.setMultiLingualData(this.props.allMultiLingualData);
		}

		if (prevProps.invites?.length !== this.props.invites?.length) {
			this.setState({
				unacceptedInvites: this.props.invites.length,
			});
		}
	}

	async getCurrencyRate(currency) {
		const response = await fetch(
			`https://api.exchangeratesapi.io/latest?base=USD&symbols=${currency}`
		);
		const result = await response.json();
		if (result.error) {
			// Make this USD
			this.setState({
				currencyRate: 0,
				currencySymbol: '',
			});
		} else {
			const rate = result.rates[currency].toFixed(5);
			const symbol = getSymbolFromCurrency(currency);
			this.props.setCurrentCurrencyRate(rate);
			this.props.setCurrentCurrencySymbol(symbol);
			this.setState({
				currencyRate: rate,
				currencySymbol: symbol,
			});
		}
	}

	getReport = () => {
		const endpoint = 'export-employee-data';
		const { companyId, cognitoId } = this.props.currentUser;
		message.success(`Export processing. You will receive an email when ready`);
		lambda({
			endpoint,
			variables: {
				companyId,
				cognitoId,
				configMode,
			},
		});
	};

	async getUserDataById(policy = 'network-only') {
		const { client, currentUser } = this.props;
		try {
			const userId = get(currentUser, 'id', null);
			if (userId !== null) {
				const { data } = await client.query({
					query: gql(getUserById),
					variables: {
						id: userId,
					},
					fetchPolicy: policy,
				});
				const result = get(data, 'getUser', null);
				this.setState({
					resultData: result,
				});
			}
		} catch (error) {
			console.log(error);
		}
	}

	setQueryToState = async (searchQuery = '', timeout = 500) => {
		const {
			filteredDepartments = [],
			filteredRoles = [],
			filteredStatus = 'all',
			filteredUserGroups = [],
		} = this.state;
		const company = this.props?.currentUser?.company;
		this.setState({ searchQuery });
		clearTimeout(this.timer);
		this.timer = setTimeout(async () => {
			this.setState({ loading: true });
			const parameters = {
				query: searchQuery,
				size: 300,
				role: get(this.props, 'currentUser.role'),
				currentUser: get(this.props, 'currentUser.id'),
				filters: {
					active: filteredStatus,
					companies: get(company, 'id'),
					departments: filteredDepartments,
					roles: filteredRoles,
					userGroups: filteredUserGroups,
				},
			};

			const matchingInvites =
				filteredStatus === 'all' || filteredStatus === 'invited'
					? searchInviteData(
							['firstName', 'lastName'],
							searchQuery,
							this.props.invites.filter((invite) => !invite.userId)
						)
					: [];

			const response = await searchOpenSearchNetwork(parameters, 'erin-users');
			if (get(response, 'query') === get(this.state, 'searchQuery')) {
				const records = await Promise.all(
					[...get(response, 'data', []), ...matchingInvites].map(
						async (record) => {
							record.avatar &&= await downloadFromS3Signed(record.avatar.key);
							return record;
						}
					)
				);
				this.setState({
					loading: false,
					searchedEmployees: records,
					totalEmployeeCount: response.metadata?.showing?.totalItems,
				});
				this.getExportData(records);
			}
		}, timeout);
	};

	addNewEmployee = async (user) => {
		return new Promise(async (resolve, reject) => {
			const { client } = this.props;
			try {
				await client
					.mutate({
						mutation: gql(createUser),
						variables: {
							input: user,
						},
					})
					.then(async (res) => {
						const user = get(res, 'data.createUser');
						await this.setQueryToState(get(this.state, 'searchQuery', ''), 0);
						resolve(user);
					});
			} catch (error) {
				reject(error);
			}
		});
	};

	capFirst(value_) {
		const value = value_.split('');
		const V = _.get(value, '[0]', '').toUpperCase();
		const alue = value.slice(1, value.length).join('');
		const Value = V + alue;
		return Value;
	}

	clearStatusFilter = () => {
		this.setState({
			statusFilter: 'accepted',
			filterEmployeeStatus: 'accepted',
		});
	};

	getExportData = (data) => {
		const { currentUser } = this.props;
		const { headers } = this.state;
		const parseDate = (date) => {
			if (date) {
				const languageCode = currentUser.languageCode ?? 'US';
				return formatDate(date, languageCode, currentUser.dateFormat);
			}

			return 'Never';
		};

		const exportData = data.map((record) => ({
			[headers.name]: `${record.firstName ?? ''} ${record.lastName ?? ''}`,
			[headers.emailAddress]: `${record.emailAddress ?? ''}`,
			[headers.jobTitle]: `${record.title ?? ''}`,
			[headers.group]: `${record.userGroup?.name ?? ''}`,
			[headers.role]: `${record.role ?? ''}`,
			// ? user.totalReferrals gives different count than <ReferralCountComponent/>
			[headers.referrals]: `${record.totalReferrals ?? 0}`,
			[headers.lastWebLogin]: `${parseDate(record.lastLogin)}`,
			[headers.lastMobileLogin]: `${parseDate(record.lastMobileLogin)}`,
		}));

		this.setState({
			exportTableData: exportData,
		});
	};

	handleCancel = () => {
		this.props.inviteEmployeesCancelled();
	};

	handleEmployeeStatusFilter = (value) => {
		this.setState({ statusFilter: value });
	};

	handleError = (e) => {
		this.props.inviteEmployeesFailed(e);
	};

	handleFilterDepartment = (department, key) => {
		let { filteredDepartments = [], searchQuery = '' } = this.state;
		if (filteredDepartments.map((dep) => dep.name).includes(department)) return;
		filteredDepartments = [...filteredDepartments, department];
		this.setState(
			{
				filteredDepartments,
			},
			async () => await this.setQueryToState(searchQuery)
		);
	};

	handleFilterRole = (role, key) => {
		let { filteredRoles = [], searchQuery } = this.state;
		if (filteredRoles.includes(role)) return;
		filteredRoles = [...filteredRoles, role];
		this.setState(
			{
				filteredRoles,
			},
			async () => await this.setQueryToState(searchQuery)
		);
	};

	handleFilterStatus = (key) => {
		const { searchQuery } = this.state;

		this.setState(
			{
				loading: true,
				filteredStatus: key,
			},
			async () => await this.setQueryToState(searchQuery)
		);
	};

	handleFilterUserGroup = (userGroup, key) => {
		let { filteredUserGroups = [], searchQuery = '' } = this.state;
		if (filteredUserGroups.map((group) => group.name).includes(userGroup))
			return;
		filteredUserGroups = [...filteredUserGroups, userGroup];
		this.setState(
			{
				filteredUserGroups,
			},
			async () => await this.setQueryToState(searchQuery)
		);
	};

	handleImportCancel = () => {
		this.props.accountClaimImportCancelled();
	};

	handleImportError = (e) => {
		this.props.accountClaimImportFailed(e);
	};

	handleImportSubmit = async (fileData, disableValue) => {
		await this.props.accountClaimImportSubmit(fileData);
	};

	handleRemoveDepartmentFilter = (department) => {
		const { filteredDepartments = [], searchQuery } = this.state;
		const index = filteredDepartments
			.map((dep) => dep.name)
			.indexOf(department);
		filteredDepartments.splice(index, 1);
		this.setState(
			{
				filteredDepartments,
			},
			async () => await this.setQueryToState(searchQuery)
		);
	};

	handleRemoveRoleFilter = (role) => {
		const { filteredRoles = [], searchQuery } = this.state;
		const index = filteredRoles.indexOf(role);
		filteredRoles.splice(index, 1);
		this.setState(
			{
				filteredRoles,
			},
			async () => await this.setQueryToState(searchQuery)
		);
	};

	handleRemoveUserGroupFilter = (userGroup) => {
		const { filteredUserGroups = [], searchQuery = '' } = this.state;
		const index = filteredUserGroups.map((dep) => dep.name).indexOf(userGroup);
		filteredUserGroups.splice(index, 1);
		this.setState(
			{
				filteredUserGroups,
			},
			async () => await this.setQueryToState(searchQuery)
		);
	};

	handleResendEmployeeInvites = () => {
		const data = this.props.users
			.filter((user) => user.role !== 'superAdmin')
			.concat(
				get(this.props, 'invites', []).filter((invite) => !invite.userId)
			);

		const inactiveEmployeeInvites = data.filter(
			(data) => !data.active && data.emailAddress
		);

		for (const employee of inactiveEmployeeInvites)
			this.resendInviteSubmit(employee.id);
	};

	handleSubmit = async (users, fileData, role) => {
		await this.props.inviteEmployeesSubmit(
			users,
			fileData,
			role,
			this.props.currentUser.id,
			this.props.currentUser.companyId,
			this.props.currentUser.languageCode
		);

		this.props.refetchInvites(this.props.filter, this.props.limit);
	};

	handleTableChange = (pagination, filters, sorter, extra) => {
		this.setState({
			sortedInfo: sorter
		});

		if (extra.action === 'sort') {
			this.getExportData(extra.currentDataSource);
		}
	};

	inviteEmailExists = (email) => {
		const lowercaseEmail = email.toLowerCase();
		const inviteEmails = this.props.invites.map((invite) =>
			invite.emailAddress.toLowerCase()
		);
		if (inviteEmails.includes(lowercaseEmail)) {
			return true;
		}

		return false;
	};

	resendInvite = (userInviteId) => {
		return new Promise((resolve, reject) => {
			try {
				const endpoint = 'base-white-email-resend-new-user-prod-app';
				lambda({ endpoint, variables: { userInviteId } });
				resolve();
			} catch (error) {
				console.error(error);
				reject(error);
			}
		});
	};

	resendInviteSubmit = (userInvitedId) => {
		this.resendInvite(userInvitedId)
			.then(() => {
				this.setState({ success: true });
				setTimeout(() => {
					this.setState({ success: false });
				}, 2000);
			})
			.catch((error) => console.error(error));
	};

	showImportModal = () => {
		this.props.accountClaimImportStarted();
	};

	showInvertedButton = (value) => {
		this.setState({
			showInvertedIcon: value,
		});
	};

	showModal = () => {
		this.props.inviteEmployeesStarted();
	};

	sort = (a = '', b = '') => {
		if (!a) return -1;
		if (!b) return 1;
		if (typeof a === 'string') {
			a = a.toLowerCase();
		}

		if (typeof b === 'string') {
			b = b.toLowerCase();
		}

		if (a > b) return 1;
		if (a < b) return -1;
		return 0;
	};

	toggleButtonStyles = (filterEmployeeStatus) => {
		this.setState({
			filterEmployeeStatus,
		});
	};

	uniqueEmployees = (employees) => {
		const idArray = [];
		const uniqueEmployees = [];
		for (const employee of employees) {
			if (!idArray.includes(employee.cognitoId)) {
				uniqueEmployees.push(employee);
				idArray.push(employee.cognitoId);
			}
		}

		return uniqueEmployees;
	};

	renderArrowIcon = (count) => {
		return (
			<div>
				<WebIcon color="var(--oslo-gray)" size={10} name="sort-down" />
			</div>
		);
	};

	render() {
		const {
			filteredDepartments = [],
			filteredRoles = [],
			filteredStatus = '',
			filteredUserGroups = [],
			headers,
			loaded,
			loading,
			managerEmployees,
			searchedEmployees,
			searchQuery = '',
			sortedInfo,
			success,
			theme,
			totalEmployeeCount,
			unacceptedInvites = 0,
		} = this.state;
		const {
			currentClient,
			currentUser,
			employeesData,
			error,
			departments,
			userGroups,
			allMultiLingualData,
		} = this.props;
		const filterOn =
			filteredStatus?.length > 0 ||
			filteredDepartments?.length > 0 ||
			filteredRoles?.length > 0 ||
			filteredUserGroups?.length > 0 ||
			searchQuery?.length > 0;
		const company = currentUser?.company;
		const isManagerPermissionDisabled = Boolean(
			managerEmployees !== 'edit' && currentUser?.role === USER_ROLES.MANAGER
		);
		const whiteLabel = get(company, 'whiteLabel');
		if (!get(currentUser, 'company') || !this.state.searchedEmployees) {
			return <Spinner />;
		}

		if (this.state.loaded !== true) {
			setTimeout(() => {
				this.setState({ loaded: true });
			}, 2000);
		}

		const sortedDepartments = _.sortBy(departments, ['name']);
		const activeDepartments = (sortedDepartments || []).filter(
			(department) => department.active
		);
		const departmentOptions = activeDepartments.map((department) => {
			return (
				<Option key={department.id} value={department.id}>
					{department.name}
				</Option>
			);
		});
		const roleOptions_ = ['admin', 'employee', 'manager'];
		const roleOptions = roleOptions_.map((role) => {
			return (
				<Option key={role} value={role}>
					{this.capFirst(role)}
				</Option>
			);
		});

		const sortedUserGroups = _.sortBy(userGroups, ['name']);
		const userGroupOptions = sortedUserGroups.map((userGroup) => {
			return (
				<Option key={userGroup.id} value={userGroup.id}>
					{userGroup.name}
				</Option>
			);
		});

		const activeEmployees = get(employeesData, 'total', 0);

		const sort = (a = '', b = '') => {
			if (!a) return -1;
			if (!b) return 1;
			if (typeof a === 'string') {
				a = a.toLowerCase();
			}

			if (typeof b === 'string') {
				b = b.toLowerCase();
			}

			if (a > b) return 1;
			if (a < b) return -1;
			return 0;
		};

		const languageCode = get(currentUser, 'languageCode', 'US');
		const columns = [
			{
				title: '',
				key: (record) => `avatar${get(record, 'id')}`,
				width: 70,
				visible: true,
				render(record) {
					return (
						<div className="custom-image-wrap">
							{record.avatar ? (
								<img
									src={record.avatar}
									className="custom-img"
									alt={`${record.firstName} ${record.lastName}`}
								/>
							) : (
								<>
									{record.firstName && record.lastName ? (
										<>
											{record.firstName[0]}
											{record.lastName[0]}
										</>
									) : null}
								</>
							)}
						</div>
					);
				},
			},
			{
				title: headers.name,
				visible: true,
				key: 'name',
				render: (record) =>
					record.firstName && record.lastName && record.cognitoId ? (
						<Link
							to={{
								pathname: `/employees/${record.id}`,
								state: { currentUser: record.currentUser },
							}}
							className="table-link"
						>
							{record.firstName} {record.lastName}
						</Link>
					) : record.firstName && record.lastName && !record.cognitoId ? (
						<>
							{record.firstName} {record.lastName}
						</>
					) : null,
				showSorterTooltip: false,
				sorter: (a, b) => {
					const nameA = `${a.firstName || ''} ${a.lastName || ''}`.toLowerCase();
					const nameB = `${b.firstName || ''} ${b.lastName || ''}`.toLowerCase();
					return nameA.localeCompare(nameB);
				},
				sortOrder: sortedInfo?.columnKey === 'name' && sortedInfo.order,
				sortDirections: ['ascend', 'descend', 'ascend'],
			},
			{
				title: headers.emailAddress,
				visible: false,
				key: 'email',
				width: '20%',
				render: (record) =>
					record.emailAddress && record.cognitoId ? (
						<Link
							to={{
								pathname: `/employees/${record.id}`,
								state: { currentUser: record.currentUser },
							}}
							className="table-link"
						>
							{record.emailAddress}
						</Link>
					) : (
						<>{record.emailAddress}</>
					),
				showSorterTooltip: false,
				sorter: (a, b) => {
					const emailA = (a.emailAddress || '').toLowerCase();
					const emailB = (b.emailAddress || '').toLowerCase();
					return emailA.localeCompare(emailB);
				},
				sortOrder: sortedInfo?.columnKey === 'email' && sortedInfo.order,
				sortDirections: ['ascend', 'descend', 'ascend'],
			},
			{
				title: headers.jobTitle,
				visible: true,
				key: 'title',
				render: (record) =>
					get(record, 'title') ? <>{get(record, 'title')}</> : null,
				showSorterTooltip: false,
				sorter: (a, b) => {
					const titleA = (get(a, 'title') || '').toLowerCase();
					const titleB = (get(b, 'title') || '').toLowerCase();
					return titleA.localeCompare(titleB);
				},
				sortOrder: sortedInfo?.columnKey === 'title' && sortedInfo.order,
				sortDirections: ['ascend', 'descend', 'ascend'],
			},
			{
				title: headers.group,
				visible: true,
				key: 'group',
				render: (record) => <>{get(record, 'userGroup.name')}</>,
				showSorterTooltip: false,
				sorter: (a, b) => {
					const groupA = (get(a, 'userGroup.name') || '').toLowerCase();
					const groupB = (get(b, 'userGroup.name') || '').toLowerCase();
					return groupA.localeCompare(groupB);
				},
				sortOrder: sortedInfo?.columnKey === 'group' && sortedInfo.order,
				sortDirections: ['ascend', 'descend', 'ascend'],
			},
			{
				title: headers.role,
				visible: true,
				key: 'role',
				render: (record) => {
					return <>{this.capFirst(get(record, 'role', ''))}</>;
				},
				showSorterTooltip: false,
				sorter: (a, b) => {
					const roleA = (get(a, 'role') || '').toLowerCase();
					const roleB = (get(b, 'role') || '').toLowerCase();
					return roleA.localeCompare(roleB);
				},
				sortOrder: sortedInfo?.columnKey === 'role' && sortedInfo.order,
				sortDirections: ['ascend', 'descend', 'ascend'],
			},
			{
				title: headers.referrals,
				visible: true,
				key: 'referrals',
				showSorterTooltip: false,
				render({ id }) {
					return <ReferralCount userId={id} />;
				},
			},
			{
				title: headers.lastWebLogin,
				visible: false,
				key: 'lastLogin',
				render: (record) =>
					!record.lastLogin && !record.cognitoId ? (
						<>
							<p>{ml('Never', currentUser, allMultiLingualData)}</p>
							<Button
								type="link"
								onClick={() => this.resendInviteSubmit(record.id)}
							>
								{ml('Resend Invite', currentUser, allMultiLingualData)}
							</Button>
						</>
					) : !record.lastLogin && record.cognitoId ? (
						<p>{ml('Never', currentUser, allMultiLingualData)}</p>
					) : (
						<>
							{formatDate(
								get(record, 'lastLogin'),
								languageCode,
								this.props.currentUser.dateFormat
							)}
						</>
					),
				showSorterTooltip: false,
				sorter: (a, b) => {
					const dateA = a.lastLogin ? new Date(a.lastLogin) : new Date(0);
					const dateB = b.lastLogin ? new Date(b.lastLogin) : new Date(0);
					return dateA - dateB;
				},
				sortOrder: sortedInfo?.columnKey === 'lastLogin' && sortedInfo.order,
				sortDirections: ['ascend', 'descend', 'ascend'],
			},
			{
				title: headers.lastMobileLogin,
				visible: false,
				key: 'lastMobileLogin',
				render: (record) =>
					!record.lastMobileLogin && !record.cognitoId ? (
						<p>{ml('Never', currentUser, allMultiLingualData)}</p>
					) : !record.lastMobileLogin && record.cognitoId ? (
						<div>
							<p>{ml('Never', currentUser, allMultiLingualData)}</p>
						</div>
					) : (
						<>
							{formatDate(
								get(record, 'lastMobileLogin'),
								languageCode,
								this.props.currentUser.dateFormat
							)}
						</>
					),
				showSorterTooltip: false,
				sorter: (a, b) => {
					const dateA = a.lastMobileLogin ? new Date(a.lastMobileLogin) : new Date(0);
					const dateB = b.lastMobileLogin ? new Date(b.lastMobileLogin) : new Date(0);
					return dateA - dateB;
				},
				sortOrder: sortedInfo?.columnKey === 'lastMobileLogin' && sortedInfo.order,
				sortDirections: ['ascend', 'descend', 'ascend'],
			},
		];

		const locale = {
			emptyText: <Spinner company={company} />,
		};

		return (
			<main>
				<div className="page-title">
					<h2 className="page-heading">
						{ml('Employees', currentUser, allMultiLingualData)}
					</h2>
					{!isManagerPermissionDisabled && (
						<ul className="info-action">
							<li>
								<Button
									type="link"
									className="add-btn btn-lg"
									onClick={this.showModal}
								>
									<span className="icon-circle">
										<i className="icon-plus" />
									</span>
									<span>
										{ml('Add Employee', currentUser, allMultiLingualData)}
									</span>
								</Button>
							</li>
						</ul>
					)}
				</div>

				<div className="color-card-grid">
					<ColorCard
						isOneSided
						bgColor="var(--fern)"
						count={Number.parseInt(activeEmployees).toLocaleString('en-US')}
						isCardDataSet={Boolean(employeesData)}
						title={ml(
							'Active Employees',
							currentUser,
							this.props.allMultiLingualData
						)}
					/>
					<ColorCard
						isOneSided
						bgColor="var(--silver-chalice)"
						count={Number.parseInt(unacceptedInvites).toLocaleString('en-US')}
						isCardDataSet={Boolean(employeesData)}
						title={ml(
							'Unaccepted Invites',
							currentUser,
							this.props.allMultiLingualData
						)}
					/>
				</div>

				<div className="referrals-actions">
					<SearchComponent
						loading={loading}
						searchQuery={searchQuery}
						setQueryToState={this.setQueryToState}
						placeholder={ml('Search', currentUser, allMultiLingualData)}
					/>
					{totalEmployeeCount && (
						<div className="referrals-btn-grp">
							<Popconfirm
								title="Resend invites to all Unaccepted Employees？"
								placement="left"
								icon={<MailOutlined style={{ color: COLORS.blue }} />}
								onConfirm={this.handleResendEmployeeInvites}
							>
								<Button type="link" className="btn-link">
									<i
										className="icon-envelope-outline"
										style={{ fontSize: 18 }}
									/>
									{ml(
										'Resend Employee Invites',
										currentUser,
										allMultiLingualData
									)}
								</Button>
							</Popconfirm>
							<ExportSelector
								title={ml('Export', currentUser, allMultiLingualData)}
								items={[
									{
										key: 1,
										label: (
											<Tooltip
												title={ml(
													`You will receive an export via email of all data.`,
													currentUser,
													allMultiLingualData
												)}
												placement="left"
											>
												{ml('Export All', currentUser, allMultiLingualData)}
											</Tooltip>
										),
										onClick: this.getReport,
									},
									{
										key: 2,
										label: (
											<Tooltip
												title={
													totalEmployeeCount > 300
														? ml(
																'Unavailable: More than 300 results. Please filter to enable.',
																currentUser,
																allMultiLingualData
															)
														: ml(
																`Instantly download an export of the filtered results.`,
																currentUser,
																allMultiLingualData
															)
												}
												placement="left"
											>
												{ml('Export View', currentUser, allMultiLingualData)}
											</Tooltip>
										),
										onClick: 'csv-export',
										data: this.state.exportTableData,
										disabled: totalEmployeeCount > 300,
										reportType: 'employee',
										companyName: company?.name,
									},
								]}
							/>
						</div>
					)}
				</div>

				<div className="custom-filter">
					<div className="filter-wrap">
						<Select
							showArrow
							placeholder="Status"
							suffixIcon={this.renderArrowIcon(filteredStatus)}
							dropdownMatchSelectWidth={false}
							className="ateet"
							onSelect={(key) => this.handleFilterStatus(key)}
						>
							<Select.Option key="all">All</Select.Option>
							<Select.Option key="true">Active</Select.Option>
							<Select.Option key="false">Inactive</Select.Option>
							<Select.Option key="invited">Invited</Select.Option>
						</Select>

						<Select
							showArrow
							mode="multiple"
							placeholder={ml('Department', currentUser, allMultiLingualData)}
							maxTagCount={1}
							maxTagTextLength={15}
							suffixIcon={this.renderArrowIcon(filteredDepartments.length)}
							dropdownMatchSelectWidth={false}
							filterOption={(input, option) =>
								option.props.value
									.toLowerCase()
									.includes(input.toLowerCase()) ||
								option.props.children
									.toLowerCase()
									.includes(input.toLowerCase())
							}
							onSelect={(department, { key }) =>
								this.handleFilterDepartment(department, key)
							}
							onDeselect={(department, { key }) =>
								this.handleRemoveDepartmentFilter(department, key)
							}
						>
							{departmentOptions}
						</Select>

						<Select
							showArrow
							mode="multiple"
							placeholder="Role"
							maxTagCount={1}
							maxTagTextLength={15}
							suffixIcon={this.renderArrowIcon(filteredRoles.length)}
							dropdownMatchSelectWidth={false}
							onSelect={(role, { key }) => this.handleFilterRole(role, key)}
							onDeselect={(role, { key }) =>
								this.handleRemoveRoleFilter(role, key)
							}
						>
							{roleOptions}
						</Select>

						<Select
							showArrow
							mode="multiple"
							placeholder="User Group"
							maxTagCount={1}
							maxTagTextLength={15}
							suffixIcon={this.renderArrowIcon(filteredUserGroups.length)}
							dropdownMatchSelectWidth={false}
							onSelect={(userGroup, { key }) =>
								this.handleFilterUserGroup(userGroup, key)
							}
							onDeselect={(userGroup, { key }) =>
								this.handleRemoveUserGroupFilter(userGroup, key)
							}
						>
							{userGroupOptions}
						</Select>
						{filterOn && totalEmployeeCount > 300 && (
							<Tooltip
								title={ml(
									`Your filter includes over 300 results. Only the first 300 are displayed.`,
									currentUser,
									allMultiLingualData
								)}
								placement="right"
							>
								<ExclamationCircleOutlined />
							</Tooltip>
						)}
					</div>
				</div>

				{searchedEmployees && searchedEmployees.length > 0 ? (
					<div className="table-card">
						<Table
							pagination={{ pageSize: 50, showSizeChanger: false }}
							rowKey={(record) => record.id}
							dataSource={searchedEmployees}
							columns={columns}
							locale={locale}
							scroll={{ x: '100%' }}
							onChange={this.handleTableChange}
						/>
					</div>
				) : loaded && !loading ? (
					<div className="no-content">
						{whiteLabel ? (
							<img
								className="no-content-icon"
								src={this.state.errorImageSrc}
								alt="error image"
							/>
						) : (
							<img className="no-content-icon" alt="erin-logo" src={fileIcon} />
						)}
						<p className="no-content-text">
							{ml(
								'There are not any employees available.',
								currentUser,
								allMultiLingualData
							)}
						</p>
					</div>
				) : (
					<Spinner company={company} />
				)}

				<EmployeeModal
					theme={theme}
					currentUser={currentUser}
					allMultiLingualData={allMultiLingualData}
					visible={this.props.modalState === 'open'}
					error={error}
					handleSubmit={this.handleSubmit}
					handleCancel={this.handleCancel}
					handleError={this.handleError}
					showInvertedButton={this.showInvertedButton}
					showInvertedIcon={this.showInvertedIcon}
					currentClient={currentClient}
					departments={departments}
					userGroups={userGroups}
					inviteEmailExists={this.inviteEmailExists}
					showImportModal={this.showImportModal}
				/>
				<EmployeesAccountClaimModal
					theme={theme}
					currentUser={currentUser}
					allMultiLingualData={allMultiLingualData}
					showModal={this.showModal}
					visible={this.props.importModalState === 'open'}
					error={error}
					handleImportSubmit={this.handleImportSubmit}
					handleImportCancel={this.handleImportCancel}
					handleImportError={this.handleImportError}
					showInvertedButton={this.showInvertedButton}
					showInvertedIcon={this.showInvertedIcon}
					currentClient={currentClient}
					departments={departments}
					userGroups={userGroups}
					inviteEmailExists={this.inviteEmailExists}
				/>
				{success && (
					<Alert
						className={successStyles}
						type="success"
						message="The invite was successfully sent"
					/>
				)}
			</main>
		);
	}
}

export default withApollo(EmployeesDashboardComponent);
