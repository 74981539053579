import { css } from 'emotion';
import get from 'lodash/get';
import {
	downloadFromS3Signed,
	getEnvironment,
} from 'src/_shared/services/utils.js';
import LoginBackground from '../_shared/assets/Erin_login_background.jpg';
import { COLORS } from '../_shared/styles/colors';

export const MainContainer = async (company) => {
	const backgroundUrl = get(company, 'background.key');
	const bucket = get(company, 'background.bucket');
	const whiteLabel = get(company, 'whiteLabel');
	let image = null;
	if (getEnvironment() !== 'prod' && whiteLabel && backgroundUrl) {
		image = `url(${await downloadFromS3Signed(backgroundUrl, bucket)})`;
	} else if (getEnvironment() === 'prod' || getEnvironment() === 'dev') {
		image = `url(${LoginBackground})`;
	}

	let brandColor = COLORS.darkBlue;

	if (getEnvironment() !== 'prod' && whiteLabel) {
		brandColor = get(company, 'brandColor', brandColor);
	}

	return css({
		backgroundImage: image,
		backgroundColor: brandColor,
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		height: '100vh',
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		overflow: 'hidden',
		'& a: visited': {
			color: COLORS.white,
		},
	});
};

export const Logo = css({
	width: 280,
	marginTop: '15%',
	marginBottom: '20%',
});

export const largeText = css({
	color: COLORS.white,
	fontSize: 24,
	width: '80%',
	textAlign: 'center',
});

export const appStoreBtn = (theme) => {
	let buttonColor = COLORS.blue;
	if (get(theme, 'enabled')) {
		buttonColor = get(theme, 'buttonColor', buttonColor);
	}

	return css({
		width: 280,
		height: 40,
		background: buttonColor,
		color: COLORS.white,
		fontSize: 18,
		borderRadius: 5,
		border: 'none',
		marginTop: 20,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		textDecoration: 'none',
	});
};

export const footer = css({
	position: 'absolute',
	bottom: 20,
	display: 'flex',
	justifyContent: 'center',
	flexDirection: 'column',
});

export const footerText = css({
	color: COLORS.white,
	textAlign: 'center',
	margin: 0,
});

export const ArrowIcon = css({
	marginLeft: 20,
	marginTop: -1,
});
