import gql from 'graphql-tag';

export const GetCompanySettingsDisableSiteOnly = gql`
	query GetCompany($id: ID!) {
		getCompany(id: $id) {
			id
			disableSite
		}
	}
`;
