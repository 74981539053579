import { Button, Table, Tooltip } from "antd"
import { useHistory } from "react-router-dom";
import formatDateInTable from "../../../rewardsAndRecognitionHelper/CommonDate";
import './style.scss'
import NoDataState from "../../../RnRCommonComponent/noDataState/NoDataState.jsx";
import { addCommas } from "../../../rewardsAndRecognitionHelper/utils.js";

const RewardsTablePresentation = ({ rewards, setShowModal, nominatedBadgeListLoader }) => {
    const history = useHistory()

    const columns = [
        {
            title: 'BADGE',
            dataIndex: 'reward',
            key: 'badge',
            render: (_, rawContent) =>
                <Button
                    type="link"
                    disabled={rawContent?.status === "Submitted"}
                    onClick={() => {
                        setShowModal({
                            show: true,
                            awardId: rawContent?.id,
                            nomineeCount: rawContent?.nominated
                        })
                    }} >{rawContent?.badge?.name}</Button>
        }, {
            title: 'START DATE',
            dataIndex: 'startDate',
            key: 'startDate',
            sorter: (a, b) => new Date(a.startDate) - new Date(b.startDate),
            render: (startDate) => {
                return <span>{formatDateInTable(startDate)}</span>
            },
        }, {
            title: 'END DATE',
            dataIndex: 'endDate',
            key: 'endDate',
            sorter: (a, b) => new Date(a.endDate) - new Date(b.endDate),
            render: (endDate) => {
                return <span>{formatDateInTable(endDate)}</span>
            },
        }, {
            title: '# NOMINATED',
            dataIndex: 'nominated',
            key: 'nominated',
            sorter: (a, b) => a.nominated - b.nominated,
            render: (text, rawContent) => <Button type="link" onClick={() => {
                history.push(`/select-winner/${rawContent.id}`)
            }} >{addCommas(text)}</Button>,
        },
        {
            title: 'STATUS',
            dataIndex: 'status',
            key: 'status',
            render: (_, rawContent) => {
                return <span className={`status-${rawContent?.status?.toLowerCase()}`}>{rawContent?.status}</span>
            },
        },
        {
            title: 'WINNER',
            dataIndex: 'winnerDetails',
            key: 'winnerDetails',
            render: (winner) => {
                const winnerList = winner.map(({ firstName, lastName }) => `${firstName} ${lastName}`).sort()
                return winnerList?.length ?
                    <Tooltip
                        placement="top"
                        trigger="hover"
                        style={{
                            position: 'absolute',
                            fontWeight: 1000,
                            margin: 0,
                            padding: 0,
                            bottom: 0,
                        }}
                        title={
                            <>
                                {winnerList.map((name) => {
                                    return (
                                        <li>{name}</li>

                                    )
                                })}
                            </>
                        }
                    >{winnerList[0]}{`${winnerList?.length > 1 ? ",..." : ""}`}</Tooltip>
                    :
                    "None"
            },
        },

    ]

    return (
        <div className="nomination-rewards-table table-card">
            <Table
                rowKey={(record) => record.id}
                dataSource={rewards}
                loading={nominatedBadgeListLoader}
                columns={columns}
                pagination={{ pageSize: 10, showSizeChanger: false }}
                locale={{ emptyText: <NoDataState title={"No nomination badges found."} /> }}
            />
        </div>
    )
}

export default RewardsTablePresentation