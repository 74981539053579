import { css } from 'emotion';
import { COLORS } from '../../styles/colors';

export const SubmitBtn = css({
	fontFamily: 'Open Sans',
	backgroundColor: COLORS.red,
	color: 'white',
	fontSize: 20,
	fontWeight: 300,
	padding: '10px 30px',
	height: 'auto',
	border: 'none',
	marginBottom: 0,
});

export const FormItemStyles = css({
	marginBottom: 0,
	margin: 'auto',
	textAlign: 'center',
});

export const CheckBoxStyles = css({
	margin: '0 auto',
	' & span': {
		fontSize: '16px !important',
	},
	'& .ant-checkbox-checked .ant-checkbox-inner': {
		borderColor: COLORS.green,
		backgroundColor: COLORS.green,
	},
});

export const SubmitBtnContainer = css({
	width: '100%',
	display: 'flex',
	justifyContent: 'center',
	padding: 20,
	marginBottom: 0,
	'& .ant-form-item-control-wrapper': {
		'@media (max-width: 575px)': {
			width: 'auto',
		},
	},
	'& .ant-btn: hover': {
		color: COLORS.blue,
		fontWeight: 300,
		border: `2px solid ${COLORS.blue}`,
	},
});

export const CheckIcon = css({
	borderRadius: 100,
	border: '1px solid white',
	padding: 2,
});

export const NotHired = css({
	fontWeight: 600,
});

export const JobInfoContainer = css({
	padding: 5,
	paddingTop: 10,
	paddingBottom: 2,

	textAlign: 'center',
	backgroundColor: COLORS.lightGreen,
	borderRadius: 8,
	marginBottom: 15,
});

export const DivDisable = css({
	pointerEvents: 'none',
	opacity: 0.7,
});
