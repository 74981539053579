import gql from 'graphql-tag';

export const GetJob = gql`
	query GetJob($id: ID!) {
		getJob(id: $id) {
			id
			dateCreated
			jobType
			departmentId
			department {
				id
				name
			}
			companyId
			company {
				id
				name
				contactIncentiveBonus
				referralBonusWaitingPeriod
			}
			title
			description
			publicLink
			internalJobLink
			hideImInterested
			isGeneralReferral
			jobMatches {
				id
				contactId
				userId
				jobId
				matchStatus
				relevance
				dateCreated
			}
			salary
			location
			locations
			createdById
			createdBy {
				id
				firstName
				lastName
			}
			hiringManagerId
			hiringManager {
				id
				emailAddress
				firstName
				lastName
			}
			jobLevelIds
			jobLevels {
				id
				active
				name
				companyId
			}
			referralBonus
			tieredBonus {
				id
				name
				tiers
			}
			notificationType
			status
			shares
			views
			lat
			lng
			externalJobId
			subCompanyId
			subCompany {
				id
				name
			}
			campaignId
			campaign {
				id
				name
				startDate
				endDate
				archived
				tieredBonusId
			}
			externalSource
			isHotJob
			displayReqId
			referrals {
				id
				bonuses {
					id
					amountDue
					bonusStatus
					companyId
					contactId
					earnedDate
					hireDate
					jobId
					payment
					recipientType
					referralId
					startDate
					userId
					notes
				}
				companyId
				company {
					id
					contactIncentiveBonus
					referralBonusWaitingPeriod
				}
				contact {
					id
					firstName
					lastName
					emailAddress
					socialMediaAccounts
					phoneNumber
					contactResume {
						bucket
						region
						key
					}
				}
				contactId
				userId
				user {
					id
					firstName
					lastName
					incentiveEligible
					role
					userGroupId
					emailAddress
					title
					department {
						id
						name
					}
					avatar {
						key
						region
						bucket
					}
				}
				jobId
				job {
					id
					referralBonus
					jobMatches {
						id
						contactId
						userId
						jobId
						matchStatus
						relevance
						dateCreated
					}
					jobType
					title
					referralBonus
					tieredBonus {
						id
						name
						tiers
					}
				}
				status
				bonusStatus
				referralDate
				referralType
				hireDate
				ownershipEndDate
				message
				customStatus
				contactResume {
					bucket
					region
					key
				}
			}
		}
	}
`;
