import gql from 'graphql-tag';
import _, { get } from 'lodash';
import { compose, graphql } from 'react-apollo';
import { parse } from 'src/_shared/services/utils.js';
import {
	GetCompanySettingsData,
	updateCompany,
} from '../../graphql/custom/company';

export const withGetCompanyData = (Component, variables) => {
	return compose(
		graphql(GetCompanySettingsData, {
			options: {
				variables,
				fetchPolicy: 'cache-and-network',
			},
			props(props) {
				const companyData = get(props, 'data.getCompanyData', undefined);
				if (companyData) {
					let questions = get(companyData, 'referralQuestions', []);
					questions = _.sortBy(questions, ['sortOrder']);
					questions = questions.map((question) => {
						question.questions = parse(question.questions);
						return question;
					});
					if (questions) companyData.referralQuestions = questions;
				}

				return {
					companyData,
				};
			},
		}),
		graphql(gql(updateCompany), {
			props: (props) => ({
				onUpdateCompany(input) {
					const optimisticResponseData = {
						...props.ownProps.company,
						...input.input,
					};
					props.mutate({
						variables: input,
						optimisticResponse: {
							__typeName: 'Mutation',
							updateCompany: {
								...optimisticResponseData,
								__typeName: 'updateCompany',
								logo: { ...input.input.logo, __typename: 'S3Object' },
							},
						},
						update(proxy) {
							const data = proxy.readQuery({
								query: GetCompanySettingsData,
								variables: { companyId: props.ownProps.currentUser.companyId },
							});
							proxy.writeQuery({
								query: GetCompanySettingsData,
								data,
								variables: { companyId: props.ownProps.currentUser.companyId },
							});
						},
					});
				},
			}),
		})
	)(Component);
};

const makeOnFetchMore = (fetchMore, nextToken) => {
	if (!nextToken) {
		return null;
	}

	return () => {
		fetchMore({
			variables: { nextToken },
			updateQuery(previous, { fetchMoreResult }) {
				if (!fetchMoreResult) {
					return { ...previous };
				}

				const previousItems = previous.getCompanyData.items
					? previous.getCompanyData.items
					: [];
				const moreItems = fetchMoreResult.getCompanyData.items
					? fetchMoreResult.getCompanyData.items
					: [];
				return {
					...previous,
					getCompanyData: {
						...previous.getCompanyData,
						...fetchMoreResult.getCompanyData,
						items: [...previousItems, ...moreItems],
					},
				};
			},
		});
	};
};
