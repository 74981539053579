import get from 'lodash/get';
import { compose, graphql } from 'react-apollo';
import { GetUserData } from '../../graphql/custom/users';

export const withUserById = (Component, variables) => {
	return compose(
		graphql(GetUserData, {
			options: {
				context: {
					headers: {
						'x-frame-options': 'deny', // This header will reach the server
					},
				},
				variables,
				fetchPolicy: 'cache-and-network',
			},
			props(props) {
				const user = get(props, 'data.getUserData', undefined);
				return {
					referredUser: user,
				};
			},
		})
	)(Component);
};
