import get from 'lodash/get';
import WebIcon from 'src/_shared/components/WebIconComponent.jsx';
import { REFERRAL_STATUS } from 'src/_shared/constants/';
import { COLORS } from 'src/_shared/styles/colors';
import {
	progressColumn,
	progressIndicatorContainer,
	progressIndicatorDotCenter,
	progressIndicatorDots,
	progressIndicatorLine,
	progressRow,
	subTitle,
} from './progressIndicatorStyles.js';

const referralSubtitles = Object.keys(REFERRAL_STATUS).map((key) => {
	return REFERRAL_STATUS[key];
});
const referralStatuses = Object.keys(REFERRAL_STATUS).map((key) => key);

const progressBarOptions = [
	{
		title: referralSubtitles[0],
		value: referralStatuses[0],
		color: COLORS.yellow,
		previousStates: [0],
	},
	{
		title: referralSubtitles[1],
		value: referralStatuses[1],
		color: COLORS.yellow,
		previousStates: [0, 1],
	},
	{
		title: referralSubtitles[2],
		value: referralStatuses[2],
		color: COLORS.yellow,
		previousStates: [0, 1, 2],
	},
	{
		title: referralSubtitles[3],
		value: referralStatuses[3],
		color: COLORS.red,
		previousStates: [0, 1, 2, 3],
	},
	{
		title: referralSubtitles[4],
		value: referralStatuses[4],
		color: COLORS.red,
		previousStates: [0, 1, 2, 3, 4],
	},
	{
		title: referralSubtitles[6],
		value: referralStatuses[6],
		color: COLORS.red,
		previousStates: [0, 1, 2, 3, 4],
	},
	{
		title: referralSubtitles[7],
		value: referralStatuses[7],
		color: COLORS.red,
		previousStates: [0, 1, 2, 3, 4],
	},
	{
		title: 'Requested',
		value: 'requested',
		color: COLORS.yellow,
		previousStates: [0],
	},
];

export function ProgressIndicator(props) {
	console.log(
		'>> src/my-referrals-extended/progress-indicator >>\n',
		props.interviewingText
	);
	const { status } = props;
	if (!status) {
		return null;
	}

	let color = COLORS.dashboardLightOrange;
	if (status === progressBarOptions[3].value) {
		color = COLORS.green;
	}

	if (
		status === progressBarOptions[4].value ||
		status === 'declined' ||
		status === 'ineligible' ||
		status === 'notHired'
	) {
		color = COLORS.red;
	}

	if (
		status === progressBarOptions[4].value ||
		status === 'noresponse' ||
		status === 'inactive'
	) {
		color = COLORS.gray;
	}

	if (status === progressBarOptions[7].value) {
		color = COLORS.gray;
	}

	const hasCheck = (index) => {
		const indexForCurrentStatus =
			status === 'declined' ||
			status === 'noresponse' ||
			status === 'inactive' ||
			status === 'ineligible'
				? referralStatuses.indexOf('notHired')
				: referralStatuses.indexOf(status);
		if (progressBarOptions[indexForCurrentStatus] !== undefined) {
			return progressBarOptions[indexForCurrentStatus].previousStates.includes(
				index
			);
		}

		if (
			index === 0 &&
			progressBarOptions[indexForCurrentStatus] === undefined &&
			status === 'requested'
		) {
			return true;
		}
	};

	const hasFillColor = (index) => {
		const indexForCurrentStatus =
			status === 'declined' ||
			status === 'noresponse' ||
			status === 'inactive' ||
			status === 'ineligible'
				? referralStatuses.indexOf('notHired')
				: referralStatuses.indexOf(status);
		if (progressBarOptions[indexForCurrentStatus] !== undefined) {
			return progressBarOptions[indexForCurrentStatus].previousStates.includes(
				index
			);
		}

		if (
			index === 0 &&
			progressBarOptions[indexForCurrentStatus] === undefined &&
			status === 'requested'
		) {
			return true;
		}
	};

	const referredText =
		status === 'requested' ? 'Requested' : get(props, 'referredText');

	return (
		<div className={progressIndicatorContainer}>
			<div className={progressRow}>
				{Array.apply(null, { length: 5 })
					.map(Function.call, Number)
					.map((i) => {
						const dotFillColor = hasFillColor(i) ? color : COLORS.gray;
						const lineFillColor =
							hasFillColor(i) && hasCheck(i) && hasCheck(i + 1)
								? color
								: COLORS.gray;
						return (
							<div key={i} className={progressColumn}>
								{i !== 4 && (
									<div className={progressIndicatorLine(lineFillColor)} />
								)}
								<div className={progressIndicatorDots(dotFillColor)} />
								<div className={progressIndicatorDotCenter}>
									{hasCheck(i) && (
										<WebIcon
											style={{ position: 'absolute', top: '3px', left: '2px' }}
											color={color}
											name="checkmark"
											size={28}
										/>
									)}
								</div>
							</div>
						);
					})}
			</div>

			<div className={progressRow}>
				<div className={progressColumn}>
					<span
						className={subTitle(-12, status)}
						style={{ textAlign: 'center' }}
					>
						{referredText}
					</span>
				</div>
				<div className={progressColumn}>
					<span
						className={subTitle(-12, status)}
						style={{ textAlign: 'center', width: 50 }}
					>
						{props.acceptedText}
					</span>
				</div>
				<div className={progressColumn}>
					<span
						className={subTitle(-19, status)}
						style={{ textAlign: 'center', width: 60 }}
					>
						{props.interviewingText}
					</span>
				</div>
				<div className={progressColumn}>
					<span
						className={subTitle(-12, status)}
						style={{ textAlign: 'center', width: 50 }}
					>
						{props.hiredText}
					</span>
				</div>
				<div className={progressColumn}>
					<span
						className={subTitle(-13, status)}
						style={{ textAlign: 'center' }}
					>
						{props.notHiredText}
					</span>
				</div>
			</div>
		</div>
	);
}
