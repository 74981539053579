export const queryMyBonusesByUserIdIndex = `query QueryBonusByUserIdIndex(
    $userId: ID!
    $first: Int
    $after: String
  ) {
    queryBonusByUserIdIndex(
      userId: $userId
      first: $first
      after: $after
    ) {
        items {
            id
            amountDue
            companyId
            contactId
                contact {
                    id
                    firstName
                    lastName
                    emailAddress
                }
            userId
            user {
              id
              firstName
              lastName
              title
              userGroupId
              userGroup {
                id
                measurement
                name
                currency
              }
              currency
              languageCode
            }
            jobId
            job {
              title
            }
            referralId
            referral {
                id
                referralDate
            }
            hireDate
            startDate
            earnedDate
            payment
            recipientType
            bonusStatus
            notes
          }
          nextToken
    }
  }
  `;
