import React from 'react';

import AllRewardsAdminPresentation from '../../presentation/AllRewardsAdminPresentation';

import useGetRewardsInfo from '../../hooks/useGetRewardsInfo.js';

// import './style.scss'
import { ReactComponent as PointsToSendIcon } from './../../../assets/svg/pointstosend.svg';
import { ReactComponent as RecipientIcon } from './../../../assets/svg/recipientOne.svg';
import { ReactComponent as RewardIcon } from './../../../assets/svg/rewardOne.svg';
import { ReactComponent as CalendarIcon } from './../../../assets/svg/calendar.svg';
import { ReactComponent as CalendarSilhouetteIcon } from './../../../assets/svg/calendar-silhouette.svg';

const AllRewardsAdminContainer = () => {
	const {
		allActivitiesInfo,
		allRewardsInfo,
		setSelectedFilter,
		selectedFilter,
		getAllRewardCSV,
	} = useGetRewardsInfo();

	const badgeSummary = [
		{
			cardColor: '#14d6d3',
			pointAmount: allRewardsInfo?.totalPoints,
			cardTitle: 'Total Points',
			Icon: PointsToSendIcon,
		},
		{
			cardColor: '#e75da7',
			pointAmount: allRewardsInfo?.peerToPeerBadges,
			cardTitle: 'On-The-Spot Recognitions',
			Icon: RewardIcon,
		},
		{
			cardColor: '#826ae6',
			pointAmount: allRewardsInfo?.anniversaryBadges,
			cardTitle: 'Milestones',
			Icon: CalendarSilhouetteIcon,
		},
	];

	const engagementSummary = [
		{
			cardColor: '#242e3f',
			pointAmount: allRewardsInfo?.Employees,
			cardTitle: 'Employees',
			Icon: PointsToSendIcon,
		},
		{
			cardColor: '#8e2123',
			pointAmount: allRewardsInfo?.allTimeShoutouts,
			cardTitle: <>Shoutout <br />(All Time)</>,
			Icon: RecipientIcon,
		},
		{
			cardColor: '#dc4c47',
			pointAmount: allRewardsInfo?.lastThirtyDaysShoutouts,
			cardTitle: <>Shoutouts <br /> (Last 30 Days)</>,
			Icon: CalendarIcon,
		},
	];

	return (
		<AllRewardsAdminPresentation
			badgeSummary={badgeSummary}
			allActivitiesInfo={allActivitiesInfo}
			setSelectedFilter={setSelectedFilter}
			selectedFilter={selectedFilter}
			getAllRewardCSV={getAllRewardCSV}
			engagementSummary={engagementSummary}
		/>
	);
};

export default AllRewardsAdminContainer;
