import { SyncOutlined } from '@ant-design/icons';
import { Button, Col, List, Modal, Popconfirm, Row, Tooltip } from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import dompurify from 'dompurify';
import gql from 'graphql-tag';
import * as htmlToImage from 'html-to-image';
import Cookies from 'js-cookie';
import jsPDF from 'jspdf';
import _ from 'lodash';
import { PureComponent, createRef } from 'react';
import { withApollo } from 'react-apollo';
import ReactGA from 'react-ga';
import { Link } from 'react-router-dom';
import { queryBonusByContactIdIndex } from 'src/_shared/api/graphql/custom/bonuses/';
import { GetJobForMatch } from 'src/_shared/api/graphql/custom/jobMatch/';
import { queryJobsCountByCompanyId } from 'src/_shared/api/graphql/custom/jobs/';
import {
	GetUserByCognitoId,
	getUserById,
} from 'src/_shared/api/graphql/custom/users/';
import Onboarding from 'src/_shared/components/onboarding/OnboardingComponent.jsx';
import { SearchBanner } from 'src/_shared/components/search-banner/index.js';
import Spinner from 'src/_shared/components/spinner/SpinnerComponent.jsx';
import { EMPLOYEE_DASHBOARD, USER_ROLES } from 'src/_shared/constants/index.js';
import {
	getSetErrorImageURL,
	getSize,
	getTextColorFromBGColor,
	logout,
	ml,
	parse,
	searchOpenSearchNetwork,
} from 'src/_shared/services/utils.js';
import { COLORS } from 'src/_shared/styles/colors.js';
import Logo from '../_shared/assets/erin_lightgray.png';
import OnDeckReferralModal from '../_shared/components/on-deck/general-referral-modal/SubmitGeneralReferralModalComponent.jsx';
import { AdminAnalyticsComponent } from './admin-analytics';
import {
	LinkStyle,
	NoSmartReferrals,
	NoSmartReferralsWithLogo,
	cardListStyle,
	linkText,
} from './dashboardStyles.js';
import {
	AnnouncementCard,
	ApplyInternally,
	BonusCard,
	BonusReferralCard,
	BonusesCard,
	EmpLeaderBoard,
	EmployeesCard,
	ExtendedTotalReferralCard,
	GeneralReferral,
	HotJobs,
	JobAlert,
	JobCard,
	JobForYouCard,
	JobMatchCard,
	JobNetworkCard,
	LeaderBoard,
	PointCard,
	RankingCard,
	RecentReferralCard,
	ReferralCard,
	ReferralPolicyCard,
	SendReferral,
} from './tiles';

const sanitizer = dompurify.sanitize;
dayjs.extend(customParseFormat);

class DashboardComponent extends PureComponent {
	constructor(props) {
		super(props);
		const jobMatches = props?.currentUser?.jobMatches || [];
		let allJobMatches = _.orderBy(jobMatches, ['relevance'], ['desc', 'asc'])
			.filter(
				(match) =>
					!match?.job?.hideImInterested &&
					props?.currentUser?.companyId === match?.job?.companyId
			)
			.map((match) => {
				match.job.location = parse(match?.job?.location);
				return match;
			});

		if (props.currentUser.role !== USER_ROLES.EXTENDED_USER) {
			// Remove job matches that base users can't apply to
			allJobMatches = allJobMatches.filter(
				(match) =>
					!(
						(props?.currentUser?.company?.allowSelfReferralsInternalLink ||
							false) &&
						!(match?.job?.internalJobLink || null)
					)
			);
		}

		this.state = {
			allJobMatches,
			company: props?.currentUser?.company,
			customText: props?.company?.customText
				? JSON.parse(props.company.customText)
				: null,
			isLoading: true,
			companyData: props.companyData,
			referralBonuses: props?.referralBonuses,
			openPositionsCount: props.openPositions,
			WithReferralCount: props.bonusJobs ? props.bonusJobs.totalJobReferral : 0,
			NoReferralCount: props.bonusJobs ? props.bonusJobs.totalJobNoReferral : 0,
			TierWiseInfo: props.bonusJobs ? props.bonusJobs.tierWise : [],
			totalReferralsCount: props.bonusReferral
				? props.bonusReferral.totalReferral
				: 0,
			statusWiseReferrals: props.bonusReferral
				? props.bonusReferral.statusWise
				: [],
			tierWiseReferrals: props.bonusReferral
				? props.bonusReferral.tierWise
				: [],
			bonuses: props.bonuses ? props.bonuses : {},
			employeesInfo: props.employees ? props.employees : {},
			dashboardLastUpdated: props.dashboardLastUpdated,
			dashboardSearchBG: props.company?.dashboardSearchBG,
			syncJobs: true,
			isTutorailVisible: localStorage.getItem('isTutorailVisible')
				? localStorage.getItem('isTutorailVisible') != 'hidden'
				: true,
			referralLeaderboard: false,
			pointsLeaderboard: true,
			isLoginPopupVisible: localStorage.getItem('firstLoginValue')
				? localStorage.getItem('firstLoginValue') === 'false'
				: false,
			referralsThisYearCount: props.referralsThisYear,
			searchQuery: '',
			visible: false,
			errorImageURL: '',
		};
	}

	async componentDidMount() {
		this.handleResize();
		window.addEventListener('resize', this.handleResize);
		const host = window.location.hostname;

		// To user's activity or user's company disableSite
		const disableSite = this.props?.currentUser?.company?.disableSite || false;
		const supportAdmin = parse(this.props?.currentUser?.admin);
		const supportAdminPermissions = supportAdmin?.permissions === '*';
		const userActive = this.props?.currentUser?.active || false;
		const { matchingAtsIdContacts = [], history } = this.props;

		if ((disableSite && !supportAdminPermissions) || !userActive) {
			history.push('/logout');
		}

		if (host === 'referrals.aus.com') {
			ReactGA.initialize('UA-128630809-2');
			ReactGA.pageview(window.location.pathname + window.location.search);
		} else if (host === 'app.erinapp.com') {
			ReactGA.initialize('UA-128630809-3');
			ReactGA.pageview(window.location.pathname + window.location.search);
		}

		setTimeout(() => {
			this.setState({ referralBonuses: this.props?.referralBonuses });
		}, 3500);
		await this.getUserDataById();
		const jwt = Cookies.get('jwt');
		const { resultData } = this.state;
		if (resultData !== '' && resultData !== undefined) {
			const expirationDoneByToken = resultData?.expirationDoneByToken;
			const expires = resultData?.expires;
			if (jwt !== undefined && jwt !== expirationDoneByToken && expires) {
				logout(this.props.client);
			}
		}

		const { bonuses = [] } = this.state;

		if (this.props?.location?.state?.successMessage)
			this.successMessage(this.props?.location?.state?.successMessage);
		matchingAtsIdContacts.forEach(async (contact) => {
			const contactId = contact?.id;
			const { data } = await this.props.client.query({
				query: gql(queryBonusByContactIdIndex),
				variables: { contactId },
			});
			const allBonuses = data?.queryBonusByContactIdIndex?.items || [];
			const candidateBonuses = allBonuses.filter(
				(bonus) => bonus?.recipientType === 'candidate'
			);
			this.setState({ bonuses: [...bonuses, ...candidateBonuses] });
		});
		const cognitoId = this.props?.currentUser?.cognitoId;
		const { data } = await this.props.client.query({
			query: GetUserByCognitoId,
			variables: { cognitoId },
			fetchPolicy: 'network-only',
		});
		const currentUser = data?.getUserByCognitoId;
		if (currentUser) {
			currentUser.displayAs = this.props.currentUser?.displayAs;
			this.props.setCurrentUser(currentUser);
		}

		await this.setQueryToState(this.state?.searchQuery);
		const errorImageURL = await getSetErrorImageURL(
			this.props?.currentUser?.company?.errorImage?.key
		);

		if (errorImageURL) {
			this.setState({ errorImageURL });
		}

		if (this.state.allJobMatches.length <= 0)
			await this.getTemporaryJobMatches();
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.handleResize);
	}

	UNSAFE_componentWillReceiveProps(props) {
		const {
			bonusJobs,
			bonusReferral,
			bonuses,
			employees,
			dashboardLastUpdated,
		} = props;

		const WithReferralCount = bonusJobs ? bonusJobs.totalJobReferral : 0;
		const NoReferralCount = bonusJobs ? bonusJobs.totalJobNoReferral : 0;
		const TierWiseInfo = bonusJobs ? bonusJobs.tierWise : [];
		const totalReferralsCount = bonusReferral ? bonusReferral.totalReferral : 0;
		const statusWiseReferrals = bonusReferral ? bonusReferral.statusWise : [];
		const tierWiseReferrals = bonusReferral ? bonusReferral.tierWise : [];
		const employeesInfo = employees ? employees : {};
		const bonusesData = bonuses ? bonuses : {};
		const lastUpdate = dashboardLastUpdated ? dashboardLastUpdated : {};

		this.setState({ syncJobs: false });
		this.setState({
			WithReferralCount,
			NoReferralCount,
			TierWiseInfo,
			totalReferralsCount,
			statusWiseReferrals,
			tierWiseReferrals,
			bonuses: bonusesData,
			employeesInfo,
			dashboardLastUpdated: lastUpdate,
		});
	}

	async componentDidUpdate(prevProps) {
		const { bonuses = [] } = this.state;
		const {
			currentUser,
			jobMatches,
			matchingAtsIdContacts = [],
			onFetchMoreJobMatches,
			updateCurrentUser,
		} = this.props;
		if (prevProps.matchingAtsIdContacts !== this.props.matchingAtsIdContacts) {
			matchingAtsIdContacts.forEach(async (contact) => {
				const contactId = contact?.id;
				const { data } = await this.props.client.query({
					query: gql(queryBonusByContactIdIndex),
					variables: { contactId },
				});
				const allBonuses = data?.queryBonusByContactIdIndex?.items || [];
				const candidateBonuses = allBonuses.filter(
					(bonus) => bonus?.recipientType === 'candidate'
				);
				this.setState({ bonuses: [...bonuses, ...candidateBonuses] });
			});
		}

		if (prevProps.companyData !== this.props.companyData) {
			this.setState({
				companyData: this.props.companyData,
			});
		}

		if (prevProps?.company?.id !== this.props?.company?.id) {
			this.setState({
				customText: this.props.company?.customText
					? JSON.parse(this.props.company.customText)
					: null,
			});
		}
	}

	async getJobsCount(policy = 'network-only') {
		const { client, currentUser } = this.props;
		try {
			this.setState({ syncJobs: true });
			const { data } = await client.query({
				query: gql(queryJobsCountByCompanyId),
				variables: {
					companyId: currentUser?.companyId,
				},
				fetchPolicy: policy,
			});
			const jobsCount = data.listOpenJobs.openPositions;
			const referralsCountThisYear = data.listOpenJobs.referralsThisYear;
			let bonusJobJson = {};
			if (data.listOpenJobs.bonusJobs) {
				bonusJobJson = parse(data.listOpenJobs.bonusJobs);
			}

			let bonusReferralJson = {};
			if (data.listOpenJobs.bonusReferral) {
				bonusReferralJson = parse(data.listOpenJobs.bonusReferral);
			}

			let bonusesJson = {};

			if (data.listOpenJobs.bonuses) {
				bonusesJson = parse(data.listOpenJobs.bonuses);
			}

			let employeesInfoJson = {};
			if (data.listOpenJobs.employees) {
				employeesInfoJson = parse(data.listOpenJobs.employees);
			}

			let dashboardLastUpdated = '';
			if (data.listOpenJobs.dashboardLastUpdated) {
				dashboardLastUpdated = data.listOpenJobs.dashboardLastUpdated;
			}

			this.setState({
				openPositionsCount: jobsCount,
				WithReferralCount: bonusJobJson.totalJobReferral,
				NoReferralCount: bonusJobJson.totalJobNoReferral,
				TierWiseInfo: bonusJobJson.tierWise,
				totalReferralsCount: bonusReferralJson.totalReferral,
				statusWiseReferrals: bonusReferralJson.statusWise,
				tierWiseReferrals: bonusReferralJson.tierWise,
				syncJobs: false,
				bonuses: bonusesJson,
				employeesInfo: employeesInfoJson,
				dashboardLastUpdated,
				referralsThisYearCount: referralsCountThisYear,
			});
		} catch (error) {
			console.log(error);
		}
	}

	getTemporaryJobMatches = async () => {
		const parameters = {
			query: this.props?.currentUser?.title ?? '',
			size: 15,
			role: this.props?.currentUser?.role,
			currentUser: this.props?.currentUser?.id,
			filters: {
				companies: [this.props?.currentUser?.companyId],
				status: 'open',
			},
		};
		const response = await searchOpenSearchNetwork(parameters, 'erin-jobs');
		const temporaryMatches = response?.data || [];
		const top15 = temporaryMatches.slice(0, 15);
		const matches = [];
		await Promise.all(
			top15.map(async (item, index) => {
				const { data } = await this.props.client.query({
					query: GetJobForMatch,
					variables: { id: item?.jobId },
				});
				const job = data?.getJob || {};
				const match = {
					active: true,
					contactId: null,
					dateCreated: new Date().toISOString(),
					id: `${index}`,
					jobId: job?.id,
					job,
					matchStatus: null,
					relevance: item?.metaScore,
					userId: this.props?.currentUser?.id,
					temporary: true,
				};
				if (
					this.props.currentUser.role !== USER_ROLES.EXTENDED_USER &&
					(this.props.currentUser.company.allowSelfReferralsInternalLink ||
						false) &&
					!(job.internalJobLink || null)
				) {
					// do nothing
				} else {
					matches.push(match);
				}
			})
		);
		matches.push({
			id: 'update',
			active: true,
		});
		this.setState((_prevState) => ({
			allJobMatches: matches,
		}));
	};

	async getUserDataById(policy = 'network-only') {
		const { client, currentUser } = this.props;
		try {
			const userId = currentUser?.id || null;
			if (userId !== null) {
				const { data } = await client.query({
					query: gql(getUserById),
					variables: {
						id: userId,
					},
					fetchPolicy: policy,
				});
				const result = data?.getUser || null;
				this.setState({
					resultData: result,
				});
			}
		} catch (error) {
			console.log(error);
		}
	}

	setQueryToState = async (searchQuery = '') => {
		const {
			filteredDepartments = [],
			filteredBonuses = [],
			filteredDistance = 0,
			filterOpenStatus = 'open',
			lat,
			lng,
			filteredSubCompanies = [],
			filteredJobLevels = [],
		} = this.state;
		const isExtendedUser = this.props?.currentUser?.role === 'extendedUser';
		const extendedCompaniesData =
			this.props?.currentUser?.extendedCompaniesData || [];
		const extendedCompanies = extendedCompaniesData.filter(
			(company) => company?.disableExtendedUserJobsView !== true
		);
		const comps =
			extendedCompanies && extendedCompanies.length > 0 && isExtendedUser
				? extendedCompanies
				: [this.props?.currentUser?.company];
		const companies = comps.map((company) => company?.id);
		const filteredCompanies =
			(this.state?.filterCompanies || []).length > 0
				? this.state.filterCompanies
				: companies;
		const filterCompanies = filteredCompanies.map((company) => company?.id);
		const unit =
			(this.props?.currentUser?.userGroup?.measurement || 'imperial') ===
			'metric'
				? 'km'
				: 'mi';
		clearTimeout(this.timer);
		this.setState({ searchQuery });
		this.timer = setTimeout(async () => {
			if (!this.state.isLoading) this.setState({ isLoading: true });
			const parameters = {
				query: this.state?.searchQuery || '',
				size: 300,
				coordinates: [{ lat, lng }],
				role: this.props?.currentUser?.role,
				currentUser: this.props?.currentUser?.id,
				filters: {
					bonuses: filteredBonuses,
					companies: filteredCompanies,
					departments: filteredDepartments,
					distance: filteredDistance,
					status: filterOpenStatus,
					subCompanies: filteredSubCompanies,
					jobLevels: filteredJobLevels,
				},
				unit,
			};

			const response = await searchOpenSearchNetwork(parameters, 'erin-jobs');

			const parametersHot = {
				query: this.state?.searchQuery || '',
				size: 300,
				coordinates: [{ lat, lng }],
				role: this.props?.currentUser?.role,
				currentUser: this.props?.currentUser?.id,
				filters: {
					companies: filteredCompanies,
					status: filterOpenStatus,
					isHotJob: true,
				},
				unit,
			};

			const responseHot = await searchOpenSearchNetwork(
				parametersHot,
				'erin-jobs'
			);

			if (response?.query === this.state?.searchQuery) {
				let searchedJobs = response?.data || [];
				const searchedHot = responseHot?.data || [];
				const filterOn =
					(filteredDistance.length > 0 && filteredDistance != '0') ||
					searchQuery.length > 0;

				searchedJobs = filterOn
					? searchedJobs || []
					: _.orderBy(
							searchedJobs || [],
							['isHotJob', 'dateCreated'],
							['desc', 'desc']
						);
				if (searchedJobs) {
					const filteredJobsGeneral = searchedJobs.filter(
						(item) => item.isGeneralReferral === true
					);
					const hotSearchedJobs =
						searchedHot.length > 0
							? searchedHot
							: searchedJobs.filter((item) => item.isHotJob === true);
					searchedJobs = searchedJobs.filter((item) => item.isHotJob !== true);
					this.setState({
						isLoading: false,
						searchedJobs,
						filteredJobsGeneral,
						hotSearchedJobs,
					});
				} else {
					this.setState({
						isLoading: false,
					});
				}
			}
		}, 700);
	};

	capitalizeIt = (string = '') => {
		return string.toUpperCase();
	};

	findBestMatch(array) {
		if (array) {
			if (array.length === 0) {
				return -1;
			}

			let maxIndex = -1;
			let maxRelevance = 9;
			for (const [i, m] of array.entries()) {
				if (m.relevance > maxRelevance && m.matchStatus !== false) {
					maxIndex = i;
					maxRelevance = m.relevance;
				}
			}

			return maxIndex;
		}

		return null;
	}

	formatDate(date, languageCode) {
		if (!date) return;
		const isoDate = new Date(date);
		const month = isoDate.getMonth() + 1;
		const day = isoDate.getDate();
		const year = isoDate.getFullYear();
		const hours = isoDate.getHours();
		const mins = ('0' + isoDate.getMinutes()).slice(-2);
		if (
			(date !== null && languageCode === 'FR') ||
			languageCode === 'DE' ||
			languageCode === 'ES' ||
			languageCode === 'RU' ||
			languageCode === 'European'
		) {
			return `${day}/${month}/${year} ${hours}${':'}${mins}`;
		}

		return `${month}/${day}/${year} ${hours}${':'}${mins}`;
	}

	handleBonus = () => {
		this.props.history.push('/bonuses');
	};

	handleBrowseJobs = (id) => {
		if (id) {
			this.props.history.push(`/browsejobs/${id}`);
		} else {
			this.props.history.push('/browsejobs');
		}
	};

	handleCancel = () => {
		this.setState({ visible: false });
	};

	handleCareerProfile = () => {
		this.props.history.push('/career');
	};

	handleEmployees = () => {
		this.props.history.push('/employees');
	};

	handleFirstLoginPopup = () => {
		localStorage.removeItem('firstLoginValue');
		this.setState({ isLoginPopupVisible: false });
	};

	handleReferrals = () => {
		this.props.history.push('/referrals');
	};

	handleResize = () => {
		const getWindowDimensions = () => {
			const { innerWidth: width, innerHeight: height } = window;
			return {
				width,
				height,
			};
		};

		const windowDimensions = getWindowDimensions();
		this.setState({ windowDimensions });
	};

	handleTutorialPopup = () => {
		const { handleCancel } = this.props;
		this.setState({ isTutorailVisible: false });
		localStorage.setItem('isTutorailVisible', 'hidden');
	};

	notInterested = (match) => {
		const input = {
			id: match?.id,
			companyId: match?.job?.companyId,
			active: false,
		};
		if (!match?.temporary) this.props.onUpdateJobMatch(input);
		let allJobMatches = this.state?.allJobMatches || [];
		const index = allJobMatches.findIndex((m) => m.id === match.id);
		const updatedMatch =
			index >= 0 ? { ...allJobMatches[index], ...input } : null;

		if (updatedMatch) {
			allJobMatches[index] = updatedMatch;
		}

		const temporaryMatches = [];
		if (match?.temporary) {
			allJobMatches = allJobMatches.filter(
				(match) => match.id !== 'update' && match.active !== false
			);
			allJobMatches.map((match, index) => {
				temporaryMatches.push(match);
				if ((index + 1) % 3 === 0 || index === allJobMatches.length - 1)
					temporaryMatches.push({
						id: 'update',
						active: true,
					});
			});
		}

		const currentUser = this.props?.currentUser;
		currentUser.jobMatches = allJobMatches;
		if (match?.temporary) {
			this.setState({ allJobMatches: temporaryMatches });
		} else {
			this.props.setCurrentUser(currentUser);
		}
	};

	successMessage = (content) => {
		Modal.success({
			content,
		});
	};

	syncJobsCount = () => {
		this.getJobsCount();
	};

	renderFirstLoginPopupContent = () => {
		const popupTitle = this.props?.currentUser?.company?.popupTitle;
		const popupTitleContent =
			this.props?.currentUser?.company?.popupTitleContent;
		return (
			<>
				<div className="ant-modal-header inside-body">
					<div className="ant-modal-title">{popupTitle}</div>
				</div>

				<div
					dangerouslySetInnerHTML={{ __html: sanitizer(popupTitleContent) }}
					style={{ marginTop: 20 }}
				/>

				<div className="modal-footer-btn">
					<Button
						type="primary"
						className="btn-min-width"
						onClick={this.handleFirstLoginPopup}
					>
						Got It!
					</Button>
				</div>
			</>
		);
	};

	renderAdminAnalytics = () => {
		const { currentUser } = this.props;
		if (
			currentUser.role === USER_ROLES.ADMIN &&
			currentUser.displayAs === USER_ROLES.ADMIN
		) {
			return (
				<div>
					<AdminAnalyticsComponent
						companyId={currentUser.companyId}
						analyticsCardInputValues={
							this.props?.companyData?.analyticsCardInputValues
						}
						onUpdateCompany={this.props.onUpdateCompany}
					/>
				</div>
			);
		}

		return null;
	};

	render() {
		const ref = createRef();
		const {
			currentUser,
			topReferrers = [],
			departments,
			jobs = [],
			userDashboard,
			jobMatches,
			bonusJobs,
			bonusReferral,
			bonuses,
			employees,
			extendedReferrals = [],
			dashboardLastUpdated,
			allMultiLingualData,
			referralContact,
			announcements,
		} = this.props;
		const {
			company,
			companyData,
			customText,
			referralBonuses,
			openPositionsCount,
			syncJobs,
			isLoginPopupVisible,
			referralsThisYearCount,
			hotSearchedJobs,
			errorImageURL,
		} = this.state;

		const customGeneralModal = this.props?.companyData?.customGeneralModal
			? JSON.parse(this.props.companyData.customGeneralModal)
			: {};
		const generalCardHeader =
			customText?.generalCardHeader ??
			ml('SUBMIT', currentUser, allMultiLingualData);
		const generalCardSubheader =
			customText?.generalCardSubheader ??
			ml('A GENERAL REFERRAL', currentUser, allMultiLingualData);
		const generalCardButton =
			customText?.generalCardButton ??
			ml('SUBMIT REFERRAL', currentUser, allMultiLingualData);
		const referralCardHeader =
			customText?.referralCardHeader ??
			ml('REFER', currentUser, allMultiLingualData);
		const referralCardSubheader =
			customText?.referralCardSubheader ??
			ml('YOUR FRIENDS', currentUser, allMultiLingualData);
		const referralCardButton =
			customText?.referralCardButton ??
			ml('SEND A REFERRAL', currentUser, allMultiLingualData);
		const internalCardHeader =
			customText?.internalCardHeader ??
			ml('APPLY', currentUser, allMultiLingualData);
		const internalCardSubheader =
			customText?.internalCardSubheader ??
			ml('& GROW YOUR CAREER', currentUser, allMultiLingualData);
		const internalCardButton =
			customText?.internalCardButton ??
			ml('APPLY INTERNALLY', currentUser, allMultiLingualData);
		const leadCardHeader =
			customText?.leadCardHeader ??
			ml('SUBMIT', currentUser, allMultiLingualData);
		const leadCardSubheader =
			customText?.leadCardSubheader ??
			ml('A REFERRAL LEAD', currentUser, allMultiLingualData);
		const leadCardButton =
			customText?.leadCardButton ??
			ml('SUBMIT REFERRAL', currentUser, allMultiLingualData);
		const onboarding = parse(this.props?.company?.onboarding);
		const onboardingStatus = onboarding?.status;
		const announcement = announcements;
		const customStages = this.props?.currentUser?.company?.stages;
		const sendReferralColor =
			currentUser?.company?.sendReferralColor || '#E75DA7';
		const applyInternallyColor =
			currentUser?.company?.applyInternallyColor || '#14D6D3';
		const generalReferralColor =
			currentUser?.company?.generalReferralColor || '#7942E7';
		const referralLeadColor =
			currentUser?.company?.referralLeadColor || '#F7A34D';
		const sendReferralTextColor = getTextColorFromBGColor({
			backgroundColor: sendReferralColor,
		});
		const applyInternallyTextColor = getTextColorFromBGColor({
			backgroundColor: applyInternallyColor,
		});
		const generalReferralTextColor = getTextColorFromBGColor({
			backgroundColor: generalReferralColor,
		});
		const referralLeadTextColor = getTextColorFromBGColor({
			backgroundColor: referralLeadColor,
		});
		const stages = [];
		if (customStages) {
			const parsedData = parse(customStages);
			if (parsedData.length > 0) {
				stages.push(...parsedData);
			}
		}

		const publicLeaderboard = currentUser?.company?.publicLeaderboard || false;
		const hideReferralsRanking =
			currentUser?.company?.hideReferralsRanking || false;
		const topUsersByPoints = this.props.topUsersByPoints
			? this.props.topUsersByPoints.filter((item) => item.points !== null)
			: [];
		const whiteLabel = company?.whiteLabel;
		const WithReferralCount = this.state.WithReferralCount
			? this.state.WithReferralCount
			: bonusJobs
				? bonusJobs.totalJobReferral
				: 0;
		const NoReferralCount = this.state.totalJobNoReferral
			? this.state.totalJobNoReferral
			: bonusJobs
				? bonusJobs.totalJobNoReferral
				: 0;
		const TierWiseInfo = this.state.tierWise
			? this.state.tierWise
			: bonusJobs
				? bonusJobs.tierWise
				: [];
		const totalReferralsCount = this.state.totalReferral
			? this.state.totalReferral
			: bonusReferral
				? bonusReferral.totalReferral
				: 0;
		const statusWiseReferrals = this.state.statusWise
			? this.state.statusWise
			: bonusReferral
				? bonusReferral.statusWise
				: [];
		const tierWiseReferrals = this.state.tierWise
			? this.state.tierWise
			: bonusReferral
				? bonusReferral.tierWise
				: [];
		const employeesInfo = this.state.employeesInfo
			? this.state.employeesInfo
			: employees
				? employees
				: {};
		const bonusesData = this.state.bonuses
			? this.state.bonuses
			: bonuses
				? bonuses
				: {};
		const lastUpdate = this.state.dashboardLastUpdated
			? this.state.dashboardLastUpdated
			: dashboardLastUpdated
				? dashboardLastUpdated
				: {};
		const dateCreated = currentUser?.company?.dateCreated;
		const recommendedReferral = this.findBestMatch(jobMatches);
		let recommendedJob;
		let matchesRecommendedJob;
		let matchNumber;
		if (recommendedReferral && recommendedReferral >= 0) {
			recommendedJob = jobs.filter(
				(job) => job.id === jobMatches[recommendedReferral].jobId
			);
			if (recommendedJob.length > 0) {
				matchesRecommendedJob = jobs.filter(
					(job) => job.id === recommendedJob[0].jobId
				);
				matchNumber = matchesRecommendedJob.length + 1;
			}
		}

		const departmentsToUse = departments ? departments : [];
		const theme = this.props?.currentUser?.company?.theme;
		const languageCode = this.props?.currentUser?.languageCode;
		const currencyCode = this.props?.currentUser?.userGroup?.currency || 'USD';
		const careerProfile = parse(this.props?.currentUser?.careerProfile);
		const hasUploadedResume = Boolean(
			careerProfile && careerProfile?.resumeFile?.name
		);
		const allJobMatches = (this.state?.allJobMatches || []).filter(
			(match) => match?.active === true
		);
		let isPointsInfoVisible = false;
		const pointsSettings = this.state?.companyData?.pointsSettings;

		if (pointsSettings !== null && pointsSettings !== undefined) {
			const pointsSettingsData = parse(pointsSettings);
			isPointsInfoVisible = Boolean(pointsSettingsData.enabled);
		}

		if (!allMultiLingualData || allMultiLingualData.length === 0) {
			return null;
		}

		const popupTitle = this.props?.currentUser?.company?.popupTitle || '';
		const popupTitleContent =
			this.props?.currentUser?.company?.popupTitleContent || '';
		const referralStatus =
			currentUser?.company?.referralStatus || 'Interviewing';
		let employeeDashboard = currentUser?.company?.employeeDashboard || [];
		employeeDashboard = employeeDashboard ? parse(employeeDashboard) : [];
		let showReferSomeone = true;
		let showInternal = true;
		let showYourReferrals = true;
		let showRanking = true;
		let showPoints = true;
		let showLeaderboards = true;
		let showJobs = true;
		let showJobAlerts = true;
		let showAnnouncements = true;
		let showPolicy = true;
		let showJobsForYou = true;
		let showHotJobs = false;
		let showGeneralReferrals = false;
		let showReferralLead = false;
		let referOrder;
		let internalOrder;
		let yourReferralsOrder;
		let rankingOrder;
		let pointsOrder;
		let jobAlertsOrder;
		let jobsOrder;
		let leaderboardOrder;
		let announcementOrder;
		let policyOrder;
		let jobsForYouOrder;
		let hotJobsOrder;
		let generalReferralsOrder;
		let referralLeadOrder;
		if (employeeDashboard && employeeDashboard.length >= 0) {
			const referSomeoneData = employeeDashboard.find(
				(x) => x.name === EMPLOYEE_DASHBOARD.REFER_SOMEONE
			);
			showReferSomeone = Boolean(referSomeoneData);
			referOrder = referSomeoneData?.order;
			const internalMobilityData = employeeDashboard.find(
				(x) => x.name === EMPLOYEE_DASHBOARD.INTERNAL_MOBILITY
			);
			showInternal = Boolean(internalMobilityData);
			internalOrder = internalMobilityData?.order;
			const yourReferralsData = employeeDashboard.find(
				(x) => x.name === EMPLOYEE_DASHBOARD.YOUR_REFERRALS
			);
			showYourReferrals = Boolean(yourReferralsData);
			yourReferralsOrder = yourReferralsData?.order;
			const referralsMadeRankingData = employeeDashboard.find(
				(x) => x.name === EMPLOYEE_DASHBOARD.REFERRALS_MADE_RANKING
			);
			showRanking = Boolean(referralsMadeRankingData);
			rankingOrder = referralsMadeRankingData?.order;
			const pointsData = employeeDashboard.find(
				(x) => x.name === EMPLOYEE_DASHBOARD.POINTS
			);
			showPoints = Boolean(pointsData);
			pointsOrder = pointsData?.order;
			const jobsOverviewData = employeeDashboard.find(
				(x) => x.name === EMPLOYEE_DASHBOARD.JOBS_OVERVIEW
			);
			showJobs = Boolean(jobsOverviewData);
			jobsOrder = jobsOverviewData?.order;
			const leaderboardsData = employeeDashboard.find(
				(x) => x.name === EMPLOYEE_DASHBOARD.LEADERBOARDS
			);
			showLeaderboards = Boolean(leaderboardsData);
			leaderboardOrder = leaderboardsData?.order;
			const announcementsData = employeeDashboard.find(
				(x) => x.name === EMPLOYEE_DASHBOARD.ANNOUNCEMENTS
			);
			showAnnouncements = Boolean(announcementsData);
			announcementOrder = announcementsData?.order;
			const policyData = employeeDashboard.find(
				(x) => x.name === EMPLOYEE_DASHBOARD.POLICY
			);
			showPolicy = Boolean(policyData);
			policyOrder = policyData?.order;
			const jobsForYouData = employeeDashboard.find(
				(x) => x.name === EMPLOYEE_DASHBOARD.JOBS_FOR_YOU
			);
			showJobsForYou = Boolean(jobsForYouData);
			jobsForYouOrder = jobsForYouData?.order;

			const hotJobsData = employeeDashboard.find(
				(x) => x.name === EMPLOYEE_DASHBOARD.HOT_JOBS
			);
			showHotJobs = Boolean(hotJobsData);
			hotJobsOrder = hotJobsData?.order;

			const generalReferralsData = employeeDashboard.find(
				(x) => x.name === EMPLOYEE_DASHBOARD.GENERAL_REFERRAL
			);
			showGeneralReferrals = Boolean(generalReferralsData);
			generalReferralsOrder = generalReferralsData?.order;

			const referralLeadData = employeeDashboard.find(
				(x) => x.name === EMPLOYEE_DASHBOARD.REFERRAL_LEAD
			);
			showReferralLead = Boolean(referralLeadData);
			referralLeadOrder = referralLeadData?.order;

			const jobAlertsData = employeeDashboard.find(
				(x) => x.name === EMPLOYEE_DASHBOARD.JOB_ALERTS
			);
			showJobAlerts = Boolean(jobAlertsData);
			jobAlertsOrder = jobAlertsData?.order;
		}

		const exportPdF = () => {
			const input = document.querySelector('#dashboard');
			htmlToImage.toCanvas(input).then((canvas) => {
				const imgData = canvas.toDataURL('img/png');
				const pdf = new jsPDF(
					this.state.windowDimensions?.width <= 1441 ? 'p' : 'l',
					'in',
					'a4'
				);
				const imgProperties = pdf.getImageProperties(imgData);
				const pdfWidth = pdf.internal.pageSize.getWidth();
				const pdfHeight = pdf.internal.pageSize.getHeight();
				const renderPdfHeight =
					imgProperties.height <= pdfHeight
						? (imgProperties.height * pdfWidth) / imgProperties.width
						: pdfHeight;
				pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, renderPdfHeight);
				pdf.save('dashboard.pdf');
			});
		};

		const pageSize = getSize(this.state.windowDimensions);

		return !currentUser?.company ||
			!(this.props.activeEmployees >= 0) ||
			!(this.props.inNetworkConnections >= 0) ? (
			<Spinner />
		) : (
			<main ref={ref} id="dashboard">
				{(currentUser.role === USER_ROLES.EMPLOYEE ||
					currentUser.displayAs === USER_ROLES.EMPLOYEE) &&
				this.props.company?.enableDashboardSearch ? null : (
					<div className="page-title">
						<h2 className="page-heading">
							{ml(`Hello`, currentUser, allMultiLingualData)},{' '}
							{currentUser?.firstName || ''}
						</h2>

						<ul className="info-action">
							{currentUser.displayAs === USER_ROLES.ADMIN && (
								<li>
									{ml(`Last Updated`, currentUser, allMultiLingualData)}:{' '}
									{dayjs(new Date(lastUpdate)).isValid
										? this.formatDate(lastUpdate, currentUser?.dateFormat)
										: this.formatDate(dateCreated, currentUser?.dateFormat)}
								</li>
							)}
							{currentUser.displayAs === USER_ROLES.ADMIN && (
								<li>
									<Popconfirm
										title="Sync count？"
										placement="right"
										okText={ml('Yes', currentUser, allMultiLingualData)}
										cancelText={ml('No', currentUser, allMultiLingualData)}
										icon={<SyncOutlined style={{ color: COLORS.blue }} />}
										onConfirm={() => this.syncJobsCount()}
									>
										<Tooltip
											arrowPointAtCenter
											placement="topRight"
											title="Sync count"
											style={{ fontWeight: 1000 }}
										>
											<SyncOutlined
												spin={syncJobs}
												style={{
													color: COLORS.blue,
													fontSize: 16,
													fontWeight: 1000,
												}}
											/>
										</Tooltip>
									</Popconfirm>
								</li>
							)}
							{currentUser.displayAs === USER_ROLES.ADMIN &&
								this.state.windowDimensions?.width > 1365 && (
									<li>
										<i
											className="icon-export icon-link"
											style={{ cursor: 'pointer' }}
											onClick={() => exportPdF()}
										/>
									</li>
								)}
						</ul>
					</div>
				)}

				{/* extended user dashboard */}
				{currentUser.displayAs === USER_ROLES.EXTENDED_USER && (
					<div>
						<Row>
							<Col xs={24} md={8}>
								<RecentReferralCard referrals={extendedReferrals} />
							</Col>
							<Col xs={24} md={8}>
								<ExtendedTotalReferralCard
									totalReferralsCount={extendedReferrals.length}
								/>
							</Col>
							{!currentUser.company?.disableExtendedUserJobsView && (
								<Col xs={24} md={8}>
									<JobNetworkCard
										extendedCompanies={
											this.props.currentUser.extendedCompaniesData
										}
									/>
								</Col>
							)}
						</Row>
						{!currentUser.company?.disableExtendedUserJobsView &&
							allJobMatches.length > 0 && (
								<>
									<div>
										<h2
											style={{ display: 'inline', marginBottom: 0 }}
											className="page-heading"
										>
											{ml(`New Job Matches`, currentUser, allMultiLingualData)}
										</h2>
										<Link
											style={{ display: 'inline', marginLeft: '6px' }}
											to="/browsejobs"
											className={linkText}
										>
											View All Jobs
										</Link>
									</div>
									<Row style={{ marginBottom: 20 }}>
										{allJobMatches.length > 0 ? (
											<List
												dataSource={allJobMatches}
												grid={{
													gutter: 10,
													xs: 1,
													sm: 1,
													md: 2,
													lg: 2,
													xl: 3,
													xxl: 4,
												}}
												className={cardListStyle}
												pagination={{
													responsive: true,
													pageSize,
													showSizeChanger: false,
												}}
												renderItem={(jobMatch) => (
													<List.Item>
														<Col key={jobMatch?.id}>
															<JobMatchCard
																handleCareerProfile={this.handleCareerProfile}
																allMultiLingualData={allMultiLingualData}
																currentUser={this.props.currentUser}
																jobMatch={jobMatch}
																notInterested={this.notInterested}
																theme={theme}
															/>
														</Col>
													</List.Item>
												)}
											/>
										) : (
											<div>
												{whiteLabel ? (
													<p className={NoSmartReferrals}>
														<img
															src={errorImageURL}
															alt="error image"
															width="150px"
														/>
													</p>
												) : (
													<p className={NoSmartReferrals}>
														<img alt="erin-logo" width={150} src={Logo} />
													</p>
												)}
												{hasUploadedResume ? (
													<p className={NoSmartReferralsWithLogo}>
														{ml(
															`We don’t have any recommended positions at this time.`,
															currentUser,
															allMultiLingualData
														)}
														<br />
														{ml(
															'Check back soon!',
															currentUser,
															allMultiLingualData
														)}
													</p>
												) : (
													<p className={NoSmartReferralsWithLogo}>
														<Link className={LinkStyle} to="/career">
															{ml(
																'Import your resume',
																currentUser,
																allMultiLingualData
															)}{' '}
														</Link>
														{ml(
															`to be automatically matched with open positions.`,
															currentUser,
															allMultiLingualData
														)}
														<br />
													</p>
												)}
											</div>
										)}
									</Row>
								</>
							)}
					</div>
				)}

				{currentUser.displayAs === USER_ROLES.EMPLOYEE && (
					<>
						{this.props.company?.enableDashboardSearch && (
							<SearchBanner
								imageS3Data={this.props?.company?.dashboardSearchBG}
								helloText={ml(`Hello`, currentUser, allMultiLingualData)}
								firstName={currentUser?.firstName}
								companyId={this.props?.company?.id}
								callToAction={
									<p>
										Find jobs at{' '}
										<strong style={{ display: 'inline-block' }}>
											{this.props.company.name}
										</strong>
									</p>
								}
								placeholder="Search by location, job name and/or keywords"
								style={{}}
								{...this.props}
							/>
						)}

						<div className="row">
							{showJobAlerts && (
								<div className="col-md-4" style={{ order: jobAlertsOrder }}>
									<JobAlert />
								</div>
							)}
							{showReferSomeone && (
								<div className="col-md-4" style={{ order: referOrder }}>
									<SendReferral
										{...this.props}
										allMultiLingualData={allMultiLingualData}
										currentUser={currentUser}
										referralCardHeader={referralCardHeader}
										referralCardSubheader={referralCardSubheader}
										referralCardButton={referralCardButton}
										background={sendReferralColor}
										textColor={sendReferralTextColor}
									/>
								</div>
							)}

							{showInternal && (
								<div className="col-md-4" style={{ order: internalOrder }}>
									<ApplyInternally
										{...this.props}
										allMultiLingualData={allMultiLingualData}
										currentUser={currentUser}
										internalCardHeader={internalCardHeader}
										internalCardSubheader={internalCardSubheader}
										internalCardButton={internalCardButton}
										background={applyInternallyColor}
										textColor={applyInternallyTextColor}
									/>
								</div>
							)}

							{showYourReferrals && (
								<div className="col-md-4" style={{ order: yourReferralsOrder }}>
									<ReferralCard
										title={ml(
											'Your Referrals',
											currentUser,
											allMultiLingualData
										)}
										{...this.props}
										currentUser={currentUser}
										referralBonuses={referralBonuses}
										allMultiLingualData={allMultiLingualData}
										height="height-equal"
										onClick={() => {
											this.handleEmployees();
										}}
									/>
								</div>
							)}

							{showRanking && !hideReferralsRanking && (
								<div className="col-md-4" style={{ order: rankingOrder }}>
									<div className="d-card height-equal">
										<div className="d-card-head">
											<h4 className="d-title">
												{this.capitalizeIt(
													ml(
														'Referrals Made Ranking',
														currentUser,
														allMultiLingualData
													)
												)}
											</h4>
										</div>
										<RankingCard
											{...this.props}
											onClick={() => {
												this.handleReferrals();
											}}
										/>
									</div>
								</div>
							)}

							{isPointsInfoVisible && showPoints && (
								<div className="col-md-4" style={{ order: pointsOrder }}>
									<PointCard
										{...this.props}
										allMultiLingualData={allMultiLingualData}
										currentUser={currentUser}
										title={ml('Points', currentUser, allMultiLingualData)}
										WithReferralCount={this.state.WithReferralCount}
										NoReferralCount={this.state.NoReferralCount}
										JobTiers={
											this.state.TierWiseInfo ? this.state.TierWiseInfo : []
										}
										companyData={companyData}
										totalPoints={userDashboard?.points}
										userPointsRanking={userDashboard?.pointsRanking}
										onClick={() => {
											this.handleBrowseJobs();
										}}
									/>
								</div>
							)}

							{showAnnouncements && (
								<div className="col-md-4" style={{ order: announcementOrder }}>
									<AnnouncementCard
										title={ml(
											'ANNOUNCEMENTS',
											currentUser,
											allMultiLingualData
										)}
										{...this.props}
										currentUser={currentUser}
										referralBonuses={referralBonuses}
										allMultiLingualData={allMultiLingualData}
										announcement={announcement}
										onClick={() => {
											this.handleEmployees();
										}}
									/>
								</div>
							)}

							{showJobs && (
								<div className="col-md-4" style={{ order: jobsOrder }}>
									<JobCard
										title={ml('Jobs', currentUser, allMultiLingualData)}
										WithReferralCount={this.state.WithReferralCount}
										NoReferralCount={this.state.NoReferralCount}
										JobTiers={
											this.state.TierWiseInfo ? this.state.TierWiseInfo : []
										}
										{...this.props}
										companyData={companyData}
										onClick={() => {
											this.handleBrowseJobs();
										}}
									/>
								</div>
							)}

							{showLeaderboards && publicLeaderboard && (
								<div className="col-md-4" style={{ order: leaderboardOrder }}>
									<EmpLeaderBoard
										isNumbered
										height="height-equal"
										title={ml('Leaderboards', currentUser, allMultiLingualData)}
										listAllPath="/employees"
										entities={topReferrers
											.filter(
												(u) => u.role === 'employee' || u.role === 'admin'
											)
											.map((u) => ({
												name: `${u.firstName} ${u.lastName}`,
												score: u.totalReferrals,
												id: u.id,
												subCompanyId: u.subCompanyId,
											}))
											.sort((a, b) =>
												b.score > a.score
													? 1
													: a.score > b.score
														? -1
														: b.score == a.score
															? -1
															: 0
											)
											.map((u, index) => ({
												name: u.name,
												score: u.score,
												id: u.id,
												subCompanyId: u.subCompanyId,
												sno: index + 1,
											}))}
										entitiesPoints={topUsersByPoints
											.filter(
												(u) => u.role === 'employee' || u.role === 'admin'
											)
											.map((u) => ({
												name: `${u.firstName} ${u.lastName}`,
												score: u.points,
												id: u.id,
												subCompanyId: u.subCompanyId,
											}))
											.sort((a, b) =>
												b.score > a.score
													? 1
													: a.score > b.score
														? -1
														: b.score == a.score
															? -1
															: 0
											)
											.map((u, index) => ({
												name: u.name,
												score: u.score,
												id: u.id,
												subCompanyId: u.subCompanyId,
												sno: index + 1,
											}))}
										role={currentUser?.role}
										allMultiLingualData={allMultiLingualData}
										currentUser={currentUser}
									/>
								</div>
							)}

							{showJobsForYou && allJobMatches && allJobMatches.length > 0 && (
								<div className="col-md-4" style={{ order: jobsForYouOrder }}>
									<JobForYouCard
										handleCareerProfile={this.handleCareerProfile}
										allMultiLingualData={allMultiLingualData}
										currentUser={this.props.currentUser}
										allJobMatches={allJobMatches}
										notInterested={this.notInterested}
										allowSelfReferrals={
											this.props?.currentUser?.company?.allowSelfReferrals
										}
										allowSelfReferralsInternalLink={
											this.props?.currentUser?.company
												?.allowSelfReferralsInternalLink
										}
										theme={theme}
										height="height-equal"
										onCreateReferral={this.props.onCreateReferral}
									/>
								</div>
							)}

							{showGeneralReferrals && (
								<div
									className="col-md-4"
									style={{ order: generalReferralsOrder }}
								>
									<div
										className="e-card-wrap"
										style={{ backgroundColor: generalReferralColor }}
									>
										<div
											className="e-card-title"
											style={{ color: generalReferralTextColor }}
										>
											{generalCardHeader}
										</div>
										<div
											className="e-card-subtitle"
											style={{ color: generalReferralTextColor }}
										>
											{generalCardSubheader}
										</div>
										<GeneralReferral
											{...this.props}
											allMultiLingualData={allMultiLingualData}
											generalCardButton={generalCardButton}
											currentUser={currentUser}
											customGeneralModal={customGeneralModal}
											companyData={companyData}
										/>
									</div>
								</div>
							)}

							{showReferralLead && (
								<div className="col-md-4" style={{ order: referralLeadOrder }}>
									<div
										className="e-card-wrap"
										style={{ backgroundColor: referralLeadColor }}
									>
										<div
											className="e-card-title"
											style={{ color: referralLeadTextColor }}
										>
											{leadCardHeader}
										</div>
										<div
											className="e-card-subtitle"
											style={{ color: referralLeadTextColor }}
										>
											{leadCardSubheader}
										</div>
										<Button
											type="button"
											className="c-btn-white"
											onClick={() =>
												this.setState({
													visible: true,
												})
											}
										>
											{leadCardButton}
										</Button>
									</div>
								</div>
							)}
							{showHotJobs && (
								<div className="col-md-4" style={{ order: hotJobsOrder }}>
									<HotJobs
										hotSearchedJobs={hotSearchedJobs}
										isLoading={this.state.isLoading}
										currentUser={currentUser}
										allMultiLingualData={allMultiLingualData}
									/>
								</div>
							)}
						</div>
					</>
				)}

				{currentUser.displayAs === USER_ROLES.EMPLOYEE && (
					<div style={{ order: policyOrder }}>
						{showPolicy && (
							<div className="row">
								<div className="col-12">
									{this.props?.currentUser?.company
										?.dashboardReferralPolicyText &&
									this.props?.currentUser?.company
										?.dashboardReferralPolicyText !== '<p><br></p>' ? (
										<ReferralPolicyCard {...this.props} minHeight="auto" />
									) : null}
								</div>
							</div>
						)}
					</div>
				)}
				{/* user dashboard */}
				{this.renderAdminAnalytics()}
				<div className="row">
					{currentUser.displayAs === USER_ROLES.ADMIN && (
						<>
							<div className="statistics-title">
								<h3>STATISTICS</h3>
							</div>
							<div className="col-md-4 registered-employee">
								<EmployeesCard
									title={ml(
										'Registered Employees',
										currentUser,
										allMultiLingualData
									)}
									// EmployeesInfo.total is actually the active employee total
									totalEmployeeCount={
										employeesInfo?.total + employeesInfo?.InactiveEmployee ?? 0
									}
									employeesWithoutReferralCount={
										employeesInfo?.withoutReferralEmployee ?? 0
									}
									employeesWithReferralsCount={
										employeesInfo?.withReferralEmployee ?? 0
									}
									newEmployeesThisMonthCount={
										employeesInfo?.newEmployeeThisMonth ?? 0
									}
									inactiveEmployeesCount={employeesInfo?.InactiveEmployee ?? 0}
									{...this.props}
									onClick={() => {
										this.handleEmployees();
									}}
								/>
							</div>
							<div className="col-md-4 referrals">
								<BonusReferralCard
									title={ml('Referrals', currentUser, allMultiLingualData)}
									statusText={{
										accepted: ml('Accepted', currentUser, allMultiLingualData),
										hired: ml('Hired', currentUser, allMultiLingualData),
										interviewing: ml(
											referralStatus,
											currentUser,
											allMultiLingualData
										),
										notHired: ml('Not Hired', currentUser, allMultiLingualData),
										referred: ml('Referred', currentUser, allMultiLingualData),
										noresponse: ml(
											'No Response',
											currentUser,
											allMultiLingualData
										),
										inactive: ml('Inactive', currentUser, allMultiLingualData),
										transferred: ml(
											'Transferred',
											currentUser,
											allMultiLingualData
										),
										ineligible: ml(
											'Ineligible',
											currentUser,
											allMultiLingualData
										),
									}}
									statusWiseReferrals={statusWiseReferrals}
									tierWise={tierWiseReferrals}
									totalReferralsCount={totalReferralsCount}
									{...this.props}
									referralContact={referralContact}
									customStages={stages}
									referralStatus={referralStatus}
								/>
							</div>
							<div className="col-md-4 jobs">
								<JobCard
									title={ml('Jobs', currentUser, allMultiLingualData)}
									WithReferralCount={WithReferralCount ? WithReferralCount : 0}
									NoReferralCount={NoReferralCount ? NoReferralCount : 0}
									JobTiers={TierWiseInfo ? TierWiseInfo : []}
									{...this.props}
									companyData={companyData}
									openPositionsCount={openPositionsCount}
									onClick={() => {
										this.handleBrowseJobs();
									}}
								/>
							</div>
							<div className="col-md-4 hired">
								<BonusesCard
									{...this.props}
									title={ml('Hired', currentUser, allMultiLingualData)}
									earned={bonusesData.earned ? bonusesData.earned : 0}
									inWaitingPeriod={
										bonusesData.inWaitingPrcess
											? bonusesData.inWaitingPrcess
											: 0
									}
									totalBonusesAvailable={
										bonusesData.totalAvailable ? bonusesData.totalAvailable : 0
									}
									tierWise={bonusesData.tierWise ? bonusesData.tierWise : []}
									languageCode={languageCode}
									currencyCode={currencyCode}
									onClick={() => {
										this.handleBonus();
									}}
								/>
							</div>
							<div className="col-md-4 referrals-made">
								<ReferralCard
									title={ml('Referrals Made', currentUser, allMultiLingualData)}
									referralTitle={ml(
										'Referrals Made',
										currentUser,
										allMultiLingualData
									)}
									{...this.props}
									allMultiLingualData={allMultiLingualData}
									referralsThisYearCount={referralsThisYearCount}
									height="height-equal"
									onClick={() => {
										this.handleReferrals();
									}}
								/>
							</div>
							<div className="col-md-4 accepted-referrals">
								<ReferralCard
									{...this.props}
									title={ml(
										'Accepted Referrals',
										currentUser,
										allMultiLingualData
									)}
									acceptedReferralsTitle={ml(
										'Accepted Referrals',
										currentUser,
										allMultiLingualData
									)}
									height="height-equal"
									onClick={() => {
										this.handleReferrals();
									}}
								/>
							</div>
							<div className="col-md-4 hired top-referrers">
								<LeaderBoard
									isNumbered
									title={ml('Top Referrers', currentUser, allMultiLingualData)}
									listAllPath="/employees"
									entities={topReferrers
										.filter((u) => u.role !== 'superAdmin')
										.map((u) => ({
											name: `${u.firstName} ${u.lastName}`,
											score: u.totalReferrals,
										}))
										.sort((a, b) =>
											b.score > a.score ? 1 : a.score > b.score ? -1 : 0
										)
										.slice(0, 5)}
									allMultiLingualData={allMultiLingualData}
									currentUser={currentUser}
									onClick={() => {
										this.handleEmployees();
									}}
								/>
							</div>
							{(this.state?.bonuses || []).length > 0 && (
								<div className="col-md-4">
									<BonusCard
										allMultiLingualData={allMultiLingualData}
										currentUser={currentUser}
										bonuses={bonuses}
										title={ml('Jobs', currentUser, allMultiLingualData)}
										{...this.props}
										companyData={companyData}
										onClick={() => this.handleBrowseJobs}
									/>
								</div>
							)}
							<div className="col-md-4 top-departments">
								<LeaderBoard
									isNumbered
									title={ml(
										'Top Departments',
										currentUser,
										allMultiLingualData
									)}
									entities={departmentsToUse
										.sort((a, b) => b.totalUsers - a.totalUsers)
										.map((department) => ({
											name: department.name,
											score:
												`${department.totalUsers} ` +
												ml('Users', currentUser, allMultiLingualData),
										}))
										.slice(0, 5)}
									allMultiLingualData={allMultiLingualData}
									currentUser={currentUser}
									onClick={() => {
										this.handleEmployees();
									}}
								/>
							</div>
							{publicLeaderboard && (
								<div className="col-md-4">
									<EmpLeaderBoard
										isNumbered
										title={ml('Leaderboards', currentUser, allMultiLingualData)}
										listAllPath="/employees"
										entities={topReferrers
											.filter(
												(u) => u.role === 'employee' || u.role === 'admin'
											)
											.map((u) => ({
												name: `${u.firstName} ${u.lastName}`,
												score: u.totalReferrals,
												id: u.id,
												subCompanyId: u.subCompanyId,
											}))
											.sort((a, b) =>
												b.score > a.score
													? 1
													: a.score > b.score
														? -1
														: b.score == a.score
															? -1
															: 0
											)
											.map((u, index) => ({
												name: u.name,
												score: u.score,
												id: u.id,
												subCompanyId: u.subCompanyId,
												sno: index + 1,
											}))}
										entitiesPoints={topUsersByPoints
											.filter(
												(u) => u.role === 'employee' || u.role === 'admin'
											)
											.map((u) => ({
												name: `${u.firstName} ${u.lastName}`,
												score: u.points,
												id: u.id,
												subCompanyId: u.subCompanyId,
											}))

											.sort((a, b) =>
												b.score > a.score
													? 1
													: a.score > b.score
														? -1
														: b.score == a.score
															? -1
															: 0
											)
											.map((u, index) => ({
												name: u.name,
												score: u.score,
												id: u.id,
												subCompanyId: u.subCompanyId,
												sno: index + 1,
											}))}
										allMultiLingualData={allMultiLingualData}
										currentUser={currentUser}
									/>
								</div>
							)}
						</>
					)}

					{currentUser.displayAs === USER_ROLES.MANAGER && (
						<div className="col-md-4">
							<ReferralCard
								title={ml('Your Referrals', currentUser, allMultiLingualData)}
								{...this.props}
								currentUser={currentUser}
								referralBonuses={referralBonuses}
								allMultiLingualData={allMultiLingualData}
								height="height-equal"
								onClick={() => {
									this.handleEmployees();
								}}
							/>
						</div>
					)}
					{currentUser.displayAs === USER_ROLES.MANAGER &&
						!hideReferralsRanking && (
							<div className="col-md-4">
								<div className="d-card height-equal">
									<div className="d-card-head">
										<h4 className="d-title">
											{this.capitalizeIt(
												ml(
													'Referrals Made Ranking',
													currentUser,
													allMultiLingualData
												)
											)}
										</h4>
									</div>
									<RankingCard
										{...this.props}
										onClick={() => {
											this.handleReferrals();
										}}
									/>
								</div>
							</div>
						)}
					{hideReferralsRanking &&
						currentUser.displayAs === USER_ROLES.MANAGER && (
							<div className="col-md-4">
								{isPointsInfoVisible ? (
									<PointCard
										{...this.props}
										allMultiLingualData={allMultiLingualData}
										currentUser={currentUser}
										title={ml('Points', currentUser, allMultiLingualData)}
										WithReferralCount={this.state.WithReferralCount}
										NoReferralCount={this.state.NoReferralCount}
										JobTiers={
											this.state.TierWiseInfo ? this.state.TierWiseInfo : []
										}
										companyData={companyData}
										totalPoints={userDashboard?.points}
										userPointsRanking={userDashboard?.pointsRanking}
										onClick={() => {
											this.handleBrowseJobs();
										}}
									/>
								) : (
									<JobCard
										title={ml('Jobs', currentUser, allMultiLingualData)}
										WithReferralCount={this.state.WithReferralCount}
										NoReferralCount={this.state.NoReferralCount}
										JobTiers={
											this.state.TierWiseInfo ? this.state.TierWiseInfo : []
										}
										{...this.props}
										companyData={companyData}
										onClick={() => {
											this.handleBrowseJobs();
										}}
									/>
								)}
							</div>
						)}

					{!hideReferralsRanking &&
						currentUser.displayAs === USER_ROLES.MANAGER && (
							<>
								{isPointsInfoVisible ? (
									<div className="col-md-4">
										<PointCard
											{...this.props}
											allMultiLingualData={allMultiLingualData}
											currentUser={currentUser}
											title={ml('Points', currentUser, allMultiLingualData)}
											WithReferralCount={this.state.WithReferralCount}
											NoReferralCount={this.state.NoReferralCount}
											JobTiers={
												this.state.TierWiseInfo ? this.state.TierWiseInfo : []
											}
											companyData={companyData}
											totalPoints={userDashboard?.points}
											userPointsRanking={userDashboard?.pointsRanking}
											onClick={() => {
												this.handleBrowseJobs();
											}}
										/>
									</div>
								) : (
									<div className="col-md-4">
										<JobCard
											title={ml('Jobs', currentUser, allMultiLingualData)}
											WithReferralCount={this.state.WithReferralCount}
											NoReferralCount={this.state.NoReferralCount}
											JobTiers={
												this.state.TierWiseInfo ? this.state.TierWiseInfo : []
											}
											{...this.props}
											companyData={companyData}
											onClick={() => {
												this.handleBrowseJobs();
											}}
										/>
									</div>
								)}
							</>
						)}
				</div>
				{currentUser.displayAs === USER_ROLES.MANAGER && (
					<div style={{ order: policyOrder }}>
						{showPolicy && (
							<div className="row">
								<div className="col-12">
									{this.props?.currentUser?.company
										?.dashboardReferralPolicyText &&
									this.props?.currentUser?.company
										?.dashboardReferralPolicyText !== '<p><br></p>' ? (
										<ReferralPolicyCard {...this.props} minHeight="auto" />
									) : null}
								</div>
							</div>
						)}
					</div>
				)}

				{currentUser.displayAs === USER_ROLES.ADMIN &&
					(!publicLeaderboard || !showLeaderboards) && (
						<Row>
							<Col xs={24} md={24}>
								{this.props?.currentUser?.company
									?.dashboardReferralPolicyText &&
								this.props?.currentUser?.company
									?.dashboardReferralPolicyText !== '<p><br></p>' ? (
									<ReferralPolicyCard {...this.props} minHeight="auto" />
								) : null}
							</Col>
						</Row>
					)}
				{popupTitle && popupTitleContent && (
					<Modal
						destroyOnClose
						open={isLoginPopupVisible}
						width="500px"
						footer={null}
						closable={false}
					>
						{this.renderFirstLoginPopupContent()}
					</Modal>
				)}
				{onboardingStatus !== 'complete' &&
					this.props?.company?.accountType === 'partner' && (
						<Modal
							destroyOnClose
							closable={false}
							open={this.state.onboardingStatus !== 'complete'}
							width="1000px"
							footer={null}
							style={{
								backgroundColor: COLORS.white,
								borderRadius: 20,
								border: 'none',
								overflow: 'auto',
								padding: 0,
							}}
							bodyStyle={{ padding: 0, border: 'none' }}
							onCancel={this.handleTutorialPopup}
						>
							<Onboarding
								currentUser={currentUser}
								status={this.state?.onboardingStatus}
								{...this.props}
							/>
						</Modal>
					)}

				<OnDeckReferralModal
					currentUser={this.props.currentUser}
					visible={this.state.visible}
					handleCancel={this.handleCancel}
					jobData={this.props.job}
					referSomeoneText={ml(
						'Refer Someone',
						currentUser,
						allMultiLingualData
					)}
					doYouKnowText={ml(
						'Do you know someone who would thrive at',
						currentUser,
						allMultiLingualData
					)}
					submitThemText={ml(
						'Submit them to be considered for new opportunities.',
						currentUser,
						allMultiLingualData
					)}
					enterReferralText={ml(
						"Enter a referral and we'll send them information to apply.",
						currentUser,
						allMultiLingualData
					)}
					enterReferralInformationText={ml(
						'Enter Referral Information',
						currentUser,
						allMultiLingualData
					)}
					firstNameText={ml('First Name', currentUser, allMultiLingualData)}
					lastNameText={ml('Last Name', currentUser, allMultiLingualData)}
					emailText={ml('Email Address', currentUser, allMultiLingualData)}
					textPlaceHolderText={ml('Text', currentUser, allMultiLingualData)}
					orText={ml('or', currentUser, allMultiLingualData)}
					clickHereText={ml('click here', currentUser, allMultiLingualData)}
					toAddExistingContactText={ml(
						'to add an existing contact',
						currentUser,
						allMultiLingualData
					)}
					includeMessageToContactText={ml(
						'Include a message to your contact',
						currentUser,
						allMultiLingualData
					)}
					optionalText={`(${ml('optional', currentUser, allMultiLingualData)})`}
					requiredText={`(${ml('required', currentUser, allMultiLingualData)})`}
					personalizeMessageText={ml(
						'Personalize the message to your referral',
						currentUser,
						allMultiLingualData
					)}
					messageHiringContactText={ml(
						'Message the Hiring Contact',
						currentUser,
						allMultiLingualData
					)}
					howKnowThemText={ml(
						'How do you know them, why are they a good fit, etc.',
						currentUser,
						allMultiLingualData
					)}
					clickHereResumeText={ml(
						'Click here',
						currentUser,
						allMultiLingualData
					)}
					attachResumeText={ml(
						'to attach a resume',
						currentUser,
						allMultiLingualData
					)}
					submitReferralText={ml(
						'Submit Referral',
						currentUser,
						allMultiLingualData
					)}
					allMultiLingualData={this.props?.allMultiLingualData}
					onDeckContacts={this.props.onDeckContacts}
					onCreateContact={this.props.ImportedCreateContact}
					onUpdateContact={this.props.onUpdateContact}
				/>
			</main>
		);
	}
}

export default withApollo(DashboardComponent);
