import gql from 'graphql-tag';
import get from 'lodash/get';
import { compose, graphql } from 'react-apollo';
import uuid from 'uuid/v4';
import {
	createBonus,
	deleteBonus,
	queryBonusByReferralIdIndex,
	updateBonus,
} from '../../graphql/custom';

export const withQueryBonusByReferralId = (Component) => {
	return compose(
		graphql(gql(queryBonusByReferralIdIndex), {
			options(props) {
				return {
					context: {
						headers: {
							'x-frame-options': 'deny', // This header will reach the server
						},
					},
					variables: {
						referralId: get(props, 'referral.referralId'),
					},
					fetchPolicy: 'cache-and-network',
				};
			},
			props(response, previous) {
				const referralBonuses = get(
					response,
					['data', 'queryBonusByReferralIdIndex', 'items'],
					get(previous, 'referralBonuses', [])
				);

				const referralBonusNextToken = get(
					response,
					['data', 'queryBonusByReferralIdIndex', 'nextToken'],
					null
				);
				const onFetchMoreReferralBonuses = makeOnFetchMoreReferralBonuses(
					response.data.fetchMore,
					referralBonusNextToken
				);

				return {
					referralBonuses,
					onFetchMoreReferralBonuses,
					referralBonusNextToken,
				};
			},
		}),
		graphql(gql(createBonus), {
			props: (props) => ({
				onCreateBonus(input) {
					const variables = {
						...input,
						id: uuid(),
					};
					props.mutate({
						context: {
							headers: {
								'x-frame-options': 'deny', // This header will reach the server
							},
						},
						variables,
					});
				},
			}),
		}),
		graphql(gql(updateBonus), {
			props: (props) => ({
				onUpdateBonus(input) {
					const optimisticResponseData = {
						...input.input,
						__typename: 'Bonus',
					};
					props.mutate({
						context: {
							headers: {
								'x-frame-options': 'deny', // This header will reach the server
							},
						},
						variables: input,
						optimisticResponse: {
							__typeName: 'Mutation',
							updateBonus: {
								__typeName: 'updateBonus',
								...optimisticResponseData,
							},
						},
						update(proxy, query) {
							try {
								const data = proxy.readQuery({
									query: gql(queryBonusByReferralIdIndex),
									context: {
										headers: {
											'x-frame-options': 'deny', // This header will reach the server
										},
									},
									variables: {
										companyId: get(
											props,
											['ownProps', 'currentUser', 'companyId'],
											[]
										),
									},
								});
								const bonuses = get(data, 'queryBonusByCompanyIdIndex.items');

								proxy.writeQuery({
									query: gql(queryBonusByReferralIdIndex),
									context: {
										headers: {
											'x-frame-options': 'deny', // This header will reach the server
										},
									},
									variables: {
										companyId: get(
											props,
											['ownProps', 'currentUser', 'companyId'],
											[]
										),
									},
									bonuses,
									data,
								});
							} catch (error) {
								console.log(error);
							}
						},
					});
				},
			}),
		}),

		graphql(gql(deleteBonus), {
			props: (props) => ({
				onDeleteBonus(input) {
					const variables = {
						...input,
					};
					props.mutate({
						variables,
						refetchQueries: [
							{
								query: gql(queryBonusByReferralIdIndex),
								context: {
									headers: {
										'x-frame-options': 'deny', // This header will reach the server
									},
								},
								variables: {
									referralId: get(props, 'ownProps.referral.referralId'),
								},
							},
						],
					});
				},
			}),
		})
	)(Component);
};

const makeOnFetchMoreReferralBonuses = (fetchMore, nextToken) => {
	if (!nextToken) {
		return null;
	}

	return () => {
		fetchMore({
			variables: { nextToken },
			updateQuery(previous, { fetchMoreResult }) {
				if (!fetchMoreResult) {
					return previous;
				}

				return {
					...previous,
					loading: false,
					queryBonusByReferralIdIndex: {
						...previous.queryBonusByReferralIdIndex,
						...fetchMoreResult.queryBonusByReferralIdIndex,
						items: [
							...previous.queryBonusByReferralIdIndex.items,
							...fetchMoreResult.queryBonusByReferralIdIndex.items,
						],
					},
				};
			},
		});
	};
};
