import { graphql } from 'react-apollo';
import { GetTopReferrers } from '../../graphql/custom/dashboard';

export const withTopReferrers = (Component) => {
	return graphql(GetTopReferrers, {
		options: (props) => ({
			context: {
				headers: {
					'x-frame-options': 'deny', // This header will reach the server
				},
			},
			variables: { companyId: props.currentUser.companyId },
			fetchPolicy: 'cache-and-network',
		}),
		props(props) {
			return { topReferrers: props.data.getTopReferrers || [] };
		},
	})(Component);
};
