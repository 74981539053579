import { css } from 'emotion';
import { COLORS } from '../../styles/colors';

const breakpoints = [576, 767, 991, 1200];

const mq = breakpoints.map((bp) => `@media (max-width: ${bp}px)`);

export const EmployeeName = css({
	marginBottom: '4px !important',
	fontSize: 28,
	lineSpacing: 0,
});

export const EmployeeEmail = css({
	fontSize: 16,
	fontWeight: '600 !important',

	[mq[0]]: {
		display: 'block',
	},
});

export const Heading = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 600,
	color: '#444444',
	padding: 0,
	marginBottom: 0,
});

export const SubHeading = css({
	fontFamily: '"Open Sans", sans-serif',
	fontSize: 16,
	fontWeight: 800,
	color: '#8d99a3',

	[mq[0]]: {
		display: 'block',
	},
});

export const Avatar = css({
	height: 70,
	width: 70,
	marginRight: 20,
});

export const SectionTitle = css({
	fontSize: 18,
});

export const WorkInfoContainer = css({
	marginBottom: 20,
});

export const SocialContainer = css({ marginTop: 5 });

export const BioContainer = css({
	marginBottom: 16,
});

export const BioContainerExt = css({
	marginBottom: 30,
	marginTop: 20,
});

export const JobInfo = css({
	fontSize: 14,
	margin: 0,
	padding: 0,
});

export const BlockContainer = css({
	width: '100%',
});

export const NoPicture = css({
	height: 65,
	width: 65,
	backgroundColor: COLORS.lightGray,
	'& h3': {
		color: 'white',
		paddingTop: 7,
		fontSize: 28,
		textAlign: 'center',
		lineHeight: '50px',
	},
});

export const BioText = css({
	color: '#404447',
	fontFamily: 'Open Sans',
	fontSize: 16,
	lineHeight: 1.5,
});

export const SocialIcon = css({
	height: '2em',
	width: '2em',
});

export const SocialRow = css({
	display: 'inline-flex',
	alignItems: 'center',
	lineHeight: 1,
});

export const ReferBtn = css({
	fontSize: 16,
	color: COLORS.darkGray,
	fontFamily: 'Open Sans',
});

export const ReferBtnPending = css({
	fontSize: 16,
	color: COLORS.gray,
	fontFamily: 'Open Sans',
});

export const ReferBtnError = css({
	fontSize: 16,
	color: COLORS.blue,
	fontFamily: 'Open Sans',
});

export const JobBtn = css({
	fontSize: 16,
	marginRight: 5,
	fontFamily: 'Open Sans',
});

export const LineSplit = css({
	borderBottom: '1px #cccccc solid',
});

export const contactDetailWrap = css({
	display: 'flex',
	[mq[0]]: {
		flexDirection: 'column',
	},
});

export const avatarWrap = css({
	marginRight: 20,
	[mq[0]]: {
		marginRight: 4,
		marginBottom: 16,
	},
});

export const addGeneral = css({
	[mq[0]]: {
		flexDirection: 'column',
	},
});

export const LinkStyles = css({
	color: COLORS.hyperLink,
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: '600 !important',
	cursor: 'pointer',
});

export const EditLinkStyles = css({
	fontFamily: '"Open Sans", sans-serif',
	color: COLORS.blue,
	height: 30,
	border: 'none',
	boxShadow: 'none',
	alignItems: 'center',
});

export const addNoteBlock = css({
	display: 'flex',
	justifyContent: 'flex-end',
});

export const HeaderStyle = css({
	fontSize: '1.2rem',
	fontWeight: '600 !important',
	fontFamily: 'Open Sans,sans-serif',
	color: '#444444 !important',
});

export const CheckBoxStyles = css({
	margin: '0 auto',
	' & span': {
		fontSize: '16px !important',
		fontWeight: 600,
	},
	'& .ant-checkbox-checked .ant-checkbox-inner': {
		borderColor: COLORS.green,
		backgroundColor: COLORS.green,
	},
});
