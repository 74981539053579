export const queryContactsByUserIdIndex = `query QueryContactsByUserIdIndex(
  $userId: ID!
  $first: Int
  $after: String
) {
  queryContactsByUserIdIndex(
    userId: $userId
    first: $first
    after: $after
  ) {
    items {
      id
      userId
      firstName
      lastName
      emailAddress
      extendedUserId
      onDeckStatus
      phoneNumber
      referrals {
        id
        contactId
      }
      importMethod
      inviteStatus
      fullContactData
      dateCreated
      questionsData
      generalReferralAcceptanceId
      contactResume {
        bucket
        region
        key
      }
      companyId
      company {
        pointsSettings
      }
    }
    nextToken
  }
}
`;
