class Store {
	constructor(t) {
		const n = this;
		(n.actions = {}),
			(n.mutations = {}),
			(n.state = {}),
			(n.status = 'resting'),
			(n.callbacks = []),
			t.hasOwnProperty('actions') && (n.actions = t.actions),
			t.hasOwnProperty('mutations') && (n.mutations = t.mutations),
			(n.state = new Proxy(t.initialState || {}, {
				set(t, o, s) {
					return (
						(t[o] = s), n.processCallbacks(n.state), (n.status = 'resting'), !0
					);
				},
			}));
	}

	dispatch(t, n) {
		const o = this;
		return typeof o.actions[t] === 'function'
			? ((o.status = 'action'), o.actions[t](o, n))
			: (console.error(`Action "${t}" doesn't exist.`), !1);
	}

	commit(t, n) {
		const o = this;
		if (typeof o.mutations[t] !== 'function')
			return console.error(`Mutation "${t}" doesn't exist`), !1;
		o.status = 'mutation';
		const s = o.mutations[t](o.state, n);
		return (o.state = s), !0;
	}

	processCallbacks(t) {
		const n = this;
		return n.callbacks.length > 0 && (n.callbacks.forEach((n) => n(t)), !0);
	}

	subscribe(t) {
		const n = this;
		return typeof t === 'function'
			? (n.callbacks.push(t), !0)
			: (console.error(
					'You can only subscribe to Store changes with a valid function'
				),
				!1);
	}
}
export default Store;
