export const queryJobsByCompanyIdStatusIndex = `query QueryJobsByCompanyIdStatusIndex(
    $companyId: ID!
    $first: Int
    $after: String
    $status: String
  ) {
    queryJobsByCompanyIdStatusIndex(
      companyId: $companyId
      first: $first
      after: $after
      status: $status
    ) {
        items {
            id
            companyId
            company {
              id
              name
            }
            departmentId
            department {
              id
              name
            }
            referrals {
              id
              contactId
              contact {
                id
                emailAddress
                userId
              }
              status
            }
            title
            referralBonus
            description
            publicLink
            location
            shares
            views
            status
            dateCreated
            lat
            lng
            externalJobId
            subCompanyId
            subCompany {
              id
              name
            }
          }
          nextToken
    }
  }
  `;
