import { css } from 'emotion';
import { COLORS } from '../../../_shared/styles/colors';

export const headerClass = css({
	fontSize: 15,
	textTransform: 'uppercase',
	letterSpacing: 1,
	fontWeight: 300,
	color: `${COLORS.heading} !important`,
	marginLeft: 5,
});

export const addJobMainContainer = css({
	justifyContent: 'center',
	alignItems: 'center',
});

export const jobCardMain = css({
	height: 315,
	maxWidth: 236,
	minWidth: 236,
});

export const topContainer = css({
	height: 40,
});

export const jobInfoContainer = css({
	height: 'auto',
});

export const bonusContainer = css({
	height: 'auto',
	marginRight: 15,
});

export const jobDetailRow = css({
	marginLeft: 5,
	marginTop: -6,
});

export const middleContainer = css({
	height: 138,
	margin: 6,
	overflowY: 'auto',
});

export const divider = css({
	height: 1,
	marginTop: 20,
	backgroundColor: COLORS.subHeading,
	opacity: 0.4,
	width: '80%',
	margin: 'auto',
});

export const bottomContainer = css({
	height: 45,
});

export const bigNumbers = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 800,
	color: COLORS.black,
	fontSize: '2em',
});

export const numberSubtitles = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 400,
	color: COLORS.black,
	fontSize: '0.85em',
});

export const bonus = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 400,
	color: COLORS.black,
	fontSize: '1em',
	marginLeft: 5,
});

export const bonusAmount = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 700,
	color: COLORS.green,
	fontSize: '1em',
	marginLeft: 5,
});

export const jobTitleText = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 600,
	color: COLORS.blue,
	fontSize: '1em',
	paddingTop: 5,
	marginLeft: 5,
});

export const jobDescText = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 400,
	color: COLORS.black,
	fontSize: '0.80em',
	paddingTop: 5,
});

export const jobMatch = css({
	fontSize: 10,
	marginTop: 10,
	marginLeft: 5,
	width: '100%',
});

export const jobMatchNumber = css({
	color: COLORS.blue,
	fontWeight: 500,
});

export const locationAndDepartment = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 300,
	color: COLORS.heading,
	fontSize: '0.75em',
	marginLeft: '5px',
	marginRight: '10px',
});

export const middleColumn = css({
	textAlign: 'center',
	width: 60,
});

export const matchBox = css({
	textAlign: 'center',
	width: 60,
	backgroundColor: COLORS.lightGray2,
});

export const smallIcons = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 300,
	color: COLORS.subHeading,
	fontSize: '0.85em',
	marginLeft: 21,
	opacity: 0.7,
});

export const jobIcons = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 300,
	color: COLORS.subHeading,
	fontSize: '0.85em',
	opacity: 0.7,
});

export const bottomSectionText = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 300,
	color: COLORS.heading,
	fontSize: '0.85em',
	marginLeft: 5,
	opacity: 0.7,
});

export const bottomSectionValue = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 800,
	color: COLORS.black,
	fontSize: '0.85em',
	float: 'right',
	marginRight: 21,
});
