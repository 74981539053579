import gql from 'graphql-tag';
import get from 'lodash/get';
import { compose, graphql } from 'react-apollo';
import uuid from 'uuid/v4';
import {
	createBonus,
	deleteBonus,
	queryBonusByCompanyIdIndex,
	updateBonus,
} from '../../graphql/custom/bonuses';
import { getReferralNew } from '../../graphql/custom/referrals';

export const withCreateBonus = (Component) => {
	return compose(
		graphql(gql(createBonus), {
			props: (props) => ({
				onCreateBonus(input) {
					const optimisticResponseData = {
						...input.input,
						__typename: 'Bonus',
					};
					const variables = {
						...input,
						id: uuid(),
					};

					props.mutate({
						context: {
							headers: {
								'x-frame-options': 'deny', // This header will reach the server
							},
						},
						variables,
						optimisticResponse: {
							__typeName: 'Mutation',
							updateBonus: {
								__typeName: 'updateBonus',
								...optimisticResponseData,
							},
						},

						refetchQueries: [
							{
								query: gql(getReferralNew),
								context: {
									headers: {
										'x-frame-options': 'deny', // This header will reach the server
									},
								},
								variables: {
									id: get(input, 'input.id'),
								},
							},
						],
					});
				},
			}),
		}),
		graphql(gql(updateBonus), {
			props: (props) => ({
				onUpdateBonus(input) {
					const optimisticResponseData = {
						...input.input,
						__typename: 'Bonus',
					};
					props.mutate({
						context: {
							headers: {
								'x-frame-options': 'deny', // This header will reach the server
							},
						},
						variables: input,
						optimisticResponse: {
							__typeName: 'Mutation',
							updateBonus: {
								__typeName: 'updateBonus',
								...optimisticResponseData,
							},
						},
						update(proxy, query) {
							try {
								const data = proxy.readQuery({
									query: gql(queryBonusByCompanyIdIndex),
									context: {
										headers: {
											'x-frame-options': 'deny', // This header will reach the server
										},
									},
									variables: {
										companyId: get(
											props,
											['ownProps', 'currentUser', 'companyId'],
											[]
										),
									},
								});
								const bonuses = get(data, 'queryBonusByCompanyIdIndex.items');

								proxy.writeQuery({
									query: gql(queryBonusByCompanyIdIndex),
									context: {
										headers: {
											'x-frame-options': 'deny', // This header will reach the server
										},
									},
									variables: {
										companyId: get(
											props,
											['ownProps', 'currentUser', 'companyId'],
											[]
										),
									},
									bonuses,
									data,
								});
							} catch (error) {
								console.log(error);
							}
						},
					});
				},
			}),
		}),

		graphql(gql(deleteBonus), {
			props: (props) => ({
				onDeleteBonus(input) {
					const optimisticResponseData = {
						...input.input,
						__typename: 'Bonus',
					};
					const variables = {
						...input,
					};
					props.mutate({
						variables,
						context: {
							headers: {
								'x-frame-options': 'deny', // This header will reach the server
							},
						},
						optimisticResponse: {
							__typeName: 'Mutation',
							updateBonus: {
								__typeName: 'updateBonus',
								...optimisticResponseData,
							},
						},
						refetchQueries: [
							{
								query: gql(getReferralNew),
								context: {
									headers: {
										'x-frame-options': 'deny', // This header will reach the server
									},
								},
								variables: {
									id: get(input, 'input.id'),
								},
							},
						],
					});
				},
			}),
		})
	)(Component);
};
