import { CheckCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Modal, Popconfirm, Row, message } from 'antd';
import gql from 'graphql-tag';
import _ from 'lodash';
import get from 'lodash/get';
import { Component } from 'react';
import { withApollo } from 'react-apollo';
import ReactDOM from 'react-dom';
import ProgressButton from 'react-progress-button';
import { configMode } from 'src/_shared/api/';
import { updateContact } from 'src/_shared/api/graphql/custom/contacts/';
import { GetJob } from 'src/_shared/api/graphql/custom/jobs/';
import { queryReferralQuestionsByCompanyId } from 'src/_shared/api/graphql/custom/referral-questions/';
import { lambda, ml, uploadToS3Multipart } from 'src/_shared/services/utils.js';
import FormElements from 'src/form-builder/FormElements.jsx';
import uuid from 'uuid/v4';
import { COLORS } from '../../styles/colors';
import WebIcon from '../WebIconComponent.jsx';
import * as ModalStyles from './inviteToApplySendStyles.js';
import {
	Department,
	checkCircle,
	fileWrap,
	linkStyles,
	locationStyles,
	reqNumber as requestNumber,
	reqNumberContainer as requestNumberContainer,
} from './inviteToApplySendStyles.js';

class InviteToApplyButtonWrapper extends Component {
	constructor(props) {
		super(props);
		this.state = {
			visible: false,
			visibleApplied: false,
			isSubmitting: false,
			showCompleteModal: false,
			newContact: get(props, 'newContact', false),
			contactId: get(props, 'currentUser.id'),
			buttonState: '',
			theme: JSON.parse(get(props, 'currentUser.company.theme', '{}')),
			referralQuestions: [],
			resumeAttachData: '',
			interestedQuestionData: '',
			isError: false,
			filename: null,
			filetype: null,
			errors: [],
			originalFile: [],
			showAppliedModal: false,
			uniqueId: uuid(),
		};
	}

	onDelete = () => {
		this.setState({ originalFile: [] });
		this.setState({ filename: null });
		this.setState({ filetype: null });
		this.setState({ errors: [] });
	};

	onFileInputChange = (e) => {
		if (e.target.files && e.target.files.length > 0) {
			const errors = [];
			const isDocOrDocsOrPdf =
				e.target.files[0].type === 'application/msword' ||
				e.target.files[0].type ===
					'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
				e.target.files[0].type === 'application/pdf';
			if (!isDocOrDocsOrPdf) {
				errors.push('You can only upload doc, docx, pdf files! ');
			}

			const isLt2M = e.target.files[0].size / 1024 / 1024 < 2;
			if (!isLt2M) {
				errors.push('File size must smaller than 2MB!');
			}

			if (errors.length > 0) {
				this.setState({ filename: e.target.files[0].name });
				this.setState({ errors });
				return errors;
			}

			this.setState({
				filename: e.target.files[0].name,
				filetype: e.target.files[0].type,
				originalFile: e.target.files[0],
			});

			this.setState({ errors: [] });
			return isDocOrDocsOrPdf && isLt2M;
		}
	};

	onPhoneNumberChange = () => {};
	getInputElement(item) {
		const Input = FormElements[item.element];
		return (
			item.text != 'Phone Number' && (
				<div key={`${item.element}`}>
					<Input
						ref={(c) => (this.inputs[item.field_name] = c)}
						key={`from_${item.id}`}
						mutable
						handleChange={this.handleChange}
						data={item}
						read_only={this.props.read_only}
					/>
				</div>
			)
		);
	}

	async getReferralQuestions(
		policy = 'network-only',
		nextToken = null,
		allQuestions = []
	) {
		const { client, currentUser } = this.props;
		try {
			const { data } = await client.query({
				query: gql(queryReferralQuestionsByCompanyId),
				variables: {
					companyId: get(currentUser, 'companyId'),
					after: nextToken,
				},
				fetchPolicy: policy,
			});
			const questions = [...data.queryReferralQuestionsByCompanyId.items];
			const token = get(data, 'queryJobsByCompanyIdDateIndex.nextToken', null);
			allQuestions = [...allQuestions, ...questions];
			let allQuestionsSort = _.sortBy(allQuestions, ['sortOrder']);
			const attachmentResumeQuestion = allQuestionsSort.filter(
				(item) => item.sortOrder === 0
			);
			const interestedQuestion = allQuestionsSort.filter(
				(item) => item.sortOrder === -1
			);
			allQuestionsSort = allQuestionsSort.filter(
				(item) => item.sortOrder !== 0 && item.sortOrder !== -1
			);
			const allQuestionsForInterested = allQuestionsSort.filter(
				(item) => item.isInterested === true
			);
			const questionsData = [];
			if (allQuestionsForInterested.length > 0) {
				allQuestionsForInterested.map((element) => {
					questionsData.push(JSON.parse(element.questions));
				});
			}

			this.setState({
				referralQuestions: questionsData,
				resumeAttachData: attachmentResumeQuestion,
				interestedQuestionData: interestedQuestion,
			});
			if (token) {
				this.getReferralQuestions(policy, token, allQuestions);
			}
		} catch (error) {
			console.log(error);
		}
	}

	getSimpleElement(item) {
		const Element = FormElements[item.element];
		return <Element key={`${item.id}`} mutable data={item} />;
	}

	_collect(item) {
		const errors = [];
		const itemData = { name: item.field_name };
		const ref = this.inputs[item.field_name];
		if (item.element === 'Checkboxes' || item.element === 'RadioButtons') {
			const checked_options = [];
			for (const option of item.options) {
				const $option = ReactDOM.findDOMNode(
					ref.options[`child_ref_${option.key}`]
				);
				if ($option.checked) {
					checked_options.push(option.key);
				}
			}

			itemData.value = checked_options;
		} else {
			if (!ref) return null;
			itemData.value = this._getItemValue(item, ref).value;

			itemData.question = item.label;
			itemData.text =
				this._getItemValue(item, ref).text === undefined
					? ''
					: this._getItemValue(item, ref).text;
			if (itemData.value === '' && itemData.text === '') {
				errors.push(`${item.label} is required.`);
			}
		}

		const object = {
			itemData,
			errors,
		};
		return object;
	}

	_collectFormData(data) {
		const formData = [];
		let errors = '';
		for (const item of data) {
			const item_data =
				this._collect(item) === null ? null : this._collect(item).itemData;
			errors += this._collect(item) === null ? '' : this._collect(item).errors;
			if (item_data) {
				formData.push(item_data);
			}
		}

		const object = {
			formData,
			errors,
		};
		return object;
	}

	_getItemValue(item, ref) {
		let $item = {
			element: item.element,
			value: '',
			text: '',
		};
		switch (item.element) {
			case 'Rating': {
				$item.value = ref.inputField.current.state.rating;

				break;
			}

			case 'Tags': {
				$item.value = ref.inputField.current.state.value;

				break;
			}

			case 'DatePicker': {
				$item.value = ref.state.value;

				break;
			}

			case 'Camera': {
				$item.value = ref.state.img
					? ref.state.img.replace('data:image/png;base64,', '')
					: '';

				break;
			}

			default: {
				if (ref && ref.inputField) {
					$item = ReactDOM.findDOMNode(ref.inputField.current);
					if (typeof $item.value === 'string') {
						$item.value = $item.value.trim();
						if (item.element === 'Dropdown') {
							$item.text = $item.selectedOptions[0].innerText;
						}
					}
				}
			}
		}

		return $item;
	}

	checkIsAlreadyAppliedToInvitedJob = async (jobId, emailAddress) => {
		try {
			const { client } = this.props;
			const { data } = await client.query({
				query: GetJob,
				variables: {
					id: jobId,
				},
				fetchPolicy: 'network-only',
			});
			const Job = get(data, 'getJob', []);
			const isAlreadyAppliedToJob = get(Job, 'referrals', []).find(
				(referral) =>
					get(referral, 'contact', false) &&
					get(referral, 'status') === 'interviewing' &&
					get(referral, 'contact.emailAddress', false) === emailAddress
			);
			return Boolean(isAlreadyAppliedToJob);
		} catch (error) {
			console.log(error);
		}
	};

	checkIsAlreadyAppliedToJob = async () => {
		try {
			this.setState({
				buttonState: '',
			});
			const { client, currentUser, job } = this.props;
			const { data } = await client.query({
				query: GetJob,
				variables: {
					id: job.id,
				},
				fetchPolicy: 'network-only',
			});
			const Job = get(data, 'getJob', []);
			const isAlreadyAppliedToJob = get(Job, 'referrals', []).find(
				(referral) =>
					get(referral, 'contact', false) &&
					get(referral, 'status') === 'interviewing' &&
					get(referral, 'contact.emailAddress', false) ===
						get(currentUser, 'emailAddress', false)
			);
			return Boolean(isAlreadyAppliedToJob);
		} catch (error) {
			console.log(error);
		}
	};

	checkIsAlreadyReferredToJob = async () => {
		try {
			const { client, currentUser, job } = this.props;
			const { data } = await client.query({
				query: GetJob,
				variables: {
					id: job.id,
				},
				fetchPolicy: 'network-only',
			});
			const Job = get(data, 'getJob', []);
			const isAlreadyReferredToJob = get(Job, 'referrals', []).find(
				(referral) =>
					get(referral, 'contact', false) &&
					get(referral, 'contact.emailAddress', false) ===
						get(currentUser, 'emailAddress', false)
			);
			return Boolean(isAlreadyReferredToJob);
		} catch (error) {
			console.log(error);
		}
	};

	handleCancel = () => {
		this.setState({
			visible: false,
			visibleApplied: false,
			showCompleteModal: false,
			buttonState: '',
			originalFile: [],
			filename: null,
			filetype: null,
			errors: [],
		});
	};

	handleSubmit = async (isResumeRequired = false) => {
		const { job, onCreateReferral, currentUser, internalJobLink } = this.props;
		const { filename, originalFile, referralQuestions } = this.state;
		try {
			if (await this.checkIsAlreadyAppliedToJob()) {
				this.setState({
					visible: false,
					visibleApplied: false,
					showCompleteModal: false,
					buttonState: 'success',
					originalFile: [],
					filename: null,
					filetype: null,
					errors: [],
				});
				return;
			}

			if (
				internalJobLink !== null &&
				internalJobLink !== undefined &&
				!referralQuestions
			) {
				this.setState({
					showCompleteModal: false,
					visible: false,
					buttonState: 'success',
				});
				this.redirectToInternalJob(internalJobLink);
			} else {
				const contactExists = this.props.contacts.some((contact) => {
					if (contact.emailAddress && contact.emailAddress !== null) {
						return (
							get(contact, 'emailAddress', '').toLowerCase() ===
							get(currentUser, 'emailAddress', '').toLowerCase()
						);
					}
				});
				const contactData = this.props.contacts.find((contact) => {
					if (contact.emailAddress && contact.emailAddress !== null) {
						return get(contact, 'emailAddress', '').toLowerCase() ===
							get(currentUser, 'emailAddress', '').toLowerCase()
							? contact.id
							: null;
					}
				});
				const contactId = get(contactData, 'id');
				const interestedQuestionsData =
					this._collectFormData(referralQuestions).formData;
				const isEmptySelect = interestedQuestionsData.some(
					(item) =>
						item.name.includes('dropdown') &&
						item.text === 'Select' &&
						item.value === '0'
				);

				if (await this.checkIsAlreadyReferredToJob()) {
					if (internalJobLink !== null && internalJobLink !== undefined) {
						this.setState({
							visible: false,
							visibleApplied: false,
							showCompleteModal: false,
							buttonState: 'success',
							originalFile: [],
							filename: null,
							filetype: null,
							errors: [],
						});
						this.redirectToInternalJob(internalJobLink);
					} else {
						this.setState({
							visible: false,
							visibleApplied: false,
							showCompleteModal: false,
							buttonState: 'success',
							originalFile: [],
							filename: null,
							filetype: null,
							errors: [],
						});
					}
				} else {
					if (contactExists) {
						const quesErrors = this._collectFormData(referralQuestions).errors;
						this.setState({ buttonState: '' });
						if (quesErrors.length > 0 || isEmptySelect) {
							message.error('Please check all the required fields', 5);
							this.setState({ buttonState: 'error' });
							return;
						}

						if (isResumeRequired && !filename) {
							message.error('Please upload your resume.', 5);
							this.setState({ buttonState: 'error' });
							return;
						}

						this.toggleIsSubmitting();
						this.showAnimation().then(async () => {
							await onCreateReferral({
								input: {
									companyId: currentUser.companyId,
									contactId,
									userId: currentUser.id,
									jobId: job.id,
									status: 'accepted',
									note: null,
									message: null,
									referralType: 'self',
									interestedQuestionsData: JSON.stringify(
										interestedQuestionsData
									),
								},
							});
							if (internalJobLink !== null && internalJobLink !== undefined) {
								this.setState({
									showCompleteModal: false,
									visible: false,
									buttonState: '',
								});
							} else {
								this.setState({
									visible: false,
									buttonState: '',
								});
							}
						});
					} else {
						const quesErrors = this._collectFormData(referralQuestions).errors;
						this.setState({ buttonState: '' });
						if (quesErrors.length > 0 || isEmptySelect) {
							message.error('Please check all the required fields', 5);
							this.setState({ buttonState: 'error' });
							return;
						}

						if (isResumeRequired && !filename) {
							message.error('Please upload your resume.', 5);
							this.setState({ buttonState: 'error' });
							return;
						}

						this.toggleIsSubmitting();
						this.showAnimation().then(() => {
							this.props
								.ImportedCreateContact({
									input: {
										firstName: currentUser.firstName,
										lastName: currentUser.lastName,
										emailAddress: get(
											currentUser,
											'emailAddress',
											''
										).toLowerCase(),
										socialMediaAccounts: null,
										userId: currentUser.id,
										companyId: currentUser.companyId,
										jobHistory: null,
										importMethod: 'email',
									},
								})
								.then(async (response) => {
									const contactId = get(response, 'data.createContact.id');
									const d = new Date();
									const dformat = `${d.getHours()}-${d.getMinutes()}-${d.getSeconds()}`;
									if (filename) {
										const contactResume = {
											bucket: 'erin-documents',
											key: `resumes/${contactId}/${dformat + '-' + filename}`,
											region: 'us-east-2',
										};
										await uploadToS3Multipart(
											originalFile,
											contactResume.key,
											contactResume.bucket
										);
										await this.updateExistingContact({
											id: contactId,
											contactResume,
										});
									}

									if (
										internalJobLink !== null &&
										internalJobLink !== undefined
									) {
										this.setState({
											showCompleteModal: false,
											visible: false,
											buttonState: '',
										});
									} else {
										this.setState({
											visible: false,
											buttonState: '',
										});
									}

									await onCreateReferral({
										input: {
											companyId: currentUser.companyId,
											contactId,
											userId: currentUser.id,
											jobId: job.id,
											status: 'accepted',
											note: null,
											message: null,
											referralType: 'self',
											interestedQuestionsData: JSON.stringify(
												interestedQuestionsData
											),
										},
									});
								});
						});
					}

					if (internalJobLink !== null && internalJobLink !== undefined) {
						this.setState({
							visible: false,
							visibleApplied: false,
							showCompleteModal: false,
							buttonState: 'success',
							originalFile: [],
							filename: null,
							filetype: null,
							errors: [],
						});
						this.redirectToInternalJob(internalJobLink);
					}
				}
			}
		} catch {}
	};

	inputs = {};

	redirectToInternalJob = (internalJobLink) => {
		window.open(internalJobLink, '_blank');
	};

	sendEmail = async (job) => {
		const { selectedEmployeeId, currentUser, selectedEmailAddress } =
			this.props;
		const jobId = get(job, 'id');
		if (
			await this.checkIsAlreadyAppliedToInvitedJob(jobId, selectedEmailAddress)
		) {
			message.success('This user is already applied for this position.', 5);
			return;
		}

		let endpoint = '';
		endpoint =
			configMode === 'DEV'
				? 'invite-to-apply-dev-app'
				: 'invite-to-apply-prod-app';
		lambda({
			endpoint,
			variables: {
				jobId,
				userId: selectedEmployeeId,
				emailAddress: selectedEmailAddress,
				referrerFirstName: get(currentUser, 'firstName'),
				referrerLastName: get(currentUser, 'lastName'),
				referredBy: get(currentUser, 'id'),
			},
		});
	};

	showAnimation = () => {
		return new Promise((resolve, reject) => {
			setTimeout(() => {
				try {
					this.toggleIsSubmitting();
					this.handleCancel();
					resolve();
				} catch (error) {
					reject(error);
				}
			}, 2500);
		});
	};

	showModal = async () => {
		if ((await this.checkIsAlreadyAppliedToJob()) || this.state.submitted) {
			this.setState({
				visibleApplied: true,
			});
		} else {
			setTimeout(() => {
				this.setState({
					visible: true,
				});
			}, 250);
		}
	};

	toggleIsSubmitting = () => {
		this.setState({ buttonState: 'loading' });
		this.setState((prevState) => ({ isSubmitting: !prevState.isSubmitting }));
		setTimeout(() => {
			this.setState({ buttonState: 'success' });
		}, 2000);
	};

	updateExistingContact = async (contact) => {
		await this.props.client.mutate({
			mutation: gql(updateContact),
			variables: { input: contact },
		});
	};

	render() {
		const {
			visible,
			visibleApplied,
			showCompleteModal,
			theme,
			referralQuestions,
			resumeAttachData,
			filename,
			uniqueId,
		} = this.state;
		const { allMultiLingualData, currentUser, internalJobLink, job } =
			this.props;
		const companyId = get(job, 'company.id');
		let modalTxt = '';
		let applyText = '';
		if (internalJobLink !== null && internalJobLink !== undefined) {
			modalTxt =
				'You will be directed to our job site to apply for this position.';
			applyText = 'Apply On Job Site';
		} else {
			modalTxt =
				'Let us know that you are interested in this position and we will be in touch.';
			applyText = 'Submit Me For This Position';
		}

		const toggleClass = ModalStyles.ModalStyles;
		const resumeData = {};
		let isResumeOptional = false;
		let isResumeRequired = false;
		let isUserEmail = false;
		if (resumeAttachData.length > 0) {
			const interestedFormResumeData = resumeAttachData.filter(
				(item) => item.isInterested === true && item.sortOrder === 0
			);
			if (interestedFormResumeData.length > 0) {
				Object.assign(resumeData, ...interestedFormResumeData);
				const resumeAttachmentData = JSON.parse(get(resumeData, 'questions'));
				for (const key in resumeAttachmentData) {
					if (resumeAttachmentData.hasOwnProperty(key)) {
						switch (key) {
							case 'Resume required': {
								isResumeRequired = resumeAttachmentData[key];

								break;
							}

							case 'Ask for resume attachment': {
								isResumeOptional = resumeAttachmentData[key];

								break;
							}

							case "Show User's Email Address": {
								isUserEmail = resumeAttachmentData[key];

								break;
							}
							// No default
						}
					}
				}
			}
		}

		const data_items = referralQuestions;
		let customItems = '';
		if (data_items.length > 0) {
			customItems = data_items.map((item) => {
				if (!item) return null;
				switch (item.element) {
					case 'TextInput':
					case 'NumberInput':
					case 'TextArea':
					case 'Dropdown':
					case 'DatePicker':
					case 'RadioButtons':
					case 'Rating':
					case 'Tags':
					case 'Range': {
						return this.getInputElement(item);
					}

					default: {
						return this.getSimpleElement(item);
					}
				}
			});
		}

		const className = ModalStyles.SubmitBtnContainer;
		const jobTitle = get(job, 'title');
		const location = get(job, 'job.location', get(job, 'location'));

		let locations = get(job, 'job.locations', get(job, 'locations', []));
		locations = locations ? locations : [];
		const department = get(job, 'department');
		return (
			<>
				<Popconfirm
					title="Are you sure?"
					placement="top"
					okText="Yes"
					cancelText="No"
					onConfirm={() => this.sendEmail(job)}
				>
					<Button block ghost type="primary" size="large">
						{ml(`Invite to Apply`, currentUser, allMultiLingualData)}
					</Button>
				</Popconfirm>
				<Modal
					className={toggleClass}
					open={visible}
					footer={null}
					width={720}
					onCancel={this.handleCancel}
				>
					<div>
						<h1 className={ModalStyles.ModalTitle(theme)}>
							{ml('Invited?', currentUser, allMultiLingualData)}
						</h1>
						<p className={ModalStyles.ModalText}>
							{ml(modalTxt, currentUser, allMultiLingualData)}
						</p>

						<div
							style={{
								display: 'flex',
								paddingBottom: 20,
								margin: 'auto',
								width: '60%',
							}}
						>
							<table
								cellPadding={0}
								cellSpacing={0}
								className="es-left"
								align="left"
								style={{
									msoTableLspace: '0pt',
									msoTableRspace: '0pt',
									borderCollapse: 'collapse',
									borderSpacing: '0px',
									float: 'left',
								}}
							>
								<tbody>
									<tr style={{ borderCollapse: 'collapse' }}>
										<td
											width={400}
											className="es-m-p20b"
											align="center"
											style={{ padding: 0, margin: 0 }}
										>
											<table
												cellPadding={0}
												cellSpacing={0}
												width="100%"
												style={{
													msoTableLspace: '0pt',
													msoTableRspace: '0pt',
													borderCollapse: 'separate',
													borderSpacing: '0px',
													borderRadius: '15px',
													backgroundColor: '#C5F4C8',
												}}
												bgcolor="#c5f4c8"
											>
												<tbody>
													<tr style={{ borderCollapse: 'collapse' }}>
														<td
															align="center"
															style={{
																padding: 0,
																margin: 0,
																paddingTop: '20px',
																paddingBottom: '25px',
															}}
														>
															<p
																style={{
																	margin: 0,
																	WebkitTextSizeAdjust: 'none',
																	msTextSizeAdjust: 'none',
																	msoLineHeightRule: 'exactly',
																	fontSize: '22px',
																	fontFamily:
																		'lato, "helvetica neue", helvetica, arial, sans-serif',
																	lineHeight: '33px',
																	color: '#444444',
																}}
															>
																<strong>
																	<font
																		face="open sans, helvetica neue, helvetica, arial, sans-serif"
																		style={{ color: '#00641F' }}
																	>
																		{jobTitle}
																	</font>
																</strong>
															</p>
															<p
																style={{
																	margin: 0,
																	WebkitTextSizeAdjust: 'none',
																	msTextSizeAdjust: 'none',
																	msoLineHeightRule: 'exactly',
																	fontSize: '16px',
																	fontFamily:
																		'lato, "helvetica neue", helvetica, arial, sans-serif',
																	lineHeight: '24px',
																	color: '#444444',
																}}
															>
																{get(location, 'city') ||
																get(location, 'state') ||
																get(location, 'isRemote') === true ? (
																	<WebIcon size={18} name="placeholder" />
																) : null}
																<span className={locationStyles}>
																	{locations.length > 1
																		? ml(
																				'Multiple Locations',
																				currentUser,
																				allMultiLingualData
																			)
																		: get(location, 'isRemote') === true
																			? ml(
																					'Remote',
																					currentUser,
																					allMultiLingualData
																				)
																			: get(location, 'city') &&
																				  get(location, 'state')
																				? `${get(location, 'city')}, ${get(
																						location,
																						'state'
																					)}`
																				: get(location, 'city')
																					? get(location, 'city')
																					: get(location, 'state')
																						? get(location, 'state')
																						: ''}
																</span>

																<WebIcon name="folder" size={18} />
																<span className={Department}>
																	{' '}
																	{department ? department.name : ''}{' '}
																</span>
																{/* If company is ZT systems */}
																{companyId ===
																	'e600195e-d1d9-4163-8b22-f0f250c4bcfd' &&
																	get(job, 'displayReqId') !== true &&
																	get(job, 'displayReqId') !== '' &&
																	get(job, 'displayReqId') && (
																		<p
																			type="flex"
																			className={requestNumberContainer}
																		>
																			<span className={requestNumber}>
																				ID#:
																			</span>{' '}
																			<span className={requestNumber}>
																				{get(job, 'displayReqId')}
																			</span>
																		</p>
																	)}
																{companyId !==
																	'e600195e-d1d9-4163-8b22-f0f250c4bcfd' &&
																	get(job, 'externalJobId') !== true &&
																	get(job, 'externalJobId') !== '' &&
																	get(job, 'externalJobId') && (
																		<p
																			type="flex"
																			className={requestNumberContainer}
																		>
																			<span className={requestNumber}>
																				ID#:
																			</span>{' '}
																			<span className={requestNumber}>
																				{get(job, 'externalJobId')}
																			</span>
																		</p>
																	)}
															</p>
														</td>
													</tr>
												</tbody>
											</table>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
						<div>
							<div>{customItems}</div>
							<div>
								{isResumeOptional ? (
									<div className="App" style={{ marginBottom: '10px' }}>
										<span className={fileWrap}>
											<label htmlFor={'file-' + uniqueId}>
												{'Click here'}{' '}
											</label>
											<input
												ref={(ref) => {
													this.uploadInput = ref;
												}}
												hidden
												type="file"
												accept=".doc,.docx,application/msword,.pdf,application/pdf"
												id={'file-' + uniqueId}
												name="file"
												onClick={() => {
													this.setState({
														filename: null,
														filetype: null,
														originalFile: [],
													});
													this.uploadInput = null;
												}}
												onChange={this.onFileInputChange}
											/>
										</span>{' '}
										to attach a resume (
										{isResumeRequired ? 'required' : 'optional'}){' '}
										{filename && (
											<p>
												{filename}
												<DeleteOutlined
													style={{
														margin: '0px 0px 4px 10px',
														color: '#ef3c3e',
													}}
													onClick={this.onDelete}
												/>
											</p>
										)}
									</div>
								) : null}
							</div>
						</div>
						{isUserEmail ? (
							<div>
								<span className={ModalStyles.userEmail}>
									You must apply using this email address:{' '}
								</span>
								<span style={{ fontStyle: 'italic', color: COLORS.black }}>
									{get(currentUser, 'emailAddress')}
								</span>
							</div>
						) : (
							''
						)}
						<div className={className}>
							<ProgressButton
								style={{ marginTop: 20 }}
								durationSuccess={1500}
								state={this.state.buttonState}
								onClick={() => {
									this.handleSubmit(isResumeRequired);
								}}
							>
								{ml(applyText, currentUser, allMultiLingualData)}
							</ProgressButton>
						</div>
					</div>
				</Modal>
				<Modal
					className={toggleClass}
					open={showCompleteModal}
					footer={null}
					width={620}
					onCancel={this.handleCancel}
				>
					<div>
						<Row style={{ textAlign: 'center' }}>
							<CheckCircleOutlined className={checkCircle} />
						</Row>
						<p className={ModalStyles.ModalText}>
							{ml(
								'Success. Your information has been submitted for this position.',
								currentUser,
								allMultiLingualData
							)}
						</p>

						<div className={ModalStyles.SubmitBtnContainer}>
							<Row style={{ marginTop: 8 }}>
								<span style={{ fontSize: 18 }}>
									<a
										className={linkStyles}
										onClick={() => {
											this.setState({ showCompleteModal: false });
										}}
									>
										{ml('Close This Window', currentUser, allMultiLingualData)}
									</a>
								</span>
							</Row>
						</div>
					</div>
				</Modal>
				<Modal
					className={toggleClass}
					open={visibleApplied}
					footer={null}
					width={720}
					onCancel={this.handleCancel}
				>
					<div>
						<h1 className={ModalStyles.AppliedModalTitle(theme)}>
							{ml("You've Applied!", currentUser, allMultiLingualData)}
						</h1>
						<p className={ModalStyles.ModalText}>
							{ml(
								"You've already applied to this position. Check My Referrals to track your status.",
								currentUser,
								allMultiLingualData
							)}
						</p>

						<div className={className}>
							<ProgressButton
								durationSuccess={1500}
								state={this.state.buttonState}
								onClick={() => {
									this.handleSubmit(isResumeRequired);
								}}
							>
								{ml('Got It', currentUser, allMultiLingualData)}
							</ProgressButton>
						</div>
					</div>
				</Modal>
			</>
		);
	}
}

export default withApollo(InviteToApplyButtonWrapper);
