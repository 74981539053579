import { createContext, useContext, useState } from 'react';
import translationsEssential from './translationsEssential.json';

// Context for managing translations

const TranslationContext = createContext({ language: 'en', translations: {} });

const useTranslation = () => {
	const context = useContext(TranslationContext);
	if (!context) {
		throw new Error('useTranslation must be used within a TranslationProvider');
	}
	const { language, translations, setLanguage } = context;
	const er18n = (key) => {
		const translation = translations.find((t) => t.matchKey === key);
		return translation ? translation.text : key;
	};
	return { er18n, language, setLanguage };
};

const TranslationProvider = ({
	children,
	initialLanguage = 'en',
	initialTranslations = {},
}) => {
	const [language, setLanguageState] = useState(initialLanguage);
	const [translations, setTranslations] = useState(initialTranslations);

	const setLanguage = async (newLanguage, params) => {
		try {
			// const newTranslations = await fetchTranslationsFromApi({
			// 	languageCode: newLanguage,
			// 	...params,
			// });
			const newTranslations = translationsEssential.filter(
				(item) => item.languageCode === newLanguage
			);
			setTranslations(newTranslations);
			setLanguageState(newLanguage);
		} catch (error) {
			console.error('Error setting language:', error);
		}
	};

	const value = { language, translations, setLanguage };

	return (
		<TranslationContext.Provider value={value}>
			{children}
		</TranslationContext.Provider>
	);
};

export { TranslationProvider, useTranslation };
