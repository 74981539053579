import get from 'lodash/get';
import { Component } from 'react';
import { withApollo } from 'react-apollo';
import Spinner from 'src/_shared/components/spinner/SpinnerComponent.jsx';
import ErinLogo from '../_shared/assets/erinwhite.png';
import { downloadFromS3Signed, graphql } from '../_shared/services/utils.js';
import NewCompanyUserForm from './new-user-items/NewCompanyUserFormComponent.jsx';
import { Card, Logo } from './newUserLandingStyles.js';

class NewCompanyUserLandingPageComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			departments: get(props, 'company.departments'),
			company: get(props, 'company'),
			subCompanies: get(props, 'company.subCompanies'),
			backgroundUrl: null,
			whiteLabel: null,
		};
	}

	async componentDidMount() {
		const id = window.location.pathname.split('/')[2];
		const company = await graphql({ input: { id }, query: 'getCompany' });
		const logo = get(company, 'logo.key', false);
		const url = await downloadFromS3Signed(
			logo,
			get(company, 'logo.bucket', false)
		);
		this.setState({
			company,
			departments: get(company, 'departments'),
			subCompanies: get(company, 'subCompanies'),
			backgroundUrl: get(company, 'background.key'),
			whiteLabel: get(company, 'whiteLabel'),
			logoSrc: url,
		});
	}

	async onCreateUser(input) {
		const newUser = await graphql({ input, update: 'createUser' });
		return newUser;
	}

	render() {
		const {
			company,
			departments,
			deptTitle,
			subCompanies,
			backgroundUrl,
			whiteLabel,
			logoSrc,
		} = this.state;

		if (!company || !departments) {
			return <Spinner forceDefault />;
		}

		const style =
			backgroundUrl && whiteLabel ? { backgroundImage: backgroundUrl } : {};
		return (
			<div className="auth" style={style}>
				{whiteLabel ? (
					<img src={logoSrc} alt="logo" width="350px" />
				) : (
					<img className={Logo} src={ErinLogo} alt="Erin Logo" />
				)}
				<div className={Card}>
					<NewCompanyUserForm
						company={company}
						whiteLabel={whiteLabel}
						setCurrentUser={this.props.setCurrentUser}
						deptTitle={deptTitle}
						subCompanies={subCompanies}
						departments={departments}
						onCreateUser={this.onCreateUser}
					/>
				</div>
			</div>
		);
	}
}

export default withApollo(NewCompanyUserLandingPageComponent);
