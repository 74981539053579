export const createUser = `mutation CreateUser($input: CreateUserInput!) {
  createUser(input: $input) {
    id
    cognitoId
    companyId
    company {
      id
      name
      defaultBonusAmount
      contactIncentiveBonus
      websiteUrl
      sendAdminNotificationsOnReferral
      sendAdminNotificationsOnInternalCandidatesFound
      referralBonusWaitingPeriod
      referralBonusNotificationRecipients
      dateCreated
      users {
        id
        cognitoId
        companyId
        emailAddress
        firstName
        lastName
        title
        departmentId
        lastLogin
        lastNotificationCheck
        contacts {
          id
          firstName
          lastName
          emailAddress
          socialMediaAccounts
          userId
          companyId
          phoneNumber
          jobHistory
          fullContactData
          dateCreated
        }
        totalReferrals
        webNotifications {
          id
          userId
          requestingUserId
          contactId
          referralId
          jobId
          matches
          message
          dateCreated
        }
        connectedApps
        active
        createdById
        dateCreated
        ytdReferralCount
      }
    }
    emailAddress
    role
    firstName
    lastName
    title
    avatar {
      bucket
      region
      key
    }
    departmentId
    department {
      id
      companyId
      name
      active
      totalUsers
      userDepartments {
        id
        userId
        departmentId
      }
    }
    managedDepartments {
      id
      userId
      departmentId
    }
    lastLogin
    lastNotificationCheck
    referrals {
      id
      companyId
      contactId
      userId
      jobId
      note
      message
      referralDate
      referralBonusNotificationSent
      hireDate
    }
    contacts {
      id
      firstName
      lastName
      emailAddress
      socialMediaAccounts
      referrals {
        id
        companyId
        contactId
        userId
        jobId
        note
        message
        referralDate
        referralBonusNotificationSent
        hireDate
      }
      userId
      user {
        id
        cognitoId
        companyId
        emailAddress
        firstName
        lastName
        title
        departmentId
        lastLogin
        lastNotificationCheck
        contacts {
          id
          firstName
          lastName
          emailAddress
          socialMediaAccounts
          userId
          companyId
          phoneNumber
          jobHistory
          fullContactData
          dateCreated
        }
        totalReferrals
        webNotifications {
          id
          userId
          requestingUserId
          contactId
          referralId
          jobId
          matches
          message
          dateCreated
        }
        connectedApps
        active
        createdById
        dateCreated
        ytdReferralCount
      }
      companyId
      company {
        id
        name
        defaultBonusAmount
        contactIncentiveBonus
        websiteUrl
        sendAdminNotificationsOnReferral
        sendAdminNotificationsOnInternalCandidatesFound
        referralBonusWaitingPeriod
        referralBonusNotificationRecipients
        dateCreated
        users {
          id
          cognitoId
          companyId
          emailAddress
          firstName
          lastName
          title
          departmentId
          lastLogin
          lastNotificationCheck
          contacts {
            id
            firstName
            lastName
            emailAddress
            socialMediaAccounts
            userId
            companyId
            phoneNumber
            jobHistory
            fullContactData
            dateCreated
          }
          totalReferrals
          webNotifications {
            id
            userId
            requestingUserId
            contactId
            referralId
            jobId
            matches
            message
            dateCreated
          }
          connectedApps
          active
          createdById
          dateCreated
          ytdReferralCount
        }
      }
      phoneNumber
      jobHistory
      importMethod
      fullContactData
      dateCreated
    }
    totalReferrals
    webNotifications {
      id
      type
      userId
      user {
        id
        cognitoId
        companyId
        emailAddress
        firstName
        lastName
        title
        departmentId
        lastLogin
        lastNotificationCheck
        contacts {
          id
          firstName
          lastName
          emailAddress
          socialMediaAccounts
          userId
          companyId
          phoneNumber
          jobHistory
          fullContactData
          dateCreated
        }
        totalReferrals
        webNotifications {
          id
          userId
          requestingUserId
          contactId
          referralId
          jobId
          matches
          message
          dateCreated
        }
        connectedApps
        active
        createdById
        dateCreated
        ytdReferralCount
      }
      referralRequestedStatus
      requestingUserId
      requestingUser {
        id
        cognitoId
        companyId
        emailAddress
        firstName
        lastName
        title
        departmentId
        lastLogin
        lastNotificationCheck
        contacts {
          id
          firstName
          lastName
          emailAddress
          socialMediaAccounts
          userId
          companyId
          phoneNumber
          jobHistory
          fullContactData
          dateCreated
        }
        totalReferrals
        webNotifications {
          id
          userId
          requestingUserId
          contactId
          referralId
          jobId
          matches
          message
          dateCreated
        }
        connectedApps
        active
        createdById
        dateCreated
        ytdReferralCount
      }
      contactId
      contact {
        id
        firstName
        lastName
        emailAddress
        socialMediaAccounts
        userId
        companyId
        phoneNumber
        jobHistory
        fullContactData
        dateCreated
      }
      referralId
      referral {
        id
        companyId
        contactId
        userId
        jobId
        note
        message
        referralDate
        referralBonusNotificationSent
        hireDate
      }
      jobId
      job {
        id
        companyId
        departmentId
        title
        description
        publicLink
        salary
        location
        hiringManagerId
        createdById
        referralBonus
        shares
        views
        referrals {
          id
          companyId
          contactId
          userId
          jobId
          note
          message
          referralDate
          referralBonusNotificationSent
          hireDate
        }
        matches {
          id
          firstName
          lastName
          emailAddress
          socialMediaAccounts
          userId
          companyId
          phoneNumber
          jobHistory
          fullContactData
          dateCreated
        }
        dateCreated
      }
      matches
      message
      dateCreated
    }
    connectedApps
    active
    createdById
    createdBy {
      id
      cognitoId
      companyId
      emailAddress
      firstName
      lastName
      title
      departmentId
      lastLogin
      lastNotificationCheck
      contacts {
        id
        firstName
        lastName
        emailAddress
        socialMediaAccounts
        userId
        companyId
        phoneNumber
        jobHistory
        fullContactData
        dateCreated
      }
      totalReferrals
      webNotifications {
        id
        userId
        requestingUserId
        contactId
        referralId
        jobId
        matches
        message
        dateCreated
      }
      connectedApps
      active
      createdById
      dateCreated
      ytdReferralCount
    }
    dateCreated
    ytdReferralCount
  }
}
`;
