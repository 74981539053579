import { referralData } from 'src/test-data/referralsData.js';
import * as Actions from './referralActions.js';
import { state as initialState } from './referralState.js';

const findInArray = (array, key, value) => {
	// Use old loop style to allow short-circuiting
	for (const [i, element] of array.entries()) {
		if (element[key] === value) {
			return i;
		}
	}

	return -1;
};

export const referralReducer = (state = initialState, action) => {
	switch (action.type) {
		case Actions.FETCH_REFERRALS: {
			return handleFetchReferrals(state, action);
		}

		case Actions.UPDATE_REFERRAL: {
			return handleReferralUpdates(state, action);
		}

		case Actions.SET_CURRENT_REFERRAL: {
			return handleSelectReferral(state, action);
		}

		case Actions.UPDATE_REFERRALS_NOT_HIRED: {
			return handleUpdateAllReferralsFor('status', state, action);
		}

		default: {
			return state;
		}
	}
};

const handleFetchReferrals = (state) => {
	return {
		...state,
		referrals: referralData, // TODO: replace with the real payload
	};
};

const handleUpdateAllReferralsFor = (property, state, { payload }) => {
	const allReferrals = [...state.referrals];
	const exemptReferral = payload.id;
	for (const [index, referral] of allReferrals.entries()) {
		if (referral.id !== exemptReferral) {
			allReferrals[index] = {
				...referral,
				status: 'Not Hired',
			};
		}
	}

	return {
		...state,
		referrals: allReferrals,
	};
};

const handleReferralUpdates = (state, { payload }) => {
	const updatedReferral = {};
	for (const key of Object.keys(payload)) {
		updatedReferral[key] = payload[key];
	}

	const referralsCopy = [...state.referrals];
	const index = findInArray(referralsCopy, 'id', updatedReferral.id);
	if (index > -1) referralsCopy[index] = updatedReferral;

	return {
		...state,
		referrals: referralsCopy,
	};
};

const handleSelectReferral = (state, { payload }) => {
	return {
		...state,
		currentReferral: payload.referralId,
	};
};
