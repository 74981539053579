import { useState, useEffect } from 'react';
import get from 'lodash/get';
import { useSelector } from 'react-redux';
import { downloadFromS3Signed } from 'src/_shared/services/utils.js';
import slothLoader from '../../assets/erin_sloth_loader.png';
import defaultLoader from '../../assets/default-loader.svg';
import defaultLoaderGif from '../../assets/default-loader.gif';

function Spinner(props) {
	const loaderUrlLS = localStorage.getItem('loaderURL');
	const whiteLabelLS = localStorage.getItem('whiteLabel');
	const supportsSVGLS = localStorage.getItem('supportsSVG');
	let company = props?.company;
	company ||= useSelector((state) => state?.user?.currentUser?.company);
	const whiteLabel = whiteLabelLS ? true : company?.whiteLabel || false;
	if (!whiteLabelLS && whiteLabel) localStorage.setItem('whiteLabel', 'true');
	const [loader, setLoader] = useState(loaderUrlLS);
	const loaderSymbol = company?.symbol?.key || null;
	useEffect(() => {
		const fetchLoader = async () => {
			if (loaderSymbol) {
				const loaderImage = await downloadFromS3Signed(
					loaderSymbol,
					'erin-images'
				);
				if (loaderImage) {
					localStorage.setItem('loaderURL', loaderImage);
					setLoader(loaderImage);
				}
			}
		};

		if (loaderUrlLS) {
			return;
		}

		fetchLoader();
	}, [loaderSymbol]);

	// Check if browser supports animated svg so we can fallback to gif
	const [supportsSVG, setSupportsSVG] = useState(supportsSVGLS === 'true');
	useEffect(() => {
		const checkSupportsAnimatedSVG = () => {
			const SVGCheck = document.createElementNS(
				'http://www.w3.org/2000/svg',
				'svg'
			);
			const animate = document.createElementNS(
				'http://www.w3.org/2000/svg',
				'animate'
			);
			SVGCheck.append(animate);
			return animate instanceof SVGAnimateElement;
		};

		if (supportsSVGLS === 'true' || supportsSVGLS === 'false') return;
		let testSVG;
		testSVG = checkSupportsAnimatedSVG() ? 'true' : 'false';
		localStorage.setItem('supportsSVG', testSVG);
		setSupportsSVG(checkSupportsAnimatedSVG());
	}, []);
	// Allow unstyled prop to remove absolute positioning of spinner
	const className = get(props, 'unstyled') ? '' : 'custom-loader';
	const forceDefault = props?.forceDefault;
	const message = get(props, 'message');

	return (
		<div className={className}>
			{whiteLabel || forceDefault ? (
				<>
					{!forceDefault && loaderSymbol ? (
						<img
							className="rotate"
							src={loader}
							alt=""
							style={{ width: 100 }}
						/>
					) : supportsSVG ? (
						<img src={defaultLoader} alt="Loading" style={{ width: 100 }} />
					) : (
						<img src={defaultLoaderGif} alt="Loading" style={{ width: 100 }} />
					)}
				</>
			) : (
				<img src={slothLoader} alt="Loading" className="floating" />
			)}
			{message && <p className="custom-loader-text">{message}</p>}
		</div>
	);
}

export default Spinner;
