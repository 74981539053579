import gql from 'graphql-tag';
import { compose, graphql } from 'react-apollo';
import {
	createInternalMobility,
	queryInternalMobilityByCompanyIdIndex,
} from '../../graphql/custom/internal-mobility';

export const withCreateInternalMobility = (Component) => {
	return compose(
		graphql(gql(createInternalMobility), {
			props: (props) => ({
				onCreateInternalMobility(input) {
					const optimisticResponseData = {
						...input.input,
						__typename: 'InternalMobility',
					};
					props.mutate({
						variables: { ...input },
						optimisticResponse: {
							__typename: 'Mutation',
							createInternalMobility: {
								__typename: 'createInternalMobility',
								...optimisticResponseData,
							},
						},
						refetchQueries: [
							{
								query: gql(queryInternalMobilityByCompanyIdIndex),
								variables: { companyId: props.ownProps.currentUser.companyId },
							},
						],
					});
				},
			}),
		})
	)(Component);
};
