import { createContext, useContext } from 'react';
import { useRnRAPIService } from '../../rewardsAndRecognitionHelper/service/RnRAPI.service.jsx';
import { ALL_REWARDS_ADMIN_ENDPOINTS } from '../constants/AllRewardsAdmin.constants.js';

const AllRewardsAdminServiceContext = createContext(null);

export const AllRewardsAdminServiceProvider = (props) => {
  const APIService = useRnRAPIService();

  const getAllActivity = (request) => {
    return APIService.get(ALL_REWARDS_ADMIN_ENDPOINTS.GET_ALL_ACTIVITY, request);
  }

  const getAllRewards = () => {
    return APIService.get(ALL_REWARDS_ADMIN_ENDPOINTS.GET_ALL_REWARDS);
  }

  const downloadAllRewardCSV = (request) => {
    return APIService.get(ALL_REWARDS_ADMIN_ENDPOINTS.EXPORT_ALL_REWARD_CSV, request);
  }

  const AllRewardsAdminServiceOperations = {
    getAllActivity,
    getAllRewards,
    downloadAllRewardCSV
  };

  return (
    <AllRewardsAdminServiceContext.Provider
      value={AllRewardsAdminServiceOperations}
    >
      {props.children}
    </AllRewardsAdminServiceContext.Provider>
  );
};

export const useAllRewardsAdminService = () => {
  return useContext(AllRewardsAdminServiceContext);
};