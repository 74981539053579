import { connect } from 'react-redux';
import { withJobMatchByCompanyId } from 'src/_shared/api/components/dashboard/';
import {
	withCreateInternalMobility,
	withQueryInternalMobilityByCompanyId,
} from 'src/_shared/api/components/internal-mobility/';
import { withCreateReferral } from 'src/_shared/api/components/referrals/';
import { withGetCompanyData } from 'src/_shared/api/components/settings/';
import { withCreateUserJobShare } from 'src/_shared/api/components/users/';
import { dashboardActions, userActions } from 'src/actions.js';
import { compose } from '../_shared/services/utils.js';
import InternalComponent from './InternalComponent.jsx';

const mapStateToProperties = (state) => {
	const { allMultiLingualData } = state.dashboard;
	return {
		currentUser: state.user.currentUser,
		companyId: state.user.currentUser.companyId,
		allMultiLingualData,
		filter: {
			companyId: { eq: state.user.currentUser.companyId },
		},
		nextToken: null,
		limit: 10_000,
		jobSearchCriteria: state.user.jobSearchCriteria,
	};
};

const mapDispatchToProperties = (dispatch) => {
	return {
		updateCurrentUserCompany(company) {
			dispatch(userActions.updateUserCompany(company));
		},
		setMultiLingualData(data) {
			dispatch(dashboardActions.createSetMultiLingualData(data));
		},
		updateCurrentUserSearchCriteria(searchCriteria) {
			dispatch(userActions.updateUserSearch(searchCriteria));
		},
	};
};

export const InternalWithApi = compose(
	withGetCompanyData,
	withCreateUserJobShare,
	withCreateInternalMobility,
	withQueryInternalMobilityByCompanyId,
	withJobMatchByCompanyId,
	withCreateReferral
)(InternalComponent);

export const Internal = connect(
	mapStateToProperties,
	mapDispatchToProperties
)(InternalWithApi);
