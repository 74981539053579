export const queryReferralsAdminNoteOwnershipEndDateByUserId = `query QueryReferralsByUserIdIndex(
    $userId: ID!
    $first: Int
    $after: String
  ) {
    queryReferralsByUserIdIndex(
      userId: $userId
      first: $first
      after: $after
    ) {
        items {
            id
            ownershipEndDate
            adminNote
          }
          nextToken
    }
  }
  `;
