import { SearchOutlined } from '@ant-design/icons';
import { Button, Input } from 'antd';
import { useEffect, useState } from 'react';
import { userActions } from 'src/actions.js';
import store from 'src/state.js';
import backgroundDefault from 'src/_shared/assets/banner-bg.jpeg';
import { downloadFromS3Signed } from '../../services/utils.js';

export function SearchBanner({
	imageS3Data,
	callToAction,
	companyId,
	placeholder,
	editable = false,
	style = {},
	children = null,
	firstName = '',
	helloText = 'Hello',
	...props
}) {
	const [searchTerm, setSearchTerm] = useState('');
	const [backgroundImage, setBackgroundImage] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		const getSetBackground = async () => {
			setIsLoading(true);

			let customBackground = backgroundDefault;
			if (imageS3Data) {
				customBackground = await downloadFromS3Signed(
					imageS3Data.key,
					imageS3Data.bucket
				);
			}

			setBackgroundImage(customBackground);
			setIsLoading(false);
		};

		getSetBackground();
	}, [companyId, imageS3Data]);

	const filterJobs = async (e) => {
		e.preventDefault();
		await store.dispatch(userActions.updateUserSearch(searchTerm));
		props.history.push('/browsejobs');
	};

	if (isLoading) {
		return null;
	}

	return (
		<div className="internal-banner" style={{ ...style }}>
			<img src={backgroundImage} className="banner-img" />
			<div className="banner-content">
				<div className="c-left">
					<div className="page-title">
						<h2
							className="page-heading"
							style={{ marginLeft: '-0.04em', color: '#fff' }}
						>
							{helloText}, {firstName}
						</h2>
					</div>
					{callToAction}
					<form className="search-input" onSubmit={filterJobs}>
						<Input
							placeholder={placeholder}
							value={searchTerm}
							onChange={(e) => setSearchTerm(e.target.value)}
						/>
						<Button type="primary" htmlType="submit" className="search-icon">
							<SearchOutlined />
						</Button>
					</form>
				</div>
				{children}
			</div>
		</div>
	);
}
