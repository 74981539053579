import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Alert, Button, Card, Input, message } from 'antd';
import { Auth } from 'aws-amplify';
import get from 'lodash/get';
import { Component } from 'react';
import uuid from 'uuid/v4';
import {
	InputStyles,
	addCompanyLabel,
	inputContainer,
	titleStyle,
} from './superUserCardStyles.js';

class SuperUserCard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			emailExists: false,
			serverError: false,
		};
	}

	handleSubmit = (e) => {
		e.preventDefault();
		const { addUser, users } = this.props;
		this.props.form.validateFields((error, values) => {
			if (error) {
				return;
			}

			if (users.find((user) => user.email === values.email)) {
				this.setState({ emailExists: true });
			} else {
				this.setState({ emailExists: false });

				Auth.signUp({
					username: uuid(),
					password: values.password,
					attributes: {
						email: values.emailAddress,
					},
				})
					.then((data) => {
						addUser({
							input: {
								cognitoId: data.user.username,
								emailAddress: get(values, 'emailAddress', '').toLowerCase(),
								role: 'employee',
								departmentId: ' ',
								avatar: null,
								lastLogin: null,
								active: true,
							},
						}).then(() => {
							message.config({
								top: 40,
							});
							message.success('Superuser successfully created!', 2.5, () => {
								this.props.form.resetFields();
							});
						});
					})
					.catch((error) => {
						this.setState({ serverError: true });
						console.error(error);
					});
			}
		});
	};

	render() {
		const { emailExists, serverError } = this.state;
		const { getFieldDecorator } = this.props.form;
		const FormItem = Form.Item;
		return (
			<Card style={{ width: '45%', border: '0' }}>
				<div className={addCompanyLabel}>Add a New SuperUser</div>
				<Form>
					<FormItem className={InputStyles}>
						<label className={titleStyle}>Admin Email Address</label>
						{getFieldDecorator('email', {
							rules: [
								{
									required: true,
									message: 'Email address required',
								},
							],
						})(<Input className={inputContainer} />)}
					</FormItem>
					<FormItem className={InputStyles}>
						<label className={titleStyle}>Password</label>
						{getFieldDecorator('password', {
							rules: [
								{
									required: true,
									message: 'Password required',
								},
							],
						})(<Input className={inputContainer} />)}
					</FormItem>
					{emailExists ? (
						<Alert type="error" message="Email is already in use" />
					) : null}
					{serverError ? <Alert type="error" message="Server error" /> : null}
					<FormItem style={{ float: 'right', marginTop: '10px' }}>
						<Button
							type="primary"
							htmlType="submit"
							onClick={this.handleSubmit}
						>
							Submit
						</Button>
					</FormItem>
				</Form>
			</Card>
		);
	}
}

export default Form.create()(SuperUserCard);
