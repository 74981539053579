/**
 * <HeaderBar />
 */

function HeaderBar({ data, editModeOn, parent, onDestroy }) {
	return (
		<div className="toolbar-header">
			<span className="sortable-item-badge">{data.text}</span>
			<ul className="toolbar-header-buttons">
				{data.element !== 'LineBreak' && (
					<li className="toolbar-btn" onClick={editModeOn.bind(parent, data)}>
						<i className="icon-edit" />
					</li>
				)}
				<li className="toolbar-btn danger" onClick={onDestroy.bind(this, data)}>
					<i className="icon-bin" />
				</li>
			</ul>
		</div>
	);
}

export default HeaderBar;
