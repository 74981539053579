import debounce from 'lodash/debounce';
import { useCallback } from 'react';

/**
 * Returns a debounced callback function that persists through re-renders.
 *
 * @param func  The function to call
 * @param delay The time to wait before calling the function
 */
export function useDebouncedCallback(function_, delay = 250) {
	return useCallback(debounce(function_, delay), [function_, delay]);
}
