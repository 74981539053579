import { css } from 'emotion';
import get from 'lodash/get';
import { COLORS } from '../../_shared/styles/colors';

const breakpoints = [576, 767, 991, 1200];

const mq = breakpoints.map((bp) => `@media (max-width: ${bp}px)`);

export const SubmitBtn = (theme) => {
	let buttonColor = COLORS.blue;
	if (get(theme, 'enabled')) {
		buttonColor = get(theme, 'buttonColor', buttonColor);
	}

	return css({
		fontFamily: 'Open Sans',
		backgroundColor: `${buttonColor} !important`,
		borderColor: `${buttonColor} !important`,
		color: 'white !important',
		fontSize: 20,
		fontWeight: 300,
		padding: '10px 30px',
		height: 50,
		border: 'none',
		marginBottom: 0,
		width: 200,
		'&:hover': {
			borderColor: `${buttonColor} !important`,
		},
		'&:focus': {
			borderColor: `${buttonColor} !important`,
		},
	});
};

export const SubmitBtnInput = (theme) => {
	let buttonColor = COLORS.blue;
	if (get(theme, 'enabled')) {
		buttonColor = get(theme, 'buttonColor', buttonColor);
	}

	return css({
		fontFamily: 'Open Sans',
		backgroundColor: `${buttonColor} !important`,
		borderColor: `${buttonColor} !important`,
		color: 'white !important',
		fontSize: 16,
		fontWeight: 300,
		marginBottom: -12,
		height: 40,
		border: 'none',
		width: 150,
		'&:hover': {
			borderColor: `${buttonColor} !important`,
		},
		'&:focus': {
			borderColor: `${buttonColor} !important`,
		},
	});
};

export const SubmitBtnContainer = css({
	'& .ant-legacy-form-item-control': {
		width: '100%',
		marginTop: 10,
		display: 'flex',
		justifyContent: 'center',
		marginBottom: 0,
		'& .ant-form-item-control-wrapper': {
			'@media (max-width: 575px)': {
				width: 'auto',
			},
		},
		'& .ant-btn:hover': {
			backgroundColor: COLORS.lightGray2,
		},
	},
});

export const BtnContainer = css({
	width: '100%',
	display: 'flex',
	justifyContent: 'center',
	marginBottom: 0,
	'& .ant-form-item-control-wrapper': {
		'@media (max-width: 575px)': {
			width: 'auto',
		},
	},
});

export const CheckIcon = css({
	marginLeft: 5,
	marginTop: -1,
});

export const LabelStyles = css({
	fontFamily: '"Open Sans", sans-serif',
	fontSize: '16px !important',
	fontWeight: 600,
});

export const OrStyle = css({
	fontFamily: '"Open Sans", sans-serif',
	color: '#595959 !important',
	width: '100%',
	display: 'flex',
	justifyContent: 'center',
	fontSize: 16,
	fontWeight: 400,
	paddingRight: 3,
	paddingLeft: 3,
	paddingBottom: 0,
	flexWrap: 'wrap',

	'& .divider-text': {
		color: 'rebeccapurple',
		'::before': {
			borderTop: '1px solid #e8e8e8',
			content: '""ABCDEFG" !important"',
			display: 'table-cell',
			position: 'relative',
			top: '50%',
			transform: 'translateY(50%)',
			width: '500px',
		},
	},

	'& .divider-text::after': {
		borderTop: '1px solid #e8e8e8',
		content: '"ABCDEFG"',
		display: 'table-cell',
		position: 'relative',
		top: '50%',
		transform: 'translateY(50%)',
		width: '500px',
	},
});

export const InputStyles = css({
	fontFamily: '"Open Sans", sans-serif',
	fontSize: 16,
	'& .ant-input': {
		height: 40,
		fontFamily: '"Open Sans", sans-serif',
		fontSize: 16,
	},
	'& .ant-form-explain': {
		fontSize: 12,
	},
});

export const InputStylesHidden = css({
	display: 'none',
});

export const FormStyles = css({
	'& .ant-form-item': {
		marginBottom: 10,
	},
});

export const ForgotPasswordBtn = css({
	color: COLORS.blue,
	border: 'none',
	backgroundColor: 'none',
	padding: 0,
	margin: 'auto',
	textAlign: 'center',
});

export const AlertError = css({
	marginBottom: 20,
});

export const miscText = css({
	fontStyle: 'italic',
	color: COLORS.lightGray,
});

export const titleText = css({
	fontSize: 22,
	fontWeight: 600,
	fontFamily: '"Open Sans", sans-serif',
});

export const modalContainerStyles = css({
	'& .ant-modal-close-x': {
		fontSize: 30,
		color: COLORS.lightGray,
	},
	'& .ant-modal-header': {
		borderBottom: 'none',
		marginTop: 20,
	},
});

export const inputWrap = css({
	display: 'flex',
	margin: 'auto',
	[mq[0]]: {
		flexDirection: 'column',
	},
});

export const inputStyle = css({
	width: 298,
	marginRight: 6,
	[mq[0]]: {
		width: '100%',
		marginBottom: 10,
	},
});
