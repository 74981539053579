import gql from 'graphql-tag';

export const queryCampaignsByCompanyIdIndex = `
  query QueryCampaignsByCompanyIdIndex(
    $companyId: ID!
    $first: Int
    $after: String
  ) {
    queryCampaignsByCompanyIdIndex(
      companyId: $companyId
      first: $first
      after: $after
    ) {
      items {
        id
        companyId
        endDate
        jobId
        job {
          title
        }
        makeHotJob
        jobIds
        name
        startDate
        archived
        tieredBonusId
        tieredBonus {
          name
        }
      }
      nextToken
    }
  }
`;
