import gql from 'graphql-tag';

export const GetDepartmentByName = gql`
	query GetDepartmentByName($companyId: ID!, $name: String!) {
		getDepartmentByName(companyId: $companyId, name: $name) {
			items {
				id
				companyId
				name
				keywords
				externalDepartmentId
				active
				totalUsers
			}
			nextToken
		}
	}
`;
