import React from 'react';
import { useSelector } from "react-redux";
import { Spinner } from "../_shared/index.js";
import ManageRewardContainer from './container/manageRewardContainer';
import { ManageRewardsServiceProvider } from './services/manageRewards.service.jsx';

export const ManageRewards = () => {
	const company = useSelector((state) => state.user.currentUser.company)
	if (!company) return <Spinner />
	return (
		<ManageRewardsServiceProvider>
			<ManageRewardContainer />
		</ManageRewardsServiceProvider>
	);
};

export default ManageRewards;
