import { useSortable } from '@dnd-kit/sortable';
import { useEffect, useState } from 'react';
import { Item } from '../item';

const useMountStatus = () => {
	const [isMounted, setIsMounted] = useState(false);

	useEffect(() => {
		const timeout = setTimeout(() => setIsMounted(true), 500);
		return () => clearTimeout(timeout);
	}, []);

	return isMounted;
};

export function SortableItem({
	disabled,
	id,
	value,
	index,
	handle,
	renderItem,
	style,
	containerId,
	getIndex,
	wrapperStyle,
	handleEdit,
}) {
	const {
		setNodeRef,
		setActivatorNodeRef,
		listeners,
		isDragging,
		isSorting,
		over,
		overIndex,
		transform,
		transition,
	} = useSortable({
		id,
		transition: {
			duration: 250,
			easing: 'cubic-bezier(0.25, 1, 0.5, 1)',
		},
	});
	const mounted = useMountStatus();
	const mountedWhileDragging = isDragging && !mounted;

	return (
		<Item
			ref={disabled ? undefined : setNodeRef}
			key={id}
			value={value}
			dragging={isDragging}
			sorting={isSorting}
			handle={handle}
			handleProps={handle ? { ref: setActivatorNodeRef } : undefined}
			index={index}
			wrapperStyle={wrapperStyle({ index })}
			style={{
				index,
				value: id,
				isDragging,
				isSorting,
				overIndex: over ? getIndex(over.id) : overIndex,
				containerId,
				...style,
			}}
			transition={transition}
			transform={transform}
			fadeIn={mountedWhileDragging}
			listeners={listeners}
			renderItem={renderItem}
			handleEdit={handleEdit}
		/>
	);
}
