import { useEffect, useRef, useState } from 'react';
import { Modal, message } from 'antd';
import dayjs from 'dayjs';
import { useApprovalRewardsService } from '../../service/ApprovalRewards.service.jsx';
import { getColumnSearchProps } from '../../../approvalsBonus/presentation/filterSearch.jsx';
import { addCommas } from '../../../rewardsAndRecognitionHelper/utils.js';
import ApprovalRewardsTable from '../../presentation/approvalRewardsTable/ApprovalRewardsTable.jsx';

const PendingApprovalContainer = (props) => {
	const {
		isPendingReward,
		isTableRefresh,
		setTableRefresh,
		pendingRecordId,
		handleDeclineClick,
		handleApprovalCLick,
		handleApproveAndDeclined,
		isApprove,
		isDecline,
		setIsApprove,
		setIsDecline,
	} = props;

	const searchInput = useRef(null);

	const [pendingApproval, setPendingApprovals] = useState();
	const [isLoading, setIsLoading] = useState(false);

	const approvalRewardsAPIService = useApprovalRewardsService();

	useEffect(() => {
		if (isTableRefresh) {
			setTableRefresh(false);
			getPendingRewards();
		}
	}, [isTableRefresh]);

	const getPendingRewards = async () => {
		setIsLoading(true);
		approvalRewardsAPIService
			.getApprovalRewardsList()
			.then((response) => {
				if (response?.code === 200) {
					setIsLoading(false);
					if (response?.data) {
						setPendingApprovals(response?.data);
						setTableRefresh(false);
					}
				} else {
					setIsLoading(false);
					message.error('Failed to fetch pending list');
				}
			})
			.catch((error) => {
				setIsLoading(false);
				console.log(error);
				message.error('Failed to fetch pending list');
			});
	};

	const handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
	};

	const handleReset = (clearFilters) => {
		clearFilters();
	};

	const columns = [
		{
			title: 'RECIPIENT',
			dataIndex: 'recipient',
			key: 'recipient',
			...getColumnSearchProps(
				'recipient',
				searchInput,
				handleSearch,
				handleReset,
				'Recipient'
			),
		},
		{
			title: 'BONUS',
			dataIndex: 'bonus',
			key: 'bonus',
			render: (bonus) => (
				<span style={{ color: bonus === 0 ? 'inherit' : 'green' }}>
					{bonus === 0 ? 'None' : '$' + addCommas(bonus)}
				</span>
			),
		},
		{
			title: 'CREATED BY',
			dataIndex: 'createdBy',
			key: 'createdBy',
		},
		{
			title: 'RECOGNITION',
			dataIndex: 'reward',
			key: 'reward',
		},
		{
			title: 'CREATED',
			dataIndex: 'created',
			key: 'created',
			sorter: (a, b) => new Date(a?.created) - new Date(b?.created),
			render: (text) => dayjs(text).format('MM/DD/YYYY'),
		},
		{
			title: 'APPROVAL',
			key: 'approval',
			render: (_, record) => (
				<div className="d-flex align-items-center">
					<button
						className="approve-btn mr-2"
						onClick={() => {
							handleApprovalCLick(record);
						}}
					>
						Approve
					</button>
					<button
						className="decline-btn"
						onClick={() => {
							handleDeclineClick(record);
						}}
					>
						Decline
					</button>
				</div>
			),
		},
	];

	return (
		<div className="mb-4">
			<div className="page-title">
				<h2 className="page-heading-manage-reward">Pending Approval</h2>
			</div>
			<ApprovalRewardsTable
				isLoading={isLoading}
				columns={columns}
				approvedData={pendingApproval}
				isPendingReward={isPendingReward}
				emptyMessage="No recognitions are pending approval."
			/>
			<Modal
				destroyOnClose
				width={420}
				open={isApprove}
				title="Confirm Approval"
				footer={false}
				className="confirmation-modal"
				onOk={false}
				onCancel={() => {
					setIsApprove(false);
				}}
			>
				<>
					<p className="text-center modal-detail">
						Are you sure you want to approve?
					</p>
					<div className="text-center">
						<button
							className="approve-btn"
							onClick={() =>
								handleApproveAndDeclined(
									pendingRecordId,
									'Approved'
								)
							}
						>
							Approve
						</button>
					</div>
				</>
			</Modal>
			<Modal
				destroyOnClose
				width={420}
				title="Decline Approval"
				open={isDecline}
				footer={false}
				className="confirmation-modal"
				onOk={false}
				onCancel={() => {
					setIsDecline(false);
				}}
			>
				<>
					<p className="text-center modal-detail">
						Are you sure you want to decline?
					</p>
					<div className="text-center">
						<button
							className="decline-btn"
							onClick={() => {
								handleApproveAndDeclined(
									pendingRecordId,
									'Declined'
								);
							}}
						>
							Decline
						</button>
					</div>
				</>
			</Modal>
		</div>
	);
};

export default PendingApprovalContainer;
