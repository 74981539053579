import React from "react";
import { useSelector } from "react-redux";
import { Spinner } from "../_shared/index.js";
import DashboardContainer from "./container/dashboardContainer/DashboardContainer.jsx";
import { HomeServiceProvider } from './service/home.services.jsx'

const Home = (props) => {
  const company = useSelector((state) => state.user.currentUser.company)
  if (!company) return <Spinner />
  return <>
    <HomeServiceProvider>
      <DashboardContainer />
    </HomeServiceProvider>
  </>
};

export default Home;
