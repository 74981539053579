import { css } from 'emotion';
import { COLORS } from '../../_shared/styles/colors';

const breakpoints = [576, 767, 991, 1200];

const mq = breakpoints.map((bp) => `@media (max-width: ${bp}px)`);

export const SaveAndDeleteContainer = css({
	position: 'absolute',
	top: '20px',
	right: '20px',
});

export const SettingsContainer = css({
	fontFamily: '"Open Sans", sans-serif',
	width: '100%',
	height: '100%',
	padding: 20,
	backgroundColor: COLORS.lightGray2,
	margin: 0,
	[mq[2]]: {
		padding: '20px 5px',
	},
});

export const SubmitBtnContainer = css({
	display: 'flex',
	justifyContent: 'center',
	padding: 10,
	marginTop: 30,
	'& .ant-btn: hover': {
		color: COLORS.blue,
		fontWeight: 300,
		border: `2px solid ${COLORS.blue}`,
	},
});

export const BonusLabel = css({
	fontWeight: 550,
	fontSize: 18,
	paddingRight: 5,
	paddingLeft: 5,
});

export const BonusLabelSmall = css({
	fontWeight: 550,
	fontSize: 16,
	paddingRight: 5,
	paddingLeft: 5,
});

export const Card = css({
	borderRadius: '10px !important',
	margin: '20px 0px',
	padding: '15px 0',
	backgroundColor: 'white',
	height: 'auto',
	'& button:focus': {
		outline: 'none',
	},
	maxWidth: 1270,
});

export const link = css({
	fontFamily: '"Open Sans", sans-serif',
	color: COLORS.blue,
});

export const BackLink = css({
	...link,
	border: 'none',
	backgroundColor: 'transparent',
	paddingLeft: 5,
	fontSize: 14,
	fontWeight: '600 !important',
});

export const BackIcon = css({
	fontSize: 14,
});

export const FlexContainer = css({
	width: '100%',
});

export const ToolBar = css({
	marginBottom: '14px',
});

export const addBonusButtonText = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 600,
	color: COLORS.black,
	fontSize: 18,
	verticalAlign: '-webkit-baseline-middle',
});

export const headerClass = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 600,
	color: COLORS.black,
	fontSize: 18,
});

export const noData = css({
	width: '100%',
	textAlign: 'center',
	marginTop: 40,
});

export const FlexContainerGroupCurrency = css({
	marginBottom: 30,
	width: '100%',
	maxWidth: 1290,
	height: 'auto',
});

export const paymentLabel = css({
	fontFamily: '"Open Sans", sans-serif',
	fontSize: 16,
	fontWeight: 550,
	color: COLORS.black,
});

export const SelectContainer = css({
	display: 'flex',
	height: 40,
	marginLeft: 15,
});

export const SelectStyles = css({
	'& .ant-select-arrow': {
		display: 'none',
	},
});

export const iconContainer = css({
	'& svg': {
		transform: 'translate(-40px, 8px)',
	},
});

export const tableHeader = css({
	fontFamily: '"Open Sans", sans-serif !important',
	fontWeight: '600 !important',
});
export const tierInputClass = css({
	fontSize: 16,
	height: 40,
	padding: 4,
	marginBottom: 10,
	width: '200px !important',
	[mq[1]]: {
		marginBottom: 0,
		width: '100% !important',
	},
});

export const addButtonClass = (theme) => {
	const buttonColor = COLORS.blue;
	return css({
		textAlign: 'center',
		color: `${buttonColor} !important`,
		marginTop: 4,
		marginLeft: 25,
		fontSize: 14,
		display: 'flex',
		borderRadius: 5,
		paddingTop: 5,
	});
};

export const groupRow = css({
	display: 'flex',
	flexWrap: 'wrap',
	[mq[1]]: {
		flexDirection: 'column',
		borderBottom: '2px solid #c5c5c5',
		paddingBottom: 30,
		marginBottom: 30,
		'& .ant-form-item': {
			marginBottom: 0,
		},
	},
});

export const iconWrap = css({
	display: 'flex',
});

export const deleteButtonClass = css({
	fontSize: '18px',
	backgroundColor: 'transparent',
	color: COLORS.red,
	borderColor: 'transparent',
	boxShadow: 'none',
	margin: '4px 0px 0px 10px',
	'&:hover': {
		color: COLORS.red,
		backgroundColor: 'transparent !important',
		borderColor: 'transparent !important',
	},
});
