import { Button, Row } from 'antd';
import {
	openButtonStyles,
	toggleButtonContainerStyles,
	toggleTitleStyles,
} from './toggleEmailPhoneStyles.js';

export function ToggleEmailPhone(props) {
	const { onClick, referralType, contact, visible } = props;

	return (contact && contact.emailAddress) ||
		(contact && contact.phoneNumber) ||
		visible ? (
		<Row className={toggleButtonContainerStyles}>
			<span className={toggleTitleStyles}>Send By:</span>
			{(contact && contact.emailAddress) || visible ? (
				<Button
					className={openButtonStyles(referralType === 'email')}
					onClick={() => onClick('email')}
				>
					Email
				</Button>
			) : null}
			{(contact && contact.phoneNumber) || visible ? (
				<Button
					className={openButtonStyles(referralType === 'text')}
					style={{ marginRight: 8 }}
					onClick={() => onClick('text')}
				>
					Text
				</Button>
			) : null}
		</Row>
	) : null;
}
