import { Alert, Button, Form, Input, Modal } from 'antd';
import { Auth } from 'aws-amplify';
import gql from 'graphql-tag';
import Cookies from 'js-cookie';
import { Component, createRef } from 'react';
import { withApollo } from 'react-apollo';
import { updateUser } from 'src/_shared/api/graphql/custom/users/';
import validator from 'validator';

const FormItem = Form.Item;

class ResetProfilePasswordModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			matchError: false,
			lengthError: false,
			newError: false,
			submitError: false,
			oldPasswordError: false,
			success: false,
		};
		this.formRef = createRef();
	}

	handleSubmit = (values) => {
		if (values.password === values.oldPassword) {
			this.setState({ newError: true });
			setTimeout(() => {
				this.formRef.current.resetFields();
				this.removeAlerts();
			}, 1000);
			return;
		}

		if (values.password) {
			if (
				validator.isStrongPassword(values.password, {
					minLength: 10,
					minLowercase: 1,
					minUppercase: 1,
					minNumbers: 1,
					minSymbols: 1,
				})
			) {
				console.log('Password is okay');
			} else {
				setTimeout(() => {
					this.formRef.current.resetFields();
					this.removeAlerts();
				}, 4000);
				this.setState({ lengthError: true });
				return;
			}
		}

		Auth.currentAuthenticatedUser()
			.then((user) => {
				return Auth.changePassword(user, values.oldPassword, values.password);
			})
			.then(() => {
				const jwt = Cookies.get('jwt');
				this.props.client.mutate({
					mutation: gql(updateUser),
					variables: {
						input: {
							id: this.props.userId,
							expires: true,
							expirationDoneByToken: jwt,
						},
					},
				});
				this.setState({ success: true });
				setTimeout(() => {
					this.formRef.current.resetFields();
					this.removeAlerts();
				}, 1000);
			})
			.catch((error) => {
				if (error.code === 'NotAuthorizedException') {
					this.setState({ oldPasswordError: true });
				} else {
					this.setState({ submitError: true });
				}

				setTimeout(() => {
					this.formRef.current.resetFields();
					this.removeAlerts();
				}, 1000);
				console.error(error);
			});
	};

	onFinishFailed = (error) => {
		this.setState({ submitError: true });
		setTimeout(() => {
			this.formRef.current.resetFields();
			this.removeAlerts();
		}, 1000);
	};

	removeAlerts = () => {
		this.setState({
			matchError: false,
			lengthError: false,
			newError: false,
			submitError: false,
			oldPasswordError: false,
			success: false,
		});
	};

	render() {
		const { visible, handlePasswordCancel } = this.props;
		const {
			matchError,
			lengthError,
			submitError,
			newError,
			oldPasswordError,
			success,
		} = this.state;
		return (
			<Modal
				destroyOnClose
				open={visible}
				title="Reset Password"
				footer={null}
				onCancel={handlePasswordCancel}
			>
				<>
					<Form
						ref={this.formRef}
						onFinish={this.handleSubmit}
						onFinishFailed={this.onFinishFailed}
					>
						<Form.Item
							name="oldPassword"
							rules={[
								{
									required: true,
									message: 'Please input your current password!',
								},
							]}
						>
							<div className="custom-form-group">
								<label className="custom-label">Current Password</label>
								<Input
									type="password"
									className="custom-input"
									placeholder=""
								/>
							</div>
						</Form.Item>
						<Form.Item
							name="password"
							rules={[
								{
									required: true,
									message: 'Please input a new password!',
								},
							]}
						>
							<div className="custom-form-group">
								<label className="custom-label">New Password</label>
								<Input
									type="password"
									className="custom-input"
									placeholder=""
								/>
							</div>
						</Form.Item>
						{matchError ? (
							<Alert
								type="error"
								message="Passwords Do Not Match! Please Try Again."
							/>
						) : null}
						{newError ? (
							<Alert
								type="error"
								message="New password can not be the same as current password."
							/>
						) : null}
						{lengthError ? (
							<Alert
								type="error"
								message="Your password must have minimum 10 characters, at least one upper case, one lower case letter, a number and a special character."
							/>
						) : null}
						{submitError ? (
							<Alert
								message="We are having trouble submitting the form, please try again later."
								type="error"
							/>
						) : null}
						{oldPasswordError ? (
							<Alert
								type="error"
								message="Old password is incorrect. Please try again."
							/>
						) : null}
						{success ? (
							<Alert message="Password successfully changed!" type="success" />
						) : null}

						<div className="modal-footer-btn">
							<Button
								htmlType="submit"
								type="primary"
								className="btn-min-width"
								size="large"
							>
								Reset Password
							</Button>
						</div>
					</Form>
				</>
			</Modal>
		);
	}
}

export default withApollo(ResetProfilePasswordModal);
