import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Col, Input, Modal, Popconfirm, Row } from 'antd';
import { ml } from 'src/_shared/services/utils.js';
import { PageItem } from 'src/manageJobs/addJobModal/pages/';

const { TextArea } = Input;

function EditJobForm({
	visible,
	onOk,
	onCancel,
	currentUser,
	allMultiLingualData,
	job,
	index,
	handleJobEdit,
	form,
	deleteUserJob,
}) {
	const { getFieldDecorator, getFieldValue, validateFields, resetFields } =
		form;

	const handleSubmit = () => {
		validateFields((error, values) => {
			if (error) {
				console.error(error);
				return;
			}

			onOk();
		});
	};

	const handleCancel = () => {
		resetFields();
		onCancel();
	};

	const footerButtons = [
		<Popconfirm
			key={'deletePopconfirm' + index}
			title="Are you sure you want to delete this position?"
			okText="Yes"
			cancelText="No"
			onConfirm={async () => await deleteUserJob(index)}
		>
			<Button key={'deleteButton' + index} type="danger" size="large">
				Delete
			</Button>
		</Popconfirm>,

		<Button
			key={'cancelButton' + index}
			size="large"
			onClick={() => handleCancel()}
		>
			Cancel
		</Button>,

		<Button
			key={'saveButton' + index}
			type="primary"
			size="large"
			onClick={() => handleSubmit()}
		>
			Save
		</Button>,
	];

	return (
		<Modal
			key={'modal' + index}
			centered
			open={visible}
			okText="Save"
			title="Edit Job"
			footer={footerButtons}
			onCancel={() => handleCancel()}
			onOk={() => handleSubmit()}
		>
			<Form>
				<div>
					<Row gutter={32}>
						<Col sm={12} xs={24}>
							<div className="custom-form-group">
								<PageItem
									title={ml('Position', currentUser, allMultiLingualData)}
								>
									<Form.Item>
										{getFieldDecorator('position', {
											rules: [
												{
													required: true,
													whitespace: true,
													message: 'Please enter your position!',
												},
											],
											initialValue: job.title,
										})(
											<Input
												id="editedPosition"
												name="editedPosition"
												placeholder={ml(
													'Position',
													currentUser,
													allMultiLingualData
												)}
												className="custom-input"
												onChange={handleJobEdit('title', index)}
											/>
										)}
									</Form.Item>
								</PageItem>
							</div>
						</Col>
						<Col sm={12} xs={24}>
							<div className="custom-form-group">
								<PageItem
									title={ml('Company', currentUser, allMultiLingualData)}
								>
									<Form.Item>
										{getFieldDecorator('company', {
											rules: [
												{
													required: true,
													whitespace: true,
													message: 'Please enter your company!',
												},
											],
											initialValue: job.name,
										})(
											<Input
												name="editedOrg"
												placeholder={ml(
													'Organization',
													currentUser,
													allMultiLingualData
												)}
												className="custom-input"
												onChange={handleJobEdit('name', index)}
											/>
										)}
									</Form.Item>
								</PageItem>
							</div>
						</Col>
					</Row>

					<Row gutter={32}>
						<Col sm={12} xs={24}>
							<div className="custom-form-group">
								<PageItem
									title={ml('Start Date', currentUser, allMultiLingualData)}
								>
									<Form.Item>
										{getFieldDecorator('startDate', {
											rules: [
												{
													required: true,
													message: 'Please enter your start date!',
												},
												{
													validator(rule, value, callback) {
														const endDate = getFieldValue('endDate');

														if (value && endDate && value > endDate) {
															return callback(
																'Start Date must be before End Date'
															);
														}

														callback();
													},
												},
											],
											initialValue: job.start.fullString,
										})(
											<Input
												name="startDate"
												placeholder={ml(
													'Start Date',
													currentUser,
													allMultiLingualData
												)}
												type="date"
												className="custom-input"
												onChange={handleJobEdit('start', index)}
											/>
										)}
									</Form.Item>
								</PageItem>
							</div>
						</Col>
						<Col sm={12} xs={24}>
							<div className="custom-form-group">
								<PageItem
									title={ml('End Date', currentUser, allMultiLingualData)}
								>
									<Form.Item>
										{getFieldDecorator('endDate', {
											rules: [
												{
													required: false,
												},
												{
													validator(rule, value, callback) {
														const startDate = getFieldValue('startDate');

														if (value && startDate && value < startDate) {
															return callback(
																'End Date must be after Start Date'
															);
														}

														callback();
													},
												},
											],
											initialValue: job.end.fullString,
										})(
											<Input
												name="endDate"
												placeholder={ml(
													'End Date',
													currentUser,
													allMultiLingualData
												)}
												type="date"
												className="custom-input"
												onChange={handleJobEdit('end', index)}
											/>
										)}
									</Form.Item>
								</PageItem>
							</div>
						</Col>
					</Row>

					<div className="custom-form-group">
						<PageItem
							title={ml('Description', currentUser, allMultiLingualData)}
						>
							<TextArea
								autoSize={{ minRows: 2, maxRows: 6 }}
								name="editedDescription"
								placeholder="Description"
								value={job.description}
								className="custom-input"
								onChange={handleJobEdit('description', index)}
							/>
						</PageItem>
					</div>
				</div>
			</Form>
		</Modal>
	);
}

const EnhancedEditJobForm = Form.create()(EditJobForm);

export default EnhancedEditJobForm;
