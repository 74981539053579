import gql from 'graphql-tag';

export default gql`
	query QueryReferralsByUserIdReferralTypeIndex(
		$userId: String!
		$referralType: String!
		$limit: Int
		$nextToken: String
	) {
		queryReferralsByUserIdReferralTypeIndex(
			userId: $userId
			referralType: $referralType
			limit: $limit
			nextToken: $nextToken
		) {
			items {
				customStatus
				id
				job {
					id
					internalJobLink
					title
				}

				referralDate
				referralType
				status
				userId
			}
			nextToken
		}
	}
`;
