import { css } from 'emotion';
import { COLORS } from '../../../_shared/styles/colors';

export const container = (role) => css({});

export const headerClass = css({
	fontSize: 16,
	textTransform: 'uppercase',
	letterSpacing: 0.96,
	fontWeight: 300,
	color: `${COLORS.heading} !important`,
	marginBottom: 16,
});

export const middleContainers = css({
	height: 80,
	width: 200,
	margin: 'auto',
	marginTop: 15,
	marginBottom: 13,
});

export const middleContainer = css({
	height: 80,
	width: 200,
	margin: 'auto',
	marginTop: 50,
	marginBottom: 60,
});

export const middleColumn = css({
	textAlign: 'center',
	width: 90,
});

export const middleColumnEmployee = css({
	textAlign: 'center',
	width: '100%',
});

export const bonusStyles = css({
	fontFamily: '"Open Sans", sans-serif',
	color: COLORS.green,
	margin: 'auto',
	fontSize: 16,
	fontWeight: 500,
	display: 'inline',
});

export const linkStyles = css({
	fontFamily: '"Open Sans", sans-serif',
	color: COLORS.blue,
	fontWeight: 800,
	fontSize: 16,
	border: 'none',
	boxShadow: 'none',
});

export const bigNumbers = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 800,
	color: COLORS.black,
	fontSize: '2em',
});

export const bigNumbersOpen = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 800,
	color: COLORS.dashboardDarkOrange,
	fontSize: '2em',
});

export const numberSubtitles = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 600,
	color: COLORS.white,
	fontSize: 14,
});

export const numberSubtitlesOpen = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 600,
	color: COLORS.lightGray,
	fontSize: 14,
	width: 70,
	margin: 'auto',
});

export const numSubtitles = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 600,
	color: COLORS.white,
	fontSize: 12,
	paddingBottom: '5px',
});

export const matchBox = css({
	textAlign: 'center',
	width: 90,
	backgroundColor: COLORS.dashboardLightOrange,
	borderRadius: '10%',
	float: 'right',
	paddingBottom: 10,
});

export const smallIcons = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 600,
	color: COLORS.subHeading,
	fontSize: 14,
	opacity: 0.7,
});

export const divider = css({
	height: 1,
	backgroundColor: COLORS.subHeading,
	opacity: 0.4,
	margin: 'auto',
});

export const bottomContainer = css({
	height: 60,
	marginTop: 32,
	marginBottom: 17,
});

export const bottomContainers = css({
	height: 60,
	marginTop: 27,
	marginBottom: 8,
});

export const bottomSectionText = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 600,
	color: COLORS.heading,
	fontSize: 14,
	marginLeft: 5,
	opacity: 0.7,
});

export const bottomSectionValue = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 800,
	color: COLORS.black,
	fontSize: 14,
	float: 'right',
});
