import { connect } from 'react-redux';
import ReferralHiredModalComponent from './ReferralHiredModalComponent.jsx';

const mapStateToProperties = (state, ownProperties) => {
	const { currentUser } = state.user;
	return {
		currentUser,
	};
};

export default connect(mapStateToProperties)(ReferralHiredModalComponent);
