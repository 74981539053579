import get from 'lodash/get';
import { connect } from 'react-redux';
import { withMyContacts } from 'src/_shared/api/components/contacts/';
import { withGetCompanyData } from 'src/_shared/api/components/settings/';
import {
	dashboardActions,
	navigationActions,
	userActions,
} from 'src/actions.js';
import { compose } from '../../services/utils';
import { AsideComponent } from './AsideComponent.jsx';

const mapStateToProperties = (state) => {
	const {
		navigation,
		user: { currentUser },
	} = state;
	const { allMultiLingualData } = state.dashboard;
	return {
		id: get(currentUser, 'id'),
		currentUser,
		currentLocation: navigation.currentLocation,
		companyId: get(currentUser, 'companyId'),
		allMultiLingualData,
	};
};

const mapDispatchToProperties = (dispatch) => {
	return {
		updatePathname(pathname) {
			dispatch(navigationActions.createUpdatePathname(pathname));
		},
		setCurrentUser(user) {
			dispatch(userActions.createSetCurrentUserAction(user));
		},
		setMultiLingualData(data) {
			dispatch(dashboardActions.createSetMultiLingualData(data));
		},
		updateDisplay(role) {
			dispatch(userActions.updateDisplayAs(role));
		},
	};
};

export const AsideApi = compose(
	withGetCompanyData,
	withMyContacts,
)(AsideComponent);

export const Aside = connect(
	mapStateToProperties,
	mapDispatchToProperties
)(AsideApi);
