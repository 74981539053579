import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import get from 'lodash/get';
import { Component } from 'react';
import OnDeckJobModal from './OnDeckJobModalComponent.jsx';

class JobModalWrapper extends Component {
	constructor(props) {
		super(props);
		this.state = {
			jobs: props.jobs,
			contacts: props.contacts,
			contact: props.contact,
			visible: false,
			referrals: props.referrals,
			theme: JSON.parse(get(props, 'currentUser.company.theme', '{}')),
		};
	}

	componentDidUpdate(prevProps) {
		if (prevProps.contacts !== this.props.contacts) {
			this.setState({ contacts: this.props.contacts });
		}
	}

	handleCancel = () => {
		this.setState({ visible: false });
	};

	showModal = () => {
		this.setState({ visible: true });
	};

	render() {
		const { contacts, visible, contact, theme } = this.state;
		return (
			<>
				<Button
					shape="circle"
					size="large"
					type="primary"
					icon={<PlusOutlined />}
					onClick={this.showModal}
				/>
				<OnDeckJobModal
					{...this.props}
					contact={contact}
					contacts={contacts}
					visible={visible}
					handleCancel={this.handleCancel}
				/>
			</>
		);
	}
}

export default JobModalWrapper;
