import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { createDepartment } from 'src/_shared/api/graphql/default';

export const withCreateDepartment = (Component) => {
	return graphql(gql(createDepartment), {
		props: (props) => ({
			onCreateDepartment(input) {
				return props
					.mutate({
						variables: input,
					})
					.then((resp) => resp.data.createDepartment);
			},
		}),
	})(Component);
};
