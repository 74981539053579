import get from 'lodash/get';
import WebIcon from 'src/_shared/components/WebIconComponent.jsx';
import { borderedTile } from '../../../_shared/components/bordered-tile';
import { COLORS } from '../../../_shared/styles/colors';
import {
	centeredText,
	grayHeaderClass,
	jobTitleText,
} from '../referral-card/referralCardStyle.js';

function RecentReferenceCard(props) {
	const lastReferral = get(props, 'referrals[0]', null);
	const location =
		lastReferral === null
			? null
			: JSON.parse(get(lastReferral, 'job.location', '{}'));
	return (
		<div style={{ height: 165 }}>
			<h2 className={grayHeaderClass} style={{ marginBottom: 20 }}>
				Recent Referral
			</h2>
			{get(props, 'referrals', []).length > 0 ? (
				<>
					<div className={jobTitleText}>{get(lastReferral, 'job.title')}</div>
					<div className={centeredText}>
						{get(lastReferral, 'company.name')}
					</div>
					<div className={centeredText}>
						<WebIcon color={COLORS.darkGray} size={18} name="placeholder" />
						<span style={{ color: COLORS.darkGray, fontSize: 12 }}>
							{location.isRemote === true
								? 'Remote'
								: get(location, 'city') + ', ' + get(location, 'state')}
						</span>
					</div>
				</>
			) : (
				<div className={centeredText}>No Referrals Yet</div>
			)}
		</div>
	);
}

export const RecentReferralCard = borderedTile(RecentReferenceCard);
