import React, { useRef } from "react";
import { Table } from 'antd';
import formatDateInTable from '../../../rewardsAndRecognitionHelper/CommonDate.js'
import './PhysicalRewardTable.scss';
import { CONFIRMATION_STATUS, STATUS } from "../../models/physicalRewardsConstants.js";
import { getColumnSearchProps } from "../../../approvalsBonus/presentation/filterSearch.jsx";
import NoDataState from "../../../RnRCommonComponent/noDataState/NoDataState.jsx";

const PhysicalRewardsListTable = (props) => {

  const { physicalRewardsList, handleOpenViewReward, handleOnPageChange, isTableLoaderVisible } = props;

  const searchInput = useRef(null);

  const getColorClasses = (type) => {
    switch (type) {
      case CONFIRMATION_STATUS.PENDING:
      case STATUS.PENDING:
        return "pending-text";

      case CONFIRMATION_STATUS.CONFIRMED:
      case STATUS.SENT:
        return "confirmed-text";

      case CONFIRMATION_STATUS.EXPIRED:
      case STATUS.NOT_SENT:
      case CONFIRMATION_STATUS.DECLINED:
      case STATUS.DECLINED:
        return "Expired-text";

    }
  }

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  }

  const handleReset = (clearFilters) => {
    clearFilters();
  };

  const columns = [
    {
      title: 'RECIPIENT',
      dataIndex: 'employeeFirstName',
      key: 'employeeFirstName',
      render: (text, record) => <>{`${record.employeeFirstName} ${record.employeeLastName}`}</>,
      ...getColumnSearchProps('employeeFirstName', searchInput, handleSearch, handleReset, "Recipient")
    },
    {
      title: 'ITEM ID',
      dataIndex: 'itemId',
      key: 'itemId',
    },
    {
      title: 'REWARD NAME',
      dataIndex: 'badgeName',
      key: 'badgeName',
      render: (text, record) => <a className="user-name" onClick={() => handleOpenViewReward(record.badgeId)}>{text}</a>,
    },
    {
      title: 'AWARDED',
      dataIndex: 'awardedAt',
      key: 'awardedAt',
      render: (date) => <div>{date && formatDateInTable(date)}</div>
    },
    {
      title: 'CONFIRMATION',
      dataIndex: 'confirmation',
      key: 'confirmation',
      render: (text) => <div className={getColorClasses(text)}>{text}</div>
    },
    {
      title: 'CONFIRMED',
      dataIndex: 'confirmationDate',
      key: 'confirmationDate',
      render: (date) => <div>{date ? formatDateInTable(date) : "-"}</div>
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      key: 'status',
      render: (text) => <div className={getColorClasses(text)}>{text}</div>
    }
  ];

  return <div className="physical-reward-table table-card">
    <Table
      loading={isTableLoaderVisible}
      columns={columns}
      dataSource={physicalRewardsList}
      onChange={handleOnPageChange}
      locale={{ emptyText: <NoDataState title={"No physical rewards sent."} /> }}
      pagination={{ pageSize: 10, showSizeChanger: false }}
    />
  </div>;
};

export default PhysicalRewardsListTable;
