import { Alert, Button, Form, Input, Modal, message } from 'antd';
import get from 'lodash/get';
import { Component, createRef } from 'react';
import { lambda } from 'src/_shared/services/utils.js';
import {
	AlertError,
	FormStyles,
	ModalContainer,
	ModalSubTitle,
	ModalTitle,
	SubmitBtnContainer as SubmitButtonContainer,
} from './resetPasswordModalStyles.js';

class SupportResetPasswordModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			lengthError: false,
			newError: false,
			submitError: false,
			success: false,
		};
		this.formRef = createRef();
	}

	handleSubmit = async (values) => {
		if (values.password.length < 8) {
			setTimeout(() => {
				this.formRef.current.resetFields();
				this.removeAlerts();
			}, 2000);
			this.setState({ lengthError: true });
			return;
		}

		const endpoint = 'support-reset-email-and-password-prod-resetUser';
		await lambda({
			endpoint,
			variables: {
				userId: get(this.props, 'employee.id'),
				email: values.email,
				password: values.password,
				env: 'prod',
			},
		})
			.then(() => message.success(`Temporary Password Reset Success!`))
			.catch((error) => console.log(error));
	};

	onFinishFailed = (error) => {
		console.error(error);
		this.setState({ submitError: true });
		setTimeout(() => {
			this.formRef.current.resetFields();
			this.removeAlerts();
		}, 2000);
	};

	removeAlerts = () => {
		this.setState({
			lengthError: false,
			newError: false,
			submitError: false,
			success: false,
		});
	};

	render() {
		const { employee, visible, handleCancel } = this.props;
		const { lengthError, submitError, success } = this.state;
		const employeeName =
			get(employee, 'firstName') && get(employee, 'lastName')
				? `${get(employee, 'firstName')} ${get(employee, 'lastName')}`
				: 'Employee';
		return (
			<Modal
				destroyOnClose
				open={visible}
				footer={null}
				width={650}
				onCancel={handleCancel}
			>
				<div className={ModalContainer}>
					<h1 className={ModalTitle}>Reset Employee Email / Password</h1>
					<h1 className={ModalSubTitle}>Employee: {employeeName}</h1>
					<Form
						ref={this.formRef}
						className={FormStyles}
						initialValues={{
							email: get(employee, 'emailAddress', ''),
							password: 'reset123',
						}}
						onFinish={this.handleSubmit}
						onFinishFailed={this.onFinishFailed}
					>
						<div>New Email:</div>
						<Form.Item
							name="email"
							rules={[
								{
									required: true,
									message: 'Please input a new email!',
								},
							]}
						>
							<Input placeholder="Enter Email Address" />
						</Form.Item>
						<div>Temporary Password:</div>
						<Form.Item
							name="password"
							rules={[
								{
									required: true,
									message: 'Please input a new password!',
								},
							]}
						>
							<Input placeholder="Enter New Temporary Password" />
						</Form.Item>
						{lengthError ? (
							<Alert
								className={AlertError}
								type="error"
								message="New password must be at least 6 characters long."
							/>
						) : null}
						{submitError ? (
							<Alert
								className={AlertError}
								message="We are having trouble submitting the form, please try again later."
								type="error"
							/>
						) : null}
						{success ? (
							<Alert
								className={AlertError}
								message="Password Successfully Reset!"
								type="success"
							/>
						) : null}
						<Form.Item className={SubmitButtonContainer}>
							<Button danger type="primary" htmlType="submit">
								Reset Password
							</Button>
						</Form.Item>
					</Form>
				</div>
			</Modal>
		);
	}
}

export default SupportResetPasswordModal;
