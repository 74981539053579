export const updateUserGroup = `mutation UpdateUserGroup($input: UpdateUserGroupInput!) {
    updateUserGroup(input: $input) {
      id
      companyId
      measurement
      name
      keywords
      active
      currency
      languageCode
    }
  }
  `;
