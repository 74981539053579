import {
	CheckCircleOutlined,
	CloseCircleOutlined,
	EditOutlined,
} from '@ant-design/icons';
import { Button, Input, Popconfirm, Radio, message, Skeleton } from 'antd';
import dompurify from 'dompurify';
import { get, toNumber } from 'lodash';
import { Component } from 'react';
import { withApollo } from 'react-apollo';
import RichTextEditor from 'react-rte';
import Spinner from 'src/_shared/components/spinner/SpinnerComponent.jsx';
import { USER_ROLES } from '../_shared/constants';
import { ml } from '../_shared/services/utils.js';
import { queryCustomPageByCompanyIdIndex } from '../_shared/api/graphql/custom/custom-page';
import { customPageWrapper } from './customPageStyles.js';

const sanitizer = dompurify.sanitize;
const { TextArea } = Input;
class CustomPageComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			edit: false,
			customPageData: [],
			htmlData: '',
			showHTML: true,
			editorState: 1,
			shouldFetchNewData: false,
			contentLoading: true,
		};
	}

	async componentDidMount() {
		const customPageData = await this.getCustomPageData();
		const pageHtmlData = customPageData.find((element) => {
			return (
				(element.pageNumber ? element.pageNumber : 1) ===
				toNumber(this.props.match.params.customPageId)
			);
		});
		this.setState({
			customPageData,
			htmlData: pageHtmlData ? pageHtmlData.content : '',
		});
	}

	async componentDidUpdate(prevProps) {
		let customPageData = [];
		const { customPageId } = this.props.match.params;

		customPageData =
			this.state.customPageData[0]?.companyId ===
			this.props?.currentUser?.companyId
				? this.state.customPageData
				: await this.getCustomPageData();

		if (prevProps.match.params.customPageId != customPageId) {
			const pageHtmlData = customPageData.find((element) => {
				return (
					(element.pageNumber ? element.pageNumber : 1) ===
					toNumber(this.props.match.params.customPageId)
				);
			});
			this.setState({
				customPageData,
				htmlData:
					this.state.edit && this.state.editorState === 1
						? pageHtmlData
							? RichTextEditor.createValueFromString(
									pageHtmlData?.content,
									'html'
								)
							: RichTextEditor.createEmptyValue()
						: pageHtmlData
							? pageHtmlData.content
							: '',
			});
		}
	}

	async getCustomPageData(policy = 'network-only') {
		const { client, currentUser } = this.props;
		try {
			const { data } = await client.query({
				query: queryCustomPageByCompanyIdIndex,
				variables: {
					companyId: get(currentUser, 'companyId'),
				},
				fetchPolicy: policy,
			});
			const customPageData = get(
				data,
				'queryCustomPageByCompanyIdIndex.items',
				[]
			);
			this.setState({ contentLoading: false });
			return customPageData;
		} catch (error) {
			console.log(error);
			return [];
		}
	}

	onChange = (e) => {
		const pageHtmlData = this.state.customPageData.find((element) => {
			return (
				(element.pageNumber ? element.pageNumber : 1) ===
				toNumber(this.props.match.params.customPageId)
			);
		});

		if (e.target.value === 1) {
			this.setState({
				editorState: e.target.value,
				htmlData: pageHtmlData
					? RichTextEditor.createValueFromString(pageHtmlData.content, 'html')
					: RichTextEditor.createEmptyValue(),
				showHTML: true,
			});
		} else if (e.target.value === 2) {
			this.setState({
				editorState: e.target.value,
				showHTML: false,
				htmlData: pageHtmlData ? pageHtmlData.content : '',
			});
		}
	};

	onChangeRTE = (e) => {
		this.setState({
			htmlData: e.target.value,
		});
	};

	onChangeRTEditor = (value) => {
		this.setState({
			htmlData: value,
		});
	};

	cancelEdit = () => {
		message.error('Edit Cancelled');
		const pageHtmlData = this.state.customPageData.find((element) => {
			return (
				(element.pageNumber ? element.pageNumber : 1) ===
				toNumber(this.props.match.params.customPageId)
			);
		});
		this.setState({
			edit: false,
			showHTML: true,
			htmlData: pageHtmlData ? pageHtmlData.content : '',
		});
	};

	editInfo = async () => {
		let customPageData = [];
		if (this.state.shouldFetchNewData) {
			customPageData = await this.getCustomPageData();
			this.setState({
				customPageData,
				shouldFetchNewData: false,
			});
		} else {
			customPageData = this.state.customPageData;
		}

		const pageHtmlData = customPageData.find((element) => {
			return (
				(element.pageNumber ? element.pageNumber : 1) ===
				toNumber(this.props.match.params.customPageId)
			);
		});
		this.setState({
			edit: true,
			htmlData:
				this.state.editorState === 1
					? RichTextEditor.createValueFromString(this.state.htmlData, 'html')
					: pageHtmlData
						? pageHtmlData.content
						: '',
		});
	};

	handleSubmit = async () => {
		try {
			const { htmlData, customPageData } = this.state;

			const { currentUser, onAddCustomPage, onUpdateCustomPage } = this.props;

			const pageHtmlData = customPageData.find((element) => {
				return (
					(element.pageNumber ? element.pageNumber : 1) ===
					toNumber(this.props.match.params.customPageId)
				);
			});

			if (pageHtmlData) {
				onUpdateCustomPage({
					input: {
						id: pageHtmlData.id,
						companyId: get(currentUser, 'companyId'),
						content: htmlData.toString('html'),
						userId: get(currentUser, 'id'),
						pageNumber: pageHtmlData.pageNumber
							? pageHtmlData.pageNumber
							: this.props.match.params.customPageId,
					},
				});
			} else {
				onAddCustomPage({
					input: {
						companyId: get(currentUser, 'companyId'),
						content: htmlData.toString('html'),
						userId: get(currentUser, 'id'),
						pageNumber: this.props.match.params.customPageId,
					},
				});
			}

			message.success('Information was submitted successfully.');
			this.setState({
				edit: false,
				htmlData: htmlData.toString('html'),
				shouldFetchNewData: true,
			});
		} catch (error) {
			console.log(error);
		}
	};

	htmlDecode = (input) => {
		let returnValue = input.replaceAll('&gt;', '>');
		returnValue = returnValue.replaceAll('&lt;', '<');
		returnValue = returnValue.replaceAll('&quot;', '"');
		returnValue = returnValue.replaceAll('&apos;', "'");
		returnValue = returnValue.replaceAll('&amp;', '&');
		return returnValue;
	};

	render() {
		const { htmlData } = this.state;

		const { currentUser, allMultiLingualData } = this.props;
		if (!get(currentUser, 'company')) return <Spinner />;
		const customPageTitle =
			get(currentUser, 'company.customPageTitle') || 'Custom Page';
		const customPageTitle2 =
			get(currentUser, 'company.customPageTitle2') || 'Custom Page 2';
		const customPageTitle3 =
			get(currentUser, 'company.customPageTitle3') || 'Custom Page 3';

		return (
			<main>
				<div className="page-title">
					<h2 className="page-heading">
						{this.props.match.params.customPageId == 1 && customPageTitle}
						{this.props.match.params.customPageId == 2 && customPageTitle2}
						{this.props.match.params.customPageId == 3 && customPageTitle3}
					</h2>
					{currentUser.displayAs === USER_ROLES.ADMIN && (
						<ul className="info-action">
							{this.state.edit ? (
								<>
									<li>
										<Popconfirm
											title="Confirm Updates?"
											placement="left"
											okText="Yes"
											cancelText="No"
											onConfirm={() => this.handleSubmit()}
										>
											<Button
												block
												type="default"
												icon={<CheckCircleOutlined />}
												size="large"
											>
												Save
											</Button>
										</Popconfirm>
									</li>
									<li>
										<Popconfirm
											title="Discard edits?"
											placement="left"
											okText="Yes"
											cancelText="No"
											onConfirm={this.cancelEdit}
										>
											<Button
												block
												type="default"
												icon={<CloseCircleOutlined />}
												size="large"
											>
												Cancel
											</Button>
										</Popconfirm>
									</li>
								</>
							) : (
								<li>
									<Button
										type="primary"
										icon={<EditOutlined />}
										size="large"
										onClick={this.editInfo}
									>
										EDIT
									</Button>
								</li>
							)}
						</ul>
					)}
				</div>
				{this.state.contentLoading ? <Skeleton active /> : null}
				{this.state.edit && (
					<div style={{ marginBottom: 16 }}>
						<Radio.Group
							buttonStyle="solid"
							size="small"
							value={this.state.editorState}
							onChange={this.onChange}
						>
							<Radio.Button value={1}>Text Editor</Radio.Button>
							<Radio.Button value={2}>HTML Editor</Radio.Button>
						</Radio.Group>
					</div>
				)}

				{currentUser.displayAs === USER_ROLES.ADMIN ? (
					this.state.edit ? (
						this.state.editorState === 1 ? (
							<RichTextEditor
								value={htmlData}
								onChange={this.onChangeRTEditor}
							/>
						) : (
							<TextArea
								placeholder={ml(
									'Enter your HTML here',
									currentUser,
									allMultiLingualData
								)}
								rows={20}
								value={htmlData}
								onChange={this.onChangeRTE}
							/>
						)
					) : (
						<div className={customPageWrapper}>
							<div
								dangerouslySetInnerHTML={{
									__html: sanitizer(this.htmlDecode(this.state.htmlData)),
								}}
								className="custom-page-wrapper"
							/>
						</div>
					)
				) : (
					<div className={customPageWrapper}>
						<div
							dangerouslySetInnerHTML={{
								__html: sanitizer(this.htmlDecode(this.state.htmlData)),
							}}
							className="custom-page-wrapper"
						/>
					</div>
				)}
			</main>
		);
	}
}

export default withApollo(CustomPageComponent);
