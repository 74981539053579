export const getIntlDayMonthYearParts = () => {
	const localeFromWindow = window.navigator.language;
	const options = { year: 'numeric', month: 'long', day: 'numeric' };
	const dateTimeFormat = new Intl.DateTimeFormat(localeFromWindow, options);

	const newDate = new Date();
	const formattedDateParts = dateTimeFormat.formatToParts(newDate);
	const day = formattedDateParts[2].value; // Ex. "10"
	const month = formattedDateParts[0].value; // Ex. "March"
	const year = formattedDateParts[4].value; // Ex. "2023"

	return { day, month, year };
};
