import { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Table, Switch, Modal, Tooltip } from 'antd';
import { useHistory } from 'react-router-dom';
import { LeftOutlined, DownloadOutlined } from '@ant-design/icons';
import VoterListModalModal from './VoterListModalModal';
import formatDateInTable from '../../../rewardsAndRecognitionHelper/CommonDate';
import { isDateLessThanOrEqualToCurrent } from '../../utils.js';
import './style.scss';
import NoDataState from '../../../RnRCommonComponent/noDataState/NoDataState.jsx';

const SelectWinnerPresentation = ({
	selectWinner: nomineeArray,
	nominationConfig,
	loading,
	selectWinnerFunc,
	nomineeVoterList,
	getNomineeVoterListById,
	getNominationCSV,
	handleOpenViewReward,
	rewardDetails,
}) => {
	const history = useHistory();

	const [selectedWinner, setSelectedWinner] = useState([]);
	const [showVoterListModal, setShowVoterListModal] = useState({
		show: false,
		voterList: [],
	});

	const columns = [
		{
			title: 'NOMINEE',
			dataIndex: 'nominee',
			key: 'nominee',
		},
		{
			title: 'NOMINEE BRANCH',
			dataIndex: 'nomineeBranch',
			key: 'nomineeBranch',
		},
		{
			title: 'DATE NOMINATED',
			dataIndex: 'dateNominated',
			key: 'dateNominated',
			sorter: (a, b) => new Date(a.dateNominated) - new Date(b.dateNominated),
			render: (dateNominated) => {
				return <span>{formatDateInTable(dateNominated)}</span>;
			},
		},
		{
			title: 'NOMINATOR',
			dataIndex: 'nominator',
			key: 'nominator',
			render: (status) => {
				return (
					<span className={`status-${status.toLowerCase()}`}>{status}</span>
				);
			},
		},
		{
			title: 'NOMINATIONS',
			dataIndex: 'votes',
			key: 'votes',
			sorter: (a, b) => a.votes - b.votes,
			render: (votes, rawContent) => {
				return true ? (
					<Button
						type="link"
						onClick={() => {
							setShowVoterListModal({
								show: true,
								nomineeId: rawContent.nomineeId,
							});
						}}
					>
						{votes}
					</Button>
				) : (
					<span className={``}>None</span>
				);
			},
		},
		{
			title: 'NOMINATION REASON',
			dataIndex: 'nominationReason',
			key: 'nominationReason',
			render: (winner) => {
				return winner ? (
					<span
						style={{
							wordWrap: 'break-word',
							fontSize: '14px',
							lineHeight: '18px',
							fontWeight: 600,
						}}
					>
						{winner}
					</span>
				) : (
					<span className={``}>None</span>
				);
			},
		},
		{
			title: 'WINNER',
			dataIndex: 'id',
			key: 'id',
			render: (id, rawContent) => {
				let temp = selectedWinner;
				const currentSelected = temp.includes(id);
				return (
					<>
						<Tooltip title={nominationConfig?.status === 'Open' ? "Nomination period is still open, winners can not be selected until after the period closes." : ""}>
							<Switch
								className={currentSelected ? 'action-undo' : 'action-select'}
								onChange={() => {
									if (currentSelected) {
										temp = temp.filter((temp_id) => temp_id != id);
									} else {
										temp.push(id);
									}
									setSelectedWinner([...temp]);
								}}
								checked={
									nominationConfig?.status === 'Submitted'
										? rawContent?.isWinner
										: selectedWinner?.includes(id)
								}
								disabled={currentSelected ? false : disableSelection}
								type={currentSelected ? 'primary' : 'default'}
							>
								Select
							</Switch>
						</Tooltip>
					</>
				);
			},
		},
	];

	const nomineeCount = useMemo(() => {
		return nominationConfig ? nominationConfig?.awardedCount : '-';
	}, [nominationConfig]);

	const disableSelection = useMemo(() => {
		return (
			selectedWinner?.length === nomineeCount ||
			nominationConfig?.status !== 'Complete' ||
			!isDateLessThanOrEqualToCurrent(nominationConfig?.endDate)
		);
	}, [nomineeCount, selectedWinner, nominationConfig]);

	const showSubmitButton = useMemo(() => {
		return (
			(nomineeArray?.length &&
				selectedWinner?.length === nomineeArray?.length) ||
			(selectedWinner?.length && selectedWinner?.length <= nomineeCount)
		);
	}, [nomineeCount, selectedWinner, nomineeArray]);

	const confirm = useCallback(() => {
		Modal.confirm({
			title: 'Confirm Winner Selection',
			content: <div>Winners for '{rewardDetails?.name}' have been selected. Are you sure you want to proceed?<br /> <div style={{ fontStyle: "italic" }}>Please note that changes cannot be made after submission.</div></div>,
			okText: 'Proceed',
			cancelText: 'Cancel',
			onOk: () => {
				selectWinnerFunc(selectedWinner);
			},
		});
	}, [rewardDetails?.name, selectedWinner]);

	const confirmLessSelcted = useCallback(() => {
		Modal.confirm({
			title: 'Confirm Submission',
			content: `Are you sure you want to submit without selecting the appropriate number of winners? You will not be able to make changes after submission.`,
			okText: 'Submit',
			cancelText: 'Cancel',
			onOk: () => {
				confirm();
			},
		});
	}, [rewardDetails?.name, selectedWinner]);

	useEffect(() => {
		nominationConfig?.badge?.id &&
			handleOpenViewReward(nominationConfig?.badge?.id);
	}, [nominationConfig?.badge?.id]);

	return (
		<>
			<div className="main select-winner-presentation">
				<div className={'bread-crubs'}>
					<Button
						type="link"
						onClick={() => {
							history.push('/nominations');
						}}
					>
						<LeftOutlined className="bread-crubs-text" />
						<span className="bread-crubs-text">Nomination Periods</span>
					</Button>
				</div>
				<div className="page-title">
					<div className="d-flex">
						<h2 className="page-heading">{rewardDetails?.name}</h2>
						{nominationConfig?.status !== 'Submitted' ? (
							<Button type="link">
								{`${selectedWinner?.length || 0} / ${nomineeCount} Winners Chosen`}
							</Button>
						) : (
							<></>
						)}
					</div>
					<div className="d-flex">
						<Button
							type="link"
							disabled={nominationConfig?.status !== 'Complete' ||
								!isDateLessThanOrEqualToCurrent(nominationConfig?.endDate)
							}
							onClick={() => {
								getNominationCSV();
							}}
							className="btn-link"
						>
							<i className="icon-export btn-large" />
							<span className="page-heading-left">Export CSV</span>
						</Button>
					</div>
				</div>
			</div>
			<div className="nomination-rewards-table-container">
				<div className="nomination-rewards-table table-card">
					<Table
						rowKey={(record) => record.id}
						dataSource={nomineeArray}
						pagination={false}
						loading={loading}
						columns={columns}
						locale={{
							emptyText: <NoDataState title={'No nominees available.'} />,
						}}
					/>
				</div>
				{showSubmitButton && nominationConfig.status != 'Submitted' ? (
					<div className="p-3 d-flex jc-end">
						<Button
							type="primary"
							onClick={() => {
								selectedWinner?.length < nomineeCount
									? confirmLessSelcted()
									: confirm();
							}}
						>
							<span className="bread-crubs-text">Submit</span>
						</Button>
					</div>
				) : (
					<></>
				)}

				{showVoterListModal.show ? (
					<VoterListModalModal
						showModal={showVoterListModal.show}
						setShowModal={setShowVoterListModal}
						nomineeVoterList={nomineeVoterList}
						nominationConfigId={nominationConfig.id}
						getNomineeVoterListById={getNomineeVoterListById}
						nomineeId={showVoterListModal.nomineeId}
					/>
				) : (
					<></>
				)}
			</div>
		</>
	);
};

export default SelectWinnerPresentation;
