import { Modal } from 'antd';
import { Component } from 'react';
import { ml } from 'src/_shared/services/utils.js';
import CloseJobForm from './close-job-form/CloseJobFormComponent.jsx';
import { FormStyles } from './closeJobModalStyles.js';

export class CloseJob extends Component {
	constructor(props) {
		super(props);
		this.state = {
			error: false,
		};
	}

	handleError = () => {
		this.setState({ error: true });
	};

	render() {
		const { allMultiLingualData, visible, handleCancel, currentUser } =
			this.props;
		const { error } = this.state;

		return (
			<Modal
				title={ml('Close This Job', currentUser, allMultiLingualData)}
				open={visible}
				footer={null}
				onCancel={handleCancel}
			>
				<p className="text-center">
					{ml(
						'Closing this job means you are no longer accepting referrals.',
						currentUser,
						allMultiLingualData
					)}{' '}
					{ml(
						'You can re-open the job at any time.',
						currentUser,
						allMultiLingualData
					)}
				</p>
				<h4 className="ant-modal-sub-title text-center">
					{ml('Would you like to also', currentUser, allMultiLingualData)}:
				</h4>
				<CloseJobForm
					allMultiLingualData={allMultiLingualData}
					currentUser={currentUser}
					className={FormStyles}
					error={error}
					handleError={this.handleError}
					referrals={this.props.referrals}
					job={this.props.job}
					onUpdateJob={this.props.onUpdateJob}
					onUpdateReferral={this.props.onUpdateReferral}
					onCancel={handleCancel}
				/>
			</Modal>
		);
	}
}
