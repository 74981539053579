import { Component } from 'react';
import ToolbarItem from './ToolbarDraggableItem.jsx';
import ID from './UUID.js';
import store from './stores/store.js';

export default class Toolbar extends Component {
	static _defaultItemOptions(element) {
		switch (element) {
			case 'Dropdown': {
				return [
					{
						value: 'place_holder_option_1',
						text: 'Place holder option 1',
						key: `dropdown_option_${ID.uuid()}`,
					},
					{
						value: 'place_holder_option_2',
						text: 'Place holder option 2',
						key: `dropdown_option_${ID.uuid()}`,
					},
					{
						value: 'place_holder_option_3',
						text: 'Place holder option 3',
						key: `dropdown_option_${ID.uuid()}`,
					},
				];
			}

			case 'Tags': {
				return [
					{
						value: 'place_holder_tag_1',
						text: 'Place holder tag 1',
						key: `tags_option_${ID.uuid()}`,
					},
					{
						value: 'place_holder_tag_2',
						text: 'Place holder tag 2',
						key: `tags_option_${ID.uuid()}`,
					},
					{
						value: 'place_holder_tag_3',
						text: 'Place holder tag 3',
						key: `tags_option_${ID.uuid()}`,
					},
				];
			}

			case 'Checkboxes': {
				return [
					{
						value: 'place_holder_option_1',
						text: 'Place holder option 1',
						key: `checkboxes_option_${ID.uuid()}`,
					},
					{
						value: 'place_holder_option_2',
						text: 'Place holder option 2',
						key: `checkboxes_option_${ID.uuid()}`,
					},
					{
						value: 'place_holder_option_3',
						text: 'Place holder option 3',
						key: `checkboxes_option_${ID.uuid()}`,
					},
				];
			}

			case 'RadioButtons': {
				return [
					{
						value: 'place_holder_option_1',
						text: 'Place holder option 1',
						key: `radiobuttons_option_${ID.uuid()}`,
					},
					{
						value: 'place_holder_option_2',
						text: 'Place holder option 2',
						key: `radiobuttons_option_${ID.uuid()}`,
					},
					{
						value: 'place_holder_option_3',
						text: 'Place holder option 3',
						key: `radiobuttons_option_${ID.uuid()}`,
					},
				];
			}

			default: {
				return [];
			}
		}
	}

	constructor(props) {
		super(props);

		const items = this.props.items ? this.props.items : this._defaultItems();
		this.state = {
			items,
		};
		store.subscribe((state) => this.setState({ store: state }));
	}

	_defaultItems() {
		return [
			{
				key: 'Header',
				name: 'Header Text',
				icon: 'fas fa-heading',
				static: true,
				content: 'Placeholder Text...',
			},
			{
				key: 'Label',
				name: 'Label',
				static: true,
				icon: 'fas fa-font',
				content: 'Placeholder Text...',
			},
			{
				key: 'Paragraph',
				name: 'Paragraph',
				static: true,
				icon: 'fas fa-paragraph',
				content: 'Placeholder Text...',
			},
			{
				key: 'LineBreak',
				name: 'Line Break',
				static: true,
				icon: 'fas fa-arrows-alt-h',
			},
			{
				key: 'Dropdown',
				canHaveAnswer: true,
				name: 'Dropdown',
				icon: 'far fa-caret-square-down',
				label: 'Placeholder Label',
				field_name: 'dropdown_',
				options: [],
			},
			{
				key: 'Tags',
				canHaveAnswer: true,
				name: 'Tags',
				icon: 'fas fa-tags',
				label: 'Placeholder Label',
				field_name: 'tags_',
				options: [],
			},
			{
				key: 'Checkboxes',
				canHaveAnswer: true,
				name: 'Checkboxes',
				icon: 'far fa-check-square',
				label: 'Placeholder Label',
				field_name: 'checkboxes_',
				options: [],
			},
			{
				key: 'RadioButtons',
				canHaveAnswer: true,
				name: 'Multiple Choice',
				icon: 'far fa-dot-circle',
				label: 'Placeholder Label',
				field_name: 'radiobuttons_',
				options: [],
			},
			{
				key: 'TextInput',
				canHaveAnswer: true,
				name: 'Text Input',
				label: 'Placeholder Label',
				icon: 'fas fa-font',
				field_name: 'text_input_',
			},
			{
				key: 'NumberInput',
				canHaveAnswer: true,
				name: 'Number Input',
				label: 'Placeholder Label',
				icon: 'fas fa-plus',
				field_name: 'number_input_',
			},
			{
				key: 'TextArea',
				canHaveAnswer: true,
				name: 'Multi-line Input',
				label: 'Placeholder Label',
				icon: 'fas fa-text-height',
				field_name: 'text_area_',
			},
			{
				key: 'Image',
				name: 'Image',
				label: '',
				icon: 'far fa-image',
				field_name: 'image_',
				src: '',
			},
			{
				key: 'Rating',
				canHaveAnswer: true,
				name: 'Rating',
				label: 'Placeholder Label',
				icon: 'fas fa-star',
				field_name: 'rating_',
			},
			{
				key: 'DatePicker',
				canDefaultToday: true,
				canReadOnly: true,
				dateFormat: 'MM/dd/yyyy',
				timeFormat: 'hh:mm aa',
				showTimeSelect: false,
				showTimeSelectOnly: false,
				name: 'Date',
				icon: 'far fa-calendar-alt',
				label: 'Placeholder Label',
				field_name: 'date_picker_',
			},
			{
				key: 'Signature',
				canReadOnly: true,
				name: 'Signature',
				icon: 'fas fa-pen-square',
				label: 'Signature',
				field_name: 'signature_',
			},
			{
				key: 'HyperLink',
				name: 'Web site',
				icon: 'fas fa-link',
				static: true,
				content: 'Placeholder Web site link ...',
				href: 'http://www.example.com',
			},
			{
				key: 'Download',
				name: 'File Attachment',
				icon: 'fas fa-file',
				static: true,
				content: 'Placeholder file name ...',
				field_name: 'download_',
				file_path: '',
				_href: '',
			},
			{
				key: 'Range',
				name: 'Range',
				icon: 'fas fa-sliders-h',
				label: 'Placeholder Label',
				field_name: 'range_',
				step: 1,
				default_value: 3,
				min_value: 1,
				max_value: 5,
				min_label: 'Easy',
				max_label: 'Difficult',
			},
			{
				key: 'Camera',
				name: 'Camera',
				icon: 'fas fa-camera',
				label: 'Placeholder Label',
				field_name: 'camera_',
			},
		];
	}

	_onClick(item) {
		store.dispatch('create', this.create(item));
	}

	create = (item) => {
		const elementOptions = {
			id: ID.uuid(),
			element: item.element || item.key,
			text: item.name,
			static: item.static,
			required: false,
			showDescription: item.showDescription,
		};

		if (this.props.showDescription === true && !item.static) {
			elementOptions.showDescription = true;
		}

		if (item.static) {
			elementOptions.bold = false;
			elementOptions.italic = false;
		}

		if (item.canHaveAnswer) {
			elementOptions.canHaveAnswer = item.canHaveAnswer;
		}

		if (item.canReadOnly) {
			elementOptions.readOnly = false;
		}

		if (item.canDefaultToday) {
			elementOptions.defaultToday = false;
		}

		if (item.content) {
			elementOptions.content = item.content;
		}

		if (item.href) {
			elementOptions.href = item.href;
		}

		elementOptions.canHavePageBreakBefore =
			item.canHavePageBreakBefore !== false;
		elementOptions.canHaveAlternateForm = item.canHaveAlternateForm !== false;
		elementOptions.canHaveDisplayHorizontal =
			item.canHaveDisplayHorizontal !== false;
		elementOptions.canHaveOptionCorrect = item.canHaveOptionCorrect !== false;
		elementOptions.canHaveOptionValue = item.canHaveOptionValue !== false;
		elementOptions.canPopulateFromApi = item.canPopulateFromApi !== false;

		if (item.key === 'Image') {
			elementOptions.src = item.src;
		}

		if (item.key === 'DatePicker') {
			elementOptions.dateFormat = item.dateFormat;
			elementOptions.timeFormat = item.timeFormat;
			elementOptions.showTimeSelect = item.showTimeSelect;
			elementOptions.showTimeSelectOnly = item.showTimeSelectOnly;
		}

		if (item.key === 'Download') {
			elementOptions._href = item._href;
			elementOptions.file_path = item.file_path;
		}

		if (item.key === 'Range') {
			elementOptions.step = item.step;
			elementOptions.default_value = item.default_value;
			elementOptions.min_value = item.min_value;
			elementOptions.max_value = item.max_value;
			elementOptions.min_label = item.min_label;
			elementOptions.max_label = item.max_label;
		}

		if (item.defaultValue) {
			elementOptions.defaultValue = item.defaultValue;
		}

		if (item.field_name) {
			elementOptions.field_name = item.field_name + ID.uuid();
		}

		if (item.label) {
			elementOptions.label = item.label;
		}

		if (item.options) {
			elementOptions.options = Toolbar._defaultItemOptions(
				elementOptions.element
			);
		}

		return elementOptions;
	};

	render() {
		return (
			<div className="fb-right-toolbar">
				<h4>Toolbox</h4>
				<ul>
					{this.state.items.map((item) => (
						<ToolbarItem
							key={item.key}
							data={item}
							onClick={this._onClick.bind(this, item)}
							onCreate={this.create}
						/>
					))}
				</ul>
			</div>
		);
	}
}
