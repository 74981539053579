import { Route } from 'react-router-dom';
import { RouterContext } from '../contexts';
import { useLocation } from 'react-router-dom';
import { TranslationProvider } from 'src/new-referral-landing-page/TranslationContext.js';

export function ByPassauthenticatedRoute(props) {
	const { component: Component } = props;
	const rest = { ...props };
	delete rest.component;
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const customLangCode = searchParams.get('lang') || null;
	const pageType = props.pageType;
	let sloth = false;
	rest.customLangCode = customLangCode;
	const host = window.location.hostname;
	if (
		host === 'testing.erinapp.com' ||
		host === 'erinapp-dev.netlify.app' ||
		host === 'erinapp-staging.netlify.app' ||
		host === 'localhost'
	) {
		sloth = Boolean(searchParams.get('🦥'));
	}
	return (
		<TranslationProvider initialLanguage="US" initialTranslations={{}}>
			<RouterContext.Consumer>
				{({ auth, currentUser = {} }) => (
					<Route
						{...rest}
						render={(props) => (
							<Component
								devMode={sloth}
								pageType={pageType}
								props={{ ...props }}
							/>
						)}
					/>
				)}
			</RouterContext.Consumer>
		</TranslationProvider>
	);
}
