import { Col, Typography, Tooltip, Popconfirm } from 'antd';
import { Link } from 'react-router-dom';
import { RetweetOutlined } from '@ant-design/icons';
import OwnershipPeriodTooltip from 'src/_shared/components/referral-card/OwnershipPeriodTooltip';

const { Paragraph } = Typography;

const getReferralIcon = (referralType) => {
	const referralIconMap = {
		email: <i className="icon-envelope-outline" style={{ fontSize: 18 }} />,
		text: <i className="icon-message-outline" style={{ fontSize: 18 }} />,
		share: <i className="icon-share" style={{ fontSize: 18 }} />,
	};
	return referralIconMap[referralType] || null;
};

const ReferralContact = ({
	contact,
	referralDate,
	referralType,
	ownershipSettings,
	handleResendReferral,
	status,
}) => (
	<Col className="referral-contact">
		<Paragraph className="referral-contact-paragraph">
			<Link to={`/mycontacts/${contact?.id}`}>
				<strong>
					{contact?.firstName} {contact?.lastName}
				</strong>
			</Link>
		</Paragraph>

		<div className="referral-date-wrap">
			{getReferralIcon(referralType)}
			<Tooltip
				placement="top"
				title={`Referred on ${referralDate} by ${referralType ?? ''}`}
			>
				<span className="referral-date">{referralDate}</span>
			</Tooltip>
			<OwnershipPeriodTooltip ownershipSettings={ownershipSettings} />

			{status === 'referred' && (
				<Popconfirm
					title="Resend referral?"
					onConfirm={handleResendReferral}
					okText="Yes"
					cancelText="No"
				>
					<Tooltip placement="top" title="Resend referral">
						<RetweetOutlined className="resend-icon" />
					</Tooltip>
				</Popconfirm>
			)}
		</div>
	</Col>
);

export default ReferralContact;
