export function CompanyName({ currentUser }) {
	const { company, subCompany } = currentUser;

	return (
		<>
			{company && company.id !== 'd5a17493-1e50-4a38-afa5-99623951d72e' ? (
				<div className="company-name">
					{subCompany ? subCompany?.name : company.name}
				</div>
			) : (
				<div style={{ marginLeft: 5 }} />
			)}
		</>
	);
}
