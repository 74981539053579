import { css } from 'emotion';
import get from 'lodash/get';
import { COLORS } from '../styles/colors';

export const ReferralBtn = (theme) => {
	let buttonColor = COLORS.blue;
	if (get(theme, 'enabled')) {
		buttonColor = get(theme, 'buttonColor', buttonColor);
	}

	return css({
		fontFamily: '"Open Sans", sans-serif',
		fontWeight: 200,
		color: COLORS.white,
		background: buttonColor,
		padding: 8,
		fontSize: 14,
		display: 'flex',
		width: '100%',
		height: 40,
		borderRadius: '10px',
		border: `2px solid ${buttonColor}`,
	});
};

export const RippleButton = css({
	display: 'inline !important',
	overflow: 'hidden',
});

export const ReferralBtnMyReferrals = css({
	width: 170,
	height: 50,
	background: '#018dd3',
	color: 'white',
	margin: 5,
	fontSize: 16,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	borderRadius: '10px',
	border: '2px solid #018dd3',
});

export const BtnContainer = (theme, isAskButtonDisabled) => {
	let buttonColor = COLORS.blue;
	if (get(theme, 'enabled')) {
		buttonColor = get(theme, 'buttonColor', buttonColor);
	}

	const styles = {
		overflow: 'hidden',

		'& button:hover > div > svg > path': {
			fill: buttonColor,
		},
		'& button:focus > div > svg > path': {
			fill: buttonColor,
		},
		width: '100%',
		'& .ant-btn:hover': {
			backgroundColor: `${buttonColor} !important`,
			border: `2px solid ${buttonColor} !important`,
			color: '#fff !important',
		},
		'& .ant-btn:focus': {
			backgroundColor: `${buttonColor} !important`,
			border: `2px solid ${buttonColor} !important`,
			color: '#fff !important',
		},
	};

	if (isAskButtonDisabled) {
		delete styles['& .ant-btn:hover'];
	}

	return css(styles);
};

export const ReferralContactBtn = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 200,
	color: COLORS.white,
	background: COLORS.red,
	padding: 8,
	fontSize: 14,
	display: 'flex',
	width: 270,
	height: 40,
});

export const userIconCss = css({
	margin: '0 auto',
	display: 'flex',
});
