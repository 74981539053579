import { connect } from 'react-redux';
import { withCreateReferralMyReferrals } from 'src/_shared/api/components/referrals/';
import ReferralModalWrapper from '../../_shared/referral-modal/ReferralModalWrapper.jsx';
import { compose } from '../../_shared/services/utils.js';

const mapStateToProperties = (state) => {
	const { currentUser } = state.user;
	return {
		currentUser,
		filter: { userId: { eq: currentUser.id } },
		limit: 1000,
	};
};

const ReferralModalWithApi = compose(withCreateReferralMyReferrals)(
	ReferralModalWrapper
);

export const ReferralModal =
	connect(mapStateToProperties)(ReferralModalWithApi);
