import {
	Button,
	Form,
	Input,
	Modal,
	Popover,
	Select,
	Spin,
	Tooltip,
	Tabs,
	Switch,
} from 'antd';
import React, { useEffect, useState } from 'react';
import Sketch from '@uiw/react-color-sketch';
import {
	BADGE_APPROVAL,
	BADGE_VISIBILITY,
	MAPPED_BADGE_TYPE,
	SSO_Required_CONSTANTS,
} from '../../../RnRCommonComponent/CoreBadge/rewardsConstants.js';
import { CoreBadge } from '../../../RnRCommonComponent';
import BadgeIcon from '../../../RnRCommonComponent/CoreBadge/BadgeIcon/BadgeIcon.jsx';

const { TextArea } = Input;

const AddReward = (props) => {
	const {
		iconsList,
		badgeTypeList,
		isModalOpen,
		handleCloseModal,
		handleCreateBadge,
		badgeDetailsObject,
		setBadgeDetailsObject,
		badgeErrorObject,
		setBadgeErrorObject,
		isLoaderVisible,
		levelList,
		payTypeList,
		automation,
		setAutomation,
		automationRules,
	} = props;

	const [displayColorPicker, setDisplayColorPicker] = useState(false);
	const [tempColor, setTempColor] = useState(badgeDetailsObject.color);

	useEffect(() => {
		if (iconsList && badgeTypeList) {
			setBadgeDetailsObject({
				iconCode: iconsList[0]?.code,
				badgeTypeCode: badgeTypeList[0]?.code,
				color: '#FAE100',
				label: 'Label',
				visibility: BADGE_VISIBILITY[0]?.value,
				isPreApproved: BADGE_APPROVAL[0]?.value,
				points: 0,
			});
		}
	}, [iconsList, badgeTypeList, isModalOpen]);

	useEffect(() => {
		setTempColor(badgeDetailsObject.color);
	}, [badgeDetailsObject]);

	const badgeApproval = BADGE_APPROVAL?.map((item, index) => (
		<Select.Option value={item.value} key={index}>
			{item.label}
		</Select.Option>
	));

	const automationData = automationRules?.map((item) => (
		<Select.Option value={item} key={item}>
			{item}
		</Select.Option>
	));

	const badgeVisibility = BADGE_VISIBILITY?.map((item) => (
		<Select.Option value={item.value} key={item.label}>
			{item.label}
		</Select.Option>
	));

	const iconsListData = iconsList?.map((icon, index) => (
		<Select.Option value={icon.code} key={index}>
			<div className="d-flex align-items-center justify-content-start list-img">
				<BadgeIcon width={40} height={22} iconName={icon.code} />
				{icon.name}
			</div>
		</Select.Option>
	));

	const badgeTypeListData = badgeTypeList?.map((badge, index) => (
		<Select.Option value={badge.code} key={index}>
			{badge.name}
		</Select.Option>
	));

	const handleColorChange = (newColor) => {
		setTempColor(newColor.hex);
		setBadgeDetailsObject({
			...badgeDetailsObject,
			color: newColor.hex,
		})
	};

	const handleGradientChange = (gradientColor) => {
		setBadgeDetailsObject({
			...badgeDetailsObject,
			gradientColor: gradientColor.hex,
		})
	};

	const getLevelList = levelList?.map((item, index) => (
		<Select.Option value={item.id} key={index}>
			{item.level}
		</Select.Option>
	));

	const getPayTypeList = payTypeList?.map((item, index) => (
		<Select.Option value={item.id} key={index}>
			{item.payType}
		</Select.Option>
	));

	const wrapStyles = {
		position: 'relative',
	};

	const colorPicker = (
		<Sketch
			disableAlpha
			style={{ marginBottom: 16, boxShadow: 'none' }}
			color={tempColor}
			onChange={handleColorChange}
		/>
	);

	const gradientPicker = (
		<Sketch
			disableAlpha
			style={{ marginBottom: 16, boxShadow: 'none' }}
			color={badgeDetailsObject?.gradientColor || ''}
			onChange={handleGradientChange}
		/>
	);

	const tabItems = [
		{
			key: '1',
			label: 'Badge Details',
			children: (
				<div className="row">
					<div className="col-lg-6">
						<label className="custom-label mb-4 font-weight-bold">
							Badge Design:
						</label>
						<div className="custom-form-group">
							<Form.Item>
								<label className="custom-label">
									Badge Name:<span className="asterisk">*</span>
								</label>
								<div className="col-sm-7 p-0">
									<div className={wrapStyles}>
										<Tooltip
											overlayClassName="error-tooltip"
											open={badgeErrorObject?.['name']}
											placement="bottomLeft"
											getPopupContainer={(trigger) => trigger.parentElement}
											title="Please enter badge name"
										>
											<Input
												maxLength={32}
												className="custom-input"
												placeholder="Badge name"
												value={badgeDetailsObject?.name || ''}
												onChange={(e) => {
													setBadgeDetailsObject({
														...badgeDetailsObject,
														name: e.target.value,
													});
													setBadgeErrorObject({
														...badgeErrorObject,
														name: false,
													});
												}}
												onBlur={() => {
													if (badgeDetailsObject.name === '')
														setBadgeErrorObject({
															...badgeErrorObject,
															name: true,
														});
												}}
											/>
										</Tooltip>
									</div>
								</div>
							</Form.Item>
						</div>
						<div className="custom-form-group">
							<Form.Item>
								<label className="custom-label">
									Badge Color:<span className="asterisk">*</span>
								</label>
								<div className="col-sm-7 p-0">
									<div className={wrapStyles}>
										<Tooltip
											overlayClassName="error-tooltip"
											open={badgeErrorObject?.['color']}
											placement="bottomLeft"
											getPopupContainer={(trigger) => trigger.parentElement}
											title="Please enter valid color"
										>
											<Popover content={colorPicker} title={null} trigger="click">
												<Input
													className="custom-input"
													placeholder="Badge Color"
													value={tempColor || ''}
												/>
											</Popover>
										</Tooltip>
									</div>
								</div>
							</Form.Item>
						</div>
						<div className="custom-form-group">
							<Form.Item>
								<label className="custom-label">
									Gradient Color:
								</label>
								<div className="col-sm-7 p-0">
									<div className={wrapStyles}>
										<Popover content={badgeDetailsObject?.badgeTypeCode !== MAPPED_BADGE_TYPE.AUTOMATED ? gradientPicker : null} title={null} trigger="click">
											<Input
												disabled={badgeDetailsObject?.badgeTypeCode === MAPPED_BADGE_TYPE.AUTOMATED}
												className="custom-input"
												placeholder="Gradient Color"
												value={badgeDetailsObject?.gradientColor || ''}
											/>
										</Popover>
									</div>
								</div>
							</Form.Item>
						</div>
						{badgeTypeList?.filter(
							(b) => b.code == badgeDetailsObject?.badgeTypeCode
						)[0]?.code !== MAPPED_BADGE_TYPE.AUTOMATED && (
							<div className="custom-form-group">
								<Form.Item>
									<label className="custom-label">
										Badge Icon:<span className="asterisk">*</span>
									</label>
									<div className="col-sm-7 p-0">
										<Select
											className="custom-input override-selector-height"
											style={{ width: '100%' }}
											value={badgeDetailsObject?.iconCode || ''}
											onChange={(value) => {
												setBadgeDetailsObject({
													...badgeDetailsObject,
													iconCode: value,
												});
											}}
											placeholder="Select General Referral Category"
										>
											{iconsListData}
										</Select>
									</div>
								</Form.Item>
							</div>
						)}
						<div className="custom-form-group">
							<Form.Item>
								<label className="custom-label">
									Badge Label:<span className="asterisk">*</span>
								</label>
								<div className="col-sm-7 p-0">
									<div className={wrapStyles}>
										<Tooltip
											overlayClassName="error-tooltip"
											open={badgeErrorObject?.['label']}
											placement="bottomLeft"
											getPopupContainer={(trigger) => trigger.parentElement}
											title="Please enter badge label"
										>
											<span>
												<Input
													value={badgeDetailsObject?.label || ''}
													className="custom-input"
													onChange={(e) => {
														setBadgeErrorObject({
															...badgeErrorObject,
															label: false,
														});
														setBadgeDetailsObject({
															...badgeDetailsObject,
															label: e.target.value,
														});
													}}
													placeholder="Badge Label"
													maxLength={30}
												/>
												<p className="mb-0" style={{ fontSize: '10px' }}>
													Maximum 30 characters allowed
												</p>
											</span>
										</Tooltip>
									</div>
								</div>
							</Form.Item>
						</div>
						<div className="custom-form-group">
							<Form.Item>
								<label className="custom-label">
									Badge Details:<span className="asterisk">*</span>
								</label>
								<div className="col-sm-7 p-0">
									<div className={wrapStyles}>
										<Tooltip
											overlayClassName="error-tooltip"
											open={badgeErrorObject?.['description']}
											placement="bottomLeft"
											getPopupContainer={(trigger) => trigger.parentElement}
											title="Please enter badge details"
										>
											<TextArea
												maxLength={255}
												placeholder="Badge Details"
												value={badgeDetailsObject?.description || ''}
												onChange={(e) => {
													setBadgeDetailsObject({
														...badgeDetailsObject,
														description: e.target.value,
													});
													setBadgeErrorObject({
														...badgeErrorObject,
														description: false,
													});
												}}
												className="custom-input"
											/>
										</Tooltip>
									</div>
								</div>
							</Form.Item>
						</div>
					</div>
					<div className="col-lg-6">
						<label className="custom-label mb-4 font-weight-bold">
							Reward Settings:
						</label>
						<div className="custom-form-group">
							<Form.Item>
								<label className="custom-label">Badge Assignment:</label>
								<div className="col-sm-7 p-0">
									<Select
										className="custom-input"
										style={{ width: '100%' }}
										value={badgeDetailsObject?.badgeTypeCode || ''}
										onChange={(value) => {
											setAutomation(undefined);
											setBadgeDetailsObject({
												...badgeDetailsObject,
												gradientColor: value === MAPPED_BADGE_TYPE.AUTOMATED ? null : badgeDetailsObject?.gradientColor,
												badgeTypeCode: value,
											});
										}}
										placeholder="Select General Referral Category"
									>
										{badgeTypeListData}
									</Select>
								</div>
							</Form.Item>
						</div>
						{badgeTypeList?.filter(
							(b) => b.code == badgeDetailsObject?.badgeTypeCode
						)[0]?.code === MAPPED_BADGE_TYPE.AUTOMATED && (
							<div className="custom-form-group">
								<Form.Item>
									<label className="custom-label">Automation:</label>
									<div className="col-sm-7 p-0">
										<Select
											style={{ width: '100%' }}
											value={automation}
											onChange={(value) => {
												setAutomation(value);
											}}
											placeholder="Select General Referral Category"
										>
											{automationData}
										</Select>
									</div>
								</Form.Item>
							</div>
						)}
						<div className="custom-form-group">
							<Form.Item>
								<label className="custom-label">Bonus Amount:</label>
								<div className="col-sm-7 p-0">
									<Input
										type="number"
										min={0}
										max={9999}
										placeholder="Bonus amount"
										value={badgeDetailsObject?.rewardBonus}
										onChange={(e) => {
											setBadgeDetailsObject({
												...badgeDetailsObject,
												rewardBonus: e.target.value,
											});
										}}
										className="custom-input"
									/>
								</div>
							</Form.Item>
						</div>
						<div className="custom-form-group">
							<Form.Item>
								<label className="custom-label">Points:</label>
								<div className="col-sm-7 p-0">
									<div className={wrapStyles}>
										<Tooltip
											overlayClassName="error-tooltip"
											open={badgeErrorObject?.['points']}
											placement="bottomLeft"
											getPopupContainer={(trigger) => trigger.parentElement}
											title="Please enter a value equal to or less than 100,000"
										>
											<Input
												type="number"
												min={0}
												max={100000}
												placeholder="Points"
												value={badgeDetailsObject?.points}
												onChange={(e) => {
													setBadgeDetailsObject({
														...badgeDetailsObject,
														points: e.target.value,
													});
													setBadgeErrorObject({
														...badgeErrorObject,
														points: false,
													});
												}}
												className="custom-input"
												onBlur={() => {
													if (badgeDetailsObject.points === '')
														setBadgeErrorObject({
															...badgeErrorObject,
															points: true,
														});
												}}
											/>
										</Tooltip>
									</div>
								</div>
							</Form.Item>
						</div>
						<div className="custom-form-group">
							<Form.Item>
								<label className="custom-label">
									Physical <br /> Award ID:
								</label>
								<div className="col-sm-7 p-0">
									<Input
										placeholder="Physical Award ID"
										value={badgeDetailsObject?.physicalAwardId || ''}
										onChange={(e) => {
											setBadgeDetailsObject({
												...badgeDetailsObject,
												physicalAwardId: e.target.value,
											});
										}}
										className="custom-input"
									/>
								</div>
							</Form.Item>
						</div>
						<div className="custom-form-group">
							<Form.Item>
								<label className="custom-label">Badge Visibility:</label>
								<div className="col-sm-7 p-0">
									<Select
										className="custom-input"
										style={{ width: '100%' }}
										value={badgeDetailsObject?.visibility}
										onChange={(value) => {
											setBadgeDetailsObject({
												...badgeDetailsObject,
												visibility: value,
											});
										}}
									>
										{badgeVisibility}
									</Select>
								</div>
							</Form.Item>
						</div>
						{badgeTypeList?.filter(
							(b) => b.code == badgeDetailsObject?.badgeTypeCode
						)[0]?.code !== MAPPED_BADGE_TYPE.AUTOMATED && (
							<div className="custom-form-group">
								<Form.Item>
									<label className="custom-label">Approval Required?</label>
									<div className="col-sm-7 p-0">
										<Select
											className="custom-input"
											style={{ width: '100%' }}
											value={badgeDetailsObject?.isPreApproved}
											onChange={(value) => {
												setBadgeDetailsObject({
													...badgeDetailsObject,
													isPreApproved: value,
												});
											}}
										>
											{badgeApproval}
										</Select>
									</div>
								</Form.Item>
							</div>
						)}
						<div className="custom-form-group">
							<Form.Item>
								<label className="custom-label">
									External <br /> Workflow ID:
								</label>
								<div className="col-sm-7 p-0">
									<Input
										placeholder="External Workflow ID"
										maxLength={32}
										value={badgeDetailsObject?.externalWorkFlowId || ''}
										onChange={(e) => {
											setBadgeDetailsObject({
												...badgeDetailsObject,
												externalWorkFlowId: e.target.value,
											});
										}}
										className="custom-input"
									/>
								</div>
							</Form.Item>
						</div>
					</div>
				</div>
			),
		},
		{
			key: '2',
			label: 'Permission Details',
			children: (
				<>
					{badgeTypeList?.filter(
						(b) => b.code == badgeDetailsObject?.badgeTypeCode
					)[0]?.code !== MAPPED_BADGE_TYPE.AUTOMATED && (
						<>
							<div className="row">
								<div className="col-lg-6 col-12">
									<div className="custom-form-group">
										<Form.Item>
											<label className="custom-label">Max Bonus:</label>
											<div className="col-sm-7 p-0">
												<Input
													type="number"
													min={1}
													disabled
													placeholder="Reward max bonus"
													value={badgeDetailsObject?.rewardMaxBonus}
													onChange={(e) => {
														setBadgeDetailsObject({
															...badgeDetailsObject,
															rewardMaxBonus: e.target.value,
														});
													}}
													className="custom-input"
												/>
											</div>
										</Form.Item>
									</div>
								</div>
								<div className="col-lg-6 col-12">
									<div className="custom-form-group">
										<Form.Item>
											<label className="custom-label">Requires date?</label>
											<div className="pl-0">
												<Switch
													disabled
													checked={badgeDetailsObject?.isRewardRequiresDate}
												/>
											</div>
										</Form.Item>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-lg-6 col-12">
									<div className="custom-form-group">
										<Form.Item>
											<label className="custom-label">Assignment level:</label>
											<div className="col-sm-7 p-0">
												<Select
													mode="multiple"
													placeholder={'Select Assignment level'}
													className="custom-input"
													style={{ width: '100%' }}
													value={badgeDetailsObject?.assignmentLevelId}
													onChange={(value) => {
														setBadgeDetailsObject({
															...badgeDetailsObject,
															assignmentLevelId: value,
														});
													}}
												>
													{getLevelList}
												</Select>
											</div>
										</Form.Item>
									</div>
								</div>
								<div className="col-lg-6 col-12">
									<div className="custom-form-group">
										<Form.Item>
											<label className="custom-label">
												Assignment SSO required?
											</label>
											<div className=" pl-0">
												<Switch
													checked={
														badgeDetailsObject?.isAssignmentSSORequired ===
														SSO_Required_CONSTANTS.SSO
													}
													onChange={(value) => {
														setBadgeDetailsObject({
															...badgeDetailsObject,
															isAssignmentSSORequired: value
																? SSO_Required_CONSTANTS.SSO
																: SSO_Required_CONSTANTS.STANDARD,
														});
													}}
												/>
											</div>
										</Form.Item>
									</div>
								</div>
							</div>
						</>
					)}
					<div className="row">
						<div className="col-lg-6 col-12">
							<div className="custom-form-group">
								<Form.Item>
									<label className="custom-label">Recipient level:</label>
									<div className="col-sm-7 p-0">
										<Select
											mode="multiple"
											placeholder={'Select Recipient level'}
											className="custom-input"
											style={{ width: '100%' }}
											value={badgeDetailsObject?.recipientLevelId}
											onChange={(value) => {
												setBadgeDetailsObject({
													...badgeDetailsObject,
													recipientLevelId: value,
												});
											}}
										>
											{getLevelList}
										</Select>
									</div>
								</Form.Item>
							</div>
						</div>
						<div className="col-lg-6 col-12">
							<div className="custom-form-group">
								<Form.Item>
									<label className="custom-label">
										Recipient SSO required?
									</label>
									<div className="pl-0">
										<Switch
											checked={
												badgeDetailsObject?.isRecipientSSORequired ===
												SSO_Required_CONSTANTS.SSO
											}
											onChange={(value) => {
												setBadgeDetailsObject({
													...badgeDetailsObject,
													isRecipientSSORequired: value
														? SSO_Required_CONSTANTS.SSO
														: SSO_Required_CONSTANTS.STANDARD,
												});
											}}
										/>
									</div>
								</Form.Item>
							</div>
						</div>
					</div>
					<div className="row h-100">
						<div className="col-lg-6 col-12">
							<div className="custom-form-group">
								<Form.Item>
									<label className="custom-label">
										Recipient <br /> pay type:
									</label>
									<div className="col-sm-7 p-0">
										<Select
											allowClear
											placeholder={'Select Recipient pay type'}
											className="custom-input"
											style={{ width: '100%' }}
											value={badgeDetailsObject?.recipientPayTypeId}
											onChange={(value) => {
												setBadgeDetailsObject({
													...badgeDetailsObject,
													recipientPayTypeId: value,
												});
											}}
										>
											{getPayTypeList}
										</Select>
									</div>
								</Form.Item>
							</div>
						</div>
					</div>
				</>
			),
		},
	];

	return (
		<>
			<div>
				<Modal
					width={950}
					className="reward-modal"
					title="Create New Badge"
					open={isModalOpen}
					footer={false}
					onOk={false}
					onCancel={handleCloseModal}
					destroyOnClose={true}
				>
					<>
						<div className="row h-100">
							<div className="col-lg-8 col-12 pr-0">
								<Tabs defaultActiveKey="1" items={tabItems} />
							</div>
							<div className="col-lg-4 col-12">
								<label className="custom-label font-weight-bold preview-label">
									Preview:
								</label>
								<div className="add-reward-preview">
									<div className="reward-svg">
										{badgeDetailsObject?.badgeTypeCode &&
											badgeDetailsObject?.iconCode && (
												<CoreBadge
													captureId={"badge-preview"}
													badgeType={badgeDetailsObject?.badgeTypeCode}
													badgeIcon={badgeDetailsObject?.iconCode}
													label={badgeDetailsObject?.label}
													color={tempColor}
													gradientColor={badgeDetailsObject?.gradientColor || badgeDetailsObject?.gradientcolor}
												/>
											)}
									</div>
									<div className="modal-footer-btn">
										<div className="pb-container">
											<Form.Item>
												<Button
													onClick={handleCreateBadge}
													className="pb-button w-auto"
												>
													Create Badge
												</Button>
											</Form.Item>
										</div>
									</div>
								</div>
							</div>
							{isLoaderVisible ? (
								<div className="add-edit-reward-loader">
									<Spin />
								</div>
							) : (
								<></>
							)}
						</div>
						<div className="row" />
					</>
				</Modal>
			</div>
		</>
	);
};

export default AddReward;
