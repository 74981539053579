import gql from 'graphql-tag';

export const ListCompanies = gql`
	query ListCompanies(
		$filter: TableCompanyFilterInput
		$limit: Int
		$nextToken: String
	) {
		listCompanies(filter: $filter, limit: $limit, nextToken: $nextToken) {
			items {
				id
				accountType
				name
				dateCreated
				disableSite
			}
			nextToken
		}
	}
`;
