import _ from 'lodash';
import { useState } from 'react';
import { withApollo } from 'react-apollo';
import { Link } from 'react-router-dom';
import { USER_ROLES } from '../../../_shared/constants';
import { ml } from '../../../_shared/services/utils.js';
import { Categories } from './ReferralBonusCustomCompoent.jsx';

function BaseReferralBonusCard({
	allMultiLingualData,
	title,
	statusText,
	statusWiseReferrals,
	tierWise,
	totalReferralsCount,
	referralContact,
	onClick,
	currentUser,
	client,
	company,
	customStages,
	referralStatus,
}) {
	const [isViewMore, setIsViewMore] = useState(false);
	let interviewingCount = statusWiseReferrals.interviewing;
	if (statusWiseReferrals.interviewing && statusWiseReferrals[referralStatus]) {
		interviewingCount = Number.parseInt(
			statusWiseReferrals.interviewing +
				Number.parseInt(statusWiseReferrals[referralStatus])
		);
	}

	return (
		<div className="d-card height-equal" onClick={onClick}>
			{currentUser.displayAs === USER_ROLES.ADMIN && (
				<div className="d-card-head">
					<h4 className="d-title">{title}</h4>
					<Link className="link" to="/referrals">
						{ml('VIEW MORE', currentUser, allMultiLingualData)}
					</Link>
				</div>
			)}

			{currentUser.displayAs === USER_ROLES.ADMIN && (
				<>
					<div className="row align-items-center">
						<div className="col-sm-5">
							<div className="data-card light-text bg-e75da7">
								<h5>
									{totalReferralsCount
										? totalReferralsCount
												.toString()
												.replaceAll(/\B(?=(\d{3})+(?!\d))/g, ',')
										: 0}
								</h5>
								<p>{ml('Total Referrals', currentUser, allMultiLingualData)}</p>
							</div>
							<div
								className="data-card light-text bg-14d6d3"
								style={{ marginTop: 14 }}
							>
								<h5>
									{referralContact
										? referralContact
												.toString()
												.replaceAll(/\B(?=(\d{3})+(?!\d))/g, ',')
										: 0}
								</h5>
								<p>
									{ml('Total Candidates', currentUser, allMultiLingualData)}
								</p>
							</div>
						</div>
						<div className="col-sm-7">
							<div
								className="list-data-wrap custom-scroll"
								style={{ height: 170, overflowY: 'auto', paddingRight: 10 }}
							>
								<li>
									<label>{statusText.referred} :</label>
									<span>
										{/* if GDPR enabled, get "referred" count from webNotifications */}
										{company && statusWiseReferrals.referred
											? statusWiseReferrals.referred
													.toString()
													.replaceAll(/\B(?=(\d{3})+(?!\d))/g, ',')
											: 0}
									</span>
								</li>

								<li>
									<label>{statusText.accepted} :</label>
									<span>
										{statusWiseReferrals.accepted
											? statusWiseReferrals.accepted
													.toString()
													.replaceAll(/\B(?=(\d{3})+(?!\d))/g, ',')
											: 0}{' '}
									</span>
								</li>

								<li>
									<label>
										{statusText.interviewing
											? statusText.interviewing
													.toString()
													.replaceAll(/\B(?=(\d{3})+(?!\d))/g, ',')
											: 'interviewing'}{' '}
										:
									</label>

									<span>
										{interviewingCount
											? interviewingCount
													.toString()
													.replaceAll(/\B(?=(\d{3})+(?!\d))/g, ',')
											: 0}{' '}
									</span>
								</li>
								<li>
									<label>{statusText.hired} :</label>
									<span>
										{statusWiseReferrals.hired
											? statusWiseReferrals.hired
													.toString()
													.replaceAll(/\B(?=(\d{3})+(?!\d))/g, ',')
											: 0}{' '}
									</span>
								</li>
								<li>
									<label>{statusText.notHired} :</label>
									<span>
										{statusWiseReferrals.notHired
											? statusWiseReferrals.notHired
													.toString()
													.replaceAll(/\B(?=(\d{3})+(?!\d))/g, ',')
											: 0}
									</span>
								</li>

								<li>
									<label>{statusText.inactive} :</label>
									<span>
										{statusWiseReferrals.inactive
											? statusWiseReferrals.inactive
													.toString()
													.replaceAll(/\B(?=(\d{3})+(?!\d))/g, ',')
											: 0}{' '}
									</span>
								</li>

								<li>
									<label>{statusText.noresponse} :</label>
									<span>
										{statusWiseReferrals.noresponse
											? statusWiseReferrals.noresponse
													.toString()
													.replaceAll(/\B(?=(\d{3})+(?!\d))/g, ',')
											: 0}{' '}
									</span>
								</li>

								<li>
									<label>{statusText.transferred} :</label>
									<span>
										{statusWiseReferrals.transferred
											? statusWiseReferrals.transferred
													.toString()
													.replaceAll(/\B(?=(\d{3})+(?!\d))/g, ',')
											: 0}{' '}
									</span>
								</li>

								<li>
									<label>{statusText.ineligible} :</label>
									<span>
										{statusWiseReferrals.ineligible
											? statusWiseReferrals.ineligible
													.toString()
													.replaceAll(/\B(?=(\d{3})+(?!\d))/g, ',')
											: 0}{' '}
									</span>
								</li>

								<Categories
									stages={customStages}
									statusWiseReferrals={statusWiseReferrals}
								/>
							</div>
						</div>
					</div>
					<div className="divider" />
					<div className="row" style={{ flex: '1 0 auto' }}>
						<div className="col-sm-5">
							<p className="label-heading">
								{' '}
								{ml(
									'Referrals By Bonus Level',
									currentUser,
									allMultiLingualData
								)}{' '}
							</p>
						</div>
						<div className="col-sm-7">
							<ul
								className="list-data-wrap custom-scroll"
								style={{
									maxHeight: 100,
									overflowY: 'auto',
									paddingRight: 10,
									minHeight: '100%',
								}}
							>
								{_.sortBy(tierWise, ['name']).map((value, index) => {
									return (
										<li key={index}>
											{' '}
											<label>{value.name} :</label>
											<span>
												{value.count
													.toString()
													.replaceAll(/\B(?=(\d{3})+(?!\d))/g, ',')}{' '}
											</span>
										</li>
									);
								})}
							</ul>
						</div>
					</div>
				</>
			)}
		</div>
	);
}

export const BonusReferralCard = withApollo(BaseReferralBonusCard);
