import { css } from 'emotion';
import get from 'lodash/get';
import { COLORS } from 'src/_shared/styles/colors';

const breakpoints = [576, 767, 991, 1200];

const mq = breakpoints.map((bp) => `@media (max-width: ${bp}px)`);

export const ModalStyles = css({
	'& .ant-modal-content': {
		borderRadius: 20,
	},
	'& .ant-modal-header': {
		background: 'none',
	},
	'& .ant-modal-close': {
		outline: 'none',
		'&:focus .ant-modal-close-icon': {
			outline: 'auto',
		},
	},
	fontFamily: 'Open Sans',
});

export const flexBox = css({
	'& .ant-form-item-children': {
		display: 'flex',
		alignItems: 'center',
	},
});

export const inputStyles = css({
	'& >input': {
		borderRadius: 20,
	},
	fontFamily: 'Open Sans',
	'& .ant-modal-close-x': {
		fontSize: 30,
		paddingTop: 10,
		color: COLORS.lightGray,
	},
});

export const ModalStylesSubmitting = css({
	'& .ant-modal-content': {
		borderRadius: 20,
		height: 750,
		width: 520,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	fontFamily: 'Open Sans',
	'& .ant-modal-close-x': {
		color: 'white',
	},
});

export const ModalTitle = (theme) => {
	let buttonColor = COLORS.red;
	if (get(theme, 'enabled')) {
		buttonColor = get(theme, 'buttonColor', buttonColor);
	}

	return css({
		width: '100%',
		textAlign: 'center',
		color: buttonColor,
		fontSize: 36,
		marginBottom: 0,
		fontWeight: 600,
	});
};

export const SmallText = css({
	fontSize: 13,
	fontWeight: 600,
	width: '100%',
	textAlign: 'center',
});

export const AddContactsHere = css({
	color: COLORS.lightGray,
	fontWeight: 400,
});

export const JobInfoContainer = css({
	padding: 20,
	paddingBottom: 10,
	backgroundColor: COLORS.lightGreen,
	borderRadius: 8,
	marginBottom: 15,
});

export const JobInfo = css({
	justifyContent: 'space-between',
	marginBottom: 0,
});

export const JobTitle = css({
	fontSize: 18,
	color: COLORS.blue,
	marginBottom: 0,
});

export const Currency = css({
	color: COLORS.green,
	fontSize: 20,
	marginBottom: 0,
	textAlign: 'right',
	padding: 0,
});

export const JobIcon = css({
	fontSize: 20,
	color: COLORS.darkGray,
	paddingRight: 3,
});

export const Flex = css({
	display: 'flex',
	marginBottom: 0,
});

export const changeOrientation = css({
	[mq[1]]: {
		flexDirection: 'column',
	},
});

export const ReferralBonus = css({
	marginBottom: 0,
});

export const addPaddingRight = css({
	paddingRight: 15,
});

export const ToggleNewContactBtn = css({
	padding: 0,
	border: 'none',
	fontSize: 13,
	height: 13,
	color: COLORS.blue,
	fontWeight: 600,
});

export const SubmitBtn = css({
	fontFamily: 'Open Sans',
	backgroundColor: COLORS.red,
	color: 'white',
	fontSize: 20,
	fontWeight: 400,
	padding: '10px 30px',
	height: 'auto',
	border: 'none',
	marginBottom: 0,
});

export const SubmitBtnContainer = css({
	width: '100%',
	display: 'flex',
	justifyContent: 'center',
	marginBottom: 0,
	'& .ant-form-item-control-wrapper': {
		'@media (max-width: 575px)': {
			width: 'auto',
		},
	},
});

export const CheckIcon = css({
	borderRadius: '100%',
	border: '1.5px solid white',
	padding: 2.5,
	paddingLeft: 4,
	paddingRight: 4,
});

export const FormTitle = css({
	width: '100%',
	textAlign: 'center',
	fontWeight: 600,
	marginBottom: 0,
	marginTop: 5,
	color: COLORS.darkGray,
	fontSize: 24,
});

export const LabelStyles = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 600,
	'& span': {
		fontWeight: 300,
		fontSize: 14,
	},
});

export const TextAreaStyles = css({
	marginBottom: 10,
});

export const InputStyles = css({
	fontFamily: '"Open Sans", sans-serif',
	fontSize: 11,
});

export const AutoCompleteStyles = css({
	fontFamily: '"Open Sans", sans-serif',
});

export const FormItemStyles = css({
	marginBottom: '0 !important',
});

export const RemoveBottomMargin = css({
	'& .ant-form-item': {
		marginBottom: 0,
	},
});

export const SubmitError = css({
	marginBottom: 24,
});

export const LinkStyles = css({
	color: COLORS.blue,
	cursor: 'pointer',
});

export const PopoverStyle = css({
	'& .ant-popover-inner-content': {
		borderColor: 'rgba(0, 0, 0, 0.75)',
		borderRadius: 4,
		color: 'white',
		backgroundColor: 'rgba(0, 0, 0, 0.75)',
		padding: 5,
	},
	'& .ant-popover-arrow': {
		backgroundColor: 'rgba(0, 0, 0, 0.75)',
		borderColor: 'rgba(0, 0, 0, 0.75)',
		border: '0px solid',
		zIndex: -1,
	},
});

export const CheckBoxItemStyles = css({
	marginBottom: 0,
	paddingLeft: 30,
	margin: 'auto',
});

export const CheckBoxStyles = css({
	margin: '0 auto',
	' & span': {
		fontSize: '16px !important',
	},
	'& .ant-checkbox-checked .ant-checkbox-inner': {
		borderColor: COLORS.green,
		backgroundColor: COLORS.green,
	},
});

export const fileWrap = css`
	display: inline-block;
	position: relative;
	font-weight: bold;
	color: ${COLORS.blue};
	label {
		cursor: pointer;
	}
	input {
		opacity: 0;
		width: 0.1px;
	}
`;

export const fieldErrors = css({
	width: 'auto',
});

export const errorText = css({
	fontFamily: '"Open Sans", sans-serif',
	color: COLORS.red,
	fontSize: '1em',
	left: -30,
});

export const iconContainer = css({
	'& svg': {
		position: 'absolute',
		top: 10,
		right: 10,
		pointerEvents: 'none',
	},
});

export const selectStyles = css({
	width: '100%',
});
