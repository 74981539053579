import get from 'lodash/get';
import { connect } from 'react-redux';
import { withGetTieredBonus } from 'src/_shared/api/components/tiered-bonuses';
import { compose } from '../../../services/utils';
import JobCard from './OnDeckJobCardComponent.jsx';

const mapStateToProperties = (state, ownProperties) => {
	const { currentUser } = state.user;
	const bonus = get(ownProperties, 'job.job.referralBonus', 'skip');
	const id = get(bonus, 'tieredBonusId', 'skip');
	return {
		currentReferral: state.referral.currentReferral,
		referrals: state.referral.referrals,
		companyId: currentUser.companyId,
		tieredBonusQuery: {
			companyId: currentUser.companyId,
			id,
		},
	};
};

const mapDispatchToProperties = () => {
	return {};
};

export default connect(
	mapStateToProperties,
	mapDispatchToProperties
)(compose(withGetTieredBonus)(JobCard));
