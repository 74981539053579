import gql from 'graphql-tag';

export const JobMatchesByCompanyId = gql`
	query QueryJobMatchesByCompanyIdIndex(
		$companyId: ID!
		$first: Int
		$after: String
	) {
		queryJobMatchesByCompanyIdIndex(
			companyId: $companyId
			first: $first
			after: $after
		) {
			items {
				id
				contactId
				jobId
				companyId
				dateCreated
				relevance
				matchStatus
			}
			nextToken
		}
	}
`;
