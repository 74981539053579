import { Auth } from 'aws-amplify';

export const awsIAM = {
	url: '',
	region: 'us-east-2',
	auth: {
		type: 'AWS_IAM',
		credentials: () => Auth.currentCredentials(),
	},
};

export const awsApiKey = {
	url: '',
	region: 'us-east-2',
	auth: {
		type: 'API_KEY',
		apiKey: '',
	},
};
