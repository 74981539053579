import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Alert, Button, Input, Modal } from 'antd';
import { Auth } from 'aws-amplify';
import { CognitoIdentityProvider } from '@aws-sdk/client-cognito-identity-provider';
import gql from 'graphql-tag';
import get from 'lodash/get';
import dayjs from 'dayjs';
import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { configMode } from 'src/_shared/api/';
import {
	GetUserByCognitoId,
	updateUser,
} from 'src/_shared/api/graphql/custom/users/';
import { decryptUsingAES256 } from 'src/_shared/api/settings';
import rightArrow from '../../../_shared/assets/right-arrow.svg';
import {
	CheckIcon,
	FormStyles,
	InputStyles,
	LabelStyles,
	SubmitBtn as SubmitButton,
	SubmitBtnContainer as SubmitButtonContainer,
} from './extendedLoginModalStyles.js';

class ExtendedLoginLinkComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			invalidCredentials: false,
			serverError: false,
			inactiveError: false,
			loading: false,
			visible: false,
			resetModalVisible: false,
		};
	}

	async componentDidMount() {
		if (this.props.locationState) {
			const { currentUser, redirectURL } = this.props.locationState;
			const { setCurrentUser, onAuthentication } = this.props;
			if (this.props.locationState) {
				try {
					const { data } = await this.props.client.query({
						query: GetUserByCognitoId,
						variables: { cognitoId: get(currentUser, 'cognitoId') },
					});
					const newUser = {
						...data.getUserByCognitoId,
						lastLogin: dayjs().toISOString(),
					};

					setCurrentUser(newUser);
					onAuthentication(redirectURL, newUser);

					this.props.client
						.mutate({
							mutation: gql(updateUser),
							variables: {
								input: {
									id: currentUser.id,
									lastLogin: dayjs().toISOString(),
								},
							},
						})
						.then(() => this.props.history.push('/dashboard'));
				} catch {}
			}
		}
	}

	closeModal = () => {
		this.setState({ visible: false });
	};

	closeResetModal = () => {
		this.setState({ resetModalVisible: false, loading: false });
	};

	handleClaimAccount = () => {
		this.setState({ visible: true });
	};

	handleCompanyLogIn = () => {
		this.props.history.push({
			pathname: '/saml/login',
		});
	};

	handleInactiveError = () => {
		this.setState({ inactiveError: true });
	};

	handleSubmit = (e) => {
		e.preventDefault();
		this.setState({ serverError: false, invalidCredentials: false });
		const {
			contact,
			onAuthentication,
			onUpdateContact,
			setCurrentUser,
		} = this.props;
		this.props.form.validateFields((error, values) => {
			if (error) return;
			this.setState({ loading: true });
			Auth.signIn(values.email.toLowerCase(), values.password)
				.then(async (user) => {
					if (get(user, 'challengeName') === 'NEW_PASSWORD_REQUIRED') {
						this.setState({ resetModalVisible: true, authUser: user });
						return;
					}

					const { data } = await this.props.client.query({
						query: GetUserByCognitoId,
						variables: { cognitoId: user.username },
					});
					const currentUser = {
						...data.getUserByCognitoId,
						authMethod: 'credentials',
						lastLogin: dayjs().toISOString(),
					};
					if (currentUser && !currentUser.active) {
						const error = new Error('User Disabled');
						error.code = 'UserDisabledException';
						throw error;
					} else {
						let extendedCompanies = JSON.parse(
							get(currentUser, 'extendedCompanies', '[]')
						);
						extendedCompanies ||= [];
						if (!extendedCompanies.includes(get(contact, 'companyId')))
							extendedCompanies.push(get(contact, 'companyId'));
						extendedCompanies = JSON.stringify(extendedCompanies);
						let extendedContactIds = JSON.parse(
							get(currentUser, 'extendedContactIds', '[]')
						);
						extendedContactIds ||= [];
						if (!extendedContactIds.includes(get(contact, 'id')))
							extendedContactIds.push(get(contact, 'id'));
						extendedContactIds = JSON.stringify(extendedContactIds);

						this.props.client.mutate({
							mutation: gql(updateUser),
							variables: {
								input: {
									id: get(data, 'getUserByCognitoId.id'),
									authMethod: 'credentials',
									lastLogin: dayjs().toISOString(),
									extendedCompanies,
									extendedContactIds,
								},
							},
						});

						onUpdateContact({
							id: get(contact, 'id'),
							inviteStatus: 'accepted',
							extendedUserId: get(currentUser, 'id'),
						}).then(() => {
							setCurrentUser(currentUser);
							onAuthentication(
								user.signInUserSession.accessToken.jwtToken,
								currentUser
							);
							this.props.history.push({
								pathname: '/dashboard',
								state: {
									successMessage: `Success! Your account has been successfully linked with ${get(
										contact,
										'company.name',
										'this company'
									)}!`,
								},
							});
						});
					}
				})
				.catch((error) => {
					console.log(error);
					switch (error.code) {
						case 'NotAuthorizedException':
						case 'UserNotFoundException': {
							this.setState({ invalidCredentials: true });
							console.error(error);

							break;
						}

						case 'UserDisabledException': {
							this.setState({ inactiveError: true });
							console.error(error);

							break;
						}

						case 'NetworkError': {
							this.setState({ serverError: true });
							console.error(error);

							break;
						}
						// No default
					}

					this.setState({ loading: false });
				});
		});
	};

	handleSubmitGoogle = () => {
		const { origin } = window.location;
		const url = `https://erinapp.auth.us-east-2.amazoncognito.com/oauth2/authorize?identity_provider=Google&redirect_uri=${origin}/saml/login&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&client_id=56v63ge0a84vvtij0eat6i45p3`;
		window.location.href = url;
	};

	updateNewPassword = (user, newPassword) => {
		const parameters = {
			ChallengeName: 'NEW_PASSWORD_REQUIRED',
			ClientId: get(user, 'pool.clientId'),
			ChallengeResponses: {
				USERNAME: get(user, 'username'),
				NEW_PASSWORD: newPassword,
			},
			Session: get(user, 'Session'),
		};
		const config = {
			region: 'us-east-2',
			accessKeyId: process.env[`REACT_APP_${configMode}_PASSWORDRESETACCESSKEYID`],
			secretAccessKey: decryptUsingAES256(process.env[`REACT_APP_${configMode}_PASSWORDRESETSECRETACCESSKEY`]),
		};
		const { closeResetModal } = this;
		const cognitoidentityserviceprovider = new CognitoIdentityProvider(config);
		cognitoidentityserviceprovider.respondToAuthChallenge(
			parameters,
			function (error, data) {
				if (error) console.log(error, error.stack);
				else {
					closeResetModal();
				}
			}
		);
	};

	renderError = (error, message) => {
		return error ? <Alert type="error" message={message} /> : null;
	};

	renderSuccess = (error, message) => {
		return error ? <Alert type="success" message={message} /> : null;
	};

	renderWarning = (error, message) => {
		return error ? <Alert type="warning" message={message} /> : null;
	};

	render() {
		const { serverError, invalidCredentials, inactiveError } = this.state;
		const {
			theme,
			accountClaimError,
			accountClaimSuccess,
			closeModal,
			companyAdminSuccess,
			successMessage,
			warningMessage,
			visible,
		} = this.props;
		const { getFieldDecorator } = this.props.form;
		const FormItem = Form.Item;
		return (
			<Modal
				destroyOnClose
				open={visible}
				title={<span>Link Your Account</span>}
				footer={null}
				onCancel={closeModal}
				onOk={null}
			>
				<div style={{ marginBottom: -20 }}>
					<p style={{ marginBottom: 20 }}>
						An account with this email address already exists. Please login
						using your credentials to link your account. Linking your account
						will allow you to view job openings from{' '}
						{get(this.props, 'contact.company.name', 'this company')}.
					</p>
					<Form className={FormStyles}>
						<FormItem className={InputStyles}>
							<label className={LabelStyles}>Email Address</label>
							{getFieldDecorator('email', {
								initialValue: get(this.props, 'email', ''),
								rules: [
									{
										type: 'email',
										message: 'Please enter a valid email address.',
									},
									{
										required: true,
										message: 'Email Required',
									},
								],
								validateTrigger: 'onSubmit',
							})(<Input />)}
						</FormItem>
						<FormItem className={InputStyles}>
							<label className={LabelStyles}>Password</label>
							{getFieldDecorator('password', {
								rules: [
									{
										required: true,
										message: 'Password Required',
									},
								],
							})(<Input.Password className={InputStyles} autoComplete="on" />)}
						</FormItem>
						{this.renderError(
							serverError,
							'There was a problem with the server, please try again.'
						)}
						{this.renderError(
							invalidCredentials,
							'User/password combination not found. Please try again.'
						)}
						{this.renderError(
							inactiveError,
							'Your account has been disabled.  Please contact an adminstrator to reactivate your account.'
						)}
						{this.renderError(accountClaimError, accountClaimError)}
						{this.renderSuccess(accountClaimSuccess, accountClaimSuccess)}
						{this.renderSuccess(companyAdminSuccess, companyAdminSuccess)}
						{this.renderSuccess(successMessage, successMessage)}
						{this.renderWarning(warningMessage, warningMessage)}
						<FormItem className={SubmitButtonContainer}>
							<Button
								loading={this.state.loading}
								className={SubmitButton(theme)}
								htmlType="submit"
								onClick={this.handleSubmit}
							>
								Log In
								<img
									className={CheckIcon}
									src={rightArrow}
									height={26}
									width={26}
									alt="right arrow"
								/>
							</Button>
						</FormItem>
					</Form>
				</div>
			</Modal>
		);
	}
}

export default withRouter(Form.create()(ExtendedLoginLinkComponent));
