import { WindowsOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import _ from 'lodash';
import { Component } from 'react';
import { AuthService, GraphService } from 'src/_shared/api/';
import ContactChecklist from './AddContactsChecklistComponent.jsx';
import {
	ModalTitle,
	alignCenter,
	darkGrayText,
} from './addContactsModalStyles.js';

class AddMicrosoftContacts extends Component {
	constructor(props) {
		super(props);
		this.authService = new AuthService();
		this.graphService = new GraphService();
		this.state = {
			user: null,
			userInfo: null,
			apiCallFailed: false,
			loginFailed: false,
			checked: [],
			checkAll: false,
		};
	}

	callAPI = () => {
		this.setState({
			apiCallFailed: false,
		});
		this.authService.getToken().then(
			(token) => {
				this.graphService.getUserInfo(token).then(
					(data) => {
						this.filterResults(data.value);
					},
					(error) => {
						console.error(error);
						this.setState({
							apiCallFailed: true,
						});
					}
				);
			},
			(error) => {
				console.error(error);
				this.setState({
					apiCallFailed: true,
				});
			}
		);
	};

	filterResults = (results) => {
		const filteredContacts = _.remove(results, function (contact) {
			return contact.emailAddresses[0] !== undefined;
		});
		this.setState({
			userInfo: filteredContacts,
		});
	};

	login = () => {
		this.setState({
			loginFailed: false,
		});
		this.authService.login().then(
			(user) => {
				if (user) {
					this.setState({
						user,
					});
					this.callAPI();
				} else {
					this.setState({
						loginFailed: true,
					});
				}
			},
			() => {
				this.setState({
					loginFailed: true,
				});
			}
		);
	};

	logout = () => {
		this.authService.logout();
	};

	render() {
		const templates = [];
		if (this.state.user) {
			templates.push(
				<div key="loggedIn">
					<h3>Hello {this.state.user.name}</h3>
				</div>
			);
		} else {
			templates.push(
				<div key="loggedIn">
					<Button
						type="primary"
						icon={<WindowsOutlined />}
						onClick={this.login}
					>
						Login with Microsoft
					</Button>
				</div>
			);
		}

		// Place to render imported contacts list
		if (this.state.userInfo) {
			const importedContacts = this.state.userInfo;
			templates.push(
				<ContactChecklist
					importedContacts={importedContacts}
					updateUserIncentiveEligibility={
						this.props.updateUserIncentiveEligibility
					}
					currentUser={this.props.currentUser}
					importMethod="microsoft"
					contactsChoice={this.props.contactsChoice}
					handleCancel={this.props.handleCancel}
					accountUsed={this.state.user.displayableId}
					onCreateContact={this.props.onCreateContact}
				/>
			);
		}

		if (this.state.loginFailed) {
			templates.push(<strong key="loginFailed">Login unsuccessful</strong>);
		}

		if (this.state.apiCallFailed) {
			templates.push(
				<strong key="apiCallFailed">Graph API call unsuccessful</strong>
			);
		}

		return (
			<div className={alignCenter}>
				<h1 className={ModalTitle}>
					Import your Microsoft and Outlook contacts
				</h1>
				<p className={darkGrayText}>
					You can choose which contacts to import after authenticating
				</p>
				{templates}
			</div>
		);
	}
}

export default AddMicrosoftContacts;
