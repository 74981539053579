import BaseFormElements from './FormElements.jsx';
import PlaceHolder from './FormPlaceHolder.jsx';
import SortableElement from './SortableElement.jsx';

const {
	Header,
	Paragraph,
	Label,
	LineBreak,
	TextInput,
	NumberInput,
	TextArea,
	Dropdown,
	Checkboxes,
	DatePicker,
	RadioButtons,
	Image,
	Rating,
	Tags,
	Signature,
	HyperLink,
	Download,
	Camera,
	Range,
} = BaseFormElements;

const FormElements = {};

FormElements.Header = SortableElement(Header);
FormElements.Paragraph = SortableElement(Paragraph);
FormElements.Label = SortableElement(Label);
FormElements.LineBreak = SortableElement(LineBreak);
FormElements.TextInput = SortableElement(TextInput);
FormElements.NumberInput = SortableElement(NumberInput);
FormElements.TextArea = SortableElement(TextArea);
FormElements.Dropdown = SortableElement(Dropdown);
FormElements.Signature = SortableElement(Signature);
FormElements.Checkboxes = SortableElement(Checkboxes);
FormElements.DatePicker = SortableElement(DatePicker);
FormElements.RadioButtons = SortableElement(RadioButtons);
FormElements.Image = SortableElement(Image);
FormElements.Rating = SortableElement(Rating);
FormElements.Tags = SortableElement(Tags);
FormElements.HyperLink = SortableElement(HyperLink);
FormElements.Download = SortableElement(Download);
FormElements.Camera = SortableElement(Camera);
FormElements.Range = SortableElement(Range);
FormElements.PlaceHolder = SortableElement(PlaceHolder);

export default FormElements;
