import { useEffect, useState } from "react"
import { Modal, message } from "antd"
import { useFormik } from 'formik'

import ManageNominatedRewardModal from '../../presentation/ManageNominatedRewardModal'

import useManageNominatedReward from '../../hooks/useManageNominatedReward'

import { nominationSchema } from "./validationSchema.js"

const ManageNominatedRewardContainer = ({
    showModal,
    create = false,
    setShowModal,
    getNominationConfiguration,
    awardId,
    nomineeCount
}) => {

    const [showLoader, setShowLoader] = useState(false)
    const [configLoading, setConfigLoading] = useState(false)
    const [initialData, setInitialData] = useState({})

    const {
        empLevel,
        badgeList,
        empLoginType,
        empPayType,
        loading,
        createNominationConfiguration,
        getNominationConfigurationById
    } = useManageNominatedReward()

    const handleCancel = () => {
        setShowModal && setShowModal(false);
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            id: initialData?.id,
            badgeId: initialData?.badge?.id,
            startDate: initialData?.startDate,
            endDate: initialData?.endDate,
            nominationCriteria: {
                nominee: {
                    employeeLevelId: initialData?.nomineeEmployeeLevelId ? initialData?.nomineeEmployeeLevelId : [],
                    employeelogInTypeId: initialData?.nomineeEmployeelogInTypeId ? initialData?.nomineeEmployeelogInTypeId : undefined,
                    employeePayTypeId: initialData?.nomineeEmployeePayTypeId ? initialData?.nomineeEmployeePayTypeId : undefined,
                },
                nominator: {
                    employeeLevelId: initialData?.nominatorEmployeeLevelId ? initialData?.nominatorEmployeeLevelId : [],
                    employeelogInTypeId: initialData?.nominatorEmployeelogInTypeId ? initialData?.nominatorEmployeelogInTypeId : undefined,
                    employeePayTypeId: initialData?.nominatorEmployeePayTypeId ? initialData?.nominatorEmployeePayTypeId : undefined,
                    awardedCount: initialData?.awardedCount ? initialData?.awardedCount : undefined,
                }
            }
        },
        validationSchema: nominationSchema,
        onSubmit: async (values) => {
            setShowLoader(true);
            try {
                const res = await createNominationConfiguration(values);
                if (res.code === 200 || res.code === 201) {
                    handleCancel();
                    const successMessage = `Nomination Award ${values.id ? "Updated" : "Created"} Successfully`;
                    message.success(successMessage);
                    if (getNominationConfiguration) {
                        await getNominationConfiguration();
                    }
                } else {
                    message.error(res.message);
                }
            } catch (error) {
                const errorMessage = error?.response?.data?.message || "Error!";
                message.error(errorMessage);
            } finally {
                setShowLoader(false);
            }
        }
    })

    useEffect(() => {
        if (awardId) {
            setConfigLoading(true)
            getNominationConfigurationById(awardId).then((res) => {
                setInitialData(res.data)
            }).catch(() => { }).finally(() => {
                setConfigLoading(false)
            })

        }
    }, [awardId])

    return (
        <Modal
            open={showModal}
            title={`${create ? "Create New Nomination Period" : "Nomination Details"}`}
            closable={true}
            onCancel={handleCancel}
            footer={null}
            width={"1000px"}
            className="manage-nominated-reward-modal reward-modal"
        >

            <ManageNominatedRewardModal
                create={create}
                badgeList={badgeList}
                empLevel={empLevel}
                empLoginType={empLoginType}
                empPayType={empPayType}
                formik={formik}
                loading={loading || configLoading}
                showLoader={showLoader}
                nominatedCount={nomineeCount}
            />
        </Modal>
    )
}

export default ManageNominatedRewardContainer