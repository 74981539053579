import { borderedTile } from '../../../_shared/components/bordered-tile';
import { COLORS } from '../../../_shared/styles/colors';
import {
	centeredText,
	grayHeaderClass,
} from '../referral-card/referralCardStyle.js';

function ExtendedTotalReferenceCard({ totalReferralsCount }) {
	return (
		<div style={{ height: 165 }}>
			<h2 className={grayHeaderClass} style={{ marginBottom: 0 }}>
				Your Referrals
			</h2>
			<div
				className={centeredText}
				style={{ fontSize: 50, color: COLORS.black }}
			>
				{totalReferralsCount}
			</div>
			<div className={centeredText}>Total Referrals</div>
		</div>
	);
}

export const ExtendedTotalReferralCard = borderedTile(
	ExtendedTotalReferenceCard
);
