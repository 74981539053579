export const updateCampaign = `mutation UpdateCampaign($input: UpdateCampaignInput!) {
    updateCampaign(input: $input) {
        id
        companyId
        endDate
        jobId
        name
        startDate
        tieredBonusId
    }
  }
  `;
