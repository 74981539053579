export const GetEmployeeByIdDetails = `
  query GetUser($id: ID!) {
    getUser(id: $id) {
        id
        authMethod
        accountClaimId
        accountClaim {
          id
          active
          eligible
          employeeId
          firstName
          lastName
          dateOfBirth
          title
          department
          atsId
          middleName
          isRehire
        }
        admin
        careerProfile
        cognitoId
        jobMatches {
          id
          active
          contactId
          userId
          jobId
          job {
            id
            companyId
            company {
              id
              name
            }
            subCompanyId
            subCompany {
              companyId
              id
              logo {
                bucket
                key
                region
              }
              name
            }
            title
            internalJobLink
            location
            departmentId
            department {
              id
              name
            }
            hideImInterested
            isGeneralReferral
            status
          }
          matchStatus
          relevance
          dateCreated
        }
        companyId
        company {
          id
          admins {
            id
            cognitoId
            companyId
            emailAddress
            firstName
            lastName
            title
            departmentId
            department {
              id
              name
            }
            lastLogin
            lastNotificationCheck
            incentiveEligible
            totalReferrals
            active
            role
            userGroupId
            dateCreated
          }
          managers {
            id
            cognitoId
            companyId
            emailAddress
            firstName
            lastName
            title
            departmentId
            department {
              id
              name
            }
            lastLogin
            lastNotificationCheck
            incentiveEligible
            totalReferrals
            active
            role
            userGroupId
            dateCreated
          }
          name
          defaultBonusAmount
          contactIncentiveBonus
          websiteUrl
          dashboardReferralPolicyText
          referralBonusWaitingPeriod
          bonusEarnedNote
          allowSelfReferrals
          allowInternalMobility
          internalMobilityImage {
            bucket
            region
            key
          }
          dateCreated
          brandColor
          accountType
          logo {
            bucket
            region
            key
          }
          employeeDashboard
          employeeDashboardRows
          ignoreSubcompany
          enableGenericReferralEmail
          enableLead
          hideSubcompanyOnSignup
          sendReferralColor
	        applyInternallyColor
          generalReferralColor
          referralLeadColor
          disableSmartReferrals
          disableSAMLLogin
          disableClaimYourAccountLogin
          confirmCompliance
          ssoGoogleDomain
          confirmContactEmails
          whiteLabel
          stages
          API {
            googleClientId
            microsoftClientId
          }
          theme
          symbol {
            bucket
            region
            key
          }
          background {
            bucket
            region
            key
          }
          errorImage {
            bucket
            region
            key
          }
          socialImage {
            bucket
            region
            key
          }
          subCompanies {
            companyId
            id
            name
            keywords
          }
          departments {
            active
            companyId
            id
            name
            keywords
          }
          userGroups {
            companyId
            id
            name
            keywords
          }
          enableGeneralReferrals
          referralStatus
          referralCustomStatuses
          disableManagerPermissions
          disableManagerPermissionsByType
          hideJobsPage
          disableShareLink
          disableNewReferralSMSNotification
          helpUrl
          privacyUrl
          partnerHostId
          termsUrl
          linkUrl
          linkName
          enableProspectCreation
          allowSelfReferralsInternalLink
          sendAdminNotificationsOnReferral
          sendAdminNotificationsOnInternalCandidatesFound
          enableAcceptedReferralReminder
          disableExtendedUserJobsView
          enableExtendedNetwork
          enableCareerProfile
          enableJobMatching
          enableJobNotifications
          externalUserSignUp
          userEditSettings
          hideLoginForm
          jobNotificationFrequency
          disableReferrals
          hideBonus
          includeAdminInRanking
          disableSite
          hideInterestedForGroup
          senderEmailAddress
          whiteLabelServiceName
          host
          hideShareLinkForDepartment
          hideShareLinkNoPublicUrl
          hideDateCreatedJobDetails
          hideBonusFilterOnBrowseJobs
          subCompanyLabel
          labelDepartment
          sftpFolderName
          defaultLocation
          labelEmployeeID
          giftCardStoreAPIKeys {
            apiKey
            region
          }
          giftCardStoreBalance
          giftCardStorePendingBalance
          enableCustomPage
          enableCustomPage2
          enableCustomPage3
          publicLeaderboard
          hideReferralsRanking
          customPageTitle
          customPageTitle2
          customPageTitle3
          pointsSettings
          popupTitle
          popupTitleContent
          hideWhatsApp
          hideRecruiterInfo
          hideRecruiterInfoJobDetails
          resetPopup
          defaultToSubcompany
          internalMobilityCustomTitle
          appStoreUrls {
            ios
            android
          }
          bonusReportFrequency
          employeeReportFrequency
          referralsReportFrequency
          referralsReportDateRange
        }
        emailAddress
        role
        firstName
        lastName
        title
        avatar {
          bucket
          region
          key
        }
        departmentId
        department {
          id
          name
        }
        extendedCompanies
        extendedCompaniesData {
          id
          name
          disableExtendedUserJobsView
          enableExtendedNetwork
          enableCareerProfile
          enableJobMatching
        }
        extendedContactIds
        extendedContactData {
          id
          firstName
          lastName
          importMethod
          emailAddress
          phoneNumber
          extendedUserId
          userId
          user {
            id
            cognitoId
            firstName
            lastName
            emailAddress
            companyId
          }
          jobMatches {
            id
            contactId
            userId
            jobId
            job {
              id
              companyId
              company {
                id
                name
              }
              subCompanyId
              title
              location
              departmentId
              department {
                id
                name
              }
              hideImInterested
              isGeneralReferral
              status
            }
            matchStatus
            relevance
            dateCreated
          }
        }
        userGroupId
        userGroup {
          id
          measurement
          name
          currency
          languageCode
        }
        lastLogin
        lastNotificationCheck
        incentiveEligible
        inviteStatus
        totalReferrals
        active
        managedDepartments {
          departmentId
          department {
            id
            name
          }
        }
        connectedApps
        location
        currency
        createdById
        languageCode
        dateFormat
        isAllowJobNotification 
        defaultDistance
        subCompanyId
        subCompany {
          companyId
          id
          logo {
            bucket
            key
            region
          }
          name
        }
        accessToken
        expires
        expirationDoneByToken
        openToNewRole
        jobNotificationFrequency
        jobNotificationSetting
        enableJobNotifications
        isOptoutAnalytics
        isOptoutReferralNotification
    }
  }
`;
