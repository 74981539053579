import getSymbolFromCurrency from 'currency-symbol-map';
import { Component } from 'react';
import { withApollo } from 'react-apollo';
import { USER_ROLES } from '../../../_shared/constants';
import { ml } from '../../../_shared/services/utils.js';

class BaseBonusesCard extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	compareObjects = (object1, object2, key) => {
		const object1_ = object1[key].toUpperCase();
		const object2_ = object2[key].toUpperCase();

		if (object1_ < object2_) {
			return -1;
		}

		if (object1_ > object2_) {
			return 1;
		}

		return 0;
	};

	numberWithCommas = (x) => {
		return x.toString().replaceAll(/\B(?=(\d{3})+(?!\d))/g, ',');
	};

	render() {
		const {
			allMultiLingualData,
			onClick,
			title,
			currentUser,
			earned,
			inWaitingPeriod,
			tierWise,
			languageCode,
			currencyCode,
		} = this.props;

		tierWise.sort((book1, book2) => {
			return this.compareObjects(book1, book2, 'name');
		});

		return (
			<div className="d-card height-equal" onClick={onClick}>
				{currentUser.displayAs === USER_ROLES.ADMIN && (
					<div className="d-card-head">
						<h4 className="d-title">{title}</h4>
					</div>
				)}

				{currentUser.displayAs === USER_ROLES.ADMIN && (
					<div className="bonus-card-hgt">
						<div className="justify-data-box">
							{languageCode === 'US' ||
							languageCode === null ||
							languageCode === undefined ? (
								<div className="data-card light-text bg-6CB761">
									<h5>${this.numberWithCommas(earned)}</h5>
									<p>{ml('Earned', currentUser, allMultiLingualData)}</p>
								</div>
							) : (
								<div className="data-card light-text bg-6CB761">
									<h5>
										{getSymbolFromCurrency(currencyCode)}
										{this.numberWithCommas(earned)}
									</h5>
									<p>{ml('Earned', currentUser, allMultiLingualData)}</p>
								</div>
							)}

							<div className="data-card dark-text bg-ffffff">
								<h5>
									{languageCode === 'US' ||
									languageCode === null ||
									languageCode === undefined
										? '$' + this.numberWithCommas(inWaitingPeriod)
										: this.numberWithCommas(inWaitingPeriod) +
											getSymbolFromCurrency(currencyCode)}
								</h5>
								<p>
									{ml('In Waiting Period', currentUser, allMultiLingualData)}
								</p>
							</div>
						</div>
						<div className="divider" />
						<div className="row" style={{ flex: '1 0 auto' }}>
							<div className="col-sm-5">
								<p className="label-heading">
									{' '}
									{ml(
										'Hired By Bonus Level',
										currentUser,
										allMultiLingualData
									)}{' '}
								</p>
							</div>
							<div className="col-sm-7">
								<ul
									className="list-data-wrap custom-scroll"
									style={{
										maxHeight: 100,
										overflowY: 'auto',
										paddingRight: 10,
										minHeight: '100%',
									}}
								>
									{(tierWise || []).map((value, index) => {
										return (
											<li key={value.name}>
												{' '}
												<label>{value.name} :</label>
												<span> {value.count} </span>
											</li>
										);
									})}
								</ul>
							</div>
						</div>
					</div>
				)}
			</div>
		);
	}
}

export const BonusesCard = withApollo(BaseBonusesCard);
