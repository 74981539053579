import gql from 'graphql-tag';
import get from 'lodash/get';
import { compose, graphql } from 'react-apollo';
import { parseJsonFields } from 'src/_shared/services';
import {
	GetDbReferrals as GetDatabaseReferrals,
} from '../../graphql/custom/dashboard';

export const withDashboardReferrals = (Component) => {
	return compose(
		graphql(gql(GetDatabaseReferrals), {
			options: (props) => ({
				context: {
					headers: {
						'x-frame-options': 'deny', // This header will reach the server
					},
				},
				variables:
					props.currentUser.role === 'manager'
						? {
								userId: props.currentUser.id,
								companyId: props.currentUser.companyId,
							}
						: { companyId: props.currentUser.companyId },
				fetchPolicy: 'cache-and-network',
			}),
			props(props) {
				if (!props.data.loading && !props.data.getDbReferrals) {
					setTimeout(props.data.refetch, 2000);
					return null;
				}

				let databaseReferrals = get(props, 'data.getDbReferrals', undefined);
				databaseReferrals &&= Object.assign(
					databaseReferrals,
					parseJsonFields(['referrals', 'acceptedReferrals'], databaseReferrals)
				);
				return {
					referralCounts:
						databaseReferrals && databaseReferrals.referrals
							? databaseReferrals.referrals
							: '',
					acceptedReferralCounts:
						databaseReferrals && databaseReferrals.referrals
							? databaseReferrals.acceptedReferrals
							: '',
				};
			},
		}),
		// This gets the count of webNotifications for current company that have a status of "referred" when GDPR enabled
		graphql(
			gql(
				`
        query QueryWebNotificationsByCompanyIdStatusIndex(
          $companyId: ID!
          $status: String!
          $first: Int
          $after: String
        ) {
          queryWebNotificationsByCompanyIdStatusIndex(
            companyId: $companyId
            status: $status
            first: $first
            after: $after
          ) {
            scannedCount
          }
        }
      `
			),
			{
				options: (props) => ({
					variables: {
						companyId: props.currentUser.companyId,
						status: 'referred',
					},
					fetchPolicy: 'network-only',
				}),
				props: (props) => ({
					countOfWebNotificationsWithStatusReferredForGDPR:
						props.data.queryWebNotificationsByCompanyIdStatusIndex &&
						props.data.queryWebNotificationsByCompanyIdStatusIndex.scannedCount,
				}),
			}
		)
	)(Component);
};
