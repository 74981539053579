import { Player } from '@lordicon/react';
import { useEffect, useRef, useState } from 'react';

function isSafari() {
	return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
}
const safariUser = isSafari();

export default function LordIconComponent({ icon, shouldAnimationDelay, introAnimation, hoverAnimation, isHovered, rareAnimation, frameModifier, className }) {
	const playerRef = useRef();
	const [animationState, setAnimationState] = useState((safariUser && shouldAnimationDelay) ? hoverAnimation : introAnimation);

	useEffect(() => {
		let timeoutId = null;
		if (shouldAnimationDelay && !safariUser) {
			timeoutId = setTimeout(() => {
				playerRef.current?.playFromBeginning();
			}, 500);
		} else {
			playerRef.current?.playFromBeginning();
		}

		return () => {
			if (timeoutId) {
				clearTimeout(timeoutId);
			}
		};
	}, []);

	useEffect(() => {
		if(animationState !== introAnimation) {
			if (isHovered) {
				playerRef.current?.playFromBeginning();
			} else {
				playerRef.current?.goToFrame(frameModifier);
			}
		}
	}, [isHovered]);

	useEffect(() => {
		if (animationState !== introAnimation && !isHovered) {
			playerRef.current?.goToFrame(frameModifier);
		}
	}, [animationState]);

	const handleOnComplete = () => {
		if (animationState === introAnimation) {
			setAnimationState(hoverAnimation);
			return;
		}
		if (isHovered) {
			if ((Math.random() < 0.1) && rareAnimation) {
				setAnimationState(rareAnimation);
				playerRef.current?.playFromBeginning();
			} else {
				if (animationState === rareAnimation) {
					setAnimationState(hoverAnimation);
				}
				playerRef.current?.playFromBeginning();
			}
		}
	};

	return (
		<div className={className}>
			<Player
				size={true} // Hacky workaround getting size to be determined by div wrapper
				ref={playerRef}
				icon={icon}
				state={animationState}
				onComplete={() => handleOnComplete()}
			/>
		</div>
	);
}
