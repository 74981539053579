export const downloadCSVFile = (res, fileName) => {
    const url = window.URL.createObjectURL(new Blob([res]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', `${fileName}.csv`)
    document.body.appendChild(link)
    link.click()
    link.remove()
}

export const addCommas = (numberString) => {
    return numberString && numberString.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}