import { css } from 'emotion';
import { COLORS } from '../_shared/styles/colors';

const breakpoints = [576, 767, 991, 1200];

const mq = breakpoints.map((bp) => `@media (max-width: ${bp}px)`);

export const MyProfileContainer = css({
	fontFamily: '"Open Sans", sans-serif',
	width: '100%',
	height: '100%',
	padding: 20,
	backgroundColor: COLORS.lightGray2,
});

export const CardStyles = css({
	display: 'flex',
	boxShadow: '1px 1px 15px rgba(0,0,0,0.25)',
	borderRadius: 2,
	padding: 40,
	backgroundColor: 'white',
	height: 'auto',
	maxWidth: 1270,
	[mq[1]]: {
		paddingTop: 50,
	},
	[mq[0]]: {
		flexDirection: 'column',
	},
});

export const EmployeeName = css({
	fontSize: 28,
	marginBottom: '-10px',
	lineSpacing: 0,
});

export const EmployeeEmail = css({
	fontSize: 16,
	fontWeight: '600 !important',
});

export const Heading = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 600,
	color: '#444444',
	padding: 0,
});

export const SubHeading = css({
	fontFamily: '"Open Sans", sans-serif',
	fontSize: 16,
	fontWeight: 800,
	color: '#8d99a3',
});

export const LinkStyles = css({
	fontFamily: '"Open Sans", sans-serif',
	color: COLORS.blue,
	fontWeight: '600 !important',
	display: 'flex',
	alignItems: 'center',
});

export const absoluteBtn = css({
	[mq[1]]: {
		position: 'absolute',
		top: 30,
		right: 20,
	},
});

export const FlexContainer2 = css({
	justifyContent: 'space-between',
	display: 'flex',
	marginBottom: 10,
	width: '100%',
});

export const AvatarStyles = css({
	height: 70,
	width: 70,
	marginRight: 20,
});

export const BlockContainer = css({
	width: '100%',
});

export const IconStyles = css({
	color: `${COLORS.blue} !important`,
});

export const ResendInvite = css({
	fontWeight: '600 !important',
});

export const EditProfileContainer = css({
	float: 'right',
	'& svg': {
		transform: 'translate(10px, 13px)',
	},
	marginTop: -13,
});

export const EditProfile = css({
	fontSize: 14,
});

export const NoPicture = css({
	height: 65,
	width: 65,
	backgroundColor: COLORS.lightGray3,
	'& h3': {
		fontWeight: 400,
		color: 'white',
		fontSize: 26,
		textAlign: 'center',
		lineHeight: '65px',
		marginBottom: 0,
	},
});

export const FlexContainer = css({
	display: 'flex',
	marginBottom: 10,
	marginRight: 20,
});

export const JobItemText = css({
	marginRight: 5,
	fontSize: 16,
	fontweight: 500,
	color: COLORS.lightGray,
	marginBottom: 0,
	'& span': {
		color: COLORS.darkGray,
		fontWeight: 300,
		marginLeft: 5,
	},
});

export const NotificationItemText = css({
	marginRight: 10,
	marginBottom: 10,
	'& span': {
		marginLeft: 5,
	},
});

export const TableStyles = css({
	'& th': {
		backgroundColor: '#fff !important',
	},
	'& span': {
		display: 'flex',
		justifyContent: 'center',
		width: '100%',
		textAlign: 'center',
	},
	'& p': {
		marginBottom: 0,
	},
	'& td': {
		textAlign: 'center',
	},
});

export const BtnStyles = css({
	backgroundColor: COLORS.lightGray2,
});

export const StatusStyles = css({
	backgroundColor: COLORS.green,
	width: 130,
	height: 30,
	color: '#fff',
	lineHeight: '30px',
	borderRadius: 4,
	margin: 'auto',
});

export const SelectStyles = css({
	width: '100%',
	maxWidth: 300,
	'& .ant-select-selection__choice': {
		backgroundColor: COLORS.lightGreen,
		color: COLORS.green,
	},
	'& .ant-select-selection--single': {
		height: 40,
		paddingTop: 4,
		zIndex: 2,
		backgroundColor: 'transparent',
		marginRight: -12,
	},
});
export const SelectRoleStyles = css({
	fontSize: 16,
	height: 40,
	'& .ant-select-selection--single': {
		height: 40,
		paddingTop: 4,
		backgroundColor: 'transparent',
	},
	'& .ant-select': {
		fontFamily: '"Open Sans", sans-serif',
	},
});

export const Container = css({
	display: 'block',
});

export const SortDown = css({});

export const DropDown = css({
	fontFamily: '"Open Sans", sans-serif',
});

export const SelectContainer = css({
	display: 'flex',
	'& svg': {
		transform: 'translate(-30px, 15px)',
	},
	paddingBottom: 7,
});

export const darkGray = css({
	color: COLORS.darkGray,
});

export const ResetPasswordBtn = css({
	fontFamily: '"Open Sans", sans-serif',
	color: COLORS.blue,
	padding: 0,
	fontWeight: '600 !important',
	'& span': {
		color: COLORS.blue,
	},
});

export const AddLocationBtn = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 200,
	color: COLORS.white,
	background: COLORS.blue,
	fontSize: 14,
	height: 35,
	lineHeight: '25px',
	'&:hover > span': {
		color: '#018dd3!important',
	},
});

export const btnText = css({
	paddingBottom: 3,
	color: '#fff!important',
});

export const inputContainer = (width) =>
	css({
		width,
	});

export const autocompleteDropdownContainer = css({
	position: 'absolute',
	width: 350,
	zIndex: 1000,
});

export const typeaheadRow = (active) => ({
	backgroundColor: active ? COLORS.red : COLORS.lightGray2,
});

export const stateAndRemoteContainer = css({
	marginLeft: 195,
	marginBottom: 20,
});

export const mobile = css({
	display: 'none',
	[mq[1]]: {
		display: 'block',
	},
});

export const desktop = css({
	[mq[1]]: {
		display: 'none',
	},
});

export const btnWrap = css({
	[mq[1]]: {
		display: 'flex',
		position: 'absolute',
		top: 30,
		right: 30,
	},
});

export const cancelBtnWrap = css({
	[mq[1]]: {
		marginLeft: 10,
	},
});

export const labelA = css({
	display: 'inline-block',
	marginRight: 10,
});

export const inputA = css({
	width: 275,
	display: 'inline-block',
});

export const FormItemContainer = css({
	display: 'flex',
	'& label': {
		display: 'block',
		fontSize: 14,
		color: COLORS.lightGray,
		lineHeight: '40px',
		marginRight: 10,
	},
	'& .ant-form-item': {
		marginBottom: 0,
	},
	[mq[0]]: {
		flexDirection: 'column',
		'& .ant-form-item': {
			marginBottom: 0,
		},
	},
});

export const filterContainer = css({
	width: 'auto',
	display: 'flex',
	flexWrap: 'wrap',
	[mq[1]]: {
		flexDirection: 'column',
	},
});

export const changeDirection = css({
	[mq[1]]: {
		flexDirection: 'column',
	},
});

export const LabelStyles = css({
	verticalAlign: 'middle',
	fontFamily: 'Open Sans,sans-serif',
	fontWeight: 600,
	color: COLORS.black,
});

export const SelectLocationContainer = css({
	float: 'right',
	position: 'relative',

	[mq[1]]: {
		margin: '0 0 10px !important',
		width: '100%',
	},
});

export const SelectStylesLocation = css({
	width: '250px !important',
	'& .ant-select-arrow': {
		display: 'none',
	},
	[mq[1]]: {
		width: '100%',
	},
});

export const iconLocationContainer = css({
	pointerEvents: 'none',
	zIndex: 1,
});

export const customSelectClass = {
	'& .ant-select-selection': {
		width: '100%',
	},
};
export const regularButton = (selected) =>
	css({
		marginLeft: 8,
		backgroundColor: selected ? COLORS.gray2 : COLORS.white,
		color: selected ? COLORS.white : COLORS.subHeading,
		'&:hover': {
			backgroundColor: COLORS.gray2,
			color: COLORS.white,
			borderColor: selected ? 'transparent' : COLORS.subHeading,
		},
		'&:focus': {
			backgroundColor: selected ? COLORS.gray2 : COLORS.white,
			color: selected ? COLORS.white : COLORS.subHeading,
			borderColor: selected ? 'transparent' : COLORS.subheading,
		},
	});
