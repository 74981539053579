import { Cache } from 'aws-amplify';
import get from 'lodash/get';
import { Component } from 'react';
import { withApollo } from 'react-apollo';
import { decryptUsingAES256 } from 'src/_shared/api/settings';
import { graphql, lambda, parse } from 'src/_shared/services/utils.js';

export class Redirect extends Component {
	componentDidMount() {
		const urlHostname = (data) => {
			const a = document.createElement('a');
			a.href = data;
			return a.hostname;
		};

		const auth = async (code, settings) => {
			const request = {
				code,
				requestType: 'saml',
			};
			const provider = get(settings, 'provider');
			if (provider) request.provider = provider;
			const auth = await lambda(
				{
					endpoint: 'auth-prod-api',
					variables: request,
				},
				'apiKey'
			);
			return parse(decryptUsingAES256(get(auth, 'token')));
		};

		const handleSubmitGoogle = async () => {
			localStorage.setItem('authType', 'saml');
			const saml = await auth('google');
			const clientId = get(saml, 'clientId');
			Cache.setItem('federatedOrgCode', 'google');
			Cache.setItem('federatedClientId', clientId);
			Cache.setItem('federatedDomain', get(saml, 'domain'));
			Cache.setItem('federatedRegion', get(saml, 'region', 'us-east-2'));
			const { origin } = window.location;
			const url = `https://erinapp.auth.us-east-2.amazoncognito.com/oauth2/authorize?identity_provider=Google&redirect_uri=${origin}/saml-auth/login&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&client_id=${clientId}`;
			window.location.href = url;
		};

		const handleSubmitSAML = async () => {
			const { origin } = window.location;
			const saml = await graphql({
				input: {
					id: this?.props?.nav?.match?.params?.companycode.toLowerCase(),
				},
				query: 'getSAMLAuth',
			});
			const url = `https://${saml?.domain}.auth.${saml?.region || 'us-east-2'}.amazoncognito.com/oauth2/authorize?identity_provider=${saml?.provider}&redirect_uri=${origin}/saml-auth/login&response_type=code&client_id=${saml?.clientId}`;
			window.location.href = url;
		};

		const pathname = window.location.pathname.split('/');

		const lastElement = pathname.at(-1);
		const secondLastElement = pathname.at(-2);

		if (lastElement === 'login' && pathname.length > 2) {
			const code = secondLastElement;
			Cache.setItem('federatedOrgCode', code);
			localStorage.setItem('authType', 'saml');
		}

		if (this.props.saml) {
			handleSubmitSAML();
		} else if (this.props.google) {
			handleSubmitGoogle();
		} else if (urlHostname(this.props.loc) === window.location.hostname) {
			this.props.nav.history.push(this.props.loc);
		} else {
			window.location = this.props.loc;
		}
	}

	render() {
		return <div />;
	}
}

export default withApollo(Redirect);
