import gql from 'graphql-tag';
import { compose, graphql } from 'react-apollo';
import { listJobLevels } from './listJobLevelsGraphql.js';

export const withListJobLevels = (Component) => {
	return compose(
		graphql(gql(listJobLevels), {
			options: (props) => ({
				context: {
					headers: {
						'x-frame-options': 'deny', // This header will reach the server
					},
				},
				variables: {
					filter: {
						companyId: { eq: props.currentUser.companyId },
					},
					limit: 1000,
					nextToken: null,
				},
				fetchPolicy: 'cache-and-network',
			}),
			props(props) {
				if (
					!props.data.loading &&
					(!props.data.listJobLevels || props.data.error)
				) {
					setTimeout(props.data.refetch, 2000);
					return null;
				}

				return {
					jobLevels: props.data.listJobLevels
						? props.data.listJobLevels.items
						: undefined,
				};
			},
		})
	)(Component);
};
