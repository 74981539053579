import { Badge, Popover } from 'antd';
import dayjs from 'dayjs';
import _ from 'lodash';
import { Component } from 'react';
import { withApollo } from 'react-apollo';
import WebNotificationList from '../web-notification-list/WebNotificationListComponent.jsx';

class WebNotificationsComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			userNotifications: this.props.userNotifications,
			toggle: false,
		};
	}

	componentDidUpdate(prevProps) {
		if (prevProps.userNotifications !== this.props.userNotifications) {
			if (this.props.onFetchMoreNotifications)
				this.props.onFetchMoreNotifications();
			const userNotifications = _.get(this.props, 'userNotifications', []);
			this.setState({
				userNotifications,
				nextToken:
					this.props.nextToken === undefined ? true : this.props.nextToken,
			});
		}
	}

	newNotificationCount = () => {
		const { userNotifications } = this.state;
		const { currentUser } = this.props;

		let count = 0;
		if (userNotifications) {
			for (const notification of userNotifications) {
				if (!currentUser.lastNotificationCheck) {
					count = userNotifications.length;
				} else if (
					notification.dateCreated > currentUser.lastNotificationCheck
				) {
					count += 1;
				}
			}

			return count;
		}

		return count;
	};

	toggleViewed = () => {
		const { currentUser, updateCurrentUserLastNotificationCheck } = this.props;
		const lastCheckedTime = _.get(currentUser, 'lastNotificationCheck', null);
		if (lastCheckedTime === null || this.newNotificationCount() > 0) {
			setTimeout(() => {
				try {
					this.props.onUpdate({
						input: {
							id: currentUser.id,
							lastNotificationCheck: dayjs().toISOString(),
						},
					});
				} catch (error) {
					console.log(error);
				}

				updateCurrentUserLastNotificationCheck(dayjs().toISOString());
			}, 1000);
		}
	};

	render() {
		const { userNotifications } = this.state;
		const { currentUser } = this.props;
		const sortedNotifications = _.sortBy(
			userNotifications,
			'dateCreated'
		).reverse();
		return (
			<Popover
				arrowPointAtCenter
				trigger="click"
				placement="bottomRight"
				content={
					<WebNotificationList
						dropDown
						currentUser={currentUser}
						sortedNotifications={_.take(sortedNotifications, 10)}
					/>
				}
				onOpenChange={() => this.toggleViewed()}
			>
				<Badge count={this.newNotificationCount()}>
					<i className="icon-bell" />
				</Badge>
			</Popover>
		);
	}
}

export default withApollo(WebNotificationsComponent);
