import { useEffect, useState } from "react";
import NeoBadge from '../BadgeIcon/NeoBadge';


const P2PBadge = ({badgeIcon, color, breakWords, shape = 'hexagon', captureId = null, useTilt, gradientColor}) => {
  const [badgeColors, setBadgeColors] = useState(badgeIcon);

  useEffect(() => {
    const hexToRgb = (hex) => {
      const bigint = parseInt(hex.slice(1), 16);
      return {
        r: (bigint >> 16) & 255,
        g: (bigint >> 8) & 255,
        b: bigint & 255
      };
    }

    const rgbToHsl = (r, g, b) => {
      r /= 255, g /= 255, b /= 255;
      const max = Math.max(r, g, b), min = Math.min(r, g, b);
      let h, s, l = (max + min) / 2;

      if (max === min) {
        h = s = 0; // achromatic
      } else {
        const d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
        switch (max) {
          case r: h = (g - b) / d + (g < b ? 6 : 0); break;
          case g: h = (b - r) / d + 2; break;
          case b: h = (r - g) / d + 4; break;
        }
        h /= 6;
      }

      return { h: h * 360, s: s * 100, l: l * 100 };
    }

    const hslToRgb = (h, s, l) => {
      let r, g, b;
      s /= 100;
      l /= 100;

      const c = (1 - Math.abs(2 * l - 1)) * s;
      const x = c * (1 - Math.abs((h / 60) % 2 - 1));
      const m = l - c / 2;

      if (0 <= h && h < 60) {
        r = c, g = x, b = 0;
      } else if (60 <= h && h < 120) {
        r = x, g = c, b = 0;
      } else if (120 <= h && h < 180) {
        r = 0, g = c, b = x;
      } else if (180 <= h && h < 240) {
        r = 0, g = x, b = c;
      } else if (240 <= h && h < 300) {
        r = x, g = 0, b = c;
      } else if (300 <= h && h < 360) {
        r = c, g = 0, b = x;
      }

      r = Math.round((r + m) * 255);
      g = Math.round((g + m) * 255);
      b = Math.round((b + m) * 255);

      return { r, g, b };
    }

    const rgbToHex = (r, g, b) => {
      return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()}`;
    }

    const adjustColor = (color1Hex) => {
      const rgb = hexToRgb(color1Hex);
      let { h, s, l } = rgbToHsl(rgb.r, rgb.g, rgb.b);

      h = (h + 10) % 360;
      s = Math.max(0, s - 20);
      l = Math.max(0, l - 10);

      const newRgb = hslToRgb(h, s, l);
      return rgbToHex(newRgb.r, newRgb.g, newRgb.b);
    }

    const isGrayscale = (r, g, b) => {
      return r === g && g === b;
    }

    const limitGrayscale = (hexColor) => {
      const limit = 215;
      const rgb = hexToRgb(hexColor);
      const grayScaleCheck = isGrayscale(rgb.r, rgb.g, rgb.b);
      if (grayScaleCheck) {
        if (rgb.r > limit) {
          return rgbToHex(limit, limit, limit);
        }
      }
      return hexColor;
    }

    if (color) {
      let gradient;
      if (!gradientColor) {
        gradient = adjustColor(color);
      } else {
        gradient = gradientColor;
      }

      setBadgeColors({ start: color, icon: limitGrayscale(color), end: gradient, text: '#ffffff' });
    }

  }, [color, gradientColor]);

  return (
    <NeoBadge
      useTilt={useTilt}
      captureId={captureId}
      shape={shape}
      icon={badgeIcon ?? "ANCHOR"}
      colors={{
        gradient: `linear-gradient(45deg,${badgeColors?.end ?? "#355C7D"},${badgeColors?.start ?? "#68B3AF"})`,
        icon: `${badgeColors?.icon ?? '#6366f1'}`,
        text: `${badgeColors?.text ?? '#0ea5e9'}`
      }}
      text={breakWords ?? "Achievement Unlocked"}
    />
  );
};

export default P2PBadge;