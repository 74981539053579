import gql from 'graphql-tag';

export const GetSAMLAuthByCompanyId = gql`
	query GetSAMLAuthByCompanyId($companyId: String!) {
		getSAMLAuthByCompanyId(companyId: $companyId) {
			id
			active
			autoCreateUsers
			clientId
			companyId
			departmentHeader
			loginButtonText
			options
			path
		}
	}
`;
