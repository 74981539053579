import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { updateSubCompany } from '../../graphql/custom/sub-company';

export const withUpdateSubCompany = (Component) => {
	return graphql(gql(updateSubCompany), {
		props: (props) => ({
			onUpdateSubCompany(input) {
				const optimisticResponseData = {
					...props.SubCompany,
					...input,
				};
				return props.mutate({
					context: {
						headers: {
							'x-frame-options': 'deny', // This header will reach the server
						},
					},
					variables: { input },
					optimisticResponse: {
						__typename: 'Mutation',
						updateSubCompany: {
							__typename: 'updateSubCompany',
							...optimisticResponseData,
						},
					},
				});
			},
		}),
	})(Component);
};
