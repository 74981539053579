import { css } from 'emotion';
import { COLORS } from '../_shared/styles/colors';

const breakpoints = [576, 767, 991, 1200];

const mq = breakpoints.map((bp) => `@media (max-width: ${bp}px)`);

export const dashboardMainContainer = css({
	width: '100%',
});

export const dashList = css({
	display: 'flex',
	listStyleType: 'none',
	padding: 0,
});

export const floatRight = css({
	float: 'right',
});

export const equalHeightBox = css({
	position: 'relative',
	height: '100%',
	display: 'flex',
});

export const popLabelText = css({
	fontSize: 24,
	display: 'block',
	fontWeight: 600,
	color: COLORS.red,
	marginBottom: 0,
});

export const videoTitle = css({
	textAlign: 'center',
});

export const loginPopTitle = css({
	textAlign: 'center',
});

export const videoWrap = css({
	marginBottom: 18,
	'& > iframe': {
		margin: '0 auto',
		display: 'block',
	},
});
export const btnCenter = css({
	textAlign: 'center',
});
export const btnLoginPopCenter = css({
	textAlign: 'center',
	position: 'absolute',
	bottom: 0,
	width: '100%',
});
export const roundButton = css({
	fontFamily: '"Open Sans",sans-serif',
	color: COLORS.white,
	background: COLORS.blue,
	margin: 5,
	padding: '8px 30px',
	fontSize: 14,
	height: 40,
	borderRadius: 40,
	border: 'none',
	outline: 'none !important',
});

export const linkText = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: '600 !important',
	color: COLORS.hyperLink,
	fontSize: 16,
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	display: '-webkit-box',
	lineHeight: '22px',
	maxHeight: '44px',
	'-webkit-line-clamp': 2,
	'-webkit-box-orient': 'vertical',
	textAlign: 'center',
	width: '100%',
});

export const LinkStyle = css({
	color: COLORS.blue,
	cursor: 'pointer',
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: '800 !important',
	fontSize: '1em',
	marginLeft: '5px',
});

export const cardListStyle = css({
	'& .ant-pagination': {
		textAlign: 'center',
		margin: 'auto',
	},
	marginTop: 15,
	width: '100%',
	'& .ant-list-empty-text': {
		visibility: 'hidden',
	},
});

export const NoSmartReferrals = css({
	marginTop: 40,
	textAlign: 'center',
	fontSize: 14,
	fontWeight: 400,
});

export const NoSmartReferralsWithLogo = css({
	textAlign: 'center',
	fontSize: 14,
	fontWeight: 400,
});

export const maintainBox = css({
	minHeight: '350px',
});

export const loginPopLabelText = css({
	fontSize: 24,
	display: 'block',
	fontWeight: 600,
	color: COLORS.black,
	marginBottom: 0,
});

export const loginPopButton = css({
	fontFamily: '"Open Sans",sans-serif',
	color: COLORS.white,
	background: COLORS.blue,
	margin: 5,
	padding: '8px 30px',
	fontSize: 14,
	height: 40,
	borderRadius: 40,
	border: 'none',
	outline: 'none !important',
	width: 200,
});

export const ModalStyles = css({
	'& .ant-modal-content': {
		borderRadius: 20,
	},
	fontFamily: 'Open Sans',
	'& .ant-modal-close-x': {
		display: 'none',
	},
});

export const RowStyles = css({
	display: 'flex',
	flexFlow: 'row wrap',
	'&::after': {
		content: '""',
		display: 'table',
		clear: 'both',
	},
});

export const ColumnCss = css({
	float: 'left',
	width: '33.3%',

	[mq[1]]: {
		width: '50%',
	},
	[mq[0]]: {
		width: '100%',
	},
});

export const JobHead = css({
	display: 'inline-flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	paddingRight: '90px',
});
export const jobTitle = css({
	fontWeight: 500,
	fontSize: '16px',
	paddingLeft: 5,
});

export const cardchart = css({
	display: 'inline-flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	paddingRight: '90px',
});
