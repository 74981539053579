import gql from 'graphql-tag';

export const getCompanyCustomDashboardValues = gql`
	query GetCompany($id: ID!) {
		getCompany(id: $id) {
			id
			sendReferralColor
			applyInternallyColor
			generalReferralColor
			referralLeadColor
			customText
			hideJobsPage
			enableDashboardSearch
			dashboardSearchBG {
				bucket
				region
				key
				__typename
			}
		}
	}
`;
