import { css } from 'emotion';
import { COLORS } from '../../styles/colors';

const breakpoints = [576, 767, 991, 1200];

const mq = breakpoints.map((bp) => `@media (max-width: ${bp}px)`);

export const toggleButtonContainerStyles = css({
	display: 'flex',
	alignItems: 'center',
	'& .ant-btn': {
		lineHeight: '12px',
	},
});

export const openButtonStyles = (selected) =>
	css({
		marginLeft: 5,
		marginTop: 5,
		marginBottom: 5,
		backgroundColor: selected ? COLORS.gray2 : COLORS.white,
		color: selected ? COLORS.white : COLORS.subHeading,
		'&:hover': {
			backgroundColor: COLORS.gray2,
			color: COLORS.white,
			borderColor: 'transparent',
		},
		'&:focus': {
			backgroundColor: COLORS.gray2,
			color: COLORS.white,
			borderColor: 'transparent',
		},
		height: 30,
		width: 70,
	});

export const toggleTitleStyles = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 550,
	color: COLORS.darkGray,
	fontSize: 9,
	textAlign: 'right',
	width: 30,
	[mq[1]]: {
		width: 'auto',
	},
});
