import { message } from 'antd';
import { useState } from 'react';
import { useHomeService } from '../../service/home.services.jsx';



const useGetFeedList = (query) => {
    const [feedListData, setFeedListData] = useState([]);
    const [isFeedLoading, setIsFeedLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [hasMoreData, setHasMoreData] = useState(false);
    const [isLoadMoreCalled, setIsLoadMoreCalled] = useState(false);

    const homeAPIService = useHomeService();

    const getFeedData = async (page, limit, isNewPost = false) => {
        !(page > 1) && setIsFeedLoading(true);
        setIsLoadMoreCalled(true);
        try {
            const feedListResponse = await homeAPIService.getFeedInteraction({ page, limit, ...query });
            if (feedListResponse?.data?.records) {
                const newFeedList = isNewPost
                    ? feedListResponse?.data?.records
                    : [...feedListData, ...feedListResponse?.data?.records];
                setFeedListData(newFeedList);
                setHasMoreData(feedListResponse.data.pagination?.hasNextPage || false);
            } else {
                message.error('Failed to fetch feed list! Try again later.');
            }
        } catch (error) {
            console.log(error);
            message.error('Failed to fetch feed list.');
        } finally {
            setIsFeedLoading(false);
            setIsLoadMoreCalled(false);
        }
    };

    const loadMoreData = () => {
        if (!isFeedLoading && hasMoreData) {
            setCurrentPage(currentPage + 1);
            getFeedData(currentPage + 1, 10);
        }
    };

    return {
        getFeedData,
        loadMoreData,
        feedListData,
        isFeedLoading,
        hasMoreData,
        currentPage,
        setCurrentPage,
        setIsFeedLoading,
        setFeedListData,
        isLoadMoreCalled
    };
};

export default useGetFeedList;
