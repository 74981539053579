import gql from 'graphql-tag';
import _, { get } from 'lodash';
import { graphql } from 'react-apollo';
import { queryUserInvitesByCompanyId } from '../../graphql/custom/invites';

// TODO add variables when Derrick adds companyID to invites
export const withListUserInvites = (Component, variables) => {
	return graphql(gql(queryUserInvitesByCompanyId), {
		options: {
			context: {
				headers: {
					'x-frame-options': 'deny', // This header will reach the server
				},
			},
			fetchPolicy: 'cache-and-network',
			variables,
		},
		props(props) {
			let invites = get(
				props,
				['data', 'queryUserInvitesByCompanyId', 'items'],
				undefined
			);
			invites = _.orderBy(invites, ['firstName', 'lastName'], ['asc', 'asc']);
			return {
				invites,
				refetchInvites() {
					return props.data.refetch({
						variables: {
							companyId: props.ownProps.currentUser.companyId,
							first: 10_000,
						},
					});
				},
			};
		},
	})(Component);
};
