import {
	DELETE_DEPARTMENT,
	DELETE_RECIPIENT,
	FETCH_COMPANY,
	UPDATE_SETTINGS,
} from './settingsActions.js';
import { state as initialState } from './settingsState.js';

export const settingsReducer = (state = initialState, action) => {
	switch (action.type) {
		case FETCH_COMPANY: {
			return handleFetchCompany(state, action);
		}

		case UPDATE_SETTINGS: {
			return handleUpdateSettings(state, action);
		}

		case DELETE_DEPARTMENT: {
			return handleDeleteDepartment(state, action);
		}

		case DELETE_RECIPIENT: {
			return handleDeleteRecipient(state, action);
		}

		default: {
			return state;
		}
	}
};

const handleFetchCompany = (state, action) => {
	const { companyId } = action.payload;
	const { companies } = state;

	const userCompany = companies.find((company) => company.id === companyId);
	return {
		...state,
		company: userCompany,
	};
};

const handleUpdateSettings = (state, action) => {
	const { updatedSettings } = action.payload;
	return {
		...state,
		company: updatedSettings,
	};
};

const handleDeleteDepartment = (state, action) => {
	const { deletedDepartment } = action.payload;
	return {
		...state,
		company: {
			...state.company,
			departments: state.company.departments.filter(
				(department) => department !== deletedDepartment
			),
		},
	};
};

const handleDeleteRecipient = (state, action) => {
	const { deletedRecipient } = action.payload;
	return {
		...state,
		company: {
			...state.company,
			referralBonusNotificationRecipients:
				state.company.referralBonusNotificationRecipients.filter(
					(recipient) => recipient !== deletedRecipient
				),
		},
	};
};
