import { connect } from 'react-redux';
import {
	withListMyReferralsContacts,
	withMyContacts,
} from 'src/_shared/api/components/contacts/';
import { withCreateWebNotification } from 'src/_shared/api/components/network/';
import { withCreateReferralNoUpdate as withCreateReferral } from 'src/_shared/api/components/referrals/';
import { withGetCompanyData } from 'src/_shared/api/components/settings/';
import { compose } from '../../../services/utils';
import OnDeckReferralModalWrapper from './SubmitGeneralReferralModalWrapper.jsx';

const mapStateToProperties = (state, ownProperties) => {
	const { currentUser } = state.user;
	const { allMultiLingualData } = state.dashboard;
	return {
		currentUser,
		filter: { userId: { eq: currentUser.id } },
		limit: 1000,
		companyId: currentUser.companyId,
		allMultiLingualData,
	};
};

const OnDeckReferralModalWithApi = compose(
	withMyContacts,
	withListMyReferralsContacts,
	withGetCompanyData,
	withCreateReferral,
	withCreateWebNotification
)(OnDeckReferralModalWrapper);

export const OnDeckReferralModal = connect(mapStateToProperties)(
	OnDeckReferralModalWithApi
);
