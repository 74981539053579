import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Modal } from 'antd';
import { configMode } from 'src/_shared/api/';
import { decryptUsingAES256 } from 'src/_shared/api/settings';
import StripeForm from './StripeFormComponent.jsx';

const stripePromise = loadStripe(
	decryptUsingAES256(process.env[`REACT_APP_${configMode}_STRIPEPUBLISHABLEKEY`])
);

function StripeModal(props) {
	const { visible, handleImportError, onCancel } = props;

	return (
		<Modal
			destroyOnClose
			open={visible}
			footer={null}
			handleImportError={handleImportError}
			width={500}
			onCancel={onCancel}
		>
			<Elements stripe={stripePromise}>
				<StripeForm {...props} />
			</Elements>
		</Modal>
	);
}

export default StripeModal;
