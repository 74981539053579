import gql from 'graphql-tag';
import { compose, graphql } from 'react-apollo';
import { getDashboardJobs } from '../../graphql/default';

export const withDashboardJobsManager = (Component) => {
	return compose(
		graphql(gql(getDashboardJobs), {
			options: (props) => ({
				context: {
					headers: {
						'x-frame-options': 'deny', // This header will reach the server
					},
				},
				variables: {
					userId: props.currentUser.id,
					companyId: props.currentUser.companyId,
				},
				fetchPolicy: 'cache-and-network',
			}),
			props(props) {
				if (
					!props.data.loading &&
					(!props.data.getDashboardJobs || props.data.error)
				) {
					setTimeout(props.data.refetch, 2000);
					return null;
				}

				return {
					openPositionsManager: props.data.getDashboardJobs
						? props.data.getDashboardJobs.openPositions
						: [],
					inNetworkMatchesManager: props.data.getDashboardJobs
						? props.data.getDashboardJobs.inNetworkMatches
						: [],
					jobSharesManager: props.data.getDashboardJobs
						? props.data.getDashboardJobs.jobShares
						: [],
					totalJobViewsManager: props.data.getDashboardJobs
						? props.data.getDashboardJobs.totalJobViews
						: [],
				};
			},
		})
	)(Component);
};
