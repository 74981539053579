import gql from 'graphql-tag';

export const GetUserInvite = gql`
	query GetUserInvite($id: ID!) {
		getUserInvite(id: $id) {
			id
			userId
			createdById
			companyId
			company {
				id
				name
				defaultBonusAmount
				dashboardReferralPolicyText
				bonusEarnedNote
				allowSelfReferrals
				allowInternalMobility
				internalMobilityImage {
					bucket
					region
					key
				}
				contactIncentiveBonus
				sendAdminNotificationsOnReferral
				sendAdminNotificationsOnInternalCandidatesFound
				referralBonusNotificationRecipients
				brandColor
				websiteUrl
				disableReferrals
				logo {
					bucket
					region
					key
				}
				symbol {
					bucket
					region
					key
				}
				whiteLabel
				theme
				labelDepartment
				hideSubcompanyOnSignup
				symbol {
					bucket
					region
					key
				}
				background {
					bucket
					region
					key
				}
				errorImage {
					bucket
					region
					key
				}
				subCompanies {
					companyId
					id
					logo {
						bucket
						region
						key
					}
					name
					keywords
				}
				departments {
					active
					companyId
					id
					name
					keywords
				}
				userGroups {
					id
					companyId
					measurement
					name
					keywords
					active
					currency
				}
			}
			userGroup {
				id
				companyId
				measurement
				name
				keywords
				active
				currency
			}
			emailAddress
			firstName
			lastName
			title
			role
			dateCreated
			departmentId
			userGroupId
			languageCode
		}
	}
`;
