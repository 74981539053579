export const state = {
	jobs: [],
	currentJob: {
		jobType: '',
		title: null,
		department: null,
		description: null,
		salary: {
			from: null,
			to: null,
			duration: null,
		},
		publicLink: null,
		hiringManager: null,
		referralBonus: {
			hasBonus: true,
			amount: null,
		},
		notificationType: 'NONE',
		location: {
			city: null,
			state: null,
			isRemote: false,
		},
	},
	currentCurrencyRate: 0,
	currentCurrencySymbol: '$',
};
