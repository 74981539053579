import { css } from 'emotion';
import { COLORS } from '../_shared/styles/colors';

export const EditUserContainer = css({
	width: '100%',
	height: '100%',
	padding: '20px 40px 40px',
	backgroundColor: COLORS.lightGray2,
	marginBottom: 0,
});

export const CardsContainer = css({
	display: 'flex',
});

export const CardStyles = css({
	boxShadow: '1px 1px 15px rgba(0,0,0,0.25)',
	borderRadius: 2,
	padding: '40px 150px 10px',
	backgroundColor: 'white',
	height: 'auto',
	marginTop: 20,
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
});

export const ResetPasswordTitle = css({
	color: 'COLORS.red !important',
	textAlign: 'center',
});

export const CardStyles2 = css({
	boxShadow: '1px 1px 15px rgba(0,0,0,0.25)',
	borderRadius: 2,
	padding: '30px 50px',
	backgroundColor: 'white',
	width: 350,
	marginTop: 20,
	marginLeft: 30,
	position: 'relative',
	height: 380,
});

export const Avatar = css({
	margin: 'auto',
});

export const NoPicture = css({
	margin: 'auto',
	height: 70,
	width: 70,
	backgroundColor: COLORS.lightGray3,
	'& h3': {
		color: 'white',
		width: 70,
		fontSize: 28,
		textAlign: 'center',
		lineHeight: '70px',
	},
});

export const FlexContainer = css({
	display: 'flex',
	marginBottom: 30,
	width: '100%',
});

export const EmployeeName = css({
	fontSize: 28,
	marginBottom: 20,
});

export const Heading = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 600,
	color: '#444444',
	padding: 0,
});

export const EditProfile = css({
	fontWeight: 600,
	fontFamily: '"Open Sans", sans-serif',
	textAlign: 'center',
	color: `${COLORS.red} !important`,
	fontSize: 28,
	marginBottom: 24,
});

export const BackLink = css({
	fontFamily: '"Open Sans", sans-serif',
	color: COLORS.blue,
	border: 'none',
	backgroundColor: 'transparent',
	paddingLeft: 5,
	cursor: 'pointer',
	width: 100,
	fontSize: 18,
	fontWeight: '600 !important',
});

export const BackIcon = css({
	fontSize: 18,
});

export const SubmitBtn = css({
	fontFamily: 'Open Sans',
	backgroundColor: COLORS.red,
	color: 'white',
	fontSize: 20,
	fontWeight: 300,
	padding: '10px 30px',
	height: 'auto',
	border: 'none',
	margin: '20px auto',
});
