import { css } from 'emotion';
import get from 'lodash/get';
import { COLORS } from '../../../styles/colors';

const breakpoints = [576, 767, 991, 1200];

const mq = breakpoints.map((bp) => `@media (max-width: ${bp}px)`);

export const headerMenuItemContainer = (isActive, theme) => {
	const highlightColor = get(theme, 'menuHighlightColor', COLORS.red);
	return css({
		height: 70,
		flexBasis: 160,
		textAlign: 'center',
		h2: {
			...(isActive ? { color: COLORS.darkGray } : {}),
			margin: 0,
			fontSize: 16,
			fontFamily: '"Open Sans", sans-serif',
			fontWeight: 400,
		},
		borderBottom: `solid 7px ${isActive ? highlightColor : COLORS.white}`,
		'&:hover': {
			borderBottom: `solid 7px ${highlightColor}`,
		},
		[mq[1]]: {
			height: 46,
			width: 'auto',
			flex: '1 1 auto',
		},
	});
};

export const itemLinkContainer = css({
	width: '100%',
	height: '100%',
	paddingTop: 7,
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	textDecoration: 'none !important',
});
