import NominationRewardsList from 'src/allrewards/presentation/NominationRewardsList/NominationRewardsList.jsx';

const NominationRewardsListContainer = (props) => {
    const {
        title,
        openNomination,
        nominatedAwardWinner,
      badgeList
    } = props;

    return (
        <NominationRewardsList
            title={title}
            openNomination={openNomination}
            badgeList={badgeList}
            nominatedAwardWinner={nominatedAwardWinner}
            emptyString={props.emptyString}
        />
    );
};

export default NominationRewardsListContainer;
