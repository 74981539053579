import { Component } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import { Upload, message } from 'antd';
import {
	ModalTitle,
	alignCenter,
	darkGrayIcon,
	darkGrayText,
	darkGrayTextHeavy,
	downloadBtnRow as downloadButtonRow,
	downloadText,
	uploadContainer,
} from './addContactsModalStyles.js';

class AddContactsLinkedin extends Component {
	constructor(props) {
		super(props);
		this.state = {
			error: false,
			size: 'large',
			emailInput: '',
		};
	}

	render() {
		const { Dragger } = Upload;

		const draggerProperties = {
			name: 'file',
			multiple: true,
			action: '//jsonplaceholder.typicode.com/posts/',
			onChange(info) {
				const { status } = info.file;
				if (status === 'done') {
					message.success(`${info.file.name} file uploaded successfully.`);
					this.props.updateUserIncentiveEligibility(true);
				} else if (status === 'error') {
					message.error(`${info.file.name} file upload failed.`);
				}
			},
		};

		return (
			<div>
				<h1 className={ModalTitle}>Import your LinkedIn file</h1>
				<div className={alignCenter}>
					<p className={darkGrayText}>
						Drag and Drop your LinkdIn csv file <br /> to import your contacts
					</p>
					<div className={uploadContainer}>
						<Dragger {...draggerProperties}>
							<p>
								<UploadOutlined className={darkGrayIcon} />
							</p>
							<p className={darkGrayTextHeavy}>Drag & Drop</p>
							<p className={downloadText}>browse files</p>
						</Dragger>
					</div>
					<div className={downloadButtonRow}>
						<a href="https://res.cloudinary.com/dpoo75fay/raw/upload/v1539894343/erin_employee_import.csv">
							Download you LinkedIn contact file here
						</a>
					</div>
				</div>
			</div>
		);
	}
}
export default AddContactsLinkedin;
