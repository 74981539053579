import { Component } from 'react';
import refer from '../../../_shared/assets/refer.png';
import { borderedTile } from '../../../_shared/components/bordered-tile';
import { USER_ROLES } from '../../../_shared/constants';
import { COLORS } from '../../../_shared/styles/colors';
import { middleContainer } from './referCardStyles.js';

class BaseReferCard extends Component {
	getReferralAcceptedColor() {
		if (
			this.props.acceptedReferralCounts &&
			this.props.acceptedReferralCounts.current >
				this.props.acceptedReferralCounts.previous
		) {
			return COLORS.dashboardGreen;
		}

		if (
			this.props.acceptedReferralCounts &&
			this.props.acceptedReferralCounts.current <
				this.props.acceptedReferralCounts.previous
		) {
			return COLORS.red;
		}

		if (
			this.props.acceptedReferralCounts &&
			(this.props.acceptedReferralCounts.current === 0 ||
				this.props.acceptedReferralCounts.current ===
					this.props.acceptedReferralCounts.previous)
		) {
			return COLORS.dashboardLightOrange;
		}
	}

	getReferralAcceptedStatus() {
		if (
			this.props.acceptedReferralCounts &&
			this.props.acceptedReferralCounts.current >
				this.props.acceptedReferralCounts.previous
		) {
			return 'arrow-up';
		}

		if (
			this.props.acceptedReferralCounts &&
			this.props.acceptedReferralCounts.current <
				this.props.acceptedReferralCounts.previous
		) {
			return 'arrow-down';
		}

		if (
			this.props.acceptedReferralCounts &&
			(this.props.acceptedReferralCounts.current === 0 ||
				this.props.acceptedReferralCounts.current ===
					this.props.acceptedReferralCounts.previous)
		) {
			return 'minus';
		}
	}

	getReferralColor() {
		if (
			this.props.referralCounts &&
			this.props.referralCounts.current > this.props.referralCounts.previous
		) {
			return COLORS.dashboardGreen;
		}

		if (
			this.props.referralCounts &&
			this.props.referralCounts.current < this.props.referralCounts.previous
		) {
			return COLORS.red;
		}

		if (
			this.props.referralCounts &&
			(this.props.referralCounts.current === 0 ||
				this.props.referralCounts.current ===
					this.props.referralCounts.previous)
		) {
			return COLORS.dashboardLightOrange;
		}
	}

	getReferralStatus() {
		if (
			this.props.referralCounts &&
			this.props.referralCounts.current > this.props.referralCounts.previous
		) {
			return 'arrow-up';
		}

		if (
			this.props.referralCounts &&
			this.props.referralCounts.current < this.props.referralCounts.previous
		) {
			return 'arrow-down';
		}

		if (
			this.props.referralCounts &&
			(this.props.referralCounts.current === 0 ||
				this.props.referralCounts.current ===
					this.props.referralCounts.previous)
		) {
			return 'minus';
		}
	}

	render() {
		const { currentUser } = this.props;
		return (
			<div style={{ cursor: 'default' }}>
				{currentUser.displayAs === USER_ROLES.EMPLOYEE && (
					<div>
						<img src={refer} className={middleContainer} />
					</div>
				)}
			</div>
		);
	}
}

export const ReferCard = borderedTile(BaseReferCard);
