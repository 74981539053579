import { Modal } from "antd"

import { CoreBadge } from "../../../RnRCommonComponent"

import './style.scss'

const WinnerListModalModal = ({ showModal, setShowModal, winnerList, badgeDetails }) => {

    const handleCancel = () => {
        setShowModal({ show: false, winnerList: [], badge: badgeDetails })
    }

    return (
      <Modal
        visible={showModal}
        title={`Winners`}
        closable={true}
        onCancel={handleCancel}
        footer={null}
        width={"420px"}
        className="winner-list-modal reward-modal"
      >
          <div className='row'>
              <div className='col-lg-5 d-flex align-items-center justify-content-center'>
                  <div className="text-center">
                      {badgeDetails && <CoreBadge
                        badgeType={badgeDetails?.badgeType?.code}
                        badgeIcon={badgeDetails?.icon?.code}
                        label={badgeDetails?.label}
                        color={badgeDetails?.color}
                        gradientColor={badgeDetails?.gradientColor || badgeDetails?.gradientcolor}
                      />}

                  </div>
              </div>
              <div className='col-lg-7 mt-md-0 mt-sm-2'>
                  <div className='d-flex justify-content-start flex-column'>
                      <b>
                          {badgeDetails?.name}
                      </b>
                      <p>
                          {badgeDetails?.description}
                      </p>
                      <b>
                          Nomination Winners
                      </b>
                  </div>

                  {winnerList?.map((data, index) => {
                      return <div className='d-flex justify-content-start py-1'>
                          <div className={'pr-1'}>{index + 1}.</div>
                          <div className={'pr-1'}>{data?.firstName} {data?.lastName}</div>
                          <div className={data.isCurrent ? 'reward-leaderboard-list-points-blue' : 'reward-leaderboard-list-points'}>{data?.points}</div>
                      </div>
                  })
                  }
              </div>
          </div>
      </Modal>
    )
}

export default WinnerListModalModal
