export const createInternalMobility = `mutation createInternalMobility($input: CreateInternalMobilityInput!) {
    createInternalMobility(input: $input) {
      id
      companyId
      contentFirst
      contentMiddle
      contentLast
    dateCreated
    }
  }
  `;
