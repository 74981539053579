import { Component } from 'react';
import { lambda } from 'src/_shared';

class ReferralCountComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			color: null,
			referralsCount: 0,
		};
	}

	async componentDidMount() {
		try {
			const data = await this.getEmployeeDetailReferralCount();
			if (data?.message?.referralsCount) {
				this.setState({
					referralsCount: data.message.referralsCount,
				});
			}
		} catch (error) {
			console.error('Error fetching referral count:', error);
		}
	}

	getEmployeeDetailReferralCount = async () => {
		const employeeId = this.props.userId;
		const endpoint = 'employeeDetailReferralCount';
		return await lambda({ endpoint, variables: { employeeId } });
	};

	render() {
		const { referralsCount } = this.state;

		return (
			<div
				style={{
					color: this.state.color,
					textAlign: 'center',
					width: 40,
				}}
			>
				{referralsCount}
			</div>
		);
	}
}

export default ReferralCountComponent;
