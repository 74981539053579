import { restrictToBoundingRect } from './restrictToBoundingRect.js';

export const restrictToDndContext = ({
	draggingNodeRect,
	transform,
	scrollableAncestorRects,
}) => {
	const boundingRect = scrollableAncestorRects[1];
	if (!draggingNodeRect || !boundingRect) {
		return transform;
	}

	return restrictToBoundingRect(transform, draggingNodeRect, boundingRect);
};
