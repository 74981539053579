import { Card, Col, Modal, Row } from 'antd';
import getSymbolFromCurrency from 'currency-symbol-map';
import get from 'lodash/get';
import { Component } from 'react';
import { Link } from 'react-router-dom';
import MyReferralsBonusDetailsModal from 'src/_shared/components/MyReferralsBonusDetailsModalComponent.jsx';
import WebIcon from 'src/_shared/components/WebIconComponent.jsx';
import {
	calculateReferralBonus,
	formatDate,
} from 'src/_shared/services/utils.js';
import { ProgressIndicator } from '../progress-indicator';
import {
	BonusAmount,
	candidateNameStyles,
	cardMainStyles,
	companyNameText,
	linkStyles,
	locationStyle,
	miscTextStyles,
	referrerNameStylesBlue,
	statusStyles,
	statusTextStyles,
} from './myReferralCardStyles.js';

class MyReferralCardComponent extends Component {
	constructor() {
		super();
		this.state = {
			visible: false,
		};
	}

	handleCancel = () => {
		this.setState({
			visible: false,
		});
	};

	showModal = () => {
		this.setState({
			visible: true,
		});
	};

	render() {
		const {
			currentTieredBonus,
			currentUser,
			referral,
			currentCurrencyRate,
			currentCurrencySymbol,
			statusText,
			bonusDetailsText,
			jobText,
			referredCandidateText,
			totalBonusText,
			hiredDateText,
			startDateText,
			bonusPaymentsText,
			bonusNotesText,
			closeText,
			viewBonusDetailsText,
			referredText,
			acceptedText,
			hiredText,
			notHiredText,
			noResponseText,
			inActiveText,
		} = this.props;

		const contacts = get(this.props, 'employee.contacts');
		const contactIncentiveBonus = get(
			this.props,
			'employee.company.contactIncentiveBonus'
		);
		const referralBonus = get(this.props, 'referral.referralBonus');
		const status = get(
			this.props,
			'referral.status',
			get(this.props, 'referral.referralRequestedStatus')
		);
		const referredOnText =
			get(this.props, 'referral.type') === 'referralRequested'
				? 'Requested On'
				: get(this.props, 'referredOnText');
		const referredByText =
			get(this.props, 'referral.type') === 'referralRequested'
				? 'Requested From'
				: get(this.props, 'referredByText');
		const languageCode = get(currentUser, 'languageCode', 'US');
		const referralDate =
			get(this.props, 'referral.type') === 'referralRequested'
				? formatDate(get(this.props, 'referral.dateCreated'), languageCode)
				: formatDate(get(this.props, 'referral.referralDate'), languageCode);
		const hireDate = formatDate(
			get(this.props, 'referral.hireDate'),
			languageCode
		);
		const department = get(this.props, 'referral.job.department');
		const title = get(this.props, 'referral.job.title');
		const locationDetails = JSON.parse(
			get(this.props, 'referral.job.location', '{}')
		);
		const referralBonusDetails = get(
			this.props,
			'referral.job.referralBonus',
			'{}'
		);
		const { city } = locationDetails;
		const { state } = locationDetails;
		const { amount } = referralBonusDetails;
		let userGroupCurrency = get(currentUser, 'userGroup.currency');
		const referralStatusText = get(
			currentUser,
			'company.referralStatus',
			'Interviewing'
		);
		if (userGroupCurrency === null) userGroupCurrency = 'USD';
		const symbol = getSymbolFromCurrency(userGroupCurrency);
		const referralStatus = (status) => {
			switch (status) {
				case 'accepted': {
					return 'Accepted';
				}

				case 'hired': {
					return 'Hired';
				}

				case 'referred': {
					return 'Referred';
				}

				case 'notHired': {
					return 'Not Hired';
				}

				case 'interviewing': {
					return 'Interviewing';
				}

				case 'declined': {
					return 'Declined';
				}

				case 'noresponse': {
					return 'No Response';
				}

				case 'inactive': {
					return 'Inactive';
				}

				default: {
					return null;
				}
			}
		};

		return (
			<div>
				<Card
					hoverable
					className={cardMainStyles}
					title={
						<Row>
							<Col span={12}>
								<div style={{ whiteSpace: 'normal' }}>
									<Link
										to={`/browsejobs/${get(this.props, 'referral.job.id')}`}
									>
										<span className={candidateNameStyles}>
											{' '}
											{title === null ? 'Job Title' : title}
										</span>
									</Link>
									<Row>
										<div className={companyNameText}>
											{get(this.props, 'referral.company.name')}
										</div>
									</Row>
									<Row style={{ paddingTop: 5 }}>
										<WebIcon name="placeholder" size={18} />
										<span className={locationStyle}>
											{city && state ? `${city}, ${state}` : 'Remote'}
										</span>
									</Row>
									{referralBonus && !currentTieredBonus && (
										<div>
											<h3 className={BonusAmount}>
												{this.props.referralType === 'self' ? (
													<span style={{ color: '#ffb461' }}>
														Self Referred
													</span>
												) : (
													<div>
														<span style={{ fontSize: '16px' }}>
															{`${symbol}${calculateReferralBonus(
																contactIncentiveBonus,
																amount,
																contacts,
																this.props.currentTieredBonus,
																'employee',
																get(currentUser, 'userGroupId')
															)}`.replaceAll(/\B(?=(\d{3})+(?!\d))/g, ',')}
														</span>
													</div>
												)}
											</h3>
										</div>
									)}
								</div>
							</Col>
							<Col span={10} offset={2} style={{ textAlign: 'right' }}>
								<div>
									{/* Referred By Name of Referrer on date */}
									<div>
										<span className={miscTextStyles}> {referredByText} </span>
										<span className={referrerNameStylesBlue}>
											{get(referral, 'user.firstName')}{' '}
											{get(referral, 'user.lastName')}
										</span>
									</div>
									<div>
										<span className={miscTextStyles}> {referredOnText} </span>
										<span className={miscTextStyles}>{referralDate}</span>
									</div>
									{hireDate === null ? null : (
										<div>
											<span className={miscTextStyles}> Hired on </span>
											<span className={miscTextStyles}>{hireDate}</span>
										</div>
									)}
								</div>
							</Col>
						</Row>
					}
				>
					<Row
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							width: '100%',
						}}
					>
						<span className={statusStyles}>
							{' '}
							{statusText}:{' '}
							<span className={statusTextStyles}>
								{status === 'interviewing'
									? referralStatusText
									: referralStatus(status)}
							</span>
						</span>
						<span />
						{status === 'hired' &&
						get(this.props, 'currentUser.role') !== 'extendedUser' ? (
							<a className={linkStyles} onClick={this.showModal}>
								{viewBonusDetailsText}
							</a>
						) : (
							<span style={{ width: '25%' }} />
						)}
					</Row>
					<Row>
						<ProgressIndicator
							type="referral"
							points={5}
							status={status}
							referral={this.props.referral}
							referredText={referredText}
							acceptedText={acceptedText}
							interviewingText={get(
								currentUser,
								'company.referralStatus',
								'Interviewing'
							)}
							hiredText={hiredText}
							notHiredText={notHiredText}
							noResponseText={noResponseText}
							inActiveText={inActiveText}
						/>
					</Row>
				</Card>
				{get(referral, 'referralType') !== 'self' && (
					<Modal
						title="Bonus Details"
						open={this.state.visible}
						footer={null}
						onCancel={this.handleCancel}
					>
						<MyReferralsBonusDetailsModal
							currentTieredBonus={this.props.currentTieredBonus}
							status={status}
							userGroup={get(referral, 'user.userGroupId')}
							currentCurrencyRate={currentCurrencyRate}
							currentCurrencySymbol={currentCurrencySymbol}
							data={referral}
							handleCancel={this.handleCancel}
							bonusDetailsText={bonusDetailsText}
							jobText={jobText}
							referredCandidateText={referredCandidateText}
							totalBonusText={totalBonusText}
							hiredDateText={hiredDateText}
							startDateText={startDateText}
							bonusPaymentsText={bonusPaymentsText}
							bonusNotesText={bonusNotesText}
							closeText={closeText}
							currentUser={currentUser}
						/>
					</Modal>
				)}
			</div>
		);
	}
}

export default MyReferralCardComponent;
