export const queryUserGroupsByCompanyIdIndex = `query QueryUserGroupsByCompanyIdIndex(
    $companyId: ID!
    $first: Int
    $after: String
  ) {
    queryUserGroupsByCompanyIdIndex(
      companyId: $companyId
      first: $first
      after: $after
    ) {
      items {
        id
        companyId
        measurement
        name
        keywords
        active
        currency
        languageCode
      }
      nextToken
    }
  }
  `;
