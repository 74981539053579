import { CheckOutlined } from '@ant-design/icons';
import { Alert, Button, Form, Input, Modal } from 'antd';
import { Auth } from 'aws-amplify';
import { Component, createRef } from 'react';
import {
	AlertError,
	CheckIcon,
	FormStyles,
	ModalContainer,
	ModalTitle,
	SubmitBtn as SubmitButton,
	SubmitBtnContainer as SubmitButtonContainer,
} from './resetPasswordModalStyles.js';

const submitButtonStyled = SubmitButton();

class ResetPasswordModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			matchError: false,
			lengthError: false,
			newError: false,
			submitError: false,
			oldPasswordError: false,
			success: false,
		};
		this.formRef = createRef();
	}

	handleSubmit = (values) => {
		if (values.password !== values.confirmPassword) {
			this.setState({ matchError: true });
			setTimeout(() => {
				this.formRef.current.resetFields();
				this.removeAlerts();
			}, 2000);
			return;
		}

		if (values.password === values.oldPassword) {
			this.setState({ newError: true });
			setTimeout(() => {
				this.formRef.current.resetFields();
				this.removeAlerts();
			}, 2000);
			return;
		}

		if (values.password.length < 8) {
			setTimeout(() => {
				this.formRef.current.resetFields();
				this.removeAlerts();
			}, 2000);
			this.setState({ lengthError: true });
			return;
		}

		if (err) {
			this.setState({ submitError: true });
			setTimeout(() => {
				this.formRef.current.resetFields();
				this.removeAlerts();
			}, 2000);
			return;
		}

		Auth.signIn(this.props.employee.emailAddress, values.oldPassword)
			.then((user) => {
				return Auth.changePassword(user, values.oldPassword, values.password);
			})
			.then(() => {
				this.setState({ success: true });
				setTimeout(() => {
					this.formRef.current.resetFields();
					this.removeAlerts();
				}, 2000);
			})
			.catch((error) => {
				if (error.code === 'NotAuthorizedException') {
					this.setState({ oldPasswordError: true });
				} else {
					this.setState({ submitError: true });
				}

				setTimeout(() => {
					this.formRef.current.resetFields();
					this.removeAlerts();
				}, 2000);
				console.error(error);
			});
	};

	removeAlerts = () => {
		this.setState({
			matchError: false,
			lengthError: false,
			newError: false,
			submitError: false,
			oldPasswordError: false,
			success: false,
		});
	};

	render() {
		const { visible, handleCancel } = this.props;
		const {
			matchError,
			lengthError,
			submitError,
			newError,
			oldPasswordError,
			success,
		} = this.state;
		return (
			<Modal
				destroyOnClose
				open={visible}
				footer={null}
				onCancel={handleCancel}
			>
				<div className={ModalContainer}>
					<h1 className={ModalTitle}>Reset Employee Password</h1>
					<Form
						ref={this.formRef}
						className={FormStyles}
						onFinish={this.handleSubmit}
					>
						<Form.Item
							name="oldPassword"
							rules={[
								{
									required: true,
									message: 'Please input your old password!',
								},
							]}
						>
							<Input type="password" placeholder="Enter Old Password" />
						</Form.Item>
						<Form.Item
							name="password"
							rules={[
								{
									required: true,
									message: 'Please input a new password!',
								},
							]}
						>
							<Input type="password" placeholder="Enter New Password" />
						</Form.Item>
						<Form.Item
							name="confirmPassword"
							rules={[
								{
									required: true,
									message: 'Please re-enter your new password!',
								},
							]}
						>
							<Input type="password" placeholder="Re-enter New Password" />
						</Form.Item>
						{matchError ? (
							<Alert
								className={AlertError}
								type="error"
								message="Passwords Do Not Match! Please Try Again."
							/>
						) : null}
						{newError ? (
							<Alert
								className={AlertError}
								type="error"
								message="New password can not be the same as current password."
							/>
						) : null}
						{lengthError ? (
							<Alert
								className={AlertError}
								type="error"
								message="New password must be at least 6 characters long."
							/>
						) : null}
						{submitError ? (
							<Alert
								className={AlertError}
								message="We are having trouble submitting the form, please try again later."
								type="error"
							/>
						) : null}
						{oldPasswordError ? (
							<Alert
								className={AlertError}
								type="error"
								message="Old password is incorrect. Please try again."
							/>
						) : null}
						{success ? (
							<Alert
								className={AlertError}
								message="Password successfully changed!"
								type="success"
							/>
						) : null}

						<Form.Item className={SubmitButtonContainer}>
							<Button className={submitButtonStyled} htmlType="submit">
								Reset Password
								<CheckOutlined className={CheckIcon} />
							</Button>
						</Form.Item>
					</Form>
				</div>
			</Modal>
		);
	}
}

export default ResetPasswordModal;
