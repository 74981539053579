import { Button } from 'antd';
import { ml } from 'src/_shared/services/utils.js';

export function CancelAddJob(props) {
	const { allMultiLingualData, currentUser, handleOk, handleCancel, theme } =
		props;

	return (
		<>
			<h5 className="text-center">
				{ml('Are you sure?', currentUser, allMultiLingualData)}
			</h5>
			<div className="modal-footer-btn">
				<Button size="large" className="btn-min-width" onClick={handleCancel}>
					{ml('Discard Job', currentUser, allMultiLingualData)}
				</Button>
				<Button
					type="primary"
					size="large"
					className="btn-min-width"
					onClick={handleOk}
				>
					{ml('Continue Job', currentUser, allMultiLingualData)}
				</Button>
			</div>
		</>
	);
}
