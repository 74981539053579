export const deleteJobMatch = `mutation DeleteJobMatch($input: DeleteJobMatchInput!) {
  deleteJobMatch(input: $input) {
    id
    contactId
    contact {
      id
      firstName
      lastName
      emailAddress
      socialMediaAccounts
      userId
      companyId
      phoneNumber
      jobHistory
      fullContactData
      dateCreated
    }
    userId
    jobId
    job {
      id
      companyId
      departmentId
      title
      description
      publicLink
      salary
      location
      hiringManagerId
      createdById
      referralBonus
      shares
      views
      referrals {
        id
        companyId
        contactId
        userId
        jobId
        note
        message
        referralDate
        referralBonusNotificationSent
        hireDate
      }
      matches {
        id
        firstName
        lastName
        emailAddress
        socialMediaAccounts
        userId
        companyId
        phoneNumber
        jobHistory
        fullContactData
        dateCreated
      }
      dateCreated
    }
    matchStatus
    relevance
    dateCreated
  }
}
`;
