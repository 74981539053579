import { css } from 'emotion';
import { COLORS } from '../../../_shared/styles/colors';

export const headerClass = css({
	textTransform: 'uppercase',
	fontSize: 16,
	letterSpacing: 2,
	fontWeight: 300,
	color: `${COLORS.heading} !important`,
	marginBottom: 20,
});

export const middleContainer = css({
	height: 83,
	margin: 'auto',
	marginBottom: 20,
});

export const middleColumn = css({
	textAlign: 'center',
});

export const middleColumnSingle = css({
	textAlign: 'center',
	width: '100%',
});

export const bigNumbers = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 800,
	color: COLORS.black,
	fontSize: '2em',
});

export const bigNumber = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 800,
	color: COLORS.black,
	fontSize: '2em',
});

export const bigNumbersOpen = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 400,
	color: COLORS.white,
	fontSize: '2.75em',
	margin: 'auto',
});

export const rightReferralBoardText = css({
	textAlign: 'center',
	float: 'right',
});

export const numberSubtitles = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 600,
	color: COLORS.white,
	fontSize: '0.85em',
});

export const numberSubtitlesOpen = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 600,
	color: COLORS.lightGray,
	fontSize: '14px',
	width: '100%',
	whiteSpace: 'nowrap',
});

export const numSubtitlesOpen = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 600,
	color: COLORS.lightGray,
	fontSize: '0.85em',
	width: '100%',
});

export const smallIcons = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 600,
	color: COLORS.subHeading,
	fontSize: '0.85em',
	marginLeft: 21,
	opacity: 0.7,
});

export const earnedTitle = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 600,
	color: COLORS.lightGray,
	fontSize: '14px',
	width: '100%',
});

export const referralsBox = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 600,
	color: COLORS.green,
	fontSize: '2.0em',
	margin: 'auto',
});

export const jobTitleText = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: '600 !important',
	color: COLORS.hyperLink,
	fontSize: 16,
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	display: '-webkit-box',
	lineHeight: '22px',
	maxHeight: '44px',
	'-webkit-line-clamp': 2,
	'-webkit-box-orient': 'vertical',
	textAlign: 'center',
	width: '100%',
	gridColumn: '1 / 3',
});

export const locationStyles = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 400,
	color: COLORS.darkGray,
	fontSize: 14,
});

export const centeredText = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: '600 !important',
	color: COLORS.lightGray,
	fontSize: 16,
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	display: '-webkit-box',
	'-webkit-line-clamp': 2,
	'-webkit-box-orient': 'vertical',
	textAlign: 'center',
	width: '100%',
});

export const grayHeaderClass = css({
	textTransform: 'uppercase',
	fontSize: 16,
	letterSpacing: 2,
	fontWeight: 300,
	color: `${COLORS.lightGray} !important`,
});

export const grayText = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: '600 !important',
	color: COLORS.lightGray,
	fontSize: 16,
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	display: '-webkit-box',
	'-webkit-line-clamp': 2,
	'-webkit-box-orient': 'vertical',
	width: '100%',
});

export const ReferralRequestBtn = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 200,
	color: COLORS.white,
	background: COLORS.blue,
	fontSize: 12,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	width: '100%',
	height: 35,
	borderRadius: '5px',
	border: `2px solid ${COLORS.blue}`,
});

export const jobInfo = css({
	display: 'grid',
	gridTemplateColumns: '18px 1fr',
	alignItems: 'center',
	gap: '0.2rem 0.3rem',
	svg: {
		color: '#B1B1B1',
	},
	img: {
		filter:
			'invert(78%) sepia(0%) saturate(674%) hue-rotate(284deg) brightness(93%) contrast(87%)',
	},
});

export const subCompanyContainerStyles = css({
	fontSize: 12,
	marginTop: 5,
});

export const subCompanyStyles = css({
	color: COLORS.gray3,
	fontSize: 12,
	fontWeight: 400,
	padding: '0 2px',
});

export const Department = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 400,
	color: COLORS.darkGray,
	fontSize: 14,
	overflow: 'hidden',
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
});

export const closeIcon = css({
	fontSize: '16px',
	fontWeight: 400,
	color: COLORS.lightGray5,
	position: 'absolute',
	top: '10px',
	right: '10px',
	border: `2px solid transparent`,
	borderRadius: 3,
	'&:active': {
		borderColor: COLORS.darkGray,
		color: COLORS.darkGray,
	},
	'&:focus': {
		borderColor: COLORS.darkGray,
		color: COLORS.darkGray,
	},
});

export const closeButton = css({
	display: 'block',
	width: 40,
	height: 40,
	fontSize: 16,
	fontStyle: 'normal',
	lineHeight: 40,
	textAlign: 'center',
	position: 'absolute',
	top: '-20px',
	right: '-20px',
	'&:active': {
		borderColor: COLORS.darkGray,
		color: COLORS.darkGray,
	},
	'&:focus': {
		borderColor: COLORS.darkGray,
		color: COLORS.darkGray,
	},
});

export const DefaultCss = css({
	cursor: 'default',
});

export const DefaultWithTransformCss = css({
	cursor: 'default',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'stretch',
	justifyContent: 'center',
	width: '100%',
});
