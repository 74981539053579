import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { createUserGroup } from '../../graphql/default';

export const withCreateUserGroup = (Component) => {
	return graphql(gql(createUserGroup), {
		props: (props) => ({
			onCreateUserGroup(input) {
				return props
					.mutate({
						context: {
							headers: {
								'x-frame-options': 'deny', // This header will reach the server
							},
						},
						variables: input,
					})
					.then((resp) => resp.data.createUserGroup);
			},
		}),
	})(Component);
};
