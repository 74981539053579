import gql from 'graphql-tag';
import get from 'lodash/get';
import { compose, graphql } from 'react-apollo';
import {
	queryWebNotificationsByRequestingUserIdIndex,
	updateWebNotification,
} from '../../graphql/custom/users';

export const withListReferralRequestingNotifications = (Component) => {
	return compose(
		graphql(gql(queryWebNotificationsByRequestingUserIdIndex), {
			options: (props) => ({
				pollInterval: 300_000,
				context: {
					headers: {
						'x-frame-options': 'deny', // This header will reach the server
					},
				},
				variables: {
					requestingUserId: props.currentUser.id,
					first: 1000,
					nextToken: null,
				},
				fetchPolicy: 'cache-and-network',
			}),
			props(props) {
				let requestingNotifications = get(
					props,
					'data.queryWebNotificationsByRequestingUserIdIndex.items',
					[]
				);
				requestingNotifications = requestingNotifications.map(
					(notification) => {
						const newNotification = notification;
						if (typeof get(notification, 'job.referralBonus') === 'string') {
							newNotification.job.referralBonus = JSON.parse(
								get(notification, 'job.referralBonus', '{}')
							);
						}

						return newNotification;
					}
				);
				return {
					requestingNotifications,
				};
			},
		}),
		graphql(gql(updateWebNotification), {
			props: (props) => ({
				onUpdateNotification(input) {
					const optimisticResponseData = {
						...input.input,
					};
					props.mutate({
						variables: input,
						optimisticResponse: {
							__typename: 'Mutation',
							updateWebNotification: {
								...optimisticResponseData,
								__typename: 'updateWebNotification',
							},
						},
						update(proxy) {
							const data = proxy.readQuery({
								query: gql(queryWebNotificationsByRequestingUserIdIndex),
								variables: {
									userId: props.ownProps.currentUser.id,
									first: 1000,
									nextToken: null,
								},
							});
							proxy.writeQuery({
								query: gql(queryWebNotificationsByRequestingUserIdIndex),
								data,
								variables: {
									requestingUserId: props.ownProps.currentUser.id,
									first: 1000,
									nextToken: null,
								},
							});
						},
					});
				},
			}),
		})
	)(Component);
};
