import React from "react";
import { useSelector } from "react-redux";
import { Spinner } from "../_shared/index.js";
import PhysicalRewardsContainer from "./container/PhysicalRewardsContainer/PhysicalRewardsContainer.jsx";
import { PhysicalRewardsServiceProvider } from "./service/physicalRewards.service.jsx";

export const PhysicalRewards = (props) => {
  const company = useSelector((state) => state.user.currentUser.company)
  if (!company) return <Spinner />
  return <>
    <PhysicalRewardsServiceProvider>
      <PhysicalRewardsContainer />
    </PhysicalRewardsServiceProvider>
  </>
};

export default PhysicalRewards;
