import { useState } from 'react'

import ManageNominatedRewardContainer from '../ManageNominatedRewardContainer'
import RewardsTablePresentation from '../../presentation/RewardsTablePresentation'

const RewardsTableContainer = ({ getNominationConfiguration, nominatedBadgeList, nominatedBadgeListLoader }) => {
    const [showEditModal, setShowEditModal] = useState({
        show: false,
        awardId: "",
        nomineeCount: 0
    })
    return (
        <>
            <RewardsTablePresentation
                rewards={nominatedBadgeList}
                nominatedBadgeListLoader={nominatedBadgeListLoader}
                setShowModal={setShowEditModal} />
            {showEditModal.show ? <ManageNominatedRewardContainer
                awardId={showEditModal.awardId}
                showModal={showEditModal.show}
                nomineeCount={showEditModal.nomineeCount}
                getNominationConfiguration={getNominationConfiguration}
                setShowModal={() => {
                    setShowEditModal({
                        show: false,
                        awardId: "",
                        nomineeCount: 0
                    })
                }} /> : null}
        </>
    )
}

export default RewardsTableContainer