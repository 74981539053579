import { Card, Tooltip, Skeleton, Typography } from 'antd';
import { Component } from 'react';
import { Link } from 'react-router-dom';
import { JobLocation } from 'src/_shared/index.js';
import graphIconUrl from '../../_shared/assets/icon-graph.svg';
import { mapReferralStatus, ml, lambda, getBonusAmount } from '../../_shared/services/utils.js';
import { generalContainer, generalText } from './jobCardStyles.js';

const { Paragraph } = Typography;

const fetchReferralCount = async (jobId) => {
	try {
		const endpoint = 'jobDetailReferralCount';
		let referralsCount = 0;
		let acceptedReferralsCount = 0;
		const data = await lambda({ endpoint, variables: { jobId } });
		if (data?.message) {
			referralsCount = data.message?.referralsCount;
			acceptedReferralsCount = data.message?.acceptedReferralsCount;
		}
		return { referralsCount, acceptedReferralsCount };
	} catch (error) {
		console.error('Error fetching referral count:', error);
		return 0;
	}
};

class JobCard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			referralsCount: 0,
			acceptedReferralsCount: 0,
		};
	}

	async componentDidMount() {
		const { referralsCount = 0, acceptedReferralsCount = 0 } = await fetchReferralCount(this.props.jobId);
		this.setState({ referralsCount, acceptedReferralsCount });
	}

	render() {
		const {
			allMultiLingualData,
			currentUser,
			job,
			loading = false,
			matches = [],
		} = this.props;

		const { referralsCount, acceptedReferralsCount } = this.state;

		const {
			isHotJob = false,
			id,
			title,
			department,
			shares = 0,
			views = 0,
			externalJobId,
			referralBonus,
			isGeneralReferral,
		} = job;

		const goodMatches = matches.filter((jobMatch) => jobMatch.relevance >= 30 && jobMatch.matchStatus !== false);
		let currencyCode = currentUser?.userGroup?.currency;
		if (currencyCode === null) currencyCode = 'USD';

		const location = job?.job?.location;
		const locations = job?.job?.locations || [];

		const generalReferralJob = () => (
			<Tooltip
				arrowPointAtCenter
				placement="topRight"
				title={ml(
					'General Jobs represent multiple openings. A new referral and bonus (if applicable) will be created when the candidate is moved to a different job.',
					currentUser,
					allMultiLingualData
				)}
				style={{ fontWeight: 1000, fontStyle: 'italic' }}
			>
				<div className={generalContainer}>
					<span className={generalText}>General Job</span>
				</div>
			</Tooltip>
		);
		if (loading) {
			return (
				<Card bordered={false} bodyStyle={{ padding: 0, height: '250px' }} className="job-card manage-job-card">
					<Skeleton loading={loading} active>
						<div></div>
					</Skeleton>
				</Card>
			);
		}
		return (
			<Card bordered={false} bodyStyle={{ padding: 0 }} className="job-card manage-job-card">
					<div className="job-card-inner manage-job-card">
						<div className="job-card-header manage-job-card">
							<div className="job-title-wrap">
								<h4 className="job-title">
									<Link
										style={{ width: '100%', marginBottom: 0 }}
										to={`/jobs/${id}`}
									>
										<Paragraph
											ellipsis={{
												rows: 3,
												expandable: false,
												tooltip: {
													title: title,
													placement: 'topRight',
												},
											}}
											component="span"
											style={{ width: '100%', marginBottom: 0, color: 'inherit' }}
										>
											{title}
										</Paragraph>
									</Link>
								</h4>

								{isHotJob && (
									<ul className="actions">
										<li>
											<i className="icon-fire text-orange hot-job-icon" />
										</li>
									</ul>
								)}
							</div>

							<div className="job-info-wrap">
								<ul className="job-info">
									<JobLocation
										allMultiLingualData={allMultiLingualData}
										currentUser={currentUser}
										location={location}
										locations={locations}
									/>

									{department?.name && (
										<li>
											<i className="icon-folder" />
											<span>{department.name || ''}</span>
										</li>
									)}
								</ul>
							</div>

							{isGeneralReferral ? (
								<div style={{ display: 'flex', paddingLeft: 5 }}>
									{generalReferralJob()}
								</div>
							) : (
								referralBonus?.hasBonus ? (
									<>
										{ml('Bonus', currentUser, allMultiLingualData)}:
										<span className="mjd-bonus">
											{' '}{getBonusAmount(job, currentUser)}
									</span>
								</>
								) : null
							)}

							{externalJobId && externalJobId !== true && externalJobId !== null && externalJobId !== '' && (
								<div className="job-id">
									{'ID#: '}
									{externalJobId}
								</div>
							)}
						</div>

						<div className="job-card-footer manage-job-card">
							<ul className="job-status manage-job-card">
								<li>
									<h4 className="job-data">{acceptedReferralsCount}</h4>
									<span className="job-data-type">
										{mapReferralStatus('accepted', currentUser.company)}
									</span>
								</li>
								<li>
									<h4 className="job-data">{referralsCount}</h4>
									<span className="job-data-type">Referrals</span>
								</li>
								<li>
									<h4 className="job-data"> {views} </h4>
									<span className="job-data-type">Job Views</span>
								</li>
							</ul>

							<div className="job-card-bottom manage-job-card">
								<div className="card-bottom">
									<i className="icon-share" />
									<span className="name">Job Shares: {shares}</span>
								</div>
								<div className="card-bottom">
									<img
										src={graphIconUrl}
										alt="A small svg icon of a bar graph"
										style={{ fill: '#fff', width: 14, height: 'auto' }}
									/>
									<span className="name">Total Matches: {goodMatches.length}</span>
								</div>
							</div>
						</div>
					</div>
			</Card>
		);
	}
}

export default JobCard;