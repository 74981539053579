export const contactData = [
	{
		id: 1,
		firstName: 'Lionel',
		lastName: 'Higgins',
		email: 'lhiggins@test.com',
		socialMedia: [
			{
				value: 'https://linkedin.com/in/test/',
				type: 'linkedin',
			},
			{
				value: 'https://www.facebook.com/profile.php?id=xxxxxxxxx',
				type: 'facebook',
			},
		],
		avatar: null,
		dateAdded: new Date(Date.now() - 8_640_000),
		addedFrom: 'LinkedIn',
	},
	{
		id: 2,
		firstName: 'Joelle',
		lastName: 'Brooks',
		email: 'jbrooks@test.com',
		socialMedia: [
			{
				value: 'https://linkedin.com/in/test/',
				type: 'linkedin',
			},
			{
				value: 'https://www.facebook.com/profile.php?id=xxxxxxxxx',
				type: 'facebook',
			},
		],
		avatar: null,
		dateAdded: new Date(Date.now() - 86_400_004),
		addedFrom: 'Facebook',
	},
	{
		id: 3,
		firstName: 'Bill',
		lastName: 'Murray',
		email: 'bmurray@test.com',
		socialMedia: [
			{
				value: 'https://linkedin.com/in/test/',
				type: 'linkedin',
			},
			{
				value: 'https://www.facebook.com/profile.php?id=xxxxxxxxx',
				type: 'facebook',
			},
		],
		avatar: 'https://fillmurray.com/50/50',
		dateAdded: new Date(Date.now() - 186_400_004),
		addedFrom: 'Contacts',
	},
	{
		id: 4,
		firstName: 'Dan',
		lastName: 'Akroyd',
		email: 'dakroyd@test.com',
		socialMedia: [
			{
				value: 'https://linkedin.com/in/test/',
				type: 'linkedin',
			},
			{
				value: 'https://www.facebook.com/profile.php?id=xxxxxxxxx',
				type: 'facebook',
			},
		],
		avatar: null,
		dateAdded: new Date(Date.now() - 864_023),
		addedFrom: 'Google Contacts',
	},
	{
		id: 5,
		firstName: 'Nicholas',
		lastName: 'Cage',
		email: 'ccage@test.com',
		socialMedia: [
			{
				value: 'https://linkedin.com/in/test/',
				type: 'linkedin',
			},
			{
				value: 'https://www.facebook.com/profile.php?id=xxxxxxxxx',
				type: 'facebook',
			},
		],
		avatar: 'https://placecage.com/50/50',
		dateAdded: new Date(Date.now()),
		addedFrom: 'Manually Added',
	},
	{
		id: 6,
		firstName: 'Steve',
		lastName: 'Martin',
		email: 'smartin@test.com',
		socialMedia: [
			{
				value: 'https://linkedin.com/in/test/',
				type: 'linkedin',
			},
			{
				value: 'https://www.facebook.com/profile.php?id=xxxxxxxxx',
				type: 'facebook',
			},
		],
		avatar: null,
		dateAdded: new Date(Date.now() - 38_640_000),
		addedFrom: 'LinkedIn',
	},
];
