import gql from 'graphql-tag';

export const GetAccountClaimByEmployeeIdCompanyId = gql`
	query GetAccountClaimByEmployeeIdCompanyId(
		$employeeId: String!
		$companyId: ID!
	) {
		getAccountClaimByEmployeeIdCompanyId(
			employeeId: $employeeId
			companyId: $companyId
		) {
			id
			atsId
			active
			claimed
			eligible
			companyId
			company {
				id
				theme
				symbol {
					bucket
					region
					key
				}
				background {
					bucket
					region
					key
				}
				errorImage {
					bucket
					region
					key
				}
				logo {
					bucket
					region
					key
				}
				whiteLabel
				brandColor
			}
			dateOfBirth
			employeeId
			firstName
			subCompanyId
			lastName
			title
			userId
			department
			group
		}
	}
`;
