import { Input, Select } from 'antd';
import _ from 'lodash';
import get from 'lodash/get';
import { Component } from 'react';
import RichTextEditor from 'react-rte';
import { ml } from 'src/_shared/services/utils.js';
import { AddJobsFooter } from '../footer/AddJobFooterComponent.jsx';

const toolbarConfig = {
	// Optionally specify the groups to display (displayed in the order listed).
	display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS'],
	INLINE_STYLE_BUTTONS: [
		{ label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
		{ label: 'Italic', style: 'ITALIC' },
	],
	BLOCK_TYPE_BUTTONS: [{ label: 'UL', style: 'unordered-list-item' }],
};

const { Option } = Select;

export class AddJobPageTwo extends Component {
	constructor(props) {
		super(props);
		this.state = {
			title: '',
			department: '',
			jobDescriptionRaw: RichTextEditor.createEmptyValue(),
			description: null,
			theme: get(props, 'theme', {}),
			externalJobId: '',
		};
	}

	componentDidMount() {
		const { description, title, department, externalJobId, subCompanyId } =
			this.props.currentJob;

		const selecteddepartment = department;

		this.setState({
			jobDescriptionRaw: description
				? RichTextEditor.createValueFromString(description, 'html')
				: RichTextEditor.createEmptyValue(),
			title,
			department: selecteddepartment,
			description,
			externalJobId,
			subCompanyId: subCompanyId || '',
		});
	}

	onChangeJobId = (e) => {
		const { value } = e.target;

		if (value === '') {
			this.setState({ externalJobId: null });
		} else {
			this.setState({ externalJobId: value });
		}
	};

	onChangeJobTitle = (e) => {
		const { value } = e.target;
		this.setState({
			title: value,
		});
	};

	onChangeRTE = (value) => {
		this.setState({
			jobDescriptionRaw: value,
			description: value.toString('html'),
		});
		if (this.state.description === '<p><br></p>') {
			this.setState({ description: null });
		}
	};

	footerButtons = (page) => {
		const { allMultiLingualData, currentUser, theme } = this.props;
		return (
			<AddJobsFooter
				allMultiLingualData={allMultiLingualData}
				currentUser={currentUser}
				page={page}
				theme={theme}
				handleNext={() => {
					this.props.handleNext(page, { ...this.props.current, ...this.state });
				}}
				handleBack={() => {
					this.props.handleBack(page);
				}}
			/>
		);
	};

	handleChangeDepartment = (value) => {
		this.setState({ department: { id: value.key, name: value.label } });
	};

	handleChangeSubCompany = (value) => {
		this.setState({ subCompanyId: value });
	};

	renderJobId = () => {
		const { externalJobId } = this.state;
		const { allMultiLingualData, currentUser } = this.props;
		return (
			<div className="col-md-6">
				<div className="custom-form-group">
					<label className="custom-label">
						{ml('Req Id', currentUser, allMultiLingualData)}
						<span className="sub-label"> (optional)</span>
					</label>
					<Input
						className="custom-input"
						value={externalJobId}
						onChange={this.onChangeJobId.bind(this)}
					/>
				</div>
			</div>
		);
	};

	renderJobTitleAndDepartment = () => {
		const { title, department } = this.state;
		const { allMultiLingualData, currentUser, departments, errors } =
			this.props;
		const options = _.orderBy(
			departments,
			[(department) => department.name.toLowerCase()],
			['asc']
		).map((item) => {
			return <Option key={item.id}>{item.name}</Option>;
		});
		return (
			<div className="row">
				<div className="col-md-6">
					<div className="custom-form-group">
						<span className="custom-label">
							{ml('Job Title', currentUser, allMultiLingualData)}
						</span>
						<Input
							className="custom-input"
							value={title}
							placeholder={ml(
								'Ex. Marketing Director',
								currentUser,
								allMultiLingualData
							)}
							onChange={this.onChangeJobTitle.bind(this)}
						/>
						{errors.title && <div className="input-error">{errors.title}</div>}
					</div>
				</div>
				<div className="col-md-6">
					<div className="custom-form-group">
						<label className="custom-label">
							{ml('Department', currentUser, allMultiLingualData)}
						</label>
						{department ? (
							<Select
								labelInValue
								className="custom-input"
								placeholder="Select a department"
								value={{ key: department.id, label: department.name }}
								onSelect={(value) => this.handleChangeDepartment(value)}
							>
								{options}
							</Select>
						) : (
							<Select
								labelInValue
								className="custom-input"
								placeholder={ml(
									'Select a department',
									currentUser,
									allMultiLingualData
								)}
								onSelect={(value) => this.handleChangeDepartment(value)}
							>
								{options}
							</Select>
						)}
						{errors.department && (
							<div className="input-error">{errors.department}</div>
						)}
					</div>
				</div>
			</div>
		);
	};

	renderRTE = () => {
		const { allMultiLingualData, currentUser, errors } = this.props;
		return (
			<div className="custom-form-group">
				<label className="custom-label">
					{ml('Job Description', currentUser, allMultiLingualData)}
					<span className="sub-label">
						{ml(
							'Describe the responsibility of the job, required experience, education, etc',
							currentUser,
							allMultiLingualData
						)}
					</span>
				</label>
				<RichTextEditor
					toolbarConfig={toolbarConfig}
					value={this.state.jobDescriptionRaw}
					rootStyle={{ minHeight: 215, height: 215 }}
					editorStyle={{
						height: 160,
						overflow: 'scroll',
						fontFamily: 'inherit',
					}}
					onChange={this.onChangeRTE}
				/>
				{errors.description && (
					<div className="input-error">{errors.description}</div>
				)}
			</div>
		);
	};

	renderSubCompanies = () => {
		const { subCompanies } = this.props;
		return (
			<div className="col-md-6">
				{/* Only show subCompany filter if subCompanies exist */}
				{subCompanies.length > 0 ? (
					<div className="custom-form-group">
						<label className="custom-label">
							Subcompany
							<span className="sub-label"> (optional)</span>
						</label>
						<Select
							className="custom-input"
							value={get(this.state, 'subCompanyId')}
							onSelect={(subCompanyId) =>
								this.handleChangeSubCompany(subCompanyId)
							}
						>
							<Option value="">None</Option>

							{_.orderBy(
								subCompanies,
								[(subCompany) => subCompany.name.toLowerCase()],
								['asc']
							).map((subCompany) => {
								return (
									<Option key={subCompany.id} value={subCompany.id}>
										{subCompany.name}
									</Option>
								);
							})}
						</Select>
					</div>
				) : null}
			</div>
		);
	};

	render() {
		const footer = this.footerButtons(this.props.page);
		return (
			<main>
				<div>
					{this.renderJobTitleAndDepartment()}
					{this.renderRTE()}
					<div className="row">
						{this.renderJobId()}
						{this.renderSubCompanies()}
					</div>
					{footer}
				</div>
			</main>
		);
	}
}
