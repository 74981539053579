import { css } from 'emotion';
import { COLORS } from '../../styles/colors';

const breakpoints = [576, 767, 991, 1200];

const mq = breakpoints.map((bp) => `@media (max-width: ${bp}px)`);

export const headerContainer = css({
	backgroundColor: COLORS.white,
	borderBottom: `1px solid ${COLORS.gray}`,
	width: '100%',
});

export const FlexContainer = css({
	display: 'flex',
	alignItems: 'center',
	width: '100%',
	maxWidth: '100%',
	padding: '0 10px',
	[mq[1]]: {
		flexWrap: 'wrap',
	},
});

export const menuListContainer = css({
	display: 'flex',
	justifyContent: 'flex-start',
	width: '100%',
	listStyle: 'none',
	margin: 0,
	padding: 0,
	[mq[2]]: {
		margin: '0 auto',
	},
	[mq[1]]: {
		order: 3,
		flex: '1 1 100%',
		borderTop: `1px solid ${COLORS.gray}`,
		margin: '0 -10px',
	},
});

export const companyName = css({
	display: 'none',
	fontSize: '20px',
	color: '#000',
	[mq[1]]: {
		display: 'block',
		margin: '0 auto',
	},
});

export const SelectStyles = css({
	width: '100%',
	'& .ant-select-selection__choice': {
		backgroundColor: COLORS.lightGreen,
		color: COLORS.green,
	},
	'& .ant-select-selection--single': {
		height: 40,
		paddingTop: 4,
		zIndex: 2,
		backgroundColor: 'transparent',
	},
});

export const hamBurger = css({
	width: 26,
	height: 30,
	cursor: 'pointer',
	flex: '0 0 26px',
	display: 'none',
	'& div': {
		height: 2,
		backgroundColor: '#242e3f',
		margin: '6px 0',
	},
	[mq[2]]: {
		display: 'block',
	},
});
