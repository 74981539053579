import PropTypes from 'prop-types';
import { useState } from 'react';

export const ColorCard = ({
	bgColor,
	count = 0,
	description,
	isCardDataSet = true,
	title = '',
	isOneSided = false,
}) => {
	const [isHovered, setIsHovered] = useState(false);

	const handleMouseEnter = () => setIsHovered(true);
	const handleMouseLeave = () => setIsHovered(false);
	return (
		<div
			className="color-card-container"
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
		>
			<div
				className={`color-card ${isCardDataSet && !isOneSided && isHovered ? 'flipped' : ''}`}
				style={{ backgroundColor: bgColor }}
			>
				{isCardDataSet && (
					<div className="color-card-inner">
						<div className="color-card-front">
							<h4>{count}</h4>
							<p>{title}</p>
						</div>
						{!isOneSided && isCardDataSet && (
							<div className="color-card-back">
								<p className="scale-down">{description}</p>
							</div>
						)}
					</div>
				)}
			</div>
		</div>
	);
};

ColorCard.propTypes = {
	bgColor: PropTypes.string,
	count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	description: PropTypes.any,
	isCardDataSet: PropTypes.bool,
	title: PropTypes.string,
	isOneSided: PropTypes.bool,
};
