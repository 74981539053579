import { connect } from 'react-redux';
import { ReferralModalWrapper } from '../../_shared/referral-modal';
import { compose } from '../../_shared/services/utils.js';

const mapStateToProperties = (state) => {
	const { currentUser } = state.user;
	const { allMultiLingualData } = state.dashboard;
	return {
		allMultiLingualData,
		currentUser,
		filter: { userId: { eq: currentUser.id } },
		limit: 1000,
	};
};

const ReferralModalWithApi = compose()(ReferralModalWrapper);

export const ReferralModal =
	connect(mapStateToProperties)(ReferralModalWithApi);
