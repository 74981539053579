import { createContext, useContext } from 'react';
import { useRnRAPIService } from '../../rewardsAndRecognitionHelper/service/RnRAPI.service.jsx';
import { PHYSICAL_REWARDS_API_ENDPOINTS } from '../models/physicalRewardsConstants.js';

const PhysicalRewardsServiceContext = createContext(null);

export const PhysicalRewardsServiceProvider = (props) => {
  const APIService = useRnRAPIService();

  const getPhysicalRewardsList = (request) => {
    return APIService.get(PHYSICAL_REWARDS_API_ENDPOINTS.GET_PHYSICAL_REWARDS_LIST, request);
  }

  const getRewardsStatistics = (request) => {
    return APIService.get(PHYSICAL_REWARDS_API_ENDPOINTS.GET_REWARDS_STATISTICS);
  }

  const PhysicalRewardsServiceOperations = {
    getPhysicalRewardsList,
    getRewardsStatistics
  };

  return (
    <PhysicalRewardsServiceContext.Provider
      value={PhysicalRewardsServiceOperations}
    >
      {props.children}
    </PhysicalRewardsServiceContext.Provider>
  );
};

export const usePhysicalRewardsService = () => {
  return useContext(PhysicalRewardsServiceContext);
};