import { useEffect, useState } from "react";
import { BADGE_TYPE } from "../../RnRCommonComponent/CoreBadge/rewardsConstants";
import { message } from "antd";
import { useRnRCommonService } from "../../RnRCommonComponent/service/RnRCommon.service.jsx";
import { useManageRewardsService } from "../../manageRewards/services/manageRewards.service.jsx";
import { useNominatedRewardsService } from "../service/NominatedRewards.service.jsx";

const useManageNominatedReward = () => {
    const [badgeList, setBadgeList] = useState([])
    const [empLoginType, setEmpLoginType] = useState([])
    const [empPayType, setEmpPayType] = useState([])
    const [empLevel, setEmpLevel] = useState([])
    const [showLoader, setShowLoader] = useState({
        badgeList: false,
        empLoginType: false,
        empPayType: false,
        empLevel: false,
    })
    const commonAPIService = useRnRCommonService();
    const manageRewardsService = useManageRewardsService();
    const nominatedAPIService = useNominatedRewardsService();

    const getAllBadgeList = () => {
        setShowLoader({
            badgeList: true,
            empLoginType: true,
            empPayType: true,
            empLevel: true,
        })
        commonAPIService.getBadgesList({ page: 1, limit: 100, visibility: true, type: BADGE_TYPE.NOMINATED }).then((response) => {
            if (response?.code === 200) {
                if (response?.data) {
                    setBadgeList(response.data.records)
                }
            } else {
                message.error('Failed to fetch badges list! Try again later.');
            }
        }).catch(() => {
            message.error('Failed to fetch badges list! Try again later.');
        }).finally(() => {
            setShowLoader({
                ...showLoader,
                badgeList: false
            })
        });

        manageRewardsService.getEmployeeLevels().then((response) => {
            if (response?.code === 200) {
                if (response?.data) {
                    setEmpLevel(response.data)
                }
            } else {
                message.error('Failed to fetch badges list! Try again later.');
            }
        }).catch(() => {
            message.error('Failed to fetch badges list! Try again later.');
        }).finally(() => {
            setShowLoader({
                ...showLoader,
                empLevel: false
            })
        });

        nominatedAPIService.getEmployeeLoginType().then((response) => {
            if (response?.code === 200) {
                if (response?.data) {
                    setEmpLoginType(response.data)
                }
            } else {
                message.error('Failed to fetch badges list! Try again later.');
            }
        }).catch(() => {
            message.error('Failed to fetch badges list! Try again later.');
        }).finally(() => {
            setShowLoader({
                ...showLoader,
                empLoginType: false
            })
        });

        manageRewardsService.getEmployeePayType().then((response) => {
            if (response?.code === 200) {
                if (response?.data) {
                    setEmpPayType(response.data)
                }
            } else {
                message.error('Failed to fetch badges list! Try again later.');
            }
        }).catch(() => {
            message.error('Failed to fetch badges list! Try again later.');
        }).finally(() => {
            setShowLoader({
                ...showLoader,
                empPayType: false
            })
        });
    };

    const createNominationConfiguration = (values) => {
        const nominationConfigObject = {
            id: values?.id,
            badgeId: values?.badgeId,
            startDate: values?.startDate,
            endDate: values?.endDate,
            status: values?.status,
            awardedCount: parseInt(values?.nominationCriteria?.nominator?.awardedCount),
            nomineeEmployeeLevelId: values?.nominationCriteria?.nominee?.employeeLevelId,
            nomineeEmployeelogInTypeId: values?.nominationCriteria?.nominee?.employeelogInTypeId,
            nomineeEmployeePayTypeId: values?.nominationCriteria?.nominee?.employeePayTypeId,
            nominatorEmployeeLevelId: values?.nominationCriteria?.nominator?.employeeLevelId,
            nominatorEmployeelogInTypeId: values?.nominationCriteria?.nominator?.employeelogInTypeId,
            nominatorEmployeePayTypeId: values?.nominationCriteria?.nominator?.employeePayTypeId,
        }
        return nominatedAPIService.createNominationConfiguration(nominationConfigObject);
    }

    const getNominationConfigurationById = (id) => {
        return commonAPIService.getNominationConfigurationById(id);
    }

    useEffect(() => {
        getAllBadgeList()
    }, [])

    return ({
        empLevel,
        badgeList,
        empLoginType,
        empPayType,
        loading: Object.values(showLoader).flat().includes(true),
        createNominationConfiguration,
        getNominationConfigurationById
    })
}

export default useManageNominatedReward