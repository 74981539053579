import { Component } from 'react';
import { getEnvironment, slackNotificationErrors } from './_shared/services';

export default class ErrorHandler extends Component {
	static getDerivedStateFromError(error) {
		// Update state to trigger fallback UI
		return { hasError: true, error };
	}

	constructor(props) {
		super(props);
		this.state = { hasError: false, error: null };
	}

	componentDidCatch(error, errorInfo) {
		this.logError(error, errorInfo);
	}

	IgnoreErrors = (error_) => {
		console.log(error_);
		try {
			if (error_ === null) return false;
			let skipError = false;
			const error = error_.toString();
			const errors = [
				'Invariant Violation',
				'TypeError',
				'ReferenceError',
			];
			for (const er of errors) {
				if (error.slice(0, er.length) === er) skipError = true;
			}

			if (skipError === true) return true;
			if (getEnvironment() === 'dev') return true;

			if (
				String(process.env.REACT_APP_FORCE_PROD).toLowerCase() == 'true'
			)
				return true;

			return false;
		} catch (error) {
			console.log(error);
		}
	};

	async logError(error, errorInfo) {
		let errorLoc = errorInfo.componentStack;
		errorLoc = errorLoc.slice(0, 1800);
		if (!this.IgnoreErrors(error)) {
			await slackNotificationErrors({
				username: 'errors',
				title: 'Application Crash: Web',
				message: `${error.toString()} ${errorLoc}`,
				location: window.location.href,
			});
		}

		console.error(error, errorInfo);
	}

	refreshApp = () => {
		window.location.reload();
	};

	render() {
		if (
			!this.state.hasError ||
			(this.state.hasError && this.IgnoreErrors(this.state.error))
		) {
			return <>{this.props.children}</>;
		}

		// Render fallback UI
		return (
			<div className="error-wrapper">
				<div className="error-container text-center">
					<h1>Woops! Something Went Wrong.</h1>
					<p style={{ fontSize: '1rem' }}>
						Try{' '}
						<a href="javascript:void(0)" onClick={this.refreshApp}>
							refreshing
						</a>{' '}
						the page. Please contact support if the issue persists.
					</p>
				</div>
			</div>
		);
	}
}
