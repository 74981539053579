import { connect } from 'react-redux';
import { compose } from 'redux';
import { withDashboardJobsData } from 'src/_shared/api/components/dashboard/';
import { withListDepartment } from 'src/_shared/api/components/departments/';
import { withListUserGroup } from 'src/_shared/api/components/userGroups/';
import { withListUserInvites } from 'src/_shared/api/components/userInvites/';
import { dashboardActions, manageJobsActions } from 'src/actions.js';
import EmployeesDashboardComponent from './EmployeesDashboardComponent.jsx';

const mapStateToProperties = (state) => {
	const { currentUser } = state.user;
	const { allMultiLingualData } = state.dashboard;
	return {
		currentUser,
		error: state.dashboard.error,
		modalState: state.dashboard.modalState,
		filter: { companyId: { eq: currentUser.companyId } },
		limit: 1000,
		companyId: currentUser.companyId,
		first: 1000,
		currentClient: state.user.currentClient,
		allMultiLingualData,
		importError: state.dashboard.importError,
		importModalState: state.dashboard.importModalState,
	};
};

const mapDispatchToProperties = (dispatch) => {
	return {
		inviteEmployeesStarted() {
			dispatch(dashboardActions.createInviteEmployeesStartedAction());
		},
		inviteEmployeesCancelled() {
			dispatch(dashboardActions.createInviteEmployeesCancelledAction());
		},
		inviteEmployeesSubmit(
			users,
			fileData,
			role,
			createdById,
			companyId,
			languageCode
		) {
			dispatch(
				dashboardActions.createInviteEmployeesAction(
					users,
					fileData,
					role,
					createdById,
					companyId,
					languageCode
				)
			);
		},
		inviteEmployeesFailed(error) {
			dispatch(dashboardActions.createInviteEmployeesFailedAction(error));
		},

		setCurrentCurrencyRate(currencyRate) {
			dispatch(manageJobsActions.setCurrentCurrencyRate(currencyRate));
		},
		setCurrentCurrencySymbol(currencySymbol) {
			dispatch(manageJobsActions.setCurrentCurrencySymbol(currencySymbol));
		},
		setMultiLingualData(data) {
			dispatch(dashboardActions.createSetMultiLingualData(data));
		},
		accountClaimImportStarted() {
			dispatch(dashboardActions.createAccountClaimImportStartedAction());
		},
		accountClaimImportCancelled() {
			dispatch(dashboardActions.createAccountClaimImportCancelledAction());
		},
		accountClaimImportSubmit(fileData) {
			dispatch(dashboardActions.createAccountClaimImportAction(fileData));
		},
		accountClaimImportFailed(error) {
			dispatch(dashboardActions.createAccountClaimImportFailedAction(error));
		},
	};
};

const EmployeesDashboardContainer = compose(
	withListUserInvites,
	withListDepartment,
	withDashboardJobsData,
	withListUserGroup
)(EmployeesDashboardComponent);

export const EmployeesDashboard = connect(
	mapStateToProperties,
	mapDispatchToProperties
)(EmployeesDashboardContainer);
