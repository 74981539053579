import { Button } from 'antd';
import { get } from 'lodash';
import { Link } from 'react-router-dom';
import { ml } from 'src/_shared/services/utils.js';
import { USER_ROLES } from '../../../_shared/constants';

export function JobDetailsCard(props) {
	const {
		allMultiLingualData,
		currentUser,
		job: { status, notificationType, id, createdBy } = {},
		onClickCloseJob,
		handleOpenJob,
		turnOnOffHotJob,
		isHotJob,
	} = props;

	let isManagerPermissionDisabled = get(
		currentUser,
		'company.disableManagerPermissions',
		false
	);
	// Preserves backwards compatibility and future compatibility with disableManagerPermissions
	const managerJobs = currentUser.company?.disableManagerPermissionsByType
		? JSON.parse(currentUser.company.disableManagerPermissionsByType)
				.managerJobs
		: isManagerPermissionDisabled
			? 'readOnly'
			: 'edit';
	isManagerPermissionDisabled = managerJobs !== 'edit';
	const displayAs = get(currentUser, 'displayAs');
	return (
		<div className="mj-referral-card">
			<div className="mjr-card-body">
				<h5>
					{ml('Job Details', currentUser, allMultiLingualData)}
					<span>{`ID: #${id}`}</span>
				</h5>
				<p>
					{ml('Status:', currentUser, allMultiLingualData)}
					<span>{status === 'open' ? 'Open' : 'Closed'}</span>
					{isManagerPermissionDisabled &&
					displayAs === USER_ROLES.MANAGER ? null : status &&
					  status === 'open' ? (
						<Button type="link" className="btn-link" onClick={onClickCloseJob}>
							{ml('Close Job', currentUser, allMultiLingualData)}
						</Button>
					) : (
						<Button type="link" className="btn-link" onClick={handleOpenJob}>
							{ml('Open Job', currentUser, allMultiLingualData)}
						</Button>
					)}
				</p>
				<p>
					{ml('Notification:', currentUser, allMultiLingualData)}
					<span>
						{notificationType && notificationType.replace('Notify ', '')}
					</span>
				</p>
				<p>
					{ml('Created By:', currentUser, allMultiLingualData)}
					{createdBy && (
						<span>
							{get(createdBy, 'firstName')} {get(createdBy, 'lastName')}
						</span>
					)}
				</p>
				<p>
					{ml('Employee View:', currentUser, allMultiLingualData)}
					<span>
						<Link
							to={{
								pathname: `/browsejobs/${id}`,
							}}
						>
							{ml('View Job', currentUser, allMultiLingualData)}
						</Link>
					</span>
				</p>
				{isManagerPermissionDisabled &&
				displayAs === USER_ROLES.MANAGER ? null : status &&
				  status === 'open' ? (
					<>
						{status && status === 'open' ? (
							isHotJob && isHotJob === true ? (
								<Button
									className="btn-hotJobs"
									onClick={() => turnOnOffHotJob(false)}
								>
									{ml('Turn Off Hot Job', currentUser, allMultiLingualData)}
								</Button>
							) : (
								<Button
									ghost
									type="primary"
									onClick={() => turnOnOffHotJob(true)}
								>
									{ml('Turn On Hot Job', currentUser, allMultiLingualData)}
								</Button>
							)
						) : null}
					</>
				) : (
					<>
						{status && status === 'open' ? (
							isHotJob && isHotJob === true ? (
								<Button
									className="btn-hotJobs"
									onClick={() => turnOnOffHotJob(false)}
								>
									{ml('Turn Off Hot Job', currentUser, allMultiLingualData)}
								</Button>
							) : (
								<Button
									ghost
									type="primary"
									onClick={() => turnOnOffHotJob(true)}
								>
									{ml('Turn On Hot Job', currentUser, allMultiLingualData)}
								</Button>
							)
						) : null}
					</>
				)}
			</div>
		</div>
	);
}
