import { ml } from '../../services/utils';

export function ToggleOpenClosedNew(props) {
	const {
		onClick,
		openJobsKey,
		closedJobsKey,
		text,
		openJobs,
		closedJobs,
		currentUser,
		allMultiLingualData,
	} = props;
	const key = text === 'Open Jobs' ? closedJobsKey : openJobsKey;

	return (
		<div className="switch-container">
			<span
				className={`switch-label left ${
					openJobs == ml(text, currentUser, allMultiLingualData) ? 'active' : ''
				}`}
			>
				{openJobs}
			</span>
			<label className="switch-wrap">
				<input
					type="checkbox"
					checked={closedJobs == ml(text, currentUser, allMultiLingualData)}
					onChange={() => onClick(key)}
				/>
				<span className="switch-slider" />
			</label>
			<span
				className={`switch-label right ${
					closedJobs == ml(text, currentUser, allMultiLingualData)
						? 'active'
						: ''
				}`}
			>
				{closedJobs}
			</span>
		</div>
	);
}
