import { LeftOutlined } from '@ant-design/icons';
import get from 'lodash/get';
import { Component } from 'react';
import Spinner from 'src/_shared/components/spinner/SpinnerComponent.jsx';
import EditUserProfileCard from './edit-user-profile-form/EditUserProfileCardComponent.jsx';
import {
	BackIcon,
	BackLink,
	CardsContainer,
	EditUserContainer,
} from './editUserProfileStyles.js';

class EditUserProfileComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			company: get(props, 'currentUser.company'),
			isSubmitting: false,
			error: false,
			selectedUser: props.currentUser,
			id: props.id,
		};
	}

	componentDidUpdate(prevProps) {
		if (prevProps.currentUser !== this.props.currentUser) {
			this.setState({ selectedUser: this.props.currentUser });
		}
	}

	goBack = () => {
		this.props.history.goBack();
	};

	handleCancel = () => {
		this.setState({ visible: false });
	};

	handleError = () => {
		this.setState({ error: true });
	};

	render() {
		const { departments, currentUser, updateUserProfile } = this.props;
		const { company, selectedUser, error, id } = this.state;
		if (!departments || !selectedUser) {
			return <Spinner company={company} />;
		}

		return (
			<main className={EditUserContainer}>
				<div className={BackLink} onClick={this.goBack}>
					<LeftOutlined className={BackIcon} />
					Back
				</div>
				<div className={CardsContainer}>
					<EditUserProfileCard
						selectedUser={selectedUser}
						currentUser={currentUser}
						id={id}
						error={error}
						departments={departments}
						updateUserProfile={updateUserProfile}
						handleError={this.handleError}
						onUpdate={this.props.onUpdate}
					/>
				</div>
			</main>
		);
	}
}

export default EditUserProfileComponent;
