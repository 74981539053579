import { css } from 'emotion';
import get from 'lodash/get';
import { COLORS } from '../../../styles/colors';

const breakpoints = [576, 767, 991, 1200];

const mq = breakpoints.map((bp) => `@media (max-width: ${bp}px)`);

export const HeaderTheme = css({
	color: COLORS.black,
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 600,
	fontSize: 18,
});

export const HeaderThemeBlue = css({
	color: COLORS.blue,
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 200,
	fontSize: 18,
	verticalAlign: '-webkit-baseline-middle',
});

export const BlueButton = css({
	color: COLORS.blue,
	fontFamily: '"Open Sans", sans-serif',
});

export const ReferralBtn = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 200,
	color: COLORS.white,
	padding: 8,
	fontSize: 14,
	display: 'flex',
	width: '100%',
	height: 40,
});

export const ReferralBtnMyReferrals = css({
	width: 170,
	height: 50,
	color: 'white',
	margin: 5,
	fontSize: 16,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
});

export const SubmitToJobBtnContainer = (theme) => {
	let buttonColor = COLORS.red;
	if (get(theme, 'enabled')) {
		buttonColor = get(theme, 'addButtonColor', COLORS.red);
	}

	return css({
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		'& .ant-btn: hover': {
			color: buttonColor,
			borderColor: buttonColor,
		},
		'& button:hover > div > svg > path': {
			fill: buttonColor,
		},
		'& button:focus > div > svg > path': {
			fill: buttonColor,
		},
	});
};

export const AddButtonText = css({
	fontSize: 18,
	fontWeight: 600,
	verticalAlign: '-webkit-baseline-middle',
});

export const plusButton = css({
	fontSize: 17,
	color: COLORS.gray2,
	'& button:hover > i > svg': {
		fill: COLORS.blue,
	},
	'& button:focus > i > svg': {
		fill: COLORS.blue,
	},
});

export const referButton = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 200,
	color: '#404447',
	background: COLORS.white,
	fontSize: 14,
	height: 30,
	lineHeight: '25px',
	width: '100% !important',
	border: '1.5px solid #8d99a3',
	borderRadius: '5px',
	marginLeft: 2,
	'&:hover': {
		backgroundColor: COLORS.white,
		color: '#404447',
		borderColor: 'transparent',
	},
	'&:focus': {
		backgroundColor: COLORS.white,
		color: '#404447',
	},
	[mq[0]]: {
		marginLeft: 0,
	},
});

export const mainContainer = css({
	width: '100%',
	paddingRight: 20,
	paddingLeft: 20,
});

export const middleContainer = css({
	maxWidth: 1280,
});

export const DropDown = css({
	fontFamily: '"Open Sans", sans-serif',
});

export const SelectContainer = css({
	display: 'flex',
	height: 40,
	marginTop: 17,
});

export const SelectStyles = css({
	width: 300,
	'& .ant-select-selection__choice': {
		backgroundColor: COLORS.lightGreen,
		color: COLORS.green,
	},
	'& .ant-select-selection--single': {
		height: 40,
		paddingTop: 4,
	},
});

export const paginationStyles = css({
	position: 'fixed',
	left: '50%',
	bottom: 57, // This is because the eldest-parent container has height of 100%
	transform: 'translate(-50%, -50%)',
	margin: '0, auto',
});

export const cardListStyle = css({
	'& .ant-pagination': {
		textAlign: 'center',
		margin: 'auto',
	},
	marginTop: 15,
	'& .ant-list-empty-text': {
		visibility: 'hidden',
	},
});

export const departmentFilterContainer = css({
	width: 'auto',
	display: 'flex',
	marginTop: -17,
});

export const topContainer = css({
	marginBottom: 10,
	paddingRight: 20,
	paddingLeft: 20,
	maxWidth: 1290,
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	[mq[1]]: {
		flexDirection: 'column',
		'& > *': {
			width: '100%',
			marginBottom: 10,
		},
	},
});

export const gridTitle = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 600,
	color: COLORS.black,
	fontSize: 18,
});

export const addJobsButtonText = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 600,
	color: COLORS.black,
	fontSize: '1.2em',
});

export const searchbarStyle = css({
	fontFamily: '"Open Sans", sans-serif',
	'& input': {
		fontFamily: '"Open Sans", sans-serif',
		borderRadius: 20,
		width: 248,
		marginTop: -7,
		height: 32,
	},
	'& .ant-input:not(:first-child)': {
		paddingLeft: 35,
	},
});

export const addJobsButtonIcon = css({
	fontSize: 35,
});

export const modalTitleText = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 500,
	color: COLORS.heading,
	fontSize: '1.5em',
});

export const modalContainer = css({
	width: 10,
});

export const loadingImage = css({
	display: 'block',
	margin: 'auto',
});

export const dropdownMenuItem = css({
	marginTop: 16,
	width: 200,
	height: 30,
});

export const dropdownButton = (width) =>
	css({
		marginTop: 17,
		height: 30,
		'&:hover': {
			backgroundColor: COLORS.red,
			color: COLORS.white,
			borderColor: 'transparent',
		},
		'&:focus': {
			backgroundColor: COLORS.white,
			color: COLORS.red,
			borderColor: COLORS.red,
		},
		width,
	});

export const SearchIconStyles = css({
	marginRight: 10,
	marginTop: -3,
});

export const spinContainer = css({
	width: '100%',
	height: '50vh',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
});

export const noJobs = css({
	width: '100%',
	textAlign: 'center',
	marginTop: 40,
});
