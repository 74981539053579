import { MinusOutlined } from '@ant-design/icons';
import { Button, Input, message } from 'antd';
import { Component } from 'react';
import { USER_ROLES } from 'src/_shared/constants/userRolesEnum.js';

class EmployeePointsInfo extends Component {
	constructor(props) {
		super(props);
		this.state = {
			point: '',
			reason: '',
		};
	}

	onChangePoints = (e) => {
		const { value } = e.target;
		this.setState({
			point: value,
		});
	};

	onChangeReason = (e) => {
		const { value } = e.target;
		this.setState({
			reason: value,
		});
	};

	validatePoints = (inputValue) => {
		// RegExp method to test if string is number
		const isNumber = /^\d+$/.test(inputValue);
		const isGreaterThanZero = Number.parseInt(inputValue, 10) > 0;

		return isNumber && isGreaterThanZero;
	};

	Add = () => {
		const {
			handlePoints,
			companyId,
			userId,
			currentUserEmail,
			selectedUserId,
		} = this.props;
		const { point, reason } = this.state;
		if (this.validatePoints(point)) {
			if (reason === '') {
				message.error('Adjustment reasons must not be blank.');
			} else {
				const input = {
					companyId,
					userId: selectedUserId,
					event: 'byAdmin',
					operation: 'add',
					points: point,
					note: reason,
					adminId: userId,
					adminEmail: currentUserEmail,
				};

				handlePoints(input);
				this.setState({
					point: '',
					reason: '',
				});
				message.success(`Added ${point} point(s).`);
			}
		} else {
			message.error('Please enter a valid number of points.');
		}
	};

	Subtract = () => {
		const {
			handlePoints,
			companyId,
			userId,
			selectedUserId,
			currentUserEmail,
		} = this.props;
		const { point, reason } = this.state;
		if (this.validatePoints(point)) {
			if (reason === '') {
				message.error('Adjustment reasons must not be blank.');
			} else {
				const input = {
					companyId,
					userId: selectedUserId,
					event: 'byAdmin',
					operation: 'subtract',
					points: point,
					note: reason,
					adminId: userId,
					adminEmail: currentUserEmail,
				};

				handlePoints(input);
				this.setState({
					point: '',
					reason: '',
				});
				message.success(`Subtracted ${point} point(s).`);
			}
		} else {
			message.error('Please enter a valid number of points.');
		}
	};

	render() {
		const { currentUser, points } = this.props;
		const managerEmployees = currentUser.company
			?.disableManagerPermissionsByType
			? JSON.parse(currentUser.company.disableManagerPermissionsByType)
					.managerEmployees
			: 'hidden';
		const isManagerPermissionDisabled = Boolean(
			managerEmployees !== 'edit' && currentUser?.role === USER_ROLES.MANAGER
		);

		return (
			<>
				<div className="ed-card-header bg-14D6D3">
					<span className="card-header-text">Total Points:</span>
					<h3>{points}</h3>
				</div>
				<div className="ed-card-body">
					<div className="card-body-detail">
						<label />
						<div className="custom-form-group">
							<label>Adjust Points:</label>
						</div>
					</div>
					{isManagerPermissionDisabled ? (
						<span>Manager Permissions have been set to Read-Only.</span>
					) : (
						<>
							<div className="card-body-detail">
								<label>Points:</label>
								<div className="custom-form-group">
									<Input
										className="custom-input"
										placeholder="# of Points"
										value={this.state.point}
										onChange={this.onChangePoints.bind(this)}
									/>
								</div>
							</div>
							<div className="card-body-detail">
								<label>Note:</label>
								<div className="custom-form-group">
									<Input
										className="custom-input"
										placeholder="Adjustment Reasons"
										value={this.state.reason}
										onChange={this.onChangeReason.bind(this)}
									/>
								</div>
							</div>
							<div className="card-body-action">
								<Button
									type="link"
									className="add-btn"
									size="small"
									onClick={this.Subtract.bind(this)}
								>
									<span className="icon-circle">
										<MinusOutlined />
									</span>
									<span>Subtract</span>
								</Button>
								<Button
									type="link"
									className="add-btn"
									size="small"
									onClick={this.Add.bind(this)}
								>
									<span className="icon-circle">
										<i className="icon-plus" />
									</span>
									<span>Add</span>
								</Button>
							</div>
						</>
					)}
				</div>
			</>
		);
	}
}

export default EmployeePointsInfo;
