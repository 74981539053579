import { get } from 'lodash';

export function EligibilityCustomValueObject(props) {
	const {
		eligbilityChecks,
		company,
        fieldObject,
		onUpdateCompany,
        fieldType,
		style,
	} = props;

	const inactivateCustomFieldInvalidOrValidValues = (name, fieldObject, fieldType) => {
		 if(fieldObject && fieldObject[fieldType]) {
			let theValues = fieldObject[fieldType].split('|');

			const fieldIndex = theValues.indexOf(name);
			if (fieldIndex > -1) {
				theValues.splice(fieldIndex, 1);
			}

			theValues = theValues.join('|');

            
			eligbilityChecks.CustomValuesToCheck.find((f)=>f.name == fieldObject.name)[fieldType] = theValues;

			onUpdateCompany({
				input: {
					id: company.id,
					eligibiltyChecks: JSON.stringify(eligbilityChecks),
				},
			});

		}
	};

	return (
		<ul className="tag-wrap">
			{fieldObject && fieldObject[fieldType] 
				? fieldObject[fieldType].split('|')
						.map((name, index) => {
							let marginLeft = get(style, 'marginLeft') ? style.marginLeft : 4;
							if (index === 0) marginLeft = 0;
							return (
								<li
									key={index}
									className="tag green"
									onClick={() => inactivateCustomFieldInvalidOrValidValues(name, fieldObject, fieldType )}
								>
									<span className="tag-name">{name}</span>
									<i className="tag-close icon-cross" />
								</li>
							);
						}) 
                    : null	
				}
		</ul>
	);
}