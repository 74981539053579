import { css } from 'emotion';
import get from 'lodash/get';
import { COLORS } from '../../../_shared/styles/colors';

const breakpoints = [576, 767, 991, 1200];

const mq = breakpoints.map((bp) => `@media (max-width: ${bp}px)`);

export const jobTypeButton = (selected) =>
	css({
		backgroundColor: selected ? COLORS.gray2 : COLORS.lightGray2,
		color: selected ? COLORS.white : COLORS.heading,
		height: 50,
		width: '100%',
		borderRadius: 10,
		borderWidth: 1,
		fontSize: '1.3em',
		fontWeight: 300,
		margin: 3,
		'&:hover': {
			backgroundColor: COLORS.gray2,
			color: COLORS.white,
			borderColor: 'transparent',
		},
		'&:focus': {
			backgroundColor: COLORS.gray2,
			color: COLORS.white,
			borderColor: 'transparent',
		},
	});

export const autocompleteDropdownContainer = css({
	position: 'absolute',
	width: 350,
	zIndex: 1000,
});

export const jobTypeButtonsContainer = css({
	flex: 1,
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
});

export const inputContainer = (maxWidth) =>
	css({
		maxWidth,
		width: '100%',
	});

export const selectStyles = css({
	width: '100%',
});

export const dropdownButton = (width) =>
	css({
		marginLeft: 8,
		'&:hover': {
			backgroundColor: COLORS.red,
			color: COLORS.white,
			borderColor: 'transparent',
		},
		'&:focus': {
			backgroundColor: COLORS.white,
			color: COLORS.red,
			borderColor: COLORS.red,
		},
		width,
	});

export const typeaheadRow = (active) => ({
	backgroundColor: active ? 'rgb(230, 247, 255)' : 'white',
	padding: '3px 10px',
});

export const regularButton = (selected) =>
	css({
		marginRight: '6px',
		backgroundColor: selected ? COLORS.gray2 : COLORS.white,
		color: selected ? COLORS.white : COLORS.subHeading,
		'&:hover': {
			backgroundColor: COLORS.gray2,
			color: COLORS.white,
			borderColor: selected ? 'transparent' : COLORS.subHeading,
		},
		'&:focus': {
			backgroundColor: selected ? COLORS.gray2 : COLORS.white,
			color: selected ? COLORS.white : COLORS.subHeading,
			borderColor: selected ? 'transparent' : COLORS.subheading,
		},
	});

export const cancelModalButton = (theme) => {
	let buttonColor = COLORS.blue;
	if (get(theme, 'enabled')) {
		buttonColor = get(theme, 'buttonColor', buttonColor);
	}

	return css({
		fontFamily: '"Open Sans", sans-serif',
		fontWeight: 200,
		color: '#404447',
		background: 'COLORS.white',
		fontSize: 14,
		height: 40,
		lineHeight: '25px',
		border: '1.5px solid #8d99a3',
		borderRadius: '10px',
		marginLeft: 8,
		'&:hover': {
			backgroundColor: COLORS.white,
			color: buttonColor,
			border: `2px solid ${buttonColor}`,
		},
		'&:focus': {
			backgroundColor: COLORS.white,
			color: buttonColor,
			borderColor: buttonColor,
		},
	});
};

export const stateAndRemoteContainer = css({
	marginLeft: 195,
	marginBottom: 20,
});

export const keepModalOpenButton = (theme) => {
	let buttonColor = COLORS.blue;
	if (get(theme, 'enabled')) {
		buttonColor = get(theme, 'buttonColor', buttonColor);
	}

	return css({
		fontFamily: '"Open Sans", sans-serif',
		fontWeight: 200,
		fontSize: 14,
		height: 40,
		borderRadius: '10px',
		border: `2px solid ${buttonColor}`,
		marginLeft: 8,
		backgroundColor: buttonColor,
		color: COLORS.white,
		'&:hover': {
			backgroundColor: buttonColor,
			color: COLORS.white,
			border: `1px solid ${buttonColor} !important`,
		},
		'&:focus': {
			backgroundColor: buttonColor,
			color: COLORS.white,
			border: `1px solid ${buttonColor} !important`,
		},
	});
};

export const dropdownMenuItem = css({
	'&:hover': {
		backgroundColor: COLORS.red,
		color: COLORS.white,
		borderColor: 'transparent',
	},
});

export const titleStyle = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 500,
	color: COLORS.heading,
	fontSize: '1.3em',
});

export const subTitleStyle = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 300,
	color: COLORS.subHeading,
	fontSize: '0.85em',
});

export const itemContainer = css({
	padding: 10,
});

export const cancelContainer = css({
	padding: 10,
	textAlign: 'center',
});

export const itemChildren = css({
	marginTop: 10,
});

export const RteContainer = css({
	height: 205,
	overflow: 'scroll',
});

export const sectionContainer = css({
	marginLeft: 30,
	marginBottom: 15,
	marginRight: 30,
});

export const summaryHeaderContainer = css({
	textAlign: 'center',
	marginBottom: 20,
	marginTop: 10,
});

export const summaryHeader = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 400,
	color: COLORS.heading,
	fontSize: '1.6em',
});

export const summaryTitle = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 400,
	color: COLORS.heading,
	fontSize: '1.25em',
});

export const summaryValue = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 200,
	color: COLORS.darkGray,
	fontSize: '1.25em',
});

export const bonusAmmount = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 200,
	color: COLORS.green,
	fontSize: '1.25em',
});

export const fieldErrors = css({
	width: 'auto',
	marginLeft: '5%',
});

export const errorText = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 200,
	color: COLORS.red,
	fontSize: '0.85em',
	left: -30,
});

export const changeOrientation = css({
	alignItems: 'center',
	[mq[0]]: {
		flexDirection: 'column',
		'& > div': {
			marginBottom: 10,
		},
	},
});

export const yearSelect = css({
	marginLeft: 20,
	[mq[0]]: {
		marginLeft: 10,
		width: 150,
	},
});

export const stateSelect = css({
	width: 100,
	[mq[0]]: {
		width: 150,
	},
});
