import { Dropdown } from 'antd';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { ml } from '../../../services/utils';
import { Avatar } from './AvatarComponent.jsx';
import { CompanyLinkName } from './CompanyLinkNameComponent.jsx';
import { LanguageSelectOptions } from './LanguageSelectOptionsComponent.jsx';

export function Menu({ currentUser, allMultiLingualData, functions }) {
	const [dropdownVisible, setDropdownVisible] = useState(false);

	const handleVisibleChange = (flag) => {
    	setDropdownVisible(flag);
  	};

	const {
		company: { linkName, linkUrl },
	} = currentUser;

	const { handleSignOut, onSelectLanguage } = functions;

	const getFormattedLink = (linkUrl) => {
		if (!linkUrl) return null;
		const formattedLink = `http://${linkUrl}`;
		return linkUrl.startsWith('http://') || linkUrl.startsWith('https://')
			? linkUrl
			: formattedLink;
	};

	const helpUrl =
		currentUser.company.helpUrl && currentUser.company.helpUrl != ''
			? getFormattedLink(currentUser.company.helpUrl)
			: 'https://erinapp.com/support';

	const privacyUrl =
		currentUser.company.privacyUrl && currentUser.company.privacyUrl != ''
			? getFormattedLink(currentUser.company.privacyUrl)
			: 'https://erinapp.com/privacy-policy';

	const termsUrl =
		currentUser.company.termsUrl && currentUser.company.termsUrl != ''
			? getFormattedLink(currentUser.company.termsUrl)
			: 'https://erinapp.com/terms-of-use';

	const menuItems = [
		{
			key: 'my-profile',
			label: <Link onClick={(e) => {
							handleVisibleChange(false);
							e.stopPropagation();
						}}to="/myprofile">{ml('My Profile', currentUser, allMultiLingualData)}</Link>,
		},
		{
			key: 'support',
			label: (
				<MenuItemLink
					href={helpUrl}
					label={ml('Help & Support', currentUser, allMultiLingualData)}
					onClick={(e) => {
							handleVisibleChange(false);
							e.stopPropagation();
						}}
				/>
			),
		},
		{
			key: 'privacy',
			label: (
				<MenuItemLink
					href={privacyUrl}
					label={ml('Privacy Policy', currentUser, allMultiLingualData)}
					onClick={(e) => {
							handleVisibleChange(false);
							e.stopPropagation();
						}}
				/>
			),
		},
		{
			key: 'terms-of-use',
			label: (
				<MenuItemLink
					href={termsUrl}
					label={ml('Terms of Use', currentUser, allMultiLingualData)}
					onClick={(e) => {
							handleVisibleChange(false);
							e.stopPropagation();
						}}
				/>
			),
		},
		...(linkName !== null && linkName !== '' && linkUrl !== null && linkUrl !== '' ? [
		{
			key: 'company-link-name',
			label: <CompanyLinkName currentUser={currentUser} />,
		},
		] : []),
		{
			key: 'language-select-options',
			label: (
				<div>
					<LanguageSelectOptions
						currentUser={currentUser}
						onSelectLanguage={(e) => {
							handleVisibleChange(false);
							onSelectLanguage(e);
						}}
						onClick={(e) => {
							handleVisibleChange(true);
							e.stopPropagation();
						}}
					/>
				</div>
			),
		},
		{
		key: 'sign-out',
		label: ml('Sign Out', currentUser, allMultiLingualData),
		onClick: handleSignOut,
		},
	];

	return (
		<Dropdown
			menu={{ items: menuItems }}
			trigger={['click']}
			open={dropdownVisible}
			onOpenChange={handleVisibleChange}
			style={{ minWidth: 180}}
		>
			<a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
				<Avatar currentUser={currentUser} />
			</a>
		</Dropdown>
	);
}

/**
 * SUB-COMPONENTS
 */
function MenuItemLink({ href, label }) {
	return (
		<a href={href} target="_blank" rel="noreferrer">
			{label}
		</a>
	);
}
