import { Form } from 'antd';
import dayjs from 'dayjs';
import get from 'lodash/get';
import { Component, createRef } from 'react';
import ProgressButton from 'react-progress-button';
import { Link } from 'react-router-dom';
import { uploadToS3Multipart } from 'src/_shared/services/utils.js';
import { EmployeeLink } from '../employeeLinkContainer.js';

class OnDeckReferralFormAdmin extends Component {
	constructor(props) {
		super(props);
		const key = get(props, ['contact', 'contactResume', 'key'], null);
		const filename = key ? key.split('/')[1] : null;

		this.state = {
			filename,
			filetype: null,
			errors: [],
			originalFile: [],
			theme: JSON.parse(get(props, 'currentUser.company.theme', '{}')),
		};
		this.formRef = createRef();
	}

	onDelete = () => {
		this.setState({ originalFile: [] });
		this.setState({ filename: null });
		this.setState({ filetype: null });
		this.setState({ errors: [] });
	};

	onFileInputChange = (e) => {
		if (e.target.files && e.target.files.length > 0) {
			const errors = [];
			const isDocOrDocsOrPdf =
				e.target.files[0].type === 'application/msword' ||
				e.target.files[0].type ===
					'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
				e.target.files[0].type === 'application/pdf';
			if (!isDocOrDocsOrPdf) {
				errors.push('You can only upload doc, docx, pdf files! ');
			}

			const isLt2M = e.target.files[0].size / 1024 / 1024 < 2;
			if (!isLt2M) {
				errors.push('File size must smaller than 2MB!');
			}

			if (errors.length > 0) {
				this.setState({ filename: e.target.files[0].name });
				this.setState({ errors });
				return errors;
			}

			this.setState({
				filename: e.target.files[0].name,
				filetype: e.target.files[0].type,
				originalFile: e.target.files[0],
			});

			this.setState({ errors: [] });
			return isDocOrDocsOrPdf && isLt2M;
		}
	};

	handleSubmit = async (e) => {
		const { toggleIsSubmitting, onUpdateContact } = this.props;
		const { filename, filetype, originalFile } = this.state;
		const result = get(this.props, ['autoCompleteResult'], []);
		const referral = result[0];
		const candidateId = get(referral, 'contact.id', null);
		e.preventDefault();
		const { notification, onUpdateNotification } = this.props;
		if (candidateId) {
			let resume = {};
			const d = new Date();
			const dformat = `${d.getHours()}-${d.getMinutes()}-${d.getSeconds()}`;
			if (filename) {
				resume = {
					bucket: 'erin-documents',
					key: `resumes/${candidateId}/${dformat + '-' + filename}`,
					region: 'us-east-2',
				};
				await uploadToS3Multipart(originalFile, resume.key, resume.bucket);
			}

			toggleIsSubmitting();
			this.showAnimation().then(() => {
				const input = {
					id: candidateId,
					onDeckDate: dayjs(),
					onDeckStatus: 'onDeck',
				};
				if (filename) input.contactResume = resume;
				onUpdateContact(input);
				if (get(notification, 'id', false)) {
					onUpdateNotification({
						input: {
							id: notification.id,
							type: notification.type,
							referralRequestedStatus: 'completed',
						},
					});
				}
			});
		}
	};

	isAlreadyOnDeck = (value) => {
		const { onDeckContacts } = this.props;
		const alreadyOnDeck = onDeckContacts.find((contact) => {
			return (
				get(contact, 'id') === value &&
				(get(contact, 'onDeckStatus') === 'onDeck' ||
					get(contact, 'offDeckStatus') === 'offDeck')
			);
		});
		if (alreadyOnDeck) {
			return new Error('This person has already been submitted.');
		}
	};

	showAnimation = () => {
		const { toggleIsSubmitting, handleCancel } = this.props;
		return new Promise((resolve, reject) => {
			setTimeout(() => {
				try {
					toggleIsSubmitting();
					handleCancel();
					resolve();
				} catch (error) {
					reject(error);
				}
			}, 5700);
		});
	};

	render() {
		if (!this.props.visible) {
			this.formRef.current.resetFields();
		}

		const result = get(this.props, ['autoCompleteResult'], []);
		const referral = result[0];
		const candidateId = get(referral, 'contact.id', null);
		const candidateFirstName = get(referral, 'contact.firstName', '');
		const candidateLastName = get(referral, 'contact.lastName', '');
		const referredById = get(referral, 'contact.userId');

		return (
			<Form ref={this.formRef} onFinish={this.handleSubmit}>
				<div className="modal-job-info-wrap">
					<p>
						Candidate:{' '}
						<Link
							to={`/mycontacts/${candidateId}`}
							target="_blank"
						>{`${candidateFirstName} ${candidateLastName}`}</Link>
					</p>

					<p style={{ margin: 0 }}>
						Referred By: <EmployeeLink id={referredById} target="_blank" />
					</p>
				</div>
				<div className="modal-footer-btn">
					<ProgressButton
						durationSuccess={10_000}
						state={this.props.buttonState}
						type="submit"
					>
						Submit Referral
					</ProgressButton>
				</div>
			</Form>
		);
	}
}

export default OnDeckReferralFormAdmin;
