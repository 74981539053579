import { Component } from 'react';

class EmployeeLinkComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id: props.id,
		};
	}

	render() {
		const { employee } = this.props;
		const { firstName, lastName, id, title } = employee;

		let content = '';
		content = title
			? `${firstName} ${lastName} (${title})`
			: firstName + ' ' + lastName;

		return (
			<a href={`/employees/${id}`} target={this.props.target}>
				{content}
			</a>
		);
	}
}

export default EmployeeLinkComponent;
