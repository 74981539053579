import { connect } from 'react-redux';
import { withListDepartmentSaml } from 'src/_shared/api/components/departments/';
import {
	withGetUserInvite,
	withNewExtendedUser,
	withNewExtendedUserLink,
	withNewExternalUser,
} from 'src/_shared/api/components/newUserLandingPage/';
import { withCreateUserGroup } from 'src/_shared/api/components/userGroups/';
import { withSAMLPartner } from 'src/_shared/api/components/users/';
import { compose } from 'src/_shared/services/utils.js';
import { userActions } from 'src/actions.js';
import NewCompanyUserLandingPageComponent from './NewCompanyUserLandingComponent.jsx';
import NewExtendedUserLandingPageComponent from './NewExtendedUserLandingComponent.jsx';
import NewExtendedUserLinkLandingPageComponent from './NewExtendedUserLinkLandingComponent.jsx';
import NewExternalUserLandingPageComponent from './NewExternalUserLandingComponent.jsx';
import NewPartnerUserLandingPageComponent from './NewPartnerUserLandingComponent.jsx';
import NewSamlUserLandingPageComponent from './NewSamlUserLandingComponent.jsx';
import NewUserLandingPageComponent from './NewUserLandingComponent.jsx';

const mapStateToProperties = (state, props) => {
	const { allMultiLingualData } = state.dashboard;
	const { id } = props.match.params;
	return {
		allMultiLingualData,
		id,
		partnerClientId: window.location.pathname.split('/')[2],
	};
};

const mapDispatchToProperties = (dispatch) => ({
	setCurrentUser(user) {
		dispatch(userActions.createSetCurrentUserAction(user));
	},
});

const NewUserLandingPageWithApi = compose(withGetUserInvite)(
	NewUserLandingPageComponent
);

const NewPartnerUserLandingPageWithApi = compose()(
	NewPartnerUserLandingPageComponent
);

const NewCompanyUserLandingPageWithApi = compose()(
	NewCompanyUserLandingPageComponent
);

const NewSamlUserLandingPageWithApi = compose(
	withGetUserInvite,
	withListDepartmentSaml,
	withSAMLPartner,
	withCreateUserGroup
)(NewSamlUserLandingPageComponent);

const NewExtendedUserLandingPageWithApi = compose(withNewExtendedUser)(
	NewExtendedUserLandingPageComponent
);
const NewExtendedUserLinkLandingPageWithApi = compose(withNewExtendedUserLink)(
	NewExtendedUserLinkLandingPageComponent
);
const NewExternalUserLandingPageWithApi = compose(withNewExternalUser)(
	NewExternalUserLandingPageComponent
);

export const NewUserLandingPage = connect(
	mapStateToProperties,
	mapDispatchToProperties
)(NewUserLandingPageWithApi);

export const NewCompanyUserLandingPage = connect(
	mapStateToProperties,
	mapDispatchToProperties
)(NewCompanyUserLandingPageWithApi);

export const NewPartnerUserLandingPage = connect(mapStateToProperties)(
	NewPartnerUserLandingPageWithApi
);

export const NewSamlUserLandingPage = connect(
	mapStateToProperties,
	mapDispatchToProperties
)(NewSamlUserLandingPageWithApi);

export const NewExtendedUserLandingPage = connect(
	mapStateToProperties,
	mapDispatchToProperties
)(NewExtendedUserLandingPageWithApi);

export const NewExtendedUserLinkLandingPage = connect(
	mapStateToProperties,
	mapDispatchToProperties
)(NewExtendedUserLinkLandingPageWithApi);

export const NewExternalUserLandingPage = connect(mapStateToProperties)(
	NewExternalUserLandingPageWithApi
);
