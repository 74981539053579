export const queryGDPRReferralLogByCompanyIdIndex = `query QueryGDPRReferralLogByCompanyIdIndex(
    $companyId: ID!
    $first: Int
    $after: String
  ) {
    queryGDPRReferralLogByCompanyIdIndex(
      companyId: $companyId
      first: $first
      after: $after
    ) {
        items {
            id
            firstName
            lastName
            emailAddress
            userId
            user {
              emailAddress
            }
            jobId
            job {
              title
            }
            date
          }
          nextToken
        }
      }
  `;
