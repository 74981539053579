import get from 'lodash/get';
import { connect } from 'react-redux';
import { withGetTieredBonus } from 'src/_shared/api/components/tiered-bonuses';
import { compose } from '../../_shared/services/utils.js';
import MyBonusCardComponent from './MyBonusCardComponent.jsx';

const mapStateToProperties = (state, ownProperties) => {
	const { currentUser } = state.user;
	const campaignId = get(ownProperties, 'referral.campaignId', null);
	const campaign = get(ownProperties, 'referral.campaign', null);
	let id = 'skip';
	id = campaignId
		? get(campaign, 'tieredBonusId', 'skip')
		: get(ownProperties, 'referral.job.referralBonus.tieredBonusId', 'skip');

	return {
		companyId: currentUser.companyId,
		tieredBonusQuery: {
			companyId: currentUser.companyId,
			id,
		},
	};
};

const mapDispatchToProperties = () => {
	return {};
};

export default connect(
	mapStateToProperties,
	mapDispatchToProperties
)(compose(withGetTieredBonus)(MyBonusCardComponent));
