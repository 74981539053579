import { FireFilled } from '@ant-design/icons';
import { Col, Tooltip } from 'antd';
import getSymbolFromCurrency from 'currency-symbol-map';
import _, { get } from 'lodash';
import { Component } from 'react';
import { JobLocation } from 'src/_shared/index.js';
import { ReferralRequestModal } from 'src/_shared/referral-request-modal/';
import { ImInterestedButton } from '../../../_shared/components/im-interested-send-button';
import { ShareSendButton } from '../../../_shared/components/share-send-button';
import { USER_ROLES } from '../../../_shared/constants';
import { ReferralModal } from '../../../_shared/referral-modal';
import { StringParser } from '../../../_shared/services';
import {
	calculateReferralBonus,
	ml,
	normalizeDollar,
	parse,
} from '../../../_shared/services/utils.js';
import { JobTypes } from './copy.js';
import { jobMatch } from './jobDescriptionStyles.js';

export class JobDescriptionCard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			contacts: [],
			translatedTitle: get(props, 'job.title', ''),
			translatedDescription: get(props, 'job.description', ''),
			language: 'US',
		};
	}

	safeSalary = (min, max) => {
		if (!min && !max) return '';
		if (!min || min === max) return `$${normalizeDollar(max)}`;
		if (!max) return `$${normalizeDollar(min)}`;
		return `$${normalizeDollar(min)} - $${normalizeDollar(max)}`;
	};

	renderLocations = () => {
		const location = parse(get(this.props, 'job.location'));
		const locations = parse(get(this.props, 'job.locations'));
		if (locations.length <= 1) {
			return (
				<ul>
					<JobLocation
						allMultiLingualData={this.props.allMultiLingualData}
						currentUser={this.props.currentUser}
						location={location}
						locations={locations}
					/>
				</ul>
			);
		}

		return (
			<ul>
				{locations.map((location, index) => (
					<JobLocation
						key={`${[location.city, location.state, location.country, index].filter(Boolean).join('-')}`}
						allMultiLingualData={this.props.allMultiLingualData}
						currentUser={this.props.currentUser}
						location={location}
						locations={[]} // set to empty array to force individual location rendering
					/>
				))}
			</ul>
		);
	};

	renderMatches = () => {
		const { matches } = this.props;
		return (
			<p className="bjc-match">
				<i className="icon-check" />
				{matches && matches.length === 1 ? (
					<>
						<span> {matches.length} Person </span> In Your Network Match This
						Job!
					</>
				) : (
					<>
						<span> {matches.length} People </span> In Your Network Match This
						Job!
					</>
				)}
			</p>
		);
	};

	render() {
		const {
			allMultiLingualData,
			job,
			job: {
				title,
				department,
				description,
				hiringManager,
				company, // TODO: get Recruiter details
				location,
				jobType,
				salary,
				jobAdditionalInfo,
				id,
				referralBonus,
				matches,
				shares,
				status,
				externalJobId,
				internalJobLink,
			},
			contactIncentiveBonus,
			contacts,
			currentUser,
			onUpdateJob,
			allowSelfReferrals,
			onCreateReferral,
			currentCurrencyRate,
			currentCurrencySymbol,
			allowSelfReferralsInternalLink,
			onCreateUserJobShare,
			userJobShares,
			onUpdateUserJobShare,
			campaignId,
			isActive,
			hideDepartmentShareLink,
			hideShareLinkNoPublicUrl,
			currentUserCompany,
		} = this.props;
		const disableShareLink =
			get(this.props, 'currentUserCompany.disableShareLink', false) ||
			currentUser.role === USER_ROLES.EXTENDED_USER;
		const subCompanyId = get(job, 'subCompanyId', null);
		const subCompanyName = get(job, 'subCompany.name', '');
		let userGroupCurrency = get(currentUser, 'userGroup.currency');
		if (userGroupCurrency === null) userGroupCurrency = 'USD';
		const _salary = salary ? this.safeSalary(salary.from, salary.to) : 'n/a';
		const referredBy = get(currentUser, 'id');
		let languageCode = get(currentUser, 'languageCode', 'US');
		if (
			languageCode === 'US' ||
			languageCode === null ||
			languageCode === undefined
		) {
			languageCode = 'EN';
		}

		const endDate = parse(jobAdditionalInfo)?.endDate
			? parse(jobAdditionalInfo).endDate
			: null;

		const jobId = get(job, 'id');
		const { origin } = window.location;
		const jobUrl = `${origin}/newreferral/${referredBy}/${jobId}/${languageCode}`;
		const hideRecruiterInfoJobDetails =
			get(currentUser, 'company.hideRecruiterInfoJobDetails', false) ||
			currentUser.role === USER_ROLES.EXTENDED_USER;
		const getBonusAmount = (
			contactIncentiveBonus,
			referralBonusAmount,
			incentiveEligible,
			currentTieredBonus,
			employee,
			userGroupId
		) => {
			const bonusValue = calculateReferralBonus(
				contactIncentiveBonus,
				referralBonusAmount,
				incentiveEligible,
				currentTieredBonus,
				employee,
				userGroupId
			);

			return `${getSymbolFromCurrency(userGroupCurrency)}${bonusValue}`.replaceAll(
				/\B(?=(\d{3})+(?!\d))/g,
				','
			);
		};

		let isHotJob = get(job, 'isHotJob', false);
		if (campaignId && !isActive) {
			isHotJob = false;
		}

		const jobPublicLink = get(job, 'publicLink');
		const disableReferrals = get(
			currentUser,
			'company.disableReferrals',
			false
		);

		const locations = parse(get(this.props, 'job.locations'));
		const jobLevels = _.sortBy(get(job, 'jobLevels', []), ['name']);
		const showJobLevels = () => {
			let result = '';
			for (const [i, jobLevel] of jobLevels.entries()) {
				result += get(jobLevel, 'name');
				if (i !== jobLevels.length - 1) result += ', ';
			}

			return result;
		};

		const jobLevelTitle =
			jobLevels && jobLevels.length > 1 ? `Job Levels: ` : `Job Level: `;
		const hideInterestedForGroup = get(
			this.props,
			'currentUser.company.hideInterestedForGroup'
		);
		let filteredUserGroups = [];
		let isHideImInterestedForEmp = false;
		if (
			hideInterestedForGroup !== null &&
			hideInterestedForGroup !== undefined
		) {
			filteredUserGroups = JSON.parse(hideInterestedForGroup);
			const userGroupId = get(this.props, 'currentUser.userGroup.id');
			isHideImInterestedForEmp = filteredUserGroups.some(function (value) {
				return value.id === userGroupId;
			});
		}

		const isGeneralReferral = get(job, 'isGeneralReferral', false);
		const generalReferralJob = () => {
			return (
				<Tooltip
					arrowPointAtCenter
					placement="topRight"
					title={ml(
						'General Jobs represent multiple openings. A new referral and bonus (if applicable) will be created when the candidate is moved to a different job.',
						currentUser,
						allMultiLingualData
					)}
					style={{ fontWeight: 1000, fontStyle: 'italic' }}
				>
					<span className="general-job-tag">General Job</span>
				</Tooltip>
			);
		};

		const extendedNetworkUsers = get(
			this.props,
			'currentUser.extendedContactData',
			[]
		).filter(
			(user) => get(user, 'user.companyId') === get(this.props, 'job.companyId')
		);
		return (
			<>
				<div className="bjc-top">
					<div className="bjc-left">
						<h2 className="bjc-name">{get(this.props, 'translatedTitle')}</h2>
						{matches && matches.length > 0 ? (
							<Col className={jobMatch}>{this.renderMatches()}</Col>
						) : null}
						{this.renderLocations()}
						<ul>
							<li>
								<i className="icon-folder" />
								<span>{department && department.name}</span>
							</li>
							<li>
								{subCompanyId !== null &&
									(!currentUserCompany.hideSubcompanyOnJobs ||
										currentUser.role !== 'employee') && (
										<>
											<i className="icon-building" />
											<span>{subCompanyName}</span>
										</>
									)}
							</li>
						</ul>

						{externalJobId &&
							externalJobId !== true &&
							externalJobId !== null &&
							externalJobId !== '' && (
								<p className="bjc-details">
									<span>ID#:</span>
									{externalJobId}
								</p>
							)}
						{job &&
						job.dateCreated &&
						!get(
							this.props,
							'currentUser.company.hideDateCreatedJobDetails'
						) ? (
							<p className="bjc-details">
								<span>Created:</span>
								{new Intl.DateTimeFormat(
									currentUser.languageCode || 'US'
								).format(new Date(job.dateCreated))}
							</p>
						) : null}

						{jobLevels && jobLevels.length > 0 && jobLevels[0] !== null && (
							<p className="bjc-details">
								<span>{jobLevelTitle}</span>
								{showJobLevels()}
							</p>
						)}
						<p className="bjc-details">
							<span>{ml('Job Type', currentUser, allMultiLingualData)}:</span>
							{JobTypes[jobType]}
						</p>
						{_salary && (
							<p className="bjc-details">
								<span>{ml('Salary', currentUser, allMultiLingualData)}:</span>
								{_salary} {salary && salary.interval ? salary.interval : null}
							</p>
						)}
						{endDate && (
							<p className="bjc-details">
								<span>{ml('End Date', currentUser, allMultiLingualData)}:</span>
								{new Intl.DateTimeFormat(
									currentUser.languageCode || 'US'
								).format(new Date(endDate))}
							</p>
						)}
					</div>
					<div className="bjc-right">
						{get(this.props, 'currentUser.role') !== 'extendedUser' && (
							<>
								{status === 'open' && (
									<div className="bjc-btn-grp">
										{disableReferrals && jobPublicLink === null ? null : (
											<ReferralModal
												allMultiLingualData={allMultiLingualData}
												currentUser={currentUser}
												currentTieredBonus={this.props.currentTieredBonus}
												job={this.props.job}
												contacts={contacts}
												currentCurrencyRate={currentCurrencyRate}
												currentCurrencySymbol={currentCurrencySymbol}
												campaignId={campaignId}
												buttonType="large"
											/>
										)}
										{/* added space after btn here instead of modifying reusable TeferralModalWrapper */}
										{/* Show I'm interested for self referral */}
										{get(this.props, 'currentUser.role') ===
											USER_ROLES.EMPLOYEE &&
										isHideImInterestedForEmp ? null : allowSelfReferrals &&
										  !job.hideImInterested &&
										  ((allowSelfReferralsInternalLink && internalJobLink) ||
												!allowSelfReferralsInternalLink) ? (
											<ImInterestedButton
												key={job.id}
												allMultiLingualData={allMultiLingualData}
												contacts={contacts}
												currentUser={this.props.currentUser}
												internalJobLink={internalJobLink}
												job={job}
												onCreateReferral={onCreateReferral}
											/>
										) : (
											<></>
										)}
									</div>
								)}
							</>
						)}
						<div className="bjc-bonus-share">
							{isGeneralReferral &&
							get(this.props, 'currentUser.role') !== 'extendedUser'
								? generalReferralJob()
								: referralBonus &&
									referralBonus.hasBonus &&
									get(this.props, 'currentUser.role') !== 'extendedUser' && (
										<p>
											{ml('Referral Bonus:', currentUser, allMultiLingualData)}
											<span className="bjc-bonus-point">
												{getBonusAmount(
													contactIncentiveBonus,
													referralBonus.amount,
													this.props.currentUser.incentiveEligible,
													this.props.currentTieredBonus,
													'employee',
													get(currentUser, 'userGroupId')
												)}
											</span>

											{isHotJob == true && (
												<FireFilled className="bjc-hotjob" />
											)}
										</p>
									)}
							{!disableShareLink &&
								status === 'open' &&
								!hideDepartmentShareLink &&
								(!hideShareLinkNoPublicUrl ||
									(hideShareLinkNoPublicUrl && jobPublicLink)) && (
									<ShareSendButton
										link
										allMultiLingualData={allMultiLingualData}
										title={title}
										jobId={id}
										company={company}
										jobUrl={jobUrl}
										jobType={jobType}
										description={description}
										city={location ? location.city : null}
										state={location ? location.state : null}
										shares={shares}
										currentUser={this.props.currentUser}
										userJobShares={userJobShares}
										onUpdateJob={onUpdateJob}
										onCreateUserJobShare={onCreateUserJobShare}
										onUpdateUserJobShare={onUpdateUserJobShare}
									/>
								)}
							{get(this.props, 'currentUser.role') === 'extendedUser' && (
								<ReferralRequestModal
									allMultiLingualData={allMultiLingualData}
									extendedNetworkUsers={extendedNetworkUsers}
									contacts={contacts}
									job={job}
									referrals={get(job, 'referrals', [])}
								/>
							)}
						</div>
					</div>
				</div>
				<>
					<div className="bjc-description">
						<h4 className="bjc-description-title">
							{ml('Job Description', currentUser, allMultiLingualData)}:
						</h4>
						<div className="bjc-description-content">
							<StringParser
								text={
									this.props.translatedDescription
										? this.props.translatedDescription
										: this.state.translatedDescription
								}
								breakOn="5"
								showMoreText={ml('Show More', currentUser, allMultiLingualData)}
								showLessText={ml('Show Less', currentUser, allMultiLingualData)}
							/>
						</div>
					</div>
					{hiringManager && !hideRecruiterInfoJobDetails && (
						<p className="bjc-recruiter">
							{ml('Recruiter', currentUser, allMultiLingualData)}
							<a href={`mailto:${hiringManager.emailAddress}`}>
								{hiringManager.firstName} {hiringManager.lastName}
								<i className="icon-envelope-outline" style={{ fontSize: 18 }} />
							</a>
						</p>
					)}
				</>
			</>
		);
	}
}
