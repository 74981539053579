import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import uuid from 'uuid/v4';
import {
	createSubCompany,
	querySubCompanyByCompanyIdIndex,
} from '../../graphql/custom/sub-company';

export const withCreateSubCompany = (Component) => {
	return graphql(gql(createSubCompany), {
		props: (props) => ({
			onCreateSubCompany(input) {
				const optimisticResponseData = {
					...input.input,
					id: uuid(),
					totalUsers: 0,
					__typename: 'SubCompany',
				};
				props.mutate({
					variables: input,
					optimisticResponse: {
						__typeName: 'Mutation',
						createSubCompany: {
							__typename: 'createSubCompany',
							...optimisticResponseData,
						},
					},
					update(proxy, { data: { createSubCompany } }) {
						const data = proxy.readQuery({
							query: gql(querySubCompanyByCompanyIdIndex),
							variables: {
								companyId: props.ownProps.currentUser.companyId,
								limit: 1000,
								nextToken: null,
							},
						});
						if (
							!data.querySubCompanyByCompanyIdIndex.items.find(
								(subCompany) => subCompany.id === createSubCompany.id
							)
						) {
							data.querySubCompanyByCompanyIdIndex.items.push({
								...createSubCompany,
								keywords: null,
								logo: null,
							});
						}

						proxy.writeQuery({
							query: gql(querySubCompanyByCompanyIdIndex),
							data,
							variables: {
								companyId: props.ownProps.currentUser.companyId,
								limit: 1000,
								nextToken: null,
							},
						});
					},
				});
			},
		}),
	})(Component);
};
