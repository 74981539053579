import gql from 'graphql-tag';
import get from 'lodash/get';
import { compose, graphql } from 'react-apollo';
import {
	queryWebNotificationsByUserIdTypeIndex,
	updateWebNotification,
} from '../../graphql/custom/users';

export const withListReferralRequestedByUseridTypeMyReferralsNotifications = (
	Component
) => {
	return compose(
		graphql(gql(queryWebNotificationsByUserIdTypeIndex), {
			options: (props) => ({
				pollInterval: 300_000,
				context: {
					headers: {
						'x-frame-options': 'deny', // This header will reach the server
					},
				},
				variables: {
					userId: props.currentUser.id,
					type: 'gdprReferralCreated',
					nextToken: null,
				},
				fetchPolicy: 'cache-and-network',
			}),
			props({ data, data: { fetchMore } }, previous) {
				const webNotificationsNetworkStatus = get(data, 'networkStatus');
				const loadingWebNotifications = get(data, 'networkStatus') !== 7;
				let notifications = get(
					data,
					'queryWebNotificationsByUserIdTypeIndex.items',
					[]
				);
				notifications = notifications.map((notification) => {
					const newNotification = notification;
					if (get(notification, 'type') === 'gdprReferralCreated')
						newNotification.referralDate = get(notification, 'dateCreated');
					if (typeof get(notification, 'job.referralBonus') === 'string') {
						newNotification.job.referralBonus = JSON.parse(
							get(notification, 'job.referralBonus', '{}')
						);
					}

					return newNotification;
				});
				const webNotificationsNextToken = get(
					data,
					'queryWebNotificationsByUserIdTypeIndex.nextToken',
					undefined
				);
				return {
					onLoadMoreWebNotifications() {
						fetchMore({
							variables: { after: webNotificationsNextToken },
							updateQuery(previous_, { fetchMoreResult }) {
								if (!fetchMoreResult) {
									return previous_;
								}

								const mergedItems = [
									...previous_.queryWebNotificationsByUserIdTypeIndex.items,
									...fetchMoreResult.queryWebNotificationsByUserIdTypeIndex
										.items,
								];
								const set = new Set();
								const uniqueItems = mergedItems.filter((item) => {
									if (!set.has(item.id)) {
										set.add(item.id);
										return true;
									}

									return false;
								});
								return {
									...previous_,
									queryWebNotificationsByUserIdTypeIndex: {
										...previous_.queryWebNotificationsByUserIdTypeIndex,
										...fetchMoreResult.queryWebNotificationsByUserIdTypeIndex,
										items: uniqueItems,
									},
								};
							},
						});
					},
					notifications,
					loadingWebNotifications,
					webNotificationsNextToken,
					webNotificationsNetworkStatus,
				};
			},
		}),
		graphql(gql(updateWebNotification), {
			props: (props) => ({
				onUpdateNotification(input) {
					const optimisticResponseData = {
						...input.input,
					};
					props.mutate({
						variables: input,
						optimisticResponse: {
							__typename: 'Mutation',
							updateWebNotification: {
								...optimisticResponseData,
								__typename: 'updateWebNotification',
							},
						},
						update(proxy) {
							const data = proxy.readQuery({
								query: gql(queryWebNotificationsByUserIdTypeIndex),
								variables: {
									userId: props.ownProps.currentUser.id,
									first: 1000,
									nextToken: null,
								},
							});
							proxy.writeQuery({
								query: gql(queryWebNotificationsByUserIdTypeIndex),
								data,
								variables: {
									userId: props.ownProps.currentUser.id,
									first: 1000,
									nextToken: null,
								},
							});
						},
					});
				},
			}),
		})
	)(Component);
};
