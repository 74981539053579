import { Button } from 'antd';
import get from 'lodash/get';
import { Component } from 'react';
import Ripples from 'react-ripples';
import { ml } from 'src/_shared/services/utils.js';
import ReferralRequestModal from './ReferralRequestModalComponent.jsx';
import {
	BtnContainer as ButtonContainer,
	ReferralBtn as ReferralButton,
	ReferralBtnMyReferrals as ReferralButtonMyReferrals,
	RippleButton,
	userIconCss,
} from './referralRequestModalWrapperStyles.js';

class ReferralRequestModalWrapper extends Component {
	constructor(props) {
		super(props);
		this.state = {
			visible: false,
			theme: JSON.parse(
				get(
					props,
					'currentUser.company.theme',
					get(props, 'companyData.theme', '{}')
				)
			),
		};
	}

	addNewWebNotification = (webNotification) => {
		this.props.requestingNotifications.push(webNotification);
	};

	handleCancel = () => {
		this.setState({ visible: false });
	};

	showModal = () => {
		setTimeout(() => {
			this.setState({ visible: true });
		}, 250);
	};

	render() {
		const { visible, theme } = this.state;
		const { allMultiLingualData, currentUser, contact, job } = this.props;
		const referralButtonStyle = contact
			? ReferralButtonMyReferrals
			: ReferralButton(theme);
		const userIconMargin = userIconCss;

		const isAskButtonDisabled = get(
			this.props,
			'requestingNotifications',
			[]
		).some((requestedNotification) => requestedNotification.jobId === job.id);

		return (
			<div className={ButtonContainer(theme, isAskButtonDisabled)}>
				<Ripples className={RippleButton} color="#bdbcb8">
					<Button
						className={referralButtonStyle}
						disabled={isAskButtonDisabled}
						onClick={this.showModal}
					>
						<div className={userIconMargin}>
							<span style={{ marginLeft: 5 }}>
								{isAskButtonDisabled
									? ml('Referral Requested', currentUser, allMultiLingualData)
									: ml('Ask For Referral', currentUser, allMultiLingualData)}
							</span>
						</div>
					</Button>
				</Ripples>
				<ReferralRequestModal
					{...this.props}
					addNewWebNotification={this.addNewWebNotification}
					visible={visible}
					handleCancel={this.handleCancel}
				/>
			</div>
		);
	}
}

export default ReferralRequestModalWrapper;
