import get from 'lodash/get';
import { connect } from 'react-redux';
import { withCreateWebNotification } from 'src/_shared/api/components/network/';
import { withCreateReferralNoUpdate as withCreateReferral } from 'src/_shared/api/components/referrals/';
import { withGetCompanyData } from 'src/_shared/api/components/settings/';
import { withGetTieredBonus } from 'src/_shared/api/components/tiered-bonuses/';
import { withListReferralRequestingNotifications } from 'src/_shared/api/components/users/';
import { compose } from '../services/utils';
import ReferralRequestModalWrapper from './ReferralRequestModalWrapper.jsx';

const mapStateToProperties = (state, ownProperties) => {
	const { currentUser } = state.user;
	let referralBonus = get(
		ownProperties,
		['jobData', 'referralBonus'],
		get(ownProperties, ['job', 'referralBonus'], 'skip')
	);
	if (typeof referralBonus === 'string' && referralBonus !== 'skip')
		referralBonus = JSON.parse(referralBonus);

	return {
		currentUser,
		filter: { userId: { eq: currentUser.id } },
		limit: 1000,
		tieredBonusQuery: {
			companyId: get(currentUser, 'companyId', null),
			id: get(referralBonus, 'tieredBonusId', 'skip'),
		},
		companyId: currentUser.companyId,
	};
};

const ReferralRequestModalWithApi = compose(
	withCreateReferral,
	withCreateWebNotification,
	withGetTieredBonus,
	withGetCompanyData,
	withListReferralRequestingNotifications
)(ReferralRequestModalWrapper);

export const ReferralRequestModal = connect(mapStateToProperties)(
	ReferralRequestModalWithApi
);
