import { useEffect, useState } from "react";
import { message } from 'antd'
import { useNominatedRewardsService } from "../service/NominatedRewards.service.jsx";

const useGetNominatedConfigList = () => {
    const [nominatedBadgeList, setNominatedBadgeList] = useState([])
    const [nominatedBadgeListLoader, setNominatedBadgeListLoader] = useState(false)
    const nominatedRewardsAPIService = useNominatedRewardsService();

    const getNominationConfiguration = async () => {
        setNominatedBadgeListLoader(true);
        try {
            const response = await nominatedRewardsAPIService.getNominationConfiguration();
            if (response?.code === 200 && response?.data) {
                const records = response.data.records;
                const nominationConfigurationOpenList = records?.filter(({ status }) => status === "Open") || [];
                const nominationConfigurationCompleteList = records?.filter(({ status }) => status === "Complete") || [];
                const nominationConfigurationPendingList = records?.filter(({ status }) => status === "Pending") || [];
                const nominationConfigurationSubmittedList = records?.filter(({ status }) => status === "Submitted") || [];

                setNominatedBadgeList([
                    ...nominationConfigurationOpenList,
                    ...nominationConfigurationPendingList,
                    ...nominationConfigurationSubmittedList,
                    ...nominationConfigurationCompleteList,
                ]);
            } else {
                throw new Error('Failed to fetch badges list! Try again later.');
            }
        } catch (error) {
            message.error(error?.response?.message || 'Failed to fetch badges list! Try again later.');
        } finally {
            setNominatedBadgeListLoader(false);
        }
    };

    useEffect(() => {
        getNominationConfiguration()
    }, [])

    return ({
        nominatedBadgeListLoader,
        nominatedBadgeList,
        getNominationConfiguration,
    })
}

export default useGetNominatedConfigList