import { getIntlDayMonthYearParts } from './getIntlDayMonthYearDateParts.js';

export function SaasAgreementText({ customerName }) {
	const { day, month, year } = getIntlDayMonthYearParts();

	function Bold({ children }) {
		return <span className="font-bold">{children}</span>;
	}

	return (
		<>
			<section>
				<p>
					This Software as a Service Agreement (the “Agreement”) is entered into
					this <Bold>{day}</Bold> of <Bold>{month}</Bold>, <Bold>{year}</Bold>
					&nbsp; (the “Effective Date”), between <Bold>{customerName} </Bold>
					(“Subscriber”), and Erin Technologies, Inc. (“Licensor”) a Delaware
					corporation. For purposes of this Agreement, Subscriber and Licensor
					each will be referred to individually as a "Party" and together as the
					"Parties."
				</p>
				<p>WHEREAS, Licensor is the owner of proprietary software; and </p>
				<p>
					WHEREAS, Licensor is willing to provide the Services (as defined
					below) and Subscriber is willing to obtain the Services, subject to
					the terms and conditions set forth here.
				</p>
				<p>
					NOW THEREFORE, for valuable consideration, the receipt and sufficiency
					of which are hereby acknowledged, the Parties hereby agree as follows:
				</p>
			</section>
			<section>
				<h3>1. Definitions</h3>

				<p>
					<Bold>“Application”</Bold> shall mean either the Licensor-developed
					application used by Subscriber for the Service hereunder;
				</p>
				<p>
					<Bold>“Agreement”</Bold> shall mean these terms and conditions, the
					Order Form, and any written amendments signed by both parties;
				</p>
				<p>
					<Bold>“Affiliate”</Bold> shall mean corporate or legal entities
					controlling, controlled by or under common control with a Party;
				</p>
				<p>
					<Bold>“Authorized Users”</Bold> shall mean Subscriber’s Affiliates and
					its and their employees and independent contractors working for
					Subscriber or such Affiliate in the ordinary course of Subscriber’s
					business who: (i) are directed to abide by the Terms of Use on the
					website and mobile app, as well as the End User License Agreement on
					the app; and (ii) are specifically authorized by Subscriber to access
					the Service;
				</p>
				<p>
					<Bold>“Candidate”</Bold> shall mean any individual seeking employment
					or engagement as a consultant, or referred for the same, through the
					Service;
				</p>
				<p>
					<Bold>“Hired Candidate”</Bold> shall mean any Candidate that accepts
					an offer of employment or engagement as an independent contractor for
					any position from Subscriber or any of Subscriber’s Affiliate(s),
					whether such offer is for full-time or part-time work, as an employee,
					independent contractor or paid advisor, regardless of the position for
					which they were referred under the Service;
				</p>
				<p>
					<Bold>“Hiring Process”</Bold> shall mean continuous, back and forth
					conversation between the Candidate and Subscriber (or one of its
					Affiliates, as the case may be) in the context of a hiring process;
				</p>
				<p>
					<Bold>“Implementation Fee”</Bold> shall mean the amount set forth on
					the Order Form;
				</p>
				<p>
					<Bold>“Licensing Fee”</Bold> shall mean the amount set forth on the
					Order Form;
				</p>
				<p>
					<Bold>“Service”</Bold> shall mean the Erin Technologies, Inc. software
					accessed through http://erinapp.com or through the mobile application,
					licensed to by Subscriber hereunder;
				</p>
				<p>
					<Bold>“Service Start Date”</Bold> shall mean the date set forth on the
					Order Form;
				</p>
				<p>
					<Bold>“Fees”</Bold> shall mean the fees payable pursuant to Section 3
					hereof;
				</p>
				<p>
					<Bold>“Order Form”</Bold> shall mean the Order Form that sets out the
					commercial terms and is executed by the parties, attached hereto, as
					the same may be amended from time to time.
				</p>
				<p>
					<Bold>“Term”</Bold> shall mean the period identified in the Order
					Form, or any renewal term, as applicable.
				</p>
			</section>
			<section>
				<h3>2. License to Receive the Service</h3>
				<p>
					Licensor hereby grants the Subscriber a limited, non-exclusive and
					non-transferable license, without right of sublicense, during the
					Term, to access, via either the website or the mobile app, the
					Service, and to permit Authorized Users to use the Service, subject to
					the terms and conditions of this Agreement, the Order Form, and the
					Terms of Use on the website or mobile app. All rights in the Service
					not expressly granted hereunder are reserved to Licensor.
				</p>
			</section>
			<section>
				<h3>3. Fees and Payment</h3>
				<p>
					<Bold>(a) Licensing Fee.</Bold> In exchange for the license granted
					above, Subscriber shall pay Licensor the Licensing Fee in the amount
					and under the terms set forth in the Order Form, payable in advance of
					the Service Start Date, based up on the Service, number of Authorized
					Users and any other commercial terms contained in this agreement.
				</p>
				<p>
					<Bold>(b) Implementation Fee.</Bold> Should Subscriber require or
					request any customization, integration or other additional services on
					the part of Licensor in order to begin using the Services, Subscriber
					shall pay Licensor the Implementation Fee in the amount and under the
					terms set forth in the Order Form, payable in advance of the Service
					Start Date.
				</p>
				<p>
					<Bold>(c) Late Payments.</Bold> If Subscriber fails to pay the Fees by
					the due date specified on the invoice, Licensor shall be entitled to
					interest from the day on which the Fees are due. Both parties agree
					that the rate of interest on overdue invoices shall be 1.5 per cent
					per month, but in no case shall be higher than the amount allowed
					under applicable law.
				</p>
				<p>
					<Bold>(d) Taxes.</Bold> Subscriber will be responsible for, and will
					promptly pay or reimburse Licensor for, the payment of all sales, use,
					excise, value-added or similar taxes, assessments, or duties (or other
					similar charges) imposed by any governmental agency (including any
					interest and penalty imposed thereon as a result of any act or
					omission of Licensor that is in accordance with the direction or
					request of Subscriber) that are based on or with respect to any
					Services or goods provided by Licensor to Subscriber, or the amounts
					payable to Licensor therefore.
				</p>
			</section>
			<section>
				<h3>4. Proprietary Rights</h3>
				<p>
					Licensor and its licensors are the sole and exclusive owners of the
					Service, including all technology and intellectual property
					comprising, appurtenant to, and/or used to support and/or operate, the
					same, and all user manuals and other documentation related thereto
					(the “Documentation”). Licensor retains all right, title and interest
					in and to the Service Documentation and Subscriber shall not have any
					right to the same except for the rights expressly granted by Licensor
					pursuant to this Agreement. Licensor may update or otherwise modify
					the Service from time to time in Licensor’s sole discretion.
				</p>
			</section>
			<section>
				<h3>5. Restriction on Use</h3>
				<p>
					Subscriber shall not reverse engineer, decompile or disassemble any
					part of the Service. Subscriber further agrees that neither Subscriber
					nor any Authorized User shall store copy, reproduce, retransmit,
					disseminate, sublicense, sell, distribute, publish, broadcast,
					circulate, create derivative works, or distribute by any means the
					Service in whole or in part to anyone. Subscriber shall not make or
					have made a service or product using similar ideas, features,
					functions or graphics of or providing a similar benefit as that
					provided by the Service. Subscriber and each Authorized User shall
					comply with all applicable laws in the use of the Service, including
					all applicable laws related to the hiring, firing or treatment of
					employees, or otherwise related to employment or labor.
				</p>
			</section>
			<section>
				<h3>6. Disclaimer</h3>
				<p>
					SUBSCRIBER ACKNOWLEDGES AND AGREES THAT THE SERVICE(S), THE CONTENTS
					THEREIN, AND ANY ACCOMPANYING DOCUMENTATION ARE PROVIDED ON AN “AS
					IS”, “AS AVAILABLE” BASIS AND LICENSOR DOES NOT MAKE ANY AND HEREBY
					SPECIFICALLY DISCLAIMS ANY REPRESENTATIONS, ENDORSEMENTS, GUARANTEES,
					OR WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, ANY
					OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, OR NON
					INFRINGEMENT OF INTELLECTUAL PROPERTY RIGHTS.
				</p>
				<p>
					SUBSCRIBER ACKNOWLEDGES THAT LICENSOR DOES NOT INQUIRE INTO THE
					BACKGROUNDS OF OR VERIFY ANY STATEMENTS MADE BY ITS AUTHORIZED USERS
					OR CANDIDATES. SUBSCRIBER AGREES TO CONDUCT ANY BACKGROUND CHECK,
					REFERENCE CHECK OR OTHER DUE DILIGENCE THAT IT MAY REQUIRE BEFORE OR
					DURING THE HIRING PROCESS.
				</p>
			</section>
			<section>
				<h3>7. Indemnification</h3>
				<Bold>Infringement</Bold>
				<p>
					Licensor will indemnify, defend, and hold harmless Subscriber for
					Losses Subscriber incurs as a direct result of any unaffiliated third
					party claim based on any claim that the Service infringes any U.S.
					copyright, trademark, or trade secret, except to the extent resulting
					from: (i) Subscriber’s modification of the Service or combination by
					Subscriber the Services with other products or services if the Service
					would not have been infringing but for such combination or
					modification; (ii) Subscriber’s use of the Service in a manner not
					authorized herein or for which it was not designed; (iii) Subscriber’s
					failure to use an updated non-infringing version of the applicable
					intellectual property to the extent Subscriber was notified that the
					update cured an infringement; (iv) changes to the Service made by
					Licensor at the direction of the Subscriber; or (v) Subscriber Data.
					If any item for which Licensor has an indemnification obligation under
					this Section 14.2 becomes, or in Licensor’s reasonable opinion is
					likely to become, the subject of an infringement or misappropriation
					claim or proceeding, Licensor will, in addition to indemnifying
					Subscriber as provided in this Section 8 (a), promptly take the
					following actions, at no additional charge to Subscriber, in the
					listed order of priority: (a) secure the right to continue using the
					item; or (b) replace or modify the item to make it non-infringing. If
					neither of such actions can be accomplished by Licensor using
					commercially reasonable efforts, and only in such event, Licensor will
					remove the item from the Service and the applicable Service Fees will
					be equitably adjusted to reflect such removal. This Section 8 (a)
					states Subscriber’s sole and exclusive remedy for Licensor’s
					infringement or misappropriation of intellectual property of a third
					party.
				</p>
				<Bold>Subscriber’s Indemnity</Bold>
				<p>
					Subscriber shall defend and indemnify Licensor and its Third Party
					Licensors against any and all Losses incurred by Licensor and its
					Third Party Licensors arising out of or in connection with a claim by
					a third party: (i) alleging that the Subscriber Data or the Subscriber
					Trademarks, or any use thereof, infringes the rights of, or has caused
					harm to, a third party; (ii) arising out of Subscriber’s (or any
					Candidate’s) breach of Article 7 of this Agreement above; (iii)
					Subscriber’s (or any Candidate’s) breach of any representation,
					warranty, covenant, or obligation under this Agreement; (iv) any third
					party claim, fine, or regulatory violation in connection with, arising
					out of, or resulting from any actual or alleged violation of any
					applicable laws by Subscriber (including its affiliates, directors,
					officers, employees, agents, successors, and assigns) or any
					Candidate; or (v) any third party claim arising from Subscriber’s
					Content or Subscriber’s or any User’s (including any Candidate’s) use
					of the Services.
				</p>
				<Bold>Indemnification Procedures</Bold>
				<p>
					The Indemnified Party seeking indemnification shall give prompt notice
					of the claim and will tender the defense; provided however, that the
					Indemnified Party’s failure to provide notification shall not affect
					the Indemnifying Party’s indemnification obligations except to the
					extent that the failure to notify delays or prejudices the
					Indemnifying Party’s ability to defend the applicable claim. The
					Indemnifying Party shall conduct the defense and shall have control of
					the litigation of any such claim, and the Indemnified Party shall
					cooperate in defending against the claim. The Indemnified Party shall
					have the right, at any time and at its own expense, to participate in
					the defense of the claim with counsel of its own choosing. The
					Indemnifying Party shall not make any settlement of the claim that
					results in any liability or imposes any obligation on the Indemnified
					Party without the prior written consent of the Indemnified Party. If
					the Indemnifying Party fails to: (i) respond to the notice of a claim;
					or (ii) assume the defense of a claim, the Indemnified Party seeking
					indemnification shall have the right to defend the claim in such
					manner as it may deem appropriate, at the reasonable cost, expense,
					and risk of the Indemnifying Party, and the Indemnifying Party shall
					promptly reimburse the Indemnified Party for all such costs and
					expenses.
				</p>
			</section>
			<section>
				<h3>8. Limitation of Liability</h3>
				<p>
					ERIN IS NOT LIABLE FOR ANY ACTION, OR LACK OF ACTION, ON THE PART OF
					ANY USER, CANDIDATE,EMPLOYER, SUBSCRIBER OR AUTHORIZED USER THAT USES
					THE ERIN SERVICE WHICH RESULTS IN ANY CLAIM OR LIABILITY UNDER ANY
					APPLICABLE LAWS CONCERNING EMPLOYMENT DISCRIMINATION, LABOR ISSUES,
					WORKPLACE SAFETY, PERSONALLY IDENTIFIABLE INFORMATION INCLUDING
					MEDICAL INFORMATION, MEDICAL LEAVE, OR CONCERNING THE PROPER
					CLASSIFICATION OF INDEPENDENT CONTRACTORS AND EMPLOYEES.
				</p>
				<p>
					NEITHER PARTY SHALL BE LIABLE TO THE OTHER FOR ANY INDIRECT, SPECIAL,
					CONSEQUENTIAL, OR PUNITIVE DAMAGES (INCLUDING LOSS OF USE, DATA,
					BUSINESS OR PROFITS) ARISING OUT OF OR IN CONNECTION WITH THIS
					AGREEMENT OR THE SERVICES.
				</p>
				<p>
					IN NO EVENT WILL THE LIABILITY OF THE PARTIES ARISING OUT OF ANY CLAIM
					RELATED TO THIS AGREEMENT EXCEED THE AGGREGATE AMOUNT PAID BY
					SUBSCRIBER HEREUNDER IN THE TWELVE (12) MONTHS IMMEDIATELY PRECEDING
					THE EVENT GIVING RISE TO SUCH CLAIM. IF ANY APPLICABLE AUTHORITY HOLDS
					ANY PORTION OF THIS SECTION TO BE UNENFORCEABLE, THEN THE PARTIES’
					LIABILITY WILL BE LIMITED TO THE FULLEST POSSIBLE EXTENT PERMITTED BY
					APPLICABLE LAW.
				</p>
				<p>
					THE LIMITATION OF LIABILITY PROVISIONS OF THIS SECTION DO NOT APPLY
					FOR CLAIMS RESULTING FROM A PARTY’S WILLFUL MISCONDUCT AND/OR GROSS
					NEGLIGENCE.
				</p>
			</section>
			<section>
				<h3>9. Term; Early Termination</h3>
				<p>
					<Bold>(a) Term.</Bold> This Agreement shall become effective when
					Subscriber signs the Order Form and, unless terminated earlier in
					accordance herewith, shall continue for the period specified in the
					Order Form. This Agreement shall automatically renew for subsequent
					like terms unless either party gives the other written notice of its
					intention not to renew no later than sixty (60) days prior to the end
					of the then-current term.
				</p>
				<p>
					<Bold>(b) Termination.</Bold> Unless otherwise permitted under Section
					3, This Agreement may be terminated as follows: (a) by Licensor if
					Subscriber fails to pay the Implementation Fee prior to the Service
					Start Date; (b) if either party commits a breach of any provision of
					this Agreement and fails to remedy such breach within thirty (30) days
					of receiving written notice thereof by the non-breaching party
					(“Notice of Breach”), the party giving such notice may then deliver a
					second written notice to the breaching party terminating this
					Agreement, in which event this Agreement, and the licenses granted
					hereunder, will terminate on the date specified in such second notice;
					(c) if a receiver is appointed over any assets of either party or if
					either party makes any arrangement with its creditors or becomes
					subject to an administration order or goes into liquidation or
					anything equivalent to the foregoing under any jurisdiction or ceases
					to carry on business, the other may terminate by giving written notice
					with immediate effect;
				</p>
			</section>
			<section>
				<h3>10. Confidentiality</h3>
				<p>
					have access to private or confidential information of the other party
					which either is marked as “confidential” or the receiving party should
					reasonably know under the circumstances that such information is
					confidential and/or proprietary information of the other party. Each
					Party shall hold such information in confidence and not, without the
					consent of the other, disclose it to a third party or use it for any
					purpose other than in performance of this Agreement. This obligation
					of confidentiality shall not apply to information that is generally
					available to the public through no act or omission of the receiving
					party or becomes known to the receiving party through a third party
					with no obligation of confidentiality, or is required to be disclosed
					by law, court or by any government or regulatory authority. If any
					Confidential Information is required to be disclosed by statute, rule,
					regulation or order of any court of competent jurisdiction, before any
					such disclosure the receiving party will provide notice to the
					disclosing party reasonably sufficient to allow the disclosing party
					the opportunity to apply for a protective order or other restriction
					regarding such disclosure. If either party elects to file this
					Agreement with the U.S. Securities and Exchange Commission or any
					other securities exchange or market, regulatory authority or other
					body, the filing party will provide the non-filing party, no less than
					five (5) business days before the expected date of the filing (the
					“Filing Date”), a copy of the Agreement marked to show the sections
					for which the filing party plans to seek confidential treatment. The
					filing party agrees to expand its confidential treatment request to
					include those provisions of this Agreement reasonably indicated by the
					non-filing party before the Filing Date as provisions for which the
					non-filing party requests confidential treatment. All confidential
					information will remain the exclusive property of the owner. No public
					announcement, press release or communication concerning this Agreement
					shall be made without the prior written consent of the other party.
				</p>
			</section>
			<section>
				<h3>11. Miscellaneous</h3>
				<p>
					<Bold>(a) Notice.</Bold> All notices to a party hereunder shall be in
					writing, and delivered by certified mail, return receipt requested,
					overnight courier service, or by electronic mail with confirmation to
					the address(es) set forth on the Order Form, or to a different address
					which a party may give written notice of pursuant to this Section from
					time to time. Notice will be deemed delivered and received on the date
					it is actually received.
				</p>
				<p>
					<Bold>(b) Amendment.</Bold> This Agreement may not be amended except
					in a writing executed by authorized representatives of Subscriber and
					Licensor.
				</p>
				<p>
					<Bold>(c) Assignment.</Bold> This Agreement is not transferable,
					assignable, delegable, or sublicenseable by Subscriber in whole or in
					part, without the prior written permission of Licensor. This Agreement
					will be binding upon and inure to the benefit of the parties and their
					respective successors, trustees, administrators, and assigns.
				</p>
				<p>
					<Bold>(d) Survival.</Bold> The following obligations of the parties
					will survive termination or expiration of this Agreement for any
					reason: Sections 4, 5, 6, 7,, 9, and 11, of this Agreement and any
					payment obligations of Subscriber that accrue prior to such
					termination or expiration.
				</p>
				<p>
					<Bold>(e) Relationship.</Bold> There is no employment relationship
					between the Parties and each Party’s personnel are not employees or
					agents of the other Party for federal, state, local or other tax
					purposes or any other purposes whatsoever. Personnel of one Party have
					no authority to make representations, commitments, bind or enter into
					contracts on behalf of or otherwise obligate the other Party in any
					manner whatsoever. Nothing in this Agreement shall be construed or
					deemed to constitute, create, give effect to or otherwise recognize a
					joint venture, partnership, business entity of any kind, nor
					constitute one Party an agent of the other Party.
				</p>
				<p>
					<Bold>(f) Binding Effect and Third-Party Beneficiary.</Bold> Except if
					specifically stated in this Agreement, neither party, nor any of their
					respective employees or agents, will have the power or authority to
					bind or obligate the other party. No third party is a beneficiary of
					this Agreement.
				</p>
				<p>
					<Bold>(g) Waiver of Rights.</Bold> Except where specifically stated to
					the contrary, all remedies available to either party for breach of
					this Agreement under this Agreement, at law, or in equity, are
					cumulative and nonexclusive. A waiver or failure of either party at
					any time to require performance by the other party of any provision
					hereof will not affect the full right to require such performance at
					any time thereafter.
				</p>
				<p>
					<Bold>(h) Severability.</Bold> If any provision or portion thereof of
					this Agreement or its application in a particular circumstance is held
					to be invalid or unenforceable to any extent in any jurisdiction, such
					provision or portion thereof will, as to such jurisdiction only, be
					ineffective to the extent of such unenforceability, all other
					provisions and portions thereof of this Agreement will not be affected
					thereby and will be valid and enforced to the fullest extent permitted
					by law.
				</p>
				<p>
					<Bold>(i) Choice of Law and Venue.</Bold> This Agreement, as well as
					any and all claims arising from this Agreement or arising from any of
					the proposals, negotiations, communications or understandings
					regarding this Agreement, will be governed by and construed in
					accordance with the laws of the Commonwealth of Pennsylvania, United
					States of America (“Pennsylvania”), applicable to contracts made
					entirely within Pennsylvania and wholly performed in Pennsylvania,
					without regard to any conflict or choice of law principles. The sole
					jurisdiction and venue for any litigation arising out of this
					Agreement will be an appropriate federal or state court located in
					Allegheny County, Pennsylvania.
				</p>
				<p>
					<Bold>(j) Force Majeure.</Bold> Any failure or delay by Licensor in
					the performance of its obligations pursuant to this Agreement will not
					be deemed a default or breach of the Agreement or a ground for
					termination to the extent such failure or delay is due to fire, flood,
					earthquake, elements of nature or acts of God, acts of war, terrorism,
					riots, civil unrest, rebellions or revolutions in the United States or
					any nation where the obligations under this Agreement are to be
					executed, strikes, supplier and third party failure, lockouts, or
					labor difficulties, or any similar cause beyond the reasonable control
					of Licensor.
				</p>
				<p>
					<Bold>(k) Entire Agreement.</Bold> This Agreement, along with any
					Terms of Use or End User License Agreement in place at any time,
					contains the final and entire agreement of the parties and supersedes
					all previous and contemporaneous verbal or written negotiations,
					understandings, or agreements regarding the Agreement’s subject
					matter.
				</p>
			</section>
		</>
	);
}
