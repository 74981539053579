import { Alert, Button } from 'antd';
import { EventEmitter } from 'fbemitter';
import gql from 'graphql-tag';
import get from 'lodash/get';
import { Component } from 'react';
import { withApollo } from 'react-apollo';
import ReactDOM from 'react-dom';
import { queryReferralQuestionsByCompanyId } from 'src/_shared/api/graphql/custom/referral-questions/';
import FormElements from 'src/form-builder/FormElements.jsx';
import FormValidator from './FormValidator.jsx';
import { SaveDisabled, SaveEnabled } from './formbuilderStyles.js';

class ReactForm extends Component {
	constructor(props) {
		super(props);
		this.answerData = this._convert(props.answer_data);
		this.emitter = new EventEmitter();
		this.state = {
			success: false,
			isDisabled: false,
			allQuestionsData: [],
			theme: JSON.parse(get(props, 'currentUser.company.theme', '{}')),
			isAttach: props.checked,
			isAttachAcceptance: props.checkedAcceptance,
			attachRequired: props.checked === false ? false : props.checkedRequired,
			attachAcceptanceRequired:
				props.checkedAcceptance === false
					? false
					: props.checkedAcceptanceRequired,

			isAttachPhoneAcceptance: props.checkedPhoneAcceptance,
			attachPhoneRequired:
				props.checked === false ? false : props.checkedPhoneRequired,
			attachPhoneAcceptanceRequired:
				props.checkedPhoneAcceptance === false
					? false
					: props.checkedPhoneAcceptanceRequired,

			isAttachInterested: props.checkedInterested,
			isAttachInterestedEmail: props.checkedInterestedEmail,
			attachInterestedRequired:
				props.checkedInterested === false
					? false
					: props.checkedInterestedRequired,
			isAttachGeneral: props.checkedGeneral,
			attachGeneralRequired:
				props.checkedGeneral === false ? false : props.checkedGeneralRequired,
		};
	}

	getInputElement(item) {
		const Input = FormElements[item.element];
		return (
			<Input
				ref={(c) => (this.inputs[item.field_name] = c)}
				key={`form_${item.id}`}
				mutable
				handleChange={this.handleChange}
				data={item}
				read_only={this.props.read_only}
				defaultValue={this._getDefaultValue(item)}
			/>
		);
	}

	async getReferralQuestions(
		policy = 'network-only',
		nextToken = null,
		allQuestions = []
	) {
		const { client, currentUser } = this.props;
		try {
			const { data } = await client.query({
				query: gql(queryReferralQuestionsByCompanyId),
				variables: {
					companyId: get(currentUser, 'companyId'),
					limit: 1000,
					after: nextToken,
				},
				fetchPolicy: policy,
			});
			const questions = [...data.queryReferralQuestionsByCompanyId.items];
			const token = get(data, 'queryJobsByCompanyIdDateIndex.nextToken', null);
			allQuestions = [...allQuestions, ...questions];
			this.setState({
				allQuestionsData: allQuestions,
			}, () => {this.handleSubmit2()});
			if (token) {
				this.getReferralQuestions(policy, token, allQuestions);
			}
		} catch (error) {
			console.log(error);
		}
	}

	getSimpleElement(item) {
		const Element = FormElements[item.element];
		return <Element key={`form_${item.id}`} mutable data={item} />;
	}

	_collect(item) {
		const itemData = { name: item.field_name };
		const ref = this.inputs[item.field_name];
		if (item.element === 'Checkboxes' || item.element === 'RadioButtons') {
			const checked_options = [];
			for (const option of item.options) {
				const $option = ReactDOM.findDOMNode(
					ref.options[`child_ref_${option.key}`]
				);
				if ($option.checked) {
					checked_options.push(option.key);
				}
			}

			itemData.value = checked_options;
		} else {
			if (!ref) return null;
			itemData.value = this._getItemValue(item, ref).value;
		}

		return itemData;
	}

	_collectFormData(data) {
		const formData = [];
		for (const item of data) {
			const item_data = this._collect(item);
			if (item_data) {
				formData.push(item_data);
			}
		}

		return formData;
	}

	_convert(answers) {
		if (Array.isArray(answers)) {
			const result = {};
			for (const x of answers) {
				result[x.name] = x.name.includes('tags_')
					? x.value.map((y) => y.value)
					: x.value;
			}

			return result;
		}

		return answers;
	}

	_getDefaultValue(item) {
		return this.answerData[item.field_name];
	}

	_getItemValue(item, ref) {
		let $item = {
			element: item.element,
			value: '',
		};
		switch (item.element) {
			case 'Rating': {
				$item.value = ref.inputField.current.state.rating;

				break;
			}

			case 'Tags': {
				$item.value = ref.inputField.current.state.value;

				break;
			}

			case 'DatePicker': {
				$item.value = ref.state.value;

				break;
			}

			case 'Camera': {
				$item.value = ref.state.img
					? ref.state.img.replace('data:image/png;base64,', '')
					: '';

				break;
			}

			default: {
				if (ref && ref.inputField) {
					$item = ReactDOM.findDOMNode(ref.inputField.current);
					if (typeof $item.value === 'string') {
						$item.value = $item.value.trim();
					}
				}
			}
		}

		return $item;
	}

	_getSignatureImg(item) {
		const ref = this.inputs[item.field_name];
		const $canvas_sig = ref.canvas.current;
		if ($canvas_sig) {
			const base64 = $canvas_sig
				.toDataURL()
				.replace('data:image/png;base64,', '');
			const isEmpty = $canvas_sig.isEmpty();
			const $input_sig = ReactDOM.findDOMNode(ref.inputField.current);
			$input_sig.value = isEmpty ? '' : base64;
		}
	}

	_isIncorrect(item) {
		let incorrect = false;
		if (item.canHaveAnswer) {
			const ref = this.inputs[item.field_name];
			if (item.element === 'Checkboxes' || item.element === 'RadioButtons') {
				for (const option of item.options) {
					const $option = ReactDOM.findDOMNode(
						ref.options[`child_ref_${option.key}`]
					);
					if (
						(option.hasOwnProperty('correct') && !$option.checked) ||
						(!option.hasOwnProperty('correct') && $option.checked)
					) {
						incorrect = true;
					}
				}
			} else {
				const $item = this._getItemValue(item, ref);
				if (item.element === 'Rating') {
					if ($item.value.toString() !== item.correct) {
						incorrect = true;
					}
				} else if (
					$item.value.toLowerCase() !== item.correct.trim().toLowerCase()
				) {
					incorrect = true;
				}
			}
		}

		return incorrect;
	}

	_isInvalid(item) {
		let invalid = false;
		if (item.required === true) {
			const ref = this.inputs[item.field_name];
			if (item.element === 'Checkboxes' || item.element === 'RadioButtons') {
				let checked_options = 0;
				for (const option of item.options) {
					const $option = ReactDOM.findDOMNode(
						ref.options[`child_ref_${option.key}`]
					);
					if ($option.checked) {
						checked_options += 1;
					}
				}

				if (checked_options < 1) {
					invalid = true;
				}
			} else {
				const $item = this._getItemValue(item, ref);
				if (item.element === 'Rating') {
					if ($item.value === 0) {
						invalid = true;
					}
				} else if ($item.value === undefined || $item.value.length === 0) {
					invalid = true;
				}
			}
		}

		return invalid;
	}

	_optionsDefaultValue(item) {
		const defaultValue = this._getDefaultValue(item);
		if (defaultValue) {
			return defaultValue;
		}

		const defaultChecked = [];
		for (const option of item.options) {
			if (this.answerData[`option_${option.key}`]) {
				defaultChecked.push(option.key);
			}
		}

		return defaultChecked;
	}

	answerData;

	form;

	handleInterestedEmailChange = (e) => {
		this.setState({
			isAttachInterestedEmail: e.target.checked,
		});
	};

	handleInterestedQuesChange = (e) => {
		if (e.target.checked) {
			this.setState({
				isAttachInterestedQues: true,
			});
		} else {
			this.setState({
				isAttachInterestedQues: false,
			});
		}
	};

	async handleSubmit(e) {
		e.preventDefault();
		this.setState({ isDisabled: true });
		let errors = [];
		if (!this.props.skip_validations) {
			errors = this.validateForm();
			// Publish errors, if any.
			this.emitter.emit('formValidation', errors);
		}

		// Only submit if there are no errors.
		if (errors.length === 0) {
			await this.getReferralQuestions();
		}
	}
	// This is only a temporary bandaid before we redo everything, yikes.
	handleSubmit2 = () => {
		const {
			onAddQuestion,
			currentUser,
			onDeleteQuestion,
			isReferral,
			isAcceptance,
			checked,
			checkedAcceptance,
			isInterested,
			checkedInterested,
			isGeneral,
			checkedGeneral,
		} = this.props;
		const { allQuestionsData } = this.state;
		if (
			allQuestionsData.length > 0 &&
			isReferral &&
			!isAcceptance &&
			!isGeneral &&
			!isInterested
		) {
			const allQuestionsDataForEmployee = allQuestionsData.filter(
				(item) =>
					item.isCandidate === false &&
					item.isInterested === false &&
					item.isGeneral === false &&
					!item?.subCompanyId
			);
			allQuestionsDataForEmployee.map((element) => {
				onDeleteQuestion({
					input: {
						id: element.id,
					},
				});
			});
		} else if (
			allQuestionsData.length > 0 &&
			!isReferral &&
			isAcceptance &&
			!isGeneral &&
			!isInterested
		) {
			const allQuestionsDataForCandidate = allQuestionsData.filter(
				(item) => item.isCandidate === true
			);
			allQuestionsDataForCandidate.map((element) => {
				onDeleteQuestion({
					input: {
						id: element.id,
					},
				});
			});
		} else if (
			allQuestionsData.length > 0 &&
			!isReferral &&
			!isAcceptance &&
			isInterested &&
			!isGeneral
		) {
			const allQuestionsDataForInterested = allQuestionsData.filter(
				(item) => item.isInterested === true
			);
			allQuestionsDataForInterested.map((element) => {
				onDeleteQuestion({
					input: {
						id: element.id,
					},
				});
			});
		} else if (
			allQuestionsData.length > 0 &&
			!isReferral &&
			!isAcceptance &&
			!isInterested &&
			isGeneral
		) {
			const allQuestionsDataForGeneral = allQuestionsData.filter(
				(item) => item.isGeneral === true
			);
			allQuestionsDataForGeneral.map((element) => {
				onDeleteQuestion({
					input: {
						id: element.id,
					},
				});
			});
		}

		const companyId = get(currentUser, 'companyId');
		let count = 0;
		let isCandidate = '';
		let isIam = '';
		let isGen = '';
		if (isReferral && !isAcceptance && !isInterested && !isGeneral) {
			isCandidate = false;
			isIam = false;
			isGen = false;
		} else if (!isReferral && isAcceptance && !isInterested && !isGeneral) {
			isCandidate = true;
			isIam = false;
			isGen = false;
		} else if (!isReferral && !isAcceptance && isInterested && !isGeneral) {
			isCandidate = false;
			isIam = true;
			isGen = false;
		} else if (!isReferral && !isAcceptance && !isInterested && isGeneral) {
			isCandidate = false;
			isIam = false;
			isGen = true;
		}

		this.props.data.map((element) => {
			count += 1;
			onAddQuestion({
				input: {
					companyId,
					questions: JSON.stringify(element),
					active: true,
					isCandidate,
					sortOrder: count,
					isInterested: isIam,
					isGeneral: isGen,
				},
			});
		});
		onAddQuestion({
			input: {
				companyId,
				questions: isCandidate
					? JSON.stringify({
							'Ask for resume attachment': checkedAcceptance,
							'Resume required': this.state.attachAcceptanceRequired,
						})
					: isIam
						? JSON.stringify({
								'Ask for resume attachment': checkedInterested,
								'Resume required': this.state.attachInterestedRequired,
								"Show User's Email Address":
									this.state.isAttachInterestedEmail,
							})
						: isGen
							? JSON.stringify({
									'Ask for resume attachment': checkedGeneral,
									'Resume required': this.state.attachGeneralRequired,
								})
							: JSON.stringify({
									'Ask for resume attachment': checked,
									'Resume required': this.state.attachRequired,
								}),
				active: true,
				isCandidate,
				sortOrder: 0,
				isInterested: isIam,
				isGeneral: isGen,
			},
		});
		this.setState({ success: true });
	}

	inputs = {};

	validateForm() {
		const errors = [];
		let data_items = this.props.data;

		if (this.props.display_short) {
			data_items = this.props.data.filter((i) => i.alternateForm === true);
		}

		for (const item of data_items) {
			if (item.element === 'Signature') {
				this._getSignatureImg(item);
			}

			if (this._isInvalid(item)) {
				errors.push(`${item.label} is required!`);
			}

			if (this.props.validateForCorrectness && this._isIncorrect(item)) {
				errors.push(`${item.label} was answered incorrectly!`);
			}
		}

		return errors;
	}

	render() {
		const { success, theme } = this.state;
		let data_items = this.props.data;
		if (this.props.display_short) {
			data_items = this.props.data.filter((i) => i.alternateForm === true);
		}

		for (const item of data_items) {
			if (
				item &&
				item.readOnly &&
				item.variableKey &&
				this.props.variables[item.variableKey]
			) {
				this.answerData[item.field_name] =
					this.props.variables[item.variableKey];
			}
		}

		const items = data_items.map((item) => {
			if (!item) return null;
			switch (item.element) {
				case 'TextInput':
				case 'NumberInput':
				case 'TextArea':
				case 'Dropdown':
				case 'DatePicker':
				case 'RadioButtons':
				case 'Rating':
				case 'Tags':
				case 'Range': {
					return this.getInputElement(item);
				}

				default: {
					return this.getSimpleElement(item);
				}
			}
		});

		const formTokenStyle = {
			display: 'none',
		};

		const buttonSaveClass = this.state.isDisabled
			? SaveDisabled(theme)
			: SaveEnabled(theme);
		const confirmCompliance = get(
			this.props.currentUser,
			'company.confirmCompliance'
		);
		return (
			<>
				<FormValidator emitter={this.emitter} />
				<div className="rfq-modal-body">
					<form
						ref={(c) => (this.form = c)}
						encType="multipart/form-data"
						action={this.props.form_action}
						method={this.props.form_method}
						onSubmit={this.handleSubmit.bind(this)}
					>
						{this.props.authenticity_token && (
							<div style={formTokenStyle}>
								<input name="utf8" type="hidden" value="&#x2713;" />
								<input
									name="authenticity_token"
									type="hidden"
									value={this.props.authenticity_token}
								/>
								<input
									name="task_id"
									type="hidden"
									value={this.props.task_id}
								/>
							</div>
						)}
						{items}
						<div className="text-center">
							{!this.props.hide_save && (
								<Button
									type="primary"
									htmltype="submit"
									disabled={this.state.isDisabled}
									className="ant-btn ant-btn-primary ant-btn-lg mb-2"
									value="Save"
									onClick={this.handleSubmit.bind(this)}
								>
									Save
								</Button>
							)}
							{success && (
								<Alert
									message="Your questions are successfully submitted!"
									type="success"
								/>
							)}
						</div>
					</form>
				</div>
			</>
		);
	}
}

ReactForm.defaultProps = { validateForCorrectness: false };
export default withApollo(ReactForm);
