import { createContext, useContext } from 'react';
import { useRnRAPIService } from '../../rewardsAndRecognitionHelper/service/RnRAPI.service.jsx';
import { ALL_REWARDS_ENDPOINTS } from '../constants/AllRewards.constants.js';

const AllRewardsServiceContext = createContext(null);

export const AllRewardsServiceProvider = (props) => {
  const APIService = useRnRAPIService();

  const getAllOpenNominations = () => {
    return APIService.get(ALL_REWARDS_ENDPOINTS.GET_ALL_OPEN_NOMINATIONS);
  }

  const getAllSubmittedNominations = () => {
    return APIService.get(ALL_REWARDS_ENDPOINTS.GET_ALL_SUBMITTED_NOMINATION);
  }

  const getEligibleEmployeeList = (id) => {
    return APIService.get(ALL_REWARDS_ENDPOINTS.GET_ELIGIBLE_EMPLOYEES + id);
  }

  const nominateEmployee = (body) => {
    return APIService.post(ALL_REWARDS_ENDPOINTS.NOMINATE_EMPLOYEE, body);
  }

  const AllRewardsServiceOperations = {
    getAllOpenNominations,
    getAllSubmittedNominations,
    getEligibleEmployeeList,
    nominateEmployee,
  };

  return (
    <AllRewardsServiceContext.Provider
      value={AllRewardsServiceOperations}
    >
      {props.children}
    </AllRewardsServiceContext.Provider>
  );
};

export const useAllRewardsService = () => {
  return useContext(AllRewardsServiceContext);
};