import { Button, Input, Modal, message, Popconfirm, Upload } from 'antd';
import { QuestionCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { WebIcon } from 'src/_shared/index.js';
import { COLORS } from 'src/_shared/styles/colors';
import backgroundDefault from 'src/_shared/assets/banner-bg.jpeg';
import gql from 'graphql-tag';
import Cookies from 'js-cookie';
import { get } from 'lodash';
import mixpanel from 'mixpanel-browser';
import { Component } from 'react';
import { withApollo } from 'react-apollo';
import ReactGA from 'react-ga';
import { getUserById } from 'src/_shared/api/graphql/custom/users/';
import { getCompanyCustomDashboardValues } from 'src/_shared/api/graphql/custom/company/companyByIdCustomDashboardValuesGraphql.js';
import Spinner from 'src/_shared/components/spinner/SpinnerComponent.jsx';
import {
	downloadFromS3Signed,
	uploadToS3Multipart,
	logout,
	ml,
} from '../../_shared/services/utils.js';
import announcements from '../../_shared/assets/announcements.png';
import hotjobs from '../../_shared/assets/hotjobs.png';
import internal from '../../_shared/assets/internal.png';
import jobalerts from '../../_shared/assets/jobalerts.png';
import jobs from '../../_shared/assets/jobs.png';
import jobsforyou from '../../_shared/assets/jobsforyou.png';
import leaderboard from '../../_shared/assets/leaderboard.png';
import points from '../../_shared/assets/points.png';
import policy from '../../_shared/assets/policy.png';
import ranking from '../../_shared/assets/ranking.png';
import refer from '../../_shared/assets/refer.png';
import generalreferral from '../../_shared/assets/submit-general-refferal.png';
import referrallead from '../../_shared/assets/submit-refferal-lead.png';
import referrals from '../../_shared/assets/yourreferrals.png';
import { GradientLabel } from '../../_shared/components';
import { DragDropDashboard } from './drag-and-drop-interface';

function beforeUpload(file) {
	const correctType = file.type === 'image/png' || file.type === 'image/jpeg';
	if (!correctType) {
		message.error('You can only upload png or jpeg files!');
	}

	const isLt2M = file.size / 1024 / 1024 < 2;
	if (!isLt2M) {
		message.error('Image must smaller than 2MB!');
	}

	return correctType && isLt2M;
}

class EmployeeDashboardSettingsComponent extends Component {
	constructor(props) {
		super(props);
		const modules = [
			{
				id: '1001',
				image: refer,
				name: 'Refer Someone',
			},
			{
				id: '1002',
				image: internal,
				name: 'Internal Mobility',
			},
			{
				id: '1003',
				image: referrals,
				name: 'Your Referrals',
			},
			{
				id: '1004',
				image: ranking,
				name: 'Referrals Made Ranking',
			},
			{
				id: '1005',
				image: points,
				name: 'Points',
			},
			{
				id: '1006',
				image: leaderboard,
				name: 'Leaderboards',
			},
			{
				id: '1007',
				image: jobs,
				name: 'Jobs Overview',
			},
			{
				id: '1008',
				image: announcements,
				name: 'Announcements',
			},
			{
				id: '1009',
				image: policy,
				name: 'Policy',
			},
			{
				id: '1010',
				image: jobsforyou,
				name: 'Jobs For You',
			},
			{
				id: '1011',
				image: hotjobs,
				name: 'Hot Jobs',
			},
			{
				id: '1012',
				image: generalreferral,
				name: 'General Referral',
			},
			{
				id: '1013',
				image: referrallead,
				name: 'Referral Lead',
			},
			{
				id: '1014',
				image: jobalerts,
				name: 'Job Alerts',
			},
		];
		const possibleIds = modules
			.filter((object) => !object.id.includes('1009')) // Preventing Policy tile from appearing in grid, is set from checkbox instead
			.map((object) => object.id);
		let orderedActiveIds = [];
		let unorderedInactiveIds = [];
		let employeeDashboard = get(
			props,
			'currentUser.company.employeeDashboard',
			[]
		);
		employeeDashboard =
			employeeDashboard && employeeDashboard.length > 0
				? JSON.parse(employeeDashboard)
				: [];
		if (employeeDashboard.length > 0) {
			orderedActiveIds = employeeDashboard
				.filter((tile) => tile.id !== '1009')
				.map((tile) => tile.id);
		}

		unorderedInactiveIds = possibleIds.filter(
			(id) => !orderedActiveIds.includes(id)
		);
		const initialItems = {
			A: orderedActiveIds,
			B: unorderedInactiveIds,
		};
		let enableDashboardPolicy = false;
		if (employeeDashboard.find((x) => x.id === '1009')) {
			enableDashboardPolicy = true;
		}

		this.state = {
			currentItems: [],
			customText: {},
			dashboardSearchBG: this.props?.company?.dashboardSearchBG,
			dashboardSearchBGChanged: false,
			dashboardSearchBGUrl: '',
			enableDashboardPolicy,
			enableDashboardSearch: get(props, 'company.enableDashboardSearch', false),
			hideJobsPage: get(props, 'company.hideJobsPage', false),
			initialItems,
			isModalVisible: false,
			hexColors: {
				1001: this.props?.company?.sendReferralColor || '#E75DA7',
				1002: this.props?.company?.applyInternallyColor || '#14D6D3',
				1012: this.props?.company?.generalReferralColor || '#7942E7',
				1013: this.props?.company?.referralLeadColor || '#F7A34D',
			},
			modules,
			tilesText: {
				1001: {
					headerText:
						this.props?.company?.customText?.referralCardHeader ?? 'REFER',
					subheaderText:
						this.props?.company?.customText?.referralCardSubheader ??
						'YOUR FRIENDS',
					buttonText:
						this.props?.company?.customText?.referralCardButton ??
						'SEND A REFERRAL',
				},
				1002: {
					headerText:
						this.props?.company?.customText?.internalCardHeader ?? 'APPLY',
					subheaderText:
						this.props?.company?.customText?.internalCardSubheader ??
						'& GROW YOUR CAREER',
					buttonText:
						this.props?.company?.customText?.internalCardButton ??
						'APPLY INTERNALLY',
				},
				1012: {
					headerText:
						this.props?.company?.customText?.generalCardHeader ?? 'SUBMIT',
					subheaderText:
						this.props?.company?.customText?.generalCardSubheader ??
						'A GENERAL REFERRAL',
					buttonText:
						this.props?.company?.customText?.generalCardButton ??
						'SUBMIT REFERRAL',
				},
				1013: {
					headerText:
						this.props?.company?.customText?.leadCardHeader ?? 'SUBMIT',
					subheaderText:
						this.props?.company?.customText?.leadCardSubheader ??
						'A REFERRAL LEAD',
					buttonText:
						this.props?.company?.customText?.leadCardButton ??
						'SUBMIT REFERRAL',
				},
			},
		};
	}

	async componentDidMount() {
		await this.getAndSetCompanyDashboardValues();
		const host = window.location.hostname;
		if (host === 'referrals.aus.com') {
			ReactGA.initialize('UA-128630809-2');
			ReactGA.pageview(window.location.pathname + window.location.search);
		} else if (host === 'app.erinapp.com') {
			ReactGA.initialize('UA-128630809-3');
			ReactGA.pageview(window.location.pathname + window.location.search);
		}

		await this.getUserDataById();
		const jwt = Cookies.get('jwt');
		const { resultData } = this.state;
		if (resultData !== '' && resultData !== undefined) {
			const expirationDoneByToken = get(
				resultData,
				'expirationDoneByToken',
				null
			);
			const expires = get(resultData, 'expires');
			if (jwt !== undefined && jwt !== expirationDoneByToken && expires) {
				logout(this.props.client);
			}
		}
	}

	setHexColors = (newHexColors) => {
		this.setState({ hexColors: newHexColors });
	};

	setTilesText = (newTilesText) => {
		this.setState({ tilesText: newTilesText });
	};

	handleOpen = () => {
		this.setState({
			isModalVisible: true,
		});
	};

	handleClose = () => {
		this.setState({
			isModalVisible: false,
		});
	};

	editIcon = () => (
		<div className="edit-banner">
			<Button type="link" onClick={() => this.handleOpen()}>
				<WebIcon name="edit" color={COLORS.blue} />
				Edit
			</Button>
		</div>
	);

	async getUserDataById(policy = 'network-only') {
		const { client, currentUser } = this.props;
		try {
			const userId = get(currentUser, 'id', null);
			if (userId !== null) {
				const { data } = await client.query({
					query: gql(getUserById),
					variables: {
						id: userId,
					},
					fetchPolicy: policy,
				});
				const result = get(data, 'getUser', null);
				this.setState({
					resultData: result,
				});
			}
		} catch (error) {
			console.log(error);
		}
	}

	async getAndSetCompanyDashboardValues(policy = 'network-only') {
		const { client, currentUser } = this.props;
		try {
			const companyId = get(currentUser, 'company.id', null);
			if (companyId !== null) {
				const { data } = await client.query({
					query: getCompanyCustomDashboardValues,
					variables: {
						id: companyId,
					},
					fetchPolicy: policy,
				});
				const result = get(data, 'getCompany', null);
				let customText;
				if (typeof result.customText === 'string') {
					customText = JSON.parse(result.customText);
				}

				const dashboardSearchBGKey = result.dashboardSearchBG?.key;
				const dashboardSearchBGUrl = dashboardSearchBGKey
					? await downloadFromS3Signed(
							dashboardSearchBGKey,
							result.dashboardSearchBG.bucket
						)
					: backgroundDefault;
				this.setState({
					customText: customText ?? {},
					dashboardSearchBG: result?.dashboardSearchBG,
					enableDashboardSearch: result?.enableDashboardSearch ?? false,
					dashboardSearchBGUrl,
					hideJobsPage: result?.hideJobsPage ?? false,
					hexColors: {
						1001: result?.sendReferralColor || '#E75DA7',
						1002: result?.applyInternallyColor || '#14D6D3',
						1012: result?.generalReferralColor || '#7942E7',
						1013: result?.referralLeadColor || '#F7A34D',
					},
					tilesText: {
						1001: {
							headerText: customText?.referralCardHeader ?? 'REFER',
							subheaderText:
								customText?.referralCardSubheader ?? 'YOUR FRIENDS',
							buttonText: customText?.referralCardButton ?? 'SEND A REFERRAL',
						},
						1002: {
							headerText: customText?.internalCardHeader ?? 'APPLY',
							subheaderText:
								customText?.internalCardSubheader ?? '& GROW YOUR CAREER',
							buttonText: customText?.internalCardButton ?? 'APPLY INTERNALLY',
						},
						1012: {
							headerText: customText?.generalCardHeader ?? 'SUBMIT',
							subheaderText:
								customText?.generalCardSubheader ?? 'A GENERAL REFERRAL',
							buttonText: customText?.generalCardButton ?? 'SUBMIT REFERRAL',
						},
						1013: {
							headerText: customText?.leadCardHeader ?? 'SUBMIT',
							subheaderText: customText?.leadCardSubheader ?? 'A REFERRAL LEAD',
							buttonText: customText?.leadCardButton ?? 'SUBMIT REFERRAL',
						},
					},
				});
			}
		} catch (error) {
			console.log(error);
			message.error(
				'Failed to retrieve customization data, please try refreshing the page.'
			);
		}
	}

	generateModulesData = (items) => {
		const orderedActiveIds = items.A;
		const tileNames = this.state.modules.map((object) => object.name);
		const imgPaths = this.state.modules.map((object) => object.image);
		let modulesData = [];

		modulesData = orderedActiveIds.map((id, index) => {
			const indexFromId = Number.parseInt(id - 1001);
			return {
				id,
				image: imgPaths[indexFromId],
				name: tileNames[indexFromId],
				order: index + 1,
			};
		});

		// Adding back policy tile to modules if box is checked, needed for mobile compatibility
		if (this.state.enableDashboardPolicy) {
			modulesData.push({
				id: '1009',
				image: imgPaths[8],
				name: tileNames[8],
				order: modulesData.length + 1,
			});
		}

		return modulesData;
	};

	handleCurrentItems = (currentItems) => {
		this.setState({ currentItems });
	};

	handleEnableDashboardSearch = (value) => {
		this.setState({
			enableDashboardSearch: value.target.checked,
		});
	};

	handleEnableDashboardPolicy = (value) => {
		this.setState({
			enableDashboardPolicy: value.target.checked,
		});
	};

	handleSubmit = async (e) => {
		e.preventDefault();
		const { company, onUpdateCompany, updateCurrentUserCompany } = this.props;
		const items = this.state.currentItems;
		const { tilesText, dashboardSearchBGChanged, dashboardSearchBG } =
			this.state;
		const customText = {
			// If we add more values to customText later, this spread becomes needed
			...this.state.customText,
			referralCardHeader: tilesText[1001].headerText,
			referralCardSubheader: tilesText[1001].subheaderText,
			referralCardButton: tilesText[1001].buttonText,
			internalCardHeader: tilesText[1002].headerText,
			internalCardSubheader: tilesText[1002].subheaderText,
			internalCardButton: tilesText[1002].buttonText,
			generalCardHeader: tilesText[1012].headerText,
			generalCardSubheader: tilesText[1012].subheaderText,
			generalCardButton: tilesText[1012].buttonText,
			leadCardHeader: tilesText[1013].headerText,
			leadCardSubheader: tilesText[1013].subheaderText,
			leadCardButton: tilesText[1013].buttonText,
		};

		try {
			const modulesData = this.generateModulesData(items);
			const input = {
				input: {
					id: company.id,
					employeeDashboard: JSON.stringify(modulesData),
					enableDashboardSearch: this.state.enableDashboardSearch,
					sendReferralColor: this.state.hexColors[1001],
					applyInternallyColor: this.state.hexColors[1002],
					generalReferralColor: this.state.hexColors[1012],
					referralLeadColor: this.state.hexColors[1013],
					customText: JSON.stringify(customText),
				},
			};
			input.input.dashboardSearchBG = dashboardSearchBGChanged
				? dashboardSearchBG
				: this.props?.company?.dashboardSearchBG;
			const responseForStoreUpdate = await onUpdateCompany(input);
			updateCurrentUserCompany(responseForStoreUpdate.data.updateCompany);
			message.success('Changes Saved');
			mixpanel.track('Employee Dashboard Updated', {
				'Employee Dashboard Count': modulesData.length,
			});
		} catch {
			message.error(
				'Some of your changes failed to save, please refresh the page and review'
			);
		}
	};

	render() {
		const { company, currentUser, allMultiLingualData } = this.props;
		const { dashboardSearchBG, dashboardSearchBGUrl } = this.state;
		const translatedTileNames = this.state.modules.map((object) =>
			ml(object.name, currentUser, allMultiLingualData)
		);

		const fileListDashboardSearchBG = dashboardSearchBG
			? [
					{
						uid: '-1',
						name: dashboardSearchBG.key,
						status: 'done',
						url: dashboardSearchBGUrl,
					},
				]
			: [];

		if (!company) return <Spinner />;

		return (
			<main>
				<div className="page-title">
					<h2 className="page-heading">
						{ml('Employee Dashboard', currentUser, allMultiLingualData)}
					</h2>
					<ul className="info-action">
						<li>
							<Button
								type="primary"
								size="large"
								htmlType="submit"
								onClick={this.handleSubmit}
							>
								{ml('Update', currentUser, allMultiLingualData)}
							</Button>
						</li>
					</ul>
				</div>
				<div className="setting-card">
					<h4 className="setting-card-title">
						{ml('Employee Dashboard Options', currentUser, allMultiLingualData)}
					</h4>
					{!this.state.hideJobsPage && (
						<div className="custom-form-group custom-checkbox left-check">
							<input
								id="enableEmployeeDashboardSearch"
								type="checkbox"
								checked={this.state.enableDashboardSearch}
								onChange={this.handleEnableDashboardSearch}
							/>
							<label htmlFor="enableEmployeeDashboardSearch">
								{ml(
									'Enable Employee Dashboard Job Search',
									currentUser,
									allMultiLingualData
								)}
							</label>
							<GradientLabel gradientText="Beta" />
						</div>
					)}
					<div className="custom-form-group custom-checkbox left-check">
						<input
							id="enableEmployeeDashboardPolicy"
							type="checkbox"
							checked={this.state.enableDashboardPolicy}
							onChange={this.handleEnableDashboardPolicy}
						/>
						<label htmlFor="enableEmployeeDashboardPolicy">
							{ml(
								'Enable Employee Dashboard Policy',
								currentUser,
								allMultiLingualData
							)}
						</label>
					</div>
					{this.state.enableDashboardSearch && (
						<>
							<h4 className="setting-card-title">
								Employee Dashboard Search Preview
							</h4>
							<div
								className="internal-banner"
								style={{ margin: 0, width: '100%' }}
							>
								<img
									src={this.state.dashboardSearchBGUrl}
									className="banner-img"
								/>
								{this.editIcon()}
								<div className="banner-content">
									<div className="c-left">
										<div className="page-title">
											<h2
												className="page-heading"
												style={{ marginLeft: '-0.04em', color: '#fff' }}
											>
												Hello, {currentUser.firstName}
											</h2>
										</div>
										<p>
											Find jobs at{' '}
											<strong style={{ display: 'inline-block' }}>
												{company.name}
											</strong>
										</p>
										<form className="search-input">
											<Input
												disabled
												placeholder="Search by location, job name and/or keywords"
											/>
											<Button disabled className="search-icon">
												<SearchOutlined />
											</Button>
										</form>
									</div>
								</div>
							</div>
						</>
					)}
				</div>
				<DragDropDashboard
					vertical
					columns={3}
					items={this.state.initialItems}
					handleCurrentItems={this.handleCurrentItems}
					hexColors={this.state.hexColors}
					setHexColors={this.setHexColors}
					tilesText={this.state.tilesText}
					setTilesText={this.setTilesText}
					activeText={ml('Active Tiles', currentUser, allMultiLingualData)}
					inactiveText={ml('Inactive Tiles', currentUser, allMultiLingualData)}
					translatedTileNames={translatedTileNames}
				/>
				<Modal
					open={this.state.isModalVisible}
					footer={null}
					onCancel={() => this.handleClose()}
				>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							marginBottom: '0.5rem',
						}}
					>
						<h1 style={{ marginBottom: 0, fontSize: 16 }}>Background Image</h1>{' '}
						&nbsp;
						<span style={{ fontSize: '12px', color: COLORS.gray }}>
							(Optimal image size: 1500x300)
						</span>
					</div>
					<div className="custom-upload-picture">
						<Upload
							showUploadList
							name="dashboard-search-bg"
							listType="picture-card"
							className={
								// Hides upload button
								fileListDashboardSearchBG.length > 0 && 'hide-upload-btn'
							}
							customRequest={async ({ file, onSuccess, onError }) => {
								try {
									const bucket = 'erin-misc';
									const key = `dashboardSearchBG/${company.id}/${file.name}`;
									await uploadToS3Multipart(file, key, bucket);
									const presignedURL = await downloadFromS3Signed(key, bucket);
									if (presignedURL) {
										onSuccess(presignedURL);
										this.setState({
											dashboardSearchBG: {
												bucket,
												key,
												region: 'us-east-2',
											},
											dashboardSearchBGChanged: true,
											dashboardSearchBGUrl: presignedURL,
										});
									}
								} catch (error) {
									onError(error);
								}
							}}
							beforeUpload={beforeUpload}
							multiple={false}
							disabled={fileListDashboardSearchBG.length > 0}
							fileList={fileListDashboardSearchBG}
						>
							<div className="upload-picture-btn">
								<i className="icon-plus" />
								<p className="upload-picture-text">Upload</p>
							</div>
						</Upload>
						{dashboardSearchBG && (
							<Popconfirm
								title="Are you sure？"
								placement="right"
								icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
								onConfirm={() =>
									this.setState({
										dashboardSearchBG: null,
										dashboardSearchBGChanged: true,
										dashboardSearchBGUrl: backgroundDefault,
									})
								}
							>
								<span className="cancel-picture-btn">
									<i className="icon-bin" />
									Remove
								</span>
							</Popconfirm>
						)}
					</div>
				</Modal>
			</main>
		);
	}
}

export default withApollo(EmployeeDashboardSettingsComponent);
