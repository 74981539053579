import { FolderOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import { cx } from 'emotion';
import gql from 'graphql-tag';
import _ from 'lodash';
import get from 'lodash/get';
import { Component } from 'react';
import { withApollo } from 'react-apollo';
import { queryReferralQuestionsByCompanyId } from 'src/_shared/api/graphql/custom/referral-questions/';
import WebIcon from 'src/_shared/components/WebIconComponent.jsx';
import Spinner from 'src/_shared/components/spinner/SpinnerComponent.jsx';
import { ml, parse, searchData } from '../services/utils';
import { COLORS } from '../styles/colors';
import { ReferralRequestForm } from './referral-request-modal-form';
import * as ModalStyles from './referralRequestModalStyles.js';

class ReferralRequestModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			company: get(props, 'currentUser.company'),
			autoCompleteResult: [],
			isSubmitting: false,
			newContact: true,
			error: false,
			contact: props.contact,
			referralType: 'email',
			visible: props.visible,
			buttonState: '',
			isComplianceSubmitting: false,
			theme: JSON.parse(get(props, 'companyData.theme', '{}')),
			referralQuestions: [],
			resumeAttachData: '',
			errorMessage: null,
			extendedNetworkUsers: get(this.props, 'extendedNetworkUsers', []),
		};
	}

	async componentDidMount() {
		if (this.props.matchFound) {
			this.setState({ autoCompleteResult: [this.props.matchFound] });
		}

		if (this.props.contact) {
			this.setState({ autoCompleteResult: [this.props.contact] });
		}

		this.getReferralQuestions();
	}

	async getReferralQuestions(
		policy = 'network-only',
		nextToken = null,
		allQuestions = []
	) {
		const { client, currentUser } = this.props;
		try {
			const { data } = await client.query({
				query: gql(queryReferralQuestionsByCompanyId),
				variables: {
					companyId: get(currentUser, 'companyId'),
					after: nextToken,
				},
				fetchPolicy: policy,
			});
			const questions = [...data.queryReferralQuestionsByCompanyId.items];
			const token = get(data, 'queryJobsByCompanyIdDateIndex.nextToken', null);
			allQuestions = [...allQuestions, ...questions];
			let allQuestionsSort = _.sortBy(allQuestions, ['sortOrder']);
			const attachmentResumeQuestion = allQuestionsSort.filter(
				(item) => item.sortOrder === 0
			);
			allQuestionsSort = allQuestionsSort.filter(
				(item) => item.sortOrder !== 0
			);
			const allQuestionsForEmployee = allQuestionsSort.filter(
				(item) => item.isCandidate === false && item.isInterested !== true
			);
			const questionsData = [];
			if (allQuestionsForEmployee.length > 0) {
				allQuestionsForEmployee.map((element) => {
					questionsData.push(JSON.parse(element.questions));
				});
			}

			this.setState({
				referralQuestions: questionsData,
				resumeAttachData: attachmentResumeQuestion,
			});
			if (token) {
				this.getReferralQuestions(policy, token, allQuestions);
			}
		} catch (error) {
			console.log(error);
		}
	}

	changeReferralType = (referralType) => {
		this.setState({ referralType });
	};

	changeReferralTypeCompliance = (referralType) => {
		this.setState({ referralType });
	};

	handleCancelAll = () => {
		this.setState({ visible: false });
		this.props.handleCancel();
	};

	handleContactChange = (value, contact) => {
		let autoCompleteResult = [];
		if (contact) {
			autoCompleteResult = [contact];

			this.setState({ contact });
		} else if (value) {
			autoCompleteResult = searchData(
				['firstName', 'lastName'],
				value,
				this.props.contacts
			);
		} else {
			autoCompleteResult = [];
		}

		this.setState({ autoCompleteResult });
	};

	handleError = (errorMessage = null) => {
		this.setState({ error: true, errorMessage });
		if (this.state.buttonState === '') {
			this.setState({ buttonState: 'error' });
		} else {
			this.setState({ buttonState: '' });
		}
	};

	handleNewContact = (contact) => {
		if (contact) this.handleContactChange(null, contact);

		this.setState((prevState) => ({ newContact: !prevState.newContact }));
	};

	searchExtendedNetworkUsers = (query) => {
		return searchData(
			['firstName', 'lastName'],
			query,
			get(this.props, 'extendedNetworkUsers')
		);
	};

	toggleIsSubmitting = () => {
		this.setState({ buttonState: 'loading' });
		this.setState((prevState) => ({ isSubmitting: !prevState.isSubmitting }));
		setTimeout(() => {
			this.setState({ buttonState: 'success' });
		}, 4500);
	};

	toggleIsSubmittingCompliance = () => {
		this.setState({ buttonState: 'loading' });
		this.setState((prevState) => ({
			isComplianceSubmitting: !prevState.isComplianceSubmitting,
		}));
		setTimeout(() => {
			this.setState({ buttonState: 'success' });
		}, 2500);
	};

	render() {
		const {
			allMultiLingualData,
			job,
			contacts,
			nextToken,
			referrals,
			visible,
			handleCancel,
			onCreateContact,
			onCreateReferral,
			onCreateWebNotification,
			currentUser,
			onUpdateNotification,
			currentTieredBonus,
			onUpdateContact,
			companyData,
			client,
		} = this.props;

		const {
			company,
			autoCompleteResult,
			contact,
			referralType,
			newContact,
			isSubmitting,
			error,
			errorMessage,
			extendedNetworkUsers,
			buttonState,
			isComplianceSubmitting,
			theme,
			referralQuestions,
			resumeAttachData,
		} = this.state;

		let referralBonus = get(this.props, 'job.referralBonus');
		const location = get(this.props, 'job.job.location');
		const locations = parse(get(this.props, 'job.job.locations', get(this.props, 'job.locations', [])));
		if (typeof referralBonus === 'string') {
			referralBonus = JSON.parse(referralBonus);
		}

		const currentCurrencyRate = get(this.props, 'currentCurrencyRate', 1);
		const currentCurrencySymbol = get(this.props, 'currentCurrencySymbol', '$');

		let toggleClass;
		const isCompliance = get(currentUser.company, 'confirmCompliance', false);
		if (isCompliance) {
			toggleClass = isComplianceSubmitting
				? ModalStyles.ModalStylesSubmitting
				: ModalStyles.ModalStyles;
		} else {
			toggleClass = isSubmitting
				? ModalStyles.ModalStylesSubmitting
				: ModalStyles.ModalStyles;
		}

		let userGroupCurrency = get(currentUser, 'userGroup.currency');
		if (userGroupCurrency === null) userGroupCurrency = 'USD';
		return job ? (
			<Modal
				className={toggleClass}
				open={visible}
				footer={null}
				onCancel={this.handleCancelAll}
			>
				{nextToken ? (
					<Spinner company={company} />
				) : (
					<div>
						<h1 className={ModalStyles.ModalTitle(theme)}>
							Ask For A Referral
						</h1>
						<p
							className={ModalStyles.SmallText}
							style={{
								fontSize: 15,
								width: '80%',
								margin: 'auto',
								marginBottom: 10,
							}}
						>
							Choose a connection below to ask them to refer you for this
							position.
						</p>
						<div className={ModalStyles.JobInfoContainer}>
							<div className={cx(ModalStyles.Flex, ModalStyles.JobInfo)}>
								<div>
									<h3 className={ModalStyles.JobTitle}>{job.title}</h3>
									<div className={ModalStyles.Flex}>
										<div
											className={cx(
												ModalStyles.Flex,
												ModalStyles.addPaddingRight
											)}
										>
											<FolderOutlined className={ModalStyles.JobIcon} />
											<p className={ModalStyles.Flex}>
												{job.department ? job.department.name : ''}
											</p>
										</div>
										<div className={ModalStyles.Flex}>
											<WebIcon
												name="placeholder"
												size={20}
												color={COLORS.darkGray}
											/>
											<p className={ModalStyles.Flex}>
										{locations?.length > 1
											? ml(
													'Multiple Locations',
													currentUser,
													allMultiLingualData
												)
											: location?.isRemote === true
												? ml('Remote', currentUser, allMultiLingualData)
												: [location?.city, location?.state]
														.filter(Boolean)
														.join(', ') ||
													location?.country ||
													''}
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>

						<ReferralRequestForm
							allMultiLingualData={allMultiLingualData}
							client={client}
							extendedNetworkUsers={extendedNetworkUsers}
							addNewWebNotification={this.props.addNewWebNotification}
							searchExtendedNetworkUsers={this.searchExtendedNetworkUsers}
							newContact={newContact}
							handleNewContact={this.handleNewContact}
							contacts={contacts}
							changeReferralType={this.changeReferralType}
							referralType={referralType}
							handleContactChange={this.handleContactChange}
							autoCompleteResult={autoCompleteResult}
							isSubmitting={isSubmitting}
							toggleIsSubmitting={this.toggleIsSubmitting}
							handleCancel={handleCancel}
							handleCancelAll={this.handleCancelAll}
							error={error}
							errorMessage={errorMessage}
							handleError={this.handleError}
							currentUser={currentUser}
							visible={visible}
							job={job}
							matchFound={this.props.matchFound}
							contact={contact}
							notification={this.props.notification}
							referrals={referrals}
							currentCurrencyRate={currentCurrencyRate}
							currentCurrencySymbol={currentCurrencySymbol}
							companyData={companyData}
							buttonState={buttonState}
							requestingNotifications={get(
								this.props,
								'requestingNotifications'
							)}
							currentTieredBonus={currentTieredBonus}
							userGroupCurrency={userGroupCurrency}
							referralQuestions={referralQuestions}
							resumeAttachData={resumeAttachData}
							onCreateContact={onCreateContact}
							onCreateReferral={onCreateReferral}
							onCreateWebNotification={onCreateWebNotification}
							onUpdateNotification={onUpdateNotification}
							onUpdateContact={onUpdateContact}
						/>
					</div>
				)}
			</Modal>
		) : null;
	}
}

export default withApollo(ReferralRequestModal);
