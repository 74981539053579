import React, { useEffect, useState } from 'react';
import RewardPointsAndBonus from './../../presentation/rewardPointsAndBonus/RewardPointsAndBonus.jsx';
import { message } from 'antd';
import { useHomeService } from '../../service/home.services.jsx';
const RewardPointsAndBonusContainer = () => {
	const [rewardPointsBonus, setRewardPointsAndBonus] = useState();
	const homeService = useHomeService()

	useEffect(() => {
		getRewardPointsAndBonus();
	}, []);

	const getRewardPointsAndBonus = () => {

		homeService
			.getReward()
			.then((response) => {
				if (response?.code === 200)
					if (response.data) {
						setRewardPointsAndBonus(response?.data);
					} else {
						message.error('Fail to load earned points and bonus points');
					}
			})
			.catch((error) => {
				console.log(error);
				message.error('Fail to load earned points and bonus points');
			});
	};

	return (
		<>
			<RewardPointsAndBonus rewardPointsBonus={rewardPointsBonus} />
		</>
	);
};

export default RewardPointsAndBonusContainer;
