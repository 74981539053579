import { Popover } from 'antd';
import { Component } from 'react';

export default class ContactInviteStatus extends Component {
	constructor(props) {
		super(props);
		this.state = {
			inviteStatus: this.props.inviteStatus,
			record: this.props.record,
		};
	}

	render() {
		const template = [];
		const { inviteStatus, record } = this.state;

		if (inviteStatus === 'accepted') {
			template.push(
				<span key={record.id} className="text-dark-gray">
					Accepted
				</span>
			);
		} else if (inviteStatus === 'pending') {
			template.push(
				<span key={record.id} className="text-gray">
					Invited
				</span>
			);
		} else {
			template.push(
				<Popover
					key={record.id}
					placement="topLeft"
					title="Not Invited"
					content="This user was not invited to join your network."
				>
					<span key={record.id} className="text-blue">
						Not Invited
					</span>
				</Popover>
			);
		}

		return <>{template}</>;
	}
}
