import { css } from 'emotion';
import { COLORS } from '../_shared/styles/colors';

export const contactBtn = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 200,
	color: `${COLORS.white} !important`,
	background: `${COLORS.blue} !important`,
	fontSize: 14,
	marginBottom: 5,
	marginTop: 5,
	paddingBottom: 5,
	paddingRight: 10,
	paddingLeft: 30,
	paddingTop: 20,
	display: 'flex',
	lineHeight: '8px',
	height: 50,
	width: 220,
	textAlign: 'center',
});

export const EmployeeEmail = css({
	fontSize: 16,
	fontWeight: '600 !important',
});

export const ReferralDetailsContainer = css({
	width: '100%',
	height: '100%',
	padding: '20px 0 40px 40px',
	backgroundColor: COLORS.lightGray2,
	marginBottom: 0,
	maxWidth: 1300,
});

export const LandingBackground = css({
	color: 'white',
	width: '-webkit-fill-available',
	height: '100%',
	position: 'absolute',
	backgroundColor: COLORS.darkBlue,
});

export const FlexContainer = css({
	display: 'flex',
	marginBottom: 30,
	width: '100%',
	paddingTop: 20,
});

export const CardStyles = css({
	boxShadow: '1px 1px 15px rgba(0,0,0,0.25)',
	borderRadius: 2,
	padding: 20,
	backgroundColor: 'white',
	height: 'auto',
});

export const CardProfile = css({
	width: '100%',
	marginRight: 15,
	height: '100%',
});

export const Card2Styles = css({
	width: '30%',
	marginLeft: 15,
	height: 200,
	minWidth: 300,
});

export const link = css({
	fontFamily: '"Open Sans", sans-serif',
	color: COLORS.blue,
});

export const BackLink = css({
	fontFamily: '"Open Sans", sans-serif',
	color: COLORS.blue,
	border: 'none',
	backgroundColor: 'transparent',
	paddingLeft: 5,
});

export const BackIcon = css({
	fontSize: 20,
	verticalAlign: 'sub',
});

export const EmployeeName = css({
	marginTop: '-8px',
	marginBottom: '-8px',
	fontSize: 28,
	lineSpacing: 0,
});

export const ReferralSection = css({
	width: 1240,
	marginTop: 10,
});

export const refererName = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 600,
	color: COLORS.blue,
	marginBottom: 0,
	lineHeight: 1,
	paddingBottom: 5,
});
export const outerCardContainer = css({
	backgroundColor: COLORS.white,
	width: '100%',
	height: '74vh',
});

export const cardContainer = css({
	width: '60%',
	margin: 'auto',
	'@media (max-width: 1024px)': {
		width: '80%',
	},
	'@media (max-width: 462px)': {
		width: '100%',
	},
});

export const headerBackground = css({
	backgroundColor: COLORS.darkBlue,
	textAlign: 'center',
});

const applyHighlight = (color) =>
	css({
		background: `${color ? color : COLORS.green} !important`,
		color: '#ffffff !important',
	});

const contactHighlight = css({
	background: '#018dd3 !important',
});

export const divider = css({
	marginLeft: '10px',
	marginRight: '10px',
});

export const listStyle = css({
	listStyleType: 'disc',
});

export const sectionTitle = css({
	marginBottom: 0,
	fontSize: '16px',
	fontFamily: '"Open Sans", sans-serif',
});

export const spinContainer = css({
	width: '100%',
	height: '50vh',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
});

export const logoContainer = css({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	img: {
		width: 230,
	},
});

export const ModalStyles = css({
	'& .ant-modal-content': {
		borderRadius: 20,
	},
	fontFamily: 'Open Sans',
	'& .ant-modal-close-x': {
		fontSize: 30,
		paddingTop: 10,
		color: COLORS.lightGray,
	},
});

export const Card = css({
	fontFamily: '"Open Sans", sans-serif',
	borderRadius: 4,
	backgroundColor: 'white',
	padding: 30,
	marginTop: 30,
	width: '85%',
	maxWidth: 625,
});

export const menuColor = css({
	color: COLORS.blue,
	cursor: 'pointer',
});

export const NoteStyle = css({
	fontSize: 13,
	fontWeight: 600,
	textAlign: 'center',
	color: `${COLORS.darkGray}`,
	paddingTop: 30,
});

export const InfoStyle = css({
	fontSize: 10,
	fontWeight: 200,
	textAlign: 'center',
	color: `${COLORS.gray}`,
	width: '65%',
	height: 'auto',
	margin: '0 auto',
	paddingTop: 30,
});

export const FormTitle = css({
	width: '100%',
	textAlign: 'center',
	fontWeight: 600,
	marginBottom: 15,
	color: COLORS.darkGray,
	fontSize: 24,
});

export const JobInfoContainer = css({
	padding: 20,
	marginTop: 15,
	paddingBottom: 10,
	backgroundColor: COLORS.lightGreen,
	borderRadius: 8,
	marginBottom: 15,
	textAlign: 'center',
	width: '55%',
	height: 'auto',
	margin: '0 auto',
	'@media (max-width: 575px)': {
		width: '100%',
	},
	'@media (max-width: 991px) and (min-width: 768px)': {
		width: '85%',
	},
	'@media (max-width: 1199px) and (min-width: 992px)': {
		width: '65%',
	},
	'@media (max-width: 1580px) and (min-width: 1200px)': {
		width: '55%',
	},
});

export const DetailContainer = css({
	textAlign: 'center',
	width: '55%',
	height: 'auto',
	margin: '0 auto',
	'@media (max-width: 575px)': {
		width: '100%',
	},
	'@media (max-width: 991px) and (min-width: 768px)': {
		width: '85%',
	},
	'@media (max-width: 1199px) and (min-width: 992px)': {
		width: '65%',
	},
	'@media (max-width: 1580px) and (min-width: 1200px)': {
		width: '55%',
	},
});

export const JobTitle = css({
	fontSize: 18,
	color: COLORS.blue,
	marginBottom: 0,
});

export const FormItemStyles = css({
	marginBottom: '0 !important',
});

export const RemoveBottomMargin = css({
	'& .ant-form-item': {
		marginBottom: 0,
	},
});

export const candidateNameStyles = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 600,
	color: COLORS.darkGray,
	color: '#00641F',
	fontSize: '1.75em',
	lineHeight: 1,
	paddingBottom: 5,
});

export const detailsTitle = css({
	fontSize: '18px',
	fontFamily: '"Open Sans", sans-serif',
});

export const Department = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 600,
	color: '#00641F',
	fontSize: 24,
	marginLeft: 5,
	marginRight: 5,
});
export const location = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 400,
	color: COLORS.darkGray,
	fontSize: 18,
	marginRight: 5,
});

export const descriptionContainer = css({
	marginBottom: 15,
});

export const summaryValue = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 200,
	color: COLORS.darkGray,
	fontSize: '1.25em',
});

export const topSpacing = css({
	marginTop: '15px',
});

export const subtitle = css({
	textAlign: 'center',
	color: COLORS.red,
	marginBottom: 0,
	fontFamily: '"Open Sans", sans-serif',
});
