import gql from 'graphql-tag';

export const GetCompanySettings = gql`
	query GetCompany($id: ID!) {
		getCompany(id: $id) {
			id
			accountType
			name
			defaultBonusAmount
			departments {
				id
				companyId
				name
				keywords
				active
				totalUsers
			}
			dashboardReferralPolicyText
			giftCardStoreAPIKeys {
				region
				apiKey
			}
			bonusEarnedNote
			allowSelfReferrals
			allowInternalMobility
			internalMobilityImage {
				bucket
				region
				key
			}
			contactIncentiveBonus
			sendAdminNotificationsOnReferral
			sendAdminNotificationsOnInternalCandidatesFound
			senderEmailAddress
			subCompanies {
				id
				companyId
				keywords
				name
				logo {
					bucket
					region
					key
				}
			}
			referralBonusNotificationRecipients
			brandColor
			websiteUrl
			disableReferrals
			sftpFolderName
			loginFormPickerSettings
			loginFormSettings
			logo {
				bucket
				region
				key
			}
			symbol {
				bucket
				region
				key
			}
			theme
			labelDepartment
			subCompanyLabel
			userGroups {
				id
				companyId
				measurement
				name
				keywords
				active
				currency
				languageCode
			}
			background {
				bucket
				region
				key
			}
			errorImage {
				bucket
				region
				key
			}
			userSignupSettings
			whiteLabel
		}
	}
`;
