import { useState } from "react"
import { message } from "antd"
import { useAllRewardsService } from "../service/AllRewards.service.jsx";

const useGetEmployeeToNominate = (closeModal) => {

    const [nominateEmpList, setNominateEmpList] = useState([])
    const [nominationLoading, setNominationLoading] = useState();
    const allRewardsAPIService = useAllRewardsService();

    const getEligibleEmployeeList = (id) => {
        allRewardsAPIService.getEligibleEmployeeList(id).then((res) => {
            setNominateEmpList(res.data)
        }).catch(() => {
            message.error("No Employee Found eligible for Nomination")
        })
    }

    const nominateEmployeeList = (data) => {
        setNominationLoading(true)
        allRewardsAPIService.nominateEmployee(data).then((response) => {
            if (response.code === 201) {
                closeModal && closeModal()
                message.success("Employee Nominated Successfully")
            } else {
                message.error(response?.message || "Nomination failed! Try again later")
            }
        }).catch((error) => {
            message.error(error?.response?.message || "Nomination with same name already exists!")
        }).finally(() => {
            setNominationLoading(false)
        })
    }

    return { nominateEmpList, nominationLoading, getEligibleEmployeeList, nominateEmployeeList }
}

export default useGetEmployeeToNominate