import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Input, message, Popconfirm, Tooltip } from 'antd';
import { useState } from 'react';
import { lambda, ml } from 'src/_shared/index.js';
import { ConditionalWrapper } from 'src/helpers/index.js';

function SubdomainCreation({ currentUser, allMultiLingualData }) {
	const [createText, setCreateText] = useState(
		currentUser?.company?.host?.match(/.*(?=.erinapp.com)/)?.[0] ?? ''
	);
	const [error, setError] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

	const FormItem = Form.Item;

	const validateSubdomain = (text) => {
		// Check for sequential periods and hyphens
		if (/[.\-]{2,}/.test(text)) {
			return false;
		}

		// Check for subdomain formatting
		// Subdomain must start and end with an alphanumeric character
		// Subdomain may on include letters, numbers, periods, and hyphens
		// Subdomain may not be longer than 62 characters
		const regex = /[A-Za-z\d](?:[A-Za-z\d\-.]{0,61}[A-Za-z\d])?/;

		return (text.match(regex)?.[0]?.length ?? 0) === text.length;
	};

	const handleChange = (e) => {
		setCreateText(e.target.value);

		const isValid = validateSubdomain(e.target.value);
		if (!isValid) {
			setError('Invalid Subdomain Format');
			return;
		}

		setError(null);
	};

	const handleCreateSubdomain = async () => {
		if (!error) {
			try {
				setIsLoading(true);
				const res = await lambda({
					endpoint: 'dns-prod-api',
					variables: {
						userId: currentUser.id,
						companyId: currentUser.company.id,
						subdomain: createText,
					},
				});

				if (res.success) {
					message.success('Your subdomain was successfully created.');
				} else {
					message.error('There was an issue creating your subdomain.');
					setError(res.message);
				}
			} catch (error_) {
				console.error(error_);
				setError(error_.message);
			} finally {
				setIsLoading(false);
			}
		}
	};

	return (
		<>
			<h2 className="setting-heading">
				{ml('Subdomain Creation', currentUser, allMultiLingualData)}
			</h2>

			<div className="setting-card">
				<h4 className="setting-card-title">
					{ml('Create / Assign Subdomain', currentUser, allMultiLingualData)}
				</h4>

				<div className="custom-form-group-with-errors">
					<ConditionalWrapper
						condition={currentUser?.company?.host}
						wrapper={(children) => (
							<Tooltip title="A subdomain is already registered for this company. If you need assistance, contact ERIN developers">
								{children}
							</Tooltip>
						)}
					>
						<FormItem
							validateStatus={error ? 'error' : 'validating'}
							help={error}
						>
							<Input
								placeholder={ml(
									'Enter Subdomain',
									currentUser,
									allMultiLingualData
								)}
								className="custom-input text-blue"
								value={createText}
								disabled={currentUser?.company?.host}
								onChange={(e) => handleChange(e)}
							/>
						</FormItem>
					</ConditionalWrapper>
					<Popconfirm
						title={
							<div>
								<p>
									Are you sure you would like to create the subdomain as{' '}
									<output style={{ fontWeight: 800 }}>{createText}</output>?
								</p>
								<strong>This action cannot be reversed</strong>
							</div>
						}
						okText="Yes"
						cancelText="No"
						disabled={currentUser?.company?.host}
						onConfirm={async () => await handleCreateSubdomain()}
					>
						<ConditionalWrapper
							condition={currentUser?.company?.host}
							wrapper={(children) => (
								<Tooltip title="A subdomain is already registered for this company. If you need assistance, contact ERIN developers">
									{children}
								</Tooltip>
							)}
						>
							<Button
								type="primary"
								size="large"
								htmlType="submit"
								loading={isLoading}
								disabled={currentUser?.company?.host}
							>
								{ml('Create Subdomain', currentUser, allMultiLingualData)}
							</Button>
						</ConditionalWrapper>
					</Popconfirm>
				</div>
			</div>
		</>
	);
}

export default Form.create()(SubdomainCreation);
