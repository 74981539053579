import React from 'react';
import { Table } from 'antd';
import NoDataState from '../../../RnRCommonComponent/noDataState/NoDataState.jsx';

const ApprovalRewardsTable = (props) => {
	const { approvedData, columns, isLoading, emptyMessage } = props;

	return (
		<div className="table-card">
			<Table
				loading={isLoading}
				dataSource={approvedData || []}
				columns={columns}
				locale={{ emptyText: <NoDataState title={emptyMessage} /> }}
				pagination={{ pageSize: 10, showSizeChanger: false }}
			/>
		</div>
	);
};


export default ApprovalRewardsTable;
