import gql from 'graphql-tag';
import get from 'lodash/get';
import { compose, graphql } from 'react-apollo';
import {
	deleteAnnouncement,
	queryAnnouncementsByCompanyIdIndex,
} from '../../graphql/custom/announcements';

export const withDeleteAnnouncement = (Component) => {
	return compose(
		graphql(gql(deleteAnnouncement), {
			props: (props) => ({
				onDeleteAnnouncement(input) {
					props.mutate({
						variables: input,
						update(proxy, query) {
							try {
								const data = proxy.readQuery({
									query: gql(queryAnnouncementsByCompanyIdIndex),
									context: {
										headers: {
											'x-frame-options': 'deny', // This header will reach the server
										},
									},
									variables: {
										companyId: get(
											props,
											['ownProps', 'currentUser', 'companyId'],
											'skip'
										),
									},
								});
								const announcements = get(
									data,
									'queryAnnouncementsByCompanyIdIndex.items',
									[]
								);

								const updatedAnnouncement = get(
									query,
									'data.deleteAnnouncement'
								);

								const index = announcements.findIndex(
									(announcement) => announcement.id === updatedAnnouncement.id
								);

								if (index > -1) {
									announcements.splice(index, 1);
								}

								proxy.writeQuery({
									query: gql(queryAnnouncementsByCompanyIdIndex),
									context: {
										headers: {
											'x-frame-options': 'deny', // This header will reach the server
										},
									},
									variables: {
										companyId: get(
											props,
											['ownProps', 'currentUser', 'companyId'],
											[]
										),
									},
									announcements,
									data,
								});
							} catch (error) {
								console.log(error);
							}
						},
					});
				},
			}),
		})
	)(Component);
};
