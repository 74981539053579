import get from 'lodash/get';
import { connect } from 'react-redux';
import { withGetTieredBonus } from 'src/_shared/api/components/tiered-bonuses';
import { compose } from '../../services/utils';
import ReferralsCardContactsComponent from './ReferralCardContactsComponent.jsx';

const mapStateToProperties = (state, ownProperties) => {
	const { currentUser } = state.user;
	const { allMultiLingualData } = state.dashboard;

	const referralBonus =
		typeof ownProperties.referral.referralBonus === 'string'
			? JSON.parse(ownProperties.referral.referralBonus)
			: ownProperties.referral.referralBonus;

	return {
		allMultiLingualData,
		currentUser,
		tieredBonusQuery: {
			companyId: currentUser.companyId,
			id: get(referralBonus, 'tieredBonusId', 'skip'),
		},
		companyId: currentUser.companyId,
	};
};

const mapDispatchToProperties = () => {
	return {};
};

export const ReferralCardContactsWithApi = compose(withGetTieredBonus)(
	ReferralsCardContactsComponent
);

export default connect(
	mapStateToProperties,
	mapDispatchToProperties
)(ReferralCardContactsWithApi);
