import { Col, Row } from 'antd';
import { Component } from 'react';
import DonutChart from 'react-donut-chart';
import WebIcon from 'src/_shared/components/WebIconComponent.jsx';
import Group from '../../../_shared/assets/group.png';
import PiggyBank from '../../../_shared/assets/piggy-bank.png';
import { borderedTile } from '../../../_shared/components/bordered-tile';
import { COLORS } from '../../../_shared/styles/colors';
import {
	LinkStyles,
	bottomContainer,
	bottomSectionText,
	bottomSectionValue,
	buttonActive,
	buttonFirst,
	chartTotal,
	connectEarn,
	divider,
	donutChart,
	headerClass,
	headerClasses,
	listStyles,
	middleColumn,
	middleContainer,
	numTotal as numberTotal,
	opacity,
} from './connectEarnStyles.js';

class BaseConnectCard extends Component {
	onPrivacyLinkClick = () => {
		this.props.history.push('/myprofile');
	};

	render() {
		const { onClick, currentUser, contacts } = this.props;
		const direct = contacts.filter(
			(contact) =>
				contact.importMethod &&
				(contact.importMethod === 'email' || contact.importMethod === 'mobile')
		).length;
		const social = contacts.length - direct;
		const total = contacts.length;
		const shouldShowYourNetwork = direct !== 0 || social !== 0;
		const shouldShowConnectAndEarn =
			direct === 0 &&
			social === 0 &&
			currentUser.company.contactIncentiveBonus &&
			currentUser.company.contactIncentiveBonus !== '0';
		const shouldShowConnectContacts =
			direct === 0 &&
			social === 0 &&
			(!currentUser.company.contactIncentiveBonus ||
				currentUser.company.contactIncentiveBonus === '0');

		return (
			<div style={{ height: 324 }}>
				{shouldShowYourNetwork ? (
					<div>
						<h2 className={headerClass}>Your Network</h2>
						<Row className={middleContainer}>
							<Col span={24} className={middleColumn}>
								<div className={donutChart}>
									<div className={chartTotal}>
										<div className={numberTotal}>{total}</div>
										<div>People</div>
									</div>
									<DonutChart
										height={153}
										width={140}
										colors={[COLORS.dashboardLightOrange, COLORS.dashboardBlue]}
										strokeColor=""
										legend={false}
										clickToggle={false}
										data={[
											{
												label: 'direct',
												value: Number.parseFloat(direct),
												className: opacity,
											},
											{
												label: 'social',
												value: Number.parseFloat(social),
												className: opacity,
											},
										]}
									/>
								</div>
							</Col>
						</Row>
						<div className={divider} />
						<Row className={bottomContainer}>
							<Row>
								<button className={buttonFirst} />
								<span className={bottomSectionText}> Direct: </span>
								<span className={bottomSectionValue}> {direct} </span>
							</Row>
							<Row>
								<button className={buttonActive} />
								<span className={bottomSectionText}> Social: </span>
								<span className={bottomSectionValue}> {social}</span>
							</Row>
						</Row>
					</div>
				) : null}
				{shouldShowConnectAndEarn ? (
					<div>
						<h2 className={headerClasses}>Connect & Earn More</h2>
						<ul className={listStyles}>
							<li>
								<div style={{ textAlign: 'center', marginBottom: '5px' }}>
									<img
										src={PiggyBank}
										width={85}
										height={85}
										alt="earn and connect"
									/>
								</div>
							</li>
							<li style={{ fontSize: 12 }}>
								Connect your contacts to automatically find people to refer and
								earn higher referral bonuses!
								<strong>
									{' '}
									We never share your contact&#39;s information until you make a
									referral.
								</strong>
							</li>
							<li>
								<button className={connectEarn}>
									<div>Earn an additional</div>
									<div
										style={{
											fontSize: '14px',
											fontWeight: 'bold',
										}}
									>
										${currentUser.company.contactIncentiveBonus}
									</div>
								</button>
								<button className={connectContacts} onClick={onClick}>
									<WebIcon name="network" size={16} color={COLORS.white} />{' '}
									Connect Contacts
								</button>
								<div
									style={{
										fontSize: 9,
										marginTop: '5px',
										textAlign: 'center',
									}}
								>
									You can edit your{' '}
									<button
										className={LinkStyles}
										onClick={this.onPrivacyLinkClick}
									>
										Privacy & Security
									</button>{' '}
									settings at any time
								</div>
							</li>
						</ul>
					</div>
				) : null}
				{shouldShowConnectContacts ? (
					<div>
						<h2 className={headerClass}>Connect Contacts</h2>
						<ul className={listStyles}>
							<li>
								<div style={{ textAlign: 'center', marginBottom: '15px' }}>
									<img
										src={Group}
										width={95}
										height={95}
										alt="earn and connect"
									/>
								</div>
							</li>
							<li>
								Connect your contacts to automatically find people to refer.
								<strong>
									{' '}
									We never share your contact&#39;s information until you make a
									referral.
								</strong>
							</li>
							<li>
								<p
									style={{
										fontSize: 9,
										marginTop: '12px',
										marginBottom: 10,
										textAlign: 'center',
									}}
								>
									You can edit your{' '}
									<button
										className={LinkStyles}
										onClick={this.onPrivacyLinkClick}
									>
										Privacy & Security
									</button>{' '}
									settings at any time
								</p>
								<button className={connectContacts} onClick={onClick}>
									<WebIcon name="network" size={14} color={COLORS.white} />{' '}
									Connect Contacts
								</button>
							</li>
						</ul>
					</div>
				) : null}
			</div>
		);
	}
}

export const ConnectCard = borderedTile(BaseConnectCard);
