import { InfoCircleOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import get from 'lodash/get';
import { Component } from 'react';
import { USER_ROLES } from 'src/_shared/constants/';
import { withQueryParams } from 'src/_shared/services/index.js';
import OnDeckReferralModal from './SubmitGeneralReferralModalComponent.jsx';

class ReferralModalWrapper extends Component {
	constructor(props) {
		super(props);
		this.state = {
			job: props.jobData,
			contacts: props.contacts,
			onDeckContacts: props.onDeckContacts,
			contact: props.contact,
			visible: false,
			referrals: props.referrals,
			height: 0,
			width: 0,
			theme: JSON.parse(get(props, 'currentUser.company.theme', '{}')),
			referPage: get(props, 'referPage', null),
		};
	}

	componentDidMount() {
		this.updateWindowDimensions();
		window.addEventListener('resize', this.updateWindowDimensions);

		let showLead;
		if (this.props?.queryParams?.size > 0) {
			showLead = this.props.queryParams.get('showLead') === 'true';
		}

		if (showLead) {
			setTimeout(() => {
				this.setState({
					visible: true,
				});
			}, 1500);
		}
	}

	componentDidUpdate(prevProps) {
		const { onFetchMore, onFetchMoreOnDeckContacts, onDeckContacts, contacts } =
			this.props;
		if (prevProps.onDeckContacts !== onDeckContacts) {
			this.setState({ onDeckContacts });
			if (onFetchMoreOnDeckContacts) onFetchMoreOnDeckContacts();
		}

		if (prevProps.contacts !== contacts) {
			this.setState({ contacts });
			if (onFetchMore) onFetchMore();
		}
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.updateWindowDimensions);
	}

	handleCancel = () => {
		this.setState({ visible: false });
	};

	showModal = () => {
		this.setState({ visible: true });
	};

	updateWindowDimensions = () => {
		this.setState({ width: window.innerWidth, height: window.innerHeight });
	};

	render() {
		const { visible, contact, contacts, onDeckContacts } = this.state;
		const {
			button,
			currentUser,
			submitGeneralReferralText,
			referSomeoneText,
			doYouKnowText,
			submitThemText,
			enterReferralText,
			enterReferralInformationText,
			firstNameText,
			lastNameText,
			emailText,
			textPlaceHolderText,
			orText,
			clickHereText,
			toAddExistingContactText,
			includeMessageToContactText,
			optionalText,
			requiredText,
			personalizeMessageText,
			messageHiringContactText,
			howKnowThemText,
			clickHereResumeText,
			attachResumeText,
			submitReferralText,
			referPage,
		} = this.props;

		return (
			<>
				{button ? (
					currentUser.displayAs === USER_ROLES.ADMIN ? (
						referPage == 'referralDetails' ? (
							<Button
								type="link"
								className="add-btn btn-lg"
								onClick={this.showModal}
							>
								<span className="icon-circle">
									<i className="icon-plus" />
								</span>
								<span>{this.props.addGeneralReferralText}</span>
							</Button>
						) : (
							<Button
								type="link"
								className="add-btn btn-lg"
								onClick={this.showModal}
							>
								<span className="icon-circle">
									<i className="icon-plus" />
								</span>
								<span>{this.props.addGeneralReferralText}</span>
							</Button>
						)
					) : (
						<Button type="primary" size="large" onClick={this.showModal}>
							Refer Contact
						</Button>
					)
				) : (
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<Button
							type="link"
							className="add-btn btn-lg"
							onClick={this.showModal}
						>
							<span className="icon-circle">
								<i className="icon-plus" />
							</span>
							<span>{submitGeneralReferralText}</span>
						</Button>
						<Tooltip
							placement="top"
							title="Leads are sent to the recruitment team to contact for more information. Your contact will not be notified when submitted."
						>
							<InfoCircleOutlined style={{ color: 'var(--theme-primary)' }} />
						</Tooltip>
					</div>
				)}

				<OnDeckReferralModal
					currentUser={this.props.currentUser}
					contact={contact}
					contacts={contacts}
					visible={visible}
					handleCancel={this.handleCancel}
					matchFound={this.props.matchFound}
					notification={this.props.notification}
					referSomeoneText={referSomeoneText}
					doYouKnowText={doYouKnowText}
					submitThemText={submitThemText}
					enterReferralText={enterReferralText}
					enterReferralInformationText={enterReferralInformationText}
					firstNameText={firstNameText}
					lastNameText={lastNameText}
					emailText={emailText}
					textPlaceHolderText={textPlaceHolderText}
					orText={orText}
					clickHereText={clickHereText}
					toAddExistingContactText={toAddExistingContactText}
					includeMessageToContactText={includeMessageToContactText}
					optionalText={optionalText}
					requiredText={requiredText}
					personalizeMessageText={personalizeMessageText}
					messageHiringContactText={messageHiringContactText}
					howKnowThemText={howKnowThemText}
					clickHereResumeText={clickHereResumeText}
					attachResumeText={attachResumeText}
					submitReferralText={submitReferralText}
					allMultiLingualData={get(this.props, 'allMultiLingualData')}
					onDeckContacts={onDeckContacts}
					onCreateContact={this.props.ImportedCreateContact}
					onUpdateContact={this.props.onUpdateContact}
					onUpdateNotification={this.props.onUpdateNotification}
				/>
			</>
		);
	}
}

export default withQueryParams(ReferralModalWrapper);
