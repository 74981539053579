import gql from 'graphql-tag';
import { compose, graphql } from 'react-apollo';
import { createWebNotification } from '../../graphql/custom/web-notification';

export const withCreateWebNotification = (Component) => {
	return compose(
		graphql(gql(createWebNotification), {
			options: {
				context: {
					headers: {
						'x-frame-options': 'deny', // This header will reach the server
					},
				},
				fetchPolicy: 'no-cache',
			},
			props: (props) => ({
				onCreateWebNotification(input) {
					return props
						.mutate({
							context: {
								headers: {
									'x-frame-options': 'deny', // This header will reach the server
								},
							},
							variables: input,
						})
						.then((resp) => resp.data.createWebNotification);
				},
			}),
		})
	)(Component);
};
