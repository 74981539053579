export const queryReferralsByCompanyIdIndex = `query QueryReferralsByCompanyIdIndex(
    $companyId: ID!
    $first: Int
    $after: String
  ) {
    queryReferralsByCompanyIdIndex(
      companyId: $companyId
      first: $first
      after: $after
    ) {
        items {
            id
            companyId
            contactId
            contact {
              id
              emailAddress
              lastName
              firstName
              socialMediaAccounts
              phoneNumber
            }
            userId
            user {
              id
              firstName
              lastName
              emailAddress
              incentiveEligible
              userGroupId
              userGroup {
                id
                measurement
                name
                currency
              }
            }
            jobId
            job {
              id
              title
              departmentId
              department {
                id
                name
              }
              referralBonus
            }
            ownershipEndDate
            note
            message
            hireDate
            referralDate
            referralType
            status
            bonusStatus
            customStatus
            contactResume {
              bucket
              region
              key
            }
          }
          nextToken
        }
      }
  `;
