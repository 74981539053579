import gql from 'graphql-tag';

export const GetJobReferralContacts = gql`
	query GetJob($id: ID!) {
		getJob(id: $id) {
			id
			referrals {
				contact {
					emailAddress
				}
			}
		}
	}
`;
