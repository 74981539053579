import { useState, useRef, useEffect } from 'react';
import store from './../../../state.js';
import GifPicker from 'gif-picker-react';
import CustomGifPicker from './CustomGifPicker.jsx';
import EmojiPicker from 'emoji-picker-react';
import EmployeePicker from 'src/RnRCommonComponent/EmployeePicker/index.js';
import { Button, Input, Modal, Spin, Tooltip, Typography } from 'antd';
import {
	DeleteOutlined,
	CloseOutlined,
	CloseCircleFilled,
} from '@ant-design/icons';
import RewardPointsAndBonusContainer from './../../container/rewardPointsAndBonusContainer/RewardPointsAndBonusContainer.jsx';
import SendRewardContainer from './../../container/sendRewardContainer/SendRewardContainer.jsx';
import RecentActivityContainer from './../../container/recentActivityContainer/RecentActivityContainer.jsx';
import FeedContainer from './../../container/feedContainer/FeedContainer.jsx';
import RewardDashboardContainer from './../../container/rewardDashboardContainer/RewardDashboardContainer.jsx';
import RewardsLeaderBoardContainer from './../../container/rewardsLeaderBoardContainer/RewardsLeaderBoardContainer.jsx';
import emoteIcon from './../../../assets/svg/emote.svg';
import { ReactComponent as RewardIcon } from './../../../assets/svg/rewardOne.svg';
import groupIcon from './../../../assets/svg/tag.svg';
import { ReactComponent as RecipientIcon } from './../../../assets/svg/recipientOne.svg';
import send from './../../../assets/svg/send.svg';
import send_gray from './../../../assets/svg/send_gray.svg';
import gifIcon from './../../../assets/svg/gif.svg';
import checkWhitesign from './../../../assets/right_white.png';
import './style.scss';
import { addCommas } from '../../../rewardsAndRecognitionHelper/utils.js';
import RewardsProgressContainer from 'src/home/container/rewardProgressContainer';

const { Text } = Typography;

const DashboardPresentation = (props) => {
	const {
		isOpenRewardModel,
		setIsOpenRewardModel,
		setSendRewardObject,
		sendrewardObject,
		isLoading,
		handleSendShoutout,
		isShoutOutSend,
		isEmployeeSelected,
		setIsEMployeeSelected,
		feedListData,
		isFeedLoading,
		setFeedListData,
		fetchMoreData,
		isLoadMoreCalled,
		hasMoreData,
		getTemplateData,
		isDataRefresh,
		setIsDataReferash,

		isMyBranchLoadMoreCalled,
		myBranchHasMoreData,
		myBranchFeedListData,
		isMyBranchFeedLoading,
		setMyBranchFeedListData,
		fetchMyBranchMoreData,

		isTopLoadMoreCalled,
		topHasMoreData,
		topFeedListData,
		isTopFeedLoading,
		setTopFeedListData,
		fetchTopMoreData,
		// client,
		// currentUser,
		// setCurrentUser,
	} = props;

	const { TextArea } = Input;
	const textareaRef = useRef(null);
	const [isGifPickerVisible, setIsGifPickerVisible] = useState(false);
	const [isEmojiPickerVisible, setIsEmojiPickerVisible] = useState(false);
	const [cursorPosition, setCursorPosition] = useState(0);
	const [isEmployeeNameVisible, setIsEmployeeNameVisible] = useState(false);
	const [selectedEmployee, setSelectedEmployee] = useState(null);
	const [searchedEmployeeData, setSearchedEmployeeData] = useState([]);
	const [isHovered, setIsHovered] = useState('');
	const state = store.getState();
	const { company } = state.user.currentUser;
	const gifIds = company?.gifIds;

	useEffect(() => {
		// Wait for the GifPicker to render, then update the placeholder text
		const intervalId = setInterval(() => {
			const inputElement = document.querySelector('.gpr-search');
			if (inputElement) {
				inputElement.placeholder = 'Search GIFs';
				clearInterval(intervalId);
			}
		}, 100);

		return () => clearInterval(intervalId);
	}, []);
	//
	// useEffect(() => {
	// 	const getUserByCognitoId = async () => {
	// 		const cognitoId = currentUser?.cognitoId;
	// 		const { data } = await Query({
	// 			query: GetUserByCognitoId,
	// 			variables: { cognitoId },
	// 			fetchPolicy: 'network-only',
	// 		});
	// 		const newCurrentUser = data?.getUserByCognitoId;
	// 		if (currentUser) {
	// 			currentUser.displayAs = newCurrentUser?.displayAs;
	// 			setCurrentUser(newCurrentUser);
	// 		}
	// 	};
	// 	getUserByCognitoId();
	// }, []);

	const handleMouseOver = (elementType) => {
		setIsHovered(elementType);
	};

	const handleMouseLeave = () => {
		setIsHovered('');
	};

	const handleSetSelectedEmployee = (value) => {
		setSelectedEmployee(value);
		setSendRewardObject({
			...sendrewardObject,
			employeeDetail: value,
		});
		setIsEMployeeSelected(false);
		setIsEmployeeNameVisible(true);
		setIsHovered(false);
	};

	const handleOnGifClicked = (img) => {
		setSendRewardObject({
			...sendrewardObject,
			content: {
				...sendrewardObject.content,
				gifURL: img.url,
			},
		});
		setIsGifPickerVisible(false);
	};

	const handleOnEmojiClicked = (img) => {
		const content = sendrewardObject?.content || {};
		const message = content?.message || '';

		const newCursorPosition = cursorPosition + img.emoji.length;

		const newText =
			message === ''
				? img.emoji
				: message.substring(0, cursorPosition) +
					img.emoji +
					message.substring(cursorPosition);

		setSendRewardObject({
			...sendrewardObject,
			content: {
				...content,
				message: newText,
			},
		});

		setCursorPosition(newCursorPosition);
		textareaRef.current.focus();
		setIsEmojiPickerVisible(false);
	};

	const handleOnChangetextState = (e) => {
		const newCursorPosition = e.target.selectionStart;

		setSendRewardObject({
			...sendrewardObject,
			content: {
				...sendrewardObject?.content,
				message: e.target.value,
			},
		});

		setCursorPosition(newCursorPosition);
	};

	const handleDeleteGif = () => {
		setSendRewardObject({
			...sendrewardObject,
			content: {
				...sendrewardObject.content,
				gifURL: null,
			},
		});
	};

	const { employeeDetail, content, rewardtype } = sendrewardObject || {};
	const isEmployeeDetailValid =
		employeeDetail !== undefined && employeeDetail !== null;
	const isContentValid =
		content &&
		((content.message && content.message.trim().length !== 0) ||
			content.gifURL);
	const matchedEmployee = searchedEmployeeData.find(
		(e) => e.id === sendrewardObject?.employeeDetail
	);

	return (
		<div>
			<div className="dashboard-grid custom-form-group">
				<div className="card-scroll ">
					<div className="">
						<div className="d-card height-equal mb-3">
							<div className="d-card-head">
								<h4 className="dashboard-title">SEND</h4>
							</div>
							<div className="pb-2 w-100 send-modal-btn">
								<div className="send-modal-container">
									<div style={{ height: '34px' }}>
										{isEmployeeNameVisible &&
										sendrewardObject &&
										sendrewardObject?.employeeData !== null &&
										sendrewardObject?.employeeDetail !== undefined ? (
											<div
												className="dropdown-wrapper"
												onMouseOver={() => handleMouseOver('recipient')}
												onMouseLeave={handleMouseLeave}
												onClick={() => {
													setSelectedEmployee(null);
													setIsEmployeeNameVisible(false);
													setSendRewardObject({
														...sendrewardObject,
														employeeDetail: null,
													});
												}}
												style={
													isHovered === 'recipient' ? { cursor: 'pointer' } : {}
												}
											>
												<div className="badge-box">
													<div className="dropdown-container">
														<div
															className={`dropdown-content ${isHovered === 'recipient' ? 'hidden' : ''}`}
														>
															<RecipientIcon className="at-symbol-icon-filled" />
															<div className="dropdown-text">
																<Text
																	style={{ maxWidth: 120, color: '#fff' }}
																	ellipsis={true}
																>
																	{(
																		matchedEmployee?.firstName +
																		' ' +
																		matchedEmployee?.lastName
																	)?.trim()}
																</Text>
															</div>
														</div>
														<div
															className={`dropdown-content hover-content ${isHovered === 'recipient' ? 'visible' : ''}`}
														>
															<CloseCircleFilled
																style={{
																	fontSize: '18px',
																	paddingRight: '0px',
																}}
															/>
															<span className="remove-text">
																<Text
																	style={{ maxWidth: 120, color: '#fff' }}
																	ellipsis={true}
																>
																	Remove
																</Text>
															</span>
														</div>
													</div>
												</div>
											</div>
										) : (
											<EmployeePicker
												selectedEmployee={selectedEmployee}
												setSelectedEmployee={handleSetSelectedEmployee}
												setSearchedEmployeeData={setSearchedEmployeeData}
												placeholder={
													<div style={{ height: 34, width: 120 }}>
														<RecipientIcon
															style={{
																height: 18,
																width: 18,
																marginRight: '4px',
															}}
														/>
														<span style={{ verticalAlign: 'middle' }}>
															Select Recipient
														</span>
													</div>
												}
											/>
										)}
										{isEmployeeSelected ? (
											<div class="ant-form-explain text-danger mt-1">
												Please add employee
											</div>
										) : (
											<></>
										)}
									</div>
									{sendrewardObject?.selectedBadge ? (
										<div
											onMouseOver={() => handleMouseOver('reward')}
											onMouseLeave={handleMouseLeave}
											onClick={() => {
												setSendRewardObject({
													...sendrewardObject,
													selectedBadge: null,
													rewardtype: null,
												});
											}}
											style={
												isHovered === 'reward' ? { cursor: 'pointer' } : {}
											}
										>
											<div className="d-flex align-items-center badge-box">
												<div className="feed-reward-logo">
													<RewardIcon style={{ height: 18, width: 18 }} />
												</div>
												<div className="d-flex align-items-center text-box">
													<span className="selected-badge-name">
														{sendrewardObject?.selectedBadge?.name}{' '}
													</span>
													<CloseOutlined
														onClick={() => {
															setSendRewardObject({
																...sendrewardObject,
																selectedBadge: null,
																rewardtype: null,
															});
														}}
													/>
												</div>
											</div>
										</div>
									) : (
										<div>
											<Button
												shape="round"
												className={
													'd-flex justify-content-start add-reward-btn' +
													(!Boolean(selectedEmployee)
														? ' reward-unselected'
														: ' reward-selected')
												}
												disabled={!Boolean(selectedEmployee)}
												onClick={() => {
													setIsOpenRewardModel(true);
												}}
											>
												<RewardIcon style={{ height: 18, width: 18 }} />
												<span style={{ marginLeft: 0 }}>Give Recognition</span>
											</Button>
										</div>
									)}
								</div>
							</div>
							<div className="mt-4 mb-2 text-area-gif">
								<TextArea
									ref={textareaRef}
									value={sendrewardObject?.content?.message || ''}
									onChange={handleOnChangetextState}
									rows={4}
									maxLength={1000}
									placeholder="Tell us why you are recognizing this person 🎉"
									className="text-area-input"
								/>
								{sendrewardObject?.content?.gifURL && (
									<div className="selected-gif">
										<img
											src={sendrewardObject?.content?.gifURL}
											alt="Selected GIF"
										/>
										<div className="gif-close-btn">
											<DeleteOutlined onClick={handleDeleteGif} />
										</div>
									</div>
								)}
							</div>
							<div className="d-flex align-items-center w-100 justify-content-between">
								<div className="d-flex">
									<div
										className="pr-2 gif-emogi"
										onClick={() => {
											setIsEmojiPickerVisible(false);
											setIsGifPickerVisible(!isGifPickerVisible);
										}}
									>
										<img src={gifIcon} alt="gifIcon" />
									</div>
									<div
										className="gif-emogi"
										onClick={(e) => {
											e.preventDefault();
											setIsGifPickerVisible(false);
											setIsEmojiPickerVisible(!isEmojiPickerVisible);
										}}
									>
										<img src={emoteIcon} alt="emoteIcon" />
									</div>
								</div>
								<div>
									{isShoutOutSend ? (
										<Spin className="d-flex align-items-center justify-content-center w-100 h-100" />
									) : (
										<Tooltip
											title="Please select a badge before sending your recognition."
											placement="topRight"
											open={(
												company?.requireBadgeSelection &&
												isEmployeeDetailValid &&
												isContentValid &&
												!rewardtype
											) ? undefined : false
											}
										>
											<div className="send-primary-btn">
												<Button
													type="primary"
													onClick={() => {
														setIsGifPickerVisible(false);
														setIsEmojiPickerVisible(false);
														setSelectedEmployee(null);
														handleSendShoutout();
													}}
													disabled={
														!(
															isEmployeeDetailValid &&
																company?.requireBadgeSelection ?
																	rewardtype :
																	(isContentValid || rewardtype)
														)
													}
												>
													<span className="d-flex align-items-center">
														<span className="pr-2">
															Send{' '}
															{sendrewardObject?.selectedBadge?.points
																? `(${addCommas(sendrewardObject?.selectedBadge?.points)} points)`
																: ''}
														</span>
														{!(
															isEmployeeDetailValid &&
																company?.requireBadgeSelection ?
																	rewardtype :
																	(isContentValid || rewardtype)
														) ? (
															<img
																src={send_gray}
																style={{ height: 18, width: 18 }}
															/>
														) : (
															<img src={send} style={{ height: 18, width: 18 }} />
														)}
													</span>
												</Button>
											</div>
										</Tooltip>
									)}
								</div>
							</div>
							<div className="gif-emogi-modal mt-2">
								{isGifPickerVisible &&
									(gifIds ? (
										<CustomGifPicker
											gifIds={gifIds}
											onGifClick={handleOnGifClicked}
										/>
									) : (
										<GifPicker
											placeholder="Search GIFs"
											tenorApiKey={process.env.REACT_APP_GIF_TENOR_KEY}
											onGifClick={handleOnGifClicked}
											contentFilter="high"
										/>
									))}
								<EmojiPicker
									open={isEmojiPickerVisible}
									lazyLoadEmojis={true}
									hiddenEmojis={[
										'1f922',
										'1f92e',
										'1f621',
										'1f92c',
										'1f480',
										'2620-fe0f',
										'1f4a9',
										'1f4a3',
										'1f595',
										'1f44e',
										'1f44a',
										'1f6ac',
										'26b0-fe0f',
										'1faa6',
										'26b1-fe0f',
										'1f52b',
									]}
									onEmojiClick={handleOnEmojiClicked}
								/>
							</div>
						</div>
					</div>
					<Modal
						width={560}
						className=""
						open={isOpenRewardModel}
						title="Give Recognition"
						footer={false}
						onOk={false}
						onCancel={() => {
							setSendRewardObject({
								...sendrewardObject,
								rewardtype: null,
								selectedBadge: null,
							});
							setIsOpenRewardModel(false);
						}}
						destroyOnClose={true}
					>
						<SendRewardContainer
							isLoading={isLoading}
							setIsOpenRewardModel={setIsOpenRewardModel}
							sendrewardObject={sendrewardObject}
							setSendRewardObject={setSendRewardObject}
							recipientId={sendrewardObject?.employeeDetail}
						/>
					</Modal>
					<RewardsProgressContainer />
					<RewardPointsAndBonusContainer />
					<RecentActivityContainer
						getTemplateData={getTemplateData}
						isDataRefresh={isDataRefresh}
						setIsDataReferash={setIsDataReferash}
					/>
				</div>
				<div className="card-scroll feed-container">
					<FeedContainer
						feedListData={feedListData}
						isFeedLoading={isFeedLoading}
						setFeedListData={setFeedListData}
						fetchMoreData={fetchMoreData}
						isLoadMoreCalled={isLoadMoreCalled}
						hasMoreData={hasMoreData}
						isMyBranchLoadMoreCalled={isMyBranchLoadMoreCalled}
						myBranchHasMoreData={myBranchHasMoreData}
						myBranchFeedListData={myBranchFeedListData}
						isMyBranchFeedLoading={isMyBranchFeedLoading}
						setMyBranchFeedListData={setMyBranchFeedListData}
						fetchMyBranchMoreData={fetchMyBranchMoreData}
						isTopLoadMoreCalled={isTopLoadMoreCalled}
						topHasMoreData={topHasMoreData}
						topFeedListData={topFeedListData}
						isTopFeedLoading={isTopFeedLoading}
						setTopFeedListData={setTopFeedListData}
						fetchTopMoreData={fetchTopMoreData}
					/>
				</div>
				<div className="card-scroll ">
					<RewardDashboardContainer
						isSharingVisible={!company?.hideRecognitionSocialShare}
					/>
					{!company?.hideRecognitionLeaderboard && (<RewardsLeaderBoardContainer />)}
				</div>
			</div>
		</div>
	);
};

export default DashboardPresentation;
