export const jobData = [
	{
		companyId: 3_332_121,
		jobId: 14_352_346_654,
		jobUrl: 'http://www.erin.com/',
		jobType: 'Full-Time',
		title: 'Sales Director',
		department: 'Sales',
		description:
			"<p>Looking for an experienced and high-energy Sales Director.</p><p>Primary duties include:</p><ul><li>Generating and submitting RFP/'s &amp; Related sales docs</li><li>Collaborating with senior dev team</li></ul><p>...</p><p><br></p>",
		salaryMinimum: '1233',
		salaryMaximum: '5552',
		salaryType: 'Monthly',
		publicLink: '',
		jobLocation: 'Pittsburgh, PA',
		hiringManager: 'Jeff Holcomb',
		referralBonusAmount: 3000,
		referralBonusAvailable: true,
		notificationType: '',
		locationCoords: '',
		jobCity: '___New York',
		jobState: '___NY',
		isRemoteJob: false,
		location: {
			isRemote: false,
			city: 'New York',
			state: 'NY',
		},
		referralBonus: {
			active: true,
			amount: 3000,
		},
		salary: {
			from: 1233,
			to: 5552,
			duration: 'String',
		},
		shares: 16,
		views: 125,
		_accepted: 1,
		_referrals: 5,
		_matches: 2,
		isOpen: true,
	},
	{
		companyId: 3_332_121,
		jobId: 2_547_743_432,
		jobUrl: 'http://www.erin.com/',
		jobType: 'Part-Time',
		title: 'Marketing Associate',
		department: 'Marketing',
		description: '',
		salaryMinimum: '1300',
		salaryMaximum: '3000',
		salaryType: 'Monthly',
		publicLink: '',
		hiringManager: 'Sam High',
		referralBonusAmount: '1000',
		referralBonusAvailable: true,
		notificationType: '',
		locationCoords: '',
		jobCity: 'San Francisco',
		jobState: 'CA',
		isRemoteJob: false,
		location: {
			isRemote: true,
			city: 'San Francisco',
			state: 'CA',
		},
		referralBonus: {
			active: true,
			amount: 1000,
		},
		salary: {
			from: 1300,
			to: 3000,
			duration: 'weekly',
		},
		shares: 12,
		views: 55,
		_accepted: 1,
		_referrals: 3,
		_matches: 2,
		isOpen: false,
	},
	{
		companyId: 87_521_239,
		jobId: 33_463_445,
		jobUrl: 'http://www.erin.com/',
		jobType: 'Full-Time',
		title: 'CIO',
		department: 'I.T.',
		description: '',
		salaryMinimum: '4500',
		salaryMaximum: '5000',
		salaryType: 'Weekly',
		publicLink: '',
		hiringManager: 'Jon Tewks',
		referralBonusAmount: '9000',
		referralBonusAvailable: true,
		notificationType: '',
		locationCoords: '',
		jobCity: 'San Francisco',
		jobState: 'CA',
		isRemoteJob: true,
		location: {
			isRemote: false,
			city: 'San Francisco',
			state: 'CA',
		},
		referralBonus: {
			active: true,
			amount: 9000,
		},
		salary: {
			from: 4500,
			to: 5000,
			duration: 'weekly',
		},
		shares: 18,
		views: 45,
		_accepted: 1,
		_referrals: 1,
		_matches: 1,
		isOpen: true,
	},
];
