import get from 'lodash/get';
import { connect } from 'react-redux';
import { withCreateUpdateBonus as withCreateBonus } from 'src/_shared/api/components/bonuses/';
import { withCreateReferralAddReferral } from 'src/_shared/api/components/contacts/';
import { withDashboardJobsData as withDashboardJobs } from 'src/_shared/api/components/dashboard/';
import { withListDepartment } from 'src/_shared/api/components/departments/';
import { withQuerySubCompanyByCompanyIdIndex } from 'src/_shared/api/components/sub-company/';
import { withListTieredBonus } from 'src/_shared/api/components/tiered-bonuses/';
import { withListReferralRequestedNotificationsByCompany } from 'src/_shared/api/components/users/';
import {
	dashboardActions,
	manageJobsActions,
	userActions,
} from 'src/actions.js';
import { compose } from '../_shared/services/utils.js';
import internalApplicantsComponent from './InternalApplicantsComponent.jsx';

const mapStateToProperties = (state, ownProperties) => {
	const { currentUser } = state.user;
	const { allMultiLingualData } = state.dashboard;

	return {
		currentUser,
		companyId: get(currentUser, 'companyId'),
		filter: {
			companyId: { eq: get(currentUser, 'companyId') },
		},
		limit: 50_000,
		tieredBonusQuery: {
			companyId: get(currentUser, 'companyId'),
			id: get(ownProperties, 'tieredBonusId', 'skip'),
		},
		allMultiLingualData,
	};
};

const mapDispatchToProperties = (dispatch) => {
	return {
		setCurrentCurrencyRate(currencyRate) {
			dispatch(manageJobsActions.setCurrentCurrencyRate(currencyRate));
		},
		setCurrentCurrencySymbol(currencySymbol) {
			dispatch(manageJobsActions.setCurrentCurrencySymbol(currencySymbol));
		},
		setMultiLingualData(data) {
			dispatch(dashboardActions.createSetMultiLingualData(data));
		},
		updateCurrentUserCompany(company) {
			dispatch(userActions.updateUserCompany(company));
		},
	};
};

const InternalApplicantsWithAPI = compose(
	withCreateReferralAddReferral,
	withListDepartment,
	withListTieredBonus,
	withDashboardJobs,
	withCreateBonus,
	withQuerySubCompanyByCompanyIdIndex,
	withListReferralRequestedNotificationsByCompany
)(internalApplicantsComponent);

export const InternalApplicationContainer = connect(
	mapStateToProperties,
	mapDispatchToProperties
)(InternalApplicantsWithAPI);
