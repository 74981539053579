import { BADGE_TYPE } from "../../CoreBadge/rewardsConstants.js";
 
export const showBadgeNumber = (width, type) => {
    if (width > 1600) {
        return 8;
    } else if (width > 1300) {
        if (type === BADGE_TYPE.AUTOMATED ) {
            return 5
        }
        return 6;
    } else if (width > 1000) {
        return 5;
    } else if (width > 700) {
        return 4;
    } else if (width > 600) {
        return 3;
    } else if (width > 400) {
        return 3;
    }
}