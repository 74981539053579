export const queryInternalMobilityByCompanyIdIndex = `query QueryInternalMobilityByCompanyIdIndex(
    $companyId: ID!
    $first: Int
    $after: String
  ) {
    queryInternalMobilityByCompanyIdIndex(
      companyId: $companyId
      first: $first
      after: $after
    ) {
        items {
            id
            companyId
            contentFirst
            contentMiddle
            contentLast
          dateCreated
          }
          nextToken
    }
  }
  `;
