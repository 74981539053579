import { get } from 'lodash';
import { Component } from 'react';
import { withApollo } from 'react-apollo';
import { borderedTile } from '../../../_shared/components/bordered-tile';
import { USER_ROLES } from '../../../_shared/constants';
import { COLORS } from '../../../_shared/styles/colors';
import { container, headerClass } from './topReferralsMonthCardStyles.js';

class BaseTopReferralCard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			totalSmartReferrals: 0,
		};
	}

	renderNumberedEntities(entities) {
		const listItems = entities.map((entity, index) => (
			<li key={index}>
				<span
					style={{
						fontWeight: 600,
						color: COLORS.lightGray,
					}}
				>
					{get(entity, 'name', '').split('', 21)}
				</span>
				<span style={{ fontWeight: 800, float: 'right' }}>
					{get(entity, 'score')}
				</span>
			</li>
		));
		return listItems;
	}

	render() {
		const { onClick, title, currentUser, entities } = this.props;

		return (
			<div className={container(currentUser.role)} onClick={onClick}>
				{currentUser.displayAs === USER_ROLES.ADMIN && (
					<div>
						<h2 className={headerClass}>{title}</h2>
					</div>
				)}

				{currentUser.displayAs === USER_ROLES.ADMIN && (
					<div>
						<ul>
							{entities.map((entity, index) => (
								<li key={'topReferrerlasthisMonth-' + index}>
									<span
										style={{
											fontWeight: 600,
											color: COLORS.lightGray,
										}}
									>
										{get(entity, 'name', '').split('', 21)}
									</span>
									<span style={{ fontWeight: 800, float: 'right' }}>
										{get(entity, 'score')}
									</span>
								</li>
							))}
						</ul>
					</div>
				)}
			</div>
		);
	}
}

export const TopReferralCard = borderedTile(withApollo(BaseTopReferralCard));
