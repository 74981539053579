import RecognitionSettingsContainer from '../recognitionSettings/container/RecognitionSettingsContainer';
import { useSelector } from 'react-redux';
import { Spinner } from '../_shared/index.js';

function RecognitionSettings() {
	const currentUser = useSelector((state) => state.user.currentUser);

	if (!currentUser?.company) return <Spinner />;

	return (
		<RecognitionSettingsContainer currentUser={currentUser} />
	);
}

export default RecognitionSettings;
