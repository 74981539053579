import gql from 'graphql-tag';
import { compose, graphql } from 'react-apollo';
import { createUser } from 'src/_shared/api/graphql/custom/users/';

export const withNewExternalUser = (Component, variables) => {
	return compose(
		graphql(gql(createUser), {
			props: (props) => ({
				onCreate(input) {
					return props.mutate({
						context: {
							headers: {
								'x-frame-options': 'deny', // This header will reach the server
							},
						},
						variables: input,
					});
				},
			}),
		})
	)(Component);
};
