import { css } from 'emotion';
import get from 'lodash/get';
import { COLORS } from '../../_shared/styles/colors';

export const addJobMainContainer = css({
	justifyContent: 'center',
	alignItems: 'center',
});

export const jobCardMain = css({
	height: 260,
	padding: '15px 10px 10px 10px',

	width: '100%',

	borderRadius: 10,
	'& ant-card-head-title': {
		margin: 0,
	},
	'& .ant-card-extra': {
		padding: 0,
	},
	'@media (max-width: 1580px)': {
		height: 240,
	},
	'&:hover': {
		backgroundColor: '#f0f6fb',
	},
});

export const hidden = css({
	display: 'none',
});

export const bonusContainer = css({
	height: 'auto',
	marginRight: 15,
});

export const jobDetailRow = css({
	marginTop: 6,
	color: COLORS.darkGray,
	marginLeft: 5,
	display: 'flex',
	flexWrap: 'wrap',
});

export const middleContainer = css({
	height: 133,
});

export const divider = css({
	height: 1,
	marginTop: 20,
	backgroundColor: COLORS.subHeading,
	opacity: 0.4,
	width: '80%',
	margin: 'auto',
});

export const bottomContainer = css({
	position: 'absolute',
	bottom: '10px',
	width: '94%',
});

export const JobIcon = css({
	fontSize: 16,
	color: COLORS.darkGray,
	paddingRight: 3,
});

export const bigNumbers = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 800,
	color: COLORS.black,
	fontSize: '2em',
});

export const numberSubtitles = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 400,
	color: COLORS.black,
	fontSize: '0.85em',
});

export const bonusAmount = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 600,
	color: COLORS.green,
	fontSize: 16,
	textAlign: 'right',
});

export const jobTitleText = (theme) => {
	let titleColor = COLORS.hyperLink;
	if (get(theme, 'jobCardTitleColor')) {
		titleColor = get(theme, 'jobCardTitleColor', titleColor);
	}

	return css({
		fontFamily: '"Open Sans", sans-serif',
		fontWeight: '600 !important',
		color: titleColor,
		fontSize: 16,
		marginLeft: 5,
		maxWidth: 205,
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		display: '-webkit-box',
		lineHeight: '22px',
		maxHeight: '44px',
		'-webkit-line-clamp': 2,
		'-webkit-box-orient': 'vertical',
	});
};

export const IconContainer = css({
	paddingTop: 3,
});

export const jobDescText = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 400,
	color: COLORS.black,
	fontSize: 14,
	height: 140,
	overflowY: 'hidden',
});

export const referBtn = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 300,
	color: COLORS.white,
	background: COLORS.red,
	fontSize: 14,
	padding: 4,
});

export const jobMatch = css({
	fontSize: 12,
	width: '100%',
	marginTop: 8,
});

export const jobMatchNumber = css({
	color: COLORS.darkGray,
	fontWeight: 500,
	padding: '0 2px',
});

export const Department = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 400,
	color: COLORS.darkGray,
	fontSize: 14,
	marginLeft: 5,
	marginRight: 5,
});
export const locationStyles = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 400,
	color: COLORS.darkGray,
	fontSize: 14,
	marginRight: 5,
});

export const middleColumn = css({
	textAlign: 'center',
	width: 60,
});

export const matchBox = css({
	textAlign: 'center',
	width: 60,
	background: COLORS.lightGray2,
});

export const smallIcons = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 300,
	color: COLORS.subHeading,
	fontSize: '0.85em',
	marginLeft: 21,
	opacity: 0.7,
});

export const bottomSectionText = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 300,
	color: COLORS.heading,
	fontSize: '0.85em',
	marginLeft: 5,
	opacity: 0.7,
});

export const bottomSectionValue = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 800,
	color: COLORS.black,
	fontSize: '0.85em',
	float: 'right',
	marginRight: 21,
});

export const managerTextStyles = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 400,
	color: COLORS.heading,
	fontSize: 14,
});

export const managerLinkStyles = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: '600 !important',
	color: COLORS.blue,
	fontSize: 14,
	marginLeft: 5,
});

export const buttonRefer = css({
	paddingBottom: '2.5px',
});

export const hover = css({
	position: 'relative',
	'&:__no-hover': {
		opacity: 0,
		position: 'absolute',
		top: 0,
	},
	'&:__hover': {
		opacity: 1,
	},
});

export const reqNumberContainer = css({
	fontSize: 12,
	marginTop: 5,
});

export const reqNumber = css({
	color: COLORS.gray3,
	fontWeight: 500,
	padding: '0 2px',
});

export const companyNameText = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: '500 !important',
	color: COLORS.gray,
	fontSize: 15,
	marginTop: 2,
	marginLeft: 5,
	maxWidth: 205,
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	display: '-webkit-box',
	lineHeight: '22px',
	maxHeight: '44px',
	'-webkit-line-clamp': 2,
	'-webkit-box-orient': 'vertical',
	whiteSpace: 'pre-wrap',
});

export const generalContainer = css({
	justifyContent: 'center',
	alignItems: 'center',
	backgroundColor: COLORS.lightGreen,
	width: 'auto',
	borderRadius: 4,
	display: 'inline-block',
	borderColor: COLORS.lightGreen,
	flex: '0, 0, auto',
	margin: 3,
	paddingBottom: 2,
});

export const generalText = css({
	color: COLORS.green,
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 600,
	fontSize: 14,
	margin: 5,
});
