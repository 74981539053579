import { css } from 'emotion';
import { COLORS } from '../../styles/colors';

const breakpoints = [576, 767, 991, 1200];

const mq = breakpoints.map((bp) => `@media (max-width: ${bp}px)`);

export const toggleButtonContainerStyles = css({
	display: 'flex',
	margin: '10px 0px 10px 0',
	alignItems: 'center',
	'& .ant-btn': {
		lineHeight: '12px',
	},
	[mq[1]]: {
		width: '100%',
	},
});

export const openButtonStyles = (selected) =>
	css({
		margin: 5,
		marginTop: 8,
		backgroundColor: selected ? '#8d99a3' : COLORS.white,
		color: selected ? COLORS.white : COLORS.subHeading,
		'&:hover': {
			backgroundColor: '#8d99a3',
			color: COLORS.white,
			borderColor: 'transparent',
		},
		'&:focus': {
			backgroundColor: '#8d99a3',
			color: COLORS.white,
			borderColor: 'transparent',
		},
		height: 30,
		width: 120,
	});

export const openClosedButtonStyles = () =>
	css({
		backgroundColor: COLORS.inputDisableBackground,
		color: COLORS.inputDisableColor,
		'&:hover': {
			backgroundColor: COLORS.inputDisableBackground,
			color: COLORS.inputDisableColor,
			borderColor: 'transparent',
		},
		'&:focus': {
			backgroundColor: COLORS.inputDisableBackground,
			color: COLORS.inputDisableColor,
			borderColor: 'transparent',
		},
		height: 30,
		width: 120,
		borderRadius: 30,
		[mq[1]]: {
			width: '100%',
		},
	});

export const openClosedButtonStylesActive = () =>
	css({
		backgroundColor: COLORS.inputActiveBackground,
		color: COLORS.inputActiveColor,
		'&:hover': {
			backgroundColor: COLORS.inputActiveBackground,
			color: COLORS.inputActiveColor,
			borderColor: 'transparent',
		},
		'&:focus': {
			backgroundColor: COLORS.inputActiveBackground,
			color: COLORS.inputActiveColor,
			borderColor: 'transparent',
		},
		height: 30,
		width: 120,
		borderRadius: 30,
		[mq[1]]: {
			width: '100%',
		},
	});

export const toggleTitleStyles = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 600,
	color: COLORS.black,
	fontSize: '18',
});

export const RippleButton = css({
	[mq[1]]: {
		width: '100%',
	},
});
