import * as CryptoJS from 'crypto-js';

export const settingsData = {
	region: '509d9045b4fdd573aaf47d40c7a430a4',
	accessKeyId2:
		'b1f6fdae28356916aa940748d56c5406bc28256190ea48cb0bcced7ca29199b0',
	secretAccessKey2:
		'e88dd41cf57c90e143f89c9cf23cc5ceb08ecb1648d8665838602928d3a055021e0ed46fc681caabc378b2e997c0451c',
};

export const awsSettingsData =
	'a6dfdc616641b4e70c37e2011b06d0b9eea24d63286ae594685d0b6610c7056d89b1df1e32f8eb6cea4eecc99972482d60f08848c750b1215303db00937263d2e4520891843ee38b3231aebe033bc17d82ad1cf394b5af46627bfb5105e881ca1ed24b9bee98f2a306ac548f25943a2f3b73be9138e7ed2c7085306d14639f8b';
export const key1 = '08277A08B0ABA70703E28A5FCED7396D';
export const key2 = 'D9062EA86462F77E';

export const slackConfigSovren = {
	url: 'https://hooks.slack.com/services/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',
	channel: {
		sovren: '#sovren',
	},
	headers: {
		'Content-Type': 'application/json',
		Connection: 'keep-alive',
		'Accept-Encoding': '',
		'Accept-Language': 'en-US,en;q=0.8',
	},
	userAccountNotification: {
		username: 'production', // This will appear as user name who posts the message
		text: '', // Text
		icon_emoji: ':metal:', // User icon, you can also use custom icons here
		attachments: [
			{
				// This defines the attachment block, allows for better layout usage
				color: '#2eb886', // Color of the attachments sidebar.
				fields: [],
				thumb_url:
					'https://avatars.slack-edge.com/2018-10-23/462090581473_3a23a4a160599b96b95a_88.png',
				footer: 'Last sync',
				footer_icon:
					'https://platform.slack-edge.com/img/default_application_icon.png',
				ts: Date.now(),
			},
		],
	},
};

export const slackConfigErrors = {
	url: 'https://hooks.slack.com/services/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',
	channel: {
		errors: '#errors',
	},
	headers: {
		'Content-Type': 'application/json',
		Connection: 'keep-alive',
		'Accept-Encoding': '',
		'Accept-Language': 'en-US,en;q=0.8',
	},
	userAccountNotification: {
		username: 'production', // This will appear as user name who posts the message
		text: '', // Text
		icon_emoji: ':metal:', // User icon, you can also use custom icons here
		attachments: [
			{
				// This defines the attachment block, allows for better layout usage
				color: '#2eb886', // Color of the attachments sidebar.
				fields: [],
				thumb_url:
					'https://avatars.slack-edge.com/2018-10-23/462090581473_3a23a4a160599b96b95a_88.png',
				footer: 'Last sync',
				footer_icon:
					'https://platform.slack-edge.com/img/default_application_icon.png',
				ts: Date.now(),
			},
		],
	},
};

export const slackConfigAuth = {
	url: 'https://hooks.slack.com/services/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',
	channel: {
		auth: '#auth',
	},
	headers: {
		'Content-Type': 'application/json',
		Connection: 'keep-alive',
		'Accept-Encoding': '',
		'Accept-Language': 'en-US,en;q=0.8',
	},
	userAccountNotification: {
		username: 'production', // This will appear as user name who posts the message
		text: '', // Text
		icon_emoji: ':metal:', // User icon, you can also use custom icons here
		attachments: [
			{
				// This defines the attachment block, allows for better layout usage
				color: '#2eb886', // Color of the attachments sidebar.
				fields: [],
				thumb_url:
					'https://avatars.slack-edge.com/2018-10-23/462090581473_3a23a4a160599b96b95a_88.png',
				footer: 'Last sync',
				footer_icon:
					'https://platform.slack-edge.com/img/default_application_icon.png',
				ts: Date.now(),
			},
		],
	},
};

export const HubSpotConfig = {
	url: 'https://api.hubapi.com/crm/v3/objects/contacts',
	key: '4155fa4f-d113-45eb-b1a1-75f73f76bcfa',
	headers: {
		'Content-Type': 'application/json',
		Connection: 'keep-alive',
		'Accept-Encoding': '',
		'Accept-Language': 'en-US,en;q=0.8',
	},
};

export function encryptUsingAES256(data, aesSecretKey = key1, aesSecretIVKey = key2) {
	if (typeof data === 'number') {
		var name = data.toString();
	} else if (typeof data === 'boolean') {
		var name = data.toString();
	} else {
		name = data;
	}

	const _key = CryptoJS.enc.Utf8.parse(aesSecretKey);
	const _iv = CryptoJS.enc.Utf8.parse(aesSecretIVKey);
	const encrypted = CryptoJS.AES.encrypt(name, _key, {
		keySize: 256 / 32,
		iv: _iv,
		mode: CryptoJS.mode.CBC,
	});
	const encryptedString = encrypted.toString();
	const encryptedData = base64toHEX(encryptedString);
	return encryptedData;
}

export function base64toHEX(base64) {
	const raw = atob(base64);
	let HEX = '';
	let i;
	for (i = 0; i < raw.length; i++) {
		const _hex = raw.charCodeAt(i).toString(16);
		HEX += _hex.length === 2 ? _hex : '0' + _hex;
	}

	return HEX;
}

export function decryptUsingAES256(
	encrypted,
	aesSecretKey = key1,
	aesSecretIVKey = key2
) {
	const _key = CryptoJS.enc.Utf8.parse(aesSecretKey);
	const _iv = CryptoJS.enc.Utf8.parse(aesSecretIVKey);
	const base64String = btoa(
		encrypted
			.match(/\w{2}/g)
			.map((a) => {
				return String.fromCharCode(Number.parseInt(a, 16));
			})
			.join('')
	);
	const decrypted = CryptoJS.AES.decrypt(base64String, _key, {
		keySize: 256 / 32,
		iv: _iv,
		mode: CryptoJS.mode.CBC,
	}).toString(CryptoJS.enc.Utf8);
	return decrypted;
}
