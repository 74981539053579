export const createContact = `mutation CreateContact($input: CreateContactInput!) {
  createContact(input: $input) {
    id
    atsId
    firstName
    lastName
    emailAddress
    socialMediaAccounts
    disableSmartReferrals
    referrals {
      id
      companyId
      contactId
      userId
      jobId
      note
      message
      referralDate
      referralType
      hireDate
    }
    userId
    user {
      id
      cognitoId
      companyId
      emailAddress
      firstName
      lastName
      title
      departmentId
      lastLogin
      lastNotificationCheck
      incentiveEligible
      contacts {
        id
        firstName
        lastName
        emailAddress
        socialMediaAccounts
        userId
        companyId
        phoneNumber
        jobHistory
        fullContactData
        dateCreated
        onDeckDate
        onDeckStatus
        onDeckNote
      }
      totalReferrals
      connectedApps
      active
      createdById
      dateCreated
    }
    companyId
    phoneNumber
    inviteStatus
    jobHistory
    importMethod
    fullContactData
    dateCreated
    onDeckDate
    onDeckStatus
    onDeckNote
    contactResume {
      bucket
      region
      key
    }
  }
}
`;
