import PropTypes from 'prop-types';
import LordIconComponent from './LordIconComponent.jsx';
import { ANALYTICS_CARD_TYPES } from 'src/_shared/constants/';
import hiresFromReferralsIcon from './icons/hires-from-referrals.json';
import referralVelocityIcon from './icons/referral-velocity.json';
import referredToHiredRatioIcon from './icons/referred-to-hired-ratio.json';
import employeeAdoptionIcon from './icons/employee-adoption.json';

function AnalyticsGraphicComponent({ cardType, isActive, isHovered, shouldAnimationDelay }) {
	const imageMap = {
		[ANALYTICS_CARD_TYPES.HIRES]: {
			animated: hiresFromReferralsIcon,
			introAnimation: hiresFromReferralsIcon.assets[1].id,
			hoverAnimation: hiresFromReferralsIcon.assets[3].id,
			rareAnimation: hiresFromReferralsIcon.assets[5].id,
		},
		[ANALYTICS_CARD_TYPES.RATIO]: {
			animated: referredToHiredRatioIcon,
			introAnimation: referredToHiredRatioIcon.assets[1].id,
			hoverAnimation: referredToHiredRatioIcon.assets[3].id,
			rareAnimation: referredToHiredRatioIcon.assets[5].id,
		},
		[ANALYTICS_CARD_TYPES.VELOCITY]: {
			animated: referralVelocityIcon,
			introAnimation: referralVelocityIcon.assets[1].id,
			hoverAnimation: referralVelocityIcon.assets[7].id,
			rareAnimation: referralVelocityIcon.assets[5].id,
			frameModifier: 2,
		},
		[ANALYTICS_CARD_TYPES.ADOPTION]: {
			animated: employeeAdoptionIcon,
			introAnimation: employeeAdoptionIcon.assets[1].id,
			hoverAnimation: employeeAdoptionIcon.assets[13].id,
			rareAnimation: employeeAdoptionIcon.assets[15].id,
		},
	};
	const imagePath = imageMap[cardType];

	return (
		<LordIconComponent
			isHovered={isHovered}
			icon={imagePath.animated}
			introAnimation={imagePath.introAnimation}
			hoverAnimation={imagePath.hoverAnimation}
			rareAnimation={imagePath.rareAnimation}
			shouldAnimationDelay={shouldAnimationDelay}
			frameModifier={imagePath?.frameModifier ?? 1}
			className={`analytics-card-graphic ${cardType} ${(isActive ? '' : ' grayscale') + (isHovered ? ' hovered' : '')}`}
		/>
	);
}

AnalyticsGraphicComponent.propTypes = {
	cardType: PropTypes.string.isRequired,
	isActive: PropTypes.bool.isRequired,
	isHovered: PropTypes.bool,
	shouldAnimationDelay: PropTypes.bool,
};

export default AnalyticsGraphicComponent;
