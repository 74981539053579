import { useState, useEffect, useRef } from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { NetworkStatus } from 'apollo-client';
import VirtualList from 'rc-virtual-list';
import { Button, List } from 'antd';
import { ArrowUpOutlined } from '@ant-design/icons';
import { ReferralCard } from 'src/_shared/components/referral-card/';
import Spinner from 'src/_shared/components/spinner/SpinnerComponent.jsx';
import _ from 'lodash';
import fileIcon from 'src/_shared/assets/erin_lightgray.png';
import { ml } from 'src/_shared/services/utils.js';

const GET_REFERRALS_BY_JOB_ID = gql`
	query QueryReferralsByJobIdIndex($jobId: ID!, $first: Int, $after: String) {
		queryReferralsByJobIdIndex(jobId: $jobId, first: $first, after: $after) {
			items {
				id
				bonuses {
					id
					amountDue
					bonusStatus
					companyId
					contactId
					earnedDate
					hireDate
					jobId
					payment
					recipientType
					referralId
					startDate
					userId
					notes
				}
				contact {
					id
					firstName
					lastName
					emailAddress
					socialMediaAccounts
					phoneNumber
					contactResume {
						bucket
						region
						key
					}
				}
				contactId
				userId
				user {
					id
					firstName
					lastName
					incentiveEligible
					role
					userGroupId
					emailAddress
					title
					department {
						id
						name
					}
					avatar {
						key
						region
						bucket
					}
				}
				status
				bonusStatus
				referralDate
				referralType
				hireDate
				ownershipEndDate
				message
				customStatus
				contactResume {
					bucket
					region
					key
				}
			}
			nextToken
		}
	}
`;

const prepareRows = (items, paginated) => {
	let referrals;
	if (paginated) {
		referrals = items;
	} else {
		referrals = _.sortBy(items, function (referral) {
			return referral.referralDate;
		}).reverse();
	}

	const rows = [];
	for (let i = 0; i < referrals.length; i += 2) {
		// This is my workaround for virtualized scroll only supporting single rows lol
		const row = referrals.slice(i, i + 2);
		rows.push(row);
	}

	return rows;
};

export function InfiniteReferralCards({
	jobId,
	itemsPerPage = 1000,
	currentUser,
	allMultiLingualData,
	currentJob,
	gdprReferrals,
	referralBonuses,
	currentCurrencyRate,
	currentCurrencySymbol,
	onUpdateReferral,
	onUpdateJob,
	parsedOwnershipSettings,
}) {
	const [hasReachedContainerBottom, setHasReachedContainerBottom] =
		useState(false);
	const [showBackToTop, setShowBackToTop] = useState(false);
	const [lastScrollDirection, setLastScrollDirection] = useState(null);
	const virtualListRef = useRef(null);
	const timerRef = useRef(null);
	const lastScrollTop = useRef(0);

	useEffect(() => {
		if (showBackToTop) {
			if (timerRef.current) {
				clearTimeout(timerRef.current);
			}

			timerRef.current = setTimeout(() => {
				setShowBackToTop(false);
			}, 3500);
		}

		return () => {
			if (timerRef.current) {
				clearTimeout(timerRef.current);
			}
		};
	}, [showBackToTop]);

	return (
		<Query
			notifyOnNetworkStatusChange
			query={GET_REFERRALS_BY_JOB_ID}
			variables={{ jobId, first: itemsPerPage, after: null }}
		>
			{({ data, loading, error, fetchMore, networkStatus }) => {
				if (loading && networkStatus === NetworkStatus.loading) return <div />;
				if (error) return <div>Error retrieving results.</div>;
				const loadingMore = networkStatus === NetworkStatus.fetchMore;
				const paginated = Boolean(
					data.queryReferralsByJobIdIndex.nextToken && !loadingMore
				);
				const ContainerHeight = 800;

				const redundantJobData = {
					id: currentJob.id,
					referralBonus: currentJob.referralBonus,
					jobMatches: currentJob.jobMatches,
					jobType: currentJob.jobType,
					title: currentJob.title,
					tieredBonus: currentJob.tieredBonus,
				};

				const rows = prepareRows(
					[...data.queryReferralsByJobIdIndex.items, ...gdprReferrals],
					paginated
				);

				const loadMoreReferrals = () => {
					const { nextToken } = data.queryReferralsByJobIdIndex;
					if (nextToken) {
						fetchMore({
							variables: { after: nextToken, first: itemsPerPage },
							updateQuery(prev, { fetchMoreResult }) {
								if (!fetchMoreResult) return prev;
								return {
									...prev,
									queryReferralsByJobIdIndex: {
										...prev.queryReferralsByJobIdIndex,
										items: [
											...prev.queryReferralsByJobIdIndex.items,
											...fetchMoreResult.queryReferralsByJobIdIndex.items,
										],
										nextToken:
											fetchMoreResult.queryReferralsByJobIdIndex.nextToken,
									},
								};
							},
						});
					}
				};

				const onScroll = (e) => {
					const tolerance = 100;
					const { scrollHeight, scrollTop, clientHeight } = e.currentTarget;
					const bottomReached =
						scrollHeight - Math.ceil(scrollTop + clientHeight) <= tolerance;

					if (scrollTop > lastScrollTop.current) {
						setLastScrollDirection('down');
					} else {
						setLastScrollDirection('up');
					}

					lastScrollTop.current = scrollTop;
					if (scrollTop > clientHeight && !hasReachedContainerBottom) {
						setHasReachedContainerBottom(true);
					} else if (scrollTop <= clientHeight && hasReachedContainerBottom) {
						setHasReachedContainerBottom(false);
					}

					if (
						bottomReached &&
						data.queryReferralsByJobIdIndex.nextToken &&
						!loading
					) {
						loadMoreReferrals();
					}

					if (hasReachedContainerBottom && lastScrollDirection === 'down') {
						setShowBackToTop(true);
					} else {
						setShowBackToTop(false);
					}
				};

				const scrollToTop = () => {
					virtualListRef.current.scrollTo({ top: 0, behavior: 'smooth' });
					if (timerRef.current) {
						clearTimeout(timerRef.current);
					}

					setHasReachedContainerBottom(false);
					setShowBackToTop(false);
				};

				if (rows.length === 0) {
					return (
						<div className="no-content">
							<img className="no-content-icon" alt="erin-logo" src={fileIcon} />
							<p className="no-content-text">
								{ml(
									'There are no Referrals for this job',
									currentUser,
									allMultiLingualData
								)}
							</p>
						</div>
					);
				}

				return (
					<div style={{ position: 'relative' }}>
						<List>
							{loading ? <Spinner /> : null}
							<VirtualList
								ref={virtualListRef}
								data={rows}
								height={ContainerHeight}
								itemHeight={242}
								itemKey={(row) => row[0].id}
								onScroll={onScroll}
							>
								{(row) => {
									const referral = row[0];
									const referral2 = row.length > 1 ? row[1] : null;
									referral.referralId = referral.id;
									referral.jobId = jobId;
									referral.job = redundantJobData;
									referral.companyId = currentJob.companyId;
									referral.company = currentJob.company;
									referral.referrals = data.queryReferralsByJobIdIndex.items;
									if (referral2) {
										referral2.referralId = referral2.id;
										referral2.jobId = jobId;
										referral2.job = redundantJobData;
										referral2.companyId = currentJob.companyId;
										referral2.company = currentJob.company;
										referral2.referrals = data.queryReferralsByJobIdIndex.items;
									}

									const paddingValue = rows.length > 3 ? '0 16px 0 0' : '0px';

									return (
										<List.Item
											key={referral.id}
											style={{
												padding: paddingValue,
												width: '100%',
												display: 'grid',
												gridTemplateColumns:
													'repeat(auto-fit, minmax(420px, 1fr))',
												gridColumnGap: '16px',
											}}
										>
											<ReferralCard
												key={referral.id + '-card'}
												ownershipSettings={parsedOwnershipSettings}
												allMultiLingualData={allMultiLingualData}
												currentUser={currentUser}
												currentJob={currentJob}
												referral={referral}
												referralBonuses={referralBonuses}
												userRole={currentUser.role}
												allReferrals={data.queryReferralsByJobIdIndex.items}
												currentCurrencyRate={currentCurrencyRate}
												currentCurrencySymbol={currentCurrencySymbol}
												cardStyle={{
													width: '100%',
													minWidth: 330,
													maxWidth: '100vw',
													height: '220px',
													marginBottom: '16px',
												}}
												onUpdateReferral={onUpdateReferral}
												onUpdateJob={onUpdateJob}
											/>
											{referral2 ? (
												<ReferralCard
													key={referral2.id + '-card'}
													ownershipSettings={parsedOwnershipSettings}
													allMultiLingualData={allMultiLingualData}
													currentUser={currentUser}
													currentJob={currentJob}
													referral={referral2}
													referralBonuses={referralBonuses}
													userRole={currentUser.role}
													allReferrals={data.queryReferralsByJobIdIndex.items}
													currentCurrencyRate={currentCurrencyRate}
													currentCurrencySymbol={currentCurrencySymbol}
													cardStyle={{
														width: '100%',
														minWidth: 330,
														maxWidth: '100vw',
														height: '220px',
														marginBottom: '16px',
													}}
													onUpdateReferral={onUpdateReferral}
													onUpdateJob={onUpdateJob}
												/>
											) : (
												<div
													style={{
														width: '100%',
														minWidth: 330,
														maxWidth: '100vw',
														height: 0,
													}}
												/>
											)}
										</List.Item>
									);
								}}
							</VirtualList>
							<Button
								icon={<ArrowUpOutlined />}
								className={`vl-back-to-top ${showBackToTop ? 'show' : ''}`}
								onMouseDown={scrollToTop}
								onMouseUp={() => setShowBackToTop(false)}
							>
								Back to Top
							</Button>
						</List>
					</div>
				);
			}}
		</Query>
	);
}
