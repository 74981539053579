import { connect } from 'react-redux';
import { compose } from 'redux';
import { withReferralCount as withQueryReferralsByUserId } from 'src/_shared/api/components/referrals/';
import ReferralCountComponent from './ReferralCountComponent.jsx';

const mapStateToProperties = (state) => {
	const { allMultiLingualData } = state.dashboard;
	return {
		allMultiLingualData,
	};
};

const ReferralCountContainer = compose(withQueryReferralsByUserId)(
	ReferralCountComponent
);

export const ReferralCount = connect(mapStateToProperties)(
	ReferralCountContainer
);
