import { connect } from 'react-redux';
import { withQueryBonusByCompanyId } from 'src/_shared/api/components/bonuses/';
import { dashboardActions, manageJobsActions } from 'src/actions.js';
import { compose } from '../_shared/services/utils.js';
import BonusReportComponent from './BonusReportComponent.jsx';

const mapStateToProperties = (state, _ownProperties) => {
	const { currentUser } = state.user;
	const { allMultiLingualData } = state.dashboard;
	return {
		currentUser,
		filter: { userId: { eq: currentUser.id } },
		limit: 1000,
		allMultiLingualData,
	};
};

const mapDispatchToProperties = (dispatch) => {
	return {
		setCurrentCurrencyRate(currencyRate) {
			dispatch(manageJobsActions.setCurrentCurrencyRate(currencyRate));
		},
		setCurrentCurrencySymbol(currencySymbol) {
			dispatch(manageJobsActions.setCurrentCurrencySymbol(currencySymbol));
		},
		setMultiLingualData(data) {
			dispatch(dashboardActions.createSetMultiLingualData(data));
		},
	};
};

const BonusReportWithAPI = compose(withQueryBonusByCompanyId)(
	BonusReportComponent
);

export const BonusReportContainer = connect(
	mapStateToProperties,
	mapDispatchToProperties
)(BonusReportWithAPI);
