import { Button, Input, Tag, Tooltip, message } from 'antd';
import _ from 'lodash';
import get from 'lodash/get';
import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {
	ImportButton,
	ImportContainer,
	ModalTitle,
	alignLeft,
	emailField,
} from './addContactsModalStyles.js';

class AddContactsEmail extends Component {
	constructor(props) {
		super(props);
		this.state = {
			error: false,
			size: 'large',
			emailInput: '',
			tags: [],
		};
	}

	handleChange = (event) => {
		const inputContacts = event.target.value;
		const contactList = inputContacts.split(',');
		this.setState({ emailInput: contactList });
	};

	handleClose = (removedTag) => {
		const tags = this.state.tags.filter((tag) => tag !== removedTag);
		this.setState({ tags });
	};

	handleInputConfirm = () => {
		const { state } = this;
		const inputValue = state.emailInput;
		let { tags } = state;
		if (inputValue && !tags.includes(inputValue)) {
			tags = [...tags, inputValue];
		}

		this.setState({
			tags,
			emailInput: '',
		});
	};

	handleSubmit = () => {
		const { onCreateContact, currentUser, handleCancel, contactsChoice } =
			this.props;
		const { tags } = this.state;
		for (const imported of tags) {
			const input = {
				input: {
					firstName: null,
					lastName: null,
					emailAddress: imported[0].toLowerCase(),
					userId: currentUser.id,
					importMethod: 'email',
					companyId: currentUser.companyId,
					socialMediaAccounts: null,
					jobHistory: null,
					phoneNumber: null,
					inviteStatus: 'pending',
				},
			};
			this.submitContact(currentUser.contacts, input, onCreateContact);
		}

		this.setState({
			tags: [],
		});
		contactsChoice(0);
		handleCancel();
		message.success('Contacts Imported');
		this.props.updateUserIncentiveEligibility(true);
	};

	saveInputRef = (input) => (this.input = input);

	submitContact = (currentContacts, selectedContact, onCreateContact) => {
		const isDuplicate = _.some(currentContacts, {
			emailAddress: get(
				selectedContact,
				'input.emailAddress',
				''
			).toLowerCase(),
		});
		if (isDuplicate === true) {
			message.error(selectedContact.input.emailAddress + ' Already Imported');
		} else {
			onCreateContact(selectedContact);
		}
	};

	render() {
		const { emailInput, tags } = this.state;

		return (
			<div>
				<h1 className={ModalTitle}>Add Contacts through email</h1>
				<div>
					<p className={alignLeft}>
						Input your contacts emails by pressing Enter at the end of each
						email.
					</p>
					<Input
						ref={this.saveInputRef}
						className={emailField}
						rows={4}
						value={emailInput}
						placeholder="email@example1.com"
						onChange={this.handleChange}
						onPressEnter={this.handleInputConfirm}
					/>
				</div>
				<div>
					{tags.map((tag) => {
						const isLongTag = tag.length > 20;
						const tagElement = (
							<Tag key={tag} closable afterClose={() => this.handleClose(tag)}>
								{isLongTag ? `${tag.slice(0, 20)}...` : tag}
							</Tag>
						);
						return isLongTag ? (
							<Tooltip key={tag} title={tag}>
								{tagElement}
							</Tooltip>
						) : (
							tagElement
						);
					})}
				</div>
				{this.state.tags.length > 0 && (
					<div className={ImportContainer}>
						<Button
							key="importBtn"
							className={ImportButton}
							onClick={this.handleSubmit}
						>
							Import Contacts
						</Button>
					</div>
				)}
			</div>
		);
	}
}
export default withRouter(AddContactsEmail);
