import get from 'lodash/get';
import { connect } from 'react-redux';
import { withUserByIdUpdateLanguage } from 'src/_shared/api/components/edit-profile/';
import { navigationActions, userActions } from 'src/actions.js';
import { compose } from '../../services/utils';
import { HeaderComponent } from './HeaderComponent.jsx';

const mapStateToProperties = (state, props) => {
	const { currentUser } = state.user;
	const { allMultiLingualData } = state.dashboard;
	return {
		id: get(state, 'user.currentUser.id'),
		currentLocation: get(state, 'navigation.currentLocation'),
		currentUser,
		allMultiLingualData,
	};
};

const mapDispatchToProperties = (dispatch) => {
	return {
		signOut(token) {
			dispatch(userActions.signOut(token));
		},
		updatePathname(pathname) {
			dispatch(navigationActions.createUpdatePathname(pathname));
		},
		updateCurrentUserLanguage(values) {
			dispatch(userActions.updateCurrentUserLanguage(values));
		},
	};
};

const HeaderComponentWithApi = compose(withUserByIdUpdateLanguage)(
	HeaderComponent
);

export const Header = connect(
	mapStateToProperties,
	mapDispatchToProperties
)(HeaderComponentWithApi);
