import React from 'react';
import { useEffect, useState } from 'react';
import { DownloadOutlined } from '@ant-design/icons';
import { Button } from 'antd';

export const RewardBonus = () => {
	const [size] = useState('large');
	return (
		<div>
			<div className="row">
				<div className="col-md-4">
					<div className="d-card height-equal">
						<div className="d-card-head">
							<h4 className="d-title"> Send</h4>
						</div>
						<div className="d-flex gap-x-2">
							<Button
								type="primary"
								shape="round"
								icon={<DownloadOutlined />}
								size={size}
							>
								Select Recipient
							</Button>
							<Button
								type="primary"
								shape="round"
								icon={<DownloadOutlined />}
								size={size}
							>
								Give Recognition
							</Button>
							<Button
								type="primary"
								shape="round"
								icon={<DownloadOutlined />}
								size={size}
							>
								Bonus Amount
							</Button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default RewardBonus;
