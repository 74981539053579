import { Button } from 'antd'

import RewardsTableContainer from '../../container/RewardsTableContainer'

import './style.scss'


const NominatedRewardsPresentation = ({ setShowCreateModal, nominatedBadgeList,
    getNominationConfiguration, nominatedBadgeListLoader }) => {
    return (
        <>
            <div className="main nominations-container">
                <div className="page-title">
                    <h2 className="page-heading">Nominations</h2>
                    <div className="page-heading-container">
                        <Button type="link" onClick={() => {
                            setShowCreateModal(true)
                        }} className="add-btn btn-lg">
                            <span className="icon-circle">
                                <i className="icon-plus" />
                            </span>
                            <span>Create Nomination</span>
                        </Button>
                    </div>
                </div>
            </div>

            <RewardsTableContainer
                nominatedBadgeListLoader={nominatedBadgeListLoader}
                nominatedBadgeList={nominatedBadgeList}
                getNominationConfiguration={getNominationConfiguration} />
        </>
    )
}

export default NominatedRewardsPresentation