import React from 'react';
import { useSelector } from "react-redux";
import { Spinner } from "../_shared/index.js";
import ApprovalRewardsContainer from './container/approvalRewardsContainer/ApprovalRewardsContainer.jsx';
import { ApprovalRewardsServiceProvider } from './service/ApprovalRewards.service.jsx';

const ApprovalRewards = () => {
	const company = useSelector((state) => state.user.currentUser.company)
	if (!company) return <Spinner />
	return <>
		<ApprovalRewardsServiceProvider>
			<ApprovalRewardsContainer />
		</ApprovalRewardsServiceProvider>
	</>
};

export default ApprovalRewards;
