import { css } from 'emotion';
import { COLORS } from '../../../_shared/styles/colors';

export const container = (role) => css({});

export const headerClass = css({
	fontSize: 16,
	textTransform: 'uppercase',
	letterSpacing: 0.96,
	fontWeight: 300,
	color: `${COLORS.heading} !important`,
	marginBottom: 20,
});

export const middleContainers = css({
	height: 83,
	margin: 'auto',
	marginTop: 15,
	marginBottom: 20,
});

export const middleContainer = css({
	height: 80,
	width: 400,
	margin: 'auto',
	marginTop: 10,
	marginBottom: 10,
});

export const middleColumn = css({
	textAlign: 'center',
});

export const leftColumn = css({
	textAlign: 'left',
	width: 90,
});

export const rightColumn = css({
	textAlign: 'left',
	width: 90,
});

export const middleColumnEmployee = css({
	textAlign: 'center',
});

export const bigNumbers = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 800,
	color: COLORS.black,
	fontSize: '2em',
});

export const bigNumbersOpen = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 800,
	color: COLORS.dashboardDarkOrange,
	fontSize: '2em',
});

export const numberSubtitles = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 600,
	color: COLORS.white,
	fontSize: 14,
});

export const numberSubtitlesOpen = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 600,
	color: COLORS.lightGray,
	fontSize: 14,
	margin: 'auto',
});

export const numSubtitles = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 600,
	color: COLORS.white,
	fontSize: 12,
	paddingBottom: '5px',
});

export const matchBox = css({
	textAlign: 'center',
	width: 90,
	backgroundColor: COLORS.dashboardLightOrange,
	borderRadius: '10%',
	float: 'right',
	paddingBottom: 10,
});

export const smallIcons = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 600,
	color: COLORS.subHeading,
	fontSize: 14,
	opacity: 0.7,
});

export const divider = css({
	height: 1,
	backgroundColor: COLORS.subHeading,
	opacity: 0.4,
	margin: 'auto',
});

export const bottomContainer = css({
	height: 60,
	marginTop: 32,
	marginBottom: 17,
});

export const bottomContainers = css({
	height: 60,
	marginTop: 29,
	marginBottom: 20,
});

export const bottomSectionText = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 600,
	color: COLORS.heading,
	fontSize: 14,
	marginLeft: 5,
	opacity: 0.7,
});

export const bottomSectionValue = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 800,
	color: COLORS.black,
	fontSize: 14,
	float: 'right',
});

export const fixedHeight = css({
	maxHeight: '286px',
	overflow: 'hidden',
});

export const viewMore = css({
	position: 'absolute',
	bottom: '-21px',
	left: 0,
	width: '100%',
	textAlign: 'right',
	textTransform: 'upperCase',
	background: COLORS.white,

	'& a': {
		fontFamily: '"Open Sans",sans-serif',
		fontWeight: '600 !important',
		lineHeight: '25px',
		color: COLORS.hyperLink,
		display: 'inline-block',
	},
});

export const HeaderTheme = css({
	color: COLORS.black,
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 600,
	fontSize: 18,
	'@media (max-width: 1199px) and (min-width: 992px)': {
		width: '150px',
	},
});

export const addButton = css({
	'& .ant-btn-addButton': {
		backgroundColor: '#038dd3 !important',
		color: COLORS.white,
		height: '42px',
		width: '42px',
	},
	'& .ant-btn-addButton:hover': {
		backgroundColor: '#038dd3 !important',
		color: COLORS.white,
		border: `2px solid ${COLORS.blue} !important`,
	},
	'& .ant-btn-addButton:focus': {
		backgroundColor: '#038dd3 !important',
		color: COLORS.white,
		border: `1px solid ${COLORS.blue} !important`,
	},
});

export const autoHeight = css({});
