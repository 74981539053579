import gql from 'graphql-tag';

export const GetCompanyByPartnerClientId = gql`
	query GetCompanyByPartnerClientId($partnerClientId: String!) {
		getCompanyByPartnerClientId(partnerClientId: $partnerClientId) {
			id
			accountType
			name
			defaultBonusAmount
			departments {
				id
				companyId
				name
				keywords
				active
			}
			subCompanies {
				companyId
				id
				logo {
					bucket
					region
					key
				}
				name
				keywords
			}
			userGroups {
				id
				companyId
				measurement
				name
				keywords
				active
				currency
				languageCode
			}
			dashboardReferralPolicyText
			giftCardStoreAPIKeys {
				region
				apiKey
			}
			bonusEarnedNote
			allowSelfReferrals
			allowInternalMobility
			internalMobilityImage {
				bucket
				region
				key
			}
			contactIncentiveBonus
			sendAdminNotificationsOnReferral
			sendAdminNotificationsOnInternalCandidatesFound
			referralBonusNotificationRecipients
			brandColor
			websiteUrl
			disableReferrals
			sftpFolderName
			loginFormPickerSettings
			loginFormSettings
			logo {
				bucket
				region
				key
			}
			symbol {
				bucket
				region
				key
			}
			theme
			userGroups {
				id
				companyId
				measurement
				name
				keywords
				active
				currency
				languageCode
			}
			background {
				bucket
				region
				key
			}
			errorImage {
				bucket
				region
				key
			}
			whiteLabel
			onboarding
			partnerBillingPolicy
			partnerClientId
			partnerHostId
		}
	}
`;
