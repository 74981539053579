export const queryUserInvitesByCompanyId = `query QueryUserInvitesByCompanyId(
  $companyId: ID!
  $first: Int
  $after: String
) {
  queryUserInvitesByCompanyId(
    companyId: $companyId
    first: $first
    after: $after
  ) {
    items {
      id
      userId
      emailAddress
      firstName
      lastName
      title
      dateCreated
    }
    nextToken
  }
}
`;
