const PLACE_HOLDER = 'form-place-holder';

function PlaceHolder({ show, text }) {
	return (
		show && (
			<div className="fb-placeholder">
				<p>{text}</p>
			</div>
		)
	);
}

export default PlaceHolder;

PlaceHolder.defaultProps = {
	text: 'Drop an item here....',
	show: false,
};
