export const getReferralNew = `query GetReferral($id: ID!) {
  getReferral(id: $id) {
    id
    adminNote
    atsId
    diversityHire
    startDate
    bonuses {
      id
      amountDue
      bonusStatus
      companyId
      contactId
      earnedDate
      hireDate
      jobId
      payment
      recipientType
      referralId
      startDate
      userId
      notes
    }
    companyId
    company {
      id
      name
      defaultBonusAmount
      contactIncentiveBonus
      websiteUrl
      users {
        id
        cognitoId
        companyId
        emailAddress
        firstName
        lastName
        title
        departmentId
        totalReferrals
      }
      dateCreated
    }
    contactId
    contact {
      id
      atsId
      firstName
      lastName
      emailAddress
      socialMediaAccounts
      userId
      phoneNumber
      onDeckStatus
      onDeckNote
      onDeckDate
      contactResume {
        bucket
        region
        key
      }
      referrals {
        id
        bonuses {
          id
          amountDue
          bonusStatus
          companyId
          contactId
          earnedDate
          hireDate
          jobId
          payment
          recipientType
          referralId
          startDate
          userId
          notes
        }
        companyId
        company {	
          id	
          name	
          whiteLabel	
          host	
          senderEmailAddress
          whiteLabelServiceName
        }
        contactId
        contact {
          id
          emailAddress
          lastName
          firstName
          socialMediaAccounts
          phoneNumber
        }
        userId
        user {
          id         
          firstName
          incentiveEligible
          lastName
          title
          userGroupId
        }
        jobId
        job {
          id
          location
          title
          referralBonus
          tieredBonus {
            id
            name
            tiers
          }
          companyId
          company {
            id
            contactIncentiveBonus
            referralBonusWaitingPeriod
          }
          departmentId
          department {
            id
            name
          }
          referrals {
            id
            bonuses {
              id
              amountDue
              bonusStatus
              companyId
              contactId
              earnedDate
              hireDate
              jobId
              payment
              recipientType
              referralId
              startDate
              userId
              notes
            }
            jobId
            job {
              id
            }
            contactId
            userId
            status
            bonusStatus
            referralDate
            customStatus
            user {
              id         
              firstName
              lastName
              userGroupId
              incentiveEligible
            }
            questionsData
            candidateQuestionsData
          }
        }
        note
        message
        ownershipEndDate
        referralDate
        referralType
        hireDate
        startDate
        diversityHire
        status
        bonusStatus
        questionsData
        adminNote
        candidateQuestionsData
        customStatus
        contactResume {
          bucket
          region
          key
        }
      }
      fullContactData
      dateCreated
    }
    userId
    user {
      id
      cognitoId
      companyId
      emailAddress
      firstName
      lastName
      title
      departmentId
      lastLogin
      lastNotificationCheck
      incentiveEligible
      userGroupId
    }
    jobId
    job {
      id
      companyId
      departmentId
      department {
        id
        name
      }
      title
      description
      publicLink
      salary
      location
      hiringManagerId
      createdById
      referralBonus
      tieredBonus {
        id
        name
        tiers
      }
      shares
      views
      dateCreated
   
    }
    status
    bonusStatus
    note
    message
    ownershipAtsId
    ownershipEndDate
    referralDate
    referralType
    hireDate
    questionsData
    candidateQuestionsData
    interestedQuestionsData
    customStatus
    contactResume {
      bucket
      region
      key
    }
  }
}
`;
