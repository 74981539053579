import { graphql } from 'react-apollo';
import { JobMatchesByUserId } from '../../graphql/custom/jobMatch/queryJobMatchesByUserIdGraphql.js';

export const withMyJobMatches = (Component) => {
	return graphql(JobMatchesByUserId, {
		options: (props) => ({
			context: {
				headers: {
					'x-frame-options': 'deny', // This header will reach the server
				},
			},
			variables: {
				userId: props.currentUser.id,
				limit: 75,
			},
			fetchPolicy: 'cache-and-network',
		}),
		props(response, previous) {
			const jobMatches =
				response?.data?.queryJobMatchesByUserIdIndex?.items ??
				previous?.jobMatches;
			const jobMatchNextToken =
				response?.data?.queryJobMatchesByUserIdIndex?.nextToken;
			const onFetchMoreJobMatches = makeOnFetchMoreJobMatches(
				response.data.fetchMore,
				jobMatchNextToken
			);

			return {
				jobMatches,
				isJobMatchesLoading: Boolean(
					response?.data?.loading || onFetchMoreJobMatches
				),
				onFetchMoreJobMatches,
			};
		},
	})(Component);
};

const makeOnFetchMoreJobMatches = (fetchMore, nextToken) => {
	if (!nextToken) {
		return null;
	}

	return () => {
		fetchMore({
			variables: { after: nextToken },
			updateQuery(previous, { fetchMoreResult }) {
				if (!fetchMoreResult) {
					return previous;
				}

				return {
					...previous,
					loading: false,
					queryJobMatchesByUserIdIndex: {
						...previous.queryJobMatchesByUserIdIndex,
						...fetchMoreResult.queryJobMatchesByUserIdIndex,
						items: [
							...previous.queryJobMatchesByUserIdIndex.items,
							...fetchMoreResult.queryJobMatchesByUserIdIndex.items,
						],
					},
				};
			},
		});
	};
};
