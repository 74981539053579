import gql from 'graphql-tag';

export const JobMatchesByUserId = gql`
	query QueryJobMatchesByUserIdIndex(
		$userId: ID!
		$first: Int
		$after: String
	) {
		queryJobMatchesByUserIdIndex(
			userId: $userId
			first: $first
			after: $after
		) {
			items {
				id
				active
				contactId
				companyId
				dateCreated
				jobId
				job {
					id
					companyId
					company {
						id
						name
					}
					displayReqId
					departmentId
					department {
						id
						name
					}
					externalJobId
					hideImInterested
					internalJobLink
					isGeneralReferral
					location
					locations
					subCompanyId
					subCompany {
						id
						name
					}
					title
				}
				relevance
				matchStatus
				matchType
				userId
			}
			nextToken
		}
	}
`;
