import { Button, Dropdown } from 'antd';
import { usePapaParse } from 'react-papaparse';
import { WebIcon } from '../index.js';

export const ExportSelector = ({ items, title }) => {
	items = items.map((item) => {
		if (item?.onClick === 'csv-export')
			item.onClick = () => {
				handleJsonToCSV(item?.data, item?.companyName, item?.reportType);
				delete item.companyName;
				delete item.reportType;
			};

		return item;
	});

	const getFileName = (companyName, reportType) => {
		const regex = /[\s\\/.]/g;
		const formattedCompanyName = companyName
			.toLowerCase()
			.replaceAll(regex, '-');
		return `${formattedCompanyName}-${reportType}-report`;
	};

	const { jsonToCSV } = usePapaParse();

	const handleDownload = (csvContent, companyName, reportType) => {
		// Create the file and url
		const blob = new Blob([csvContent], { type: 'text/csv' });
		const url = window.URL.createObjectURL(blob);

		// Create a temporary link
		const link = document.createElement('a');
		link.href = url;
		link.download = getFileName(companyName, reportType);
		document.body.append(link);

		// Trigger the download
		link.click();

		// Clean up
		window.URL.revokeObjectURL(url);
		link.remove();
	};

	const handleJsonToCSV = (data, companyName, reportType) => {
		const jsonData = typeof data === 'string' ? data : JSON.stringify(data);
		const results = jsonToCSV(jsonData);
		handleDownload(results, companyName, reportType);
	};

	return (
		<Dropdown menu={{ items }} trigger={['click']}>
			<Button className="export-selector">
				<div>{title}</div>
				<div className="export-arrow">
					<WebIcon color="var(--oslo-gray)" size={10} name="sort-down" />
				</div>
			</Button>
		</Dropdown>
	);
};
