import gql from 'graphql-tag';

export const queryReferralsByContactIdIndex = gql`
	query QueryReferralsByContactIdIndex(
		$contactId: ID!
		$first: Int
		$after: String
	) {
		queryReferralsByContactIdIndex(
			contactId: $contactId
			first: $first
			after: $after
		) {
			items {
				id
				bonuses {
					id
					amountDue
					bonusStatus
					companyId
					contactId
					earnedDate
					hireDate
					jobId
					payment
					recipientType
					referralId
					startDate
					userId
					notes
				}
				companyId
				contactId
				contact {
					id
					emailAddress
					lastName
					firstName
					socialMediaAccounts
					phoneNumber
				}
				userId
				user {
					id
					firstName
					lastName
					incentiveEligible
					userGroupId
					userGroup {
						id
						measurement
						name
						currency
					}
				}
				jobId
				job {
					id
					title
					createdById
					createdBy {
						id
						firstName
						lastName
					}
					location
					departmentId
					department {
						id
						name
					}
					referralBonus
				}
				ownershipEndDate
				note
				message
				hireDate
				referralDate
				referralType
				status
				questionsData
				bonusStatus
				contactResume {
					bucket
					region
					key
				}
			}
			nextToken
		}
	}
`;
