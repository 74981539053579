import { css } from 'emotion';
import { COLORS } from '../_shared/styles/colors';

const breakpoints = [576, 767, 991, 1200];

const mq = breakpoints.map((bp) => `@media (max-width: ${bp}px)`);

export const EmployeeEmail = css({
	fontSize: 16,
	fontWeight: '600 !important',
});

export const ReferralDetailsContainer = css({
	width: '100%',
	height: '100%',
	padding: '20px',
	backgroundColor: COLORS.lightGray2,
	marginBottom: 0,
	maxWidth: 1300,
	borderRadius: 10,
	[mq[2]]: {
		padding: '10px 5px',
	},
});

export const FlexContainer = css({
	marginBottom: 30,
	width: '100%',
	paddingTop: 20,
});

export const CardStyles = css({
	borderRadius: 10,
	padding: 20,
	backgroundColor: 'white',
	height: 'auto',
});

export const CardProfile = css({
	width: '100%',
	marginRight: 15,
	height: '100%',
});

export const Card2Styles = css({
	width: '30%',
	marginLeft: 15,
	height: 200,
	minWidth: 300,
});

export const link = css({
	fontFamily: '"Open Sans", sans-serif',
	color: COLORS.blue,
});

export const LinkStyles = css({
	fontFamily: '"Open Sans", sans-serif',
	color: COLORS.blue,
	display: 'inline-flex',
	alignItems: 'center',
	fontWeight: '600 !important',
	cursor: 'pointer',
});

export const spinContainer = css({
	width: '100%',
	height: '50vh',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
});

export const EmployeeName = css({
	marginTop: '-8px',
	marginBottom: '-8px',
	fontSize: 28,
	lineSpacing: 0,
});

export const ReferralSection = css({
	width: '100%',
	marginTop: 10,
	overflow: 'hidden',
	'& .ant-card-bordered-card': {
		borderRadius: '10px !important',
	},
	'& .ant-col.ant-col-sm-24.ant-col-lg-12:nth-child(2n+1)': {
		clear: 'left',
	},
});

export const HeaderStyle = css({
	fontSize: '1.2rem',
	fontWeight: 600,
	fontFamily: 'Open Sans,sans-serif',
});
