import { Modal } from 'antd';
import { Component } from 'react';
import { withApollo } from 'react-apollo';
import { ReferralHiredForm } from './referral-hired-form';
import { FormStyles } from './referralHiredModalStyles.js';

class ReferralHiredModalComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			error: false,
		};
	}

	handleError = () => {
		this.setState({ error: true });
	};

	render() {
		const {
			visible,
			handleCancel,
			handleHired,
			referral,
			onUpdateReferral,
			updateReferralState,
			theme,
			currentUser,
		} = this.props;

		const { error } = this.state;
		return (
			<Modal
				open={visible}
				footer={null}
				title="Great Work!"
				onCancel={handleCancel}
			>
				<p className="text-center">
					You&#39;re one click away from filling this position.
				</p>
				<ReferralHiredForm
					currentUser={currentUser}
					theme={theme}
					client={this.props.client}
					className={FormStyles}
					handleCancel={handleCancel}
					error={error}
					handleError={this.handleError}
					updateReferralState={updateReferralState}
					handleHired={handleHired}
					referral={referral}
					onUpdateReferral={onUpdateReferral}
				/>
			</Modal>
		);
	}
}

export default withApollo(ReferralHiredModalComponent);
