export const queryJobsByCampaignIdIndex = `query QueryJobsByCampaignIdIndex(
    $campaignId: ID!
    $first: Int
    $after: String
  ) {
    queryJobsByCampaignIdIndex(
      campaignId: $campaignId
      first: $first
      after: $after
    ) {
      items {
        id
        companyId
        campaignId
        isHotJob
      }
      nextToken
    }
  }
  `;
