export const requiredFields = [
	{ key: 'firstName', message: 'Please enter your First Name' },
	{ key: 'lastName', message: 'Please enter your Last Name' },
	{ key: 'department', message: 'Please select a Department' },
	{ key: 'title', message: 'Please enter your Job Title' },
	{ key: 'subCompany', message: 'Please select a Company' },
];

export const requiredFieldsForExternalUser = [
	{ key: 'firstName', message: 'Please enter your First Name' },
	{ key: 'lastName', message: 'Please enter your Last Name' },
	{ key: 'title', message: 'Please enter your Job Title' },
];

export const requiredFieldsForReferVetsExternalUser = [
	{ key: 'firstName', message: 'Please enter your First Name' },
	{ key: 'lastName', message: 'Please enter your Last Name' },
	{ key: 'userGroup', message: 'Please select Employee Group' },
];
