import {
	DndContext,
	DragOverlay,
	KeyboardSensor,
	MeasuringStrategy,
	MouseSensor,
	TouchSensor,
	closestCenter,
	defaultDropAnimationSideEffects,
	getFirstCollision,
	pointerWithin,
	rectIntersection,
	useSensor,
	useSensors,
} from '@dnd-kit/core';
import {
	SortableContext,
	arrayMove,
	defaultAnimateLayoutChanges,
	horizontalListSortingStrategy,
	useSortable,
	verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { Container, Item } from './components';
import { coordinateGetter as multipleContainersCoordinateGetter } from './multipleContainersKeyboardCoordinates.js';
import {
	createRange,
	rectSortingStrategy,
	useDebouncedCallback,
} from './utilities';

const animateLayoutChanges = (args) =>
	defaultAnimateLayoutChanges({ ...args, wasDragging: true });

function DroppableContainer({
	children,
	columns = 1,
	disabled,
	id,
	items,
	style,
	...props
}) {
	const {
		active,
		attributes,
		isDragging,
		listeners,
		over,
		setNodeRef,
		transition,
		transform,
	} = useSortable({
		id,
		data: {
			type: 'container',
			children: items,
		},
		animateLayoutChanges,
	});
	const isOverContainer = over
		? (id === over.id && active?.data.current?.type !== 'container') ||
			items.includes(over.id)
		: false;

	return (
		<Container
			ref={disabled ? undefined : setNodeRef}
			style={{
				...style,
				transition,
				transform: CSS.Translate.toString(transform),
				opacity: isDragging ? 0.5 : undefined,
			}}
			hover={isOverContainer}
			handleProps={{
				...attributes,
				...listeners,
			}}
			columns={columns}
			{...props}
		>
			{children}
		</Container>
	);
}

const dropAnimation = {
	sideEffects: defaultDropAnimationSideEffects({
		styles: {
			active: {
				opacity: '0.5',
			},
		},
	}),
};

const empty = [];

export function DragDropDashboard({
	adjustScale = false,
	itemCount = 14,
	cancelDrop,
	columns,
	handle = false,
	handleCurrentItems,
	hexColors,
	setHexColors,
	tilesText,
	setTilesText,
	items: initialItems,
	containerStyle,
	coordinateGetter = multipleContainersCoordinateGetter,
	getItemStyles = () => ({}),
	wrapperStyle = () => ({}),
	minimal = false,
	modifiers,
	renderItem,
	strategy = verticalListSortingStrategy,
	vertical = false,
	scrollable,
	activeText = 'Active Tiles',
	inactiveText = 'Inactive Tiles',
	translatedTileNames = [],
}) {
	const [items, setItems] = useState(
		() =>
			initialItems ?? {
				A: [],
				B: createRange(itemCount, (index) => `${index + 1001}`),
			}
	);
	const [containers, setContainers] = useState(Object.keys(items));
	const [activeId, setActiveId] = useState(null);
	const lastOverId = useRef(null);
	const recentlyMovedToNewContainer = useRef(false);
	const isSortingContainer = activeId ? containers.includes(activeId) : false;

	/**
	 * Custom collision detection strategy optimized for multiple containers
	 *
	 * - First, find any droppable containers intersecting with the pointer.
	 * - If there are none, find intersecting containers with the active draggable.
	 * - If there are no intersecting containers, return the last matched intersection
	 *
	 */
	const collisionDetectionStrategy = useCallback(
		(args) => {
			if (activeId && activeId in items) {
				return closestCenter({
					...args,
					droppableContainers: args.droppableContainers.filter(
						(container) => container.id in items
					),
				});
			}

			// Start by finding any intersecting droppable
			const pointerIntersections = pointerWithin(args);
			const intersections =
				pointerIntersections.length > 0
					? // If there are droppables intersecting with the pointer, return those
						pointerIntersections
					: rectIntersection(args);
			let overId = getFirstCollision(intersections, 'id');

			if (overId != null) {
				if (overId in items) {
					const containerItems = items[overId];

					// If a container is matched and it contains items (columns 'A', 'B', 'C')
					if (containerItems.length > 0) {
						// Return the closest droppable within that container
						overId = closestCenter({
							...args,
							droppableContainers: args.droppableContainers.filter(
								(container) =>
									container.id !== overId ||
									containerItems.includes(container.id)
							),
						})[0]?.id;
					}
				}

				lastOverId.current = overId;

				return [{ id: overId }];
			}

			// When a draggable item moves to a new container, the layout may shift
			// and the `overId` may become `null`. We manually set the cached `lastOverId`
			// to the id of the draggable item that was moved to the new container, otherwise
			// the previous `overId` will be returned which can cause items to incorrectly shift positions
			if (recentlyMovedToNewContainer.current) {
				lastOverId.current = activeId;
			}

			// If no droppable is matched, return the last match
			return lastOverId.current ? [{ id: lastOverId.current }] : [];
		},
		[activeId, items]
	);
	const [clonedItems, setClonedItems] = useState(null);
	const sensors = useSensors(
		useSensor(MouseSensor),
		useSensor(TouchSensor),
		useSensor(KeyboardSensor, {
			coordinateGetter,
		})
	);
	const findContainer = (id) => {
		if (id in items) {
			return id;
		}

		return Object.keys(items).find((key) => items[key].includes(id));
	};

	const getIndex = (id) => {
		const container = findContainer(id);

		if (!container) {
			return -1;
		}

		const index = items[container].indexOf(id);

		return index;
	};

	const onDragCancel = () => {
		if (clonedItems) {
			// Reset items to their original state in case items have been
			// Dragged across containers
			setItems(clonedItems);
		}

		setActiveId(null);
		setClonedItems(null);
	};

	useEffect(() => {
		requestAnimationFrame(() => {
			recentlyMovedToNewContainer.current = false;
		});
		handleCurrentItems(items);
	}, [items]);

	const onDragOver = ({ active, over }) => {
		const overId = over?.id;

		if (overId == null || active.id in items) {
			return;
		}

		const overContainer = findContainer(overId);
		const activeContainer = findContainer(active.id);

		if (!overContainer || !activeContainer) {
			return;
		}

		if (activeContainer !== overContainer) {
			setItems((items) => {
				const activeItems = items[activeContainer];
				const overItems = items[overContainer];
				const overIndex = overItems.indexOf(overId);
				const activeIndex = activeItems.indexOf(active.id);

				let newIndex;

				if (overId in items) {
					newIndex = overItems.length + 1;
				} else {
					const isBelowOverItem =
						over &&
						active.rect.current.translated &&
						active.rect.current.translated.top >
							over.rect.top + over.rect.height;

					const modifier = isBelowOverItem ? 1 : 0;

					newIndex =
						overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
				}

				recentlyMovedToNewContainer.current = true;

				return {
					...items,
					[activeContainer]: items[activeContainer].filter(
						(item) => item !== active.id
					),
					[overContainer]: [
						...items[overContainer].slice(0, newIndex),
						items[activeContainer][activeIndex],
						...items[overContainer].slice(
							newIndex,
							items[overContainer].length
						),
					],
				};
			});
		}
	};

	return (
		<DndContext
			sensors={sensors}
			collisionDetection={collisionDetectionStrategy}
			measuring={{
				droppable: {
					strategy: MeasuringStrategy.Always,
				},
			}}
			cancelDrop={cancelDrop}
			modifiers={modifiers}
			onDragStart={({ active }) => {
				setActiveId(active.id);
				setClonedItems(items);
			}}
			onDragOver={useDebouncedCallback(onDragOver, 10)}
			onDragEnd={({ active, over }) => {
				if (active.id in items && over?.id) {
					setContainers((containers) => {
						const activeIndex = containers.indexOf(active.id);
						const overIndex = containers.indexOf(over.id);

						return arrayMove(containers, activeIndex, overIndex);
					});
				}

				const activeContainer = findContainer(active.id);

				if (!activeContainer) {
					setActiveId(null);
					return;
				}

				const overId = over?.id;

				if (overId == null) {
					setActiveId(null);
					return;
				}

				const overContainer = findContainer(overId);

				if (overContainer) {
					const activeIndex = items[activeContainer].indexOf(active.id);
					const overIndex = items[overContainer].indexOf(overId);

					if (activeIndex !== overIndex) {
						setItems((items) => ({
							...items,
							[overContainer]: arrayMove(
								items[overContainer],
								activeIndex,
								overIndex
							),
						}));
					}
				}

				setActiveId(null);
			}}
			onDragCancel={onDragCancel}
		>
			<div
				style={{
					display: 'inline-grid',
					boxSizing: 'border-box',

					gridAutoFlow: vertical ? 'row' : 'column',
					width: '100%',
				}}
			>
				<SortableContext
					items={[...containers]}
					strategy={
						vertical
							? verticalListSortingStrategy
							: horizontalListSortingStrategy
					}
				>
					{containers.map((containerId) => (
						<DroppableContainer
							key={containerId}
							id={containerId}
							label={
								minimal
									? undefined
									: containerId === 'A'
										? activeText
										: inactiveText
							}
							columns={columns}
							items={items[containerId]}
							scrollable={scrollable}
							style={containerStyle}
							unstyled={minimal}
						>
							<SortableContext
								items={items[containerId]}
								strategy={rectSortingStrategy}
							>
								{items[containerId].map((value, index) => {
									return (
										<SortableItem
											key={value}
											disabled={isSortingContainer}
											id={value}
											hexColors={hexColors}
											setHexColors={setHexColors}
											tilesText={tilesText}
											setTilesText={setTilesText}
											index={index}
											style={getItemStyles}
											wrapperStyle={wrapperStyle}
											renderItem={renderItem}
											containerId={containerId}
											getIndex={getIndex}
											translatedTileNames={translatedTileNames}
										/>
									);
								})}
							</SortableContext>
						</DroppableContainer>
					))}
				</SortableContext>
			</div>
			{createPortal(
				<DragOverlay adjustScale={adjustScale} dropAnimation={dropAnimation}>
					{activeId
						? containers.includes(activeId)
							? renderContainerDragOverlay(activeId)
							: renderSortableItemDragOverlay(activeId)
						: null}
				</DragOverlay>,
				document.body
			)}
			<div style={{ height: '100px' }} />
		</DndContext>
	);

	function renderSortableItemDragOverlay(id) {
		return (
			<Item
				dragOverlay
				value={id}
				hexColors={hexColors}
				setHexColors={setHexColors}
				tilesText={tilesText}
				setTilesText={setTilesText}
				style={getItemStyles({
					containerId: findContainer(id),
					overIndex: -1,
					index: getIndex(id),
					value: id,
					isSorting: true,
					isDragging: true,
					isDragOverlay: true,
				})}
				wrapperStyle={wrapperStyle({ index: 0 })}
				renderItem={renderItem}
				translatedTileNames={translatedTileNames}
			/>
		);
	}

	function renderContainerDragOverlay(containerId) {
		return (
			<Container
				shadow
				label={`Column ${containerId}`}
				columns={columns}
				style={{
					height: '100%',
				}}
				unstyled={false}
			>
				{items[containerId].map((item, index) => (
					<Item
						key={item}
						value={item}
						hexColors={hexColors}
						setHexColors={setHexColors}
						tilesText={tilesText}
						setTilesText={setTilesText}
						style={getItemStyles({
							containerId,
							overIndex: -1,
							index: getIndex(item),
							value: item,
							isDragging: false,
							isSorting: false,
							isDragOverlay: false,
						})}
						wrapperStyle={wrapperStyle({ index })}
						renderItem={renderItem}
						translatedTileNames={translatedTileNames}
					/>
				))}
			</Container>
		);
	}
}

function SortableItem({
	disabled,
	id,
	index,
	renderItem,
	style,
	containerId,
	getIndex,
	wrapperStyle,
	translatedTileNames,
	hexColors,
	setHexColors,
	tilesText,
	setTilesText,
}) {
	const {
		setNodeRef,
		setActivatorNodeRef,
		listeners,
		isDragging,
		isSorting,
		over,
		overIndex,
		transform,
		transition,
	} = useSortable({
		id,
	});
	const mounted = useMountStatus();
	const mountedWhileDragging = isDragging && !mounted;

	return (
		<Item
			ref={disabled ? undefined : setNodeRef}
			value={id}
			hexColors={hexColors}
			setHexColors={setHexColors}
			tilesText={tilesText}
			setTilesText={setTilesText}
			dragging={isDragging}
			sorting={isSorting}
			index={index}
			wrapperStyle={wrapperStyle({ index })}
			style={style({
				index,
				value: id,
				isDragging,
				isSorting,
				overIndex: over ? getIndex(over.id) : overIndex,
				containerId,
			})}
			transition={transition}
			transform={transform}
			fadeIn={mountedWhileDragging}
			listeners={listeners}
			renderItem={renderItem}
			translatedTileNames={translatedTileNames}
		/>
	);
}

function useMountStatus() {
	const [isMounted, setIsMounted] = useState(false);

	useEffect(() => {
		const timeout = setTimeout(() => setIsMounted(true), 500);

		return () => clearTimeout(timeout);
	}, []);

	return isMounted;
}
