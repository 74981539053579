import { Progress, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Link as Scroll } from 'react-scroll';
import { COLORS } from 'src/_shared/styles/colors';
import {
	ConditionalWrapper,
	getCareerProfileCompletionPercent,
} from 'src/helpers';

export function ProgressIndicator({ careerProgress }) {
	const [percentComplete, setPercentComplete] = useState(0);
	const [steps, setSteps] = useState([]);

	useEffect(() => {
		const stepBuilder = Object.values(careerProgress);
		setSteps(() => [...stepBuilder]);
		setPercentComplete(() => getCareerProfileCompletionPercent(careerProgress));
	}, [percentComplete, careerProgress]);

	return (
		<>
			<div className="progress-circle-wrap">
				<Progress
					type="circle"
					width={80}
					percent={percentComplete}
					format={(percent) => `${percent}% Complete`}
				/>
			</div>
			<div className="progress-step-container">
				{steps.map((step, i) => {
					const color = step.value ? COLORS.green : '#888';
					return (
						<ConditionalWrapper
							key={i}
							condition={step.route}
							wrapper={(children) =>
								step.route.startsWith('#') ? (
									<Scroll smooth to={step.route.slice(1)}>
										{children}
									</Scroll>
								) : (
									<Link to={step.route}>{children}</Link>
								)
							}
						>
							<Tooltip placement="top" title={step.message}>
								<div>
									<div
										className="progress-circle"
										style={{
											backgroundColor: color,
											border: `2px solid ${color}`,
										}}
									>
										<i className="icon-check progress-icon" />
									</div>
									<span
										className="progress-text"
										style={{
											textAlign: 'center',
											color: step.value ? null : COLORS.inputDisableColor,
										}}
									>
										{step.ui}
									</span>
								</div>
							</Tooltip>
						</ConditionalWrapper>
					);
				})}
			</div>
		</>
	);
}
