import { useEffect } from 'react';
import { userActions } from 'src/actions.js';

export default function LogoutComponent(props) {
	useEffect(() => {
		const asyncSignOut = async () => {
			const { signOut } = userActions;
			signOut();
			await props.handleSignOut();
			props.history.push('/');
		};

		asyncSignOut();
	}, []);

	return <div></div>;
}
