export const createBonus = `mutation CreateBonus($input: CreateBonusInput!) {
    createBonus(input: $input) {
      id
      amountDue
      companyId
      contactId
      contact {
        id
        firstName
        lastName
        emailAddress
        accountClaimId
        accountClaim {
          id
          employeeId
        }
      }
      userId
      user {
        id
        firstName
        lastName
        emailAddress
        location
        title
        active
        userGroupId
        userGroup {
          id
          measurement
          name
          currency
        }
        accountClaimId
        accountClaim {
          id
          active
          eligible
          employeeId
          userId
          companyNumber
        }
      }
      jobId
      job {
        id
        title
        referralBonus
        externalJobId
      }
      referralId
      referral {
        id
        jobId
        referralType
        status
        bonusStatus
      }
      hireDate
      startDate
      earnedDate
      payment
      recipientType
      bonusStatus
      notes
      paidDate
    }
  }
  `;
