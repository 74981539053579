import gql from 'graphql-tag';

export const listWebNotifications = gql`
	query ListWebNotifications(
		$filter: TableWebNotificationFilterInput
		$limit: Int
		$nextToken: String
	) {
		listWebNotifications(
			filter: $filter
			limit: $limit
			nextToken: $nextToken
		) {
			items {
				id
				type
				user {
					id
					company {
						id
						name
					}
					avatar {
						bucket
						region
						key
					}
					firstName
					lastName
				}
				referralId
				referral {
					id
					user {
						id
						firstName
						lastName
					}
					contact {
						id
						firstName
						lastName
					}
				}
				jobId
				job {
					id
					title
					createdBy {
						id
						firstName
						lastName
					}
				}
				matches
				dateCreated
				contactId
				requestingUserId
				requestingUser {
					id
					firstName
					lastName
					avatar {
						bucket
						region
						key
					}
				}
				questionsData
			}
			nextToken
		}
	}
`;
