import get from 'lodash/get';
import { Link } from 'react-router-dom';
import { borderedTile } from '../../../_shared/components/bordered-tile';
import {
	centeredText,
	grayHeaderClass,
	jobTitleText,
} from '../referral-card/referralCardStyle.js';

function BaseJobNetworkCard(props) {
	return (
		<div style={{ height: 165 }}>
			<h2 className={grayHeaderClass}>Job Network</h2>
			<div className={centeredText} style={{ fontSize: 12, marginBottom: 10 }}>
				You can browse jobs and request referrals from:
			</div>
			{get(props, 'extendedCompanies', []).map((company, index) => (
				<Link key={index} className={jobTitleText} to="/browsejobs">
					{get(company, 'name')}
				</Link>
			))}
		</div>
	);
}

export const JobNetworkCard = borderedTile(BaseJobNetworkCard);
