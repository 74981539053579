import get from 'lodash/get';
import { connect } from 'react-redux';
import { dashboardActions, referralActions } from 'src/actions.js';
import { compose } from '../../services/utils';
import { ReferralCardComponent } from './ReferralCardComponent.jsx';
import ReferralCardJobComponent from './ReferralCardJobComponent.jsx';

const mapStateToProperties = (state, ownProperties) => {
	const { currentUser } = state.user;
	const { allMultiLingualData } = state.dashboard;
	let getReferralBonus = get(ownProperties, 'referral.job.referralBonus');
	if (typeof getReferralBonus === 'string') {
		getReferralBonus = JSON.parse(
			get(ownProperties, 'referral.job.referralBonus', '')
		);
	}

	const referralBonus = get(getReferralBonus, 'tieredBonusId', 'skip');
	return {
		currentReferral: state.referral.currentReferral,
		referrals: state.referral.referrals,
		companyId: currentUser.companyId,
		tieredBonusQuery: {
			companyId: currentUser.companyId,
			id: referralBonus,
		},
		currentUser,
		allMultiLingualData,
	};
};

const mapDispatchToProperties = (dispatch) => {
	return {
		updateReferral(values) {
			dispatch(referralActions.updateReferral(values));
		},
		setMultiLingualData(data) {
			dispatch(dashboardActions.createSetMultiLingualData(data));
		},
	};
};

export const ReferralCard = connect(
	mapStateToProperties,
	mapDispatchToProperties
)(compose()(ReferralCardComponent));

export const ReferralCardJob = connect(
	mapStateToProperties,
	mapDispatchToProperties
)(compose()(ReferralCardJobComponent));
