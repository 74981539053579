import { Modal } from 'antd';
import PageOne from './PageOneComponent.jsx';
import PageTwo from './PageTwoComponent.jsx';
import { titleText } from './forgottenPasswordModalStyles.js';

function ForgottenPasswordModal(props) {
	const {
		visible,
		userEmail,
		page,
		setUserEmail,
		closeModal,
		setPageOne,
		setPageTwo,
	} = props;
	return (
		<Modal
			height={350}
			title={<span className={titleText}>Forgotten Password</span>}
			destroyOnClose={true}
			footer={null}
			open={visible}
			width={500}
			onCancel={closeModal}
			onOk={null}
		>
			{page === 1 && (
				<PageOne
					setPageTwo={setPageTwo}
					setUserEmail={setUserEmail}
					page={page}
				/>
			)}
			{page === 2 && (
				<PageTwo
					setPageOne={setPageOne}
					userEmail={userEmail}
					page={page}
					closeModal={closeModal}
					setUserEmail={setUserEmail}
				/>
			)}
		</Modal>
	);
}

export default ForgottenPasswordModal;
