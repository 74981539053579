import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { ArrowRightOutlined, EnvironmentOutlined } from '@ant-design/icons';
import { Alert, Button, Col, Input, Modal, Row, Select } from 'antd';
import { Auth } from 'aws-amplify';
import dayjs from 'dayjs';
import gql from 'graphql-tag';
import Cookies from 'js-cookie';
import _ from 'lodash';
import get from 'lodash/get';
import mixpanel from 'mixpanel-browser';
import { Component } from 'react';
import { withApollo } from 'react-apollo';
import { geolocated } from 'react-geolocated';
import PlacesAutocomplete from 'src/_shared/components/location/PlacesAutocomplete.jsx';
import { withRouter } from 'react-router-dom';
import {
	GetUserByCognitoId,
	updateUser,
} from 'src/_shared/api/graphql/custom/users/';
import Spinner from 'src/_shared/components/spinner/SpinnerComponent.jsx';
import { RouterContext } from 'src/_shared/contexts';
import { getLocation, graphql } from 'src/_shared/services/utils.js';
import uuid from 'uuid/v4';
import { newUserkeys } from '../newUserKeys.js';
import { PageItem } from './AddLocationPageItemComponent.jsx';
import { USStates } from './copy.js';
import { ExtendedLoginLinkModal } from './extended-user-merge/index.js';
import {
	AddLocationBtn as AddLocationButton,
	CheckIcon,
	FormStyles,
	InputStyles2,
	LabelStyles,
	MiscText,
	SubTitleStyles,
	SubmitBtn as SubmitButton,
	SubmitBtnContainer as SubmitButtonContainer,
	TitleStyles,
	btnText as buttonText,
} from './newUserFormStyles.js';
import { SelectStyles } from './selectStyles.js';

class NewExtendedUserLinkForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			serverError: false,
			showMergeAccountModal: false,
			department: '',
			geolocationAllowed: false,
			tokenData: this.props.tokenData,
			loading:
				get(this.props, 'tokenData.identities[0].providerName', null) ===
				'Rush',
			redirectURL: this.props.redirectURL,
			submitting: false,
			currencyAbbrev: 'USD',
			contactData: {},
			multiLingualData: '',
			ml_LetsStarted: '',
			ml_FirstComplete: '',
			ml_FirstName: '',
			ml_LastName: '',
			ml_Department: '',
			ml_Location: '',
			ml_AutofillLocation: '',
			ml_Email: '',
			ml_NotValid: '',
			ml_ByCreating: '',
			ml_Terms: '',
			ml_And: '',
			ml_Privacy: '',
			ml_Agree: '',
			ml_Problem: '',
			ml_GetStarted: '',
			address: '',
		};
	}

	async componentDidMount() {
		const permissions = await navigator.permissions.query({
			name: 'geolocation',
		});
		const geolocationAllowed = permissions?.state === 'granted';
		this.setState({ geolocationAllowed });
		if (geolocationAllowed) this.getCurrentLocation();
		await this.getMultiLingualData();
		const { multiLingualData } = this.state;
		if (multiLingualData.length > 0) {
			const languageCode = 'US';
			const filteredData = multiLingualData.filter(
				(item) => item.languageCode === languageCode
			);
			const letsStarted = filteredData.find(
				(item) => item.key === newUserkeys.LetsStarted
			);
			const firstComplete = filteredData.find(
				(item) => item.key === newUserkeys.FirstComplete
			);
			const firstName = filteredData.find(
				(item) => item.key === newUserkeys.FirstName
			);
			const lastName = filteredData.find(
				(item) => item.key === newUserkeys.LastName
			);
			const department = filteredData.find(
				(item) => item.key === newUserkeys.Department
			);
			const location = filteredData.find(
				(item) => item.key === newUserkeys.Location
			);
			const autofillLocation = filteredData.find(
				(item) => item.key === newUserkeys.AutofillLocation
			);
			const email = filteredData.find((item) => item.key === newUserkeys.Email);
			const notValid = filteredData.find(
				(item) => item.key === newUserkeys.NotValid
			);
			const byCreating = filteredData.find(
				(item) => item.key === newUserkeys.ByCreating
			);
			const terms = filteredData.find((item) => item.key === newUserkeys.Terms);
			const and = filteredData.find((item) => item.key === newUserkeys.And);
			const privacy = filteredData.find(
				(item) => item.key === newUserkeys.Privacy
			);
			const agree = filteredData.find((item) => item.key === newUserkeys.Agree);
			const problem = filteredData.find(
				(item) => item.key === newUserkeys.Problem
			);
			const getStarted = filteredData.find(
				(item) => item.key === newUserkeys.GetStarted
			);
			this.setState({
				ml_LetsStarted: get(letsStarted, 'text', "Let's Get Started"),
				ml_FirstComplete: get(
					firstComplete,
					'text',
					'First complete your profile'
				),
				ml_FirstName: get(firstName, 'text', 'First Name'),
				ml_LastName: get(lastName, 'text', 'Last Name'),
				ml_Department: get(department, 'text', 'Department'),

				ml_Location: get(location, 'text', 'Location'),
				ml_AutofillLocation: get(autofillLocation, 'text', 'Autofill Location'),
				ml_Email: get(email, 'text', 'Email Address'),
				ml_NotValid: get(notValid, 'text', 'Not a valid email address.'),
				ml_ByCreating: get(
					byCreating,
					'text',
					'By creating a profile you agree to the ERIN'
				),
				ml_Terms: get(terms, 'text', 'Terms of Use'),
				ml_And: get(and, 'text', 'and'),
				ml_Privacy: get(privacy, 'text', 'Privacy Policy,'),
				ml_Agree: get(
					agree,
					'text',
					'and agree that we can send you information about jobs and referrals at your company. You can opt out at any time.'
				),
				ml_Problem: get(
					problem,
					'text',
					'There was a problem creating your account. Please try again.'
				),
				ml_GetStarted: get(getStarted, 'text', 'Get Started'),
			});
		} else {
			this.setState({
				ml_LetsStarted: "Let's Get Started",
				ml_FirstComplete: 'First complete your profile',
				ml_FirstName: 'First Name',
				ml_LastName: 'Last Name',
				ml_Department: 'Department',
				ml_Location: 'Location',
				ml_AutofillLocation: 'Autofill Location',
				ml_Email: 'Email Address',
				ml_NotValid: 'Not a valid email address.',
				ml_ByCreating: 'By creating a profile you agree to the ERIN',
				ml_Terms: 'Terms of Use',
				ml_And: 'and',
				ml_Privacy: 'Privacy Policy,',
				ml_Agree:
					'and agree that we can send you information about jobs and referrals at your company. You can opt out at any time.',
				ml_Problem:
					'There was a problem creating your account. Please try again.',
				ml_GetStarted: 'Get Started',
			});
		}

		const provider = get(
			this.props,
			'tokenData.identities[0].providerName',
			null
		);
		if (provider === 'Rush') {
			this.handleSubmit();
		}
	}

	onAuthentication = (authToken, currentUser) => {
		try {
			const host = window.location.hostname;
			if (host === 'localhost') {
				Cookies.set('jwt', authToken);
			} else {
				Cookies.set('jwt', authToken, { secure: true, httpOnly: false });
			}

			this.setState({
				auth: true,
			});
		} catch (error) {
			console.log(error);
		}
	};

	onChange = (event_) => {
		const { value } = event_.target;
		this.setState({ currencyAbbrev: value });
	};

	getCurrentLocation = () => {
		if (navigator.geolocation) {
			const options = { timeout: 60_000 };
			navigator.geolocation.getCurrentPosition(
				this.showLocation,
				this.errorHandler,
				options
			);
		} else {
			console.log('Sorry, browser does not support geolocation!');
		}
	};

	async getMultiLingualData() {
		try {
			let multiLingualData = await graphql({
				input: { module: 'Registration' },
				query: 'queryMultiLingualByModuleIndex',
			});
			multiLingualData = multiLingualData?.items || multiLingualData;
			this.setState({
				multiLingualData,
			});
		} catch (error) {
			console.log(error);
		}
	}

	addLocation = async () => {
		if (this.props.isGeolocationAvailable) {
			const lat = this.props?.coords?.latitude;
			const lon = this.props?.coords?.longitude;
			const location = await getLocation({ lat, lon }, 'apiKey');
			this.setState({ address: location?.address, location });
		} else {
			console.log('Location is not available');
		}
	};

	errorHandler = (error) => {
		if (error.code == 1) {
			console.log('Error: Access is denied!');
		} else if (error.code == 2) {
			console.log('Error: Position is unavailable!');
		}
	};

	handleChangeLocation = (address) => {
		this.setState({ address });
	};

	handleSelectDepartment = (department) => {
		this.setState({ department });
	};

	handleSelectLocation = async (location) => {
		this.setState({ address: location?.address, location });
	};

	handleSelectState = (state) => {
		this.setState({ state });
	};

	handleSubmit = async (e) => {
		e.preventDefault();

		const { onCreate, onUpdateContact, onCreateContact, setCurrentUser } =
			this.props;
		const user = get(this.props, 'user', get(this.props, 'userLink'));
		this.setState({ serverError: false });
		const { err, values } = await new Promise((resolve) => {
			this.props.form.validateFields((error, values) => {
				resolve({ err: error, values });
			});
		});

		// If there was a validation error
		if (err) {
			return;
		}

		this.setState({ loading: true });

		const emailAddress = values.emailAddress.toLowerCase();
		let existingContacts = await graphql({
			input: { userId: user.id, emailAddress },
			query: 'queryContactsByUserIdEmailAddressIndex',
		});
		existingContacts = existingContacts?.items || existingContacts;
		let contact = get(existingContacts, '[0]');

		try {
			// Check if there's already an extended network user with this email address
			const userEmail = get(values, 'emailAddress', '')
				.split(' ')
				.join('')
				.toLowerCase();
			const existingUser = await graphql({
				input: { emailAddress: userEmail },
				query: 'getUserByEmailAddress',
			});
			// If there is an existing user, show the modal that allows them to "merge accounts"
			if (existingUser) {
				this.setState({
					emailError: true,
					loading: false,
					showMergeAccountModal: true,
				});

				return;
			}

			// If no existing user, make a new one
			const whiteLabel = get(this.props, 'whiteLabel');
			const companyId = whiteLabel
				? user.companyId
				: 'd5a17493-1e50-4a38-afa5-99623951d72e'; // Extended Network Company
			await Auth.signUp({
				username: uuid(),
				password: values.password,
				attributes: {
					email: emailAddress,
				},
			}).then((authData) => {
				Auth.signIn(
					values.emailAddress.toLowerCase().trim(),
					values.password.trim()
				).then(async (authUser) => {
					contact = contact
						? contact
						: await onCreateContact({
								firstName: values.firstName,
								lastName: values.lastName,
								emailAddress,
								userId: user.id,
								companyId: user.companyId,
								inviteStatus: 'pending',
							}).then((result) => result.data.createContact);
					const extendedUserInput = {
						cognitoId: authData.user.username,
						companyId,
						emailAddress,
						extendedCompanies: JSON.stringify([user.companyId]),
						extendedContactIds: JSON.stringify([contact.id]),
						inviteStatus: 'accepted',
						role: 'extendedUser',
						firstName: values.firstName,
						lastName: values.lastName,
						departmentId: 'f5da3fd2-2ae5-41c3-8c8c-b901b7ec8eb0', // Extended Network Department
						active: true,
						createdById: user.id,
						userGroupId: '3391aa35-275b-47ee-a505-f59cbef37b42', // Extended Network Default User Group
						currency: get(this.state, 'currencyAbbrev', 'USD'),
					};
					if (this.state?.location)
						extendedUserInput.location = JSON.stringify(this.state.location);
					const extendedUser = await onCreate(extendedUserInput).then(
						(result) => result.data.createUser
					);
					// Update contact to accepted status, and put extended user id on the contact
					await onUpdateContact({
						id: contact.id,
						inviteStatus: 'accepted',
						extendedUserId: extendedUser.id,
					}).then(async () => {
						const { data } = await this.props.client.query({
							query: GetUserByCognitoId,
							variables: { cognitoId: authUser.username },
						});
						const currentUser = {
							...data.getUserByCognitoId,
							authMethod: 'credentials',
							lastLogin: dayjs().toISOString(),
						};
						if (currentUser && !currentUser.active) {
							const error = new Error('User Disabled');
							error.code = 'UserDisabledException';
							throw error;
						} else {
							setCurrentUser(currentUser);
							mixpanel.identify(currentUser.id);
							mixpanel.register({
								'Company Name': currentUser?.company?.name,
								'Company ID': currentUser?.company?.id,
							});
							mixpanel.track('Account Created');
							mixpanel.track('Logged In');
							localStorage.setItem('mixpanel_user_identified', 'true');
							this.onAuthentication(this.props.location.hash, currentUser);
							await this.props.client.mutate({
								mutation: gql(updateUser),
								variables: {
									input: {
										id: data.getUserByCognitoId.id,
										authMethod: 'credentials',
										lastLogin: dayjs().toISOString(),
										accessToken:
											authUser.signInUserSession.accessToken.jwtToken,
										expires: false,
									},
								},
							});
							this.onAuthentication(
								authUser.signInUserSession.accessToken.jwtToken,
								currentUser
							);
							window.location.href = '/dashboard';
						}
					});
				});
			});
		} catch (error) {
			console.log('Error signing up user:', error);
			this.setState({
				loading: false,
				serverError: true,
			});
		}
	};

	showLocation = async (position) => {
		const lat = position?.coords?.latitude;
		const lon = position?.coords?.longitude;
		const location = await getLocation({ lat, lon }, 'apiKey');
		this.setState({ address: location?.address, location });
	};

	renderLocation = () => {
		const { ml_Location, ml_AutofillLocation } = this.state;
		const { Option } = Select;
		const options = [];
		Object.keys(USStates).map((key) =>
			options.push(<Option key={key}>{USStates[key]}</Option>)
		);
		return (
			<div>
				<PageItem>
					<Col md={24} xs={48}>
						<label className={LabelStyles}>{ml_Location}</label>
						{this.state?.geolocationAllowed && (
							<Button className={AddLocationButton} onClick={this.addLocation}>
								<EnvironmentOutlined />
								<span className={buttonText}>{ml_AutofillLocation}</span>
							</Button>
						)}
					</Col>
					<Col md={24} xs={16}>
						<div>
							<PlacesAutocomplete
								address={this.state.address}
								auth="apiKey"
								setAddress={this.handleChangeLocation.bind(this)}
								onSelect={this.handleSelectLocation.bind(this)}
							/>
						</div>
					</Col>
				</PageItem>
			</div>
		);
	};

	render() {
		const { getFieldDecorator } = this.props.form;
		const {
			contact,
			loading,
			serverError,
			showMergeAccountModal,
			submitting,
			ml_LetsStarted,
			ml_FirstComplete,
			ml_FirstName,
			ml_LastName,
			ml_Email,
			ml_NotValid,
			ml_ByCreating,
			ml_Terms,
			ml_And,
			ml_Privacy,
			ml_Agree,
			ml_Problem,
			ml_GetStarted,
		} = this.state;
		const FormItem = Form.Item;
		const theme = null;
		const whiteLabel = get(this.props, 'whiteLabel');

		if (submitting) {
			return (
				<div style={{ width: '100%' }}>
					<Spinner />
				</div>
			);
		}

		return (
			<div>
				<Row className="justify-content-center">
					<h1 className={TitleStyles}>{ml_LetsStarted}</h1>
				</Row>
				<Row className="justify-content-center">
					<h2 className={SubTitleStyles}>{ml_FirstComplete}</h2>
				</Row>
				<Form className={FormStyles}>
					<Row>
						<Col md={11} xs={24}>
							<FormItem className={InputStyles2}>
								<label className={LabelStyles}>{ml_FirstName}</label>
								{getFieldDecorator('firstName', {
									rules: [
										{
											required: true,
											message: 'Please enter your first name',
										},
									],
								})(<Input />)}
							</FormItem>
						</Col>
						<Col md={{ span: 12, offset: 1 }} xs={24}>
							<FormItem className={InputStyles2}>
								<label className={LabelStyles}>{ml_LastName}</label>
								{getFieldDecorator('lastName', {
									rules: [
										{
											required: true,
											message: 'Please enter your last name',
										},
									],
								})(<Input />)}
							</FormItem>
						</Col>
					</Row>
					<Row>{this.renderLocation()}</Row>
					<FormItem className={InputStyles2}>
						<label className={LabelStyles}>{ml_Email}</label>
						{getFieldDecorator('emailAddress', {
							rules: [
								{
									type: 'email',
									message: ml_NotValid,
								},
							],
						})(<Input disabled={false} />)}
					</FormItem>
					<FormItem className={InputStyles2}>
						<label className={LabelStyles}>Create Password</label>
						{getFieldDecorator('password', {
							rules: [
								{
									required: true,
									message: 'Password Required',
								},
								{ min: 8 },
							],
						})(<Input.Password className={InputStyles2} autoComplete="on" />)}
					</FormItem>

					{!whiteLabel && (
						<p className={MiscText}>
							{ml_ByCreating}{' '}
							<a
								href="http://erinapp.com/terms-of-use"
								rel="noopener noreferrer"
								target="_blank"
							>
								{ml_Terms}
							</a>{' '}
							{ml_And}{' '}
							<a
								href="https://erinapp.com/privacy-policy"
								rel="noopener noreferrer"
								target="_blank"
							>
								{ml_Privacy}
							</a>{' '}
							{ml_Agree}
						</p>
					)}
					{serverError ? <Alert type="error" message={ml_Problem} /> : null}

					<FormItem className={SubmitButtonContainer(theme)}>
						<Button
							className={SubmitButton(theme)}
							onClick={_.debounce(this.handleSubmit, 300, {
								leading: true,
								trailing: false,
							})}
						>
							{ml_GetStarted}
							<ArrowRightOutlined className={CheckIcon} />
						</Button>
					</FormItem>
				</Form>
				<Modal
					cancelButtonProps={{ style: { display: 'none' } }}
					open={loading}
					footer={null}
					closable={false}
					width="100%"
					bodyStyle={{
						backgroundColor: '#242e3f',
						height: window.innerHeight,
					}}
					style={{
						top: '0px',
					}}
				>
					<div
						style={{
							marginTop: '10%',
						}}
					>
						<Spinner />
					</div>
				</Modal>
				<RouterContext.Consumer>
					{({ onAuthentication }) => (
						<ExtendedLoginLinkModal
							contact={contact}
							visible={showMergeAccountModal}
							closeModal={() => this.setState({ showMergeAccountModal: false })}
							client={this.props.client}
							onAuthentication={onAuthentication}
							onUpdateContact={this.props.onUpdateContact}
						/>
					)}
				</RouterContext.Consumer>
			</div>
		);
	}
}

export default withApollo(
	withRouter(
		Form.create()(
			geolocated({
				positionOptions: {
					enableHighAccuracy: true,
				},
				userDecisionTimeout: 60_000,
			})(NewExtendedUserLinkForm)
		)
	)
);
