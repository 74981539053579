import gql from 'graphql-tag';

export const GetAccountClaimByEmployeeId = gql`
	query GetAccountClaimByEmployeeId($employeeId: String!) {
		getAccountClaimByEmployeeId(employeeId: $employeeId) {
			id
			active
			claimed
			eligible
			companyId
			company {
				id
				theme
				symbol {
					bucket
					region
					key
				}
				background {
					bucket
					region
					key
				}
				errorImage {
					bucket
					region
					key
				}
				logo {
					bucket
					region
					key
				}
				whiteLabel
				brandColor
			}
			dateOfBirth
			employeeId
			firstName
			lastName
			title
			userId
			department
			group
		}
	}
`;
