import { css } from 'emotion';
import { COLORS } from '../../../_shared/styles/colors';

export const topContainer = css({
	display: 'flex',
	width: '100%',
	justifyContent: 'space-between',
	marginBottom: 20,
});

export const jobInfoContainer = css({
	height: 'auto',
});

export const jobTitleText = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 600,
	color: COLORS.black,
	fontSize: 22,
});

export const jobIcons = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 300,
	color: COLORS.subHeading,
	fontSize: 16,
});

export const jobDetailRow = css({
	marginTop: 5,
});

export const Department = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 400,
	color: COLORS.darkGray,
	fontSize: 16,
	marginLeft: 5,
	marginRight: 5,
});
export const jobLocation = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 400,
	color: COLORS.darkGray,
	fontSize: 16,
	marginRight: 5,
});

export const bonusAmount = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 400,
	color: COLORS.green,
	fontSize: '1em',
});

export const bonusStyles = css({
	fontFamily: '"Open Sans", sans-serif',
	marginTop: '5px',
	fontSize: 18,
	fontWeight: 400,
	color: '#444444',
});

export const sectionContainer = css({
	marginBottom: 15,
});

export const descriptionContainer = css({
	marginBottom: 15,
	minHeight: 230,
});

export const summaryTitle = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 400,
	color: COLORS.heading,
	fontSize: '1.25em',
});

export const summaryValue = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 200,
	color: COLORS.darkGray,
	fontSize: '1.25em',
});

export const jobLinkStyles = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: '600 !important',
	color: COLORS.hyperLink,
	fontSize: '1em',
});

export const BtnContainer = css({
	marginRight: 5,
	'& .ant-btn: hover': {
		color: COLORS.hyperLink,
		borderColor: COLORS.hyperLink,
	},
	'& button:hover > div > svg > path': {
		fill: COLORS.hyperLink,
	},
	'& button:focus > div > svg > path': {
		fill: COLORS.hyperLink,
	},
});

export const shareButtonStyles = css({
	height: 50,
	borderColor: COLORS.white,
	backgroundColor: COLORS.blue,
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 200,
	color: COLORS.white,
	fontSize: '1.25em',
	'&:hover': {
		backgroundColor: COLORS.blue,
		borderColor: COLORS.white,
		color: COLORS.white,
	},
	'&:focus': {
		backgroundColor: COLORS.blue,
		borderColor: COLORS.white,
		color: COLORS.white,
	},
});

export const buttonIconStyles = css({
	fontWeight: 300,
	color: COLORS.white,
	fontSize: '1.45em',
});

export const footerTextStyles = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 400,
	color: COLORS.heading,
	fontSize: '1.25em',
});

export const hiringManagerLinkStyles = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 600,
	color: COLORS.hyperLink,
	fontSize: '1.25em',
});

export const footerStyles = css({
	marginBottom: 40,
});

export const cardHeadStyle = {
	borderWidth: 0,
	padding: 5,
	marginTop: -15,
};

export const cardBodyStyle = {
	padding: 5,
};

export const reqNumber = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 400,
	color: COLORS.gray3,
	fontSize: 16,
});

export const generalContainer = css({
	justifyContent: 'center',
	alignItems: 'center',
	backgroundColor: COLORS.lightGreen,
	width: 'auto',
	borderRadius: 4,
	display: 'inline-block',
	borderColor: COLORS.lightGreen,
	flex: '0, 0, auto',
	margin: 3,
	paddingBottom: 2,
});

export const generalText = css({
	color: COLORS.green,
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 600,
	fontSize: 14,
	margin: 5,
});
