import { css } from 'emotion';
import { COLORS } from '../../_shared/styles/colors';

export const departmentContainer = css({
	justifyContent: 'center',
	alignItems: 'center',
	backgroundColor: COLORS.lightGreen,
	width: 'auto',
	borderRadius: 4,
	display: 'inline-block',
	borderColor: COLORS.lightGreen,
	flex: '0, 0, auto',
	margin: 3,
	paddingBottom: 2,
});

export const closeIcon = css({
	fontSize: '14',
	color: COLORS.green,
	marginRight: 5,
});

export const departmentText = css({
	color: COLORS.green,
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 400,
	fontSize: 14,
	margin: 5,
});

export const DepartmentsContainer = css({
	display: 'flex',
	flexWrap: 'wrap',
});
