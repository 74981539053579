import gql from 'graphql-tag';
import get from 'lodash/get';
import { compose, graphql } from 'react-apollo';
import { listDepartments } from '../../graphql/custom/departments';
import {
	GetEmployeeByIdDetails as GetEmployeeById,
	updateUser,
} from '../../graphql/custom/users';

export const withUserByIdEditProfile = (Component, variables) => {
	return compose(
		graphql(gql(GetEmployeeById), {
			options(props) {
				return { variables, fetchPolicy: 'cache-and-network' };
			},
			props(res, props) {
				const user = get(res, ['data', 'getUser'], get(props, 'user', {}));
				return { currentUser: user };
			},
		}),
		graphql(gql(listDepartments), {
			options: (props) => ({
				variables: {
					filter: {
						companyId: { eq: props.currentUser.companyId },
						active: { eq: true },
					},
					limit: 1000,
					nextToken: null,
				},
				fetchPolicy: 'cache-and-network',
			}),
			props(props) {
				let departmentList = props?.ownProps?.currentUser?.company?.departments
					? [...props?.ownProps?.currentUser?.company?.departments]
					: [];
				departmentList = departmentList.filter((item) => item.active == true);
				return {
					departments: departmentList ? departmentList : [],
				};
			},
		}),
		graphql(gql(updateUser), {
			props: (props) => ({
				onUpdate(input) {
					const optimisticResponseData = {
						...props.ownProps.currentUser,
						...input.input,
					};
					props.mutate({
						variables: input,
						optimisticResponse: {
							__typename: 'Mutation',
							updateUser: {
								...optimisticResponseData,
								__typeName: 'updateUser',
							},
						},
						update(proxy) {
							const { getUser } = proxy.readQuery({
								query: gql(GetEmployeeById),
								variables: { id: props.ownProps.currentUser.id },
							});
							const newData = get(input, 'input');
							const data = { getUser: { ...getUser, ...newData } };
							proxy.writeQuery({
								query: gql(GetEmployeeById),
								data,
								variables: { id: props.ownProps.currentUser.id },
							});
						},
					});
				},
			}),
		})
	)(Component);
};
