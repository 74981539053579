import { Button, Col, Modal, Row } from 'antd';
import _, { get } from 'lodash';
import { Component } from 'react';
import { withApollo } from 'react-apollo';
import { Link } from 'react-router-dom';
import Spinner from 'src/_shared/components/spinner/SpinnerComponent.jsx';
import { ml, searchOpenSearchNetwork } from 'src/_shared/services/utils.js';
import { JOB_STATUSES, USER_ROLES } from 'src/_shared/constants/index.js';
import {
	miscFieldErrors,
	referralBonusErrors,
	requiredFields,
} from 'src/manageJobs/errorHandlers.js';
import { InternalMobilityCard } from 'src/manageJobs/jobDetail/internalMobilityCard/index.js';
import { JobDescriptionCard } from 'src/manageJobs/jobDetail/jobDescriptionCard/index.js';
import { JobDetailsCard } from 'src/manageJobs/jobDetail/jobDetailsCard/index.js';
import { ReferralSummaryCard } from 'src/manageJobs/jobDetail/referralSummaryCard/index.js';
import { InfiniteReferralCards } from 'src/_shared/components/index.js';
import { AddJob } from '../addJobModal';
import { spacer } from './jobDetailStyles.js';
import { CloseJob } from './close-job-modal';

const filterAllKey = 'All';
const filterAcceptedKey = 'accepted';
const filterReferredKey = 'referred';

class JobDetail extends Component {
	constructor(props) {
		super(props);
		this.state = {
			company: get(props, 'currentUser.company'),
			id: props.id,
			page: 0,
			errors: {},
			editing: false,
			filterReferralsBy: filterAllKey,
			closeJobModalVisible: false,
			loading: true,
			buttonState: '',
			isHotJob: get(props, 'currentJob.isHotJob'),
			searchedEmployees: null,
		};
	}

	async componentDidMount() {
		const { onUpdateJob, currentJob } = this.props;
		this.setState({
			isHotJob: get(currentJob, 'isHotJob'),
		});
		const { id } = this.state;

		if (currentJob) {
			this.setState({ currentJob }, () => {
				onUpdateJob({
					id,
					views: currentJob.views + 1,
				}).catch((error) => console.error('Update Job View Error:', error));
			});
		}

		await this.setQueryToState(get(this.state, 'searchQuery', ''), 0);
	}

	setQueryToState = async (searchQuery = '', timeout = 500) => {
		const {
			company,
			filteredDepartments = [],
			filteredRoles = [],
			filteredStatus = 'all',
		} = this.state;
		this.setState({ searchQuery });
		clearTimeout(this.timer);
		if (searchQuery === '' && filteredDepartments.length <= 0) {
			this.setState({
				loading: false,
				searchedEmployees: [],
			});
		}

		this.setState({ loading: true });
		this.timer = setTimeout(async () => {
			const parameters = {
				query: searchQuery,
				size: 300,
				role: get(this.props, 'currentUser.role'),
				currentUser: get(this.props, 'currentUser.id'),
				filters: {
					companies: get(company, 'id'),
					departments: filteredDepartments,
					roles: filteredRoles,
					active: filteredStatus,
					openToNewRole: true,
				},
			};

			const response = await searchOpenSearchNetwork(parameters, 'erin-users');
			if (get(response, 'query') === get(this.state, 'searchQuery')) {
				this.setState({
					loading: false,
					searchedEmployees: [...get(response, 'data', [])],
				});
			}
		}, timeout);
	};

	editJob = () => {
		this.setState({ editing: true });
	};

	filterReferrals = (property, match, referrals) => {
		if (!referrals) return [];
		if (match === filterAllKey) return referrals;
		if (match === 'accepted')
			return referrals.filter(
				(r) =>
					r[property] === match ||
					r.status === 'hired' ||
					r.status === 'interviewing' ||
					r.status === 'notHired'
			);
		return referrals.filter((r) => r[property] === match);
	};

	handleBack = () => {
		this.setState({ page: this.state.page > 0 ? this.state.page - 1 : 0 });
	};

	handleCancel = () => {
		this.setState({ editing: false });
		this.resetForm();
	};

	handleCancelCloseJob = () => {
		this.setState({
			closeJobModalVisible: false,
		});
	};

	handleNext = (page, values) => {
		if (this.pageIsValid(this.state.page, values)) {
			this.setState(
				{ page: this.state.page < 3 ? this.state.page + 1 : 3 },
				() => {
					this.props.updateAddJobForm(values);
				}
			);
		}
	};

	handleOk = () => {
		this.setState({ page: 0 });
	};

	handleOpenJob = () => {
		const { currentJob } = this.props;
		const { id } = this.state;
		this.props.onUpdateJob({
			id,
			jobType: currentJob.jobType,
			status: JOB_STATUSES.OPEN,
		});
	};

	handleShowCloseJob = () => {
		this.setState({
			closeJobModalVisible: true,
		});
	};

	handleSubmit = () => {
		const { jobFormData, onUpdateJob, currentUser } = this.props;
		const { id } = this.state;
		let cityValue = null;
		let stateValue = null;
		let isRemoteValue = false;

		if (jobFormData.location.isRemote == false) {
			if (jobFormData.location.city == null && jobFormData.location.state) {
				const loca = jobFormData.location.state.split(',');
				cityValue = loca[0];
				loca.splice(0, 1);
				stateValue = loca.join(',');
			} else if (jobFormData.location.city && jobFormData.location.state) {
				cityValue = jobFormData.location.city;
				stateValue = jobFormData.location.state;
			}
		} else {
			isRemoteValue = true;
		}

		const job = {
			id,
			departmentId: jobFormData.department.id,
			jobType: jobFormData.jobType,
			title: jobFormData.title,
			description: jobFormData.description,
			internalJobLink: jobFormData.internalJobLink,
			hideImInterested: jobFormData.hideImInterested,
			isGeneralReferral: jobFormData.isGeneralReferral,
			publicLink: jobFormData.publicLink ? jobFormData.publicLink : null,
			externalJobId: jobFormData.externalJobId
				? jobFormData.externalJobId
				: null,
			salary: JSON.stringify({
				from: jobFormData.salary.from,
				to: jobFormData.salary.to,
				interval:
					jobFormData.salary.to || jobFormData.salary.from
						? jobFormData.salary.interval
						: null,
			}),
			location: JSON.stringify({
				...jobFormData.location,
				city: cityValue,
				state: stateValue,
				isRemote: isRemoteValue,
			}),
			hiringManagerId: jobFormData.hiringManager
				? jobFormData.hiringManager.id
				: null,
			subCompanyId:
				jobFormData.subCompanyId === '' ? null : jobFormData.subCompanyId,
			createdById: currentUser.id,
			referralBonus: JSON.stringify({
				hasBonus: jobFormData.referralBonus.hasBonus,
				amount: jobFormData.referralBonus.hasBonus
					? jobFormData.referralBonus.amount
					: null,
				tieredBonusId: jobFormData.referralBonus.hasBonus
					? jobFormData.referralBonus.tieredBonusId
					: null,
			}),
			notificationType: jobFormData.notificationType,
		};

		onUpdateJob(job);

		this.setState(
			{
				buttonState: 'loading',
			},
			() => {
				setTimeout(() => {
					this.setState({
						buttonState: 'success',
					});
				}, 5400);
			}
		);
		setTimeout(() => {
			this.setState({
				editing: false,
			});
		}, 6000);
	};

	pageIsValid = (currentPage, currentJob) => {
		let isValid = true;
		const errors = {};
		for (const { key, message, page } of requiredFields) {
			if (!currentJob[key] && page === currentPage) {
				errors[key] = message;
				isValid = false;
			}

			if (
				key === 'location' &&
				get(currentJob, 'location.isRemote') === false &&
				(get(currentJob, 'location.state') === null ||
					get(currentJob, 'location.city') === null)
			) {
				errors[key] = message;
				isValid = false;
			}
		}

		for (const { key, message, page } of referralBonusErrors) {
			const { referralBonus } = currentJob;
			if (
				referralBonus &&
				!referralBonus.amount &&
				referralBonus.hasBonus === true &&
				!referralBonus.tieredBonusId &&
				page === currentPage
			) {
				errors[key] = message;
				isValid = false;
			}
		}

		for (const { key, message, page } of miscFieldErrors) {
			const { salary } = currentJob;
			if (page === currentPage && salary && (!salary.to || !salary.from)) {
				continue;
			}

			if (page === currentPage && salary && salary.from > salary.to) {
				errors[key] = message;
				isValid = false;
			}
		}

		this.setState({
			errors,
		});

		return isValid;
	};

	resetForm = () => {
		this.props.resetFormFields();
		this.setState({
			page: 0,
		});
	};

	showCancelScreen = () => {
		const confirmCancelEditPage = 4;

		if (this.state.page === confirmCancelEditPage) {
			this.handleCancel();
		} else {
			this.setState({ page: confirmCancelEditPage });
		}
	};

	turnOnOffHotJob = (value) => {
		const { id } = this.state;
		this.props
			.onUpdateJob({
				id,
				isHotJob: value,
			})
			.then((res) => {
				this.setState({
					isHotJob: res.data.updateJob.isHotJob,
				});
			});
	};

	render() {
		const { managers, companyData } = this.props;
		const { company, isHotJob } = this.state;
		let path = window.location.pathname.split('/');
		const urlId = path.pop();
		path = path.join('/');
		const id = get(this.props, ['match', 'params', 'id'], urlId);
		if (get(this.props, ['currentJob', 'id'], false) !== id) {
			return <Spinner company={company} />;
		}

		let goodMatches = [];
		let internalMatches = [];
		const {
			editing,
			page,
			errors,
			isLoading,
			filterReferralsBy,
			closeJobModalVisible,
			buttonState,
			searchedEmployees,
			loading,
		} = this.state;

		const adminAndManagers = (managers || []).filter(
			(user) => user.active === true && user.role !== 'superAdmin'
		);
		const {
			allMultiLingualData,
			currentUser,
			jobFormData,
			currentCurrencyRate,
			currentCurrencySymbol,
			internalJobLink,
		} = this.props;
		const jobMatches = get(this.props, 'currentJob.jobMatches', []);
		if (!get(currentUser, 'company')) return <Spinner />;
		const { currentJob } = this.props;
		const supportAdmin = JSON.parse(get(this.props, 'currentUser.admin'));
		const supportAdminPermissions = get(supportAdmin, 'permissions') === '*';
		if (
			currentJob &&
			currentJob.companyId != currentUser.companyId &&
			!supportAdminPermissions
		) {
			return <Spinner company={company} />;
		}

		if (!currentJob) {
			return <Spinner company={company} />;
		}

		if (jobMatches) {
			goodMatches = jobMatches.filter(
				(jobMatch) => jobMatch.relevance >= 30 && jobMatch.matchStatus !== false
			);
			if (searchedEmployees) {
				internalMatches = searchedEmployees.filter((employee) => {
					return goodMatches.some((match) => employee.id === match.userId);
				});
			}
		}

		const references = [...get(this.props, 'gdprReferrals', [])];
		const sortedReferrals = _.sortBy(references, function (referral) {
			return referral.referralDate;
		}).reverse();

		const referredOnlyReferrals = this.filterReferrals(
			'status',
			filterReferredKey,
			sortedReferrals
		);
		const acceptedOnlyReferrals = this.filterReferrals(
			'status',
			filterAcceptedKey,
			sortedReferrals
		);
		const allReferrals = this.filterReferrals(
			'status',
			filterAllKey,
			sortedReferrals
		);
		const _referrals = {
			[filterReferredKey]: referredOnlyReferrals,
			[filterAcceptedKey]: acceptedOnlyReferrals,
			[filterAllKey]: allReferrals,
		};
		const referralData = _referrals[filterReferralsBy];
		for (const referral of referralData) referral.referralId = referral.id;
		const numberHired = 0; // TODO: currentJob._accepted;
		const nomenclature = numberHired > 1 ? 'people' : 'person';
		// eslint-disable-next-line
		const hiredText = "You've hired"; // because of conflicting prettier/eslint rules
		const { referralBonuses } = this.props;
		let isEditJobDisabled = get(
			currentUser,
			'company.disableManagerPermissions',
			false
		);
		// Preserves backwards compatibility and future compatibility with disableManagerPermissions
		const managerJobs = currentUser.company?.disableManagerPermissionsByType
			? JSON.parse(currentUser.company.disableManagerPermissionsByType)
					.managerJobs
			: isEditJobDisabled
				? 'readOnly'
				: 'edit';
		isEditJobDisabled = managerJobs !== 'edit';
		const displayAs = get(currentUser, 'displayAs');
		const parsedOwnershipSettings = JSON.parse(
			get(companyData, 'ownershipSettings', '{}')
		);

		return (
			<main>
				<div className="page-title">
					<Link to="/jobs" className="custom-back-btn">
						<i className="icon-arrow-left" />
						{ml('Manage Jobs', currentUser, allMultiLingualData)}
					</Link>
					<ul className="info-action">
						<li>
							{isEditJobDisabled && displayAs === USER_ROLES.MANAGER ? null : (
								<Button type="link" className="btn-link" onClick={this.editJob}>
									<i className="icon-edit" />
									{ml('EDIT JOB', currentUser, allMultiLingualData)}
								</Button>
							)}
						</li>
					</ul>
				</div>
				<div className="mj-referral">
					<Row gutter={24}>
						<Col xs={24} xl={15}>
							<div className="manage-job-details">
								<JobDescriptionCard
									allMultiLingualData={this.props.allMultiLingualData}
									currentUser={currentUser}
									job={currentJob}
								/>
							</div>
						</Col>
						<Col xs={24} xl={9}>
							<>
								{numberHired > 0 && (
									<div className="mj-referral-card">
										<div className={spacer} />
										<p>
											{hiredText}
											<span>{`${numberHired}`}</span>
										</p>
										<p>{` ${nomenclature} for this job!`}</p>
									</div>
								)}
								{currentJob && currentJob.status === 'closed' ? (
									<div className="mj-referral-card mjc-gray">
										<div className="mjr-card-body">
											<p className="text-center">
												{isEditJobDisabled &&
												displayAs === USER_ROLES.MANAGER ? (
													<span>This Job is closed.</span>
												) : (
													<span>
														This Job is closed.{' '}
														<a
															href="javascript:void(0);"
															onClick={this.handleOpenJob}
														>
															Click here{' '}
														</a>
														open it.
													</span>
												)}
											</p>
										</div>
									</div>
								) : null}
								<ReferralSummaryCard
									allMultiLingualData={allMultiLingualData}
									currentUser={currentUser}
									job={currentJob}
									matches={goodMatches}
									gdprReferrals={get(this.props, 'gdprReferrals')}
								/>
								<JobDetailsCard
									allMultiLingualData={allMultiLingualData}
									currentUser={currentUser}
									job={currentJob}
									handleOpenJob={this.handleOpenJob}
									isHotJob={isHotJob}
									turnOnOffHotJob={this.turnOnOffHotJob}
									onClickCloseJob={this.handleShowCloseJob}
								/>
								<InternalMobilityCard
									allMultiLingualData={allMultiLingualData}
									currentUser={currentUser}
									matches={internalMatches}
									loading={loading}
								/>
							</>
						</Col>
					</Row>
				</div>

				<div className="rd-actions">
					<h4 className="rda-name">
						{ml('Referrals', currentUser, allMultiLingualData)}
					</h4>
					<div className="rda-filter">
						<span className="rda-label">
							View in{' '}
							<Link
								to={`/referrals?search=${currentJob.externalJobId || currentJob.id}`}
							>
								Manage Referrals
							</Link>{' '}
							for Advanced Filters & Sorting
						</span>
					</div>
				</div>
				<InfiniteReferralCards
					jobId={currentJob.id}
					itemsPerPage={50}
					currentUser={currentUser}
					allMultiLingualData={allMultiLingualData}
					currentJob={currentJob}
					gdprReferrals={sortedReferrals}
					referralBonuses={referralBonuses}
					currentCurrencyRate={currentCurrencyRate}
					currentCurrencySymbol={currentCurrencySymbol}
					parsedOwnershipSettings={parsedOwnershipSettings}
					onUpdateReferral={this.props.onUpdateReferral}
					onUpdateJob={this.props.onUpdateJob}
				/>
				<div>
					<Modal
						destroyOnClose
						open={editing}
						width={620}
						height={735}
						title={ml('Edit Job', currentUser, allMultiLingualData)}
						afterClose={this.resetForm.bind(this)}
						maskClosable={false}
						footer={null}
						onOk={null}
						onCancel={this.showCancelScreen}
					>
						{!isLoading && (
							<AddJob
								allMultiLingualData={allMultiLingualData}
								currentUser={currentUser}
								isNew={false}
								internalJobLink={internalJobLink}
								handleNext={this.handleNext}
								handleBack={this.handleBack}
								subCompanies={this.props.subCompanies}
								page={page}
								managers={adminAndManagers}
								currentJob={
									jobFormData.jobType.length > 0 ? jobFormData : currentJob
								}
								departments={this.props.departments}
								bonuses={this.props.bonuses}
								handleSubmit={this.handleSubmit}
								handleOk={this.handleOk}
								handleCancel={this.handleCancel}
								errors={errors}
								buttonState={buttonState}
								editing={editing}
							/>
						)}
					</Modal>
					<CloseJob
						allMultiLingualData={allMultiLingualData}
						currentUser={currentUser}
						visible={closeJobModalVisible}
						handleCancel={this.handleCancelCloseJob}
						referrals={sortedReferrals}
						job={this.props.currentJob}
						onUpdateJob={this.props.onUpdateJob}
						onUpdateReferral={this.props.onUpdateReferral}
					/>
				</div>
			</main>
		);
	}
}

export default withApollo(JobDetail);
