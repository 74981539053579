export const updateContact = `mutation UpdateContact($input: UpdateContactInput!) {
  updateContact(input: $input) {
    id
    atsId
    firstName
    lastName
    emailAddress
    extendedUserId
    socialMediaAccounts
    phoneNumber
    jobHistory
    importMethod
    inviteStatus
    fullContactData
    dateCreated
    onDeckStatus
    onDeckNote
    disableSmartReferrals
    confirmedEmail
    contactResume {
      bucket
      region
      key
    }
  }
}
`;
