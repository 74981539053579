import { createContext, useContext } from 'react';
import { useRnRAPIService } from '../../rewardsAndRecognitionHelper/service/RnRAPI.service.jsx';
import { MY_REWARDS_API_ENDPOINTS } from '../constants/MyRewards.constants.js';

const MyRewardsServiceContext = createContext(null);

export const MyRewardsServiceProvider = (props) => {
  const APIService = useRnRAPIService();

  const getRewardPointsAndBonuses = () => {
    return APIService.get(MY_REWARDS_API_ENDPOINTS.GET_REWARD_POINTS_AND_BONUSES);
  }

  const getEmployeeAddressById = (id) => {
    const url = MY_REWARDS_API_ENDPOINTS.EMPLOYEE_ADDRESS
    if (id) {
      url + `${id}`;
    }
    return APIService.get(url);
  }

  const createEmployeeAddress = (requestBody) => {
    return APIService.post(MY_REWARDS_API_ENDPOINTS.EMPLOYEE_ADDRESS, requestBody);
  }

  const updateEmployeeAddress = (id, requestBody) => {
    return APIService.put(`${MY_REWARDS_API_ENDPOINTS.EMPLOYEE_ADDRESS}/${id}`, requestBody);
  }

  const getPendingPhysicalAwards = () => {
    return APIService.get(MY_REWARDS_API_ENDPOINTS.GET_PENDING_PHYSICAL_AWARDS);
  }

  const managePhysicalAwardConfirmationStatus = (requestBody) => {
    return APIService.post(MY_REWARDS_API_ENDPOINTS.MANAGE_PHYSICAL_AWARD_CONFIRMATION_STATUS, requestBody);
  }

  const MyRewardsServiceOperations = {
    getRewardPointsAndBonuses,
    getEmployeeAddressById,
    createEmployeeAddress,
    updateEmployeeAddress,
    getPendingPhysicalAwards,
    managePhysicalAwardConfirmationStatus
  };

  return (
    <MyRewardsServiceContext.Provider
      value={MyRewardsServiceOperations}
    >
      {props.children}
    </MyRewardsServiceContext.Provider>
  );
};

export const useMyRewardsService = () => {
  return useContext(MyRewardsServiceContext);
};