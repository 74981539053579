import gql from 'graphql-tag';
import { compose, graphql } from 'react-apollo';
import { updateJob } from '../../graphql/custom/jobs';

export const withUpdateJob = (Component) => {
	return compose(
		graphql(gql(updateJob), {
			props: (props) => ({
				onUpdateJob(input) {
					const optimisticResponseData = {
						...input,
					};
					return props.mutate({
						variables: { input },
						optimisticResponse: {
							__typename: 'Mutation',
							updateJob: {
								...optimisticResponseData,
								__typename: 'updateJob',
							},
						},
					});
				},
			}),
		})
	)(Component);
};
