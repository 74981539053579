import gql from 'graphql-tag';
import get from 'lodash/get';
import { compose, graphql } from 'react-apollo';
import { parse } from 'src/_shared/services/utils.js';
import uuid from 'uuid/v4';
import { parseJsonFields } from '../../../services/parseApiService.js';
import {
	createBonus,
	deleteBonus,
	queryBonusByCompanyIdIndex,
	updateBonus,
} from '../../graphql/custom/bonuses';
import { updateJob, GetJobNoReferrals } from '../../graphql/custom/jobs';
import { updateReferral } from '../../graphql/custom/referrals';

export const withJobById = (Component, variables) => {
	return compose(
		graphql(GetJobNoReferrals, {
			options: {
				context: {
					headers: {
						'x-frame-options': 'deny', // This header will reach the server
					},
				},
				variables,
				fetchPolicy: 'cache-and-network',
			},
			props(response, previous) {
				let job = get(response, 'data.getJob');
				job ||= get(previous, 'currentJob');
				job &&= Object.assign(
					job,
					parseJsonFields(['location', 'salary', 'referralBonus'], job)
				);
				let tiers = get(job, 'tieredBonus.tiers', []);
				tiers = tiers.map((tier) => parse(tier));
				if (get(job, 'tieredBonus.tiers')) job.tieredBonus.tiers = tiers;
				return {
					currentJob: job,
				};
			},
		}),
		graphql(gql(updateJob), {
			props: (props) => ({
				onUpdateJob(input) {
					return props.mutate({
						variables: { input },
					});
				},
			}),
		}),
		graphql(gql(updateReferral), {
			props: (props) => ({
				onUpdateReferral(input) {
					props.mutate({
						variables: input,
						refetchQueries: [
							{
								query: GetJobNoReferrals,
								variables: {
									id: get(props, 'ownProps.jobId'),
								},
							},
						],
					});
				},
			}),
		}),
		graphql(gql(createBonus), {
			props: (props) => ({
				onCreateBonus(input) {
					const variables = {
						...input,
						id: uuid(),
					};
					props.mutate({
						variables,
					});
				},
			}),
		}),
		graphql(gql(updateBonus), {
			props: (props) => ({
				onUpdateBonus(input) {
					const optimisticResponseData = {
						...input.input,
						__typename: 'Bonus',
					};
					props.mutate({
						variables: input,
						optimisticResponse: {
							__typeName: 'Mutation',
							updateBonus: {
								__typeName: 'updateBonus',
								...optimisticResponseData,
							},
						},
						update(proxy, query) {
							try {
								const data = proxy.readQuery({
									query: gql(queryBonusByCompanyIdIndex),
									variables: {
										companyId: get(
											props,
											['ownProps', 'currentUser', 'companyId'],
											[]
										),
									},
								});
								const bonuses = get(data, 'queryBonusByCompanyIdIndex.items');

								proxy.writeQuery({
									query: gql(queryBonusByCompanyIdIndex),
									variables: {
										companyId: get(
											props,
											['ownProps', 'currentUser', 'companyId'],
											[]
										),
									},
									bonuses,
									data,
								});
							} catch (error) {
								console.log(error);
							}
						},
					});
				},
			}),
		}),

		graphql(gql(deleteBonus), {
			props: (props) => ({
				onDeleteBonus(input) {
					const variables = {
						...input,
					};
					props.mutate({
						variables,
					});
				},
			}),
		})
	)(Component);
};
