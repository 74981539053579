import { EnvironmentOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import get from 'lodash/get';
import { Link } from 'react-router-dom';
import WebIcon from 'src/_shared/components/WebIconComponent.jsx';
import { borderedTile } from '../../../_shared/components/bordered-tile';
import { COLORS } from '../../../_shared/styles/colors';
import {
	bonus,
	bonusAmount,
	bottomContainer,
	headerClass,
	jobDetailRow,
	jobIcons,
	jobInfoContainer,
	jobMatch,
	jobMatchNumber,
	jobTitleText,
	locationAndDepartment,
	topContainer,
} from './recReferralStyles.js';

function BaseRecReferral(props) {
	const { tileTitle, selectJob, matchNumber } = props;
	let locationDetails;
	let bonusDetails;
	let location;
	if (selectJob && selectJob.location) {
		locationDetails = JSON.parse(selectJob.location);
		bonusDetails = JSON.parse(selectJob.referralBonus);
	}

	if (locationDetails === undefined) {
		location = 'null, null';
	} else if (locationDetails) {
		location =
			locationDetails.isRemote === true
				? 'Remote'
				: get(locationDetails, 'city', 'N/A') +
					', ' +
					get(locationDetails, 'state', 'N/A');
	}

	return (
		<div style={{ height: 132 }}>
			<div>
				<h2 className={headerClass}>{tileTitle}</h2>
			</div>
			{selectJob === null ? (
				<p>Sorry, we don’t have any Smart Referrals for you.</p>
			) : (
				<div>
					<Row className={topContainer}>
						<Col className={jobInfoContainer}>
							<div>
								{selectJob && (
									<Link to={`/browsejobs/${selectJob.id}`}>
										<span className={jobTitleText}>{selectJob.title}</span>
									</Link>
								)}
							</div>
							<Row className={jobDetailRow}>
								<EnvironmentOutlined className={jobIcons} />
								<span className={locationAndDepartment}>
									{location.startsWith('null,') ? 'Unavailable ' : location}
								</span>
							</Row>
						</Col>
					</Row>
					{bonusDetails && (
						<Row>
							<div>
								{bonusDetails.amount && (
									<div className={bonus}>
										Referral Bonus:{' '}
										<span className={bonusAmount}>
											{' '}
											{`$${bonusDetails.amount}`.replaceAll(
												/\B(?=(\d{3})+(?!\d))/g,
												','
											)}
										</span>
									</div>
								)}
							</div>
						</Row>
					)}
					<Row className={bottomContainer}>
						<Row className={jobMatch}>
							<WebIcon
								name="tick-inside-circle"
								color={COLORS.green}
								size={14}
							/>
							<span className={jobMatchNumber}> {matchNumber} Person/s </span>{' '}
							In Your Network Match This Job!
						</Row>
					</Row>
				</div>
			)}
		</div>
	);
}

export const RecommendReferral = borderedTile(BaseRecReferral);
