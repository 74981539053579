import {
	Button,
	Drawer,
	Modal,
	Popconfirm,
	Progress,
	Spin,
	Tooltip,
	message,
} from 'antd';
import { css } from 'emotion';
import gql from 'graphql-tag';
import _, { get } from 'lodash';
import { useEffect, useState } from 'react';
import { withApollo } from 'react-apollo';
import { AiOutlineCloseCircle, AiOutlineShoppingCart } from 'react-icons/ai';
import { useHistory } from 'react-router-dom';
import { configMode } from 'src/_shared/api/';
import { GetCompanyPointsData } from 'src/_shared/api/graphql/custom/company/';
import { getUserPoints } from 'src/_shared/api/graphql/custom/users/';
import Spinner from 'src/_shared/components/spinner/SpinnerComponent.jsx';
import {
	getSetErrorImageURL,
	lambda,
	ml,
	parse,
} from 'src/_shared/services/utils.js';
import { v4 as uuidv4 } from 'uuid';
import fileIcon from '../_shared/assets/erin_lightgray.png';
import ErinFilterSelect from '../_shared/components/ErinFilterSelectComponent.jsx';
import brandsByCategory from './brandsByCategory.json';

// ToDo:
// Add a paginating option at the bottom of the page so not to render too many cards
// Track all company balances

function GiftCardStore({ allMultiLingualData, client, currentUser }) {
	const [isLoading, setIsLoading] = useState(true);
	const routeHistory = useHistory();
	if (!get(currentUser, 'company')) return <Spinner />;

	// If currentUser falsey, show error, and go back to prevous page
	if (!currentUser || !currentUser.id || !currentUser.companyId) {
		return (
			<>
				{alert(
					ml(
						`Sorry, some necessary data was missing to securely process your request. Please notify customer support.`,
						currentUser,
						allMultiLingualData
					)
				)}
				{routeHistory.goBack()}
			</>
		);
	}

	const giftCardStoreUrl =
		configMode === 'DEV' ? 'gift-card-store-dev' : 'gift-card-store-prod';

	const [products, setProducts] = useState();
	const [cart, setCart] = useState([]);
	const [points, setPoints] = useState(null);
	const [isCartOpen, setIsCartOpen] = useState(false);
	const [orderStatus, setOrderStatus] = useState('');
	const [desiredCardDenomination, setDesiredCardDenomination] = useState(null);
	const [pointsSettings, setPointsSettings] = useState(null);
	const [appliedFilters, setAppliedFilters] = useState([]);
	const [cardBeingViewed, setCardBeingViewed] = useState(null);
	const [modalVisible, setModalVisible] = useState(false);
	const [companyBalance, setCompanyBalance] = useState(0);
	const [errorImageURL, setErrorImageURL] = useState('');

	const updatePointsData = async (fetchPolicy = 'network-only') => {
		const company = await client
			.query({
				query: GetCompanyPointsData,
				fetchPolicy,
				variables: {
					id: currentUser.companyId,
				},
			})
			.then((response) => response.data.getCompany);
		const pointsSettings = parse(get(company, 'pointsSettings'));
		const giftCardStoreBalance = get(company, 'giftCardStoreBalance', 0);
		if (pointsSettings) {
			setPointsSettings(pointsSettings);
			setCompanyBalance(giftCardStoreBalance);
		}

		return { pointsSettings, giftCardStoreBalance };
	};

	// Get user points and error image upon page load
	useEffect(() => {
		(async () => {
			const points = await client
				.query({
					query: gql(getUserPoints),
					fetchPolicy: 'cache-first',
					variables: { id: currentUser.id },
				})
				.then((response) => response.data.getUserPoints.points);
			if (points) {
				setPoints(points);
			}

			const errorImageURL = await getSetErrorImageURL(
				currentUser?.company?.errorImage?.key
			);

			if (errorImageURL) {
				setErrorImageURL(errorImageURL);
			}
		})();
	}, []);

	// Get all products on page load
	useEffect(() => {
		(async () => {
			const products = await lambda({
				endpoint: giftCardStoreUrl,
				variables: { companyId: currentUser?.companyId, products: 'products' },
			});
			const pointsData = await updatePointsData();
			const balance = get(pointsData, 'giftCardStoreBalance');
			const results = get(products, 'results', []);
			products.results = results.filter((product) => {
				let { denominations } = product;
				denominations = denominations ? denominations : [];
				denominations = denominations.filter((denomination) => {
					return get(denomination, 'amount') / 100 <= balance;
				});
				product.denominations = denominations;
				if (denominations.length > 0) {
					return product;
				}
			});
			setProducts(products);
			setIsLoading(false);
		})();
	}, []);

	const submitOrder = async () => {
		const endpoint =
			configMode === 'DEV' ? 'gift-card-store-dev' : 'gift-card-store-prod';

		const variables = {
			reference: uuidv4(),
			payment_method: 'PREPAID_CREDIT',
			items: cart.map((product) => {
				const item = {
					reference: uuidv4(),
					product_code: product.code,
					amount: product.selectedDenomination,
					currency: 'USD',
					delivery_method: 'LINK',
					delivery_details: {
						style_code: 'PREZZEE_WHITE',
						recipient_name: currentUser.firstName,
						recipient_email: currentUser.emailAddress,
					},
				};
				return item;
			}),
		};
		const orderResponse = await lambda({
			endpoint: `${endpoint}?createorder=createorder&companyId=${currentUser.companyId}&userId=${currentUser.id}`,
			variables,
		});
		return orderResponse;
	};

	const company = get(currentUser, 'company');
	const whiteLabel = get(company, 'whiteLabel');
	return (
		<main>
			<Modal
				okText={ml('Add to Cart', currentUser, allMultiLingualData)}
				okButtonProps={{
					disabled: !desiredCardDenomination,
				}}
				cancelText={ml('Cancel', currentUser, allMultiLingualData)}
				title={ml('Select Denomination', currentUser, allMultiLingualData)}
				open={modalVisible}
				afterClose={() => {
					setCardBeingViewed(null);
					setDesiredCardDenomination(null);
				}}
				onOk={() => {
					// If there are adequate points, allow user to add to cart
					if (
						points >=
						calculatePointValueOfCard(
							desiredCardDenomination,
							pointsSettings.pointsRatio
						)
					) {
						setIsCartOpen(true);
						// Only change the product if the previous order has fully processed
						// If cart null, add product, else spread current products and add new product
						orderStatus === '' && cart.length > 0
							? setCart([
									...cart,
									{
										...cardBeingViewed,
										selectedDenomination: desiredCardDenomination,
									},
								])
							: setCart([
									{
										...cardBeingViewed,
										selectedDenomination: desiredCardDenomination,
									},
								]);
						// When item added to cart, deduct the points
						{
							setPoints(
								points -
									calculatePointValueOfCard(
										desiredCardDenomination,
										pointsSettings.pointsRatio
									)
							);
						}
					} else {
						message.error(
							ml(
								'You do not have enough points remaining to add this giftcard to cart.',
								currentUser,
								allMultiLingualData
							)
						);
					}

					setModalVisible(false);
				}}
				onCancel={() => setModalVisible(false)}
			>
				<Card product={cardBeingViewed} currentUser={currentUser} />

				<div className="denominations-wrap">
					{/* Loop over all available denominations for this card */}
					{cardBeingViewed?.denominations.map((cardDenomination, index) => (
						<div
							key={index}
							className={`ant-btn ant-btn-sm ant-btn-default ${
								cardDenomination.price === desiredCardDenomination
									? 'active'
									: ''
							}`}
							onClick={() => setDesiredCardDenomination(cardDenomination.price)}
						>
							{new Intl.NumberFormat(currentUser.languageCode || undefined, {
								maximumSignificantDigits: 3,
								style: 'currency',
								currency: currentUser.currency || 'USD',
							}).format(cardDenomination.price / 100)}
						</div>
					))}
				</div>
			</Modal>
			<div className="page-title">
				<div className="gift-card-title-wrap">
					<div className="gift-card-title">
						<h5>
							{ml('Balance', currentUser, allMultiLingualData)}:&nbsp;
							{points && pointsSettings
								? new Intl.NumberFormat(currentUser.languageCode || undefined, {
										maximumSignificantDigits: 3,
										style: 'currency',
										currency: currentUser.currency || 'USD',
									}).format(points / pointsSettings.pointsRatio)
								: '$0'}
						</h5>
						<p>({points || 0} Points)</p>
					</div>
					<ErinFilterSelect
						placeholder={ml('Category', currentUser, allMultiLingualData)}
						options={
							// Find all unique category names from the brandsByCategory json, turn into array of strings
							_.orderBy(
								_.uniq(brandsByCategory.flatMap((brand) => brand.Categories))
									// Filter out all Empty string Categories
									.filter(Boolean)
									// Make key value pairs for filter options out of uniqe values
									.map((item) => ({
										label: item,
										value: item.toLowerCase(),
									})),
								['label', ['asc']]
							)
						}
						appliedFilters={appliedFilters}
						onChange={(selectedFilters) => setAppliedFilters(selectedFilters)}
					/>
				</div>

				<Button type="link" onClick={() => setIsCartOpen(!isCartOpen)}>
					<AiOutlineShoppingCart size={24} />
					{ml('View Cart', currentUser, allMultiLingualData)}
					<div className="cart-items">{cart.length}</div>
				</Button>
			</div>

			{products && get(products, 'results', []).length <= 0 && (
				<div className="no-content">
					{whiteLabel ? (
						<img
							src={errorImageURL}
							alt="error image"
							className="no-content-icon"
						/>
					) : (
						<img alt="erin-logo" className="no-content-icon" src={fileIcon} />
					)}
					<p className="no-content-text">
						{ml(
							'There are no gift cards available at this time. Please check back later.',
							currentUser,
							allMultiLingualData
						)}
					</p>
				</div>
			)}
			{isLoading ? (
				<Spinner />
			) : (
				<div className="row">
					{products &&
						_.sortBy(products.results, 'name')
							// Provide all referrals by default, filter out values that don't match filter criteria
							.filter((product) => {
								const relativeCategories = brandsByCategory.filter((brand) =>
									brand.Categories.some((category) =>
										appliedFilters.includes(category.toLowerCase())
									)
								);

								const arrayOfMatchingProducts = relativeCategories.map(
									(category) => category['SKU Name'].toLowerCase()
								);

								if (
									appliedFilters.length > 0 &&
									!arrayOfMatchingProducts.includes(
										product.code.split('_').join(' ').toLowerCase()
									)
								) {
									return false;
								}

								return true;
							})
							.map((product, index) => (
								<div className="col-md-4 col-xl-3">
									<Card
										key={product.code}
										hoverScaleAndCursor
										product={product}
										currentUser={currentUser}
										onClick={() => {
											setModalVisible(true);
											setCardBeingViewed(product);
										}}
									/>
								</div>
							))}
				</div>
			)}
			<Drawer
				closable
				keyboard
				title={ml('Cart', currentUser, allMultiLingualData)}
				width={300}
				headerStyle={{ fontWeight: 700, textAlign: 'center' }}
				bodyStyle={{ padding: 0 }}
				className={css({
					'& .ant-drawer-title': {
						color: '#008000',
						fontWeight: 700,
					},
				})}
				placement="right"
				open={isCartOpen}
				onClose={() => {
					setIsCartOpen(false);
				}}
			>
				{cart.length > 0 ? (
					<>
						{cart.length &&
							cart.map((product, index) => (
								<div key={index} className="cart-list-items">
									<div className="cart-list-image">
										<img
											alt={product.name}
											src={product.themes[0].thumbnail_url}
										/>
									</div>

									<div className="info-price">
										<h5>{product.name}</h5>
										<p>
											{/* languageCode can accept undefined and not crash, but can't accept null */}
											{new Intl.NumberFormat(
												currentUser.languageCode || undefined,
												{
													maximumSignificantDigits: 3,
													style: 'currency',
													currency: currentUser.currency || 'USD',
												}
											).format(product.selectedDenomination / 100)}
										</p>
									</div>
									<div className="cart-action">
										<Tooltip title="Remove">
											<AiOutlineCloseCircle
												color="#FF0000"
												size={20}
												className="cursor-pointer"
												// Remove the deleted card from cart array
												onClick={() => {
													const filteredCart = cart.filter(
														(card, filterIndex) => filterIndex !== index
													);
													// If the cart is emptied, set to null

													setCart(filteredCart);

													setPoints(
														points +
															calculatePointValueOfCard(
																product.selectedDenomination,
																pointsSettings.pointsRatio
															)
													);
												}}
											/>
										</Tooltip>
									</div>
								</div>
							))}
						<div
							className="subtotal-wrap text-center"
							style={{ padding: '10px' }}
						>
							<p>
								<span>
									Subtotal{' '}
									{cart.length <= 1
										? `(${cart.length} item)`
										: cart.length && `(${cart.length}  items)`}
								</span>
								<span>
									{' '}
									{pointsSettings
										? calculatePointValueOfCard(
												_.sumBy(cart, 'selectedDenomination'),
												pointsSettings.pointsRatio
											)
										: null}
									&nbsp;points
								</span>
							</p>

							{orderStatus === 'processing' ? (
								<Spin style={{ height: '30px' }} />
							) : orderStatus === 'success' ? (
								<Progress type="circle" percent={100} width={30} />
							) : (
								<Popconfirm
									disabled={!cart || (orderStatus && true)}
									title="Are you sure?"
									okText="Yes"
									cancelText="No"
									onConfirm={async () => {
										setOrderStatus('processing');

										// If orderLimitReached, notify user and return
										const orderResponse = await submitOrder();

										if (orderResponse.errorCode) {
											if (orderResponse.errorCode === 'redeemableLimit') {
												alert(
													ml(
														orderResponse.error,
														currentUser,
														allMultiLingualData
													)
												);
											}

											if (
												orderResponse.errorCode === 'insufficientFunds' ||
												orderResponse.errorCode === 'insufficientPoints'
											) {
												alert(
													ml(
														'There was an error completing the transaction. Please try again later.',
														currentUser,
														allMultiLingualData
													)
												);
												await updatePointsData();
											}

											setOrderStatus('');
											return;
										}

										const newPoints = await client
											.query({
												query: gql(getUserPoints),
												fetchPolicy: 'network-only',
												variables: { id: currentUser.id },
											})
											.then((response) => response.data.getUserPoints.points);
										if (newPoints) {
											setPoints(newPoints);
										} else setPoints(0);
										setOrderStatus('success');
										// Show success for some time before state cleanup
										const OrderCleanup = setTimeout(() => {
											setCart([]);
											setIsCartOpen(false);
											setOrderStatus('');
											return () => clearTimeout(OrderCleanup);
										}, 5000);
									}}
								>
									<Button disabled={cart.length === 0 && true} type="primary">
										{ml('Submit Order', currentUser, allMultiLingualData)}
									</Button>
								</Popconfirm>
							)}
						</div>
						{orderStatus === 'success' ? (
							<div className="text-center mt-3 p-2 text-red-500 font-semibold">
								{ml(
									'Your transaction is pending! You will receive an email upon completion',
									currentUser,
									allMultiLingualData
								)}
							</div>
						) : null}
					</>
				) : (
					<div className="text-center" style={{ padding: '20px 10px' }}>
						<p>{ml('Your cart is empty.', currentUser, allMultiLingualData)}</p>
						<AiOutlineShoppingCart size={110} />
					</div>
				)}
			</Drawer>
		</main>
	);
}

function calculatePointValueOfCard(cardDenomination, pointsRatio) {
	return (cardDenomination / 100) * pointsRatio;
}

// TODO: extract this card into its own component, and give props and children.
function Card({ product, currentUser, hoverScaleAndCursor, ...rest }) {
	return (
		<div
			// Only have hover scaling on dashboard cards, not cardBeingViewed
			{...rest}
			className="product-card"
		>
			<div className="product-card-img">
				<img alt={product.name} src={product.themes[0].thumbnail_url} />
			</div>
			<div className="product-card-info">
				<h4 className="product-title">{product.name}</h4>
				<p className="product-price">
					{/* languageCode can accept undefined and not crash, but can't accept null */}
					{new Intl.NumberFormat(currentUser.languageCode || undefined, {
						maximumSignificantDigits: 3,
						style: 'currency',
						currency: currentUser.currency || 'USD',
					}).format(_.minBy(product.denominations, 'price').price / 100)}
					&nbsp;-&nbsp;
					{new Intl.NumberFormat(currentUser.languageCode || undefined, {
						maximumSignificantDigits: 3,
						style: 'currency',
						currency: currentUser.currency || 'USD',
					}).format(_.maxBy(product.denominations, 'price').price / 100)}
				</p>
			</div>
		</div>
	);
}

export default withApollo(GiftCardStore);
