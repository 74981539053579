import { Button, Checkbox, Input, InputNumber, Select } from 'antd';
import _ from 'lodash';
import get from 'lodash/get';
import { Component } from 'react';
import { ml } from 'src/_shared/services/utils.js';
import { urlRegex } from '../../../_shared/constants/regex';
import { AddJobsFooter } from '../footer/AddJobFooterComponent.jsx';
import { PageItem } from './AddJobPageItemComponent.jsx';

export class AddJobPageThree extends Component {
	constructor(props) {
		super(props);
		this.state = {
			internalJobLink: this.props.currentJob.internalJobLink,
			hideImInterested: this.props.currentJob.hideImInterested,
			isGeneralReferral: this.props.currentJob.isGeneralReferral,
			isGeneralReferral: this.props.currentJob.isGeneralReferral,
			publicLink: '',
			hiringManager: '',
			tieredBonus: undefined,
			notificationType: 'NONE',
			referralBonus: props.isNew
				? {
						hasBonus: true,
						amount: get(props, 'currentUser.company.defaultBonusAmount', null),
					}
				: props.currentJob.referralBonus,
			autoCompleteResult: [],

			theme: get(props, 'theme', {}),
		};
	}

	componentDidMount() {
		const { hiringManager, publicLink, notificationType, tieredBonus } =
			this.props.currentJob;

		this.setState({
			publicLink,
			hiringManager,
			notificationType,
			tieredBonus,
		});
	}

	onChangeBonusTier = (value) => {
		this.setState({
			referralBonus: {
				...this.state.referralBonus,
				tieredBonusId: value.key,
				hasBonus: true,
				amount: undefined,
			},
		});
	};

	onChangeHiringManager = (value) => {
		this.setState({
			hiringManager: {
				id: value.key,
				name: value.label.join(''),
			},
		});
	};

	onChangeInternalJobLink = (e) => {
		const { value } = e.target;
		const url = new RegExp(urlRegex, 'i');
		if (value === '' || url.test(value)) {
			this.setState({ internalJobLinkError: false });
		} else {
			this.setState({ internalJobLinkError: true });
		}

		if (value === '') {
			this.setState({ internalJobLink: null });
		} else {
			this.setState({ internalJobLink: value });
		}
	};

	onChangeJobPostingLink = (e) => {
		const { value } = e.target;
		const url = new RegExp(urlRegex, 'i');
		if (value === '' || url.test(value)) {
			this.setState({ jobPostingLinkError: false });
		} else {
			this.setState({ jobPostingLinkError: true });
		}

		if (value === '') {
			this.setState({ publicLink: null });
		} else {
			this.setState({ publicLink: value });
		}
	};

	onChangeNotificationType = (notificationType) => {
		this.setState({
			notificationType,
		});
	};

	onChangeReferralBonusAmount = (value) => {
		const reg = /^-?(0|[1-9]\d*)(\.\d*)?$/;
		if ((!isNaN(value) && reg.test(value)) || value === '' || value === '-') {
			this.setState({
				referralBonus: { ...this.state.referralBonus, amount: value },
			});
		}
	};

	footerButtons = (page) => {
		const { allMultiLingualData, currentUser } = this.props;
		const { theme } = this.state;
		const callback = () => {
			this.props.handleNext(page, {
				...this.props.currentJob,
				...this.state,
			});
		};

		return (
			<AddJobsFooter
				allMultiLingualData={allMultiLingualData}
				currentUser={currentUser}
				page={page}
				theme={theme}
				handleNext={callback}
				handleBack={() => {
					this.props.handleBack(page);
				}}
			/>
		);
	};

	renderHiringManager = () => {
		const { allMultiLingualData, currentUser, managers, errors } = this.props;
		const { hiringManager } = this.state;
		const options = _.orderBy(
			managers,
			[(manager) => manager.firstName.toLowerCase()],
			['asc']
		)
			.filter((manager) => manager.role !== 'employee')
			.map((manager) => (
				<Select.Option key={manager.id}>
					{manager.firstName} {manager.lastName}
				</Select.Option>
			));
		return (
			<div className="custom-form-group">
				<label className="custom-label">
					{ml('Recruiter', currentUser, allMultiLingualData)}
				</label>
				<Select
					className="custom-input"
					showSearch={true}
					labelInValue={true}
					placeholder="Ex. James Smith"
					filterOption={(input, option) =>
						option.children
							.join('')
							.toLowerCase()
							.indexOf(input.toLowerCase()) >= 0
					}
					optionFilterProp="children"
					onSelect={(value) => this.onChangeHiringManager(value)}
					optionLabelProp="children"
					//with handleSubmit, hiringManager updates currentJob in ErinAppStateManagement with id, not name
					value = {
						hiringManager ? {
							key: hiringManager.id,
							label: hiringManager.name,
						} :
							null
					}
				>
					{options}
				</Select>
				{errors.hiringManager && (
					<div className="input-error">{errors.hiringManager}</div>
				)}
			</div>
		);
	};

	renderJobPostingLink = () => {
		const { publicLink, jobPostingLinkError } = this.state;
		const { allMultiLingualData, currentUser } = this.props;
		return (
			<div className="custom-form-group">
				<label className="custom-label">
					{ml('Public Job Posting Link', currentUser, allMultiLingualData)}
					/ApplyURL
					<span className="sub-label">(optional)</span>
				</label>
				<Input
					className="custom-input"
					value={publicLink}
					placeholder="http://linkToYourJob.org/post?id=12345"
					onChange={this.onChangeJobPostingLink.bind(this)}
				/>
				{jobPostingLinkError && (
					<div className="input-error">
						{ml(
							'Please enter the url in the format',
							currentUser,
							allMultiLingualData
						)}
						: http://www.bestco.com
					</div>
				)}
			</div>
		);
	};

	renderNotification = () => {
		const { notificationType } = this.state;
		const { allMultiLingualData, currentUser } = this.props;

		const notificationTypes = {
			ALL: ml('Notify All Employees', currentUser, allMultiLingualData),
			DEPARTMENT: ml(
				'Notify Department Only',
				currentUser,
				allMultiLingualData
			),
			NONE: ml('No Notifications', currentUser, allMultiLingualData),
		};
		return (
			<div className="custom-form-group">
				<label className="custom-label">
					Job Added Email {ml('Notification', currentUser, allMultiLingualData)}
				</label>
				<div className="custom-btn-group">
					{Object.keys(notificationTypes).map((key) => {
						return (
							<Button
								key={key}
								className={`${
									(key === notificationType && 'active') + ' btn-pink'
								}`}
								style={{ marginLeft: 0 }}
								onClick={this.onChangeNotificationType.bind(this, key)}
							>
								{notificationTypes[key]}
							</Button>
						);
					})}
				</div>
			</div>
		);
	};

	renderReferralBonus = () => {
		const { allMultiLingualData, currentUser, errors, tieredBonuses } =
			this.props;
		const { referralBonus } = this.state;
		const { Option } = Select;
		let options;
		if (tieredBonuses) {
			options = tieredBonuses.map((bonus) => (
				<Option key={bonus.id}>{bonus.name}</Option>
			));
		}

		let matchedBonus;
		let bonusValue;
		if (referralBonus.tieredBonusId) {
			matchedBonus = tieredBonuses.find(
				(t) => t.id === referralBonus.tieredBonusId
			);
			bonusValue = referralBonus.tieredBonusId
				? {
						key: referralBonus.tieredBonusId,
						label: matchedBonus ? matchedBonus.name : 'n/a',
					}
				: undefined;
		}

		return (
			<div className="custom-form-group">
				<label className="custom-label">
					{ml('Referral Bonus', currentUser, allMultiLingualData)}
				</label>
				<div className="c-form-row">
					<div className="c-form-col">
						<Select
							showSearch
							labelInValue
							className="custom-input"
							style={{ minWidth: 240 }}
							optionFilterProp="children"
							value={bonusValue}
							placeholder="Select A Bonus"
							filterOption={(input, option) =>
								option.props.children
									.join('')
									.toLowerCase()
									.includes(input.toLowerCase())
							}
							onSelect={(value) => this.onChangeBonusTier(value)}
						>
							{options}
						</Select>
					</div>
					<div className="c-form-col">
						<span className="divider-text-row">or</span>
					</div>
					<div className="c-form-col">
						<Button
							className={`${
								(!referralBonus.hasBonus && 'active') + ' btn-pink'
							}`}
							onClick={() => {
								this.setState({
									referralBonus: {
										...this.state.referralBonus,
										hasBonus: !this.state.referralBonus.hasBonus,
										amount: undefined,
									},
								});
							}}
						>
							No Bonus
						</Button>
					</div>
					<div className="c-form-col">
						<span className="divider-text-row">or</span>
					</div>
					<div className="c-form-col">
						<div className="amount-number">
							<span>$</span>
							<InputNumber
								className="custom-input"
								max={999_999}
								min={0}
								value={referralBonus.amount === 0 ? null : referralBonus.amount}
								formatter={(value) =>
									`${value}`.replaceAll(/\B(?=(\d{3})+(?!\d))/g, ',')
								}
								parser={(value) => value.replaceAll(/$\s?|(,*)/g, '')}
								placeholder="Enter Amount"
								onChange={this.onChangeReferralBonusAmount.bind(this)}
								onFocus={() => {
									this.setState({
										referralBonus: {
											...this.state.referralBonus,
											hasBonus: true,
											tieredBonusId: undefined,
										},
									});
								}}
							/>
						</div>
					</div>
				</div>
				{errors.referralBonus && (
					<div className="input-error">{errors.referralBonus}</div>
				)}
			</div>
		);
	};

	renderinternalJobLink = () => {
		const { internalJobLink, internalJobLinkError } = this.state;
		return (
			<div className="custom-form-group">
				<PageItem optional title="Internal Job Link">
					<Input
						className="custom-input"
						value={internalJobLink}
						placeholder="ex. http://internalJobLinkToJob.com"
						onChange={this.onChangeInternalJobLink.bind(this)}
					/>
				</PageItem>
				{internalJobLinkError && (
					<div className="input-error">
						Please enter the url in the format: http://www.bestco.com
					</div>
				)}
			</div>
		);
	};

	render() {
		const {
			currentUser: {
				company: { allowSelfReferrals, allowSelfReferralsInternalLink },
			},
		} = this.props;
		const footer = this.footerButtons(this.props.page);
		const { Option } = Select;
		return (
			<main>
				<div>
					{this.renderJobPostingLink()}
					{allowSelfReferrals &&
						allowSelfReferralsInternalLink &&
						this.renderinternalJobLink()}
					<div className="row">
						<div className="col-md-6">{this.renderHiringManager()}</div>
						<div className="col-md-6">
							{allowSelfReferrals && (
								<div className="custom-form-group">
									<Checkbox
										checked={this.state.hideImInterested}
										onChange={() =>
											this.setState({
												hideImInterested: !this.state.hideImInterested,
											})
										}
									>
										<label
											className="custom-label"
											style={{ display: 'inline-block' }}
										>
											Hide "I'm Interested" button
										</label>
									</Checkbox>
								</div>
							)}
							<div className="custom-form-group">
								<Checkbox
									checked={this.state.isGeneralReferral}
									onChange={() =>
										this.setState({
											isGeneralReferral: !this.state.isGeneralReferral,
										})
									}
								>
									<label
										className="custom-label"
										style={{ display: 'inline-block' }}
									>
										Set as General Referral job
									</label>
								</Checkbox>
							</div>
						</div>
					</div>
					{this.renderReferralBonus()}
					{this.renderNotification()}
					{footer}
				</div>
			</main>
		);
	}
}
