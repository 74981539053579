import React, { useState, useEffect } from 'react';
import { message } from 'antd';
import RewardDashboard from './../../presentation/rewardDashboard/RewardDashboard.jsx';
import { Message } from 'antd';
import { useHomeService } from './../../service/home.services.jsx'

const RewardDashboardContainer = (props) => {
	const { isSharingVisible } = props;
	const [rewardModalData, setRewardModalData] = useState()
	const [isRewardModalOpen, setIsRewardModaOpen] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const [rewardListData, setRewardListData] = useState()
	const homeAPIService = useHomeService();

	const getRewardDetail = (id) => {
		setIsRewardModaOpen(true)
		homeAPIService.getIssuedBadgeDashboard(id).then((response) => {
			if (response.code === 200) {
				setRewardModalData(response?.data)
				setIsRewardModaOpen(false)
			} else {
				message.error('Failed to fetch reward data.');
			}
		})
			.catch((error) => {
				console.log(error);
				message.error('Failed to fetch reward data.');
			});
	}

	useEffect(() => {
		getRewardData()
	}, [])

	const getRewardData = async () => {
		setIsLoading(true)
		homeAPIService.getEmployeeRewards({ assignmentType: 'badgeAssignment' })
			.then((response) => {
				if (response?.code === 200) {
					setIsLoading(false)
					if (response?.data?.records) {
						setRewardListData(response?.data?.records);
					}
				} else {
					setIsLoading(false)
					Message.error('Failed to fetch reward list');
				}
			})
			.catch((error) => {
				setIsLoading(false)
				console.log(error);
				Message.error('Failed to fetch reward list');
			});
	};

	return (
		<div>
			<RewardDashboard
				isLoading={isLoading}
				rewardsListData={rewardListData}
				getRewardDetail={getRewardDetail}
				rewardModalData={rewardModalData}
				isRewardModalOpen={isRewardModalOpen}
				isSharingVisible={isSharingVisible}
			/>
		</div>
	);
};

export default RewardDashboardContainer;
