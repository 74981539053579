import { css } from 'emotion';
import { COLORS } from '../_shared/styles/colors';

const breakpoints = [576, 767, 991, 1200];

const mq = breakpoints.map((bp) => `@media (max-width: ${bp}px)`);

export const ListStyles = css({
	'& .ant-list-item': {
		fontFamily: '"Open Sans", sans-serif !important',
		fontWeight: 400,
		fontSize: 14,
		color: COLORS.lightGray,
		marginBottom: 0,
		lineHeight: '19px',
		width: 382,
	},
});

export const ListTextContainer = css({
	fontFamily: '"Open Sans", sans-serif !important',
	padding: '0 12px',
});

export const ListPageStyles = css({
	'& .ant-list-item-meta-description': {
		fontFamily: '"Open Sans", sans-serif',
		fontWeight: 400,
		fontSize: 14,
		color: COLORS.lightGray,
		marginBottom: 0,
		paddingTop: 5,
	},
	'& .ant-list-pagination': {
		textAlign: 'left',
		paddingLeft: 20,
		fontFamily: '"Open Sans", sans-serif',
	},
	'& .ant-pagination-total-text': {
		fontFamily: '"Open Sans", sans-serif',
		fontWeight: 400,
		fontSize: 14,
		color: COLORS.lightGray,
	},
});

export const ListItemStyles = css({
	fontFamily: '"Open Sans", sans-serif !important',
	padding: '5px 16px',
	height: 'auto',
	justifyContent: 'normal',
	flexWrap: 'nowrap',
	margin: '0 -16px',
	'& .ant-list-item-content': {
		alignItems: 'center',
	},
});

export const ListItemStylesBlue = css({
	fontFamily: '"Open Sans", sans-serif !important',
	backgroundColor: '#E4F7FE',
	padding: '5px 16px',
	margin: '0 -16px',
	'& .ant-list-item-content': {
		alignItems: 'center',
	},
});

export const ListItemPageStyles = css({
	fontFamily: '"Open Sans", sans-serif !important',
	padding: '5px 20px',
	'& .ant-list-item-content': {
		alignItems: 'center',
	},
});

export const ListItemPageStylesBlue = css({
	fontFamily: '"Open Sans", sans-serif !important',
	backgroundColor: '#E4F7FE',
	padding: '5px 20px',
	'& .ant-list-item-content': {
		alignItems: 'center',
	},
});

export const PopoverContainer = css({
	padding: '10px 0',
});

export const BtnStyles = css({
	width: 50,
	border: 'none',
});

export const CountStyles = css({
	borderRadius: '50%',
	backgroundColor: COLORS.red,
	color: 'white',
	width: 17,
	height: 17,
	textAlign: 'center',
	fontSize: 9,
	paddingTop: 1.5,
	position: 'relative',
	right: 22,
	top: 3,
});
export const NotificationsContainer = css({
	display: 'flex',
});

export const RemoveMargins = css({
	margin: 0,
	color: COLORS.lightGray,
});

export const Bold = css({
	fontWeight: 600,
	color: COLORS.darkGray,
});

export const ViewMore = css({
	fontWeight: '600 !important',
	width: '382px',
	textAlign: 'center',
	borderTop: '1px solid #e8e8e8',
	paddingTop: 10,
	'& a': {
		color: COLORS.blue,
	},
	margin: '0 -16px',
});

export const NotificationPageContainer = css({
	width: '100%',
	height: '100%',
	margin: 30,
	paddingRight: 30,
});

export const ListContainer = css({
	boxShadow: '1px 1px 15px rgba(0,0,0,0.25)',
	borderRadius: 2,
	maxWidth: 1270,
	marginRight: 20,
	backgroundColor: 'white',
	paddingBottom: 20,
});

export const ListAvatar = css({
	borderRadius: 0,
});
export const Header = css({
	padding: 20,
	paddingBottom: 5,
	display: 'flex',
	borderBottom: '1px solid #e8e8e8',
});

export const NameStyles = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 600,
	color: '#444444',
	padding: 0,
	fontSize: 28,
	lineSpacing: 0,
	margin: 0,
	position: 'relative',
	top: -8,
});

export const NotificationCount = css({
	margin: 0,
	position: 'relative',
	top: -8,
	fontWeight: 400,
	color: COLORS.lightGray,
});

export const InfoContainer = css({
	paddingLeft: 16,
});

export const NoPicture = css({
	height: 50,
	width: 50,
	backgroundColor: '#cfcfcf',
	'& h3': {
		fontWeight: 400,
		color: 'white',
		fontSize: 26,
		textAlign: 'center',
		lineHeight: '50px',
		marginBottom: 0,
	},
});

export const CompanyAvatar = css({
	height: 50,
	width: 50,
	backgroundColor: COLORS.darkGray,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
});

export const FlexContainer = css({
	display: 'flex',
	width: '100%',
	alignItems: 'center',
});

export const TimeStyles = css({
	color: COLORS.lightGray,
	fontSize: 12,
	marginBottom: 0,
});
