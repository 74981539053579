import { useEffect, useState } from 'react';
import { message } from 'antd';
import { useMyRewardsService } from '../service/MyRewards.service.jsx';

const useMyRewardsPoint = () => {
    const [rewardPointsBonus, setRewardPointsAndBonus] = useState();
    const myRewardsAPIService = useMyRewardsService();

    useEffect(() => {
        getRewardPointsAndBonus();
    }, []);

    const getRewardPointsAndBonus = async () => {
        try {
            const response = await myRewardsAPIService.getRewardPointsAndBonuses();
            if (response?.code === 200 && response?.data) {
                setRewardPointsAndBonus(response.data);
            } else {
                message.error('Failed to load earned points and bonus points');
            }
        } catch (error) {
            console.error(error);
            message.error('Failed to load earned points and bonus points');
        }
    };

    return { rewardPointsBonus };
};

export default useMyRewardsPoint;
