export const CONFIRMATION_STATUS = {
  PENDING: 'Pending',
  CONFIRMED: 'Confirmed',
  EXPIRED: 'Expired',
  DECLINED: 'Declined'
}

export const STATUS = {
  PENDING: 'Pending',
  SENT: 'Sent',
  NOT_SENT: 'Not Sent',
  DECLINED: 'Declined'
}

export const PHYSICAL_REWARDS_API_ENDPOINTS = {
  GET_PHYSICAL_REWARDS_LIST: 'api/v1/physical-award-activity',
  GET_REWARDS_STATISTICS: 'api/v1/physical-award-activity/statics'
}