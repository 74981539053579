import { connect } from 'react-redux';
import GiftCardStore from './GiftCardStoreComponent.jsx';

const mapStateToProperties = (state) => {
	const { allMultiLingualData } = state.dashboard;
	return {
		allMultiLingualData,
		currentUser: state.user.currentUser,
	};
};

export default connect(mapStateToProperties)(GiftCardStore);
