import { createContext, useContext } from 'react';
import { useRnRAPIService } from '../../rewardsAndRecognitionHelper/service/RnRAPI.service.jsx';
import { NOMINATED_REWARDS_ENDPOINTS } from '../constants/NominatedRewards.constants.js';

const NominatedRewardsServiceContext = createContext(null);

export const NominatedRewardsServiceProvider = (props) => {
  const APIService = useRnRAPIService();

  const getNomineeListById = (id, request) => {
    return APIService.get(`${NOMINATED_REWARDS_ENDPOINTS.GET_NOMINEE_LIST_BY_ID}${id}`, request);
  }

  const getNominationConfiguration = () => {
    return APIService.get(NOMINATED_REWARDS_ENDPOINTS.GET_NOMINATION_CONFIGURATION);
  };

  const getEmployeeLoginType = () => {
    return APIService.get(NOMINATED_REWARDS_ENDPOINTS.GET_EMPLOYEE_LOGIN_TYPE);
  };

  const createNominationConfiguration = (payload) => {
    return APIService.post(NOMINATED_REWARDS_ENDPOINTS.CREATE_NOMINATION_CONFIGURATION, payload);
  };

  const NominatedRewardsServiceOperations = {
    getNomineeListById,
    getNominationConfiguration,
    getEmployeeLoginType,
    createNominationConfiguration
  };

  return (
    <NominatedRewardsServiceContext.Provider
      value={NominatedRewardsServiceOperations}
    >
      {props.children}
    </NominatedRewardsServiceContext.Provider>
  );
};

export const useNominatedRewardsService = () => {
  return useContext(NominatedRewardsServiceContext);
};