// IMPORTANT NOTE: keep this order maintained because of we're accessing thiese values using index, new key will be added in the bottom.

export const REFERRAL_STATUS = {
	referred: 'Referred',
	accepted: 'Accepted',
	interviewing: 'Interviewing',
	hired: 'Hired',
	ineligible: 'Ineligible',
	notHired: 'Not Hired',
	declined: 'Declined',
	transferred: 'Transferred',
	inactive: 'Inactive',
	noresponse: 'No Response',
};
export const BONUS_STATUS = {
	pending: 'Pending',
	ineligible: 'Ineligible',
	needsReview: 'Review',
	earned: 'Eligible',
	paid: 'Paid',
};
