export const ONBOARDING_STATUS = Object.freeze({
	COMPLETE: 'complete',
	INCOMPLETE: 'incomplete',
});

export const PAYOUT_METHODS = Object.freeze({
	GIFT_CARDS: 'giftcards',
	INTERNAL: 'internal',
});
// NOTE: map to [useState(ob)] in [onboarding.component.jsx]
export const ONBOARDING_SCREENS = Object.freeze({
	WELCOME: 'welcome',
	SAAS_AGREEMENT: 'saasAgreement',
	HOW_TO_PAY: 'howToPay',
	CREATE_BONUSES: 'createBonuses',
	GIFT_CARD_PAYOUTS: 'giftCardPayouts',
	BONUS_REPORTING: 'bonusReporting',
	INVITE_EMPLOYEES: 'inviteEmployees',
	LEARN_MORE_ADMIN: 'learnMoreAdmin',
});
