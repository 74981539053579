import React, { useState } from 'react';
import { Button } from 'antd';
import AddRewardContainer from '../addRewardContainer/AddRewardContainer';
import EditRewardContainer from '../editRewardContainer/EditRewardContainer';
import { RewardList as RewardListContainer } from "../../../RnRCommonComponent";
import { BADGE_TYPE } from '../../../RnRCommonComponent/CoreBadge/rewardsConstants.js'
import '../manageRewardContainer/manageReward.scss'
import Automated from '../../../assets/svg/automated.svg'
import NoDataState from '../../../RnRCommonComponent/noDataState/NoDataState.jsx';

const ManageRewardContainer = () => {

	const [isAddModalOpen, setIsAddModalOpen] = useState(false);
	const [isEditModalOpen, setIsEditModalOpen] = useState(false)
	const [selectedBadge, setSelectedBadge] = useState();
	const [shouldRefresh, setShouldRefresh] = useState(false);

	const showAddModal = () => {
		setIsAddModalOpen(true);
	};

	const handleCloseAddModal = () => {
		setIsAddModalOpen(false);
	};

	const handleIconClickEvent = (badge) => {
		setSelectedBadge(badge)
		setIsEditModalOpen(true);
	}

	return (
		<>
			<div className="main">
				<div className="page-title">
					<h2 className="page-heading-manage-reward">Manage Badges</h2>
					<div style={{ display: "flex" }}>
						<Button type="link" className="add-btn btn-lg create-reward" onClick={showAddModal}>
							<span className="icon-circle">
								<b className="icon-plus" />
							</span>
							<span>Create Badge</span>
						</Button>
					</div>
				</div>
			</div>
			{isAddModalOpen && <AddRewardContainer
				isModalOpen={isAddModalOpen}
				handleCloseModal={handleCloseAddModal}
				setShouldRefresh={setShouldRefresh}
			/>}

			{isEditModalOpen && <EditRewardContainer
				isEditModalOpen={isEditModalOpen}
				handleCloseModal={() => setIsEditModalOpen(false)}
				selectedBadge={selectedBadge}
				setShouldRefresh={setShouldRefresh}
			/>}

			<div className="row">
				<div className="col-12">
					<RewardListContainer
						handleIconClickEvent={handleIconClickEvent}
						title="On-The-Spot Recognitions"
						badgeType={BADGE_TYPE.PEERTOPEER}
						shouldRefresh={shouldRefresh}
						setShouldRefresh={setShouldRefresh}
						isDetailsShown={true}
						isPointsShown={false}
						isIssuedVisible={true}
					/>
					<RewardListContainer
						handleIconClickEvent={handleIconClickEvent}
						title="Milestones"
						badgeType={BADGE_TYPE.AUTOMATED}
						shouldRefresh={shouldRefresh}
						setShouldRefresh={setShouldRefresh}
						isDetailsShown={true}
						isPointsShown={false}
						isIssuedVisible={true}
					/>
					<RewardListContainer
						handleIconClickEvent={handleIconClickEvent}
						title="Nominations"
						badgeType={BADGE_TYPE.NOMINATED}
						shouldRefresh={shouldRefresh}
						setShouldRefresh={setShouldRefresh}
						isDetailsShown={true}
						isPointsShown={false}
						isIssuedVisible={true} S
					/>
					<div className="d-card ">
						<div className="d-card-head">
							<h4 className="d-title-no-case">{"Automated Recognitions"}</h4>
						</div>
						<div style={{
							maxHeight: "220px",
							height: "100%",
							overflow: "unset",
						}}>
							<div className="badges-list-flex">
								<NoDataState title={"No automated recognitions generated yet."} />
								{/* <div
									className='badges-container'
									style={{ opacity: 1 }}>
									<div className='badges-container-edit-target' style={{ cursor: 'pointer' }}>
										<div className="badges-img">
											{
												<div className='badges-svg'>
													<img src={Automated} alt='img' />
												</div>
											}
											<div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
												<div style={{ marginBottom: "-0.5rem" }}>{150}</div>
												<div>Issued</div>
											</div>
										</div>
									</div>
									<div style={{
										display: "flex",
										justifyContent: "center",
										marginTop: "2px"
									}}>
										<Button
											type='link'
											className="text-center img-label">
											Details
										</Button>
									</div>
								</div> */}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ManageRewardContainer;
