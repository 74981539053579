import gql from 'graphql-tag';
import { get } from 'lodash';
import { compose, graphql } from 'react-apollo';
import {
	createTieredBonus,
	updateTieredBonus,
} from '../../graphql/custom/tiered-bonuses';

export const withModifyTieredBonus = (Component) => {
	return compose(
		graphql(gql(updateTieredBonus), {
			props: (props) => ({
				onUpdateTieredBonus(input) {
					const optimisticResponseData = {
						...input,
					};
					return props.mutate({
						variables: { input },
						optimisticResponse: {
							__typename: 'Mutation',
							updateTieredBonus: {
								...optimisticResponseData,
								__typename: 'updateTieredBonus',
							},
						},
					});
				},
			}),
		}),
		graphql(gql(createTieredBonus), {
			props: (props) => ({
				async onAddTieredBonus(input) {
					const { data } = await props.mutate({
						variables: { input },
					});
					return get(data, 'createTieredBonus');
				},
			}),
		})
	)(Component);
};

const makeOnFetchMoreBonuses = (fetchMore, nextToken) => {
	if (!nextToken) {
		return null;
	}

	return () => {
		fetchMore({
			variables: { nextToken },
			updateQuery(previous, { fetchMoreResult }) {
				if (!fetchMoreResult) {
					return previous;
				}

				const mergedItems = [
					...previous.listTieredBonuses.items,
					...fetchMoreResult.listTieredBonuses.items,
				];
				const set = new Set();
				const uniqueItems = mergedItems.filter((item) => {
					if (!set.has(item.id)) {
						set.add(item.id);
						return true;
					}

					return false;
				});
				return {
					...previous,
					listTieredBonuses: {
						...previous.listTieredBonuses,
						...fetchMoreResult.listTieredBonuses,
						items: uniqueItems,
					},
				};
			},
		});
	};
};
