import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import dompurify from 'dompurify';
import { get } from 'lodash';
import { Component } from 'react';
import { showingLess, textFade } from './referralPolicyStyles.js';

const sanitizer = dompurify.sanitize;

class BaseReferralPolicyCard extends Component {
	state = {
		isViewBoxExpanded: false,
	};

	render() {
		const { title, currentUser, minHeight } = this.props;
		const { isViewBoxExpanded } = this.state;
		return (
			<div className="d-card">
				{title && (
					<div className="d-card-head">
						<h4 className="d-title">{title}</h4>
					</div>
				)}
				<div className={`${isViewBoxExpanded ? '' : showingLess}`}>
					<div
						dangerouslySetInnerHTML={{
							__html: sanitizer(
								get(
									currentUser,
									'company.dashboardReferralPolicyText',
									''
								).toString('html')
							),
						}}
						className="referral-policy"
					/>
					<div className={textFade} />
				</div>
				<Button
					type="link"
					style={{ color: '#018dd3', fontWeight: 600 }}
					onClick={() =>
						this.setState({ isViewBoxExpanded: !isViewBoxExpanded })
					}
				>
					{isViewBoxExpanded ? <UpOutlined /> : <DownOutlined />}
				</Button>
			</div>
		);
	}
}

export const ReferralPolicyCard = BaseReferralPolicyCard;
