import { compose, graphql } from 'react-apollo';
import gql from 'graphql-tag';
import dayjs from 'dayjs';
import uuid from 'uuid/v4';
import { createContact, updateContact } from '../../graphql/custom/contacts';
import { createReferral } from '../../graphql/custom/referrals';
import { updateWebNotification } from '../../graphql/custom/users';

export const withCreateGDPRReferral = (Component) => {
	return compose(
		graphql(gql(createReferral), {
			props: (props) => ({
				onCreateReferral(input) {
					const optimisticResponseData = {
						...input,
						id: uuid(),
						referralDate: dayjs(),
					};
					return props.mutate({
						variables: input,
						optimisticResponse: {
							__typeName: 'Mutation',
							createReferral: {
								__typeName: 'createReferral',
								...optimisticResponseData,
							},
						},
					});
				},
			}),
		}),
		graphql(gql(createContact), {
			props: (props) => ({
				ImportedCreateContact(input) {
					return props.mutate({
						variables: input,
					});
				},
			}),
		}),
		graphql(gql(updateContact), {
			props: (props) => ({
				onUpdateContact(input) {
					const optimisticResponseData = {
						...input.input,
						__typename: 'Contact',
					};
					return props.mutate({
						variables: { input: { ...input } },
						optimisticResponse: {
							__typename: 'Mutation',
							updateContact: {
								__typename: 'updateContact',
								...optimisticResponseData,
							},
						},
					});
				},
			}),
		}),
		graphql(gql(updateWebNotification), {
			props: (props) => ({
				onUpdateWebNotification(input) {
					const optimisticResponseData = {
						...input.input,
						__typename: 'WebNotification',
					};
					return props.mutate({
						variables: { input: { ...input } },
						optimisticResponse: {
							__typename: 'Mutation',
							updateWebNotification: {
								__typename: 'updateWebNotification',
								...optimisticResponseData,
							},
						},
					});
				},
			}),
		})
	)(Component);
};
