import { css } from 'emotion';
import get from 'lodash/get';
import { COLORS } from 'src/_shared/styles/colors';

const breakpoints = [576, 767, 991, 1200];

const mq = breakpoints.map((bp) => `@media (max-width: ${bp}px)`);

export const addButtonText = css({
	fontSize: 18,
	fontWeight: 600,
	color: COLORS.black,
});

export const boldSytle = css({
	fontWeight: 900,
});

export const bulletPointStyle = css({
	fontSize: 20,
	fontWeight: 500,
	marginBottom: 10,
	marginLeft: 10,
});

export const buttonContainerStyle = css({
	marginTop: 50,
	display: 'flex',
	justifyContent: 'center',
});

export const buttonStyle = css({
	minWidth: 200,
	fontWeight: 500,
	backgroundColor: '#0067d2',
});

export const containerStyle = css({
	fontFamily: "'Open Sans', sans-serif",
	display: 'flex',
	justifyContent: 'center',
	width: '100%',
	color: COLORS.black,
});

export const inputStyle = css({
	width: 375,
	margin: 0,
	marginLeft: 20,
	display: 'flex',
	marginBottom: 15,
});

export const noteStyle = css({
	fontStyle: 'italic',
	fontSize: 18,
	fontWeight: 500,
	marginLeft: 10,
	color: COLORS.gray,
});

export const paymentLabel = css({
	padding: 8,
	fontSize: 16,
	fontWeight: 550,
	[mq[1]]: {
		textAlign: 'center',
	},
});

export const radioStyle = css({
	fontSize: 20,
	fontWeight: 500,
	margin: 5,
	color: COLORS.black,
});

export const scrollDiv = css({
	overflowY: 'scroll',
	maxHeight: '750px',
});

export const subHeaderStyle = css({
	fontSize: 20,
	fontWeight: 500,
});

export const subtitleStyle = css({
	fontSize: 24,
	fontWeight: 600,
	marginBottom: 20,
	textAlign: 'center',
});

export const textBodyStyle = css({
	backgroundColor: COLORS.white,
	fontFamily: "'Open Sans', sans-serif",
	width: '100%',
	paddingLeft: 50,
	paddingRight: 50,
	paddingTop: 25,
	fontSize: 20,
	fontWeight: 500,
	color: COLORS.black,
	overflowY: 'scroll',
	maxHeight: '700px',
});

export const tierInputClass = css({
	fontSize: 16,
	height: 40,
	padding: 4,
	marginBottom: 0,
	width: '230px',
	[mq[1]]: {
		marginBottom: 0,
		width: '100%',
	},
});

export const titleContainerStyle = (company) => {
	const backgroundColor = get(company, 'brandColor')
		? company.brandColor
		: '#f33943';
	return css({
		width: '100%',
		backgroundColor,
		borderRadius: 20,
	});
};

export const titleStyle = css({
	fontFamily: "'Open Sans', sans-serif",
	color: COLORS.white,
	fontWeight: 600,
	padding: 8,
	paddingTop: 20,
	textAlign: 'center',
});

export const videoWrap = css({
	marginBottom: 18,
	'& > iframe': {
		margin: '0 auto',
		display: 'block',
	},
});
