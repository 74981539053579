import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Col, Input, Modal, Row } from 'antd';
import { ml } from 'src/_shared/services/utils.js';
import { PageItem } from 'src/manageJobs/addJobModal/pages/';

const { TextArea } = Input;

function AddJobForm({
	currentUser,
	allMultiLingualData,
	handleChange,
	addPosition,
	editedDescription,
	toggleNewPosition,
	handleCancel,
	submitNewPosition,
	form,
}) {
	const { getFieldDecorator, getFieldValue, validateFields, resetFields } =
		form;

	return (
		<Modal
			centered
			open={addPosition}
			okText="Submit"
			title="Add Job"
			onCancel={() => {
				resetFields();
				toggleNewPosition();
				handleCancel();
			}}
			onOk={() => {
				validateFields(async (error, values) => {
					if (error) {
						return;
					}

					await submitNewPosition();
					resetFields();
				});
			}}
		>
			<Form>
				<div>
					<Row gutter={12}>
						<Col sm={12} xs={24}>
							<div className="custom-form-group">
								<PageItem
									title={ml('Position', currentUser, allMultiLingualData)}
								>
									<Form.Item>
										{getFieldDecorator('position', {
											rules: [
												{
													required: true,
													whitespace: true,
													message: 'Please enter your position!',
												},
											],
										})(
											<Input
												id="editedPosition"
												name="editedPosition"
												placeholder={ml(
													'Position',
													currentUser,
													allMultiLingualData
												)}
												className="custom-input"
												onChange={handleChange('editedResumePosition')}
											/>
										)}
									</Form.Item>
								</PageItem>
							</div>
						</Col>
						<Col sm={12} xs={24}>
							<div className="custom-form-group">
								<PageItem
									title={ml('Company', currentUser, allMultiLingualData)}
								>
									<Form.Item>
										{getFieldDecorator('company', {
											rules: [
												{
													required: true,
													whitespace: true,
													message: 'Please enter your company!',
												},
											],
										})(
											<Input
												name="editedOrg"
												placeholder="Organization"
												className="custom-input"
												onChange={handleChange('editedOrg')}
											/>
										)}
									</Form.Item>
								</PageItem>
							</div>
						</Col>
					</Row>

					<Row gutter={12}>
						<Col sm={12} xs={24}>
							<div className="custom-form-group">
								<PageItem
									title={ml('Start Date', currentUser, allMultiLingualData)}
								>
									<Form.Item>
										{getFieldDecorator('startDate', {
											rules: [
												{
													required: true,
													message: 'Please enter your start date!',
												},
												{
													validator(rule, value, callback) {
														const endDate = getFieldValue('endDate');

														if (value && endDate && value > endDate) {
															return callback(
																'Start Date must be before End Date'
															);
														}

														callback();
													},
												},
											],
										})(
											<Input
												name="startDate"
												placeholder={ml(
													'Start Date',
													currentUser,
													allMultiLingualData
												)}
												type="date"
												className="custom-input"
												onChange={handleChange('editedStartDate')}
											/>
										)}
									</Form.Item>
								</PageItem>
							</div>
						</Col>
						<Col sm={12} xs={24}>
							<div className="custom-form-group">
								<PageItem
									title={ml('End Date', currentUser, allMultiLingualData)}
								>
									<Form.Item>
										{getFieldDecorator('endDate', {
											rules: [
												{
													required: false,
												},
												{
													validator(rule, value, callback) {
														const startDate = getFieldValue('startDate');

														if (value && startDate && value < startDate) {
															return callback(
																'End Date must be after Start Date'
															);
														}

														callback();
													},
												},
											],
										})(
											<Input
												name="endDate"
												placeholder={ml(
													'End Date',
													currentUser,
													allMultiLingualData
												)}
												type="date"
												className="custom-input"
												onChange={handleChange('editedEndDate')}
											/>
										)}
									</Form.Item>
								</PageItem>
							</div>
						</Col>
					</Row>

					<Row>
						<div className="custom-form-group mb-0 w-100">
							<PageItem
								title={ml('Description', currentUser, allMultiLingualData)}
							>
								<TextArea
									autoSize={{ minRows: 2, maxRows: 6 }}
									name="editedDescription"
									placeholder={ml(
										'Description',
										currentUser,
										allMultiLingualData
									)}
									value={editedDescription}
									className="custom-input"
									onChange={handleChange('editedDescription')}
								/>
							</PageItem>
						</div>
					</Row>
				</div>
			</Form>
		</Modal>
	);
}

const EnhancedAddJobForm = Form.create()(AddJobForm);

export default EnhancedAddJobForm;
