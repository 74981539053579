import { css } from 'emotion';
import { COLORS } from '../../styles/colors';

export const departmentContainer = css({
	justifyContent: 'center',
	alignItems: 'center',
	backgroundColor: COLORS.lightGreen,
	width: 'auto',
	borderRadius: 4,
	display: 'inline-block',
	borderColor: COLORS.lightGreen,
	flex: '0, 0, auto',
	margin: 3,
});

export const closeIcon = css({
	fontSize: '0.9em',
	color: COLORS.green,
	marginRight: 5,
});

export const departmentText = css({
	color: COLORS.green,
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 100,
	fontSize: '0.85em',
	margin: 5,
});
