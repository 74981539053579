import { Avatar } from 'antd';
import { Component } from 'react';
import email from '../../../assets/email-filled.png';
import gmail from '../../../assets/gmail.png';
import outlook from '../../../assets/outlook.png';
import {
	ModalTitle,
	SubTitle,
	roundSocial,
	roundSocialRow,
	roundSocialText,
} from './addContactsModalStyles.js';

class AddContactsChoice extends Component {
	constructor(props) {
		super(props);
		this.state = {
			error: false,
			size: 'large',
			emailInput: '',
		};
	}

	render() {
		const { contactsChoice } = this.props;
		return (
			<div>
				<h1 className={ModalTitle}>Add Contacts</h1>
				<p className={SubTitle}>
					Automatically find people to refer to open jobs. <br />
					We only share your contact information when you make a referral
				</p>
				<div className={roundSocialRow}>
					<div>
						<Avatar
							className={roundSocial}
							size={60}
							shape="square"
							src={gmail}
							onClick={() => contactsChoice(2)}
						/>
						<span className={roundSocialText}>Google</span>
					</div>
					<div>
						<Avatar
							className={roundSocial}
							size={60}
							shape="square"
							src={outlook}
							onClick={() => contactsChoice(3)}
						/>
						<span className={roundSocialText}>Outlook</span>
					</div>
					<div>
						<Avatar
							className={roundSocial}
							size={60}
							shape="square"
							src={email}
							onClick={() => contactsChoice(4)}
						/>
						<span className={roundSocialText}>Email</span>
					</div>
				</div>
			</div>
		);
	}
}
export default AddContactsChoice;
