import { Row } from 'antd';
import {
	DepartmentsContainer,
	departmentContainer,
	departmentText,
} from './departmentItemStyles.js';

function DepartmentItem(props) {
	const { departments } = props;
	return (
		<div className={DepartmentsContainer}>
			{departments.map((item) => {
				return (
					<div key={item.departmentId}>
						<Row className={departmentContainer}>
							<span className={departmentText}> {item.department.name} </span>
						</Row>
					</div>
				);
			})}
		</div>
	);
}

export default DepartmentItem;
