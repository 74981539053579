export const updateAnnouncement = `mutation updateAnnouncement($input: UpdateAnnouncementInput!) {
    updateAnnouncement(input: $input) {
      id
      companyId
      title
    content
    dateCreated
    hide
    userGroups
    displayGroupSelect
    }
  }
  `;
