import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Alert, Button, Input, Popconfirm, Table, message } from 'antd';
import dayjs from 'dayjs';
import { Component } from 'react';

class CompanyCard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			emailExists: false,
			companyExists: false,
			serverError: false,
		};
	}

	handleSubmit = (e) => {
		e.preventDefault();
		const { addUser, addCompany, addDepartment, currentUser, addUserGroup } =
			this.props;
		this.props.form.validateFields(async (error, values) => {
			if (error) {
				return;
			}

			this.setState({ companyExists: false, emailExists: false });
			try {
				const companyData = await addCompany({
					input: {
						name: values.company,
					},
				});
				const addDepartmentPromise = addDepartment({
					input: {
						companyId: companyData.data.createCompany.id,
						name: 'Other',
						active: true,
					},
				});
				const addUserGroupPromise = addUserGroup({
					input: {
						companyId: companyData.data.createCompany.id,
						name: 'Default',
						active: true,
						currency: 'USD',
					},
				});
				const addExternalUserGroupPromise = addUserGroup({
					input: {
						companyId: companyData.data.createCompany.id,
						name: 'External',
						active: true,
					},
				});
				await Promise.all([
					addDepartmentPromise,
					addUserGroupPromise,
					addExternalUserGroupPromise,
				]);
				message.config({
					top: 40,
				});
				message.success('Company Successfully Created!', 2.5, () => {
					this.props.form.resetFields();
				});
			} catch (error) {
				this.setState({ serverError: true });
				console.error(error);
			}
		});
	};

	render() {
		const dataSource = this.props.companies.map((company) => ({
			name: company ? company.name : '',
			date: company.dateCreated,
		}));

		const columns = [
			{
				title: 'Company Name',
				dataIndex: 'name',
				key: 'name',
			},
			{
				title: 'Date Added',
				dataIndex: 'date',
				key: 'date',
				render: (dateAdded) => dayjs(dateAdded).format('M/D/YYYY'),
			},
		];

		const { emailExists, companyExists, serverError } = this.state;
		const { getFieldDecorator } = this.props.form;
		const FormItem = Form.Item;
		return (
			<div className="custom-card">
				<h5>Add a New Company</h5>
				<Form>
					<div className="add-company-form">
						<FormItem>
							<div
								className="custom-form-group"
								style={{ marginRight: 10, width: 300 }}
							>
								<label className="custom-label">Company Name</label>
								{getFieldDecorator('company', {
									rules: [
										{
											required: true,
											message: 'Company name required',
										},
									],
								})(<Input className="custom-input" />)}
							</div>
						</FormItem>
						<FormItem>
							<Popconfirm
								key={1}
								title="Are you sure you want to create this new company?"
								placement="bottom"
								okText="Yes"
								cancelText="No"
								onConfirm={this.handleSubmit}
							>
								<Button type="primary" size="large">
									Create Company
								</Button>
							</Popconfirm>
						</FormItem>
					</div>
					{companyExists ? (
						<Alert type="error" message="Company already exists" />
					) : null}
					{emailExists ? (
						<Alert type="error" message="Email is already in use" />
					) : null}
					{serverError ? <Alert type="error" message="Server error" /> : null}
				</Form>
				<div className="table-card">
					<Table dataSource={dataSource} columns={columns} />
				</div>
			</div>
		);
	}
}

export default Form.create()(CompanyCard);
