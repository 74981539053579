import { Component } from 'react';
import { USER_ROLES } from '../../../_shared/constants';

class ApplyInternallyCard extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	handleInternalMobility = () => {
		this.props.history.push('/internal');
	};

	render() {
		const {
			currentUser,
			background,
			internalCardHeader,
			internalCardSubheader,
			internalCardButton,
			textColor,
		} = this.props;

		return (
			<div className="e-card-wrap" style={{ backgroundColor: background }}>
				{currentUser.displayAs === USER_ROLES.EMPLOYEE && (
					<>
						<div className="e-card-title" style={{ color: textColor }}>
							{internalCardHeader}
						</div>
						<div className="e-card-subtitle" style={{ color: textColor }}>
							{internalCardSubheader}
						</div>

						<button
							type="button"
							className="c-btn-white"
							onClick={() => {
								this.handleInternalMobility();
							}}
						>
							{internalCardButton}
						</button>
					</>
				)}
			</div>
		);
	}
}
export const ApplyInternally = ApplyInternallyCard;
