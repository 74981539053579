import gql from 'graphql-tag';
import get from 'lodash/get';
import { compose, graphql } from 'react-apollo';
import { queryWebNotificationsByUserIdStatusIndex } from 'src/_shared/api/graphql/custom/users/';
import { updateWebNotification } from '../../graphql/custom/users';

export const withListReferralRequestedNotificationsByUserIdStatus = (
	Component
) => {
	return compose(
		graphql(gql(queryWebNotificationsByUserIdStatusIndex), {
			options(props) {
				return {
					pollInterval: 300_000,
					context: {
						headers: {
							'x-frame-options': 'deny', // This header will reach the server
						},
					},
					variables: {
						userId: get(props, 'user.id'),
						status: 'referred',
						nextToken: null,
					},
					fetchPolicy: 'cache-and-network',
				};
			},
			props(props) {
				let notifications = get(
					props,
					'data.queryWebNotificationsByUserIdStatusIndex.items'
				);
				if (!notifications) return null;
				notifications = notifications.map((notification) => {
					const newNotification = notification;
					if (get(notification, 'type') === 'gdprReferralCreated')
						newNotification.referralDate = get(notification, 'dateCreated');
					if (typeof get(notification, 'job.referralBonus') === 'string') {
						newNotification.job.referralBonus = JSON.parse(
							get(notification, 'job.referralBonus', '{}')
						);
					}

					return newNotification;
				});
				return {
					gdprReferrals: notifications,
				};
			},
		}),
		graphql(gql(updateWebNotification), {
			props: (props) => ({
				onUpdateNotification(input) {
					const optimisticResponseData = {
						...input.input,
					};
					props.mutate({
						variables: input,
						optimisticResponse: {
							__typename: 'Mutation',
							updateWebNotification: {
								...optimisticResponseData,
								__typename: 'updateWebNotification',
							},
						},
						update(proxy) {
							const data = proxy.readQuery({
								query: gql(queryWebNotificationsByUserIdStatusIndex),
								variables: {
									userId: props.ownProps.currentUser.id,
									first: 1000,
									nextToken: null,
								},
							});
							proxy.writeQuery({
								query: gql(queryWebNotificationsByUserIdStatusIndex),
								data,
								variables: {
									userId: props.ownProps.currentUser.id,
									first: 1000,
									nextToken: null,
								},
							});
						},
					});
				},
			}),
		})
	)(Component);
};
