import { Popconfirm } from 'antd';

export function CustomPickerList(props) {
	const { data, handleDelete } = props;
	return (
		<ul className="tag-wrap">
			{data
				? data.map((item) => {
						return (
							<li
								className="tag green"
								onClick={() => (e) => e.stopPropagation()}
							>
								<span className="tag-name"> {item.name} </span>
								<Popconfirm
									placement="left"
									title="are you sure？"
									okText="delete"
									cancelText="cancel"
									onConfirm={(e) => handleDelete(item.name)}
								>
									<i className="tag-close icon-cross" />
								</Popconfirm>
							</li>
						);
					})
				: null}
		</ul>
	);
}
