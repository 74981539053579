import { css } from 'emotion';
import { COLORS } from '../../_shared/styles/colors';

export const cardMainStyles = css({
	minHeight: 130,
	borderRadius: 10,
});

export const candidateNameStyles = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: '600 !important',
	color: COLORS.hyperLink,
	fontSize: 18,
	marginBottom: 0,
	lineHeight: 1,
	paddingBottom: 5,
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
	overflow: 'hidden',
});

export const referrerNameStyles = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: '600 !important',
	color: COLORS.blue,
	fontSize: 14,
});

export const miscTextStyles = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 400,
	color: COLORS.heading,
	fontSize: 14,
	textAlign: 'right',
});

export const miscTextColStyles = css({
	textAlign: 'right',
	whiteSpace: 'nowrap',
	overflow: 'hidden',
});

export const statusTextStyles = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 600,
	color: COLORS.heading,
	fontSize: '16px',
});

export const statusStyles = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 400,
	color: COLORS.lightGray,
	fontSize: 14,
	paddingLeft: 10,
});

export const dropdownMenuItem = css({
	borderColor: 'transparent',
	color: COLORS.blue,
	'&:hover': {
		backgroundColor: COLORS.white,
		color: COLORS.blue,
		borderColor: 'transparent',
	},
});

export const dropdownButton = (width) =>
	css({
		marginLeft: 8,
		borderColor: 'transparent',
		color: COLORS.blue,
		'&:hover': {
			backgroundColor: COLORS.white,
			color: COLORS.blue,
			borderColor: 'transparent',
		},
		'&:focus': {
			backgroundColor: COLORS.white,
			color: COLORS.blue,
			borderColor: COLORS.white,
		},
		width,
	});

export const BonusAmount = css({
	color: COLORS.green,
	textAlign: 'right',
	marginTop: -15,
});

export const linkStyles = css({
	fontWeight: '600 !important',
	color: `${COLORS.hyperLink} !important`,
});

export const ModalStyles = css({
	'& .ant-modal-content': {
		borderRadius: 10,
		borderWidth: '1px',
		borderStyle: 'solid',
		borderColor: COLORS.blue,
	},
	'& .ant-modal-close': {
		display: 'none',
	},
	fontFamily: 'Open Sans',
	width: 620,
});

export const tableHeader = css({
	fontFamily: '"Open Sans", sans-serif !important',
	fontWeight: '600 !important',
});

export const successStyles = css({
	width: 250,
	position: 'fixed',
	top: 300,
	left: 480,
});

export const noteClass = css({
	paddingLeft: 7,
});

export const StatusStyles = css({
	paddingTop: 16,
});

export const StatusBold = css({
	fontWeight: 700,
});

export const StatusItalic = css({
	fontStyle: 'italic',
});

export const filterPayment = css({
	padding: 0,
	border: 'none',
	backgroundColor: 'transparent',
	marginLeft: 2,
	fontSize: 14,
	height: 14,
	color: '#018dd3',
	fontWeight: 600,
});
export const cardBefore = (symbol) => {
	return css({
		paddingLeft: '80px',
		borderRadius: '12px',
		overflow: 'hidden',

		'&::after': {
			content: `url(${symbol})`,
			width: '80px',
			height: '100%',
			top: '0',
			left: '0',
			position: 'absolute',
			textAlign: 'center',
			clear: 'none !important',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			color: 'rgba(0,0,0,0.6)',
		},
	});
};

export const orange = css({
	'&::after': {
		background: '#feb361',
	},
});
export const red = css({
	'&::after': {
		background: '#ed3d3f',
	},
});
export const green = css({
	'&::after': {
		background: '#1f861d',
	},
});

export const BonusStatus = (color) => {
	return css({
		color,
		textAlign: 'left',
		marginTop: -15,
	});
};
