export const updateJobMatch = `mutation UpdateJobMatch($input: UpdateJobMatchInput!) {
  updateJobMatch(input: $input) {
    id
    active
    companyId
    contactId
    jobId
  }
}
`;
