import gql from 'graphql-tag';

export const ListJobsOptions = gql`
	query ListJobsOptions(
		$filter: TableJobFilterInput
		$limit: Int
		$nextToken: String
	) {
		listJobsOptions(filter: $filter, limit: $limit, nextToken: $nextToken) {
			items {
				id
				companyId
				company {
					id
					name
				}
				departmentId
				department {
					id
					name
				}

				title
				referralBonus
				description
				publicLink
				location
				shares
				views
				status
				dateCreated
				externalJobId
				externalDepartmentId
				externalSource
			}
			nextToken
		}
	}
`;
