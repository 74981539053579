import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { listContactsDashboard } from '../../graphql/custom/dashboard';

export const withListContacts = (Component) => {
	return graphql(gql(listContactsDashboard), {
		options: (props) => ({
			context: {
				headers: {
					'x-frame-options': 'deny', // This header will reach the server
				},
			},
			variables: {
				filter: { userId: { eq: props.currentUser.id } },
				limit: 10_000,
				nextToken: null,
			},
			fetchPolicy: 'cache-and-network',
		}),
		props: (props) => ({
			contacts: props.data.listContacts ? props.data.listContacts.items : [],
		}),
	})(Component);
};
