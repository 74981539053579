import { css } from 'emotion';
import { COLORS } from '../../_shared/styles/colors';

const breakpoints = [576, 767, 991, 1200];

const mq = breakpoints.map((bp) => `@media (max-width: ${bp}px)`);

export const toggleButtonContainerStyles = css({
	display: 'flex',
	alignItems: 'center',
	[mq[1]]: {
		margin: '10px 0',
	},
});

export const openButtonStyles = (selected) =>
	css({
		margin: 5,
		marginTop: 8,
		backgroundColor: selected ? '#8d99a3' : COLORS.white,
		color: selected ? COLORS.white : COLORS.subHeading,
		'&:hover': {
			backgroundColor: '#8d99a3',
			color: COLORS.white,
			borderColor: 'transparent',
		},
		'&:focus': {
			backgroundColor: '#8d99a3',
			color: COLORS.white,
			borderColor: 'transparent',
		},
		height: 30,
		width: 75,
	});

export const openClosedButtonStyles = (selected) =>
	css({
		backgroundColor: selected ? '#8d99a3' : COLORS.white,
		color: selected ? COLORS.white : COLORS.subHeading,
		'&:hover': {
			backgroundColor: '#8d99a3',
			color: COLORS.white,
			borderColor: 'transparent',
		},
		'&:focus': {
			backgroundColor: '#8d99a3',
			color: COLORS.white,
			borderColor: 'transparent',
		},
		height: 30,
		padding: '4px',
		width: '91px',
	});

export const toggleTitleStyles = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 600,
	color: COLORS.black,
	fontSize: '18',
});

export const RippleButton = css({
	display: 'inline !important',
});
