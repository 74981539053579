export const state = {
	users: [],
	currentUser: null,
	superUser: {},
	company: {},
	topReferrers: [],
	currentClient: {},
	failedLoginAttempts: 0,
	jobSearchCriteria: '',
};
