/** Packages */
import { Select } from 'antd';
import { sortBy } from 'lodash';

/** Shared Utils */
import {
	FREQUENCY_MAP,
	FREQUENCY_TYPE,
	FREQUENCY_VALUE,
} from 'src/_shared/constants/';
import { COLORS } from 'src/_shared/styles/colors.js';
import { initialCaps, ml } from '../../_shared/services/utils.js';

export function Notifications({
	allMultiLingualData,
	currentUser,
	labelDepartment,
	departmentOptions,
	enableJobNotifications,
	getFieldDecorator,
	handleChange,
	handleFrequencyTypeChange,
	isEditing,
	isOptoutReferralNotification,
	jobNotificationSetting,
	notificationFrequency,
	notificationType,
	subCompanies,
	subCompanyLabel,
}) {
	const {
		departmentSelection,
		subCompanySelection,
		salarySelection = null,
	} = jobNotificationSetting;

	const sortedSubcompanies = sortBy(subCompanies, ['name']);
	const sortedDepartmentOptions = sortBy(departmentOptions, ['name']);

	return (
		<>
			<h4 className="profile-heading">
				{ml('Job Notification Settings', currentUser, allMultiLingualData)}:
			</h4>

			<div className="po-details-edit custom-form-group custom-checkbox left-check">
				<input
					type="checkbox"
					id="newJobNotifications"
					checked={enableJobNotifications}
					disabled={!isEditing}
					onChange={(e) =>
						handleChange({ enableJobNotifications: e.target.checked })
					}
				/>
				<label htmlFor="newJobNotifications">
					{ml(
						'Receive new job notifications',
						currentUser,
						allMultiLingualData
					)}
				</label>
			</div>

			{enableJobNotifications && (
				<>
					<h5 className="profile-subheading">
						{ml('Frequency', currentUser, allMultiLingualData)}
					</h5>
					<div className="row">
						<div className="col-md-6">
							<div className="custom-form-group">
								<label className="custom-label">
									{ml('Delivery Frequency', currentUser, allMultiLingualData)}
								</label>
								{getFieldDecorator(`deliveryFrequency`, {
									initialValue:
										ml(
											initialCaps(notificationType),
											currentUser,
											allMultiLingualData
										) ?? '',
								})(
									<Select
										placeholder={ml(
											'Select Frequency ',
											currentUser,
											allMultiLingualData
										)}
										className="custom-input"
										disabled={!isEditing}
										onSelect={handleFrequencyTypeChange}
									>
										{Object.values(FREQUENCY_TYPE).map((frequency) => (
											<Select.Option key={frequency} value={frequency}>
												{ml(
													initialCaps(frequency),
													currentUser,
													allMultiLingualData
												)}
											</Select.Option>
										))}
									</Select>
								)}
							</div>
						</div>

						{notificationType !== FREQUENCY_VALUE.DAILY && (
							<div className="col-md-6">
								<div className="custom-form-group">
									<label className="custom-label">
										{ml('Delivery Date', currentUser, allMultiLingualData)}
									</label>
									{getFieldDecorator(`deliveryTime`, {
										initialValue:
											ml(
												initialCaps(notificationFrequency),
												currentUser,
												allMultiLingualData
											) ?? '',
									})(
										<Select
											placeholder={ml(
												'Select Date ',
												currentUser,
												allMultiLingualData
											)}
											className="custom-input"
											disabled={!isEditing}
											onSelect={(value) =>
												handleChange({ notificationFrequency: value })
											}
										>
											{Object.values(FREQUENCY_MAP)
												.filter(
													(frequency) => frequency.type === notificationType
												)
												.map((frequency) => (
													<Select.Option
														key={frequency.key}
														title={frequency.key}
														value={frequency.value}
													>
														{ml(
															frequency.key,
															currentUser,
															allMultiLingualData
														)}
													</Select.Option>
												))}
										</Select>
									)}
								</div>
							</div>
						)}
					</div>
				</>
			)}

			{enableJobNotifications && (
				<>
					<h5 className="profile-subheading">
						{ml('Alert Filters', currentUser, allMultiLingualData)}
					</h5>
					<div className="row">
						<div className="col-md-6">
							<div className="custom-form-group">
								<label className="custom-label">
									{subCompanyLabel
										? `${ml(subCompanyLabel, currentUser, allMultiLingualData)}`
										: ml('Subcompany', currentUser, allMultiLingualData)}
								</label>
								<Select
									showSearch
									showArrow
									labelInValue
									mode="multiple"
									className="custom-input"
									placeholder={
										subCompanyLabel
											? `${ml('Select', currentUser, allMultiLingualData)} ${ml(
													subCompanyLabel,
													currentUser,
													allMultiLingualData
												)}`
											: ml(
													'Select Sub Company',
													currentUser,
													allMultiLingualData
												)
									}
									defaultValue={subCompanySelection}
									optionLabelProp="label"
									maxTagCount={1}
									value={subCompanySelection}
									disabled={!isEditing}
									onChange={(value) =>
										handleChange({
											jobNotificationSetting: {
												...jobNotificationSetting,
												subCompanySelection: value,
											},
										})
									}
								>
									{Object.keys(sortedSubcompanies).length > 0 &&
										sortedSubcompanies.map((item, index) => {
											return (
												<Select.Option
													key={index}
													value={item.id}
													label={item.name}
												>
													<div className="demo-option-label-item">
														{item.name}
													</div>
												</Select.Option>
											);
										})}
								</Select>
							</div>
						</div>
						<div className="col-md-6">
							<div className="custom-form-group">
								<label className="custom-label">
									{labelDepartment
										? `${ml(labelDepartment, currentUser, allMultiLingualData)}`
										: ml('Department', currentUser, allMultiLingualData)}
								</label>
								<Select
									showSearch
									showArrow
									labelInValue
									mode="multiple"
									className="custom-input"
									placeholder={
										labelDepartment
											? `${ml('Select', currentUser, allMultiLingualData)} ${ml(
													labelDepartment,
													currentUser,
													allMultiLingualData
												)}s`
											: ml(
													'Select Departments',
													currentUser,
													allMultiLingualData
												)
									}
									defaultValue={departmentSelection}
									optionLabelProp="label"
									maxTagCount={1}
									value={departmentSelection}
									disabled={!isEditing}
									onChange={(value) =>
										handleChange({
											jobNotificationSetting: {
												...jobNotificationSetting,
												departmentSelection: value,
											},
										})
									}
								>
									{Object.keys(sortedDepartmentOptions).length > 0 &&
										sortedDepartmentOptions.map((item, index) => {
											return (
												<Select.Option
													key={index}
													value={item.id}
													label={item.name}
												>
													<div className="demo-option-label-item">
														{item.name}
													</div>
												</Select.Option>
											);
										})}
								</Select>
							</div>
						</div>
						<div className="col-md-6">
							<div className="custom-form-group">
								<label className="custom-label">
									{ml('Min. Pay Rate', currentUser, allMultiLingualData)}
								</label>
								<Select
									placeholder="Select Min. Pay Rate"
									className="custom-input"
									defaultValue={salarySelection}
									value={salarySelection}
									disabled={!isEditing}
									onChange={(value) =>
										handleChange({
											jobNotificationSetting: {
												...jobNotificationSetting,
												salarySelection: value,
											},
										})
									}
								>
									<Select.Option key={1} value="">
										<span style={{ color: COLORS.placeholderGray }}>
											Select Min. Pay Rate
										</span>
									</Select.Option>
									<Select.Option key={0} value={0}>
										Any Min. Pay Rate
									</Select.Option>
									<Select.Option key={30_000} value={30_000}>
										$15/hr. / $30,000/yr.
									</Select.Option>
									<Select.Option key={40_000} value={40_000}>
										$20/hr. / $40,000/yr.
									</Select.Option>
									<Select.Option key={50_000} value={50_000}>
										$25/hr. / $50,000/yr.
									</Select.Option>
									<Select.Option key={60_000} value={60_000}>
										$30/hr. / $60,000/yr.
									</Select.Option>
									<Select.Option key={70_000} value={70_000}>
										$35/hr. / $70,000/yr.
									</Select.Option>
									<Select.Option key={80_000} value={80_000}>
										$40/hr. / $80,000/yr.
									</Select.Option>
									<Select.Option key={90_000} value={90_000}>
										$45/hr. / $90,000/yr.
									</Select.Option>
									<Select.Option key={100_000} value={100_000}>
										$50/hr. / $100,000/yr.
									</Select.Option>
									<Select.Option key={110_000} value={110_000}>
										$55/hr. / $110,000/yr.
									</Select.Option>
									<Select.Option key={120_000} value={120_000}>
										$60/hr. / $120,000/yr.
									</Select.Option>
									<Select.Option key={140_000} value={140_000}>
										$70/hr. / $140,000/yr.
									</Select.Option>
									<Select.Option key={160_000} value={160_000}>
										$80/hr. / $160,000/yr.
									</Select.Option>
									<Select.Option key={180_000} value={180_000}>
										$90/hr. / $180,000/yr.
									</Select.Option>
									<Select.Option key={200_000} value={200_000}>
										$100/hr. / $200,000/yr.
									</Select.Option>
									<Select.Option key={250_000} value={250_000}>
										$125/hr. / $250,000/yr.
									</Select.Option>
									<Select.Option key={300_000} value={300_000}>
										$150/hr. / $300,000/yr.
									</Select.Option>
								</Select>
							</div>
						</div>
					</div>
				</>
			)}

			<div className="po-details-edit custom-form-group custom-checkbox left-check">
				<input
					type="checkbox"
					id="referralOptoutUpdates"
					checked={isOptoutReferralNotification}
					disabled={!isEditing}
					onChange={(e) =>
						handleChange({ isOptoutReferralNotification: e.target.checked })
					}
				/>
				<label htmlFor="referralOptoutUpdates">
					{ml('Opt Out of Referral Updates', currentUser, allMultiLingualData)}{' '}
				</label>
			</div>
		</>
	);
}
