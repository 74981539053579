import { useRef, useEffect } from 'react';
import Slider from "react-slick";
import { Button, Icon, Tooltip } from 'antd';
import { CoreBadge } from '../../../RnRCommonComponent';
import { CheckOutlined, LeftCircleFilled, LeftCirleFilled, RightCircleFilled } from '@ant-design/icons';
import { addCommas } from '../../../rewardsAndRecognitionHelper/utils.js';

function CustomNextArrow(props) {
  const { className, style, onClick } = props;
  return (
      <RightCircleFilled onClick={onClick} className={className} style={{ ...style }} />
  );
}

function CustomPrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <LeftCircleFilled onClick={onClick} className={className} style={{ ...style }} />
  );
}

export const SendRewardSlider = (props) => {
  const {
    badgeData,
    setSendRewardObject,
    sendrewardObject,
    selectedPage
  } = props;
  let sliderRef = useRef(null);

  useEffect(() => {
    sliderRef.slickGoTo(selectedPage);
  }, [selectedPage]);

  const settings = {
    dots: true,
    infinite: badgeData?.length >= 3,
    speed: 500,
    slidesToShow: badgeData?.length >= 3 ? 3 : badgeData?.length,
    slidesToScroll: badgeData?.length >= 3 ? 3 : 0,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />
  };

  const handleClick = (badge) => {

    setSendRewardObject({
      ...sendrewardObject,
      rewardtype: badge.id,
      selectedBadge: badge
    });
  };

  return (
    <div className='main-slider-container'>
      <Slider
        ref={slider => {
          sliderRef = slider;
        }}
        {...settings}
        className="badge-slider"
      >
        {badgeData?.map((badge) => (
          <div key={"badge-" + badge.id} className={`${sendrewardObject?.rewardtype === badge.id ? "send-reward-slider" : "slider-container-hover"} slider-container`}>
            <div className='slider-img' disabled={badge.visibility === false} style={{ cursor: 'pointer', opacity: badge.visibility ? 1 : 0.3 }}>
              {sendrewardObject?.rewardtype === badge.id ?
                <div className="active-tick send-reward-slider">
                  {/* <Icon type="check" /> */}
                  <CheckOutlined />
                </div> : <></>}
              <div>
                <Tooltip
                  placement="top"
                  title={badge?.description}
                  style={{ fontWeight: 1000 }}
                >
                  <div onClick={() => handleClick(badge)} className='slider-badges-svg'> {/* Pass the badge object to handleClick */}
                    <CoreBadge
                      badgeType={badge?.badgeTypeCode}
                      badgeIcon={badge?.iconCode}
                      label={badge?.label}
                      color={badge?.color}
                      gradientColor={badge?.gradientColor || badge?.gradientcolor}
                    />
                    <div className="text-center img-label">{addCommas(badge.points) + " Points" || ""}</div>
                    {badge?.ispreapproved ? "" : <span>{"Approval Required"}</span>}
                  </div>
                </Tooltip>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}
export default SendRewardSlider;