import { css } from 'emotion';

export const borderedTileContainerClass = (
	width,
	height,
	padding,
	margin,
	minHeight,
	minWidth,
	background
) =>
	css({
		cursor: 'pointer',
		width: width || 'calc(100% - 20px)',
		...(height ? { height } : {}),
		margin: margin || 10,
		background: background ? background : 'white',
		padding: padding || 25,
		borderRadius: 10,
		position: 'relative',
		minHeight: minHeight ? minHeight : 265,
		minWidth: minWidth ? minWidth : 240,
	});
