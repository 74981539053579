import gql from 'graphql-tag';
import dayjs from 'dayjs';
import { compose, graphql } from 'react-apollo';
import uuid from 'uuid/v4';
import {
	createContact,
	queryContactsByUserIdIndex,
	updateContact,
} from '../../graphql/custom/contacts';
import { createReferral } from '../../graphql/custom/referrals';

export const withCreateReferralNoUpdate = (Component) => {
	return compose(
		graphql(gql(createReferral), {
			props: (props) => ({
				onCreateReferral(referral) {
					const optimisticResponseData = {
						...referral,
						id: uuid(),
						referralDate: dayjs(),
					};
					return props.mutate({
						variables: { input: referral },
						optimisticResponse: {
							__typeName: 'Mutation',
							createReferral: {
								__typeName: 'createReferral',
								...optimisticResponseData,
							},
						},
					});
				},
			}),
		}),
		graphql(gql(createContact), {
			props: (props) => ({
				ImportedCreateContact(input) {
					const optimisticResponseData = {
						id: uuid(),
						...input.input,
						__typename: 'Contact',
					};
					return props.mutate({
						variables: input,
						optimisticResponse: {
							__typename: 'Mutation',
							createContact: {
								__typename: 'createContact',
								...optimisticResponseData,
							},
						},
						update(store, { data: { createContact } }) {
							try {
								const data = store.readQuery({
									query: gql(queryContactsByUserIdIndex),
									variables: { userId: props.ownProps.currentUser.id },
								});
								if (
									!data.queryContactsByUserIdIndex.items.find(
										(contact) => contact.id === createContact.id
									)
								)
									data.queryContactsByUserIdIndex.items.push(createContact);
								store.writeQuery({
									query: gql(queryContactsByUserIdIndex),
									data,
								});
							} catch (error) {
								console.log(error);
							}
						},
					});
				},
			}),
		}),
		graphql(gql(updateContact), {
			props: (props) => ({
				onUpdateContact(input) {
					const optimisticResponseData = {
						...input,
						__typename: 'Contact',
					};
					return props.mutate({
						variables: { input: { ...input } },
						optimisticResponse: {
							__typename: 'Mutation',
							updateContact: {
								__typename: 'updateContact',
								...optimisticResponseData,
							},
						},
						update(store, { data: { updateContact } }) {
							try {
								const data = store.readQuery({
									query: gql(queryContactsByUserIdIndex),
									variables: { userId: props.ownProps.currentUser.id },
								});
								const index = data.queryContactsByUserIdIndex.items.findIndex(
									(contact) => contact.id === updateContact.id
								);
								if (index > -1)
									data.queryContactsByUserIdIndex.items.splice(
										index,
										1,
										updateContact
									);
								store.writeQuery({
									query: gql(queryContactsByUserIdIndex),
									data,
								});
							} catch (error) {
								console.log(error);
							}
						},
					});
				},
			}),
		})
	)(Component);
};
