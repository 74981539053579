import { subTitleStyle } from './pagesStyles.js';

export function PageItemSetting(props) {
	const { title, subtitle, children, optional } = props;
	return (
		<div>
			<span> {title} </span>
			{optional && <span className={subTitleStyle}> (optional) </span>}

			{subtitle && <span className={subTitleStyle}> {subtitle} </span>}
			{children}
		</div>
	);
}
