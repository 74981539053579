import React from "react";
import RewardPointsAndBonus from './../../presentation/rewardPointsAndBonus/RewardPointsAndBonus'
import useMyRewardsPoint from "../../hooks/useMyRewardsPoint";

const RewardPointsAndBonusContainer = () => {
  const { rewardPointsBonus } = useMyRewardsPoint()
  return <RewardPointsAndBonus rewardPointsBonus={rewardPointsBonus} />
};

export default RewardPointsAndBonusContainer;
