import gql from 'graphql-tag';

export const GetCompanySettingsData = gql`
	query GetCompanyData($companyId: ID!) {
		getCompanyData(companyId: $companyId) {
			id
			name
			defaultBonusAmount
			contactIncentiveBonus
			brandColor
			websiteUrl
			sendAdminNotificationsOnReferral
			sendAdminNotificationsOnInternalCandidatesFound
			referralBonusWaitingPeriod
			referralBonusNotificationRecipients
			dashboardReferralPolicyText
			bonusEarnedNote
			allowSelfReferrals
			allowInternalMobility
			internalMobilityImage {
				bucket
				region
				key
			}
			dateCreated
			disableSmartReferrals
			disableSAMLLogin
			disableClaimYourAccountLogin
			disableShareLink
			disableNewReferralSMSNotification
			confirmCompliance
			ssoGoogleDomain
			confirmContactEmails
			theme
			symbol {
				bucket
				region
				key
			}
			background {
				bucket
				region
				key
			}
			errorImage {
				bucket
				region
				key
			}
			whiteLabel
			enableGeneralReferrals
			enableExtendedNetwork
			enableCareerProfile
			referralStatus
			referralCustomStatuses
			referralQuestions {
				id
				companyId
				questions
				active
				isCandidate
				sortOrder
				isInterested
				isGeneral
				subCompanyId
			}
			allowSelfReferralsInternalLink
			disableReferrals
			pointsSettings
			hideBonus
			hideShareLinkForDepartment
			hideShareLinkNoPublicUrl
			hideDateCreatedJobDetails
			hideSubcompanyOnJobs
			hidePaymentInfoJobCard
			subCompanyLabel
			hideBonusFilterOnBrowseJobs
			labelDepartment
			defaultLocation
			onboarding
			labelSocialShare
			showPoweredByErin
			ownershipSettings
			helpUrl
			privacyUrl
			termsUrl
			linkUrl
			linkName
			customGeneralModal
			analyticsCardInputValues
			gifIds
		}
	}
`;
