import { connect } from 'react-redux';
import { userActions } from 'src/actions.js';
import ExtendedLoginComponent from './ExtendedLoginModalComponent.jsx';

const mapStateToProperties = (state) => {
	const { currentUser } = state.user;
	return {
		currentUser,
	};
};

const mapDispatchToProperties = (dispatch) => {
	return {
		setCurrentUser(user) {
			dispatch(userActions.createSetCurrentUserAction(user));
		},
	};
};

export const ExtendedLoginModal = connect(
	mapStateToProperties,
	mapDispatchToProperties
)(ExtendedLoginComponent);
