import { connect } from 'react-redux';
import { withCreateReferral } from 'src/_shared/api/components/referrals/';
import { compose } from '../../services/utils';
import InviteToApplyButtonWrapper from './InviteToApplySendComponent.jsx';

const mapStateToProperties = (state) => {
	const { currentUser } = state.user;
	return {
		currentUser,
		filter: { userId: { eq: currentUser.id } },
		limit: 1000,
	};
};

const InviteToApplyWithApi = compose(withCreateReferral)(
	InviteToApplyButtonWrapper
);

export const InviteToApplyButton =
	connect(mapStateToProperties)(InviteToApplyWithApi);
