import get from 'lodash/get';
import { connect } from 'react-redux';
import { withQueryBonusByUserId } from 'src/_shared/api/components/bonuses/';
import { withListMyReferralsContacts } from 'src/_shared/api/components/contacts/';
import {
	withListReferralRequestedNotifications,
	withListReferralRequestingNotifications,
} from 'src/_shared/api/components/users/';
import { dashboardActions, manageJobsActions } from 'src/actions.js';
import { compose } from '../_shared/services/utils.js';
import MyReferralsExtendedComponent from './MyReferralsExtendedComponent.jsx';

const mapStateToProperties = (state) => {
	const { currentUser } = state.user;
	const { allMultiLingualData } = state.dashboard;
	const extendedContactsData = get(currentUser, 'extendedContactData', []);
	let referrals = [];
	extendedContactsData.map((contact) => {
		referrals = [...referrals, ...get(contact, 'referrals')];
	});
	return {
		currentUser,
		allMultiLingualData,
		referrals,
	};
};

const mapDispatchToProperties = (dispatch) => {
	return {
		setCurrentCurrencyRate(currencyRate) {
			dispatch(manageJobsActions.setCurrentCurrencyRate(currencyRate));
		},
		setCurrentCurrencySymbol(currencySymbol) {
			dispatch(manageJobsActions.setCurrentCurrencySymbol(currencySymbol));
		},
		setMultiLingualData(data) {
			dispatch(dashboardActions.createSetMultiLingualData(data));
		},
	};
};

export const MyReferralsExtendedWithApi = compose(
	withQueryBonusByUserId,
	withListMyReferralsContacts,
	withListReferralRequestedNotifications,
	withListReferralRequestingNotifications
)(MyReferralsExtendedComponent);

export const MyReferralsExtended = connect(
	mapStateToProperties,
	mapDispatchToProperties
)(MyReferralsExtendedWithApi);
