import gql from 'graphql-tag';
import { compose, graphql } from 'react-apollo';
import { createUser } from 'src/_shared/api/graphql/custom/users/';
import { updateAccountClaim } from 'src/graphql/custom/account-claims/updateAccountClaim.js';
import { updateUserInvite } from '../../graphql/default';

export const withGetUserInvite = (Component, variables) => {
	return compose(
		graphql(gql(createUser), {
			props: (props) => ({
				onCreate(input) {
					return props.mutate({
						context: {
							headers: {
								'x-frame-options': 'deny', // This header will reach the server
							},
						},
						variables: input,
					});
				},
			}),
		}),
		graphql(gql(updateUserInvite), {
			props: (props) => ({
				onUpdateInvite(input) {
					props.mutate({
						context: {
							headers: {
								'x-frame-options': 'deny', // This header will reach the server
							},
						},
						variables: input,
					});
				},
			}),
		}),
		graphql(gql(updateAccountClaim), {
			props: (props) => ({
				onUpdateAccountClaim(input) {
					props.mutate({
						context: {
							headers: {
								'x-frame-options': 'deny', // This header will reach the server
							},
						},
						variables: input,
					});
				},
			}),
		})
	)(Component);
};
