import { connect } from 'react-redux';
import { userActions } from 'src/actions.js';
import ExtendedLoginLinkComponent from './ExtendedLoginLinkModalComponent.jsx';

const mapStateToProperties = (state) => {
	const { currentUser } = state.user;

	return {
		currentUser,
	};
};

const mapDispatchToProperties = (dispatch) => {
	return {
		setCurrentUser(user) {
			dispatch(userActions.createSetCurrentUserAction(user));
		},
	};
};

export const ExtendedLoginLinkModal = connect(
	mapStateToProperties,
	mapDispatchToProperties
)(ExtendedLoginLinkComponent);
