import gql from 'graphql-tag';
import { compose, graphql } from 'react-apollo';
import {
	createBonus,
	deleteBonus,
	updateBonus,
} from '../../graphql/custom/bonuses';
import { updateReferralStatus } from '../../graphql/custom/referrals';

export const withQueryBonusByCompanyId = (Component) => {
	return compose(
		graphql(gql(createBonus), {
			props: (props) => ({
				onCreateBonus(input) {
					props.mutate({
						variables: input,
					});
				},
			}),
		}),
		graphql(gql(updateBonus), {
			props: (props) => ({
				onUpdateBonus(input) {
					props.mutate({
						context: {
							headers: {
								'x-frame-options': 'deny', // This header will reach the server
							},
						},
						variables: { input },
					});
				},
			}),
		}),
		graphql(gql(deleteBonus), {
			props: (props) => ({
				onDeleteBonus(input) {
					const variables = {
						...input,
					};
					props.mutate({
						context: {
							headers: {
								'x-frame-options': 'deny', // This header will reach the server
							},
						},
						variables,
					});
				},
			}),
		}),
		graphql(gql(updateReferralStatus), {
			props: (props) => ({
				onUpdateReferral(input) {
					return props
						.mutate({
							context: {
								headers: {
									'x-frame-options': 'deny', // This header will reach the server
								},
							},
							variables: input,
						})
						.then((response) => {
							return response;
						})
						.catch((error) => {
							console.error('Mutation error:', error);
							throw error;
						});
				},
			}),
		})
	)(Component);
};
