import { ManageRewardsServiceProvider } from '../manageRewards/services/manageRewards.service.jsx';
import NominatedRewardsContainer from './container/NominatedRewardsContainer'
import { NominatedRewardsServiceProvider } from './service/NominatedRewards.service.jsx';
import { useSelector } from "react-redux";
import { Spinner } from "../_shared/index.js";

export const NominatedRewards = () => {
	const company = useSelector((state) => state.user.currentUser.company)
	if (!company) return <Spinner />
	return <>
		<ManageRewardsServiceProvider>
			<NominatedRewardsServiceProvider>
				<NominatedRewardsContainer />;
			</NominatedRewardsServiceProvider>
		</ManageRewardsServiceProvider>
	</>
};

export default NominatedRewards;
