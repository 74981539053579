export const hardCodedId = {
    awardedById: "1c95abd1-9b1b-4959-9139-1a0fb02fd295",
    employeeId: "3b595bea-5177-4a4e-abb2-ba27c0ab5554"
}

export const COMMON_API_ENDPOINT = {
    GET_BADGES_LIST: 'api/v1/badges',
    GET_EMPLOYEE_REWARDS: 'api/v1/badge/assignments',
    GET_ADMIN_ISSUED_BADGE: 'api/v1/badge/assignments/admin/issued-badge/',
    GET_NOMINEE_LIST_BY_ID: 'api/v1/nomination/nominee-votes/',
    GET_NOMINEE_VOTER_LIST_BY_ID: 'api/v1/nomination/candidate-votes/',
    GET_NOMINATION_CONFIGURATION_BY_ID: 'api/v1/nomination/configuration/',
    SELECT_WINNER: 'api/v1/nomination/bulk-update',
    EXPORT_NOMINATION_CSV: 'api/v1/nomination/configuration/download'
}
