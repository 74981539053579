import { get } from 'lodash';
import { Component } from 'react';
import { withApollo } from 'react-apollo';
import Spinner from 'src/_shared/components/spinner/SpinnerComponent.jsx';
import DetailsView from './OpenDetailsViewComponent.jsx';
import { spinContainer } from './openToNewRolesDetailsStyles.js';

export class OpenToNewRoleDetailsComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id: props.id,
			visible: false,
			departments: props.departments ? props.departments : [],
			userGroups: props.userGroups ? props.userGroups : [],
		};
	}

	render() {
		const { currentUser, id, user, allMultiLingualData } = this.props;
		const { company } = this.state;
		const careerProfile = get(this.props, 'user.careerProfile', '{}')
			? JSON.parse(get(this.props, 'user.careerProfile', '{}'))
			: [];
		if (!get(currentUser, 'company')) {
			return <Spinner />;
		}

		return (
			<div>
				{user !== undefined && get(user, 'id') === id ? (
					<DetailsView
						jobs={[]}
						employeeData={user}
						history={this.props.history}
						currentUser={currentUser}
						employee={user}
						allMultiLingualData={allMultiLingualData}
						careerProfile={careerProfile}
						selectedUser={user}
					/>
				) : (
					<div className={spinContainer}>
						<Spinner company={company} />
					</div>
				)}
			</div>
		);
	}
}
export default withApollo(OpenToNewRoleDetailsComponent);
