import gql from 'graphql-tag';
import get from 'lodash/get';
import { compose, graphql } from 'react-apollo';
import {
	queryInternalMobilityByCompanyIdIndex,
	updateInternalMobility,
} from '../../graphql/custom/internal-mobility';

export const withQueryInternalMobilityByCompanyId = (Component) => {
	return compose(
		graphql(gql(queryInternalMobilityByCompanyIdIndex), {
			options(props) {
				return {
					variables: {
						companyId: props.currentUser.companyId,
						limit: 1000,
					},
					fetchPolicy: 'cache-and-network',
				};
			},
			props({ data, data: { fetchMore } }, previous) {
				const internalMobility = get(
					data,
					'queryInternalMobilityByCompanyIdIndex.items',
					[]
				);
				const internalMobilityToken = get(
					data,
					'queryInternalMobilityByCompanyIdIndex.nextToken'
				);
				return {
					onLoadMore() {
						fetchMore({
							variables: { after: internalMobilityToken },
							updateQuery(previous_, { fetchMoreResult }) {
								if (!fetchMoreResult) {
									return previous_;
								}

								const mergedItems = [
									...previous_.queryInternalMobilityByCompanyIdIndex.items,
									...fetchMoreResult.queryInternalMobilityByCompanyIdIndex
										.items,
								];
								const set = new Set();
								const uniqueItems = mergedItems.filter((item) => {
									if (!set.has(item.id)) {
										set.add(item.id);
										return true;
									}

									return false;
								});
								return {
									...previous_,
									queryInternalMobilityByCompanyIdIndex: {
										...previous_.queryInternalMobilityByCompanyIdIndex,
										...fetchMoreResult.queryInternalMobilityByCompanyIdIndex,
										items: uniqueItems,
									},
								};
							},
						});
					},
					internalMobility,
					internalMobilityToken,
				};
			},
		}),
		graphql(gql(updateInternalMobility), {
			props: (props) => ({
				onUpdateInternalMobility(input) {
					const optimisticResponseData = {
						...input,
						__typename: 'InternalMobility',
					};
					props.mutate({
						variables: { ...input },
						optimisticResponse: {
							__typename: 'Mutation',
							updateInternalMobility: {
								__typename: 'updateInternalMobility',
								...optimisticResponseData,
							},
						},
						refetchQueries: [
							{
								query: gql(queryInternalMobilityByCompanyIdIndex),
								variables: { companyId: props.ownProps.currentUser.companyId },
							},
						],
					});
				},
			}),
		})
	)(Component);
};
