/**
 * <ToolbarItem />
 */

import { DragSource } from 'react-dnd';
import ItemTypes from './ItemTypes.js';
import ID from './UUID.js';

const cardSource = {
	beginDrag(props) {
		return {
			id: ID.uuid(),
			index: -1,
			data: props.data,
			onCreate: props.onCreate,
		};
	},
};

function ToolbarItem(props) {
	const { connectDragSource, data, onClick } = props;
	if (!connectDragSource) return null;
	return connectDragSource(
		<li onClick={onClick}>
			<i className={data.icon} />
			{data.name}
		</li>
	);
}

export default DragSource(ItemTypes.CARD, cardSource, (connect) => ({
	connectDragSource: connect.dragSource(),
}))(ToolbarItem);
