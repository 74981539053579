import get from 'lodash/get';
import { connect } from 'react-redux';
import { withListOnDeckContacts } from 'src/_shared/api/components/contacts/';
import { dashboardActions } from 'src/actions.js';
import { compose } from '../../services/utils';
import OnDeckComponent from './OnDeckComponent.jsx';

const mapStateToProperties = (state) => {
	const { currentUser } = state.user;
	const { allMultiLingualData } = state.dashboard;
	return {
		currentUser,
		id: get(currentUser, 'id'),
		companyId: get(currentUser, 'company.id'),
		filter: {
			companyId: { eq: get(currentUser, 'company.id') },
		},
		allMultiLingualData,
	};
};

const mapDispatchToProperties = (dispatch) => {
	return {
		setMultiLingualData(data) {
			dispatch(dashboardActions.createSetMultiLingualData(data));
		},
	};
};

export const OnDeckWithApi = compose(withListOnDeckContacts)(OnDeckComponent);

export const OnDeck = connect(
	mapStateToProperties,
	mapDispatchToProperties
)(OnDeckWithApi);
