import { Popconfirm } from 'antd';

export function DepartmentItem(props) {
	const { departments, onUpdateDepartment, handleDepartmentError } = props;

	const inactivateDepartment = (departmentId, departmentName) => {
		if (departmentName === 'Other') {
			handleDepartmentError();
			return;
		}

		onUpdateDepartment({
			input: {
				id: departmentId,
				active: false,
			},
		});
	};

	return (
		<ul className="tag-wrap">
			{departments
				? departments.map((department) => {
						return (
							<li key={department.id} className="tag green">
								<span className="tag-name"> {department.name} </span>
								<Popconfirm
									placement="left"
									title="Are you sure？"
									okText="Delete"
									cancelText="Cancel"
									onConfirm={() =>
										inactivateDepartment(department.id, department.name)
									}
								>
									<i className="tag-close icon-cross" />
								</Popconfirm>
							</li>
						);
					})
				: null}
		</ul>
	);
}
