export const myReferralsKeys = {
	MyReferrals: 'ml_MyReferrals',
	SubmitGeneralReferral: 'ml_SubmitGeneralReferral',
	FilterByStatus: 'ml_FilterByStatus',
	SearchByName: 'ml_SearchByName',
	Status: 'ml_Status',
	Referred: 'ml_Referred',
	Accepted: 'ml_Accepted',
	Hired: 'ml_Hired',
	ReferredOn: 'ml_ReferredOn',
	Total: 'ml_Total',
	Interviewing: 'ml_Interviewing ',
	Earned: 'ml_Earned',
	NotHired: 'ml_NotHired',
	YourEarnedTotal: 'ml_YourEarnedTotal',
	SelfReferred: 'ml_SelfReferred',
	Declined: 'ml_Declined',
	ReferSomeone: 'ml_ReferSomeone',
	DoYouKnow: 'ml_DoYouKnow',
	SubmitThem: 'ml_SubmitThem',
	EnterReferralInformation: 'ml_EnterReferralInformation',
	FirstName: 'ml_FirstName',
	LastName: 'ml_LastName',
	Email: 'ml_Email',
	Or: 'ml_Or',
	ClickHere: 'ml_ClickHere',
	ToAddExistingContact: 'ml_ToAddExistingContact',
	Optional: 'ml_Optional',
	MessageHiringContact: 'ml_MessageHiringContact',
	HowKnowThem: 'ml_HowKnowThem',
	Clickhere: 'ml_Clickhere',
	AttachResume: 'ml_AttachResume',
	SubmitReferral: 'ml_SubmitReferral',

	BonusDetails: 'ml_BonusDetails',
	Job: 'ml_Job',
	ReferredCandidate: 'ml_ReferredCandidate',
	TotalBonus: 'ml_TotalBonus',
	HiredDate: 'ml_HiredDate',
	StartDate: 'ml_StartDate',
	BonusPayments: 'ml_BonusPayments',
	BonusNotes: 'ml_BonusNotes',
	Close: 'ml_Close',
	ViewBonusDetails: 'ml_ViewBonusDetails',
	NoResponse: 'ml_NoResponse',
	InActive: 'ml_InActive',
};
