import { lambda } from './utils.js';

export const CreateContact = async (data) => {
	// Default options are marked with *
	return new Promise((resolve, reject) => {
		lambda({ endpoint: 'erinfree-create-hubspot-contact', variables: data })
			.then((res) => res.json())
			.then((result) => {
				resolve(result);
			});
	});
};

export const UpdateContact = async (data) => {
	// Default options are marked with *
	return new Promise((resolve, reject) => {
		lambda({ endpoint: 'update-hubspot-contact', variables: data })
			.then((res) => res.json())
			.then((result) => {
				resolve(result);
			});
	});
};
