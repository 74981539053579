import { graphql } from 'react-apollo';
import { QueryUsersByCompanyIdPointsIndex } from '../../graphql/custom/dashboard';

export const withUsersByPoints = (Component) => {
	return graphql(QueryUsersByCompanyIdPointsIndex, {
		options: (props) => ({
			variables: { companyId: props.currentUser.companyId },
			fetchPolicy: 'cache-and-network',
		}),
		props(props) {
			return {
				topUsersByPoints: props.data.queryUsersByCompanyIdPointsIndex
					? props.data.queryUsersByCompanyIdPointsIndex.items
					: [],
			};
		},
	})(Component);
};
