export const USER_ROLES = {
	ADMIN: 'admin',
	MANAGER: 'manager',
	EMPLOYEE: 'employee',
	EXTENDED_USER: 'extendedUser',
	SUPER_ADMIN: 'superAdmin',
	SUPER_USER: 'superUser',
	SUPPORT_ADMIN: 'supportAdmin',
};

export const COMPANIES = {
	ReferVets: '79e17481-e7c3-401e-afc0-3d9480509730',
	Allied: '4cc1443d-4f67-463d-8ff5-3f6dc814f5e9',
	HeartlandAffiliationReferrals: '35b88e8f-41a6-4937-8c71-bd491eb1118c',
	HeartlandAffiliateProgram: '69575265-e654-4b0c-a2fd-26acc2b6f057',
	HeartlandDental: '213b3a6f-5357-43ec-906a-05f28438bafb',
	TechAhead: 'd0829b5c-c03d-45fc-9a0e-e58a3f0c75d3',
};

export const EMPLOYEE_DASHBOARD = {
	REFER_SOMEONE: 'Refer Someone',
	INTERNAL_MOBILITY: 'Internal Mobility',
	YOUR_REFERRALS: 'Your Referrals',
	REFERRALS_MADE_RANKING: 'Referrals Made Ranking',
	POINTS: 'Points',
	LEADERBOARDS: 'Leaderboards',
	JOBS_OVERVIEW: 'Jobs Overview',
	ANNOUNCEMENTS: 'Announcements',
	POLICY: 'Policy',
	JOBS_FOR_YOU: 'Jobs For You',
	HOT_JOBS: 'Hot Jobs',
	GENERAL_REFERRAL: 'General Referral',
	REFERRAL_LEAD: 'Referral Lead',
	JOB_ALERTS: 'Job Alerts',
};
