export const updateUserInvite = `mutation UpdateUserInvite($input: UpdateUserInviteInput!) {
  updateUserInvite(input: $input) {
    id
    companyId
    company {
      id
      name
      departments {
        id
        companyId
        name
        active
        totalUsers
        userDepartments {
          id
          userId
          departmentId
        }
      }
      defaultBonusAmount
      contactIncentiveBonus
      websiteUrl
      users {
        id
        cognitoId
        companyId
        emailAddress
        firstName
        lastName
        title
        departmentId
        lastLogin
        lastNotificationCheck
        contacts {
          id
          firstName
          lastName
          emailAddress
          socialMediaAccounts
          userId
          phoneNumber
          jobHistory
          fullContactData
          dateCreated
        }
        totalReferrals
        webNotifications {
          id
          userId
          requestingUserId
          contactId
          referralId
          jobId
          matches
          message
          dateCreated
        }
        connectedApps
        active
        createdById
        dateCreated
      }
      dateCreated
    }
    userId
    user {
      id
      cognitoId
      companyId
      emailAddress
      firstName
      lastName
      title
      departmentId
      lastLogin
      lastNotificationCheck
      contacts {
        id
        firstName
        lastName
        emailAddress
        socialMediaAccounts
        userId
        phoneNumber
        jobHistory
        fullContactData
        dateCreated
      }
      totalReferrals
      webNotifications {
        id
        userId
        requestingUserId
        contactId
        referralId
        jobId
        matches
        message
        dateCreated
      }
      connectedApps
      active
      createdById
      dateCreated
      languageCode
    }
    createdById
    createdBy {
      id
      cognitoId
      companyId
      emailAddress
      firstName
      lastName
      title
      departmentId
      lastLogin
      lastNotificationCheck
      contacts {
        id
        firstName
        lastName
        emailAddress
        socialMediaAccounts
        userId
        phoneNumber
        jobHistory
        fullContactData
        dateCreated
      }
      totalReferrals
      webNotifications {
        id
        userId
        requestingUserId
        contactId
        referralId
        jobId
        matches
        message
        dateCreated
      }
      connectedApps
      active
      createdById
      dateCreated
    }
    emailAddress
    firstName
    lastName
    title
    dateCreated
  }
}
`;
