export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const ADD_SUPER_USER = 'ADD_SUPER_USER';
export const ADD_COMPANY = 'ADD_COMPANY';
export const SIGN_OUT = 'SIGN_OUT';
export const UPDATE_USER_COMPANY = 'UPDATE_USER_COMPANY';
export const UPDATE_USER_LAST_NOTIFICATION_CHECK =
	'UPDATE_USER_LAST_NOTIFICATION_CHECK';
export const SET_CURRENT_CLIENT = 'SET_CURRENT_CLIENT';
export const UPDATE_CURRENT_USER = 'UPDATE_CURRENT_USER';
export const SET_CURRENT_LANGUAGE = 'SET_CURRENT_LANGUAGE';
export const UPDATE_CURRENT_USER_GROUP = 'UPDATE_CURRENT_USER_GROUP';
export const UPDATE_LOGIN_ATTEMPTS = 'UPDATE_LOGIN_ATTEMPTS';
export const UPDATE_USER_SEARCH = 'UPDATE_USER_SEARCH';
export const UPDATE_DISPLAY_AS = 'UPDATE_DISPLAY_AS';

export const createSetCurrentUserAction = (currentUser) => ({
	type: SET_CURRENT_USER,
	payload: {
		currentUser,
	},
});

export const addSuperUserAction = (superUser) => ({
	type: ADD_SUPER_USER,
	payload: {
		superUser,
	},
});

export const addCompanyAction = (company) => ({
	type: ADD_COMPANY,
	payload: {
		company,
	},
});

export const updateUserCompany = (company) => ({
	type: UPDATE_USER_COMPANY,
	payload: {
		company,
	},
});

export const updateUserLastNotificationCheck = (lastNotificationCheck) => ({
	type: UPDATE_USER_LAST_NOTIFICATION_CHECK,
	payload: {
		lastNotificationCheck,
	},
});

export const signOut = (token) => {
	return {
		type: SIGN_OUT,
		payload: {
			token,
		},
	};
};

export const createSetClientAction = (client) => ({
	type: SET_CURRENT_CLIENT,
	payload: {
		client,
	},
});

export const updateCurrentUser = (user) => ({
	type: UPDATE_CURRENT_USER,
	payload: {
		user,
	},
});

export const updateCurrentUserLanguage = (user) => ({
	type: SET_CURRENT_LANGUAGE,
	payload: {
		user,
	},
});

export const updateCurrentUserGroup = (userGroup) => ({
	type: UPDATE_CURRENT_USER_GROUP,
	payload: {
		userGroup,
	},
});

export const updateFailedLoginAttempts = (user) => ({
	type: UPDATE_LOGIN_ATTEMPTS,
	payload: {
		user,
	},
});

export const updateUserSearch = (searchCriteria) => ({
	type: UPDATE_USER_SEARCH,
	searchCriteria,
});

export const updateDisplayAs = (role) => {
	return {
		type: UPDATE_DISPLAY_AS,
		payload: {
			role,
		},
	};
};
