import { css } from 'emotion';
import get from 'lodash/get';
import { COLORS } from '../_shared/styles/colors';

const breakpoints = [576, 767, 991, 1200];

const mq = breakpoints.map((bp) => `@media (max-width: ${bp}px)`);

export const messageCenterContainer = css({
	width: '100%',
	height: '100%',
	padding: 20,
	backgroundColor: COLORS.lightGray2,
	[mq[2]]: {
		padding: '10px 5px',
	},
});
export const tableHeaderContainer = css({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	maxWidth: '100%',
	position: 'relative',
});

export const tableHeader = css({
	fontFamily: '"Open Sans", sans-serif !important',
	fontWeight: '600 !important',
});

export const tableContainer = css({
	borderRadius: 10,
	backgroundColor: 'white',
	padding: 20,
	height: 'auto',
	'& button:focus': {
		outline: 'none',
	},
	[mq[2]]: {
		padding: 8,
	},
});

export const FormStyles = css({
	marginTop: 15,
});

export const InputStyles = css({
	fontFamily: '"Open Sans", sans-serif',
	fontSize: 16,
	marginBottom: 15,
	height: 120,
	'& .ant-input': {
		height: 120,
		fontFamily: '"Open Sans", sans-serif',
		fontSize: 16,
	},
	'& .ant-form-explain': {
		fontSize: 12,
	},
});

export const InputStyles2 = css({
	fontFamily: '"Open Sans", sans-serif',
	margineTop: 10,
	marginBottom: 10,
	fontSize: 16,
	'& .ant-input': {
		height: 120,
		fontFamily: '"Open Sans", sans-serif',
		fontSize: 16,
	},
	'& .ant-form-explain': {
		fontSize: 12,
	},
	'& .ant-form-item-control': {
		lineHeight: '15px',
	},
});

export const LabelStyles = css({
	fontFamily: '"Open Sans", sans-serif',
	fontSize: '18px !important',
	fontWeight: 600,
});

export const ReferralLabelStyles = css({
	verticalAlign: 'middle',
	fontFamily: 'Open Sans,sans-serif',
	fontWeight: 600,
	color: COLORS.black,
});

export const checkBox = css({
	marginRight: 8,
});

export const BelowTextStyles = css({
	fontFamily: '"Open Sans", sans-serif',
	fontSize: '14px !important',
	fontWeight: 400,
	color: COLORS.lightGray5,
	marginBottom: 15,
});

export const btnRight = css({
	float: 'right',
	[mq[1]]: {
		float: 'none',
		marginTop: 10,
	},
});

export const BtnContainer = (theme) => {
	let buttonColor = COLORS.blue;
	if (get(theme, 'enabled')) {
		buttonColor = get(theme, 'buttonColor', buttonColor);
	}

	return css({
		overflow: 'hidden',
		'& .ant-btn: hover': {
			color: buttonColor,
			borderColor: buttonColor,
		},
		'& button:hover > div > svg > path': {
			fill: buttonColor,
		},
		'& button:focus > div > svg > path': {
			fill: buttonColor,
		},
		width: '100%',
		'& .ant-btn:hover': {
			backgroundColor: `${buttonColor} !important`,
			border: `2px solid ${buttonColor} !important`,
			color: '#fff !important',
		},
		'& .ant-btn:focus': {
			backgroundColor: `${buttonColor} !important`,
			border: `2px solid ${buttonColor} !important`,
			color: '#fff !important',
		},
	});
};

export const SaveButton = css({
	width: 150,
	height: 40,
	background: '#018dd3',
	color: 'white',
	fontSize: 16,
	fontWeight: 600,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	borderRadius: '10px',
	border: '2px solid #018dd3',
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 200,
	lineHeight: '25px',
	[mq[1]]: {
		width: '100%',
	},
	marginBottom: 10,
});

export const successStyles = css({
	width: 270,
	position: 'fixed',
	left: 580,
});
