import gql from 'graphql-tag';
import get from 'lodash/get';
import { compose, graphql } from 'react-apollo';
import { queryReferralsByUserIdIndex } from '../../graphql/custom/referrals';

export const withQueryReferralsByUserId = (Component) => {
	return compose(
		graphql(gql(queryReferralsByUserIdIndex), {
			options(props) {
				return {
					context: {
						headers: {
							'x-frame-options': 'deny', // This header will reach the server
						},
					},
					variables: {
						userId: props.id,
					},
					fetchPolicy: 'cache-and-network',
				};
			},

			props({ data, data: { fetchMore } }, previous) {
				const referralsNetworkStatus = get(data, 'networkStatus');
				const loadingBonuses = get(data, 'networkStatus') !== 7;
				const referrals = get(data, 'queryReferralsByUserIdIndex.items', []);
				const referralsNextToken = get(
					data,
					'queryReferralsByUserIdIndex.nextToken'
				);
				return {
					onLoadMoreReferrals() {
						fetchMore({
							variables: { after: referralsNextToken },
							updateQuery(previous_, { fetchMoreResult }) {
								if (!fetchMoreResult) {
									return previous_;
								}

								const mergedItems = [
									...previous_.queryReferralsByUserIdIndex.items,
									...fetchMoreResult.queryReferralsByUserIdIndex.items,
								];
								const set = new Set();
								const uniqueItems = mergedItems.filter((item) => {
									if (!set.has(item.id)) {
										set.add(item.id);
										return true;
									}

									return false;
								});
								return {
									...previous_,
									queryReferralsByUserIdIndex: {
										...previous_.queryReferralsByUserIdIndex,
										...fetchMoreResult.queryReferralsByUserIdIndex,
										items: uniqueItems,
									},
								};
							},
						});
					},
					referrals,
					loadingBonuses,
					referralsNextToken,
					referralsNetworkStatus,
				};
			},
		})
	)(Component);
};
