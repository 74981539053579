import { Component } from 'react';
import { USER_ROLES } from '../../../_shared/constants';

class SendReferralCard extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	handleBrowseJobs = (id) => {
		if (id) {
			this.props.history.push(`/browsejobs/${id}`);
		} else {
			this.props.history.push('/browsejobs');
		}
	};

	render() {
		const {
			currentUser,
			background,
			referralCardButton,
			referralCardSubheader,
			referralCardHeader,
			textColor,
		} = this.props;

		return (
			<>
				{currentUser.displayAs === USER_ROLES.EMPLOYEE && (
					<div className="e-card-wrap" style={{ backgroundColor: background }}>
						<div className="e-card-title" style={{ color: textColor }}>
							{referralCardHeader}
						</div>
						<div className="e-card-subtitle" style={{ color: textColor }}>{referralCardSubheader}</div>
						<button
							type="button"
							className="c-btn-white"
							onClick={() => {
								this.handleBrowseJobs();
							}}
						>
							{referralCardButton}
						</button>
					</div>
				)}
			</>
		);
	}
}
export const SendReferral = SendReferralCard;
