export const queryJobsCountByCompanyId = `query listOpenJobs($companyId: ID!) {
    listOpenJobs(companyId: $companyId) {
      openPositions,
      bonusReferral,
      bonusJobs,
      employees,
      dashboardLastUpdated,
      bonuses
      referralsThisYear
    }
  }
  `;
