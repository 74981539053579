import gql from 'graphql-tag';

export const ListReferralsDashboard = gql`
	query ListReferrals(
		$filter: TableReferralFilterInput
		$limit: Int
		$nextToken: String
	) {
		listReferrals(filter: $filter, limit: $limit, nextToken: $nextToken) {
			items {
				id
				companyId
				contactId
				contact {
					id
					emailAddress
					lastName
					firstName
					socialMediaAccounts
					phoneNumber
				}
				userId
				user {
					id
					firstName
					lastName
					incentiveEligible
					userGroupId
					userGroup {
						id
						measurement
						name
						currency
					}
				}
				jobId
				job {
					id
					title
					departmentId
					department {
						id
						name
					}
					referralBonus
				}
				note
				message
				hireDate
				referralDate
				referralType
				status
				bonusStatus
				contactResume {
					bucket
					region
					key
				}
			}
			nextToken
		}
	}
`;
