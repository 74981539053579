import axios from 'axios';
const fetchGifsByIds = async (gifIds) => {
  //const ids = gifIds.join(','); // Convert array of IDs to a comma-separated string
  const url = `https://tenor.googleapis.com/v2/posts?ids=${gifIds}&key=${process.env.REACT_APP_GIF_TENOR_KEY}`;
  
  try {
    const response = await axios.get(url);
    return response.data.results; // Returns an array of GIF objects
  } catch (error) {
    console.error('Error fetching GIFs:', error);
    return [];
  }
};

export default fetchGifsByIds;
