import { applyMiddleware, compose, createStore } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import localforage from 'localforage';
import thunk from 'redux-thunk';
import reducer from './reducer.js';

const persistConfig = {
	key: 'root',
	storage: localforage,
	stateReconciler: autoMergeLevel2,
	timeout: 30_000,
};

const pReducer = persistReducer(persistConfig, reducer);

export const store = createStore(
	pReducer,
	undefined,
	compose(applyMiddleware(thunk))
);

export const persistor = persistStore(store);
