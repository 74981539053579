import gql from 'graphql-tag';

export const GetAccountClaimByEmployeeIdDOB = gql`
	query GetAccountClaimByEmployeeIdDOB(
		$employeeId: String!
		$dateOfBirth: String
	) {
		getAccountClaimByEmployeeIdDOB(
			employeeId: $employeeId
			dateOfBirth: $dateOfBirth
		) {
			id
			active
			claimed
			eligible
			companyId
			company {
				id
				theme
				labelDepartment
				symbol {
					bucket
					region
					key
				}
				background {
					bucket
					region
					key
				}
				errorImage {
					bucket
					region
					key
				}
				logo {
					bucket
					region
					key
				}
				userGroups {
					id
					name
					languageCode
				}
				whiteLabel
				brandColor
				labelEmployeeID
			}
			dateOfBirth
			employeeId
			firstName
			subCompanyId
			lastName
			title
			userId
			department
			group
		}
	}
`;
