import gql from 'graphql-tag';

export const GetSAMLAuth = gql`
	query GetSAMLAuth($id: String!) {
		getSAMLAuth(id: $id) {
			id
			active
			autoCreateUsers
			clientId
			companyId
			departmentHeader
			loginButtonText
			options
			path
		}
	}
`;
