import { Button, Modal, Select, message } from 'antd';
import get from 'lodash/get';
import { Component } from 'react';
import { Spinner, WebIcon } from 'src/_shared/index.js';
import { isEmail, ml, sanitize } from 'src/_shared/services/utils.js';
import { USER_ROLES } from '../../_shared/constants';
import { COLORS } from '../../_shared/styles/colors';

export default class EmployeesModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			value: null,
			filename: null,
			role: USER_ROLES.EMPLOYEE,
			submitting: false,
		};
	}

	onFileInputChange = (e) => {
		if (e.target.files && e.target.files.length > 0) {
			this.setState({ filename: e.target.files[0].name });
		}
	};

	onRoleSelectChange = (value) => {
		this.setState({ role: value });
	};

	onTextAreaBlur = (e) => {
		this.setState({ value: e.target.value });
	};

	showHideModal = () => {
		this.props.handleCancel();
		this.props.showImportModal();
	};

	submit = (e) => {
		e.preventDefault();

		if (this.state.submitting) return;
		try {
			let users = [];
			let submitError = false;
			const hasFiles = false;
			const data = new FormData();
			if (this.state && this.state.value) {
				const prefilterLines = this.state.value.split(/\n+/);
				const lines = prefilterLines.filter((line) => line !== '');
				if (lines.length === 0) {
					message.error(
						'There was an issue submitting employee add, Please check your input.'
					);
					throw new Error(`Invalid input`);
					return;
				}

				users = lines.map((l) => {
					const tokens = l.split(/,/);
					if (tokens.length === 0) {
						message.error(
							'There was an issue submitting employee add, Please check your input.'
						);
						throw new Error(`Invalid input`);
						submitError = true;
					}

					let emailAddress;
					let firstName;
					let lastName;
					let title;
					let deptName;
					let userGroupName;
					let departmentId = null;
					let userGroupId = null;
					let usrGrp = '';
					if (tokens.length > 0) {
						emailAddress = tokens[0].trim();
						emailAddress &&= sanitize(emailAddress);
						usrGrp = this.props.userGroups.filter(
							(element) => element.name === 'Default'
						);

						if (usrGrp.length > 0) {
							userGroupId = usrGrp[0].id;
						}

						if (!isEmail(emailAddress)) {
							message.error(`${emailAddress} is not an email`);
							throw new Error(`${emailAddress} is not an email`);
							submitError = true;
						}
					}

					if (tokens.length > 1) {
						firstName = tokens[1].trim();
						firstName &&= sanitize(firstName);
					}

					if (tokens.length > 2) {
						lastName = tokens[2].trim();
						lastName &&= sanitize(lastName);
					}

					if (tokens.length > 3) {
						title = tokens[3].trim();
						title &&= sanitize(title);
					}

					if (tokens.length > 4) {
						deptName = tokens[4].trim();
						const dept = this.props.departments.filter(
							(element) => element.name === deptName
						);
						if (dept.length > 0) {
							departmentId = dept[0].id;
						}
					}

					if (tokens.length > 5) {
						userGroupName = tokens[5].trim();
						usrGrp = '';
						userGroupId = null;
						usrGrp = this.props.userGroups.filter(
							(element) => element.name === userGroupName
						);
						if (usrGrp.length > 0) {
							userGroupId = usrGrp[0].id;
						} else {
							usrGrp = this.props.userGroups.filter(
								(element) => element.name === 'Default'
							);
							if (usrGrp.length > 0) {
								userGroupId = usrGrp[0].id;
							}
						}
					}

					const active = false;
					if (this.props.inviteEmailExists(emailAddress)) {
						message.error(`${emailAddress} has already been invited.`);
						submitError = true;
					}

					return {
						emailAddress,
						firstName,
						lastName,
						title,
						departmentId,
						userGroupId,
						active,
					};
				});

				if (submitError === true) {
					return;
				}

				this.setState({ submitting: true });

				this.props
					.handleSubmit(
						users,
						hasFiles ? data : null,
						this.state.role,
						this.props.currentUser.id,
						this.props.currentUser.companyId
					)
					.then(() => {
						const message_ = 'Employee invites submitted';
						message.success(message_);
						this.props.handleCancel();
						this.setState({ submitting: false });
						this.setState({ value: '' });
					})
					.catch((error) => {
						console.error(error);
						message.error('There was an issue submitting employee invites');
						this.props.handleCancel();
						this.setState({ submitting: false });
					});
			} else {
				message.error(
					'There was an issue submitting employee add, Please check your input.'
				);
			}
		} catch (error) {
			this.props.handleError(error);
			this.props.handleCancel();
			this.setState({ submitting: false });
		}
	};

	renderRoleOptions() {
		return (
			<Select
				defaultValue={USER_ROLES.EMPLOYEE}
				className="custom-input"
				onChange={this.onRoleSelectChange}
			>
				<Select.Option key={USER_ROLES.EMPLOYEE} value={USER_ROLES.EMPLOYEE}>
					Employee
				</Select.Option>
				<Select.Option key={USER_ROLES.ADMIN} value={USER_ROLES.ADMIN}>
					Administrator
				</Select.Option>
			</Select>
		);
	}

	render() {
		const {
			currentUser,
			allMultiLingualData,
			company,
			visible,
			handleCancel,
			handleError,
		} = this.props;

		const companyId = get(this.props.currentUser, 'companyId');
		return (
			<Modal
				centered
				destroyOnClose
				open={visible}
				footer={null}
				handleError={handleError}
				width={680}
				title={
					ml('Invite People', currentUser, allMultiLingualData) +
					' ' +
					this.props.currentUser.company.name
				}
				onCancel={handleCancel}
			>
				{this.state.submitting ? (
					<Spinner company={company} />
				) : (
					<>
						<p>
							{ml(
								'Enter one email address per line to send an invite to your employees',
								currentUser,
								allMultiLingualData
							)}
						</p>

						<form onSubmit={this.submit}>
							<div className="custom-form-group">
								<label>
									{ml(
										'Select a role to be applied to all invites',
										currentUser,
										allMultiLingualData
									)}
								</label>
								{this.renderRoleOptions()}
							</div>
							<div className="custom-form-group">
								<textarea
									className="custom-input"
									type="text"
									onBlur={this.onTextAreaBlur}
								/>
							</div>
							<p className="font-italic">
								{ml(
									'You can also add additional details in this format',
									currentUser,
									allMultiLingualData
								)}
								:{' '}
								<strong>
									{ml(
										'Email, First Name, Last Name, Job Title, Department, Group',
										currentUser,
										allMultiLingualData
									)}
								</strong>
							</p>

							{this.state.filename && (
								<center>
									<p>{this.state.filename}</p>
								</center>
							)}
							{this.props.error && this.props.error.message && (
								<center>
									<p style={{ color: 'red' }}>{this.props.error.message}</p>
								</center>
							)}
							<div className="modal-footer-btn space-between">
								{companyId !== '4cc1443d-4f67-463d-8ff5-3f6dc814f5e9' && (
									<p style={{ margin: 0 }}>
										or{' '}
										<a href="javascript:void(0)" onClick={this.showHideModal}>
											Upload Claim Your Account File
										</a>
									</p>
								)}
								<Button
									className="btn-min-width"
									size="large"
									style={{ cursor: 'pointer', textAlign: 'center' }}
									hidden={this.state.submitting}
									type="primary"
									onClick={this.submit}
								>
									<>
										<span>
											{ml('Invite', currentUser, allMultiLingualData)}
										</span>
										<WebIcon
											name="email-filled"
											size={20}
											color={COLORS.white}
										/>
									</>
								</Button>
							</div>
						</form>
					</>
				)}
			</Modal>
		);
	}
}
