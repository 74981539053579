import { connect } from 'react-redux';
import { withListCompanies } from 'src/_shared/api/components/company/';
import { withCreateDepartment } from 'src/_shared/api/components/departments/';
import { withCreateUserGroup } from 'src/_shared/api/components/userGroups/';
import { withGetCompany } from 'src/_shared/api/components/settings/';
import { USER_ROLES } from '../_shared/constants';
import { compose } from '../_shared/services/utils.js';
import { userActions } from 'src/actions.js';
import { SuperUser as SuperUserComponent } from './SuperUserComponent.jsx';

const mapStateToProperties = (state) => {
	const { currentUser } = state.user;
	return {
		currentUser,
		filter: {
			role: { eq: USER_ROLES.SUPER_ADMIN },
		},
		limit: 1000,
	};
};

const mapDispatchToProperties = (dispatch) => {
	return {
		updateCurrentUserCompany(company) {
			dispatch(userActions.updateUserCompany(company));
		},
	};
};

const SuperUserComponentWithApi = compose(
	withListCompanies,
	withCreateDepartment,
	withCreateUserGroup,
	withGetCompany
)(SuperUserComponent);

export const SuperUser = connect(
	mapStateToProperties,
	mapDispatchToProperties
)(SuperUserComponentWithApi);
