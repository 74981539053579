import { css } from 'emotion';
import { COLORS } from '../_shared/styles/colors';

const breakpoints = [576, 767, 991, 1200];

const mq = breakpoints.map((bp) => `@media (max-width: ${bp}px)`);

export const EmployeeEmail = css({
	fontSize: 16,
	fontWeight: '600 !important',
});

export const JobInfoContainer = css({
	marginBottom: 30,
});

export const ReferralDetailsContainer = css({
	width: '100%',
	height: '100%',
	padding: '20px',
	backgroundColor: COLORS.lightGray2,
	marginBottom: 0,
	maxWidth: 1300,
	borderRadius: 10,
	[mq[2]]: {
		padding: '10px 5px',
	},
});

export const FlexContainer = css({
	marginBottom: 30,
	width: '100%',
	paddingTop: 20,
});

export const CardStyles = css({
	borderRadius: 10,
	padding: 20,
	backgroundColor: 'white',
	height: 'auto',
});

export const CardProfile = css({
	width: '100%',
	marginRight: 15,
	height: '100%',
});

export const Card2Styles = css({
	width: '30%',
	marginLeft: 15,
	height: 200,
	minWidth: 300,
});

export const link = css({
	fontFamily: '"Open Sans", sans-serif',
	color: COLORS.blue,
});

export const LinkStyles = css({
	fontFamily: '"Open Sans", sans-serif',
	color: COLORS.blue,
	display: 'inline-flex',
	alignItems: 'center',
	fontWeight: '600 !important',
	cursor: 'pointer',
});

export const spinContainer = css({
	width: '100%',
	height: '50vh',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
});

export const EmployeeName = css({
	marginTop: '-8px',
	marginBottom: '-8px',
	fontSize: 28,
	lineSpacing: 0,
});

export const ReferralSection = css({
	width: '100%',
	marginTop: 10,
	overflow: 'hidden',
	'& .ant-card-bordered-card': {
		borderRadius: '10px !important',
	},
});

export const HeaderStyle = css({
	fontSize: '1.2rem',
	fontWeight: 600,
	fontFamily: 'Open Sans,sans-serif',
	color: `${COLORS.heading} !important`,
});

export const contactDetailWrap = css({
	display: 'flex',
	[mq[0]]: {
		flexDirection: 'column',
	},
});

export const avatarWrap = css({
	marginRight: 20,
	[mq[0]]: {
		marginRight: 4,
		marginBottom: 16,
	},
});

export const addGeneral = css({
	[mq[0]]: {
		flexDirection: 'column',
	},
});

export const EditLinkStyles = css({
	fontFamily: '"Open Sans", sans-serif',
	color: COLORS.blue,
	height: 30,
	border: 'none',
	boxShadow: 'none',
	alignItems: 'center',
});

export const addNoteBlock = css({
	display: 'flex',
	justifyContent: 'flex-end',
});

export const CheckBoxStyles = css({
	margin: '0 auto',
	' & span': {
		fontSize: '16px !important',
		fontWeight: 600,
	},
	'& .ant-checkbox-checked .ant-checkbox-inner': {
		borderColor: COLORS.green,
		backgroundColor: COLORS.green,
	},
});

export const Heading = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 600,
	color: '#444444',
	padding: 0,
	marginBottom: 0,
});

export const SubHeading = css({
	fontFamily: '"Open Sans", sans-serif',
	fontSize: 16,
	fontWeight: 800,
	color: '#8d99a3',

	[mq[0]]: {
		display: 'block',
	},
});

export const Avatar = css({
	height: 70,
	width: 70,
	marginRight: 20,
});

export const NoPicture = css({
	height: 65,
	width: 65,
	backgroundColor: COLORS.lightGray,
	'& h3': {
		color: 'white',
		paddingTop: 7,
		fontSize: 28,
		textAlign: 'center',
		lineHeight: '50px',
	},
});

export const JobInfo = css({
	fontSize: 14,
	margin: 0,
	padding: 0,
});

export const NoSmartReferrals = css({
	marginTop: 40,
	textAlign: 'center',
	fontSize: 14,
	fontWeight: 400,
});

export const LinkStyle = css({
	color: COLORS.blue,
	cursor: 'pointer',
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: '800 !important',
	fontSize: '1em',
	marginLeft: '5px',
});
