export const getUserDataById = `query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      cognitoId
      companyId
      active
      emailAddress
      accessToken
      expires
      expirationDoneByToken
    }
  }
  `;
