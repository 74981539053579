import { Button, Input, Radio, Select, message } from 'antd';
import QRCode from 'qrcode';
import { useEffect, useState } from 'react';
import { get } from 'lodash';
import { USER_ROLES } from 'src/_shared/constants/';
import { isEmail } from 'src/_shared/services/utils.js';
import { ONBOARDING_SCREENS } from '../constants';
import {
	buttonContainerStyle,
	buttonStyle,
	containerStyle,
	noteStyle,
	radioStyle,
	subtitleStyle,
	textBodyStyle,
	titleContainerStyle,
	titleStyle,
} from './onboardingStyles.js';

const { Search } = Input;
function InviteYourEmployees(props) {
	const {
		company,
		currentUser,
		invites = [],
		nextPage,
		prevPage,
		completeOnboardingPage,
		uncompleteOnboardingPage,
		navToScreen,
	} = props;
	const partnerHost = get(company, 'partnerHost');
	const [invitationTypeSelected, setInvitationTypeSelected] = useState(false);
	const [role, setRole] = useState(USER_ROLES.EMPLOYEE);
	const [text, setText] = useState('');
	const [submitting, setSubmitting] = useState(false);
	const [generatedQRCode, setgeneratedQRCode] = useState(null);
	const [newEmployeeUrl] = useState(
		`${window.location.origin}/signup/${get(company, 'partnerClientId')}`
	);
	const [view, setView] = useState();

	useEffect(() => {
		try {
			QRCode.toDataURL(newEmployeeUrl).then((result) =>
				setgeneratedQRCode(result)
			);
		} catch (error) {
			console.error(error);
		}
	}, [newEmployeeUrl]);

	const next = (e) => {
		if (text) {
			submit(e);
		} else {
			completeOnboardingPage(ONBOARDING_SCREENS.INVITE_EMPLOYEES, {
				status: 'skipped',
			});
			navToScreen(ONBOARDING_SCREENS.LEARN_MORE_ADMIN);
		}
	};

	const back = (e) => {
		uncompleteOnboardingPage(ONBOARDING_SCREENS.INVITE_EMPLOYEES);
		navToScreen(ONBOARDING_SCREENS.BONUS_REPORTING);
	};

	const renderRoleOptions = () => {
		return (
			<Select
				defaultValue={USER_ROLES.EMPLOYEE}
				style={{ width: 150 }}
				onChange={setRole}
			>
				<Select.Option key={USER_ROLES.EMPLOYEE} value={USER_ROLES.EMPLOYEE}>
					Employee
				</Select.Option>
				<Select.Option key={USER_ROLES.ADMIN} value={USER_ROLES.ADMIN}>
					Administrator
				</Select.Option>
			</Select>
		);
	};

	const inviteEmailExists = (email) => {
		const lowercaseEmail = email.toLowerCase();
		const inviteEmails = invites.map((invite) =>
			invite.emailAddress.toLowerCase()
		);
		if (inviteEmails.includes(lowercaseEmail)) {
			return true;
		}

		return false;
	};

	const submit = async (e) => {
		e.preventDefault();

		if (submitting) return;
		try {
			let users = [];
			let submitError = false;
			const hasFiles = false;
			const data = new FormData();
			const userGroups = get(currentUser, 'company.userGroups', []);
			const departments = get(currentUser, 'company.departments', []);
			if (text) {
				const prefilterLines = text.split(/\n+/);
				const lines = prefilterLines.filter((line) => line !== '');
				if (lines.length === 0) {
					message.error(
						'There was an issue submitting employee add, Please check your input.'
					);
					throw new Error(`Invalid input`);
					return;
				}

				users = lines.map((l) => {
					const tokens = l.split(/,/);
					if (tokens.length === 0) {
						message.error(
							'There was an issue submitting employee add, Please check your input.'
						);
						throw new Error(`Invalid input`);
						submitError = true;
					}

					let emailAddress;
					let firstName;
					let lastName;
					let title;
					let deptName;
					let userGroupName;
					let departmentId = null;
					let userGroupId = null;
					let usrGrp = '';
					if (tokens.length > 0) {
						emailAddress = tokens[0].trim();
						usrGrp = userGroups.filter((element) => element.name === 'Default');

						if (usrGrp.length > 0) {
							userGroupId = usrGrp[0].id;
						}

						if (!isEmail(emailAddress)) {
							message.error(`${emailAddress} is not an email`);
							throw new Error(`${emailAddress} is not an email`);
							submitError = true;
						}
					}

					if (tokens.length > 1) {
						firstName = tokens[1].trim();
					}

					if (tokens.length > 2) {
						lastName = tokens[2].trim();
					}

					if (tokens.length > 3) {
						title = tokens[3].trim();
					}

					if (tokens.length > 4) {
						deptName = tokens[4].trim();
						const dept = departments.filter(
							(element) => element.name === deptName
						);
						if (dept.length > 0) {
							departmentId = dept[0].id;
						}
					}

					if (tokens.length > 5) {
						userGroupName = tokens[5].trim();
						usrGrp = '';
						userGroupId = null;
						usrGrp = userGroups.filter(
							(element) => element.name === userGroupName
						);
						if (usrGrp.length > 0) {
							userGroupId = usrGrp[0].id;
						} else {
							usrGrp = userGroups.filter(
								(element) => element.name === 'Default'
							);
							if (usrGrp.length > 0) {
								userGroupId = usrGrp[0].id;
							}
						}
					}

					const active = false;

					if (inviteEmailExists(emailAddress)) {
						message.error(`${emailAddress} has already been invited.`);
						submitError = true;
					}

					return {
						emailAddress,
						firstName,
						lastName,
						title,
						departmentId,
						userGroupId,
						active,
					};
				});
				if (submitError === true) {
					return;
				}

				setSubmitting(true);
				const callback = (res) => {
					if (res === true) {
						const message_ = 'Employee invites sent!';
						message.success(message_);
						setSubmitting(false);
						completeOnboardingPage('inviteEmployees', {
							status: 'employees-invited',
						});
						nextPage(e);
					} else {
						message.error(
							'There was an issue submitting the employee invites.'
						);
						setSubmitting(false);
					}
				};

				await props.inviteEmployeesSubmit(
					users,
					hasFiles ? data : null,
					role,
					get(currentUser, 'id'),
					get(currentUser, 'companyId'),
					get(currentUser, 'languageCode'),
					callback
				);
			} else {
				message.error(
					'There was an issue submitting employee add, Please check your input.'
				);
			}
		} catch (error) {
			console.log(error);
			setSubmitting(false);
		}
	};

	const buttonLabel = invitationTypeSelected ? 'Next' : 'Skip';

	const onChange = (e) => {
		setInvitationTypeSelected(true);
		setView(e.target.value);
	};

	return (
		<div className={containerStyle}>
			<div className={titleContainerStyle(partnerHost)}>
				<h1 className={titleStyle}>Invite Your Employees</h1>
				<div className={textBodyStyle}>
					<div
						style={{
							textAlign: 'center',
							marginTop: 20,
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
						}}
					>
						<div className={subtitleStyle} style={{ width: '80%' }}>
							Choose from the options below to invite your employees to the
							referral network!
						</div>
						<Radio.Group
							value={view}
							style={{
								display: 'flex',
								flexDirection: 'column',
								marginBottom: 50,
							}}
							onChange={onChange}
						>
							<Radio value="link" className={radioStyle}>
								Copy and share a link with your employees
							</Radio>

							<Radio value="qr" className={radioStyle}>
								Download a QR Code to hang in the break room
							</Radio>

							<Radio value="email" className={radioStyle}>
								Email employees individually{' '}
								<span className={noteStyle}>
									(NOTE: You will need to have email addresses ready to input)
								</span>
							</Radio>
						</Radio.Group>
						{view === 'link' && (
							<div>
								<p style={{ fontSize: 22 }}>
									Copy and share this link with your employees to allow them to
									sign up
								</p>
								<Search
									style={{ marginBottom: '20px', maxWidth: '460px' }}
									enterButton="Copy"
									defaultValue={newEmployeeUrl}
									onSearch={() => {
										navigator.clipboard
											.writeText(newEmployeeUrl)
											.then(() => {
												message.success('Copied to clipboard');
											})
											.catch((error) => {
												message.error('Failed to copy to clipboard');
											});
									}}
								/>
							</div>
						)}
						{view === 'qr' && (
							<div>
								<p style={{ fontSize: 22 }}>
									<a
										download={`employee-signup-link-${get(
											company,
											'partnerClientId'
										)}.jpg`}
										href={generatedQRCode}
										title="Click To Download The Employee Signup Link QR Code"
									>
										Download
									</a>{' '}
									this QR Code to hang up in the break room.
									{generatedQRCode && (
										<div>
											<a
												download={`employee-signup-link-${get(
													company,
													'partnerClientId'
												)}.jpg`}
												href={generatedQRCode}
												title="Click To Download The Employee Signup Link QR Code"
											>
												<img
													style={{
														width: '200px',
														display: 'block',
														margin: 'auto',
													}}
													src={generatedQRCode}
												/>
											</a>
										</div>
									)}
								</p>
							</div>
						)}
						{view === 'email' && (
							<div>
								<div style={{ fontSize: 22, fontWeight: 600 }}>
									Enter one email address per line to send an email invite to
									your employees
								</div>
								<div style={{ marginTop: 15 }}>
									<span
										style={{ fontSize: 18, fontWeight: 600, marginRight: 10 }}
									>
										Select a role to be applied to all invites:
									</span>
									<span>{renderRoleOptions()}</span>
								</div>
								<div>
									<textarea
										type="text"
										style={{
											width: '100%',
											padding: 10,
											minHeight: 200,
											border: 'solid 1px #ddd',
											marginTop: 20,
										}}
										onChange={(e) => setText(e.target.value)}
									/>
									<div style={{ fontSize: 18 }}>
										You can add additional details in this format: Email, First
										Name, Last Name, Job Title, Department, Group
									</div>
								</div>
							</div>
						)}
						{view && <div style={{ marginTop: 50 }} />}
					</div>
					<div className={buttonContainerStyle} style={{ marginTop: 20 }}>
						<Button
							type="primary"
							shape="round"
							size="large"
							className={buttonStyle}
							style={{ marginRight: 30 }}
							onClick={back}
						>
							Back
						</Button>
						<Button
							disabled={false}
							type="primary"
							shape="round"
							size="large"
							className={buttonStyle}
							onClick={next}
						>
							{buttonLabel}
						</Button>
					</div>
					<div style={{ height: 40 }} />
				</div>
			</div>
		</div>
	);
}

export default InviteYourEmployees;
