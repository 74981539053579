import { css } from 'emotion';
import { COLORS } from '../../../_shared/styles/colors';

export const topContainer = css({
	marginTop: 25,
	marginBottom: 20,
});

export const jobInfoContainer = css({
	height: 'auto',
});

export const jobTitleText = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 600,
	color: COLORS.black,
	fontSize: 20,
});

export const jobIcons = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 300,
	color: COLORS.subHeading,
	fontSize: '1.45em',
	opacity: 0.7,
});

export const jobDetailRow = css({
	marginTop: 5,
	'& svg': {
		transform: 'translate(0, 1.5px)',
	},
});

export const jobLocationAndDepartment = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 400,
	color: COLORS.heading,
	fontSize: 16,
	marginRight: 5,
});

export const locationAndDepartment = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 400,
	color: COLORS.heading,
	fontSize: 16,
	marginLeft: 5,
	marginRight: 5,
});

export const bonusAmount = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 400,
	color: COLORS.green,
	fontSize: 18,
});

export const cardContainer = css({
	height: 'auto',
	width: '100%',
	backgroundColor: COLORS.white,
	fontFamily: '"Open Sans", sans-serif',
	borderRadius: 10,
});

export const sectionContainer = css({
	marginBottom: 15,
});
export const descriptionContainer = css({
	marginBottom: 15,
	minHeight: 230,
});

export const summaryTitle = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 400,
	color: COLORS.heading,
	fontSize: '1.25em',
});

export const summaryValue = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 200,
	color: COLORS.darkGray,
	fontSize: '1.25em',
});

export const footerTextStyles = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 400,
	color: COLORS.heading,
	fontSize: '1.25em',
});

export const hiringManagerLinkStyles = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 400,
	color: COLORS.blue,
	fontSize: '1.25em',
	marginLeft: 5,
});

export const footerStyles = css({
	'& svg': {
		transform: 'translate(20px, 6px)',
	},
});

export const jobMatch = css({
	fontSize: 14,
	marginLeft: 20,
	marginTop: 6,
});

export const jobMatchNumber = css({
	color: COLORS.blue,
	fontWeight: 500,
});

export const linkStyles = css({
	fontWeight: '600 !important',
	color: COLORS.hyperLink,
	'&:hover': {
		color: 'inherit',
	},
});

export const reqNumber = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 400,
	color: COLORS.gray3,
	fontSize: 16,
});

export const generalContainer = css({
	justifyContent: 'center',
	alignItems: 'center',
	backgroundColor: COLORS.lightGreen,
	width: 'auto',
	borderRadius: 4,
	display: 'inline-block',
	borderColor: COLORS.lightGreen,
	flex: '0, 0, auto',
	margin: 3,
	paddingBottom: 2,
});

export const generalText = css({
	color: COLORS.green,
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 600,
	fontSize: 14,
	margin: 5,
});
