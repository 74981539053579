import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Collapse, Select } from 'antd';
import get from 'lodash/get';
import { Component } from 'react';
import gql from 'graphql-tag';
import { withApollo } from 'react-apollo';
import { queryUserGroupsByCompanyIdIndex } from 'src/_shared/api/graphql/custom/userGroups/';
import fileIcon from '../../_shared/assets/erin_lightgray.png';
import { downloadFromS3Signed } from '../../_shared/services/utils.js';
import { measurements } from './commonMeasurement.js';

export class GroupMeasurementComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			defaultActiveUserGroupKey: '',
			company: get(props, 'currentUser.company'),
			theme: JSON.parse(get(props, 'currentUser.company.theme', '{}')),
			userGroup: get(props, 'currentUser.userGroup'),
			userGroups: [],
		};
	}

	async componentDidMount() {
		this.getUserGroups();

		let errorImageSource;
		const errorImage = get(this.state.company, 'errorImage.key', false);
		if (errorImage) {
			errorImageSource = await downloadFromS3Signed(errorImage, 'erin-images');
		}

		this.setState({ errorImageSrc: errorImageSource });
	}

	async getUserGroups(
		policy = 'network-only',
		nextToken = null,
		allUserGroups = []
	) {
		const { client, currentUser } = this.props;
		try {
			const { data } = await client.query({
				query: gql(queryUserGroupsByCompanyIdIndex),
				variables: {
					companyId: get(currentUser, 'companyId'),
					after: nextToken,
				},
				fetchPolicy: policy,
			});
			const userGrps = [...data.queryUserGroupsByCompanyIdIndex.items];
			const token = get(
				data,
				'queryUserGroupsByCompanyIdIndex.nextToken',
				null
			);
			allUserGroups = [...allUserGroups, ...userGrps];
			this.setState({
				userGroups: allUserGroups,
			});
			if (token) {
				this.getUserGroups(policy, token, allUserGroups);
			}
		} catch (error) {
			console.log(error);
		}
	}

	handleSelectMeasurment = (value, userGroupId) => {
		const { userGroup } = this.state;

		this.props.onUpdateUserGroup({
			input: {
				id: userGroupId,
				measurement: value,
			},
		});
		const currentUserGroupId = get(this.props, 'currentUser.userGroupId');
		if (currentUserGroupId === userGroupId) {
			const updatedUserGroup = {
				...userGroup,
				id: userGroup.id,
				measurement: value,
			};
			this.props.setCurrentUserGroup(updatedUserGroup);
		}
	};

	renderList = (userGroups) => {
		const { company, defaultActiveUserGroupKey } = this.state;
		const whiteLabel = get(company, 'whiteLabel');
		if (userGroups.length === 0) {
			return (
				<div className="no-content">
					{whiteLabel ? (
						<img
							src={this.state.errorImageSrc}
							alt="error image"
							className="no-content-icon"
						/>
					) : (
						<img alt="erin-logo" className="no-content-icon" src={fileIcon} />
					)}
					<p className="no-content-text">No User Group have been created. </p>
				</div>
			);
		}

		if (userGroups && userGroups.length > 0 && defaultActiveUserGroupKey) {
			return (
				<Collapse
					bordered={false}
					activeKey={defaultActiveUserGroupKey}
					className="bonus-collapse"
					expandIconPosition="end"
				>
					{this.renderUserGroups()}
				</Collapse>
			);
		}

		if (userGroups && userGroups.length > 0 && !defaultActiveUserGroupKey) {
			return (
				<Collapse
					bordered={false}
					className="bonus-collapse"
					expandIconPosition="end"
				>
					{this.renderUserGroups()}
				</Collapse>
			);
		}
	};

	renderUserGroups = () => {
		const { userGroups } = this.state;
		const { Option } = Select;
		const options = [];
		measurements.map((measurement) =>
			options.push(<Option key={measurement.id}>{measurement.name}</Option>)
		);
		return (
			<Collapse
				className="bonus-collapse"
				bordered={false}
				expandIconPosition="end"
			>
				<Collapse.Panel
					key="measurement"
					className="bonus-collapse-card"
					extra={
						<div className="bonus-collapse-icon-wrap">
							<i className="icon-arrow-down bonus-collapse-icon" />
						</div>
					}
					header={<h4>Group Setting</h4>}
					onClick={() =>
						this.setState({ defaultActiveUserGroupKey: 'measurement' })
					}
				>
					{userGroups
						.filter((group) => group.active === true)
						.map((item) => (
							<div key={item.id} className="bs-form">
								<div className="bs-form-grp">
									<div className="custom-form-group">
										<label className="bs-label bs-label-width">
											{item.name}:
										</label>
										<Select
											style={{ width: 180 }}
											className="custom-input"
											defaultValue={get(item, 'measurement')}
											placeholder="Select"
											onSelect={(value) =>
												this.handleSelectMeasurment(value, item.id)
											}
										>
											{options}
										</Select>
									</div>
									<div className="custom-form-group">
										<label className="bs-label bs-label-width">
											Default Language:
										</label>
										<Select
											className="custom-input"
											defaultValue={item.languageCode}
											placeholder="Select"
											dropdownMatchSelectWidth={false}
											onSelect={(value) => {
												return this.props.onUpdateUserGroup({
													input: {
														id: item.id,
														languageCode: value,
													},
												});
											}}
										>
											<Select.Option key="US" value="US">
												English
											</Select.Option>
											<Select.Option key="AR" value="AR">
												اَلْفُصْحَىٰ
											</Select.Option>
											<Select.Option key="CS" value="CS">
												Čeština
											</Select.Option>
											<Select.Option key="DE" value="DE">
												Deutsch
											</Select.Option>
											<Select.Option key="ES" value="ES">
												Español
											</Select.Option>
											<Select.Option key="FR" value="FR">
												Français
											</Select.Option>
											<Select.Option key="FR-CA" value="FR-CA">
												Français (Canada)
											</Select.Option>
											<Select.Option key="IT" value="IT">
												Italiano
											</Select.Option>
											<Select.Option key="HU" value="HU">
												Magyar
											</Select.Option>
											<Select.Option key="NL" value="NL">
												Nederlands
											</Select.Option>
											<Select.Option key="PL" value="PL">
												Polski
											</Select.Option>
											<Select.Option key="PT" value="PT">
												Português
											</Select.Option>
											<Select.Option key="PT-BR" value="PT-BR">
												Português (Brasil)
											</Select.Option>
											<Select.Option key="TL" value="TL">
												Tagalog
											</Select.Option>
											<Select.Option key="TR" value="TR">
												Türkçe
											</Select.Option>
											<Select.Option key="RU" value="RU">
												Русский
											</Select.Option>
											<Select.Option key="ZH-CN" value="ZH-CN">
												简体中文
											</Select.Option>
											<Select.Option key="JA" value="JA">
												日本語
											</Select.Option>
										</Select>
									</div>
								</div>
							</div>
						))}
				</Collapse.Panel>
			</Collapse>
		);
	};

	render() {
		const { userGroups } = this.state;
		return <Form>{this.renderList(userGroups)}</Form>;
	}
}

export const GroupMeasurement = withApollo(
	Form.create()(GroupMeasurementComponent)
);
