export const createAnnouncement = `mutation createAnnouncement($input: CreateAnnouncementInput!) {
    createAnnouncement(input: $input) {
      id
      companyId
      title
    content
    dateCreated
    hide
    userGroups
    displayGroupSelect
    }
  }
  `;
