import { css } from 'emotion';
import { COLORS } from '../../../styles/colors';

const breakpoints = [576, 768, 991, 1200];

const mq = breakpoints.map((bp) => `@media (max-width: ${bp}px)`);

export const avatarImageStyles = css({
	border: '1px solid',
	borderColor: COLORS.lightGray,
	width: 50,
	height: 50,
});

export const avatarNoImageStyles = css({
	background: COLORS.lightGray3,
	width: 50,
	height: 50,
	color: 'white',
	fontSize: 24,
	lineHeight: '50px',
	marginBottom: 0,
	'& .ant-avatar-string': {
		lineHeight: '50px',
	},
});

export const companyNameStyles = css({
	color: COLORS.darkGray,
	fontSize: 18,
	fontWeight: 600,
	minWidth: 100,
	textAlign: 'center',
	[mq[2]]: {
		display: 'none',
	},
});

export const menuColorStyles = css({
	color: COLORS.blue,
	cursor: 'pointer',
});

export const navPositionStyles = css({
	display: 'flex',
	alignItems: 'center',
	marginLeft: 'auto',
	whiteSpace: 'nowrap',
	'& > div': {
		margin: '0 10px',
	},
	[mq[1]]: {
		marginLeft: 0,
	},
});

export const flagIconStyles = css({
	'& .flag-select__btn': {
		padding: 0,
	},
	'& .flag-select__option__icon': {
		top: '0em',
	},
	'& .flag-select__option__label': {
		color: COLORS.gray,
		fontWeight: 200,
		fontSize: 14,
	},
	'& .flag-select__btn:after': {
		borderTop: `5px solid ${COLORS.gray}`,
		borderLeft: '5px solid transparent',
		borderRight: '5px solid transparent',
		borderBottom: 0,
	},
	'& .flag-select__btn[aria-expanded="true"]:after': {
		borderTop: 0,
		borderLeft: '5px solid transparent',
		borderRight: '5px solid transparent',
		borderBottom: `5px solid ${COLORS.gray}`,
	},
});

export const dropDownStyles = css({
	fontFamily: '"Open Sans", sans-serif',
});

export const selectStyles = css({
	fontFamily: '"Open Sans", sans-serif',
	width: 215,
	'& .ant-select-selection__choice': {
		backgroundColor: COLORS.lightGreen,
		color: COLORS.green,
	},
	'& .ant-select-arrow': {
		display: 'none',
	},
});
export const iconContainerStyles = css({
	'& svg': {
		position: 'absolute',
		pointerEvents: 'none',
		top: '10px',
		right: '10px',
	},
});

export const selectContainerStyles = css({
	display: 'flex',
	marginBottom: '1em',
	position: 'relative',
});
