export const myContactsKeys = {
	CancelAdd: 'ml_CancelAdd',
	ConfirmNewPosition: 'ml_ConfirmNewPosition',
	TieredBonus: 'ml_TieredBonus',
	Mobile: 'ml_Mobile',
	ReferralComments: 'ml_ReferralComments',
	Status: 'ml_Status',
	Resume: 'ml_Resume',
	SearchByName: 'ml_SearchByName',
	AddGeneralReferral: 'ml_AddGeneralReferral',
	AddJobHistory: 'ml_AddJobHistory',
	ShowJobHistory: 'ml_ShowJobHistory',
	ReferredOn: 'ml_ReferredOn',
	ReferralReady: 'ml_ReferralReady',
	Description: 'ml_Description',
	Company: 'ml_Company',
	EmailPhoneNumber: 'ml_EmailPhoneNumber',
	DownloadResume: 'ml_DownloadResume',
	ReferredBy: 'ml_ReferredBy',
	EditProfile: 'ml_EditProfile',
	Added: 'ml_Added',
	Name: 'ml_Name',
	Organization: 'ml_Organization',
	Edit: 'ml_Edit',
	Source: 'ml_Source',
	Delete: 'ml_Delete',
	AddContacts: 'ml_AddContacts',
	StartDate: 'ml_StartDate',
	EndDates: 'ml_EndDates',
	MyContacts: 'ml_MyContacts',
	SmartReferral: 'ml_SmartReferral',
	Referrals: 'ml_Referrals',
	Action: 'ml_Action',
	Position: 'ml_Position',
	BonusDetails: 'ml_BonusDetails',
	Job: 'ml_Job',
	ReferredCandidate: 'ml_ReferredCandidate',
	TotalBonus: 'ml_TotalBonus',
	HiredDate: 'ml_HiredDate',
	BonusPayments: 'ml_BonusPayments',
	BonusNotes: 'ml_BonusNotes',
	Close: 'ml_Close',
	ViewBonusDetails: 'ml_ViewBonusDetails',
	Referred: 'ml_Referred',
	Accepted: 'ml_Accepted',
	Hired: 'ml_Hired',
	Total: 'ml_Total',
	Interviewing: 'ml_Interviewing ',
	Earned: 'ml_Earned',
	NotHired: 'ml_NotHired',
	NoResponse: 'ml_NoResponse',
	InActive: 'ml_InActive',
};
