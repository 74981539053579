import get from 'lodash/get';
import { Component } from 'react';
import { Link } from 'react-router-dom';
import WebIcon from 'src/_shared/components/WebIconComponent.jsx';
import sad from '../../../_shared/assets/sad.svg';
import { USER_ROLES } from '../../../_shared/constants';
import { ml } from '../../../_shared/services/utils.js';
import { COLORS } from '../../../_shared/styles/colors';
import {
	emptyTile,
	leaderListClassNoBullet,
	leaderListItemClass,
	linkStyles,
} from './leaderBoardStyles.js';

class BaseLeaderBoard extends Component {
	renderEntities(title, entities) {
		const listItems = entities.map((entity, index) => (
			<li key={index} className={leaderListItemClass}>
				{title === 'Recent Jobs' && (
					<span>
						<Link className={linkStyles} to={`/browsejobs/${entity.id}`}>
							{entity.name}
						</Link>
					</span>
				)}
				{title === 'Recent Referrals' && (
					<span>
						<Link className={linkStyles} to={`/referrals/${entity.id}`}>
							{entity.name}
						</Link>
					</span>
				)}
				{title === 'Your Departments' && (
					<span>
						<Link className={linkStyles} to="#">
							{entity.department.name}
						</Link>
					</span>
				)}
			</li>
		));
		return listItems;
	}

	renderNumberedEntities(entities) {
		const listItems = entities.map((entity, index) => (
			<li key={index}>
				<label>{get(entity, 'name', '').split('', 21)}</label>
				<span>
					{entity.score
						? entity.score.toString().replaceAll(/\B(?=(\d{3})+(?!\d))/g, ',')
						: ''}
				</span>
			</li>
		));
		return listItems;
	}

	render() {
		const {
			onClick,
			title,
			entities,
			isNumbered,
			allMultiLingualData,
			currentUser,
		} = this.props;
		const showEmptyContainer = entities.length === 0;
		return (
			<div className="d-card height-equal" onClick={onClick}>
				<div className="d-card-head">
					<h4 className="d-title">
						{ml(title, currentUser, allMultiLingualData)}
					</h4>
					{currentUser.displayAs === USER_ROLES.ADMIN ? (
						<Link className="link" to="#">
							{ml('VIEW MORE', currentUser, allMultiLingualData)}
						</Link>
					) : null}
				</div>
				{showEmptyContainer ? (
					<div className={emptyTile}>
						{title === 'Top Referrers' && (
							<div>
								<img
									src={sad}
									height={60}
									width={60}
									alt="Sad Face. No employees yet."
								/>
								<div style={{ marginTop: '10px' }}>
									Uh oh. You haven&#39;t added any employees yet. Don&#39;t
									worry, just
									<a style={{ color: COLORS.blue }}> click here </a> to add
									them!
								</div>
							</div>
						)}
						{title === 'Top Departments' && (
							<div>
								<WebIcon name="gas" size={60} color={COLORS.lightGray} />
								<div style={{ marginTop: '10px' }}>
									You don&#39;t have any departments right now, but we&#39;ll
									update this when you do!
								</div>
							</div>
						)}
					</div>
				) : (
					<div>
						{isNumbered ? (
							<ol className="list-data-wrap alternate">
								{this.renderNumberedEntities(entities)}
							</ol>
						) : (
							<ul className={leaderListClassNoBullet}>
								{this.renderEntities(title, entities)}
							</ul>
						)}
					</div>
				)}
			</div>
		);
	}
}

export const LeaderBoard = BaseLeaderBoard;
