import { Component } from 'react';
import { Link } from 'react-router-dom';

export class HeaderMenuItem extends Component {
	constructor(props) {
		super(props);
	}

	ProAuthRender = (props) => {
		const { title, active, link, theme } = props;
		return (
			<li className={`menu-item ${active && 'active'}`}>
				<Link text={title} to={link} onClick={this.handleMenuItemClick}>
					{title}
				</Link>
			</li>
		);
	};

	handleMenuItemClick = () => {
		const { link, onMenuItemClick, currentUser } = this.props;
		onMenuItemClick(link);
	};

	render() {
		return this.ProAuthRender(this.props);
	}
}
