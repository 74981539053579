import { Button } from 'antd';
import parse from 'html-react-parser';
import { Component } from 'react';

export class StringParser extends Component {
	state = {
		isMore: false,
		text: [],
	};

	componentDidMount() {
		this.createTextArr(this.props);
	}

	componentDidUpdate(prevProps) {
		// eslint-disable-next-line
		if (prevProps.text != this.props.text) {
			this.createTextArr(this.props);
		}
	}

	createTextArr = (nextProperty) => {
		let text = [];
		const result = [];
		if (nextProperty.text) {
			text = nextProperty.text.split('\n');
			if (text.length === 1) {
				text = nextProperty.text.split('</p>');
			}
		}

		for (let i = 0; i < text.length; i++) {
			result.push({
				text: text[i],
				newLine: true,
			});

			let tempText = text[i];
			const tempArray = [];
			let cmptext = '';
			tempArray.push(tempText);
			while (
				tempText.length > this.props.charLimit &&
				// eslint-disable-next-line
				(!tempArray.length || tempArray[0] != cmptext)
			) {
				cmptext = tempArray[0];
				tempText = tempArray[0].slice(0, Math.max(0, tempArray[0].length / 2));
				for (let j = 0; j < tempArray.length; j += 2) {
					let splitIndex = tempArray[j].length / 2;
					while (
						// eslint-disable-next-line
						tempArray[j].charAt(splitIndex) != ' ' &&
						splitIndex < tempArray[j].length
					) {
						splitIndex++;
					}

					tempArray.splice(j + 1, 0, tempArray[j].slice(splitIndex));
					tempArray.splice(
						j,
						1,
						tempArray[j].slice(0, Math.max(0, splitIndex))
					);
				}
			}

			text.splice(i, 1, tempArray[0]);
			result.splice(i, 1, {
				text: tempArray[0],
				// eslint-disable-next-line
				newLine: tempArray.length == 1 ? true : false,
			});
			for (let j = 1; j < tempArray.length; j++) {
				text.splice(i + j, 0, tempArray[j]);
				result.splice(i + j, 0, {
					text: tempArray[j],
					// eslint-disable-next-line
					newLine: j == tempArray.length - 1 ? true : false,
				});
				i++;
			}
		}

		this.setState({ text: result });
	};

	render() {
		return (
			<>
				{this.state.text.map(
					function (station, i) {
						if (
							// eslint-disable-next-line
							station.text == '' &&
							(i < this.props.breakOn || this.state.isMore)
						)
							return (
								<div key={i} style={{ height: 10 }} className="">
									{station.text.replaceAll(' ', '\u00A0')}
								</div>
							);
						if (i < this.props.breakOn || this.state.isMore)
							return station.newLine ? (
								<div
									key={i}
									properties={{ target: '_blank' }}
									target="_blank"
									style={{ listStyleType: 'disc' }}
								>
									{parse(station.text)}
								</div>
							) : (
								<span
									key={i}
									properties={{ target: '_blank' }}
									target="_blank"
									style={{ listStyleType: 'disc' }}
								>
									{parse(station.text)}
								</span>
							);
						// eslint-disable-next-line
						else if (i == this.props.breakOn)
							return (
								<Button
									key={i}
									type="link"
									onClick={() => {
										this.setState({ isMore: true });
									}}
								>
									<span>{this.props.showMoreText}</span>
									<i className="icon-arrow-down" style={{ fontSize: 8 }} />
								</Button>
							);
						else return;
					}.bind(this)
				)}
				{this.state.isMore ? (
					<Button
						type="link"
						onClick={() => {
							this.setState({ isMore: false });
						}}
					>
						<span>{this.props.showLessText}</span>{' '}
						<i className="icon-arrow-up" style={{ fontSize: 8 }} />
					</Button>
				) : null}
			</>
		);
	}
}
