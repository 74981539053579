import gql from 'graphql-tag';
import get from 'lodash/get';
import { compose, graphql } from 'react-apollo';
import { createUser } from 'src/_shared/api/graphql/custom/users/';
import { GetCompanyByHost } from '../../graphql/custom/company';
import {
	getExtendedContactById,
	updateContact,
} from '../../graphql/custom/contacts';

export const withNewExtendedUser = (Component, variables) => {
	const id = window.location.pathname.split('/').pop();
	let { host } = window.location;
	host = host.replace('www.', '');
	return compose(
		graphql(getExtendedContactById, {
			options: {
				context: {
					headers: {
						'x-frame-options': 'deny', // This header will reach the server
					},
				},
				variables: { id },
				fetchPolicy: 'network-only',
			},
			props: (props) => ({
				contact: get(props, 'data.getContact'),
			}),
		}),
		graphql(GetCompanyByHost, {
			options: {
				context: {
					headers: {
						'x-frame-options': 'deny', // This header will reach the server
					},
				},
				variables: { host },
				fetchPolicy: 'network-only',
			},
			props: (props) => ({
				company: get(props, 'data.getCompanyByHost'),
			}),
		}),
		graphql(gql(createUser), {
			props: (props) => ({
				onCreate(input) {
					return props.mutate({
						context: {
							headers: {
								'x-frame-options': 'deny', // This header will reach the server
							},
						},
						variables: input,
					});
				},
			}),
		}),
		graphql(gql(updateContact), {
			props: (props) => ({
				onUpdateContact(input) {
					return props.mutate({
						context: {
							headers: {
								'x-frame-options': 'deny', // This header will reach the server
							},
						},
						variables: input,
					});
				},
			}),
		})
	)(Component);
};
