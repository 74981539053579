import get from 'lodash/get';
import { compose, graphql } from 'react-apollo';
import { queryPointsLogByCompanyIdIndex } from '../../graphql/custom/points';

export const queryCompanyPointsLogData = (Component, variables) => {
	return compose(
		graphql(queryPointsLogByCompanyIdIndex, {
			options: (props) => ({
				variables: {
					companyId: props.currentUser.companyId,
				},
				fetchPolicy: 'network-only',
			}),
			props({ data, data: { fetchMore } }, previous) {
				const pointsLogNetworkStatus = get(data, 'networkStatus');
				const loadingPointsLogData = get(data, 'networkStatus') !== 7;
				const pointsLogData = get(
					data,
					'queryPointsLogByCompanyIdIndex.items',
					[]
				);
				const pointsLogNextToken = get(
					data,
					'queryPointsLogByCompanyIdIndex.nextToken'
				);
				return {
					onLoadMorePointsLogData() {
						fetchMore({
							variables: { after: pointsLogNextToken },
							updateQuery(previous_, { fetchMoreResult }) {
								if (!fetchMoreResult) {
									return previous_;
								}

								const mergedItems = [
									...previous_.queryPointsLogByCompanyIdIndex.items,
									...fetchMoreResult.queryPointsLogByCompanyIdIndex.items,
								];
								const set = new Set();
								const uniqueItems = mergedItems.filter((item) => {
									if (!set.has(item.id)) {
										set.add(item.id);
										return true;
									}

									return false;
								});
								return {
									...previous_,
									queryPointsLogByCompanyIdIndex: {
										...previous_.queryPointsLogByCompanyIdIndex,
										...fetchMoreResult.queryPointsLogByCompanyIdIndex,
										items: uniqueItems,
									},
								};
							},
						});
					},
					pointsLogData,
					loadingPointsLogData,
					pointsLogNextToken,
					pointsLogNetworkStatus,
				};
			},
		})
	)(Component);
};
