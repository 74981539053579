// Made to replace our react-select-currency package, last commit was over 7 years ago
// Oct 23, 2017 https://github.com/lsiden/react-select-currency I used the same sources
// for currency/country codes/names to ensure compatibility and used flag emojis instead of a huge icon pack 🤠
import { Select, Typography } from 'antd';
import { useState, useEffect } from 'react';
import { countryCurrenyEmojiCodes } from './countryCurrencyEmojiCodes.js';

const { Text } = Typography;

export function CurrencySelectorComponent({ value, onChange, ...props }) {
	const [selectedValue, setSelectedValue] = useState(undefined);
	const handleChange = (value, option) => {
		onChange(option.code);
		setSelectedValue({ value: option.value, label: option.code });
	};

	useEffect(() => {
		setSelectedValue(value);
	}, [value]);

	const listOptionRender = (option) => {
		const ellipsis = option.name.length > 25;
		return (
			<div
				key={option.countryCode + '-option'}
				id={option.name}
				className="currency-selector-option-wrap"
				style={{
					display: 'grid',
					gridTemplateColumns: 'auto 1fr auto auto',
				}}
			>
				<span
					role="img"
					aria-label="emoji"
					style={{
						fontSize: '24px',
						verticalAlign: 'middle',
						width: 40,
						color: 'var(--dove-gray)',
					}}
				>
					{option.flagEmoji}
				</span>
				<span
					className="currency-selector-option-text"
					style={{ width: 120, fontWeight: 600, fontSize: '14px' }}
				>
					<Text
						style={ellipsis ? { width: 120 } : { width: 120 }}
						ellipsis={ellipsis ? { tooltip: option.name } : false}
					>
						{option.name}
					</Text>
				</span>
				<span
					style={{
						textAlign: 'start',
						fontWeight: 800,
						width: 60,
						fontSize: 14,
						marginLeft: 5,
					}}
				>
					{'(' + option.currencyCode + ')'}
				</span>
				<span
					style={{
						textAlign: 'end',
						width: 25,
						marginRight: 5,
						fontWeight: 900,
					}}
				>
					{option.currencySymbol}
				</span>
			</div>
		);
	};

	const options = countryCurrenyEmojiCodes.map((item) => {
		const currencyOption = {
			value: item.name,
			label: listOptionRender(item),
			code: item.currencyCode,
			symbol: item.currencySymbol,
		};
		return currencyOption;
	});

	const filterOption = (input, option) => {
		return (
			option.code.toLowerCase().includes(input.toLowerCase()) ||
			option.value.toLowerCase().includes(input.toLowerCase()) ||
			option.symbol.toLowerCase().includes(input.toLowerCase())
		);
	};

	return (
		<Select
			showSearch
			className="custom-input"
			style={{
				height: 'auto',
				minHeight: '40px',
				lineHeight: 1,
				padding: '11px 16x',
				borderRadius: '8px',
				width: '100%',
				fontSize: '14px',
				fontFamily: '"Open Sans", sans-serif',
				color: '#7f7f7f',
			}}
			filterOption={filterOption}
			filterSort={(optionA, optionB) =>
				(optionA.value ?? '')
					.toLowerCase()
					.localeCompare(optionB.value.toLowerCase())
			}
			notFoundContent={<div>No matches found</div>}
			value={selectedValue}
			options={options}
			onChange={handleChange}
		/>
	);
}

export default CurrencySelectorComponent;
