import { css } from 'emotion';
import { COLORS } from '../../../_shared/styles/colors';

export const headerClass = css({
	fontSize: 16,
	textTransform: 'uppercase',
	letterSpacing: 2,
	fontWeight: 300,
	color: `${COLORS.heading} !important`,
	marginLeft: 15,
	marginBottom: 10,
});

export const headerClasses = css({
	fontSize: 16,
	textTransform: 'uppercase',
	letterSpacing: 0,
	fontWeight: 300,
	color: `${COLORS.heading} !important`,
	marginLeft: 15,
	marginBottom: 0,
});

export const opacity = css({
	opacity: '1',
});

export const buttonActive = css({
	borderColor: COLORS.dashboardBlue,
	background: COLORS.dashboardBlue,
	width: '10px',
	height: '10px',
	borderRadius: '35%',
	marginLeft: '20px',
});

export const buttonBusiness = css({
	borderColor: COLORS.dashboardGreen,
	background: COLORS.dashboardGreen,
	width: '10px',
	height: '10px',
	borderRadius: '35%',
	marginLeft: '20px',
});

export const buttonFirst = css({
	borderColor: COLORS.dashboardLightOrange,
	background: COLORS.dashboardLightOrange,
	width: '10px',
	height: '10px',
	borderRadius: '35%',
	marginLeft: '20px',
});

export const donutChart = css({
	position: 'absolute',
	top: '-36px',
	left: '20px',
});

export const chartTotal = css({
	position: 'absolute',
	top: '24%',
	left: '23%',
	background: COLORS.white,
	padding: '6px 15px',
	flexWrap: 'wrap',
	width: '75px',
});

export const numTotal = css({
	fontSize: 20,
	fontWeight: 800,
});

export const middleContainer = css({
	height: 80,
	width: 180,
	margin: 'auto',
	marginTop: 40,
	marginBottom: 40,
});

export const middleColumn = css({
	textAlign: 'center',
	width: 85,
});

export const bigNumbers = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 800,
	color: COLORS.black,
	fontSize: '2em',
});

export const bigNumbersOpen = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 800,
	color: COLORS.dashboardDarkOrange,
	fontSize: '2em',
});

export const numberSubtitles = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 600,
	color: COLORS.white,
	fontSize: '0.85em',
});

export const numberSubtitlesOpen = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 600,
	color: COLORS.lightGray,
	fontSize: '0.85em',
	width: 70,
	margin: 'auto',
});

export const matchBox = css({
	textAlign: 'center',
	width: 80,
	backgroundColor: COLORS.dashboardLightOrange,
	borderRadius: '3%',
});

export const listStyles = css({
	fontSize: 12,
	listStyleType: 'none',
	padding: '5px 15px',
});

export const smallIcons = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 600,
	color: COLORS.subHeading,
	fontSize: '0.85em',
	marginLeft: 21,
	opacity: 0.7,
});

export const divider = css({
	height: 1,
	backgroundColor: COLORS.subHeading,
	opacity: 0.4,
	width: '80%',
	margin: 'auto',
});

export const bottomContainer = css({
	height: 60,
	marginTop: 32,
	marginBottom: 32,
});

export const bottomSectionText = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 600,
	color: COLORS.heading,
	fontSize: '0.85em',
	marginLeft: 5,
	opacity: 0.7,
});

export const bottomSectionValue = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 800,
	color: COLORS.black,
	fontSize: '0.85em',
	float: 'right',
	marginRight: 21,
});

export const connectEarn = css({
	background: COLORS.lightGreen,
	color: COLORS.green,
	marginTop: 5,
	padding: '5px 57px',
	borderRadius: 10,
	border: 0,
	width: '100%',
});

export const LinkStyles = css({
	fontWeight: '600 !important',
	color: COLORS.hyperLink,
	border: 'none',
	padding: '0px',
	margin: '0px',
	cursor: 'pointer',
});
