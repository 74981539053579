import { useEffect, Component } from 'react';
import get from 'lodash/get';
import mixpanel from 'mixpanel-browser';
import { Route, Switch, withRouter } from 'react-router-dom';
import NewReferralLandingPageComponent from 'src/new-referral-landing-page/NewReferralLandingPageComponent.jsx';
import { DashboardLatest } from './DashboardNew';
import { Dashboard } from './dashboard';
import { OnDeck } from './_shared/components/on-deck';
import { Redirect } from './_shared/components/redirect';
import { USER_ROLES } from './_shared/constants';
import { RouterContext } from './_shared/contexts';
import { Aside } from './_shared/navigation/aside';
import { Header } from './_shared/navigation/header';
import {
	AuthenticatedRoute,
	ByPassauthenticatedRoute,
	ShareWrapper,
	UnauthenticatedRoute,
} from './_shared/router';
import { Announcements } from './announcements';
import { BonusCampaigns } from './bonus-campaigns';
import { BonusReportContainer } from './bonus-report';
import { BrowseJobs } from './browseJobs';
import { JobDetailsComponent } from './browseJobs/jobDetail';
import { CareerProfile } from './career-profile';
import { ContactDetails } from './contact-details';
import { CustomPage } from './custom-page';
import { EditEmails } from './edit-emails';
import { EditUserProfile } from './edit-user-profile';
import { EmployeeDetails } from './employee-details';
import { EmployeesDashboard } from './employees-dashboard';
import { FormBuilder } from './form-builder';
import { GeneralReferralPage } from './general-referral-page';
import { GiftCardStore } from './gift-card-store';
import { Internal } from './internal';
import { InternalApplicationContainer } from './internal-applicants';
import { Login, Logout, SAMLMobileLogin, SAMLWebLogin } from './login';
import { ManageJobs } from './manageJobs';
import { JobDetail } from './manageJobs/jobDetail';
import { MessageCenter } from './message-center';
import { MobileRedirect } from './mobile-redirect';
import { MyApplications } from './my-applications';
import { MyBonuses } from './my-bonuses';
import { MyContacts } from './my-contacts';
import { MyProfile } from './my-profile';
import { MyReferrals } from './my-referrals';
import { MyReferralsExtended } from './my-referrals-extended';
import {
	NewCompanyUserLandingPage,
	NewExtendedUserLandingPage,
	NewExtendedUserLinkLandingPage,
	NewExternalUserLandingPage,
	NewPartnerUserLandingPage,
	NewSamlUserLandingPage,
	NewUserLandingPage,
} from './new-user-landing-page';
import { OpenToNewRole } from './open-to-new-roles';
import { OpenToNewRoleDetails } from './open-to-new-roles-details';
import { Points } from './points';
import { ReferralDetails } from './referral-details';
import { ReferralsDashboardContainer } from './referrals-admin-dashboard';
import { ReportBuilder } from './reports';
import { Branding, Settings } from './settings-dashboard';
import { EmployeeDashboardSettings } from './settings-dashboard/employee-dashboard-settings';
import { ReferralTiersSettings } from './settings-dashboard/referral-tiers';
import { SuperUser } from './superUser';
import { WebNotificationsPage } from './web-notifications';
import manageRewards from './manageRewards';
import ApprovalRewards from './approvalRewards';
import PhysicalRewards from './PhysicalRewards';
import nominatedRewards from './nominatedRewards';
import selectWinnerContainer from './nominatedRewards/container/SelectWinnerContainer';
import rewardHome from './home';
import myrewards from './my-rewards';
import allrewards from './allrewards';
import allRewardsAdmin from './allRewardsAdmin';
import ApprovalsBonus from './approvalsBonus';
import rewardBonus from './rewardBonus/index.js';
import recognitionSettings from 'src/recognitionSettings';

class AppRouter extends Component {
	constructor(props) {
		super(props);
		this.state = {
			sidebarOpen: true,
			resultData: '',
			disableSite: false,
			showNav:
				window.location.pathname.includes('newreferral') ||
				window.location.pathname.includes('newinternalreferral') ||
				window.location.pathname.includes('logout'),
		};
	}

	hamburgerToggleClickHandler = () => {
		this.setState((prevState) => {
			return { sidebarOpen: !prevState.sidebarOpen };
		});
	};

	componentDidUpdate() {
		mixpanel.track_pageview();
		const showNav =
			window.location.pathname.includes('newreferral') ||
			window.location.pathname.includes('newinternalreferral') ||
			window.location.pathname.includes('logout');
		if (showNav !== this.state.showNav) this.setState({ showNav });
	}

	render() {
		const { showNav } = this.state;
		const host = window.location.hostname;

		const enableByHost =
			host === 'erin-app-demo.netlify.app' || host === 'localhost';
		return (
			<RouterContext.Consumer>
				{({ auth, currentUser, handleSignOut, primaryPlatform }) => (
					<div className={auth ? 'wrapper' : 'auth-wrapper'}>
						{auth && !showNav && (
							<Aside
								show={this.state.sidebarOpen}
								user={currentUser}
								hamburgerClickHandler={this.hamburgerToggleClickHandler}
							/>
						)}
						<div className="container-fluid">
							{auth && !showNav && (
								<div className="row">
									<Header
										hamburgerClickHandler={this.hamburgerToggleClickHandler}
										user={currentUser}
										handleSignOut={handleSignOut}
									/>
								</div>
							)}
							<div className="row">
								<div className="col-12">
									<div className="content-area">
										<Switch>
											<Route
												exact
												path="/"
												render={(props) => <Home {...props} currentUser={currentUser} primaryPlatform={primaryPlatform} auth={auth} />}
											/>
											<UnauthenticatedRoute path="/login" component={Login} />
											<UnauthenticatedRoute
												path="/saml-auth/login"
												component={SAMLWebLogin}
											/>
											<UnauthenticatedRoute
												path="/claim"
												component={Login}
												routeData={{ openClaimYourAccount: true }}
											/>
											<UnauthenticatedRoute
												path="/saml/login"
												component={SAMLMobileLogin}
											/>
											<UnauthenticatedRoute
												path="/newuser/:id"
												component={NewUserLandingPage}
											/>
											<UnauthenticatedRoute
												path="/signup/:id"
												component={NewPartnerUserLandingPage}
											/>
											<UnauthenticatedRoute
												path="/new-user/:id"
												component={NewExtendedUserLandingPage}
											/>
											<UnauthenticatedRoute
												path="/external-user"
												component={NewExternalUserLandingPage}
											/>
											<UnauthenticatedRoute
												path="/new-user-sign-up/:id"
												component={NewExtendedUserLinkLandingPage}
											/>
											<UnauthenticatedRoute
												path="/new-user-signup/:id"
												component={NewCompanyUserLandingPage}
											/>
											<ByPassauthenticatedRoute
												exact
												path="/newreferral/:referralId"
												component={NewReferralLandingPageComponent}
												pageType="standard"
											/>
											<UnauthenticatedRoute
												exact
												path="/mobile-redirect"
												component={MobileRedirect}
											/>
											<Route exact path="/share" component={ShareWrapper} />
											<ByPassauthenticatedRoute
												exact
												path="/newreferral/:referredById/:jobId/:langCode"
												component={NewReferralLandingPageComponent}
												pageType="public"
											/>
											<ByPassauthenticatedRoute
												exact
												path="/newreferral/:webNotificationId/:langCode"
												component={NewReferralLandingPageComponent}
												pageType="gdpr"
											/>
											<ByPassauthenticatedRoute
												exact
												path="/newinternalreferral/:referredById/:jobId/:referredUserId"
												component={NewReferralLandingPageComponent}
												pageType="internal"
											/>
											<UnauthenticatedRoute
												exact
												path="/generalreferral/:referredBy/:id"
												component={GeneralReferralPage}
											/>
											<Route
												exact
												path="/google/login"
												render={(props) => <Redirect google nav={props} />}
											/>
											<Route
												exact
												path="/:companycode/login"
												render={(props) => <Redirect saml nav={props} />}
											/>
											<Route
												path="/newsamluser/:id"
												render={(props) => (
													<NewSamlUserLandingPage {...props} />
												)}
											/>
											<Route
												path="/logout"
												render={(props) => (
													<Logout {...props} handleSignOut={handleSignOut} />
												)}
											/>
											<AuthenticatedRoute
												roles={[
													USER_ROLES.EMPLOYEE,
													USER_ROLES.MANAGER,
													USER_ROLES.ADMIN,
													USER_ROLES.SUPER_ADMIN,
													USER_ROLES.SUPPORT_ADMIN,
													USER_ROLES.EXTENDED_USER,
												]}
												path="/giftcardstore"
												component={GiftCardStore}
											/>
											<AuthenticatedRoute
												roles={[
													USER_ROLES.EMPLOYEE,
													USER_ROLES.MANAGER,
													USER_ROLES.ADMIN,
													USER_ROLES.SUPER_ADMIN,
													USER_ROLES.SUPPORT_ADMIN,
													USER_ROLES.EXTENDED_USER,
												]}
												path="/myapplications"
												component={MyApplications}
											/>
											<AuthenticatedRoute
												exact
												roles={[
													USER_ROLES.EMPLOYEE,
													USER_ROLES.MANAGER,
													USER_ROLES.ADMIN,
													USER_ROLES.SUPER_ADMIN,
													USER_ROLES.SUPPORT_ADMIN,
													USER_ROLES.EXTENDED_USER,
												]}
												path="/browsejobs"
												component={BrowseJobs}
											/>
											<AuthenticatedRoute
												exact
												roles={[
													USER_ROLES.EMPLOYEE,
													USER_ROLES.MANAGER,
													USER_ROLES.ADMIN,
													USER_ROLES.SUPER_ADMIN,
													USER_ROLES.SUPPORT_ADMIN,
													USER_ROLES.EXTENDED_USER,
												]}
												path="/browsejobs/new"
												component={BrowseJobs}
											/>
											<AuthenticatedRoute
												exact
												roles={[
													USER_ROLES.EMPLOYEE,
													USER_ROLES.MANAGER,
													USER_ROLES.ADMIN,
													USER_ROLES.SUPER_ADMIN,
													USER_ROLES.SUPPORT_ADMIN,
													USER_ROLES.EXTENDED_USER,
												]}
												path="/browsejobs/:id"
												component={JobDetailsComponent}
											/>
											<AuthenticatedRoute
												exact
												roles={[
													USER_ROLES.EMPLOYEE,
													USER_ROLES.MANAGER,
													USER_ROLES.ADMIN,
													USER_ROLES.SUPER_ADMIN,
													USER_ROLES.SUPPORT_ADMIN,
													USER_ROLES.EXTENDED_USER,
												]}
												path="/dashboard"
												component={Dashboard}
											/>
											<AuthenticatedRoute
												exact
												roles={[USER_ROLES.EXTENDED_USER]}
												path="/my-referrals"
												component={MyReferralsExtended}
											/>
											<AuthenticatedRoute
												exact
												roles={[
													USER_ROLES.EMPLOYEE,
													USER_ROLES.MANAGER,
													USER_ROLES.ADMIN,
													USER_ROLES.SUPER_ADMIN,
													USER_ROLES.SUPPORT_ADMIN,
													USER_ROLES.EXTENDED_USER,
												]}
												path="/career"
												component={CareerProfile}
											/>
											<AuthenticatedRoute
												exact
												roles={[
													USER_ROLES.MANAGER,
													USER_ROLES.ADMIN,
													USER_ROLES.SUPER_ADMIN,
													USER_ROLES.SUPPORT_ADMIN,
												]}
												path="/employees"
												component={EmployeesDashboard}
											/>
											<AuthenticatedRoute
												exact
												roles={[
													USER_ROLES.ADMIN,
													USER_ROLES.SUPER_ADMIN,
													USER_ROLES.SUPPORT_ADMIN,
												]}
												path="/employees/form"
												component={EmployeesDashboard}
											/>
											<AuthenticatedRoute
												exact
												roles={[
													USER_ROLES.MANAGER,
													USER_ROLES.ADMIN,
													USER_ROLES.SUPER_ADMIN,
													USER_ROLES.SUPPORT_ADMIN,
												]}
												path="/referrals"
												component={ReferralsDashboardContainer}
											/>
											<AuthenticatedRoute
												exact
												roles={[
													USER_ROLES.MANAGER,
													USER_ROLES.ADMIN,
													USER_ROLES.SUPER_ADMIN,
													USER_ROLES.SUPPORT_ADMIN,
												]}
												path="/referrals/:id"
												component={ReferralDetails}
											/>
											<AuthenticatedRoute
												exact
												roles={[
													USER_ROLES.MANAGER,
													USER_ROLES.ADMIN,
													USER_ROLES.SUPER_ADMIN,
													USER_ROLES.SUPPORT_ADMIN,
												]}
												path="/employees/:id"
												component={EmployeeDetails}
											/>
											<AuthenticatedRoute
												exact
												roles={[
													USER_ROLES.MANAGER,
													USER_ROLES.ADMIN,
													USER_ROLES.SUPER_ADMIN,
													USER_ROLES.SUPPORT_ADMIN,
												]}
												path="/jobs"
												component={ManageJobs}
											/>
											<AuthenticatedRoute
												exact
												roles={[
													USER_ROLES.MANAGER,
													USER_ROLES.ADMIN,
													USER_ROLES.SUPER_ADMIN,
													USER_ROLES.SUPPORT_ADMIN,
												]}
												path="/jobs/:id"
												component={JobDetail}
											/>
											<AuthenticatedRoute
												exact
												roles={[
													USER_ROLES.EMPLOYEE,
													USER_ROLES.MANAGER,
													USER_ROLES.ADMIN,
													USER_ROLES.SUPER_ADMIN,
													USER_ROLES.SUPPORT_ADMIN,
												]}
												path="/editprofile/:id"
												component={EditUserProfile}
											/>
											<AuthenticatedRoute
												path="/myreferrals"
												roles={[
													USER_ROLES.EMPLOYEE,
													USER_ROLES.MANAGER,
													USER_ROLES.ADMIN,
													USER_ROLES.SUPER_ADMIN,
													USER_ROLES.SUPPORT_ADMIN,
												]}
												component={MyReferrals}
											/>
											<AuthenticatedRoute
												roles={[
													USER_ROLES.EMPLOYEE,
													USER_ROLES.MANAGER,
													USER_ROLES.ADMIN,
													USER_ROLES.SUPER_ADMIN,
													USER_ROLES.SUPPORT_ADMIN,
												]}
												path="/notifications"
												component={WebNotificationsPage}
											/>
											<AuthenticatedRoute
												exact
												roles={[
													USER_ROLES.EMPLOYEE,
													USER_ROLES.MANAGER,
													USER_ROLES.ADMIN,
													USER_ROLES.SUPER_ADMIN,
													USER_ROLES.SUPPORT_ADMIN,
												]}
												path="/mycontacts"
												component={MyContacts}
												{...this.props}
											/>
											<AuthenticatedRoute
												exact
												roles={[
													USER_ROLES.EMPLOYEE,
													USER_ROLES.MANAGER,
													USER_ROLES.ADMIN,
													USER_ROLES.SUPER_ADMIN,
													USER_ROLES.SUPPORT_ADMIN,
												]}
												path="/mycontacts/:id"
												component={ContactDetails}
											/>
											<AuthenticatedRoute
												exact
												roles={[
													USER_ROLES.EMPLOYEE,
													USER_ROLES.MANAGER,
													USER_ROLES.ADMIN,
													USER_ROLES.SUPER_ADMIN,
													USER_ROLES.SUPPORT_ADMIN,
												]}
												path="/mycontacts/add/form"
												component={MyContacts}
											/>
											<AuthenticatedRoute
												roles={[
													USER_ROLES.SUPER_USER,
													USER_ROLES.SUPPORT_ADMIN,
												]}
												path="/admin-console"
												component={SuperUser}
											/>
											<AuthenticatedRoute
												exact
												roles={[
													USER_ROLES.ADMIN,
													USER_ROLES.SUPER_ADMIN,
													USER_ROLES.SUPPORT_ADMIN,
												]}
												path="/settings"
												component={Settings}
											/>
											<AuthenticatedRoute
												exact
												roles={[
													USER_ROLES.ADMIN,
													USER_ROLES.SUPER_ADMIN,
													USER_ROLES.SUPPORT_ADMIN,
													USER_ROLES.MANAGER,
												]}
												path="/deck"
												component={OnDeck}
											/>
											<AuthenticatedRoute
												exact
												roles={[
													USER_ROLES.ADMIN,
													USER_ROLES.SUPER_ADMIN,
													USER_ROLES.SUPPORT_ADMIN,
													USER_ROLES.MANAGER,
												]}
												path="/bonuses"
												component={BonusReportContainer}
											/>
											<AuthenticatedRoute
												exact
												roles={[
													USER_ROLES.ADMIN,
													USER_ROLES.SUPER_ADMIN,
													USER_ROLES.SUPPORT_ADMIN,
												]}
												path="/builder"
												component={ReferralTiersSettings}
											/>
											<AuthenticatedRoute
												roles={[
													USER_ROLES.EMPLOYEE,
													USER_ROLES.MANAGER,
													USER_ROLES.ADMIN,
													USER_ROLES.SUPER_ADMIN,
													USER_ROLES.SUPPORT_ADMIN,
													USER_ROLES.EXTENDED_USER,
												]}
												path="/myprofile"
												component={MyProfile}
											/>
											<AuthenticatedRoute
												exact
												roles={[
													USER_ROLES.ADMIN,
													USER_ROLES.SUPER_ADMIN,
													USER_ROLES.SUPPORT_ADMIN,
												]}
												path="/messagecenter"
												component={MessageCenter}
											/>
											<AuthenticatedRoute
												exact
												roles={[
													USER_ROLES.ADMIN,
													USER_ROLES.SUPER_ADMIN,
													USER_ROLES.SUPPORT_ADMIN,
												]}
												path="/bonus-campaigns"
												component={BonusCampaigns}
											/>
											<AuthenticatedRoute
												exact
												roles={[
													USER_ROLES.ADMIN,
													USER_ROLES.SUPER_ADMIN,
													USER_ROLES.SUPPORT_ADMIN,
												]}
												path="/points"
												component={Points}
											/>
											<AuthenticatedRoute
												exact
												roles={[
													USER_ROLES.ADMIN,
													USER_ROLES.SUPER_ADMIN,
													USER_ROLES.SUPPORT_ADMIN,
												]}
												path="/form-builder"
												component={FormBuilder}
											/>
											<AuthenticatedRoute
												exact
												roles={[
													USER_ROLES.ADMIN,
													USER_ROLES.SUPER_ADMIN,
													USER_ROLES.SUPPORT_ADMIN,
												]}
												path="/job-notification-email"
												component={EditEmails}
											/>
											<AuthenticatedRoute
												exact
												roles={[
													USER_ROLES.ADMIN,
													USER_ROLES.SUPER_ADMIN,
													USER_ROLES.SUPPORT_ADMIN,
												]}
												path="/branding"
												component={Branding}
											/>
											<AuthenticatedRoute
												exact
												roles={[
													USER_ROLES.ADMIN,
													USER_ROLES.SUPER_ADMIN,
													USER_ROLES.SUPPORT_ADMIN,
												]}
												path="/employee-dashboard-settings"
												component={EmployeeDashboardSettings}
											/>
											<AuthenticatedRoute
												exact
												roles={[
													USER_ROLES.ADMIN,
													USER_ROLES.SUPER_ADMIN,
													USER_ROLES.SUPPORT_ADMIN,
												]}
												path="/report-builder"
												component={ReportBuilder}
											/>
											<AuthenticatedRoute
												path="/mybonuses"
												roles={[
													USER_ROLES.EMPLOYEE,
													USER_ROLES.SUPER_ADMIN,
													USER_ROLES.SUPPORT_ADMIN,
													USER_ROLES.MANAGER,
													USER_ROLES.ADMIN,
												]}
												component={MyBonuses}
											/>
											<AuthenticatedRoute
												path="/custompage/:customPageId"
												roles={[
													USER_ROLES.EMPLOYEE,
													USER_ROLES.SUPER_ADMIN,
													USER_ROLES.SUPPORT_ADMIN,
													USER_ROLES.MANAGER,
													USER_ROLES.ADMIN,
												]}
												component={CustomPage}
											/>
											<AuthenticatedRoute
												path="/announcements"
												roles={[
													USER_ROLES.EMPLOYEE,
													USER_ROLES.SUPER_ADMIN,
													USER_ROLES.SUPPORT_ADMIN,
													USER_ROLES.MANAGER,
													USER_ROLES.ADMIN,
												]}
												component={Announcements}
											/>
											<AuthenticatedRoute
												path="/internal"
												roles={[
													USER_ROLES.EMPLOYEE,
													USER_ROLES.SUPER_ADMIN,
													USER_ROLES.SUPPORT_ADMIN,
													USER_ROLES.MANAGER,
													USER_ROLES.ADMIN,
												]}
												component={Internal}
											/>
											<AuthenticatedRoute
												path="/internal-applicants"
												roles={[
													USER_ROLES.EMPLOYEE,
													USER_ROLES.SUPER_ADMIN,
													USER_ROLES.SUPPORT_ADMIN,
													USER_ROLES.MANAGER,
													USER_ROLES.ADMIN,
												]}
												component={InternalApplicationContainer}
											/>
											<AuthenticatedRoute
												path="/open"
												roles={[
													USER_ROLES.SUPER_ADMIN,
													USER_ROLES.SUPPORT_ADMIN,
													USER_ROLES.MANAGER,
													USER_ROLES.ADMIN,
												]}
												component={OpenToNewRole}
											/>
											<AuthenticatedRoute
												exact
												roles={[
													USER_ROLES.ADMIN,
													USER_ROLES.SUPER_ADMIN,
													USER_ROLES.MANAGER,
													USER_ROLES.SUPPORT_ADMIN,
												]}
												path="/openDetails/:id"
												component={OpenToNewRoleDetails}
											/>
											<AuthenticatedRoute
												path="/manage-badges"
												roles={
													enableByHost ||
													currentUser?.enableRecognition ||
													currentUser?.company?.enableRecognition
														? [
																USER_ROLES.ADMIN,
																USER_ROLES.MANAGER,
																USER_ROLES.SUPER_ADMIN,
															]
														: [USER_ROLES.SUPER_ADMIN]
												}
												component={manageRewards}
											/>
											<AuthenticatedRoute
												path="/recognition-bonus"
												roles={
													enableByHost ||
													currentUser?.enableRecognition ||
													currentUser?.company?.enableRecognition
														? [
																USER_ROLES.ADMIN,
																USER_ROLES.MANAGER,
																USER_ROLES.SUPER_ADMIN,
																USER_ROLES.SUPPORT_ADMIN,
															]
														: [USER_ROLES.SUPER_ADMIN]
												}
												component={rewardBonus}
											/>
											<AuthenticatedRoute
												path="/recognition-approvals"
												roles={
													enableByHost ||
													currentUser?.enableRecognition ||
													currentUser?.company?.enableRecognition
														? [
																USER_ROLES.ADMIN,
																USER_ROLES.MANAGER,
																USER_ROLES.SUPER_ADMIN,
															]
														: [USER_ROLES.SUPER_ADMIN]
												}
												component={ApprovalRewards}
												{...this.props}
											/>
											<AuthenticatedRoute
												path="/recognitions-admin"
												roles={
													enableByHost ||
													currentUser?.enableRecognition ||
													currentUser?.company?.enableRecognition
														? [
																USER_ROLES.ADMIN,
																USER_ROLES.MANAGER,
																USER_ROLES.SUPER_ADMIN,
															]
														: [USER_ROLES.SUPER_ADMIN]
												}
												component={allRewardsAdmin}
												{...this.props}
											/>
											<AuthenticatedRoute
												path="/physical-rewards"
												roles={
													enableByHost ||
													currentUser?.enableRecognition ||
													currentUser?.company?.enableRecognition
														? [
																USER_ROLES.ADMIN,
																USER_ROLES.MANAGER,
																USER_ROLES.SUPER_ADMIN,
															]
														: [USER_ROLES.SUPER_ADMIN]
												}
												component={PhysicalRewards}
												{...this.props}
											/>
											<AuthenticatedRoute
												path="/nominations"
												roles={
													enableByHost ||
													currentUser?.enableRecognition ||
													currentUser?.company?.enableRecognition
														? [
																USER_ROLES.ADMIN,
																USER_ROLES.MANAGER,
																USER_ROLES.SUPER_ADMIN,
															]
														: [USER_ROLES.SUPER_ADMIN]
												}
												component={nominatedRewards}
											/>
											<AuthenticatedRoute
												path="/recognition-settings"
												roles={
													enableByHost ||
													currentUser?.enableRecognition ||
													currentUser?.company?.enableRecognition
														? [
																USER_ROLES.ADMIN,
																USER_ROLES.MANAGER,
																USER_ROLES.SUPER_ADMIN,
															]
														: [USER_ROLES.SUPER_ADMIN]
												}
												component={recognitionSettings}
											/>
											<AuthenticatedRoute
												path="/select-winner/:id"
												roles={
													enableByHost ||
													currentUser?.enableRecognition ||
													currentUser?.company?.enableRecognition
														? [
																USER_ROLES.ADMIN,
																USER_ROLES.MANAGER,
																USER_ROLES.SUPER_ADMIN,
																USER_ROLES.SUPPORT_ADMIN,
															]
														: [USER_ROLES.SUPER_ADMIN]
												}
												component={selectWinnerContainer}
											/>
											<AuthenticatedRoute
												path="/recognition-hub"
												roles={
													enableByHost ||
													currentUser?.enableRecognition ||
													currentUser?.company?.enableRecognition
														? [USER_ROLES.EMPLOYEE]
														: [USER_ROLES.SUPER_ADMIN]
												}
												component={rewardHome}
											/>
											<AuthenticatedRoute
												path="/my-recognitions"
												roles={
													enableByHost ||
													currentUser?.enableRecognition ||
													currentUser?.company?.enableRecognition
														? [USER_ROLES.EMPLOYEE]
														: [USER_ROLES.SUPER_ADMIN]
												}
												component={myrewards}
											/>
											<AuthenticatedRoute
												path="/recognitions"
												roles={
													enableByHost ||
													currentUser?.enableRecognition ||
													currentUser?.company?.enableRecognition
														? [USER_ROLES.EMPLOYEE]
														: [USER_ROLES.SUPER_ADMIN]
												}
												component={allrewards}
											/>
											<AuthenticatedRoute
												path="/approvals-bonus"
												roles={
													enableByHost ||
													currentUser?.enableRecognition ||
													currentUser?.company?.enableRecognition
														? [USER_ROLES.EMPLOYEE]
														: [USER_ROLES.SUPER_ADMIN]
												}
												component={ApprovalsBonus}
												{...this.props}
											/>
											{/* Catch all route for any unmatched
                    requests and navigates back to "Home" Component */}
											<Route
												render={(props) => <Home {...props} currentUser={currentUser} primaryPlatform={primaryPlatform} auth={auth} />}
											/>
										</Switch>
									</div>
								</div>
							</div>
						</div>
					</div>
				)}
			</RouterContext.Consumer>
		);
	}
}

function Home(props) {
	useEffect(() => {
		if (props.auth && props?.primaryPlatform === 'recognition') {
			if (props.currentUser?.role === USER_ROLES.ADMIN || props.currentUser?.role === USER_ROLES.SUPER_ADMIN) {
				props.history.push('/recognitions-admin');
			} else {
				props.history.push('/recognition-hub');
			}
		} else if (props.auth) {
			props.history.push('/dashboard');
		} else {
			props.history.push('/login');
		}
	}, [props.auth]);

	return null;
}

export default withRouter(AppRouter);
