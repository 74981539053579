import { Button } from 'antd';
import getSymbolFromCurrency from 'currency-symbol-map';
import dayjs from 'dayjs';
import get from 'lodash/get';
import { Component } from 'react';
import { formatDate } from '../services/utils';
import {
	LinkStyles,
	ModalTitle,
	NameStyles,
	ReferralBtn as ReferralButton,
	SubTitle,
	TextStyle,
	filterPayment,
	myReferralsTextEmphasisClass,
} from './bonusTableDetailsModalStyles.js';

class MyBonusDetailsModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			editMode: false,
			error: false,
			visible: false,
		};
	}

	getBonusStatus(status) {
		if (status === 'paid') {
			return 'Paid';
		}

		if (status === 'pending') {
			return 'Pending';
		}

		if (status === 'earned') {
			return 'Earned';
		}

		return 'Ineligible';
	}

	getName = (recipientType) => {
		const { data } = this.props;
		const firstName = get(data, 'contact.firstName');

		const lastName = get(data, 'contact.lastName');
		const URL = `/referrals/${get(data, 'id')}`;

		return { firstName, lastName, URL };
	};

	getPayment = (payment) => {
		const payString = payment === null ? '' : payment;
		const splitLink = payString.split('');
		const lastChar = splitLink.pop();
		const label = splitLink.join('');
		return (
			<span>
				{label} <span className={filterPayment}>{lastChar}</span>
			</span>
		);
	};

	closeModal = () => {
		this.setState({
			visible: false,
			editMode: false,
			amountDue: null,
			startDate: null,
			earnedDate: null,
			bonusStatus: null,
		});
	};

	handleError = () => {
		this.setState({ error: true });
	};

	render() {
		const {
			data,
			bonusDetailsText,
			referredCandidateText,
			hiredDateText,
			startDateText,
			bonusNotesText,
			closeText,
			jobText,
			paymentText,
			eligibleDateText,
			bonusPaymentsText,
			navigateTo,
		} = this.props;
		const languageCode = get(this.props.currentUser, 'languageCode');

		const hireDate = formatDate(get(data, 'hireDate', null), languageCode);
		const earnedDate = formatDate(get(data, 'earnedDate', null), languageCode);
		let recipientType = '';
		const type = get(data, 'recipientType');
		if (type && typeof type === 'string') {
			const value = type.split('');
			value.splice(0, 1, value[0].toUpperCase());
			recipientType = value.join('');
		}

		const referredType = type === 'employee' ? 'candidate' : 'employee';
		const referred = this.getName(referredType);
		let bonusStatus = get(data, 'bonusStatus', null);
		if (bonusStatus === null) {
			bonusStatus = get(data, 'bonusStatus', '');
		}

		const amountDue = get(data, 'amountDue', null);
		const bonuses = get(data, 'bonuses', []).sort(function (a, b) {
			return a.earnedDate < b.earnedDate
				? -1
				: a.earnedDate > b.earnedDate
					? 1
					: 0;
		});
		const symbol = getSymbolFromCurrency(
			get(this.props.currentUser, 'userGroup.currency')
		);
		const startDate = formatDate(get(data, 'startDate'), languageCode);
		const payment = get(data, 'payment');
		let notes = '';
		notes ||= get(data, 'notes', '');
		const showEmphasis = dayjs().isAfter(earnedDate);
		return (
			<div style={{ textAlign: 'left' }}>
				<div>
					<div style={{ display: 'flex', justifyContent: 'space-between' }}>
						<h1 className={ModalTitle}>
							{bonusDetailsText}
							{/* Bonus Details */}
						</h1>
					</div>
					<div style={{ marginBottom: 20 }}>
						<div className={SubTitle}>
							{jobText}:{' '}
							<a
								className={LinkStyles}
								href={`/jobs/${get(data, 'job.id')}`}
								onClick={(event) => {
									event.preventDefault();
									navigateTo(`/jobs/${get(data, 'job.id')}`);
								}}
							>
								{this.props.translatedTitle}
							</a>
						</div>
						<div className={SubTitle}>
							{referredCandidateText}:{' '}
							<a
								href={get(referred, 'URL')}
								className={NameStyles}
								onClick={(event) => {
									event.preventDefault();
									navigateTo(get(referred, 'URL'));
								}}
							>
								{get(referred, 'firstName')} {get(referred, 'lastName')}
							</a>
						</div>
						<div className={SubTitle}>
							{hiredDateText}: <span className={TextStyle}>{hireDate}</span>
						</div>
						<div className={SubTitle}>
							{startDateText}: <span className={TextStyle}>{startDate}</span>
						</div>
					</div>
					<div style={{ marginBottom: 20 }}>
						<div className={SubTitle}>
							<div>
								{bonusPaymentsText}:{' '}
								<span
									className={myReferralsTextEmphasisClass(showEmphasis, true)}
								>
									{`${symbol}`}
									{Number.parseInt(amountDue)}
								</span>
							</div>
						</div>
						<div className={SubTitle}>
							{paymentText}:{' '}
							<span className={TextStyle}>{this.getPayment(payment)}</span>
						</div>
						<div className={SubTitle}>
							{eligibleDateText}:{' '}
							<span className={TextStyle}>{earnedDate}</span>
						</div>
					</div>
					<div>
						<div className={SubTitle}>
							<div>{bonusNotesText}: </div>
							<span className={TextStyle}>{notes}</span>
						</div>
					</div>
					<div
						style={{
							display: 'flex',
							justifyContent: 'center',
							marginTop: 20,
						}}
					>
						<Button
							className={ReferralButton}
							onClick={this.props.handleCancel}
						>
							<div
								style={{
									width: '100%',
									margin: 'auto',
								}}
							>
								{closeText}
								{/* Close */}
							</div>
						</Button>
					</div>
				</div>
			</div>
		);
	}
}

export default MyBonusDetailsModal;
