import { css } from 'emotion';
import { COLORS } from '../../_shared/styles/colors';

export const InputStyles = css({
	fontFamily: '"Open Sans", sans-serif',
	fontSize: 16,
	'& .ant-input': {
		height: 40,
		fontFamily: '"Open Sans", sans-serif',
		fontSize: 16,
	},
	'& i': {
		fontSize: 60,
		color: COLORS.blue,
	},
	'& .ant-select': {
		height: 40,
		fontFamily: '"Open Sans", sans-serif',
		fontSize: 16,
		display: 'inline-block',
	},
	'& .ant-upload-drag-container': {
		padding: '0px 100px',
		fontFamily: '"Open Sans", sans-serif',
		fontSize: 16,
	},
});

export const SubmitBtn = css({
	fontFamily: 'Open Sans',
	backgroundColor: COLORS.red,
	color: 'white',
	fontSize: 20,
	fontWeight: 300,
	padding: '10px 30px',
	height: 'auto',
	border: 'none',
	marginBottom: 0,
});

export const SubmitBtnContainer = css({
	width: '100%',
	display: 'flex',
	justifyContent: 'center',
	padding: 10,
	'& .ant-form-item-control-wrapper': {
		'@media (max-width: 575px)': {
			width: 'auto',
		},
	},
	'& .ant-btn: hover': {
		color: COLORS.blue,
		fontWeight: 300,
		border: `2px solid ${COLORS.blue}`,
	},
});

export const CheckIcon = css({
	borderRadius: 100,
	border: '1px solid white',
	padding: 2,
});

export const FormStyles = css({
	width: 500,
});

export const FormItemStyles = css({
	marginBottom: '15px !important',
});

export const LabelStyles = css({
	fontSize: '16px !important',
	fontWeight: 600,
});
