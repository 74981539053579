import gql from 'graphql-tag';

export const getContactDetails = gql`
	query GetContact($id: ID!) {
		getContact(id: $id) {
			companyId
			id
			firstName
			lastName
			emailAddress
			socialMediaAccounts
			referrals {
				id
				bonuses {
					id
					amountDue
					bonusStatus
					companyId
					contactId
					earnedDate
					hireDate
					jobId
					payment
					recipientType
					referralId
					startDate
					userId
					notes
				}
				companyId
				contactId
				userId
				user {
					id
					firstName
					lastName
					title
					userGroupId
				}
				jobId
				job {
					id
					title
				}
				note
				message
				ownershipEndDate
				status
				customStatus
				bonusStatus
				referralDate
				referralType
				questionsData
				candidateQuestionsData
				hireDate
				contactResume {
					bucket
					region
					key
				}
			}
			userId
			user {
				id
				firstName
				lastName
				title
				userGroupId
			}
			phoneNumber
			jobHistory
			importMethod
			fullContactData
			dateCreated
			onDeckStatus
			onDeckNote
			onDeckDate
			questionsData
			contactResume {
				bucket
				region
				key
			}
		}
	}
`;
