import { css } from 'emotion';
import { COLORS } from '../../_shared/styles/colors';

export const departmentContainer = css({
	backgroundColor: COLORS.lightGreen,
	width: 'auto',
	borderRadius: 4,
	display: 'inline-block',
	borderColor: COLORS.lightGreen,
	margin: 3,
});

export const closeIcon = css({
	fontSize: '12',
	color: COLORS.green,
	marginRight: 5,
});

export const departmentText = css({
	color: COLORS.green,
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 400,
	fontSize: 12,
	margin: 5,
});

export const DepartmentsContainer = css({
	display: 'flex',
	flexWrap: 'wrap',
	padding: '20px, 0, 40px, 20px',
});
