import gql from 'graphql-tag';
import get from 'lodash/get';
import { compose, graphql } from 'react-apollo';
import {
	queryAnnouncementsByCompanyIdIndex,
	updateAnnouncement,
} from '../../graphql/custom/announcements';

export const withQueryAnnouncementByCompanyId = (Component) => {
	return compose(
		graphql(gql(queryAnnouncementsByCompanyIdIndex), {
			options(props) {
				return {
					variables: {
						companyId: props.currentUser.companyId,
						limit: 1000,
					},
					fetchPolicy: 'cache-and-network',
				};
			},
			props({ data, data: { fetchMore } }, previous) {
				const announcements = get(
					data,
					'queryAnnouncementsByCompanyIdIndex.items',
					[]
				);
				const announcementsToken = get(
					data,
					'queryAnnouncementsByCompanyIdIndex.nextToken'
				);
				return {
					onLoadMore() {
						fetchMore({
							variables: { after: announcementsToken },
							updateQuery(previous_, { fetchMoreResult }) {
								if (!fetchMoreResult) {
									return previous_;
								}

								const mergedItems = [
									...previous_.queryAnnouncementsByCompanyIdIndex.items,
									...fetchMoreResult.queryAnnouncementsByCompanyIdIndex.items,
								];
								const set = new Set();
								const uniqueItems = mergedItems.filter((item) => {
									if (!set.has(item.id)) {
										set.add(item.id);
										return true;
									}

									return false;
								});
								return {
									...previous_,
									queryAnnouncementsByCompanyIdIndex: {
										...previous_.queryAnnouncementsByCompanyIdIndex,
										...fetchMoreResult.queryAnnouncementsByCompanyIdIndex,
										items: uniqueItems,
									},
								};
							},
						});
					},
					announcements,
					announcementsToken,
				};
			},
		}),
		graphql(gql(updateAnnouncement), {
			props: (props) => ({
				onUpdateAnnouncement(input) {
					const optimisticResponseData = {
						...input,
						__typename: 'Announcements',
					};
					props.mutate({
						variables: { ...input },
						optimisticResponse: {
							__typename: 'Mutation',
							updateAnnouncement: {
								__typename: 'updateAnnouncement',
								...optimisticResponseData,
							},
						},
						refetchQueries: [
							{
								query: gql(queryAnnouncementsByCompanyIdIndex),
								variables: { companyId: props.ownProps.currentUser.companyId },
							},
						],
					});
				},
			}),
		})
	)(Component);
};
