import {
	DndContext,
	DragOverlay,
	KeyboardSensor,
	MeasuringStrategy,
	MouseSensor,
	TouchSensor,
	closestCenter,
	defaultDropAnimationSideEffects,
	getFirstCollision,
	pointerWithin,
	rectIntersection,
	useSensor,
	useSensors,
	useDroppable,
} from '@dnd-kit/core';
import { restrictToWindowEdges } from '@dnd-kit/modifiers';
import {
	SortableContext,
	arrayMove,
	horizontalListSortingStrategy,
	verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import {
	forwardRef,
	useCallback,
	useEffect,
	useImperativeHandle,
	useRef,
	useState,
} from 'react';
import { createPortal } from 'react-dom';
import { v4 as uuidv4 } from 'uuid';
import { SortableItem } from '../draggable/index.js';
import { DroppableContainer } from '../droppable/index.js';
import { Item } from '../item/index.js';
import { coordinateGetter } from './coordinateGetter.js';
import { restrictToDndContext } from './modifiers/restrictToDndContext.js';

const dropAnimation = {
	sideEffects: defaultDropAnimationSideEffects({
		styles: {
			active: {
				opacity: '0.5',
			},
		},
	}),
};

const TRASH_ID = 'void';

function Trash({ id, className }) {
	const { setNodeRef, isOver } = useDroppable({
		id,
	});

	return (
		<div
			ref={setNodeRef}
			className={className}
			style={{ borderColor: isOver ? 'red' : '#DDD' }}
		>
			Drop here to delete
		</div>
	);
}

/** Drag & Drop Interface for Report Headers */
export const ReportBuilderContext = forwardRef(
	(
		{
			adjustScale = false,
			cancelDrop,
			handle = false,
			containerStyle,
			getItemStyles = () => ({}),
			wrapperStyle = () => ({}),
			minimal = false,
			modifiers = [],
			renderItem,
			strategy = verticalListSortingStrategy,
			vertical = false,
			scrollable,
			configData,
		},
		_ref
	) => {
		const [items, setItems] = useState({
			selected: configData.selected,
			unused: configData.unused,
		});
		const [clonedItems, setClonedItems] = useState(null); // Cloned headers used for drop overlay
		const [containers, setContainers] = useState(Object.keys(items));
		const [activeItem, setActiveItem] = useState(null);
		const lastOverId = useRef(null);
		const [lastKnownIndex, setLastKnownIndex] = useState(null);
		const recentlyMovedToNewContainer = useRef(false);
		const isSortingContainer =
			activeItem && activeItem?.id
				? containers.includes(activeItem?.id)
				: false;

		/** Utils */

		const findContainer = (defaultValue) => {
			if (defaultValue in items) {
				return defaultValue;
			}

			return Object.keys(items).find((key) =>
				items[key].some((object) => object?.id === defaultValue)
			);
		};

		const getIndex = (id) => {
			const container = findContainer(id);

			if (!container) {
				return -1;
			}

			const index = items[container].findIndex((object) => object.id === id);

			return index;
		};

		const getPosition = (id) => {
			const container = containers.find((key) =>
				items[key].some((object) => object.id === id)
			);

			if (!container) {
				return 'outside of all containers';
			}

			const position =
				items[container].findIndex((headerConfig) => headerConfig.id === id) +
				1; // Prefer position over index for screen readers;
			return position;
		};

		const getItemCount = (id) => {
			const container = containers.find((key) =>
				items[key].some((object) => object.id === id)
			);
			return items[container]?.length;
		};

		/** Event Handlers */

		/**
		 * Custom collision detection strategy optimized for multiple containers
		 *
		 * - First, find any droppable containers intersecting with the pointer.
		 * - If there are none, find intersecting containers with the active draggable.
		 * - If there are no intersecting containers, return the last matched intersection
		 *
		 */
		const collisionDetectionStrategy = useCallback(
			(args) => {
				if (activeItem && activeItem?.id in items) {
					return closestCenter({
						...args,
						droppableContainers: args.droppableContainers.filter(
							(container) => container.id in items
						),
					});
				}

				// Start by finding any intersecting droppable
				const pointerIntersections = pointerWithin(args);
				const intersections =
					pointerIntersections.length > 0
						? // If there are droppables intersecting with the pointer, return those
							pointerIntersections
						: rectIntersection(args);
				let overId = getFirstCollision(intersections, 'id');

				if (overId !== null) {
					if (overId === TRASH_ID) {
						return intersections;
					}

					if (overId in items) {
						const containerItems = items[overId];

						// If a container is matched and it contains items
						if (containerItems.length > 0) {
							// Return the closest droppable within that container
							overId =
								closestCenter({
									...args,
									droppableContainers: args.droppableContainers.filter(
										(container) =>
											container.id !== overId &&
											containerItems.includes(container.id)
									),
								})[0]?.id ?? overId;
						}
					}

					lastOverId.current = overId;

					return [{ id: overId }];
				}

				// When a draggable item moves to a new container, the layout may shift
				// and the `overId` may become `null`. We manually set the cached `lastOverId`
				// to the id of the draggable item that was moved to the new container, otherwise
				// the previous `overId` will be returned which can cause items to incorrectly shift positions
				if (recentlyMovedToNewContainer.current) {
					lastOverId.current = activeItem.id;
				}

				// If no droppable is matched, return the last match
				return lastOverId.current ? [{ id: lastOverId.current }] : [];
			},
			[activeItem, items]
		);

		// Accessibility event listeners
		const sensors = useSensors(
			useSensor(MouseSensor),
			useSensor(TouchSensor),
			useSensor(KeyboardSensor, {
				coordinateGetter,
			})
		);

		/** Handles editable text for headers or values for custom headers */
		const handleEdit = ({ item, property, newValue }) => {
			const itemContainer = findContainer(item.id);
			const itemIndex = getIndex(item.id);
			const newItem = { ...item, [property]: newValue };

			setItems((items) => {
				return {
					...items,
					[itemContainer]: [
						...items[itemContainer].slice(0, itemIndex),
						newItem,
						...items[itemContainer].slice(
							itemIndex + 1,
							items[itemContainer].length
						),
					],
				};
			});
		};

		/** Handles adding custom static headers */
		const handleAdd = () => {
			setItems((items) => ({
				...items,
				selected: [
					...items.selected,
					{
						displayName: 'Custom Header',
						default: 'Custom Header',
						type: 'STATIC',
						value: '',
						id: uuidv4(),
					},
				],
			}));
		};

		const onDragStart = ({ active }) => {
			const itemContainer = findContainer(active.id);
			const itemIndex = getIndex(active.id);
			setActiveItem(items[itemContainer][itemIndex]);
			setClonedItems(items);
		};

		const onDragCancel = () => {
			if (clonedItems) {
				// Reset items to their original state in case items have been
				// Dragged across containers
				setItems(clonedItems);
			}

			setActiveItem(null);
			setClonedItems(null);
		};

		const onDragOver = ({ active, over }) => {
			setLastKnownIndex(active.data.current.sortable.index);
			const overId = over?.id;

			if (overId === null || overId === TRASH_ID || active.id in items) {
				return;
			}

			const overContainer = findContainer(overId);
			const activeContainer = findContainer(active.id);

			if (!overContainer || !activeContainer) {
				return;
			}

			if (activeContainer !== overContainer) {
				setItems((items) => {
					const activeItems = items[activeContainer];
					const overItems = items[overContainer];
					const overIndex = overItems.findIndex(
						(object) => object.id === overId
					);
					const activeIndex = activeItems.findIndex(
						(object) => object.id === active.id
					);

					let newIndex;

					if (overId in items) {
						newIndex = overItems.length + 1;
					} else {
						const isBelowOverItem =
							over &&
							active.rect.current.translated &&
							active.rect.current.translated.top >
								over.rect.top + over.rect.height;

						const modifier = isBelowOverItem ? 1 : 0;

						newIndex =
							overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
					}

					recentlyMovedToNewContainer.current = true;

					return {
						...items,
						[activeContainer]: items[activeContainer].filter(
							(item) => item.id !== active.id
						),
						[overContainer]: [
							...items[overContainer].slice(0, newIndex),
							items[activeContainer][activeIndex],
							...items[overContainer].slice(
								newIndex,
								items[overContainer].length
							),
						],
					};
				});
			} else if (active.id !== overId) {
				setItems((items) => {
					const overItems = items[overContainer];
					const overIndex = overItems.findIndex(
						(object) => object.id === overId
					);
					if (overIndex >= 0) {
						setLastKnownIndex(overIndex);
					}

					const activeIndex = overItems.findIndex(
						(object) => object.id === active.id
					);

					const newIndex = overIndex >= 0 ? overIndex : lastKnownIndex;
					return {
						...items,
						[overContainer]: [...arrayMove(overItems, activeIndex, newIndex)],
					};
				});
			}
		};

		const onDragEnd = ({ active, over }) => {
			const activeContainer = findContainer(active.id);

			if (!activeContainer) {
				setActiveItem(null);
				return;
			}

			const overId = over?.id;

			if (overId === null) {
				setActiveItem(null);
				return;
			}

			if (overId === TRASH_ID) {
				setItems((items) => ({
					...items,
					[activeContainer]: items[activeContainer].filter(
						(item) => item.id !== activeItem.id
					),
				}));
				setActiveItem(null);
				return;
			}

			const overContainer = findContainer(overId);

			if (overContainer && overContainer !== activeContainer) {
				const activeIndex = items[activeContainer].findIndex(
					(object) => object.id === active.id
				);
				const overIndex = items[overContainer].findIndex(
					(object) => object.id === overId
				);

				if (activeIndex !== overIndex) {
					setItems((items) => ({
						...items,
						[overContainer]: arrayMove(
							items[overContainer],
							activeIndex,
							overIndex
						),
					}));
				}
			}

			setActiveItem(null);
		};

		// Screen Reader Announcements
		const announcements = {
			onDragStart({ active }) {
				return `Picked up sortable item ${active.id}. Sortable item ${
					active.id
				} is in position ${getPosition(active.id)} of ${getItemCount(
					active.id
				)} positions in ${findContainer(active.id)} Report Headers`;
			},
			onDragOver({ active, over }) {
				if (over) {
					return `Sortable item ${
						active.id
					} was moved into position ${getPosition(over.id)} of ${getItemCount(
						active.id
					)} positions in ${findContainer(active.id)} Report Headers`;
				}
			},
			onDragEnd({ active, over }) {
				if (over) {
					return `Sortable item ${
						active.id
					} was dropped at position ${getPosition(over.id)} of ${getItemCount(
						active.id
					)} positions in ${findContainer(active.id)} Report Headers`;
				}
			},
			onDragCancel({ active }) {
				return `Dragging was cancelled. Sortable item ${active.id} was dropped.`;
			},
		};

		useEffect(() => {
			requestAnimationFrame(() => {
				recentlyMovedToNewContainer.current = false;
			});
		}, [items]);

		useImperativeHandle(_ref, () => ({
			getHeaders() {
				return items;
			},
			setHeaders(headers) {
				setItems(headers);
			},
		}));

		return (
			<DndContext
				sensors={sensors}
				collisionDetection={collisionDetectionStrategy}
				measuring={{
					droppable: {
						strategy: MeasuringStrategy.Always,
					},
				}}
				cancelDrop={cancelDrop}
				modifiers={[...modifiers, restrictToWindowEdges, restrictToDndContext]}
				accessibility={{ announcements }}
				onDragStart={onDragStart}
				onDragOver={onDragOver}
				onDragEnd={onDragEnd}
				onDragCancel={onDragCancel}
			>
				<div className="report-builder-grid-container">
					<SortableContext
						items={[...containers]}
						strategy={
							vertical
								? verticalListSortingStrategy
								: horizontalListSortingStrategy
						}
					>
						{containers.map((containerId) => (
							<DroppableContainer
								key={containerId}
								id={containerId}
								label={minimal ? undefined : `${containerId} Report Headers`}
								items={items[containerId].map((item) => item.id)}
								scrollable={scrollable}
								style={containerStyle}
								unstyled={minimal}
								showAddButton={!activeItem}
								handleAdd={handleAdd}
							>
								<SortableContext
									items={items[containerId].map((item) => item.id)}
									strategy={strategy}
								>
									{items[containerId].map((value, index) => {
										return (
											<SortableItem
												key={value?.id}
												handle
												disabled={isSortingContainer}
												id={value?.id}
												value={value}
												index={index}
												wrapperStyle={wrapperStyle}
												renderItem={renderItem}
												containerId={containerId}
												getIndex={getIndex}
												handleEdit={handleEdit}
											/>
										);
									})}
								</SortableContext>
							</DroppableContainer>
						))}
					</SortableContext>

					{activeItem && activeItem.type === 'STATIC' ? (
						<Trash id={TRASH_ID} className="report-builder-trash" />
					) : null}
				</div>

				{createPortal(
					<DragOverlay adjustScale={adjustScale} dropAnimation={dropAnimation}>
						{activeItem && activeItem?.id ? (
							<Item
								dragOverlay
								handle
								value={activeItem}
								style={getItemStyles({
									containerId: findContainer(activeItem.id),
									overIndex: -1,
									index: getIndex(activeItem.id),
									value: activeItem.id,
									isSorting: true,
									isDragging: true,
									isDragOverlay: true,
								})}
								wrapperStyle={wrapperStyle({ index: 0 })}
								renderItem={renderItem}
							/>
						) : null}
					</DragOverlay>,
					document.body
				)}
			</DndContext>
		);
	}
);
