export const createCampaign = `mutation CreateCampaign($input: CreateCampaignInput!) {
    createCampaign(input: $input) {
      id
      companyId
      endDate
      jobId
      name
      startDate
      tieredBonusId
    }
  }
  `;
