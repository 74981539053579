export const updateSubCompany = `mutation UpdateSubCompany($input: UpdateSubCompanyInput!) {
    updateSubCompany(input: $input) {
      id
      keywords
      companyId
      logo {
        bucket
        region
        key
      }
      name
    }
  }
  `;
