import gql from 'graphql-tag';
import { compose, graphql } from 'react-apollo';
import get from 'lodash/get';
import { getReferralCountByCompany } from '../../graphql/custom/referrals';
import { getTieredBonus } from '../../graphql/custom/tiered-bonuses';
import { parseJsonFields } from '../../../services/parseApiService.js';

export const withGetTieredBonus = (Component) => {
	return compose(
		graphql(gql(getTieredBonus), {
			options: (props) => ({
				context: {
					headers: {
						'x-frame-options': 'deny', // This header will reach the server
					},
				},
				variables: props.tieredBonusQuery,
				fetchPolicy: 'cache-and-network',
			}),
			props(props, previous) {
				let tieredBonus = get(
					props,
					'data.getTieredBonus',
					get(previous, 'currentTieredBonus')
				);
				tieredBonus &&= Object.assign(
					tieredBonus,
					parseJsonFields(['tiers'], tieredBonus)
				);
				return {
					currentTieredBonus: tieredBonus,
				};
			},
		}),
		graphql(gql(getReferralCountByCompany), {
			options: (props) => ({
				variables: {
					companyId: props.companyId,
				},
				fetchPolicy: 'cache-and-network',
			}),
			props(res, props) {
				const referralCount = get(res, 'data.getReferralCountByCompany');
				if (referralCount) {
					return {
						currentReferralCount: referralCount,
					};
				}
			},
		})
	)(Component);
};
