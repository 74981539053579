import { useEffect, useState } from 'react';
import fetchGifsByIds from './fetchGifsByIds.jsx'; // Adjust the path as needed

const CustomGifPicker = ({ gifIds, onGifClick }) => {
  const [gifs, setGifs] = useState([]);

  useEffect(() => {
    const loadGifs = async () => {
      const fetchedGifs = await fetchGifsByIds(gifIds);
      setGifs(fetchedGifs);
    };

    loadGifs();
  }, [gifIds]);

  const handleGifClick = (gif) => {
    const tenorImage = {
      id: gif.id,
      tenorUrl: gif.itemurl,
      shortTenorUrl: gif.url,
      description: gif.content_description,
      createdAt: gif.created,
      tags: gif.tags,
      url: gif.media_formats.gif.url,
      height: gif.media_formats.gif.dims[1],
      width: gif.media_formats.gif.dims[0],
      preview: {
        url: gif.media_formats.gifpreview.url,
        height: gif.media_formats.gifpreview.dims[1],
        width: gif.media_formats.gifpreview.dims[0],
      }
    };
    onGifClick(tenorImage);
  };

  return (
    <div style={{
      width: '100%',
      height: '300px',
      overflowY: 'auto',
      display: 'flex',
      flexDirection: 'row',
    }}>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        width: '50%',
        paddingRight: '2.5px',
      }}>
        {gifs.filter((_, index) => index % 2 === 0).map((gif) => (
          <GifItem key={gif.id} gif={gif} onClick={handleGifClick} />
        ))}
      </div>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        width: '50%',
        paddingLeft: '2.5px',
      }}>
        {gifs.filter((_, index) => index % 2 !== 0).map((gif) => (
          <GifItem key={gif.id} gif={gif} onClick={handleGifClick} />
        ))}
      </div>
    </div>
  );
};

const GifItem = ({ gif, onClick }) => (
  <div
    style={{
      width: '100%',
      marginBottom: '5px',
      cursor: 'pointer'
    }}
    onClick={() => onClick(gif)}
  >
    <img
      src={gif.media_formats.gif.url}
      alt={gif.content_description}
      style={{
        width: '100%',
        height: 'auto',
        display: 'block'
      }}
    />
  </div>
);

export default CustomGifPicker;
