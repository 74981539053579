import gql from 'graphql-tag';

export const GetCompanyInviteEmail = gql`
	query GetCompanyInviteEmail($id: ID!) {
		getCompanyInviteEmail(id: $id) {
			id
			emailAddress
			dateCreated
		}
	}
`;
