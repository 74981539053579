import get from 'lodash/get';
import { connect } from 'react-redux';
import { withUserByIdEditProfile } from 'src/_shared/api/components/edit-profile/';
import { withMyJobMatches } from 'src/_shared/index.js';
import { compose } from 'src/_shared/services/utils.js';
import { dashboardActions, userActions } from 'src/actions.js';
import CareerProfileComponent from './CareerProfileComponent.jsx';

const mapStateToProperties = (state) => {
	const currentUser = get(state, 'user.currentUser');
	const allMultiLingualData = get(state, 'dashboard.allMultiLingualData');
	return {
		currentUser,
		id: get(currentUser, 'id'),
		filter: { companyId: { eq: get(currentUser, 'companyId') } },
		allMultiLingualData,
	};
};

const mapDispatchToProperties = (dispatch) => {
	return {
		updateCurrentUser(values) {
			dispatch(userActions.updateCurrentUser(values));
		},
		setMultiLingualData(data) {
			dispatch(dashboardActions.createSetMultiLingualData(data));
		},
	};
};

const CareerProfileWithApi = compose(
	withUserByIdEditProfile,
	withMyJobMatches
)(CareerProfileComponent);

export const CareerProfile = connect(
	mapStateToProperties,
	mapDispatchToProperties
)(CareerProfileWithApi);
