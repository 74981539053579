import { useEffect, useState } from 'react';
import { lambda, mapReferralStatus, ml } from 'src/_shared/services/utils.js';

export function ReferralSummaryCard(props) {
	const [counts, setCounts] = useState({referralsCount: 0, acceptedReferralsCount: 0 });
	const {
		allMultiLingualData,
		currentUser,
		job = {},
	} = props;
	const { matches } = props;
	const { id, shares, views, referrals = [] } = job;

	const jobId = id;

	useEffect(() => {
		const fetchReferralCount = async () => {
			try {
				const data = await getJobDetailReferralCount();

				if (data?.message) {
					setCounts({referralsCount: data.message?.referralsCount, acceptedReferralsCount: data.message?.acceptedReferralsCount});
				}
			} catch (error) {
				console.error('Error fetching referral count:', error);
			}
		};
		if (id) {
			fetchReferralCount();
		}
	}, [jobId]);

	const getJobDetailReferralCount = async () => {
		const endpoint = 'jobDetailReferralCount';
		return await lambda({ endpoint, variables: { jobId } });
	}



	return (
		<div className="mj-referral-card">
			<div className="mjr-card-head">
				<p className="mjr-summary-title">
					{ml('Referral Summary', currentUser, allMultiLingualData)}
				</p>
				<ul className="mjr-summary-count">
					<li>
						<h4>{counts.acceptedReferralsCount}</h4>
						<span>
							{' '}
							{ml(
								`${mapReferralStatus('accepted', currentUser.company)}`,
								currentUser,
								allMultiLingualData
							)}{' '}
						</span>
					</li>
					<li>
						<h4>{counts.referralsCount}</h4>
						<span>{ml('Referrals', currentUser, allMultiLingualData)}</span>
					</li>
					<li className="mjr-count-bg">
						<h4>{matches ? matches.length : 0}</h4>
						<span>{ml('Matches', currentUser, allMultiLingualData)}</span>
					</li>
				</ul>
			</div>
			<div className="mjr-card-body">
				<div className="mjr-view-share">
					<label>{ml('Job Shares', currentUser, allMultiLingualData)}</label>
					<p> {shares} </p>
				</div>
				<div className="mjr-view-share">
					<label>
						{ml('Total Job Views', currentUser, allMultiLingualData)}
					</label>
					<p> {views} </p>
				</div>
			</div>
		</div>
	);
}
