import { css } from 'emotion';
import get from 'lodash/get';
import { COLORS } from '../../styles/colors';

export const ModalContainer = css({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
});

export const ModalTitle = css({
	width: '100%',
	textAlign: 'center',
	color: COLORS.red,
	fontSize: 32,
	marginBottom: 30,
	fontWeight: 600,
});

export const ModalSubTitle = css({
	width: '100%',
	textAlign: 'center',
	fontSize: 18,
	marginBottom: 30,
	fontWeight: 600,
});

export const SubmitBtn = (theme) => {
	let buttonColor = COLORS.red;
	if (get(theme, 'enabled')) {
		buttonColor = get(theme, 'buttonColor', buttonColor);
	}

	return css({
		fontFamily: 'Open Sans',
		backgroundColor: buttonColor,
		color: COLORS.white,
		fontSize: 20,
		fontWeight: 300,
		padding: '10px 30px',
		height: 'auto',
		border: 'none',
		marginBottom: 0,
		borderRadius: 5,
		'&:hover': {
			backgroundColor: buttonColor,
			color: COLORS.white,
		},
		'&:focus': {
			backgroundColor: buttonColor,
			color: COLORS.white,
		},
	});
};

export const SubmitBtnContainer = css({
	width: '100%',
	display: 'flex',
	justifyContent: 'center',
	marginBottom: 0,
	'& .ant-form-item-control-wrapper': {
		'@media (max-width: 575px)': {
			width: 'auto',
		},
	},
});

export const CheckIcon = css({
	borderRadius: 100,
	border: '1px solid white',
	padding: 2,
});

export const AlertError = css({
	marginBottom: 20,
});

export const FormStyles = css({
	width: '70%',
});
