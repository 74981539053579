export const updateCompany = `mutation UpdateCompany($input: UpdateCompanyInput!) {
  updateCompany(input: $input) {
    id
    name
    defaultBonusAmount
    contactIncentiveBonus
    brandColor
    accountType
    loginFormPickerSettings
    loginFormSettings
    logo {
      bucket
      region
      key
      __typename
    }
    shareLogo {
      bucket
      region
      key
      __typename
    }
    dashboardSearchBG {
      bucket
      region
      key
      __typename
    }
    websiteUrl
    sendAdminNotificationsOnReferral
    sendAdminNotificationsOnInternalCandidatesFound
    enableAcceptedReferralReminder
    referralBonusWaitingPeriod
    referralBonusNotificationRecipients
    dashboardReferralPolicyText
    bonusEarnedNote
    allowSelfReferrals
    allowInternalMobility
    internalMobilityImage {
      bucket
      region
      key
    }
    employeeDashboard
    employeeDashboardRows
    emailSettings
    ignoreSubcompany
    enableGenericReferralEmail
    enableLead
    hideSubcompanyOnSignup
    hideSubcompanyOnJobs
    sendReferralColor
    applyInternallyColor
    generalReferralColor
    referralLeadColor
    defaultLocation
    disableSmartReferrals
    disableSAMLLogin
    disableClaimYourAccountLogin
    emailSettings
    confirmContactEmails
    confirmCompliance
    ssoGoogleDomain
    theme
    symbol {
      bucket
      region
      key
      __typename
    }
    background {
      bucket
      region
      key
      __typename
    }
    errorImage {
      bucket
      region
      key
      __typename
    }
    whiteLabel
    userEditSettings
    stages
    enableGeneralReferrals
    enableProspectCreation
    enableRetroactiveBonuses
    enableRecognition
    disableManagerPermissions
    disableManagerPermissionsByType
    hideBonus
    enableDashboardSearch
    hideDateCreatedJobDetails
    hideRecognitionSocialShare
    hideRecognitionLeaderboard
    recognitionCustomTitle
    referralStatus
    referralCustomStatuses
    requireBadgeSelection
    requireBonusChangeReason
    allowSelfReferralsInternalLink
    disableReferrals
    automationSettings
    platformConfig
    ownershipSettings
    bonusReportSetting
    includeAdminInRanking
    senderEmailAddress
    whiteLabelServiceName
    eligibiltyChecks
    enableCustomPage
    enableCustomPage2
    enableCustomPage3
    publicLeaderboard
    hideReferralsRanking
    giftCardStoreAPIKeys {
      apiKey
      region
    }
    giftCardStoreBalance
    giftCardStorePendingBalance
    customPageTitle
    customPageTitle2
    customPageTitle3
    pointsSettings
    popupTitle
    popupTitleContent
    hideWhatsApp
    defaultToSubcompany
    hideMessageRecruiter
    hidePaymentInfoJobCard
    hideRecruiterInfo
    hideRecruiterInfoJobDetails
    internalMobilityCustomTitle
    appStoreUrls{
      ios
      android
    }
    onboarding
    partnerBillingPolicy
    partnerClientId
    partnerHostId
    payBonusesWithPoints
    enableReferralNetworkShare
    referralNetworkShareOptions
    enableAppReview
    referralsReportFrequency
    referralsReportDateRange
    employeeReportFrequency
    bonusReportFrequency
    countryCode
    analyticsCardInputValues
    customText
  }
}
`;
