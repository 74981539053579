export const MANAGE_REWARD_ENDPOINTS = {
	BADGES: 'badges',
	ICONS: 'icons',
	BADGE: 'badge',
	BADGE_TYPE: 'badge-types',
};

export const AUTOMATED_REWARDS = [
	{ value: '1 year Work Milestone', label: '1 year Work Milestone' },
	{ value: '2 year Work Milestone', label: '2 year Work Milestone' },
	{ value: '3 year Work Milestone', label: '3 year Work Milestone' },
	{ value: '5 year Work Milestone', label: '5 year Work Milestone' },
	{ value: '10 year Work Milestone', label: '10 year Work Milestone' },
	{ value: '1st referral', label: '1st referral' },
	{ value: '5 referrals in a month', label: '5 referrals in a month' },
];

export const BADGE_VISIBILITY = [
	{ value: true, label: 'Active' },
	{ value: false, label: 'Inactive' },
];

export const BADGE_APPROVAL = [
	{ value: false, label: 'Yes' },
	{ value: true, label: 'No' },
];
export const BADGE_TYPE = {
	PEERTOPEER: 'On-the-Spot',
	NOMINATED: 'Nominated',
	AUTOMATED: 'Anniversary',
}

export const MAPPED_BADGE_TYPE_1 = {
	PEERTOPEER: '75b30c17-5617-4103-b781-d62b1d8a3fa7',
	NOMINATED: '2d05cd35-12dd-4c6e-b099-5221e026d680',
	AUTOMATED: '449e1150-ddac-485d-8321-537705c5bb40',
}

export const REVERSE_MAPING_BADGE_TYPE = {
	'75b30c17-5617-4103-b781-d62b1d8a3fa7': "PEERTOPEER",
	'2d05cd35-12dd-4c6e-b099-5221e026d680': "NOMINATED",
	'449e1150-ddac-485d-8321-537705c5bb40': "AUTOMATED",
}

export const REVERSE_MAPING_BADGE_ICON = {
	'd2b33bdd-f090-42ad-bb2a-432f6bcc615b': 'WRENCH',
	'741c3ac4-c5a5-41ec-b9fb-b0c42a0f3b73': 'TROPHY',
	'6711677e-832c-444e-868d-7f0b62274e61': 'USERS',
	'a68a23d6-4543-443c-b6fe-f12ac810cedf': 'STAR',
	'89882e76-ea17-4ceb-b169-b0bc56c249c8': 'ROCKET',
	'a6a56a9b-54ea-4971-a5be-26f442f8c5ca': 'WAND',
	'3b2a3eb3-7a1b-4b3f-b585-08a84d3364c7': 'THUMBSUP',
	'663f098c-f49c-4384-ad6f-f59f15abc026': 'HANDSHAKE',
	'250af36e-c073-4140-9967-659558e96fdd': 'HANDPLUS',
	'7b3eea86-a180-4846-ad04-25e5088c7847': 'PUZZLE',
	'c5cd07ae-d6b1-4ec4-8ae1-d0faa9595221': 'PIPERHAT',
	'abf3ede4-f9a5-40f5-b3ff-e26624dc2d1f': 'PEOPLEPULLING',
	'b7f38f46-d835-4a7a-bf41-d75ab1d1e19b': 'PEOPLECARRYING',
	'bafa0cfb-2ddd-43ca-b954-4814c09b85a1': 'MUG',
	'f45916fc-b22a-4304-8b01-687adfd0ce96': 'MEDAL',
	'01f7790e-e7da-4ea3-806f-f9459bf2565b': 'LIGHTBULB',
	'7252331b-e81f-4c41-a57b-fd762bc617b9': 'HEART',
	'962a7220-5c4d-4730-bea0-e56e9f9fb9ac': 'AWARD',
	'f734ab47-7f05-43c1-ae4b-92a806b91726': 'HANDHEART',
	'4a20bb22-3faa-4fcc-9e8b-e507c22ae904': 'GIFT',
	'7c1dbb3f-67e6-45df-8924-54af00fa1933': 'FIRE',
	'a75409c4-fe4c-417a-b506-77f67313d891': 'DUMBELL',
	'f12b7223-b2f3-4351-95d8-c7f218e71657': 'CROWN',
	'fcdb094a-a59c-41d4-a540-293c80f1ccd1': 'BRUSH',
	'c98f6802-c79b-4d32-a13b-b65fc19d7258': 'BOLT',
	'c4794131-7cb5-46ac-87cb-b6b7be3eb3a5': 'BOLT',
	'cc4c300f-99ca-4f91-ac4f-14c12511a2c3': 'COMMENTS',
}

export const MAPPED_BADGE_ICON_1 = {
	WRENCH: 'd2b33bdd-f090-42ad-bb2a-432f6bcc615b',
	TROPHY: '741c3ac4-c5a5-41ec-b9fb-b0c42a0f3b73',
	USERS: '6711677e-832c-444e-868d-7f0b62274e61',
	STAR: 'a68a23d6-4543-443c-b6fe-f12ac810cedf',
	ROCKET: '89882e76-ea17-4ceb-b169-b0bc56c249c8',
	WAND: 'a6a56a9b-54ea-4971-a5be-26f442f8c5ca',
	THUMBS_UP: '3b2a3eb3-7a1b-4b3f-b585-08a84d3364c7',
	HANDSHAKE: '663f098c-f49c-4384-ad6f-f59f15abc026',
	HAND_PLUS: '250af36e-c073-4140-9967-659558e96fdd',
	PUZZLE: '7b3eea86-a180-4846-ad04-25e5088c7847',
	PIPER_HAT: 'c5cd07ae-d6b1-4ec4-8ae1-d0faa9595221',
	PEOPLE_PULLING: 'abf3ede4-f9a5-40f5-b3ff-e26624dc2d1f',
	PEOPLE_CARRYING: 'b7f38f46-d835-4a7a-bf41-d75ab1d1e19b',
	MUG: 'bafa0cfb-2ddd-43ca-b954-4814c09b85a1',
	MEDAL: 'f45916fc-b22a-4304-8b01-687adfd0ce96',
	LIGHT_BULB: '01f7790e-e7da-4ea3-806f-f9459bf2565b',
	HEART: '7252331b-e81f-4c41-a57b-fd762bc617b9',
	AWARD: '962a7220-5c4d-4730-bea0-e56e9f9fb9ac',
	HAND_HEART: 'f734ab47-7f05-43c1-ae4b-92a806b91726',
	GIFT: '4a20bb22-3faa-4fcc-9e8b-e507c22ae904',
	FIRE: '7c1dbb3f-67e6-45df-8924-54af00fa1933',
	DUMBELL: 'a75409c4-fe4c-417a-b506-77f67313d891',
	CROWN: 'f12b7223-b2f3-4351-95d8-c7f218e71657',
	BRUSH: 'fcdb094a-a59c-41d4-a540-293c80f1ccd1',
	BOLT: 'c98f6802-c79b-4d32-a13b-b65fc19d7258',
	BELL: 'c4794131-7cb5-46ac-87cb-b6b7be3eb3a5',
	COMMENTS: 'cc4c300f-99ca-4f91-ac4f-14c12511a2c3',
}

export const MAPPED_BADGE_TYPE = {
	PEERTOPEER: 'PEERTOPEER',
	NOMINATED: 'NOMINATED',
	AUTOMATED: 'AUTOMATED',
}

export const MAPPED_BADGE_ICON = {
	WRENCH: 'WRENCH',
	TROPHY: 'TROPHY',
	USERS: 'USERS',
	STAR: 'STAR',
	ROCKET: 'ROCKET',
	WAND: 'WAND',
	THUMBS_UP: 'THUMBSUP',
	HANDSHAKE: 'HANDSHAKE',
	HAND_PLUS: 'HANDPLUS',
	PUZZLE: 'PUZZLE',
	PIPER_HAT: 'PIPERHAT',
	PEOPLE_PULLING: 'PEOPLEPULLING',
	PEOPLE_CARRYING: 'PEOPLECARRYING',
	MUG: 'MUG',
	MEDAL: 'MEDAL',
	LIGHT_BULB: 'LIGHTBULB',
	HEART: 'HEART',
	AWARD: 'AWARD',
	HAND_HEART: 'HANDHEART',
	GIFT: 'GIFT',
	FIRE: 'FIRE',
	DUMBELL: 'DUMBELL',
	CROWN: 'CROWN',
	BRUSH: 'BRUSH',
	BOLT: 'BOLT',
	BELL: 'BELL',
	COMMENTS: 'COMMENTS',
	ANCHOR: 'ANCHOR',
	BINOCULARS: 'BINOCULARS',
	BOOK_OPEN_READER: 'BOOK_OPEN_READER',
	BOOK: 'BOOK',
	BRAIN: 'BRAIN',
	BULLHORN: 'BULLHORN',
	BULLSEYE: 'BULLSEYE',
	CAKE_CANDLES: 'CAKE_CANDLES',
	CERTIFICATE: 'CERTIFICATE',
	CHALKBOARD_USER: 'CHALKBOARD_USER',
	CHAMPAGNE_GLASSES: 'CHAMPAGNE_GLASSES',
	CHART_LINE: 'CHART_LINE',
	FACE_SMILE: 'FACE_SMILE',
	FLAG_CHECKERED: 'FLAG_CHECKERED',
	FLASK: 'FLASK',
	GEARS: 'GEARS',
	GEM: 'GEM',
	GRADUATION_CAP: 'GRADUATION_CAP',
	HAT_WIZARD: 'HAT_WIZARD',
	HOUSE: 'HOUSE',
	KEY: 'KEY',
	LIST_CHECK: 'LIST_CHECK',
	MAGNIFYING_GLASS: 'MAGNIFYING_GLASS',
	MOUNTAIN: 'MOUNTAIN',
	NETWORK_WIRED: 'NETWORK_WIRED',
	PEN: 'PEN',
	RIBBON: 'RIBBON',
	SCREWDRIVER_WRENCH: 'SCREWDRIVER_WRENCH',
	SEEDLING: 'SEEDLING',
	SHIELD_HALVED: 'SHIELD_HALVED',
	STOPWATCH: 'STOPWATCH',
	SUITCASE_MEDICAL: 'SUITCASE_MEDICAL',
	SUN: 'SUN',
	THUMBTACK: 'THUMBTACK',
	TICKET: 'TICKET',

}

export const SSO_Required_CONSTANTS = {
	STANDARD: "Standard",
	SSO: "SSO"
}