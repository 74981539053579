import gql from 'graphql-tag';

export const queryContactsByCompanyIdIndex = gql`
	query QueryContactsByCompanyIdIndex(
		$companyId: ID!
		$first: Int
		$after: String
	) {
		queryContactsByCompanyIdIndex(
			companyId: $companyId
			first: $first
			after: $after
		) {
			items {
				id
				firstName
				lastName
				emailAddress
				userId
				companyId
				phoneNumber
				importMethod
				fullContactData
				dateCreated
				onDeckStatus
				onDeckNote
				onDeckDate
			}
			nextToken
		}
	}
`;
