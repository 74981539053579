import { Radio } from 'antd';

export function Tab({
	id,
	index,
	selectedTab,
	tabPanelId,
	title,
	handleChange,
	tabRef,
	...props
}) {
	const handleClick = () => handleChange(index);
	return (
		<Radio.Button
			ref={tabRef}
			className="tab-item"
			role="tab"
			id={id}
			aria-selected={selectedTab === index}
			aria-controls={tabPanelId}
			tabIndex={selectedTab === index ? 0 : -1}
			onClick={handleClick}
			{...props}
			value={index}
		>
			{title}
		</Radio.Button>
	);
}
