import React from "react";

import CustomCards from "./CutomCards.jsx";

import './style.scss'

const RewardPointsAndBonus = ({ title, summaryDetals }) => {
  return (
    <div className='rewards-points d-card height-equal'>
      <div className='d-card-head'>
        <h4 className="d-title-no-case">{title}</h4>
      </div>
      <div className='reward-point-boxes'>
        {summaryDetals?.map((props, index) =>
          <span key={index}>
            <CustomCards
              customCardsClassName="send-points"
              {...props}
            />
          </span>)}
      </div>
    </div>
  )
};

export default RewardPointsAndBonus;