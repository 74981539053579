import MyrewardsContainer from './container/myrewardsContainer'
import { MyRewardsServiceProvider } from './service/MyRewards.service.jsx';
import { useSelector } from "react-redux";
import { Spinner } from "../_shared/index.js";

const Myrewards = () => {
  const company = useSelector((state) => state.user.currentUser.company)
  if (!company) return <Spinner />
  return <MyRewardsServiceProvider><MyrewardsContainer /></MyRewardsServiceProvider>
};

export default Myrewards;
