import gql from 'graphql-tag';
import { compose, graphql } from 'react-apollo';
import {
	GetEmployeeByIdDetails as GetEmployeeById,
	updateUserLanguage,
} from '../../graphql/custom/users';

export const withUserByIdUpdateLanguage = (Component, variables) => {
	return compose(
		graphql(gql(updateUserLanguage), {
			props: (props) => ({
				onUpdate(input) {
					const optimisticResponseData = {
						...props.ownProps.currentUser,
						...input.input,
					};
					props.mutate({
						variables: input,
						optimisticResponse: {
							__typename: 'Mutation',
							updateUser: {
								...optimisticResponseData,
								__typeName: 'updateUser',
							},
						},
					});
				},
			}),
		})
	)(Component);
};
