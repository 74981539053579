export const COLORS = {
	close: '#8c8c8c',
	heading: '#444444',
	text: '#444444',
	subHeading: '#8d99a3',
	hyperLink: '#018dd3',
	white: '#ffffff',
	black: '#000000',
	red: '#ef3c3f',
	blue: '#018dd3',
	darkBlue: '#242e3f',
	green: '#47B549',
	lightGreen: '#bdf9bd',
	darkGray: '#404447',
	lightGray: '#8d99a3',
	lightGray2: '#eae9ef',
	lightGray3: '#cfcfcf',
	lightGrayBackground: '#f7f8fa',
	dashboardGreen: '#19bb4b',
	dashboardBlue: '#62a7ff',
	dashboardDarkOrange: '#8e4b01',
	dashboardLightOrange: '#f7a34d',
	gray: '#888888',
	lightGray4: '#999999',
	lightGray5: '#808e98',
	lightGray6: '#818e98',
	lightGray7: '#b1b1b1',
	lightGray8: '#E0E0E0',
	gray2: '#838e97',
	gray3: '#8f99a2',
	grayMenu: '#727d91',
	darkBlue2: '#00295d',
	inputActiveBackground: '#8f8f8f',
	inputActiveColor: '#ffffff',
	inputDisableBackground: '#d1d1d1',
	inputDisableColor: '#999999',
	commonGreen: '#1ef202',
	rareBlue: '#0560b6',
	epicPurple: '#732fc2',
	purple: '#7942e7',
	placeholderGray: '#BFBFBF',
	osloGray: '#95979a',
	transparent: '#00000000',
};
