export const deleteReferral = `mutation DeleteReferral($input: DeleteReferralInput!) {
  deleteReferral(input: $input) {
    id
    companyId
    company {
      id
      name
      defaultBonusAmount
      contactIncentiveBonus
      websiteUrl
      sendAdminNotificationsOnReferral
      sendAdminNotificationsOnInternalCandidatesFound
      referralBonusWaitingPeriod
      referralBonusNotificationRecipients
      dateCreated
      users {
        id
        cognitoId
        companyId
        emailAddress
        firstName
        lastName
        title
        departmentId
        lastLogin
        lastNotificationCheck
        contacts {
          id
          firstName
          lastName
          emailAddress
          socialMediaAccounts
          userId
          companyId
          phoneNumber
          jobHistory
          fullContactData
          dateCreated
        }
        totalReferrals
        webNotifications {
          id
          userId
          requestingUserId
          contactId
          referralId
          jobId
          matches
          message
          dateCreated
        }
        connectedApps
        active
        createdById
        dateCreated
        
        
        ytdReferralCount
      }
    }
    contactId
    contact {
      id
      firstName
      lastName
      emailAddress
      socialMediaAccounts
      userId
      companyId
      phoneNumber
      jobHistory
      fullContactData
      dateCreated
    }
    userId
    user {
      id
      cognitoId
      companyId
      emailAddress
      firstName
      lastName
      title
      departmentId
      lastLogin
      lastNotificationCheck
      contacts {
        id
        firstName
        lastName
        emailAddress
        socialMediaAccounts
        userId
        companyId
        phoneNumber
        jobHistory
        fullContactData
        dateCreated
      }
      totalReferrals
      webNotifications {
        id
        userId
        requestingUserId
        contactId
        referralId
        jobId
        matches
        message
        dateCreated
      }
      connectedApps
      active
      createdById
      dateCreated
      
      
      ytdReferralCount
    }
    jobId
    job {
      id
      companyId
      departmentId
      title
      description
      publicLink
      salary
      location
      hiringManagerId
      createdById
      referralBonus
      shares
      views
      referrals {
        id
        companyId
        contactId
        userId
        jobId
        note
        message
        referralDate
        referralBonusNotificationSent
        hireDate
      }
      matches {
        id
        firstName
        lastName
        emailAddress
        socialMediaAccounts
        userId
        companyId
        phoneNumber
        jobHistory
        fullContactData
        dateCreated
      }
      dateCreated
    }
    status
    note
    message
    referralDate
    referralBonusNotificationSent
    hireDate
  }
}
`;
