import { connect } from 'react-redux';
import { withGetUserNotifications } from 'src/_shared/api/components/users/';
import { compose } from '../../_shared/services/utils.js';
import WebNotificationsPageComponent from './WebNotificationsPageComponent.jsx';

const mapStateToProperties = (state) => {
	const { currentUser } = state.user;
	return {
		currentUser,
		id: currentUser.id,
	};
};

const WebNotificationsWithApi = compose(withGetUserNotifications)(
	WebNotificationsPageComponent
);

export const WebNotificationsPage = connect(mapStateToProperties)(
	WebNotificationsWithApi
);
