import { CloseCircleOutlined } from '@ant-design/icons';
import { Popconfirm, Row } from 'antd';
import {
	CategoriesContainer,
	categoryContainer,
	categoryText,
	closeIcon,
} from './categoryItemStyles.js';

export function CategoryItem(props) {
	const inactivateCategory = (index) => {
		const newCategories = props.categories;
		newCategories.splice(index, 1);
		props.callback(newCategories);
	};

	return (
		<div className={CategoriesContainer}>
			{props.categories
				? props.categories.map((category, index) => {
						return (
							<div key={index}>
								<Row
									className={categoryContainer}
									style={{
										display: 'flex',
										alignItems: 'center',
									}}
								>
									<span className={categoryText}> {category} </span>
									<Popconfirm
										placement="left"
										title="Are you sure？"
										okText="Delete"
										cancelText="Cancel"
										onConfirm={() => inactivateCategory(index)}
									>
										<CloseCircleOutlined className={closeIcon} />
									</Popconfirm>
								</Row>
							</div>
						);
					})
				: null}
		</div>
	);
}
