import { css } from 'emotion';
import { COLORS } from '../../_shared/styles/colors';

export const addCompanyLabel = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 600,
	color: COLORS.heading,
	fontSize: '1.5em',
	marginBottom: 10,
});

export const titleStyle = css({
	fontFamily: '"Open Sans", sans-serif',
	marginLeft: 10,
	fontWeight: 500,
	color: COLORS.heading,
});

export const inputContainer = css({
	'&:selected': {
		borderColor: COLORS.red,
	},
	'&:focus': {
		borderColor: COLORS.red,
	},
	'&:hover': {
		borderColor: COLORS.red,
	},
	borderRadius: 30,
	width: '100%',
});

export const InputStyles = css({
	'& .ant-form-explain': {
		fontSize: 12,
		marginLeft: 10,
		marginTop: 2,
	},
	'& .ant-form-item-control': {
		lineHeight: 2,
	},
});
