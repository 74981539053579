export const queryWebNotificationsByUserIdIndex = `query QueryWebNotificationsByUserIdIndex(
  $userId: ID!
  $first: Int
  $after: String
) {
  queryWebNotificationsByUserIdIndex(
    userId: $userId
    first: $first
    after: $after
  ) {
    items {
      id
      type
      status
      user {
        id
        userGroupId
        company {
          id
          name
        }
        subCompany {
          id
          name
        }
        avatar {
          bucket
          region
          key
        }
        firstName
        lastName
      }
      referralId
      referral {
        id
        user {
          id
          firstName
          lastName
          incentiveEligible
        }
        contact {
          id
          firstName
          lastName
        }
      }
      jobId
      matches
      message
      job {
        id
        title
        createdBy {
          id
          firstName
          lastName
        }
        departmentId
        department {
          id
          name
        }
        location
        referrals {
          id
          contactId
        }
        referralBonus
        tieredBonus {
          id
          name
          tiers
        }
        subCompany {
          id
          name
        }
      }
      dateCreated
      contactId
      contact {
        id
        firstName
        lastName
        emailAddress
        socialMediaAccounts
      }
      referralRequestedStatus
      requestingUserId
      requestingUser {
        id
        firstName
        lastName
        avatar {
          bucket
          region
          key
        }
      }
      questionsData
    }
    nextToken
  }
}
`;
