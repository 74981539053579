export const queryDepartmentsByCompanyIdIndex = `query QueryDepartmentsByCompanyIdIndex(
  $companyId: ID!
  $first: Int
  $after: String
) {
  queryDepartmentsByCompanyIdIndex(
    companyId: $companyId
    first: $first
    after: $after
  ) {
    items {
      id
      companyId
      name
      keywords
      active
      totalUsers
    }
    nextToken
  }
}
`;
