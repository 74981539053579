import React, { useState, useEffect } from 'react';
import ApprovalBonusListPresentation from '../presentation/ApprovalBonusList.jsx';
import { message } from 'antd';
import { useApprovalBonusService } from '../service/ApprovalBonus.service.jsx';

const ApprovalBonusListContainer = () => {
    const [approvalBonus, setapprovalBonus] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [approvalCount, setApprovalCount] = useState()
    const approvalBonusAPIService = useApprovalBonusService();

    useEffect(() => {
        getApprovalBonusList();
        getApprovalRewardCount()
    }, []);

    const getApprovalBonusList = async () => {
        try {
            await approvalBonusAPIService.getApprovalBonusesList()
                .then((response) => {
                    if (response?.code === 200) {
                        if (response?.data) {
                            setapprovalBonus(response?.data);
                            setIsLoading(false);
                        }
                    } else {
                        message.error('Failed to fetch badge details! Try again later.');
                    }
                });
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const getApprovalRewardCount = async () => {
        approvalBonusAPIService.getApprovalRewardsCount()
            .then((response) => {
                if (response?.code === 200) {
                    if (response?.data) {
                        setApprovalCount(response?.data);
                    }
                } else {
                    message.error('Failed to fetch pending list');
                }
            })
            .catch((error) => {
                console.log(error);
                message.error('Failed to fetch pending list');
            });
    };

    return (
        <>
            <ApprovalBonusListPresentation
                approvalBonus={approvalBonus}
                isLoading={isLoading}
                approvalCount={approvalCount}
            />
        </>
    );
};

export default ApprovalBonusListContainer;
