import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Col, Input, Row } from 'antd';
import get from 'lodash/get';
import { forwardRef, useMemo, useState } from 'react';
import Autocomplete from 'react-autocomplete';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';
import { COLORS } from 'src/_shared/styles/colors';
import { filterPhoneNumber, ml, sanitize } from '../../services/utils';
import { ToggleEmailPhone } from './ToggleEmailPhoneComponent.jsx';

// Wrapping react-autocomplete to address initialValue error because antD has hits own state and conflicts with onSelect
const WrappedAutocomplete = forwardRef(
	({ inputProps, menuHeaderText, ...props }, ref) => (
		<Autocomplete
			ref={ref}
			wrapperStyle={{
				width: '100%',
				position: 'relative',
			}}
			inputProps={{
				...inputProps,
				className: 'ant-input custom-input',
			}}
			renderMenu={(items, value, style) => {
				return (
					<div className="custom-input-dropdown">
						<div className="custom-input-dropdown-text">{menuHeaderText}</div>
						{items}
					</div>
				);
			}}
			{...props}
		/>
	)
);

function ReferralFormAddContactFields(props) {
	const {
		currentUser,
		allMultiLingualData,
		referralType,
		changeReferralType,
		selectedContact,
		handleSelectContact,
		handleClearSelected,
		handleViewContact,
	} = props;

	const [countryCode, setCountryCode] = useState(
		// TODO: stop casting to lower case after removing react-phone-input-2
		(currentUser?.company?.countryCode ?? 'US').toLowerCase()
	);

	const handleCountryChange = (country) => {
		// TODO: stop casting to lower case after removing react-phone-input-2
		setCountryCode((country?.countryCode ?? 'us').toLowerCase());
	};

	// Get the values of form for useMemo
	// For each field, useMemo filters all contacts first, so it's not taking place each keypress in autocomplete
	// Makes sure a contact includes a first and last and either an email or phone number
	const contacts = props.contacts ? props.contacts : [props.contact]; // If accessing from Referral Network page, restricts form to single contact

	const firstNameContacts = useMemo(
		() =>
			contacts.filter(
				(contact) =>
					contact.firstName &&
					contact.lastName &&
					(contact.emailAddress || contact.phoneNumber) &&
					contact.firstName
						.toLowerCase()
						.includes(
							sanitize(
								props.form.getFieldValue('firstName') || ''
							).toLowerCase()
						)
			),
		[sanitize(props.form.getFieldValue('firstName') || ''), contacts]
	);

	const { getFieldDecorator, getFieldValue } = props.form;
	const FormItem = Form.Item;

	if (selectedContact) {
		return (
			// When contact is selected, display in disabled input, with an option to remove and set selectedContact to null
			<>
				<h4 className="ant-modal-sub-title text-center">
					{ml('Refer a Contact', currentUser, allMultiLingualData)}
				</h4>
				<Row gutter={12}>
					<Col xs={24}>
						<div className="custom-form-group d-flex align-items-center">
							<FormItem className="w-100">
								<Input
									readOnly
									className="custom-input"
									value={`${get(selectedContact, 'firstName')} ${get(
										selectedContact,
										'lastName'
									)}`}
								/>
							</FormItem>
							{!get(props, 'contact') && (
								<i
									className="icon-bin text-danger ml-2 cursor-p"
									onClick={() => {
										handleClearSelected();
									}}
								/>
							)}
						</div>
					</Col>
				</Row>
				<Row gutter={12}>
					{/* If selectedContact has both an email and a phone number, give user option to selected contact method */}
					<Col xs={24}>
						<div className="custom-form-group">
							<label className="custom-label">
								{ml('Send By', currentUser, allMultiLingualData)}
							</label>
							<div className="send-by">
								<ToggleEmailPhone
									visible
									allMultiLingualData={allMultiLingualData}
									contact={selectedContact}
									currentUser={currentUser}
									referralType={referralType}
									sendByText={ml('Send By', currentUser, allMultiLingualData)}
									inputLabel={false}
									onClick={(referralType) => {
										changeReferralType(referralType);
									}}
								/>
								{/* Display the email or phone number in disabled input */}
								<div className="custom-form-group">
									<FormItem>
										<Input
											readOnly
											className="custom-input"
											value={
												referralType === 'email' && selectedContact.emailAddress
													? selectedContact.emailAddress
													: referralType === 'text' &&
														selectedContact.phoneNumber
															? selectedContact.phoneNumber
															: null
											}
										/>
									</FormItem>
								</div>
							</div>
						</div>
					</Col>
				</Row>
			</>
		);
	}

	// Display Fields for entering a new contact
	// Wrapped react-autoComplete in order to pass props to limit option limit to prevent freezing
	return (
		<>
			<h4 className="ant-modal-sub-title" style={{ color: '#000000' }}>
				{ml('Enter Referral Information', currentUser, allMultiLingualData)}
			</h4>
			<Row gutter={12}>
				<Col xs={24} md={12}>
					<div className="custom-form-group">
						<FormItem>
							{getFieldDecorator('firstName', {
								rules: [
									{
										required: true,
										message: ml('Required', currentUser, allMultiLingualData),
									},
								],
							})(
								<WrappedAutocomplete
									shouldItemRender={(contact) => {
										return firstNameContacts.some(
											(singleContact) =>
												singleContact.firstName === contact.firstName
										);
									}}
									items={contacts}
									sortItems={(a, b) => {
										const firstNameA = a.firstName.toLowerCase();
										const firstNameB = b.firstName.toLowerCase();

										if (firstNameA < firstNameB) return -1;
										if (firstNameA > firstNameB) return 1;
										return 0;
									}}
									getItemValue={(contact) => contact.firstName}
									inputProps={{
										placeholder: ml(
											'First Name',
											currentUser,
											allMultiLingualData
										),
									}}
									menuHeaderText="Refer an existing contact:"
									renderItem={(contact, isHighlighted, styles) => (
										<div
											key={contacts.indexOf(contact)}
											className={`custom-input-dropdown-item ${
												isHighlighted ? 'hovered' : ''
											}`}
										>
											{`${contact.firstName} ${contact.lastName} `}
											{contact.emailAddress && `(${contact.emailAddress})`}{' '}
											&nbsp;
											{contact.phoneNumber &&
												`(${contact.phoneNumber.replaceAll(/[^\d+]/g, '')})`}
										</div>
									)}
									onSelect={(value, contact) => handleSelectContact(contact)}
								/>
							)}
						</FormItem>
					</div>
				</Col>
				<Col xs={24} md={12}>
					<div className="custom-form-group">
						<FormItem>
							{getFieldDecorator('lastName', {
								rules: [
									{
										required: true,
										message: ml('Required', currentUser, allMultiLingualData),
									},
								],
							})(
								<Input
									className="custom-input"
									placeholder={ml(
										'Last Name',
										currentUser,
										allMultiLingualData
									)}
								/>
							)}
						</FormItem>
					</div>
				</Col>
			</Row>
			<Row gutter={12}>
				<Col xs={24}>
					<div className="custom-form-group" style={{marginBottom: 0}}>
						<div className="send-by">
							<ToggleEmailPhone
								visible
								referralType={referralType}
								sendByText={ml('Send By', currentUser, allMultiLingualData)}
								currentUser={currentUser}
								allMultiLingualData={allMultiLingualData}
								inputLabel={false}
								onClick={(referralType) => {
									changeReferralType(referralType);
								}}
							/>
							{referralType === 'email' || referralType === null ? (
								<div className="custom-form-group">
									<FormItem>
										{getFieldDecorator('emailAddress', {
											rules: [
												{
													required: true,
													message: ml(
														'Required',
														currentUser,
														allMultiLingualData
													),
												},
												{
													pattern:
														/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[(?:\d{1,3}\.){3}\d{1,3}])|(([a-zA-Z\-\d]+\.)+[a-zA-Z]{2,}))$/g,
													message: ml(
														'Invalid Email',
														currentUser,
														allMultiLingualData
													),
												},
												{
													// If email input matches an existing contact, require user to select that contact
													validator(rule, value, callback) {
														if (
															contacts.some(
																(contact) =>
																	(contact &&
																		contact.emailAddress &&
																		contact.emailAddress.toLowerCase()) ===
																		value || ''.toLowerCase()
															)
														) {
															const contact = contacts.find(
																(contact) =>
																	(contact &&
																		contact.emailAddress &&
																		contact.emailAddress.toLowerCase()) ===
																		value || ''.toLowerCase()
															);
															return callback(
																<>
																	{ml(
																		'A contact with this email already exists',
																		currentUser,
																		allMultiLingualData
																	)}
																	,&nbsp;
																	<Button
																		type="link"
																		style={{
																			margin: 0,
																		}}
																		onClick={() => handleSelectContact(contact)}
																	>
																		{ml(
																			'click here',
																			currentUser,
																			allMultiLingualData
																		)}
																	</Button>
																	&nbsp;{' '}
																	{ml(
																		'to refer or',
																		currentUser,
																		allMultiLingualData
																	)}{' '}
																	&nbsp;
																	<a
																		type="link"
																		href={`/mycontacts/${contact.id}`}
																		onClick={(event) => {
																			event.preventDefault();
																			handleViewContact(contact);
																		}}
																	>
																		{ml(
																			'view',
																			currentUser,
																			allMultiLingualData
																		)}
																	</a>
																	&nbsp;{' '}
																	{ml(
																		'the contact',
																		currentUser,
																		allMultiLingualData
																	)}
																</>
															);
														}

														callback();
													},
												},
											],
										})(
											<Input
												className="custom-input"
												placeholder={ml(
													'Email Address',
													currentUser,
													allMultiLingualData
												)}
											/>
										)}
									</FormItem>
								</div>
							) : (
								<div className="custom-form-group">
									<FormItem>
										{getFieldDecorator('phoneNumber', {
											getValueFromEvent(value) {
												return value.replaceAll(/[^\d+]/g, '');
											},
											rules: [
												{
													required: true,
													message: ml(
														'Required',
														currentUser,
														allMultiLingualData
													),
												},
												{
													validator(rule, value, callback) {
														const regex = /\d{10,}/;
														if (value.slice(0, 2) === '55') {
															return callback(
																ml(
																	'SMS referrals to Brazil are not supported at this time. Please use email or social media to refer your contact.',
																	currentUser,
																	allMultiLingualData
																)
															);
														}

														if (!regex.test(value)) {
															return callback(
																ml(
																	'Invalid Number',
																	currentUser,
																	allMultiLingualData
																)
															);
														}

														return callback();
													},
												},
												{
													validator(rule, value, callback) {
														// Normalize phone number, and check to see if this contact already exists

														const contact = contacts.find(
															(contact) =>
																filterPhoneNumber(contact.phoneNumber) === value
														);
														if (contact) {
															return callback(
																<>
																	{ml(
																		'A contact with this phone number already exists',
																		currentUser,
																		allMultiLingualData
																	)}
																	,&nbsp;
																	<Button
																		type="link"
																		style={{
																			color: '#018dd3',
																			fontWeight: '600',
																			padding: 0,
																		}}
																		onClick={() => handleSelectContact(contact)}
																	>
																		{ml(
																			'click here',
																			currentUser,
																			allMultiLingualData
																		)}
																	</Button>
																	&nbsp;{' '}
																	{ml(
																		'to refer or',
																		currentUser,
																		allMultiLingualData
																	)}{' '}
																	&nbsp;
																	<a
																		style={{
																			fontWeight: '600',
																			color: COLORS.blue,
																		}}
																		href={`/mycontacts/${contact.id}`}
																		onClick={(event) => {
																			event.preventDefault();
																			handleViewContact(contact);
																		}}
																	>
																		{ml(
																			'view',
																			currentUser,
																			allMultiLingualData
																		)}
																	</a>
																	&nbsp;{' '}
																	{ml(
																		'the contact',
																		currentUser,
																		allMultiLingualData
																	)}
																</>
															);
														}

														callback();
													},
												},
											],
										})(
											<PhoneInput
												// Styles on parent element with css selectors
												disableCountryGuess
												dropdownStyle={{
													lineHeight: 1,
												}}
												inputProps={{
													className: 'ant-input custom-input',
												}}
												inputStyle={{
													paddingLeft: '50px',
												}}
												country={countryCode}
												masks={{ cn: '... .... ....' }}
												onChange={(value, country, e, formattedValue) => {
													handleCountryChange(country);
												}}
											/>
										)}
									</FormItem>
								</div>
							)}
						</div>
					</div>
				</Col>
			</Row>
		</>
	);
}

export default ReferralFormAddContactFields;
