import {
	CardCvcElement,
	CardExpiryElement,
	CardNumberElement,
	useElements,
	useStripe,
} from '@stripe/react-stripe-js';
import { Button, Input } from 'antd';
import get from 'lodash/get';
import { useState } from 'react';
import Spinner from 'src/_shared/components/spinner/SpinnerComponent.jsx';
import { lambda } from 'src/_shared/services/utils.js';
import { COLORS } from 'src/_shared/styles/colors';
import { calculateStripeFee, getCardBrand } from './stripeFees.js';

function StripeForm(props) {
	const { callback, currentUser, setCurrentUser, updateCurrentUser } = props;
	const [error, setError] = useState({ code: null, message: null });
	const [subTotal, setSubTotal] = useState('0');
	const [total, setTotal] = useState('0');
	const [processingFees, setProcessingFees] = useState('0');
	const [checkout, setCheckout] = useState();
	const [loading, setLoading] = useState(false);
	const [payIntent, setPayIntent] = useState();
	const [payMethod, setPayMethod] = useState();
	const [valid, setValid] = useState(false);
	const [form, _setForm] = useState({
		amount: {},
		number: {},
		expiration: {},
		cvc: {},
	});
	const setForm = (frm) => {
		_setForm(frm);
		isValid(frm);
	};

	const isValid = (frm) => {
		let result = true;
		if (!get(frm, 'amount.complete')) result = false;
		if (!get(frm, 'number.complete')) result = false;
		if (!get(frm, 'expiration.complete')) result = false;
		if (!get(frm, 'cvc.complete')) result = false;
		setValid(result);
		return result;
	};

	const gcsBalance = currentUser?.company?.giftCardStoreBalance
		? currentUser.company.giftCardStoreBalance
		: 0;
	const [giftCardStoreBalance, setGiftCardStoreBalance] = useState(gcsBalance);
	const [giftCardStorePendingBalance, setGiftCardStorePendingBalance] =
		useState(get(currentUser, 'company.giftCardStorePendingBalance', 0));
	const stripe = useStripe();
	const elements = useElements();

	const handleSubmit = async (event) => {
		event.preventDefault();
		if (!stripe || !elements) return;
		try {
			setLoading(true);
			// Create the stripe payment method and send to the stripe-payments-prod-api lambda
			const cardElement = elements.getElement(CardNumberElement);
			let error_ = null;
			const paymentMethodResponse = await stripe.createPaymentMethod({
				type: 'card',
				card: cardElement,
			});
			if (get(paymentMethodResponse, 'error'))
				error_ = paymentMethodResponse.error;
			const paymentMethod = get(paymentMethodResponse, 'paymentMethod');
			if (error_) setError(error_);
			setPayMethod(paymentMethod);
			const endpoint = 'stripe-payments-prod-api';
			const paymentIntentResponse = await lambda({
				endpoint,
				variables: {
					amount: subTotal,
					currency: 'usd',
					userId: get(currentUser, 'id'),
					paymentMethod: get(paymentMethod, 'id'),
					price: total,
					status: 'create-payment-intent',
				},
			});
			const paymentIntent = get(paymentIntentResponse, 'paymentIntent');
			if (get(paymentIntentResponse, 'error'))
				error_ = paymentIntentResponse.error;
			if (error_) setError(error_);
			if (get(paymentIntent, 'status') === 'requires_confirmation') {
				setCheckout('requires_confirmation');
				setPayIntent(paymentIntent);
			}

			setLoading(false);
		} catch (error_) {
			console.log(error_);
		}
	};

	const submitOrder = async () => {
		setLoading(true);
		const { paymentIntent, error } = await stripe.confirmCardPayment(
			payIntent.client_secret,
			{
				payment_method: get(payMethod, 'id'),
			}
		);
		if (!paymentIntent)
			setError({
				code: 'authentication_failed',
				message: 'Authentication Failed',
			});
		if (error) {
			setError(error);
		}

		if (get(paymentIntent, 'status') === 'succeeded') {
			const endpoint = 'stripe-payments-prod-api';
			const { giftCardStorePendingBalance, giftCardStoreBalance, purchase } =
				await lambda({
					endpoint,
					variables: {
						amount: subTotal,
						currency: 'usd',
						userId: get(currentUser, 'id'),
						orderId: get(paymentIntent, 'id'),
						paymentMethod: get(payMethod, 'id'),
						price: total,
						status: 'complete-order',
					},
				});
			setCheckout('succeeded');
			setPayIntent(paymentIntent);
			setGiftCardStoreBalance(giftCardStoreBalance);
			setGiftCardStorePendingBalance(giftCardStorePendingBalance);
			const user = currentUser;
			user.company.giftCardStorePendingBalance = Number.parseFloat(
				giftCardStorePendingBalance
			).toFixed(2);
			if (callback) {
				setCurrentUser(user);
				callback(true);
			} else {
				updateCurrentUser(user);
			}
		}

		setLoading(false);
	};

	const inputStyle = {
		iconColor: '#c4f0ff',
		position: 'relative',
		color: COLORS.black,
		fontWeight: '400',
		fontSize: '18px',
		fontSmoothing: 'antialiased',
		'::placeholder': {
			color: COLORS.lightGray,
		},
		border: '1px solid #d9d9d9',
		borderRadius: 4,
		margin: 0,
		padding: '5px 11px',
		lineHeight: 1,
		':focus': {
			borderColor: COLORS.blue,
		},
	};

	const inputOptions = {
		style: {
			base: inputStyle,
			complete: {
				color: COLORS.green,
			},
		},
	};

	const calculateTotals = (subTot) => {
		const processingFees = calculateStripeFee(subTot, 'USD');
		const amount = processingFees.amount > 0 ? processingFees.amount : 0;
		const fee = processingFees.fee > 0 ? processingFees.fee : 0;
		const total = processingFees.total > 0 ? processingFees.total : 0;
		setSubTotal(amount);
		setProcessingFees(fee);
		setTotal(total);
		return processingFees;
	};

	let title = 'Add Store Funds';
	if (checkout === 'requires_confirmation') title = 'Checkout';
	if (checkout === 'succeeded') title = 'Order Complete!';
	if (loading) title = '';
	if (get(error, 'code')) title = 'Transaction Failed';
	return get(error, 'code') ? (
		<>
			<div className="ant-modal-header inside-body">
				<div className="ant-modal-title">{title}</div>
			</div>
			<h4 className="text-center">
				Your purchase was not able to be completed.
			</h4>
			<p className="input-error text-center">{get(error, 'message')}</p>
			{!callback && (
				<div className="modal-footer-btn">
					<Button
						type="primary"
						className="btn-min-width"
						size="large"
						disabled={!stripe}
						onClick={props.onCancel}
					>
						Close
					</Button>
				</div>
			)}
		</>
	) : (
		<>
			<div className="ant-modal-header inside-body">
				<div className="ant-modal-title">{title}</div>
			</div>
			{checkout === 'succeeded' ? (
				<>
					<h5 className="text-center">Your purchase was successful!</h5>
					<p className="text-center">
						Current Balance:{' '}
						<span style={{ color: COLORS.green }}>${giftCardStoreBalance}</span>
					</p>
					<p className="text-center">
						Pending Balance:{' '}
						<span style={{ color: COLORS.green }}>
							${giftCardStorePendingBalance}
						</span>
					</p>
					{callback ? null : (
						<div className="modal-footer-btn">
							<Button
								type="primary"
								className="btn-min-width"
								size="large"
								disabled={!stripe}
								onClick={props.onCancel}
							>
								Finish
							</Button>
						</div>
					)}
				</>
			) : checkout === 'requires_confirmation' ? (
				<>
					{loading ? (
						<Spinner message="Processing..." />
					) : (
						<form onSubmit={submitOrder}>
							<p>
								Gift Card Store Funds:{' '}
								<span style={{ color: COLORS.green }}>${subTotal}</span>
							</p>
							<p>
								Subtotal:{' '}
								<span style={{ color: COLORS.green }}>${subTotal}</span>
							</p>
							<p>
								Processing Fee:{' '}
								<span style={{ color: COLORS.green }}>${processingFees}</span>
							</p>
							<p>
								Order Total:{' '}
								<span style={{ color: COLORS.green }}>${total}</span>
							</p>

							<h5
								className="text-center"
								style={{ margin: '30px 0', lineHeight: '1.5' }}
							>
								Are you sure you want to pay{' '}
								<span style={{ color: COLORS.green }}>${total}</span> with your{' '}
								{getCardBrand(get(payMethod, 'card.brand'))} credit card ending
								***
								{get(payMethod, 'card.last4')}?
							</h5>

							<p>
								Note: It may take up to 7 business days for the balance to
								become available for use.
							</p>

							<div className="modal-footer-btn">
								<Button
									type="primary"
									className="btn-min-width"
									htmlType="submit"
									size="large"
									disabled={!stripe}
								>
									Confirm Purchase
								</Button>
							</div>
						</form>
					)}
				</>
			) : (
				<form onSubmit={handleSubmit}>
					<div className="row">
						<div className="col-md-12">
							<div className="custom-form-group">
								<label className="custom-label">Card Number</label>
								<CardNumberElement
									className="custom-input"
									options={inputOptions}
									onChange={(event) => {
										setForm({
											...form,
											number: event,
										});
									}}
								/>
								<div className="input-error">
									{get(form, 'number.error.message', '')}
								</div>
							</div>
							<div className="row">
								<div className="col-md-8">
									<div className="custom-form-group">
										<label className="custom-label">Expiration Date</label>
										<CardExpiryElement
											className="custom-input"
											options={inputOptions}
											onChange={(event) => {
												setForm({
													...form,
													expiration: event,
												});
											}}
										/>
										<div className="input-error">
											{get(form, 'expiration.error.message', '')}
										</div>
									</div>
								</div>
								<div className="col-md-4">
									<div className="custom-form-group">
										<label className="custom-label">CVC</label>
										<CardCvcElement
											className="custom-input"
											options={inputOptions}
											onChange={(event) => {
												setForm({
													...form,
													cvc: event,
												});
											}}
										/>
										<div className="input-error">
											{get(form, 'cvc.error.message', '')}
										</div>
									</div>
								</div>
							</div>
						</div>
						<div style={{ margin: '0 0 12px' }} />
						<div className="col-md-12">
							<h5>
								How much would you like to add to your <br />
								gift card store balance?
							</h5>
							<div
								className="custom-form-group"
								style={{ display: 'flex', alignItems: 'center' }}
							>
								<span
									style={{
										marginRight: 6,
										fontSize: 18,
										fontWeight: 600,
									}}
								>
									$
								</span>
								<Input
									className="custom-input"
									onChange={(e) => {
										if (Number.parseFloat(get(e, 'target.value')) > 0) {
											setForm({ ...form, amount: { complete: true } });
										} else {
											setForm({ ...form, amount: { complete: false } });
										}

										calculateTotals(e.target.value);
									}}
								/>
							</div>
							<div className="row">
								<div className="col-md-6">
									<p>
										Subtotal:{' '}
										<span style={{ color: COLORS.green }}>${subTotal}</span>
									</p>
								</div>
								<div className="col-md-6">
									<p>
										Processing Fee:{' '}
										<span style={{ color: COLORS.green }}>
											${processingFees}
										</span>
									</p>
								</div>
								<div className="col-md-12">
									<h5>
										Order Total:{' '}
										<span style={{ color: COLORS.green }}>${total}</span>
									</h5>
								</div>
							</div>
						</div>
					</div>

					<div className="modal-footer-btn">
						{loading ? (
							<Spinner message="Processing..." />
						) : (
							<Button
								type="primary"
								className="btn-min-width"
								size="large"
								htmlType="submit"
								disabled={!stripe || !valid}
							>
								Checkout
							</Button>
						)}
					</div>
				</form>
			)}
		</>
	);
}

export default StripeForm;
