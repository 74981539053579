export function TabContent({
	id,
	selectedTab,
	tabId,
	tabIndex,
	children,
	...props
}) {
	return (
		<section
			role="tabpanel"
			id={id}
			aria-labelledby={tabId}
			hidden={selectedTab !== tabIndex}
			tabIndex={0}
			{...props}
		>
			{children}
		</section>
	);
}
