import gql from 'graphql-tag';
import get from 'lodash/get';
import { compose, graphql } from 'react-apollo';
import {
	queryWebNotificationsByCompanyIdStatusIndex,
	updateWebNotification,
} from '../../graphql/custom/users';

export const withListReferralRequestedNotificationsByCompany = (Component) => {
	return compose(
		graphql(gql(queryWebNotificationsByCompanyIdStatusIndex), {
			options: (props) => ({
				pollInterval: 300_000,
				context: {
					headers: {
						'x-frame-options': 'deny', // This header will reach the server
					},
				},
				variables: {
					companyId: get(props, 'currentUser.companyId'),
					status: 'referred',
					nextToken: null,
				},
				fetchPolicy: 'cache-and-network',
			}),
			props({ data, data: { fetchMore } }, previous) {
				const gdprNextToken = get(
					data,
					'queryWebNotificationsByCompanyIdStatusIndex.nextToken'
				);
				let gdprReferrals = get(
					data,
					'queryWebNotificationsByCompanyIdStatusIndex.items'
				);
				if (!gdprReferrals) return null;
				gdprReferrals = gdprReferrals.map((ref) => {
					const newReference = ref;
					if (get(ref, 'type') === 'gdprReferralCreated')
						newReference.referralDate = get(ref, 'dateCreated');
					if (typeof get(ref, 'job.referralBonus') === 'string') {
						newReference.job.referralBonus = JSON.parse(
							get(ref, 'job.referralBonus', '{}')
						);
					}

					return newReference;
				});
				const gdprNetworkStatus = get(data, 'networkStatus');
				const loadingGDPR =
					get(data, 'networkStatus') !== 7 || get(data, 'networkStatus') === 1;
				return {
					onLoadMore() {
						fetchMore({
							variables: { after: gdprNextToken },
							updateQuery(previous_, { fetchMoreResult }) {
								if (!fetchMoreResult) {
									return previous_;
								}

								const mergedItems = [
									...previous_.queryWebNotificationsByCompanyIdStatusIndex
										.items,
									...fetchMoreResult.queryWebNotificationsByCompanyIdStatusIndex
										.items,
								];
								const set = new Set();
								const uniqueItems = mergedItems.filter((item) => {
									if (!set.has(item.id)) {
										set.add(item.id);
										return true;
									}

									return false;
								});
								return {
									...previous_,
									queryWebNotificationsByCompanyIdStatusIndex: {
										...previous_.queryWebNotificationsByCompanyIdStatusIndex,
										...fetchMoreResult.queryWebNotificationsByCompanyIdStatusIndex,
										items: uniqueItems,
									},
								};
							},
						});
					},
					gdprReferrals,
					loadingGDPR,
					gdprNextToken,
					gdprNetworkStatus,
				};
			},
		}),

		graphql(gql(updateWebNotification), {
			props: (props) => ({
				onUpdateNotification(input) {
					const optimisticResponseData = {
						...input.input,
					};
					props.mutate({
						variables: input,
						optimisticResponse: {
							__typename: 'Mutation',
							updateWebNotification: {
								...optimisticResponseData,
								__typename: 'updateWebNotification',
							},
						},
						update(proxy) {
							const data = proxy.readQuery({
								query: gql(queryWebNotificationsByCompanyIdStatusIndex),
								variables: {
									userId: props.ownProps.currentUser.id,
									first: 1000,
									nextToken: null,
								},
							});
							proxy.writeQuery({
								query: gql(queryWebNotificationsByCompanyIdStatusIndex),
								data,
								variables: {
									userId: props.ownProps.currentUser.id,
									first: 1000,
									nextToken: null,
								},
							});
						},
					});
				},
			}),
		})
	)(Component);
};
