import gql from 'graphql-tag';
import _, { get } from 'lodash';
import { compose, graphql } from 'react-apollo';
import { parseJsonFields } from '../../../services/parseApiService.js';
import { listTieredBonuses } from '../../graphql/custom/tiered-bonuses';

export const withListTieredBonus = (Component) => {
	return compose(
		graphql(gql(listTieredBonuses), {
			options: (props) => ({
				context: {
					headers: {
						'x-frame-options': 'deny', // This header will reach the server
					},
				},
				variables: {
					filter: {
						companyId: { eq: props.currentUser.companyId },
						archived: { ne: true },
					},
					limit: 1000,
					nextToken: null,
				},
				fetchPolicy: 'cache-and-network',
			}),
			props(props) {
				const bonusesNextToken = get(
					props,
					'data.listTieredBonuses.nextToken',
					null
				);
				const onFetchMoreBonuses = makeOnFetchMoreBonuses(
					props.data.fetchMore,
					bonusesNextToken
				);
				if (
					!props.data.loading &&
					(!props.data.listTieredBonuses || props.data.error)
				) {
					setTimeout(props.data.refetch, 2000);
					return null;
				}

				let bonuses = get(props, 'data.listTieredBonuses.items', []);
				if (bonuses) {
					for (let bonus of bonuses) {
						bonus = Object.assign(bonus, parseJsonFields(['tiers'], bonus));
					}

					bonuses = _.orderBy(bonuses, ['name'], ['asc']);
				} else {
					bonuses = [];
				}

				if (bonusesNextToken) onFetchMoreBonuses();
				return {
					bonuses,
				};
			},
		})
	)(Component);
};

const makeOnFetchMoreBonuses = (fetchMore, nextToken) => {
	if (!nextToken) {
		return null;
	}

	return () => {
		fetchMore({
			variables: { nextToken },
			updateQuery(previous, { fetchMoreResult }) {
				if (!fetchMoreResult) {
					return previous;
				}

				const mergedItems = [
					...previous.listTieredBonuses.items,
					...fetchMoreResult.listTieredBonuses.items,
				];
				const set = new Set();
				const uniqueItems = mergedItems.filter((item) => {
					if (!set.has(item.id)) {
						set.add(item.id);
						return true;
					}

					return false;
				});
				return {
					...previous,
					listTieredBonuses: {
						...previous.listTieredBonuses,
						...fetchMoreResult.listTieredBonuses,
						items: uniqueItems,
					},
				};
			},
		});
	};
};
