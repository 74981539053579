export const listJobLevels = `query ListJobLevels(
    $filter: TableJobLevelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listJobLevels(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        active
        companyId
        name
      }
      nextToken
    }
  }
  `;
