import { css } from 'emotion';
import get from 'lodash/get';
import { COLORS } from 'src/_shared/styles/colors';

const breakpoints = [576, 767, 991, 1200];

const mq = breakpoints.map((bp) => `@media (max-width: ${bp}px)`);

export const LabelStyle = css({
	width: '100%',
	marginTop: '15px',
});

export const LabelTitle = css({
	fontSize: 18,
	fontWeight: 600,
	color: COLORS.darkGray,
	marginBottom: 0,
});

export const ModalStyles = css({
	'& .ant-modal-content': {
		borderRadius: 20,
	},
	fontFamily: '"Open Sans", sans-serif',
	'& .ant-modal-close-x': {
		fontSize: 30,
		padding: '0.5rem',
		color: COLORS.lightGray,
		position: 'relative',
		bottom: '0.85rem',
		left: '0.75rem',
		width: 'fit-content',
		height: 'fit-content',
	},
});

export const ModalStylesSubmitting = css({
	'& .ant-modal-content': {
		borderRadius: 20,
		height: 'auto',
		width: 520,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	fontFamily: '"Open Sans", sans-serif',
	'& .ant-modal-close-x': {
		color: 'white',
	},
});

export const ModalTitle = (theme) => {
	let buttonColor = COLORS.red;
	if (get(theme, 'enabled')) {
		buttonColor = get(theme, 'buttonColor', buttonColor);
	}

	return css({
		width: '100%',
		textAlign: 'center',
		color: buttonColor,
		fontSize: 36,
		marginBottom: 0,
		fontWeight: 600,
	});
};

export const SmallText = css({
	fontSize: 13,
	fontWeight: 600,
	width: '100%',
	textAlign: 'center',
});

export const NoteStyle = css({
	fontSize: 13,
	fontWeight: 600,
	position: 'absolute',
	textAlign: 'center',
	color: `${COLORS.gray}`,
	paddingLeft: '20px',
	paddingRight: '20px',
	paddingTop: 30,
});

export const AddContactsHere = css({
	color: COLORS.lightGray,
	fontWeight: 400,
});

export const JobInfoContainer = css({
	padding: 20,
	paddingBottom: 10,
	backgroundColor: COLORS.lightGreen,
	borderRadius: 8,
	marginBottom: 15,
});

export const JobInfo = css({
	justifyContent: 'space-between',
	marginBottom: 0,
	[mq[1]]: {
		flexDirection: 'column',
		'& div': {
			width: '100% !important',
		},
	},
});

export const JobTitle = css({
	fontSize: 18,
	color: COLORS.blue,
	marginBottom: 0,
});

export const Currency = css({
	color: COLORS.green,
	fontSize: 26,
	marginBottom: 0,
	textAlign: 'right',
	padding: 0,
});

export const CurrencyNoBonus = css({
	color: COLORS.green,
	fontSize: 22,
	marginBottom: 0,
	textAlign: 'right',
	padding: 0,
});

export const JobIcon = css({
	fontSize: 20,
	color: COLORS.darkGray,
	paddingRight: 3,
});

export const SubTitle = css({
	margin: 0,
	marginBlockEnd: 0,
});

export const Flex = css({
	display: 'flex',
	marginBottom: 0,
});

export const ReferralBonus = css({
	marginBottom: 0,
});

export const addPaddingRight = css({
	paddingRight: 15,
});

export const ToggleNewContactBtn = css({
	padding: 0,
	border: 'none',
	fontSize: 13,
	color: COLORS.blue,
	fontWeight: 600,
	height: 13,
	marginTop: 10,
	backgroundColor: 'transparent',
	'&:hover': {
		backgroundColor: 'transparent',
		borderColor: 'transparent',
	},
	'&:focus': {
		backgroundColor: 'transparent',
		borderColor: 'transparent',
	},
});

export const PopoverToggleNewContactBtn = css({
	padding: 0,
	border: 'none',
	fontSize: 14,
	color: COLORS.blue,
	fontWeight: 600,
	height: 14,
	backgroundColor: 'transparent',
	'&:hover': {
		backgroundColor: 'transparent',
		borderColor: 'transparent',
	},
	'&:focus': {
		backgroundColor: 'transparent',
		borderColor: 'transparent',
	},
});

export const SubmitBtn = css({
	fontFamily: '"Open Sans", sans-serif',
	backgroundColor: COLORS.blue,
	color: 'white',
	fontSize: 20,
	fontWeight: 300,
	padding: '10px 30px',
	height: 'auto',
	border: 'none',
	marginBottom: 0,
	borderRadius: 10,
});

export const SubmitBtnContainer = css({
	width: '100%',
	display: 'flex',
	justifyContent: 'center',
	marginBottom: 0,
	'& .ant-form-item-control-wrapper': {
		'@media (max-width: 575px)': {
			width: 'auto',
		},
	},
	overflow: 'hidden',
});

export const CheckIcon = css({
	borderRadius: 100,
	border: '1px solid white',
	padding: 2,
});

export const FormTitle = css({
	width: '100%',
	textAlign: 'center',
	fontWeight: 600,
	marginBottom: 0,
	color: '#404447',
	fontSize: 24,
});

export const LabelStyles = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 600,
	'& span': {
		fontWeight: 300,
		fontSize: 12,
	},
});

export const TextAreaStyles = css({
	marginBottom: 10,
});

export const InputStyles = css({
	fontFamily: '"Open Sans", sans-serif',
	fontSize: 11,
});

export const AutoCompleteStyles = css({
	fontFamily: '"Open Sans", sans-serif',
});

export const FormItemStyles = css({
	marginBottom: '0 !important',
});

export const RemoveBottomMargin = css({
	'& .ant-form-item': {
		marginBottom: 0,
	},
});

export const SubmitError = css({
	marginBottom: 24,
});

export const LinkStyles = css({
	color: COLORS.blue,
	cursor: 'pointer',
	fontWeight: 600,
});

export const PopoverStyle = css({
	'& .ant-popover-inner-content': {
		borderColor: 'rgba(0, 0, 0, 0.75)',
		borderRadius: 4,
		color: 'white',
		backgroundColor: 'rgba(0, 0, 0, 0.75)',
		padding: 5,
	},
	'& .ant-popover-arrow': {
		backgroundColor: 'rgba(0, 0, 0, 0.75)',
		borderColor: 'rgba(0, 0, 0, 0.75)',
		border: '0px solid',
		zIndex: -1,
	},
});

export const fileWrap = css`
	display: inline-block;
	position: relative;
	font-weight: bold;
	color: ${COLORS.blue};
	label {
		cursor: pointer;
	}
	input {
		opacity: 0;
		width: 0.1px;
	}
`;
export const fieldErrors = css({
	width: 'auto',
});

export const errorText = css({
	fontFamily: '"Open Sans", sans-serif',
	color: COLORS.red,
	fontSize: '1em',
	left: -30,
});

export const RippleButton = css({
	display: 'inline !important',
	cursor: 'pointer',
});

export const ShareNote = css({
	textAlign: 'center',
	color: `${COLORS.gray2}`,
	marginBottom: '30px',
	paddingLeft: '20px',
	paddingRight: '20px',
	paddingTop: '45px',
});
