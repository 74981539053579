import { Button } from 'antd';
import parse from 'html-react-parser';
import { Component } from 'react';

export class StringParserAlt extends Component {
	state = {
		isMore: false,
		text: [],
	};

	render() {
		let smallDescription = false;
		if (this.props.text?.length < 800) {
			smallDescription = true;
		}

		return (
			<>
				{!smallDescription &&
					(this.state.isMore ? (
						<>
							<div
								properties={{ target: '_blank' }}
								className=""
								target="_blank"
								style={{ listStyleType: 'disc' }}
							>
								{parse(this.props.text)}
							</div>
							<Button
								type="link"
								onClick={() => {
									this.setState({ isMore: false });
								}}
							>
								<span>{this.props.showLessText}</span>{' '}
								<i className="icon-arrow-up" style={{ fontSize: 8 }} />
							</Button>
						</>
					) : (
						<>
							<div
								properties={{ target: '_blank' }}
								className="fade-bottom"
								target="_blank"
								style={{
									listStyleType: 'disc',
									height: '250px',
									overflowY: 'hidden',
								}}
							>
								{parse(this.props.text)}
							</div>
							<Button
								type="link"
								onClick={() => {
									this.setState({ isMore: true });
								}}
							>
								<span>{this.props.showMoreText}</span>
								<i className="icon-arrow-down" style={{ fontSize: 8 }} />
							</Button>
						</>
					))}
				{smallDescription && (
					<div
						properties={{ target: '_blank' }}
						className=""
						target="_blank"
						style={{ listStyleType: 'disc' }}
					>
						{parse(this.props.text)}
					</div>
				)}
			</>
		);
	}
}
