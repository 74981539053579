import { Button, Radio } from 'antd';
import { useState } from 'react';
import { get } from 'lodash';
import { ONBOARDING_SCREENS, PAYOUT_METHODS } from '../constants';
import {
	buttonStyle,
	containerStyle,
	textBodyStyle,
	titleContainerStyle,
	titleStyle,
} from './onboardingStyles.js';

function HowToPayScreen(props) {
	const {
		company,
		completeOnboardingPage,
		uncompleteOnboardingPage,
		prevPage,
		nextPage,
		navToScreen,
	} = props;

	const partnerHost = get(company, 'partnerHost');

	const [howToPay, setHowToPay] = useState(PAYOUT_METHODS.GIFT_CARDS);

	const onChange = (e) => {
		setHowToPay(e.target.value);
	};

	const back = () => {
		uncompleteOnboardingPage(ONBOARDING_SCREENS.HOW_TO_PAY);
		navToScreen(ONBOARDING_SCREENS.SAAS_AGREEMENT);
	};

	const next = () => {
		const nextScreen =
			howToPay === PAYOUT_METHODS.GIFT_CARDS
				? ONBOARDING_SCREENS.GIFT_CARD_PAYOUTS
				: ONBOARDING_SCREENS.CREATE_BONUSES;

		const paysWithPoints = howToPay === PAYOUT_METHODS.GIFT_CARDS;

		completeOnboardingPage(ONBOARDING_SCREENS.HOW_TO_PAY, {
			payBonusesWithPoints: howToPay === Boolean(PAYOUT_METHODS.GIFT_CARDS),
		});

		navToScreen(nextScreen);
	};

	return (
		<div className={containerStyle}>
			<div className={titleContainerStyle(partnerHost)}>
				<h1 className={titleStyle}>Choose How You Will Payout Bonuses</h1>
				<div className={textBodyStyle}>
					<Radio.Group
						value={howToPay}
						style={{ display: 'flex', flexDirection: 'column' }}
						onChange={onChange}
					>
						<Radio value={PAYOUT_METHODS.INTERNAL}>
							{/* Manage bonus payments internally through payroll. */}
							Payroll
						</Radio>
						<Radio value={PAYOUT_METHODS.GIFT_CARDS} style={{ marginTop: 10 }}>
							{/* Pay your employees using gift cards. */}
							Gift Cards
						</Radio>
					</Radio.Group>
					<div className="flex my-4 justify-center">
						<Button
							type="primary"
							shape="round"
							size="large"
							className={buttonStyle}
							style={{ marginRight: 30 }}
							onClick={back}
						>
							Back
						</Button>
						<Button
							type="primary"
							shape="round"
							size="large"
							className={buttonStyle}
							onClick={next}
						>
							Next
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
}

export default HowToPayScreen;
