import {
	ADD_COMPANY,
	ADD_SUPER_USER,
	SET_CURRENT_CLIENT,
	SET_CURRENT_LANGUAGE,
	SET_CURRENT_USER,
	SIGN_OUT,
	UPDATE_CURRENT_USER,
	UPDATE_CURRENT_USER_GROUP,
	UPDATE_DISPLAY_AS,
	UPDATE_LOGIN_ATTEMPTS,
	UPDATE_USER_COMPANY,
	UPDATE_USER_LAST_NOTIFICATION_CHECK,
	UPDATE_USER_SEARCH,
} from './userActions.js';
import { state as initialState } from './userState.js';

export const userReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_CURRENT_USER: {
			return handleSetCurrentUserAction(state, action);
		}

		case ADD_SUPER_USER: {
			return handleAddSuperUserAction(state, action);
		}

		case ADD_COMPANY: {
			return handleAddCompanyAction(state, action);
		}

		case UPDATE_USER_COMPANY: {
			return handleUpdateUserCompany(state, action);
		}

		case UPDATE_USER_LAST_NOTIFICATION_CHECK: {
			return handleUpdateUserLastNotificationCheck(state, action);
		}

		case SIGN_OUT: {
			return handleSignOut(state, action);
		}

		case SET_CURRENT_CLIENT: {
			return handleCurrentClientAction(state, action);
		}

		case UPDATE_CURRENT_USER: {
			return handleUpdateCurrentUser(state, action);
		}

		case SET_CURRENT_LANGUAGE: {
			return handleUpdateCurrentUserLanguage(state, action);
		}

		case UPDATE_CURRENT_USER_GROUP: {
			return handleUpdateCurrentUserGroup(state, action);
		}

		case UPDATE_LOGIN_ATTEMPTS: {
			return handleFailedLoginAttempts(state, action);
		}

		case UPDATE_USER_SEARCH: {
			return handleUpdateUserSearch(state, action);
		}

		case UPDATE_DISPLAY_AS: {
			return handleUpdateDisplayAs(state, action);
		}

		default: {
			return state;
		}
	}
};

const handleSetCurrentUserAction = (state, action) => {
	const { currentUser } = action.payload;
	if (state.currentUser?.displayAs) {
		currentUser.displayAs = state.currentUser.displayAs;
	}

	return {
		...state,
		currentUser: currentUser ? currentUser : state.currentUser,
	};
};

const handleAddSuperUserAction = (state, { payload }) => {
	const newUser = {};
	for (const key of Object.keys(payload)) {
		newUser[key] = payload[key];
	}

	return {
		...state,
		superUser: { ...state.superUser, ...newUser },
	};
};

const handleAddCompanyAction = (state, { payload }) => {
	const newCompany = {};
	for (const key of Object.keys(payload)) {
		newCompany[key] = payload[key];
	}

	return {
		...state,
		company: { ...state.company, ...newCompany },
	};
};

const handleSignOut = (state) => {
	return {
		...state,
		currentUser: {},
		failedLoginAttempts: 0,
	};
};

const handleUpdateUserCompany = (state, action) => {
	const { company } = action.payload;
	const { currentUser } = state;
	const updatedUser = { ...currentUser, company };
	return {
		...state,
		currentUser: updatedUser,
	};
};

const handleUpdateUserLastNotificationCheck = (state, action) => {
	const { lastNotificationCheck } = action.payload;
	const { currentUser } = state;
	const updatedUser = {
		...currentUser,
		lastNotificationCheck,
	};
	return {
		...state,
		currentUser: updatedUser,
	};
};

const handleCurrentClientAction = (state, payload) => {
	return {
		...state,
		currentClient: payload,
	};
};

const handleUpdateCurrentUser = (state, action) => {
	const {
		location,
		currency,
		avatar,
		dateFormat,
		defaultDistance,
		openToNewRole,
		careerProfile,
		jobMatches,
	} = action.payload.user;
	const { currentUser } = state;
	const updatedUser = {
		...currentUser,
		location,
		currency,
		avatar,
		dateFormat,
		defaultDistance,
		openToNewRole,
		careerProfile,
		jobMatches,
	};
	return {
		...state,
		currentUser: updatedUser,
	};
};

const handleUpdateCurrentUserLanguage = (state, action) => {
	const { languageCode } = action.payload.user;
	const { currentUser } = state;
	const updatedUser = { ...currentUser, languageCode };
	return {
		...state,
		currentUser: updatedUser,
	};
};

const handleUpdateCurrentUserGroup = (state, action) => {
	const { userGroup } = action.payload;
	const { currentUser } = state;
	const updatedUser = { ...currentUser, userGroup };
	return {
		...state,
		currentUser: updatedUser,
	};
};

const handleFailedLoginAttempts = (state, action) => {
	return {
		...state,
		failedLoginAttempts: state.failedLoginAttempts + 1,
	};
};

const handleUpdateUserSearch = (state, payload) => {
	return {
		...state,
		jobSearchCriteria: payload.searchCriteria,
	};
};

const handleUpdateDisplayAs = (state, action) => {
	const { role } = action.payload;
	const { currentUser } = state;
	const updatedUser = { ...currentUser, displayAs: role };
	return {
		...state,
		currentUser: updatedUser,
	};
};
