import BadgeIcon from './BadgeIcon';
import { useState, useRef, useEffect } from 'react';
import VanillaTilt from "vanilla-tilt";
import './NeoBadge.css';

const NeoBadge = ({ shape = 'hexagon', icon = "ANCHOR", colors = {gradient: "linear-gradient(to bottom right, #6366f1, #0ea5e9, #10b981)", icon: "#ffffff", text: "#ffffff" }, text, captureId, useTilt = true }) => {
	const [isHovered, setIsHovered] = useState(false);
	const badgeRef = useRef(null);

	const getBadgeShape = () => {
		switch (shape) {
			case 'circle':
				return 'badge-circle';
			case 'hexagon':
				return 'badge-hexagon';
			case 'shield':
				return 'badge-shield';
			case 'flower':
				return 'badge-flower';
			case 'wavy':
				return 'badge-wavy';
			case 'starburst':
				return 'badge-starburst';
			default:
				return 'badge-square';
		}
	};

	useEffect(() => {
		if (useTilt) {
			VanillaTilt.init(badgeRef.current, {
				max: 15,
				speed: 350,
				glare: true,
				reset: true,
				"max-glare": 0.8,
				"glare-prerender": true,
				scale: 1.1,
				reverse: true,
			});

			return () => {
				badgeRef.current?.vanillaTilt?.destroy();
			};
		}
	}, []);

	return (
		<div
			id={captureId?.toString() ? captureId?.toString() : null}
			ref={badgeRef}
			className={`badge tilt-container`}
			onMouseEnter={() => setIsHovered(true)}
			onMouseLeave={() => setIsHovered(false)}
		>
			<div className="badge-shadow">
				<div style={{ background: colors.gradient }}
						 className={`badge-inner ${isHovered ? 'hovered' : ''} ${getBadgeShape()}`}>
					<div className="js-tilt-glare">
						<div className="badge-content">
							<div className="badge-inner-circle">
								<BadgeIcon iconName={icon} className="badge-icon" color={colors.icon} />
							</div>
						</div>
						<div className="js-tilt-glare-inner"></div>
					</div>
				</div>
			</div>
			<div className={`badge-ribbon ${isHovered ? 'badge-hover' : ''}`}>
				<span className="badge-text" style={{ color: colors.text }}>{text}</span>
			</div>
		</div>
	);
};

export default NeoBadge;
