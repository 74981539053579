import gql from 'graphql-tag';

export const GetJobsByCompanyIdAndStatus = gql`
	query queryJobsByCompanyIdStatusIndex($companyId: ID!, $status: String!) {
		queryJobsByCompanyIdStatusIndex(companyId: $companyId, status: $status) {
			items {
				id
				title
				description
				status
				departmentId
				department {
					id
					name
				}
			}
			nextToken
		}
	}
`;
