import { connect } from 'react-redux';
import { withUserByIdEditProfile } from 'src/_shared/api/components/edit-profile/';
import { compose } from '../../services/utils';
import EmployeeLinkComponent from './EmployeeLinkComponent.jsx';

const mapStateToProperties = (state, props) => {
	const { id } = props;
	const { currentUser } = state.user;
	return {
		currentUser,
		id,
		companyId: currentUser.companyId,
		filter: { companyId: { eq: currentUser.companyId }, active: { eq: true } },
		limit: 200,
	};
};

const EmployeeLinkWithApi = compose(withUserByIdEditProfile)(
	EmployeeLinkComponent
);

export const EmployeeLink = connect(mapStateToProperties)(EmployeeLinkWithApi);
