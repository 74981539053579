import { Message } from 'antd';
import { useEffect, useState } from "react";
import SendRewardModal from "../../presentation/sendRewardModal/SendRewardModal.jsx";
import { useHomeService } from '../../service/home.services.jsx';
import { BADGE_TYPE } from '../../../RnRCommonComponent/CoreBadge/rewardsConstants';

const SendRewardContainer = (props) => {

  const {
    setSendRewardObject,
    sendrewardObject,
    setIsOpenRewardModel,
    recipientId
  } = props;
  const [employeeData, setEmployeeData] = useState()
  const [badgeDetailObject, setBagdeDetailObject] = useState({})
  const [badgeData, setBadgeData] = useState();
  const [isLoading, setIsLoading] = useState(false)
  const [isBadgeLoading, setIsBadgeLoading] = useState(false)
  const [errorObject, setErrorObject] = useState({})
  const [rewardsAvailable, setRewardsAvailable] = useState(true);
  const homeAPIService = useHomeService();

  useEffect(() => {
    getEmployeeList()
    fatchInitialData()
  }, [])

  const fatchInitialData = () => {
    setIsBadgeLoading(true)
    getAllBadgeList(BADGE_TYPE.PEERTOPEER)
  }

  const handleSendReward = async () => {
    if (
      !Boolean(sendrewardObject?.employeeDetail) ||
      !Boolean(sendrewardObject?.badgeAssignmentReason) ||
      !Boolean(sendrewardObject?.rewardtype)) {
      setErrorObject({
        ...errorObject,
        badgeAssignmentReason: !Boolean(sendrewardObject?.badgeAssignmentReason),
        rewardtype: !Boolean(sendrewardObject?.rewardtype),
        employeeDetails: !Boolean(sendrewardObject?.employeeDetail)
      })
      return
    }

    const sendData = {
      rewardPoints: sendrewardObject?.addBonus,
      badgeAssignmentReason: sendrewardObject?.badgeAssignmentReason,
      badgeId: sendrewardObject?.rewardtype,
      employeeId: sendrewardObject?.employeeDetail
    }

    setIsLoading(true)
    homeAPIService.createBadgeAssignment(sendData).then(async (response) => {
      if (response.code === 200) {
        setIsLoading(false)
        setIsOpenRewardModel(false)
        Message.success(response.message);
        setSendRewardObject(null)
      }
      else {
        setIsLoading(false)
        setSendRewardObject(null)
        Message.error(response.message);
      }
    })
  };

  const getEmployeeList = (search) => {
    homeAPIService.getEmployeeList({ search: search }).then((response) => {
      if (response?.code === 200) {
        setEmployeeData(response?.data)
      } else {
        Message.error('Failed to fetch employee list');
      }
    }).catch((error) => {
      console.log(error);
      Message.error('Failed to fetch employee list');
    });
  };

  const getAllBadgeList = (badgeType, page, limit, search) => {
    homeAPIService.getBadgesDashboard({
      page: page,
      limit: limit,
      visibility: true,
      name: search,
      type: badgeType,
      recipientId
    })
      .then((response) => {
        setIsBadgeLoading(false)
        if (response?.code === 200) {
          setBadgeData(response?.data?.records)
          const rewardsExist = response?.data?.records?.length > 0;
          setRewardsAvailable(rewardsExist);
        } else {
          Message.error('Failed to fetch badges list! Try again later.');
        }
      }).catch((error) => {
        console.log(error);
        Message.error('Failed to fetch badges list! Try again later.');
      });
  };

  return <>
    <SendRewardModal
      getEmployeeList={getEmployeeList}
      badgeDetailObject={badgeDetailObject}
      setBagdeDetailObject={setBagdeDetailObject}
      setIsOpenRewardModel={setIsOpenRewardModel}
      sendrewardObject={sendrewardObject}
      setSendRewardObject={setSendRewardObject}
      employeeData={employeeData}
      badgeData={badgeData}
      isBadgeLoading={isBadgeLoading}
      setIsBadgeLoading={setIsBadgeLoading}
      handleSendReward={handleSendReward}
      setEmployeeData={setEmployeeData}
      errorObject={errorObject}
      setErrorObject={setErrorObject}
      isLoading={isLoading}
      rewardsAvailable={rewardsAvailable}
    />
  </>;
};

export default SendRewardContainer;
