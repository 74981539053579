export const createEditEmails = `mutation CreateEditEmails($input: CreateEditEmailsInput!) {
    createEditEmails(input: $input) {
      id
      companyId
      referredEmailText
      alertEmailText
      inviteEmailText
    }
}
`;
