import { Button } from 'antd';
import get from 'lodash/get';
import { Component } from 'react';
import WebIcon from 'src/_shared/components/WebIconComponent.jsx';
import { COLORS } from '../../../styles/colors';
import OnDeckReferralModal from './OnDeckModalComponent.jsx';
import { HeaderTheme } from './onDeckReferralModalWrapperStyles.js';

class ReferralModalWrapper extends Component {
	constructor(props) {
		super(props);
		this.state = {
			job: props.jobData,
			contacts: props.contacts,
			onDeckContacts: props.onDeckContacts,
			contact: props.contact,
			visible: false,
			referrals: props.referrals,
			height: 0,
			width: 0,
			theme: JSON.parse(get(props, 'currentUser.company.theme', '{}')),
			referPage: get(props, 'referPage', null),
		};
	}

	componentDidMount() {
		this.updateWindowDimensions();
		window.addEventListener('resize', this.updateWindowDimensions);
	}

	componentDidUpdate(prevProps) {
		const { onFetchMore, onFetchMoreOnDeckContacts, onDeckContacts, contacts } =
			this.props;
		if (prevProps.onDeckContacts !== onDeckContacts) {
			this.setState({ onDeckContacts });
			if (onFetchMoreOnDeckContacts) onFetchMoreOnDeckContacts();
		}

		if (prevProps.contacts !== contacts) {
			this.setState({ contacts });
			if (onFetchMore) onFetchMore();
		}
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.updateWindowDimensions);
	}

	handleCancel = () => {
		this.setState({ visible: false });
	};

	showModal = () => {
		this.setState({ visible: true });
	};

	updateWindowDimensions = () => {
		this.setState({ width: window.innerWidth, height: window.innerHeight });
	};

	render() {
		const {
			button,
			currentUser,
			companyData,
			allMultiLingualData,
			filteredJobs,
			onCreateReferral,
		} = this.props;
		const { visible, contact, contacts, onDeckContacts } = this.state;
		const {
			submitGeneralReferralText,
			referSomeoneText,
			doYouKnowText,
			submitThemText,
			enterReferralText,
			enterReferralInformationText,
			firstNameText,
			lastNameText,
			emailText,
			textPlaceHolderText,
			orText,
			clickHereText,
			toAddExistingContactText,
			includeMessageToContactText,
			optionalText,
			personalizeMessageText,
			messageHiringContactText,
			howKnowThemText,
			clickHereResumeText,
			attachResumeText,
			submitReferralText,
			referPage,
		} = this.props;
		const enableGeneralReferrals = get(
			currentUser,
			'company.enableGeneralReferrals',
			true
		);

		return (
			<>
				{button ? (
					this.props.currentUser.displayAs === 'admin' &&
					enableGeneralReferrals ? (
						referPage == 'referralDetails' ? (
							<Button className="add-btn" type="link" onClick={this.showModal}>
								<span className="icon-circle">
									<i className="icon-plus" />
								</span>
								Add General Referral
							</Button>
						) : (
							<Button className="add-btn" type="link" onClick={this.showModal}>
								<span className="icon-circle">
									<i className="icon-plus" />
								</span>
								Add General Referral
							</Button>
						)
					) : null
				) : enableGeneralReferrals ? (
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							flex: 2,
							justifyContent: 'flex-end',
							margin: '0 10px 0 0',
						}}
					>
						<Button shape="circle" type="addButton" onClick={this.showModal}>
							<WebIcon name="plus-symbol" size={30} color={COLORS.white} />
						</Button>
						&nbsp;
						<span className={HeaderTheme}> {submitGeneralReferralText} </span>
					</div>
				) : null}

				{visible && (
					<OnDeckReferralModal
						visible
						currentUser={this.props.currentUser}
						contact={contact}
						contacts={contacts}
						handleCancel={this.handleCancel}
						matchFound={this.props.matchFound}
						notification={this.props.notification}
						referSomeoneText={referSomeoneText}
						doYouKnowText={doYouKnowText}
						submitThemText={submitThemText}
						enterReferralText={enterReferralText}
						enterReferralInformationText={enterReferralInformationText}
						firstNameText={firstNameText}
						lastNameText={lastNameText}
						emailText={emailText}
						textPlaceHolderText={textPlaceHolderText}
						orText={orText}
						clickHereText={clickHereText}
						toAddExistingContactText={toAddExistingContactText}
						includeMessageToContactText={includeMessageToContactText}
						optionalText={optionalText}
						personalizeMessageText={personalizeMessageText}
						messageHiringContactText={messageHiringContactText}
						howKnowThemText={howKnowThemText}
						clickHereResumeText={clickHereResumeText}
						attachResumeText={attachResumeText}
						submitReferralText={submitReferralText}
						companyData={companyData}
						allMultiLingualData={allMultiLingualData}
						filteredJobs={filteredJobs}
						onDeckContacts={onDeckContacts}
						onCreateContact={this.props.ImportedCreateContact}
						onUpdateContact={this.props.onUpdateContact}
						onUpdateNotification={this.props.onUpdateNotification}
						onCreateReferral={onCreateReferral}
					/>
				)}
			</>
		);
	}
}

export default ReferralModalWrapper;
