import get from 'lodash/get';
import { Component } from 'react';
import { Link } from 'react-router-dom';
import { borderedTile } from '../../../_shared/components/bordered-tile';
import {
	bonusStyles,
	container,
	headerClass,
	linkStyles,
} from './bonusCardStyles.js';

class BaseBonusCard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			totalSmartReferrals: 0,
		};
	}

	formatDate(date) {
		if (!date) return;
		const isoDate = new Date(date);
		const month = isoDate.getMonth();
		const day = isoDate.getDay();
		const year = isoDate.getFullYear();
		return `${month}/${day}/${year}`;
	}

	renderAmounts(bonuses = []) {
		const allBonuses = [];
		for (const bonus of bonuses) {
			const displayBonus = (
				<div>
					<span className={bonusStyles}>${get(bonus, 'amountDue')}</span>{' '}
					eligible on {this.formatDate(get(bonus, 'earnedDate'))}
				</div>
			);
			allBonuses.push(displayBonus);
		}

		return allBonuses;
	}

	renderBonuses() {
		const { bonuses } = this.props;
		const firstBonus = bonuses[0];
		const title = get(firstBonus, 'job.title', '');
		const jobId = get(firstBonus, 'job.id', '');
		const startDate = get(firstBonus, 'startDate');
		return (
			<div>
				<Link to={`/browsejobs/${jobId}`}>
					<div className={linkStyles}>{title}</div>
				</Link>
				<div style={{ marginBottom: 8, fontWeight: 500 }}>
					Start Date:{' '}
					<span style={{ fontWeight: 400 }}>{this.formatDate(startDate)}</span>
				</div>
				<div>{this.renderAmounts(bonuses)}</div>
			</div>
		);
	}

	render() {
		const { bonuses, onClick, currentUser } = this.props;
		const firstBonus = bonuses[0];
		const jobId = get(firstBonus, 'job.id', '');
		return (
			<div className={container(currentUser?.role)} onClick={onClick(jobId)}>
				<div>
					<h2 className={headerClass}>YOUR RETENTION BONUS</h2>
					{this.renderBonuses()}
				</div>
			</div>
		);
	}
}

export const BonusCard = borderedTile(BaseBonusCard);
