import gql from 'graphql-tag';
import get from 'lodash/get';
import { compose, graphql } from 'react-apollo';
import {
	createCustomPage,
	queryCustomPageByCompanyIdIndex,
	updateCustomPage,
} from '../../graphql/custom/custom-page';

export const withListCustomPage = (Component) => {
	return compose(
		graphql(queryCustomPageByCompanyIdIndex, {
			options: (props) => ({
				variables: {
					companyId: props.currentUser.companyId,
				},
				fetchPolicy: 'cache-and-network',
			}),
			props(response, previous) {
				const customPageData = get(
					response,
					['data', 'queryCustomPageByCompanyIdIndex', 'items'],
					get(previous, 'customPageData', [])
				);

				const customPageDataNextToken = get(
					response,
					['data', 'queryCustomPageByCompanyIdIndex', 'nextToken'],
					null
				);
				const onFetchMoreContacts = makeOnFetchMoreCustomPageData(
					response.data.fetchMore,
					customPageDataNextToken
				);

				return { customPageData, onFetchMoreContacts, customPageDataNextToken };
			},
		}),
		graphql(gql(createCustomPage), {
			props: (props) => ({
				onAddCustomPage(input) {
					const optimisticResponseData = {
						...input.input,
						__typename: 'CustomPage',
					};
					props.mutate({
						variables: { ...input },
						optimisticResponse: {
							__typename: 'Mutation',
							createCustomPage: {
								__typename: 'createCustomPage',
								...optimisticResponseData,
							},
						},
						refetchQueries: [
							{
								query: queryCustomPageByCompanyIdIndex,
								variables: { companyId: props.ownProps.currentUser.companyId },
							},
						],
					});
				},
			}),
		}),
		graphql(gql(updateCustomPage), {
			props: (props) => ({
				onUpdateCustomPage(input) {
					const variables = {
						...input,
					};
					props.mutate({
						variables,
						refetchQueries: [
							{
								query: queryCustomPageByCompanyIdIndex,
								variables: { companyId: props.ownProps.currentUser.companyId },
							},
						],
					});
				},
			}),
		})
	)(Component);
};

const makeOnFetchMoreCustomPageData = (fetchMore, nextToken) => {
	if (!nextToken) {
		return null;
	}

	return () => {
		fetchMore({
			variables: { after: nextToken },
			updateQuery(previous, { fetchMoreResult }) {
				if (!fetchMoreResult) {
					return previous;
				}

				return {
					...previous,
					loading: false,
					queryCustomPageByCompanyIdIndex: {
						...previous.queryCustomPageByCompanyIdIndex,
						...fetchMoreResult.queryCustomPageByCompanyIdIndex,
						items: [
							...previous.queryCustomPageByCompanyIdIndex.items,
							...fetchMoreResult.queryCustomPageByCompanyIdIndex.items,
						],
					},
				};
			},
		});
	};
};
