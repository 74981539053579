import { Button, Card, Modal, Tooltip } from 'antd';
import getSymbolFromCurrency from 'currency-symbol-map';
import get from 'lodash/get';
import { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { ProgressIndicator } from 'src/_shared/components/referral-progress-indicator';
import { JobLocation, MyReferralsBonusDetailsModal } from '..';
import {
	calculateReferralBonus,
	conditionalStatusStyle,
	formatDate,
	mapReferralStatus,
	ml,
	parse,
} from '../../services/utils';
import OwnershipPeriodTooltip from './OwnershipPeriodTooltip.jsx';

class ReferralCard extends Component {
	constructor() {
		super();
		this.state = {
			visible: false,
			translatedTitle: '',
		};
	}

	async componentDidMount() {
		const { referral, currentUser } = this.props;
		const jobTitle = get(referral, 'title');
		const newTitle = jobTitle;
		this.setState({
			translatedTitle: newTitle,
		});
	}

	componentDidUpdate(prevProps) {
		if (
			prevProps.currentUser.languageCode !== this.props.currentUser.languageCode
		) {
			const { referral, currentUser } = this.props;
			const jobTitle = get(referral, 'title');
			const newTitle = jobTitle;
			this.setState({
				translatedTitle: newTitle,
			});
		}
	}

	handleCancel = () => {
		this.setState({
			visible: false,
		});
	};

	showModal = () => {
		this.setState({
			visible: true,
		});
	};

	render() {
		const {
			currentTieredBonus,
			currentUser,
			referral,
			currentCurrencyRate,
			currentCurrencySymbol,
			referredByText,
			referredOnText,
			statusText,
			bonusDetailsText,
			jobText,
			referredCandidateText,
			totalBonusText,
			hiredDateText,
			startDateText,
			bonusPaymentsText,
			bonusNotesText,
			closeText,
			viewBonusDetailsText,
			referredText,
			acceptedText,
			hiredText,
			notHiredText,
			noResponseText,
			inActiveText,
			allMultiLingualData,
			ownershipSettings,
		} = this.props;

		const contactIncentiveBonus = get(
			this.props,
			'employee.company.contactIncentiveBonus'
		);
		const contacts = get(this.props, 'employee.contacts');
		const { department, location, referralBonus, status } = referral;
		const referrals = get(this.props, 'referral.referrals', []);
		const { translatedTitle } = this.state;
		const locationDetails = parse(location);
		const referralBonusDetails = parse(referralBonus);
		const city = get(locationDetails, 'city');
		const state = get(locationDetails, 'state');
		const amount = get(referralBonusDetails, 'amount');
		let userGroupCurrency = get(currentUser, 'userGroup.currency');
		const referralStatusText = get(referral, 'customStatus', 'Interviewing');
		if (userGroupCurrency === null) userGroupCurrency = 'USD';
		const symbol = getSymbolFromCurrency(userGroupCurrency);
		const referralStatus = (status) => {
			switch (status) {
				case 'accepted': {
					return 'Accepted';
				}

				case 'hired': {
					return 'Hired';
				}

				case 'referred': {
					return 'Referred';
				}

				case 'notHired': {
					return 'Not Hired';
				}

				case 'interviewing': {
					return 'Interviewing';
				}

				case 'declined': {
					return 'Declined';
				}

				case 'noresponse': {
					return 'No Response';
				}

				case 'inactive': {
					return 'Inactive';
				}

				case 'transferred': {
					return 'Transferred';
				}

				default: {
					return null;
				}
			}
		};

		const languageCode = get(currentUser, 'languageCode', 'US');
		const dateFormat = get(currentUser, 'dateFormat', 'American');
		const referralDate = formatDate(
			get(this.props, 'referral.referralDate'),
			languageCode,
			dateFormat
		);
		const hireDate = get(this.props, 'referral.hireDate');

		if (ownershipSettings) {
			ownershipSettings.expiryDate = formatDate(
				get(this.props, 'referral.ownershipEndDate'),
				languageCode,
				dateFormat
			);
		}

		let myBonusReferral = referral;
		if (referrals.length > 0) {
			const foundMyReferral = referrals.find(
				(f) => f.userId == (referral.user ? referral.user.id : null)
			);
			if (foundMyReferral) {
				myBonusReferral = foundMyReferral;
			}
		}

		return (
			<>
				<Card className="rd-card">
					<div className="rd-card-top">
						<div className="rdc-left">
							{translatedTitle && (
								<h4 className="rdc-name">
									<Link to={`/browsejobs/${this.props.referral.id}`}>
										{translatedTitle}
									</Link>
								</h4>
							)}
							<ul>
								{department && (
									<li>
										<i className="icon-folder" />
										<span>{department ? department.name : ''}</span>
									</li>
								)}
								<li>
									<JobLocation
										allMultiLingualData={allMultiLingualData}
										currentUser={currentUser}
										location={location}
										locations={[]}
									/>
								</li>
							</ul>
							{referralBonus && !currentTieredBonus && (
								<p className="rd-card-bonus">
									{this.props.referralType === 'self' ? (
										<span className="text-orange">Self Referred</span>
									) : (
										<span className="text-green">
											{`${symbol}${calculateReferralBonus(
												contactIncentiveBonus,
												amount,
												contacts,
												this.props.currentTieredBonus,
												'employee',
												get(currentUser, 'userGroupId')
											)}`.replaceAll(/\B(?=(\d{3})+(?!\d))/g, ',')}
										</span>
									)}
								</p>
							)}
						</div>
						<div className="rdc-right">
							<span className="rdc-referred">
								{referredByText}
								<Link to={`/employees/${get(referral, 'user.id', '')}`}>
									<>
										{get(referral, 'user.firstName')}{' '}
										{get(referral, 'user.lastName')}
									</>
								</Link>
							</span>
							<span className="rdc-referred-date">
								<OwnershipPeriodTooltip ownershipSettings={ownershipSettings} />
								<Tooltip
									placement="top"
									title={`Referred on ${referralDate} by ${get(
										referral,
										'referralType'
									)}`}
								>
									{get(referral, 'referralType') === 'email' && (
										<i
											className="icon-envelope-outline"
											style={{ fontSize: 16 }}
										/>
									)}
									{get(referral, 'referralType') === 'text' && (
										<i
											className="icon-message-outline"
											style={{ fontSize: 16 }}
										/>
									)}
									{get(referral, 'referralType') === '' && (
										<i
											className="icon-telephone-outline"
											style={{ fontSize: 16 }}
										/>
									)}
									{referral.referralType &&
										get(referral, 'referralType').includes('share') && (
											<i className="icon-share" />
										)}
									<span>{referralDate}</span>
								</Tooltip>
							</span>
							{hireDate === null ? null : (
								<span className="rdc-referred">
									Hired on
									<span className="text-gray">
										{formatDate(hireDate, languageCode, dateFormat)}
									</span>
								</span>
							)}
						</div>
					</div>
					<div className="rdc-spacer" />
					<div className="rdc-status-dropdown">
						<p className="status-dropdown-label">
							{statusText}:{' '}
							<span style={conditionalStatusStyle(referral.status)}>
								{mapReferralStatus(referral, currentUser?.company)}
							</span>
						</p>
						{status === 'hired' ? (
							<Button type="primary" onClick={() => this.showModal()}>
								{viewBonusDetailsText}
							</Button>
						) : (
							<></>
						)}
					</div>
					<div className="my-progressbar-wrap">
						<ProgressIndicator
							type="referral"
							currentUser={currentUser}
							points={5}
							status={status}
							referral={referral}
							referredText={referredText}
							acceptedText={acceptedText}
							interviewingText={
								get(this.props, 'referral.customStatus') !== null &&
								get(this.props, 'referral.customStatus') !== undefined
									? get(this.props, 'referral.customStatus')
									: get(currentUser, 'company.referralStatus') === null
										? ml('Interviewing', currentUser, allMultiLingualData)
										: get(
												currentUser,
												'company.referralStatus',
												ml('Interviewing', currentUser, allMultiLingualData)
											)
							}
							hiredText={hiredText}
							notHiredText={notHiredText}
							noResponseText={noResponseText}
							inActiveText={inActiveText}
						/>
					</div>
				</Card>
				{get(referral, 'referralType') !== 'self' && (
					<Modal
						footer={null}
						title="Bonus Details"
						open={this.state.visible}
						onCancel={this.handleCancel}
					>
						<MyReferralsBonusDetailsModal
							currentTieredBonus={this.props.currentTieredBonus}
							history={this.props.history}
							status={status}
							userGroup={get(referral, 'user.userGroupId')}
							currentCurrencyRate={currentCurrencyRate}
							currentCurrencySymbol={currentCurrencySymbol}
							data={myBonusReferral}
							handleCancel={this.handleCancel}
							bonusDetailsText={bonusDetailsText}
							jobText={jobText}
							referredCandidateText={referredCandidateText}
							totalBonusText={totalBonusText}
							hiredDateText={hiredDateText}
							startDateText={startDateText}
							bonusPaymentsText={bonusPaymentsText}
							bonusNotesText={bonusNotesText}
							closeText={closeText}
							currentUser={currentUser}
							translatedTitle={translatedTitle}
						/>
					</Modal>
				)}
			</>
		);
	}
}

export default withRouter(ReferralCard);
