import { Button } from 'antd';
import get from 'lodash/get';
import { ONBOARDING_SCREENS } from '../constants';
import {
	buttonContainerStyle,
	buttonStyle,
	containerStyle,
	subHeaderStyle,
	subtitleStyle,
	textBodyStyle,
	titleContainerStyle,
	titleStyle,
	videoWrap,
} from './onboardingStyles.js';

function LearnMoreAdmin(props) {
	const {
		company,
		completeOnboardingPage,
		uncompleteOnboardingPage,
		prevPage,
		nextPage,
		navToScreen,
	} = props;
	const partnerHost = get(company, 'partnerHost');

	const next = (e) => {
		completeOnboardingPage(
			ONBOARDING_SCREENS.LEARN_MORE_ADMIN,
			{
				dateCompleted: new Date().toISOString(),
			},
			true
		);
	};

	return (
		<div className={containerStyle}>
			<div className={titleContainerStyle(partnerHost)}>
				<h1 className={titleStyle}>Get Started Now</h1>

				<div className={textBodyStyle}>
					<div className={subtitleStyle}>
						You are just one step away from engaging your employees and growing
						your employee referrals!
					</div>

					<div
						className={subHeaderStyle}
						style={{ marginBottom: 20, textAlign: 'center' }}
					>
						Visit our knowledge base {}
						<a
							href="https://mitratech.my.site.com/talentreef/s/topic/0TO8Z000000ibG4WAI/talentreef-referrals"
							target="_blank"
							rel="noreferrer"
						>
							here
						</a>
					</div>
					<div className={buttonContainerStyle}>
						<Button
							disabled={false}
							type="primary"
							shape="round"
							size="large"
							className={buttonStyle}
							onClick={next}
						>
							Finish
						</Button>
					</div>
					<div style={{ height: 40 }} />
				</div>
			</div>
		</div>
	);
}

function LearnMoreAdminVideo() {
	return (
		<div className={videoWrap}>
			<iframe
				allowFullScreen
				src="https://player.vimeo.com/video/747752974"
				width="800"
				height="500"
				frameBorder="0"
				allow="autoplay; fullscreen"
			/>
		</div>
	);
}

export default LearnMoreAdmin;
