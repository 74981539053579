import gql from 'graphql-tag';

export const GetCompanyByName = gql`
	query GetCompanyByName($name: String!) {
		getCompanyByName(name: $name) {
			id
			accountType
			name
			defaultBonusAmount
			departments {
				id
				companyId
				name
				keywords
				active
			}
			dashboardReferralPolicyText
			bonusEarnedNote
			allowSelfReferrals
			allowInternalMobility
			internalMobilityImage {
				bucket
				region
				key
			}
			contactIncentiveBonus
			sendAdminNotificationsOnReferral
			sendAdminNotificationsOnInternalCandidatesFound
			referralBonusNotificationRecipients
			brandColor
			websiteUrl
			disableReferrals
			sftpFolderName
			logo {
				bucket
				region
				key
			}
			symbol {
				bucket
				region
				key
			}
			symbol {
				bucket
				region
				key
			}
			theme
			userGroups {
				id
				companyId
				measurement
				name
				keywords
				active
				currency
				languageCode
			}
			background {
				bucket
				region
				key
			}
			errorImage {
				bucket
				region
				key
			}
			whiteLabel
		}
	}
`;
