import { css } from 'emotion';
import get from 'lodash/get';
import { COLORS } from '../../../styles/colors';

export const ModalStyles = css({
	'& .ant-modal-content': {
		borderRadius: 20,
	},
	fontFamily: 'Open Sans',
	'& .ant-modal-close-x': {
		fontSize: 30,
		paddingTop: 10,
		color: COLORS.lightGray,
	},
});

export const ModalStylesSubmitting = css({
	'& .ant-modal-content': {
		borderRadius: 20,
		height: 750,
		width: 520,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	fontFamily: 'Open Sans',
	'& .ant-modal-close-x': {
		color: 'white',
	},
});

export const AddContactsHere = css({
	color: COLORS.lightGray,
	fontWeight: 400,
});

export const JobInfoContainer = css({
	padding: 20,
	paddingBottom: 10,
	backgroundColor: COLORS.lightGreen,
	borderRadius: 8,
	marginBottom: 15,
});

export const JobInfo = css({
	justifyContent: 'space-between',
	marginBottom: 0,
});

export const JobTitle = css({
	fontSize: 18,
	color: COLORS.blue,
	marginBottom: 0,
});

export const Currency = css({
	color: COLORS.green,
	fontSize: 26,
	marginBottom: 0,
	textAlign: 'right',
	padding: 0,
});

export const JobIcon = css({
	fontSize: 20,
	color: COLORS.darkGray,
	paddingRight: 3,
});

export const Flex = css({
	display: 'flex',
	marginBottom: 0,
});

export const ReferralBonus = css({
	marginBottom: 0,
});

export const addPaddingRight = css({
	paddingRight: 15,
});

export const ToggleNewContactBtn = css({
	padding: 0,
	border: 'none',
	fontSize: 11,
	color: COLORS.blue,
	fontWeight: 600,
});

export const SubmitBtn = css({
	fontFamily: 'Open Sans',
	backgroundColor: COLORS.red,
	color: 'white',
	fontSize: 20,
	fontWeight: 300,
	padding: '10px 30px',
	height: 'auto',
	border: 'none',
	marginBottom: 0,
});

export const SubmitBtnContainer = css({
	width: '100%',
	display: 'flex',
	justifyContent: 'center',
	marginBottom: 0,
	'& .ant-form-item-control-wrapper': {
		'@media (max-width: 575px)': {
			width: 'auto',
		},
	},
});

export const CheckIcon = css({
	borderRadius: 100,
	border: '1px solid white',
	padding: 2,
});

export const FormTitle = css({
	width: '100%',
	textAlign: 'center',
	fontWeight: 600,
	marginBottom: 0,
	color: COLORS.darkGray,
	fontSize: 24,
});

export const LabelStyles = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 600,
	'& span': {
		fontWeight: 300,
		fontSize: 14,
	},
});

export const TextAreaStyles = css({
	marginBottom: 10,
});

export const InputStyles = css({
	fontFamily: '"Open Sans", sans-serif',
	fontSize: 11,
});

export const AutoCompleteStyles = css({
	fontFamily: '"Open Sans", sans-serif',
});

export const FormItemStyles = css({
	marginBottom: '0 !important',
});

export const RemoveBottomMargin = css({
	'& .ant-form-item': {
		marginBottom: 0,
	},
});

export const SubmitError = css({
	marginBottom: 24,
});

export const LinkStyles = css({
	color: COLORS.blue,
	cursor: 'pointer',
});

export const ModalTitle = (theme) => {
	let buttonColor = COLORS.red;
	if (get(theme, 'enabled')) {
		buttonColor = get(theme, 'addButtonColor', buttonColor);
	}

	return css({
		color: buttonColor,
		fontSize: 32,
		marginBottom: 0,
		fontWeight: 600,
	});
};

export const ModalTitleAddReferral = css({
	color: COLORS.red,
	width: '100%',
	textAlign: 'center',
	marginTop: 2,
	fontSize: 32,
	marginBottom: 0,
	fontWeight: 600,
});

export const SmallText = css({
	fontSize: 18,
	fontWeight: 400,
	color: COLORS.lightGray,
	margin: 0,
});

export const SubTitle = css({
	textAlign: 'center',
	fontSize: 16,
	fontWeight: 500,
	color: COLORS.lightGray,
});

export const FormStyles = css({
	margin: 'auto',
});

export const alignCenter = css({
	textAlign: 'center',
});

export const roundSocial = css({
	marginLeft: '25',
	marginRight: '25',
	marginTop: 20,
	marginBottom: 10,
});

export const roundSocialRow = css({
	textAlign: 'center',
	display: 'flex',
	marginLeft: '40',
	marginRight: '40',
});

export const roundSocialTable = css({
	display: 'table',
});

export const roundSocialText = css({
	marginLeft: '20',
	marginRight: '25',
	color: COLORS.lightGray,
	fontSize: 16,
});

export const downloadRow = css({
	textAlign: 'center',
	marginTop: 25,
});

export const downloadBtnRow = css({
	textAlign: 'center',
	marginTop: 25,
	marginBottom: 25,
});

export const downloadText = css({
	color: COLORS.blue,
	fontSize: 16,
});

export const downloadIcon = css({
	fontSize: '16px',
});

export const emailField = css({
	padding: '10px',
	borderRadius: '5px',
	marginTop: '-10px',
	border: '2px solid #CCCCCC',
});

export const alignLeft = css({
	textAlign: 'left',
	color: COLORS.lightGray,
});

export const BackLink = css({
	fontFamily: '"Open Sans", sans-serif',
	color: COLORS.blue,
	border: 'none',
	backgroundColor: 'transparent',
	paddingLeft: 5,
	marginBottom: 20,
	cursor: 'pointer',
	display: 'inline-block',
});

export const BackIcon = css({
	fontSize: 20,
	verticalAlign: 'sub',
});

export const ImportButton = css({
	textAlign: 'center',
	backgroundColor: COLORS.red,
	color: 'white',
});

export const titleCancel = css({
	float: 'right',
	marginRight: '20px',
	color: COLORS.lightGray,
});

export const darkGrayText = css({
	textAlign: 'center',
	color: COLORS.darkGray,
	fontSize: 20,
});

export const darkGrayIcon = css({
	textAlign: 'center',
	color: COLORS.darkGray,
	fontSize: 32,
	marginBottom: '10px',
});

export const darkGrayTextHeavy = css({
	textAlign: 'center',
	color: COLORS.darkGray,
	fontSize: 20,
	fontWeight: 500,
});

export const downloadTextCenter = css({
	color: COLORS.blue,
	textAlign: 'center',
	marginTop: '15px',
	marginBottom: '15px',
});

export const uploadContainer = css({
	width: '280px',
	margin: 'auto',
});

export const contactContainer = css({
	border: '1px solid #e8e8e8',
	borderRadius: '4px',
	marginTop: 20,
	marginBottom: 20,
});

export const ImportContainer = css({
	marginTop: 10,
	textAlign: 'center',
});
