import gql from 'graphql-tag';
import _, { get } from 'lodash';
import { compose, graphql } from 'react-apollo';
import uuid from 'uuid/v4';
import {
	createContact,
	deleteContact,
	queryContactsByUserIdIndex,
} from '../../graphql/custom/contacts';

export const withMyContacts = (Component, variables) => {
	return compose(
		graphql(gql(queryContactsByUserIdIndex), {
			options: (props) => ({
				context: {
					headers: {
						'x-frame-options': 'deny', // This header will reach the server
					},
				},
				variables: {
					userId: props?.currentUser?.id,
				},
				fetchPolicy: 'network-only',
			}),
			props(response, previous) {
				const contacts = get(
					response,
					['data', 'queryContactsByUserIdIndex', 'items'],
					[]
				);

				const nextToken = get(
					response,
					['data', 'queryContactsByUserIdIndex', 'nextToken'],
					null
				);
				const onFetchMore = makeOnFetchMore(response.data.fetchMore, nextToken);

				return { contacts, onFetchMore, nextToken };
			},
		}),
		graphql(gql(createContact), {
			props: (props) => ({
				ImportedCreateContact(input) {
					const optimisticResponseData = {
						id: uuid(),
						...input.input,
						__typename: 'Contact',
					};
					return props.mutate({
						variables: input,
						optimisticResponse: {
							__typename: 'Mutation',
							createContact: {
								__typename: 'createContact',
								...optimisticResponseData,
							},
						},
						update(store, { data: { createContact } }) {
							try {
								const data = store.readQuery({
									query: gql(queryContactsByUserIdIndex),
									variables: { userId: props.ownProps?.currentUser?.id },
								});
								if (
									!data.queryContactsByUserIdIndex.items.find(
										(contact) => contact.id === createContact.id
									)
								)
									data.queryContactsByUserIdIndex.items.push(createContact);
								store.writeQuery({
									query: gql(queryContactsByUserIdIndex),
									data,
								});
							} catch (error) {
								console.log(error);
							}
						},
					});
				},
			}),
		}),
		graphql(gql(deleteContact), {
			props: (props) => ({
				onDeleteContact(input) {
					const optimisticResponseData = {
						...props.contact,
						...input.input,
					};
					props.mutate({
						variables: input,
						optimisticResponse: {
							__typeName: 'Mutation',
							deleteContact: {
								...optimisticResponseData,
								__typeName: 'deleteContact',
							},
						},
						update(store, { data: { deleteContact } }) {
							try {
								const data = store.readQuery({
									query: gql(queryContactsByUserIdIndex),
									variables: { userId: props.ownProps?.currentUser?.id },
								});
								_.remove(data, (contact) => contact.id === createContact.id);
								store.writeQuery({
									query: gql(queryContactsByUserIdIndex),
									data,
								});
							} catch (error) {
								console.log(error);
							}
						},
					});
				},
			}),
		})
	)(Component);
};

const makeOnFetchMore = (fetchMore, nextToken) => {
	if (!nextToken) {
		return null;
	}

	return () => {
		fetchMore({
			variables: { after: nextToken },
			updateQuery(previous, { fetchMoreResult }) {
				if (!fetchMoreResult) {
					return previous;
				}

				return {
					...previous,
					loading: false,
					queryContactsByUserIdIndex: {
						...previous.queryContactsByUserIdIndex,
						...fetchMoreResult.queryContactsByUserIdIndex,
						items: [
							...previous.queryContactsByUserIdIndex.items,
							...fetchMoreResult.queryContactsByUserIdIndex.items,
						],
					},
				};
			},
		});
	};
};
