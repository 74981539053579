import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Collapse, Input, InputNumber, Select, Tooltip } from 'antd';
import getSymbolFromCurrency from 'currency-symbol-map';
import _, { get } from 'lodash';
import { Component } from 'react';
import {
	camelCase,
	lowerCase,
	parse,
	strip,
} from '../../_shared/services/utils.js';
import { CategoryItem } from './category-items/CategoryItemComponent.jsx';

class ReferralTiers extends Component {
	constructor(props) {
		super(props);
		this.state = {
			tiers: [],
			exclusions: [],
			inclusions: [],
		};
	}

	componentDidMount() {
		const { currentTiers = [], userGroups = [] } = this.props;
		const userGroup = userGroups.filter((group) => group.name === 'Default');
		let userGroupId = null;
		if (userGroup && userGroup[0]) {
			userGroupId = userGroup[0].id;
		}

		let tier = {
			userGroup: userGroupId,
		};
		tier = JSON.stringify(tier);
		const tiers = currentTiers.length === 0 ? [tier] : currentTiers;
		const exclusions = this.getExclusions(userGroupId, tiers);
		const inclusions = this.getInclusions(userGroupId, tiers);
		this.setState({
			tiers,
			exclusions,
			inclusions,
			userGroup: userGroupId,
		});
	}

	getExclusions = (group, allTiers) => {
		try {
			const currentTiers = allTiers ? allTiers : get(this.state, 'tiers', []);
			const tiers = currentTiers.map((tier) => JSON.parse(tier));
			const exclusions = tiers.find((tier) => tier.userGroup === group);
			const result = exclusions.rules.exclusions;
			return result ? result : [];
		} catch {
			return [];
		}
	};

	getGroupCurrency = (userGroup) => {
		const { userGroups = [] } = this.props;
		const foundGroup = userGroups.find((group) => group.id === userGroup);
		return get(foundGroup, 'currency', '');
	};

	getGroupName = (userGroup) => {
		const { userGroups = [] } = this.props;
		const foundGroup = userGroups.find((group) => group.id === userGroup);
		return get(foundGroup, 'name', '');
	};

	getInclusions = (group, allTiers) => {
		try {
			const currentTiers = allTiers ? allTiers : get(this.state, 'tiers', []);
			const tiers = currentTiers.map((tier) => JSON.parse(tier));
			const inclusions = tiers.find((tier) => tier.userGroup === group);
			const result = inclusions.rules.inclusions;
			return result ? result : [];
		} catch {
			return [];
		}
	};

	handleAddDiversityBonus = (tier, index) => {
		const { tiers } = this.state;
		const newTier = {
			...tier,
			rules: { diversityHire: { active: true, amount: null } },
		};
		const newTiers = tiers;
		newTiers[index] = JSON.stringify(newTier);
		this.setState({ tiers: newTiers });
	};

	handleAddExclusionRule = (group) => {
		const { exclusions = [] } = this.state;
		const rule = { userGroup: group };
		this.setState({
			exclusions: [...exclusions, rule],
		});
	};

	handleAddInclusionRule = (group) => {
		const { inclusions = [] } = this.state;
		const rule = { userGroup: group };
		this.setState({
			inclusions: [...inclusions, rule],
		});
	};

	handleAddTier = (group) => {
		const { tiers } = this.state;
		let tier = { userGroup: group };
		tier = JSON.stringify(tier);

		this.setState({
			tiers: [...tiers, tier],
		});
	};

	handleRemoveDiversityBonus = (tier, index) => {
		const { tiers } = this.state;
		const newTier = tier;
		delete newTier.rules.diversityHire;
		const newTiers = tiers;
		newTiers[index] = JSON.stringify(newTier);
		this.setState({ tiers: newTiers });
	};

	handleRemoveExclusionRuleClick = (itemIndex) => {
		const { exclusions = [] } = this.state;
		const copy = [...exclusions];
		copy.splice(itemIndex, 1);
		this.setState({
			exclusions: copy,
		});
	};

	handleRemoveInclusionRuleClick = (itemIndex) => {
		const { inclusions = [] } = this.state;
		const copy = [...inclusions];
		copy.splice(itemIndex, 1);
		this.setState({
			inclusions: copy,
		});
	};

	handleRemoveTierClick = (itemIndex) => {
		const { tiers } = this.state;
		const copy = [...tiers];
		copy.splice(itemIndex, 1);
		this.setState({
			tiers: copy,
		});
	};

	renderExcludeCategory = (group, index) => {
		const { exclusions = [] } = this.state;
		const rule = exclusions[index];
		const category = rule.type;
		const { value } = rule;
		if (category === 'Department') {
			return this.renderExcludePicker(group, index, category, value);
		}

		if (category === 'Role') {
			return this.renderExcludePicker(group, index, category, value);
		}

		if (category === 'Job Classification') {
			return this.renderExcludePicker(group, index, category, value);
		}

		if (category === 'Job Profile') {
			return this.renderExcludePicker(group, index, category, value);
		}

		if (category === 'Management Level') {
			return this.renderExcludePicker(group, index, category, value);
		}

		if (category === 'Job Family') {
			return this.renderExcludePicker(group, index, category, value);
		}

		if (category === 'Employee Type') {
			return this.renderExcludePicker(group, index, category, value);
		}

		return null;
	};

	renderExcludePicker = (group, index, key, value) => {
		const { getFieldDecorator } = this.props.form;
		const { exclusions, tiers } = this.state;
		let categories = value;
		categories = categories ? categories : [];
		const stateKey = `${camelCase(key)}Categories`;
		const callback = (value) => this.setState({ [stateKey]: value });
		return (
			<div
				key={`tier-${group}-${index}-${strip(lowerCase(key))}-excludepicker`}
				className="bs-form-grp"
			>
				<div className="custom-form-group">
					<label className="bs-label">Create {key}: </label>
					{getFieldDecorator(
						`tier-${group}-${index}-${strip(lowerCase(key))}-excludepicker`,
						{ initialValue: '' }
					)(
						<Input
							className="custom-input"
							size="small"
							placeholder={`Enter ${key}`}
							onPressEnter={(e) => {
								const { value } = e.target;
								categories.push(value);
								const exclusion = exclusions.find(
									(ex) =>
										get(ex, 'type') === key && get(ex, 'userGroup') === group
								);
								const exclusionIndex = exclusions.findIndex(
									(ex) =>
										get(ex, 'type') === key && get(ex, 'userGroup') === group
								);
								const newExclusions = exclusions;
								if (exclusion) {
									exclusion.value = categories;
									newExclusions[exclusionIndex] = exclusion;
									const updatedTiers = tiers.map((tier) => {
										const newTier = parse(tier);
										if (newTier.userGroup === group) {
											if (get(newTier, 'rules')) {
												newTier.rules.exclusions = newExclusions;
											} else {
												newTier.rules = { exclusions: newExclusions };
											}
										}

										return JSON.stringify(newTier);
									});
									if (updatedTiers) this.setState({ tiers: updatedTiers });
								}

								this.setState({
									[stateKey]: categories,
									exclusions: newExclusions,
								});
								this.props.updateExclusions(newExclusions);
								this.props.form.setFieldsValue({
									[`tier-${group}-${index}-${strip(
										lowerCase(key)
									)}-excludepicker`]: '',
								});
								this.props.form.setFieldsValue({
									[`tier-${group}-${index}-${strip(
										lowerCase(key)
									)}-excludepickervalue`]: newExclusions,
								});
							}}
						/>
					)}
				</div>
				<div style={{ marginLeft: 20 }}>
					<CategoryItem
						categories={categories}
						callback={callback}
						exclusions={exclusions}
					/>
				</div>
			</div>
		);
	};

	renderExclusions = (group, index) => {
		const { getFieldDecorator, tierKeyBase, theme } = this.props;
		const { exclusions = [] } = this.state;

		const options = [];

		if (
			!exclusions.find(
				(exc) => exc.type === 'Department' && exc.userGroup === group
			)
		)
			options.push(
				<Select.Option value="Department">Department</Select.Option>
			);
		if (
			!exclusions.find(
				(exc) => exc.type === 'Employee Type' && exc.userGroup === group
			)
		)
			options.push(
				<Select.Option value="Employee Type">Employee Type</Select.Option>
			);
		if (
			!exclusions.find(
				(exc) => exc.type === 'Job Classification' && exc.userGroup === group
			)
		)
			options.push(
				<Select.Option value="Job Classification">
					Job Classification
				</Select.Option>
			);
		if (
			!exclusions.find(
				(exc) => exc.type === 'Job Family' && exc.userGroup === group
			)
		)
			options.push(
				<Select.Option value="Job Family">Job Family</Select.Option>
			);
		if (
			!exclusions.find(
				(exc) => exc.type === 'Job Profile' && exc.userGroup === group
			)
		)
			options.push(
				<Select.Option value="Job Profile">Job Profile</Select.Option>
			);
		if (
			!exclusions.find(
				(exc) => exc.type === 'Management Level' && exc.userGroup === group
			)
		)
			options.push(
				<Select.Option value="Management Level">Management Level</Select.Option>
			);
		if (
			!exclusions.find((exc) => exc.type === 'Role' && exc.userGroup === group)
		)
			options.push(<Select.Option value="Role">Role</Select.Option>);

		return exclusions.map((rule, index) => {
			if (typeof rule === 'string') {
				rule = parse(rule);
			}

			const disabled = get(rule, 'value', []).length > 0;
			return (
				rule.userGroup === group && (
					<Collapse
						key={'exclusion' + group + index}
						defaultActiveKey={['0']}
						expandIconPosition="start"
						collapsible="header"
						bordered={false}
						className="bs-form-collapse"
					>
						<Collapse.Panel
							key="0"
							className="bs-form-collapse-card"
							expandIconPosition="start"
							collapsible="disabled"
							header={
								<div className="bs-form-grp">
									<i className="icon-arrow-down bs-form-collapse-icon" />
									<div className="custom-form-group">
										<label className="bs-label">Exclude employees by</label>
										{getFieldDecorator(
											`tier-${tierKeyBase}-${strip(
												lowerCase(rule.type)
											)}-${index}-exclusionscategory`,
											{
												initialValue: rule.type || null,
											}
										)(
											<Select
												placeholder="Category"
												className="custom-input"
												style={{ width: 180 }}
												disabled={disabled}
												onSelect={(value) => {
													const newRule = rule;
													newRule.type = value;
													const newExclusions = exclusions;
													newExclusions[index] = newRule;
													this.setState({ exclusions: newExclusions });
													this.props.updateExclusions(newExclusions);
												}}
											>
												{options}
											</Select>
										)}
										{getFieldDecorator(
											`tier-${tierKeyBase}-${index}-exclusions`,
											{
												initialValue: exclusions,
											}
										)(<Input type="hidden" className="custom-input" />)}
									</div>
									<Button
										type="link"
										className="btn-link danger"
										onClick={() => this.handleRemoveExclusionRuleClick(index)}
									>
										<i className="icon-bin" />
										Delete this rule
									</Button>
								</div>
							}
						>
							{this.renderExcludeCategory(group, index)}
						</Collapse.Panel>
					</Collapse>
				)
			);
		});
	};

	renderIncludeCategory = (group, index) => {
		const { inclusions = [] } = this.state;
		const rule = inclusions[index];
		const category = rule.type;
		const { value } = rule;
		if (category === 'Department') {
			return this.renderIncludePicker(group, index, category, value);
		}

		if (category === 'Role') {
			return this.renderIncludePicker(group, index, category, value);
		}

		if (category === 'Job Classification') {
			return this.renderIncludePicker(group, index, category, value);
		}

		if (category === 'Job Profile') {
			return this.renderIncludePicker(group, index, category, value);
		}

		if (category === 'Management Level') {
			return this.renderIncludePicker(group, index, category, value);
		}

		if (category === 'Job Family') {
			return this.renderIncludePicker(group, index, category, value);
		}

		if (category === 'Employee Type') {
			return this.renderExcludePicker(group, index, category, value);
		}

		return null;
	};

	renderIncludePicker = (group, index, key, value) => {
		const { getFieldDecorator } = this.props.form;
		const { inclusions, tiers } = this.state;
		let categories = value;
		categories = categories ? categories : [];
		const stateKey = `${camelCase(key)}Categories`;
		const callback = (value) => this.setState({ [stateKey]: value });
		return (
			<div
				key={`tier-${group}-${index}-${strip(lowerCase(key))}-includepicker`}
				className="bs-form-grp"
			>
				<div className="custom-form-group">
					<label className="bs-label">Create {key}: </label>
					{getFieldDecorator(
						`tier-${group}-${index}-${strip(lowerCase(key))}-includepicker`,
						{ initialValue: '' }
					)(
						<Input
							className="custom-input"
							size="small"
							placeholder={`Enter ${key}`}
							onPressEnter={(e) => {
								const { value } = e.target;
								categories.push(value);
								const inclusion = inclusions.find(
									(ex) =>
										get(ex, 'type') === key && get(ex, 'userGroup') === group
								);
								const inclusionIndex = inclusions.findIndex(
									(ex) =>
										get(ex, 'type') === key && get(ex, 'userGroup') === group
								);
								const newInclusions = inclusions;
								if (inclusion) {
									inclusion.value = categories;
									newInclusions[inclusionIndex] = inclusion;
									const updatedTiers = tiers.map((tier) => {
										const newTier = parse(tier);
										if (newTier.userGroup === group) {
											if (get(newTier, 'rules')) {
												newTier.rules.inclusions = newInclusions;
											} else {
												newTier.rules = { inclusions: newInclusions };
											}
										}

										return JSON.stringify(newTier);
									});
									if (updatedTiers) this.setState({ tiers: updatedTiers });
								}

								this.setState({
									[stateKey]: categories,
									inclusions: newInclusions,
								});
								this.props.updateExclusions(newInclusions);
								this.props.form.setFieldsValue({
									[`tier-${group}-${index}-${strip(
										lowerCase(key)
									)}-includepicker`]: '',
								});
								this.props.form.setFieldsValue({
									[`tier-${group}-${index}-${strip(
										lowerCase(key)
									)}-includepickervalue`]: newInclusions,
								});
							}}
						/>
					)}
				</div>
				<div style={{ marginLeft: 20 }}>
					<CategoryItem
						categories={categories}
						callback={callback}
						exclusions={inclusions}
					/>
				</div>
			</div>
		);
	};

	renderInclusions = (group, index) => {
		const { getFieldDecorator, tierKeyBase, theme } = this.props;
		const { inclusions = [] } = this.state;

		const options = [];

		if (
			!inclusions.find(
				(exc) => exc.type === 'Department' && exc.userGroup === group
			)
		)
			options.push(
				<Select.Option value="Department">Department</Select.Option>
			);
		if (
			!inclusions.find(
				(exc) => exc.type === 'Employee Type' && exc.userGroup === group
			)
		)
			options.push(
				<Select.Option value="Employee Type">Employee Type</Select.Option>
			);
		if (
			!inclusions.find(
				(exc) => exc.type === 'Job Classification' && exc.userGroup === group
			)
		)
			options.push(
				<Select.Option value="Job Classification">
					Job Classification
				</Select.Option>
			);
		if (
			!inclusions.find(
				(exc) => exc.type === 'Job Family' && exc.userGroup === group
			)
		)
			options.push(
				<Select.Option value="Job Family">Job Family</Select.Option>
			);
		if (
			!inclusions.find(
				(exc) => exc.type === 'Job Profile' && exc.userGroup === group
			)
		)
			options.push(
				<Select.Option value="Job Profile">Job Profile</Select.Option>
			);
		if (
			!inclusions.find(
				(exc) => exc.type === 'Management Level' && exc.userGroup === group
			)
		)
			options.push(
				<Select.Option value="Management Level">Management Level</Select.Option>
			);
		if (
			!inclusions.find((exc) => exc.type === 'Role' && exc.userGroup === group)
		)
			options.push(<Select.Option value="Role">Role</Select.Option>);

		return inclusions.map((rule, index) => {
			if (typeof rule === 'string') {
				rule = parse(rule);
			}

			const disabled = get(rule, 'value', []).length > 0;
			return (
				rule.userGroup === group && (
					<Collapse
						key={'inclusion' + group + index}
						defaultActiveKey={['0']}
						collapsible="header"
						bordered={false}
						expandIconPosition="start"
						className="bs-form-collapse"
					>
						<Collapse.Panel
							key="0"
							className="bs-form-collapse-card"
							collapsible="disabled"
							header={
								<div key={index} className="bs-form-grp">
									<i className="icon-arrow-down bs-form-collapse-icon" />
									<div className="custom-form-group">
										<label className="bs-label">Include employees by</label>
										{getFieldDecorator(
											`tier-${tierKeyBase}-${strip(
												lowerCase(rule.type)
											)}-${index}-inclusionscategory`,
											{
												initialValue: rule.type || null,
											}
										)(
											<Select
												placeholder="Category"
												className="custom-input"
												style={{ width: 180 }}
												disabled={disabled}
												onSelect={(value) => {
													const newRule = rule;
													newRule.type = value;
													const newInclusions = inclusions;
													newInclusions[index] = newRule;
													this.setState({ inclusions: newInclusions });
													this.props.updateInclusions(newInclusions);
												}}
											>
												{options}
											</Select>
										)}
										{getFieldDecorator(
											`tier-${tierKeyBase}-${index}-inclusions`,
											{
												initialValue: inclusions,
											}
										)(<Input hidden type="text" className="custom-input" />)}
									</div>
									<Button
										type="link"
										className="btn-link danger"
										onClick={() => this.handleRemoveInclusionRuleClick(index)}
									>
										<i className="icon-bin" />
										Delete this rule
									</Button>
								</div>
							}
						>
							{this.renderIncludeCategory(group, index)}
						</Collapse.Panel>
					</Collapse>
				)
			);
		});
	};

	renderTierInputs = (group) => {
		const {
			getFieldDecorator,
			tierKeyBase,
			daysText,
			afterText,
			daysFromHireDateText,
			bonusAmountText,
			candidateText,
			employeeText,
			deletePaymentText,
			theme,
		} = this.props;
		const { tiers = [] } = this.state;
		const currencyCode = this.getGroupCurrency(group);
		return tiers.map((tier, index) => {
			if (typeof tier === 'string') {
				tier = parse(tier);
			}

			return (
				tier.userGroup === group && (
					<div key={index} className="bs-form-grp">
						<div className="custom-form-group">
							<label className="bs-label">
								{getSymbolFromCurrency(currencyCode)}
							</label>
							{getFieldDecorator(`tier-${tierKeyBase}-${index}-usergroup`, {
								initialValue: group,
							})(
								<Input
									hidden
									className="custom-input"
									placeholder={bonusAmountText}
								/>
							)}
							{getFieldDecorator(`tier-${tierKeyBase}-${index}`, {
								initialValue: tier.amount,
								rules: [{ required: true, message: 'Enter a bonus amount.' }],
							})(
								<InputNumber
									min={0}
									className="custom-input"
									placeholder={bonusAmountText}
								/>
							)}
						</div>
						<div className="custom-form-group">
							<label className="bs-label">{afterText}</label>
							<div className="cf-addon-wrap">
								{getFieldDecorator(`tier-${tierKeyBase}-${index}-payoutdays`, {
									initialValue: tier.payOutDays,
									rules: [{ required: true, message: 'Enter pay out days.' }],
								})(
									<InputNumber
										min={0}
										className="custom-input"
										placeholder={daysFromHireDateText}
									/>
								)}
								<div className="cf-addon">days</div>
							</div>
						</div>
						<div className="custom-form-group">
							<label className="bs-label">payable to</label>
							{getFieldDecorator(`tier-${tierKeyBase}-${index}-recipienttype`, {
								initialValue: tier.recipientType || 'employee',
							})(
								<Select placeholder="Recipient Type" className="custom-input">
									<Select.Option value="candidate">
										{candidateText}
									</Select.Option>
									<Select.Option value="employee">{employeeText}</Select.Option>
								</Select>
							)}
						</div>
						{!get(tier, 'rules.diversityHire.active') && (
							<Button
								type="link"
								className="add-btn"
								size="small"
								onClick={() => this.handleAddDiversityBonus(tier, index)}
							>
								<span className="icon-circle">
									<i className="icon-plus" />
								</span>
								Add Diversity Incentive
							</Button>
						)}
						{get(tier, 'rules.diversityHire.active') && (
							<div className="custom-form-group">
								<Tooltip title="Cancel Diversity Incentive.">
									<Button
										type="link"
										className="btn-link danger"
										onClick={() => this.handleRemoveDiversityBonus(tier, index)}
									>
										<i className="icon-bin" />
									</Button>
								</Tooltip>
								<label className="bs-label">
									{`Diversity Hire Amount:`}{' '}
									{getSymbolFromCurrency(currencyCode)}
								</label>
								{getFieldDecorator(
									`tier-${tierKeyBase}-${index}-diversityincentive`,
									{
										initialValue: get(tier, 'rules.diversityHire.amount'),
										rules: [
											{ required: true, message: 'Enter a bonus amount.' },
										],
									}
								)(
									<InputNumber
										min={0}
										className="custom-input"
										placeholder="Amount"
									/>
								)}
							</div>
						)}

						{index > 0 && (
							<Tooltip title="Delete this tier.">
								<Button
									type="link"
									className="btn-link danger"
									onClick={() => this.handleRemoveTierClick(index)}
								>
									<i className="icon-bin" />
								</Button>
							</Tooltip>
						)}
					</div>
				)
			);
		});
	};

	renderUserGroup = (group, index) => {
		const { theme } = this.props;
		return (
			<div key={'rendeug' + group + index}>
				<h6 className="bs-form-label">
					{this.getGroupName(group)} {this.props.groupText}:
				</h6>
				<div className="bs-form-grp">
					<div className="custom-form-group">
						<label className="bs-label">Exclusions:</label>
						<Button
							type="link"
							className="add-btn"
							size="small"
							onClick={() => this.handleAddExclusionRule(group)}
						>
							<span className="icon-circle">
								<i className="icon-plus" />
							</span>
							Add Exclusion Rule
						</Button>
					</div>
				</div>
				{this.renderExclusions(group, index)}
				<div className="bs-form-grp">
					<div className="custom-form-group">
						<label className="bs-label">Inclusions:</label>
						<Button
							type="link"
							className="add-btn"
							size="small"
							onClick={() => this.handleAddInclusionRule(group)}
						>
							<span className="icon-circle">
								<i className="icon-plus" />
							</span>
							Add Inclusion Rule
						</Button>
					</div>
				</div>
				{this.renderInclusions(group, index)}
				<h6 className="bs-form-label">Payments:</h6>
				{this.renderTierInputs(group)}
				<Button
					type="primary"
					size="large"
					onClick={() => this.handleAddTier(group)}
				>
					{`${this.props.addBonusText}`}
				</Button>
			</div>
		);
	};

	renderUserGroups = () => {
		const { tiers } = this.state;
		let groups = [];
		for (const tier of tiers) groups.push(get(parse(tier), 'userGroup'));
		groups = _.uniq(groups);
		const allUserGroups = [];
		for (const [index, group] of groups.entries())
			allUserGroups.push(this.renderUserGroup(group, index));
		return allUserGroups;
	};

	render() {
		const {
			userGroups = [],
			bonusPaymentsText,
			addGroupBonusText,
			chooseEmployeeGroupText,
			theme,
		} = this.props;
		const { userGroup } = this.state;
		const options = [];
		for (const [index, group] of _.sortBy(userGroups, [
			(group) => group.name,
		]).entries()) {
			const option = (
				<Select.Option key={'usrG' + index} value={group.id}>
					{group.name}
				</Select.Option>
			);
			options.push(option);
		}

		return (
			<>
				<h6 className="bs-form-label">{bonusPaymentsText}</h6>
				<div className="bs-form-grp">
					<div className="custom-form-group">
						<label className="bs-label">{chooseEmployeeGroupText}:</label>
						<Select
							defaultValue={userGroup}
							value={userGroup}
							className="custom-input"
							onChange={(userGroup) => this.setState({ userGroup })}
						>
							{options}
						</Select>
					</div>
					<Button
						type="link"
						className="add-btn"
						size="small"
						onClick={() => this.handleAddTier(userGroup)}
					>
						<span className="icon-circle">
							<i className="icon-plus" />
						</span>
						{addGroupBonusText}
					</Button>
				</div>
				{this.renderUserGroups()}
			</>
		);
	}
}

export default Form.create()(ReferralTiers);
