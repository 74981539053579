import gql from 'graphql-tag';

export const ListPointsLog = gql`
	query ListPointsLog(
		$filter: TablePointsLogFilterInput
		$limit: Int
		$nextToken: String
	) {
		listPointsLog(filter: $filter, limit: $limit, nextToken: $nextToken) {
			items {
				id
				companyId
				userId
				user {
					firstName
					lastName
				}
				weeklyLastLogin
			}
			nextToken
		}
	}
`;
