import gql from 'graphql-tag';

export const GetJobNoReferrals = gql`
	query GetJobNoReferrals($id: ID!) {
		getJob(id: $id) {
			id
			dateCreated
			jobType
			departmentId
			department {
				id
				name
			}
			companyId
			company {
				id
				name
				contactIncentiveBonus
				referralBonusWaitingPeriod
			}
			title
			description
			publicLink
			internalJobLink
			hideImInterested
			isGeneralReferral
			jobAdditionalInfo
			jobMatches {
				id
				contactId
				userId
				jobId
				matchStatus
				relevance
				dateCreated
			}
			salary
			location
			locations
			createdById
			createdBy {
				id
				firstName
				lastName
			}
			hiringManagerId
			hiringManager {
				id
				emailAddress
				firstName
				lastName
			}
			jobLevelIds
			jobLevels {
				id
				active
				name
				companyId
			}
			referralBonus
			tieredBonus {
				id
				name
				tiers
			}
			notificationType
			status
			shares
			views
			lat
			lng
			externalJobId
			subCompanyId
			subCompany {
				id
				name
			}
			campaignId
			campaign {
				id
				name
				startDate
				endDate
				archived
				tieredBonusId
				tieredBonus {
					id
					name
					archived
					companyId
					tiers
				}
			}
			externalSource
			isHotJob
			displayReqId
		}
	}
`;
