import { Modal } from "antd"
import { useCallback, useEffect, useMemo } from "react"
import { useFormik } from "formik"

import NominateEmployeePresentation from "../../presentation/NominateEmployeePresentation"

import useGetEmployeeToNominate from "../../hooks/useGetEmployeeToNominate"

import { nominationSchema } from "./validationSchema.js"

const NominateEmployeeModalContainer = ({ showModal, closeModal, nominationConfigId, badgeDetails, endDate }) => {
    const { nominateEmpList, nominationLoading, getEligibleEmployeeList, nominateEmployeeList } = useGetEmployeeToNominate(closeModal)
    useEffect(() => {
        nominationConfigId && getEligibleEmployeeList(nominationConfigId)
    }, [nominationConfigId])

    const formik = useFormik({
        initialValues: {
            nominationConfigurationId: nominationConfigId,
            nomineeId: "",
            nominationReason: ""
        },
        validationSchema: nominationSchema,
        onSubmit: (value) => {
            confirm()
        }
    })

    const { values, handleSubmit } = formik

    const nomineeName = useMemo(() => {
        let details = nominateEmpList?.find(({ employee_firstName, employee_id, employee_lastName }) => {
            if (employee_id === values.nomineeId) {
                return `${employee_firstName} ${employee_lastName || ""}`
            }
        })
        return `${details?.employee_firstName} ${details?.employee_lastName}`
    }, [nominateEmpList, values.nomineeId])

    const confirm = useCallback(() => {
        Modal.confirm({
            title: 'Confirm',
            content: <>{`Are you sure, you want to nominate ${nomineeName} for ${badgeDetails?.name}?`}</>,
            okText: 'Proceed',
            cancelText: 'Cancel',
            onOk: () => {
                nominateEmployeeList(values)
            }
        });
    }, [badgeDetails, nominateEmpList, nomineeName, values])

    return (
        <Modal
            open={showModal}
            title={"Select Employee To Nominate"}
            closable={true}
            onCancel={closeModal}
            footer={null}
            width={"650px"}
            className="manage-nominated-reward-modal reward-modal"
        >
            <NominateEmployeePresentation
                formik={formik}
                nominateEmpList={nominateEmpList}
                badgeDetails={badgeDetails}
                endDate={endDate}
                nominationLoading={nominationLoading} />
        </Modal>
    )
}

export default NominateEmployeeModalContainer