import { get } from 'lodash';

export function EligibilityCustomItem(props) {
	const {
		eligbilityChecks,
		company,
		fieldName,
		onUpdateCompany,
		style,
	} = props;

	const inactivateCustomField = (name, fieldName) => {
		if (eligbilityChecks && eligbilityChecks[fieldName] && fieldName == 'CustomValuesToCheck') {
			let customValuesToCheck = eligbilityChecks.CustomValuesToCheck;

			const index = customValuesToCheck.findIndex(item => item.name === name);

            if (index !== -1) {
                customValuesToCheck.splice(index, 1);
            }

			eligbilityChecks.CustomValuesToCheck = customValuesToCheck;

			onUpdateCompany({
				input: {
					id: company.id,
					eligibiltyChecks: JSON.stringify(eligbilityChecks),
				},
			});
		}
		else if(eligbilityChecks && eligbilityChecks[fieldName] && fieldName != 'CustomValuesToCheck') {
			let fieldValues = eligbilityChecks[fieldName].split('|');

			const fieldIndex = fieldValues.indexOf(name);
			if (fieldIndex > -1) {
				fieldValues.splice(fieldIndex, 1);
			}

			fieldValues = fieldValues.join('|');

			eligbilityChecks[fieldName] = fieldValues;

			onUpdateCompany({
				input: {
					id: company.id,
					eligibiltyChecks: JSON.stringify(eligbilityChecks),
				},
			});

		}
	};

	return (
		<ul className="tag-wrap">
			{eligbilityChecks && eligbilityChecks[fieldName] && fieldName == 'CustomValuesToCheck'
				? ( eligbilityChecks && eligbilityChecks.CustomValuesToCheck.map(item=>item.name)
						.map((name, index) => {
							let marginLeft = get(style, 'marginLeft') ? style.marginLeft : 4;
							if (index === 0) marginLeft = 0;
							return (
								<li
									key={index}
									className="tag green"
									onClick={() => inactivateCustomField(name, fieldName)}
								>
									<span className="tag-name">{name}</span>
									<i className="tag-close icon-cross" />
								</li>
							);
						}))
				: (eligbilityChecks && eligbilityChecks[fieldName] && fieldName != 'CustomValuesToCheck' 
				? eligbilityChecks && eligbilityChecks[fieldName].split('|')
						.map((name, index) => {
							let marginLeft = get(style, 'marginLeft') ? style.marginLeft : 4;
							if (index === 0) marginLeft = 0;
							return (
								<li
									key={index}
									className="tag green"
									onClick={() => inactivateCustomField(name, fieldName )}
								>
									<span className="tag-name">{name}</span>
									<i className="tag-close icon-cross" />
								</li>
							);
						}) 
					: null	
					)
				
				
				
				
				
				}
		</ul>
	);
}