import { get } from 'lodash';
import { Component } from 'react';
import { withApollo } from 'react-apollo';
import fileIcon from '../../../_shared/assets/erin_lightgray.png';
import { USER_ROLES } from '../../../_shared/constants';
import { getSetErrorImageURL, ml } from '../../../_shared/services/utils.js';

class BaseJobCard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			totalSmartReferrals: 0,
			isViewMore: false,
			errorImageURL: '',
		};
	}

	async componentDidMount() {
		const { currentUser } = this.props;
		const errorImageURL = await getSetErrorImageURL(
			currentUser?.company?.errorImage?.key
		);

		if (errorImageURL) {
			this.setState({ errorImageURL });
		}
	}

	compareObjects = (object1, object2, key) => {
		const object1_ = object1[key].toUpperCase();
		const object2_ = object2[key].toUpperCase();

		if (object1_ < object2_) {
			return -1;
		}

		if (object1_ > object2_) {
			return 1;
		}

		return 0;
	};

	formatNumber = (number) => {
		try {
			if (number !== null && number !== undefined) {
				return number.toString().replaceAll(/\B(?=(\d{3})+(?!\d))/g, ',');
			}

			return number;
		} catch {
			return 0;
		}
	};

	render() {
		const {
			allMultiLingualData,
			onClick,
			title,
			jobMatches,
			openPositions,
			currentUser,
			JobMatchesByCompanyId,
			WithReferralCount,
			NoReferralCount,
			openPositionsCount,
			JobTiers,
			company,
		} = this.props;

		const { errorImageURL } = this.state;

		const whiteLabel = get(company, 'whiteLabel');

		JobTiers.sort((book1, book2) => {
			return this.compareObjects(book1, book2, 'name');
		});

		let goodMatches = [];
		let totalSmartRefer = [];
		if (jobMatches !== undefined && jobMatches !== null) {
			goodMatches = jobMatches.filter(
				(jobMatch) => jobMatch.relevance >= 30 && jobMatch.matchStatus !== false
			);
		}

		if (JobMatchesByCompanyId !== undefined && JobMatchesByCompanyId !== null) {
			totalSmartRefer = JobMatchesByCompanyId.filter(
				(jobMatch) => jobMatch.relevance >= 30 && jobMatch.matchStatus !== false
			);
		}

		return (
			<div className="d-card height-equal">
				<div className="d-card-head">
					<h4 className="d-title">{title}</h4>
				</div>

				{currentUser.displayAs === USER_ROLES.ADMIN && (
					<>
						<div className="row">
							<div className="col-sm-5">
								<div className="data-card light-text bg-f7a34d">
									<h5>
										{openPositionsCount
											? this.formatNumber(openPositionsCount)
											: this.formatNumber(openPositions)}
									</h5>
									<p>
										{ml('Open Positions', currentUser, allMultiLingualData)}
									</p>
								</div>
							</div>
							<div className="col-sm-7">
								<ul className="list-data-wrap">
									<li>
										<label>
											{ml('With Referrals', currentUser, allMultiLingualData)}:
										</label>
										<span>
											{WithReferralCount.toString().replaceAll(
												/\B(?=(\d{3})+(?!\d))/g,
												','
											)}
										</span>
									</li>
									<li>
										<label>
											{ml('No Referrals', currentUser, allMultiLingualData)}:
										</label>
										<span>
											{NoReferralCount.toString().replaceAll(
												/\B(?=(\d{3})+(?!\d))/g,
												','
											)}
										</span>
									</li>
								</ul>
							</div>
						</div>
						<div className="divider" />
						<div className="row" style={{ flex: '1 0 auto' }}>
							<div className="col-sm-5">
								<p className="label-heading">
									{' '}
									{ml(
										'Jobs By Bonus Level',
										currentUser,
										allMultiLingualData
									)}{' '}
								</p>
							</div>
							<div className="col-sm-7">
								<ul
									className="list-data-wrap custom-scroll"
									style={{
										maxHeight: 170,
										overflowY: 'auto',
										paddingRight: 10,
										minHeight: '100%',
									}}
								>
									{JobTiers.map((value) => {
										return (
											<li key={value.id}>
												{' '}
												<label>{value.name} :</label>
												<span>
													{value.count
														.toString()
														.replaceAll(/\B(?=(\d{3})+(?!\d))/g, ',')}{' '}
												</span>
											</li>
										);
									})}
								</ul>
							</div>
						</div>
					</>
				)}
				{(currentUser.displayAs === USER_ROLES.EMPLOYEE ||
					currentUser.displayAs === USER_ROLES.MANAGER) && (
					<div className="d-card-body vh-center" style={{ cursor: 'pointer' }}>
						{openPositions ? (
							<div className="text-center">
								<h3 className="d-number">{openPositions}</h3>
								<p className="d-info ant-btn-link" onClick={onClick}>
									{ml('Open Jobs', currentUser, allMultiLingualData)}
								</p>
							</div>
						) : (
							<div className="no-content">
								{whiteLabel ? (
									<img
										src={errorImageURL}
										alt="error image"
										className="no-content-icon"
									/>
								) : (
									<img
										alt="erin-logo"
										className="no-content-icon"
										src={fileIcon}
									/>
								)}
								<p className="no-content-text">
									{ml(
										'There currently are no open jobs.',
										currentUser,
										allMultiLingualData
									)}
								</p>
							</div>
						)}
					</div>
				)}
			</div>
		);
	}
}

export const JobCard = withApollo(BaseJobCard);
