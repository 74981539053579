import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
	AutoComplete,
	Col,
	Input,
	Modal,
	Row,
	Select,
	Tooltip,
	message,
} from 'antd';
import getSymbolFromCurrency from 'currency-symbol-map';
import gql from 'graphql-tag';
import get from 'lodash/get';
import { Component } from 'react';
import ProgressButton from 'react-progress-button';
import { queryContactsByUserIdIndex } from 'src/_shared/api/graphql/custom/contacts/';
import {
	lowerCase,
	sanitize,
	searchOpenSearchNetwork,
	uploadToS3Multipart,
	upperCase,
	validEmail,
} from 'src/_shared/services/utils.js';
import * as ModalStyles from './referralModalStyles.js';

const AutoCompleteOption = AutoComplete.Option;

const FormItem = Form.Item;
class ReferralModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			autoCompleteResult: [],
			isSubmitting: false,
			isSubmitDisabled: false,
			newContact: true,
			error: false,
			buttonState: 'disabled',
			filename: null,
			filetype: null,
			errors: [],
			originalFile: [],
			selectedEmployee: '',

			selectedContact: '',
			autoCompleteContactResult: [],
			isSearchContact: false,
			selectedCandidate: {},
			selectedCandidateEmailOrPhone: '',
			visibleCandidate: false,
			isNewContact: false,
		};
	}

	async componentDidMount() {
		this.getCurrencyRate(get(this.props, 'currentUser.currency', 'USD'));
		await this.setQueryToState(get(this.state, 'searchQuery', ''), 0);
		if (this.props.matchFound) {
			this.setState({ autoCompleteResult: [this.props.matchFound] });
		}

		if (this.props.contact) {
			this.setState({ autoCompleteResult: [this.props.contact] });
		}

		if (this.props.contacts) {
			this.setState({ autoCompleteContactResult: this.props.contacts });
		}
	}

	onChangeContact = async (value) => {
		const { contacts } = this.props;
		let autoCompleteContactResult = [];
		let isNewContact = true;
		if (!this.props.form) return;

		this.setState({
			isSearchContact: false,
		});

		if (value && value.length > 0) {
			this.setState({
				isSearchContact: true,
			});

			autoCompleteContactResult = contacts.filter((x) => {
				if (
					lowerCase(get(x, 'firstName')).includes(lowerCase(value)) ||
					lowerCase(get(x, 'lastName')).includes(lowerCase(value))
				) {
					isNewContact = false;
					return x;
				}

				isNewContact = true;
			});
			this.setState({ autoCompleteContactResult });
			this.setState({ isNewContact });
		}
	};

	onChangeEmployee = (value) => {
		this.setState({
			selectedEmployee: {
				id: value.key,
				name: value.label,
			},
		});
	};

	onDelete = () => {
		this.setState({ originalFile: [] });
		this.setState({ filename: null });
		this.setState({ filetype: null });
		this.setState({ errors: [] });
	};

	onFileInputChange = (e) => {
		if (e.target.files && e.target.files.length > 0) {
			const errors = [];
			const isDocOrDocsOrPdf =
				e.target.files[0].type === 'application/msword' ||
				e.target.files[0].type ===
					'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
				e.target.files[0].type === 'application/pdf';
			if (!isDocOrDocsOrPdf) {
				errors.push('You can only upload doc, docx, pdf files! ');
			}

			const isLt2M = e.target.files[0].size / 1024 / 1024 < 2;
			if (!isLt2M) {
				errors.push('File size must smaller than 2MB!');
			}

			if (errors.length > 0) {
				this.setState({ filename: e.target.files[0].name });
				this.setState({ errors });
				return errors;
			}

			this.setState({
				filename: e.target.files[0].name,
				filetype: e.target.files[0].type,
				originalFile: e.target.files[0],
			});

			this.setState({ errors: [] });
			return isDocOrDocsOrPdf && isLt2M;
		}
	};

	onselectContact = (id, props) => {
		this.setState({ isNewContact: false });

		const { contacts } = this.props;
		const selected = contacts.find((x) => x.id === id);
		this.setState({
			selectedCandidate: selected,
		});
		if (selected && selected.emailAddress) {
			this.props.form.setFieldsValue({
				firstName: selected.firstName,
				emailAddress: selected.emailAddress,
				lastName: selected.lastName,
			});
		}
	};

	async getCurrencyRate(currency) {
		const response = await fetch(
			`https://api.exchangeratesapi.io/latest?base=USD&symbols=${currency}`
		);
		const result = await response.json();
		if (result.error) {
			this.setState({
				currencyRate: 1,
				currencySymbol: '$',
			});
		} else {
			const rate = result.rates[currency].toFixed(5);
			const symbol = getSymbolFromCurrency(currency);
			this.setState({
				currencyRate: rate,
				currencySymbol: symbol,
			});
		}
	}

	setQueryToState = async (searchQuery = '', timeout = 800) => {
		const company = get(this.props, 'currentUser.company');
		const { filteredDepartments = [], filteredRoles = [] } = this.state;
		this.setState({ searchQuery });
		clearTimeout(this.timer);
		if (searchQuery === '' && filteredDepartments.length <= 0) {
			this.setState({
				loading: false,
				searchedEmployees: get(this.state, 'allUsers', []),
			});
		}

		this.setState({ loading: true });
		this.timer = setTimeout(async () => {
			const parameters = {
				query: searchQuery,
				size: 300,
				role: get(this.props, 'currentUser.role'),
				currentUser: get(this.props, 'currentUser.id'),
				filters: {
					companies: get(company, 'id'),
					departments: filteredDepartments,
					roles: filteredRoles,
				},
			};
			const response = await searchOpenSearchNetwork(parameters, 'erin-users');
			if (get(response, 'query') === get(this.state, 'searchQuery')) {
				this.setState({
					loading: false,
					searchedEmployees: get(response, 'data', []),
				});
			}
		}, timeout);
	};

	handleAutoCompleteCustomValidator(rule, value, callback) {
		callback();
	}

	handleContactChange = (value) => {
		let autoCompleteResult = [];
		if (value) {
			for (const record of this.props.contacts) {
				const { firstName, lastName, emailAddress } = record;
				const isFirstNameMatch =
					firstName && firstName.toLowerCase().includes(value.toLowerCase());
				const isLastNameMatch =
					lastName && lastName.toLowerCase().includes(value.toLowerCase());
				const isEmailMatch =
					emailAddress &&
					emailAddress.toLowerCase().includes(value.toLowerCase());

				if (isFirstNameMatch || isLastNameMatch || isEmailMatch) {
					autoCompleteResult.push(record);
				}
			}
		} else {
			autoCompleteResult = [];
		}

		this.setState({ autoCompleteResult });
	};

	handleNewContact = () => {
		this.setState((prevState) => ({ newContact: !prevState.newContact }));
	};

	handleSubmit = async (e) => {
		e.preventDefault();
		const {
			currentUser,
			form,
			job,
			onCreateContact,
			onCreateReferral,
			onUpdateContact,
			client,
			toggleLoading,
		} = this.props;
		const { filename, isSubmitDisabled, isNewContact } = this.state;
		if (isSubmitDisabled) {
			return;
		}

		try {
			const { errors, values } = await new Promise((resolve) => {
				form.validateFields((errors, values) => {
					resolve({ errors, values });
				});
			});

			if (errors) {
				this.setState({ buttonState: 'error' });

				setTimeout(() => {
					this.setState({ buttonState: '' });
				}, 1500);
				return;
			}

			this.setState({ buttonState: 'loading', isSubmitDisabled: true });
			toggleLoading();
			let email = null;
			let importMethod = '';
			let { emailAddress } = values;
			emailAddress &&= sanitize(emailAddress);
			let { firstName } = values;
			firstName &&= sanitize(firstName);
			let { lastName } = values;
			lastName &&= sanitize(lastName);
			if (emailAddress && validEmail(emailAddress)) {
				email = emailAddress;
				importMethod = 'email';
			}

			let { atsId } = values;
			atsId &&= sanitize(atsId);

			const d = new Date();
			const dformat = `${d.getHours()}-${d.getMinutes()}-${d.getSeconds()}`;

			const contactInput = {
				input: {
					firstName,
					lastName,
					socialMediaAccounts: null,
					userId: this.state.selectedEmployee.id,
					companyId: currentUser.companyId,
					jobHistory: null,
					importMethod,
					disableSmartReferrals: false,
					emailAddress: email.toLowerCase(),
				},
			};

			if (atsId) {
				contactInput.input.atsId = atsId;
			}

			const existingContact = await client
				.query({
					query: gql(queryContactsByUserIdIndex),
					variables: {
						userId: this.state.selectedEmployee.id,
					},
					fetchPolicy: 'network-only',
				})
				.then((result) => {
					return result.data.queryContactsByUserIdIndex.items.find(
						(contact) => contact.emailAddress === emailAddress
					);
				});
			// If contact already exists, then skip creating contact
			let newContactId = '';
			if (!existingContact) {
				const newContact = await onCreateContact(contactInput).then(
					(response) => response.data.createContact
				);
				newContactId = newContact.id;
			} else if (atsId) {
				contactInput.input.id = existingContact.id;
				const updatedContact = await onUpdateContact(contactInput).then(
					(response) => response.data.updateContact
				);
			}

			// Proceed creating referral for whether it was a new contact, or existing contact
			const referralInput = {
				input: {
					referralDate: new Date().toISOString(),
					companyId: currentUser.companyId,
					contactId: existingContact ? existingContact.id : newContactId,
					referralType: 'email',
					referralSource: 'direct',
					referralDevice: 'web',
					userId: this.state.selectedEmployee.id,
					jobId: get(job, ['id'], ''),
					status: 'accepted',
					note: null,
					message: null,
					referralSource: 'general',
					referralDevice: 'web',
					creationType: 'manual',
				},
			};
			if (atsId) {
				referralInput.input.ownershipAtsId = atsId;
			}

			const resume = {
				bucket: 'erin-documents',
				key: `resumes/${
					existingContact ? existingContact.id : newContactId
				}/${dformat + '-' + filename}`,
				region: 'us-east-2',
			};
			if (filename) referralInput.input.contactResume = resume;
			if (filename) {
				const updateContactResumeInput = {
					id: existingContact ? existingContact.id : newContactId,
					contactResume: resume,
				};
				await onUpdateContact({ input: updateContactResumeInput });
				await this.updateS3ContactResume(resume);
			}

			await onCreateReferral(referralInput);

			// Show success
			this.setState({ buttonState: 'success' });
			message.success('Your request is submitted.', 5);
			await new Promise((resolve) => {
				setTimeout(() => resolve(), 1000);
			});

			this.props.handleCancelAll();
			return this.props.updateReferrals(referralInput.input);
		} catch (error) {
			console.log(error, 'There was an error in handleSubmit ');
			this.setState({ buttonState: 'error' });

			await new Promise((resolve) => {
				setTimeout(() => resolve(), 2000);
			});
			this.setState({ buttonState: '' });
			this.setState({ isSubmitDisabled: false });
		}
	};

	updateS3ContactResume = async (resume) => {
		const { filename, filetype, originalFile } = this.state;
		if (filename) {
			try {
				await uploadToS3Multipart(originalFile, resume.key, resume.bucket);
			} catch (error) {
				console.error(error);
			}
		}
	};

	renderContact = () => {
		const { autoCompleteContactResult, isSearchContact } = this.state;
		const contactOptions = autoCompleteContactResult
			.sort((a, b) => {
				const aName = upperCase(get(a, 'firstName'));
				const bName = upperCase(get(b, 'firstName'));
				if (aName > bName) {
					return 1;
				}

				if (aName < bName) {
					return -1;
				}

				return 0;
			})
			.map((contact) => {
				const firstName = get(contact, 'firstName', '');
				const lastName = get(contact, 'lastName', '');
				const emailAddress = get(contact, 'emailAddress', '');
				const phoneNumber = get(contact, 'phoneNumber', '');
				let content = '';
				if (emailAddress) {
					content = `${firstName + ' ' + lastName + ' (' + emailAddress + ')'}`;
				} else if (phoneNumber) {
					content = `${firstName + ' ' + lastName + ' (' + phoneNumber + ')'}`;
				}

				return (
					<AutoCompleteOption key={contact.id} id={contact.id} title={content}>
						{`${firstName + ' ' + lastName}`}
					</AutoCompleteOption>
				);
			});

		const { getFieldDecorator } = this.props.form;

		const FormItem = Form.Item;
		const { visibleCandidate } = this.state;

		return (
			<FormItem>
				{getFieldDecorator('firstName', {
					rules: [
						{
							required: true,
							message: (
								<Tooltip
									open={visibleCandidate}
									placement="topLeft"
									style={{ fontWeight: 1000 }}
									title="First Name"
								>
									<div
										style={{
											position: 'relative',
											top: -50,
											display: 'inline-block',
										}}
									/>
								</Tooltip>
							),
						},
						{
							validator: this.handleAutoCompleteCustomValidator,
						},
					],
				})(
					<AutoComplete
						dataSource={isSearchContact ? contactOptions : []}
						className="custom-input custom-input-autocomplete"
						filterOption={false}
						placeholder="First Name"
						autoComplete="off"
						onSearch={this.onChangeContact}
						onSelect={this.onselectContact}
					/>
				)}
			</FormItem>
		);
	};

	renderEmployees = () => {
		const users = get(this.state, 'searchedEmployees', []);
		const { Option } = Select;
		const options = users
			.sort(function (a, b) {
				const nameA = a.firstName.toUpperCase(); // ignore upper and lowercase
				const nameB = b.firstName.toUpperCase(); // ignore upper and lowercase
				if (nameA < nameB) {
					return -1;
				}

				if (nameA > nameB) {
					return 1;
				}

				// Names must be equal
				return 0;
			})
			.map((emp) => (
				<Option key={emp.id}>
					{`${emp.firstName} ${emp.lastName} - ${emp.emailAddress}`}
				</Option>
			));
		return (
			<Select
				showSearch
				labelInValue
				className="custom-input"
				optionFilterProp="children"
				placeholder="Ex. James Smith"
				filterOption={(input, option) => {
					return option.props.children
						.toLowerCase()
						.includes(input.toLowerCase());
				}}
				onSelect={(value) => this.onChangeEmployee(value)}
				onSearch={async (value) => await this.setQueryToState(value, 500)}
			>
				{options}
			</Select>
		);
	};

	render() {
		const {
			job,
			visible,
			handleCancel,
			referralInformationText,
			createReferralForText,
			positiontext,
			selectEmployeeText,
			enterReferralInformationText,
			lastNameText,
			emailText,
			clickHereText,
			attachResumeText,
			noteText,
			referralAutomaticallyAcceptedText,
			submitReferralText,
		} = this.props;
		const { buttonState, errors, filename } = this.state;
		const { getFieldDecorator } = this.props.form;
		const FormItem = Form.Item;

		return (
			<Modal
				centered
				open={visible}
				footer={null}
				width={600}
				title={referralInformationText}
				onCancel={handleCancel}
			>
				<>
					<p className="text-center small">
						{createReferralForText}{' '}
						<a
							href={`/jobs/${get(job, ['id'], '')}`}
							target="_blank"
							rel="noreferrer"
						>
							{get(job, 'title')}
						</a>{' '}
						{positiontext}.
					</p>
					<div className="custom-form-group">
						<label className="custom-label">{selectEmployeeText}</label>
						<Form.Item>
							{getFieldDecorator('selectEmployee', {
								rules: [
									{ required: true, message: 'Please select an Employee' },
									{
										validator: async (rule, value) => {
											if (value) {
												this.setState({ buttonState: '' });
											}
										},
									},
								],
							})(this.renderEmployees())}
						</Form.Item>
					</div>
					<div className="custom-form-group">
						<h3 className="custom-label">{enterReferralInformationText}</h3>
						<Row type="flex" justify="space-between" gutter={12}>
							<Col xs={24} lg={12} className="mb-3">
								{this.renderContact()}
							</Col>
							<Col xs={24} lg={12} className="mb-3">
								<FormItem>
									{getFieldDecorator('lastName', {
										rules: [
											{ required: true, message: 'Please input Last Name' },
										],
									})(
										<Input
											className="custom-input"
											placeholder={lastNameText}
										/>
									)}
								</FormItem>
							</Col>
							<Col xs={24} lg={12}>
								<FormItem>
									{getFieldDecorator('emailAddress', {
										rules: [
											{ type: 'email', message: 'Enter a valid email.' },
											{
												required: true,
												message: 'Please enter an email address.',
											},
										],
									})(
										<Input className="custom-input" placeholder={emailText} />
									)}
								</FormItem>
							</Col>
							<Col xs={24} lg={12}>
								<FormItem>
									{getFieldDecorator('atsId', {
										rules: [
											{
												required: false,
											},
										],
									})(
										<Input
											className="custom-input"
											placeholder="Candidate ID"
										/>
									)}
								</FormItem>
							</Col>
						</Row>
					</div>
					{!filename && (
						<p className="upload-link-text">
							<label className="upload-link" htmlFor="resumeFile">
								{clickHereText}
								<input
									ref={(ref) => {
										this.uploadInput = ref;
									}}
									hidden
									type="file"
									accept=".doc,.docx,application/msword,.pdf,application/pdf"
									id="resumeFile"
									name="file"
									onClick={() => {
										this.setState({
											filename: null,
											filetype: null,
											originalFile: [],
										});
										this.uploadInput = null;
									}}
									onChange={this.onFileInputChange}
								/>
							</label>
							{attachResumeText}
							<span className="label-optional">(optional)</span>
						</p>
					)}
					{filename && (
						<div className="upload-file-text">
							<p>
								{filename}

								<i
									className="icon-bin text-danger cursor-p"
									onClick={this.onDelete}
								/>
							</p>
						</div>
					)}
					{errors && errors.length > 0 ? (
						<div className={ModalStyles.fieldErrors}>
							<span className={ModalStyles.errorText}> {errors} </span>
						</div>
					) : null}
					<p className="text-note mt-3">
						<strong>{noteText}:</strong> {referralAutomaticallyAcceptedText}.
					</p>
					<FormItem className="modal-footer-btn">
						<ProgressButton
							controlled
							durationSuccess={3000}
							state={buttonState}
							onClick={this.handleSubmit}
						>
							{submitReferralText}
						</ProgressButton>
					</FormItem>
				</>
			</Modal>
		);
	}
}
const WrappedReferralModal = Form.create()(ReferralModal);
export default WrappedReferralModal;
