import get from 'lodash/get';
import { compose, graphql } from 'react-apollo';
import { getContactDetails } from '../../graphql/custom/contacts';

export const withContactDetails = (Component, variables) => {
	return compose(
		graphql(getContactDetails, {
			options: {
				context: {
					headers: {
						'x-frame-options': 'deny', // This header will reach the server
					},
				},
				variables,

				fetchPolicy: 'cache-and-network',
			},
			props: (props) => ({
				contact: get(props, ['data', 'getContact'], undefined),
			}),
		})
	)(Component);
};
