import dayjs from 'dayjs';
import get from 'lodash/get';
import { connect } from 'react-redux';
import { withListOnDeckContacts } from 'src/_shared/api/components/contacts/';
import { withGetTieredBonus } from 'src/_shared/api/components/tiered-bonuses';
import { dashboardActions } from 'src/actions.js';
import { compose } from '../../../_shared/services/utils.js';
import { JobDescriptionCard as JobDescriptionCardComponent } from './JobDescriptionComponent.jsx';

const mapStateToProperties = (state, ownProperties) => {
	const { currentUser } = state.user;
	const { allMultiLingualData } = state.dashboard;
	let isActive;
	let archived;

	const campaignId = get(ownProperties, ['job', 'campaignId'], null);

	const campaign = get(ownProperties, 'job.campaign');
	if (campaignId) {
		const today = dayjs();
		const startDate = get(campaign, 'startDate');
		const endDate = get(campaign, 'endDate');
		archived = get(campaign, 'archived', false);

		isActive = Boolean(today.isBetween(startDate, endDate, 'day', '[]'));
	}

	if (archived) {
		isActive = false;
	}

	if (isActive) {
		return {
			tieredBonusQuery: {
				companyId: currentUser.companyId,
				id: get(ownProperties, 'job.campaign.tieredBonusId', 'skip'),
			},
			allMultiLingualData,
			campaignId,
			isActive,
		};
	}

	return {
		tieredBonusQuery: {
			companyId: currentUser.companyId,
			id: get(ownProperties, 'job.referralBonus.tieredBonusId', 'skip'),
		},
		allMultiLingualData,
		campaignId,
		isActive,
	};
};

const mapDispatchToProperties = (dispatch) => {
	return {
		setMultiLingualData(data) {
			dispatch(dashboardActions.createSetMultiLingualData(data));
		},
	};
};

export const JobDescriptionCard = connect(
	mapStateToProperties,
	mapDispatchToProperties
)(
	compose(
		withGetTieredBonus,
		withListOnDeckContacts
	)(JobDescriptionCardComponent)
);
