import React,
{ useEffect, useState } from 'react';
import { message } from 'antd';
import EditReward from '../../presentation/editReward/EditReward.jsx'
import { useManageRewardsService } from '../../services/manageRewards.service.jsx';
import * as htmlToImage from 'html-to-image';

const EditRewardContainer = (props) => {

  const { isEditModalOpen, handleCloseModal, selectedBadge, setShouldRefresh } = props;

  const [badgeDetailsObject, setBadgeDetailsObject] = useState({});
  const [badgeErrorObject, setBadgeErrorObject] = useState();
  const [iconsList, setIconsList] = useState([]);
  const [badgeTypeList, setBadgeTypeList] = useState();
  const [isLoaderVisible, setIsLoaderVisible] = useState(false);
  const [levelList, setLevelList] = useState();
  const [payTypeList, setPayTypeList] = useState();
  const [automationRules, setAutomationRule] = useState([]);

  const manageRewardService = useManageRewardsService();

  useEffect(() => {
    getBadgeTypesList();
    getIconsList();
    getLevelList();
    getPayTypeList();
    getAutomationRules();
  }, [])

  useEffect(() => {
    if (selectedBadge) {
      getBadgeDetails();
    }
  }, [selectedBadge]);

  const getBadgeDetails = () => {
    setIsLoaderVisible(true);
    manageRewardService.getBadgeDetails(selectedBadge?.id).then((response) => {
      setIsLoaderVisible(false)
      if (response?.code === 200) {
        if (response?.data) {
          setBadgeDetailsObject(response.data);
        }
      } else {
        message.error('Failed to fetch badge details! Try again later.');
      }
    }).catch((error) => {
      setIsLoaderVisible(false)
      console.log(error);
      message.error('Failed to fetch badge details! Try again later.');
    });
  }

  const getAutomationRules = () => {
    manageRewardService.getAutomationRule().then((automationRuleListResponse) => {
      if (automationRuleListResponse?.code === 200) {
        if (automationRuleListResponse?.data) {
          setAutomationRule(automationRuleListResponse?.data);
        }
      } else {
        message.error('Failed to fetch Automation Rules list! Try again later.');
      }
    }).catch(() => {
      message.error('Failed to fetch Automation Rules list! Try again later.');
    });
  };

  const getBadgeTypesList = () => {
    setIsLoaderVisible(true);
    manageRewardService.getBadgeTypes().then((getBadgeTypesListRes) => {
      if (getBadgeTypesListRes?.code === 200) {
        if (getBadgeTypesListRes?.data) {
          setBadgeTypeList(getBadgeTypesListRes?.data);
        }
      } else {
        message.error('Failed to fetch badge type list! Try again later.');
      }
    }).catch((error) => {
      console.log(error);
      message.error('Failed to fetch badge type list! Try again later.');
    });
  };

  const getIconsList = () => {
    manageRewardService
      .getIcons()
      .then((iconsListResponse) => {
        if (iconsListResponse?.code === 200) {
          if (iconsListResponse?.data) {
            iconsListResponse?.data.sort((a, b) => {
              return a.name.localeCompare(b.name);
            });
            setIconsList(iconsListResponse?.data);
          }
        } else {
          message.error('Failed to fetch icons list! Try again later.');
        }
      })
      .catch((error) => {
        message.error('Failed to fetch icons list! Try again later.');
      });
  };

  const getLevelList = () => {
    manageRewardService.getEmployeeLevels().then((response) => {
      if (response?.code === 200) {
        if (response?.data) {
          setLevelList(response?.data);
        }
      } else {
        message.error('Failed to fetch badge type list! Try again later.');
      }
    }).catch((error) => {
      message.error('Failed to fetch badge type list! Try again later.');
    });
  };

  const getPayTypeList = () => {
    manageRewardService.getEmployeePayType().then((response) => {
      if (response?.code === 200) {
        if (response?.data) {
          setPayTypeList(response?.data);
        }
      } else {
        message.error('Failed to fetch badge type list! Try again later.');
      }
    }).catch((error) => {
      message.error('Failed to fetch badge type list! Try again later.');
    });
  };

  const handleUpdateBadge = async () => {
    const requiredFields = [
      'name',
      'label',
      'description',
      'color'
    ];
    let hasEmptyField = false;
    let errorObject = badgeErrorObject && badgeErrorObject;

    for (const field of requiredFields) {
      if (
        !badgeDetailsObject[field] ||
        badgeDetailsObject[field]?.toString().trim() === ''
      ) {
        errorObject = {
          ...errorObject,
          [field]: true,
        };

        hasEmptyField = true;
      }
    }
    //Validation of points 
    const pointsValue = parseInt(badgeDetailsObject.points);
    if (badgeDetailsObject.points === '' || isNaN(badgeDetailsObject.points) || pointsValue < 0 || pointsValue > 9999) {
      errorObject = {
        ...errorObject,
        points: true,
      };
      hasEmptyField = true;
    }

    setBadgeErrorObject(errorObject);

    if (hasEmptyField) {
      return;
    }

    try {
      const badgeElement = document.getElementById('badge-preview');
      const dataUrl = await htmlToImage.toPng(badgeElement);

      const pngBase64 = dataUrl.split(',')[1];
      setIsLoaderVisible(true);


      const requestBody = {
        name: badgeDetailsObject.name,
        label: badgeDetailsObject.label,
        automationRule: badgeDetailsObject.automationRule,
        description: badgeDetailsObject.description,
        badgeTypeCode: badgeDetailsObject.badgeTypeCode,
        color: badgeDetailsObject.color,
        gradientColor: badgeDetailsObject.gradientColor,
        image: pngBase64,
        visibility: badgeDetailsObject.visibility,
        iconCode: badgeDetailsObject.iconCode,
        bonusPoints: badgeDetailsObject.bonusPoints,
        rewardBonus: badgeDetailsObject.rewardBonus,
        points: badgeDetailsObject.points || 0,
        isPreApproved: badgeDetailsObject.isPreApproved,
        externalWorkFlowId: badgeDetailsObject.externalWorkFlowId || "",
        physicalAwardId: badgeDetailsObject.physicalAwardId || "",
        recipientLevelId: badgeDetailsObject.recipientLevelId || [],
        assignmentLevelId: badgeDetailsObject.assignmentLevelId || [],
        isAssignmentSSORequired: badgeDetailsObject.isAssignmentSSORequired,
        isRecipientSSORequired: badgeDetailsObject.isRecipientSSORequired,
        recipientPayTypeId: badgeDetailsObject.recipientPayTypeId,
        rewardMaxBonus: badgeDetailsObject.rewardMaxBonus,
      }

      manageRewardService.updateBadge(badgeDetailsObject.id, requestBody).then((response) => {
        if (response.code === 200) {
          message.success(response.message || 'Badge updated successfully');
          setShouldRefresh(true)
          handleCloseModal();
          setBadgeDetailsObject(null);
        } else {
          message.error(response.message || 'Failed to update badge! Try again later.');
        }
        setIsLoaderVisible(false);
      }).catch((error) => {
        setIsLoaderVisible(false);
        message.error(error?.response?.message || 'Failed to update badge! Try again later.');
      });
    } catch (e) {
      console.log(error);
    }
  };

  return <div>
    <EditReward
      iconsList={iconsList}
      badgeTypeList={badgeTypeList}
      isModalOpen={isEditModalOpen}
      handleCloseModal={handleCloseModal}
      handleUpdateBadge={handleUpdateBadge}
      badgeDetailsObject={badgeDetailsObject}
      setBadgeDetailsObject={setBadgeDetailsObject}
      badgeErrorObject={badgeErrorObject}
      setBadgeErrorObject={setBadgeErrorObject}
      isLoaderVisible={isLoaderVisible}
      levelList={levelList}
      automationRules={automationRules}
      payTypeList={payTypeList}
    />
  </div>;
};

export default EditRewardContainer;
