import { useEffect, useState } from 'react';
import { message } from 'antd';
import MyrewardsPresentation from '../../presentation/myrewardsPresentation';
import { useRnRCommonService } from '../../../RnRCommonComponent/service/RnRCommon.service.jsx';
import { useSelector } from 'react-redux';
import { snappy } from 'src/_shared';

const MyrewardsContainer = () => {
	const [campaigns, setCampaigns] = useState([]);
	const currentUser = useSelector((state) => state.user.currentUser);
	const [isViewRewardModalVisible, setIsViewRewardModalVisible] =
		useState(false);
	const [rewardDetails, setRewardDetails] = useState();
	const [isModalLoaderVisible, setIsModalLoaderVisible] = useState(false);

	const commonServiceAPI = useRnRCommonService();

	useEffect(() => {
		getCampaigns();
	}, []);

	const getCampaigns = async () => {
		try {
			const response = await snappy({
				type: 'getCampaigns',
				userId: currentUser.id,
			});
			setCampaigns(response || []);
		} catch (error) {
			console.error('Error getCampaigns:', error);
		}
	};

	const handleOpenViewReward = async (id) => {
		try {
			setIsViewRewardModalVisible(true);
			setIsModalLoaderVisible(true);
			const response = await commonServiceAPI.getAdminIssuedBadge(id);
			setIsModalLoaderVisible(false);
			if (response?.code === 200) {
				if (response?.data) {
					setRewardDetails(response.data);
				}
			} else {
				message.error('Failed to fetch badge details! Try again later.');
			}
		} catch (error) {
			setIsModalLoaderVisible(false);
			console.log(error);
			message.error('Failed to fetch badge details! Try again later.');
		}
	};

	const handleCloseViewReward = () => {
		setIsViewRewardModalVisible(false);
	};

	return (
		<MyrewardsPresentation
			tiers={
				campaigns.length > 0 && campaigns[0]?.tiers ? campaigns[0].tiers : []
			}
			handleOpenViewReward={handleOpenViewReward}
			handleCloseViewReward={handleCloseViewReward}
			isViewRewardModalVisible={isViewRewardModalVisible}
			rewardDetails={rewardDetails}
			isModalLoaderVisible={isModalLoaderVisible}
			currentUser={currentUser}
		/>
	);
};

export default MyrewardsContainer;
