import gql from 'graphql-tag';
import get from 'lodash/get';
import { compose, graphql } from 'react-apollo';
import { parse } from 'src/_shared/services/utils.js';
import { querySubCompanyByCompanyIdIndex } from '../../graphql/custom/sub-company';

export const withQuerySubCompanyByCompanyIdIndex = (Component) => {
	return compose(
		graphql(gql(querySubCompanyByCompanyIdIndex), {
			options: (props) => ({
				context: {
					headers: {
						'x-frame-options': 'deny', // This header will reach the server
					},
				},
				variables: {
					companyId: props.currentUser.companyId,

					limit: 1000,
					nextToken: null,
				},
				fetchPolicy: 'cache-and-network',
			}),
			props(props) {
				if (
					!props.data.loading &&
					(!props.data.querySubCompanyByCompanyIdIndex || props.data.error)
				) {
					setTimeout(props.data.refetch, 2000);
					return null;
				}

				let subCompanies = get(
					props,
					'data.querySubCompanyByCompanyIdIndex.items',
					undefined
				);
				if (subCompanies !== undefined && subCompanies.length > 0) {
					subCompanies = subCompanies.map((subCompany) => {
						if (get(subCompany, 'keywords'))
							subCompany.keywords = parse(subCompany.keywords);
						return subCompany;
					});
				}

				return {
					subCompanies,
				};
			},
		})
	)(Component);
};
