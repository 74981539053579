import gql from 'graphql-tag';

export const GetUserGroupByName = gql`
	query GetUserGroupByName($companyId: ID!, $name: String!) {
		getUserGroupByName(companyId: $companyId, name: $name) {
			items {
				id
				companyId
				name
				keywords
				active
			}
			nextToken
		}
	}
`;
