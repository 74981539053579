import { connect } from 'react-redux';
import { withListDepartment } from 'src/_shared/api/components/departments/';
import { withListJobLevels } from 'src/_shared/api/components/job-level/';
import { withQuerySubCompanyByCompanyIdIndex } from 'src/_shared/api/components/sub-company/';
import { withListTieredBonus } from 'src/_shared/api/components/tiered-bonuses/';
import { dashboardActions, manageJobsActions } from 'src/actions.js';
import { compose } from '../_shared/services/utils.js';
import ManageJobsComponent from './ManageJobsComponent.jsx';

const mapStateToProperties = (state) => {
	const { currentUser } = state.user;
	const { allMultiLingualData } = state.dashboard;

	return {
		currentUser,
		currentJob: state.manageJobs.currentJob,
		filter: { companyId: { eq: currentUser.companyId } },
		limit: 50_000,
		allMultiLingualData,
	};
};

const mapDispatchToProperties = (dispatch) => {
	return {
		updateAddJobForm(values) {
			dispatch(manageJobsActions.updateAddJobForm(values));
		},
		fetchJobs() {
			dispatch(manageJobsActions.fetchJobs());
		},
		resetFormFields() {
			dispatch(manageJobsActions.resetAddJobForm());
		},
		setCurrentJob(job) {
			dispatch(manageJobsActions.setCurrentJob(job));
		},
		setCurrentCurrencyRate(currencyRate) {
			dispatch(manageJobsActions.setCurrentCurrencyRate(currencyRate));
		},
		setCurrentCurrencySymbol(currencySymbol) {
			dispatch(manageJobsActions.setCurrentCurrencySymbol(currencySymbol));
		},
		setMultiLingualData(data) {
			dispatch(dashboardActions.createSetMultiLingualData(data));
		},
	};
};

const ManageJobsWithApi = compose(
	withListDepartment,
	withListJobLevels,
	withQuerySubCompanyByCompanyIdIndex,
	withListTieredBonus
)(ManageJobsComponent);

export const ManageJobs = connect(
	mapStateToProperties,
	mapDispatchToProperties
)(ManageJobsWithApi);
