import gql from 'graphql-tag';

export const ListJobs = gql`
	query ListJobs(
		$filter: TableJobFilterInput
		$limit: Int
		$nextToken: String
	) {
		listJobs(filter: $filter, limit: $limit, nextToken: $nextToken) {
			items {
				id
				companyId
				company {
					id
					name
				}
				departmentId
				department {
					id
					name
				}
				referrals {
					id
					contactId
					contact {
						id
						emailAddress
						userId
					}
					status
				}
				title
				referralBonus
				description
				publicLink
				location
				shares
				views
				status
				dateCreated
			}
			nextToken
		}
	}
`;
