import { Select } from 'antd';

const { Option } = Select;

function SelectRole({ handleChangeRole, role }) {
	const onChangeRole = (value) => {
		handleChangeRole(value);
	};

	return (
		<Select
			className="custom-input"
			value={role}
			dropdownMatchSelectWidth={false}
			onChange={onChangeRole}
		>
			<Option value="admin">Administrator</Option>
			<Option value="employee">Employee</Option>
			<Option value="manager">Manager</Option>
		</Select>
	);
}

export default SelectRole;
