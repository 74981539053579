import { css } from 'emotion';
import { COLORS } from '../../../_shared/styles/colors';

const breakpoints = [576, 767, 991, 1200];
const mq = breakpoints.map((bp) => `@media (max-width: ${bp}px)`);

export const tileContainerClass = css({
	width: '100%',
	position: 'relative',
});

export const headerClass = css({
	fontSize: 16,
	textTransform: 'uppercase',
	letterSpacing: 0.95,
	fontWeight: 300,
	color: `${COLORS.heading} !important`,
	marginLeft: 0,
	width: '100%',
	marginBottom: 16,
});

export const leaderListClass = css({
	marginBottom: 5,
	margin: '0px',
	padding: 0,
	width: '100%',
	listStyle: 'none',
	'& li::before': {
		color: COLORS.lightGray,
		fontWeight: 600,
		display: 'inline-block',
		width: '1.5em',
	},
});
export const leaderListClassNoBullet = css({
	marginBottom: 5,
	margin: '15px 0px',
	padding: 0,
	width: '100%',
	listStyle: 'none',
	fontWeight: 600,
});

export const leaderListItemClass = css({
	display: 'flex',
	justifyContent: 'space-between',
});

export const emptyTile = css({
	textAlign: 'center',
	marginTop: '15px',
	color: COLORS.lightGray,
});

export const linkStyles = css({
	fontWeight: '600 !important',
	color: COLORS.hyperLink,
});

export const MinimalReferral = (selected, wid) =>
	css({
		marginBottom: 10,
		marginTop: 8,
		backgroundColor: selected ? COLORS.lightGray5 : COLORS.white,
		color: selected ? COLORS.white : COLORS.subHeading,
		'&:hover': {
			backgroundColor: COLORS.lightGray5,
			color: COLORS.white,
			borderColor: 'transparent',
		},
		'&:focus': {
			backgroundColor: COLORS.lightGray5,
			color: COLORS.white,
			borderColor: 'transparent',
		},
		height: 30,
		width: 150,
		width: wid,
	});

export const LabelStyles = css({
	verticalAlign: 'middle',
	fontFamily: 'Open Sans,sans-serif',
	fontWeight: 600,
	color: COLORS.darkGray,
	marginRight: '15px',
	lineHeight: 1,
	marginBottom: 2,
});

export const SelectContainer = css({
	display: 'flex',
	height: 40,
});

export const SelectLocationContainer = css({
	right: 0,
	width: '250px',
	top: -3,

	[mq[1]]: {
		margin: '0 0 10px !important',
		width: '100%',
	},
});

export const iconLocationContainer = css({
	pointerEvents: 'none',
	zIndex: 1,
});

export const SelectStylesLocation = css({
	width: '250px !important',
	'& .ant-select-arrow': {
		display: 'none',
	},
	[mq[1]]: {
		width: '100%',
	},
	'& .ant-select-selection': {
		border: 0,
		color: '#018dd3',
		lineHeight: 32,
		'&:focus': {
			WebkitBoxShadow: 'none',
			boxShadow: 'none',
		},
		'&:active': {
			WebkitBoxShadow: 'none',
			boxShadow: 'none',
		},
	},
});

export const DropDown = css({
	fontFamily: '"Open Sans", sans-serif',
});

export const SelectEmpStylesLocation = css({
	'& .ant-select-arrow': {
		display: 'none',
	},
	[mq[1]]: {
		width: '100%',
	},
	'& .ant-select-selection': {
		border: 0,
		color: '#018dd3',
		lineHeight: 32,
		'&:focus': {
			WebkitBoxShadow: 'none',
			boxShadow: 'none',
		},
		'&:active': {
			WebkitBoxShadow: 'none',
			boxShadow: 'none',
		},
	},
});
