import React, { useEffect, useState } from 'react';
import RecentActivity from './../../presentation/recentActivity/RecentActivity.jsx';
import { message } from 'antd';
import { useHomeService } from '../../service/home.services.jsx';

const RecentActivityContainer = (props) => {
	const { getTemplateData, isDataRefresh, setIsDataReferash } = props

	let templateData = localStorage.getItem("template");
	let recentActivityTemplateData = templateData ? JSON.parse(templateData) : null;
	const [currentPage, setCurrentPage] = useState(1);
	const [pageLimit] = useState(10);
	const [isRecentActivityLoading, setIsRecentActivityLoading] = useState(false);
	const [recentActivityData, setRecentActivityData] = useState();
	const [isShowMoreClicked, setIsShowMoreClicked] = useState(false);
	const [hasShowMoreData, setHasShowMoreData] = useState(false);
	const homeAPIService = useHomeService();

	useEffect(() => {
		fetchInitialData();
	}, [])

	useEffect(() => {
		if (!recentActivityTemplateData) {
			getTemplateData();
			fetchInitialData();
		}
	}, [recentActivityTemplateData]);

	useEffect(() => {
		if (isDataRefresh) {
			fetchInitialData()
		}
	}, [isDataRefresh])

	const fetchMoreData = () => {
		setCurrentPage(currentPage + 1)
		getRecentActivityData(currentPage + 1, pageLimit, true)
	};

	const fetchInitialData = (isNewPost = false) => {
		setIsRecentActivityLoading(true)
		setCurrentPage(1);
		getRecentActivityData(1, pageLimit, isNewPost, false);
	};

	const getRecentActivityData = (currentPage, pageLimit, shouldAppend = false) => {
		setIsShowMoreClicked(true)
		homeAPIService.getRecentActivity({
			page: currentPage,
			limit: pageLimit
		}).then((response) => {
			if (response.code === 200) {
				if (response?.data?.pagination?.count > 10) {
					setHasShowMoreData(response?.data?.pagination?.hasNextPage)
				}
				if (shouldAppend) {
					setRecentActivityData(prev => {
						return [...prev, ...response?.data?.records];
					});
				} else {
					setRecentActivityData(response?.data?.records)
				}
				setIsShowMoreClicked(false)
				setIsRecentActivityLoading(false)
				setIsDataReferash(false)

			}
			else {
				message.error('Failed to fetch recent activity data.');
				setIsRecentActivityLoading(false)
				setIsDataReferash(false)
			}
		}).catch((error) => {
			console.log(error);
			message.error('Failed to fetch recent activity data.');
		});
	}

	return (
		<RecentActivity
			isRecentActivityLoading={isRecentActivityLoading}
			recentActivityData={recentActivityData}
			fetchMoreData={fetchMoreData}
			recentActivityTemplateData={recentActivityTemplateData}
			isShowMoreClicked={isShowMoreClicked}
			hasShowMoreData={hasShowMoreData}
		/>
	);
};

export default RecentActivityContainer;
