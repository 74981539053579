import {
	slackConfigAuth,
	slackConfigErrors,
	slackConfigSovren,
} from 'src/_shared/api/settings';

export const slackNotificationSovren = (data, channel) => {
	const body = slackConfigSovren.userAccountNotification;
	body.username = data.username;
	body.attachments[0].fields.push(
		new Object({
			title: data ? data.title : '', // Custom field
			value: data ? data.message : '', // Custom value
			short: true, // Long fields will be full width
		})
	);
	const option = {
		method: 'POST',
		headers: { ...slackConfigSovren.headers },
		mode: 'no-cors',
		body: JSON.stringify(body),
	};
	return fetch(slackConfigSovren.url, option);
};

export const slackNotificationErrors = (data, channel) => {
	const body = slackConfigErrors.userAccountNotification;
	body.username = data.username;
	body.attachments[0].fields.push(
		new Object({
			title: data ? data.title : '', // Custom field
			value: data ? `Location: ${data.location} \n${data.message}` : '', // Custom value
			short: false, // Long fields will be full width
		})
	);
	const option = {
		method: 'POST',
		headers: { ...slackConfigErrors.headers },
		mode: 'no-cors',
		body: JSON.stringify(body),
	};
	return fetch(slackConfigErrors.url, option);
};

export const slackNotificationAuth = (data, channel) => {
	const body = slackConfigAuth.userAccountNotification;
	body.username = data.username;
	body.attachments[0].fields.push(
		new Object({
			title: data ? data.title : '', // Custom field
			value: data ? `${data.message}` : '', // Custom value
			short: false, // Long fields will be full width
		})
	);
	const option = {
		method: 'POST',
		headers: { ...slackConfigAuth.headers },
		mode: 'no-cors',
		body: JSON.stringify(body),
	};
	return fetch(slackConfigAuth.url, option);
};
