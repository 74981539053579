import { createContext, useContext } from 'react';
import { useRnRAPIService } from '../../rewardsAndRecognitionHelper/service/RnRAPI.service.jsx';
import { MANAGE_REWARDS_API_ENDPOINTS } from '../constants/manageRewardsAPI.constants.js';

const ManageRewardsServiceContext = createContext(null);

export const ManageRewardsServiceProvider = (props) => {
  const APIService = useRnRAPIService();

  const getBadgeTypes = () => {
    return APIService.get(MANAGE_REWARDS_API_ENDPOINTS.GET_BADGE_TYPES);
  };

  const getIcons = () => {
    return APIService.get(MANAGE_REWARDS_API_ENDPOINTS.GET_BADGES);
  };

  const getEmployeeLevels = () => {
    return APIService.get(MANAGE_REWARDS_API_ENDPOINTS.GET_EMPLOYEE_LEVELS);
  }

  const getEmployeePayType = () => {
    return APIService.get(MANAGE_REWARDS_API_ENDPOINTS.GET_EMPLOYEE_PAY_TYPE);
  }

  const createBadge = (body) => {
    return APIService.postFormData(MANAGE_REWARDS_API_ENDPOINTS.CREATE_BADGE, body);
  };

  const getBadgeDetails = (id) => {
    return APIService.get(MANAGE_REWARDS_API_ENDPOINTS.GET_BADGE_DETAILS + id);
  }

  const updateBadge = (id, body) => {
    return APIService.putFormData(MANAGE_REWARDS_API_ENDPOINTS.UPDATE_BADGE + id, body);
  }

  const getBadgesList = (request) => {
    return APIService.get(MANAGE_REWARDS_API_ENDPOINTS.GET_BADGES_LIST, request);
  }

  const getAutomationRule = () => {
    return APIService.get(MANAGE_REWARDS_API_ENDPOINTS.GET_AUTOMATION_RULE);
  }

  const ManageRewardsServiceOperations = {
    getBadgeTypes,
    getIcons,
    createBadge,
    getEmployeeLevels,
    getEmployeePayType,
    getBadgeDetails,
    updateBadge,
    getBadgesList,
    getAutomationRule
  };

  return (
    <ManageRewardsServiceContext.Provider
      value={ManageRewardsServiceOperations}
    >
      {props.children}
    </ManageRewardsServiceContext.Provider>
  );
};

export const useManageRewardsService = () => {
  return useContext(ManageRewardsServiceContext);
};