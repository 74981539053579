import gql from 'graphql-tag';
import get from 'lodash/get';
import { compose, graphql } from 'react-apollo';
import {
	createEditEmails,
	queryEditEmailsByCompanyId,
} from '../../graphql/custom/edit-emails';

export const withCreateEditEmails = (Component) => {
	return compose(
		graphql(gql(queryEditEmailsByCompanyId), {
			options: (props) => ({
				variables: {
					companyId: get(props.currentUser, 'companyId'),
					after: null,
				},
				fetchPolicy: 'cache-and-network',
			}),
			props: (props) => ({
				CompanyEmailData: props.data.queryEditEmailsByCompanyId
					? props.data.queryEditEmailsByCompanyId.items
					: undefined,
			}),
		}),
		graphql(gql(createEditEmails), {
			props: (props) => ({
				onCreateEditEmails(input) {
					return props
						.mutate({
							variables: input,
						})
						.then((resp) => resp.data.createEditEmails);
				},
			}),
		})
	)(Component);
};
