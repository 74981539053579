export const queryBonusByCompanyIdIndex = `query QueryBonusByCompanyIdIndex(
    $companyId: ID!
    $first: Int
    $after: String
  ) {
    queryBonusByCompanyIdIndex(
      companyId: $companyId
      first: $first
      after: $after
    ) {
        items {
            id
            amountDue
            companyId
            contactId
            contact {
              id
              firstName
              lastName
              emailAddress
              accountClaimId
              accountClaim {
                id
                employeeId
              }
            }
            currency
            userId
            user {
              id
              firstName
              lastName
              emailAddress
              location
              title
              active
              userGroupId
              userGroup {
                id
                measurement
                name
                currency
              }
              accountClaimId
              accountClaim {
                id
                active
                eligible
                employeeId
                userId
                companyNumber
              }
            }
            jobId
            job {
              id
              title
              referralBonus
              externalJobId
            }
            referralId
            referral {
              id
              jobId
              referralType
              status
              bonusStatus
            }
            hireDate
            startDate
            earnedDate
            payment
            recipientType
            bonusStatus
            notes
            paidDate
          }
          nextToken
    }
  }
  `;
