import React from 'react';
import NoData from './../../assets/svg/quality.svg';
import './noDataState.scss';

const NoDataState = ({ title }) => {
	return (
		<div className="d-flex w-100 h-100 justify-content-center align-items-center flex-column no-data-found">
			<img src={NoData} alt="img"></img>
			<p className="mt-2">{title}</p>
		</div>
	);
};

export default NoDataState;
