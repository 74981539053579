import { css } from 'emotion';
import { COLORS } from '../_shared/styles/colors';

const breakpoints = [576, 767, 991, 1200];

const mq = breakpoints.map((bp) => `@media (max-width: ${bp}px)`);

export const employeesDashboardContainer = css({
	width: '100%',
	height: '100%',
	padding: 20,
	[mq[2]]: {
		padding: '10px 5px',
	},
	backgroundColor: COLORS.lightGray2,
});

export const tableContainer = css({
	borderRadius: 10,
	backgroundColor: 'white',
	'& thead > tr > th': {
		backgroundColor: 'white',
		fontWeight: 600,
		minWidth: 130,
		paddingLeft: 10,
		paddingRight: 10,
	},
	'& tbody > tr > td:first-child': {
		minWidth: 65,
	},
	'& thead > tr > th:first-child': {
		minWidth: 65,
	},
	'& tbody > tr > td': {
		paddingTop: 0,
		paddingBottom: 0,
		height: 65,
		paddingLeft: 10,
		paddingRight: 10,
	},
});

export const toolbarContainer = css({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	marginBottom: 30,
	maxWidth: 1290,
	[mq[2]]: {
		flexDirection: 'column',
	},
});
export const toolBar = css({
	marginBottom: '1px',
});

export const filterComponentsContainer = css({
	display: 'flex',
	justifyContent: 'left',
	alignItems: 'right',
	[mq[2]]: {
		width: '100%',
	},
	[mq[1]]: {
		flexDirection: 'column',
	},
});

export const tableHeaderContainer = css({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	maxWidth: '100%',
	position: 'relative',
	[mq[1]]: {
		flexDirection: 'column',
		alignItems: 'flex-start',
	},
});

export const employeeData = css({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	maxWidth: '100%',
	[mq[1]]: {
		width: '100%',
		marginBottom: 10,
	},
});

export const searchStyle = css({
	fontFamily: '"Open Sans", sans-serif',
	'& input': {
		fontFamily: '"Open Sans", sans-serif',
		borderRadius: 20,
		paddingLeft: '40px !important',
	},
	'& span': {
		left: '12px !important',
	},
	'& i': {
		color: COLORS.darkGray,
		fontSize: 18,
	},
});

export const selectStyle = css({
	fontFamily: '"Open Sans", sans-serif',
	width: 225,
	'& .ant-select-selection__choice': {
		backgroundColor: COLORS.lightGreen,
		color: COLORS.green,
	},
	[mq[1]]: {
		width: '100%',
	},
});

export const SelectContainer = css({
	float: 'right',
	position: 'relative',
	[mq[1]]: {
		margin: '0 0 10px !important',
		width: '100%',
	},

	'& > svg': {
		right: '10px',
		top: '10px',
		pointerEvents: 'none',
		position: 'absolute',
	},
});

export const iconContainer = css({
	width: 0,
	pointerEvents: 'none',
	zIndex: 1,
});

export const paginationStyle = css({
	'.ant-pagination-options': {
		float: 'left',
	},
	'& .ant-pagination': {
		textAlign: 'center',
		marginLeft: 'auto',
		marginRight: 'auto',
		float: 'none',
	},
});

export const tableHeader = css({
	fontFamily: '"Open Sans", sans-serif !important',
	fontWeight: '600 !important',
});

export const btnContainer = css({
	minWidth: 200,
	alignItems: 'center',
	display: 'inline-flex',
	[mq[2]]: {
		width: '100%',
		marginBottom: 20,
	},
});

export const AddButtonText = css({
	color: COLORS.black,
	fontSize: 18,
	fontWeight: 600,
	verticalAlign: '-webkit-baseline-middle',
});

export const AddButtonIcon = css({
	fontSize: 35,
});

export const nameStyle = css({
	color: COLORS.blue,
	fontSize: 16,
	fontWeight: '600 !important',
});

export const nameStyleNoLink = css({
	fontSize: 16,
	fontWeight: '600 !important',
});

export const emailStyle = css({
	fontSize: 14,
	fontWeight: 400,
	marginBottom: 0,
});

export const departmentStyle = css({
	fontSize: 14,
	fontWeight: 400,
});

export const filterIcon = css({
	zIndex: 0,
});

export const avatarContainer = css({
	display: 'flex',
});

export const Avatar = css({
	height: 50,
	width: 50,
	fontWeight: 'bold',
	backgroundColor: COLORS.lightGray,
	color: 'white',
	fontSize: 28,
	textAlign: 'center',
});

export const NoPicture = css({
	height: 50,
	width: 50,
	backgroundColor: '#cfcfcf',
	'& h3': {
		fontWeight: 400,
		color: 'white',
		fontSize: 26,
		textAlign: 'center',
		lineHeight: '50px',
		marginBottom: 0,
	},
});

export const searchbarStyle = css({
	fontFamily: '"Open Sans", sans-serif',
	width: 300,
	'& input': {
		fontFamily: '"Open Sans", sans-serif',
		borderRadius: 20,
		height: 32,
	},
	'& .ant-input:not(:first-child)': {
		paddingLeft: 35,
	},
});

export const resendInviteBtn = css({
	border: 'none',
	padding: 0,
	color: COLORS.hyperLink,
	fontSize: 14,
	fontWeight: 600,
	background: 'transparent',
	'&:hover': {
		backgroundColor: 'transparent',
		color: COLORS.hyperLink,
		border: 'none',
		lineHeight: '14px',
	},
	'&:focus': {
		backgroundColor: 'transparent',
		color: COLORS.hyperLink,
		border: 'none',
	},
});

export const successStyles = css({
	width: 250,
	position: 'fixed',
	top: 300,
	left: 480,
});
export const addEmployeeButtonText = css({
	fontSize: 18,
	fontWeight: 600,
	color: COLORS.black,
});

export const SelectStyles = css({
	fontFamily: '"Open Sans", sans-serif',
	width: '98%',
	position: 'relative',
	'& .ant-select-selection__choice': {
		backgroundColor: COLORS.lightGreen,
		color: COLORS.green,
	},
	'& .anticon': {
		backgroundColor: COLORS.lightGreen,
		color: COLORS.green,
	},
});

export const DropDown = css({
	fontFamily: '"Open Sans", sans-serif',
});

export const SelectStylesNewActive = (length = 1) =>
	css({
		fontFamily: '"Open Sans", sans-serif',
		marginLeft: 15,
		width: length ? 280 : 240,
		maxWidth: 480,
		'& .ant-select-selection': {
			background: COLORS.inputActiveBackground,
			color: COLORS.inputActiveColor,
			borderRadius: 32,
			padding: '0 10px',
			borderColor: COLORS.inputActiveBackground,
			boxShadow: 'none',
		},
		'& .ant-select-selection__placeholder': {
			color: COLORS.inputActiveColor,
		},
		'& .ant-select-search__field__placeholder': {
			color: COLORS.inputActiveColor,
		},
		'& .ant-select-selection__choice': {
			backgroundColor: 'rgba(143, 143, 143, 0.2)',
			color: COLORS.inputActiveColor,
		},
		'& .ant-select-focused .ant-select-selection': {
			borderColor: COLORS.inputActiveColor,
			boxShadow: '0 0 0 2px rgba(143, 143, 143, 0.2)',
		},
		'& .ant-select-selection__choice__remove': {
			color: COLORS.inputActiveColor,
		},
		'& .ant-select-selection:hover': {
			borderColor: COLORS.inputActiveBackground,
			boxShadow: '0 0 0 2px rgba(143, 143, 143, 0.2)',
		},
		'& .ant-select-selection:focus': {
			borderColor: COLORS.inputActiveBackground,
			boxShadow: '0 0 0 2px rgba(143, 143, 143, 0.2)',
		},
		'& .ant-select-selection:active': {
			borderColor: COLORS.inputActiveBackground,
			boxShadow: '0 0 0 2px rgba(143, 143, 143, 0.2)',
		},
		[mq[1]]: {
			width: '100%',
			marginLeft: 0,
			maxWidth: 'none',
		},
	});
export const SelectStylesNew = css({
	fontFamily: '"Open Sans", sans-serif',
	marginLeft: 15,
	width: 240,
	'& .ant-select-selection': {
		background: COLORS.inputDisableBackground,
		color: COLORS.inputDisableColor,
		borderRadius: 32,
		padding: '0 10px',
		borderColor: COLORS.inputDisableBackground,
		boxShadow: 'none',
	},
	'& .ant-select-selection__placeholder': {
		color: COLORS.inputDisableColor,
	},
	'& .ant-select-search__field__placeholder': {
		color: COLORS.inputDisableColor,
	},
	'& .ant-select-selection__choice': {
		backgroundColor: COLORS.lightGreen,
		color: COLORS.green,
	},
	'& .ant-select-focused .ant-select-selection': {
		borderColor: COLORS.inputDisableBackground,
		boxShadow: '0 0 0 2px rgba(143, 143, 143, 0.2)',
	},
	'& .ant-select-selection:hover': {
		borderColor: COLORS.inputActiveBackground,
		boxShadow: '0 0 0 2px rgba(143, 143, 143, 0.2)',
	},
	'& .ant-select-selection:focus': {
		borderColor: COLORS.inputActiveBackground,
		boxShadow: '0 0 0 2px rgba(143, 143, 143, 0.2)',
	},
	'& .ant-select-selection:active': {
		borderColor: COLORS.inputActiveBackground,
		boxShadow: '0 0 0 2px rgba(143, 143, 143, 0.2)',
	},
	[mq[1]]: {
		width: '100%',
		marginLeft: 0,
	},
});

export const filterRow = css({
	display: 'flex',
	flexWrap: 'wrap',
	width: '100%',
	alignItems: 'center',
	marginTop: 10,
	[mq[1]]: {
		marginTop: 0,
	},
});

export const iconContainerDark = css({
	top: '-1px',
	right: 8,
	position: 'absolute',
	zIndex: 1,
});
