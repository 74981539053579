import { createAction } from '../helpers';

export const FETCH_JOBS = 'ManageJobs/FETCH_JOBS';
export const UPDATE_ADD_JOB_FORM = 'ManageJobs/job/UPDATE_ADD_JOB_FORM';
export const RESET_FORM = 'ManageJobs/job/RESET_FORM';
export const SET_CURRENT_JOB = 'ManageJobs/SET_CURRENT_JOB';
export const CLOSE_JOB = 'ManageJobs/CLOSE_JOB';
export const SET_CURRENT_CURRENCY_RATE = 'ManageJobs/SET_CURRENT_CURRENCY';
export const SET_CURRENT_CURRENCY_SYMBOL =
	'ManageJobs/SET_CURRENT_CURRENCY_SYMBOL';

export const fetchJobs = createAction(FETCH_JOBS);
export const updateAddJobForm = createAction(UPDATE_ADD_JOB_FORM);
export const resetAddJobForm = createAction(RESET_FORM);
export const setCurrentJob = createAction(SET_CURRENT_JOB);
export const closeJob = createAction(CLOSE_JOB);
export const setCurrentCurrencyRate = createAction(SET_CURRENT_CURRENCY_RATE);
export const setCurrentCurrencySymbol = createAction(
	SET_CURRENT_CURRENCY_SYMBOL
);
