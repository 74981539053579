export const createUserGroup = `mutation CreateUserGroup($input: CreateUserGroupInput!) {
    createUserGroup(input: $input) {
      id
      companyId
      name
      keywords
      measurement
      active
      currency
    }
  }
  `;
