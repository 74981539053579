import { Button, Checkbox, Divider, Input, Select } from 'antd';
import { get } from 'lodash';
import { useState } from 'react';
import WebIcon from 'src/_shared/components/WebIconComponent.jsx';
import { ml, parse } from 'src/_shared/services/utils.js';
import { COLORS } from 'src/_shared/styles/colors';
import {
	BonusReportRecipientItem,
	monthlyFrequncy,
	weeklyFrequency,
} from 'src/settings-dashboard/bonus-report-recipient-items/';
import { ONBOARDING_SCREENS } from '../constants';
import {
	buttonContainerStyle,
	buttonStyle,
	containerStyle,
	subHeaderStyle,
	subtitleStyle,
	textBodyStyle,
	titleContainerStyle,
	titleStyle,
} from './onboardingStyles.js';

function BonusReporting(props) {
	const {
		nextPage,
		completeOnboardingPage,
		uncompleteOnboardingPage,
		prevPage,
		currentUser,
		allMultiLingualData,
		company,
		navToScreen,
	} = props;
	const partnerHost = get(company, 'partnerHost');
	const [bonusReportSetting, setBonusReportSetting] = useState(
		parse(get(company, 'bonusReportSetting'))
	);
	const [bonusEmailAddress, setBonusEmailAddress] = useState('');
	const frequency = get(bonusReportSetting, 'frequency');
	const frequencyOptions =
		frequency === 'Monthly'
			? monthlyFrequncy
			: frequency === 'Weekly'
				? weeklyFrequency
				: null;
	const onUpdateCompany = (parameters) => {
		setBonusReportSetting(parse(get(parameters, 'input.bonusReportSetting')));
		props.onUpdateCompany(parameters);
	};

	const next = (e) => {
		completeOnboardingPage(ONBOARDING_SCREENS.BONUS_REPORTING, {
			reportingSettingsSelected: bonusReportSetting,
		});
		navToScreen(ONBOARDING_SCREENS.INVITE_EMPLOYEES);
	};

	const back = (e) => {
		const paysWithPoints = get(
			company,
			'onboarding.howToPay.data.payBonusesWithPoints'
		);

		const previousScreen = paysWithPoints
			? ONBOARDING_SCREENS.GIFT_CARD_PAYOUTS
			: ONBOARDING_SCREENS.CREATE_BONUSES;

		uncompleteOnboardingPage(ONBOARDING_SCREENS.BONUS_REPORTING);

		navToScreen(previousScreen);
	};

	const addEmailToBonusReport = () => {
		const emailAddresses =
			bonusReportSetting && bonusReportSetting.emailAddresses
				? bonusReportSetting.emailAddresses.split(',')
				: [];
		if (bonusEmailAddress && !emailAddresses.includes(bonusEmailAddress)) {
			emailAddresses.push(bonusEmailAddress);
			onUpdateCompany({
				input: {
					id: company.id,
					bonusReportSetting: JSON.stringify({
						...bonusReportSetting,
						emailAddresses: emailAddresses.join(','),
					}),
				},
			});
			setBonusEmailAddress('');
		}
	};

	const handleBonusFrequency = (value) => {
		const setting = bonusReportSetting;
		setting.frequency = value;
		onUpdateCompany({
			input: {
				id: company.id,
				bonusReportSetting: JSON.stringify(setting),
			},
		});
	};

	const handleBonusDate = (value) => {
		const setting = bonusReportSetting;
		setting.time = value;
		onUpdateCompany({
			input: {
				id: company.id,
				bonusReportSetting: JSON.stringify(setting),
			},
		});
	};

	const handleBonusReportStatusonChange = (e) => {
		const setting = {
			isEnable: e.target.checked,
			frequency: bonusReportSetting?.frequency
				? bonusReportSetting.frequency
				: 'Monthly',
			time: bonusReportSetting?.time ? bonusReportSetting.time : '1',
			emailAddresses: bonusReportSetting?.emailAddresses
				? bonusReportSetting.emailAddresses
				: '',
		};
		onUpdateCompany({
			input: {
				id: company.id,
				bonusReportSetting: JSON.stringify(setting),
			},
		});
	};

	return (
		<div className={containerStyle}>
			<div className={titleContainerStyle(partnerHost)}>
				<h1 className={titleStyle}>Who Should Receive The Payout Report?</h1>
				<div className={textBodyStyle}>
					<div className={subtitleStyle}>
						The payout report will show all eligible payouts that will be made
						to your employees. If you are using the gift card option for payouts
						this will allow you to track activity and know when your account may
						need more funds.
					</div>
					<div className={subHeaderStyle} style={{ marginBottom: 20 }}>
						Automated Payout Eligible Reports
					</div>
					<Checkbox
						style={{ fontSize: 18 }}
						checked={get(bonusReportSetting, 'isEnable', false)}
						onChange={handleBonusReportStatusonChange}
					>
						Enable Automated Bonus Eligible Reports
					</Checkbox>
					<Divider />
					<div>
						<div style={{ display: 'flex' }}>
							<span className={subHeaderStyle} style={{ marginRight: 15 }}>
								Send automated payout report to:
							</span>
							<Input
								placeholder="Enter Email"
								value={bonusEmailAddress}
								style={{ width: 200 }}
								onPressEnter={addEmailToBonusReport}
								onChange={(e) => setBonusEmailAddress(e.target.value)}
							/>
							<div style={{ marginLeft: 30 }}>
								<Button
									shape="circle"
									type="addButton"
									style={{ backgroundColor: COLORS.blue, fontWeight: 600 }}
									onClick={addEmailToBonusReport}
								>
									<WebIcon
										name="plus-symbol"
										size={16}
										style={{
											paddingBottom: 2,
											strokeWidth: 70,
											stroke: COLORS.white,
										}}
										color={COLORS.white}
									/>
								</Button>
								<span style={{ marginLeft: 5 }}>
									{ml('Add Email', currentUser, allMultiLingualData)}
								</span>
							</div>
						</div>
						<div style={{ marginTop: 30 }}>
							<BonusReportRecipientItem
								style={{
									backgroundColor: '#4b4b4b',
									borderRadius: 20,
									color: COLORS.white,
									marginRight: 5,
									marginLeft: 5,
								}}
								company={company}
								bonusReportSetting={bonusReportSetting}
								onUpdateCompany={onUpdateCompany}
							/>
						</div>

						<div style={{ display: 'flex', marginTop: 35 }}>
							<div style={{ marginRight: 10 }}>Delivery Frequency</div>
							<Select
								placeholder="Select Frequency "
								style={{
									width: 160,
									fontSize: '14px',
								}}
								value={get(bonusReportSetting, 'frequency')}
								onSelect={handleBonusFrequency}
							>
								<Select.Option value="Weekly">Weekly</Select.Option>
								<Select.Option value="Monthly">Monthly</Select.Option>
								<Select.Option value="Daily">Daily</Select.Option>
							</Select>
							{frequencyOptions && (
								<div style={{ display: 'flex' }}>
									<div style={{ marginLeft: 30, marginRight: 10 }}>
										Delivery Date
									</div>
									<Select
										placeholder="Select Date"
										style={{
											width: 160,
											fontSize: '14px',
										}}
										value={get(bonusReportSetting, 'time')}
										onSelect={handleBonusDate}
									>
										{frequencyOptions.map((option) => (
											<Select.Option value={option.value}>
												{option.key}
											</Select.Option>
										))}
									</Select>
								</div>
							)}
						</div>
					</div>

					<div className={buttonContainerStyle}>
						<Button
							type="primary"
							shape="round"
							size="large"
							className={buttonStyle}
							style={{ marginRight: 30 }}
							onClick={back}
						>
							Back
						</Button>
						<Button
							disabled={false}
							type="primary"
							shape="round"
							size="large"
							className={buttonStyle}
							onClick={next}
						>
							Next
						</Button>
					</div>
					<div style={{ height: 40 }} />
				</div>
			</div>
		</div>
	);
}

export default BonusReporting;
