import React, { useState } from 'react';
import { Empty, Spin } from 'antd';
import { useHistory } from 'react-router-dom';
import { CoreBadge } from '../../../RnRCommonComponent';
import './style.scss';
import ViewRewardModal from '../../../RnRCommonComponent/ViewRewardModal/ViewRewardModal.jsx';
import { MAPPED_BADGE_TYPE } from '../../../RnRCommonComponent/CoreBadge/rewardsConstants.js';
import NoDataState from '../../../RnRCommonComponent/noDataState/NoDataState.jsx';
import { addCommas } from '../../../rewardsAndRecognitionHelper/utils.js';

const RewardDashboard = (props) => {
	const { rewardsListData, rewardModalData, getRewardDetail, isLoading, isSharingVisible } =
		props;
	let history = useHistory();
	const [isModelOpen, setIsModelOpen] = useState(false);

	const confirmBadgeType = () => {
		if (
			rewardsListData[0]?.badgeTypeCode === MAPPED_BADGE_TYPE.AUTOMATED ||
			rewardsListData[1]?.badgeTypeCode === MAPPED_BADGE_TYPE.AUTOMATED
		) {
			return false;
		}
		return true;
	};

	return (
		<div className="reward-card d-card">
			<div className="d-flex align-items-center justify-content-between">
				<h4 className="reward-card-title">My Recognitions</h4>
				{(confirmBadgeType && rewardsListData?.length > 0) ||
				rewardsListData?.length > 2 ? (
					<div
						className="reward-card-view-all"
						onClick={() => {
							history.push('/my-recognitions');
						}}
					>
						View All
					</div>
				) : (
					<></>
				)}
			</div>

			{isLoading ? (
				<Spin className="d-flex align-items-center justify-content-center w-100 h-100" />
			) : rewardsListData?.length > 0 ? (
				<>
					{confirmBadgeType() ? (
						<>
							<div className="pt-4 your-rewards your-rewards-list">
								{rewardsListData?.slice(0, 2).map((badge) => (
									<div
										onClick={() => {
											getRewardDetail(badge?.badgeId);
											setIsModelOpen(true);
										}}
										className="text-center home-my-reward single-reward"
										key={badge?.badgeId}
									>
										{badge.badgeId != null ? (
											<>
												<CoreBadge
													badgeType={badge?.badgeTypeCode}
													badgeIcon={badge?.iconCode}
													label={badge?.label}
													color={badge?.color}
													gradientColor={badge?.gradientColor || badge?.gradientcolor}
												/>
												<div className="reward-card-points">
													{addCommas(badge?.points)} Points
												</div>
												{rewardsListData[0]?.rewardBonus === 0 ? (
													<></>
												) : (
													<div className="reward-card-bonus">
														${addCommas(rewardsListData[0]?.rewardBonus)} bonus
													</div>
												)}
											</>
										) : (
											<></>
										)}
									</div>
								))}
							</div>
						</>
					) : (
						<>
							<div className="pt-4 your-rewards your-rewards-list">
								<div
									onClick={() => {
										getRewardDetail(rewardsListData[0]?.badgeId);
										setIsModelOpen(true);
									}}
									className="text-center home-my-reward"
									key={rewardsListData[0]?.badgeId}
								>
									{rewardsListData[0].badgeId != null ? (
										<>
											<CoreBadge
												badgeType={rewardsListData[0]?.badgeTypeCode}
												badgeIcon={rewardsListData[0]?.iconCode}
												label={rewardsListData[0]?.label}
												color={rewardsListData[0]?.color}
												gradientColor={rewardsListData[0]?.gradientColor || rewardsListData[0]?.gradientcolor}
											/>
											<div className="reward-card-points">
												{rewardsListData[0]?.points} Points
											</div>
											{rewardsListData[0]?.rewardBonus === 0 ? (
												<></>
											) : (
												<div className="reward-card-bonus">
													${rewardsListData[0]?.rewardBonus} bonus
												</div>
											)}
										</>
									) : (
										<></>
									)}
								</div>
							</div>
						</>
					)}
				</>
			) : (
				<NoDataState title={"You haven't received an award yet"} />
			)}
			<ViewRewardModal
				isViewRewardModalVisible={isModelOpen}
				handleCloseViewReward={() => setIsModelOpen(false)}
				rewardDetails={rewardModalData}
				isModalLoaderVisible={false}
				isSharingVisible={isSharingVisible}
			/>
		</div>
	);
};

export default RewardDashboard;
