import gql from 'graphql-tag';

export const queryMultiLingualByLanguageCodeModuleIndex = gql`
	query QueryMultiLingualByLanguageCodeModuleIndex(
		$languageCode: String!
		$module: String!
		$first: Int
		$after: String
	) {
		queryMultiLingualByLanguageCodeModuleIndex(
			languageCode: $languageCode
			module: $module
			first: $first
			after: $after
		) {
			items {
				id
				key
				languageCode
				module
				text
			}
			nextToken
		}
	}
`;
