import { css } from 'emotion';
import get from 'lodash/get';
import { COLORS } from '../styles/colors';

export const ReferralBtn = (theme) => {
	let buttonColor = COLORS.blue;
	if (get(theme, 'enabled')) {
		buttonColor = get(theme, 'buttonColor', buttonColor);
	}

	return css({
		fontFamily: '"Open Sans", sans-serif',
		fontWeight: 200,
		color: COLORS.white,
		backgroundColor: buttonColor,
		fontSize: 14,
		width: '100%',
		height: 40,
		borderRadius: '10px',
		border: 'none',
		'&:hover:enabled': {
			border: `1.5px solid ${buttonColor}`,
			color: buttonColor,
			borderColor: buttonColor,
		},
	});
};

export const ReferralRequestBtn = (theme) => {
	let buttonColor = COLORS.blue;
	if (get(theme, 'enabled')) {
		buttonColor = get(theme, 'buttonColor', buttonColor);
	}

	return css({
		fontFamily: '"Open Sans", sans-serif',
		fontWeight: 200,
		color: COLORS.white,
		background: buttonColor,
		fontSize: 12,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		width: 80,
		height: 25,
		borderRadius: '5px',
		border: 'none',
		'&:hover:enabled': {
			border: `1.5px solid ${buttonColor}`,
			color: buttonColor,
		},
	});
};

export const ReferralBtnMyReferrals = css({
	width: 170,
	height: 50,
	background: '#018dd3',
	color: 'white',
	margin: 5,
	fontSize: 16,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	borderRadius: '10px',
	border: '2px solid #018dd3',
	'& .ant-btn: disabled': {
		border: 'none',
	},
});

export const BtnContainer = (theme) => {
	let buttonColor = COLORS.blue;
	if (get(theme, 'enabled')) {
		buttonColor = get(theme, 'buttonColor', buttonColor);
	}

	return css({
		'& .ant-btn: hover': {
			color: buttonColor,
			borderColor: buttonColor,
		},
		'& button:hover > div > svg > path': {
			fill: buttonColor,
		},
		'& button:focus > div > svg > path': {
			fill: buttonColor,
		},

		'& .ant-btn:hover': {
			backgroundColor: `${buttonColor} !important`,
			border: `2px solid ${buttonColor} !important`,
			color: '#fff !important',
		},
		'& .ant-btn:focus': {
			backgroundColor: `${buttonColor} !important`,
			border: `2px solid ${buttonColor} !important`,
			color: '#fff !important',
		},
	});
};

export const ReferralContactBtn = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 200,
	color: COLORS.white,
	background: COLORS.red,
	padding: 8,
	fontSize: 14,
	display: 'flex',
	width: 270,
	height: 40,
});

export const userIconCss = css({
	margin: '0 auto',
	display: 'flex',
});
