import { css } from 'emotion';
import { COLORS } from '../../_shared/styles/colors';

export const subTitleStyle = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 300,
	color: COLORS.subHeading,
	fontSize: '0.85em',
});

export const itemChildren = css({
	marginTop: 10,
});
