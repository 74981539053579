import React from "react";

import RewardPointsAndBonusContainer from '../../container/rewardPointsAndBonusContainer'
import AllActivityContainer from '../../container/AllActivityContainer'
import { Button, Select } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { WebIcon } from 'src/_shared/index.js';

const AllRewardsAdminPresentation = ({
    badgeSummary,
    engagementSummary,
    allActivitiesInfo,
    setSelectedFilter,
    selectedFilter,
    getAllRewardCSV
}) => {
    return <div className="my-recognitions">
        <div className="page-title">
            <h2 className="page-heading">Recognitions</h2>
        </div>
        <div className="my-recognitions-container">
            <div className="row">
                <div className="col-md-6 col-12">
                    <RewardPointsAndBonusContainer
                        title="Rewards Summary"
                        summaryDetals={badgeSummary}
                    />
                </div>
                <div className="col-md-6 col-12">
                    <RewardPointsAndBonusContainer
                        title="Engagement"
                        summaryDetals={engagementSummary}
                    />
                </div>
            </div>
        </div>
        <div className="main select-winner-presentation">
            <div className="page-title">
                <div className="page-title">
                    <h2 className="page-heading">All Activity</h2>
                </div>
                <div style={{ gap: "10px" }} className="page-title d-flex filter-wrap mb-0">
                    <Select
                        className="custom-input choose-reward-input reward-activity-input"
                        suffixIcon={<WebIcon color="var(--oslo-gray)" size={10} name="sort-down" />}
                        value={selectedFilter}
                        onChange={(e) => {
                            setSelectedFilter(e)
                        }}
                        style={{
                            width: "90px"
                        }}
                        placeholder="Employee Pay Type">
                        <Select.Option value={"all"}>All</Select.Option>
                        <Select.Option value={"points"}>Points</Select.Option>
                        <Select.Option value={"physical"}>Physical</Select.Option>
                        <Select.Option value={"bonus"}>Bonus</Select.Option>
                    </Select>
                    <Button type="link" onClick={() => { getAllRewardCSV() }} className="btn-link">
                        <i className="icon-export btn-large" />
                        <span className="page-heading-left">Export CSV</span>
                    </Button>
                </div>
            </div>
        </div>
        <AllActivityContainer allActivitiesInfo={allActivitiesInfo} />
    </div>;
};

export default AllRewardsAdminPresentation;