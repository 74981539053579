import { css } from 'emotion';
import { COLORS } from 'src/_shared/styles/colors';
const ApplyHighlight = (color) => {
	return css({
		background: `${color ? color : '#32aaf0'} !important`,
		color: '#ffffff !important',
	});
};
const ContactHighlight = css({
	background: '#32aaf0 !important',
});
const styles = {
	EmployeeEmail: css({
		fontSize: 16,
		fontWeight: '600 !important',
	}),

	ReferralDetailsContainer: css({
		width: '100%',
		height: '100%',
		padding: '20px 0 40px 40px',
		backgroundColor: COLORS.lightGray2,
		marginBottom: 0,
		maxWidth: 1300,
	}),

	LandingBackground: css({}),

	FlexContainer: css({
		display: 'flex',
		marginBottom: 30,
		width: '100%',
		paddingTop: 20,
	}),

	CardStyles: css({
		'--box-shadow':
			'0px 1px 1.2px hsl(var(--shadow-color) / 0.03),\n' +
			'    0px 2.6px 3.2px -0.4px hsl(var(--shadow-color) / 0.04),\n' +
			'    0px 4.7px 5.9px -0.8px hsl(var(--shadow-color) / 0.05),\n' +
			'    0px 8.7px 10.8px -1.3px hsl(var(--shadow-color) / 0.05),\n' +
			'    0px 15.9px 19.8px -1.7px hsl(var(--shadow-color) / 0.06);',
		'--shadow-color': '0deg 0% 0%',
		boxShadow: 'var(--box-shadow)',
		backgroundColor: 'white',
		height: 'auto',
		margin: 'auto',
		maxWidth: '1200px',
		'@media (max-width: 1580px)': {
			width: 'calc(100% - 300px)',
			minWidth: '1080px',
		},
		'@media (max-width: 1200px)': {
			minWidth: '920px',
			width: 'calc(100% - 280px)',
		},
		'@media (max-width: 992px)': {
			minWidth: '700px',
			width: 'calc(100% - 120px)',
		},
		'@media (max-width: 768px)': {
			minWidth: '600px',
			width: 'calc(100% - 260px)',
		},
		'@media (max-width: 575px)': {
			minWidth: '200px',
			width: '100%',
			borderRadius: '0px !important',
		},
	}),

	CardProfile: css({
		width: '100%',
		marginRight: 15,
		height: '100%',
	}),

	Card2Styles: css({
		width: '30%',
		marginLeft: 15,
		height: 200,
		minWidth: 300,
	}),

	link: css({
		fontFamily: '"Open Sans", sans-serif',
		color: COLORS.blue,
	}),

	BackLink: css({
		fontFamily: '"Open Sans", sans-serif',
		color: COLORS.blue,
		border: 'none',
		backgroundColor: 'transparent',
		paddingLeft: 5,
	}),

	BackIcon: css({
		fontSize: 20,
		verticalAlign: 'sub',
	}),

	EmployeeName: css({
		marginTop: '-8px',
		marginBottom: '-8px',
		fontSize: 28,
		lineSpacing: 0,
	}),

	ReferralSection: css({
		width: 1240,
		marginTop: 10,
	}),

	DepartmentText: css({
		fontFamily: '"Open Sans", sans-serif',
		fontWeight: 400,
		color: COLORS.darkGray,
		fontSize: 16,
		marginLeft: 5,
		marginRight: 5,
	}),

	LocationText: css({
		fontFamily: '"Open Sans", sans-serif',
		fontWeight: 400,
		color: COLORS.darkGray,
		fontSize: 16,
		marginLeft: 5,
		marginRight: 5,
	}),

	CandidateNameStyles: css({
		fontFamily: '"Open Sans", sans-serif',
		fontWeight: 900,
		color: COLORS.darkGray,
		fontSize: '40px',
		marginBottom: 0,
		lineHeight: 1.2,
		marginTop: 20,
	}),
	ByEmailText: css({
		fontFamily: '"Open Sans", sans-serif',
		fontWeight: 600,
		color: COLORS.blue,
		marginBottom: 0,
		lineHeight: 1.2,
		marginLeft: 4,
	}),

	JobTitleStyles: css({
		fontFamily: '"Open Sans", sans-serif',
		fontWeight: 900,
		color: COLORS.darkGray,
		fontSize: '40px',
		marginBottom: 0,
		lineHeight: 1.2,
		marginTop: 20,
	}),

	ReferrerName: css({
		fontFamily: '"Open Sans", sans-serif',
		fontWeight: 600,
		color: COLORS.blue,
		marginBottom: 0,
		lineHeight: 1.2,
		marginLeft: 4,
	}),

	OuterCardContainer: css({
		backgroundColor: 'unset',
		width: '100%',
		margin: 'auto',
		marginTop: '-134px',
	}),

	CardContainer: css({
		margin: 'auto',
	}),

	HeaderBackground: css({
		backgroundColor: COLORS.darkBlue,
		backgroundImage: `linear-gradient(to top, #202A3A 0%, #202A3A 1%, ${COLORS.darkBlue} 100%)`,
		textAlign: 'center',
		padding: 20,
	}),
	FooterBackground: css({
		backgroundColor: COLORS.darkBlue,
		backgroundImage: `linear-gradient(to top, #202A3A 0%, #202A3A 1%, ${COLORS.darkBlue} 100%)`,
		textAlign: 'center',
		padding: 20,
	}),

	Subtitle: css({
		textAlign: 'center',
		color: `${COLORS.red}`,
		marginBottom: 0,
		fontFamily: '"Open Sans", sans-serif',
	}),
	ReferralStatusText: css({
		textAlign: 'center',
		color: `${COLORS.red}`,
		marginBottom: 0,
		fontFamily: '"Open Sans", sans-serif',
	}),
	ApplyButton: css({
		borderRadius: '10px !important',
		fontFamily: '"Open Sans", sans-serif',
		fontWeight: '400 !important',
		margin: 'auto',
		color: COLORS.white,
		backgroundColor: '#0686d0',
		fontSize: '16px !important',
		display: 'flex',
		border: 'none',
		width: '100%',
		lineHeight: 'unset',
		padding: '4 !important',
		minWidth: 'fit-content',
		textAlign: 'center',
		height: '40 !important',
		':hover,:focus': ApplyHighlight('#32aaf0'),
	}),

	DeclineButton: css({
		borderRadius: '10px !important',
		fontFamily: '"Open Sans", sans-serif',
		fontWeight: '400 !important',
		margin: '8px auto 0px auto',
		color: COLORS.text,
		background: COLORS.lightGray3,
		fontSize: 16,
		display: 'flex',
		border: 'none',
		':hover,:focus': ApplyHighlight(COLORS.red),
		height: '50px !important',
		width: '100%',
		minWidth: 'fit-content',
		textAlign: 'center',
	}),

	ContactButton: css({
		fontFamily: '"Open Sans", sans-serif',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		fontWeight: '400 !important',
		color: COLORS.white,
		background: COLORS.blue,
		fontSize: 14,
		marginBottom: 5,
		minWidth: 'fit-content',
		margin: 'auto',
		':hover,:focus': ContactHighlight,
		height: '50px !important',
		width: '100%',
		textAlign: 'center',
	}),

	IconContainer: css({}),

	CancelIconContainer: css({
		marginRight: 10,
		transform: 'rotate(45deg)',
	}),

	Title: css({
		fontSize: '28px',
		alignSelf: 'center',
		fontWeight: 800,
		color: COLORS.darkGray,
		marginBottom: 10,
		textAlign: 'center',
		fontFamily: '"Open Sans", sans-serif',
	}),

	Title2: css({
		color: COLORS.white,
		fontSize: '36px',
	}),

	Divider: css({
		marginLeft: '10px',
		marginRight: '10px',
	}),

	ListStyle: css({
		listStyleType: 'disc',
	}),

	TopSpacing: css({
		marginTop: '60px',
	}),
	ReferralStatusWrap: css({
		marginTop: '60px',
		marginBottom: '16px',
	}),

	SectionTitle: css({
		fontSize: '16px',
		fontWeight: 400,
		color: COLORS.darkGray,
		fontFamily: '"Open Sans", sans-serif',
		marginBottom: '8px',
		width: '100%',
	}),
	FooterText: css({
		fontSize: '16px',
		fontWeight: 400,
		color: COLORS.darkGray,
		fontFamily: '"Open Sans", sans-serif',
		marginBottom: '8px',
		width: '100%',
	}),

	SpinContainer: css({
		width: '100%',
		height: '50vh',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	}),

	SummaryValue: css({
		fontFamily: '"Open Sans", sans-serif',
		color: COLORS.darkGray,
		fontSize: '16px',
		fontWeight: 400,
	}),
	DetailsTitle: css({
		marginTop: 10,
		'&, &': {
			fontSize: '16px',
			fontFamily: '"Open Sans", sans-serif',
		},
		b: {
			marginRight: '4px',
		},
	}),

	JobTypeRow: css({
		marginTop: 10,
		'&, &': {
			fontSize: '16px',
			fontFamily: '"Open Sans", sans-serif',
		},
		b: {
			marginRight: '4px',
		},
	}),
	SalaryRow: css({
		marginTop: 10,
		'&, &': {
			fontSize: '16px',
			fontFamily: '"Open Sans", sans-serif',
		},
		b: {
			marginRight: '4px',
		},
	}),
	RetentionBonusRow: css({
		marginTop: 10,
		'&, &': {
			fontSize: '16px',
			fontFamily: '"Open Sans", sans-serif',
		},
		b: {
			marginRight: '4px',
		},
	}),

	ButtonSpacing: css({
		justifyContent: 'center',
		width: '60%',
		minWidth: 'fit-content',
		marginTop: '15px',
		marginLeft: 'auto !important',
		marginRight: 'auto !important',
		'@media (max-width: 411px)': {
			display: 'inline-block',
			justifyContent: 'space-around',
		},
		display: 'flex',
		flexDirection: 'column',
		rowGap: '10px',
		justifyContent: 'center',
		margin: 'auto',
		width: '60%',
	}),

	LogoContainer: css({
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		paddingBottom: '20px',
		paddingTop: '20px',
		width: '500px',
		maxWidth: '100%',
		img: {
			width: '500px',
			maxWidth: '100%',
		},
	}),

	LoaderHeight: css({
		'& div': {
			'& div': {
				height: '5vh',
				marginTop: '2rem',
			},
		},
		height: '10vh',
		overflow: 'hidden',
	}),

	MiscText: css({
		textAlign: 'left',
		fontSize: '12px',
		fontStyle: 'italic',
	}),

	MenuColor: css({
		color: COLORS.blue,
		cursor: 'pointer',
	}),

	JobInfoContainer: css({
		marginTop: 10,
		textAlign: 'left',
		width: '100%',
		height: 'auto',
		margin: '0 auto',
		'@media (max-width: 575px)': {
			width: '100%',
		},
		'@media (max-width: 991px) and (min-width: 768px)': {
			width: '100%',
		},
		'@media (max-width: 1199px) and (min-width: 992px)': {
			width: '100%',
		},
		'@media (max-width: 1580px) and (min-width: 1200px)': {
			width: '100%',
		},
	}),

	InfoStyle: css({
		fontSize: 12,
		fontWeight: 400,
		textAlign: 'center',
		color: `${COLORS.gray}`,
		//width: '60%',
		height: 'auto',
		margin: '0 auto',
		paddingTop: 30,
		'@media (max-width: 575px)': {
			width: '100%',
		},
	}),

	PopoverStyle: css({
		'& .ant-popover-inner-content': {
			borderColor: 'rgba(0, 0, 0, 0.75)',
			borderRadius: 4,
			color: 'white',
			backgroundColor: 'rgba(0, 0, 0, 0.75)',
			padding: 5,
		},
		'& .ant-popover-arrow': {
			backgroundColor: 'rgba(0, 0, 0, 0.75)',
			borderColor: 'rgba(0, 0, 0, 0.75)',
			border: '0px solid',
			zIndex: -1,
		},
	}),

	OuterCardContainerError: css({
		backgroundColor: COLORS.white,
		width: '100%',
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, 50%)',
	}),

	ContainerDiv: css({
		height: 'auto',
		marginTop: 0,
		position: 'relative',
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'center',
	}),

	DetailContainer: css({
		//width: '60%',
		height: 'auto',
		margin: '0 auto',
		'@media (max-width: 575px)': {
			maxWidth: '100%',
		},
		'@media (max-width: 991px) and (min-width: 576px)': {
			maxWidth: '576px',
		},
		'@media (max-width: 1199px) and (min-width: 992px)': {
			maxWidth: '600px',
		},
		'@media (max-width: 1580px) and (min-width: 1200px)': {
			maxWidth: '680px',
		},
	}),

	FormItemStyles: css({
		marginBottom: '0 !important',
	}),
	PolicyWrapper: css({
		'@media (max-width: 575px)': {
			maxWidth: '100%',
		},
		'@media (max-width: 991px) and (min-width: 576px)': {
			maxWidth: '576px',
		},
		'@media (max-width: 1199px) and (min-width: 992px)': {
			maxWidth: '600px',
		},
		'@media (max-width: 1580px) and (min-width: 1200px)': {
			maxWidth: '680px',
		},
		margin: '0 auto 30px',
	}),
	NoteStyle: css({
		fontSize: 16,
		fontWeight: 900,
		textAlign: 'center',
		color: COLORS.darkGray,
		paddingTop: 30,
		//width: '60%',
		'@media (max-width: 575px)': {
			maxWidth: '100%',
		},
	}),

	RemoveBottomMargin: css({
		'& .ant-form-item': {
			marginBottom: 0,
		},
		minHeight: '40px',
		borderRadius: '8px',
	}),

	FileWrap: css({
		display: 'inline-block',
		position: 'relative',
		fontWeight: 'bold',
		color: COLORS.blue,
		label: {
			cursor: 'pointer',
		},
		input: {
			opacity: 0,
			width: '0.1px',
		},
	}),

	FullWrapper: css({
		height: '100%',
	}),
};

export default styles;
