import { Tag } from 'antd';

const PayoutDaysDisplay = ({ currentUser, tiers }) => {
	const parseTier = (item) => {
		try {
			return typeof item === 'string' ? JSON.parse(item) : item;
		} catch (e) {
			console.error('Error parsing tieredBonus item:', e);
			return null;
		}
	};

	const formatPayoutDays = (tiers) => {
		const payoutDays = tiers
			.map(parseTier)
			.filter(
				(item) =>
					item?.payOutDays != null &&
					currentUser?.userGroupId === item?.userGroup &&
					item?.recipientType === 'employee'
			)
			.map((item) => item.payOutDays);

		if (payoutDays.length === 0) return null;

		if (payoutDays.length === 1) {
			return payoutDays[0] === '1'
				? `${payoutDays[0]} Day After Hire`
				: `${payoutDays[0]} Days After Hire`;
		}

		return `${payoutDays.slice(0, -1).join(', ')} & ${
			payoutDays[payoutDays.length - 1]
		} Days After Hire`;
	};

	const payoutDaysDisplay = formatPayoutDays(tiers);

	return (
		<Tag
			style={{
				borderRadius: '10px',
				marginRight: '0px',
				whiteSpace: 'nowrap',
				overflow: 'hidden',
				textOverflow: 'ellipsis',
				cursor: 'default',
			}}
			title={payoutDaysDisplay || 'No Bonus'}
		>
			{payoutDaysDisplay || 'No Bonus'}
		</Tag>
	);
};

export default PayoutDaysDisplay;
