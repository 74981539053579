import { connect } from 'react-redux';
import { withUserByIdUpdateProfile } from 'src/_shared/api/components/edit-profile/';
import { dashboardActions, userActions } from 'src/actions.js';
import { compose } from '../_shared/services/utils.js';
import MyProfileComponent from './MyProfileComponent.jsx';

const mapStateToProperties = (state) => {
	const { currentUser } = state.user;
	const { allMultiLingualData } = state.dashboard;
	return {
		currentUser,
		id: currentUser.id,
		filter: { companyId: { eq: currentUser.companyId } },
		allMultiLingualData,
	};
};

const mapDispatchToProperties = (dispatch) => {
	return {
		updateCurrentUser(values) {
			dispatch(userActions.updateCurrentUser(values));
		},
		setMultiLingualData(data) {
			dispatch(dashboardActions.createSetMultiLingualData(data));
		},
	};
};

const MyProfileWithApi = compose(withUserByIdUpdateProfile)(MyProfileComponent);

export const MyProfile = connect(
	mapStateToProperties,
	mapDispatchToProperties
)(MyProfileWithApi);
