import React from "react";
import NoDataState from "../../../RnRCommonComponent/noDataState/NoDataState.jsx";
import { addCommas } from "../../../rewardsAndRecognitionHelper/utils.js";

const RewardsLeaderBoard = (props) => {
  const { leaderboardData } = props;
  return <div className='reward-leaderboard-card'>
    <div className='d-flex justify-content-between align-items-center'>
      <h4 className='reward-leaderboard-card-title'>
        RECOGNITION LEADERBOARD
      </h4>
    </div>
    {leaderboardData?.length > 0 ? leaderboardData?.slice(0, 10).map((data, index) => {
      return <div key={index} className='reward-leaderboard-list d-flex justify-content-between align-items-center py-1'>
        <div className='reward-leaderboard-list-name d-flex align-items-center'>
          <div className={data.isCurrent ? 'pr-1 text-blue' : 'pr-1'}>{data?.rank}.</div>
          <div className={data.isCurrent ? 'pr-1 text-blue' : 'pr-1'}>{data?.firstName} {data?.lastName}</div>
        </div>
        <div className={data.isCurrent ? 'reward-leaderboard-list-points-blue' : 'reward-leaderboard-list-points'}>{addCommas(data?.points)}</div>
      </div>
    }) :
      <NoDataState title={"No leaders found yet."} />
    }
  </div >
};

export default RewardsLeaderBoard;
