import { Link } from 'react-router-dom';
import { borderedTile } from '../../../_shared/components/bordered-tile';
import {
	headerClass,
	linkItemClass,
	linkListContainer,
	quickLinkClass,
} from './quickLinksStyles.js';

function BaseQuickLinks(props) {
	const {
		quickLinksText,
		postNewJobText,
		addContactsText,
		inviteEmployeesText,
		companySettingsText,
	} = props;
	return (
		<div style={{ height: 191 }}>
			<div>
				<h2 className={headerClass}>{quickLinksText}</h2>
			</div>
			<div style={{ marginLeft: '15px' }}>
				<ul className={linkListContainer}>
					<li className={linkItemClass}>
						<Link
							className={quickLinkClass}
							text={postNewJobText}
							to={{
								pathname: '/jobs',
							}}
						>
							{postNewJobText}
						</Link>
					</li>
					<li className={linkItemClass}>
						<Link
							className={quickLinkClass}
							text={addContactsText}
							to={{
								pathname: '/mycontacts',
							}}
						>
							{addContactsText}
						</Link>
					</li>
					<li className={linkItemClass}>
						<Link
							className={quickLinkClass}
							text={inviteEmployeesText}
							to={{
								pathname: '/employees/form',
							}}
						>
							{inviteEmployeesText}
						</Link>
					</li>
					<li className={linkItemClass}>
						<Link
							className={quickLinkClass}
							text={companySettingsText}
							to="/settings"
						>
							{companySettingsText}
						</Link>
					</li>
				</ul>
			</div>
		</div>
	);
}

export const QuickLinks = borderedTile(BaseQuickLinks);
