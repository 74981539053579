import { Select } from 'antd';
import _ from 'lodash';
import WebIcon from 'src/_shared/components/WebIconComponent.jsx';
import { COLORS } from '../../_shared/styles/colors';
import {
	SelectContainer,
	SelectStyles,
	iconContainer,
} from '../bonusReportStyles.js';

function FilterByDepartment(props) {
	const { handleDepartmentFilter, departments } = props;
	const { Option } = Select;

	const sortedDepartments = _.sortBy(departments, ['name']);
	const options = sortedDepartments.map((department) => {
		return (
			<Option key={department.id} value={department.name}>
				{department.name}
			</Option>
		);
	});

	return (
		<div className={SelectContainer}>
			<Select
				key="choosedepartment"
				className={SelectStyles}
				mode="multiple"
				placeholder="Department"
				onChange={(value) => handleDepartmentFilter(value)}
			>
				{options}
			</Select>
			<div className={iconContainer}>
				<WebIcon color={COLORS.darkGray} size={14} name="sort-down" />
			</div>
		</div>
	);
}

export default FilterByDepartment;
