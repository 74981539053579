export const queryEditEmailsByCompanyId = `query QueryEditEmailsByCompanyId(
    $companyId: ID!
    $first: Int
    $after: String
  ) {
    queryEditEmailsByCompanyId(
      companyId: $companyId
      first: $first
      after: $after
    ) {
      items {
        id
        companyId
        referredEmailText
        alertEmailText
        inviteEmailText
        acceptedEmailText
        applicationEmailText
        updatedEmailText
        ineligibleEmailText
        welcomeEmailText
        footerEmailText
        signatureEmailText
      }
      nextToken
    }
  }
  `;
