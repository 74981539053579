import { jobData } from 'src/test-data';
import * as Actions from './manageJobsActions.js';
import { state as initialState } from './manageJobsState.js';

export const manageJobsReducer = (state = initialState, action) => {
	switch (action.type) {
		case Actions.FETCH_JOBS: {
			return handleFetchJobs(state, action);
		}

		case Actions.SET_CURRENT_JOB: {
			return handleSetJob(state, action);
		}

		case Actions.UPDATE_ADD_JOB_FORM: {
			return handleFormUpdates(state, action);
		}

		case Actions.RESET_FORM: {
			return handleFormReset(state, action);
		}

		case Actions.SET_CURRENT_CURRENCY_RATE: {
			return handleSetCurrencyRate(state, action);
		}

		case Actions.SET_CURRENT_CURRENCY_SYMBOL: {
			return handleSetCurrencySymbol(state, action);
		}

		default: {
			return state;
		}
	}
};

const handleFetchJobs = (state, { payload }) => {
	return {
		...state,
		jobs: jobData, // TODO: replace with the real payload
	};
};

const handleFormUpdates = (state, { payload }) => {
	const newItems = {};
	for (const key of Object.keys(payload)) {
		newItems[key] = payload[key];
	}

	return {
		...state,
		currentJob: { ...state.currentJob, ...newItems },
	};
};

const handleFormReset = (state) => {
	return { ...state, currentJob: { ...initialState.currentJob } };
};

const handleSetJob = (state, { payload }) => {
	return {
		...state,
		currentJob: payload,
	};
};

const handleSetCurrencyRate = (state, { payload }) => {
	return {
		...state,
		currentCurrencyRate: payload,
	};
};

const handleSetCurrencySymbol = (state, { payload }) => {
	return {
		...state,
		currentCurrencySymbol: payload,
	};
};
