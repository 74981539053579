export const createWebNotification = `mutation CreateWebNotification($input: CreateWebNotificationInput!) {
    createWebNotification(input: $input) {
      id
      type
      userId
      referralDevice
      referralRequestedStatus
      referralType
      requestingUserId
      contactId
      referralId
      jobId
      matches
      message
      dateCreated
      questionsData
    }
  }
  `;
