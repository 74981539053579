import { css } from 'emotion';
import { COLORS } from '../../styles/colors';

export const containerClass = css({
	marginTop: 15,
	display: 'flex',
	justifyContent: 'center',
});

export const textEmphasisClass = (emphasize, isCurrency) =>
	css({
		color: emphasize && isCurrency ? COLORS.green : 'default',
		fontWeight: emphasize ? 'bolder' : 'lighter',
		textTransform: 'capitalize',
	});

export const tieredListClass = css({
	listStyle: 'none',
	padding: 0,
	width: '100%',
});

export const tierItemClass = css({
	width: '100%',
	maxWidth: 250,
	display: 'flex',
	justifyContent: 'space-between',
});

export const tierItemClassManageJobs = css({
	width: 350,
	display: 'flex',
	justifyContent: 'space-between',
});

export const myReferralsTextEmphasisClass = (emphasize, isCurrency) =>
	css({
		color: isCurrency ? COLORS.green : COLORS.gray2,
		fontWeight: isCurrency ? 600 : 'lighter',
		textTransform: 'capitalize',
	});

export const myReferralsContainerClass = css({
	display: 'flex',
	justifyContent: 'left',
});
