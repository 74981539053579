import { useState } from 'react';

import { useHomeService } from '../../service/home.services.jsx';


const useClapCount = ({ setFeedListData, feedListData }) => {
    const [clapCount, setClapCount] = useState({});

    const homeAPIService = useHomeService();

    const updateFeedListData = (id, feedObj) => {
        const updatedFeedListData = feedListData.map((feed) => {
            if (feed?.id === id) {
                return {
                    ...feed,
                    feedInteraction: {
                        ...feed.feedInteraction,
                        id: feedObj.id,
                        click: feedObj.click,
                        totalCounts: feedObj.totalCounts,
                    },
                };
            }
            return feed;
        });
        setFeedListData(updatedFeedListData);
    };

    const handleClapCount = async (id, isPositiveInteraction, feedInteractionId = "") => {
        if (!id) return;
        try {
            let clapData = {
                badgeAssignmentId: id,
                interactionType: 'Clap',
                isPositiveInteraction: isPositiveInteraction,
            };
            if (!isPositiveInteraction) {
                clapData = { ...clapData, id: feedInteractionId }
            }
            const response = await homeAPIService.createFeedInteraction(clapData);
            if (response?.code === 201) {
                updateFeedListData(id, response.data);
            }
        }
        catch (error) {
            console.log(error);
        }
    };

    return {
        handleClapCount,
        clapCount,
        setClapCount
    };
};

export default useClapCount;
