import { EditOutlined } from '@ant-design/icons';
import Sketch from '@uiw/react-color-sketch';
import { Button, Card, Input, Modal } from 'antd';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { getTextColorFromBGColor } from 'src/_shared/services/utils.js';
import styles from './Item.module.css';

export const Item = React.memo(
	React.forwardRef(
		(
			{
				color,
				dragOverlay,
				dragging,
				disabled,
				fadeIn,
				handle,
				handleProps,
				height,
				hexColors,
				setHexColors,
				tilesText,
				setTilesText,
				index,
				listeners,
				onRemove,
				renderItem,
				sorting,
				style,
				transition,
				transform,
				translatedTileNames,
				value,
				wrapperStyle,
				...props
			},
			ref
		) => {
			const [editableCard, setEditableCard] = useState(false);
			const [visible, setVisible] = useState(false);

			useEffect(() => {
				if (
					value?.toString() === '1001' ||
					value?.toString() === '1002' ||
					value?.toString() === '1012' ||
					value?.toString() === '1013'
				) {
					setEditableCard(true);
				}
			}, []);

			useEffect(() => {
				if (!dragOverlay) {
					return;
				}

				document.body.style.cursor = 'grabbing';

				return () => {
					document.body.style.cursor = '';
				};
			}, [dragOverlay]);

			const toggleModal = () => {
				setVisible(!visible);
			};

			const handleColorChange = (color) => {
				setHexColors({ ...hexColors, [value]: color.hex });
			};

			const handleTextChange = (e, textType) => {
				switch (textType) {
					case 'header': {
						if (e.target.value.length <= 10) {
							setTilesText({
								...tilesText,
								[value]: {
									...tilesText[value],
									headerText: e.target.value.toUpperCase(),
								},
							});
						}

						break;
					}

					case 'subheader': {
						if (e.target.value.length <= 26) {
							setTilesText({
								...tilesText,
								[value]: {
									...tilesText[value],
									subheaderText: e.target.value.toUpperCase(),
								},
							});
						}

						break;
					}

					case 'button': {
						if (e.target.value.length <= 26) {
							setTilesText({
								...tilesText,
								[value]: {
									...tilesText[value],
									buttonText: e.target.value.toUpperCase(),
								},
							});
						}

						break;
					}
					// No default
				}
			};

			const modalRender = () => (
				<Card
					title="Tile Customizations"
					style={{
						width: 260,
						boxShadow:
							'rgb(0 0 0 / 15%) 0px 0px 0px 1px, rgb(0 0 0 / 15%) 0px 8px 16px',
					}}
				>
					<Sketch
						disableAlpha
						style={{ marginBottom: 16, boxShadow: 'none' }}
						color={hexColors[value]}
						onChange={handleColorChange}
					/>
					<label>Header Text:</label>
					<Input
						value={tilesText[value].headerText}
						onChange={(e) => handleTextChange(e, 'header')}
					/>
					<label style={{ marginTop: 16 }}>Subheader Text:</label>
					<Input
						value={tilesText[value].subheaderText}
						onChange={(e) => handleTextChange(e, 'subheader')}
					/>
					<label style={{ marginTop: 16 }}>Button Text:</label>
					<Input
						value={tilesText[value].buttonText}
						onChange={(e) => handleTextChange(e, 'button')}
					/>
				</Card>
			);

			const editIcon = () => (
				<div className="preview-tile-edit">
					<Button
						shape="circle"
						icon={<EditOutlined />}
						onClick={() => toggleModal()}
					/>
				</div>
			);

			const fontColor = dragging ? 'var(--oslo-gray' : 'var(--black)';

			return renderItem ? (
				renderItem({
					dragOverlay,
					dragging,
					sorting,
					index,
					fadeIn,
					listeners,
					ref,
					style,
					transform,
					transition,
					value,
				})
			) : (
				<li
					ref={ref}
					className={classNames(
						styles.Wrapper,
						fadeIn && styles.fadeIn,
						sorting && styles.sorting,
						dragOverlay && styles.dragOverlay
					)}
					style={{
						...wrapperStyle,
						listStyle: 'none',
						transition: [transition, wrapperStyle?.transition]
							.filter(Boolean)
							.join(', '),
						'--translate-x': transform
							? `${Math.round(transform.x)}px`
							: undefined,
						'--translate-y': transform
							? `${Math.round(transform.y)}px`
							: undefined,
						'--scale-x': transform?.scaleX ? `${transform.scaleX}` : undefined,
						'--scale-y': transform?.scaleY ? `${transform.scaleY}` : undefined,
						'--index': index,
						'--color': color,
					}}
					data-id={value?.toString()}
				>
					<h3
						className="ed-title"
						style={{
							fontSize: '1.15em',
							marginBottom: '6px',
							color: fontColor,
							wordBreak: 'break-word',
						}}
					>
						{translatedTileNames[value - 1001]}
					</h3>
					{editableCard && editIcon()}
					<div
						className={classNames(
							styles.Item,
							dragging && styles.dragging,
							handle && styles.withHandle,
							dragOverlay && styles.dragOverlay,
							disabled && styles.disabled,
							color && styles.color
						)}
						style={{ ...style, padding: '3% 1%' }}
						data-cypress="draggable-item"
						{...(handle ? undefined : listeners)}
						{...props}
						tabIndex={handle ? undefined : 0}
						data-id={value?.toString()}
					>
						{editableCard ? (
							<div
								className="preview-tile-wrapper"
								style={{
									backgroundColor: hexColors[value],
								}}
							>
								<div
									className="preview-tile-header"
									style={{
										color: getTextColorFromBGColor({
											backgroundColor: hexColors[value],
										}),
									}}
								>
									{tilesText[value].headerText}
								</div>
								<div
									className="preview-tile-subheader"
									style={{
										color: getTextColorFromBGColor({
											backgroundColor: hexColors[value],
										}),
									}}
								>
									{tilesText[value].subheaderText}
								</div>
								<div className="preview-tile-button">
									<div>{tilesText[value].buttonText}</div>
								</div>
							</div>
						) : null}
					</div>
					<Modal
						width={100}
						style={{ pointerEvents: 'auto' }}
						open={visible}
						closable={false}
						mask={false}
						modalRender={modalRender}
						onCancel={toggleModal}
					/>
				</li>
			);
		}
	)
);
