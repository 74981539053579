import gql from 'graphql-tag';
import get from 'lodash/get';
import { compose, graphql } from 'react-apollo';
import uuid from 'uuid/v4';
import { createContact, updateContact } from '../../graphql/custom/contacts';
import {
	createReferral,
	queryGDPRReferralLogByCompanyIdIndex,
	updateReferralStatus,
} from '../../graphql/custom/referrals';

export const withCreateReferralAddReferral = (Component) => {
	return compose(
		graphql(gql(queryGDPRReferralLogByCompanyIdIndex), {
			options: (props) => ({
				variables: {
					companyId: props.currentUser.companyId,
				},
				fetchPolicy: 'cache-and-network',
			}),
			props(response, previous) {
				const referralLogs = get(
					response,
					['data', 'queryGDPRReferralLogByCompanyIdIndex', 'items'],
					get(previous, 'referralLogs', [])
				);

				const referralLogsNextToken = get(
					response,
					['data', 'queryGDPRReferralLogByCompanyIdIndex', 'nextToken'],
					null
				);
				const onFetchMoreContacts = makeOnFetchMoreContacts(
					response.data.fetchMore,
					referralLogsNextToken
				);
				return { referralLogs, onFetchMoreContacts, referralLogsNextToken };
			},
		}),

		graphql(gql(createContact), {
			props: (props) => ({
				ImportedCreateContact(input) {
					const optimisticResponseData = {
						id: uuid(),
						...input.input,
						referrals: null,
						__typename: 'Contact',
					};
					return props.mutate({
						variables: input,
						optimisticResponse: {
							__typename: 'Mutation',
							createContact: {
								__typename: 'createContact',
								...optimisticResponseData,
							},
						},
					});
				},
			}),
		}),
		graphql(gql(updateContact), {
			props: (props) => ({
				onUpdateContactDeckStatus(input) {
					const optimisticResponseData = {
						...input.input,
						__typename: 'Contact',
					};
					return props.mutate({
						variables: { input: { ...input } },
						optimisticResponse: {
							__typename: 'Mutation',
							updateContact: {
								__typename: 'updateContact',
								...optimisticResponseData,
							},
						},
					});
				},
			}),
		}),
		graphql(gql(updateContact), {
			props: (props) => ({
				onUpdateContact(input) {
					return props.mutate({
						context: {
							headers: {
								'x-frame-options': 'deny', // This header will reach the server
							},
						},
						variables: input,
					});
				},
			}),
		}),
		graphql(gql(createReferral), {
			props: (props) => ({
				onCreateReferral(input) {
					const optimisticResponseData = {
						...input.input,
					};
					return props.mutate({
						variables: input,
						optimisticResponse: {
							__typeName: 'Mutation',
							createReferral: {
								__typeName: 'createReferral',
								...optimisticResponseData,
							},
						},
					});
				},
			}),
		}),
		graphql(gql(updateReferralStatus), {
			props: (props) => ({
				onUpdateReferral(input) {
					return props.mutate({
						variables: input,
					});
				},
			}),
		})
	)(Component);
};

const makeOnFetchMoreContacts = (fetchMore, nextToken) => {
	if (!nextToken) {
		return null;
	}

	return () => {
		fetchMore({
			variables: { after: nextToken },
			updateQuery(previous, { fetchMoreResult }) {
				if (!fetchMoreResult) {
					return previous;
				}

				return {
					...previous,
					loading: false,
					queryContactsByUserIdIndex: {
						...previous.queryContactsByUserIdIndex,
						...fetchMoreResult.queryContactsByUserIdIndex,
						items: [
							...previous.queryContactsByUserIdIndex.items,
							...fetchMoreResult.queryContactsByUserIdIndex.items,
						],
					},
				};
			},
		});
	};
};
