import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Col, Input, Popover, Row } from 'antd';
import get from 'lodash/get';
import { useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';
import { filterPhoneNumber } from '../../services/utils';
import { emailPattern, phonePattern } from 'src/_shared/constants/regex';
import * as ModalStyles from '../referralRequestModalStyles.js';
import { ToggleEmailPhone } from './ToggleEmailPhoneComponent.jsx';

function ReferralLinkAutoComplete(props) {
	const {
		contact,
		form,
		referralType,
		ContactOptions,
		customValidator,
		matchFound,
		visible,
		changeReferralTypeCompliance,
	} = props;

	const [locatedContact, setLocatedContact] = useState([]);
	const [email, setEmail] = useState(get(contact, 'emailAddress', null));
	const [phone, setPhone] = useState(get(contact, 'phoneNumber', null));

	if (
		get(contact, 'emailAddress', false) &&
		contact.emailAddress !== email &&
		referralType === 'email'
	) {
		setEmail(get(contact, 'emailAddress', null));
	}

	if (
		get(contact, 'phoneNumber', false) &&
		filterPhoneNumber(contact.phoneNumber) !== filterPhoneNumber(phone) &&
		referralType === 'text'
	) {
		setPhone(filterPhoneNumber(get(phone, 'phoneNumber', null)));
	}

	const { getFieldDecorator } = form;
	const FormItem = Form.Item;

	const handleEmailValidator = (rule, value, callback) => {
		const { visible } = props;
		const errors = [];

		if (!emailPattern.test(value) && value !== '') {
			const error = new Error('Please enter a valid email address.');
			errors.push(error);
		}

		if (errors.length > 0) {
			callback(
				<Popover
					content={get(errors[0], 'message', null)}
					open={visible}
					placement="bottomLeft"
					overlayStyle={{ width: 190 }}
					overlayClassName={ModalStyles.PopoverStyle}
					style={{
						position: 'absolute',
						fontWeight: 1000,
						margin: 0,
						padding: 0,
					}}
				>
					<div
						style={{
							position: 'relative',
							fontWeight: 1000,
						}}
					/>
				</Popover>
			);
			return;
		}

		callback();
	};

	const handlePhoneNumberValidator = (rule, value, callback) => {
		const { visible } = props;
		const errors = [];
		if (!phonePattern.test(filterPhoneNumber(value)) && phone) {
			const error = new Error('Please enter a valid phone number.');
			errors.push(error);
		}

		if (errors.length > 0) {
			callback(
				<Popover
					content={get(errors[0], 'message', null)}
					open={visible}
					placement="bottomLeft"
					overlayStyle={{ width: 190 }}
					overlayClassName={ModalStyles.PopoverStyle}
					style={{
						position: 'absolute',
						fontWeight: 1000,
						margin: 0,
						padding: 0,
					}}
				>
					<div
						style={{
							position: 'relative',
							fontWeight: 1000,
						}}
					/>
				</Popover>
			);
			return;
		}

		callback();
	};

	return (
		<div>
			<h2 className={ModalStyles.FormTitle}>Enter Referral Information</h2>
			<Row type="flex" justify="center" gutter={8}>
				<Col xs={24} md={12}>
					<FormItem className={ModalStyles.FormItemStyles}>
						{getFieldDecorator('firstName', {
							rules: [
								{
									required: true,
									message: visible ? (
										<Popover
											open={visible}
											placement="topLeft"
											overlayClassName={ModalStyles.PopoverStyle}
											style={{
												position: 'absolute',
												fontWeight: 1000,
												margin: 0,
												padding: 0,
											}}
											content="Enter a first name."
										>
											<div
												style={{
													position: 'relative',
													top: -50,
													display: 'inline-block',
												}}
											/>
										</Popover>
									) : null,
								},
							],
						})(
							<Input
								className={ModalStyles.RemoveBottomMargin}
								placeholder="First Name"
							/>
						)}
					</FormItem>
				</Col>
				<Col xs={24} md={12}>
					<FormItem className={ModalStyles.FormItemStyles}>
						{getFieldDecorator('lastName', {
							rules: [
								{
									required: true,
									message: visible ? (
										<Popover
											open={visible}
											placement="topLeft"
											overlayClassName={ModalStyles.PopoverStyle}
											style={{
												position: 'absolute',
												fontWeight: 1000,
												margin: 0,
												padding: 0,
											}}
											content="Enter a last name."
										>
											<div
												style={{
													position: 'relative',
													top: -50,
													display: 'inline-block',
												}}
											/>
										</Popover>
									) : null,
								},
							],
						})(
							<Input
								className={ModalStyles.RemoveBottomMargin}
								placeholder="Last Name"
							/>
						)}
					</FormItem>
				</Col>
			</Row>
			<Row gutter={8}>
				<Col xs={24} md={12}>
					<ToggleEmailPhone
						visible
						referralType={referralType}
						onClick={(referralType) => {
							changeReferralTypeCompliance(referralType);
						}}
					/>
				</Col>
				<Col xs={24} md={12}>
					{referralType === 'email' ? (
						<FormItem className={ModalStyles.FormItemStyles}>
							{getFieldDecorator('emailAddress', {
								rules: [{ validator: handleEmailValidator }],
							})(
								<Input
									className={ModalStyles.RemoveBottomMargin}
									placeholder="Email"
								/>
							)}
						</FormItem>
					) : (
						<FormItem className={ModalStyles.FormItemStyles}>
							{getFieldDecorator('phoneNumber', {
								rules: [{ validator: handlePhoneNumberValidator }],
							})(
								<PhoneInput
									inputStyle={{
										border: '1px solid',
										borderRadius: 4,
										borderColor: 'rgba(217, 217, 217, 0.95)',
										color: 'rgba(0, 0, 0, 0.65)',
										font: '400 14px system-ui',
										padding: '0px 0px 0px 50px',
										height: 32,
										width: '100%',
									}}
									dropdownStyle={{
										lineHeight: 1,
									}}
									country="us"
									masks={{ cn: '... .... ....' }}
									onChange={(phone) => setPhone(filterPhoneNumber(phone))}
								/>
							)}
						</FormItem>
					)}
				</Col>
			</Row>
		</div>
	);
}

export default ReferralLinkAutoComplete;
