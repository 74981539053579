import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import {
	deleteSubCompany,
	querySubCompanyByCompanyIdIndex,
} from '../../graphql/custom/sub-company';

export const withDeleteSubCompany = (Component) => {
	return graphql(gql(deleteSubCompany), {
		props: (props) => ({
			onDeleteSubCompany(input) {
				const optimisticResponseData = {
					...props.SubCompany,
					...input.input,
				};
				return props.mutate({
					variables: input,
					optimisticResponse: {
						__typeName: 'Mutation',
						deleteSubCompany: {
							__typename: 'deleteSubCompany',
							...optimisticResponseData,
						},
					},
					update(proxy, { data: { deleteSubCompany } }) {
						const data = proxy.readQuery({
							query: gql(querySubCompanyByCompanyIdIndex),
							variables: {
								companyId: props.ownProps.currentUser.companyId,
								limit: 1000,
								nextToken: null,
							},
						});

						data.querySubCompanyByCompanyIdIndex.items =
							data.querySubCompanyByCompanyIdIndex.items.filter(
								(SubCompany) => SubCompany.id !== deleteSubCompany.id
							);
						proxy.writeQuery({
							query: gql(querySubCompanyByCompanyIdIndex),
							variables: {
								companyId: props.ownProps.currentUser.companyId,
								limit: 1000,
								nextToken: null,
							},
							data,
						});
					},
				});
			},
		}),
	})(Component);
};
