import gql from 'graphql-tag';

export const GetCompanyDataByHost = gql`
	query GetCompanyDataByHost($host: String!) {
		getCompanyDataByHost(host: $host) {
			id
			name
			defaultBonusAmount
			contactIncentiveBonus
			websiteUrl
			dashboardReferralPolicyText
			bonusEarnedNote
			allowSelfReferrals
			allowInternalMobility
			internalMobilityImage {
				bucket
				region
				key
			}
			dateCreated
			brandColor
			logo {
				bucket
				region
				key
			}
			shareLogo {
				bucket
				region
				key
			}
			disableSmartReferrals
			disableSAMLLogin
			disableClaimYourAccountLogin
			confirmCompliance
			ssoGoogleDomain
			sftpFolderName
			confirmContactEmails
			whiteLabel
			theme
			symbol {
				bucket
				region
				key
			}
			background {
				bucket
				region
				key
			}
			errorImage {
				bucket
				region
				key
			}
			host
			allowSelfReferralsInternalLink
			accountType
			automationSettings
			ownershipSettings
			bonusReportSetting
			eligibiltyChecks
			referralBonusNotificationRecipients
			enableJobNotifications
			jobNotificationFrequency
			jobNotificationSetting
			externalUserSignUp
			hideLoginForm
			appStoreUrls {
				ios
				android
			}
			disableSite
			hideInterestedForGroup
			senderEmailAddress
			whiteLabelServiceName
			hideShareLinkForDepartment
			hideShareLinkNoPublicUrl
			hideDateCreatedJobDetails
			subCompanyLabel
			hideBonusFilterOnBrowseJobs
			labelDepartment
			defaultLocation
			labelEmployeeID
			favicon {
				bucket
				region
				key
			}
			faviconTitle
			labelSocialShare
			bonusReportFrequency
			employeeReportFrequency
			referralsReportFrequency
			referralsReportDateRange
		}
	}
`;
