import { LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import { Input } from 'antd';

function SearchComponent(props) {
	const { loading, setQueryToState, className, searchQuery, ...rest } = props;

	return (
		<div className="search-input">
			<Input
				{...rest}
				value={searchQuery}
				onChange={({ target }) => {
					setQueryToState(target.value);
				}}
			/>
			<span className="search-icon">
				{loading ? <LoadingOutlined /> : <SearchOutlined />}
			</span>
		</div>
	);
}

export default SearchComponent;
