import { Button } from 'antd';

function DisableUserButton({ onUpdate, employee, disableUser, enableUser }) {
	const handleToggleDisabled = (props) => {
		onUpdate({
			input: {
				active: !employee.active,
				id: employee.id,
			},
		});
	};

	return (
		<Button type="link" onClick={handleToggleDisabled}>
			{employee.active ? disableUser : enableUser}
		</Button>
	);
}

export default DisableUserButton;
