import gql from 'graphql-tag';
import get from 'lodash/get';
import { compose, graphql } from 'react-apollo';
import { parse } from 'src/_shared/services/utils.js';
import uuid from 'uuid/v4';
import {
	createUserGroup,
	listUserGroups,
	updateUserGroup,
} from '../../graphql/custom/userGroups';

export const withListUserGroup = (Component) => {
	return compose(
		graphql(gql(listUserGroups), {
			options: (props) => ({
				context: {
					headers: {
						'x-frame-options': 'deny', // This header will reach the server
					},
				},
				variables: {
					filter: {
						companyId: { eq: props.currentUser.companyId },
						active: { eq: true },
					},
					limit: 1000,
					nextToken: null,
				},
				fetchPolicy: 'cache-and-network',
			}),
			props(props) {
				const userGroupsNextToken = get(
					props,
					'data.listUserGroups.nextToken',
					null
				);
				const onFetchMoreUserGroup = makeOnFetchMoreUserGroups(
					props.data.fetchMore,
					userGroupsNextToken
				);
				if (
					!props.data.loading &&
					(!props.data.listUserGroups || props.data.error)
				) {
					setTimeout(props.data.refetch, 2000);
					return null;
				}

				let userGroups = get(props, 'data.listUserGroups.items', []);
				if (userGroups.length > 0) {
					userGroups = userGroups.map((group) => {
						if (get(group, 'keywords')) group.keywords = parse(group.keywords);
						return group;
					});
				}

				if (userGroupsNextToken) onFetchMoreUserGroup();
				return {
					userGroups,
				};
			},
		}),
		graphql(gql(createUserGroup), {
			props: (props) => ({
				onCreateUserGroup(input) {
					const optimisticResponseData = {
						...input.input,
						id: uuid(),
						__typename: 'UserGroup',
					};
					props.mutate({
						variables: input,
						optimisticResponse: {
							__typeName: 'Mutation',
							createUserGroup: {
								__typename: 'createUserGroup',
								...optimisticResponseData,
							},
						},
						update(proxy, { data: { createUserGroup } }) {
							const data = proxy.readQuery({
								query: gql(listUserGroups),
								variables: {
									filter: {
										companyId: { eq: props.ownProps.company.id },
										active: { eq: true },
									},
									limit: 1000,
									nextToken: null,
								},
							});
							if (
								!data.listUserGroups.items.find(
									(userGroup) => userGroup.id === createUserGroup.id
								)
							) {
								data.listUserGroups.items.push(createUserGroup);
							}

							proxy.writeQuery({
								query: gql(listUserGroups),
								data,
								variables: {
									filter: {
										companyId: { eq: props.ownProps.company.id },
										active: { eq: true },
									},
									limit: 1000,
									nextToken: null,
								},
							});
						},
					});
				},
			}),
		}),
		graphql(gql(updateUserGroup), {
			props: (props) => ({
				onUpdateUserGroup(input) {
					const optimisticResponseData = {
						...input.input,
					};
					props.mutate({
						variables: input,
						optimisticResponse: {
							__typeName: 'Mutation',
							updateUserGroup: {
								...optimisticResponseData,
								__typename: 'updateUserGroup',
							},
						},
						update(proxy, { data: { updateUserGroup } }) {
							const data = proxy.readQuery({
								query: gql(listUserGroups),
								variables: {
									filter: {
										companyId: { eq: props.ownProps.currentUser.companyId },
										active: { eq: true },
									},
									limit: 1000,
									nextToken: null,
								},
							});

							data.listUserGroups.items = data.listUserGroups.items.filter(
								(userGroup) => userGroup.id !== updateUserGroup.id
							);
							proxy.writeQuery({
								query: gql(listUserGroups),
								data,
								variables: {
									filter: {
										companyId: { eq: props.ownProps.currentUser.companyId },
										active: { eq: true },
									},
									limit: 1000,
									nextToken: null,
								},
							});
						},
					});
				},
			}),
		})
	)(Component);
};

const makeOnFetchMoreUserGroups = (fetchMore, nextToken) => {
	if (!nextToken) {
		return null;
	}

	return () => {
		fetchMore({
			variables: { nextToken },
			updateQuery(previous, { fetchMoreResult }) {
				if (!fetchMoreResult) {
					return previous;
				}

				const mergedItems = [
					...previous.listUserGroups.items,
					...fetchMoreResult.listUserGroups.items,
				];
				const set = new Set();
				const uniqueItems = mergedItems.filter((item) => {
					if (!set.has(item.id)) {
						set.add(item.id);
						return true;
					}

					return false;
				});
				return {
					...previous,
					listUserGroups: {
						...previous.listUserGroups,
						...fetchMoreResult.listUserGroups,
						items: uniqueItems,
					},
				};
			},
		});
	};
};
