import gql from 'graphql-tag';
import _ from 'lodash';
import { compose, graphql } from 'react-apollo';
import {
	createDepartment,
	queryDepartmentsByCompanyIdIndex,
	updateDepartment,
} from '../../graphql/custom/departments';

export const withListDepartmentSaml = (Component) => {
	return compose(
		graphql(gql(queryDepartmentsByCompanyIdIndex), {
			options(props) {
				return {
					variables: {
						companyId: props.match.params.id,
						first: 1000,
					},
					fetchPolicy: 'network-only',
				};
			},
			props(props) {
				if (props.data.loading)
					return {
						departments: [],
					};
				let departments = props.data.queryDepartmentsByCompanyIdIndex.items;
				departments &&= departments.filter(
					(department) => department.active === true
				);
				departments = _.sortBy(departments, ['name']);
				return {
					departments,
				};
			},
		}),
		graphql(gql(createDepartment), {
			props: (props) => ({
				onCreateDepartment(input) {
					return props.mutate({
						variables: input,
					});
				},
			}),
		}),
		graphql(gql(updateDepartment), {
			props: (props) => ({
				onUpdateDepartment(input) {
					const optimisticResponseData = {
						...input.input,
					};
					props.mutate({
						variables: input,
						optimisticResponse: {
							__typeName: 'Mutation',
							updateDepartment: {
								...optimisticResponseData,
								__typename: 'updateDepartment',
							},
						},
						update(proxy, { data: { updateDepartment } }) {
							const data = proxy.readQuery({
								query: gql(queryDepartmentsByCompanyIdIndex),
								variables: {
									companyId: props.ownProps.currentUser.companyId,
									first: 1000,
								},
							});

							data.queryDepartmentsByCompanyIdIndex.items =
								data.queryDepartmentsByCompanyIdIndex.items.filter(
									(department) => department.id !== updateDepartment.id
								);
							proxy.writeQuery({
								query: gql(queryDepartmentsByCompanyIdIndex),
								data,
								variables: {
									companyId: props.ownProps.currentUser.companyId,
									first: 1000,
								},
							});
						},
					});
				},
			}),
		})
	)(Component);
};
