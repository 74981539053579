export const queryReferralQuestionsByCompanyId = `query QueryReferralQuestionsByCompanyId(
    $companyId: ID!
    $first: Int
    $after: String
  ) {
    queryReferralQuestionsByCompanyId(
      companyId: $companyId
      first: $first
      after: $after
    ) {
        items {
            id
            companyId
            questions
            active
            isCandidate
            sortOrder
            isInterested
            isGeneral
            subCompanyId
          }
          nextToken
    }
  }
  `;
