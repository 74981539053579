export const queryUsersByCompanyIdRoleIndex = `query QueryUsersByCompanyIdRoleIndex(
    $companyId: ID!
    $role: String
    $first: Int
    $after: String
  ) {
    queryUsersByCompanyIdRoleIndex(
      companyId: $companyId
      role: $role
      first: $first
      after: $after
    ) {
      items {
        id
        cognitoId
        companyId
        emailAddress
        firstName
        lastName
        title
        departmentId
        department {
          id
          name
        }
        lastLogin
        lastNotificationCheck
        incentiveEligible
        totalReferrals
        active
        role
        userGroupId
        dateCreated
        location
      }
      nextToken
    }
  }
  `;
