import gql from 'graphql-tag';
import get from 'lodash/get';
import { compose, graphql } from 'react-apollo';
import {
	createAnnouncement,
	queryAnnouncementsByCompanyIdIndex,
} from '../../graphql/custom/announcements';

export const withCreateAnnouncement = (Component) => {
	return compose(
		graphql(gql(createAnnouncement), {
			props: (props) => ({
				onCreateAnnouncement(input) {
					props.mutate({
						variables: { ...input },
						update(proxy, query) {
							try {
								const data = proxy.readQuery({
									query: gql(queryAnnouncementsByCompanyIdIndex),
									context: {
										headers: {
											'x-frame-options': 'deny', // This header will reach the server
										},
									},
									variables: {
										companyId: get(
											props,
											['ownProps', 'currentUser', 'companyId'],
											'skip'
										),
									},
								});
								const announcements = get(
									data,
									'queryAnnouncementsByCompanyIdIndex.items',
									[]
								);
								const updatedAnnouncement = get(
									query,
									'data.createAnnouncement'
								);
								if (
									!announcements.find(
										(announcement) => announcement.id === updatedAnnouncement.id
									)
								)
									announcements.unshift(updatedAnnouncement);
								proxy.writeQuery({
									query: gql(queryAnnouncementsByCompanyIdIndex),
									context: {
										headers: {
											'x-frame-options': 'deny', // This header will reach the server
										},
									},
									variables: {
										companyId: get(
											props,
											['ownProps', 'currentUser', 'companyId'],
											[]
										),
									},
									announcements,
									data,
								});
							} catch (error) {
								console.log(error);
							}
						},
					});
				},
			}),
		})
	)(Component);
};
