export const getTotalReferrals = `query GetTotalReferrals($companyId: ID!) {
    getTotalReferrals(companyId: $companyId) {
        finalResult
    }
  }
  `;

export const getTotalUsers = `query GetTotalUsers($companyId: ID!) {
    getTotalUsers(companyId: $companyId) {
        finalResult
    }
  }
  `;

export const getTotalUnclaimedAccounts = `query GetTotalUnclaimedAccounts($companyId: ID!) {
    getTotalUnclaimedAccounts(companyId: $companyId) {
        finalResult
    }
  }
  `;
