import { object, string, date, number } from 'yup';

export const nominationSchema = object().shape({
    badgeId: string().uuid().required('Required*'),
    startDate: date().required('Start Date Required*'),
    endDate: date()
        .required('End Date Required*')
        .test('is-greater', 'End date must be greater than start date', function (value) {
            const { startDate } = this.parent;
            return value > startDate;
        }),
    nominationCriteria: object().shape({
        nominee: object().shape({
            employeelogInTypeId: string().uuid(),
            employeePayTypeId: string().uuid(),
        }),
        nominator: object().shape({
            employeelogInTypeId: string().uuid(),
            employeePayTypeId: string().uuid(),
            awardedCount: number().integer().min(1, 'Minimum awarded count is 1').max(99, 'Maximum awarded count is 99').required('Required*'),
        }),
    }),
});


