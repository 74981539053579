import gql from 'graphql-tag';
import get from 'lodash/get';
import { graphql } from 'react-apollo';
import { queryContactsByAtsIdIndex } from '../../graphql/custom/contacts';

export const withListContactsByAtsId = (Component, variables) => {
	return graphql(gql(queryContactsByAtsIdIndex), {
		options(props) {
			return {
				context: {
					headers: {
						'x-frame-options': 'deny', // This header will reach the server
					},
				},
				variables: {
					atsId: get(props, 'currentUser.accountClaim.atsId'),
				},
				fetchPolicy: 'cache-and-network',
			};
		},
		props(props) {
			return {
				matchingAtsIdContacts: get(
					props,
					'data.queryContactsByAtsIdIndex.items',
					[]
				),
			};
		},
	})(Component);
};
