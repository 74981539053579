import { css } from 'emotion';
import get from 'lodash/get';
import { COLORS } from '../_shared/styles/colors';

const breakpoints = [640, 767, 991, 1200];

const mq = breakpoints.map((bp) => `@media (max-width: ${bp}px)`);
export const customPageContainer = css({
	width: '100%',
	height: '100%',
	padding: 20,
	backgroundColor: COLORS.lightGray2,
	[mq[2]]: {
		padding: '10px 5px',
	},

	'& .ImageSpan__root___RoAqL': {
		maxWidth: '800px !important',
		width: '100% !important',
	},
});

export const customPageWrapper = css({
	'& .page-banner': {
		width: '100%',

		'& .image': {
			borderRadius: '20px 20px 0 0',
			overflow: 'hidden',

			'& img': {
				width: '100%',
			},
		},

		'& .banner-text': {
			padding: '40px 100px',
			textAlign: 'center',
			backgroundColor: '#033552',

			[mq[1]]: {
				padding: '20px',
			},

			'& h2': {
				fontSize: '30px',
				lineHeight: '32px',
				fontWeight: '600',
				color: COLORS.white,
				margin: '0 0 16px',

				[mq[0]]: {
					fontSize: '22px',
					lineHeight: '20px',
				},
			},

			'& p': {
				fontSize: '18px',
				lineHeight: '22px',
				color: COLORS.white,
				margin: '0',

				[mq[0]]: {
					fontSize: '16px',
					lineHeight: '20px',
				},
			},
		},
	},
	'& .content-wrap': {
		display: 'flex',

		[mq[1]]: {
			flexDirection: 'column',
		},

		'& .content-area': {
			flex: '0 0 70%',
			backgroundColor: COLORS.white,
			borderRadius: '0 0 0 16px',

			[mq[1]]: {
				flex: '0 0 100%',
			},

			'& a': {
				fontFamily: '"Open Sans", sans-serif',
				fontSize: '16px',
				lineHeight: '20px',
				color: COLORS.blue,
				fontWeight: 600,
				display: 'inline-block',
			},

			'& .jobs-for-you': {
				padding: '30px',
				borderBottom: `1px solid ${COLORS.gray}`,

				[mq[1]]: {
					padding: '20px',
				},

				'& h3': {
					fontSize: '22px',
					lineHeight: '28px',
					fontWeight: '700',
					color: COLORS.black,
					margin: '0 0 12px',
				},

				'& p': {
					fontSize: '18px',
					lineHeight: '22px',
					color: COLORS.black,
					margin: '0 0 16px',
				},

				'& .btn-wrap': {
					'& button': {
						backgroundColor: COLORS.blue,
						color: COLORS.white,
						fontSize: '16px',
						lineHeight: '20px',
						border: 'none',
						outline: 'none',
						padding: '8px 30px',
						borderRadius: 10,

						[mq[1]]: {
							padding: '8px 10px',
						},
					},
					'& button:not(:last-child)': {
						marginRight: 10,

						[mq[0]]: {
							marginBottom: 10,
						},
					},
				},
			},
			'& .admin-posts': {
				'& .post-list': {
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-between',
					padding: '30px 0',

					[mq[0]]: {
						flexDirection: 'column',
					},

					'&:not(:last-child)': {
						borderBottom: `1px solid ${COLORS.gray}`,
					},

					'&:nth-child(even)': {
						flexDirection: 'row-reverse',

						[mq[0]]: {
							flexDirection: 'column',
						},
					},

					'& .post-media': {
						flex: '1 1 calc(40% - 32px)',
						margin: '0 16px',

						[mq[0]]: {
							flex: '1 1 100%',
							marginBottom: 14,
						},

						'& img': {
							width: '100%',
							height: 'auto',
						},

						'& iframe': {
							width: '100%',
							height: '100%',
							position: 'absolute',
							top: '0',
							left: '0',
						},
					},
					'& .content': {
						flex: '1 1 calc(60% - 32px)',
						padding: '0 16px',

						[mq[0]]: {
							flex: '1 1 100%',
						},

						'& .content-heading': {
							margin: '0 0 16px',

							'& h4': {
								fontSize: '18px',
								lineHeight: '22px',
								fontWeight: '700',
								color: COLORS.black,
								margin: '0px',
							},
							'& span': {
								fontSize: '18px',
								lineHeight: '20px',
								color: COLORS.black,
								fontStyle: 'italic',
							},
						},

						'& p': {
							fontSize: '16px',
							lineHeight: '20px',
							color: COLORS.black,
							margin: '0',

							'& + p': {
								marginTop: '16px',
							},
						},
						'& .link-btn': {
							marginTop: '16px',
						},
					},
				},
			},
		},
		'& .sidebar': {
			flex: '0 0 30%',
			backgroundColor: '#d2e3ec',
			padding: '30px 20px',
			borderRadius: '0 0 16px 0',

			[mq[1]]: {
				flex: '0 0 100%',
			},

			'& .sidebar-media': {
				textAlign: 'center',
				margin: ' 0 0 16px',

				'& img': {
					width: '100%',
					height: 'auto',
				},
			},

			'& h3': {
				fontSize: '22px',
				lineHeight: '28px',
				fontWeight: '700',
				color: COLORS.black,
				margin: '0 0 12px',
			},

			'& p': {
				fontSize: '16px',
				lineHeight: '20px',
				color: COLORS.black,
				margin: '0 0 16px',
			},

			'& ul': {
				padding: '0 0 0 20px',

				'& li': {
					fontSize: '16px',
					lineHeight: '20px',
					color: COLORS.black,
					margin: '0 0 6px',
				},
			},
		},
	},
});

export const btnDefault = css({
	backgroundColor: COLORS.blue,
	color: COLORS.white,
	fontSize: '16px',
	lineHeight: '20px',
	border: 'none',
	outline: 'none',
	padding: '8px 30px',
	borderRadius: 10,
});
export const btnWrap = css({
	[mq[1]]: {
		display: 'flex',
		position: 'absolute',
		top: 30,
		right: 30,
	},
});
export const LinkStyles = css({
	fontFamily: '"Open Sans", sans-serif',
	color: COLORS.blue,
	height: 30,
	border: 'none',
	boxShadow: 'none',
	fontWeight: 600,
	alignItems: 'center',
});
export const cancelBtnWrap = css({
	[mq[1]]: {
		marginLeft: 10,
	},
});
export const absoluteBtn = css({
	[mq[1]]: {
		position: 'absolute',
		top: 30,
		right: 20,
	},
});
export const InputStyles = css({
	fontFamily: '"Open Sans", sans-serif',
	fontSize: 14,
});
export const filterGroupSpace = css({
	marginBottom: 15,
	fontFamily: '"Open Sans", sans-serif',
	paddingTop: 20,
});

export const radioButton = (theme) => {
	let buttonColor = COLORS.red;
	if (get(theme, 'buttonColor')) {
		buttonColor = get(theme, 'buttonColor', buttonColor);
	}

	return css({
		fontSize: '1.25em',
		color: COLORS.black,
		'& .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)':
			{
				backgroundColor: COLORS.red,
			},
		'& .ant-radio-button & .ant-radio-button-checked': {
			backgroundColor: COLORS.red,
		},
		'&:selected': {
			color: buttonColor,
			':hover': {
				color: COLORS.white,
			},
		},
	});
};
