import { AutoComplete, Button, Col, Form, Input, Row, Tooltip } from 'antd';
import get from 'lodash/get';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { ml } from '../../../services/utils';
import * as ModalStyles from '../referral-modal/referralModalStyles.js';

function ContactAutoComplete(props) {
	const {
		contact,
		onDeckContacts,
		newContact,
		handleNewContact,
		handleContactChange,
		ContactOptions,
		customValidator,
		matchFound,
		visible,
		currentUser,
		allMultiLingualData,
	} = props;

	const [locatedContact, setLocatedContact] = useState([]);

	const handleNewContactCustomValidator = (rule, value = '', callback) => {
		const { contacts = [] } = props;
		const errors = [];

		const customValidatorError = customValidator(value);
		if (customValidatorError) {
			errors.push(customValidatorError);
			if (errors.length > 0) {
				callback(
					<Tooltip
						open={visible}
						placement="topLeft"
						overlayStyle={{ width: 180 }}
						title={get(errors[0], 'message', null)}
					>
						<div
							style={{
								position: 'relative',
								top: -50,
								display: 'inline-block',
							}}
						/>
					</Tooltip>
				);
			}

			callback();
			return;
		}

		let foundContact = contacts.find(
			(contact) => get(contact, 'emailAddress') === value.toLowerCase()
		);

		const allowDupContacts = get(
			props.currentUser.company,
			'referralLeadsAllowDuplicateContacts',
			false
		);

		if (!foundContact && !allowDupContacts)
			foundContact = onDeckContacts.find(
				(contact) => get(contact, 'emailAddress') === value.toLowerCase()
			);

		if (foundContact) {
			const error = new Error(
				'A contact with this email address already exists.'
			);
			errors.push(error);
		}

		if (errors.length > 0) {
			callback(
				<Tooltip
					open={visible}
					placement="bottomLeft"
					overlayStyle={{ width: 170 }}
					title={get(errors[0], 'message', null)}
				>
					<div
						style={{
							position: 'relative',
							fontWeight: 1000,
						}}
					/>
				</Tooltip>
			);
		}

		callback();
	};

	const handleAutoCompleteCustomValidator = (rule, value, callback) => {
		const errors = [];
		const customValidatorError = customValidator(value);
		if (customValidatorError) {
			errors.push(customValidatorError);
		}

		const contactExists = ContactOptions.find((item) => {
			return item.key === value;
		});
		if (!contactExists && value && value.length > 0) {
			const error = new Error('something_wrong');
			errors.push(error);
		}

		const isMatch = ContactOptions.find((option) => option.key === value);
		if (isMatch && !isMatch.props.email) {
			const error = new Error(
				'Contact does not have an email address. Add an email to this person in Contacts.'
			);
			errors.push(error);
		}

		if (errors.length > 0 && errors[0].message === 'something_wrong') {
			callback(
				<Tooltip
					open={visible}
					placement="topLeft"
					title={<div style={{ padding: 5 }}>Contact does not exist.</div>}
				>
					<div
						style={{
							position: 'relative',
							top: -50,
							display: 'inline-block',
						}}
					/>
				</Tooltip>
			);
		} else if (errors.length > 0) {
			const url = locatedContact
				? `/mycontacts/${get(isMatch, 'key', '')}`
				: '/mycontacts';
			callback(
				<Tooltip
					open={visible}
					placement="topLeft"
					title={get(errors[0], 'message', null)}
				>
					<div
						style={{
							position: 'relative',
							top: -50,
							display: 'inline-block',
						}}
					/>
				</Tooltip>
			);
		}

		callback();
	};

	if (newContact && !matchFound) {
		return (
			<>
				<h4 className="ant-modal-sub-title text-center">
					{ml('Enter Referral Information', currentUser, allMultiLingualData)}
				</h4>
				<Row type="flex" justify="space-between" gutter={8}>
					<Col md={8} xs={12}>
						<Form.Item
							name="firstName"
							rules={[
								{
									required: true,
									message: (
										<Tooltip
											open={visible}
											placement="bottomLeft"
											style={{
												position: 'absolute',
												fontWeight: 1000,
												margin: 0,
												padding: 0,
											}}
											title="Enter a first name."
										/>
									),
								},
							]}
						>
							<div className="custom-form-group">
								<Input
									className="custom-input"
									placeholder={ml(
										'First Name',
										currentUser,
										allMultiLingualData
									)}
								/>
							</div>
						</Form.Item>
					</Col>
					<Col md={8} xs={12}>
						<Form.Item
							name="lastName"
							rules={[
								{
									required: true,
									message: (
										<Tooltip
											open={visible}
											placement="bottomLeft"
											style={{}}
											title="Enter a last name."
										/>
									),
								},
							]}
						>
							<div className="custom-form-group">
								<Input
									className="custom-input"
									placeholder={ml(
										'Last Name',
										currentUser,
										allMultiLingualData
									)}
								/>
							</div>
						</Form.Item>
					</Col>
					<Col md={8} xs={24}>
						<Form.Item
							name="emailAddress"
							rules={[
								{
									required: true,
									message: (
										<Tooltip
											open={visible}
											placement="bottomLeft"
											style={{ fontWeight: 1000 }}
											title="Enter a valid email address."
										/>
									),
								},
								{
									pattern:
										/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[(?:\d{1,3}\.){3}\d{1,3}])|(([a-zA-Z\-\d]+\.)+[a-zA-Z]{2,}))$/g,
									message: `Invalid Email`,
								},
								{ validator: handleNewContactCustomValidator },
							]}
						>
							<div className="custom-form-group">
								<Input
									className="custom-input"
									placeholder={ml(
										'Email Address',
										currentUser,
										allMultiLingualData
									)}
								/>
							</div>
						</Form.Item>
					</Col>
				</Row>
				<p className="text-center">
					{ml('or', currentUser, allMultiLingualData)}{' '}
					<Button type="link" onClick={handleNewContact}>
						{ml('click here', currentUser, allMultiLingualData)}
					</Button>{' '}
					{ml('to add an existing contact', currentUser, allMultiLingualData)}
				</p>
			</>
		);
	}

	return (
		<>
			<h4 className="ant-modal-sub-title text-center">Refer a Contact</h4>
			<p className="text-center">
				add your contacts <Link to="/mycontacts/add/form">here</Link>
			</p>
			<Row type="flex" justify="center">
				<Col span={24}>
					<Form.Item
						name="userId"
						rules={[
							{
								required: true,
								message: visible ? (
									<Tooltip
										open={visible}
										placement="topLeft"
										style={{ fontWeight: 1000 }}
										title="Please enter a contact name."
									>
										<div
											style={{
												position: 'relative',
												top: -50,
												display: 'inline-block',
											}}
										/>
									</Tooltip>
								) : null,
							},
							{
								validator: handleAutoCompleteCustomValidator,
							},
						]}
					>
						<AutoComplete
							dataSource={ContactOptions}
							className="custom-input"
							placeholder="Start typing a contact&#39;s name..."
							onSearch={handleContactChange}
						/>
					</Form.Item>
				</Col>
				<p className={ModalStyles.SmallText}>
					or{' '}
					<Button type="link" onClick={handleNewContact}>
						click here
					</Button>{' '}
					to enter name and email
				</p>
			</Row>
		</>
	);
}

export default ContactAutoComplete;
