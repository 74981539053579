import { css } from 'emotion';
import get from 'lodash/get';
import { COLORS } from '../../../_shared/styles/colors';

export const SubmitBtn = (theme) => {
	let buttonColor = COLORS.blue;
	if (get(theme, 'enabled')) {
		buttonColor = get(theme, 'buttonColor', buttonColor);
	}

	return css({
		fontFamily: 'Open Sans',
		backgroundColor: `${buttonColor} !important`,
		color: 'white',
		fontSize: 20,
		fontWeight: 300,
		padding: '10px 30px',
		height: 'auto',
		border: 'none',
		marginBottom: 15,
		marginTop: 15,
		width: 200,
	});
};

export const CancelBtn = (theme) => {
	const buttonColor = COLORS.red;
	return css({
		fontFamily: 'Open Sans',
		backgroundColor: `${buttonColor} !important`,
		color: 'white',
		fontSize: 20,
		fontWeight: 300,
		padding: '10px 30px',
		height: 'auto',
		border: 'none',
		marginBottom: 0,
		width: 200,
	});
};

export const SubmitBtnContainer = css({
	width: '100%',
	marginTop: 15,
	marginBottom: 15,
	display: 'flex',
	justifyContent: 'center',
	'& .ant-form-item-control-wrapper': {
		'@media (max-width: 575px)': {
			width: 'auto',
		},
	},
	'& .ant-btn:hover': {
		backgroundColor: COLORS.blue,
		border: 'none',
		color: COLORS.white,
	},
	'& .ant-btn:focus': {
		backgroundColor: COLORS.blue,
		border: 'none',
		color: COLORS.white,
	},
});

export const CompanyLoginBtnContainer = css({
	width: '100%',
	display: 'flex',
	justifyContent: 'center',
});

export const OrStyle = css({
	fontFamily: '"Open Sans", sans-serif',
	color: '#595959 !important',
	width: '100%',
	display: 'flex',
	justifyContent: 'center',
	marginBottom: 0,
	fontSize: 16,
	fontWeight: 400,
	paddingRight: 3,
	paddingLeft: 3,
	paddingBottom: 0,
	flexWrap: 'wrap',
});

export const CompanyLoginBtn = css({
	fontFamily: 'Open Sans',
	fontSize: 16,
	fontWeight: 400,
	boxShadow: 'none',
	height: 'auto',
	color: COLORS.blue,
	border: 'none',
	backgroundColor: 'none',
	paddingLeft: 5,
	paddingRight: 5,
	margin: 'auto',
	textAlign: 'center',
});

export const BtnContainer = css({
	width: '100%',
	display: 'flex',
	justifyContent: 'center',
	marginBottom: 0,
	'& .ant-form-item-control-wrapper': {
		'@media (max-width: 575px)': {
			width: 'auto',
		},
	},
});

export const CheckIcon = css({
	marginLeft: 5,
	marginTop: -1,
});

export const LabelStyles = css({
	fontFamily: '"Open Sans", sans-serif',
	fontSize: '16px !important',
	fontWeight: 600,
});

export const InputStyles = css({
	fontFamily: '"Open Sans", sans-serif',
	fontSize: 16,
	'& .ant-input': {
		height: 40,
		fontFamily: '"Open Sans", sans-serif',
		fontSize: 16,
	},
	'& .ant-form-explain': {
		fontSize: 12,
	},
	'& .ant-form-item-control': {
		lineHeight: 1.5,
	},
});

export const FormStyles = css({
	'& .ant-form-item': {
		marginBottom: 10,
	},
});

export const ForgotPasswordBtn = css({
	color: COLORS.blue,
	border: 'none',
	boxShadow: 'none',
	backgroundColor: 'none',
	padding: 0,
	margin: 'auto',
	textAlign: 'center',
});

export const titleText = css({
	fontSize: 22,
	fontWeight: 600,
	fontFamily: '"Open Sans", sans-serif',
	color: COLORS.darkBlue,
});

export const PopoverStyle = css({
	'& .ant-popover-inner-content': {
		borderColor: 'rgba(0, 0, 0, 0.75)',
		borderRadius: 4,
		color: 'white',
		backgroundColor: 'rgba(0, 0, 0, 0.75)',
		padding: 5,
	},
	'& .ant-popover-arrow': {
		backgroundColor: 'rgba(0, 0, 0, 0.75)',
		borderColor: 'rgba(0, 0, 0, 0.75)',
		border: '0px solid',
		zIndex: -1,
	},
});

export const ErrorMsgStyles = css({
	fontFamily: '"Open Sans", sans-serif',
	fontSize: '14px !important',
	fontWeight: 400,
	color: COLORS.red,
});
