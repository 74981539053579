export const listUserGroups = `query ListUserGroups(
    $filter: TableUserGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        companyId
        measurement
        name
        keywords
        active
        currency
      }
      nextToken
    }
  }
  `;
