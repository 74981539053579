import { combineReducers } from 'redux';
import { referralReducer } from './_shared/referral';
import { userReducer } from './_shared/user';
import { browseJobsReducer } from './browseJobs';
import { dashboardReducer } from './dashboard';
import { editUserProfileReducer } from './editUserProfile';
import { employeeDetailsReducer } from './employee-details';
import { manageJobsReducer } from './manageJobs';
import { myReferralsReducer } from './my-referrals';
import { navigationReducer } from './navigation';
import { notificationsReducer } from './notifications';
import { settingsReducer } from './settings';

export default combineReducers({
	user: userReducer,
	dashboard: dashboardReducer,
	employeeDetails: employeeDetailsReducer,
	manageJobs: manageJobsReducer,
	browseJobs: browseJobsReducer,
	editUserProfile: editUserProfileReducer,
	myReferrals: myReferralsReducer,
	notifications: notificationsReducer,
	referral: referralReducer,
	settings: settingsReducer,
	navigation: navigationReducer,
});
