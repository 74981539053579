export const queryReferralEligibilityResults = `
query GetReferral($id: ID!) {
  getReferral(id: $id) {
    user {
      active
      title
    }
    eligibilityResults
  }
}
`;
