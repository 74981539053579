import get from 'lodash/get';
import { Component } from 'react';
import { withApollo } from 'react-apollo';
import { downloadFromS3Signed, graphql } from 'src/_shared/services/utils.js';
import LoginBackground from '../_shared/assets/auth-bg-full.jpg';
import ErinLogo from '../_shared/assets/erinwhite.png';
import { Spinner } from '../_shared/index.js';
import NewUserForm from './new-user-items/NewUserFormComponent.jsx';

class NewExternalUserLandingPageComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			accountClaim: get(props, 'location.state.accountClaim'),
			backgroundImage: null,
			backgroundImageURL: '',
			departments: null,
			company: get(
				props,
				'currentUser.company',
				get(props, 'location.state.accountClaim.company')
			),
			loaded: false,
			externalCompanyId: '',
			externalCompanyName: '',
		};
	}

	async componentDidMount() {
		// If invite is already stored in cache
		const externalUserCompanyId = get(
			this.props,
			'location.state.company.id',
			'skip'
		);
		if (externalUserCompanyId) {
			this.setState({
				externalCompanyId: externalUserCompanyId,
				externalCompanyName: get(
					this.props,
					'location.state.company.name',
					'skip'
				),
			});
			let departments = await graphql({
				input: { companyId: externalUserCompanyId, first: 500 },
				query: 'queryDepartmentsByCompanyIdIndex',
			});
			departments = departments?.items || departments;
			const otherDepartmentId = this.getDepartmentId(departments, 'Other');
			this.setState({ otherDepartmentId });

			let userGroups = await graphql({
				input: { companyId: externalUserCompanyId, first: 500 },
				query: 'queryUserGroupsByCompanyIdIndex',
			});
			userGroups = userGroups?.items || userGroups;
			const defaultExternalUserGroupId = this.getUserGroupId(
				userGroups,
				'External'
			);
			this.setState({
				userGroups,
				defaultExternalUserGroupId,
				externalUserCompanyId,
			});
		}

		// Set background image for whitelabel comapnies
		const { company } = get(this.props, 'location.state', {});
		const key = get(company, 'background.key', false);
		if (key) {
			const url = await downloadFromS3Signed(key, company?.background?.bucket);
			this.setState({ backgroundImageURL: url }, () =>
				this.setBackgroundImage(company)
			);
		} else {
			this.setBackgroundImage(company);
		}

		let logoSource;
		const logo = get(company, 'logo.key', false);

		if (logo) {
			logoSource = await downloadFromS3Signed(logo, 'erin-images');
		}

		this.setState({ logoSrc: logoSource });
	}

	getDepartmentId = (departments, name) => {
		const department = departments.find((dept) => dept.name === name);
		return get(department, 'id');
	};

	getUserGroupId = (userGroups, name) => {
		const userGroup = userGroups.find((group) => group.name === name);
		return get(userGroup, 'id');
	};

	setBackgroundImage = (company) => {
		const { backgroundImageURL } = this.state;
		const whiteLabel = get(company, 'whiteLabel');
		const { host } = window.location;
		let backgroundImage = null;
		if (host !== 'app.erinapp.com' && whiteLabel && backgroundImageURL) {
			backgroundImage = `url(${backgroundImageURL})`;
			this.setState({ backgroundImage });
		} else if (company) {
			backgroundImage = `url(${LoginBackground})`;
			this.setState({ backgroundImage });
		}
	};

	render() {
		const { userInvite } = this.props;
		const { company } = get(this.props, 'location.state', {});
		const {
			accountClaim,
			defaultExternalUserGroupId,
			departments,
			externalUserCompanyId,
			otherDepartmentId,
			externalCompanyName,
			externalCompanyId,
			userGroups,
			backgroundImage,
			logoSrc,
		} = this.state;

		const whiteLabel = get(company, 'whiteLabel');

		if (!company || !backgroundImage) return <Spinner forceDefault />;

		return (
			<div
				className="auth"
				style={backgroundImage && { backgroundImage, color: 'red' }}
			>
				<div className="auth-left">
					<div className="auth-logo-wrap">
						{whiteLabel ? (
							<div className="auth-logo">
								<img src={logoSrc} />
							</div>
						) : (
							<div className="auth-logo">
								<img src={ErinLogo} alt="Erin Logo" />
							</div>
						)}
					</div>
				</div>

				<div className="auth-right">
					<div className="auth-card">
						<NewUserForm
							whiteLabel={whiteLabel}
							accountClaim={accountClaim}
							defaultExternalUserGroupId={defaultExternalUserGroupId}
							externalUserCompanyId={externalUserCompanyId}
							otherDepartmentId={otherDepartmentId}
							userInvite={userInvite}
							departments={get(
								departments,
								'data.queryDepartmentsByCompanyIdIndex.items'
							)}
							externalCompanyName={externalCompanyName}
							externalCompanyId={externalCompanyId}
							userGroups={userGroups}
							onCreate={this.props.onCreate}
							onUpdateInvite={this.props.onUpdateInvite}
						/>
					</div>
				</div>
			</div>
		);
	}
}

export default withApollo(NewExternalUserLandingPageComponent);
