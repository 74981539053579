import { Select, message, Tooltip } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import gql from 'graphql-tag';
import Cookies from 'js-cookie';
import _, { get } from 'lodash';
import { Component } from 'react';
import { withApollo } from 'react-apollo';
import ReactGA from 'react-ga';
import { configMode } from 'src/_shared/api/';
import { ColorCard, CSVExporter, Spinner, WebIcon } from 'src/_shared/index.js';
import {
	getSetErrorImageURL,
	lambda,
	logout,
	mapReferralStatus,
	ml,
	parse,
	searchOpenSearchNetwork,
} from 'src/_shared/services/utils.js';
import { getUserById } from 'src/_shared/api/graphql/custom/users/';
import fileIcon from '../_shared/assets/erin_lightgray.png';
import InternalApplicantsTable from '../_shared/components/InternalApplicantTableComponent.jsx';
import { SearchComponent } from '../_shared/components/search';
import { ExportSelector } from '../_shared/components/export-selector/ExportSelector.jsx';

const { Option } = Select;

class InternalApplicantsComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			company: get(props, 'currentUser.company'),
			currencyRate: 1,
			currencySymbol: '$',
			currentTableData: null,
			departments: get(props, 'departments', []),
			exportTableData: [],
			filteredBonuses: [],
			filteredCompanyStages: [],
			filteredData: get(props, 'referrals', []),
			filteredDepartments: [],
			filteredRecruiters: [],
			filteredSubCompanies: [],
			filteredStatuses: [],
			filteredUserGroups: [],
			filterOn: false,
			filterReferralStatus: '',
			gdprRequests: [],
			isCardDataSet: false,
			isFilteredByInterviewing: false,
			isNewReferralCreated: false,
			loading: true,
			query: '',
			referralLogs: get(props, 'referralLogs', []),
			searchedReferrals: [],
			searchQuery: '',
			statuses: [
				'referred',
				'accepted',
				'interviewing',
				'hired',
				'notHired',
				'declined',
				'noresponse',
				'transferred',
				'inactive',
				'ineligible',
			],
			statusFilterValue: '',
			subCompanyLabel: get(
				props,
				'currentUser.company.subCompanyLabel',
				'Company'
			)
				? get(props, 'currentUser.company.subCompanyLabel', 'Company')
				: 'Company',
			totalReferralCount: undefined,
			theme: parse(get(props, 'currentUser.company.theme', '{}')),
			userGroups: get(props, 'currentUser.company.userGroups', []),
			visible: false,
			errorImageURL: '',
		};
	}

	async componentDidMount() {
		await this.getUserDataById();
		const jwt = Cookies.get('jwt');
		const { resultData } = this.state;
		if (resultData !== '' && resultData !== undefined) {
			const expirationDoneByToken = get(
				resultData,
				'expirationDoneByToken',
				null
			);
			const expires = get(resultData, 'expires');
			if (jwt !== undefined && jwt !== expirationDoneByToken && expires) {
				logout(this.props.client);
			}
		}

		await this.setQueryToState('', 0);
		await this.getAdminAndManagers();

		const host = window.location.hostname;
		if (host === 'referrals.aus.com') {
			ReactGA.initialize('UA-128630809-2');
			ReactGA.pageview(window.location.pathname + window.location.search);
		} else if (host === 'app.erinapp.com') {
			ReactGA.initialize('UA-128630809-3');
			ReactGA.pageview(window.location.pathname + window.location.search);
		}

		const { currentUser } = this.props;
		const errorImageURL = await getSetErrorImageURL(
			currentUser?.company?.errorImage?.key
		);

		if (errorImageURL) {
			this.setState({ errorImageURL });
		}
	}

	componentDidUpdate(prevProps) {
		const {
			filteredBonuses = [],
			filteredDepartments = [],
			filteredRecruiters = [],
			filteredStatuses = [],
			filteredUserGroups = [],
			query = '',
		} = this.state;
		const { referrals } = this.props;
		if (prevProps.referrals !== referrals) {
			this.setState({ filteredData: referrals });
		}

		const filterOn =
			filteredBonuses.length > 0 ||
			filteredDepartments.length > 0 ||
			filteredStatuses.length > 0 ||
			filteredRecruiters.length > 0 ||
			filteredUserGroups.length > 0 ||
			query.length > 0;
		if (get(this.state, 'filterOn') !== filterOn) this.setState({ filterOn });

		const { gdprRequests } = this.state;
		const { gdprNextToken, onLoadMore } = this.props;
		if (
			gdprNextToken &&
			!gdprRequests.find((request) => request === gdprNextToken)
		) {
			const { gdprRequests } = this.state;
			gdprRequests.push(gdprNextToken);
			onLoadMore();
			this.setState({ gdprRequests });
		}
	}

	async getAdminAndManagers() {
		const company = get(this.props, 'currentUser.company');
		const filteredRoles = ['admin', 'manager'];
		const parameters = {
			query: '',
			size: 300,
			currentUser: get(this.props, 'currentUser.id'),
			filters: {
				companies: [get(company, 'id')],
				roles: filteredRoles,
			},
		};
		const response = await searchOpenSearchNetwork(parameters, 'erin-users');
		if (get(response, 'data')) {
			const sortedRecruiters = _.sortBy(get(response, 'data', []), [
				'firstName',
			]);
			this.setState({
				recruiters: sortedRecruiters,
			});
		}
	}

	getReport = () => {
		const endpoint = 'export-referral-data';
		const { companyId, cognitoId } = this.props.currentUser;
		const reportType = 'internal';
		message.success(`Export processing. You will receive an email when ready`);
		lambda({
			endpoint,
			variables: { companyId, cognitoId, configMode, reportType: 'internal' },
			async: false,
		});
	};

	async getUserDataById(policy = 'network-only') {
		const { client, currentUser } = this.props;
		try {
			const userId = get(currentUser, 'id', null);
			if (userId !== null) {
				const { data } = await client.query({
					query: gql(getUserById),
					variables: {
						id: userId,
					},
					fetchPolicy: policy,
				});
				const result = get(data, 'getUser', null);
				this.setState({
					resultData: result,
				});
			}
		} catch (error) {
			console.log(error);
		}
	}

	setQueryToState = async (searchQuery = '', delay = 300) => {
		const { referralsData } = this.props;
		const {
			company,
			filteredBonuses = [],
			filteredCompanyStages = [],
			filteredDepartments = [],
			filteredRecruiters = [],
			filteredStatuses = [],
			filteredSubCompanies = [],
			filteredUserGroups = [],
			filterOn,
		} = this.state;

		this.setState({ query: searchQuery });
		clearTimeout(this.timer);

		if (!filterOn) {
			this.setState({
				searchedReferrals: get(this.state, 'allReferrals', []),
			});
		}

		const totals = {
			total: get(referralsData, 'totalReferral', 0),
			accepted: get(referralsData, 'statusWise.accepted', 0),
			interviewing: get(referralsData, 'statusWise.interviewing', 0),
			hired: get(referralsData, 'statusWise.hired', 0),
			declined: get(referralsData, 'statusWise.declined', 0),
			noResponse: get(referralsData, 'statusWise.noresponse', 0),
			notHired: get(referralsData, 'statusWise.notHired', 0),
			inactive: get(referralsData, 'statusWise.inactive', 0),
			internalAccepted: get(referralsData, 'statusWiseInternal.accepted', 0),
			internalInterviewing: get(
				referralsData,
				'statusWiseInternal.interviewing',
				0
			),
			internalHired: get(referralsData, 'statusWiseInternal.hired', 0),
			internalNotHired: get(referralsData, 'statusWiseInternal.notHired', 0),
			internalTotal: get(referralsData, 'statusWiseInternal.total', 0),
		};

		this.timer = setTimeout(async () => {
			this.setState({ loading: true });
			const parameters = {
				query: searchQuery,
				size: 300,
				filterOn,
				totals,
				role: get(this.props, 'currentUser.role'),
				currentUser: get(this.props, 'currentUser.id'),
				filters: {
					bonuses: filteredBonuses,
					companies: get(company, 'id'),
					customStatuses: filteredCompanyStages,
					departments: filteredDepartments,
					recruiters: filteredRecruiters,
					statuses: filteredStatuses,
					subCompanies: filteredSubCompanies,
					userGroups: filteredUserGroups,
					referralType: 'self',
				},
			};
			const response = await searchOpenSearchNetwork(
				parameters,
				'erin-referrals'
			);
			let searchedReferrals = get(response, 'data', []);
			searchedReferrals =
				searchQuery.length > 0
					? searchedReferrals
					: this.sortByDateReferred(searchedReferrals);

			if (get(response, 'query') === get(this.state, 'query')) {
				this.setState({
					isCardDataSet: true,
					loading: false,
					referralTotals: get(response, 'totals', []),
					searchedReferrals,
					totalReferralCount: response.metadata?.showing?.totalItems,
				});
			}
		}, delay);
	};

	handleFilterDepartment = (department, key) => {
		let { filteredDepartments = [], searchQuery = '' } = this.state;
		if (filteredDepartments.map((dep) => dep.name).includes(department)) return;
		filteredDepartments = [
			...filteredDepartments,
			{ name: department, id: key },
		];
		this.setState(
			{
				filteredDepartments,
			},
			async () => await this.setQueryToState(searchQuery)
		);
	};

	handleFilterRecruiter = (recruiter) => {
		let { filteredRecruiters = [], searchQuery = '' } = this.state;
		if (filteredRecruiters.map((r) => r).includes(recruiter)) return;
		filteredRecruiters = [...filteredRecruiters, recruiter];
		this.setState(
			{
				filteredRecruiters,
			},
			async () => await this.setQueryToState(searchQuery)
		);
	};

	handleFilterStatus = (status, key) => {
		let {
			isFilteredByInterviewing,
			filteredCompanyStages = [],
			filteredStatuses = [],
			statuses = [],
			searchQuery = '',
		} = this.state;

		const selectedFilter = status;
		const INTERVIEWING = 'interviewing';

		function filterIsActive(selectedFilter) {
			return (
				filteredCompanyStages.includes(selectedFilter) ||
				filteredStatuses.includes(selectedFilter)
			);
		}

		function convertStagesToStringArray(companyStages) {
			const parsedStages = parse(companyStages) ? parse(companyStages) : [];
			return parsedStages.map((stage) => Object.values(stage)[0]);
		}

		if (filterIsActive(selectedFilter)) return;
		const companyStages = get(this.props, 'currentUser.company.stages');
		const customStages = convertStagesToStringArray(companyStages);

		if (customStages.includes(selectedFilter)) {
			this.setState(
				{
					filteredCompanyStages: [...filteredCompanyStages, selectedFilter],
					loading: true,
				},
				async () => await this.setQueryToState(searchQuery)
			);
		}

		if (statuses.includes(selectedFilter)) {
			if (filteredStatuses.includes(selectedFilter)) return;
			if (selectedFilter === INTERVIEWING) isFilteredByInterviewing = true;

			this.setState(
				{
					filteredStatuses: [...filteredStatuses, selectedFilter],
					isFilteredByInterviewing,
					loading: true,
				},
				async () => await this.setQueryToState(searchQuery)
			);
		}
	};

	handleFilterSubCompany = (subCompany) => {
		let { filteredSubCompanies = [], searchQuery = '' } = this.state;
		if (filteredSubCompanies.map((b) => b).includes(subCompany)) return;
		filteredSubCompanies = [...filteredSubCompanies, subCompany];
		this.setState(
			{
				filteredSubCompanies,
			},
			async () => await this.setQueryToState(searchQuery)
		);
	};

	handleFilterUserGroup = (userGroup, key) => {
		let { filteredUserGroups = [], searchQuery = '' } = this.state;

		if (filteredUserGroups.map((group) => group.name).includes(userGroup))
			return;

		filteredUserGroups = [...filteredUserGroups, { name: userGroup, id: key }];

		this.setState(
			{
				filteredUserGroups,
			},
			async () => await this.setQueryToState(searchQuery)
		);
	};

	handleRemoveDepartmentFilter = (department) => {
		const { filteredDepartments = [], searchQuery = '' } = this.state;
		const index = filteredDepartments
			.map((dep) => dep.name)
			.indexOf(department);
		filteredDepartments.splice(index, 1);
		this.setState(
			{
				filteredDepartments,
			},
			async () => await this.setQueryToState(searchQuery)
		);
	};

	handleRemoveRecruiterFilter = (recruiter) => {
		const { filteredRecruiters = [], searchQuery = '' } = this.state;
		const index = filteredRecruiters.map((r) => r).indexOf(recruiter);
		filteredRecruiters.splice(index, 1);
		this.setState(
			{
				filteredRecruiters,
			},
			async () => await this.setQueryToState(searchQuery)
		);
	};

	handleRemoveStatusFilter = (status) => {
		let {
			isFilteredByInterviewing,
			filteredCompanyStages = [],
			filteredStatuses = [],
			searchQuery = '',
		} = this.state;
		const filterToRemove = status;
		const INTERVIEWING = 'interviewing';

		function notFilteredByInterviewing() {
			return (
				filteredCompanyStages.length === 1 && isFilteredByInterviewing === false
			);
		}

		function removeFilter(filters, filterToRemove) {
			return filters.filter((statusFilter) => statusFilter !== filterToRemove);
		}

		function hasActiveCustomFilters() {
			return filteredCompanyStages.length > 0;
		}

		if (filteredCompanyStages.includes(filterToRemove)) {
			if (notFilteredByInterviewing()) {
				filteredStatuses = removeFilter(filteredStatuses, INTERVIEWING);
			}

			filteredCompanyStages = removeFilter(
				filteredCompanyStages,
				filterToRemove
			);
		}

		if (filteredStatuses.includes(filterToRemove)) {
			if (filterToRemove === INTERVIEWING) {
				this.setState({ isFilteredByInterviewing: false, loading: true });
				if (hasActiveCustomFilters()) return;
			}

			filteredStatuses = removeFilter(filteredStatuses, filterToRemove);
		}

		this.setState(
			{
				filteredCompanyStages,
				filteredStatuses,
				loading: true,
			},
			async () => await this.setQueryToState(searchQuery)
		);
	};

	handleRemoveSubCompanyFilter = (subCompany) => {
		const { filteredSubCompanies = [], searchQuery = '' } = this.state;
		const index = filteredSubCompanies.map((b) => b).indexOf(subCompany);
		filteredSubCompanies.splice(index, 1);
		this.setState(
			{
				filteredSubCompanies,
			},
			async () => await this.setQueryToState(searchQuery)
		);
	};

	handleRemoveUserGroupFilter = (userGroup) => {
		const { filteredUserGroups = [], searchQuery = '' } = this.state;
		const index = filteredUserGroups
			.map((group) => group.name)
			.indexOf(userGroup);

		filteredUserGroups.splice(index, 1);

		this.setState(
			{
				filteredUserGroups,
			},
			async () => await this.setQueryToState(searchQuery)
		);
	};

	setExportData = (data) => {
		this.setState({ exportTableData: data });
	};

	sortByAlph = (a, b) => {
		if (!a) return -1;
		if (!b) return 1;
		if (a > b) return 1;
		if (a < b) return -1;
		return 0;
	};

	sortByDateReferred(data = []) {
		const sortedData = data.sort((a, b) =>
			this.sortByAlph(
				dayjs(get(b, 'referralDate')),
				dayjs(get(a, 'referralDate'))
			)
		);
		return sortedData;
	}

	updateReferralState = (referral) => {
		const searchedReferrals = this.state?.searchedReferrals ?? [];
		// Immutable pattern to update array or else React won't update table props
		const updatedSearchedReferrals = searchedReferrals.map(
			(previousReferral) => {
				if (previousReferral?.id === referral?.id) {
					return {
						...previousReferral,
						...referral,
					};
				}

				return previousReferral;
			}
		);

		this.setState({ searchedReferrals: updatedSearchedReferrals });
	};

	renderArrowIcon = (count) => {
		return (
			<div>
				<WebIcon color="var(--oslo-gray)" size={10} name="sort-down" />
			</div>
		);
	};

	render() {
		const {
			allMultiLingualData,
			currentUser,
			currentUser: {
				company: { userGroups },
			},
			departments,
			subCompanies = [],
		} = this.props;
		const {
			company,
			currencyRate,
			currencySymbol,
			exportTableData,
			filteredDepartments,
			filteredRecruiters,
			filteredStatuses,
			filteredSubCompanies,
			filteredUserGroups,
			filterOn,
			isCardDataSet,
			loading,
			query = '',
			recruiters = [],
			referralTotals,
			searchedReferrals,
			totalReferralCount,
			errorImageURL,
		} = this.state;

		if (!get(currentUser, 'company')) return <Spinner />;
		const languageCode = get(currentUser, 'languageCode', 'US');
		const dateFormat = get(currentUser, 'dateFormat', 'US');
		const whiteLabel = get(company, 'whiteLabel');
		const sortedDepartments = _.sortBy(departments, ['name']);
		const departmentOptions = sortedDepartments.map((department) => {
			return (
				<Option key={department.id} value={department.name}>
					{department.name}
				</Option>
			);
		});
		const statusesData = ['referred', 'accepted', 'interviewing'];
		const customStages = get(this.props, 'currentUser.company.stages');
		if (customStages) {
			const stages = parse(customStages).map(
				(stage) => Object.values(stage)[0]
			);
			if (stages.length > 0) {
				for (const status of stages) statusesData.push(status.trim());
			}
		}

		statusesData.push(
			'hired',
			'notHired',
			'declined',
			'noresponse',
			'transferred',
			'inactive',
			'ineligible'
		);

		const statusOptions = statusesData.map((status) => {
			return (
				<Option key={status} value={status}>
					{mapReferralStatus(status, company)}
				</Option>
			);
		});

		const recruiterOptions = recruiters.map((recruiter) => {
			return (
				<Option key={get(recruiter, 'id')} value={get(recruiter, 'id')}>
					{`${get(recruiter, 'firstName')} ${get(recruiter, 'lastName')}`}
				</Option>
			);
		});
		const subComps = _.sortBy(subCompanies, ['name']);
		const subCompanyOptions = subComps.map((subCompany) => {
			return (
				<Option key={get(subCompany, 'id')} value={get(subCompany, 'id')}>
					{get(subCompany, 'name')}
				</Option>
			);
		});

		const sortedUserGroups = _.sortBy(userGroups, ['name']);
		const userGroupOptions = sortedUserGroups.map((userGroup) => {
			return (
				<Option key={userGroup.id} value={userGroup.name}>
					{userGroup.name}
				</Option>
			);
		});

		const showRecruiterFilter = recruiters.length > 0;

		const getCountDisplay = (count) =>
			count >= 1000 ? '1000+' : count.toString();

		const counts = {
			totalSent: 0,
			accepted: 0,
			declined: 0,
			interviewing: 0,
			hired: 0,
			notHired: 0,
		};

		if (!loading) {
			for (const item of searchedReferrals) {
				if (
					item &&
					item.status &&
					[
						'accepted',
						'declined',
						'interviewing',
						'hired',
						'notHired',
					].includes(item.status)
				) {
					counts[item.status]++;
				}
			}

			counts.totalSent = getCountDisplay(searchedReferrals.length);
			counts.accepted = getCountDisplay(counts.accepted);
			counts.declined = getCountDisplay(counts.declined);
			counts.interviewing = getCountDisplay(counts.interviewing);
			counts.hired = getCountDisplay(counts.hired);
			counts.notHired = getCountDisplay(counts.notHired);
		}

		return (
			<main>
				<div className="page-title">
					<h2 className="page-heading">
						{ml('Internal Applicants', currentUser, allMultiLingualData)}
					</h2>
				</div>

				<div className="color-card-grid">
					<ColorCard
						isOneSided
						bgColor="var(--deep-blush)"
						count={counts.totalSent ?? referralTotals?.internalTotal ?? '0'}
						isCardDataSet={isCardDataSet}
						title={ml('Total Applications', currentUser, allMultiLingualData)}
					/>
					<ColorCard
						isOneSided
						bgColor="var(--tan-hide)"
						count={counts.accepted ?? referralTotals?.internalAccepted ?? '0'}
						isCardDataSet={isCardDataSet}
						title={ml('In Progress', currentUser, allMultiLingualData)}
					/>
					<ColorCard
						isOneSided
						bgColor="var(--royal-blue)"
						count={
							counts.interviewing ?? referralTotals?.internalInterviewing ?? '0'
						}
						isCardDataSet={isCardDataSet}
						title={ml('Currently Applied', currentUser, allMultiLingualData)}
					/>
					<ColorCard
						isOneSided
						bgColor="var(--fern)"
						count={counts.hired ?? referralTotals?.internalHired ?? '0'}
						isCardDataSet={isCardDataSet}
						title={ml('Hired', currentUser, allMultiLingualData)}
					/>
					<ColorCard
						isOneSided
						bgColor="var(--sunset-orange)"
						count={counts.notHired ?? referralTotals?.internalNotHired ?? '0'}
						isCardDataSet={isCardDataSet}
						title={ml('Not Hired', currentUser, allMultiLingualData)}
					/>
				</div>

				<div className="referrals-actions">
					<SearchComponent
						loading={loading}
						searchQuery={query}
						setQueryToState={this.setQueryToState}
						placeholder={ml('Search', currentUser, allMultiLingualData)}
					/>
					<ExportSelector
						title={ml('Export', currentUser, allMultiLingualData)}
						items={[
							{
								key: 1,
								label: (
									<Tooltip
										title={ml(
											`You will receive an export via email of all data.`,
											currentUser,
											allMultiLingualData
										)}
										placement="left"
									>
										{ml('Export All', currentUser, allMultiLingualData)}
									</Tooltip>
								),
								onClick: this.getReport,
							},
							{
								key: 2,
								label: (
									<Tooltip
										title={
											totalReferralCount > 300
												? ml(
														'Unavailable: More than 300 results. Please filter to enable.',
														currentUser,
														allMultiLingualData
													)
												: ml(
														`Instantly download an export of the filtered results.`,
														currentUser,
														allMultiLingualData
													)
										}
										placement="left"
									>
										{ml('Export View', currentUser, allMultiLingualData)}
									</Tooltip>
								),
								onClick: 'csv-export',
								data: this.state.exportTableData,
								disabled: totalReferralCount > 300,
								reportType: 'internal-referral',
								companyName: currentUser?.company?.name,
							},
						]}
					/>
				</div>
				<div className="custom-filter">
					<div className="filter-wrap">
						<Select
							showArrow
							mode="multiple"
							placeholder={ml('Status', currentUser, allMultiLingualData)}
							maxTagCount={1}
							maxTagTextLength={15}
							suffixIcon={this.renderArrowIcon(filteredStatuses.length)}
							dropdownMatchSelectWidth={false}
							onSelect={(status, { key }) =>
								this.handleFilterStatus(status, key)
							}
							onDeselect={(status, { key }) =>
								this.handleRemoveStatusFilter(status, key)
							}
						>
							{statusOptions}
						</Select>
						{showRecruiterFilter ? (
							<Select
								showArrow
								mode="multiple"
								placeholder={ml('Recruiter', currentUser, allMultiLingualData)}
								maxTagCount={1}
								maxTagTextLength={15}
								suffixIcon={this.renderArrowIcon(filteredRecruiters.length)}
								dropdownMatchSelectWidth={false}
								filterOption={(input, option) =>
									option.children.toLowerCase().includes(input.toLowerCase())
								}
								onSelect={(recruiter) => this.handleFilterRecruiter(recruiter)}
								onDeselect={(recruiter) =>
									this.handleRemoveRecruiterFilter(recruiter)
								}
							>
								{recruiterOptions}
							</Select>
						) : null}
						<Select
							showArrow
							mode="multiple"
							placeholder={ml('Department', currentUser, allMultiLingualData)}
							maxTagCount={1}
							maxTagTextLength={15}
							suffixIcon={this.renderArrowIcon(filteredDepartments.length)}
							dropdownMatchSelectWidth={false}
							onSelect={(department, { key }) =>
								this.handleFilterDepartment(department, key)
							}
							onDeselect={(department, { key }) =>
								this.handleRemoveDepartmentFilter(department, key)
							}
						>
							{departmentOptions}
						</Select>
						{subCompanyOptions && subCompanyOptions.length > 0 && (
							<Select
								showArrow
								filterOption={(input, option) => {
									return option.props.children
										.toLowerCase()
										.includes(input.toLowerCase());
								}}
								mode="multiple"
								placeholder={ml(
									`${this.state.subCompanyLabel}`,
									this.props.currentUser,
									allMultiLingualData
								)}
								maxTagCount={1}
								maxTagTextLength={15}
								suffixIcon={this.renderArrowIcon(filteredSubCompanies.length)}
								dropdownMatchSelectWidth={false}
								onSelect={(subCompany) =>
									this.handleFilterSubCompany(subCompany)
								}
								onDeselect={(subCompany) =>
									this.handleRemoveSubCompanyFilter(subCompany)
								}
							>
								{subCompanyOptions}
							</Select>
						)}
						<Select
							showArrow
							mode="multiple"
							placeholder={ml('User Group', currentUser, allMultiLingualData)}
							maxTagCount={1}
							maxTagTextLength={15}
							suffixIcon={this.renderArrowIcon(filteredUserGroups.length)}
							onSelect={(userGroup, { key }) =>
								this.handleFilterUserGroup(userGroup, key)
							}
							onDeselect={(userGroup, { key }) =>
								this.handleRemoveUserGroupFilter(userGroup, key)
							}
						>
							{userGroupOptions}
						</Select>
						{filterOn && totalReferralCount > 300 && (
							<Tooltip
								title={ml(
									`Your filter includes over 300 results. Only the first 300 are displayed.`,
									currentUser,
									allMultiLingualData
								)}
								placement="right"
							>
								<ExclamationCircleOutlined />
							</Tooltip>
						)}
					</div>
				</div>

				{searchedReferrals.length > 0 && !loading ? (
					<div className="table-card">
						<InternalApplicantsTable
							allMultiLingualData={allMultiLingualData}
							currentUser={currentUser}
							company={company}
							tieredBonusQuery={this.props.tieredBonusQuery}
							updateReferralState={this.updateReferralState}
							role={this.props.currentUser.role}
							filteredData={searchedReferrals}
							sortByAlph={this.sortByAlph}
							rowKey={(record) => record.id}
							contactIncentiveBonus={
								this.props.currentUser.company.contactIncentiveBonus
							}
							currentCurrencyRate={currencyRate}
							currentCurrencySymbol={currencySymbol}
							languageCode={languageCode}
							dateFormat={dateFormat}
							updateCurrentUserCompany={this.props.updateCurrentUserCompany}
							setExportData={this.setExportData}
							onUpdateReferral={this.props.onUpdateReferral}
							onUpdateJob={this.props.onUpdateJob}
							onUpdateCompany={this.props.onUpdateCompany}
						/>
					</div>
				) : loading ? (
					<Spinner />
				) : (
					<div className="no-content">
						{whiteLabel ? (
							<img
								className="no-content-icon"
								src={errorImageURL}
								alt="error image"
							/>
						) : (
							<img className="no-content-icon" alt="erin-logo" src={fileIcon} />
						)}
						<p className="no-content-text">
							There are currently not any internal applicants.
						</p>
					</div>
				)}
			</main>
		);
	}
}

export default withApollo(InternalApplicantsComponent);
