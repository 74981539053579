import * as Msal from 'msal';

const uri = window.location.origin + '/mycontacts';
export const msConfig = {
	clientID: 'b77cd394-2b71-4de1-80ff-098f86d9d2d3',
	graphScopes: ['contacts.read'],
	redirectUri: uri,

	graphUrl: 'https://graph.microsoft.com/v1.0/',
};

export class AuthService {
	constructor(microsoftClientId) {
		const clientID = microsoftClientId ? microsoftClientId : msConfig.clientID;
		const { redirectUri } = msConfig;

		this.applicationConfig = {
			clientID,
			graphScopes: msConfig.graphScopes,
		};
		this.app = new Msal.UserAgentApplication(
			this.applicationConfig.clientID,
			'',
			() => {
				// Callback for login redirect
			},
			{
				redirectUri,
			}
		);
	}

	login = () => {
		return this.app.loginPopup(this.applicationConfig.graphScopes).then(
			(idToken) => {
				const user = this.app.getUser();
				if (user) {
					return user;
				}

				return null;
			},
			() => {
				return null;
			}
		);
	};

	logout = () => {
		this.app.logout();
	};

	getToken = () => {
		return this.app.acquireTokenSilent(this.applicationConfig.graphScopes).then(
			(accessToken) => {
				return accessToken;
			},
			(error) => {
				return this.app
					.acquireTokenPopup(this.applicationConfig.graphScopes)
					.then(
						(accessToken) => {
							return accessToken;
						},
						(error_) => {
							console.error(error_);
						}
					);
			}
		);
	};
}

export class GraphService {
	constructor() {
		this.graphUrl = msConfig.graphUrl;
	}

	getUserInfo = (token) => {
		const headers = new Headers({ Authorization: `Bearer ${token}` });
		const options = {
			headers,
		};
		return fetch(`${this.graphUrl}/me/contacts?$top=900&`, options)
			.then((response) => response.json())
			.catch((error) => {
				throw new Error(error.text());
			});
	};
}
