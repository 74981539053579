export const updateEditEmails = `mutation UpdateEditEmails($input: UpdateEditEmailsInput!) {
    updateEditEmails(input: $input) {
      id
      companyId
      referredEmailText
      alertEmailText
      inviteEmailText
    }
}
`;
