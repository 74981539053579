import { css } from 'emotion';
import { COLORS } from '../_shared/styles/colors';

const breakpoints = [576, 767, 991, 1200];

const mq = breakpoints.map((bp) => `@media (max-width: ${bp}px)`);

export const SelectStyles = css({
	fontFamily: '"Open Sans", sans-serif',
	width: '100%',
	'& .ant-select-selection__choice': {
		backgroundColor: COLORS.lightGreen,
		color: COLORS.green,
	},
});
export const iconContainer = css({
	width: 0,
	'& svg': {
		transform: 'translate(-22px, 8px)',
	},
});
export const searchbarStyle = css({
	fontFamily: '"Open Sans", sans-serif',
	width: 300,
	'& input': {
		fontFamily: '"Open Sans", sans-serif',
		borderRadius: 20,
		height: 32,
	},
	'& .ant-input:not(:first-child)': {
		paddingLeft: 35,
	},
});
export const StatusFilter = css({
	display: 'flex',
	padding: 0,
	'& h3': {
		marginRight: 10,
		fontSize: 18,
	},
	'& .ant-btn': {
		lineHeight: '12px',
	},
});
export const AllButtonStyles = (selected) =>
	css({
		width: 50,
		height: 30,
		backgroundColor: selected ? COLORS.red : COLORS.white,
		color: selected ? COLORS.white : COLORS.subHeading,
		'&:hover': {
			backgroundColor: COLORS.red,
			color: 'white',
			border: 'none',
		},
		'&:focus': {
			backgroundColor: COLORS.red,
			color: 'white',
			border: 'none',
		},
	});
export const StyledHeading = css({
	fontFamily: '"Open Sans", sans-serif',
	color: '#444444',
	fontWeight: 600,
	fontSize: 18,
	marginBottom: 0,
	display: 'inline',
});
export const total = css({
	[mq[1]]: {
		marginTop: 10,
	},
});
export const BonusStyles = css({
	fontFamily: '"Open Sans", sans-serif',
	color: COLORS.green,
	margin: 'auto',
	width: '50%',
	fontSize: 18,
	fontWeight: 600,
	marginBottom: 0,
	display: 'inline',
});
export const styleFont = css({
	fontFamily: '"Open Sans", sans-serif',
});
export const ReferralsDashboardContainer = css({
	width: '100%',
	height: '100%',
	padding: 20,
	[mq[2]]: {
		padding: '10px 5px',
	},
});
export const ToolBar = css({
	display: 'flex',
	justifyContent: 'space-between',
	maxWidth: 1290,
	marginBottom: 14,
});
export const SectionStyles = css({
	maxWidth: 1290,
	marginTop: 20,
	'& .ant-pagination': {
		textAlign: 'center',
		marginLeft: 'auto',
		marginRight: 'auto',
		float: 'none',
	},
});
export const StatusFilterContainer = css({
	width: 'auto',
	display: 'flex',
	marginTop: -17,
});
export const ReferralButtonStyles = (selected) =>
	css({
		marginRight: 10,
		backgroundColor: selected ? COLORS.blue : COLORS.white,
		color: selected ? COLORS.white : COLORS.subHeading,
		'&:hover': {
			backgroundColor: COLORS.blue,
			color: COLORS.white,
			borderColor: 'transparent',
		},
		'&:focus': {
			backgroundColor: COLORS.blue,
			color: COLORS.white,
			borderColor: 'transparent',
		},
		height: 30,
		width: 'auto',
	});
export const SelectContainer = css({
	display: 'flex',
	[mq[1]]: {
		marginBottom: 10,
	},
});
export const noReferrals = css({
	width: '100%',
	textAlign: 'center',
	marginTop: 40,
});

export const noReferralsText = css({
	width: '100%',
	textAlign: 'center',
});

export const AddButtonText = css({
	fontSize: 18,
	fontWeight: 600,
});

export const btnContainer = css({
	paddingBottom: 10,
	minWidth: 130,
	'& svg': {
		transform: 'translate(0px, 15%)',
	},
});

export const myReferrals = css({
	marginTop: -22,
	'@media (max-width: 1400px)': {
		marginTop: 5,
	},
});

export const myReferralsAlternate = css({
	marginTop: -17,
});

export const InfoIconStyles = css({
	position: 'relative',
	bottom: '2px',
	fontSize: 10,
	paddingLeft: 5,
	color: COLORS.lightGray7,
});
