export const newUserkeys = {
	LetsStarted: 'ml_LetsStarted',
	FirstComplete: 'ml_FirstComplete',
	FirstName: 'ml_FirstName',
	LastName: 'ml_LastName',
	Department: 'ml_Department',
	JobTitle: 'ml_JobTitle',
	Location: 'ml_Location',
	AutofillLocation: 'ml_AutofillLocation',
	City: 'ml_City',
	State: 'ml_State',
	Country: 'ml_Country',
	Email: 'ml_Email',
	NotValid: 'ml_NotValid',
	ByCreating: 'ml_ByCreating',
	Terms: 'ml_Terms',
	And: 'ml_And',
	Privacy: 'ml_Privacy',
	Agree: 'ml_Agree',
	Problem: 'ml_Problem',
	GetStarted: 'ml_GetStarted',
};
