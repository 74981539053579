import gql from 'graphql-tag';
import get from 'lodash/get';
import { compose, graphql } from 'react-apollo';
import { GetUserByIdWithReferrals } from 'src/_shared/api/graphql/custom/users/';
import uuid from 'uuid/v4';
import { updateUser } from '../../graphql/custom/users';
import {
	createUserDepartment,
	deleteUserDepartment,
} from '../../graphql/default';

export const withUserById = (Component, variables) => {
	return compose(
		graphql(gql(GetUserByIdWithReferrals), {
			options: {
				context: {
					headers: {
						'x-frame-options': 'deny', // This header will reach the server
					},
				},
				variables,
				fetchPolicy: 'cache-and-network',
			},
			props(response, props) {
				if (!response.data.getUser) {
					setTimeout(response.data.refetch, 2000);
					return null;
				}

				const user = get(
					response,
					'data.getUser',
					get(props, 'user', undefined)
				);
				return {
					user,
				};
			},
		}),
		graphql(gql(updateUser), {
			props: ({ mutate, ownProps, result }) => ({
				onUpdate(input) {
					mutate({
						variables: input,
						update(apolloCache, mutationResult) {
							try {
								const cacheData = apolloCache.readQuery({
									query: gql(GetUserByIdWithReferrals),
									context: {
										headers: {
											'x-frame-options': 'deny',
										},
									},
									variables: { id: ownProps?.user.id },
								});
								const updatedUser = mutationResult?.data.updateUser;
								const newCacheData = {
									getUser: {
										...cacheData?.getUser,
										...input.input,
									},
								};

								apolloCache.writeQuery({
									query: gql(GetUserByIdWithReferrals),
									context: {
										headers: {
											'x-frame-options': 'deny',
										},
									},
									data: cacheData,
									variables: ownProps?.user.id,
								});
							} catch (error) {
								console.log('onUpdate user ERROR:\n', error);
							}
						},
					});
				},
			}),
		}),
		graphql(gql(createUserDepartment), {
			props: (props) => ({
				onAddDepartment(input) {
					const optimisticResponseData = {
						...input,
						id: uuid(),
						user: props.ownProps.user,
						department: get(props, 'ownProps.departments', []).find(
							(dept) => dept.id === input.departmentId
						),
					};

					props.mutate({
						variables: { input },
						optimisticResponse: {
							__typename: 'Mutation',
							createUserDepartment: {
								...optimisticResponseData,
								__typename: 'createUserDepartment',
							},
						},
						update(proxy, { data: { createUserDepartment } }) {
							const data = proxy.readQuery({
								query: gql(GetUserByIdWithReferrals),
								variables: { id: input.userId },
							});

							// For some reason this update ran one too many times
							// those causing duplicates so let's check to see if it exists
							if (
								!data.getUser.managedDepartments.find(
									(dept) => dept.id === createUserDepartment.id
								)
							) {
								data.getUser.managedDepartments.push(createUserDepartment);
							}

							proxy.writeQuery({
								query: gql(GetUserByIdWithReferrals),
								data,
								variables: { id: input.userId },
							});
						},
					});
				},
			}),
		}),
		graphql(gql(deleteUserDepartment), {
			props: (props) => ({
				onDeleteDepartment(input) {
					const deletedDepartment = props.ownProps.user.managedDepartments.find(
						(dept) => dept.id === input.input.id
					);
					const optimisticResponseData = {
						...input,
						...deletedDepartment,
						user: props.ownProps.user,
					};
					props.mutate({
						variables: input,
						optimisticResponse: {
							__typename: 'Mutation',
							deleteUserDepartment: {
								...optimisticResponseData,
							},
						},
						update(proxy, { data: { deleteUserDepartment } }) {
							const data = proxy.readQuery({
								query: gql(GetUserByIdWithReferrals),
								variables: { id: props.ownProps.user.id },
							});
							data.getUser.managedDepartments =
								data.getUser.managedDepartments.filter(
									(dept) => dept.id !== deleteUserDepartment.id
								);
							proxy.writeQuery({
								query: gql(GetUserByIdWithReferrals),
								data,
								variables: { id: props.ownProps.user.id },
							});
						},
					});
				},
			}),
		})
	)(Component);
};
