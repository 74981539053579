export const deleteReferralQuestion = `mutation DeleteReferralQuestion($input: DeleteReferralQuestionInput!) {
    deleteReferralQuestion(input: $input) {
      id
      companyId
      questions
      active
      isCandidate
      sortOrder
    }
  }
  `;
