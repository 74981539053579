export const createJob = `mutation CreateJob($input: CreateJobInput!) {
  createJob(input: $input) {
    id
    jobType
    departmentId
    department {
      id
      name
    }
    companyId
    company {
      id
      name
    }
    title
    description
    publicLink
    salary
    location
    createdById
    createdBy {
      id
      firstName
      lastName
    }
    hiringManagerId
    hiringManager {
      id
      emailAddress
      firstName
      lastName
    }
    isGeneralReferral
    referralBonus
    notificationType
    status
    shares
    views
    dateCreated
    externalJobId
    externalDepartmentId
    lat
    lng
    referrals {
      id
      contact {
        id
        firstName
        lastName
        emailAddress
        socialMediaAccounts
      }
      contactId
      userId
      user {
        id
        firstName
        lastName
        role
        userGroupId
      }
      status
      bonusStatus
      referralDate
      referralType
    } 
  }
}
`;
