import get from 'lodash/get';
import { Route } from 'react-router-dom';
import { Redirect } from '../components/redirect';
import { RouterContext } from '../contexts';

export function UnauthenticatedRoute(props) {
	const { component: Component } = props;
	const routeData = get(props, 'routeData');
	const rest = { ...props };
	delete rest.component;
	return (
		<RouterContext.Consumer>
			{({ auth }) => (
				<Route
					{...rest}
					render={(props) => {
						if (auth) {
							const redirectURL =
								props.location?.state?.redirectURL ?? '/dashboard';
							return <Redirect loc={redirectURL} nav={props} />;
						}

						return <Component {...props} routeData={routeData} />;
					}}
				/>
			)}
		</RouterContext.Consumer>
	);
}
