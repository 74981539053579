import gql from 'graphql-tag';
import get from 'lodash/get';
import { compose, graphql } from 'react-apollo';
import uuid from 'uuid/v4';
import {
	createBonus,
	deleteBonus,
	queryBonusByCompanyIdIndex,
	updateBonus,
} from '../../graphql/custom/bonuses';
import { updateContact } from '../../graphql/custom/contacts';
import { updateJob } from '../../graphql/custom/jobs';
import { getReferralNew, updateReferral } from '../../graphql/custom/referrals';

export const withReferralDetails = (Component) => {
	return compose(
		graphql(gql(getReferralNew), {
			options(props, previous) {
				return {
					context: {
						headers: {
							'x-frame-options': 'deny', // This header will reach the server
						},
					},
					variables: {
						id: get(props, 'ownProps.id', get(props, 'match.params.id')),
					},
					fetchPolicy: 'cache-and-network',
				};
			},
			props(props, previous) {
				return {
					referral: get(props, 'data.getReferral', {}),
				};
			},
		}),
		graphql(gql(updateReferral), {
			props(props) {
				return {
					onUpdateReferral(input) {
						props.mutate({
							variables: input,
							refetchQueries: [
								{
									query: gql(getReferralNew),
									variables: {
										id: input.input.id,
									},
								},
							],
						});
					},
				};
			},
		}),
		graphql(gql(updateContact), {
			props: (props) => ({
				onUpdateContact(input) {
					const optimisticResponseData = {
						...input,
						__typename: 'Contact',
					};
					return props.mutate({
						variables: { input: { ...input } },
						optimisticResponse: {
							__typename: 'Mutation',
							updateContact: {
								__typename: 'updateContact',
								...optimisticResponseData,
							},
						},
					});
				},
			}),
		}),
		graphql(gql(updateJob), {
			props: (props) => ({
				onUpdateJob(input) {
					const optimisticResponseData = {
						...input.input,
					};
					return props.mutate({
						variables: { input },
						optimisticResponse: {
							__typename: 'Mutation',
							updateJob: {
								...optimisticResponseData,
								__typename: 'updateJob',
							},
							refetchQueries: [
								{
									query: gql(getReferralNew),
									variables: {
										id: get(
											props,
											'ownProps.referral.id',
											get(
												props,
												'ownProps.id',
												get(
													props,
													'ownProps.referral.id',
													get(props, 'match.params.id')
												)
											)
										),
									},
								},
							],
						},
					});
				},
			}),
		}),
		graphql(gql(createBonus), {
			props: (props) => ({
				onCreateBonus(input) {
					const variables = {
						...input,
						id: uuid(),
					};
					props.mutate({
						variables,
					});
				},
			}),
		}),
		graphql(gql(updateBonus), {
			props: (props) => ({
				onUpdateBonus(input) {
					const optimisticResponseData = {
						...input.input,
						__typename: 'Bonus',
					};
					props.mutate({
						variables: input,
						optimisticResponse: {
							__typeName: 'Mutation',
							updateBonus: {
								__typeName: 'updateBonus',
								...optimisticResponseData,
							},
						},
						update(proxy, query) {
							try {
								const data = proxy.readQuery({
									query: gql(queryBonusByCompanyIdIndex),
									variables: {
										companyId: get(
											props,
											['ownProps', 'currentUser', 'companyId'],
											[]
										),
									},
								});
								const bonuses = get(data, 'queryBonusByCompanyIdIndex.items');

								proxy.writeQuery({
									query: gql(queryBonusByCompanyIdIndex),
									variables: {
										companyId: get(
											props,
											['ownProps', 'currentUser', 'companyId'],
											[]
										),
									},
									bonuses,
									data,
								});
							} catch (error) {
								console.log(error);
							}
						},
					});
				},
			}),
		}),

		graphql(gql(deleteBonus), {
			props: (props) => ({
				onDeleteBonus(input) {
					const variables = {
						...input,
					};
					props.mutate({
						variables,
					});
				},
			}),
		})
	)(Component);
};
