import { Button } from 'antd';
import get from 'lodash/get';
import { Link } from 'react-router-dom';
import { calculateReferralBonus } from '../../_shared/services/utils.js';
import { ReferralModal } from './ReferralModalContainer.jsx';

function ReferralNotification(props) {
	const {
		currentUser,
		referrals,
		notification,
		onCreateReferral,
		onUpdateNotification,
		removeReferralRequest,
	} = props;
	const contact = get(notification, 'contact');
	const job = get(notification, 'job');
	const contactIncentiveBonus = get(
		props,
		'currentUser.company.contactIncentiveBonus'
	);
	const handleDontRefer = (e) => {
		e.preventDefault();
		onUpdateNotification({
			input: {
				id: notification.id,
				type: notification.type,
				referralRequestedStatus: 'declined',
			},
		});
		removeReferralRequest(notification.id);
	};

	const handleNotificationCompleted = () => {
		onUpdateNotification({
			input: {
				id: notification.id,
				type: notification.type,
				referralRequestedStatus: 'completed',
			},
		});
		removeReferralRequest(notification.id);
	};

	const formatDate = (date) => {
		const d = new Date(date);
		let month = String(d.getMonth() + 1);
		let day = String(d.getDate());
		const year = d.getFullYear().toString().slice(-2);

		if (month.length < 2) month = '0' + month;
		if (day.length < 2) day = '0' + day;

		return [month, day, year].join('/');
	};

	const newContact = !contact;
	return (
		<div className="referral-request-data">
			{contact && (
				<span className="rr-name">
					<Link to={`/mycontacts/${get(contact, 'id')}`}>
						{get(contact, 'firstName')} {get(contact, 'lastName')}
					</Link>
				</span>
			)}
			<span className="rr-position">
				<Link to={`/browsejobs/${get(job, 'id')}`}>{get(job, 'title')}</Link>
			</span>
			<div className="rr-info">
				{get(job, 'referralBonus') && get(job, 'referralBonus.hasBonus') && (
					<span className="text-success">
						{`${get(props, 'currencySymbol') || '$'}${calculateReferralBonus(
							contactIncentiveBonus,
							get(job, 'referralBonus.amount'),
							get(props, 'currentUser.incentiveEligible'),
							get(job, 'tieredBonus'),
							'employee',
							get(props, 'currentUser.userGroupId')
						)}`.replaceAll(/\B(?=(\d{3})+(?!\d))/g, ',')}
					</span>
				)}
				<span>{formatDate(get(props, 'notification.dateCreated'))}</span>
				<ReferralModal
					handleNotificationCompleted={handleNotificationCompleted}
					currentUser={currentUser}
					newContact={newContact}
					buttonType="small"
					notification={notification}
					jobData={job}
					job={job}
					contact={contact}
					referrals={referrals}
					onUpdateNotification={onUpdateNotification}
					onCreateReferral={onCreateReferral}
				/>
				<Button size="small" onClick={handleDontRefer}>
					Decline
				</Button>
			</div>
		</div>
	);
}

export default ReferralNotification;
