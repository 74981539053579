import { css } from 'emotion';
import get from 'lodash/get';
import { COLORS } from 'src/_shared/styles/colors';

const breakpoints = [576, 767, 991, 1200];

const mq = breakpoints.map((bp) => `@media (max-width: ${bp}px)`);

export const mainContainer = css({
	width: '100%',
	height: '100%',
	padding: 20,
	[mq[2]]: {
		padding: '10px 5px',
	},
});

export const btnContainer = css({
	minWidth: 130,
	alignItems: 'center',
	display: 'inline-flex',
});

export const addJobsButton = (theme) => {
	let buttonColor = COLORS.red;
	if (get(theme, 'enabled')) {
		buttonColor = get(theme, 'addButtonColor', COLORS.red);
	}

	return css({
		backgroundColor: buttonColor,
		color: COLORS.white,
		height: 42,
		width: 42,
		'&:hover': {
			backgroundColor: buttonColor,
			color: COLORS.white,
			borderColor: 'transparent',
		},
		'&:focus': {
			backgroundColor: buttonColor,
			color: COLORS.white,
			borderColor: 'transparent',
		},
	});
};

export const DropDown = css({
	fontFamily: '"Open Sans", sans-serif',
});

export const iconContainer = css({
	width: 0,
	pointerEvents: 'none',
	zIndex: 1,
});

export const SelectStyles = css({
	fontFamily: '"Open Sans", sans-serif',
	width: '98%',
	position: 'relative',
	'& .ant-select-selection__choice': {
		backgroundColor: COLORS.lightGreen,
		color: COLORS.green,
	},
	'& .anticon': {
		backgroundColor: COLORS.lightGreen,
		color: COLORS.green,
	},
});

export const paginationStyles = css({
	position: 'fixed',
	left: '50%',
	bottom: 57, // This is because the eldest-parent container has height of 100%
	transform: 'translate(-50%, -50%)',
	margin: '0, auto',
});

export const cardListStyle = css({
	'& .ant-pagination': {
		textAlign: 'center',
		margin: 'auto',
	},
	marginTop: 15,
	'& .ant-list-empty-text': {
		visibility: 'hidden',
	},
});

export const departmentFilterContainer = css({
	width: '100%',
	display: 'flex',
	marginTop: -17,
	[mq[1]]: {
		marginTop: 0,
	},
});

export const filterWrap = css({
	display: 'flex',
	[mq[1]]: {
		width: '100%',
	},
});

export const topContainer = css({
	marginBottom: 10,
	display: 'flex',
	justifyContent: 'space-between',
	[mq[1]]: {
		flexDirection: 'column',
	},
});

export const middleContainer = css({
	marginBottom: 10,
});

export const gridTitle = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 600,
	color: COLORS.black,
	fontSize: 18,
});

export const addJobsButtonText = css({
	fontSize: 18,
	fontWeight: 600,
	color: COLORS.black,
});

export const searchbarStyle = css({
	fontFamily: '"Open Sans", sans-serif',
	'& input': {
		fontFamily: '"Open Sans", sans-serif',
		borderRadius: 20,
		width: 248,
		height: 32,
	},
	'& .ant-input:not(:first-child)': {
		paddingLeft: 35,
	},
});

export const addJobsButtonIcon = css({
	fontSize: 35,
});

export const modalTitleText = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 500,
	color: COLORS.heading,
	fontSize: '1.5em',
});

export const modalContainer = css({
	width: 10,
});

export const loadingImage = css({
	display: 'block',
	margin: 'auto',
});

export const dropdownMenuItem = css({
	marginTop: 16,
	width: 200,
	height: 30,
});

export const dropdownButton = (width) =>
	css({
		marginTop: 17,
		height: 30,
		'&:hover': {
			backgroundColor: COLORS.red,
			color: COLORS.white,
			borderColor: 'transparent',
		},
		'&:focus': {
			backgroundColor: COLORS.white,
			color: COLORS.red,
			borderColor: COLORS.red,
		},
		width,
	});

export const SearchIconStyles = css({
	marginRight: 10,
	marginTop: -3,
});

export const spinContainer = css({
	width: '100%',
	height: '50vh',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
});

export const noJobs = css({
	width: '100%',
	textAlign: 'center',
	marginTop: 40,
});

export const imageContainer = css({
	display: 'flex',
	justifyContent: 'center',
});

export const imageCropContainer = css({
	width: 295,
	overflow: 'hidden',
});

export const ToolBar = css({
	marginBottom: '10px',
});

export const ColumnStyles = css({});

export const SelectContainer = css({
	float: 'right',
	position: 'relative',
	[mq[1]]: {
		margin: '0 0 10px !important',
		width: '100%',
	},

	'& > svg': {
		right: '10px',
		top: '10px',
		pointerEvents: 'none',
		position: 'absolute',
	},
});
export const searchWrap = css({
	width: 360,
	[mq[1]]: {
		width: '100%',
		margin: '0 0 10px',
	},
});
export const iconContainerDark = css({
	top: '-1px',
	right: 8,
	position: 'absolute',
	zIndex: 1,
});
export const SelectStylesNewActive = (length = 1) =>
	css({
		fontFamily: '"Open Sans", sans-serif',
		marginLeft: 15,
		width: length ? 280 : 240,
		maxWidth: 480,
		'& .ant-select-selection': {
			background: COLORS.inputActiveBackground,
			color: COLORS.inputActiveColor,
			borderRadius: 32,
			padding: '0 10px',
			borderColor: COLORS.inputActiveBackground,
			boxShadow: 'none',
		},
		'& .ant-select-selection__placeholder': {
			color: COLORS.inputActiveColor,
		},
		'& .ant-select-search__field__placeholder': {
			color: COLORS.inputActiveColor,
		},
		'& .ant-select-selection__choice': {
			backgroundColor: 'rgba(143, 143, 143, 0.2)',
			color: COLORS.inputActiveColor,
		},
		'& .ant-select-focused .ant-select-selection': {
			borderColor: COLORS.inputActiveColor,
			boxShadow: '0 0 0 2px rgba(143, 143, 143, 0.2)',
		},
		'& .ant-select-selection__choice__remove': {
			color: COLORS.inputActiveColor,
		},
		'& .ant-select-selection:hover': {
			borderColor: COLORS.inputActiveBackground,
			boxShadow: '0 0 0 2px rgba(143, 143, 143, 0.2)',
		},
		'& .ant-select-selection:focus': {
			borderColor: COLORS.inputActiveBackground,
			boxShadow: '0 0 0 2px rgba(143, 143, 143, 0.2)',
		},
		'& .ant-select-selection:active': {
			borderColor: COLORS.inputActiveBackground,
			boxShadow: '0 0 0 2px rgba(143, 143, 143, 0.2)',
		},
		[mq[1]]: {
			width: '100%',
			marginLeft: 0,
			maxWidth: 'none',
		},
	});
export const SelectStylesNew = css({
	fontFamily: '"Open Sans", sans-serif',
	marginLeft: 15,
	width: 240,
	'& .ant-select-selection': {
		background: COLORS.inputDisableBackground,
		color: COLORS.inputDisableColor,
		borderRadius: 32,
		padding: '0 10px',
		borderColor: COLORS.inputDisableBackground,
		boxShadow: 'none',
	},
	'& .ant-select-selection__placeholder': {
		color: COLORS.inputDisableColor,
	},
	'& .ant-select-search__field__placeholder': {
		color: COLORS.inputDisableColor,
	},
	'& .ant-select-selection__choice': {
		backgroundColor: COLORS.lightGreen,
		color: COLORS.green,
	},
	'& .ant-select-focused .ant-select-selection': {
		borderColor: COLORS.inputDisableBackground,
		boxShadow: '0 0 0 2px rgba(143, 143, 143, 0.2)',
	},
	'& .ant-select-selection:hover': {
		borderColor: COLORS.inputActiveBackground,
		boxShadow: '0 0 0 2px rgba(143, 143, 143, 0.2)',
	},
	'& .ant-select-selection:focus': {
		borderColor: COLORS.inputActiveBackground,
		boxShadow: '0 0 0 2px rgba(143, 143, 143, 0.2)',
	},
	'& .ant-select-selection:active': {
		borderColor: COLORS.inputActiveBackground,
		boxShadow: '0 0 0 2px rgba(143, 143, 143, 0.2)',
	},
	[mq[1]]: {
		width: '100%',
		marginLeft: 0,
	},
});

export const filterRow = css({
	display: 'flex',
	flexWrap: 'wrap',
	width: '100%',
	alignItems: 'center',
	marginTop: 10,
	[mq[1]]: {
		marginTop: 0,
	},
});
