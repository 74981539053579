import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Alert, Button, Checkbox, Popconfirm, Radio } from 'antd';
import get from 'lodash/get';
import { Component } from 'react';
import { withApollo } from 'react-apollo';
import { lambda } from '../_shared/services/utils.js';

class SettingsSFTPFolderForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			company: get(props, 'company'),
			setFolderName: false,
			loginType: 'none',
		};
	}

	generateCredentials = (e) => {
		const { currentUser } = this.props;
		const { loginType } = this.state;

		if (loginType) {
			const endpoint = 'sftp-credentials-generator';
			lambda({
				endpoint,
				variables: {
					companyId: currentUser?.companyId,
					userId: currentUser?.id,
					type: loginType,
				},
			});
		}
	};

	handleSubmit = (e) => {
		e.preventDefault();
		const checked = e?.target?.checked;
		const { company, onUpdateCompany } = this.props;
		this.props.form.validateFields((error, values) => {
			if (error) {
				return;
			}

			let sftpFolderName = '';
			if (checked) {
				sftpFolderName = get(company, 'id');
			}

			onUpdateCompany({
				input: {
					id: company.id,
					sftpFolderName,
				},
			});
			this.setState(
				{
					setFolderName: true,
				},
				() => {
					setTimeout(() => {
						this.setState({
							setFolderName: false,
						});
					}, 3000);
				}
			);
		});
	};

	render() {
		const { setFolderName } = this.state;
		const { company } = this.props;
		const folderName = company?.sftpFolderName;
		const { getFieldDecorator } = this.props.form;
		const FormItem = Form.Item;
		return (
			<>
				<Form>
					<h4 className="setting-card-title">SFTP Settings</h4>
					<div className="custom-form-group">
						<FormItem>
							{getFieldDecorator('folderName', {
								valuePropName: 'checked',
								initialValue: Boolean(folderName),
							})(<Checkbox onChange={this.handleSubmit}>Enable SFTP</Checkbox>)}
						</FormItem>
						{folderName && (
							<div>
								<h4 className="setting-card-title">Customer Login Type</h4>
								<FormItem>
									{getFieldDecorator('SSH', {
										initialValue: this.state.loginType,
									})(
										<Radio.Group
											onChange={(e) => {
												this.setState({ loginType: e.target.value });
											}}
										>
											<Radio value="ssh">SSH</Radio>
											<Radio value="credentials">Username and Password</Radio>
										</Radio.Group>
									)}
								</FormItem>
								{this.state.loginType !== 'none' && (
									<Popconfirm
										title={`Warning: Generating a new ${
											this.state.loginType === 'ssh'
												? 'SSH Key'
												: 'Username and Password'
										} will replace any existing ${
											this.state.loginType === 'ssh' ? 'keys' : 'credentials'
										}. ${
											this.state.loginType === 'ssh'
												? 'SSH Keys'
												: 'Credentials'
										} will be emailed to ${
											this.props?.currentUser?.emailAddress
										}.`}
										placement="top"
										okText="Yes"
										cancelText="No"
										onConfirm={this.generateCredentials}
									>
										<Button
											type="primary"
											size="large"
											className="btn-min-width"
										>
											{`Generate ${
												this.state.loginType === 'ssh'
													? 'SSH Key'
													: 'Credentials'
											}`}
										</Button>
									</Popconfirm>
								)}
							</div>
						)}
					</div>
				</Form>
				{setFolderName ? (
					<Alert
						message="Your updates were successfully submitted."
						type="success"
					/>
				) : null}
			</>
		);
	}
}
export default Form.create()(withApollo(SettingsSFTPFolderForm));
