import React from "react";
import AllRewardsListContainer from "./container/AllRewardsListContainer";
import { AllRewardsServiceProvider } from "./service/AllRewards.service.jsx";
import { useSelector } from "react-redux";
import { Spinner } from "../_shared/index.js";

const Allrewards = () => {
  const company = useSelector((state) => state.user.currentUser.company)
  if (!company) return <Spinner />
  return <>
    <AllRewardsServiceProvider>
      <AllRewardsListContainer />
    </AllRewardsServiceProvider>
  </>;
};

export default Allrewards;
