import { Component } from 'react';
import { borderedTileContainerClass } from './borderedTileStyles.js';

export function borderedTile(WrappedComponent) {
	return class BorderedTile extends Component {
		render() {
			const {
				width,
				height,
				padding,
				margin,
				minHeight,
				minWidth,
				background,
				style,
			} = this.props;
			return (
				<div
					className={borderedTileContainerClass(
						width,
						height,
						padding,
						margin,
						minHeight,
						minWidth,
						background
					)}
					style={style}
				>
					<WrappedComponent {...this.props} />
				</div>
			);
		}
	};
}
