/** Packages */
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { message } from 'antd';
import gql from 'graphql-tag';
import Cookies from 'js-cookie';
import { get, isEqual } from 'lodash';
import mixpanel from 'mixpanel-browser';
import { Component } from 'react';
import { withApollo } from 'react-apollo';

/** Components & Styles */

/** Shared Utils */
import { getUserById } from 'src/_shared/api/graphql/custom/users/';
import {
	FREQUENCY_MAP,
	FREQUENCY_TYPE,
	FREQUENCY_VALUE,
} from 'src/_shared/constants/';
import { logout, ml, parse } from 'src/_shared/services/utils.js';
import {
	ActionButtons,
	Avatar,
	EmployeeInfo,
	GiftCardLog,
	JobInfo,
	Notifications,
	PasswordLink,
	PointsLog,
} from './my-profile-components';

class MyProfileComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			allMultiLingualData: props.allMultiLingualData,
			avatarFile: undefined,
			currentUser: props.currentUser,
			defaultDistance: props.currentUser.defaultDistance ?? '0',
			departments: props.departments,
			editedAvatar: props.currentUser.avatar,
			editedCity: parse(props.currentUser.location ?? '{}')?.city ?? null,
			editedCountry: parse(props.currentUser.location ?? '{}')?.country ?? null,
			editedDateFormat: props.currentUser.dateFormat ?? 'US',
			editedDepartment: {
				key:
					props.currentUser?.department?.id ?? props.currentUser?.departmentId,
				label: props.currentUser?.department?.name,
			},
			editedFirstName: props.currentUser.firstName,
			editedLastName: props.currentUser.lastName,
			editedLocation: props.currentUser.location ?? null,
			editedPosition: props.currentUser.title,
			editedState: parse(props.currentUser.location ?? '{}')?.state ?? null,
			enableJobNotifications:
				props.currentUser?.enableJobNotifications ??
				props.currentUser?.company?.enableJobNotifications ??
				false,
			isEditing: false,
			isOptoutReferralNotification:
				props.currentUser.isOptoutReferralNotification ?? false,
			jobNotificationSetting: props?.currentUser?.jobNotificationSetting
				? parse(get(props, 'currentUser.jobNotificationSetting'))
				: props?.company?.jobNotificationSetting
					? parse(get(props, 'company.jobNotificationSetting'))
					: {
							departmentSelection: [],
							subCompanySelection: [],
						},
			location: parse(props.currentUser.location ?? '{}'),
			ml_Unit:
				props.currentUser?.userGroup?.measurement?.imperial === 'metric'
					? 'km'
					: ml('miles', props.currentUser, props.allMultiLingualData),
			notificationFrequency:
				props.currentUser?.jobNotificationFrequency ??
				props.currentUser.company?.jobNotificationFrequency ??
				FREQUENCY_VALUE.MONDAYS,
			notificationType:
				FREQUENCY_MAP?.[props.currentUser?.jobNotificationFrequency]?.type ??
				FREQUENCY_MAP?.[props.currentUser.company?.jobNotificationFrequency]
					?.type ??
				FREQUENCY_TYPE.WEEKLY,
			pointsSettings: JSON.parse(
				props.currentUser.company?.pointsSettings ?? '{}'
			),
			subCompanies: props.currentUser.company?.subCompanies ?? [],
			theme: parse(props.currentUser.company?.theme ?? '{}'),
			uploadedAvatar: undefined,
			visible: false,
			avatarRemoved: false,
		};

		this.handleChange = this.handleChange.bind(this);
		this.setAvatarRemoved = this.setAvatarRemoved.bind(this);
	}

	async componentDidMount() {
		await this.getUserDataById();
		const jwt = Cookies.get('jwt');
		const { resultData } = this.state;
		if (resultData !== '' && resultData !== undefined) {
			const expirationDoneByToken = get(
				resultData,
				'expirationDoneByToken',
				null
			);
			const expires = get(resultData, 'expires');
			if (jwt !== undefined && jwt !== expirationDoneByToken && expires) {
				logout(this.props.client);
			}
		}

		if (this.props.location.pathname === '/mycontacts/add/form') {
			this.setState({
				contactsModal: true,
			});
		}
	}

	componentDidUpdate(prevProps) {
		if (
			prevProps.currentUser.languageCode !==
				this.props.currentUser.languageCode &&
			this.props.allMultiLingualData.length > 0
		) {
			this.props.setMultiLingualData(this.props.allMultiLingualData);
		}

		if (
			!isEqual(prevProps.allMultiLingualData, this.props.allMultiLingualData)
		) {
			const newUnit =
				this.props.currentUser?.userGroup?.measurement?.imperial === 'metric'
					? 'km'
					: ml('miles', this.props.currentUser, this.props.allMultiLingualData);
			this.setState({
				currentUser: this.props.currentUser,
				allMultiLingualData: this.props.allMultiLingualData,
				ml_Unit: newUnit,
			});
		}
	}

	async getUserDataById(policy = 'network-only') {
		const { client, currentUser } = this.props;
		try {
			const userId = get(currentUser, 'id', null);
			if (userId !== null) {
				const { data } = await client.query({
					query: gql(getUserById),
					variables: {
						id: userId,
					},
					fetchPolicy: policy,
				});
				const result = get(data, 'getUser', null);
				this.setState({
					resultData: result,
				});
			}
		} catch (error) {
			console.error(error);
		}
	}

	setAvatarRemoved = (value) => {
		this.setState({ avatarRemoved: value });
	};

	handleCancel = () => {
		message.error('Edit Canceled');

		this.setState({
			avatarFile: undefined,
			uploadedAvatar: undefined,
			editedAvatar: this.props.currentUser.avatar,
			defaultDistance: this.props.currentUser.defaultDistance ?? '0',
			editedCity: this.state.location?.city ?? null,
			editedCountry: this.state.location?.country ?? null,
			editedDateFormat: this.props.currentUser.dateFormat ?? 'US',
			editedDepartment: {
				key:
					this.props.currentUser?.departmentId ??
					this.props.currentUser?.department?.id,
				label: this.props.currentUser?.department?.name,
			},
			editedFirstName: this.props.currentUser.firstName,
			editedLastName: this.props.currentUser.lastName,
			editedLocation: this.state.location,
			editedPosition: this.props.currentUser.title,
			editedState: this.state.location?.state ?? null,
			enableJobNotifications:
				this.props.currentUser?.enableJobNotifications ??
				this.props.currentUser?.company?.enableJobNotifications ??
				false,
			isEditing: false,
			isOptoutReferralNotification:
				this.props.currentUser.isOptoutReferralNotification ?? false,
			jobNotificationSetting: this.props?.currentUser?.jobNotificationSetting
				? parse(get(this.props, 'currentUser.jobNotificationSetting'))
				: this.props?.company?.jobNotificationSetting
					? parse(get(this.props, 'company.jobNotificationSetting'))
					: {
							departmentSelection: [],
							subCompanySelection: [],
						},
			notificationFrequency:
				this.props.currentUser.jobNotificationFrequency ??
				this.props.currentUser.company.jobNotificationFrequency ??
				FREQUENCY_VALUE.MONDAYS,
			notificationType:
				FREQUENCY_MAP?.[this.props.currentUser.jobNotificationFrequency]
					?.type ??
				FREQUENCY_MAP?.[this.props.currentUser.company.jobNotificationFrequency]
					?.type ??
				FREQUENCY_TYPE.WEEKLY,
			visible: false,
			avatarRemoved: false,
		});
	};

	handleChange = (newState) => {
		this.setState(newState);
	};

	handleFrequencyTypeChange = (selection) => {
		const notificationFrequency =
			selection === FREQUENCY_TYPE.WEEKLY
				? FREQUENCY_VALUE.MONDAYS
				: selection === FREQUENCY_TYPE.MONTHLY
					? FREQUENCY_VALUE.FIRST
					: FREQUENCY_VALUE.DAILY;
		this.setState({
			notificationType: selection,
			notificationFrequency,
		});
		this.props.form.setFields({
			deliveryTime: {
				value: notificationFrequency,
			},
		});
	};

	handleInput = (name) => (event) => {
		this.setState({
			[name]: event.target.value,
		});
	};

	handleSubmit = () => {
		try {
			const {
				avatarFile,
				editedAvatar,
				uploadedAvatar,
				editedCity,
				editedState,
				editedCountry,
			} = this.state;

			let s3Object = null;
			if (uploadedAvatar) {
				s3Object = uploadedAvatar;
				s3Object.__typename = 'S3Object';
			} else if (avatarFile) {
				s3Object = {
					bucket: 'erin-avatars',
					key: `user-pic/${this.props.currentUser.id}/${avatarFile.name}`,
					region: 'us-east-2',
					__typename: 'S3Object',
				};
			} else if (editedAvatar) {
				s3Object = {
					bucket: editedAvatar.bucket,
					key: editedAvatar.key,
					region: editedAvatar.region,
					__typename: 'S3Object',
				};
			} else {
				s3Object = null;
			}

			const dehydratedLocation = JSON.stringify({
				city: editedCity ?? null,
				state: editedState ?? null,
				country: editedCountry ?? null,
				lat: location?.lat ?? null,
				lng: location?.lng ?? null,
			});

			const mixpanelEvents = {};
			let mixpanelEvent = false;
			const enableJobNotificationsPrevious =
				this.props.currentUser?.enableJobNotifications ??
				this.props.currentUser?.company?.enableJobNotifications ??
				false;
			const isOptoutReferralNotificationPrevious =
				this.props.currentUser.isOptoutReferralNotification ?? false;

			if (enableJobNotificationsPrevious != this.state.enableJobNotifications) {
				mixpanelEvents['Job Notifications'] = this.state.enableJobNotifications;
				mixpanelEvent = true;
			}

			if (
				isOptoutReferralNotificationPrevious !=
				this.state.isOptoutReferralNotification
			) {
				mixpanelEvents['Opt Out of Referral'] =
					this.state.isOptoutReferralNotification;
				mixpanelEvent = true;
			}

			this.props.onUpdate({
				input: {
					id: this.state.currentUser.id,
					avatar: s3Object,
					dateFormat: this.state.editedDateFormat,
					defaultDistance: this.state.defaultDistance,
					departmentId: this.state.editedDepartment.key,
					enableJobNotifications: this.state.enableJobNotifications,
					firstName: this.state.editedFirstName,
					isOptoutReferralNotification: this.state.isOptoutReferralNotification,
					jobNotificationSetting: JSON.stringify(
						this.state.jobNotificationSetting
					),
					lastName: this.state.editedLastName,
					location: dehydratedLocation,
					jobNotificationFrequency: this.state.notificationFrequency,
					role: this.state.currentUser.role,
					title: this.state.editedPosition,
				},
			});

			const updatedCurrentUser = {
				...this.state.currentUser,
				avatar: s3Object,
				dateFormat: this.state.editedDateFormat,
				defaultDistance: this.state.defaultDistance,
				departmentId: this.state.editedDepartment.key,
				enableJobNotifications: this.state.enableJobNotifications,
				firstName: this.state.editedFirstName,
				isOptoutReferralNotification: this.state.isOptoutReferralNotification,
				jobNotificationSetting: JSON.stringify(
					this.state.jobNotificationSetting
				),
				jobNotificationFrequency: this.state.notificationFrequency,
				lastName: this.state.editedLastName,
				location: parse(dehydratedLocation),
				role: this.state.currentUser.role,
				title: this.state.editedPosition,
			};

			this.props.updateCurrentUser(updatedCurrentUser);

			const jobLocationCoordinate = {
				city: editedCity ?? '',
				state: editedState ?? '',
			};

			window.localStorage.setItem(
				'jobLocationCoordinate',
				JSON.stringify(jobLocationCoordinate)
			);
			window.localStorage.removeItem('setWithInDistance');

			this.setState({
				currentUser: updatedCurrentUser,
				editedLocation: dehydratedLocation,
				isEditing: false,
				// uploadedAvatar: undefined,
			});

			message.success('Your profile has been updated.');
			if (mixpanelEvent) {
				mixpanel.track('Profile Settings Updated', mixpanelEvents);
			}
		} catch (error) {
			console.error(error);
			message.error('An error has occurred.');
		}
	};

	render() {
		const {
			allMultiLingualData,
			avatarRemoved,
			currentUser,
			defaultDistance,
			departments,
			editedCity,
			editedCountry,
			editedDateFormat,
			editedDepartment,
			editedFirstName,
			editedLastName,
			editedPosition,
			editedState,
			enableJobNotifications,
			isEditing,
			jobNotificationSetting,
			ml_Unit,
			notificationFrequency,
			notificationType,
			pointsSettings,
			subCompanies,
			theme,
			uploadedAvatar,
			visible,
		} = this.state;
		const { avatar, id, emailAddress, lastLogin, role, managedDepartments } =
			currentUser;
		const userEditSettings = currentUser?.company?.userEditSettings
			? JSON.parse(currentUser.company?.userEditSettings)
			: {
					lockProfileFields: {
						employeeDepartment: false,
						employeeJobTitle: false,
						employeeLocation: false,
						employeeName: false,
					},
				};
		return (
			<main>
				<div className="profile-card">
					<div style={{ marginRight: 14 }}>
						<Avatar
							allMultiLingualData={allMultiLingualData}
							currentAvatar={this.props.currentUser.avatar}
							currentUser={currentUser}
							file={avatar}
							handleChange={(value) => this.handleChange(value)}
							isEditing={isEditing}
							uploadedAvatar={uploadedAvatar}
							avatarRemoved={avatarRemoved}
							setAvatarRemoved={this.setAvatarRemoved}
						/>
					</div>
					<div className="profile-details">
						<EmployeeInfo
							allMultiLingualData={allMultiLingualData}
							currentUser={currentUser}
							edit={isEditing}
							emailAddress={emailAddress}
							firstName={editedFirstName}
							handleInput={this.handleInput}
							lastName={editedLastName}
							lastLogin={lastLogin}
							lastLoginText={ml('Last Login', currentUser, allMultiLingualData)}
							userEditSettings={userEditSettings}
						/>

						<JobInfo
							allMultiLingualData={allMultiLingualData}
							city={editedCity}
							country={editedCountry}
							currentUser={currentUser}
							dateFormat={editedDateFormat}
							defaultDistance={defaultDistance}
							department={editedDepartment}
							departmentOptions={departments}
							handleChange={this.handleChange}
							handleInput={this.handleInput}
							isEditing={isEditing}
							labelDepartment={currentUser.company?.labelDepartment}
							managedDepartments={managedDepartments}
							ml_Unit={ml_Unit}
							role={role}
							state={editedState}
							title={editedPosition}
							userEditSettings={userEditSettings}
						/>
						<Notifications
							allMultiLingualData={allMultiLingualData}
							currentUser={currentUser}
							labelDepartment={currentUser.company?.labelDepartment}
							departmentOptions={departments}
							enableJobNotifications={enableJobNotifications}
							FormItem={Form.Item}
							getFieldDecorator={this.props.form.getFieldDecorator}
							handleChange={this.handleChange}
							handleFrequencyTypeChange={this.handleFrequencyTypeChange}
							isEditing={isEditing}
							isOptoutReferralNotification={
								this.state.isOptoutReferralNotification
							}
							jobNotificationSetting={jobNotificationSetting}
							notificationFrequency={notificationFrequency}
							notificationType={notificationType}
							subCompanies={subCompanies}
							subCompanyLabel={currentUser?.company?.subCompanyLabel}
						/>

						{get(this.props, 'currentUser.authMethod') === 'credentials' && (
							<PasswordLink
								theme={theme}
								userId={id}
								visible={visible}
								showModal={() => this.setState({ visible: true })}
								handlePasswordCancel={() => this.setState({ visible: false })}
								passwordText={ml('Password', currentUser, allMultiLingualData)}
								resetPasswordText={ml(
									'Reset Password',
									currentUser,
									allMultiLingualData
								)}
							/>
						)}

						{pointsSettings?.enabled && (
							<PointsLog
								allMultiLingualData={allMultiLingualData}
								currentUser={currentUser}
							/>
						)}

						{pointsSettings?.isStoreEnabled && (
							<GiftCardLog
								allMultiLingualData={allMultiLingualData}
								currentUser={currentUser}
							/>
						)}
					</div>
					<div className="profile-actions">
						<ActionButtons
							allMultiLingualData={allMultiLingualData}
							currentUser={currentUser}
							handleSubmit={this.handleSubmit}
							handleCancel={this.handleCancel}
							isEditing={isEditing}
							handleChange={this.handleChange}
						/>
					</div>
				</div>
			</main>
		);
	}
}

export default Form.create()(withApollo(MyProfileComponent));
