import * as Actions from './dashboardActions.js';
import { state as initialState } from './dashboardState.js';

export const dashboardReducer = (state = initialState, action) => {
	switch (action.type) {
		case Actions.INVITE_EMPLOYEES_STARTED: {
			return {
				...state,
				error: null,
				modalState: 'open',
			};
		}

		case Actions.INVITE_EMPLOYEES_SUBMITTING: {
			return {
				...state,
				loading: true,
			};
		}

		case Actions.BULK_REFERRAL_SUBMITTING: {
			return {
				...state,
				loading: true,
			};
		}

		case Actions.INVITE_EMPLOYEES_SUCCEEDED: {
			return {
				...state,
				loading: false,
				error: null,
				modalState: 'closed',
				inviteResults: action.payload.inviteResults,
			};
		}

		case Actions.INVITE_EMPLOYEES_FAILED: {
			return {
				...state,
				loading: false,
				error: action.payload.error,
			};
		}

		case Actions.INVITE_EMPLOYEES_CANCELLED: {
			return {
				...state,
				loading: false,
				modalState: 'closed',
			};
		}

		case Actions.SET_MULTILINGUAL_DATA: {
			return handleSetMultiLingualData(state, action);
		}

		case Actions.SET_TRANSLATIONSBYLANGUAGECODE_DATA: {
			return {
				...state,
				translationsByLanguageCode: action.payload.translationsByLanguageCode,
			};
		}

		case Actions.ACCOUNT_CLAIM_IMPORT_STARTED: {
			return {
				...state,
				importError: null,
				importModalState: 'open',
			};
		}

		case Actions.ACCOUNT_CLAIM_IMPORT_CANCELLED: {
			return {
				...state,
				loading: false,
				importModalState: 'closed',
			};
		}

		case Actions.ACCOUNT_CLAIM_IMPORT_SUBMITTING: {
			return {
				...state,
				loading: true,
			};
		}

		case Actions.ACCOUNT_CLAIM_IMPORT_SUCCEEDED: {
			return {
				...state,
				loading: false,
				importError: null,
				modalState: 'closed',
				importResults: action.payload.importResults,
			};
		}

		case Actions.ACCOUNT_CLAIM_IMPORT_FAILED: {
			return {
				...state,
				loading: false,
				importError: action.payload.error,
			};
		}

		default: {
			return state;
		}
	}
};

const handleSetMultiLingualData = (state, { payload }) => {
	const { multiLingualData } = payload;
	return {
		...state,
		allMultiLingualData: multiLingualData,
	};
};
