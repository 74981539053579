import { createContext, useContext } from 'react';
import { useRnRAPIService } from '../../rewardsAndRecognitionHelper/service/RnRAPI.service.jsx';
import { APPROVAL_REWARDS_ENDPOINTS } from '../constants/ApprovalRewards.constants.jsx';

const ApprovalRewardsServiceContext = createContext(null);

export const ApprovalRewardsServiceProvider = (props) => {
  const APIService = useRnRAPIService();

  const getApprovalRewardsCount = () => {
    return APIService.get(APPROVAL_REWARDS_ENDPOINTS.GET_APPROVAL_REWARDS_COUNT);
  }

  const updateApprovalStatus = (id, requestBody) => {
    return APIService.put(APPROVAL_REWARDS_ENDPOINTS.UPDATE_APPROVAL_STATUS + id, requestBody);
  }

  const getPendingRewardsList = () => {
    return APIService.get(APPROVAL_REWARDS_ENDPOINTS.GET_PENDING_REWARDS_LIST);
  }

  const getApprovalRewardsList = () => {
    return APIService.get(APPROVAL_REWARDS_ENDPOINTS.GET_APPROVAL_REWARDS_LIST);
  }

  const ApprovalRewardsServiceOperations = {
    getApprovalRewardsCount,
    updateApprovalStatus,
    getPendingRewardsList,
    getApprovalRewardsList
  };

  return (
    <ApprovalRewardsServiceContext.Provider
      value={ApprovalRewardsServiceOperations}
    >
      {props.children}
    </ApprovalRewardsServiceContext.Provider>
  );
};

export const useApprovalRewardsService = () => {
  return useContext(ApprovalRewardsServiceContext);
};