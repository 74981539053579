import { css } from 'emotion';
import { ICONS } from './icons.js';
import award from '../../assets/svg/award.svg';

function WebIcon(props) {
	const iconClass = css({
		fill: props.color,
	});
	const svgClass = css({
		verticalAlign: props.align,
	});
	const hw = props.size;

	let size;
	switch (props.name) {
		case 'bonus': {
			size = '0 0 500 500';
			break;
		}

		case 'ondeck': {
			size = '0 0 650 650';
			break;
		}

		case 'messages': {
			size = '0 0 512 512';
			break;
		}

		case 'lock': {
			size = '0 0 512 512';
			break;
		}

		case 'edit': {
			size = '0 0 530 530';
			break;
		}

		case 'career': {
			size = '0 0 297.384 297.384';
			break;
		}

		case 'contacts': {
			size = '0 0 512 512';
			break;
		}

		case 'email-outline': {
			size = '0 0 500 500';
			break;
		}

		case 'shoppingBag': {
			size = '0 0 36 36';
			break;
		}

		case 'customPage': {
			size = '0 0 512 512';
			break;
		}

		case 'announcements': {
			size = '0 0 500 500';
			break;
		}

		case 'internal': {
			size = '0 0 331.38 472.37';
			break;
		}

		case 'gamification': {
			size = '0 0 948 1024';
			break;
		}

		case 'reports': {
			size = '0 0 550 550';
			break;
		}

		default: {
			size = '0 0 1000 1000';
		}
	}

	return (
		<>
			{props.name === 'award' ? (
				<>
					<img src={award} alt="img" />
				</>
			) : (
				<svg
					width={`${hw}px`}
					height={`${hw}px`}
					viewBox={size}
					className={props.align ? svgClass : null}
					style={{ ...props.style }}
				>
					{Array.isArray(ICONS[props.name]) ? (
						ICONS[props.name].map((path, index) => {
							return <path key={index} className={iconClass} d={path} />;
						})
					) : (
						<path className={iconClass} d={ICONS[props.name]} />
					)}
				</svg>
			)}
		</>
	);
}

WebIcon.defaultProps = {
	size: 14,
};

export default WebIcon;
