import getSymbolFromCurrency from 'currency-symbol-map';
import gql from 'graphql-tag';
import get from 'lodash/get';
import { Component } from 'react';
import { withApollo } from 'react-apollo';
import {
	deleteBonus,
	queryBonusByReferralIdIndex,
} from 'src/_shared/api/graphql/custom/bonuses/';
import { deleteReferral } from 'src/_shared/api/graphql/default/mutations';
import Spinner from 'src/_shared/components/spinner/SpinnerComponent.jsx';
import { findWithAttr as findWithAttribute } from '../_shared/services/utils.js';
import DetailsView from './ReferralDetailsViewComponent.jsx';
import { spinContainer } from './referralDetailsStyles.js';
import { referralKeys } from './referralsKeys.js';

class ReferralDetailsComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			company: get(props, 'currentUser.company'),
			selectedUser: props.currentUser,
			edit: false,
			currencyRate: 1,
			currencySymbol: '$',
			ml_CancelAdd: '',
			ml_ConfirmNewPosition: '',
			ml_TieredBonus: '',
			ml_Mobile: '',
			ml_ReferralComments: '',
			ml_Resume: '',
			ml_AddGeneralReferral: '',
			ml_AddJobHistory: '',
			ml_ShowJobHistory: '',
			ml_ReferredOn: '',
			ml_ReferralReady: '',
			ml_Description: '',
			ml_Company: '',
			ml_EmailPhoneNumber: '',
			ml_EditProfile: '',
			ml_Added: '',
			ml_Name: '',
			ml_Organization: '',
			ml_Edit: '',
			ml_Source: '',
			ml_Delete: '',
			ml_AddContacts: '',
			ml_StartDate: '',
			ml_EndDates: '',
			ml_MyContacts: '',
			ml_SmartReferral: '',
			ml_Action: '',
			ml_Position: '',
		};
	}

	componentDidMount() {
		this.getCurrencyRate(
			this.props.currentUser && this.props.currentUser.currency !== null
				? this.props.currentUser.currency
				: 'USD'
		);
		if (this.props.allMultiLingualData.length > 0) {
			const currentUser = this.state.selectedUser;
			this.props.setMultiLingualData(this.props.allMultiLingualData);
			const languageCode =
				currentUser.languageCode === null ? 'US' : currentUser.languageCode;
			const filteredData = this.props.allMultiLingualData.filter(
				(item) => item.languageCode === languageCode
			);
			const cancelAdd = filteredData.filter(
				(item) => item.key === referralKeys.CancelAdd
			);
			const confirmNewPosition = filteredData.filter(
				(item) => item.key === referralKeys.ConfirmNewPosition
			);
			const tieredBonus = filteredData.filter(
				(item) => item.key === referralKeys.TieredBonus
			);
			const mobile = filteredData.filter(
				(item) => item.key === referralKeys.Mobile
			);
			const referralComments = filteredData.filter(
				(item) => item.key === referralKeys.ReferralComments
			);
			const status = filteredData.filter(
				(item) => item.key === referralKeys.Status
			);
			const resume = filteredData.filter(
				(item) => item.key === referralKeys.Resume
			);
			const searchByName = filteredData.filter(
				(item) => item.key === referralKeys.SearchByName
			);
			const addGeneralReferral = filteredData.filter(
				(item) => item.key === referralKeys.AddGeneralReferral
			);
			const addJobHistory = filteredData.filter(
				(item) => item.key === referralKeys.AddJobHistory
			);
			const showJobHistory = filteredData.filter(
				(item) => item.key === referralKeys.ShowJobHistory
			);
			const referredOn = filteredData.filter(
				(item) => item.key === referralKeys.ReferredOn
			);
			const referralReady = filteredData.filter(
				(item) => item.key === referralKeys.ReferralReady
			);
			const description = filteredData.filter(
				(item) => item.key === referralKeys.Description
			);
			const company = filteredData.filter(
				(item) => item.key === referralKeys.Company
			);
			const emailPhoneNumber = filteredData.filter(
				(item) => item.key === referralKeys.EmailPhoneNumber
			);
			const downloadResume = filteredData.filter(
				(item) => item.key === referralKeys.DownloadResume
			);
			const referredBy = filteredData.filter(
				(item) => item.key === referralKeys.ReferredBy
			);
			const editProfile = filteredData.filter(
				(item) => item.key === referralKeys.EditProfile
			);
			const added = filteredData.filter(
				(item) => item.key === referralKeys.Added
			);
			const name = filteredData.filter(
				(item) => item.key === referralKeys.Name
			);
			const organization = filteredData.filter(
				(item) => item.key === referralKeys.Organization
			);
			const editLabel = filteredData.filter(
				(item) => item.key === referralKeys.Edit
			);
			const source = filteredData.filter(
				(item) => item.key === referralKeys.Source
			);
			const deleteLabel = filteredData.filter(
				(item) => item.key === referralKeys.Delete
			);
			const addContacts = filteredData.filter(
				(item) => item.key === referralKeys.AddContacts
			);
			const startDate = filteredData.filter(
				(item) => item.key === referralKeys.StartDate
			);
			const endDates = filteredData.filter(
				(item) => item.key === referralKeys.EndDates
			);
			const myContacts = filteredData.filter(
				(item) => item.key === referralKeys.MyContacts
			);
			const smartReferral = filteredData.filter(
				(item) => item.key === referralKeys.SmartReferral
			);
			const referrals = filteredData.filter(
				(item) => item.key === referralKeys.Referrals
			);
			const action = filteredData.filter(
				(item) => item.key === referralKeys.Action
			);
			const position = filteredData.filter(
				(item) => item.key === referralKeys.Position
			);
			this.setState({
				ml_CancelAdd: get(cancelAdd, 'text', 'Cancel Add'),
				ml_ConfirmNewPosition: get(
					confirmNewPosition,
					'text',
					'Confirm new position'
				),
				ml_TieredBonus: get(tieredBonus, 'text', 'Tiered Bonus'),
				ml_Mobile: get(mobile, 'text', 'Mobile'),
				ml_ReferralComments: get(referralComments, 'text', 'Referral Comments'),
				ml_Status: get(status, 'text', 'Status'),
				ml_Resume: get(resume, 'text', 'Resume'),
				ml_SearchByName: get(searchByName, 'text', 'Search'),
				ml_AddGeneralReferral: get(
					addGeneralReferral,
					'text',
					'Add General Referral'
				),
				ml_AddJobHistory: get(addJobHistory, 'text', 'Add Job History'),
				ml_ShowJobHistory: get(showJobHistory, 'text', 'Show Career Profile'),
				ml_ReferredOn: get(referredOn, 'text', 'Referred on'),
				ml_ReferralReady: get(referralReady, 'text', 'Referral Ready'),
				ml_Description: get(description, 'text', 'Description'),
				ml_Company: get(company, 'text', 'Company'),
				ml_EmailPhoneNumber: get(
					emailPhoneNumber,
					'text',
					'Email / Phone Number'
				),
				ml_DownloadResume: get(downloadResume, 'text', 'Download Resume'),
				ml_ReferredBy: get(referredBy, 'text', 'Referred By'),
				ml_EditProfile: get(editProfile, 'text', 'Edit Profile'),
				ml_Added: get(added, 'text', 'Added'),
				ml_Name: get(name, 'text', 'Name'),
				ml_Organization: get(organization, 'text', 'Organization'),
				ml_Edit: get(editLabel, 'text', 'Edit'),
				ml_Source: get(source, 'text', 'Source'),
				ml_Delete: get(deleteLabel, 'text', 'Delete'),
				ml_AddContacts: get(addContacts, 'text', 'Add Contacts'),
				ml_StartDate: get(startDate, 'text', 'Start Date'),
				ml_EndDates: get(endDates, 'text', 'End Dates'),
				ml_MyContacts: get(myContacts, 'text', 'My Contacts'),
				ml_SmartReferral: get(smartReferral, 'text', 'Smart Referral'),
				ml_Referrals: get(referrals, 'text', 'Referrals'),
				ml_Action: get(action, 'text', 'Action'),
				ml_Position: get(position, 'text', 'Position'),
			});
		} else {
			this.setState({
				ml_CancelAdd: 'Cancel Add',
				ml_ConfirmNewPosition: 'Confirm new position',
				ml_TieredBonus: 'Tiered Bonus',
				ml_Mobile: 'Mobile',
				ml_ReferralComments: 'Referral Comments',
				ml_Status: 'Status',
				ml_Resume: 'Resume',
				ml_SearchByName: 'Search',
				ml_AddGeneralReferral: 'Add General Referral',
				ml_AddJobHistory: 'Add Job History',
				ml_ShowJobHistory: 'Show Career Profile',
				ml_ReferredOn: 'Referred on',
				ml_ReferralReady: 'Referral Ready ',
				ml_Description: 'Description',
				ml_Company: 'Company',
				ml_EmailPhoneNumber: 'Email / Phone Number',
				ml_DownloadResume: 'Download Resume',
				ml_ReferredBy: 'Referred By',
				ml_EditProfile: 'Edit Profile',
				ml_Added: 'Added',
				ml_Name: 'Name',
				ml_Organization: 'Organization',
				ml_Edit: 'Edit',
				ml_Source: 'Source',
				ml_Delete: 'Delete',
				ml_AddContacts: 'Add Contacts',
				ml_StartDate: 'Start Date',
				ml_EndDates: 'End Dates',
				ml_MyContacts: 'My Contacts',
				ml_SmartReferral: 'Smart Referral',
				ml_Referrals: 'Referrals',
				ml_Action: 'Action',
				ml_Position: 'Position',
			});
		}
	}

	componentDidUpdate(prevProps) {
		if (
			prevProps.currentUser.languageCode !==
				this.props.currentUser.languageCode &&
			this.props.allMultiLingualData.length > 0
		) {
			this.props.setMultiLingualData(this.props.allMultiLingualData);
			const languageCode =
				this.props.currentUser.languageCode === null
					? 'US'
					: this.props.currentUser.languageCode;
			const filteredData = this.props.allMultiLingualData.filter(
				(item) => item.languageCode === languageCode
			);
			const cancelAdd = filteredData.filter(
				(item) => item.key === referralKeys.CancelAdd
			);
			const confirmNewPosition = filteredData.filter(
				(item) => item.key === referralKeys.ConfirmNewPosition
			);
			const tieredBonus = filteredData.filter(
				(item) => item.key === referralKeys.TieredBonus
			);
			const mobile = filteredData.filter(
				(item) => item.key === referralKeys.Mobile
			);
			const referralComments = filteredData.filter(
				(item) => item.key === referralKeys.ReferralComments
			);
			const status = filteredData.filter(
				(item) => item.key === referralKeys.Status
			);
			const resume = filteredData.filter(
				(item) => item.key === referralKeys.Resume
			);
			const searchByName = filteredData.filter(
				(item) => item.key === referralKeys.SearchByName
			);
			const addGeneralReferral = filteredData.filter(
				(item) => item.key === referralKeys.AddGeneralReferral
			);
			const addJobHistory = filteredData.filter(
				(item) => item.key === referralKeys.AddJobHistory
			);
			const showJobHistory = filteredData.filter(
				(item) => item.key === referralKeys.ShowJobHistory
			);
			const referredOn = filteredData.filter(
				(item) => item.key === referralKeys.ReferredOn
			);
			const referralReady = filteredData.filter(
				(item) => item.key === referralKeys.ReferralReady
			);
			const description = filteredData.filter(
				(item) => item.key === referralKeys.Description
			);
			const company = filteredData.filter(
				(item) => item.key === referralKeys.Company
			);
			const emailPhoneNumber = filteredData.filter(
				(item) => item.key === referralKeys.EmailPhoneNumber
			);
			const downloadResume = filteredData.filter(
				(item) => item.key === referralKeys.DownloadResume
			);
			const referredBy = filteredData.filter(
				(item) => item.key === referralKeys.ReferredBy
			);
			const editProfile = filteredData.filter(
				(item) => item.key === referralKeys.EditProfile
			);
			const added = filteredData.filter(
				(item) => item.key === referralKeys.Added
			);
			const name = filteredData.filter(
				(item) => item.key === referralKeys.Name
			);
			const organization = filteredData.filter(
				(item) => item.key === referralKeys.Organization
			);
			const editLabel = filteredData.filter(
				(item) => item.key === referralKeys.Edit
			);
			const source = filteredData.filter(
				(item) => item.key === referralKeys.Source
			);
			const deleteLabel = filteredData.filter(
				(item) => item.key === referralKeys.Delete
			);
			const addContacts = filteredData.filter(
				(item) => item.key === referralKeys.AddContacts
			);
			const startDate = filteredData.filter(
				(item) => item.key === referralKeys.StartDate
			);
			const endDates = filteredData.filter(
				(item) => item.key === referralKeys.EndDates
			);
			const myContacts = filteredData.filter(
				(item) => item.key === referralKeys.MyContacts
			);
			const smartReferral = filteredData.filter(
				(item) => item.key === referralKeys.SmartReferral
			);
			const referrals = filteredData.filter(
				(item) => item.key === referralKeys.Referrals
			);
			const action = filteredData.filter(
				(item) => item.key === referralKeys.Action
			);
			const position = filteredData.filter(
				(item) => item.key === referralKeys.Position
			);
			this.setState({
				ml_CancelAdd: get(cancelAdd, 'text', 'Cancel Add'),
				ml_ConfirmNewPosition: get(
					confirmNewPosition,
					'text',
					'Confirm new position'
				),
				ml_TieredBonus: get(tieredBonus, 'text', 'Tiered Bonus'),
				ml_Mobile: get(mobile, 'text', 'Mobile'),
				ml_ReferralComments: get(referralComments, 'text', 'Referral Comments'),
				ml_Status: get(status, 'text', 'Status'),
				ml_Resume: get(resume, 'text', 'Resume'),
				ml_SearchByName: get(searchByName, 'text', 'Search'),
				ml_AddGeneralReferral: get(
					addGeneralReferral,
					'text',
					'Add General Referral'
				),
				ml_AddJobHistory: get(addJobHistory, 'text', 'Add Job History'),
				ml_ShowJobHistory: get(showJobHistory, 'text', 'Show Career Profile'),
				ml_ReferredOn: get(referredOn, 'text', 'Referred on'),
				ml_ReferralReady: get(referralReady, 'text', 'Referral Ready'),
				ml_Description: get(description, 'text', 'Description'),
				ml_Company: get(company, 'text', 'Company'),
				ml_EmailPhoneNumber: get(
					emailPhoneNumber,
					'text',
					'Email / Phone Number'
				),
				ml_DownloadResume: get(downloadResume, 'text', 'Download Resume'),
				ml_ReferredBy: get(referredBy, 'text', 'Referred By'),
				ml_EditProfile: get(editProfile, 'text', 'Edit Profile'),
				ml_Added: get(added, 'text', 'Added'),
				ml_Name: get(name, 'text', 'Name'),
				ml_Organization: get(organization, 'text', 'Organization'),
				ml_Edit: get(editLabel, 'text', 'Edit'),
				ml_Source: get(source, 'text', 'Source'),
				ml_Delete: get(deleteLabel, 'text', 'Delete'),
				ml_AddContacts: get(addContacts, 'text', 'Add Contacts'),
				ml_StartDate: get(startDate, 'text', 'Start Date'),
				ml_EndDates: get(endDates, 'text', 'End Dates'),
				ml_MyContacts: get(myContacts, 'text', 'My Contacts'),
				ml_SmartReferral: get(smartReferral, 'text', 'Smart Referral'),
				ml_Referrals: get(referrals, 'text', 'Referrals'),
				ml_Action: get(action, 'text', 'Action'),
				ml_Position: get(position, 'text', 'Position'),
			});
		}
	}

	async getCurrencyRate(currency) {
		const response = await fetch(
			`https://api.exchangeratesapi.io/latest?base=USD&symbols=${currency}`
		);
		const result = await response.json();
		if (result.error) {
			// Make this USD
			this.setState({
				currencyRate: 1,
				currencySymbol: '$',
			});
		} else {
			const rate = result.rates[currency].toFixed(5);
			const symbol = getSymbolFromCurrency(currency);
			this.props.setCurrentCurrencyRate(rate);
			this.props.setCurrentCurrencySymbol(symbol);
			this.setState({
				currencyRate: rate,
				currencySymbol: symbol,
			});
		}
	}

	deleteReferral = (referral) => {
		this.props.client
			.query({
				query: gql(queryBonusByReferralIdIndex),
				variables: {
					referralId: get(referral, 'id'),
				},
			})
			.then((data) => {
				const bonuses = get(data, 'data.queryBonusByReferralIdIndex.items', []);
				if (bonuses.length > 0) {
					bonuses.forEach(async (bonus) => {
						await this.props.client.mutate({
							mutation: gql(deleteBonus),
							variables: {
								input: {
									id: get(bonus, 'id'),
								},
							},
						});
					});
				}

				this.props.client.mutate({
					mutation: gql(deleteReferral),
					variables: {
						input: {
							id: get(referral, 'id'),
						},
					},
				});
			});
	};

	render() {
		const { currentUser, location, referral, companyData } = this.props;
		const parsedOwnershipSettings = JSON.parse(
			get(companyData, 'ownershipSettings', '{}')
		);
		const { company } = this.state;
		if (referral && referral.companyId != currentUser.companyId) {
			return <Spinner company={company} />;
		}

		const {
			currencyRate,
			currencySymbol,
			ml_CancelAdd,
			ml_ConfirmNewPosition,
			ml_TieredBonus,
			ml_Mobile,
			ml_ReferralComments,
			ml_Status,
			ml_Resume,
			ml_AddGeneralReferral,
			ml_AddJobHistory,
			ml_ShowJobHistory,
			ml_ReferredOn,
			ml_ReferralReady,
			ml_Description,
			ml_Company,
			ml_DownloadResume,
			ml_ReferredBy,
			ml_EditProfile,
			ml_Organization,
			ml_StartDate,
			ml_EndDates,
			ml_MyContacts,
			ml_Referrals,
			ml_Position,
		} = this.state;
		const allReferrals = get(location, 'state.filteredData', []);
		let referralData;
		let mockReferral;
		let fullContactData;
		fullContactData = referral
			? referral.contact
				? referral.contact.fullContactData
				: null
			: null;

		const socials =
			typeof get(referral, 'contact.socialMediaAccounts') === 'string'
				? JSON.parse(get(referral, 'contact.socialMediaAccounts', '[]'))
				: null;

		if (referral !== undefined) {
			referralData = {
				id: get(referral, 'contact.id'),
				atsId: get(referral, 'atsId'),
				referralId: get(referral, 'id'),
				adminNote: get(referral, 'adminNote', ''),
				bonuses: get(referral, 'bonuses'),
				firstName: get(referral, 'contact.firstName'),
				lastName: get(referral, 'contact.lastName'),
				emailAddress: get(referral, 'contact.emailAddress')
					? get(referral, 'contact.emailAddress').toLowerCase()
					: null,
				position:
					fullContactData !== null && fullContactData.details !== undefined
						? fullContactData.title
						: null,
				organization:
					fullContactData !== null && fullContactData.details !== undefined
						? fullContactData.organization
						: null,
				location: 'New York, NY',
				bio:
					fullContactData !== null && fullContactData.details !== undefined
						? fullContactData.bio
						: null,
				phone: get(referral, 'contact.phoneNumber')
					? get(referral, 'contact.phoneNumber')
					: null,
				companyId: get(referral, 'companyId'),
				contactId: get(referral, 'contact.id'),
				contact: get(referral, 'contact'),
				department: 'Sales',
				linkedin:
					socials !== null &&
					findWithAttribute(socials, 'network', 'linkedin') >= 0
						? socials[findWithAttribute(socials, 'network', 'linkedin')].profile
						: null,
				facebook:
					socials !== null &&
					findWithAttribute(socials, 'network', 'facebook') >= 0
						? socials[findWithAttribute(socials, 'network', 'facebook')].profile
						: null,
				Twitter:
					socials !== null &&
					findWithAttribute(socials, 'network', 'twitter') >= 0
						? socials[findWithAttribute(socials, 'network', 'twitter')].profile
						: null,
				employment:
					fullContactData !== null && fullContactData.details !== undefined
						? fullContactData.details.employment
						: [],
				allReferrals,
				referrals: get(referral, 'contact.referrals'),
				referralType: get(referral, 'referralType', 'standard'),
				fullContact: fullContactData,
				incentiveEligible: get(referral, 'user.incentiveEligible'),
				job: get(referral, 'job'),
				jobId: get(referral, 'jobId'),
				socialMediaAccounts: socials,
				status: get(referral, 'status'),
				bonusStatus: get(referral, 'bonusStatus'),
				user: get(referral, 'user'),
				userId: get(referral, 'userId'),
				hireDate: get(referral, 'hireDate'),
				onDeckStatus: get(referral, ['contact', 'onDeckStatus']),
				onDeckDate: get(referral, ['contact', 'onDeckDate']),
				contactResume:
					get(referral, 'contactResume') === null
						? get(referral, ['contact', 'contactResume'], null)
						: get(referral, 'contactResume'),
				questionsData: get(referral, 'questionsData', []),
				candidateQuestionsData: get(referral, 'candidateQuestionsData', []),
				interestedQuestionsData: referral?.interestedQuestionsData ?? [],
				diversityHire: get(referral, 'diversityHire', false),
				startDate: get(referral, 'startDate'),
				customStatus: get(referral, 'customStatus'),
				ownershipAtsId: get(referral, 'ownershipAtsId'),
			};
			mockReferral = {
				firstName: 'test',
				lastName: 'test',
				avatar: 'https://randomuser.me/api/portraits/men/15.jpg',
				emailAddress: 'test',
				position: 'Head of Sales',
				organization: 'Salesforce',
				location: 'New York, NY',
				bio: 'CEO & Co-Founder of @FullContact, Managing Director @v1vc_. Tech Entrepreneur, Investor. Wife to @parkerbenson and Mother to Greyson Lorang',
				phone: '347-111-1111',
				id: '',
				department: 'Sales',
				linkedin: 'linkedin.com/in/gagecurry',
				facebook: 'facebook/gagecurry',
				Twitter: 'twitter/gagecurry',
			};
		}

		return (
			<div>
				{referral === undefined ? (
					<div className={spinContainer}>
						<Spinner company={company} />
					</div>
				) : (
					<DetailsView
						ownershipSettings={parsedOwnershipSettings}
						referral={referralData}
						mockReferral={mockReferral}
						employeeData={currentUser}
						history={this.props.history}
						currentUser={this.props.currentUser}
						currencyRate={currencyRate}
						currencySymbol={currencySymbol}
						cancelAddText={ml_CancelAdd}
						confirmNewPositionText={ml_ConfirmNewPosition}
						tieredBonusText={ml_TieredBonus}
						mobileText={ml_Mobile}
						referralCommentsText={ml_ReferralComments}
						statusText={ml_Status}
						resumeText={ml_Resume}
						addGeneralReferralText={ml_AddGeneralReferral}
						addJobHistoryText={ml_AddJobHistory}
						showJobHistoryText={ml_ShowJobHistory}
						referredOnText={ml_ReferredOn}
						referralReadyText={ml_ReferralReady}
						descriptionText={ml_Description}
						companyText={ml_Company}
						downloadResumeText={ml_DownloadResume}
						referredByText={ml_ReferredBy}
						editProfileText={ml_EditProfile}
						organizationText={ml_Organization}
						startDateText={ml_StartDate}
						endDatesText={ml_EndDates}
						myContactsText={ml_MyContacts}
						referralsText={ml_Referrals}
						positionText={ml_Position}
						deleteReferral={this.deleteReferral}
						onUpdate={this.props.onUpdateContact}
						onUpdateReferral={this.props.onUpdateReferral}
						onUpdateJob={this.props.onUpdateJob}
						onUpdateContact={this.props.onUpdateContact}
					/>
				)}
			</div>
		);
	}
}

export default withApollo(ReferralDetailsComponent);
