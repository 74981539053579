export const JobTypes = {
	fulltime: 'Full-time',
	parttime: 'Part-time',
	contract: 'Contract',
	internship: 'Internship',
	commission: 'Commission',
};

export const SalaryTypes = {
	yearly: 'per year',
	monthly: 'per month',
	weekly: 'per week',
	daily: 'per day',
	hourly: 'per hour',
};

export const tempJobDepartments = {
	'46c58997-c103-4937-864c-d52600619770': 'Department 002',
	'7c4085ff-2631-4ad2-8aea-6f159fded825': 'Department 003',
	'15d0e06e-4567-40cc-91bf-da8e97926816': 'Department 005',
};

export const notificationTypes = {
	ALL: 'Notify All Employees',
	DEPARTMENT: 'Notify Department Only',
	NONE: 'No Notifications',
};

export const pageSubtitles = [
	'Job Info',
	'Description',
	'Referral Info',
	'Review',
];

export const USStates = {
	AL: 'AL',
	AK: 'AK',
	AZ: 'AZ',
	AR: 'AR',
	CA: 'CA',
	CO: 'CO',
	CT: 'CT',
	DE: 'DE',
	DC: 'DC',
	FL: 'FL',
	GA: 'GA',
	HI: 'HI',
	ID: 'ID',
	IL: 'IL',
	IN: 'IN',
	IA: 'IA',
	KS: 'KS',
	KY: 'KY',
	LA: 'LA',
	ME: 'ME',
	MD: 'MD',
	MA: 'MA',
	MI: 'MI',
	MN: 'MN',
	MS: 'MS',
	MO: 'MO',
	MT: 'MT',
	NE: 'NE',
	NV: 'NV',
	NH: 'NH',
	NJ: 'NJ',
	NM: 'NM',
	NY: 'NY',
	NC: 'NC',
	ND: 'ND',
	OH: 'OH',
	OK: 'OK',
	OR: 'OR',
	PA: 'PA',
	RI: 'RI',
	SC: 'SC',
	SD: 'SD',
	TN: 'TN',
	TX: 'TX',
	UT: 'UT',
	VT: 'VT',
	VA: 'VA',
	WA: 'WA',
	WV: 'WV',
	WI: 'WI',
	WY: 'WY',
};
