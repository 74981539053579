export const updateReferralStatus = `mutation UpdateReferral($input: UpdateReferralInput!) {
  updateReferral(input: $input) {
    id
    contactId
    userId
    jobId
    bonusStatus
    status
    customStatus
    eligibilityResults
  }
}
`;
