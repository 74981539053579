import { css } from 'emotion';
import get from 'lodash/get';
import { COLORS } from '../../_shared/styles/colors';

const breakpoints = [576, 767, 991, 1200];

const mq = breakpoints.map((bp) => `@media (max-width: ${bp}px)`);

export const messageCenterContainer = css({
	width: '100%',
	height: '100%',
	padding: 20,
	backgroundColor: COLORS.lightGray2,
	[mq[2]]: {
		padding: '10px 5px',
	},
});
export const tableHeaderContainer = css({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	maxWidth: '100%',
	position: 'relative',
});

export const tableHeader = css({
	fontFamily: '"Open Sans", sans-serif !important',
	fontWeight: '600 !important',
});

export const tableContainer = css({
	borderRadius: 10,
	backgroundColor: 'white',
	padding: 20,
	height: 'auto',
	'& button:focus': {
		outline: 'none',
	},
	[mq[2]]: {
		padding: 8,
	},
});

export const FormStyles = css({
	marginTop: 15,
});

export const InputStyles = css({
	fontFamily: '"Open Sans", sans-serif',
	fontSize: 16,
	marginBottom: 15,
	height: 120,
	'& .ant-input': {
		height: 120,
		fontFamily: '"Open Sans", sans-serif',
		fontSize: 16,
	},
	'& .ant-form-explain': {
		fontSize: 12,
	},
});

export const InputStyles2 = css({
	fontFamily: '"Open Sans", sans-serif',
	margineTop: 10,
	marginBottom: 10,
	fontSize: 16,
	'& .ant-input': {
		height: 120,
		fontFamily: '"Open Sans", sans-serif',
		fontSize: 16,
	},
	'& .ant-form-explain': {
		fontSize: 12,
	},
	'& .ant-form-item-control': {
		lineHeight: '15px',
	},
});

export const BelowTextStyles = css({
	fontFamily: '"Open Sans", sans-serif',
	fontSize: '14px !important',
	fontWeight: 400,
	color: COLORS.lightGray5,
	marginBottom: 15,
});

export const btnRight = css({
	float: 'right',
	[mq[1]]: {
		float: 'none',
		marginTop: 10,
	},
});

export const SaveButton = css({
	width: 150,
	height: 40,
	background: '#018dd3',
	color: 'white',
	fontSize: 16,
	fontWeight: 600,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	borderRadius: '10px',
	border: '2px solid #018dd3',
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 200,
	lineHeight: '25px',
	[mq[1]]: {
		width: '100%',
	},
	marginBottom: 10,
});

export const successStyles = css({
	width: 270,
	position: 'fixed',
	left: 580,
});

export const regularButton = (selected) =>
	css({
		marginLeft: 8,
		backgroundColor: selected ? COLORS.gray2 : COLORS.white,
		color: selected ? COLORS.white : COLORS.subHeading,
		'&:hover': {
			backgroundColor: COLORS.gray2,
			color: COLORS.white,
			borderColor: selected ? 'transparent' : COLORS.subHeading,
		},
		'&:focus': {
			backgroundColor: selected ? COLORS.gray2 : COLORS.white,
			color: selected ? COLORS.white : COLORS.subHeading,
			borderColor: selected ? 'transparent' : COLORS.subheading,
		},
	});

export const inputContainer = (maxWidth) =>
	css({
		maxWidth,
		width: '100%',
	});

export const autocompleteDropdownContainer = css({
	position: 'absolute',
	width: 350,
	zIndex: 1000,
	boxShadow: '0 1px 6px 0 rgb(32 33 36 / 28%)',
});

export const typeaheadRow = (active) => ({
	backgroundColor: active ? '#e6f7ff' : 'white',
	padding: '3px 10px',
});

export const DropDown = css({
	fontFamily: '"Open Sans", sans-serif',
});

export const FormItemContainer = css({
	display: 'flex',
	alignItems: 'center',
	color: '#222222',
	margin: '0 0 20px',

	'& label': {
		display: 'block',
		fontSize: 16,
		color: COLORS.lightGray,
		lineHeight: '32px',
		marginRight: 10,
		marginBottom: 0,
	},

	'& .ant-row.ant-form-item': {
		margin: 0,
		height: 32,
	},
});

export const filterContainer = css({
	width: 'auto',
	display: 'flex',
	flexWrap: 'wrap',
	[mq[1]]: {
		flexDirection: 'column',
	},
});
export const SelectContainer = css({
	display: 'flex',
	height: 40,
	'& svg': {
		pointerEvents: 'none',
	},
});

export const customSelectClass = {
	'& .ant-select-selection': {
		width: '100%',
	},
};

export const iconContainer = css({
	'& svg': {
		transform: 'translate(-40px, 8px)',
	},
});

export const SelectStyles = css({
	width: 225,
	'& .ant-select-selection__choice': {
		backgroundColor: COLORS.lightGreen,
		color: COLORS.green,
	},
	'& .ant-select-selection--single': {
		height: 40,
		paddingTop: 4,
	},
});

export const LabelStyles = css({
	verticalAlign: 'middle',
	fontFamily: 'Open Sans,sans-serif',
	fontWeight: 600,
	color: COLORS.black,
});

export const checkBox = css({
	marginRight: 8,
});

export const SelectedEmployeesContainer = css({
	display: 'table',
});

export const SelectedEmployees = css({
	textAlign: 'center',
	width: '150px',
	backgroundColor: COLORS.dashboardGreen,
	borderRadius: 10,
	height: 100,
	position: 'relative',
	overflow: 'hidden',
});

export const SelectedEmployeesTextContiner = css({
	top: '20%',
	position: 'relative',
});

export const EmployeeCountClass = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 600,
	color: COLORS.green,
	fontSize: '1.50em',
	width: '100%',
});

export const SelectedEmployeesTextClass = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 600,
	color: COLORS.white,
	fontSize: '1.0em',
	width: '100%',
});

export const LabelClass = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 600,
	color: '#444444',
	fontSize: '1.5em',
});

export const DividerClass = css({
	'& .ant-divider-horizontal': {
		margin: '15px 0px !important',
	},
});

export const RTEContainer = css({
	display: 'flex',
	height: 215,
	flex: '1 1 auto',
});

export const SubmitBtnContainer = css({
	width: '100%',
	display: 'flex',
	justifyContent: 'center',
	marginBottom: 0,
	'& .ant-form-item-control-wrapper': {
		'@media (max-width: 575px)': {
			width: 'auto',
		},
	},
});

export const BtnContainer = (theme) => {
	let buttonColor = COLORS.blue;
	if (get(theme, 'enabled')) {
		buttonColor = get(theme, 'buttonColor', buttonColor);
	}

	return css({
		overflow: 'hidden',
		'& .ant-btn: hover': {
			color: buttonColor,
			borderColor: buttonColor,
		},
		'& button:hover > div > svg > path': {
			fill: buttonColor,
		},
		'& button:focus > div > svg > path': {
			fill: buttonColor,
		},
		width: '100%',
		'& .ant-btn:hover': {
			backgroundColor: `${buttonColor} !important`,
			border: `2px solid ${buttonColor} !important`,
			color: '#fff !important',
		},
		'& .ant-btn:focus': {
			backgroundColor: `${buttonColor} !important`,
			border: `2px solid ${buttonColor} !important`,
			color: '#fff !important',
		},
	});
};

export const RippleButton = css({
	display: 'inline !important',
	overflow: 'hidden',
	[mq[1]]: {
		width: '100%',
	},
});

export const SendTestEmailButton = css({
	width: 180,
	height: 40,
	background: COLORS.white,
	color: COLORS.gray,
	fontSize: 14,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	borderRadius: '10px',
	border: '1.5px solid #8d99a3',
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 200,
	lineHeight: '25px',
	'& .ant-btn: hover': {
		color: COLORS.gray,
		borderColor: COLORS.gray,
	},
	'& .ant-btn: focus': {
		color: COLORS.gray,
		borderColor: COLORS.gray,
	},
	[mq[1]]: {
		width: '100%',
	},
});

export const SendEmailEmployees = css({
	width: 180,
	height: 40,
	background: '#018dd3',
	color: 'white',
	fontSize: 14,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	borderRadius: '10px',
	border: '2px solid #018dd3',
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 200,
	lineHeight: '25px',
	[mq[1]]: {
		width: '100%',
	},
});

export const SelectStylesJob = css({
	width: 225,
	'& .ant-select-selection__choice': {
		backgroundColor: COLORS.lightGreen,
		color: COLORS.green,
	},
	'& .ant-select-selection--single': {
		height: 40,
		paddingTop: 4,
	},
	'& .ant-select-arrow': {
		display: 'none',
	},
});

export const AlertError = css({
	marginBottom: 20,
});

export const PopoverStyle = css({
	'& .ant-popover-placement-topLeft': {
		width: 290,
		left: 424,
		top: 644,
	},
	'& .ant-popover-inner-content': {
		borderColor: 'rgba(0, 0, 0, 0.75)',
		borderRadius: 4,
		color: 'white',
		backgroundColor: 'rgba(0, 0, 0, 0.75)',
		padding: 5,
	},
	'& .ant-popover-arrow': {
		backgroundColor: 'rgba(0, 0, 0, 0.75)',
		borderColor: 'rgba(0, 0, 0, 0.75)',
		border: '0px solid',
		zIndex: -1,
	},
});

export const fieldErrors = css({
	width: 'auto',
	marginLeft: '5%',
});

export const errorText = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 200,
	color: COLORS.red,
	fontSize: '0.85em',
	left: -30,
});

export const subjectInput = css({
	flex: '1 1 auto',
});

export const changeDirection = css({
	[mq[1]]: {
		flexDirection: 'column',
	},
});

export const bottomButton = css({
	width: '100%',
	paddingTop: 40,
	paddingLeft: 90,
	paddingBottom: 40,
	[mq[1]]: {
		paddingLeft: 0,
	},
});

export const changeOrientation = css({
	alignItems: 'center',
	[mq[0]]: {
		flexDirection: 'column',
		'& > div': {
			marginBottom: 10,
		},
	},
});

export const SelectStylesLocation = css({
	width: 205,
	'& .ant-select-arrow': {
		display: 'none',
	},
	[mq[1]]: {
		width: '100%',
	},
});

export const rightSelect = css({
	marginLeft: '2%',
	marginRight: '2%',
	[mq[1]]: {
		marginRight: 0,
	},
});
