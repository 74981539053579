export const NominationParticipant = {
    Nominator: 'Nominator',
    Nominee: 'Nominee',
}

export const datediff = (first, second) => {
    return Math.round((second - first) / (1000 * 60 * 60 * 24)) + 1;
}

export function isDateLessThanOrEqualToCurrent(dateString) {
    return new Date(dateString) <= new Date();
}