import { Button, Tooltip } from 'antd';
import gql from 'graphql-tag';
import Cookies from 'js-cookie';
import _, { get } from 'lodash';
import { Component } from 'react';
import { withApollo } from 'react-apollo';
import { queryReferralQuestionsByCompanyId } from 'src/_shared/api/graphql/custom/referral-questions/';
import { getUserById } from 'src/_shared/api/graphql/custom/users/';
import Spinner from 'src/_shared/components/spinner/SpinnerComponent.jsx';
import { logout } from 'src/_shared/services/utils.js';
import uuid from 'uuid/v4';
import DemoBar from './Demobar.jsx';
import { ReactFormBuilder } from './ReactFormBuilder.jsx';
import * as variables from './variables.js';

class FormBuilderComponent extends Component {
	static _defaultItemOptions(element) {
		switch (element) {
			case 'Dropdown': {
				return [
					{
						value: 'place_holder_option_1',
						text: 'Place holder option 1',
						key: `dropdown_option_${uuid()}`,
					},
					{
						value: 'place_holder_option_2',
						text: 'Place holder option 2',
						key: `dropdown_option_${uuid()}`,
					},
					{
						value: 'place_holder_option_3',
						text: 'Place holder option 3',
						key: `dropdown_option_${uuid()}`,
					},
				];
			}

			default: {
				return [];
			}
		}
	}

	constructor(props) {
		super(props);
		this.state = {
			theme: JSON.parse(get(props, 'currentUser.company.theme', '{}')),
			referralQuestionsData: [],
			allQuestionsData: [],
			isReferral: true,
			isAcceptance: false,
			isAttach: false,
			formStatus: 'Referral',
			isAttachAcceptance: false,
			attachRequired: false,
			attachAcceptanceRequired: false,
			isInterested: false,
			isGeneral: false,
			isAttachInterested: false,
			isAttachInterestedEmail: false,
			attachInterestedRequired: false,
			isAttachGeneral: false,
			attachGeneralRequired: false,
			toolbarItems: [
				{
					key: 'Label',
					name: 'Label',
					static: true,
					icon: 'fas fa-font',
					content: 'Placeholder Text...',
				},
				{
					key: 'LineBreak',
					name: 'Line Break',
					static: true,
					icon: 'fas fa-arrows-alt-h',
				},
				{
					key: 'Dropdown',
					canHaveAnswer: true,
					name: 'Dropdown',
					icon: 'far fa-caret-square-down',
					label: 'Placeholder Label',
					field_name: 'dropdown_',
					options: [],
				},
				{
					key: 'TextInput',
					canHaveAnswer: true,
					name: 'Text Input',
					label: 'Placeholder Label',
					icon: 'fas fa-font',
					field_name: 'text_input_',
				},

				{
					key: 'TextArea',
					canHaveAnswer: true,
					name: 'Multi-line Input',
					label: 'Placeholder Label',
					icon: 'fas fa-text-height',
					field_name: 'text_area_',
				},
			],
		};
	}

	async componentDidMount() {
		await this.getUserDataById();
		const jwt = Cookies.get('jwt');
		const { resultData } = this.state;
		if (resultData !== '' && resultData !== undefined) {
			const expirationDoneByToken = get(
				resultData,
				'expirationDoneByToken',
				null
			);
			const expires = get(resultData, 'expires');
			if (jwt !== undefined && jwt !== expirationDoneByToken && expires) {
				logout(this.props.client);
			}
		}

		this.getReferralQuestions();
	}

	async getReferralQuestions(
		isCandidate = false,
		isInterested = false,
		isGeneral = false,
		policy = 'network-only',
		nextToken = null,
		allQuestions = []
	) {
		const { client, currentUser } = this.props;
		try {
			this.setState({
				referralQuestionsData: [],
			});
			const { data } = await client.query({
				query: gql(queryReferralQuestionsByCompanyId),
				variables: {
					companyId: get(currentUser, 'companyId'),
					after: nextToken,
				},
				fetchPolicy: policy,
			});
			const questions = [...data.queryReferralQuestionsByCompanyId.items];
			const token = get(
				data,
				'queryJobsByCompanyIdDateIndex.nextToken',
				null
			);
			allQuestions = [...allQuestions, ...questions];
			const allQuestionsSort = _.sortBy(allQuestions, ['sortOrder']);
			let allQuestionsForReferralForm = '';
			if (isInterested) {
				allQuestionsForReferralForm = allQuestionsSort.filter(
					(item) => item.isInterested === isInterested
				);
			} else if (isGeneral) {
				allQuestionsForReferralForm = allQuestionsSort.filter(
					(item) => item.isGeneral === isGeneral
				);
			} else {
				allQuestionsForReferralForm = allQuestionsSort.filter(
					(item) =>
						item.isCandidate === isCandidate &&
						item.isInterested !== true &&
						item.isGeneral !== true &&
						!item?.subCompanyId
				);
			}

			const questionsData = [];
			const attachmentDataReferral = allQuestionsForReferralForm.find(
				(item) =>
					item.sortOrder === 0 &&
					item.isCandidate === false &&
					item.isInterested !== true &&
					item.isGeneral !== true
			);
			const attachmentDataAcceptance = allQuestionsForReferralForm.find(
				(item) =>
					item.sortOrder === 0 &&
					item.isCandidate === true &&
					item.isInterested !== true &&
					item.isGeneral !== true
			);
			const attachmentDataInterested = allQuestionsForReferralForm.find(
				(item) => item.sortOrder === 0 && item.isInterested === true
			);
			const attachmentDataGeneral = allQuestionsForReferralForm.find(
				(item) => item.sortOrder === 0 && item.isGeneral === true
			);
			let attachQuestionReferral = '';
			let attachQuestionAcceptance = '';
			let attachQuestionInterested = '';
			let attachQuestionGeneral = '';
			if (
				attachmentDataReferral !== undefined &&
				Object.keys(attachmentDataReferral).length > 0
			) {
				attachQuestionReferral = JSON.parse(
					attachmentDataReferral.questions
				);
				for (var key in attachQuestionReferral) {
					if (attachQuestionReferral.hasOwnProperty(key)) {
						if (key === 'Resume required') {
							this.setState({
								attachRequired: attachQuestionReferral[key],
							});
						} else {
							this.setState({
								isAttach: attachQuestionReferral[key],
							});
						}
					}
				}
			}

			if (
				attachmentDataAcceptance !== undefined &&
				Object.keys(attachmentDataAcceptance).length > 0
			) {
				attachQuestionAcceptance = JSON.parse(
					attachmentDataAcceptance.questions
				);
				for (var key in attachQuestionAcceptance) {
					if (attachQuestionAcceptance.hasOwnProperty(key)) {
						if (key === 'Resume required') {
							this.setState({
								attachAcceptanceRequired:
									attachQuestionAcceptance[key],
							});
						} else {
							this.setState({
								isAttachAcceptance:
									attachQuestionAcceptance[key],
							});
						}
					}
				}
			}

			if (
				attachmentDataInterested !== undefined &&
				Object.keys(attachmentDataInterested).length > 0
			) {
				attachQuestionInterested = JSON.parse(
					attachmentDataInterested.questions
				);
				for (var key in attachQuestionInterested) {
					if (attachQuestionInterested.hasOwnProperty(key)) {
						switch (key) {
							case 'Resume required': {
								this.setState({
									attachInterestedRequired:
										attachQuestionInterested[key],
								});

								break;
							}

							case 'Ask for resume attachment': {
								this.setState({
									isAttachInterested:
										attachQuestionInterested[key],
								});

								break;
							}

							case "Show User's Email Address": {
								this.setState({
									isAttachInterestedEmail:
										attachQuestionInterested[key],
								});

								break;
							}
							// No default
						}
					}
				}
			}

			// AttachmentDataGeneral
			if (
				attachmentDataGeneral !== undefined &&
				Object.keys(attachmentDataGeneral).length > 0
			) {
				attachQuestionGeneral = JSON.parse(
					attachmentDataGeneral.questions
				);
				for (var key in attachQuestionGeneral) {
					if (attachQuestionGeneral.hasOwnProperty(key)) {
						if (key === 'Resume required') {
							this.setState({
								attachGeneralRequired:
									attachQuestionGeneral[key],
							});
						} else {
							this.setState({
								isAttachGeneral: attachQuestionGeneral[key],
							});
						}
					}
				}
			}

			allQuestionsForReferralForm = allQuestionsForReferralForm.filter(
				(item) => item.sortOrder !== 0
			);
			if (allQuestionsForReferralForm.length > 0) {
				allQuestionsForReferralForm.map((element) => {
					questionsData.push(JSON.parse(element.questions));
				});
			}

			this.setState({
				referralQuestionsData: questionsData,
				allQuestionsData: allQuestionsSort,
			});
			if (token) {
				this.getReferralQuestions(
					isCandidate,
					isInterested,
					isGeneral,
					policy,
					token,
					allQuestions
				);
			}
		} catch (error) {
			console.log(error);
		}
	}

	async getUserDataById(policy = 'network-only') {
		const { client, currentUser } = this.props;
		try {
			const userId = get(currentUser, 'id', null);
			if (userId !== null) {
				const { data } = await client.query({
					query: gql(getUserById),
					variables: {
						id: userId,
					},
					fetchPolicy: policy,
				});
				const result = get(data, 'getUser', null);
				this.setState({
					resultData: result,
				});
			}
		} catch (error) {
			console.log(error);
		}
	}

	handleAcceptanceForm = () => {
		const { toolbarItems, isAcceptance } = this.state;

		this.setState(
			{
				isReferral: false,
				isAcceptance: true,
				isInterested: false,
				isGeneral: false,
			},
			() => {
				const newObject = {
					key: 'TextInput',
					canHaveAnswer: true,
					name: 'Phone Number',
					label: 'Phone Number',
					icon: 'fas fa-phone',
					field_name: 'text_input_',
				};
				toolbarItems.push(newObject);

				this.setState({
					toolbarItems,
				});
			}
		);

		this.getReferralQuestions(true, false, false);
	};

	handleAttachChange = (e) => {
		if (e.target.checked) {
			if (
				this.state.isReferral &&
				!this.state.isAcceptance &&
				!this.state.isInterested &&
				!this.state.isGeneral
			) {
				this.setState({
					isAttach: true,
					isAttachAcceptance: false,
					isAttachInterested: false,
					isAttachGeneral: false,
				});
			} else if (
				!this.state.isReferral &&
				this.state.isAcceptance &&
				!this.state.isInterested &&
				!this.state.isGeneral
			) {
				this.setState({
					isAttach: false,
					isAttachAcceptance: true,
					isAttachInterested: false,
					isAttachGeneral: false,
				});
			} else if (
				!this.state.isReferral &&
				!this.state.isAcceptance &&
				this.state.isInterested &&
				!this.state.isGeneral
			) {
				this.setState({
					isAttach: false,
					isAttachAcceptance: false,
					isAttachInterested: true,
					isAttachGeneral: false,
				});
			} else if (
				!this.state.isReferral &&
				!this.state.isAcceptance &&
				!this.state.isInterested &&
				this.state.isGeneral
			) {
				this.setState({
					isAttach: false,
					isAttachAcceptance: false,
					isAttachInterested: false,
					isAttachGeneral: true,
				});
			}
		} else {
			this.setState({
				isAttach: false,
				isAttachAcceptance: false,
				isAttachInterested: false,
				isAttachGeneral: false,
			});
		}
	};

	handleGeneralForm = () => {
		const { toolbarItems, isAcceptance } = this.state;

		this.setState({
			isReferral: false,
			isAcceptance: false,
			isInterested: false,
			isGeneral: true,
		});

		const phoneIndex = toolbarItems.findIndex(
			(x) => x.name == 'Phone Number'
		);
		if (phoneIndex > 0) {
			toolbarItems.splice(phoneIndex, 1);
		}

		this.setState({
			toolbarItems,
		});
		this.getReferralQuestions(false, false, true);
	};

	handleInterestedChange = (e) => {
		if (e.target.checked) {
			if (
				!this.state.isReferral &&
				!this.state.isAcceptance &&
				this.state.isInterested
			) {
				this.setState({
					isAttach: false,
					isAttachAcceptance: false,
					isAttachInterested: true,
				});
			}
		} else {
			this.setState({
				isAttach: false,
				isAttachAcceptance: false,
				isAttachInterested: false,
			});
		}
	};

	handleInterestedEmailChange = (e) => {
		this.setState({
			isAttachInterestedEmail: e.target.checked,
		});
	};

	handleInterestedForm = () => {
		const { toolbarItems, isAcceptance } = this.state;

		this.setState({
			isReferral: false,
			isAcceptance: false,
			isInterested: true,
			isGeneral: false,
		});

		const phoneIndex = toolbarItems.findIndex(
			(x) => x.name == 'Phone Number'
		);
		if (phoneIndex > 0) {
			toolbarItems.splice(phoneIndex, 1);
		}

		this.setState({
			toolbarItems,
		});
		this.getReferralQuestions(false, true, false);
	};

	handleInterestedQuesChange = (e) => {
		if (e.target.checked) {
			this.setState({
				isAttachInterestedQues: true,
			});
		} else {
			this.setState({
				isAttachInterestedQues: false,
			});
		}
	};

	handleReferralForm = () => {
		this.setState({
			isReferral: true,
			isAcceptance: false,
			isInterested: false,
			isGeneral: false,
		});
		const { toolbarItems } = this.state;
		const phoneIndex = toolbarItems.findIndex(
			(x) => x.name == 'Phone Number'
		);
		if (phoneIndex > 0) {
			toolbarItems.splice(phoneIndex, 1);
		}

		this.setState({
			toolbarItems,
		});

		this.getReferralQuestions(false, false, false);
	};

	render() {
		const {
			theme,
			referralQuestionsData,
			isReferral,
			isAcceptance,
			isInterested,
			isGeneral,
		} = this.state;
		const { onAddQuestion, currentUser, onDeleteQuestion } = this.props;
		if (!get(currentUser, 'company')) return <Spinner />;

		const confirmCompliance = get(currentUser, 'company.confirmCompliance');
		return (
			<main>
				<div className="page-title">
					<h2 className="page-heading">Referral Form Questions</h2>
				</div>
				<div className="send-msg-card">
					<div className="msg-tab-btnGrp">
						<Button
							className={`msg-tab-btn ${isReferral ? 'active' : ''}`}
							onClick={this.handleReferralForm}
						>
							Referral Form
						</Button>
						<Button
							className={`msg-tab-btn ${isAcceptance ? 'active' : ''}`}
							onClick={this.handleAcceptanceForm}
						>
							Acceptance Form
						</Button>
						<Button
							className={`msg-tab-btn ${isInterested ? 'active' : ''}`}
							onClick={this.handleInterestedForm}
						>
							I'm Interested
						</Button>
						<Button
							className={`msg-tab-btn ${isGeneral ? 'active' : ''}`}
							onClick={this.handleGeneralForm}
						>
							General/Lead Form
						</Button>
					</div>
					<div className="sm-left sm-left-form-builder">
						<h4 className="sm-heading">
							{isReferral &&
								'Add custom questions for your employees to answer when making a referral.'}
							{isAcceptance &&
								'Add custom questions to Acceptance Form for referrals to answer when accepting a referral.'}
							{isInterested &&
								'Add custom questions to the Self Referral Form for applicants to answer when completing their self-referral.'}
							{isGeneral &&
								'Add custom questions that referrals will answer on either the General Referral or Lead form.'}
						</h4>

						<DemoBar
							variables={variables}
							currentUser={currentUser}
							isReferral={isReferral}
							isAcceptance={isAcceptance}
							checked={this.state.isAttach}
							checkedAcceptance={this.state.isAttachAcceptance}
							checkedRequired={this.state.attachRequired}
							checkedAcceptanceRequired={
								this.state.attachAcceptanceRequired
							}
							isInterested={isInterested}
							checkedInterested={this.state.isAttachInterested}
							checkedInterestedEmail={
								this.state.isAttachInterestedEmail
							}
							checkedInterestedRequired={
								this.state.attachInterestedRequired
							}
							checkedInterestedQues={
								this.state.isAttachInterestedQues
							}
							checkedInterestedQuesRequired={
								this.state.attachInterestedQuesRequired
							}
							handleInterestedEmailChange={
								this.handleInterestedEmailChange
							}
							isGeneral={isGeneral}
							checkedGeneral={this.state.isAttachGeneral}
							checkedGeneralRequired={
								this.state.attachGeneralRequired
							}
							onAddQuestion={onAddQuestion}
							onDeleteQuestion={onDeleteQuestion}
							onCheckChange={this.handleAttachChange}
							onCheckQuesChange={this.handleInterestedQuesChange}
						/>
						<ReactFormBuilder
							toolbarItems={this.state.toolbarItems}
							data={referralQuestionsData}
						/>
					</div>
					<div className="sm-right sm-right-form-builder">
						{isReferral &&
						!isAcceptance &&
						!isInterested &&
						!isGeneral ? (
							<>
								{confirmCompliance ? (
									<Tooltip
										placement="top"
										title="Do not allow a resume to be uploaded"
									>
										<div className="custom-form-group custom-checkbox left-check">
											<input
												disabled
												id="resumeAttachment4"
												type="checkbox"
												checked={this.state.isAttach}
												onChange={
													this.handleAttachChange
												}
											/>
											<label htmlFor="resumeAttachment4">
												Ask for resume attachment
											</label>
										</div>
									</Tooltip>
								) : (
									<div className="custom-form-group custom-checkbox left-check">
										<input
											id="resumeAttachment3"
											type="checkbox"
											checked={this.state.isAttach}
											onChange={this.handleAttachChange}
										/>
										<label htmlFor="resumeAttachment3">
											Ask for resume attachment
										</label>
									</div>
								)}
								{this.state.isAttach && (
									<div className="custom-form-group custom-checkbox left-check">
										<input
											id="requireResume3"
											type="checkbox"
											checked={this.state.attachRequired}
											onChange={() =>
												this.setState({
													attachRequired:
														!this.state
															.attachRequired,
												})
											}
										/>
										<label htmlFor="requireResume3">
											Require resume
										</label>
									</div>
								)}
							</>
						) : !isReferral &&
						  isAcceptance &&
						  !isInterested &&
						  !isGeneral ? (
							<>
								<div className="custom-form-group custom-checkbox left-check">
									<input
										id="resumeAttachment"
										type="checkbox"
										checked={this.state.isAttachAcceptance}
										onChange={this.handleAttachChange}
									/>
									<label htmlFor="resumeAttachment">
										Ask for resume attachment
									</label>
								</div>
								{this.state.isAttachAcceptance && (
									<div className="custom-form-group custom-checkbox left-check">
										<input
											id="requireResume"
											type="checkbox"
											checked={
												this.state
													.attachAcceptanceRequired
											}
											onChange={() =>
												this.setState({
													attachAcceptanceRequired:
														!this.state
															.attachAcceptanceRequired,
												})
											}
										/>
										<label htmlFor="requireResume">
											Require resume
										</label>
									</div>
								)}
							</>
						) : !isReferral &&
						  !isAcceptance &&
						  isInterested &&
						  !isGeneral ? (
							<>
								<div className="custom-form-group custom-checkbox left-check">
									<input
										id="resumeAttachment1"
										type="checkbox"
										checked={this.state.isAttachInterested}
										onChange={this.handleAttachChange}
									/>
									<label htmlFor="resumeAttachment1">
										Ask for resume attachment
									</label>
								</div>
								{this.state.isAttachInterested && (
									<div className="custom-form-group custom-checkbox left-check">
										<input
											id="requireResume1"
											type="checkbox"
											checked={
												this.state
													.attachInterestedRequired
											}
											onChange={() =>
												this.setState({
													attachInterestedRequired:
														!this.state
															.attachInterestedRequired,
												})
											}
										/>
										<label htmlFor="requireResume1">
											Require resume
										</label>
									</div>
								)}
								<div className="custom-form-group custom-checkbox left-check">
									<input
										id="userEmailAddress"
										type="checkbox"
										checked={
											this.state.isAttachInterestedEmail
										}
										onChange={
											this.handleInterestedEmailChange
										}
									/>
									<label htmlFor="userEmailAddress">
										Show User's Email Address
									</label>
								</div>
							</>
						) : (
							<>
								<div className="custom-form-group custom-checkbox left-check">
									<input
										id="resumeAttachment2"
										type="checkbox"
										checked={this.state.isAttachGeneral}
										onChange={this.handleAttachChange}
									/>
									<label htmlFor="resumeAttachment2">
										Ask for resume attachment
									</label>
								</div>
								{this.state.isAttachGeneral && (
									<div className="custom-form-group custom-checkbox left-check">
										<input
											id="eequireResume2"
											type="checkbox"
											checked={
												this.state.attachGeneralRequired
											}
											onChange={() =>
												this.setState({
													attachGeneralRequired:
														!this.state
															.attachGeneralRequired,
												})
											}
										/>
										<label htmlFor="eequireResume2">
											Require resume
										</label>
									</div>
								)}
							</>
						)}
					</div>
				</div>
			</main>
		);
	}
}

export default withApollo(FormBuilderComponent);
