import { css } from 'emotion';
import get from 'lodash/get';
import { COLORS } from '../_shared/styles/colors';

const breakpoints = [576, 767, 991, 1200];

const mq = breakpoints.map((bp) => `@media (max-width: ${bp}px)`);

export const messageCenterContainer = css({
	width: '100%',
	height: '100%',
	padding: 20,
	backgroundColor: COLORS.lightGray2,
	[mq[2]]: {
		padding: '10px 5px',
	},
});
export const tableHeaderContainer = css({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	maxWidth: '100%',
	position: 'relative',
});

export const tableHeader = css({
	fontFamily: '"Open Sans", sans-serif !important',
	fontWeight: '600 !important',
});

export const tableContainer = css({
	borderRadius: 10,
	backgroundColor: 'white',
	padding: 30,
	height: 'auto',
	'& button:focus': {
		outline: 'none',
	},
	[mq[2]]: {
		padding: 8,
	},
});

export const FormItemContainer = css({
	display: 'flex',
	'& label': {
		display: 'block',
		fontSize: 18,
		color: COLORS.lightGray,
		lineHeight: '40px',
		marginRight: 10,
	},
});

export const filterContainer = css({
	width: 'auto',
	display: 'flex',
	flexWrap: 'wrap',
	[mq[1]]: {
		flexDirection: 'column',
	},
});

export const DropDown = css({
	fontFamily: '"Open Sans", sans-serif',
});

export const SelectContainer = css({
	display: 'flex',
	height: 40,
});

export const iconContainer = css({
	'& svg': {
		transform: 'translate(-40px, 8px)',
	},
});

export const SelectStyles = css({
	width: 225,
	'& .ant-select-selection__choice': {
		backgroundColor: COLORS.lightGreen,
		color: COLORS.green,
	},
	'& .ant-select-selection--single': {
		height: 40,
		paddingTop: 4,
	},
});

export const LabelStyles = css({
	verticalAlign: 'middle',
	fontFamily: 'Open Sans,sans-serif',
	fontWeight: 600,
	color: COLORS.black,
});

export const SelectedEmployeesContainer = css({
	display: 'table',
});

export const SelectedEmployees = css({
	textAlign: 'center',
	width: '150px',
	backgroundColor: COLORS.dashboardGreen,
	borderRadius: 10,
	height: 100,
	position: 'relative',
	overflow: 'hidden',
});

export const SelectedEmployeesTextContiner = css({
	top: '20%',
	position: 'relative',
});

export const EmployeeCountClass = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 600,
	color: COLORS.green,
	fontSize: '1.50em',
	width: '100%',
});

export const SelectedEmployeesTextClass = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 600,
	color: COLORS.white,
	fontSize: '1.0em',
	width: '100%',
});

export const LabelClass = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 600,
	color: '#444444',
	fontSize: '1.5em',
});

export const DividerClass = css({
	'& .ant-divider-horizontal': {
		margin: '15px 0px !important',
	},
});

export const RTEContainer = css({
	display: 'flex',
	height: 215,
	flex: '1 1 auto',
});

export const SubmitBtnContainer = css({
	width: '100%',
	display: 'flex',
	justifyContent: 'center',
	marginBottom: 0,
	'& .ant-form-item-control-wrapper': {
		'@media (max-width: 575px)': {
			width: 'auto',
		},
	},
});

export const BtnContainer = (theme) => {
	let buttonColor = COLORS.blue;
	if (get(theme, 'enabled')) {
		buttonColor = get(theme, 'buttonColor', buttonColor);
	}

	return css({
		overflow: 'hidden',
		'& .ant-btn: hover': {
			color: buttonColor,
			borderColor: buttonColor,
		},
		'& button:hover > div > svg > path': {
			fill: buttonColor,
		},
		'& button:focus > div > svg > path': {
			fill: buttonColor,
		},
		width: '100%',
		'& .ant-btn:hover': {
			backgroundColor: `${buttonColor} !important`,
			border: `2px solid ${buttonColor} !important`,
			color: '#fff !important',
		},
		'& .ant-btn:focus': {
			backgroundColor: `${buttonColor} !important`,
			border: `2px solid ${buttonColor} !important`,
			color: '#fff !important',
		},
	});
};

export const RippleButton = css({
	display: 'inline !important',
	overflow: 'hidden',
	[mq[1]]: {
		width: '100%',
	},
});

export const SendTestEmailButton = css({
	width: 180,
	height: 40,
	background: COLORS.white,
	color: COLORS.gray,
	fontSize: 14,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	borderRadius: '10px',
	border: '1.5px solid #8d99a3',
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 200,
	lineHeight: '25px',
	'& .ant-btn: hover': {
		color: COLORS.gray,
		borderColor: COLORS.gray,
	},
	'& .ant-btn: focus': {
		color: COLORS.gray,
		borderColor: COLORS.gray,
	},
	[mq[1]]: {
		width: '100%',
	},
});

export const SendEmailEmployees = css({
	width: 180,
	height: 40,
	background: '#018dd3',
	color: 'white',
	fontSize: 14,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	borderRadius: '10px',
	border: '2px solid #018dd3',
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 200,
	lineHeight: '25px',
	[mq[1]]: {
		width: '100%',
	},
});

export const SelectStylesJob = css({
	width: 225,
	'& .ant-select-selection__choice': {
		backgroundColor: COLORS.lightGreen,
		color: COLORS.green,
	},
	'& .ant-select-selection--single': {
		height: 40,
		paddingTop: 4,
	},
	'& .ant-select-arrow': {
		display: 'none',
	},
});

export const AlertError = css({
	marginBottom: 20,
});

export const PopoverStyle = css({
	'& .ant-popover-placement-topLeft': {
		width: 290,
		left: 424,
		top: 644,
	},
	'& .ant-popover-inner-content': {
		borderColor: 'rgba(0, 0, 0, 0.75)',
		borderRadius: 4,
		color: 'white',
		backgroundColor: 'rgba(0, 0, 0, 0.75)',
		padding: 5,
	},
	'& .ant-popover-arrow': {
		backgroundColor: 'rgba(0, 0, 0, 0.75)',
		borderColor: 'rgba(0, 0, 0, 0.75)',
		border: '0px solid',
		zIndex: -1,
	},
});

export const fieldErrors = css({
	width: 'auto',
	marginLeft: '5%',
});

export const errorText = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 200,
	color: COLORS.red,
	fontSize: '0.85em',
	left: -30,
});

export const errorSelect = css({
	borderColor: COLORS.red,
});

export const subjectInput = css({
	flex: '1 1 auto',
});

export const changeDirection = css({
	[mq[1]]: {
		flexDirection: 'column',
	},
});

export const bottomButton = css({
	width: '100%',
	paddingTop: 40,
	paddingLeft: 90,
	paddingBottom: 40,
	[mq[1]]: {
		paddingLeft: 0,
	},
});

export const btnLeft = css({
	float: 'left',
	[mq[1]]: {
		float: 'none',
	},
});

export const btnRight = css({
	float: 'right',
	[mq[1]]: {
		float: 'none',
		marginTop: 10,
	},
});

export const SaveEnabled = (theme) => {
	let buttonColor = COLORS.blue;
	if (get(theme, 'enabled')) {
		buttonColor = get(theme, 'buttonColor', buttonColor);
	}

	return css({
		fontFamily: '"Open Sans", sans-serif',
		textAlign: 'center',
		fontWeight: 200,
		color: `${COLORS.white} !important`,
		background: `${buttonColor} !important`,
		margin: 5,
		padding: 8,
		fontSize: 14,
		width: 100,
		height: 40,
		borderRadius: '10px',
		border: `2px solid ${buttonColor}`,
		'&:hover': {
			color: buttonColor,
			borderColor: buttonColor,
		},
		'&:focus': {
			color: buttonColor,
			borderColor: buttonColor,
		},
		cursor: 'pointer',
	});
};

export const SaveDisabled = (theme) => {
	const buttonColor = COLORS.gray3;
	return css({
		fontFamily: '"Open Sans", sans-serif',
		textAlign: 'center',
		fontWeight: 200,
		color: `${COLORS.white} !important`,
		background: `${buttonColor} !important`,
		margin: 5,
		padding: 8,
		fontSize: 14,
		width: 100,
		height: 40,
		borderRadius: '10px',
		border: `2px solid ${buttonColor}`,
		'&:hover': {
			color: buttonColor,
			borderColor: buttonColor,
		},
		'&:focus': {
			color: buttonColor,
			borderColor: buttonColor,
		},
		cursor: 'not-allowed',
	});
};

export const cursorAllow = css({
	cursor: 'pointer',
	width: 100,
	marginBottom: 5,
});

export const cursorNotAllow = css({
	cursor: 'not-allowed',
	width: 100,
	marginBottom: 5,
});

export const ActiveButton = css({
	marginRight: 10,
	background: COLORS.blue,
	fontSize: 14,
	borderRadius: 10,
	border: '2px solid #018dd3',
	fontFamily: '"Open Sans", sans-serif',
	height: 40,
	color: COLORS.white,
});

export const InactiveButton = css({
	marginRight: 10,
	background: COLORS.gray,
	fontSize: 14,
	borderRadius: 10,
	border: '2px solid #888888',
	fontFamily: '"Open Sans", sans-serif',
	height: 40,
	color: COLORS.white,
});

export const MinimalReferral = (selected) =>
	css({
		margin: 5,
		marginTop: 8,
		backgroundColor: selected ? COLORS.lightGray5 : COLORS.white,
		color: selected ? COLORS.white : COLORS.subHeading,
		'&:hover': {
			backgroundColor: COLORS.lightGray5,
			color: COLORS.white,
			borderColor: 'transparent',
		},
		'&:focus': {
			backgroundColor: COLORS.lightGray5,
			color: COLORS.white,
			borderColor: 'transparent',
		},
		height: 30,
		width: 120,
	});

export const MinimalAcceptance = (selected) =>
	css({
		margin: 5,
		marginTop: 8,
		backgroundColor: selected ? COLORS.lightGray5 : COLORS.white,
		color: selected ? COLORS.white : COLORS.subHeading,
		'&:hover': {
			backgroundColor: COLORS.lightGray5,
			color: COLORS.white,
			borderColor: 'transparent',
		},
		'&:focus': {
			backgroundColor: COLORS.lightGray5,
			color: COLORS.white,
			borderColor: 'transparent',
		},
		height: 30,
		width: 150,
	});

export const MinimalGeneral = (selected) =>
	css({
		margin: 5,
		marginTop: 8,
		backgroundColor: selected ? COLORS.lightGray5 : COLORS.white,
		color: selected ? COLORS.white : COLORS.subHeading,
		'&:hover': {
			backgroundColor: COLORS.lightGray5,
			color: COLORS.white,
			borderColor: 'transparent',
		},
		'&:focus': {
			backgroundColor: COLORS.lightGray5,
			color: COLORS.white,
			borderColor: 'transparent',
		},
		height: 30,
		width: 180,
	});

export const disableDiv = css({
	pointerEvents: 'none',
	opacity: '0.7',
});
