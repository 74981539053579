import gql from 'graphql-tag';
import get from 'lodash/get';
import { compose, graphql } from 'react-apollo';
import { queryMyBonusesByUserIdIndex as queryBonusByUserIdIndex } from '../../graphql/custom/bonuses';

export const withQueryMyBonusesByUserId = (Component) => {
	return compose(
		graphql(gql(queryBonusByUserIdIndex), {
			options(props) {
				return {
					variables: {
						userId: get(props, 'id', get(props, 'currentUser.id')),
					},
					fetchPolicy: 'cache-and-network',
				};
			},
			props(response, previous) {
				const referralBonuses = get(
					response,
					['data', 'queryBonusByUserIdIndex', 'items'],
					get(previous, 'referralBonuses', [])
				);

				const referralBonusNextToken = get(
					response,
					['data', 'queryBonusByUserIdIndex', 'nextToken'],
					null
				);
				const onFetchMoreReferralBonuses = makeOnFetchMoreReferralBonuses(
					response.data.fetchMore,
					referralBonusNextToken
				);

				return {
					referralBonuses,
					onFetchMoreReferralBonuses,
					referralBonusNextToken,
				};
			},
		})
	)(Component);
};

const makeOnFetchMoreReferralBonuses = (fetchMore, nextToken) => {
	if (!nextToken) {
		return null;
	}

	return () => {
		fetchMore({
			variables: { nextToken },
			updateQuery(previous, { fetchMoreResult }) {
				if (!fetchMoreResult) {
					return previous;
				}

				return {
					...previous,
					loading: false,
					queryBonusByUserIdIndex: {
						...previous.queryBonusByUserIdIndex,
						...fetchMoreResult.queryBonusByUserIdIndex,
						items: [
							...previous.queryBonusByUserIdIndex.items,
							...fetchMoreResult.queryBonusByUserIdIndex.items,
						],
					},
				};
			},
		});
	};
};
