export const MANAGE_REWARDS_API_ENDPOINTS = {
  GET_BADGES: 'api/v1/icons',
  GET_BADGE_TYPES: 'api/v1/badge/types',
  CREATE_BADGE: 'api/v1/badges',
  GET_EMPLOYEE_LEVELS: 'api/v1/employees/level',
  GET_EMPLOYEE_PAY_TYPE: 'api/v1/employees/pay-type',
  GET_BADGE_DETAILS: 'api/v1/badges/',
  UPDATE_BADGE: 'api/v1/badges/',
  GET_BADGES_LIST: 'api/v1/badges',
  GET_AUTOMATION_RULE: 'api/v1/badges/automationrule'
}