import gql from 'graphql-tag';
import get from 'lodash/get';
import { compose, graphql } from 'react-apollo';
import { GetCompanySettings } from 'src/settings-dashboard/custom/company';
import { createCompany, createUser } from '../../graphql/default';

export const withSAMLPartner = (Component, variables) => {
	return compose(
		graphql(GetCompanySettings, {
			options: {
				context: {
					headers: {
						'x-frame-options': 'deny', // This header will reach the server
					},
				},
				variables,
				fetchPolicy: 'cache-and-network',
			},
			props(response, previous) {
				const partnerHostCompany = get(response, 'data.getCompany');
				return { partnerHostCompany };
			},
		}),
		graphql(gql(createUser), {
			props: (props) => ({
				onCreateUser(input) {
					props.mutate({
						variables: input,
					});
				},
			}),
		}),
		graphql(gql(createCompany), {
			props: (props) => ({
				onCreateCompany(input) {
					return props.mutate({
						variables: input,
					});
				},
			}),
		})
	)(Component);
};
