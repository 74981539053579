import { Input } from 'antd';
import Spinner from 'src/_shared/components/spinner/SpinnerComponent.jsx';
import ReferralTiers from './ReferralTiersComponent.jsx';

export function TieredBonus(props) {
	const {
		getFieldDecorator,
		bonusId,
		name,
		tiers,
		onChange,
		submitting,
		daysText,
		addBonusText,
		bonusBuilderText,
		bonusNameText,
		bonusPaymentsText,
		deleteBonusText,
		afterText,
		addGroupBonusText,
		employeeGroupText,
		chooseEmployeeGroupText,
		daysFromHireDateText,
		bonusAmountText,
		candidateText,
		employeeText,
		deletePaymentText,
		groupText,
		theme,
		company,
	} = props;

	return submitting ? (
		<Spinner company={company} />
	) : (
		<div className="bs-form">
			<div className="custom-form-group">
				<h6 className="bs-form-label">
					<span className="required">*</span> {bonusNameText}
				</h6>
				{getFieldDecorator(`tieredBonus-${bonusId}`, {
					rules: [{ required: true, message: 'Enter bonus name' }],
					initialValue: name,
				})(
					<Input
						className="custom-input"
						placeholder="ex. Level One"
						onChange={onChange}
					/>
				)}
			</div>
			<ReferralTiers
				theme={theme}
				tierKeyBase={bonusId}
				getFieldDecorator={getFieldDecorator}
				currentTiers={tiers}
				userGroups={props.userGroups}
				userGroup={props.userGroup}
				updateExclusions={props.updateExclusions}
				updateInclusions={props.updateInclusions}
				daysText={daysText}
				addBonusText={addBonusText}
				bonusBuilderText={bonusBuilderText}
				bonusNameText={bonusNameText}
				bonusPaymentsText={bonusPaymentsText}
				deleteBonusText={deleteBonusText}
				afterText={afterText}
				addGroupBonusText={addGroupBonusText}
				employeeGroupText={employeeGroupText}
				chooseEmployeeGroupText={chooseEmployeeGroupText}
				daysFromHireDateText={daysFromHireDateText}
				bonusAmountText={bonusAmountText}
				candidateText={candidateText}
				employeeText={employeeText}
				deletePaymentText={deletePaymentText}
				groupText={groupText}
			/>
		</div>
	);
}
