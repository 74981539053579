import { Button } from 'antd';
import _, { get } from 'lodash';
import { Component } from 'react';
import { withApollo } from 'react-apollo';
import { ml, searchOpenSearchNetwork } from 'src/_shared/services/utils.js';
import { withQueryParams } from 'src/_shared/services/index.js';
import OnDeckReferralModal from '../../../_shared/components/on-deck/on-deck-modal/OnDeckModalComponent.jsx';
import { USER_ROLES } from '../../../_shared/constants';
import { autoHeight, fixedHeight } from './generalReferralCardStyles.js';

class GeneralReferralCard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			totalSmartReferrals: 0,
			isViewMore: false,
			toggleClassName: fixedHeight,
			visibleGeneral: false,
			filteredJobsGeneral: [],
			searchQuery: '',
			loading: true,
		};
	}

	async componentDidMount() {
		let showGeneral;
		if (this.props?.queryParams?.size > 0) {
			showGeneral = this.props.queryParams.get('showGeneral');
		}

		if (showGeneral) {
			this.setState({ visibleGeneral: true });
		}

		await this.setQueryToState(get(this.state, 'searchQuery', ''));
	}

	setQueryToState = async (searchQuery = '') => {
		const {
			filteredDepartments = [],
			filteredBonuses = [],
			filteredDistance = 0,
			filterOpenStatus = 'open',
			lat,
			lng,
			filteredSubCompanies = [],
			filteredJobLevels = [],
		} = this.state;
		const filterCompanies = get(this.state, 'filteredCompanies', []).map(
			(company) => get(company, 'id')
		);
		const extendedCompaniesData = get(
			this.props,
			'currentUser.extendedCompaniesData',
			[]
		);
		const extendedCompanies = extendedCompaniesData.filter(
			(company) => get(company, 'disableExtendedUserJobsView') !== true
		);
		const isExtendedUser =
			get(this.props, 'currentUser.role') === 'extendedUser';
		const comps =
			extendedCompanies && extendedCompanies.length > 0 && isExtendedUser
				? extendedCompanies
				: [get(this.props, 'currentUser.company')];
		const companies = comps.map((company) => get(company, 'id'));
		const filteredCompanies =
			filterCompanies.length > 0 ? filterCompanies : companies;

		const unit =
			get(this.props, 'currentUser.userGroup.measurement', 'imperial') ===
			'metric'
				? 'km'
				: 'mi';
		clearTimeout(this.timer);
		this.setState({ searchQuery });
		this.timer = setTimeout(async () => {
			this.setState({ loading: true });
			const parameters = {
				query: get(this.state, 'searchQuery', ''),
				size: 300,
				coordinates: [{ lat, lng }],
				role: get(this.props, 'currentUser.role'),
				currentUser: get(this.props, 'currentUser.id'),
				filters: {
					bonuses: filteredBonuses,
					companies: filteredCompanies,
					departments: filteredDepartments,
					distance: filteredDistance,
					status: filterOpenStatus,
					subCompanies: filteredSubCompanies,
					jobLevels: filteredJobLevels,
				},
				unit,
			};
			const response = await searchOpenSearchNetwork(parameters, 'erin-jobs');
			if (get(response, 'query') === get(this.state, 'searchQuery')) {
				let searchedJobs = get(response, 'data', []);
				const filterOn =
					(filteredDistance.length > 0 && filteredDistance != '0') ||
					searchQuery.length > 0;

				searchedJobs = filterOn
					? searchedJobs || []
					: _.orderBy(
							searchedJobs || [],
							['isHotJob', 'dateCreated'],
							['desc', 'desc']
						);
				if (searchedJobs) {
					const filteredJobsGeneral = searchedJobs.filter(
						(item) => item.isGeneralReferral === true
					);
					this.setState({
						loading: false,
						filteredJobsGeneral,
					});
				}
			}
		}, 700);
	};

	compareObjects = (object1, object2, key) => {
		const object1_ = object1[key].toUpperCase();
		const object2_ = object2[key].toUpperCase();

		if (object1_ < object2_) {
			return -1;
		}

		if (object1_ > object2_) {
			return 1;
		}

		return 0;
	};

	formatNumber = (number) => {
		try {
			if (number !== null && number !== undefined) {
				return number.toString().replaceAll(/\B(?=(\d{3})+(?!\d))/g, ',');
			}

			return number;
		} catch {
			return 0;
		}
	};

	handleCancelGeneral = () => {
		this.setState({ visibleGeneral: false });
	};

	showModalGeneral = () => {
		this.setState({ visibleGeneral: true });
	};

	toggleCLass = () => {
		this.setState(
			{
				isViewMore: !this.state.isViewMore,
			},
			() => {
				this.setState({
					toggleClassName: this.state.isViewMore ? autoHeight : fixedHeight,
				});
			}
		);
	};

	render() {
		const {
			allMultiLingualData,
			currentUser,
			companyData,
			contacts,
			generalCardButton,
			customGeneralModal = {},
			onDeckContacts,
		} = this.props;
		const { visibleGeneral, filteredJobsGeneral } = this.state;
		return (
			<div className="d-card-body vh-center">
				{currentUser.displayAs === USER_ROLES.EMPLOYEE && (
					<Button
						type="button"
						className="c-btn-white"
						onClick={this.showModalGeneral}
					>
						{generalCardButton}
					</Button>
				)}
				{visibleGeneral && (
					<OnDeckReferralModal
						visible
						currentUser={this.props.currentUser}
						contact=""
						contacts={contacts}
						customGeneralModal={customGeneralModal}
						handleCancel={this.handleCancelGeneral}
						referSomeoneText={ml(
							'Refer Someone',
							currentUser,
							allMultiLingualData
						)}
						doYouKnowText={ml(
							'Do you know someone who would thrive at',
							currentUser,
							allMultiLingualData
						)}
						submitThemText={ml(
							'Submit them to be considered for new opportunities.',
							currentUser,
							allMultiLingualData
						)}
						enterReferralText={ml(
							"Enter a referral and we'll send them information to apply.",
							currentUser,
							allMultiLingualData
						)}
						enterReferralInformationText={ml(
							'Enter Referral Information',
							currentUser,
							allMultiLingualData
						)}
						firstNameText={ml('First Name', currentUser, allMultiLingualData)}
						lastNameText={ml('Last Name', currentUser, allMultiLingualData)}
						emailText={ml('Email Address', currentUser, allMultiLingualData)}
						textPlaceHolderText={ml('Text', currentUser, allMultiLingualData)}
						orText={ml('or', currentUser, allMultiLingualData)}
						clickHereText={ml('click here', currentUser, allMultiLingualData)}
						toAddExistingContactText={ml(
							'to add an existing contact',
							currentUser,
							allMultiLingualData
						)}
						includeMessageToContactText={ml(
							'Include a message to your contact',
							currentUser,
							allMultiLingualData
						)}
						optionalText={ml('optional', currentUser, allMultiLingualData)}
						personalizeMessageText={ml(
							'Personalize the message to your referral',
							currentUser,
							allMultiLingualData
						)}
						messageHiringContactText={ml(
							'Message the Hiring Contact',
							currentUser,
							allMultiLingualData
						)}
						howKnowThemText={ml(
							'How do you know them, why are they a good fit, etc.',
							currentUser,
							allMultiLingualData
						)}
						clickHereResumeText={ml(
							'Click here',
							currentUser,
							allMultiLingualData
						)}
						attachResumeText={ml(
							'to attach a resume',
							currentUser,
							allMultiLingualData
						)}
						submitReferralText={ml(
							'Submit Referral',
							currentUser,
							allMultiLingualData
						)}
						companyData={companyData}
						allMultiLingualData={allMultiLingualData}
						filteredJobs={filteredJobsGeneral}
						onDeckContacts={onDeckContacts}
					/>
				)}
			</div>
		);
	}
}

export default withApollo(withQueryParams(GeneralReferralCard));
