export const builderKeys = {
	Days: 'ml_Days',
	AddBonus: 'ml_AddBonus',
	UpdateBonuses: 'ml_UpdateBonuses',
	BonusBuilder: 'ml_BonusBuilder',
	BonusName: 'ml_BonusName',
	Bonuses: 'ml_Bonuses',
	BonusPayments: 'ml_BonusPayments',
	DeleteBonus: 'ml_DeleteBonus',
	After: 'ml_After',
	AddGroupBonus: 'ml_AddGroupBonus',
	EmployeeGroup: 'ml_EmployeeGroup',
	ChooseEmployeeGroup: 'ml_ChooseEmployeeGroup',
	DaysFromHireDate: 'ml_DaysFromHireDate',

	BonusAmount: 'ml_BonusAmount',
	Candidate: 'ml_Candidate',
	Employee: 'ml_Employee',
	DeletePayment: 'ml_DeletePayment',
	Group: 'ml_Group',
};
