import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { message } from "antd";
import { useRnRCommonService } from "../../RnRCommonComponent/service/RnRCommon.service.jsx";
import { downloadCSVFile } from "../../rewardsAndRecognitionHelper/utils.js";

const useNomineeWinnerList = (id) => {
    const history = useHistory();
    const [nomineeList, setNomineeList] = useState([])
    const [nomineeVoterList, setNomineeVoterList] = useState([])
    const [nomineeLoader, setNomineeLoader] = useState(false)
    const [nominationConfig, setNominationConfig] = useState({})
    const commonAPIService = useRnRCommonService();

    const getNomineeListById = (id) => {
        setNomineeLoader(true)
        commonAPIService.getNomineeListById(id).then((response) => {
            setNomineeList(response.data)
        }).finally(() => {
            setNomineeLoader(false)
        })
    }

    const getNomineeVoterListById = (nominationConfigurationId, nomineeId) => {
        setNomineeLoader(true)
        commonAPIService.getNomineeVoterListById(nominationConfigurationId, nomineeId).then((response) => {
            setNomineeVoterList(response.data)
        }).finally(() => {
            setNomineeLoader(false)
        })
    }

    const getNominationConfigurationById = () => {
        commonAPIService.getNominationConfigurationById(id).then((res) => {
            setNominationConfig(res.data)
        })
    }

    const selectWinner = (winnerArray) => {
        commonAPIService.selectWinner({
            payload: {
                isWinner: true
            },
            nominationIds: winnerArray,
            nominationConfigurationId: id
        }).then(() => {
            history.push('/nominations')
            message.success("Winner Selected Successfully")
        }).catch((error) => {
            message.error(error?.response?.data?.message || "Please try again")
        })
    }

    const getNominationCSV = () => {
        return commonAPIService.exportNominationCSV(id).then((responce) => {
            downloadCSVFile(responce, "Nominee")
        }).catch((error) => {
            message.error(error?.response?.data?.message || "Please try again")
        })
    }

    useEffect(() => {
        getNomineeListById(id)
        getNominationConfigurationById(id)
    }, [id])

    return ({
        getNomineeListById,
        selectWinner,
        getNomineeVoterListById,
        nomineeList,
        nominationConfig,
        loading: nomineeLoader,
        nomineeVoterList,
        getNominationCSV
    })
}

export default useNomineeWinnerList