import { Component } from 'react';
import ReactDOM from 'react-dom';

/**
 * @fileoverview react-star-rating
 * @author @cameronjroe
 * <StarRating
 *   name={string} - name for form input (required)
 *   caption={string} - caption for rating (optional)
 *   ratingAmount={number} - the rating amount (required, default: 5)
 *   rating={number} - a set rating between the rating amount (optional)
 *   disabled={boolean} - whether to disable the rating from being selected (optional)
 *   editing={boolean} - whether the rating is explicitly in editing mode (optional)
 *   size={string} - size of stars (optional)
 *   onRatingClick={function} - a handler function that gets called onClick of the rating (optional)
 *   />
 */

export default class StarRating extends Component {
	constructor(props) {
		super(props);

		this.state = {
			ratingCache: {
				pos: 0,
				rating: 0,
			},
			editing: props.editing || true,
			stars: 5,
			rating: 0,
			pos: 0,
			glyph: this.getStars(),
		};
	}

	UNSAFE_componentWillMount() {
		this.min = 0;
		this.max = this.props.ratingAmount || 5;
		if (this.props.rating) {
			this.state.editing = this.props.editing || false;
			const ratingValue = this.props.rating;
			this.state.ratingCache.pos = this.getStarRatingPosition(ratingValue);
			this.state.ratingCache.rating = ratingValue;

			this.setState({
				ratingCache: this.state.ratingCache,
				rating: ratingValue,
				pos: this.getStarRatingPosition(ratingValue),
			});
		}
	}

	componentDidMount() {
		this.root = ReactDOM.findDOMNode(this.rootNode);
		this.ratingContainer = ReactDOM.findDOMNode(this.node);
	}

	shouldComponentUpdate(nextProperties, nextState) {
		if (nextProperties !== this.props) {
			this.updateRating(
				this.getStarRatingPosition(nextProperties.rating),
				nextProperties.rating
			);
			return true;
		}

		return (
			nextState.ratingCache.rating !== this.state.ratingCache.rating ||
			nextState.rating !== this.state.rating
		);
	}

	componentWillUnmount() {
		delete this.root;
		delete this.ratingContainer;
	}

	getDecimalPlaces(number_) {
		const match = `${number_}`.match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
		return match
			? Math.max(
					0,
					(match[1] ? match[1].length : 0) - (match[2] ? Number(match[2]) : 0)
				)
			: 0;
	}

	getPosition(e) {
		return e.pageX - this.root.getBoundingClientRect().left;
	}

	getRatingEvent(e) {
		const pos = this.getPosition(e);
		return this.calculate(pos);
	}

	getStarRatingPosition(value) {
		const width = `${this.getWidthFromValue(value)}%`;
		return width;
	}

	/**
	 * Gets the stars based on ratingAmount
	 * @return {string} stars
	 */
	getStars() {
		let stars = '';
		const numberRating = this.props.ratingAmount;
		for (let i = 0; i < numberRating; i++) {
			stars += '\u2605';
		}

		return stars;
	}

	getSvg() {
		return (
			<svg
				className="react-star-rating__star"
				viewBox="0 0 286 272"
				version="1.1"
				xmlns="http://www.w3.org/2000/svg"
			>
				<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
					<polygon
						id="star-flat"
						points="143 225 54.8322122 271.352549 71.6707613 173.176275 0.341522556 103.647451 98.9161061 89.3237254 143 0 187.083894 89.3237254 285.658477 103.647451 214.329239 173.176275 231.167788 271.352549 "
					/>
				</g>
			</svg>
		);
	}

	getValueFromPosition(pos) {
		const precision = this.getDecimalPlaces(this.props.step);
		const maxWidth = this.ratingContainer.offsetWidth;
		const diff = this.max - this.min;
		let factor = (diff * pos) / (maxWidth * this.props.step);
		factor = Math.ceil(factor);
		let value = this.applyPrecision(
			Number.parseFloat(this.min + factor * this.props.step),
			precision
		);
		value = Math.max(Math.min(value, this.max), this.min);
		return value;
	}

	getWidthFromValue(value) {
		const { min } = this;
		const { max } = this;

		if (value <= min || min === max) {
			return 0;
		}

		if (value >= max) {
			return 100;
		}

		return (value / (max - min)) * 100;
	}

	applyPrecision(value, precision) {
		return Number.parseFloat(value.toFixed(precision));
	}

	calculate(pos) {
		const value = this.getValueFromPosition(pos);
		let width = this.getWidthFromValue(value);

		width += '%';
		return { width, val: value };
	}

	handleClick(e) {
		// Is it disabled?
		if (this.props.disabled) {
			e.stopPropagation();
			e.preventDefault();
			return false;
		}

		const ratingCache = {
			pos: this.state.pos,
			rating: this.state.rating,
			caption: this.props.caption,
			name: this.props.name,
		};

		this.setState({
			ratingCache,
		});

		this.props.onRatingClick(e, ratingCache);
		return true;
	}

	handleMouseLeave() {
		this.setState({
			pos: this.state.ratingCache.pos,
			rating: this.state.ratingCache.rating,
		});
	}

	handleMouseMove(e) {
		// Get hover position
		const ratingEvent = this.getRatingEvent(e);
		this.updateRating(ratingEvent.width, ratingEvent.val);
	}

	treatName(title) {
		if (typeof title === 'string') {
			return title.toLowerCase().split(' ').join('_');
		}

		return null;
	}

	updateRating(width, value) {
		this.setState({
			pos: width,
			rating: value,
		});
	}

	render() {
		const classes = {
			'react-star-rating__root': true,
			'rating-disabled': this.props.disabled,
			[`react-star-rating__size--${this.props.size}`]: this.props.size,
			'rating-editing': this.state.editing,
		};

		// Are we editing this rating?
		let starRating;
		if (this.state.editing) {
			starRating = (
				<div
					ref={(c) => (this.node = c)}
					className="rating-container rating-gly-star"
					data-content={this.state.glyph}
					onMouseMove={this.handleMouseMove.bind(this)}
					onMouseLeave={this.handleMouseLeave.bind(this)}
					onClick={this.handleClick.bind(this)}
				>
					<div
						className="rating-stars"
						data-content={this.state.glyph}
						style={{ width: this.state.pos }}
					/>
				</div>
			);
		} else {
			starRating = (
				<div
					ref={(c) => (this.node = c)}
					className="rating-container rating-gly-star"
					data-content={this.state.glyph}
				>
					<div
						className="rating-stars"
						data-content={this.state.glyph}
						style={{ width: this.state.pos }}
					/>
				</div>
			);
		}

		return (
			<span className="react-star-rating">
				<span
					ref={(c) => (this.rootNode = c)}
					style={{ cursor: 'pointer' }}
					className={Object.keys(classes).filter((key) => classes[key])}
				>
					{starRating}
					<input
						readOnly
						type="number"
						name={this.props.name}
						value={this.state.ratingCache.rating}
						style={{ display: 'none !important', width: 65 }}
						min={this.min}
						max={this.max}
					/>
				</span>
			</span>
		);
	}
}

StarRating.defaultProps = {
	step: 0.5,
	ratingAmount: 5,
	onRatingClick() {},
	disabled: false,
};
