import { Tooltip } from 'antd';
import getSymbolFromCurrency from 'currency-symbol-map';
import get from 'lodash/get';
import {
	calculateTotalBonuses,
	mapReferralStatus,
	ml,
} from '../../_shared/services/utils.js';

function EmployeeReferralInfo(props) {
	const {
		employeeReferrals = [],
		referralBonuses,
		currentUser,
		allMultiLingualData,
		referralsCount,
		hiredReferralsCount,
	} = props;
	const currencyCode =
		currentUser.userGroup && currentUser.userGroup.currency !== null
			? currentUser.userGroup.currency
			: 'USD';
	const symbol = getSymbolFromCurrency(currencyCode);
	const totalReferrals = employeeReferrals.length;
	const referralsHired = employeeReferrals.filter(
		(referral) => referral.status === 'hired'
	).length;
	const referralStatus = mapReferralStatus(
		'hired',
		get(props, 'currentUser.company')
	);
	return (
		<>
			<div className="ed-card-header bg-1D8627">
				<span className="card-header-text">
					{ml('Total Referral Bonuses', currentUser, allMultiLingualData)}:
				</span>
				<h3>
					{symbol}
					{calculateTotalBonuses(referralBonuses, false)}
				</h3>
				<span className="card-header-text">
					{ml('Earned', currentUser, allMultiLingualData)}:{' '}
					<Tooltip title="Your Earned Total reflects referral bonuses that are past the waiting period.">
						<>
							{symbol}
							{calculateTotalBonuses(referralBonuses, true)}
						</>
					</Tooltip>
				</span>
			</div>
			<div className="ed-card-body">
				<div className="card-body-detail">
					<label>
						{ml('Total Referrals', currentUser, allMultiLingualData)}:
					</label>
					<p>{referralsCount}</p>
				</div>
				<div className="card-body-detail">
					<label>
						{ml('Referrals', currentUser, allMultiLingualData)}{' '}
						{ml(`${referralStatus}`, currentUser, allMultiLingualData)}:
					</label>
					<p>{hiredReferralsCount}</p>
				</div>
			</div>
		</>
	);
}

export default EmployeeReferralInfo;
