import { useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import { message } from 'antd';
import { useApprovalRewardsService } from '../../service/ApprovalRewards.service.jsx';
import { getColumnSearchProps } from '../../../approvalsBonus/presentation/filterSearch.jsx';
import ApprovalRewardsTable from '../../presentation/approvalRewardsTable/ApprovalRewardsTable.jsx';

const ApprovedContainer = (props) => {
	const { isTableRefresh, setTableRefresh } = props;

	const [approvalStatus, setApprovalStatus] = useState();
	const [isLoading, setIsLoading] = useState(false);

	const approvalRewardsAPIService = useApprovalRewardsService();

	const searchInput = useRef(null);

	useEffect(() => {
		if (isTableRefresh) {
			setTableRefresh(false);
			getApprovalRewards();
		}
	}, [isTableRefresh]);

	const getApprovalRewards = async () => {
		setIsLoading(true);
		approvalRewardsAPIService
			.getPendingRewardsList()
			.then((response) => {
				if (response?.code === 200) {
					setIsLoading(false);
					if (response?.data) {
						setApprovalStatus(response?.data);
						setTableRefresh(false);
					}
				} else {
					setIsLoading(false);
					message.error('Failed to fetch pending list');
				}
			})
			.catch((error) => {
				setIsLoading(false);
				console.log(error);
				message.error('Failed to fetch pending list');
			});
	};

	const handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
	};

	const handleReset = (clearFilters) => {
		clearFilters();
	};

	const columns = [
		{
			title: 'RECIPIENT',
			dataIndex: 'recipient',
			key: 'recipient',
			...getColumnSearchProps(
				'recipient',
				searchInput,
				handleSearch,
				handleReset,
				'Recipient'
			),
		},
		{
			title: 'BONUS',
			dataIndex: 'bonus',
			key: 'bonus',
			render: (bonus) => (
				<span style={{ color: bonus === 0 ? 'inherit' : 'green' }}>
					{bonus === 0 ? 'None' : '$' + bonus}
				</span>
			),
		},
		{
			title: 'CREATED BY',
			dataIndex: 'createdBy',
			key: 'createdBy',
		},
		{
			title: 'BADGE',
			dataIndex: 'reward',
			key: 'reward',
		},
		{
			title: 'CREATED',
			dataIndex: 'created',
			key: 'created',
			sorter: (a, b) => new Date(a?.created) - new Date(b?.created),
			render: (record) => dayjs(record).format('MM/DD/YYYY'),
		},
		{
			title: 'COMPLETED',
			dataIndex: 'Completed',
			key: 'Completed',
			render: (record) => dayjs(record).format('MM/DD/YYYY'),
		},
		{
			title: 'STATUS',
			dataIndex: 'status',
			key: 'status',
			render: (status) => (
				<span
					style={{
						color:
							status === 'Approved'
								? 'green'
								: status === 'Declined'
									? 'red'
									: 'inherit',
					}}
				>
					{status}
				</span>
			),
		},
	];

	return (
		<div className="mt-4">
			<div className="page-title">
				<h2 className="page-heading-manage-reward">Approval Status</h2>
			</div>
			<ApprovalRewardsTable
				columns={columns}
				isLoading={isLoading}
				approvedData={approvalStatus}
				emptyMessage="You have not approved any recognitions yet."
			/>
		</div>
	);
};

export default ApprovedContainer;
