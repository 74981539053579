import { Select } from 'antd';
import { forwardRef, useImperativeHandle, useMemo, useState } from 'react';
import countryList from 'react-select-country-list';
import { ml } from '../../services/utils.js';

export const CountrySelect = forwardRef(
	({ defaultCountry, currentUser, allMultiLingualData }, _ref) => {
		const [country, setCountry] = useState(defaultCountry);
		const options = useMemo(() => countryList().getData(), []);
		useImperativeHandle(_ref, () => ({
			getCountry: () => country,
		}));

		const handleChange = (value) => {
			setCountry(value);
		};

		return (
			<>
				<h4 className="setting-card-title">
					{ml('Default Phone Number Format', currentUser, allMultiLingualData)}
				</h4>
				<div className="custom-form-group">
					<label className="custom-label">
						{ml('Select Country', currentUser, allMultiLingualData)}:
					</label>
					<Select
						showSearch
						className="custom-input"
						optionFilterProp="children"
						placeholder="Country"
						value={country}
						onChange={handleChange}
					>
						{options.map((option) => (
							<Select.Option key={option.value} value={option.value}>
								{ml(option.label, currentUser, allMultiLingualData)}
							</Select.Option>
						))}
					</Select>
				</div>
			</>
		);
	}
);
