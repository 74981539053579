import { css } from 'emotion';
import get from 'lodash/get';
import { COLORS } from '../_shared/styles/colors';

const breakpoints = [576, 767, 991, 1200];

const mq = breakpoints.map((bp) => `@media (max-width: ${bp}px)`);

export const messageCenterContainer = css({
	width: '100%',
	height: '100%',
	padding: 20,
	backgroundColor: COLORS.lightGray2,
	[mq[2]]: {
		padding: '10px 5px',
	},
});
export const tableHeaderContainer = css({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	maxWidth: '100%',
	position: 'relative',
});

export const tableHeader = css({
	fontFamily: '"Open Sans", sans-serif !important',
	fontWeight: '600 !important',
});

export const tableContainer = css({
	borderRadius: 10,
	backgroundColor: 'white',
	padding: 20,
	height: 'auto',
	'& button:focus': {
		outline: 'none',
	},
	[mq[2]]: {
		padding: 8,
	},
});

export const FormItemContainer = css({
	display: 'flex',
	'& label': {
		display: 'block',
		fontSize: 18,
		color: COLORS.lightGray,
		lineHeight: '40px',
		marginRight: 10,
	},
});

export const filterContainer = css({
	'& svg': {
		pointerEvents: 'none',
	},
});

export const scheduleEmailFilterContainer = css({
	width: 'auto',
	display: 'flex',
	flexWrap: 'wrap',
	marginBottom: 6,
	[mq[1]]: {
		flexDirection: 'column',
	},
	'& svg': {
		pointerEvents: 'none',
	},
});

export const DropDown = css({
	fontFamily: '"Open Sans", sans-serif',
});

export const SelectContainer = css({
	position: 'relative',
	display: 'flex',
	height: 40,
});

export const iconContainer = css({
	'& svg': {
		position: 'absolute',
		top: 10,
		right: 10,
		pointerEvents: 'none',
	},
});

export const SelectStyles = css({
	width: '100%',
	'& .ant-select-selection__choice': {
		backgroundColor: COLORS.lightGreen,
		color: COLORS.green,
	},

	[mq[1]]: {
		width: '100%',
	},
});

export const SelectStylesGroup = css({
	width: '100%',
	'& .ant-select-selection__choice': {
		backgroundColor: COLORS.lightGreen,
		color: COLORS.green,
	},
	[mq[1]]: {
		width: '100%',
	},
});

export const collapseMenu = css({
	fontFamily: 'Open Sans,sans-serif',
	'& .ant-collapse-item': {
		borderBottom: 0,
		'& .ant-collapse-header': {
			paddingLeft: 0,

			'& .ant-collapse-arrow': {
				color: COLORS.blue,
				paddingLeft: 95,
			},
		},
		'& .ant-collapse-content-box': {
			paddingTop: 0,
			padding: 0,
		},
	},
});

export const LabelStyles = css({
	fontFamily: 'Open Sans,sans-serif',
	fontWeight: 600,
	color: COLORS.black,
	display: 'inline-Block',
	marginTop: '6px',
});

export const candidateNameStyles = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: '600 !important',
	color: COLORS.hyperLink,
	fontSize: 14,
	textAlign: 'center',
});

export const NormalLabelStyles = css({
	verticalAlign: 'middle',
	fontFamily: 'Open Sans,sans-serif',
});

export const SelectedCandidates = css({
	textAlign: 'center',
	width: '150px',
	backgroundColor: COLORS.dashboardBlue,
	borderRadius: 10,
	height: 100,
	position: 'relative',
	overflow: 'hidden',
});

export const SelectedCandidatesTextContiner = css({
	top: '2%',
	position: 'relative',
});

export const CandidateCountClass = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 800,
	color: COLORS.darkBlue2,
	fontSize: '3.0em',
	width: '100%',
});

export const SelectedEmployeesTextClass = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 600,
	color: COLORS.white,
	fontSize: '1.0em',
	width: '100%',
});

export const LabelClass = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 600,
	color: '#444444',
	fontSize: '1.5em',
});

export const DividerClass = css({
	'& .ant-divider-horizontal': {
		margin: '15px 0px !important',
	},
});

export const BtnContainer = (theme) => {
	let buttonColor = COLORS.blue;
	if (get(theme, 'enabled')) {
		buttonColor = get(theme, 'buttonColor', buttonColor);
	}

	return css({
		overflow: 'hidden',
		'& .ant-btn: hover': {
			color: buttonColor,
			borderColor: buttonColor,
		},
		'& button:hover > div > svg > path': {
			fill: buttonColor,
		},
		'& button:focus > div > svg > path': {
			fill: buttonColor,
		},
		'& .ant-btn:hover': {
			backgroundColor: `${buttonColor} !important`,
			border: `2px solid ${buttonColor} !important`,
			color: '#fff !important',
		},
		'& .ant-btn:focus': {
			backgroundColor: `${buttonColor} !important`,
			border: `2px solid ${buttonColor} !important`,
			color: '#fff !important',
		},
	});
};

export const RippleButton = css({
	overflow: 'hidden',
	marginRight: '10px',
});

export const SendTestEmailButton = css({
	width: 180,
	height: 40,
	background: COLORS.white,
	color: COLORS.gray,
	fontSize: 14,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	borderRadius: '10px',
	border: '1.5px solid #8d99a3',
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 200,
	lineHeight: '25px',
	'& .ant-btn: hover': {
		color: COLORS.gray,
		borderColor: COLORS.gray,
	},
	'& .ant-btn: focus': {
		color: COLORS.gray,
		borderColor: COLORS.gray,
	},
	[mq[1]]: {
		width: '100%',
	},
});

export const SendEmailEmployees = css({
	width: 180,
	height: 40,
	background: '#018dd3',
	color: 'white',
	fontSize: 14,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	borderRadius: '10px',
	border: '2px solid #018dd3',
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 200,
	lineHeight: '25px',
});

export const SelectStylesJob = css({
	display: 'block',
	width: '100%',
	'& .ant-select-selection__choice': {
		backgroundColor: COLORS.lightGreen,
		color: COLORS.green,
	},
	'& .ant-select-arrow': {
		display: 'none',
	},
	[mq[1]]: {
		width: '100%',
	},
});

export const AlertError = css({
	marginBottom: 20,
});

export const PopoverStyle = css({
	'& .ant-popover-placement-topLeft': {
		width: 290,
		left: 424,
		top: 644,
	},
	'& .ant-popover-inner-content': {
		borderColor: 'rgba(0, 0, 0, 0.75)',
		borderRadius: 4,
		color: 'white',
		backgroundColor: 'rgba(0, 0, 0, 0.75)',
		padding: 5,
	},
	'& .ant-popover-arrow': {
		backgroundColor: 'rgba(0, 0, 0, 0.75)',
		borderColor: 'rgba(0, 0, 0, 0.75)',
		border: '0px solid',
		zIndex: -1,
	},
});

export const fieldErrors = css({
	width: 'auto',
	marginLeft: '5%',
});

export const errorText = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 200,
	color: COLORS.red,
	fontSize: '0.85em',
	left: -30,
});

export const subjectInput = css({
	flex: '1 1 auto',
});

export const changeDirection = css({
	[mq[1]]: {
		flexDirection: 'column',
	},
});

export const autocompleteDropdownContainer = css({
	position: 'absolute',
	width: 350,
	zIndex: 1000,
	boxShadow: 'rgb(32 33 36 / 28%) 0px 1px 6px 0px',
});

export const inputContainer = (maxWidth) =>
	css({
		maxWidth,
		width: '100%',
		[mq[1]]: {
			maxWidth: 'none',
		},
	});

export const alignCenter = css({
	[mq[1]]: {
		marginTop: 30,
		alignItems: 'center',
		justifyContent: 'space-around',
	},
});

export const fullWidth = css({
	[mq[1]]: {
		width: '100% !important',
	},
});

export const typeaheadRow = (active) => ({
	backgroundColor: active ? COLORS.red : COLORS.lightGray2,
});

export const SelectStylesLocation = css({
	width: '100%',
	'& .ant-select-arrow': {
		display: 'none',
	},
	[mq[1]]: {
		width: '100%',
	},
});

export const MinimalEmployees = (selected) =>
	css({
		margin: 5,
		marginTop: 8,
		backgroundColor: selected ? COLORS.lightGray5 : COLORS.white,
		color: selected ? COLORS.white : COLORS.subHeading,
		'&:hover': {
			backgroundColor: COLORS.lightGray5,
			color: COLORS.white,
			borderColor: 'transparent',
		},
		'&:focus': {
			backgroundColor: COLORS.lightGray5,
			color: COLORS.white,
			borderColor: 'transparent',
		},
		height: 30,
		width: 120,
	});

export const rangePicker = css({
	padding: 0,
	width: '100%',
});

export const disableDiv = css({
	pointerEvents: 'none',
	opacity: '0.7',
});

export const ModalStyles = css({
	position: 'absolute',
	left: '50%',
	top: '50%',
	transform: 'translate(-50%, -50%)',
	'& .ant-modal-content': {
		borderRadius: 20,
	},
	'& .ant-modal-header': {
		background: 'none',
	},
	'& .ant-modal-close': {
		display: 'none',
	},
	fontFamily: 'Open Sans',
});

export const ModalTitle = (theme) => {
	let buttonColor = COLORS.red;
	if (get(theme, 'enabled')) {
		buttonColor = get(theme, 'buttonColor', buttonColor);
	}

	return css({
		width: '100%',
		textAlign: 'center',
		color: buttonColor,
		fontSize: 36,
		marginBottom: 0,
		fontWeight: 600,
	});
};

export const SmallText = css({
	fontSize: 13,
	fontWeight: 600,
	width: '100%',
	textAlign: 'center',
});

export const NevermindBtn = css({
	color: COLORS.blue,
	border: 'none',
	boxShadow: 'none',
	backgroundColor: 'none',
	padding: 0,
	marginTop: 10,
	textAlign: 'center',
});
