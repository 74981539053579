import { Tooltip } from 'antd';
import dayjs from 'dayjs';
import gql from 'graphql-tag';
import Cookies from 'js-cookie';
import _ from 'lodash';
import get from 'lodash/get';
import * as Msal from 'msal';
import { Component } from 'react';
import { withApollo } from 'react-apollo';
import ReactGA from 'react-ga';
import { AuthService, GraphService } from 'src/_shared/api/';
import { getUserDataById } from 'src/_shared/api/graphql/custom/users/';

import { SearchComponent } from 'src/_shared/components/search';
import Spinner from 'src/_shared/components/spinner/SpinnerComponent.jsx';
import fileIcon from '../../assets/erin_lightgray.png';
import {
	getSetErrorImageURL,
	logout,
	ml,
	searchData,
} from '../../services/utils';
import { ExportSelector } from '../export-selector/ExportSelector.jsx';

import OnDeckTable from './OnDeckTableComponent.jsx';
import { AddContactsModal } from './add-contacts';
import { ToggleOnOffDeck } from './toggleOnOffDeck';

const filterOnDeckKey = 'onDeck';
const filterOffDeckKey = 'offDeck';

class OnDeckComponent extends Component {
	constructor(props) {
		super(props);
		this.authService = new AuthService();
		this.graphService = new GraphService();
		this.state = {
			company: get(props, 'currentUser.company'),
			filteredContacts: props.onDeckContacts,
			filterOnDeckStatus: filterOnDeckKey,
			contactsModal: false,
			currentUser: props.currentUser,
			jobs: props.jobs,
			query: '',
			userContacts: props.onDeckContacts,
		};
	}

	async componentDidMount() {
		const host = window.location.hostname;
		if (host === 'referrals.aus.com') {
			ReactGA.initialize('UA-128630809-2');
			ReactGA.pageview(window.location.pathname + window.location.search);
		} else if (host === 'app.erinapp.com') {
			ReactGA.initialize('UA-128630809-3');
			ReactGA.pageview(window.location.pathname + window.location.search);
		}

		await this.getUserDataById();
		const jwt = Cookies.get('jwt');
		const { resultData } = this.state;
		if (resultData !== '' && resultData !== undefined) {
			const expirationDoneByToken = get(
				resultData,
				'expirationDoneByToken',
				null
			);
			const expires = get(resultData, 'expires');
			if (jwt !== undefined && jwt !== expirationDoneByToken && expires) {
				logout(this.props.client);
			}
		}

		if (this.props.location.pathname === '/mycontacts/add/form') {
			this.setState({
				contactsModal: true,
			});
		}

		const { currentUser } = this.props;
		const errorImageURL = await getSetErrorImageURL(
			currentUser?.company?.errorImage?.key
		);

		if (errorImageURL) {
			this.setState({ errorImageURL });
		}
	}

	componentDidUpdate(prevProps) {
		const { jobs, onDeckContacts, onFetchMore, onFetchMoreJobs } = this.props;
		if (prevProps.onDeckContacts !== onDeckContacts) {
			this.setState({ filteredContacts: onDeckContacts });
			if (onFetchMore) onFetchMore();
		}

		if (prevProps.jobs !== jobs) {
			if (onFetchMoreJobs) onFetchMoreJobs();
			this.setState({
				jobs,
			});
		}
	}


	getExportData = (contacts) => {

		const { currentUser, allMultiLingualData } = this.props;
		const tableData = contacts;
		const newData = [];
		const headers = {
			candidate: ml('Candidate', currentUser, allMultiLingualData),
			info: ml('Info', currentUser, allMultiLingualData),
			email: ml('Email', currentUser, allMultiLingualData),
			phoneNumber: ml('Phone Number', currentUser, allMultiLingualData),
			referredBy: ml('Referred By', currentUser, allMultiLingualData),
			dateReferred: ml('Date Referred', currentUser, allMultiLingualData),
		};
		for (const item of tableData) {
			const referredByFirstName = get(item, ['user', 'firstName'], '');
			const referredByLastName = get(item, ['user', 'lastName'], '');
			const newItem = {
				[headers.candidate]: `${get(item, 'firstName', '')} ${get(item, 'lastName', '')}`,
				[headers.info]: get(item, 'onDeckNote'),
				[headers.email]: get(item, 'emailAddress'),
				[headers.phoneNumber]: get(item, 'phoneNumber'),
				[headers.referredBy]: referredByFirstName + ' ' + referredByLastName,
				[headers.dateReferred]: dayjs(get(item, 'onDeckDate', '')).format(
					'M/D/YYYY'
				),
			};

			newData.push(newItem);
		}

		return newData;
	};

	async getUserDataById(policy = 'network-only') {
		const { client, currentUser } = this.props;
		try {
			const userId = get(currentUser, 'id', null);
			if (userId !== null) {
				const { data } = await client.query({
					query: gql(getUserDataById),
					variables: {
						id: userId,
					},
					fetchPolicy: policy,
				});
				const result = get(data, 'getUser', null);
				this.setState({
					resultData: result,
				});
			}
		} catch (error) {
			console.log(error);
		}
	}

	setQueryToState = (value) => {
		this.setState({ query: value });
	};

	changeDeck = (data) => {
		const { filteredContacts } = this.state;

		const onDeckStatus = _.get(data, 'onDeckStatus');
		const newDeckStatus = onDeckStatus === 'offDeck' ? 'onDeck' : 'offDeck';
		const newContact = _.set(data, 'onDeckStatus', newDeckStatus);
		const newContacts = filteredContacts;
		newContacts.splice(
			newContacts.findIndex((contact) => contact.id === data.id),
			newContact
		);
		this.props.onUpdateContact({
			id: newContact.id,
			onDeckStatus: newDeckStatus,
		});
		this.setState({ filteredContacts: newContacts });
	};

	filterData = (property, match, dataSet) => {
		return dataSet.filter((job) => job[property] === match);
	};

	hideContactModal = () => {
		this.setState({
			contactsModal: false,
		});
	};

	parseBonusStatus(status) {
		if (status === 'paid') {
			return 'Paid';
		}

		if (status === 'pending') {
			return 'Pending';
		}

		if (status === 'earned') {
			return 'Earned';
		}

		return 'Ineligible';
	}

	showModal = () => {
		this.setState({
			contactsModal: true,
		});
	};

	sortByAlph = (a, b) => {
		if (typeof a === 'string') {
			a = a.toLowerCase();
		}

		if (typeof b === 'string') {
			b = b.toLowerCase();
		}

		if (a > b) return 1;
		if (a < b) return -1;
		return 0;
	};

	updateUserIncentiveEligibility = (eligible) => {
		this.props.onUpdate({
			input: {
				id: this.props.currentUser.id,
				incentiveEligible: eligible,
			},
		});
	};

	render() {
		new Msal.UserAgentApplication(
			'b77cd394-2b71-4de1-80ff-098f86d9d2d3',
			null,
			null
		);
		const { allMultiLingualData } = this.props;
		const {
			filteredContacts,
			filterOnDeckStatus,
			company,
			contactsModal,
			currentUser,
			loaded,
			query,
		} = this.state;

		const languageCode = get(currentUser, 'languageCode', 'US');
		const whiteLabel = get(company, 'whiteLabel');
		if (
			!get(this.props, 'currentUser.company') ||
			!filteredContacts ||
			this.props.nextToken
		) {
			return <Spinner />;
		}

		if (this.state.loaded !== true) {
			setTimeout(() => {
				this.setState({ loaded: true });
			}, 2000);
		}

		const searchedContacts = searchData(
			['firstName', 'lastName', 'emailAddress'],
			query,
			filteredContacts
		);
		const onDeckContacts = this.filterData(
			'onDeckStatus',
			'onDeck',
			searchedContacts
		);
		const offDeckContacts = this.filterData(
			'onDeckStatus',
			'offDeck',
			searchedContacts
		);
		const onDeckOtherStatusTitle =
			filterOnDeckStatus === filterOnDeckKey ? 'Off Deck ' : 'On Deck ';
		const jobData =
			filterOnDeckStatus === filterOnDeckKey ? onDeckContacts : offDeckContacts;
		const sortedContacts = _.sortBy(jobData, function (job) {
			return job.dateCreated;
		}).reverse();
		const exportTableData = this.getExportData(sortedContacts);
		const totalReferrals = filteredContacts ? filteredContacts.length : 0;
		return (
			<main>
				<div className="page-title">
					<h2 className="page-heading">
						{ml('Referral Leads', currentUser, allMultiLingualData)}
					</h2>
					<ul className="info-action">
						<li>
							{ml('Total General Referrals', currentUser, allMultiLingualData)}:{' '}
							{totalReferrals}
						</li>
						<li>

							<ExportSelector
								title={ml('Export', currentUser, allMultiLingualData)}
								items={[
									{
										key: 1,
										label: (
											<Tooltip
												title={ml(
													`Instantly download an export of the filtered results.`,
													currentUser,
													allMultiLingualData
												)}
												placement="left"
											>
												{ml('Export View', currentUser, allMultiLingualData)}
											</Tooltip>
										),
										onClick: 'csv-export',
										data: exportTableData,
										reportType: 'referral-leads',
										companyName: company?.name,
									},
								]}
							/>

						</li>
					</ul>
				</div>
				<div className="referral-filter">
					<SearchComponent
						style={{ flex: 1 }}
						setQueryToState={this.setQueryToState}
						searchQuery={query}
						placeholder={ml('Search', currentUser, allMultiLingualData)}
					/>
					<ToggleOnOffDeck
						filterOnDeckStatus={filterOnDeckStatus}
						offDeckJobsKey={filterOffDeckKey}
						activeText={ml('Active', currentUser, allMultiLingualData)}
						inactivetext={ml('Inactive', currentUser, allMultiLingualData)}
						onClick={(filterOnDeckStatus) => {
							this.setState({
								filterOnDeckStatus,
							});
						}}
						onDeckJobsKey={filterOnDeckKey}
					/>
				</div>

				{sortedContacts.length > 0 ? (
					<div className="table-card">
						<OnDeckTable
							allMultiLingualData={allMultiLingualData}
							currentUser={currentUser}
							jobs={this.state.jobs}
							loaded={this.state.loaded}
							contacts={sortedContacts}
							changeDeck={this.changeDeck}
							sortByAlph={this.sortByAlph}
							fetchMore={this.props.fetchMore}
							languageCode={languageCode}
							displayAs={this.props.currentUser.displayAs}
							onDeckStatusTitle={onDeckOtherStatusTitle}
							onCreateReferral={this.props.onCreateReferral}
							onDeleteContact={this.props.onDeleteContact}
							onDeleteJobMatch={this.props.onDeleteJobMatch}
							onUpdateContactDeckStatus={this.props.onUpdateContactDeckStatus}
							onUpdateContact={this.props.onUpdateContact}
						/>
					</div>
				) : loaded ? (
					<div className="no-content">
						{whiteLabel ? (
							<img
								src={this.state.errorImageSrc}
								alt="error image"
								className="no-content-icon"
							/>
						) : (
							<img alt="erin-logo" className="no-content-icon" src={fileIcon} />
						)}
						<p className="no-content-text">
							There are no General Referrals at this time.
						</p>
					</div>
				) : (
					<Spinner company={company} />
				)}
				<AddContactsModal
					visible={contactsModal}
					handleCancel={this.hideContactModal}
					currentUser={currentUser}
					updateUserIncentiveEligibility={this.updateUserIncentiveEligibility}
					onCreateContact={this.props.ImportedCreateContact}
				/>
			</main>
		);
	}
}

export default withApollo(OnDeckComponent);
