export const getUser = `query GetUser($id: ID!) {
  getUser(id: $id) {
    id
    accountClaimId
    cognitoId
    companyId
    firstName
    lastName
    role
    userGroupId
    emailAddress
    avatar {
      key
      region
      bucket
    }
    managedDepartments {
      id
      userId
      departmentId
      department {
        id
        name
      }
    }
    lastNotificationCheck
    incentiveEligible
    lastLogin
    totalReferrals
    ranking
    previousRanking
    createdById
    connectedApps
    active
    departmentId
    points
    pointsRanking
  }
}
`;
