import { css } from 'emotion';
import { COLORS } from '../../styles/colors';

export const progressIndicatorContainer = css({
	width: '100%',
	height: 40,
	marginLeft: '13%',
	marginTop: '6%',
	paddingBottom: 45,
});

export const progressIndicatorLine = (color) =>
	css({
		width: '100%',
		backgroundColor: color,
		height: '5px',
		zIndex: 0,
		position: 'absolute',
		marginTop: 10,
		marginLeft: 2,
	});

export const progressIndicatorDots = (color) =>
	css({
		width: '26px',
		height: '26px',
		borderRadius: 13,
		backgroundColor: color,
		position: 'absolute',
		zIndex: 1,
	});

export const progressIndicatorDotCenter = css({
	width: '22px',
	height: '22px',
	borderRadius: 11,
	backgroundColor: COLORS.white,
	position: 'absolute',
	zIndex: 10,
	marginLeft: 2,
	marginTop: 2,
});

export const checkMark = (color) =>
	css({
		width: 24,
		height: 24,
		zIndex: 20,
		position: 'absolute',
		marginTop: 5,
		marginleft: -5,
		color,
		fontWeight: 600,
	});

export const subTitle = (left) =>
	css({
		fontFamily: '"Open Sans", sans-serif',
		fontWeight: 500,
		color: COLORS.heading,
		fontSize: '0.85em',
		top: 30,
		position: 'absolute',
		left,
	});
