import { Alert, Form } from 'antd';
import gql from 'graphql-tag';
import get from 'lodash/get';
import { Component } from 'react';
import { withApollo } from 'react-apollo';
import ProgressButton from 'react-progress-button';
import { queryReferralsByJobIdIndex } from 'src/_shared/api/graphql/custom/referrals/';
import uuid from 'uuid/v4';
import * as ModalStyles from '../referralModalStyles.js';

class ReferralForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isSubmitting: false,
			error: false,
			sendReferralEmail: false,
			moveCandidateOffDeck: true,
			theme: JSON.parse(get(props, 'currentUser.company.theme', '{}')),
			message: null,
			uniqueId: uuid(),
		};
	}

	checkIsAlreadyReferredToJob = async () => {
		const { client, job } = this.props;
		const referral = this.props.data;

		const email =
			get(referral, 'emailAddress', '') !== null &&
			get(referral, 'emailAddress', '') !== ''
				? get(referral, 'emailAddress').toLowerCase()
				: get(referral, 'emailAddress', '');
		let isAlreadyReferredToJob = false;
		try {
			const { data } = await client.query({
				query: gql(queryReferralsByJobIdIndex),
				variables: { jobId: get(job, 'id') },
				fetchPolicy: 'network-only',
			});
			const jobReferrals = get(data, 'queryReferralsByJobIdIndex.items', []);
			for (const ref of jobReferrals) {
				const contactEmailAddress =
					get(ref, 'contact.emailAddress', '') !== null &&
					get(ref, 'contact.emailAddress', '') !== ''
						? get(ref, 'contact.emailAddress').toLowerCase()
						: get(ref, 'contact.emailAddress', '');
				if (email === contactEmailAddress) {
					isAlreadyReferredToJob = true;
				}
			}
		} catch (error) {
			console.log(error);
		}

		if (isAlreadyReferredToJob) {
			this.setState({ error: 'This person has already been referred' });
			return new Error('This person has already been referred');
		}

		return isAlreadyReferredToJob;
	};

	showAnimation = () => {
		const { toggleIsSubmitting, handleCancelAll } = this.props;
		return new Promise((resolve, reject) => {
			setTimeout(() => {
				try {
					toggleIsSubmitting();
					handleCancelAll();
					resolve();
				} catch (error) {
					reject(error);
				}
			}, 5700);
		});
	};

	handleChangeSendEmail = (e) => {
		this.setState({
			sendReferralEmail: e.target.checked,
		});
	};

	handleChangeOffDeck = (e) => {
		this.setState({
			moveCandidateOffDeck: e.target.checked,
		});
	};

	render() {
		const {
			currentUser,
			data,
			handleError,
			job,
			onCreateReferral,
			onUpdateContactDeckStatus,
			toggleIsSubmitting,
			buttonState,
		} = this.props;
		const { error, moveCandidateOffDeck, sendReferralEmail, uniqueId } =
			this.state;
		const questionsData = get(data, 'questionsData');
		const handleSubmit = async (values) => {
			const isReferred = await this.checkIsAlreadyReferredToJob();
			if (isReferred) return;
			toggleIsSubmitting();
			this.showAnimation().then(() => {
				if (moveCandidateOffDeck) {
					onUpdateContactDeckStatus({
						id: data.id,
						onDeckStatus: 'offDeck',
					});
				}

				// Review Referral
				const referralInput = {
					input: {
						companyId: currentUser.companyId,
						contactId: data.id,
						userId: data.userId,
						jobId: job.id,
						status: 'referred',
						note: values.note ? values.note : null,
						message: values.message ? values.message : null,
						referralSource: 'direct',
						referralDevice: 'web',
					},
				};
				if (sendReferralEmail) {
					referralInput.input.referralType = 'email';
				}

				if (questionsData) {
					referralInput.input.questionsData = questionsData;
				}

				onCreateReferral(referralInput);
			});
		};

		return (
			<Form onFinish={handleSubmit}>
				{error ? (
					<Alert
						className={ModalStyles.SubmitError}
						message="This person has already been referred for this position."
						type="error"
					/>
				) : (
					<>
						<div className="custom-form-group custom-checkbox left-check">
							<input
								type="checkbox"
								id={`"sendReferralEmail-" + ${uniqueId}`}
								checked={sendReferralEmail}
								onChange={this.handleChangeSendEmail}
							/>
							<label htmlFor={`"sendReferralEmail-" + ${uniqueId}`}>
								Send candidate a referral email to accept or decline
							</label>
						</div>
						<div className="custom-form-group custom-checkbox left-check">
							<input
								id={`"moveCandidateOffDeck-" + ${uniqueId}`}
								type="checkbox"
								checked={moveCandidateOffDeck}
								onChange={this.handleChangeOffDeck}
							/>
							<label htmlFor={`"moveCandidateOffDeck-" + ${uniqueId}`}>
								Remove this candidate's General Referral
							</label>
						</div>
						<div className="modal-footer-btn">
							<ProgressButton
								durationSuccess={10_000}
								type="submit"
								state={buttonState}
							>
								Submit Referral
							</ProgressButton>
						</div>
					</>
				)}
			</Form>
		);
	}
}

export default withApollo(ReferralForm);
