export const countryCurrenyEmojiCodes = [
    {
        name: "Afghanistan",
        countryCode: "AF",
        currencyCode: "AFN",
        flagEmoji: "🇦🇫",
        currencySymbol: "؋"
    },
    {
        name: "Aland Islands",
        countryCode: "AX",
        currencyCode: "EUR",
        flagEmoji: "🇦🇽",
        currencySymbol: "€"
    },
    {
        name: "Albania",
        countryCode: "AL",
        currencyCode: "ALL",
        flagEmoji: "🇦🇱",
        currencySymbol: "L"
    },
    {
        name: "Algeria",
        countryCode: "DZ",
        currencyCode: "DZD",
        flagEmoji: "🇩🇿",
        currencySymbol: "دج"
    },
    {
        name: "American Samoa",
        countryCode: "AS",
        currencyCode: "USD",
        flagEmoji: "🇦🇸",
        currencySymbol: "$"
    },
    {
        name: "Andorra",
        countryCode: "AD",
        currencyCode: "EUR",
        flagEmoji: "🇦🇩",
        currencySymbol: "€"
    },
    {
        name: "Angola",
        countryCode: "AO",
        currencyCode: "AOA",
        flagEmoji: "🇦🇴",
        currencySymbol: "Kz"
    },
    {
        name: "Anguilla",
        countryCode: "AI",
        currencyCode: "XCD",
        flagEmoji: "🇦🇮",
        currencySymbol: "$"
    },
    {
        name: "Antigua and Barbuda",
        countryCode: "AG",
        currencyCode: "XCD",
        flagEmoji: "🇦🇬",
        currencySymbol: "$"
    },
    {
        name: "Argentina",
        countryCode: "AR",
        currencyCode: "ARS",
        flagEmoji: "🇦🇷",
        currencySymbol: "$"
    },
    {
        name: "Armenia",
        countryCode: "AM",
        currencyCode: "AMD",
        flagEmoji: "🇦🇲",
        currencySymbol: "֏"
    },
    {
        name: "Aruba",
        countryCode: "AW",
        currencyCode: "AWG",
        flagEmoji: "🇦🇼",
        currencySymbol: "ƒ"
    },
    {
        name: "Australia",
        countryCode: "AU",
        currencyCode: "AUD",
        flagEmoji: "🇦🇺",
        currencySymbol: "$"
    },
    {
        name: "Austria",
        countryCode: "AT",
        currencyCode: "EUR",
        flagEmoji: "🇦🇹",
        currencySymbol: "€"
    },
    {
        name: "Azerbaijan",
        countryCode: "AZ",
        currencyCode: "AZN",
        flagEmoji: "🇦🇿",
        currencySymbol: "₼"
    },
    {
        name: "Bahamas",
        countryCode: "BS",
        currencyCode: "BSD",
        flagEmoji: "🇧🇸",
        currencySymbol: "$"
    },
    {
        name: "Bahrain",
        countryCode: "BH",
        currencyCode: "BHD",
        flagEmoji: "🇧🇭",
        currencySymbol: ".د.ب"
    },
    {
        name: "Bangladesh",
        countryCode: "BD",
        currencyCode: "BDT",
        flagEmoji: "🇧🇩",
        currencySymbol: "৳"
    },
    {
        name: "Barbados",
        countryCode: "BB",
        currencyCode: "BBD",
        flagEmoji: "🇧🇧",
        currencySymbol: "$"
    },
    {
        name: "Belarus",
        countryCode: "BY",
        currencyCode: "BYR",
        flagEmoji: "🇧🇾",
        currencySymbol: "Br"
    },
    {
        name: "Belgium",
        countryCode: "BE",
        currencyCode: "EUR",
        flagEmoji: "🇧🇪",
        currencySymbol: "€"
    },
    {
        name: "Belize",
        countryCode: "BZ",
        currencyCode: "BZD",
        flagEmoji: "🇧🇿",
        currencySymbol: "BZ$"
    },
    {
        name: "Benin",
        countryCode: "BJ",
        currencyCode: "XOF",
        flagEmoji: "🇧🇯",
        currencySymbol: "CFA"
    },
    {
        name: "Bermuda",
        countryCode: "BM",
        currencyCode: "BMD",
        flagEmoji: "🇧🇲",
        currencySymbol: "$"
    },
    {
        name: "Bhutan",
        countryCode: "BT",
        currencyCode: "BTN",
        flagEmoji: "🇧🇹",
        currencySymbol: "Nu."
    },
    {
        name: "Bolivia",
        countryCode: "BO",
        currencyCode: "BOB",
        flagEmoji: "🇧🇴",
        currencySymbol: "$b"
    },
    {
        name: "Bosnia and Herzegovina",
        countryCode: "BA",
        currencyCode: "BAM",
        flagEmoji: "🇧🇦",
        currencySymbol: "KM"
    },
    {
        name: "Botswana",
        countryCode: "BW",
        currencyCode: "BWP",
        flagEmoji: "🇧🇼",
        currencySymbol: "P"
    },
    {
        name: "Bouvet Island",
        countryCode: "BV",
        currencyCode: "NOK",
        flagEmoji: "🇧🇻",
        currencySymbol: "kr"
    },
    {
        name: "Brazil",
        countryCode: "BR",
        currencyCode: "BRL",
        flagEmoji: "🇧🇷",
        currencySymbol: "R$"
    },
    {
        name: "British Virgin Islands",
        countryCode: "VG",
        currencyCode: "USD",
        flagEmoji: "🇻🇬",
        currencySymbol: "$"
    },
    {
        name: "British Indian Ocean Territory",
        countryCode: "IO",
        currencyCode: "USD",
        flagEmoji: "🇮🇴",
        currencySymbol: "$"
    },
    {
        name: "Brunei Darussalam",
        countryCode: "BN",
        currencyCode: "BND",
        flagEmoji: "🇧🇳",
        currencySymbol: "$"
    },
    {
        name: "Bulgaria",
        countryCode: "BG",
        currencyCode: "BGN",
        flagEmoji: "🇧🇬",
        currencySymbol: "лв"
    },
    {
        name: "Burkina Faso",
        countryCode: "BF",
        currencyCode: "XOF",
        flagEmoji: "🇧🇫",
        currencySymbol: "CFA"
    },
    {
        name: "Burundi",
        countryCode: "BI",
        currencyCode: "BIF",
        flagEmoji: "🇧🇮",
        currencySymbol: "FBu"
    },
    {
        name: "Cambodia",
        countryCode: "KH",
        currencyCode: "KHR",
        flagEmoji: "🇰🇭",
        currencySymbol: "៛"
    },
    {
        name: "Cameroon",
        countryCode: "CM",
        currencyCode: "XAF",
        flagEmoji: "🇨🇲",
        currencySymbol: "FCFA"
    },
    {
        name: "Canada",
        countryCode: "CA",
        currencyCode: "CAD",
        flagEmoji: "🇨🇦",
        currencySymbol: "$"
    },
    {
        name: "Cape Verde",
        countryCode: "CV",
        currencyCode: "CVE",
        flagEmoji: "🇨🇻",
        currencySymbol: "$"
    },
    {
        name: "Cayman Islands",
        countryCode: "KY",
        currencyCode: "KYD",
        flagEmoji: "🇰🇾",
        currencySymbol: "$"
    },
    {
        name: "Central African Republic",
        countryCode: "CF",
        currencyCode: "XAF",
        flagEmoji: "🇨🇫",
        currencySymbol: "FCFA"
    },
    {
        name: "Chad",
        countryCode: "TD",
        currencyCode: "XAF",
        flagEmoji: "🇹🇩",
        currencySymbol: "FCFA"
    },
    {
        name: "Chile",
        countryCode: "CL",
        currencyCode: "CLP",
        flagEmoji: "🇨🇱",
        currencySymbol: "$"
    },
    {
        name: "China",
        countryCode: "CN",
        currencyCode: "CNY",
        flagEmoji: "🇨🇳",
        currencySymbol: "¥"
    },
    {
        name: "Hong Kong, Special Administrative Region of China",
        countryCode: "HK",
        currencyCode: "HKD",
        flagEmoji: "🇭🇰",
        currencySymbol: "$"
    },
    {
        name: "Macao, Special Administrative Region of China",
        countryCode: "MO",
        currencyCode: "MOP",
        flagEmoji: "🇲🇴",
        currencySymbol: "MOP$"
    },
    {
        name: "Christmas Island",
        countryCode: "CX",
        currencyCode: "AUD",
        flagEmoji: "🇨🇽",
        currencySymbol: "$"
    },
    {
        name: "Cocos (Keeling) Islands",
        countryCode: "CC",
        currencyCode: "AUD",
        flagEmoji: "🇨🇨",
        currencySymbol: "$"
    },
    {
        name: "Colombia",
        countryCode: "CO",
        currencyCode: "COP",
        flagEmoji: "🇨🇴",
        currencySymbol: "$"
    },
    {
        name: "Comoros",
        countryCode: "KM",
        currencyCode: "KMF",
        flagEmoji: "🇰🇲",
        currencySymbol: "CF"
    },
    {
        name: "Congo (Brazzaville)",
        countryCode: "CG",
        currencyCode: "XAF",
        flagEmoji: "🇨🇬",
        currencySymbol: "FCFA"
    },
    {
        name: "Congo, Democratic Republic of the",
        countryCode: "CD",
        currencyCode: "CDF",
        flagEmoji: "🇨🇩",
        currencySymbol: "FC"
    },
    {
        name: "Cook Islands",
        countryCode: "CK",
        currencyCode: "NZD",
        flagEmoji: "🇨🇰",
        currencySymbol: "$"
    },
    {
        name: "Costa Rica",
        countryCode: "CR",
        currencyCode: "CRC",
        flagEmoji: "🇨🇷",
        currencySymbol: "₡"
    },
    {
        name: "Côte d'Ivoire",
        countryCode: "CI",
        currencyCode: "XOF",
        flagEmoji: "🇨🇮",
        currencySymbol: "CFA"
    },
    {
        name: "Croatia",
        countryCode: "HR",
        currencyCode: "HRK",
        flagEmoji: "🇭🇷",
        currencySymbol: "kn"
    },
    {
        name: "Cuba",
        countryCode: "CU",
        currencyCode: "CUP",
        flagEmoji: "🇨🇺",
        currencySymbol: "₱"
    },
    {
        name: "Cyprus",
        countryCode: "CY",
        currencyCode: "EUR",
        flagEmoji: "🇨🇾",
        currencySymbol: "€"
    },
    {
        name: "Czech Republic",
        countryCode: "CZ",
        currencyCode: "CZK",
        flagEmoji: "🇨🇿",
        currencySymbol: "Kč"
    },
    {
        name: "Denmark",
        countryCode: "DK",
        currencyCode: "DKK",
        flagEmoji: "🇩🇰",
        currencySymbol: "kr"
    },
    {
        name: "Djibouti",
        countryCode: "DJ",
        currencyCode: "DJF",
        flagEmoji: "🇩🇯",
        currencySymbol: "Fdj"
    },
    {
        name: "Dominica",
        countryCode: "DM",
        currencyCode: "XCD",
        flagEmoji: "🇩🇲",
        currencySymbol: "$"
    },
    {
        name: "Dominican Republic",
        countryCode: "DO",
        currencyCode: "DOP",
        flagEmoji: "🇩🇴",
        currencySymbol: "RD$"
    },
    {
        name: "Ecuador",
        countryCode: "EC",
        currencyCode: "USD",
        flagEmoji: "🇪🇨",
        currencySymbol: "$"
    },
    {
        name: "Egypt",
        countryCode: "EG",
        currencyCode: "EGP",
        flagEmoji: "🇪🇬",
        currencySymbol: "£"
    },
    {
        name: "El Salvador",
        countryCode: "SV",
        currencyCode: "SVC",
        flagEmoji: "🇸🇻",
        currencySymbol: "$"
    },
    {
        name: "Equatorial Guinea",
        countryCode: "GQ",
        currencyCode: "XAF",
        flagEmoji: "🇬🇶",
        currencySymbol: "FCFA"
    },
    {
        name: "Eritrea",
        countryCode: "ER",
        currencyCode: "ERN",
        flagEmoji: "🇪🇷",
        currencySymbol: "Nfk"
    },
    {
        name: "Estonia",
        countryCode: "EE",
        currencyCode: "EUR",
        flagEmoji: "🇪🇪",
        currencySymbol: "€"
    },
    {
        name: "Ethiopia",
        countryCode: "ET",
        currencyCode: "ETB",
        flagEmoji: "🇪🇹",
        currencySymbol: "Br"
    },
    {
        name: "Falkland Islands (Malvinas)",
        countryCode: "FK",
        currencyCode: "FKP",
        flagEmoji: "🇫🇰",
        currencySymbol: "£"
    },
    {
        name: "Faroe Islands",
        countryCode: "FO",
        currencyCode: "DKK",
        flagEmoji: "🇫🇴",
        currencySymbol: "kr"
    },
    {
        name: "Fiji",
        countryCode: "FJ",
        currencyCode: "FJD",
        flagEmoji: "🇫🇯",
        currencySymbol: "$"
    },
    {
        name: "Finland",
        countryCode: "FI",
        currencyCode: "EUR",
        flagEmoji: "🇫🇮",
        currencySymbol: "€"
    },
    {
        name: "France",
        countryCode: "FR",
        currencyCode: "EUR",
        flagEmoji: "🇫🇷",
        currencySymbol: "€"
    },
    {
        name: "French Guiana",
        countryCode: "GF",
        currencyCode: "EUR",
        flagEmoji: "🇬🇫",
        currencySymbol: "€"
    },
    {
        name: "French Polynesia",
        countryCode: "PF",
        currencyCode: "XPF",
        flagEmoji: "🇵🇫",
        currencySymbol: "₣"
    },
    {
        name: "French Southern Territories",
        countryCode: "TF",
        currencyCode: "EUR",
        flagEmoji: "🇹🇫",
        currencySymbol: "€"
    },
    {
        name: "Gabon",
        countryCode: "GA",
        currencyCode: "XAF",
        flagEmoji: "🇬🇦",
        currencySymbol: "FCFA"
    },
    {
        name: "Gambia",
        countryCode: "GM",
        currencyCode: "GMD",
        flagEmoji: "🇬🇲",
        currencySymbol: "D"
    },
    {
        name: "Georgia",
        countryCode: "GE",
        currencyCode: "GEL",
        flagEmoji: "🇬🇪",
        currencySymbol: "₾"
    },
    {
        name: "Germany",
        countryCode: "DE",
        currencyCode: "EUR",
        flagEmoji: "🇩🇪",
        currencySymbol: "€"
    },
    {
        name: "Ghana",
        countryCode: "GH",
        currencyCode: "GHS",
        flagEmoji: "🇬🇭",
        currencySymbol: "GH₵"
    },
    {
        name: "Gibraltar",
        countryCode: "GI",
        currencyCode: "GIP",
        flagEmoji: "🇬🇮",
        currencySymbol: "£"
    },
    {
        name: "Greece",
        countryCode: "GR",
        currencyCode: "EUR",
        flagEmoji: "🇬🇷",
        currencySymbol: "€"
    },
    {
        name: "Greenland",
        countryCode: "GL",
        currencyCode: "DKK",
        flagEmoji: "🇬🇱",
        currencySymbol: "kr"
    },
    {
        name: "Grenada",
        countryCode: "GD",
        currencyCode: "XCD",
        flagEmoji: "🇬🇩",
        currencySymbol: "$"
    },
    {
        name: "Guadeloupe",
        countryCode: "GP",
        currencyCode: "EUR",
        flagEmoji: "🇬🇵",
        currencySymbol: "€"
    },
    {
        name: "Guam",
        countryCode: "GU",
        currencyCode: "USD",
        flagEmoji: "🇬🇺",
        currencySymbol: "$"
    },
    {
        name: "Guatemala",
        countryCode: "GT",
        currencyCode: "GTQ",
        flagEmoji: "🇬🇹",
        currencySymbol: "Q"
    },
    {
        name: "Guernsey",
        countryCode: "GG",
        currencyCode: "GBP",
        flagEmoji: "🇬🇬",
        currencySymbol: "£"
    },
    {
        name: "Guinea",
        countryCode: "GN",
        currencyCode: "GNF",
        flagEmoji: "🇬🇳",
        currencySymbol: "FG"
    },
    {
        name: "Guinea-Bissau",
        countryCode: "GW",
        currencyCode: "XOF",
        flagEmoji: "🇬🇼",
        currencySymbol: "CFA"
    },
    {
        name: "Guyana",
        countryCode: "GY",
        currencyCode: "GYD",
        flagEmoji: "🇬🇾",
        currencySymbol: "$"
    },
    {
        name: "Haiti",
        countryCode: "HT",
        currencyCode: "HTG",
        flagEmoji: "🇭🇹",
        currencySymbol: "G"
    },
    {
        name: "Heard Island and Mcdonald Islands",
        countryCode: "HM",
        currencyCode: "AUD",
        flagEmoji: "🇭🇲",
        currencySymbol: "$"
    },
    {
        name: "Holy See (Vatican City State)",
        countryCode: "VA",
        currencyCode: "EUR",
        flagEmoji: "🇻🇦",
        currencySymbol: "€"
    },
    {
        name: "Honduras",
        countryCode: "HN",
        currencyCode: "HNL",
        flagEmoji: "🇭🇳",
        currencySymbol: "L"
    },
    {
        name: "Hungary",
        countryCode: "HU",
        currencyCode: "HUF",
        flagEmoji: "🇭🇺",
        currencySymbol: "Ft"
    },
    {
        name: "Iceland",
        countryCode: "IS",
        currencyCode: "ISK",
        flagEmoji: "🇮🇸",
        currencySymbol: "kr"
    },
    {
        name: "India",
        countryCode: "IN",
        currencyCode: "INR",
        flagEmoji: "🇮🇳",
        currencySymbol: "₹"
    },
    {
        name: "Indonesia",
        countryCode: "ID",
        currencyCode: "IDR",
        flagEmoji: "🇮🇩",
        currencySymbol: "Rp"
    },
    {
        name: "Iran, Islamic Republic of",
        countryCode: "IR",
        currencyCode: "IRR",
        flagEmoji: "🇮🇷",
        currencySymbol: "﷼"
    },
    {
        name: "Iraq",
        countryCode: "IQ",
        currencyCode: "IQD",
        flagEmoji: "🇮🇶",
        currencySymbol: "ع.د"
    },
    {
        name: "Ireland",
        countryCode: "IE",
        currencyCode: "EUR",
        flagEmoji: "🇮🇪",
        currencySymbol: "€"
    },
    {
        name: "Isle of Man",
        countryCode: "IM",
        currencyCode: "GBP",
        flagEmoji: "🇮🇲",
        currencySymbol: "£"
    },
    {
        name: "Israel",
        countryCode: "IL",
        currencyCode: "ILS",
        flagEmoji: "🇮🇱",
        currencySymbol: "₪"
    },
    {
        name: "Italy",
        countryCode: "IT",
        currencyCode: "EUR",
        flagEmoji: "🇮🇹",
        currencySymbol: "€"
    },
    {
        name: "Jamaica",
        countryCode: "JM",
        currencyCode: "JMD",
        flagEmoji: "🇯🇲",
        currencySymbol: "J$"
    },
    {
        name: "Japan",
        countryCode: "JP",
        currencyCode: "JPY",
        flagEmoji: "🇯🇵",
        currencySymbol: "¥"
    },
    {
        name: "Jersey",
        countryCode: "JE",
        currencyCode: "GBP",
        flagEmoji: "🇯🇪",
        currencySymbol: "£"
    },
    {
        name: "Jordan",
        countryCode: "JO",
        currencyCode: "JOD",
        flagEmoji: "🇯🇴",
        currencySymbol: "JD"
    },
    {
        name: "Kazakhstan",
        countryCode: "KZ",
        currencyCode: "KZT",
        flagEmoji: "🇰🇿",
        currencySymbol: "лв"
    },
    {
        name: "Kenya",
        countryCode: "KE",
        currencyCode: "KES",
        flagEmoji: "🇰🇪",
        currencySymbol: "KSh"
    },
    {
        name: "Kiribati",
        countryCode: "KI",
        currencyCode: "AUD",
        flagEmoji: "🇰🇮",
        currencySymbol: "$"
    },
    {
        name: "Korea, Democratic People's Republic of",
        countryCode: "KP",
        currencyCode: "KPW",
        flagEmoji: "🇰🇵",
        currencySymbol: "₩"
    },
    {
        name: "Korea, Republic of",
        countryCode: "KR",
        currencyCode: "KRW",
        flagEmoji: "🇰🇷",
        currencySymbol: "₩"
    },
    {
        name: "Kuwait",
        countryCode: "KW",
        currencyCode: "KWD",
        flagEmoji: "🇰🇼",
        currencySymbol: "KD"
    },
    {
        name: "Kyrgyzstan",
        countryCode: "KG",
        currencyCode: "KGS",
        flagEmoji: "🇰🇬",
        currencySymbol: "лв"
    },
    {
        name: "Lao PDR",
        countryCode: "LA",
        currencyCode: "LAK",
        flagEmoji: "🇱🇦",
        currencySymbol: "₭"
    },
    {
        name: "Latvia",
        countryCode: "LV",
        currencyCode: "LVL",
        flagEmoji: "🇱🇻",
        currencySymbol: "Ls"
    },
    {
        name: "Lebanon",
        countryCode: "LB",
        currencyCode: "LBP",
        flagEmoji: "🇱🇧",
        currencySymbol: "£"
    },
    {
        name: "Lesotho",
        countryCode: "LS",
        currencyCode: "LSL",
        flagEmoji: "🇱🇸",
        currencySymbol: "M"
    },
    {
        name: "Liberia",
        countryCode: "LR",
        currencyCode: "LRD",
        flagEmoji: "🇱🇷",
        currencySymbol: "$"
    },
    {
        name: "Libya",
        countryCode: "LY",
        currencyCode: "LYD",
        flagEmoji: "🇱🇾",
        currencySymbol: "LD"
    },
    {
        name: "Liechtenstein",
        countryCode: "LI",
        currencyCode: "CHF",
        flagEmoji: "🇱🇮",
        currencySymbol: "CHF"
    },
    {
        name: "Lithuania",
        countryCode: "LT",
        currencyCode: "LTL",
        flagEmoji: "🇱🇹",
        currencySymbol: "Lt"
    },
    {
        name: "Luxembourg",
        countryCode: "LU",
        currencyCode: "EUR",
        flagEmoji: "🇱🇺",
        currencySymbol: "€"
    },
    {
        name: "Macedonia, Republic of",
        countryCode: "MK",
        currencyCode: "MKD",
        flagEmoji: "🇲🇰",
        currencySymbol: "ден"
    },
    {
        name: "Madagascar",
        countryCode: "MG",
        currencyCode: "MGA",
        flagEmoji: "🇲🇬",
        currencySymbol: "Ar"
    },
    {
        name: "Malawi",
        countryCode: "MW",
        currencyCode: "MWK",
        flagEmoji: "🇲🇼",
        currencySymbol: "MK"
    },
    {
        name: "Malaysia",
        countryCode: "MY",
        currencyCode: "MYR",
        flagEmoji: "🇲🇾",
        currencySymbol: "RM"
    },
    {
        name: "Maldives",
        countryCode: "MV",
        currencyCode: "MVR",
        flagEmoji: "🇲🇻",
        currencySymbol: "Rf"
    },
    {
        name: "Mali",
        countryCode: "ML",
        currencyCode: "XOF",
        flagEmoji: "🇲🇱",
        currencySymbol: "CFA"
    },
    {
        name: "Malta",
        countryCode: "MT",
        currencyCode: "EUR",
        flagEmoji: "🇲🇹",
        currencySymbol: "€"
    },
    {
        name: "Marshall Islands",
        countryCode: "MH",
        currencyCode: "USD",
        flagEmoji: "🇲🇭",
        currencySymbol: "$"
    },
    {
        name: "Martinique",
        countryCode: "MQ",
        currencyCode: "EUR",
        flagEmoji: "🇲🇶",
        currencySymbol: "€"
    },
    {
        name: "Mauritania",
        countryCode: "MR",
        currencyCode: "MRO",
        flagEmoji: "🇲🇷",
        currencySymbol: "UM"
    },
    {
        name: "Mauritius",
        countryCode: "MU",
        currencyCode: "MUR",
        flagEmoji: "🇲🇺",
        currencySymbol: "₨"
    },
    {
        name: "Mayotte",
        countryCode: "YT",
        currencyCode: "EUR",
        flagEmoji: "🇾🇹",
        currencySymbol: "€"
    },
    {
        name: "Mexico",
        countryCode: "MX",
        currencyCode: "MXN",
        flagEmoji: "🇲🇽",
        currencySymbol: "$"
    },
    {
        name: "Micronesia, Federated States of",
        countryCode: "FM",
        currencyCode: "USD",
        flagEmoji: "🇫🇲",
        currencySymbol: "$"
    },
    {
        name: "Moldova",
        countryCode: "MD",
        currencyCode: "MDL",
        flagEmoji: "🇲🇩",
        currencySymbol: "lei"
    },
    {
        name: "Monaco",
        countryCode: "MC",
        currencyCode: "EUR",
        flagEmoji: "🇲🇨",
        currencySymbol: "€"
    },
    {
        name: "Mongolia",
        countryCode: "MN",
        currencyCode: "MNT",
        flagEmoji: "🇲🇳",
        currencySymbol: "₮"
    },
    {
        name: "Montenegro",
        countryCode: "ME",
        currencyCode: "EUR",
        flagEmoji: "🇲🇪",
        currencySymbol: "€"
    },
    {
        name: "Montserrat",
        countryCode: "MS",
        currencyCode: "XCD",
        flagEmoji: "🇲🇸",
        currencySymbol: "$"
    },
    {
        name: "Morocco",
        countryCode: "MA",
        currencyCode: "MAD",
        flagEmoji: "🇲🇦",
        currencySymbol: "MAD"
    },
    {
        name: "Mozambique",
        countryCode: "MZ",
        currencyCode: "MZN",
        flagEmoji: "🇲🇿",
        currencySymbol: "MT"
    },
    {
        name: "Myanmar",
        countryCode: "MM",
        currencyCode: "MMK",
        flagEmoji: "🇲🇲",
        currencySymbol: "K"
    },
    {
        name: "Namibia",
        countryCode: "NA",
        currencyCode: "NAD",
        flagEmoji: "🇳🇦",
        currencySymbol: "$"
    },
    {
        name: "Nauru",
        countryCode: "NR",
        currencyCode: "AUD",
        flagEmoji: "🇳🇷",
        currencySymbol: "$"
    },
    {
        name: "Nepal",
        countryCode: "NP",
        currencyCode: "NPR",
        flagEmoji: "🇳🇵",
        currencySymbol: "₨"
    },
    {
        name: "Netherlands",
        countryCode: "NL",
        currencyCode: "EUR",
        flagEmoji: "🇳🇱",
        currencySymbol: "€"
    },
    {
        name: "Netherlands Antilles",
        countryCode: "AN",
        currencyCode: "ANG",
        flagEmoji: "🇧🇶",
        currencySymbol: "ƒ"
    },
    {
        name: "New Caledonia",
        countryCode: "NC",
        currencyCode: "XPF",
        flagEmoji: "🇳🇨",
        currencySymbol: "₣"
    },
    {
        name: "New Zealand",
        countryCode: "NZ",
        currencyCode: "NZD",
        flagEmoji: "🇳🇿",
        currencySymbol: "$"
    },
    {
        name: "Nicaragua",
        countryCode: "NI",
        currencyCode: "NIO",
        flagEmoji: "🇳🇮",
        currencySymbol: "C$"
    },
    {
        name: "Niger",
        countryCode: "NE",
        currencyCode: "XOF",
        flagEmoji: "🇳🇪",
        currencySymbol: "CFA"
    },
    {
        name: "Nigeria",
        countryCode: "NG",
        currencyCode: "NGN",
        flagEmoji: "🇳🇬",
        currencySymbol: "₦"
    },
    {
        name: "Niue",
        countryCode: "NU",
        currencyCode: "NZD",
        flagEmoji: "🇳🇺",
        currencySymbol: "$"
    },
    {
        name: "Norfolk Island",
        countryCode: "NF",
        currencyCode: "AUD",
        flagEmoji: "🇳🇫",
        currencySymbol: "$"
    },
    {
        name: "Northern Mariana Islands",
        countryCode: "MP",
        currencyCode: "USD",
        flagEmoji: "🇲🇵",
        currencySymbol: "$"
    },
    {
        name: "Norway",
        countryCode: "NO",
        currencyCode: "NOK",
        flagEmoji: "🇳🇴",
        currencySymbol: "kr"
    },
    {
        name: "Oman",
        countryCode: "OM",
        currencyCode: "OMR",
        flagEmoji: "🇴🇲",
        currencySymbol: "﷼"
    },
    {
        name: "Pakistan",
        countryCode: "PK",
        currencyCode: "PKR",
        flagEmoji: "🇵🇰",
        currencySymbol: "₨"
    },
    {
        name: "Palau",
        countryCode: "PW",
        currencyCode: "USD",
        flagEmoji: "🇵🇼",
        currencySymbol: "$"
    },
    {
        name: "Palestinian Territory, Occupied",
        countryCode: "PS",
        currencyCode: "ILS",
        flagEmoji: "🇵🇸",
        currencySymbol: "₪"
    },
    {
        name: "Panama",
        countryCode: "PA",
        currencyCode: "PAB",
        flagEmoji: "🇵🇦",
        currencySymbol: "B/."
    },
    {
        name: "Papua New Guinea",
        countryCode: "PG",
        currencyCode: "PGK",
        flagEmoji: "🇵🇬",
        currencySymbol: "K"
    },
    {
        name: "Paraguay",
        countryCode: "PY",
        currencyCode: "PYG",
        flagEmoji: "🇵🇾",
        currencySymbol: "Gs"
    },
    {
        name: "Peru",
        countryCode: "PE",
        currencyCode: "PEN",
        flagEmoji: "🇵🇪",
        currencySymbol: "S/."
    },
    {
        name: "Philippines",
        countryCode: "PH",
        currencyCode: "PHP",
        flagEmoji: "🇵🇭",
        currencySymbol: "₱"
    },
    {
        name: "Pitcairn",
        countryCode: "PN",
        currencyCode: "NZD",
        flagEmoji: "🇵🇳",
        currencySymbol: "$"
    },
    {
        name: "Poland",
        countryCode: "PL",
        currencyCode: "PLN",
        flagEmoji: "🇵🇱",
        currencySymbol: "zł"
    },
    {
        name: "Portugal",
        countryCode: "PT",
        currencyCode: "EUR",
        flagEmoji: "🇵🇹",
        currencySymbol: "€"
    },
    {
        name: "Puerto Rico",
        countryCode: "PR",
        currencyCode: "USD",
        flagEmoji: "🇵🇷",
        currencySymbol: "$"
    },
    {
        name: "Qatar",
        countryCode: "QA",
        currencyCode: "QAR",
        flagEmoji: "🇶🇦",
        currencySymbol: "﷼"
    },
    {
        name: "Réunion",
        countryCode: "RE",
        currencyCode: "EUR",
        flagEmoji: "🇷🇪",
        currencySymbol: "€"
    },
    {
        name: "Romania",
        countryCode: "RO",
        currencyCode: "RON",
        flagEmoji: "🇷🇴",
        currencySymbol: "lei"
    },
    {
        name: "Russian Federation",
        countryCode: "RU",
        currencyCode: "RUB",
        flagEmoji: "🇷🇺",
        currencySymbol: "₽"
    },
    {
        name: "Rwanda",
        countryCode: "RW",
        currencyCode: "RWF",
        flagEmoji: "🇷🇼",
        currencySymbol: "R₣"
    },
    {
        name: "Saint-Barthélemy",
        countryCode: "BL",
        currencyCode: "EUR",
        flagEmoji: "🇧🇱",
        currencySymbol: "€"
    },
    {
        name: "Saint Helena",
        countryCode: "SH",
        currencyCode: "SHP",
        flagEmoji: "🇸🇭",
        currencySymbol: "£"
    },
    {
        name: "Saint Kitts and Nevis",
        countryCode: "KN",
        currencyCode: "XCD",
        flagEmoji: "🇰🇳",
        currencySymbol: "$"
    },
    {
        name: "Saint Lucia",
        countryCode: "LC",
        currencyCode: "XCD",
        flagEmoji: "🇱🇨",
        currencySymbol: "$"
    },
    {
        name: "Saint-Martin (French part)",
        countryCode: "MF",
        currencyCode: "EUR",
        flagEmoji: "🇲🇫",
        currencySymbol: "€"
    },
    {
        name: "Saint Pierre and Miquelon",
        countryCode: "PM",
        currencyCode: "EUR",
        flagEmoji: "🇵🇲",
        currencySymbol: "€"
    },
    {
        name: "Saint Vincent and Grenadines",
        countryCode: "VC",
        currencyCode: "XCD",
        flagEmoji: "🇻🇨",
        currencySymbol: "$"
    },
    {
        name: "Samoa",
        countryCode: "WS",
        currencyCode: "WST",
        flagEmoji: "🇼🇸",
        currencySymbol: "WS$"
    },
    {
        name: "San Marino",
        countryCode: "SM",
        currencyCode: "EUR",
        flagEmoji: "🇸🇲",
        currencySymbol: "€"
    },
    {
        name: "Sao Tome and Principe",
        countryCode: "ST",
        currencyCode: "STD",
        flagEmoji: "🇸🇹",
        currencySymbol: "Db"
    },
    {
        name: "Saudi Arabia",
        countryCode: "SA",
        currencyCode: "SAR",
        flagEmoji: "🇸🇦",
        currencySymbol: "﷼"
    },
    {
        name: "Senegal",
        countryCode: "SN",
        currencyCode: "XOF",
        flagEmoji: "🇸🇳",
        currencySymbol: "CFA"
    },
    {
        name: "Serbia",
        countryCode: "RS",
        currencyCode: "RSD",
        flagEmoji: "🇷🇸",
        currencySymbol: "Дин."
    },
    {
        name: "Seychelles",
        countryCode: "SC",
        currencyCode: "SCR",
        flagEmoji: "🇸🇨",
        currencySymbol: "₨"
    },
    {
        name: "Sierra Leone",
        countryCode: "SL",
        currencyCode: "SLL",
        flagEmoji: "🇸🇱",
        currencySymbol: "Le"
    },
    {
        name: "Singapore",
        countryCode: "SG",
        currencyCode: "SGD",
        flagEmoji: "🇸🇬",
        currencySymbol: "$"
    },
    {
        name: "Slovakia",
        countryCode: "SK",
        currencyCode: "EUR",
        flagEmoji: "🇸🇰",
        currencySymbol: "€"
    },
    {
        name: "Slovenia",
        countryCode: "SI",
        currencyCode: "EUR",
        flagEmoji: "🇸🇮",
        currencySymbol: "€"
    },
    {
        name: "Solomon Islands",
        countryCode: "SB",
        currencyCode: "SBD",
        flagEmoji: "🇸🇧",
        currencySymbol: "$"
    },
    {
        name: "Somalia",
        countryCode: "SO",
        currencyCode: "SOS",
        flagEmoji: "🇸🇴",
        currencySymbol: "S"
    },
    {
        name: "South Africa",
        countryCode: "ZA",
        currencyCode: "ZAR",
        flagEmoji: "🇿🇦",
        currencySymbol: "R"
    },
    {
        name: "South Georgia and the South Sandwich Islands",
        countryCode: "GS",
        currencyCode: "GBP",
        flagEmoji: "🇬🇸",
        currencySymbol: "£"
    },
    {
        name: "Spain",
        countryCode: "ES",
        currencyCode: "EUR",
        flagEmoji: "🇪🇸",
        currencySymbol: "€"
    },
    {
        name: "Sri Lanka",
        countryCode: "LK",
        currencyCode: "LKR",
        flagEmoji: "🇱🇰",
        currencySymbol: "₨"
    },
    {
        name: "Sudan",
        countryCode: "SD",
        currencyCode: "SDG",
        flagEmoji: "🇸🇩",
        currencySymbol: "ج.س."
    },
    {
        name: "Suriname *",
        countryCode: "SR",
        currencyCode: "SRD",
        flagEmoji: "🇸🇷",
        currencySymbol: "$"
    },
    {
        name: "Svalbard and Jan Mayen Islands",
        countryCode: "SJ",
        currencyCode: "NOK",
        flagEmoji: "🇸🇯",
        currencySymbol: "kr"
    },
    {
        name: "Swaziland",
        countryCode: "SZ",
        currencyCode: "SZL",
        flagEmoji: "🇸🇿",
        currencySymbol: "E"
    },
    {
        name: "Sweden",
        countryCode: "SE",
        currencyCode: "SEK",
        flagEmoji: "🇸🇪",
        currencySymbol: "kr"
    },
    {
        name: "Switzerland",
        countryCode: "CH",
        currencyCode: "CHF",
        flagEmoji: "🇨🇭",
        currencySymbol: "CHF"
    },
    {
        name: "Syrian Arab Republic (Syria)",
        countryCode: "SY",
        currencyCode: "SYP",
        flagEmoji: "🇸🇾",
        currencySymbol: "£"
    },
    {
        name: "Taiwan, Republic of China",
        countryCode: "TW",
        currencyCode: "TWD",
        flagEmoji: "🇹🇼",
        currencySymbol: "NT$"
    },
    {
        name: "Tajikistan",
        countryCode: "TJ",
        currencyCode: "TJS",
        flagEmoji: "🇹🇯",
        currencySymbol: "SM"
    },
    {
        name: "Tanzania *, United Republic of",
        countryCode: "TZ",
        currencyCode: "TZS",
        flagEmoji: "🇹🇿",
        currencySymbol: "TSh"
    },
    {
        name: "Thailand",
        countryCode: "TH",
        currencyCode: "THB",
        flagEmoji: "🇹🇭",
        currencySymbol: "฿"
    },
    {
        name: "Timor-Leste",
        countryCode: "TL",
        currencyCode: "USD",
        flagEmoji: "🇹🇱",
        currencySymbol: "$"
    },
    {
        name: "Togo",
        countryCode: "TG",
        currencyCode: "XOF",
        flagEmoji: "🇹🇬",
        currencySymbol: "CFA"
    },
    {
        name: "Tokelau",
        countryCode: "TK",
        currencyCode: "NZD",
        flagEmoji: "🇹🇰",
        currencySymbol: "$"
    },
    {
        name: "Tonga",
        countryCode: "TO",
        currencyCode: "TOP",
        flagEmoji: "🇹🇴",
        currencySymbol: "T$"
    },
    {
        name: "Trinidad and Tobago",
        countryCode: "TT",
        currencyCode: "TTD",
        flagEmoji: "🇹🇹",
        currencySymbol: "TT$"
    },
    {
        name: "Tunisia",
        countryCode: "TN",
        currencyCode: "TND",
        flagEmoji: "🇹🇳",
        currencySymbol: "د.ت"
    },
    {
        name: "Turkey",
        countryCode: "TR",
        currencyCode: "TRY",
        flagEmoji: "🇹🇷",
        currencySymbol: "₺"
    },
    {
        name: "Turkmenistan",
        countryCode: "TM",
        currencyCode: "TMT",
        flagEmoji: "🇹🇲",
        currencySymbol: "T"
    },
    {
        name: "Turks and Caicos Islands",
        countryCode: "TC",
        currencyCode: "USD",
        flagEmoji: "🇹🇨",
        currencySymbol: "$"
    },
    {
        name: "Tuvalu",
        countryCode: "TV",
        currencyCode: "AUD",
        flagEmoji: "🇹🇻",
        currencySymbol: "$"
    },
    {
        name: "Uganda",
        countryCode: "UG",
        currencyCode: "UGX",
        flagEmoji: "🇺🇬",
        currencySymbol: "USh"
    },
    {
        name: "Ukraine",
        countryCode: "UA",
        currencyCode: "UAH",
        flagEmoji: "🇺🇦",
        currencySymbol: "₴"
    },
    {
        name: "United Arab Emirates",
        countryCode: "AE",
        currencyCode: "AED",
        flagEmoji: "🇦🇪",
        currencySymbol: "د.إ"
    },
    {
        name: "United Kingdom",
        countryCode: "GB",
        currencyCode: "GBP",
        flagEmoji: "🇬🇧",
        currencySymbol: "£"
    },
    {
        name: "United States of America",
        countryCode: "US",
        currencyCode: "USD",
        flagEmoji: "🇺🇸",
        currencySymbol: "$"
    },
    {
        name: "United States Minor Outlying Islands",
        countryCode: "UM",
        currencyCode: "USD",
        flagEmoji: "🇺🇲",
        currencySymbol: "$"
    },
    {
        name: "Uruguay",
        countryCode: "UY",
        currencyCode: "UYU",
        flagEmoji: "🇺🇾",
        currencySymbol: "$U"
    },
    {
        name: "Uzbekistan",
        countryCode: "UZ",
        currencyCode: "UZS",
        flagEmoji: "🇺🇿",
        currencySymbol: "лв"
    },
    {
        name: "Vanuatu",
        countryCode: "VU",
        currencyCode: "VUV",
        flagEmoji: "🇻🇺",
        currencySymbol: "VT"
    },
    {
        name: "Venezuela (Bolivarian Republic of)",
        countryCode: "VE",
        currencyCode: "VES",
        flagEmoji: "🇻🇪",
        currencySymbol: "Bs"
    },
    {
        name: "Viet Nam",
        countryCode: "VN",
        currencyCode: "VND",
        flagEmoji: "🇻🇳",
        currencySymbol: "₫"
    },
    {
        name: "Virgin Islands, US",
        countryCode: "VI",
        currencyCode: "USD",
        flagEmoji: "🇻🇮",
        currencySymbol: "$"
    },
    {
        name: "Wallis and Futuna Islands",
        countryCode: "WF",
        currencyCode: "XPF",
        flagEmoji: "🇼🇫",
        currencySymbol: "₣"
    },
    {
        name: "Western Sahara",
        countryCode: "EH",
        currencyCode: "MAD",
        flagEmoji: "🇪🇭",
        currencySymbol: "MAD"
    },
    {
        name: "Yemen",
        countryCode: "YE",
        currencyCode: "YER",
        flagEmoji: "🇾🇪",
        currencySymbol: "﷼"
    },
    {
        name: "Zambia",
        countryCode: "ZM",
        currencyCode: "ZMK",
        flagEmoji: "🇿🇲",
        currencySymbol: "ZK"
    },
    {
        name: "Zimbabwe",
        countryCode: "ZW",
        currencyCode: "ZWL",
        flagEmoji: "🇿🇼",
        currencySymbol: "Z$"
    }
];
