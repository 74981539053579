import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { deleteJobMatch } from '../../graphql/default';

export const withDeleteJobMatch = (Component) => {
	return graphql(gql(deleteJobMatch), {
		props: (props) => ({
			onDeleteJobMatch(input) {
				const optimisticResponseData = {
					...props.jobMatch,
					...input.input,
				};
				props.mutate({
					variables: input,
					optimisticResponse: {
						__typeName: 'Mutation',
						deleteContact: {
							...optimisticResponseData,
							__typeName: 'deleteJobMatch',
						},
					},
				});
			},
		}),
	})(Component);
};
