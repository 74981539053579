import React, { useState, useEffect } from 'react';
import { Form, message } from 'antd';
import RecognitionSettingsPresentation from '../../presentation/RecognitionSettingsPresentation';
import { snappy } from 'src/_shared';
import { Spinner } from 'src/_shared';
import { withGetCompany } from 'src/_shared/api/components/settings/';
import { connect } from 'react-redux';
import { userActions } from 'src/actions.js';

function RecognitionSettingsContainer(props) {
	const { currentUser, onUpdateCompany, updateCurrentUserCompany } = props;
	const [campaigns, setCampaigns] = useState([]);
	const [snappyCampaigns, setSnappyCampaigns] = useState([]);
	const [formVisibleCampaignId, setFormVisibleCampaignId] = useState(null);
	const [showCreateCampaignForm, setShowCreateCampaignForm] = useState(false);
	const [inlineEditingIndex, setInlineEditingIndex] = useState(null);
	const [inlineFormData, setInlineFormData] = useState({});
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [deletingTierId, setDeletingTierId] = useState(null);
	const [updatingTierId, setUpdatingTierId] = useState(null);
	const [form] = Form.useForm();
	const [createCampaignForm] = Form.useForm();
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		getCampaigns();
		getSnappyCampaigns();
	}, []);

	const getCampaigns = async () => {
		try {
			setLoading(true);
			const response = await snappy({
				type: 'getCampaigns',
				userId: currentUser.id,
			});
			setCampaigns(response || []);
			setLoading(false);
		} catch (error) {
			console.error('Error fetching campaigns:', error);
			setLoading(false);
		}
	};

	const getSnappyCampaigns = async () => {
		try {
			const response = await snappy({
				type: 'getSnappyCampaigns',
				userId: currentUser.id,
			});
			setSnappyCampaigns(response || []);
		} catch (error) {
			console.error('Error fetching snappyCampaigns:', error);
		}
	};

	const onCreateCampaign = async (values) => {
		setIsSubmitting(true);
		try {
			const payload = {
				type: 'createCampaign',
				userId: currentUser.id,
				campaignName: values.campaignName,
				snappyAPIKey: values.snappyAPIKey,
			};

			const response = await snappy(payload);

			setCampaigns([{ ...payload, id: response.id }]);

			message.success('Campaign created successfully!');
			setShowCreateCampaignForm(false);
			createCampaignForm.resetFields();
		} catch (error) {
			console.error('Error creating campaign:', error);
			message.error('Failed to create campaign.');
		} finally {
			setIsSubmitting(false);
		}
	};

	const onFinish = async (values, campaignId) => {
		setIsSubmitting(true);
		try {
			const payload = {
				campaignId,
				pointsRequired: values.pointsRequired,
				snappyCampaignId: values.snappyCampaignId,
				tierName: values.tierName,
				tierDescription: values.tierDescription,
				type: 'createCampaignTier',
				userId: currentUser.id,
			};

			const response = await snappy(payload);

			const updatedCampaigns = campaigns.map((campaign) =>
				campaign.id === campaignId
					? {
							...campaign,
							tiers: [...campaign.tiers, { ...payload, id: response.id }],
						}
					: campaign
			);

			setCampaigns(updatedCampaigns);

			setFormVisibleCampaignId(null);
			setIsSubmitting(false);
			message.success('Tier created successfully!');
		} catch (error) {
			console.error('Error creating campaign tier:', error);
			setIsSubmitting(false);
		}
	};

	const onDelete = async (id, campaignId) => {
		setDeletingTierId(id);
		try {
			await snappy({
				type: 'deleteCampaignTier',
				userId: currentUser.id,
				id: id,
			});

			const updatedCampaigns = campaigns.map((campaign) =>
				campaign.id === campaignId
					? {
							...campaign,
							tiers: campaign.tiers.filter((tier) => tier.id !== id),
						}
					: campaign
			);
			setCampaigns(updatedCampaigns);

			message.success('Tier deleted successfully!');
		} catch (error) {
			console.error('Error deleting campaign tier:', error);
		} finally {
			setDeletingTierId(null);
		}
	};

	const onInlineEditSave = async (id, updatedTier, campaignId) => {
		setUpdatingTierId(id);
		try {
			const payload = {
				id,
				campaignId,
				pointsRequired: updatedTier.pointsRequired,
				snappyCampaignId: updatedTier.snappyCampaignId,
				tierName: updatedTier.tierName,
				tierDescription: updatedTier.tierDescription,
				type: 'updateCampaignTier',
				userId: currentUser.id,
			};

			await snappy(payload);

			const updatedCampaigns = campaigns.map((campaign) =>
				campaign.id === campaignId
					? {
							...campaign,
							tiers: campaign.tiers.map((tier) =>
								tier.id === id ? { ...updatedTier, id } : tier
							),
						}
					: campaign
			);

			setCampaigns(updatedCampaigns);
			message.success('Tier updated successfully!');
			setInlineEditingIndex(null);
		} catch (error) {
			console.error('Error updating campaign tier:', error);
			message.error('Failed to update the tier.');
		} finally {
			setUpdatingTierId(null);
		}
	};

	const onInlineEditCancel = () => {
		setInlineEditingIndex(null);
	};

	const handleInlineInputChange = (key, value) => {
		setInlineFormData({
			...inlineFormData,
			[key]: value,
		});
	};

	const handleInlineEditClick = (id, tier) => {
		setInlineEditingIndex(id);
		setInlineFormData(tier);
	};

	const handleUpdateCompany = async (values) => {
		try {
			const result = await onUpdateCompany({input: {id: currentUser?.company?.id, ...values}});
			if (result?.data?.updateCompany) {
				updateCurrentUserCompany(result?.data?.updateCompany);
				message.success('Company updated successfully!');
			}
		} catch (error) {
			message.error('Something went wrong, please try again later');
			console.log(error);
		}

	}

	if (loading) {
		return <Spinner />;
	}

	return (
		<RecognitionSettingsPresentation
			currentUser={currentUser}
			handleUpdateCompany={handleUpdateCompany}
			campaigns={campaigns}
			snappyCampaigns={snappyCampaigns}
			formVisibleCampaignId={formVisibleCampaignId}
			setFormVisibleCampaignId={setFormVisibleCampaignId}
			onFinish={onFinish}
			onDelete={onDelete}
			onCreateCampaign={onCreateCampaign}
			showCreateCampaignForm={showCreateCampaignForm}
			setShowCreateCampaignForm={setShowCreateCampaignForm}
			inlineEditingIndex={inlineEditingIndex}
			onInlineEditSave={onInlineEditSave}
			onInlineEditCancel={onInlineEditCancel}
			form={form}
			createCampaignForm={createCampaignForm}
			inlineFormData={inlineFormData}
			handleInlineInputChange={handleInlineInputChange}
			handleInlineEditClick={handleInlineEditClick}
			isSubmitting={isSubmitting}
			deletingTierId={deletingTierId}
			updatingTierId={updatingTierId}
		/>
	);
}

const mapStateToProperties = (state) => {
	return {
	};
};

const mapDispatchToProperties = (dispatch) => {
	return {
		updateCurrentUserCompany(company) {
			dispatch(userActions.updateUserCompany(company));
		}
	};
};

export default withGetCompany(connect(mapStateToProperties,mapDispatchToProperties)(RecognitionSettingsContainer));
