import gql from 'graphql-tag';
import { compose, graphql } from 'react-apollo';
import {
	GetOpenJobsCountByCompany,
	ListJobs,
} from '../../graphql/custom/dashboard';
import { getDashboardJobs } from '../../graphql/default';

export const withDashboardJobs = (Component, variables) => {
	return compose(
		graphql(GetOpenJobsCountByCompany, {
			options: (props) => ({
				context: {
					headers: {
						'x-frame-options': 'deny', // This header will reach the server
					},
				},
				variables: {
					companyId: props.currentUser.companyId,
				},
				fetchPolicy: 'cache-and-network',
			}),
			props(props) {
				if (!props.data.getOpenJobsCountByCompany) {
					setTimeout(props.data.refetch, 2000);
					return null;
				}

				return {
					openPositions: props.data.getOpenJobsCountByCompany
						? props.data.getOpenJobsCountByCompany.openJobs
						: 0,
					bonusJobs: props.data.getOpenJobsCountByCompany
						? props.data.getOpenJobsCountByCompany.bonusJobs
							? JSON.parse(props.data.getOpenJobsCountByCompany.bonusJobs)
							: null
						: null,
					bonusReferral: props.data.getOpenJobsCountByCompany
						? props.data.getOpenJobsCountByCompany.bonusReferral
							? JSON.parse(props.data.getOpenJobsCountByCompany.bonusReferral)
							: null
						: null,
					employees: props.data.getOpenJobsCountByCompany
						? props.data.getOpenJobsCountByCompany.employees
							? JSON.parse(props.data.getOpenJobsCountByCompany.employees)
							: null
						: null,
					bonuses: props.data.getOpenJobsCountByCompany
						? props.data.getOpenJobsCountByCompany.bonuses
							? JSON.parse(props.data.getOpenJobsCountByCompany.bonuses)
							: null
						: null,
					dashboardLastUpdated: props.data.getOpenJobsCountByCompany
						? props.data.getOpenJobsCountByCompany.dashboardLastUpdated
							? props.data.getOpenJobsCountByCompany.dashboardLastUpdated
							: null
						: null,
					referralContact: props.data.getOpenJobsCountByCompany
						? props.data.getOpenJobsCountByCompany.referralContact
						: 0,
					referralsThisYear: props.data.getOpenJobsCountByCompany
						? props.data.getOpenJobsCountByCompany.referralsThisYear
						: 0,
					referralsThisMonth: props.data.getOpenJobsCountByCompany
						? props.data.getOpenJobsCountByCompany.referralsThisMonth
						: 0,
					referralsLastMonth: props.data.getOpenJobsCountByCompany
						? props.data.getOpenJobsCountByCompany.referralsLastMonth
						: 0,
					acceptedThisMonth: props.data.getOpenJobsCountByCompany
						? props.data.getOpenJobsCountByCompany.acceptedThisMonth
						: 0,
					acceptedLastMonth: props.data.getOpenJobsCountByCompany
						? props.data.getOpenJobsCountByCompany.acceptedLastMonth
						: 0,
				};
			},
		}),
		graphql(gql(getDashboardJobs), {
			options: {
				context: {
					headers: {
						'x-frame-options': 'deny', // This header will reach the server
					},
				},
				variables,
				fetchPolicy: 'cache-and-network',
			},
			props(props) {
				if (
					!props.data.loading &&
					(!props.data.getDashboardJobs || props.data.error)
				) {
					setTimeout(props.data.refetch, 2000);
					return null;
				}

				return {
					inNetworkMatches: props.data.getDashboardJobs
						? props.data.getDashboardJobs.inNetworkMatches
						: [],
					jobShares: props.data.getDashboardJobs
						? props.data.getDashboardJobs.jobShares
						: [],
					totalJobViews: props.data.getDashboardJobs
						? props.data.getDashboardJobs.totalJobViews
						: [],
				};
			},
		}),
		graphql(ListJobs, {
			options: {
				context: {
					headers: {
						'x-frame-options': 'deny', // This header will reach the server
					},
				},
				variables,
				fetchPolicy: 'cache-and-network',
			},
			props(props) {
				if (!props.data.loading && (!props.data.listJobs || props.data.error)) {
					setTimeout(props.data.refetch, 2000);
					return null;
				}

				return {
					jobs: props.data.listJobs ? props.data.listJobs.items : undefined,
				};
			},
		})
	)(Component);
};
