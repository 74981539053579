import React from "react";
import { useSelector } from "react-redux";
import { Spinner } from "../_shared/index.js";
import AllRewardsAdminContainer from "./container/AllRewardsAdminContainer";
import { AllRewardsAdminServiceProvider } from "./service/AllRewardsAdmin.service.jsx";

const AllRewardsAdmin = () => {
  const company = useSelector((state) => state.user.currentUser.company)
  if (!company) return <Spinner />
  return <>
    <AllRewardsAdminServiceProvider>
      <AllRewardsAdminContainer />
    </AllRewardsAdminServiceProvider>
  </>;
};

export default AllRewardsAdmin;
