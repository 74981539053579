export const createDepartment = `mutation CreateDepartment($input: CreateDepartmentInput!) {
  createDepartment(input: $input) {
    id
    companyId
    name
    active
    totalUsers
    userDepartments {
      id
      userId
      user {
        id
        cognitoId
        companyId
        emailAddress
        firstName
        lastName
        title
        departmentId
        lastLogin
        lastNotificationCheck
        contacts {
          id
          firstName
          lastName
          emailAddress
          socialMediaAccounts
          userId
          companyId
          phoneNumber
          jobHistory
          fullContactData
          dateCreated
        }
        totalReferrals
        webNotifications {
          id
          userId
          requestingUserId
          contactId
          referralId
          jobId
          matches
          message
          dateCreated
        }
        connectedApps
        active
        createdById
        dateCreated
        
        
        ytdReferralCount
      }
      departmentId
      department {
        id
        companyId
        name
        active
        totalUsers
        userDepartments {
          id
          userId
          departmentId
        }
      }
    }
  }
}
`;
