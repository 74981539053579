import RewardsProgress from '../../presentation/rewardProgress/RewardsProgress';
import { snappy } from 'src/_shared';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

function RewardsProgressContainer() {
	const [campaigns, setCampaigns] = useState([]);
	const currentUser = useSelector((state) => state.user.currentUser);

	useEffect(() => {
		getCampaigns();
	}, []);

	const getCampaigns = async () => {
		try {
			const response = await snappy({
				type: 'getCampaigns',
				userId: currentUser.id,
			});
			setCampaigns(response || []);
		} catch (error) {
			console.error('Error getCampaigns:', error);
		}
	};

	return (
		<>
			{campaigns.length > 0 && campaigns[0]?.tiers ? (
				<RewardsProgress currentUser={currentUser} tiers={campaigns[0].tiers} />
			) : null}
		</>
	);
}

export default RewardsProgressContainer;
