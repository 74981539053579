import { List } from 'antd';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import get from 'lodash/get';
import { Link } from 'react-router-dom';
import WebIcon from 'src/_shared/components/WebIconComponent.jsx';
import ErinLogo from '../../_shared/assets/erin_square.png';
import Party from '../../_shared/assets/partysmall.png';
import { NOTIFICATIONS } from '../../_shared/constants';
import {
	ListItemPageStyles,
	ListItemPageStylesBlue,
	ListItemStyles,
	ListItemStylesBlue,
} from '../webNotificationsStyles.js';

dayjs.extend(relativeTime);

function WebNotificationList({
	sortedNotifications,
	currentUser: {
		lastNotificationCheck,
		firstName,
		lastName,
		company: { referralStatus = undefined } = {},
	},
	dropDown,
}) {
	const filteredNotifications = sortedNotifications.filter(
		(notification) =>
			(notification.type === 'jobCreated' &&
				get(notification, 'job.title', false)) ||
			(notification.type === 'referralCreated' &&
				get(notification, 'referral', false)) ||
			(notification.type === 'referralAccepted' &&
				get(notification, 'referral', false)) ||
			(notification.type === 'referralHired' &&
				get(notification, 'referral', false)) ||
			(notification.type === 'referralRequested' &&
				get(notification, 'referralRequestedStatus', false)) ||
			(notification.type === 'contactImported' &&
				get(notification, 'user.id', false)) ||
			(notification.type === 'jobSummaryEmail' &&
				get(notification, 'user.id', false)) ||
			(notification.type === 'empMessageCenter' &&
				get(notification, 'user.id', false)) ||
			(notification.type === 'socialMediaShare' &&
				get(notification, 'user.id', false)) ||
			(notification.type === 'referralSent' &&
				get(notification, 'referral', false)) ||
			(notification.type === 'generalReferral' &&
				get(notification, 'referral', false)) ||
			(notification.type === 'pointsReferralAccepted' &&
				get(notification, 'referral', false)) ||
			(notification.type === 'pointsReferralInterviewing' &&
				get(notification, 'referral', false)) ||
			(notification.type === 'pointsReferralHired' &&
				get(notification, 'referral', false))
	);
	const pagination = {
		showTotal(total, range) {
			return `${range[0]}-${range[1]} of ${total} notifications`;
		},
	};

	const renderAvatar = (notification) => {
		const { type, requestingUser, referral, user } = notification;

		switch (type) {
			case NOTIFICATIONS.REFERRAL_CREATED: {
				return referral && referral.user.avatar ? (
					<div className="notification-icon">
						<img
							src={referral.user.avatar}
							alt={`${referral.user.firstName} ${referral.user.lastName}`}
						/>
					</div>
				) : referral ? (
					<div className="notification-icon">
						<h4>
							{referral.user.firstName && referral.user.firstName[0]}
							{referral.user.lastName && referral.user.lastName[0]}
						</h4>
					</div>
				) : (
					<div />
				);
			}

			case NOTIFICATIONS.REFERRAL_REQUESTED: {
				return requestingUser.avatar ? (
					<div className="notification-icon">
						<img
							src={requestingUser.avatar}
							alt={`${requestingUser.firstName} ${requestingUser.lastName}`}
						/>
					</div>
				) : (
					<div className="notification-icon">
						<h4>
							{requestingUser.firstName && requestingUser.firstName[0]}
							{requestingUser.lastName && requestingUser.lastName[0]}
						</h4>
					</div>
				);
			}

			case NOTIFICATIONS.REFERRAL_ACCEPTED: {
				return referral && referral.contact && referral.contact.avatar ? (
					<div className="notification-icon">
						<img
							src={referral.contact.avatar}
							alt={`${referral.contact.firstName} ${referral.contact.lastName}`}
						/>
					</div>
				) : referral && referral.contact ? (
					<div className="notification-icon">
						<h4>
							{referral.contact.firstName && referral.contact.firstName[0]}
							{referral.contact.lastName && referral.contact.lastName[0]}
						</h4>
					</div>
				) : (
					<></>
				);
			}

			case NOTIFICATIONS.JOB_CREATED: {
				return (
					<div className="notification-icon">
						<WebIcon name="id" size={26} color="text-secondary" />
					</div>
				);
			}

			case NOTIFICATIONS.REFERRAL_HIRED: {
				return (
					<div className="notification-icon">
						<WebIcon name="id" size={26} color="text-secondary" />
					</div>
				);
			}

			case NOTIFICATIONS.CONTACTS_IMPORTED: {
				return referral && referral.user.avatar ? (
					<img
						src={referral.user.avatar}
						alt={`${referral.user.firstName} ${referral.user.lastName}`}
					/>
				) : referral ? (
					<h4>
						{referral.user.firstName && referral.user.firstName[0]}
						{referral.user.lastName && referral.user.lastName[0]}
					</h4>
				) : (
					<h4>
						{firstName && firstName[0]}
						{lastName && lastName[0]}
					</h4>
				);
			}

			case NOTIFICATIONS.REFERRAL_MATCH: {
				return <img src={ErinLogo} alt="Erin App" />;
			}

			case NOTIFICATIONS.JOB_SUMMARY_EMAIL: {
				return (
					<div className="notification-icon">
						<WebIcon name="id" size={26} color="text-secondary" />
					</div>
				);
			}

			case NOTIFICATIONS.EMP_MESSAGE_CENTER: {
				return (
					<div className="notification-icon">
						<WebIcon name="id" size={26} color="text-secondary" />
					</div>
				);
			}

			case NOTIFICATIONS.SOCIAL_MEDIA_SHARE: {
				return user ? (
					<div className="notification-icon">
						<h4>
							{user.firstName && user.firstName[0]}
							{user.lastName && user.lastName[0]}
						</h4>
					</div>
				) : (
					<></>
				);
			}

			case NOTIFICATIONS.REFERRAL_SENT: {
				return referral ? (
					<div className="notification-icon">
						<h4>
							{referral.user.firstName && referral.user.firstName[0]}
							{referral.user.lastName && referral.user.lastName[0]}
						</h4>
					</div>
				) : (
					<></>
				);
			}

			case NOTIFICATIONS.GENERAL_REFERRAL: {
				return referral ? (
					<div className="notification-icon">
						<h4>
							{referral.user.firstName && referral.user.firstName[0]}
							{referral.user.lastName && referral.user.lastName[0]}
						</h4>
					</div>
				) : (
					<></>
				);
			}

			case NOTIFICATIONS.POINTS_REFERRAL_ACCEPTED: {
				return (
					<div className="notification-icon">
						<img src={Party} alt="Erin App" />
					</div>
				);
			}

			case NOTIFICATIONS.POINTS_REFERRAL_INTERVIEWING: {
				return (
					<div className="notification-icon">
						<img src={Party} alt="Erin App" />
					</div>
				);
			}

			case NOTIFICATIONS.POINTS_REFERRAL_HIRED: {
				return (
					<div className="notification-icon">
						<img src={Party} alt="Erin App" />
					</div>
				);
			}

			default: {
				return null;
			}
		}
	};

	const defineNotificationStyles = (item) => {
		if (
			(!lastNotificationCheck || item.dateCreated > lastNotificationCheck) &&
			dropDown
		) {
			return ListItemStylesBlue;
		}

		if (item.dateCreated < lastNotificationCheck && dropDown) {
			return ListItemStyles;
		}

		if (
			(!lastNotificationCheck || item.dateCreated > lastNotificationCheck) &&
			dropDown
		) {
			return ListItemPageStylesBlue;
		}

		return ListItemPageStyles;
	};

	return (
		<>
			{filteredNotifications && filteredNotifications.length > 0 ? (
				<List
					className={
						dropDown ? 'notification-list-wrap' : 'notification-page-wrap'
					}
					dataSource={filteredNotifications.filter(Boolean)}
					pagination={dropDown ? null : pagination}
					renderItem={(item) => (
						<List.Item>
							<>{renderAvatar(item)}</>
							{item.type === NOTIFICATIONS.REFERRAL_CREATED && item.referral ? (
								<Link
									to={`/jobs/${get(item, 'jobId', '')}`}
									className="notification-text"
								>
									<>
										{item.referral && (
											<span className="notification-text-blue">
												{item.referral.user.firstName &&
													item.referral.user.firstName}{' '}
												{item.referral.user.lastName &&
													item.referral.user.lastName}
											</span>
										)}{' '}
										has made a <strong>referral</strong> for{' '}
										<span className="notification-text-blue">
											{get(item, 'job.title', '')}
										</span>
									</>
									<span className="notification-time">
										{dayjs(`${item.dateCreated}`).fromNow()}
									</span>
								</Link>
							) : item.type === NOTIFICATIONS.JOB_CREATED ? (
								<Link
									to={`/browsejobs/${get(item, 'jobId', '')}`}
									className="notification-text"
								>
									<>
										<strong>
											{item.job.subCompany === null
												? item.user.company.name
												: item.job.subCompany.name}
										</strong>{' '}
										has added a <strong>new job</strong>{' '}
										<span className="notification-text-blue">
											{get(item, 'job.title', '')}
										</span>
									</>
									<span className="notification-time">
										{dayjs(`${item.dateCreated}`).fromNow()}
									</span>
								</Link>
							) : item.type === NOTIFICATIONS.REFERRAL_ACCEPTED &&
							  item.referral ? (
								<Link
									to={`/browsejobs/${get(item, 'jobId', '')}`}
									className="notification-text"
								>
									<>
										<span className="notification-text-blue" to="#">
											{item.referral.contact && item.referral.contact.firstName}{' '}
											{item.referral.contact && item.referral.contact.lastName}
										</span>{' '}
										has <strong>accepted</strong> a referral for{' '}
										<span className="notification-text-blue">
											{get(item, 'job.title', '')}
										</span>
									</>
									<span className="notification-time">
										{dayjs(`${item.dateCreated}`).fromNow()}
									</span>
								</Link>
							) : item.type === NOTIFICATIONS.REFERRAL_HIRED &&
							  item.referral ? (
								<Link
									to={`/browsejobs/${get(item, 'jobId', '')}`}
									className="notification-text"
								>
									<>
										<strong>
											{item.job.subCompany === null
												? item.user.company.name
												: item.job.subCompany.name}
										</strong>{' '}
										just <strong>hired</strong>{' '}
										<span className="notification-text-blue" to="#">
											{item.referral.contact.firstName}{' '}
											{item.referral.contact.lastName}
										</span>{' '}
										for{' '}
										<span className="notification-text-blue">
											{get(item, 'job.title', '')}
										</span>
									</>
									<span className="notification-time">
										{dayjs(`${item.dateCreated}`).fromNow()}
									</span>
								</Link>
							) : item.type === NOTIFICATIONS.REFERRAL_REQUESTED ? (
								<Link to="/mycontacts" className="notification-text">
									<>
										<span className="notification-text-blue" to="#">
											{item.requestingUser.firstName}{' '}
											{item.requestingUser.lastName}
										</span>{' '}
										has requested a <strong>referral</strong> for{' '}
										<span className="notification-text-blue">
											{get(item, 'job.title', '')}
										</span>
									</>
									<span className="notification-time">
										{dayjs(`${item.dateCreated}`).fromNow()}
									</span>
								</Link>
							) : item.type === NOTIFICATIONS.CONTACTS_IMPORTED ? (
								<Link to="/mycontacts" className="notification-text">
									<>
										<span className="notification-text-blue" to="#">
											{firstName} {lastName}
										</span>{' '}
										has completed importing contacts
									</>
									<span className="notification-time">
										{dayjs(`${item.dateCreated}`).fromNow()}
									</span>
								</Link>
							) : item.type === NOTIFICATIONS.REFERRAL_MATCH ? (
								<Link
									to={`/browsejobs/${get(item, 'jobId')}`}
									className="notification-text"
								>
									<>
										You know{' '}
										<strong>{get(item, 'matches', 0).length} people </strong>
										that match the{' '}
										<span className="notification-text-blue">
											{get(item, 'job.title', '')}
										</span>{' '}
										job. Click here to view them.
									</>
									<span className="notification-time">
										{dayjs(`${item.dateCreated}`).fromNow()}
									</span>
								</Link>
							) : item.type === NOTIFICATIONS.JOB_SUMMARY_EMAIL ? (
								<Link to="/browsejobs" className="notification-text">
									<>
										<strong>
											{item.user.subCompany === null
												? item.user.company.name
												: item.user.subCompany.name}
										</strong>{' '}
										has added {item.matches} <strong>new jobs</strong>{' '}
									</>
									<span className="notification-time">
										{dayjs(`${item.dateCreated}`).fromNow()}
									</span>
								</Link>
							) : item.type === NOTIFICATIONS.EMP_MESSAGE_CENTER ? (
								<Link to="/browsejobs" className="notification-text">
									<>
										<strong /> {item.message}
									</>
									<span className="notification-time">
										{dayjs(`${item.dateCreated}`).fromNow()}
									</span>
								</Link>
							) : item.type === NOTIFICATIONS.SOCIAL_MEDIA_SHARE ? (
								<Link to="/points" className="notification-text">
									<>
										{item.user && (
											<span className="notification-text-blue">
												{item.user.firstName && item.user.firstName}{' '}
												{item.user.lastName && item.user.lastName}
											</span>
										)}{' '}
										has earned <strong>{get(item, 'matches')} points</strong>{' '}
										for sharing a{' '}
										<span className="notification-text-blue">
											{get(item, 'job.title', '')}
										</span>
										{' job.'}
									</>
									<span className="notification-time">
										{dayjs(`${item.dateCreated}`).fromNow()}
									</span>
								</Link>
							) : item.type === NOTIFICATIONS.REFERRAL_SENT && item.referral ? (
								<Link
									to={`/jobs/${get(item, 'jobId', '')}`}
									className="notification-text"
								>
									<>
										{item.referral && (
											<span className="notification-text-blue">
												{item.referral.user.firstName &&
													item.referral.user.firstName}{' '}
												{item.referral.user.lastName &&
													item.referral.user.lastName}
											</span>
										)}{' '}
										has earned <strong>{get(item, 'matches')} points</strong>{' '}
										{'for sending a referral for '}
										<span className="notification-text-blue">
											{get(item, 'job.title', '')}
										</span>
									</>
									<span className="notification-time">
										{dayjs(`${item.dateCreated}`).fromNow()}
									</span>
								</Link>
							) : item.type === NOTIFICATIONS.GENERAL_REFERRAL &&
							  item.referral ? (
								<Link
									to={`/jobs/${get(item, 'jobId', '')}`}
									className="notification-text"
								>
									<>
										{item.referral && (
											<span className="notification-text-blue">
												{item.referral.user.firstName &&
													item.referral.user.firstName}{' '}
												{item.referral.user.lastName &&
													item.referral.user.lastName}
											</span>
										)}{' '}
										has earned <strong>{get(item, 'matches')} points</strong>{' '}
										{'for sending a general referral for '}
										<span className="notification-text-blue">
											{get(item, 'job.title', '')}
										</span>
									</>
									<span className="notification-time">
										{dayjs(`${item.dateCreated}`).fromNow()}
									</span>
								</Link>
							) : item.type === NOTIFICATIONS.POINTS_REFERRAL_ACCEPTED &&
							  item.referral ? (
								<Link
									to={`/jobs/${get(item, 'jobId', '')}`}
									className="notification-text"
								>
									<>
										{item.referral && (
											<span className="notification-text-blue">
												{item.referral.user.firstName &&
													item.referral.user.firstName}{' '}
												{item.referral.user.lastName &&
													item.referral.user.lastName}
											</span>
										)}{' '}
										has earned <strong>{get(item, 'matches')} points</strong>{' '}
										{'for accepting a referral for '}
										<span className="notification-text-blue">
											{get(item, 'job.title', '')}
										</span>
									</>
									<span className="notification-time">
										{dayjs(`${item.dateCreated}`).fromNow()}
									</span>
								</Link>
							) : item.type === NOTIFICATIONS.POINTS_REFERRAL_INTERVIEWING &&
							  item.referral ? (
								<Link
									to={`/jobs/${get(item, 'jobId', '')}`}
									className="notification-text"
								>
									<>
										{item.referral && (
											<span className="notification-text-blue">
												{item.referral.user.firstName &&
													item.referral.user.firstName}{' '}
												{item.referral.user.lastName &&
													item.referral.user.lastName}
											</span>
										)}{' '}
										has earned <strong>{get(item, 'matches')} points</strong>{' '}
										{'for ' +
											(referralStatus
												? `referral ${referralStatus.toLowerCase()} for `
												: 'interviewing a referral for ')}
										<span className="notification-text-blue">
											{get(item, 'job.title', '')}
										</span>
									</>
									<span className="notification-time">
										{dayjs(`${item.dateCreated}`).fromNow()}
									</span>
								</Link>
							) : item.type === NOTIFICATIONS.POINTS_REFERRAL_HIRED &&
							  item.referral ? (
								<Link
									to={`/jobs/${get(item, 'jobId', '')}`}
									className="notification-text"
								>
									<>
										{item.referral && (
											<span className="notification-text-blue">
												{item.referral.user.firstName &&
													item.referral.user.firstName}{' '}
												{item.referral.user.lastName &&
													item.referral.user.lastName}
											</span>
										)}{' '}
										has earned <strong>{get(item, 'matches')} points</strong>{' '}
										{'for hiring a referral for '}
										<span className="notification-text-blue">
											{get(item, 'job.title', '')}
										</span>
									</>
									<span className="notification-time">
										{dayjs(`${item.dateCreated}`).fromNow()}
									</span>
								</Link>
							) : null}
						</List.Item>
					)}
				/>
			) : (
				<p> No Notifications </p>
			)}
			{dropDown ? (
				<div className="notification-view-more">
					<Link to="/notifications">View More</Link>
				</div>
			) : null}
		</>
	);
}

export default WebNotificationList;
