
function formatDate(dateString, shouldShowAgoTime = false) {
    const date = new Date(dateString);

    if (shouldShowAgoTime) {
        const now = new Date();
        const timeDifference = now - date;

        if (timeDifference < 24 * 60 * 60 * 1000) { // If the time difference is less than 24 hours
            const elapsedHours = Math.floor(timeDifference / (60 * 60 * 1000));
            const elapsedMinutes = Math.floor((timeDifference % (60 * 60 * 1000)) / (60 * 1000));

            if (elapsedHours > 0) {
                return `${elapsedHours} hour${elapsedHours > 1 ? 's' : ''} ago`;
            } else if (elapsedMinutes >= 1) {
                return `${elapsedMinutes} minute${elapsedMinutes > 1 ? 's' : ''} ago`;
            } else {
                return "Now";
            }
        }
    }

    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const month = months[date.getMonth()];

    const day = date.getDate();
    const suffix = getSuffix(day);

    const year = date.getFullYear();

    let hour = date.getHours();
    const minute = date.getMinutes();
    const ampm = hour >= 12 ? 'pm' : 'am';

    hour = hour % 12;
    hour = hour ? hour : 12;

    const formattedDate = `${month} ${day}${suffix}, ${year} @ ${hour}:${minute.toString().padStart(2, '0')} ${ampm}`;
    return formattedDate;
}

function getSuffix(day) {
    if (day === 1 || day === 21 || day === 31) {
        return 'st';
    } else if (day === 2 || day === 22) {
        return 'nd';
    } else if (day === 3 || day === 23) {
        return 'rd';
    } else {
        return 'th';
    }
}
export default formatDate;