import { gql } from 'graphql-tag';
import get from 'lodash/get';
import { compose, graphql } from 'react-apollo';
import { updateJobMatch } from 'src/_shared/api/graphql/custom/jobMatch/';
import { GetUserByCognitoId } from 'src/_shared/api/graphql/custom/users/';
import { JobMatchesByCompanyId } from '../../graphql/custom/jobMatch';

export const withJobMatchByCompanyId = (Component, variables) => {
	return compose(
		graphql(JobMatchesByCompanyId, {
			options: {
				context: {
					headers: {
						'x-frame-options': 'deny', // This header will reach the server
					},
				},
				variables,
				fetchPolicy: 'cache-and-network',
			},
			props(props) {
				const matchByCompanyId = get(
					props,
					'data.queryJobMatchesByCompanyIdIndex.items',
					[]
				);
				return {
					JobMatchesByCompanyId: matchByCompanyId,
				};
			},
		}),
		graphql(gql(updateJobMatch), {
			props: (props) => ({
				onUpdateJobMatch(input) {
					return props.mutate({
						variables: { input },
						update(proxy) {
							const data = proxy.readQuery({
								query: GetUserByCognitoId,
								variables: { cognitoId: props.ownProps.currentUser.cognitoId },
							});
							const user = get(data, 'getUserByCognitoId');
							const matches = get(user, 'jobMatches', []);
							const index = matches.findIndex((match) => match.id === input.id);
							const updatedMatch =
								index >= 0 ? { ...matches[index], ...input } : null;
							if (updatedMatch) {
								matches[index] = updatedMatch;
								user.jobMatches = matches;
							}

							proxy.writeQuery({
								query: GetUserByCognitoId,
								user,
								data,
								variables: { cognitoId: props.ownProps.currentUser.cognitoId },
							});
						},
					});
				},
			}),
		})
	)(Component);
};
