import {
	AddJobPageFour,
	AddJobPageOne,
	AddJobPageThree,
	AddJobPageTwo,
	CancelAddJob,
	CancelEditJob,
} from './pages';
import { ProgressIndicator } from './progressIndicator';

export function AddJob({
	allMultiLingualData,
	currentUser,
	page,
	theme,
	handleSubmit,
	currentJob,
	handleNext,
	handleBack,
	handleOk,
	handleCancel,
	errors,
	isNew,
	departments,
	managers,
	bonuses,
	buttonState,
	subCompanies,
	editing,
}) {
	return (
		<div>
			{page !== 4 && (
				<div style={{ marginBottom: 30 }}>
					<ProgressIndicator
						allMultiLingualData={allMultiLingualData}
						currentUser={currentUser}
						page={page}
					/>
				</div>
			)}
			<div>
				{/* "Page" numbers start at zero to utilize indecies in other areas */}
				{page === 0 && (
					<AddJobPageOne
						allMultiLingualData={allMultiLingualData}
						currentUser={currentUser}
						currentJob={currentJob}
						handleNext={handleNext}
						page={page}
						theme={theme}
						errors={errors}
						editing={editing}
					/>
				)}

				{page === 1 && (
					<AddJobPageTwo
						allMultiLingualData={allMultiLingualData}
						currentUser={currentUser}
						isNew={isNew}
						currentJob={currentJob}
						handleNext={handleNext}
						handleBack={handleBack}
						departments={departments}
						subCompanies={subCompanies}
						page={page}
						theme={theme}
						errors={errors}
					/>
				)}

				{page === 2 && (
					<AddJobPageThree
						allMultiLingualData={allMultiLingualData}
						currentUser={currentUser}
						isNew={isNew}
						currentJob={currentJob}
						handleNext={handleNext}
						handleBack={handleBack}
						managers={managers}
						tieredBonuses={bonuses}
						subCompanies={subCompanies}
						page={page}
						theme={theme}
						errors={errors}
					/>
				)}

				{page === 3 && (
					<AddJobPageFour
						allMultiLingualData={allMultiLingualData}
						currentUser={currentUser}
						isNew={isNew}
						currentJob={currentJob}
						tieredBonuses={bonuses}
						handleSubmit={handleSubmit}
						handleBack={handleBack}
						page={page}
						theme={theme}
						errors={errors}
						buttonState={buttonState}
						subCompanies={subCompanies}
					/>
				)}

				{page === 4 && isNew && (
					<CancelAddJob
						allMultiLingualData={allMultiLingualData}
						currentUser={currentUser}
						theme={theme}
						handleOk={handleOk}
						handleCancel={handleCancel}
					/>
				)}

				{page === 4 && !isNew && (
					<CancelEditJob
						allMultiLingualData={allMultiLingualData}
						currentUser={currentUser}
						handleOk={handleOk}
						handleCancel={handleCancel}
					/>
				)}
			</div>
		</div>
	);
}
