import { Button } from 'antd';
import get from 'lodash/get';
import { Component } from 'react';
import { withApollo } from 'react-apollo';
import { USER_ROLES } from 'src/_shared/constants/index.js';
import { getSetErrorImageURL, ml } from 'src/_shared/services/utils.js';
import fileIcon from '../../../_shared/assets/erin_lightgray.png';
import { autoHeight, fixedHeight } from './pointCardStyles.js';

class BasePointCard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			totalSmartReferrals: 0,
			isViewMore: false,
			toggleClassName: fixedHeight,
			errorImageURL: '',
		};
	}

	async componentDidMount() {
		const { currentUser } = this.props;
		const errorImageURL = await getSetErrorImageURL(
			currentUser?.company?.errorImage?.key
		);

		if (errorImageURL) {
			this.setState({ errorImageURL });
		}
	}

	compareObjects = (object1, object2, key) => {
		const object1_ = object1[key].toUpperCase();
		const object2_ = object2[key].toUpperCase();

		if (object1_ < object2_) {
			return -1;
		}

		if (object1_ > object2_) {
			return 1;
		}

		return 0;
	};

	formatNumber = (number) => {
		try {
			if (number !== null && number !== undefined) {
				return number.toString().replaceAll(/\B(?=(\d{3})+(?!\d))/g, ',');
			}

			return number;
		} catch {
			return 0;
		}
	};

	toggleCLass = () => {
		this.setState(
			{
				isViewMore: !this.state.isViewMore,
			},
			() => {
				this.setState({
					toggleClassName: this.state.isViewMore ? autoHeight : fixedHeight,
				});
			}
		);
	};

	render() {
		const {
			allMultiLingualData,
			title,
			jobMatches,
			currentUser,
			JobMatchesByCompanyId,
			WithReferralCount,
			NoReferralCount,
			openPositionsCount,
			JobTiers,
			totalPoints,
			userPointsRanking,
			company,
		} = this.props;
		const { errorImageURL } = this.state;

		const whiteLabel = get(company, 'whiteLabel');

		JobTiers.sort((book1, book2) => {
			return this.compareObjects(book1, book2, 'name');
		});

		let goodMatches = [];
		let totalSmartRefer = [];
		if (jobMatches !== undefined && jobMatches !== null) {
			goodMatches = jobMatches.filter(
				(jobMatch) => jobMatch.relevance >= 30 && jobMatch.matchStatus !== false
			);
		}

		if (JobMatchesByCompanyId !== undefined && JobMatchesByCompanyId !== null) {
			totalSmartRefer = JobMatchesByCompanyId.filter(
				(jobMatch) => jobMatch.relevance >= 30 && jobMatch.matchStatus !== false
			);
		}

		return (
			<div className="d-card height-equal">
				<div className="d-card-head">
					<h4 className="d-title">{title}</h4>
				</div>
				<div className="d-card-body vh-center">
					{currentUser.displayAs === USER_ROLES.ADMIN && (
						<div>
							<div className="row align-items-center card-top-stat">
								<div className="col-sm-5">
									<div className="stat-box">
										<span>{openPositionsCount ?? 0}</span>
										<div className="d-block stat-text">
											{ml('Open Positions', currentUser, allMultiLingualData)}
										</div>
									</div>
								</div>
								<div className="col-sm-7">
									<div className="list-data-wrap">
										<div className="d-flex data-row">
											<label>
												{ml('With Referrals', currentUser, allMultiLingualData)}
												:
											</label>
											<span className="ml-auto pl-1">{WithReferralCount}</span>
										</div>
										<div className="d-flex data-row">
											<label>
												{ml('No Referrals', currentUser, allMultiLingualData)}:
											</label>
											<span className="ml-auto pl-1">{NoReferralCount}</span>
										</div>
									</div>
								</div>
							</div>
							<div className="divider-sep" />

							<div className="row">
								<div className="col-sm-5">
									<div className="label-heading">
										{' '}
										{ml(
											'Job By Bonus Level',
											currentUser,
											allMultiLingualData
										)}{' '}
									</div>
								</div>
								<div className="col-sm-7">
									<div className="list-data-wrap">
										{JobTiers.map((value) => {
											return (
												<div key={value.id} className="d-flex data-row">
													{' '}
													<label>{value.name} :</label>
													<span className="ml-auto pl-1">{value.count} </span>
												</div>
											);
										})}
									</div>
								</div>
							</div>
						</div>
					)}
					{(currentUser.displayAs === USER_ROLES.EMPLOYEE ||
						currentUser.displayAs === USER_ROLES.MANAGER) && (
						<div style={{ width: '100%' }}>
							{totalPoints ? (
								<div className="row align-items-center text-center">
									<div className="col-md-7">
										<h3 className="d-number">
											{this.formatNumber(totalPoints)}
										</h3>
										<p className="d-info">
											{ml('Total Points', currentUser, allMultiLingualData)}
										</p>
									</div>
									<div className="col-md-5">
										{userPointsRanking > 0 && (
											<h3 className="d-number"># {userPointsRanking}</h3>
										)}
										{userPointsRanking === -1 && (
											<p className="d-info">
												{ml('No Rank', currentUser, allMultiLingualData)}.
											</p>
										)}
									</div>
								</div>
							) : (
								<div className="no-content">
									{whiteLabel ? (
										<img
											src={errorImageURL}
											alt="error image"
											className="no-content-icon"
										/>
									) : (
										<img
											alt="erin-logo"
											className="no-content-icon"
											src={fileIcon}
										/>
									)}
									<p className="no-content-text">
										Your total points will calculate once you start referring
									</p>
								</div>
							)}
						</div>
					)}
				</div>
				{JobTiers.length > 4 &&
					currentUser.displayAs !== USER_ROLES.EMPLOYEE &&
					currentUser.displayAs !== USER_ROLES.MANAGER && (
						<div className="view-more">
							<Button
								className="more-less"
								type="link"
								onClick={this.toggleCLass}
							>
								{this.state.isViewMore
									? ml('VIEW LESS', currentUser, allMultiLingualData)
									: ml('VIEW MORE', currentUser, allMultiLingualData)}
							</Button>
						</div>
					)}
			</div>
		);
	}
}

export const PointCard = withApollo(BasePointCard);
