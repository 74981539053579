import { Alert, Button, Form, Input } from 'antd';
import { Auth } from 'aws-amplify';
import { Component, createRef } from 'react';
import validator from 'validator';
import { AlertError } from './forgottenPasswordModalStyles.js';

class PageTwo extends Component {
	constructor(props) {
		super(props);

		this.state = {
			cognitoError: false,
			errorMessage: '',
			matchError: false,
			lengthError: false,
			submitError: false,
			success: false,
		};
		this.formRef = createRef();
	}

	handleSubmit = (values) => {
		if (values.password !== values.confirmPassword) {
			this.setState({ matchError: true });
			return;
		}

		if (
			values.password &&
			!validator.isStrongPassword(values.password, {
				minLength: 10,
				minLowercase: 1,
				minUppercase: 1,
				minNumbers: 1,
				minSymbols: 1,
			})
		) {
			this.setState({ lengthError: true });
			return;
		}

		Auth.forgotPasswordSubmit(
			this.props.userEmail,
			values.code,
			values.password
		)
			.then(() => {
				this.setState({ success: true });
				setTimeout(() => {
					this.removeAlerts();
					this.props.closeModal();
					this.props.setPageOne();
					this.props.setUserEmail('');
				}, 1000);
			})
			.catch((error) => {
				console.error(error);
				this.setState({ errorMessage: error.message, cognitoError: true });
			});
	};

	onFinishFailed = (error) => {
		this.setState({ submitError: true });
		setTimeout(() => {
			this.formRef.current.resetFields();
			this.removeAlerts();
		}, 1000);
	};

	removeAlerts = () => {
		this.setState({
			cognitoError: false,
			errorMessage: '',
			matchError: false,
			lengthError: false,
			submitError: false,
			success: false,
		});
	};

	render() {
		const {
			cognitoError,
			errorMessage,
			submitError,
			lengthError,
			matchError,
			success,
		} = this.state;
		return (
			<Form
				ref={this.formRef}
				onFinish={this.handleSubmit}
				onFinishFailed={this.onFinishFailed}
			>
				<p>
					If this email exists you will have a one time code sent to you. Please
					enter it below.
				</p>
				<Form.Item
					name="code"
					rules={[
						{
							required: true,
							message: 'Verfication Code Required',
						},
					]}
				>
					<div className="custom-form-group">
						<label className="custom-label">Verification Code</label>
						<Input className="custom-input" />
					</div>
				</Form.Item>
				<Form.Item
					name="password"
					rules={[
						{
							required: true,
							message: 'Please enter a new password',
						},
					]}
				>
					<div className="custom-form-group">
						<label className="custom-label">New Password</label>
						<Input className="custom-input" type="password" />
					</div>
				</Form.Item>
				<Form.Item
					name="confirmPassword"
					rules={[
						{
							required: true,
							message: 'Please confirm your new password',
						},
					]}
				>
					<div className="custom-form-group">
						<label className="custom-label">Confirm Password</label>
						<Input className="custom-input" type="password" />
					</div>
				</Form.Item>
				{cognitoError && <Alert type="error" message={errorMessage} />}
				{matchError ? (
					<Alert
						className={AlertError}
						type="error"
						message="Passwords Do Not Match. Please Try Again."
					/>
				) : null}
				{lengthError ? (
					<Alert
						className={AlertError}
						type="error"
						message="Your password must have minimum 10 characters, at least one upper case, one lower case letter, a number and a special character."
					/>
				) : null}
				{submitError ? (
					<Alert
						className={AlertError}
						message="We are having trouble submitting the form, please try again later."
						type="error"
					/>
				) : null}
				{success ? (
					<Alert
						className={AlertError}
						message="Password successfully changed!"
						type="success"
					/>
				) : null}
				<div className="modal-footer-btn">
					<Button
						type="primary"
						size="large"
						className="btn-min-width"
						htmlType="submit"
					>
						Submit
					</Button>
				</div>
			</Form>
		);
	}
}

export default PageTwo;
