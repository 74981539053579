import React, { useEffect, useState } from 'react';
import ApprovalRewards from './../../presentation/approvalRewards/ApprovalRewards.jsx';
import ApprovedContainer from './../ApprovedContainer/ApprovedContainer.jsx';
import PendingApprovalContainer from './../pendingApprovalContainer/PendingApprovalContainer.jsx';
import { message } from 'antd';
import { useApprovalRewardsService } from '../../service/ApprovalRewards.service.jsx';

const ApprovalRewardsContainer = () => {

	const [pendingRecordId, setPendingRecordId] = useState()
	const [isApprove, setIsApprove] = useState(false);
	const [isDecline, setIsDecline] = useState(false);
	const [approvalCountData, setApprovalCountData] = useState()
	const [isTableRefresh, setTableRefresh] = useState(true)
	const approvalRewardsAPIService = useApprovalRewardsService();

	useEffect(() => {
		getApprovalRewardCountData()
	}, [])

	const getApprovalRewardCountData = async () => {
		approvalRewardsAPIService.getApprovalRewardsCount()
			.then((response) => {
				if (response?.code === 200) {
					if (response?.data) {
						setApprovalCountData(response?.data);
					}
				} else {
					message.error('Failed to fetch pending list');
				}
			})
			.catch((error) => {
				console.log(error);
				message.error('Failed to fetch pending list');
			});
	};

	const handleApproveAndDeclined = (record, status) => {
		const rewardStatus = {
			approvalStatus: status
		}

		approvalRewardsAPIService.updateApprovalStatus(record, rewardStatus)
			.then((response) => {
				if (response) {
					setIsDecline(false);
					setIsApprove(false);
					setTableRefresh(true)
					getApprovalRewardCountData()
				}
			})
	};

	const handleApprovalCLick = (record) => {
		setPendingRecordId(record?.id)
		setIsApprove(true);
	};

	const handleDeclineClick = (record) => {
		setPendingRecordId(record?.id)
		setIsDecline(true);
	};

	return (
		<div>
			<ApprovalRewards approvalCountData={approvalCountData} />
			<PendingApprovalContainer
				isPendingReward={true}
				pendingRecordId={pendingRecordId}
				setIsDecline={setIsDecline}
				setIsApprove={setIsApprove}
				isApprove={isApprove}
				isDecline={isDecline}
				handleApprovalCLick={handleApprovalCLick}
				handleDeclineClick={handleDeclineClick}
				handleApproveAndDeclined={handleApproveAndDeclined}
				setPendingRecordId={setPendingRecordId}
				setTableRefresh={setTableRefresh}
				isTableRefresh={isTableRefresh} />
			<ApprovedContainer
				isPendingReward={false}
				pendingRecordId={pendingRecordId}
				setPendingRecordId={setPendingRecordId}
				isTableRefresh={isTableRefresh}
				setTableRefresh={setTableRefresh}
			/>
		</div>
	);
};

export default ApprovalRewardsContainer;
