import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { SettingOutlined } from '@ant-design/icons';
import { Button, Input, Tooltip } from 'antd';
import { ANALYTICS_CARD_TYPES } from 'src/_shared/constants/';
import AnalyticsGraphicComponent from './AnalyticsGraphicComponent.jsx';

function AnalyticsCardComponent({
	cardCalculation,
	cardType,
	handleUpdateCompany,
	editHeaderText = null,
	editQuestionText = null,
	inputValue = null,
	labelText,
}) {
	const [isActive, setIsActive] = useState(false);
	const [isEditable, setIsEditable] = useState(false);
	const [isEditing, setIsEditing] = useState(false);
	const [shouldAnimationDelay, setShouldAnimationDelay] = useState(true);
	const [currentInputValue, setCurrentInputValue] = useState('');
	const [isHovered, setIsHovered] = useState(false);
	const isAltLayout = cardType === ANALYTICS_CARD_TYPES.VELOCITY && isActive;

	useEffect(() => {
		setIsActive(cardCalculation !== undefined && cardCalculation !== null);
	}, [cardCalculation]);

	useEffect(() => {
		if (inputValue !== null) {
			setCurrentInputValue(String(inputValue));
		}
	}, [inputValue]);

	useEffect(() => {
		setIsEditable(editHeaderText && editQuestionText);
	}, [editHeaderText, editQuestionText]);

	const handleMouseEnter = () => setIsHovered(true);
	const handleMouseLeave = () => setIsHovered(false);
	const handleEditToggle = () => setIsEditing(!isEditing);

	const handleInputChange = (event) => {
		const newValue = event.target.value;
		if (/^-?\d+\.?\d*$/.test(newValue) || newValue === '') {
			setCurrentInputValue(
				newValue[0] === '0' && newValue.length > 1 ? newValue[1] : newValue
			);
		}
	};

	const onClickSave = () => {
		const parsedInput = Number(currentInputValue);
		if (currentInputValue === '') {
			handleUpdateCompany(null, cardType);
		} else if (inputValue !== parsedInput) {
			handleUpdateCompany(parsedInput, cardType);
		}
		if (shouldAnimationDelay) setShouldAnimationDelay(false);
		handleEditToggle();
	};

	const renderCardEdit = () => (
		<div className="analytics-card-edit">
			<div
				className="analytics-card-stat-label"
				style={{ marginBottom: 8, fontSize: '16px', fontWeight: 900 }}
			>
				{editHeaderText}
			</div>
			<div
				className="analytics-card-stat-label"
				style={{ marginBottom: 8, fontSize: '14px' }}
			>
				{editQuestionText}
			</div>
			<Input
				style={{ marginBottom: 8, width: '108px' }}
				value={currentInputValue}
				maxLength={10}
				onChange={handleInputChange}
			/>
			<Button type="primary" onClick={onClickSave}>
				Save
			</Button>
		</div>
	);

	const renderCardContent = () => (
		<div className="analytics-card-content">
			<div className="analytics-card-content-left">
				<AnalyticsGraphicComponent
					cardType={cardType}
					isActive={isActive}
					isHovered={isHovered}
					shouldAnimationDelay={shouldAnimationDelay}
				/>
			</div>
			<div className="analytics-card-content-right">
				<div className="analytics-card-content-right-top">
					{isAltLayout && (
						<>
							<div className="analytics-card-stat-days">
								{`${cardCalculation?.sourcingVelocity} Days`}
							</div>
							<div className="analytics-card-stat-label">
								Referral Sourcing Velocity
							</div>
						</>
					)}
					<div
						className={`analytics-card-stat-data${isActive ? '' : ' analytics-card-inactive'}`}
					>
						{isActive && cardType !== ANALYTICS_CARD_TYPES.VELOCITY
							? cardType === ANALYTICS_CARD_TYPES.RATIO
								? `${cardCalculation?.totalUniqueCandidates}:${cardCalculation?.totalHires}`
								: `${cardCalculation}%`
							: null}
					</div>
					<div
						className={`analytics-card-stat-label${isActive ? '' : ' analytics-card-inactive'}`}
					>
						{!isActive &&
							(isEditable
								? 'Click the gear icon to enter the required criteria and enable this metric.'
								: "This metric will display after your first hire is made.")}
					</div>
				</div>
				<div className="analytics-card-content-right-bottom">
					{isAltLayout ? (
						<>
							<div className="analytics-card-stat-days">
								{`${cardCalculation?.hiringVelocity} Days`}
							</div>
							<div className="analytics-card-stat-label">
								Referral Hiring Velocity
							</div>
						</>
					) : (
						<div
							className={`analytics-card-stat-label${isActive ? '' : ' analytics-card-inactive'}`}
						>
							{labelText}
						</div>
					)}
				</div>
			</div>
		</div>
	);

	return (
		<div
			className="analytics-card-wrapper"
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
		>
			<div
				className={`analytics-card ${cardType} animate__animated_slow animate__fadeIn`}
			>
				{isEditable && !isEditing && (
					<Tooltip title="Toggle Edit" placement="topRight">
						<Button
							type="text"
							style={{ position: 'absolute', top: '8px', right: '8px' }}
							icon={<SettingOutlined />}
							onClick={handleEditToggle}
						/>
					</Tooltip>
				)}
				{isEditing ? renderCardEdit() : renderCardContent()}
			</div>
		</div>
	);
}

AnalyticsCardComponent.propTypes = {
	cardCalculation: PropTypes.oneOfType([
		PropTypes.number,
		PropTypes.string,
		PropTypes.shape({
			sourcingVelocity: PropTypes.number,
			hiringVelocity: PropTypes.number,
		}),
		PropTypes.shape({
			totalUniqueCandidates: PropTypes.number,
			totalHires: PropTypes.number,
		}),
	]),
	cardType: PropTypes.string.isRequired,
	handleUpdateCompany: PropTypes.func,
	labelText: PropTypes.string,
	inputValue: PropTypes.number,
	editHeaderText: PropTypes.string,
	editQuestionText: PropTypes.string,
};

export default AnalyticsCardComponent;
