import { css } from 'emotion';
import { COLORS } from '../styles/colors';

export const ModalStyles = css({
	'& .ant-modal-content': {
		borderRadius: 12,
	},
	fontFamily: 'Open Sans',
	'& .ant-modal-close-x': {
		paddingTop: 10,
		fontSize: 30,
		color: COLORS.lightGray,
	},
});

export const ModalTitle = css({
	color: COLORS.darkGray,
	width: '100%',
	textAlign: 'center',
	fontSize: 36,
	marginBottom: 0,
	fontWeight: 600,
});

export const SmallText = css({
	fontSize: 16,
	fontWeight: 600,
	width: '100%',
	textAlign: 'center',
});

export const SubTitle = css({
	textAlign: 'center',
	fontSize: 18,
	fontWeight: 600,
	paddingBottom: 0,
});

export const SubTitleItalic = css({
	textAlign: 'center',
	fontSize: 18,
	fontWeight: 400,
	fontStyle: 'italic',
	paddingBottom: 0,
	marginBottom: 0,
});

export const FormStyles = css({
	margin: 'auto',
});
