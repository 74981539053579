import { InfoCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { Button, Select, Spin, Tooltip } from 'antd';
import _ from 'lodash';
import React from 'react';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import WebIcon from 'src/_shared/components/WebIconComponent.jsx';
import {
	getISODateRangeUTC,
	ml,
	parse,
	searchOpenSearchNetwork,
	subtractDays,
} from 'src/_shared/services/utils.js';
import { COLORS } from 'src/_shared/styles/colors.js';

const { Option } = Select;

class JobAlertCard extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			cardView: 'home',
			searchedJobs: [],
			subCompanyOptions: [],
			departmentOptions: [],
			selectedSubCompanyOptions: [],
			selectedDepartmentOptions: [],
			selectedSalaryOption: undefined,
			subCompanyLabel:
				this.props?.currentUser?.company?.subCompanyLabel ?? 'Subcompany',
			labelDepartment:
				this.props?.currentUser?.company?.labelDepartment ?? 'Department',
			hideSubCompany: false,
		};
	}

	async componentDidMount() {
		const subCompanies = _.get(
			this.props,
			'currentUser.company.subCompanies',
			[]
		);
		let hideSubCompany = false;
		const hideSubcompanyOnJobs = _.get(
			this.props,
			'currentUser.company.hideSubcompanyOnJobs',
			false
		);
		if (subCompanies.length === 0 || hideSubcompanyOnJobs) {
			hideSubCompany = true;
			this.setState({ hideSubCompany: true });
		}

		const subComps = _.sortBy(subCompanies, ['name']);
		const subCompanyOptions = subComps.map((subCompany) => {
			return (
				<Option key={_.get(subCompany, 'id')} value={_.get(subCompany, 'id')}>
					{_.get(subCompany, 'name')}
				</Option>
			);
		});
		const departments = _.get(this.props, 'departments', []);
		const sortedDepartments = _.sortBy(departments, ['name']).filter(
			(department) => department.active
		);
		const departmentOptions = sortedDepartments.map((department) => {
			return (
				<Option key={department.id} value={department.id}>
					{department.name}
				</Option>
			);
		});
		let jobNotificationSetting = _.get(
			this.props,
			'currentUser.jobNotificationSetting'
		);
		jobNotificationSetting &&= parse(jobNotificationSetting);

		const selectedDepartmentOptions =
			jobNotificationSetting?.departmentSelection
				? jobNotificationSetting.departmentSelection
				: [];
		const selectedSubCompanyOptions =
			jobNotificationSetting?.subcompanySelection && !hideSubCompany
				? jobNotificationSetting.subcompanySelection
				: [];
		const selectedSalaryOption =
			jobNotificationSetting?.salarySelection > 0 ||
			jobNotificationSetting?.salarySelection === 0
				? jobNotificationSetting.salarySelection
				: undefined;
		const newState = {
			departmentOptions,
			subCompanyOptions,
			selectedDepartmentOptions,
			selectedSubCompanyOptions,
			selectedSalaryOption,
		};
		if (
			selectedDepartmentOptions.length > 0 ||
			selectedSubCompanyOptions.length > 0 ||
			selectedSalaryOption > 0 ||
			selectedSalaryOption === 0
		)
			newState.cardView = 'view';

		this.setState(newState, () => {
			// If old subcompany filters exist, and subcompany has been hidden, clear out old filters
			if (
				jobNotificationSetting?.subcompanySelection?.length > 0 &&
				hideSubCompany
			) {
				this.savePreferences();
			}
		});
		await this.setQueryToState('', 0, newState);
	}

	setQueryToState = async (searchQuery = '', timeout = 0, overrideState) => {
		const selectedDepartmentOptions = overrideState?.selectedDepartmentOptions
			? overrideState.selectedDepartmentOptions
			: this.state.selectedDepartmentOptions;
		const selectedSubCompanyOptions = overrideState?.selectedSubCompanyOptions
			? overrideState.selectedSubCompanyOptions
			: this.state.selectedSubCompanyOptions;
		const selectedSalaryOption = overrideState?.selectedSalaryOption
			? overrideState.selectedSalaryOption
			: this.state.selectedSalaryOption;
		const { filteredDistance = 0, filterOpenStatus = 'open' } = this.state;
		const filteredDepartments = selectedDepartmentOptions.map(
			(options) => options.key
		);
		const filteredSubCompanies = selectedSubCompanyOptions.map(
			(options) => options.key
		);
		const salary = selectedSalaryOption;

		const filterCompanies = _.get(this.state, 'filteredCompanies', []).map(
			(company) => company?.id
		);
		const extendedCompaniesData = _.get(
			this.props,
			'currentUser.extendedCompaniesData',
			[]
		);
		const extendedCompanies = extendedCompaniesData.filter(
			(company) => company?.disableExtendedUserJobsView !== true
		);
		const isExtendedUser = this.props?.currentUser?.role === 'extendedUser';
		const comps =
			extendedCompanies && extendedCompanies.length > 0 && isExtendedUser
				? extendedCompanies
				: [this.props?.currentUser?.company];
		const companies = comps.map((company) => company?.id);
		const filteredCompanies =
			filterCompanies.length > 0 ? filterCompanies : companies;

		clearTimeout(this.timer);
		this.setState({ searchQuery });
		this.timer = setTimeout(async () => {
			this.setState({ loading: true });
			const [from, to] = getISODateRangeUTC([
				subtractDays(14),
				new Date().toISOString(),
			]);
			const parameters = {
				query: _.get(this.state, 'searchQuery', ''),
				size: 300,
				role: _.get(this.props, 'currentUser.role'),
				currentUser: _.get(this.props, 'currentUser.id'),
				filters: {
					companies: filteredCompanies,
					departments: filteredDepartments,
					status: filterOpenStatus,
					subCompanies: filteredSubCompanies,
					dateCreated: {
						from,
						to,
					},
				},
			};
			if (salary > 0 || salary === 0) parameters.filters.salary = salary;
			const response = await searchOpenSearchNetwork(parameters, 'erin-jobs');
			if (_.get(response, 'query') === _.get(this.state, 'searchQuery')) {
				let searchedJobs = _.get(response, 'data', []);
				const filterOn =
					(filteredDistance.length > 0 && filteredDistance != '0') ||
					searchQuery.length > 0;
				searchedJobs = filterOn
					? searchedJobs || []
					: _.orderBy(
							searchedJobs || [],
							['isHotJob', 'dateCreated'],
							['desc', 'desc']
						);
				if (searchedJobs) {
					const filteredJobsGeneral = searchedJobs.filter(
						(item) => item.isGeneralReferral === true
					);
					const hotSearchedJobs = searchedJobs.filter(
						(item) => item.isHotJob === true
					);
					searchedJobs = searchedJobs.filter((item) => item.isHotJob !== true);
					this.setState({
						loading: false,
						searchedJobs,
						filteredJobsGeneral,
						hotSearchedJobs,
					});
				}
			}
		}, timeout);
	};

	handleSelectDepartment = (option, data) => {
		const departments = [];
		for (const item of data) {
			const department = {
				key: item.props.value,
				label: item.props.children,
			};
			departments.push(department);
		}

		this.setState({ selectedDepartmentOptions: departments });
	};

	handleSelectSubCompany = (option, data) => {
		const subCompanies = [];
		for (const item of data) {
			const subCompany = {
				key: item.props.value,
				label: item.props.children,
			};
			subCompanies.push(subCompany);
		}

		this.setState({ selectedSubCompanyOptions: subCompanies });
	};

	handleSelectSalary = (option) => {
		this.setState({ selectedSalaryOption: option });
	};

	savePreferences = async () => {
		const { onUpdateUser, currentUser, setCurrentUser } = this.props;
		const {
			selectedDepartmentOptions = [],
			selectedSubCompanyOptions = [],
			selectedSalaryOption,
		} = this.state;
		let jobNotificationSetting = {
			departmentSelection: selectedDepartmentOptions,
			subcompanySelection: selectedSubCompanyOptions,
		};
		if (selectedSalaryOption > 0 || selectedSalaryOption === 0)
			jobNotificationSetting.salarySelection = selectedSalaryOption;
		jobNotificationSetting = JSON.stringify(jobNotificationSetting);
		currentUser.jobNotificationSetting = jobNotificationSetting;
		setCurrentUser(currentUser);
		await onUpdateUser({
			input: {
				id: this.props?.currentUser?.id,
				jobNotificationSetting,
			},
		});
		await this.setQueryToState('');
		if (
			selectedDepartmentOptions.length > 0 ||
			selectedSubCompanyOptions.length > 0 ||
			selectedSalaryOption > 0 ||
			selectedSalaryOption === 0
		) {
			this.setState({ cardView: 'view' });
		} else {
			this.setState({ cardView: 'home' });
		}
	};

	renderArrowIcon = () => {
		return (
			<div>
				<WebIcon color="var(--oslo-gray)" size={10} name="sort-down" />
			</div>
		);
	};

	customLoader = () => {
		const antIcon = <LoadingOutlined spin style={{ fontSize: 36 }} />;
		return (
			<div style={{ marginTop: '-10px', color: '#00295d' }}>
				<Spin style={{ color: '#00295d' }} indicator={antIcon} />
			</div>
		);
	};

	viewJobs = () => {
		this.props.history.push('/browsejobs/new');
	};

	render() {
		const {
			departmentOptions,
			subCompanyOptions,
			cardView,
			searchedJobs = [],
			selectedDepartmentOptions = [],
			selectedSubCompanyOptions = [],
			selectedSalaryOption,
			hideSubCompany,
		} = this.state;
		const { allMultiLingualData, currentUser } = this.props;

		return (
			<>
				{cardView === 'view' ? (
					<div
						className="e-card-wrap job-alert-wrap"
						style={{ backgroundColor: '#f7a34d' }}
					>
						{this.state.loading ? (
							<div
								className="e-card-title"
								style={{
									height: 90,
									width: 50,
									margin: '-10px 0 10px 0',
									color: '#00295d',
								}}
							>
								{this.customLoader()}
							</div>
						) : (
							<div className="e-card-title">{searchedJobs.length}</div>
						)}

						<div className="job-alert-card-subtitle">
							<h5>New Jobs Added</h5>
							<p>
								Matching Your Preferences{' '}
								<Tooltip
									placement="top"
									title="These are new job matches from the last 14 days that match your criteria."
									style={{ fontWeight: 1000 }}
								>
									<InfoCircleOutlined
										style={{
											cursor: 'pointer',
											verticalAlign: '0.1em',
											color: 'var(--theme-primary)',
										}}
									/>
								</Tooltip>
							</p>
						</div>
						<Button
							type="button"
							className="c-btn-white"
							onClick={this.viewJobs}
						>
							{ml('VIEW JOBS', currentUser, allMultiLingualData)}
						</Button>
						<a onClick={() => this.setState({ cardView: 'edit' })}>
							Edit Job Alerts
						</a>
					</div>
				) : cardView === 'edit' ? (
					<div className="d-card alert-card height-equal">
						<div className="d-card-head">
							<h4 className="d-title">Your Job Alerts</h4>
						</div>
						<div className="d-card-body">
							<div className="custom-form-group" style={{ marginBottom: 10 }}>
								<label className="custom-label">
									{ml(
										this.state.labelDepartment,
										currentUser,
										allMultiLingualData
									)}
									:
								</label>

								<Select
									defaultValue={selectedDepartmentOptions.map(
										(options) => options.key
									)}
									mode="multiple"
									placeholder="Select"
									suffixIcon={this.renderArrowIcon()}
									style={{ borderRadius: 30, width: '100%' }}
									onChange={this.handleSelectDepartment}
								>
									{departmentOptions}
								</Select>
							</div>
							{!hideSubCompany && (
								<div className="custom-form-group" style={{ marginBottom: 10 }}>
									<label className="custom-label">
										{ml(
											this.state.subCompanyLabel,
											currentUser,
											allMultiLingualData
										)}
										:
									</label>

									<Select
										defaultValue={selectedSubCompanyOptions.map(
											(options) => options.key
										)}
										mode="multiple"
										placeholder="Select"
										suffixIcon={this.renderArrowIcon()}
										style={{ borderRadius: 30, width: '100%' }}
										onChange={this.handleSelectSubCompany}
									>
										{subCompanyOptions}
									</Select>
								</div>
							)}
							<div
								className="cutom-form-group"
								style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'space-between',
									marginBottom: 10,
								}}
							>
								<label
									className="custom-label"
									style={{ lineHeight: 1, margin: 0, width: 50 }}
								>
									Min. Pay Rate:
								</label>

								<Select
									placeholder="Select"
									defaultValue={selectedSalaryOption}
									suffixIcon={this.renderArrowIcon()}
									style={{ width: '80%' }}
									onChange={this.handleSelectSalary}
								>
									<Option key={1} value="">
										<span style={{ color: COLORS.placeholderGray }}>
											Select
										</span>
									</Option>
									<Option key={0} value={0}>
										Any Min. Pay Rate
									</Option>
									<Option key={30_000} value={30_000}>
										$15/hr. / $30,000/yr.
									</Option>
									<Option key={40_000} value={40_000}>
										$20/hr. / $40,000/yr.
									</Option>
									<Option key={50_000} value={50_000}>
										$25/hr. / $50,000/yr.
									</Option>
									<Option key={60_000} value={60_000}>
										$30/hr. / $60,000/yr.
									</Option>
									<Option key={70_000} value={70_000}>
										$35/hr. / $70,000/yr.
									</Option>
									<Option key={80_000} value={80_000}>
										$40/hr. / $80,000/yr.
									</Option>
									<Option key={90_000} value={90_000}>
										$45/hr. / $90,000/yr.
									</Option>
									<Option key={100_000} value={100_000}>
										$50/hr. / $100,000/yr.
									</Option>
									<Option key={110_000} value={110_000}>
										$55/hr. / $110,000/yr.
									</Option>
									<Option key={120_000} value={120_000}>
										$60/hr. / $120,000/yr.
									</Option>
									<Option key={140_000} value={140_000}>
										$70/hr. / $140,000/yr.
									</Option>
									<Option key={160_000} value={160_000}>
										$80/hr. / $160,000/yr.
									</Option>
									<Option key={180_000} value={180_000}>
										$90/hr. / $180,000/yr.
									</Option>
									<Option key={200_000} value={200_000}>
										$100/hr. / $200,000/yr.
									</Option>
									<Option key={250_000} value={250_000}>
										$125/hr. / $250,000/yr.
									</Option>
									<Option key={300_000} value={300_000}>
										$150/hr. / $300,000/yr.
									</Option>
								</Select>
							</div>
							<p className="text-center" style={{ fontSize: 10 }}>
								Email alerts are sent weekly. You can edit this under{' '}
								<a href="/myprofile">My Profile</a>
							</p>
							<Button
								block
								disabled={false}
								type="primary"
								shape="round"
								size="large"
								onClick={this.savePreferences}
							>
								Save Preferences
							</Button>
						</div>
					</div>
				) : (
					<div className="d-card alert-card height-equal">
						<div className="d-card-body vh-center">
							<div className="text-center">
								<i
									className="icon-bell"
									style={{
										fontSize: 50,
										color: '#ef3c3f',
									}}
								/>
								<h5 style={{ fontWeight: 700, margin: '10px 0 10px' }}>
									Stay up to date with <br />
									jobs you care about!
								</h5>
								<p style={{ marginBottom: 5 }}>
									<a
										style={{ color: '#018dd3' }}
										target="_blank"
										onClick={() => this.setState({ cardView: 'edit' })}
									>
										Click here
									</a>{' '}
									to set your job search <br />& notification preferences
								</p>
							</div>
						</div>
					</div>
				)}
			</>
		);
	}
}

export default withApollo(withRouter(JobAlertCard));
