import { LeftOutlined } from '@ant-design/icons';
import { Button } from 'antd';

const modalNavigation = (props) => {
	const { contactsChoice } = props;
	return (
		<Button type="link" onClick={() => contactsChoice(0)}>
			<LeftOutlined /> Back
		</Button>
	);
};

export default modalNavigation;
