import { useState } from 'react';
import { message } from 'antd';
import { useRnRCommonService } from '../../service/RnRCommon.service.jsx';

const useRewardDetails = (setIsViewRewardModalVisible) => {

    const [rewardDetails, setRewardDetails] = useState();
    const [isModalLoaderVisible, setIsModalLoaderVisible] = useState(false);
    const commonServiceAPI = useRnRCommonService();

    const handleOpenViewReward = async (id) => {
        try {

            setIsViewRewardModalVisible && setIsViewRewardModalVisible(true);
            setIsModalLoaderVisible(true);
            const response = await commonServiceAPI.getAdminIssuedBadge(id);
            setIsModalLoaderVisible(false);
            if (response?.code === 200) {
                if (response?.data) {
                    setRewardDetails(response.data);
                }
            } else {
                message.error('Failed to fetch badge details! Try again later.');
            }
        } catch (error) {
            setIsModalLoaderVisible(false);
            console.log(error);
            message.error('Failed to fetch badge details! Try again later.');
        }
    };

    return { rewardDetails, isModalLoaderVisible, handleOpenViewReward };
};

export default useRewardDetails;
