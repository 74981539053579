import { connect } from 'react-redux';
import { withGetCompany } from 'src/_shared/api/components/settings/';
import { compose } from '../../_shared/services/utils.js';
import JobNotificationComponent from './JobNotificationComponent.jsx';

const mapStateToProperties = (state) => {
	const { allMultiLingualData } = state.dashboard;
	return {
		currentUser: state.user.currentUser,
		companyId: state.user.currentUser.companyId,
		allMultiLingualData,
		filter: {
			companyId: { eq: state.user.currentUser.companyId },
		},
		nextToken: null,
		limit: 10_000,
	};
};

export const JobNotificationApi = compose(withGetCompany)(
	JobNotificationComponent
);

export const JobNotification =
	connect(mapStateToProperties)(JobNotificationApi);
