export const listOnDeckContacts = `query ListOnDeckContacts(
  $filter: TableContactFilterInput
  $limit: Int
  $nextToken: String
) {
  listOnDeckContacts(
    filter: $filter,
    limit: $limit,
    nextToken: $nextToken
  ) {
    items {
      id
      companyId
      firstName
      lastName
      emailAddress
      phoneNumber
      userId 
      user {
        id
        firstName
        lastName
        title
        userGroupId
        userGroup {
          id
          measurement
          name
          currency
        }
        currency
        languageCode
      }
      referrals {
        id
        contactId
      }
      importMethod
      fullContactData
      dateCreated
      onDeckDate
      onDeckStatus
      onDeckNote
      contactResume {
        bucket
        region
        key
      }
      questionsData
    }
    nextToken
  }
}
`;
