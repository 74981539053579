import { Select } from 'antd';
import { css } from 'emotion';
import { COLORS } from '../styles/colors';

// THIS COMPONENT IS MEANT TO BE A REUSABLE FILTER PURPOSED TO KEEP TOOLBAR FILTERS STYLED CONSISTENTLY

export default ({
	options,
	placeholder,
	onChange,
	appliedFilters,
	className,
	...props
}) => {
	return (
		<div className="custom-form-group">
			<div className="custom-form-green-select">
				<Select
					showArrow
					mode="multiple"
					placeholder={placeholder}
					maxTagCount={1}
					maxTagTextLength={15}
					className="custom-input"
					onChange={onChange}
					{...props}
				>
					{options.map(({ label, value }, index) => (
						<Select.Option key={index} value={value}>
							{label}
						</Select.Option>
					))}
				</Select>
			</div>
		</div>
	);
};

const appliedFiltersStyles = css({
	width: 300,
	'& .ant-select-selection': {
		background: COLORS.inputActiveBackground,
		color: COLORS.inputActiveColor,
		borderRadius: 32,
		padding: '0 10px',
		borderColor: COLORS.inputActiveBackground,
		boxShadow: 'none',
	},
	'& .ant-select-selection.ant-select-selection--multiple': {
		height: '32px',
		overflow: 'hidden',
	},
	'& .ant-select-selection__placeholder': {
		color: COLORS.inputActiveColor,
	},
	'& .ant-select-search__field__placeholder': {
		color: COLORS.inputActiveColor,
	},
	'& .ant-select-selection__choice': {
		backgroundColor: 'rgba(143, 143, 143, 0.2)',
		color: COLORS.inputActiveColor,
		maxWidth: '60%',
	},
	'& .ant-select-focused .ant-select-selection': {
		borderColor: COLORS.inputActiveColor,
		boxShadow: '0 0 0 2px rgba(143, 143, 143, 0.2)',
	},
	'& .ant-select-selection__choice__remove': {
		color: COLORS.inputActiveColor,
	},
	'& .ant-select-selection:hover': {
		borderColor: COLORS.inputActiveBackground,
		boxShadow: '0 0 0 2px rgba(143, 143, 143, 0.2)',
	},
	'& .ant-select-selection:focus': {
		borderColor: COLORS.inputActiveBackground,
		boxShadow: '0 0 0 2px rgba(143, 143, 143, 0.2)',
	},
	'& .ant-select-selection:active': {
		borderColor: COLORS.inputActiveBackground,
		boxShadow: '0 0 0 2px rgba(143, 143, 143, 0.2)',
	},
});

const defaultStyles = css({
	width: 300,
	'& .ant-select-selection': {
		background: COLORS.inputDisableBackground,
		color: COLORS.inputDisableColor,
		borderRadius: 32,
		padding: '0 10px',
		borderColor: COLORS.inputDisableBackground,
		boxShadow: 'none',
	},
	'& .ant-select-selection__placeholder': {
		color: COLORS.inputDisableColor,
	},
	'& .ant-select-search__field__placeholder': {
		color: COLORS.inputDisableColor,
	},
	'& .ant-select-selection__choice': {
		backgroundColor: COLORS.inputActiveBackground,
		color: COLORS.white,
	},
	'& .ant-select-focused .ant-select-selection': {
		borderColor: COLORS.inputDisableBackground,
		boxShadow: '0 0 0 2px rgba(143, 143, 143, 0.2)',
	},
	'& .ant-select-selection:hover': {
		borderColor: COLORS.inputActiveBackground,
		boxShadow: '0 0 0 2px rgba(143, 143, 143, 0.2)',
	},
	'& .ant-select-selection:focus': {
		borderColor: COLORS.inputActiveBackground,
		boxShadow: '0 0 0 2px rgba(143, 143, 143, 0.2)',
	},
	'& .ant-select-selection:active': {
		borderColor: COLORS.inputActiveBackground,
		boxShadow: '0 0 0 2px rgba(143, 143, 143, 0.2)',
	},
});
