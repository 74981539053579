import gql from 'graphql-tag';

export const GetSubCompany = gql`
	query GetSubCompany($id: ID!) {
		getSubCompany(id: $id) {
			id
			companyId
			keywords
			logo {
				bucket
				region
				key
			}
			name
		}
	}
`;

export const querySubCompanyByCompanyIdIndex = `
  query QuerySubCompanyByCompanyIdIndex($companyId: ID!
    $first: Int
    $after: String) {
    querySubCompanyByCompanyIdIndex(
      companyId: $companyId
      first: $first
      after: $after
    ) {
      items { 
        id
        companyId
        keywords
        logo {
          bucket
          region
          key
        }
        name
      }
    }
  }
`;
