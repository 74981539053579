import { Button, Input, message } from 'antd';
import get from 'lodash/get';
import QRCode from 'qrcode';
import { useEffect, useState } from 'react';
import { ml } from 'src/_shared/services/utils.js';
import { roundSocialRow, socialTypes } from './addContactsModalStyles.js';

function AddContactsChoice({
	contactsChoice,
	companyData,
	currentUser,
	allMultilingualData,
}) {
	const [generatedQRCode, setgeneratedQRCode] = useState(null);
	const [extendedNetworkUrl] = useState(
		currentUser.id
			? `${window.location.origin}/new-user-sign-up/${currentUser.id}`
			: null
	);
	const { Search } = Input;

	useEffect(() => {
		try {
			QRCode.toDataURL(extendedNetworkUrl).then((result) =>
				setgeneratedQRCode(result)
			);
		} catch (error) {
			console.error(error);
		}
	}, [extendedNetworkUrl]);

	return (
		<>
			<div className="ant-modal-header inside-body">
				<div className="ant-modal-title">
					{get(companyData, 'enableExtendedNetwork')
						? ml('Invite to Network', currentUser, allMultilingualData)
						: ml('Add Contacts', currentUser, allMultilingualData)}
				</div>
			</div>

			{get(companyData, 'enableExtendedNetwork') && (
				<p className="small text-center">
					Invite your network to browse your company's jobs and request
					referrals from you. They can also be matched to open positions when
					they upload their resume.
				</p>
			)}
			{generatedQRCode && get(companyData, 'enableExtendedNetwork') && (
				<div className="share-qr-code">
					<img src={generatedQRCode} />
				</div>
			)}
			<div className="contact-invite-link">
				{get(companyData, 'enableExtendedNetwork') && (
					<>
						<div className="invite-label">
							<label>Invite Link</label>
							<p>Anyone can use this link to join your referral network</p>
						</div>
						<Search
							className="custom-search-grp"
							enterButton="Copy"
							defaultValue={extendedNetworkUrl}
							onSearch={() => {
								navigator.clipboard
									.writeText(extendedNetworkUrl)
									.then(() => {
										message.success('Copied to clipboard');
									})
									.catch((error) => {
										message.error('Failed to copy to clipboard');
									});
							}}
						/>
					</>
				)}
				<div className="invite-link-bottom">
					<div className="text-center" style={{ marginBottom: 10 }}>
						<Button type="link" onClick={() => contactsChoice(4)}>
							<i className="icon-envelope-outline" style={{ fontSize: 18 }} />
							<span>
								{get(companyData, 'enableExtendedNetwork')
									? 'Invite by Email'
									: 'Add by Email'}
							</span>
						</Button>
					</div>
					<p>
						Add your contacts to easily refer them and Invite them to your
						referral network
					</p>
				</div>
			</div>

			<div className={roundSocialRow}>
				<div className={socialTypes} />
			</div>
		</>
	);
}

export default AddContactsChoice;
