import axios from 'axios';
import _ from 'lodash';
import get from 'lodash/get';
import { Component } from 'react';
import GoogleLogin from 'react-google-login';
import { gConfig } from 'src/_shared/api/';
import ContactChecklist from './AddContactsChecklistComponent.jsx';
import {
	ModalTitle,
	alignCenter,
	darkGrayText,
} from './addContactsModalStyles.js';

class AddContactsGoogle extends Component {
	constructor(props) {
		super(props);
		const googleClientId = _.get(
			props,
			'currentUser.company.API.googleClientId'
		);
		let gConfig_;
		if (googleClientId) {
			gConfig_ = { ...gConfig, clientId: googleClientId };
		} else {
			gConfig_ = gConfig;
			if (get(props, 'googleAuthClientID'))
				gConfig_.clientId = this.props.googleAuthClientID;
		}

		this.state = {
			isSignedIn: false, // Local signed-in state.
			gContacts: null,
			checked: [],
			checkAll: false,
			gContactsOther: null,
			gEmail: '',
			gConfig: gConfig_,
		};
	}

	getOtherGContacts = (oauthToken, gContacts) => {
		const peopleUrl = _.get(this.state, 'gConfig.peopleUrl');
		axios
			.get(peopleUrl, {
				crossdomain: true,
				headers: {
					'GData-Version': 3,
					Authorization: `Bearer ${oauthToken}`,
				},
			})
			.then(
				(response) => {
					const { data } = response;
					const otherGContacts = data.connections;

					this.combineContacts(gContacts, otherGContacts);
				},
				(error) => {
					console.error(error);
				}
			);
	};

	combineContacts = (gContacts, otherGContacts) => {
		const filteredGContacts = _.remove(gContacts, function (contact) {
			return (
				contact.gd$email !== undefined || contact.gd$phoneNumber !== undefined
			);
		});
		const filteredOtherContacts = _.remove(otherGContacts, function (contact) {
			return (
				contact.phoneNumbers !== undefined ||
				contact.emailAddresses !== undefined
			);
		});
		const combinedArray = [...filteredGContacts, ...filteredOtherContacts];
		this.setState({
			gContacts: combinedArray,
		});
	};

	responseGoogle = (googleUser) => {
		const oauthToken = googleUser.getAuthResponse(true).access_token;
		this.setState({ gEmail: googleUser.profileObj.email });
		const contactsUrl = _.get(this.state, 'gConfig.contactsUrl');
		axios
			.get(contactsUrl + oauthToken + '&alt=json&v=3.0', {
				crossdomain: true,
				headers: {
					'GData-Version': 3,
					Authorization: `Bearer ${oauthToken}`,
				},
			})
			.then(
				(response) => {
					const gContacts = response.data.feed.entry;
					this.getOtherGContacts(oauthToken, gContacts);
				},
				(error) => {
					const errorStatus = error.response.status;
					console.error(errorStatus);
				}
			);
	};

	render() {
		const templates = [];
		if (this.state.gContacts) {
			const importedGContacts = this.state.gContacts;
			templates.push(
				<ContactChecklist
					key="googleContactsList"
					importedContacts={importedGContacts}
					updateUserIncentiveEligibility={
						this.props.updateUserIncentiveEligibility
					}
					currentUser={this.props.currentUser}
					importMethod="google"
					contactsChoice={this.props.contactsChoice}
					handleCancel={this.props.handleCancel}
					accountUsed={this.state.gEmail}
					onCreateContact={this.props.onCreateContact}
				/>
			);
		}

		return (
			<div className={alignCenter}>
				<h1 className={ModalTitle}>Import your Google contacts</h1>
				<p className={darkGrayText}>
					You can choose which contacts to import after authenticating
				</p>
				<GoogleLogin
					clientId={_.get(this.state, 'gConfig.clientId')}
					scope={_.get(this.state, 'gConfig.scope')}
					buttonText="Login and Import Contacts"
					onSuccess={this.responseGoogle}
					onFailure={this.responseGoogle}
				/>
				{templates}
			</div>
		);
	}
}

export default AddContactsGoogle;
