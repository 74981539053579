import gql from 'graphql-tag';

export const GetMultiLingualDataByLanguage = gql`
	query GetMultiLingualDataBylanguageCode($languageCode: String!) {
		getMultiLingualDataBylanguageCode(languageCode: $languageCode) {
			items {
				id
				key
				languageCode
				matchKey
				module
				text
			}
			nextToken
		}
	}
`;
