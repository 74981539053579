import _ from 'lodash';

export default ({ referral: { status, customStatus } }) => {
	// Progress bar is highlighed depending on what the status is
	const [progress, widthClassName] = [
		'hired',
		'ineligible',
		'notHired',
		'declined',
		'inactive',
		'noResponse',
	].includes(status)
		? [2, 'w-full']
		: ['interviewing', customStatus].includes(status)
			? [1, 'w-1/2']
			: ['accepted'].includes(status)
				? [0, 'w-0']
				: [0, 'w-0'];

	const progressBarColor =
		status === 'hired'
			? '#1d861d'
			: [
						'ineligible',
						'notHired',
						'declined',
						'inactive',
						'noResponse',
				  ].includes(status)
				? '#ef3c3f'
				: '#ffb461';

	return (
		<ul className="my-progressbar-inner progressbar-single">
			{/* Bubble two and three are dynamic, after you find out what they're supposed to be, map over and render correct bubbles */}
			{[
				'started',
				`${customStatus ? customStatus : 'interviewing'}`,
				`${
					[
						'hired',
						'ineligible',
						'notHired',
						'declined',
						'inactive',
						'noResponse',
					].includes(status)
						? status
						: 'hired'
				}`,
			].map((statusOption, index) => {
				const highlighted = index <= progress;
				const progressHighlight = index < progress;

				return (
					<li key={index}>
						<Bubble
							highlighted={highlighted}
							statusOption={statusOption}
							progressBarColor={progressBarColor}
							progressHighlight={progressHighlight}
						/>
					</li>
				);
			})}
		</ul>
	);
};

function Bubble({
	highlighted,
	statusOption,
	progressBarColor,
	progressHighlight,
}) {
	return (
		<>
			<div
				className="progress-line gray"
				style={progressHighlight ? { borderColor: progressBarColor } : {}}
			/>
			<div
				className="progress-circle gray"
				style={
					highlighted
						? {
								borderColor: progressBarColor,
								backgroundColor: progressBarColor,
							}
						: {}
				}
			>
				{highlighted ? <i className="icon-check progress-icon" /> : null}
			</div>
			<span className="progress-text">{_.startCase(statusOption)}</span>
		</>
	);
}
