import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { updateEditEmails } from '../../graphql/custom/edit-emails';

export const withUpdateEditEmails = (Component) => {
	return graphql(gql(updateEditEmails), {
		props: (props) => ({
			onUpdateEditEmails(input) {
				return props
					.mutate({
						variables: input,
					})
					.then((resp) => resp.data.updateEditEmails);
			},
		}),
	})(Component);
};
