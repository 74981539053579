import gql from 'graphql-tag';
import get from 'lodash/get';
import dayjs from 'dayjs';
import { compose, graphql } from 'react-apollo';
import uuid from 'uuid/v4';
import { createContact, listContacts } from '../../graphql/custom/contacts';
import { GetJob } from '../../graphql/custom/jobs';
import { createReferral } from '../../graphql/custom/referrals';

export const withCreateReferral = (Component) => {
	return compose(
		graphql(gql(createReferral), {
			props: (props) => ({
				onCreateReferral(input) {
					const optimisticResponseData = {
						...input.input,
						id: uuid(),
						referralDate: dayjs(),
					};
					props.mutate({
						context: {
							headers: {
								'x-frame-options': 'deny', // This header will reach the server
							},
						},
						variables: input,
						optimisticResponse: {
							__typeName: 'Mutation',
							createReferral: {
								__typeName: 'createReferral',
								...optimisticResponseData,
							},
						},
						update(proxy, { data: { createReferral: newReferral } }) {
							try {
								const data = proxy.readQuery({
									query: GetJob,
									variables: {
										id: get(
											props,
											['ownProps', 'jobData', 'id'],
											get(props, ['ownProps', 'job', 'id'], [])
										),
									},
								});
								if (data.GetJob) {
									const referrals = get(data, 'GetJob.referrals', []);
									const hasReferralBeenCached = referrals.find((referral) => {
										return newReferral ? referral.id === newReferral.id : false;
									});

									if (!hasReferralBeenCached) {
										referrals.push(newReferral);

										proxy.writeQuery({
											query: GetJob,
											variables: {
												filter: {
													id: get(
														props,
														['ownProps', 'jobData', 'id'],
														get(props, ['ownProps', 'job', 'id'], [])
													),
												},
											},
											referrals,
											data,
										});
									}
								}
							} catch {
								// ignore
							}
						},
					});
				},
			}),
		}),
		graphql(gql(createContact), {
			props: (props) => ({
				ImportedCreateContact(input) {
					const optimisticResponseData = {
						id: uuid(),
						...input.input,
						referrals: null,
						__typename: 'Contact',
					};
					return props.mutate({
						context: {
							headers: {
								'x-frame-options': 'deny', // This header will reach the server
							},
						},
						variables: input,
						optimisticResponse: {
							__typename: 'Mutation',
							createContact: {
								__typename: 'createContact',
								...optimisticResponseData,
							},
						},
						update(proxy, { data: { createContact } }) {
							const data = proxy.readQuery({
								query: gql(listContacts),
								variables: {
									filter: {
										userId: { eq: props.ownProps.currentUser.id },
									},
									limit: 5000,
									nextToken: null,
								},
							});
							if (
								!data.listContacts.items.find(
									(contact) => contact.id === createContact.id
								)
							) {
								data.listContacts.items.push(createContact);
							}

							proxy.writeQuery({
								query: gql(listContacts),
								context: {
									headers: {
										'x-frame-options': 'deny', // This header will reach the server
									},
								},
								variables: {
									filter: {
										userId: { eq: props.ownProps.currentUser.id },
									},
									limit: 5000,
									nextToken: null,
								},
								data,
							});
						},
					});
				},
			}),
		})
	)(Component);
};
