import gql from 'graphql-tag';
import get from 'lodash/get';
import { compose, graphql } from 'react-apollo';
import {
	GetCompanySettings,
	updateCompany,
} from '../../graphql/custom/company';

export const withGetCompany = (Component, variables) => {
	return compose(
		graphql(GetCompanySettings, {
			options: (props) => ({
				variables: {
					id: props.currentUser.companyId,
				},
				fetchPolicy: 'network-only',
			}),
			props(props) {
				return {
					company: props.data.getCompany ? props.data.getCompany : undefined,
				};
			},
		}),
		graphql(gql(updateCompany), {
			props: (props) => ({
				onUpdateCompany(input) {
					try {
						return props.mutate({
							variables: input,
							refetchQueries: [
								{
									query: GetCompanySettings,
									variables: {
										id: get(props, 'ownProps.currentUser.companyId'),
									},
								},
							],
						});
					} catch (error) {
						console.log(error);
					}
				},
			}),
		})
	)(Component);
};
