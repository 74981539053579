import { css } from 'emotion';
import { COLORS } from '../../_shared/styles/colors';

const breakpoints = [576, 767, 991, 1200];

const mq = breakpoints.map((bp) => `@media (max-width: ${bp}px)`);

export const mainContainer = css({
	width: '100%',
	height: '100%',
	padding: 20,
	[mq[1]]: {
		padding: '10px 5px',
	},
});

export const LinkStyles = css({
	color: COLORS.hyperLink,
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: '600 !important',
	display: 'inline-flex',
	alignItems: 'center',
});

export const hiredCountDiv = css({
	backgroundColor: COLORS.dashboardGreen,
	borderRadius: 10,
	height: 50,
	marginBottom: 20,
	textAlign: 'center',
	display: 'inline-block',
});

export const editJobButtonText = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 200,
	color: COLORS.blue,
	fontSize: 18,
	marginLeft: 3,
});

export const addJobsButtonIcon = css({
	fontSize: 18,
});

export const topContainer = css({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'flex-start',
	marginBottom: 30,
	maxWidth: 1290,
});

export const bottomContainer = css({
	marginTop: 25,
	marginBottom: 15,
	maxWidth: 1290,
});

export const Card1Styles = css({
	padding: 30,
	backgroundColor: 'white',
	borderRadius: 10,
	[mq[1]]: {
		padding: 20,
	},
});

export const modalTitleText = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 500,
	color: COLORS.heading,
	fontSize: '1.5em',
});

export const ModalStyles = css({
	'& .ant-modal-content': {
		borderRadius: 20,
	},
	'& .ant-modal-header': {
		background: 'none',
	},
	'& .ant-modal-close': {
		outline: 'none',
		'&:focus .ant-modal-close-icon': {
			outline: 'auto',
		},
	},
	fontFamily: 'Open Sans',
});

export const loadingImage = css({
	display: 'block',
	margin: 'auto',
});

export const hiredStandardText = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 600,
	color: COLORS.heading,
	fontSize: '1.25em',
	opacity: 0.75,
});

export const hiredCountText = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 600,
	color: COLORS.white,
	fontSize: '1.25em',
});

export const spacer = css({
	margin: 5,
	height: 2,
	width: '100%',
});

export const gridTitle = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 600,
	color: COLORS.black,
	fontSize: 22,
	lineHeight: '40px',
});

export const FlexContainer = css({
	display: 'flex',
	maxWidth: 1290,
	paddingRight: 20,
});

export const jobDetailWrap = css({
	maxWidth: 1290,
});

export const middleContainer = css({
	display: 'flex',
	maxWidth: 1290,
	justifyContent: 'space-between',
	marginTop: 30,
	[mq[1]]: {
		flexDirection: 'column',
	},
});

export const openButtonStyles = (selected) =>
	css({
		margin: 8,
		backgroundColor: selected ? COLORS.red : COLORS.white,
		color: selected ? COLORS.white : COLORS.subHeading,
		'&:hover': {
			backgroundColor: COLORS.red,
			color: COLORS.white,
			borderColor: 'transparent',
		},
		'&:focus': {
			backgroundColor: COLORS.red,
			color: COLORS.white,
			borderColor: 'transparent',
		},
		height: 30,
		width: 'auto',
		[mq[1]]: {
			margin: 4,
		},
	});

export const toggleTitleStyles = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 600,
	color: COLORS.black,
	fontSize: 18,
	marginTop: 14,
});

export const NoSmartReferralsWithLogo = css({
	width: 955,
	textAlign: 'center',
	fontSize: 14,
	fontWeight: 400,
});

export const NoSmartReferrals = css({
	marginTop: 40,
	width: '100%',
	textAlign: 'center',
	fontSize: 14,
	fontWeight: 400,
});

export const spinContainer = css({
	width: '100%',
	height: '50vh',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
});

export const openJobContainer = css({
	height: 50,
	backgroundColor: COLORS.lightGray3,
	borderRadius: 10,
	width: '100%',
	marginBottom: 20,
	display: 'flex',
	justifyContent: 'center',
	'& .ant-btn:hover': {
		background: COLORS.lightGray3,
		color: COLORS.blue,
	},
});

export const openJobText = css({
	lineHeight: '50px',
});

export const openJobBtn = css({
	border: 'none',
	padding: 0,
	backgroundColor: 'transparent',
	color: COLORS.blue,
});
