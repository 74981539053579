export const createReferral = `mutation CreateReferral($input: CreateReferralInput!) {
  createReferral(input: $input) {
    id
    companyId
    contactId
    contact {
      id
      emailAddress
      lastName
      firstName
      socialMediaAccounts
      phoneNumber
      contactResume {
        bucket
        region
        key
      }
    }
    userId
    user {
      id
      firstName
      lastName
      emailAddress
    }
    jobId
    job {
      id
      title
      departmentId
      department {
        id
        name
      }
      referralBonus
      externalJobId
      externalSource
    }
    note
    message
    referralDate
    referralType
    status
    bonusStatus
    ownershipAtsId
    customStatus
  }
}
`;
