import gql from 'graphql-tag';

export const getContactById = gql`
	query GetContact($id: ID!) {
		getContact(id: $id) {
			id
			firstName
			lastName
			emailAddress
			socialMediaAccounts
			fullContactData
		}
	}
`;
