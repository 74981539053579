import { css } from 'emotion';
import get from 'lodash/get';
import { COLORS } from '../../_shared/styles/colors';

const breakpoints = [576, 767, 991, 1200];

const mq = breakpoints.map((bp) => `@media (max-width: ${bp}px)`);

export const bodyStyle = css({
	'.ant-modal-content': {
		borderRadius: 12,
	},
	'& .ant-modal-footer': {
		borderTop: 'none',
	},
	'& .ant-modal-wrap': {
		paddingRight: 70,
	},
	'& .ant-modal-close-x': {
		fontSize: 30,
		paddingTop: 10,
		color: COLORS.lightGray,
	},
});

export const cardStyle = css({
	width: '100%',
	padding: 10,
	minHeight: 200,
	border: 'solid 1px #ddd',
});

export const subtitleStyle = css({
	fontWeight: 600,
	fontSize: 16,
	paddingTop: 5,
	marginBottom: 0,
});

export const modalText1 = css({
	textAlign: 'center',
	fontStyle: 'italic',
	fontSize: 12,
});

export const modalText2 = css({
	textAlign: 'center',
});

export const LinkStyle = css({
	color: COLORS.blue,
	textAlign: 'center',
});

export const buttonStyle = (theme) => {
	let buttonColor = COLORS.blue;
	if (get(theme, 'enabled')) {
		buttonColor = get(theme, 'buttonColor', buttonColor);
	}

	return css({
		border: 0,
		borderRadius: 3,
		backgroundColor: buttonColor,
		color: COLORS.white,
		fontSize: 20,
		width: 120,
		height: 45,
		fontWeight: '300',
		letterSpacing: '2.0px',
	});
};

export const fileWrap = css`
	display: inline-block;
	position: relative;
	font-weight: 600;
	font-size: 14px;
	color: ${COLORS.blue};
	label {
		cursor: pointer;
	}
	input {
		opacity: 0;
		width: 0.1px;
	}
`;

export const switchModal = css`
	display: inline-block;
	position: relative;
	font-weight: 400;
	font-size: 13px;
	color: ${COLORS.blue};
	label {
		cursor: pointer;
	}
	input {
		opacity: 0;
		width: 0.1px;
	}
`;

export const roleContainer = css({
	'> *': {
		display: 'inline-block',
	},
	[mq[1]]: {
		'> *': {
			display: 'block',
		},
	},
});

export const roleSelector = css({
	marginTop: 10,
	maxWidth: 200,
	[mq[1]]: {
		marginLeft: 0,
		marginBottom: 16,
	},
});

export const UploadLabel = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 600,
	color: COLORS.lightGray,
	fontSize: 15,
});
