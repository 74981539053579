import { useState } from 'react';
import { RewardList as RewardListContainer } from '../../../RnRCommonComponent';
import RewardPointsAndBonusContainer from '../../container/rewardPointsAndBonusContainer';
import './style.scss';
import { BADGE_TYPE } from '../../../RnRCommonComponent/CoreBadge/rewardsConstants.js';
import ViewRewardModal from '../../../RnRCommonComponent/ViewRewardModal/ViewRewardModal.jsx';
import RewardsProgressIndicator from 'src/_shared/components/rewards-progress-indicator/RewardsProgressIndicator.jsx';

const MyrewardsPresentation = (props) => {
	const {
		handleOpenViewReward,
		handleCloseViewReward,
		isViewRewardModalVisible,
		rewardDetails,
		isModalLoaderVisible,
		tiers,
		currentUser,
	} = props;
	const [shouldRefresh, setShouldRefresh] = useState(false);
	const isSharingVisible = !currentUser?.company?.hideRecognitionSocialShare;

	return (
		<div className="my-recognitions">
			<div className="my-recognitions-container">
				<div className="row">
					<div className="col-xl-6 col-12">
						<RewardPointsAndBonusContainer />
					</div>
					<div className="col-xl-6 col-12">
						<div className="rewards-points d-card height-equal">
							<div className="d-card-head">
								<h4 className="d-title-no-case">Rewards Progress </h4>
							</div>
							<RewardsProgressIndicator
								currentUser={currentUser}
								tiers={tiers}
							/>
						</div>
					</div>
				</div>
			</div>
			<RewardListContainer
				handleIconClickEvent={(badge) => {
					handleOpenViewReward(badge.badgeId);
				}}
				title="My Recognitions"
				badgeType={BADGE_TYPE.PEERTOPEER}
				getMyBadge
				shouldRefresh={shouldRefresh}
				setShouldRefresh={setShouldRefresh}
				isDetailsShown={false}
				isPointsShown={true}
				isIssuedVisible={true}
			/>
			<ViewRewardModal
				isViewRewardModalVisible={isViewRewardModalVisible}
				handleCloseViewReward={handleCloseViewReward}
				rewardDetails={rewardDetails}
				isModalLoaderVisible={isModalLoaderVisible}
				isSharingVisible={isSharingVisible}
			/>
		</div>
	);
};

export default MyrewardsPresentation;
