import { connect } from 'react-redux';
import { compose } from '../../services/utils';
import TieredBonus from './TieredBonusComponent.jsx';

const mapStateToProperties = (state) => {
	const { currentUser } = state.user;

	return {
		currentUser,
	};
};

export default connect(mapStateToProperties)(compose()(TieredBonus));
