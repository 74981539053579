import gql from 'graphql-tag';
import get from 'lodash/get';
import { compose, graphql } from 'react-apollo';
import { queryUsersByCompanyIdRoleIndex } from '../../graphql/custom/users';

export const withQueryUsersByCompanyIdRoleIndex = (Component) => {
	return compose(
		graphql(gql(queryUsersByCompanyIdRoleIndex), {
			options: (props) => ({
				variables: {
					companyId: props.currentUser.companyId,
					role: props.filter.role.eq,
				},
				fetchPolicy: 'cache-and-network',
			}),
			props(response, previous) {
				const users = get(
					response,
					['data', 'queryUsersByCompanyIdRoleIndex', 'items'],
					get(previous, 'users', [])
				);
				const usersNextToken = get(
					response,
					['data', 'queryUsersByCompanyIdRoleIndex', 'nextToken'],
					null
				);
				const onFetchMoreUsers = makeOnFetchMoreUsers(
					response.data.fetchMore,
					usersNextToken
				);

				return { users, onFetchMoreUsers, usersNextToken };
			},
		})
	)(Component);
};

const makeOnFetchMoreUsers = (fetchMore, nextToken) => {
	if (!nextToken) {
		return null;
	}

	return () => {
		fetchMore({
			variables: {
				companyId: '7d51747c-f954-493c-b49d-8931faf28025',
				role: 'employee',
				after: nextToken,
			},
			updateQuery(previous, { fetchMoreResult }) {
				if (!fetchMoreResult) {
					return;
				}

				const previousItems = get(
					previous,
					['queryUsersByCompanyIdRoleIndex', 'items'],
					[]
				);
				const moreItems = get(
					fetchMoreResult,
					['queryUsersByCompanyIdRoleIndex', 'items'],
					[]
				);
				return {
					...previous,
					queryUsersByCompanyIdRoleIndex: {
						...previous.queryUsersByCompanyIdRoleIndex,
						...fetchMoreResult.queryUsersByCompanyIdRoleIndex,
						items: [...previousItems, ...moreItems],
					},
				};
			},
		});
	};
};
