import { connect } from 'react-redux';
import MyApplications from './MyApplicationsComponent.jsx';

const mapStateToProperties = (state) => {
	const { allMultiLingualData } = state.dashboard;
	return {
		currentUser: state.user.currentUser,
		allMultiLingualData,
	};
};

export default connect(mapStateToProperties)(MyApplications);
