import React, { useEffect, useState } from 'react'

const useCommonHooks = (apiCall, id = null) => {
  const [data, setData] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [resError, setResError] = useState(null)

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true)
        const res = await apiCall
        if (res.code === 200) {
          setIsLoading(false)
          setData(res?.data)
        }
      } catch (err) {
        setResError(err.message)
      }
    }
    )()

    return () => {
    }
  }, [id])


  return { isLoading, data, resError }
}

export default useCommonHooks