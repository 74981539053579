import gql from 'graphql-tag';

export const getExtendedContactById = gql`
	query GetContact($id: ID!) {
		getContact(id: $id) {
			id
			firstName
			lastName
			emailAddress
			extendedUserId
			socialMediaAccounts
			userId
			user {
				id
				firstName
				lastName
				avatar {
					bucket
					region
					key
				}
			}
			companyId
			company {
				id
				brandColor
				logo {
					key
				}
				name
				theme
				whiteLabel
			}
			phoneNumber
			jobHistory
			importMethod
			inviteStatus
			fullContactData
			dateCreated
			onDeckStatus
			onDeckNote
			onDeckDate
			contactResume {
				bucket
				region
				key
			}
		}
	}
`;
