import gql from 'graphql-tag';
import get from 'lodash/get';
import { compose, graphql } from 'react-apollo';
import { createContact } from 'src/_shared/api/graphql/custom/contacts/';
import { createUser, getUserById } from 'src/_shared/api/graphql/custom/users/';
import { GetCompanyByHost } from '../../graphql/custom/company';
import { updateContact } from '../../graphql/custom/contacts';

export const withNewExtendedUserLink = (Component) => {
	let { host } = window.location;
	host = host.replace('www.', '');
	return compose(
		graphql(gql(getUserById), {
			options: (props) => ({
				context: {
					headers: {
						'x-frame-options': 'deny', // This header will reach the server
					},
				},
				variables: { id: props.id },
				fetchPolicy: 'network-only',
			}),
			props: (props) => ({
				user: get(props, 'data.getUser'),
			}),
		}),
		graphql(GetCompanyByHost, {
			options: {
				context: {
					headers: {
						'x-frame-options': 'deny', // This header will reach the server
					},
				},
				variables: { host },
				fetchPolicy: 'network-only',
			},
			props: (props) => ({
				company: get(props, 'data.getCompanyByHost'),
			}),
		}),
		graphql(gql(createUser), {
			props: (props) => ({
				onCreate(user) {
					return props.mutate({
						context: {
							headers: {
								'x-frame-options': 'deny', // This header will reach the server
							},
						},
						variables: { input: user },
					});
				},
			}),
		}),
		graphql(gql(createContact), {
			props: (props) => ({
				onCreateContact(contact) {
					return props.mutate({
						context: {
							headers: {
								'x-frame-options': 'deny', // This header will reach the server
							},
						},
						variables: { input: contact },
					});
				},
			}),
		}),
		graphql(gql(updateContact), {
			props: (props) => ({
				onUpdateContact(contact) {
					return props.mutate({
						context: {
							headers: {
								'x-frame-options': 'deny', // This header will reach the server
							},
						},
						variables: { input: contact },
					});
				},
			}),
		})
	)(Component);
};
