import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Input } from 'antd';
import { get } from 'lodash';
import { useEffect, useState } from 'react';
import { parse } from 'src/_shared/services/utils.js';
import { ONBOARDING_SCREENS } from '../constants';
import { SaasAgreementText } from '../utils';
import {
	buttonContainerStyle,
	buttonStyle,
	containerStyle,
	textBodyStyle,
	titleContainerStyle,
	titleStyle,
} from './onboardingStyles.js';

function SaasAgreementScreen(props) {
	const {
		company,
		completeOnboardingPage,
		currentUser,
		nextPage,
		onboarding,
		navToScreen,
	} = props;

	const [formValues, setFormValues] = useState({
		firstName: '',
		lastName: '',
		email: '',
	});

	const { name: companyName, partnerHost } = company;
	const policy = parse(get(company, 'partnerHost.partnerBillingPolicy'));

	const next = (e) => {
		completeOnboardingPage(ONBOARDING_SCREENS.SAAS_AGREEMENT, {
			acceptedAgreementDate: new Date().toISOString(),
			acceptedAgreementUserId: get(currentUser, 'id'),
			acceptedAgreementUserFirstName: formValues.firstName,
			acceptedAgreementUserLastName: formValues.lastName,
			acceptedAgreementUserEmail: formValues.email,
		});

		navToScreen(ONBOARDING_SCREENS.HOW_TO_PAY);
	};

	return (
		<div className={containerStyle}>
			<div className={titleContainerStyle(partnerHost)}>
				<h1 className={titleStyle}>Software As A Service Agreement</h1>
				<div className={textBodyStyle}>
					<SaasAgreementText companyName={companyName} />
					<p className="text-center">
						<em>
							By clicking "Accept & Continue", you are understand and agree to
							the ERIN Technologies SaaS Agreement.
						</em>
					</p>
					<AcceptSaasAgreementForm
						policy={policy}
						navToScreen={next}
						formValues={formValues}
						onValueChange={setFormValues}
					/>
				</div>
			</div>
		</div>
	);
}

function AcceptSaasAgreementForm({
	policy,
	navToScreen,
	formValues,
	onValueChange,
}) {
	const [formComplete, setFormComplete] = useState(false);

	const { firstName, lastName, email } = formValues;

	const handleChange = (e, key) => {
		onValueChange((prevState) => {
			return {
				...prevState,
				[key]: e.target.value,
			};
		});
	};

	useEffect(() => {
		const validFirstName = formValues.firstName.length > 0;
		const validLastName = formValues.lastName.length > 0;
		const validEmail = /^[\w.%+-]+@[a-z\d.-]+\.[a-z]{2,4}$/i.test(
			formValues.email
		);

		if (validFirstName && validLastName && validEmail) {
			setFormComplete(true);
		}
	}, [formValues]);

	return (
		<Form autoComplete="off" name="saasAgreement">
			<Form.Item
				label="First Name"
				name="firstName"
				rules={[
					{
						required: true,
						message: 'Please enter your first name!',
					},
				]}
			>
				<Input
					id="firstName"
					placeholder="enter your first name"
					value={firstName}
					onChange={(e) => handleChange(e, 'firstName')}
				/>
			</Form.Item>
			<Form.Item
				label="Last Name"
				name="lastName"
				rules={[
					{
						required: true,
						message: 'Please enter your last name!',
					},
				]}
			>
				<Input
					id="lastName"
					placeholder="enter your last name"
					value={lastName}
					onChange={(e) => handleChange(e, 'lastName')}
				/>
			</Form.Item>
			<Form.Item
				label="Email"
				name="email"
				rules={[
					{
						required: true,
						message: 'Please enter your email!',
					},
				]}
			>
				<Input
					id="email"
					placeholder="enter your email"
					value={email}
					onChange={(e) => handleChange(e, 'email')}
				/>
			</Form.Item>
			<Form.Item className={buttonContainerStyle}>
				<Button
					className={buttonStyle}
					disabled={!formComplete}
					shape="round"
					size="large"
					type="primary"
					onClick={navToScreen}
				>
					Accept & Continue
				</Button>
			</Form.Item>
		</Form>
	);
}

export default SaasAgreementScreen;
