import gql from 'graphql-tag';

export const UpdateUserAside = gql`
	mutation UpdateUser($input: UpdateUserInput!) {
		updateUser(input: $input) {
			admin
			companyId
			id
			role
		}
	}
`;
