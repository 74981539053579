import { connect } from 'react-redux';
import { withCreateReferral } from 'src/_shared/api/components/referrals/withCreateReferralProvider.js';
import { compose } from '../../services/utils';
import ImInterestedButtonWrapper from './ImInterestedSendComponent.jsx';

const mapStateToProperties = (state) => {
	const { currentUser } = state.user;
	return {
		currentUser,
		filter: { userId: { eq: currentUser.id } },
		limit: 1000,
	};
};

const ImInterestedButtonWithApi = compose(withCreateReferral)(
	ImInterestedButtonWrapper
);

export const ImInterestedButton = connect(mapStateToProperties)(
	ImInterestedButtonWithApi
);
