import gql from 'graphql-tag';
import dayjs from 'dayjs';
import { compose, graphql } from 'react-apollo';
import uuid from 'uuid/v4';
import { createContact, listContacts } from '../../graphql/custom/contacts';
import {
	ListReferralsDashboard,
	createReferral,
} from '../../graphql/custom/referrals';

export const withCreateReferralMyReferrals = (Component) => {
	return compose(
		graphql(gql(listContacts), {
			options: (props) => ({
				context: {
					headers: {
						'x-frame-options': 'deny', // This header will reach the server
					},
				},
				variables: {
					filter: {
						userId: { eq: props.currentUser.id },
					},
					limit: 1000,
					nextToken: null,
				},
				fetchPolicy: 'cache-and-network',
			}),
			props: (props) => ({
				contacts: props.data.listContacts
					? props.data.listContacts.items
					: undefined,
			}),
		}),
		graphql(gql(createContact), {
			props: (props) => ({
				ImportedCreateContact(input) {
					const optimisticResponseData = {
						id: uuid(),
						...input.input,
						referrals: null,
						__typename: 'Contact',
					};
					return props.mutate({
						variables: input,
						optimisticResponse: {
							__typename: 'Mutation',
							createContact: {
								__typename: 'createContact',
								...optimisticResponseData,
							},
						},
						update(proxy, { data: { createContact } }) {
							const data = proxy.readQuery({
								query: gql(listContacts),
								variables: {
									filter: {
										userId: { eq: props.ownProps.currentUser.id },
									},
									limit: 1000,
									nextToken: null,
								},
							});

							if (
								!data.listContacts.items.find(
									(contact) => contact.id === createContact.id
								)
							) {
								data.listContacts.items.push(createContact);
							}

							proxy.writeQuery({
								query: gql(listContacts),
								variables: {
									filter: {
										userId: { eq: props.ownProps.currentUser.id },
									},
									limit: 1000,
									nextToken: null,
								},
								data,
							});
						},
					});
				},
			}),
		}),
		graphql(gql(createReferral), {
			props: (props) => ({
				onCreateReferral(input) {
					const optimisticResponseData = {
						...input.input,
						id: uuid(),
						referralDate: dayjs(),
					};
					props.mutate({
						variables: input,
						optimisticResponse: {
							__typeName: 'Mutation',
							createReferral: {
								__typeName: 'createReferral',
								...optimisticResponseData,
							},
						},
						update(proxy, { data: { createReferral } }) {
							const data = proxy.readQuery({
								query: ListReferralsDashboard,
								variables: {
									filter: {
										userId: {
											eq: props.ownProps.currentUser.id,
										},
									},
									limit: 1000,
									nextToken: null,
								},
							});

							if (
								!data.listReferrals.items.find(
									(referral) => referral.id === createReferral.id
								)
							) {
								data.listReferrals.items.push(createReferral);
							}

							proxy.writeQuery({
								query: ListReferralsDashboard,
								variables: {
									filter: {
										userId: {
											eq: props.ownProps.currentUser.id,
										},
									},
									limit: 1000,
									nextToken: null,
								},
								data,
							});
						},
					});
				},
			}),
		})
	)(Component);
};
