import { css } from 'emotion';
import { COLORS } from '../../_shared/styles/colors';

export const ModalStyles = css({
	'& .ant-modal-content': {
		borderRadius: 12,
	},
	fontFamily: 'Open Sans',
	'& .ant-modal-close-x': {
		color: COLORS.lightGray,
	},
});

// Styles add-contacts-email.component
// removes default antd space below inputs, centers directions, and
export const AddContactsEmailClassname = css({
	'& .ant-form-item': {
		marginBottom: 0,
	},

	'& .directions': {
		textAlign: 'center',
		color: 'gray',
	},
});

export const ModalTitle = css({
	color: COLORS.darkGray,
	width: '100%',
	textAlign: 'center',
	fontSize: 28,
	marginBottom: 20,
	fontWeight: 600,
});

export const SubTitle = css({
	maxWidth: '540px',
	margin: 'auto',
	marginBottom: '20px',
	fontSize: '13px',
	textAlign: 'center',
	color: COLORS.lightGray,
});

export const paragraphStyles = css({
	margin: 0,
	fontSize: '13px',
	color: COLORS.lightGray,
});

export const alignCenter = css({
	textAlign: 'center',
});

export const roundSocial = css({
	marginLeft: 'auto',
	marginRight: 'auto',
	marginTop: 2,
	marginBottom: 10,
});

export const roundSocialRow = css({
	textAlign: 'center',
	display: 'flex',
	justifyContent: 'space-around',
});

export const socialTypes = css({
	display: 'flex',
	flexDirection: 'column',
});

export const roundSocialText = css({
	marginLeft: '20',
	marginRight: '25',
	color: COLORS.lightGray,
	fontSize: 16,
});

export const downloadBtnRow = css({
	textAlign: 'center',
	marginTop: 25,
	marginBottom: 25,
});

export const downloadText = css({
	color: COLORS.blue,
	fontSize: 16,
});

export const BackLink = css({
	fontFamily: '"Open Sans", sans-serif',
	color: COLORS.blue,
	alignItems: 'center',
	display: 'inline-flex',
	fontWeight: '600 !important',
});

export const ImportButton = css({
	textAlign: 'center',
	backgroundColor: COLORS.red,
	color: 'white',
});

export const darkGrayText = css({
	textAlign: 'center',
	color: COLORS.darkGray,
	fontSize: 20,
});

export const smallerDarkGrayText = css({
	textAlign: 'center',
	color: COLORS.darkGray,
	fontSize: 16,
});

export const darkGrayIcon = css({
	textAlign: 'center',
	color: COLORS.darkGray,
	fontSize: 32,
	marginBottom: '10px',
});

export const darkGrayTextHeavy = css({
	textAlign: 'center',
	color: COLORS.darkGray,
	fontSize: 20,
	fontWeight: 500,
});

export const uploadContainer = css({
	width: '280px',
	margin: 'auto',
});

export const contactContainer = css({
	border: '1px solid #e8e8e8',
	borderRadius: '4px',
	marginTop: 20,
	marginBottom: 20,
});

export const ImportContainer = css({
	marginTop: 10,
	textAlign: 'center',
});
