import { Button, Col, Form, Input, Row } from 'antd';
import _ from 'lodash';
import get from 'lodash/get';
import { forwardRef, useMemo } from 'react';
import Autocomplete from 'react-autocomplete';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';
import { Link } from 'react-router-dom';
import { ToggleEmailPhone } from '../../../referral-modal/referral-modal-form/ToggleEmailPhoneComponent.jsx';
import { filterPhoneNumber, ml } from '../../../services/utils';

// Wrapping react-autocomplete to address initialValue error because antD has hits own state and conflicts with onSelect
const WrappedAutocomplete = forwardRef(
	({ inputProps, menuHeaderText, ...props }, ref) => (
		<Autocomplete
			ref={ref}
			wrapperStyle={{
				position: 'relative',
				width: '100%',
			}}
			inputProps={{
				...inputProps,
				className: 'ant-input custom-input',
			}}
			renderMenu={(items, value, style) => {
				return (
					<div className="custom-input-dropdown">
						<div className="custom-input-dropdown-text">{menuHeaderText}</div>
						{items}
					</div>
				);
			}}
			{...props}
		/>
	)
);

function OnDeckReferralFormAddContactFields(props) {
	const {
		selectedContact,
		handleClearSelected,
		handleSelectContact,
		contacts,
		currentUser,
		allMultiLingualData,
		referralSubheaderText = 'Enter Referral Information',
		referralType,
		changeReferralType,
	} = props;
	const { getFieldValue } = props.form;
	const enablePhone = props?.enablePhone ?? false;
	const hideReferralSubheader = props?.hideReferralSubheader ?? false;

	// Get the values of form for useMemo
	const firstNameValue = getFieldValue('firstName') || '';

	// For each field, useMemo filters all contacts first, so it's not taking place each keypress in autocomplete
	const firstNameContacts = useMemo(
		() =>
			contacts.filter(
				(contact) =>
					contact.firstName &&
					contact.lastName &&
					contact.emailAddress &&
					contact.firstName.toLowerCase().includes(firstNameValue.toLowerCase())
			),
		[firstNameValue, contacts]
	);

	function TextWithRedAsterisk({ text }) {
		const hasAsterisk = text.endsWith('*');

		if (hasAsterisk) {
			const mainText = text.slice(0, -1);
			return (
				<h4 className="ant-modal-sub-title text-center">
					{ml(mainText, currentUser, allMultiLingualData)}
					<span style={{ color: 'red' }}>*</span>
				</h4>
			);
		}

		return (
			<h4 className="ant-modal-sub-title text-center">
				{ml(referralSubheaderText, currentUser, allMultiLingualData)}
			</h4>
		);
	}

	// If you selected a contact, change the modal, unless they were already selected
	if (selectedContact) {
		return (
			<>
				<h4 className="ant-modal-sub-title text-center">Refer This Contact</h4>
				<Row gutter={12}>
					<Col xs={24}>
						<div className="custom-form-group d-flex align-items-center">
							<Form.Item className="w-100">
								<Input
									readOnly
									className="custom-input"
									value={`${get(selectedContact, 'firstName')} ${get(
										selectedContact,
										'lastName'
									)} ${get(selectedContact, 'emailAddress')}`}
								/>
							</Form.Item>
							{!get(props, 'contact') && (
								<i
									className="icon-bin text-danger ml-2 cursor-p"
									onClick={() => {
										handleClearSelected();
									}}
								/>
							)}
						</div>
					</Col>
				</Row>
			</>
		);
	}

	return (
		<>
			{!hideReferralSubheader && (
				<TextWithRedAsterisk text={referralSubheaderText} />
			)}
			<Row gutter={12}>
				<Col xs={24} md={12}>
					<div className="custom-form-group">
						<Form.Item
							name="firstName"
							rules={[
								{
									required: true,
									message: ml('Required', currentUser, allMultiLingualData),
								},
							]}
						>
							<WrappedAutocomplete
								shouldItemRender={(contact) => {
									const contactIndex = firstNameContacts.indexOf(contact);
									return contactIndex > -1 && contactIndex < 1000;
								}}
								items={_.sortBy(contacts, [(contact) => contact.firstName])}
								getItemValue={(contact) => contact.firstName}
								inputProps={{
									placeholder: ml(
										'First Name',
										currentUser,
										allMultiLingualData
									),
								}}
								menuHeaderText="Refer an existing contact:"
								renderItem={(contact, isHighlighted, styles) => (
									<div
										key={contacts.indexOf(contact)}
										className={`custom-input-dropdown-item ${
											isHighlighted ? 'hovered' : ''
										}`}
									>
										{`${contact.firstName} ${contact.lastName} `}
										{contact.emailAddress && `(${contact.emailAddress})`} &nbsp;
										{contact.phoneNumber &&
											`(${contact.phoneNumber.replaceAll(/[^\d+]/g, '')})`}
									</div>
								)}
								onSelect={(value, contact) => handleSelectContact(contact)}
							/>
						</Form.Item>
					</div>
				</Col>

				<Col xs={24} md={12}>
					<div className="custom-form-group">
						<Form.Item
							name="lastName"
							rules={[
								{
									required: true,
									message: ml('Required', currentUser, allMultiLingualData),
								},
							]}
						>
							<Input
								className="custom-input"
								placeholder={ml('Last Name', currentUser, allMultiLingualData)}
							/>
						</Form.Item>
					</div>
				</Col>
			</Row>
			<Row gutter={12}>
				<Col xs={24}>
					<ToggleEmailPhone
						allMultiLingualData={allMultiLingualData}
						referralType={referralType}
						currentUser={currentUser}
						onClick={(referralType) => {
							changeReferralType(referralType);
						}}
					/>
				</Col>
				<Col xs={24}>
					{(referralType === 'email' || referralType === null) && (
						<div className="custom-form-group">
							<Form.Item
								name="emailAddress"
								rules={[
									{
										required: true,
										message: ml('Required', currentUser, allMultiLingualData),
									},
									{
										pattern:
											/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[(?:\d{1,3}\.){3}\d{1,3}])|(([a-zA-Z\-\d]+\.)+[a-zA-Z]{2,}))$/g,
										message: ml(
											'Invalid Email',
											currentUser,
											allMultiLingualData
										),
									},
									{
										// If email input matches an existing contact, require user to select that contact
										validator(rule, value, callback) {
											if (
												contacts.some(
													(contact) =>
														(contact &&
															contact.emailAddress &&
															contact.emailAddress.toLowerCase()) === value ||
														''.toLowerCase()
												)
											) {
												const contact = contacts.find(
													(contact) =>
														(contact &&
															contact.emailAddress &&
															contact.emailAddress.toLowerCase()) === value ||
														''.toLowerCase()
												);

												return callback(
													<>
														{ml(
															'A contact with this phone number already exists',
															currentUser,
															allMultiLingualData
														)}
														,&nbsp;
														<Button
															type="link"
															style={{
																margin: 0,
															}}
															onClick={() => handleSelectContact(contact)}
														>
															{ml(
																'click here',
																currentUser,
																allMultiLingualData
															)}
														</Button>
														&nbsp;{' '}
														{ml(
															'to refer or',
															currentUser,
															allMultiLingualData
														)}{' '}
														&nbsp;
														<Link to={`/mycontacts/${contact.id}`}>
															{ml('view', currentUser, allMultiLingualData)}
														</Link>
														&nbsp;{' '}
														{ml(
															'the contact',
															currentUser,
															allMultiLingualData
														)}
													</>
												);
											}

											callback();
										},
									},
								]}
							>
								<Input
									className="custom-input"
									placeholder={ml(
										'Email Address',
										currentUser,
										allMultiLingualData
									)}
								/>
							</Form.Item>
						</div>
					)}
					{(!referralType === 'email' ||
						!referralType === null ||
						enablePhone) && (
						<div className="custom-form-group">
							<Form.Item
								name="phoneNumber"
								getValueFromEvent={(value) => value.replaceAll(/[^\d+]/g, '')}
								rules={[
									{
										required: true,
										message: ml('Required', currentUser, allMultiLingualData),
									},
									{
										validator(rule, value, callback) {
											const regex = /\d{10,}/;
											if (value.slice(0, 2) === '55') {
												return callback(
													ml(
														'SMS referrals to Brazil are not supported at this time. Please use email or social media to refer your contact.',
														currentUser,
														allMultiLingualData
													)
												);
											}

											if (!regex.test(value)) {
												return callback(
													ml('Invalid Number', currentUser, allMultiLingualData)
												);
											}

											return callback();
										},
									},
									{
										validator(rule, value, callback) {
											// Normalize phone number, and check to see if this contact already exists
											if (value) {
												const contact = contacts.find(
													(contact) =>
														filterPhoneNumber(contact.phoneNumber) === value
												);
												if (contact) {
													return callback(
														<>
															{
																'A contact with this phone number already exists, '
															}
															<Button
																type="link"
																style={{
																	color: '#018dd3',
																	fontSize: '12px',
																	lineHeight: '14px',
																	fontWeight: '600',
																	padding: 0,
																	margin: 0,
																}}
																onClick={() => handleSelectContact(contact)}
															>
																click here
															</Button>
															{' to refer or '}
															<Link
																style={{
																	color: '#018dd3',
																	fontWeight: '600',
																	padding: 0,
																	margin: 0,
																}}
																to={`/mycontacts/${contact.id}`}
															>
																view
															</Link>
															{' the contact.'}
														</>
													);
												}
											}

											callback();
										},
									},
								]}
							>
								<PhoneInput
									// Styles on parent element with css selectors
									dropdownStyle={{
										lineHeight: 1,
									}}
									inputProps={{
										className: 'ant-input custom-input',
									}}
									inputStyle={{
										paddingLeft: '50px',
									}}
									country="us"
									masks={{ cn: '... .... ....' }}
								/>
							</Form.Item>
						</div>
					)}
				</Col>
			</Row>
		</>
	);
}

export default OnDeckReferralFormAddContactFields;
