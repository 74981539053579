import { css } from 'emotion';
import get from 'lodash/get';
import { COLORS } from 'src/_shared/styles/colors';

const breakpoints = [576, 767, 991, 1200];

const mq = breakpoints.map((bp) => `@media (max-width: ${bp}px)`);

export const mainContainer = css({
	width: '100%',
	padding: 20,
	[mq[2]]: {
		padding: '20px 5px',
	},
});

export const middleContainer = css({
	paddingTop: 30,
});

export const filterLeft = css({
	display: 'flex',
	flexWrap: 'wrap',
	width: '100%',
});

export const filterRow = css({
	display: 'flex',
	flexWrap: 'wrap',
	width: '100%',
	alignItems: 'center',
	marginTop: 10,
	[mq[1]]: {
		marginTop: 0,
	},
});

export const middleContainerJobCard = css({
	'& .ant-col-xl-6': {
		'@media (max-width: 1580px) and (min-width: 1200px)': {
			width: '33.33%',
		},
	},
});

export const DropDown = css({
	fontFamily: '"Open Sans", sans-serif',
});

export const filterLeftWrap = css({
	flex: '1 1 auto',
});

export const SelectContainer = css({
	float: 'right',
	position: 'relative',
	[mq[1]]: {
		margin: '0 0 10px !important',
		width: '100%',
	},
});

export const iconContainerDark = css({
	top: '-1px',
	right: 8,
	position: 'absolute',
	zIndex: 1,
});

export const rightSelect = css({
	marginLeft: 15,
	marginRight: 15,
	[mq[1]]: {
		marginRight: 0,
	},
});

export const iconContainer = css({
	width: 0,
	pointerEvents: 'none',
	zIndex: 1,
});

export const SelectStyles = css({
	fontFamily: '"Open Sans", sans-serif',
	marginLeft: 15,
	width: 240,
	background: COLORS.inputActiveBackground,
	color: COLORS.inputActiveColor,
	'& .ant-select-selection__choice': {
		backgroundColor: COLORS.lightGreen,
		color: COLORS.green,
	},
	[mq[1]]: {
		width: '100%',
		marginLeft: 0,
	},
});

export const SelectStylesNew = css({
	fontFamily: '"Open Sans", sans-serif',
	marginLeft: 15,
	width: '240px !important',
	'& .ant-select-selection': {
		background: COLORS.inputDisableBackground,
		color: COLORS.inputDisableColor,
		borderRadius: 32,
		padding: '0 10px',
		borderColor: COLORS.inputDisableBackground,
		boxShadow: 'none',
	},
	'& .ant-select-selection__placeholder': {
		color: COLORS.inputDisableColor,
	},
	'& .ant-select-search__field__placeholder': {
		color: COLORS.inputDisableColor,
	},
	'& .ant-select-selection__choice': {
		backgroundColor: COLORS.lightGreen,
		color: COLORS.green,
	},
	'& .ant-select-focused .ant-select-selection': {
		borderColor: COLORS.inputDisableBackground,
		boxShadow: '0 0 0 2px rgba(143, 143, 143, 0.2)',
	},
	'& .ant-select-selection:hover': {
		borderColor: COLORS.inputActiveBackground,
		boxShadow: '0 0 0 2px rgba(143, 143, 143, 0.2)',
	},
	'& .ant-select-selection:focus': {
		borderColor: COLORS.inputActiveBackground,
		boxShadow: '0 0 0 2px rgba(143, 143, 143, 0.2)',
	},
	'& .ant-select-selection:active': {
		borderColor: COLORS.inputActiveBackground,
		boxShadow: '0 0 0 2px rgba(143, 143, 143, 0.2)',
	},
	[mq[1]]: {
		width: '100%',
		marginLeft: 0,
	},
});

export const SelectStylesNewActive = (length = 1) =>
	css({
		fontFamily: '"Open Sans", sans-serif',
		marginLeft: 15,
		width: length ? '280px !important' : '240px !important',
		maxWidth: 480,
		'& .ant-select-selection': {
			background: COLORS.inputActiveBackground,
			color: COLORS.inputActiveColor,
			borderRadius: 32,
			padding: '0 10px',
			borderColor: COLORS.inputActiveBackground,
			boxShadow: 'none',
		},
		'& .ant-select-selection__placeholder': {
			color: COLORS.inputActiveColor,
		},
		'& .ant-select-search__field__placeholder': {
			color: COLORS.inputActiveColor,
		},
		'& .ant-select-selection__choice': {
			backgroundColor: 'rgba(143, 143, 143, 0.2)',
			color: COLORS.inputActiveColor,
		},
		'& .ant-select-focused .ant-select-selection': {
			borderColor: COLORS.inputActiveColor,
			boxShadow: '0 0 0 2px rgba(143, 143, 143, 0.2)',
		},
		'& .ant-select-selection__choice__remove': {
			color: COLORS.inputActiveColor,
		},
		'& .ant-select-selection:hover': {
			borderColor: COLORS.inputActiveBackground,
			boxShadow: '0 0 0 2px rgba(143, 143, 143, 0.2)',
		},
		'& .ant-select-selection:focus': {
			borderColor: COLORS.inputActiveBackground,
			boxShadow: '0 0 0 2px rgba(143, 143, 143, 0.2)',
		},
		'& .ant-select-selection:active': {
			borderColor: COLORS.inputActiveBackground,
			boxShadow: '0 0 0 2px rgba(143, 143, 143, 0.2)',
		},
		[mq[1]]: {
			width: '100%',
			marginLeft: 0,
			maxWidth: 'none',
		},
	});

export const SelectStylesDark = css({
	fontFamily: '"Open Sans", sans-serif',
	marginLeft: 15,
	width: 240,
	'& .ant-select-selection': {
		background: COLORS.inputActiveBackground,
		color: COLORS.inputActiveColor,
		borderRadius: 32,
		padding: '0 10px',
	},
	'& .ant-select-selection__placeholder': {
		color: COLORS.inputActiveColor,
	},
	'& .ant-select-search__field__placeholder': {
		color: COLORS.inputActiveColor,
	},
	'& .ant-select-selection__choice': {
		backgroundColor: COLORS.lightGreen,
		color: COLORS.green,
	},
	'& .ant-select-focused .ant-select-selection': {
		borderColor: COLORS.inputActiveBackground,
		boxShadow: '0 0 0 2px rgba(143, 143, 143, 0.2)',
	},
	'& .ant-select-selection:hover': {
		borderColor: COLORS.inputActiveBackground,
		boxShadow: '0 0 0 2px rgba(143, 143, 143, 0.2)',
	},
	'& .ant-select-selection:focus': {
		borderColor: COLORS.inputActiveBackground,
		boxShadow: '0 0 0 2px rgba(143, 143, 143, 0.2)',
	},
	'& .ant-select-selection:active': {
		borderColor: COLORS.inputActiveBackground,
		boxShadow: '0 0 0 2px rgba(143, 143, 143, 0.2)',
	},
	'&.ant-select.ant-select-disabled': {
		'& .ant-select-selection': {
			background: COLORS.inputDisableBackground,
			color: COLORS.inputDisableColor,
		},
		'& .ant-select-selection__placeholder': {
			color: COLORS.inputDisableColor,
		},
	},
	[mq[1]]: {
		width: '100%',
		marginLeft: 0,
	},
});

export const paginationStyles = css({
	position: 'fixed',
	left: '50%',
	bottom: 57, // This is because the eldest-parent container has height of 100%
	transform: 'translate(-50%, -50%)',
	margin: '0, auto',
});

export const cardListStyle = css({
	'& .ant-pagination': {
		textAlign: 'center',
		margin: 'auto',
	},
	marginTop: 15,
	'& .ant-list-empty-text': {
		visibility: 'hidden',
	},
});
export const JobHead = css({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	paddingRight: '90px',
});
export const jobTitle = css({
	fontWeight: 'bold',
	fontSize: '16px',
});

export const hotCardListStyle = css({
	'& .ant-pagination': {
		marginBottm: 'auto',
	},
	marginTop: 15,
	'& .ant-list-empty-text': {
		visibility: 'hidden',
	},
	'& .ant-list-pagination': {
		marginTop: 0,
		position: 'absolute',
		top: -42,
		right: 0,
	},
	'& .ant-pagination-item': {
		display: 'none!important',
	},
});

export const departmentFilterContainer = css({
	width: 'auto',
	display: 'flex',
	[mq[1]]: {
		marginTop: '0',
	},
});

export const topContainer = css({
	marginBottom: 10,
	[mq[1]]: {
		flexDirection: 'column',
	},
});

export const gridTitle = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 600,
	color: COLORS.black,
	fontSize: 18,
});

export const addJobsButtonText = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 600,
	color: COLORS.black,
	fontSize: '1.2em',
});

export const searchbarStyle = css({
	fontFamily: '"Open Sans", sans-serif',
	'& input': {
		fontFamily: '"Open Sans", sans-serif',
		borderRadius: 20,
		width: 248,
		marginTop: -7,
		height: 32,
	},
	'& .ant-input:not(:first-child)': {
		paddingLeft: 35,
	},
});

export const addJobsButtonIcon = css({
	fontSize: 35,
});

export const modalTitleText = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 500,
	color: COLORS.heading,
	fontSize: '1.5em',
});

export const modalContainer = css({
	width: 10,
});

export const loadingImage = css({
	display: 'block',
	margin: 'auto',
});

export const dropdownMenuItem = css({
	marginTop: 16,
	width: 200,
	height: 30,
});

export const dropdownButton = (width) =>
	css({
		marginTop: 17,
		height: 30,
		'&:hover': {
			backgroundColor: COLORS.red,
			color: COLORS.white,
			borderColor: 'transparent',
		},
		'&:focus': {
			backgroundColor: COLORS.white,
			color: COLORS.red,
			borderColor: COLORS.red,
		},
		width,
	});

export const SearchIconStyles = css({
	marginRight: 10,
	marginTop: -3,
});

export const spinContainer = css({
	width: '100%',
	height: '50vh',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
});

export const noJobs = css({
	width: '100%',
	textAlign: 'center',
	marginTop: 40,
});

export const noJobsText = css({
	width: '100%',
	textAlign: 'center',
});

export const searchWrap = css({
	width: '33%',
	[mq[1]]: {
		width: '100%',
		margin: '0 0 10px',
	},
});

export const AddLocationBtn = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 200,
	color: '#999',
	background: 'transparent',
	fontSize: 14,
	height: 35,
	border: 0,
	lineHeight: '25px',
	boxShadow: 'none',
	'> span': {
		color: '#999!important',
	},
	'&:hover > span': {
		color: '#999!important',
	},
	[mq[1]]: {
		margin: '0 0 10px !important',
		width: '100%',
	},
});

export const btnText = css({
	paddingBottom: 3,
	color: '#999 !important',
});

export const successStyles = css({
	width: 250,
	position: 'absolute',
	top: 25,
	left: 750,
});

export const setlocationCircle = css({
	height: 15,
	width: 15,
	backgroundColor: '#bbb',
	borderRadius: '50%',
	display: 'inline-block',
});
export const locationCircle = css({
	height: 15,
	width: 15,
	backgroundColor: '#54b759',
	borderRadius: '50%',
	display: 'inline-block',
});

export const SelectStylesLocation = css({
	width: 205,
	'& .ant-select-arrow': {
		display: 'none',
	},
	[mq[1]]: {
		width: '100%',
	},
});

export const ModalTitle = (theme) => {
	let buttonColor = COLORS.red;
	if (get(theme, 'enabled')) {
		buttonColor = get(theme, 'buttonColor', buttonColor);
	}

	return css({
		width: '100%',
		textAlign: 'center',
		color: buttonColor,
		fontSize: 25,
		marginBottom: 0,
		fontWeight: 600,
	});
};

export const ModalStyles = css({
	'& .ant-modal-content': {
		borderRadius: 20,
	},
	fontFamily: 'Open Sans',
	'& .ant-modal-close-x': {
		fontSize: 30,
		paddingTop: 10,
		color: COLORS.lightGray,
	},
});

export const TooltipStyles = (width, isLocationPopoverVisible, TooltipData) => {
	return css({
		width,
		'& .ant-tooltip-inner': {
			backgroundColor: 'white',
		},

		'& .ant-popover-content': {
			display: `${isLocationPopoverVisible && TooltipData ? 'block' : 'none'}`,
		},
	});
};

export const plusButton = css({
	fontSize: 17,
	color: COLORS.gray2,
	'& button:hover > i > svg': {
		fill: COLORS.blue,
	},
	'& button:focus > i > svg': {
		fill: COLORS.blue,
	},
});

export const referButton = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 200,
	color: '#404447',
	background: COLORS.white,
	fontSize: 14,
	height: 30,
	lineHeight: '25px',
	width: '100% !important',
	border: '1.5px solid #8d99a3',
	borderRadius: '5px',
	marginLeft: 2,
	'&:hover': {
		backgroundColor: COLORS.white,
		color: '#404447',
		borderColor: 'transparent',
	},
	'&:focus': {
		backgroundColor: COLORS.white,
		color: '#404447',
	},
	[mq[0]]: {
		marginLeft: 0,
	},
});

export const HeaderTheme = css({
	color: COLORS.black,
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 600,
	fontSize: 18,
	'@media (max-width: 1199px) and (min-width: 992px)': {
		width: '150px',
	},
});

export const BlueButton = css({
	color: COLORS.blue,
	fontFamily: '"Open Sans", sans-serif',
});

export const LinkStyles = css({
	fontFamily: '"Open Sans", sans-serif',
	color: COLORS.blue,
	fontWeight: '600 !important',
	display: 'flex',
	alignItems: 'center',
	padding: 0,
});

export const absoluteBtn = css({
	[mq[1]]: {
		position: 'absolute',
		top: 30,
		right: 20,
	},
});

export const collapseMenu = css({
	fontFamily: 'Open Sans,sans-serif',
	'& .ant-collapse-item': {
		borderBottom: 0,
		'& .ant-collapse-header': {
			paddingLeft: 0,

			'& .ant-collapse-arrow': {
				color: COLORS.blue,
				paddingLeft: 70,
			},
		},
		'& .ant-collapse-content-box': {
			paddingTop: 0,
			padding: 0,
		},
	},
});

export const LabelStyles = css({
	fontFamily: 'Open Sans,sans-serif',
	fontWeight: 600,
	color: COLORS.black,
	display: 'inline-Block',
	marginTop: '6px',
});

export const SelectStylesGroup = css({
	width: '100%',
	'& .ant-select-selection__choice': {
		backgroundColor: COLORS.lightGreen,
		color: COLORS.green,
	},
	[mq[1]]: {
		width: '100%',
	},
});

export const disableDiv = css({
	pointerEvents: 'none',
	opacity: '0.7',
});

export const changeDirection = css({
	[mq[1]]: {
		flexDirection: 'column',
	},
});
