function arrayMove(array, from, to) {
	const newArray = [...array];
	newArray.splice(
		to < 0 ? newArray.length + to : to,
		0,
		newArray.splice(from, 1)[0]
	);

	return newArray;
}

export const rectSortingStrategy = ({
	rects,
	activeIndex,
	overIndex,
	index,
}) => {
	const newRects = arrayMove(rects, overIndex, activeIndex);

	const oldRect = rects[index];
	const newRect = newRects[index];

	if (!newRect || !oldRect) {
		return null;
	}

	return {
		x: newRect.left - oldRect.left,
		y: newRect.top - oldRect.top,
		scaleX: newRect.width / oldRect.width,
		scaleY: newRect.height / oldRect.height,
	};
};
