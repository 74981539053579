function GradientLabel(props) {
	const { gradientText } = props;

	return (
		<div className="gradient-text-container">
			<div className="gradient-text">{gradientText}</div>
		</div>
	);
}

export default GradientLabel;
