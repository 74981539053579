import { get } from 'lodash';

export function BonusReportRecipientItem(props) {
	const {
		bonusReportSetting,
		company,
		onUpdateCompany,
		handleRecipientError,
		style,
		iconStyle = {},
	} = props;

	const inactivateRecipient = (recipient) => {
		if (bonusReportSetting && bonusReportSetting.emailAddresses) {
			let newRecipients = bonusReportSetting.emailAddresses.split(',');

			const recipientIndex = newRecipients.indexOf(recipient);
			if (recipientIndex > -1) {
				newRecipients.splice(recipientIndex, 1);
			}

			newRecipients = newRecipients.join(',');
			if (newRecipients === '') {
				newRecipients = ' ';
			}

			bonusReportSetting.emailAddresses = newRecipients;

			onUpdateCompany({
				input: {
					id: company.id,
					bonusReportSetting: JSON.stringify(bonusReportSetting),
				},
			});
		}
	};

	return (
		<ul className="tag-wrap">
			{bonusReportSetting && bonusReportSetting.emailAddresses
				? bonusReportSetting.emailAddresses
						.split(',')
						.map((recipient, index) => {
							let marginLeft = get(style, 'marginLeft') ? style.marginLeft : 4;
							if (index === 0) marginLeft = 0;
							return (
								<li
									key={index}
									className="tag green"
									onClick={() => inactivateRecipient(recipient)}
								>
									<span className="tag-name">{recipient}</span>
									<i className="tag-close icon-cross" />
								</li>
							);
						})
				: null}
		</ul>
	);
}

export const monthlyFrequncy = [
	{ key: '01', value: 1 },
	{ key: '02', value: 2 },
	{ key: '03', value: 3 },
	{ key: '04', value: 4 },
	{ key: '05', value: 5 },
	{ key: '06', value: 6 },
	{ key: '07', value: 7 },
	{ key: '08', value: 8 },
	{ key: '09', value: 9 },
	{ key: '10', value: 10 },
	{ key: '11', value: 11 },
	{ key: '12', value: 12 },
	{ key: '13', value: 13 },
	{ key: '14', value: 14 },
	{ key: '15', value: 15 },
	{ key: '17', value: 17 },
	{ key: '16', value: 16 },
	{ key: '18', value: 18 },
	{ key: '19', value: 19 },
	{ key: '20', value: 20 },
	{ key: '21', value: 21 },
	{ key: '22', value: 22 },
	{ key: '23', value: 23 },
	{ key: '24', value: 24 },
	{ key: '25', value: 25 },
	{ key: '26', value: 26 },
	{ key: '27', value: 27 },
	{ key: '28', value: 28 },
];

export const weeklyFrequency = [
	{ key: 'Monday', value: 1 },
	{ key: 'Tuesday', value: 2 },
	{ key: 'Wednesday', value: 3 },
	{ key: 'Thursday', value: 4 },
	{ key: 'Friday', value: 5 },
	{ key: 'Saturday', value: 6 },
	{ key: 'Sunday', value: 7 },
];
