export const measurements = [
	{
		id: 'imperial',
		name: 'Imperial System',
	},
	{
		id: 'metric',
		name: 'Metric System',
	},
];
