import { InfoCircleOutlined } from '@ant-design/icons';
import { Button, List } from 'antd';
import getSymbolFromCurrency from 'currency-symbol-map';
import gql from 'graphql-tag';
import Cookies from 'js-cookie';
import _, { get } from 'lodash';
import { useEffect, useState } from 'react';
import { withApollo } from 'react-apollo';
import ReactGA from 'react-ga';
import { getUserById } from 'src/_shared/api/graphql/custom/users/';
import Spinner from 'src/_shared/components/spinner/SpinnerComponent.jsx';
import {
	getSetErrorImageURL,
	logout,
	ml,
	searchData,
} from 'src/_shared/services/utils.js';
import { COLORS } from 'src/_shared/styles/colors.js';
import fileIcon from '../_shared/assets/erin_lightgray.png';
import { SearchComponent } from '../_shared/components/search';
import { ColorCard } from '../_shared/index.js';
import ContactsTable from './ContactsTableComponent.jsx';
import InviteContactModal from './add-contacts/AddContactsModalComponent.jsx';
import { ReferralNotification } from './referral-notifications';

function MyContactsComponent(props) {
	const {
		allMultiLingualData,
		client,
		companyData,
		contacts = [],
		currentUser,
		ImportedCreateContact,
		googleAuthClientID,
		loadMore,
		notifications,
		onDeleteContact,
		onDeleteJobMatch,
		onUpdate,
		onUpdateNotification,
		onCreateReferral,
		onDeckContacts,
		onUpdateContact,
		user,
	} = props;

	if (!get(user, 'company')) return <Spinner />;

	const [refNotifications, setReferralNotifications] = useState(notifications);
	const [isInviteContactModalOpen, setIsInviteContactModalOpen] =
		useState(false);
	const [searchQuery, setSearchQuery] = useState('');
	const [summaryTotals, setSummaryTotals] = useState({
		active: 0,
		invited: 0,
		ready: 0,
	});
	const [isCardDataSet, setIsCardDataSet] = useState(false);

	const [errorImageSource, setErrorImageSource] = useState('');

	useEffect(() => {
		const fetchErrorImageSource = async () => {
			const errorImageURL = await getSetErrorImageURL(
				user?.company?.errorImage?.key
			);

			if (errorImageURL) {
				setErrorImageSource(errorImageURL);
			}
		};

		fetchErrorImageSource();
	}, [user?.company]);

	const handleInviteContact = () => setIsInviteContactModalOpen(true);
	const getMLText = (text) => ml(text, user, allMultiLingualData);

	/**
	 * NOTE: temporarily accessing enableExtendedNetwork value via currentUser.company
	 * [with-get-companyData.provider] no longer returning companyData
	 */

	const extendedNetworkEnabled = user && user.company.enableExtendedNetwork;
	const propsInputSearch = {
		searchQuery,
		setSearchQuery,
		placeholder: getMLText('Search'),
	};
	const propsTableContacts = {
		allMultiLingualData,
		companyData,
		contacts,
		user,
		loadMore,
		onDeleteContact,
		onDeleteJobMatch,
		searchQuery,
	};
	const inviteMessage =
		'Inviting your contacts will allow them to create a profile to view open jobs, request that you refer them to positions, and track their own referral status.';

	// TODO: Invite Contact Handler

	// pre-existing componentDidMount code that deals with token
	useEffect(() => {
		(async () => {
			const userData = await client
				.query({
					query: gql(getUserById),
					variables: {
						id: get(user, 'id', null),
					},
					fetchPolicy: 'network-only',
				})
				.then((response) => response.data.getUser);

			const { hostname, search, pathname } = window.location;
			const jwt = Cookies.get('jwt');

			if (pathname === '/mycontacts/add/form') {
				setIsInviteContactModalOpen(true);
			}

			if (hostname === 'referrals.aus.com') {
				ReactGA.initialize('UA-128630809-2');
				ReactGA.pageview(pathname + search);
			} else if (hostname === 'app.erinapp.com') {
				ReactGA.initialize('UA-128630809-3');
				ReactGA.pageview(pathname + search);
			}

			if (
				userData !== '' &&
				userData !== undefined &&
				jwt !== undefined &&
				jwt !== get(userData, 'expirationDoneByToken', null) &&
				get(userData, 'expires')
			) {
				logout(this.props.client);
			}
		})();
	}, []);

	useEffect(() => {
		(async () => {
			setReferralNotifications(notifications);
		})();
	}, [notifications]);

	// Pre-existing code that gets invite, active, Smart Referral summaries
	useEffect(() => {
		(() => {
			const totals = {
				invited: 0,
				active: 0,
				ready: 0,
			};

			const getReferralStatus = (fullContactStatus) => {
				try {
					switch (true) {
						case fullContactStatus === null: {
							return 'pending';
						}

						case fullContactStatus.details !== undefined: {
							return 'ready';
						}

						case fullContactStatus.message === 'Profile not found': {
							return 'unavailable';
						}

						default: {
							return 'unavailable';
						}
					}
				} catch (error) {
					console.error(error);
				}
			};

			for (const contact of contacts) {
				const fullContactData = JSON.parse(
					get(contact, 'fullContactData', '{}')
				);
				const referralStatus = getReferralStatus(fullContactData);
				if (get(contact, 'extendedUserId')) {
					totals.active++;
				}

				if (get(contact, 'inviteStatus') || referralStatus !== 'unavailable') {
					totals.invited++;
				}

				if (
					get(contact, 'extendedUser.inviteStatus') === 'complete' ||
					referralStatus === 'ready'
				) {
					totals.ready++;
				}
			}

			setSummaryTotals((previousTotals) => {
				return {
					...previousTotals,
					totals,
				};
			});
			setIsCardDataSet(true);
		})();
	}, [contacts]);

	return (
		<main>
			{extendedNetworkEnabled && (
				<div className="contact-card-wrap">
					<SummarySection stats={summaryTotals} isCardDataSet={isCardDataSet} />
					<ReferralRequestsSection
						currentUser={user}
						notifications={refNotifications}
						ImportedCreateContact={ImportedCreateContact}
						setReferralNotifications={setReferralNotifications}
						onUpdateNotification={onUpdateNotification}
						onCreateReferral={onCreateReferral}
						onDeckContacts={onDeckContacts}
						onUpdateContact={onUpdateContact}
					/>
				</div>
			)}
			{extendedNetworkEnabled && contacts ? (
				<div className="page-title">
					<div className="search-input">
						<SearchInput {...propsInputSearch} />
					</div>
					<div className="d-flex align-items-center">
						<InviteContactButton
							label={getMLText('Invite to Network')}
							onClick={handleInviteContact}
						/>
						<IconToolTip color={COLORS.blue} message={inviteMessage} />
					</div>
				</div>
			) : null}
			{contacts ? (
				<ReferralNetworkTable {...propsTableContacts} />
			) : (
				<NoContactsMessage
					isWhitelabel={get(user, 'company.whiteLabel')}
					errorImageSrc={errorImageSource}
				/>
			)}
			{extendedNetworkEnabled ? (
				<InviteContactModal
					allMultiLingualData={allMultiLingualData}
					companyData={user.company}
					currentUser={user}
					googleAuthClientID={googleAuthClientID}
					handleCancel={() => setIsInviteContactModalOpen(false)}
					updateUserIncentiveEligibility={(eligible) =>
						onUpdate({
							input: {
								id: get(user, 'id'),
								incentiveEligible: eligible,
							},
						})
					}
					visible={isInviteContactModalOpen}
					onCreateContact={ImportedCreateContact}
				/>
			) : null}
		</main>
	);
}

/**
 * SUB-COMPONENTS
 */
function IconToolTip({ color = '', message = '' }) {
	return (
		<InfoCircleOutlined className="ml-2" style={{ color }} title={message} />
	);
}

function InviteContactButton({ label, onClick }) {
	return (
		<Button type="link" className="add-btn" onClick={onClick}>
			<span className="icon-circle">
				<i className="icon-plus" />
			</span>
			{label}
		</Button>
	);
}

function SearchInput(props) {
	const { searchQuery, setSearchQuery, placeholder } = props;

	return (
		<SearchComponent
			placeholder={placeholder}
			searchQuery={searchQuery}
			setQueryToState={(value) => setSearchQuery(value)}
		/>
	);
}

function NoContactsMessage({ isWhitelabel, errorImageSrc }) {
	return (
		<div className="no-content">
			{isWhitelabel ? (
				<img
					src={errorImageSrc}
					alt="error image"
					className="no-content-icon"
				/>
			) : (
				<img alt="erin-logo" className="no-content-icon" src={fileIcon} />
			)}

			<p className="no-content-icon">
				You do not have any contacts. Contacts that are referred will show here.
			</p>
		</div>
	);
}

function ReferralRequestsSection({
	currentUser,
	notifications,
	onUpdateNotification,
	onCreateReferral,
	onDeckContacts,
	onUpdateContact,
	ImportedCreateContact,
	setReferralNotifications,
}) {
	return (
		<div className="contact-card">
			<div className="contact-card-title">Referral Requests</div>
			<div className="contact-card-inner">
				<div className="referral-request">
					{notifications === undefined ? (
						<Spinner />
					) : (
						<List
							dataSource={
								// Arrange by most recent
								_.orderBy(notifications, ['dateCreated'], ['desc'])
									// Show notifications that aren't completed
									.filter(
										(notification) =>
											notification.type === 'referralRequested' &&
											(notification.referralRequestedStatus === 'requested' ||
												notification.referralRequestedStatus === null)
									)
							}
							itemLayout="horizontal"
							pagination={{
								pageSize: 3,
								hideOnSinglePage: true,
								showSizeChanger: false,
							}}
							locale={{
								emptyText: (
									<div className="empty-referrals">
										<i className="icon-user-two" />
										<p>
											You do not have any referral requests. When your network
											contacts request to be referred to a position, you will
											see the requests here.
										</p>
									</div>
								),
							}}
							split={false}
							renderItem={(notification) => (
								<List.Item>
									<ReferralNotification
										key={get(notification, 'id')}
										notification={notification}
										currentUser={currentUser}
										currencySymbol={getSymbolFromCurrency(
											get(currentUser, 'userGroup.currency', 'USD')
										)}
										removeReferralRequest={(notificationId) => {
											const remainingNotifications = notifications.filter(
												(notification) => notification.id !== notificationId
											);
											setReferralNotifications(remainingNotifications);
										}}
										onUpdateNotification={onUpdateNotification}
										onCreateReferral={onCreateReferral}
										onDeckContacts={onDeckContacts}
										onCreateContact={ImportedCreateContact}
										onUpdateContact={onUpdateContact}
									/>
								</List.Item>
							)}
						/>
					)}
				</div>
			</div>
		</div>
	);
}

function SummarySection({ stats, isCardDataSet }) {
	function SummaryStatBox({ color = '', statName, label }) {
		return (
			<ColorCard
				isOneSided
				bgColor={`var(${color})`}
				count={get(stats.totals, statName, 0)}
				isCardDataSet={isCardDataSet}
				title={label ? label : statName}
			/>
		);
	}

	return (
		<div className="contact-card">
			<div className="contact-card-title">Summary</div>
			<div className="contact-card-inner">
				<div className="color-card-grid">
					<SummaryStatBox color="--bright-turquoise" statName="Invited" />
					<SummaryStatBox color="--tan-hide" statName="Active" />
					<SummaryStatBox
						color="--forest-green"
						statName="ready"
						label="Smart Referral Ready"
					/>
				</div>
			</div>
		</div>
	);
}

function ReferralNetworkTable(props) {
	const {
		allMultiLingualData,
		companyData,
		contacts,
		user,
		loadMore,
		onDeleteContact,
		onDeleteJobMatch,
		searchQuery,
	} = props;

	return (
		<div className="table-card">
			<ContactsTable
				allMultiLingualData={allMultiLingualData}
				companyData={companyData}
				contacts={searchData(
					[
						'contact.firstName',
						'contact.lastName',
						'contact.emailAddress',
						'contact.phoneNumber',
					],
					searchQuery,
					contacts
				)}
				currentUser={user}
				loadMore={loadMore}
				onDeleteContact={onDeleteContact}
				onDeleteJobMatch={onDeleteJobMatch}
			/>
		</div>
	);
}

export default withApollo(MyContactsComponent);
