export const createReferralQuestions = `mutation CreateReferralQuestions($input: CreateReferralQuestionInput!) {
    createReferralQuestions(input: $input) {
      id
      companyId
      questions
      active
      isCandidate
      sortOrder
    }
  }
  `;
