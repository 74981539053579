import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export function ShareWrapper() {
	const history = useHistory();
	const location = useLocation();

	const searchParams = new URLSearchParams(location.search);
	const referredBy = searchParams.get('referredBy');
	const jobId = searchParams.get('jobId');
	const languageCode = searchParams.get('languageCode');

	useEffect(() => {
		if (referredBy && jobId && languageCode) {
			history.push(`/newreferral/${referredBy}/${jobId}/${languageCode}`);
		} else {
			history.push(`/`);
		}
	}, [referredBy, jobId, languageCode, history]);

	return null;
}
