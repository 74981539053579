import { createContext, useContext } from 'react';
import { useRnRAPIService } from '../../rewardsAndRecognitionHelper/service/RnRAPI.service.jsx';
import { COMMON_API_ENDPOINT } from '../constant.js';

const RnRCommonServiceContext = createContext(null);

export const RnRCommonServiceProvider = (props) => {
  const APIService = useRnRAPIService();

  const getBadgesList = (request) => {
    return APIService.get(COMMON_API_ENDPOINT.GET_BADGES_LIST, request);
  }

  const getEmployeeRewardsList = (request) => {
    return APIService.get(COMMON_API_ENDPOINT.GET_EMPLOYEE_REWARDS, request);
  }

  const getAdminIssuedBadge = (id) => {
    return APIService.get(`${COMMON_API_ENDPOINT.GET_ADMIN_ISSUED_BADGE}${id}`);
  }

  const getNomineeListById = (id) => {
    return APIService.get(`${COMMON_API_ENDPOINT.GET_NOMINEE_LIST_BY_ID}${id}`);
  }

  const getNomineeVoterListById = (nominationConfigurationId, nomineeId) => {
    return APIService.get(`${COMMON_API_ENDPOINT.GET_NOMINEE_VOTER_LIST_BY_ID}${nominationConfigurationId}/${nomineeId}`)
  }

  const getNominationConfigurationById = (id) => {
    return APIService.get(`${COMMON_API_ENDPOINT.GET_NOMINATION_CONFIGURATION_BY_ID}${id}`);
  }

  const selectWinner = (requestBody) => {
    return APIService.put(COMMON_API_ENDPOINT.SELECT_WINNER, requestBody)
  }

  const exportNominationCSV = (nominationID) => {
    return APIService.get(`${COMMON_API_ENDPOINT.EXPORT_NOMINATION_CSV}/${nominationID}`)
  }

  const RnRCommonServiceOperations = {
    getBadgesList,
    getEmployeeRewardsList,
    getAdminIssuedBadge,
    getNomineeListById,
    getNomineeVoterListById,
    getNominationConfigurationById,
    selectWinner,
    exportNominationCSV
  };

  return (
    <RnRCommonServiceContext.Provider
      value={RnRCommonServiceOperations}
    >
      {props.children}
    </RnRCommonServiceContext.Provider>
  );
};

export const useRnRCommonService = () => {
  return useContext(RnRCommonServiceContext);
};