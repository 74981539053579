import gql from 'graphql-tag';

export const queryPointsLogByCompanyIdIndex = gql`
	query QueryPointsLogByCompanyIdIndex(
		$companyId: ID!
		$first: Int
		$after: String
	) {
		queryPointsLogByCompanyIdIndex(
			companyId: $companyId
			first: $first
			after: $after
		) {
			items {
				id
				companyId
				date
				event
				note
				operation
				points
				userId
				user {
					firstName
					lastName
					emailAddress
				}
				contactId
				contact {
					firstName
					lastName
					emailAddress
					phoneNumber
				}
				adminId
				adminEmail
				weeklyLastLogin
			}
			nextToken
		}
	}
`;
