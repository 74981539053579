export function PageItem(props) {
	const { title, subtitle, children, optional } = props;
	return (
		<>
			<label className="custom-label">
				{title}
				{optional && <span className="sub-label">(optional)</span>}
				{subtitle && <span className="sub-label">{subtitle}</span>}
			</label>
			{children}
		</>
	);
}
