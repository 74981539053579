/**
 * <Preview />
 */

import update from 'immutability-helper';
import { Component, createRef } from 'react';
import FormElementsEdit from './FormElementsEdit.jsx';
import SortableFormElements from './SortableFormElements.jsx';
import store from './stores/store.js';

const { PlaceHolder } = SortableFormElements;

export default class Preview extends Component {
	constructor(props) {
		super(props);

		const { onLoad, onPost } = props;
		store.setExternalHandler(onLoad, onPost);

		this.editForm = createRef();
		this.state = {
			data: [],
			answer_data: {},
		};
		this.seq = 0;

		const onUpdate = this._onChange.bind(this);
		store.subscribe((state) => onUpdate(state.data));
	}

	componentDidMount() {
		const { data, url, saveUrl } = this.props;
		store.dispatch('load', { loadUrl: url, saveUrl, data: data || [] });
		document.addEventListener('mousedown', this.editModeOff);
	}

	componentDidUpdate(prevProps) {
		if (this.props.data !== prevProps.data) {
			store.dispatch('updateOrder', this.props.data);
		}
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.editModeOff);
	}

	getElement(item, index) {
		const SortableFormElement = SortableFormElements[item.element];
		return (
			<SortableFormElement
				key={item.id}
				isDraggable
				id={item.id}
				seq={this.seq}
				index={index}
				moveCard={this.moveCard}
				insertCard={this.insertCard}
				mutable={false}
				parent={this.props.parent}
				editModeOn={this.props.editModeOn}
				sortData={item.id}
				data={item}
				_onDestroy={this._onDestroy}
			/>
		);
	}

	_onChange(data) {
		const answer_data = {};

		for (const item of data) {
			if (item && item.readOnly && this.props.variables[item.variableKey]) {
				answer_data[item.field_name] = this.props.variables[item.variableKey];
			}
		}

		this.setState({
			data,
			answer_data,
		});
	}

	_onDestroy(item) {
		store.dispatch('delete', item);
	}

	_setValue(text) {
		return text.replaceAll(/[^a-z\d]+/gi, '_').toLowerCase();
	}

	// eslint-disable-next-line no-unused-vars
	cardPlaceHolder(dragIndex, hoverIndex) {
		// Dummy
	}

	editModeOff = (e) => {
		if (this.editForm.current && !this.editForm.current.contains(e.target)) {
			this.manualEditModeOff();
		}
	};

	insertCard = (item, hoverIndex) => {
		const { data } = this.state;
		data.splice(hoverIndex, 0, item);
		this.saveData(item, hoverIndex, hoverIndex);
	};

	manualEditModeOff = () => {
		const { editElement } = this.props;
		if (editElement && editElement.dirty) {
			editElement.dirty = false;
			this.updateElement(editElement);
		}

		this.props.manualEditModeOff();
	};

	moveCard = (dragIndex, hoverIndex) => {
		const { data } = this.state;
		const dragCard = data[dragIndex];
		this.saveData(dragCard, dragIndex, hoverIndex);
	};

	saveData(dragCard, dragIndex, hoverIndex) {
		const newData = update(this.state, {
			data: {
				$splice: [
					[dragIndex, 1],
					[hoverIndex, 0, dragCard],
				],
			},
		});
		this.setState(newData);
		store.dispatch('updateOrder', newData.data);
	}

	updateElement(element) {
		const { data } = this.state;
		let found = false;

		for (let i = 0, length_ = data.length; i < length_; i++) {
			if (element.id === data[i].id) {
				data[i] = element;
				found = true;
				break;
			}
		}

		if (found) {
			this.seq = this.seq > 100_000 ? 0 : this.seq + 1;
			store.dispatch('updateOrder', data);
		}
	}

	render() {
		const data = this.state.data.filter(Boolean);
		const items = data.map((item, index) => this.getElement(item, index));
		return (
			<div
				className={`fb-preview ${
					this.props.editMode ? 'fb-edit-form-open' : ''
				}`}
			>
				<div ref={this.editForm} className="fb-edit-form">
					<div className="fb-form-inner">
						{this.props.editElement !== null && (
							<FormElementsEdit
								showCorrectColumn={this.props.showCorrectColumn}
								files={this.props.files}
								manualEditModeOff={this.manualEditModeOff}
								preview={this}
								element={this.props.editElement}
								updateElement={this.updateElement}
							/>
						)}
					</div>
				</div>
				<div className="fb-sortable">{items}</div>
				<PlaceHolder
					id="form-place-holder"
					show={items.length === 0}
					index={items.length}
					moveCard={this.cardPlaceHolder}
					insertCard={this.insertCard}
				/>
			</div>
		);
	}
}
Preview.defaultProps = {
	showCorrectColumn: false,
	files: [],
	editMode: false,
	editElement: null,
	className: 'react-form-builder-preview float-left',
};
