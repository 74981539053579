import React from 'react';
import ApprovalBonusListContainer from "./container/ApprovalBonusContainer.jsx"
import { ApprovalBonusServiceProvider } from './service/ApprovalBonus.service.jsx';
import { useSelector } from "react-redux";
import { Spinner } from "../_shared/index.js";

export const ApprovalsBonus = () => {
	const company = useSelector((state) => state.user.currentUser.company)
	if (!company) return <Spinner />
	return (
		<main>
			<ApprovalBonusServiceProvider>
				<ApprovalBonusListContainer />
			</ApprovalBonusServiceProvider>
		</main>
	);
};

export default ApprovalsBonus;
