import gql from 'graphql-tag';

export const QueryUsersByCompanyIdPointsIndex = gql`
	query QueryUsersByCompanyIdPointsIndex($companyId: ID!) {
		queryUsersByCompanyIdPointsIndex(companyId: $companyId) {
			items {
				id
				firstName
				lastName
				role
				userGroupId
				points
				pointsRanking
				dateCreated
				subCompanyId
			}
		}
	}
`;
