import { Button, Input, InputNumber, Select } from 'antd';
import { Component } from 'react';
import PlacesAutocomplete from 'src/_shared/components/location/PlacesAutocomplete.jsx';
import { ml } from 'src/_shared/services/utils.js';
import { AddJobsFooter } from '../footer/AddJobFooterComponent.jsx';
import { USStates } from './copy.js';

export class AddJobPageOne extends Component {
	constructor(props) {
		super(props);
		this.state = {
			address: '',
			salary: {
				from: '',
				to: '',
				interval: 'yearly',
			},
			location: {
				city: props?.currentJob?.location?.city || '',
				state: props?.currentJob?.location?.state || '',
				country: props?.currentJob?.location?.country || '',
				isRemote: props?.currentJob?.location?.isRemote || false,
			},
			loc: {
				city: props?.currentJob?.location?.city || '',
				state: props?.currentJob?.location?.state || '',
				country: props?.currentJob?.location?.country || '',
			},
			jobType: '',
			otherJobType: '',
		};
	}

	componentDidMount() {
		const { salary, jobType, location } = this.props.currentJob;
		const { allMultiLingualData, currentUser, editing } = this.props;
		const { loc } = this.state;

		const SalaryTypes = {
			yearly: ml('per year', currentUser, allMultiLingualData),
			monthly: ml('per month', currentUser, allMultiLingualData),
			weekly: ml('per week', currentUser, allMultiLingualData),
			daily: ml('per day', currentUser, allMultiLingualData),
			hourly: ml('per hour', currentUser, allMultiLingualData),
		};

		this.setState({
			salary: {
				...salary,
				interval: salary.interval ? salary.interval : SalaryTypes.yearly,
			},
			jobType,
		});

		const isRemote = location?.isRemote;
		if (!isRemote && editing) {
			const address = [loc?.city, loc?.state, loc?.country]
				.filter(Boolean)
				.join(', ');
			this.setState({
				address,
			});
		}
	}

	onChangeSalaryMaximum = (value) => {
		const reg = /^[1-9]\d*$/;
		if ((!isNaN(value) && reg.test(value)) || value === '' || value === '-') {
			this.setState({
				salary: {
					from: this.state.salary.from,
					to: value,
					interval: this.state.salary.interval,
				},
			});
		}
	};

	onChangeSalaryMinimum = (value) => {
		const reg = /^[1-9]\d*$/;
		if ((!isNaN(value) && reg.test(value)) || value === '' || value === '-') {
			this.setState({
				salary: {
					to: this.state.salary.to,
					from: value,
					interval: this.state.salary.interval,
				},
			});
		}
	};

	footerButtons = (page) => {
		const { allMultiLingualData, currentUser } = this.props;
		const { theme } = this.state;
		return (
			<AddJobsFooter
				allMultiLingualData={allMultiLingualData}
				currentUser={currentUser}
				page={page}
				theme={theme}
				handleNext={() => {
					this.props.handleNext(page, {
						...this.props.currentJob,
						...this.state,
					});
				}}
				handleBack={() => {
					this.props.handleBack(page);
				}}
			/>
		);
	};

	renderJobtype = () => {
		const { jobType } = this.state;
		const { allMultiLingualData, currentUser, errors } = this.props;
		const JobTypes = {
			fulltime: ml('Full-time', currentUser, allMultiLingualData),
			parttime: ml('Part-time', currentUser, allMultiLingualData),
			contract: ml('Contract', currentUser, allMultiLingualData),
			internship: ml('Internship', currentUser, allMultiLingualData),
			commission: ml('Commission', currentUser, allMultiLingualData),
			others: ml('Others', currentUser, allMultiLingualData),
		};

		const handleOtherJobType = (e) => {
			this.setState({
				otherJobType: e.target.value,
			});
		};

		return (
			<>
				<div className="custom-form-group">
					<label className="custom-label">
						{ml('Job Type', currentUser, allMultiLingualData)}
					</label>
					<div className="custom-btn-group">
						{Object.keys(JobTypes).map((key) => {
							return (
								<Button
									key={key}
									className={
										(key === jobType && 'active') + ' btn-pink btn-pink-sm'
									}
									onClick={() => {
										this.setState({
											jobType: key,
										});
									}}
								>
									{JobTypes[key]}
								</Button>
							);
						})}
					</div>
					{errors && errors.jobType ? (
						<div className="input-error">{errors.jobType}</div>
					) : null}
				</div>
				{this.state.jobType == 'others' && (
					<div className="custom-form-group">
						<label className="custom-label">
							Please specify other job type:{' '}
						</label>
						<Input
							placeholder="Job Type"
							className="custom-input"
							onChange={handleOtherJobType}
						/>
					</div>
				)}
			</>
		);
	};

	handleChangeLocation(address) {
		this.setState({ address });
	}

	handleSelectLocation(location) {
		this.setState({ address: location?.address, location });
	}

	renderLocation = () => {
		const { allMultiLingualData, currentUser, errors } = this.props;
		const city = this.state?.location?.city;
		const state = this.state?.location?.state;
		const isRemote = this.state?.location?.isRemote;
		let cityValue = city;
		let stateValue = state;

		if (city == null && state && isRemote == false) {
			const loca = state.split(',');
			cityValue = loca[0];
			loca.splice(0, 1);
			stateValue = loca.join(',');
		}

		const { Option } = Select;
		const options = [];
		Object.keys(USStates).map((key) =>
			options.push(<Option key={key}>{USStates[key]}</Option>)
		);
		return (
			<div className="custom-form-group">
				<label className="custom-label">
					{ml('Location', currentUser, allMultiLingualData)}
				</label>
				<div className="c-form-row">
					<div className="c-form-col">
						<PlacesAutocomplete
							address={this.state.address}
							setAddress={this.handleChangeLocation.bind(this)}
							style={{ width: 350 }}
							onSelect={this.handleSelectLocation.bind(this)}
						/>
					</div>
					<div className="c-form-col">
						{ml('or', currentUser, allMultiLingualData)}
					</div>
					<div className="c-form-col">
						<Button
							className={(isRemote && 'active') + ' btn-pink'}
							onClick={() => {
								this.setState({
									location: {
										...this.state.location,
										isRemote: !this.state.location.isRemote,
									},
								});
							}}
						>
							{ml('This Position is Remote', currentUser, allMultiLingualData)}
						</Button>
					</div>
				</div>
				{errors && errors.location ? (
					<div className="input-error">{errors.location}</div>
				) : null}
			</div>
		);
	};

	renderSalaryRange = () => {
		const { salary } = this.state;
		const { allMultiLingualData, currentUser, errors } = this.props;
		const SalaryTypes = {
			yearly: ml('per year', currentUser, allMultiLingualData),
			monthly: ml('per month', currentUser, allMultiLingualData),
			weekly: ml('per week', currentUser, allMultiLingualData),
			daily: ml('per day', currentUser, allMultiLingualData),
			hourly: ml('per hour', currentUser, allMultiLingualData),
		};
		return (
			<div className="custom-form-group">
				<label className="custom-label">
					{ml('Salary Range', currentUser, allMultiLingualData)}
				</label>
				<div className="c-form-row">
					<div className="amount-number c-form-col">
						<span>$</span>
						<InputNumber
							className="custom-input"
							max={999_999_999}
							min={0}
							formatter={(value) =>
								`${value}`.replaceAll(/\B(?=(\d{3})+(?!\d))/g, ',')
							}
							parser={(value) => value.replaceAll(/$\s?|(,*)/g, '')}
							value={salary.from}
							placeholder="ex. $55,000"
							onChange={this.onChangeSalaryMinimum.bind(this)}
						/>
					</div>
					<div className="c-form-col">
						<span className="divider-text-row">To</span>
					</div>
					<div className="amount-number c-form-col">
						<span>$</span>
						<InputNumber
							className="custom-input"
							max={999_999_999}
							min={0}
							placeholder="ex. $65,000"
							value={salary.to}
							formatter={(value) =>
								`${value}`.replaceAll(/\B(?=(\d{3})+(?!\d))/g, ',')
							}
							parser={(value) => value.replaceAll(/$\s?|(,*)/g, '')}
							onChange={this.onChangeSalaryMaximum.bind(this)}
						/>
					</div>
					<div className="c-form-col">
						<Select
							className="custom-input"
							value={
								salary && salary.interval ? salary.interval : SalaryTypes.yearly
							}
							onSelect={(value) =>
								this.setState({
									salary: {
										to: this.state.salary.to,
										from: this.state.salary.from,
										interval: value,
									},
								})
							}
						>
							{Object.keys(SalaryTypes).map((key) => {
								return (
									<Select.Option key={key}>{SalaryTypes[key]}</Select.Option>
								);
							})}
						</Select>
					</div>
				</div>
				{errors && errors.salary ? (
					<div className="input-error">{errors.salary}</div>
				) : null}
			</div>
		);
	};

	render() {
		const footer = this.footerButtons(this.props.page);
		return (
			<div>
				{this.renderJobtype()}
				{this.renderSalaryRange()}
				{this.renderLocation()}
				{footer}
			</div>
		);
	}
}
