import './cards-style.scss'
import { addCommas } from '../../../rewardsAndRecognitionHelper/utils.js';

const CustomCards = ({
    Icon,
    pointAmount,
    cardTitle,
    customCardsClassName,
    cardColor
}) => {
    return (
        <div style={{ backgroundColor: cardColor }} className={`${customCardsClassName} custom-cards`}>
            <div className='points-detail'>
                <div className='reward-point-img'>
                    <Icon alt='img' className="custom-card-icon" />
                </div>
                <div className='about-points'>
                    <div className='point-amount'>{pointAmount && addCommas(pointAmount)}</div>
                    <div className='point-info'>{cardTitle}</div>
                </div>
            </div>
        </div>
    )
}

export default CustomCards