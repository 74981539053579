import { Component } from 'react';
import JobCard from '../on-deck-job-modal/OnDeckJobCardContainer.jsx';
import ReferralModal from './ReferralModalComponent.jsx';

class ReferralModalWrapper extends Component {
	constructor(props) {
		super(props);
		this.state = {
			job: props.job,
			contacts: props.contacts,
			contact: props.contact,
			visible: false,
			referrals: props.referrals,
		};
	}

	componentDidUpdate(prevProps) {
		if (prevProps.contacts !== this.props.contacts) {
			this.setState({ contacts: this.props.contacts });
		}
	}

	handleCancel = () => {
		this.setState({ visible: false });
	};

	handleCancelAll = () => {
		this.props.handleCancel();
		this.setState({ visible: false });
	};

	showModal = () => {
		this.setState({ visible: true });
	};

	render() {
		const { visible, contact, referrals } = this.state;
		const { job, contacts, currentTieredBonus, data } = this.props;
		return (
			<>
				<JobCard
					key={job.id}
					allMultiLingualData={this.props.allMultiLingualData}
					currentUser={this.props.currentUser}
					client={this.props.client}
					job={job}
					contact={contact}
					data={data}
					currentTieredBonus={currentTieredBonus}
					referrals={referrals}
					onSelectJob={this.showModal}
					onUpdateContactDeckStatus={this.props.onUpdateContactDeckStatus}
					onUpdateContact={this.props.onUpdateContact}
					onUpdateNotification={this.props.onUpdateNotification}
				/>
				<ReferralModal
					allMultiLingualData={this.props.allMultiLingualData}
					client={this.props.client}
					currentUser={this.props.currentUser}
					currentTieredBonus={currentTieredBonus}
					data={this.props.data}
					job={job}
					contact={contact}
					contacts={contacts}
					visible={visible}
					handleCancel={this.handleCancel}
					handleCancelAll={this.handleCancelAll}
					matchFound={this.props.matchFound}
					notification={this.props.notification}
					referrals={referrals}
					onCreateContact={this.props.onCreateContact}
					onCreateReferral={this.props.onCreateReferral}
					onUpdateContactDeckStatus={this.props.onUpdateContactDeckStatus}
					onUpdateContact={this.props.onUpdateContact}
					onUpdateNotification={this.props.onUpdateNotification}
				/>
			</>
		);
	}
}

export default ReferralModalWrapper;
