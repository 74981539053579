export const messageCenterKeys = {
	SendAMessage: 'ml_SendAMessage',
	SelectEmployees: 'ml_SelectEmployees',
	WhoWill: 'ml_WhoWill',
	InGroup: 'ml_InGroup',
	AndInDept: 'ml_AndInDept',
	All: 'ml_All',
	None: 'ml_None',
	EmpSelected: 'ml_EmpSelected',
	Job: 'ml_Job',
	Optional: 'ml_Optional',
	DoYouWant: 'ml_DoYouWant',
	SelectJob: 'ml_MessageCenter_SelectJob',
	Message: 'ml_Message',
	TheMessage: 'ml_TheMessage',
	Subject: 'ml_Subject',
	SendMe: 'ml_SendMe',
	Email: 'ml_MessageCenter_Email',
	Employees: 'ml_Employees',
	SelectBrand: 'ml_MessageCenter_SelectBrand',
	ml_DoYouWantChangeBrand: 'ml_MessageCenter_DoYouWantChangeBrand',
};
