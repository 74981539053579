import { connect } from 'react-redux';
import JobModalWrapper from './OnDeckJobModalWrapper.jsx';

const mapStateToProperties = (state) => {
	const { currentUser } = state.user;
	return {
		currentUser,
		currentJob: state.manageJobs.currentJob,
		filter: { companyId: { eq: currentUser.companyId } },
		limit: 5000,
	};
};

const OnDeckJobModalWithApi = JobModalWrapper;

export const OnDeckJobModal = connect(mapStateToProperties)(
	OnDeckJobModalWithApi
);
