import gql from 'graphql-tag';

export const listContactsByUserIdIndex = gql`
	query listContactsByUserIdIndex(
		$filter: TableContactFilterInput
		$limit: Int
		$nextToken: String
	) {
		listContactsByUserIdIndex(
			filter: $filter
			limit: $limit
			nextToken: $nextToken
		) {
			items {
				id
				firstName
				lastName
				emailAddress
				phoneNumber
				referrals {
					id
					contactId
				}
				importMethod
				fullContactData
				dateCreated
				onDeckStatus
				onDeckNote
				onDeckDate
				contactResume {
					bucket
					region
					key
				}
			}
			nextToken
		}
	}
`;
