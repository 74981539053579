import gql from 'graphql-tag';

export const queryOnDeckContactsByCompanyIdIndex = gql`
	query QueryOnDeckContactsByCompanyIdIndex(
		$companyId: ID!
		$first: Int
		$after: String
	) {
		queryOnDeckContactsByCompanyIdIndex(
			companyId: $companyId
			first: $first
			after: $after
		) {
			items {
				id
				firstName
				lastName
				emailAddress
				userId
				companyId
				phoneNumber
				importMethod
				fullContactData
				dateCreated
				onDeckStatus
				onDeckNote
				onDeckDate
				contactResume {
					bucket
					region
					key
				}
			}
			nextToken
		}
	}
`;
