import { Alert, Button, Form, Input, Modal } from 'antd';
import { Component, createRef } from 'react';
import rightArrow from 'src/_shared/assets/right-arrow.svg';
import validator from 'validator';
import {
	AlertError,
	CheckIcon,
	InputStyles,
	LabelStyles,
	SubmitBtn as SubmitButton,
	SubmitBtnContainer as SubmitButtonContainer,
	modalContainerStyles,
	titleText,
} from '../fogotten-password-modal/forgottenPasswordModalStyles.js';

class ResetPasswordModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			cognitoError: false,
			errorMessage: '',
			matchError: false,
			lengthError: false,
			submitError: false,
			success: false,
		};
		this.formRef = createRef();
	}

	handleSubmit = (values) => {
		const { authUser, updateNewPassword } = this.props;
		if (values.password !== values.confirmPassword) {
			this.setState({ matchError: true });
			return;
		}

		if (values.password) {
			if (
				validator.isStrongPassword(values.password, {
					minLength: 10,
					minLowercase: 1,
					minUppercase: 1,
					minNumbers: 1,
					minSymbols: 1,
				})
			) {
				console.log('It is okay');
			} else {
				this.setState({ lengthError: true });
				return;
			}
		}

		updateNewPassword(authUser, values.confirmPassword);
	};

	onFinishFailed = (error) => {
		console.error(error);
		this.setState({ submitError: true });
		setTimeout(() => {
			this.formRef.current.resetFields();
			this.removeAlerts();
		}, 1000);
	};

	removeAlerts = () => {
		this.setState({
			cognitoError: false,
			errorMessage: '',
			matchError: false,
			lengthError: false,
			submitError: false,
			success: false,
		});
	};

	render() {
		const { visible, closeModal, theme } = this.props;
		const {
			cognitoError,
			errorMessage,
			submitError,
			lengthError,
			matchError,
			success,
		} = this.state;

		return (
			<Modal
				destroyOnClose
				open={visible}
				wrapClassName={modalContainerStyles}
				width={500}
				height={350}
				title={<span className={titleText}>Your Password Has Expired</span>}
				footer={null}
				onCancel={closeModal}
				onOk={null}
			>
				<Form
					ref={this.formRef}
					onFinish={this.handleSubmit}
					onFinishFailed={this.onFinishFailed}
				>
					<Form.Item
						className={InputStyles}
						name="password"
						rules={[
							{
								required: true,
								message: 'Please enter a new password',
							},
						]}
					>
						<div>
							<label className={LabelStyles}>New Password</label>
							<Input type="password" />
						</div>
					</Form.Item>
					<Form.Item
						className={InputStyles}
						name="confirmPassword"
						rules={[
							{
								required: true,
								message: 'Please confirm your new password',
							},
						]}
					>
						<div>
							<label className={LabelStyles}>Confirm Password</label>
							<Input type="password" />
						</div>
					</Form.Item>
					<Form.Item className={SubmitButtonContainer}>
						<Button
							className={SubmitButton(theme)}
							type="primary"
							htmlType="submit"
						>
							Submit
							<img
								className={CheckIcon}
								src={rightArrow}
								height={26}
								width={26}
								alt="right arrow"
							/>
						</Button>
					</Form.Item>
					{cognitoError && <Alert type="error" message={errorMessage} />}
					{matchError ? (
						<Alert
							className={AlertError}
							type="error"
							message="Passwords do not match. Please try again."
						/>
					) : null}
					{lengthError ? (
						<Alert
							className={AlertError}
							type="error"
							message="Your password must have minimum 10 characters, at least one upper case, one lower case letter, a number and a special character."
						/>
					) : null}
					{submitError ? (
						<Alert
							className={AlertError}
							message="We are having trouble submitting the form, please try again later."
							type="error"
						/>
					) : null}
					{success ? (
						<Alert
							className={AlertError}
							message="Password successfully changed!"
							type="success"
						/>
					) : null}
				</Form>
			</Modal>
		);
	}
}

export default ResetPasswordModal;
