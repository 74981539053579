/* eslint-disable react/no-unescaped-entities */
import { Col, List, Row } from 'antd';
import getSymbolFromCurrency from 'currency-symbol-map';
import gql from 'graphql-tag';
import Cookies from 'js-cookie';
import _ from 'lodash';
import get from 'lodash/get';
import { Component } from 'react';
import { withApollo } from 'react-apollo';
import ReactGA from 'react-ga';
import { getUserById } from 'src/_shared/api/graphql/custom/users/';
import Spinner from 'src/_shared/components/spinner/SpinnerComponent.jsx';
import { OnDeckReferralModal } from 'src/_shared/components/on-deck/on-deck-modal';
import {
	getSetErrorImageURL,
	logout,
	searchReferralData,
} from 'src/_shared/services/utils.js';
import Logo from '../_shared/assets/erin_lightgray.png';
import { SearchComponent } from '../_shared/components/search';
import { myReferralsKeys } from './myReferralsExtendedKeys.js';
import {
	ReferralsDashboardContainer,
	SectionStyles,
	ToolBar,
	noReferrals,
	noReferralsText,
} from './myReferralsExtendedStyles.js';
import ReferralCard from './referral-card/myReferralCardContainer.js';
import ReferralNotification from './referral-notifications/ReferralNotificationComponent.jsx';

class MyReferralsExtendedComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			company: get(props, 'currentUser.company'),
			filteredReferrals: [],
			notifications: null,
			searchQuery: '',
			onDeckReferralModal: false,
			height: 0,
			width: 0,
			loaded: false,
			currencyRate: 1,
			currencySymbol: '$',
			referralBonuses: get(props, 'referralBonuses'),
			ml_MyReferrals: '',
			ml_SubmitGeneralReferral: '',
			ml_FilterByStatus: '',
			ml_SearchByName: '',
			ml_Status: '',
			ml_Referred: '',
			ml_Accepted: '',
			ml_Hired: '',
			ml_ReferredOn: '',
			ml_Total: '',
			ml_Interviewing: '',
			ml_Earned: '',
			ml_NotHired: '',
			ml_YourEarnedTotal: '',
			ml_SelfReferred: '',
			ml_Declined: '',

			ml_ReferSomeone: '',
			ml_DoYouKnow: '',
			ml_SubmitThem: '',
			ml_EnterReferral: '',
			ml_EnterReferralInformation: '',
			ml_FirstName: '',
			ml_LastName: '',
			ml_Email: '',
			ml_Text: '',
			ml_Or: '',
			ml_ClickHere: '',
			ml_ToAddExistingContact: '',
			ml_IncludeMessageToContact: '',
			ml_Optional: '',
			ml_PersonalizeMessage: '',
			ml_MessageHiringContact: '',
			ml_HowKnowThem: '',
			ml_Clickhere: '',
			ml_AttachResume: '',
			ml_SubmitReferral: '',
			ml_BonusDetails: '',
			ml_Job: '',
			ml_ReferredCandidate: '',
			ml_TotalBonus: '',
			ml_HiredDate: '',
			ml_StartDate: '',
			ml_BonusPayments: '',
			ml_BonusNotes: '',
			ml_Close: '',
			ml_ViewBonusDetails: '',
			ml_NoResponse: '',
			ml_InActive: '',
		};
	}

	async componentDidMount() {
		const host = window.location.hostname;
		if (host === 'referrals.aus.com') {
			ReactGA.initialize('UA-128630809-2');
			ReactGA.pageview(window.location.pathname + window.location.search);
		} else if (host === 'app.erinapp.com') {
			ReactGA.initialize('UA-128630809-3');
			ReactGA.pageview(window.location.pathname + window.location.search);
		}

		await this.getUserDataById();
		const jwt = Cookies.get('jwt');
		const { resultData } = this.state;
		if (resultData !== '' && resultData !== undefined) {
			const expirationDoneByToken = get(
				resultData,
				'expirationDoneByToken',
				null
			);
			const expires = get(resultData, 'expires');
			if (jwt !== undefined && jwt !== expirationDoneByToken && expires) {
				logout(this.props.client);
			}
		}

		const errorImageURL = await getSetErrorImageURL(
			this.state?.company?.errorImage?.key
		);

		this.updateWindowDimensions();
		window.addEventListener('resize', this.updateWindowDimensions);
		this.setState({
			notifications: this.props.notifications,
			filteredReferrals: this.props.referrals,
			onDeckReferrals: this.getOnDeckReferrals(),
			errorImageSrc: errorImageURL,
		});
		await this.getCurrencyRate(
			this.props.currentUser && this.props.currentUser.currency
				? this.props.currentUser.currency
				: 'USD'
		);
		if (this.props.allMultiLingualData.length > 0) {
			this.props.setMultiLingualData(this.props.allMultiLingualData);
			const languageCode =
				this.props.currentUser.languageCode === null
					? 'US'
					: this.props.currentUser.languageCode;
			const filteredData = this.props.allMultiLingualData.filter(
				(item) => item.languageCode === languageCode
			);
			const myReferrals = filteredData.filter(
				(item) => item.key === myReferralsKeys.MyReferrals
			);
			const submitGeneralReferral = filteredData.filter(
				(item) => item.key === myReferralsKeys.SubmitGeneralReferral
			);
			const filterByStatus = filteredData.filter(
				(item) => item.key === myReferralsKeys.FilterByStatus
			);
			const searchByName = filteredData.filter(
				(item) => item.key === myReferralsKeys.SearchByName
			);
			const status = filteredData.filter(
				(item) => item.key === myReferralsKeys.Status
			);
			const referred = filteredData.filter(
				(item) => item.key === myReferralsKeys.Referred
			);
			const accepted = filteredData.filter(
				(item) => item.key === myReferralsKeys.Accepted
			);
			const hired = filteredData.filter(
				(item) => item.key === myReferralsKeys.Hired
			);
			const referredOn = filteredData.filter(
				(item) => item.key === myReferralsKeys.ReferredOn
			);
			const total = filteredData.filter(
				(item) => item.key === myReferralsKeys.Total
			);
			const interviewing = filteredData.filter(
				(item) => item.key === myReferralsKeys.Interviewing
			);
			const earned = filteredData.filter(
				(item) => item.key === myReferralsKeys.Earned
			);
			const notHired = filteredData.filter(
				(item) => item.key === myReferralsKeys.NotHired
			);
			const yourEarnedTotal = filteredData.filter(
				(item) => item.key === myReferralsKeys.YourEarnedTotal
			);
			const selfReferred = filteredData.filter(
				(item) => item.key === myReferralsKeys.SelfReferred
			);
			const declined = filteredData.filter(
				(item) => item.key === myReferralsKeys.Declined
			);
			const referSomeone = filteredData.filter(
				(item) => item.key === myReferralsKeys.ReferSomeone
			);
			const doYouKnow = filteredData.filter(
				(item) => item.key === myReferralsKeys.DoYouKnow
			);
			const submitThem = filteredData.filter(
				(item) => item.key === myReferralsKeys.SubmitThem
			);
			const enterReferral = filteredData.filter(
				(item) => item.key === myReferralsKeys.EnterReferral
			);
			const enterReferralInformation = filteredData.filter(
				(item) => item.key === myReferralsKeys.EnterReferralInformation
			);
			const firstName = filteredData.filter(
				(item) => item.key === myReferralsKeys.FirstName
			);
			const lastName = filteredData.filter(
				(item) => item.key === myReferralsKeys.LastName
			);
			const email = filteredData.filter(
				(item) => item.key === myReferralsKeys.Email
			);
			const textPlaceHolder = filteredData.filter(
				(item) => item.key === myReferralsKeys.Text
			);
			const or = filteredData.filter((item) => item.key === myReferralsKeys.Or);
			const clickHere = filteredData.filter(
				(item) => item.key === myReferralsKeys.ClickHere
			);
			const toAddExistingContact = filteredData.filter(
				(item) => item.key === myReferralsKeys.ToAddExistingContact
			);
			const includeMessageToContact = filteredData.filter(
				(item) => item.key === myReferralsKeys.IncludeMessageToContact
			);
			const optional = filteredData.filter(
				(item) => item.key === myReferralsKeys.Optional
			);
			const personalizeMessage = filteredData.filter(
				(item) => item.key === myReferralsKeys.PersonalizeMessage
			);
			const messageHiringContact = filteredData.filter(
				(item) => item.key === myReferralsKeys.MessageHiringContact
			);
			const howKnowThem = filteredData.filter(
				(item) => item.key === myReferralsKeys.HowKnowThem
			);
			const clickhereResume = filteredData.filter(
				(item) => item.key === myReferralsKeys.Clickhere
			);
			const attachResume = filteredData.filter(
				(item) => item.key === myReferralsKeys.AttachResume
			);
			const submitReferral = filteredData.filter(
				(item) => item.key === myReferralsKeys.SubmitReferral
			);
			const bonusDetail = filteredData.find(
				(item) => item.key === myReferralsKeys.BonusDetails
			);
			const job = filteredData.find((item) => item.key === myReferralsKeys.Job);
			const referredCandidate = filteredData.find(
				(item) => item.key === myReferralsKeys.ReferredCandidate
			);
			const totalBonus = filteredData.find(
				(item) => item.key === myReferralsKeys.TotalBonus
			);
			const hiredDate = filteredData.find(
				(item) => item.key === myReferralsKeys.HiredDate
			);
			const startDate = filteredData.find(
				(item) => item.key === myReferralsKeys.StartDate
			);
			const bonusPayment = filteredData.find(
				(item) => item.key === myReferralsKeys.BonusPayments
			);
			const bonusNote = filteredData.find(
				(item) => item.key === myReferralsKeys.BonusNotes
			);
			const close = filteredData.find(
				(item) => item.key === myReferralsKeys.Close
			);
			const viewBonusDetail = filteredData.find(
				(item) => item.key === myReferralsKeys.ViewBonusDetails
			);

			const inActive = filteredData.find(
				(item) => item.key === myReferralsKeys.InActive
			);

			const noResponse = filteredData.find(
				(item) => item.key === myReferralsKeys.NoResponse
			);

			this.setState({
				ml_MyReferrals:
					myReferrals.length > 0 ? myReferrals[0].text : 'My Referrals',
				ml_SubmitGeneralReferral:
					submitGeneralReferral.length > 0
						? submitGeneralReferral[0].text
						: 'Submit General Referral',
				ml_FilterByStatus:
					filterByStatus.length > 0 ? filterByStatus[0].text : 'Status',
				ml_SearchByName:
					searchByName.length > 0 ? searchByName[0].text : 'Search',
				ml_Status: status.length > 0 ? status[0].text : 'Status',
				ml_Referred: referred.length > 0 ? referred[0].text : 'Referred',
				ml_Accepted: accepted.length > 0 ? accepted[0].text : 'Accepted',
				ml_Hired: hired.length > 0 ? hired[0].text : 'Hired',
				ml_ReferredOn:
					referredOn.length > 0 ? referredOn[0].text : 'Referred on',
				ml_Total: total.length > 0 ? total[0].text : 'Total',
				ml_Interviewing:
					interviewing.length > 0 ? interviewing[0].text : 'Interviewing',
				ml_Earned: earned.length > 0 ? earned[0].text : 'Earned',
				ml_NotHired: notHired.length > 0 ? notHired[0].text : 'Not Hired',
				ml_YourEarnedTotal:
					yourEarnedTotal.length > 0
						? yourEarnedTotal[0].text
						: 'Your Earned Total reflects referral bonuses that are past the waiting period.',
				ml_SelfReferred:
					selfReferred.length > 0 ? selfReferred[0].text : 'Self Referred',
				ml_Declined: declined.length > 0 ? declined[0].text : 'Declined',
				ml_ReferSomeone:
					referSomeone.length > 0 ? referSomeone[0].text : 'Refer Someone',
				ml_DoYouKnow:
					doYouKnow.length > 0
						? doYouKnow[0].text
						: 'Do you know someone who would thrive at',
				ml_SubmitThem:
					submitThem.length > 0
						? submitThem[0].text
						: 'Submit them to be considered for new opportunities!',
				ml_EnterReferral:
					enterReferral.length > 0
						? enterReferral[0].text
						: "Enter a referral and we'll send them information to apply.",
				ml_EnterReferralInformation:
					enterReferralInformation.length > 0
						? enterReferralInformation[0].text
						: 'Enter Referral Information',
				ml_FirstName: firstName.length > 0 ? firstName[0].text : 'First Name',
				ml_LastName: lastName.length > 0 ? lastName[0].text : 'Last Name',
				ml_Email: email.length > 0 ? email[0].text : 'Email',
				ml_Text: textPlaceHolder.length > 0 ? textPlaceHolder[0].text : 'Text',
				ml_Or: or.length > 0 ? or[0].text : 'or',
				ml_ClickHere: clickHere.length > 0 ? clickHere[0].text : 'click here',
				ml_ToAddExistingContact:
					toAddExistingContact.length > 0
						? toAddExistingContact[0].text
						: 'to add an existing contact',
				ml_IncludeMessageToContact:
					includeMessageToContact.length > 0
						? includeMessageToContact[0].text
						: 'Include a message to your contact',
				ml_Optional: optional.length > 0 ? optional[0].text : 'optional',
				ml_PersonalizeMessage:
					personalizeMessage.length > 0
						? personalizeMessage[0].text
						: 'Personalize the message to your referral',
				ml_MessageHiringContact:
					messageHiringContact.length > 0
						? messageHiringContact[0].text
						: 'Message the Recruiter',
				ml_HowKnowThem:
					howKnowThem.length > 0
						? howKnowThem[0].text
						: 'How do you know them, why are they a good fit, etc.',
				ml_Clickhere:
					clickhereResume.length > 0 ? clickhereResume[0].text : 'Click here',
				ml_AttachResume:
					attachResume.length > 0 ? attachResume[0].text : 'to attach a resume',
				ml_SubmitReferral:
					submitReferral.length > 0
						? submitReferral[0].text
						: 'Submit Referral',
				ml_BonusDetails: get(bonusDetail, 'text', 'Bonus Details'),
				ml_Job: get(job, 'text', 'Job'),
				ml_ReferredCandidate: get(
					referredCandidate,
					'text',
					'Referred Candidate'
				),
				ml_TotalBonus: get(totalBonus, 'text', 'Total Bonus'),
				ml_HiredDate: get(hiredDate, 'text', 'Hired Date'),
				ml_StartDate: get(startDate, 'text', 'Start Date'),
				ml_BonusPayments: get(bonusPayment, 'text', 'Bonus Payments'),
				ml_BonusNotes: get(bonusNote, 'text', 'Bonus Notes'),
				ml_Close: get(close, 'text', 'Close'),
				ml_ViewBonusDetails: get(viewBonusDetail, 'text', 'View Bonus Details'),
				ml_NoResponse: get(noResponse, 'text', 'No Response'),
				ml_InActive: get(inActive, 'text', 'Inactive'),
			});
		} else {
			this.setState({
				ml_MyReferrals: 'My Referrals',
				ml_SubmitGeneralReferral: 'Submit General Referral',
				ml_FilterByStatus: 'Status',
				ml_SearchByName: 'Search',
				ml_Status: 'Status',
				ml_Referred: 'Referred',
				ml_Accepted: 'Accepted',
				ml_Hired: 'Hired',
				ml_ReferredOn: 'Referred on',
				ml_Total: 'Total',
				ml_Interviewing: 'Interviewing',
				ml_Earned: 'Earned',
				ml_NotHired: 'Not Hired',
				ml_YourEarnedTotal:
					'Your Earned Total reflects referral bonuses that are past the waiting period.',
				ml_SelfReferred: 'Self Referred',
				ml_Declined: 'Declined',
				ml_ReferSomeone: 'Refer Someone',
				ml_DoYouKnow: 'Do you know someone who would thrive at',
				ml_SubmitThem: 'Submit them to be considered for new opportunities!',
				ml_EnterReferral:
					"Enter a referral and we'll send them information to apply.",
				ml_EnterReferralInformation: 'Enter Referral Information',
				ml_FirstName: 'First Name',
				ml_LastName: 'Last Name',
				ml_Email: 'Email',
				ml_Text: 'Text',
				ml_Or: 'or',
				ml_ClickHere: 'click here',
				ml_ToAddExistingContact: 'to add an existing contact',
				ml_IncludeMessageToContact: 'Include a message to your contact',
				ml_Optional: 'optional',
				ml_PersonalizeMessage: 'Personalize the message to your referral',
				ml_MessageHiringContact: 'Message the Recruiter',
				ml_HowKnowThem: 'How do you know them, why are they a good fit, etc.',
				ml_Clickhere: 'Click here',
				ml_AttachResume: 'to attach a resume',
				ml_SubmitReferral: 'Submit Referral',
				ml_BonusDetails: 'Bonus Details',
				ml_Job: 'Job',
				ml_ReferredCandidate: 'Referred Candidate',
				ml_TotalBonus: 'Total Bonus',
				ml_HiredDate: 'Hired Date',
				ml_StartDate: 'Start Date',
				ml_BonusPayments: 'Bonus Payments',
				ml_BonusNotes: 'Bonus Notes',
				ml_Close: 'Close',
				ml_ViewBonusDetails: 'View Bonus Details',
				ml_NoResponse: 'No Response',
				ml_InActive: 'Inactive',
			});
		}
	}

	componentDidUpdate(prevProps) {
		const {
			referrals,
			referralBonuses,
			notifications,
			onDeckContacts,
			onFetchMoreOnDeckContacts,
			onFetchMoreReferrals,
		} = this.props;
		if (
			prevProps.referrals !== referrals &&
			prevProps.referrals.length <= referrals.length
		) {
			if (onFetchMoreReferrals) onFetchMoreReferrals();
			this.setState({
				filteredReferrals: referrals,
			});
		}

		if (prevProps.onDeckContacts !== onDeckContacts) {
			this.setState({ onDeckReferrals: this.getOnDeckReferrals() });
			if (onFetchMoreOnDeckContacts) onFetchMoreOnDeckContacts();
		}

		if (prevProps.notifications !== notifications) {
			this.setState({
				notifications,
			});
		}

		if (prevProps.referralBonuses !== referralBonuses) {
			this.setState({
				referralBonuses,
			});
		}

		if (
			prevProps.currentUser.languageCode !==
				this.props.currentUser.languageCode &&
			this.props.allMultiLingualData.length > 0
		) {
			this.props.setMultiLingualData(this.props.allMultiLingualData);
			const languageCode =
				this.props.currentUser.languageCode === null
					? 'US'
					: this.props.currentUser.languageCode;
			const filteredData = this.props.allMultiLingualData.filter(
				(item) => item.languageCode === languageCode
			);
			const myReferrals = filteredData.filter(
				(item) => item.key === myReferralsKeys.MyReferrals
			);
			const submitGeneralReferral = filteredData.filter(
				(item) => item.key === myReferralsKeys.SubmitGeneralReferral
			);
			const filterByStatus = filteredData.filter(
				(item) => item.key === myReferralsKeys.FilterByStatus
			);
			const searchByName = filteredData.filter(
				(item) => item.key === myReferralsKeys.SearchByName
			);
			const status = filteredData.filter(
				(item) => item.key === myReferralsKeys.Status
			);
			const referred = filteredData.filter(
				(item) => item.key === myReferralsKeys.Referred
			);
			const accepted = filteredData.filter(
				(item) => item.key === myReferralsKeys.Accepted
			);
			const hired = filteredData.filter(
				(item) => item.key === myReferralsKeys.Hired
			);
			const referredOn = filteredData.filter(
				(item) => item.key === myReferralsKeys.ReferredOn
			);
			const total = filteredData.filter(
				(item) => item.key === myReferralsKeys.Total
			);
			const interviewing = filteredData.filter(
				(item) => item.key === myReferralsKeys.Interviewing
			);
			const earned = filteredData.filter(
				(item) => item.key === myReferralsKeys.Earned
			);
			const notHired = filteredData.filter(
				(item) => item.key === myReferralsKeys.NotHired
			);
			const yourEarnedTotal = filteredData.filter(
				(item) => item.key === myReferralsKeys.YourEarnedTotal
			);
			const selfReferred = filteredData.filter(
				(item) => item.key === myReferralsKeys.SelfReferred
			);
			const declined = filteredData.filter(
				(item) => item.key === myReferralsKeys.Declined
			);
			const referSomeone = filteredData.filter(
				(item) => item.key === myReferralsKeys.ReferSomeone
			);
			const doYouKnow = filteredData.filter(
				(item) => item.key === myReferralsKeys.DoYouKnow
			);
			const submitThem = filteredData.filter(
				(item) => item.key === myReferralsKeys.SubmitThem
			);
			const enterReferral = filteredData.filter(
				(item) => item.key === myReferralsKeys.EnterReferral
			);
			const enterReferralInformation = filteredData.filter(
				(item) => item.key === myReferralsKeys.EnterReferralInformation
			);
			const firstName = filteredData.filter(
				(item) => item.key === myReferralsKeys.FirstName
			);
			const lastName = filteredData.filter(
				(item) => item.key === myReferralsKeys.LastName
			);
			const email = filteredData.filter(
				(item) => item.key === myReferralsKeys.Email
			);
			const textPlaceHolder = filteredData.filter(
				(item) => item.key === myReferralsKeys.Text
			);
			const or = filteredData.filter((item) => item.key === myReferralsKeys.Or);
			const clickHere = filteredData.filter(
				(item) => item.key === myReferralsKeys.ClickHere
			);
			const toAddExistingContact = filteredData.filter(
				(item) => item.key === myReferralsKeys.ToAddExistingContact
			);
			const includeMessageToContact = filteredData.filter(
				(item) => item.key === myReferralsKeys.IncludeMessageToContact
			);
			const optional = filteredData.filter(
				(item) => item.key === myReferralsKeys.Optional
			);
			const personalizeMessage = filteredData.filter(
				(item) => item.key === myReferralsKeys.PersonalizeMessage
			);
			const messageHiringContact = filteredData.filter(
				(item) => item.key === myReferralsKeys.MessageHiringContact
			);
			const howKnowThem = filteredData.filter(
				(item) => item.key === myReferralsKeys.HowKnowThem
			);
			const clickhereResume = filteredData.filter(
				(item) => item.key === myReferralsKeys.Clickhere
			);
			const attachResume = filteredData.filter(
				(item) => item.key === myReferralsKeys.AttachResume
			);
			const submitReferral = filteredData.filter(
				(item) => item.key === myReferralsKeys.SubmitReferral
			);
			const bonusDetail = filteredData.find(
				(item) => item.key === myReferralsKeys.BonusDetails
			);
			const job = filteredData.find((item) => item.key === myReferralsKeys.Job);
			const referredCandidate = filteredData.find(
				(item) => item.key === myReferralsKeys.ReferredCandidate
			);
			const totalBonus = filteredData.find(
				(item) => item.key === myReferralsKeys.TotalBonus
			);
			const hiredDate = filteredData.find(
				(item) => item.key === myReferralsKeys.HiredDate
			);
			const startDate = filteredData.find(
				(item) => item.key === myReferralsKeys.StartDate
			);
			const bonusPayment = filteredData.find(
				(item) => item.key === myReferralsKeys.BonusPayments
			);
			const bonusNote = filteredData.find(
				(item) => item.key === myReferralsKeys.BonusNotes
			);
			const close = filteredData.find(
				(item) => item.key === myReferralsKeys.Close
			);
			const viewBonusDetail = filteredData.find(
				(item) => item.key === myReferralsKeys.ViewBonusDetails
			);
			const inActive = filteredData.find(
				(item) => item.key === myReferralsKeys.InActive
			);

			const noResponse = filteredData.find(
				(item) => item.key === myReferralsKeys.NoResponse
			);
			this.setState({
				ml_MyReferrals:
					myReferrals.length > 0 ? myReferrals[0].text : 'My Referrals',
				ml_SubmitGeneralReferral:
					submitGeneralReferral.length > 0
						? submitGeneralReferral[0].text
						: 'Submit General Referral',
				ml_FilterByStatus:
					filterByStatus.length > 0 ? filterByStatus[0].text : 'Status',
				ml_SearchByName:
					searchByName.length > 0 ? searchByName[0].text : 'Search',
				ml_Status: status.length > 0 ? status[0].text : 'Status',
				ml_Referred: referred.length > 0 ? referred[0].text : 'Referred',
				ml_Accepted: accepted.length > 0 ? accepted[0].text : 'Accepted',
				ml_Hired: hired.length > 0 ? hired[0].text : 'Hired',
				ml_ReferredOn:
					referredOn.length > 0 ? referredOn[0].text : 'Referred on',
				ml_Total: total.length > 0 ? total[0].text : 'Total',
				ml_Interviewing:
					interviewing.length > 0 ? interviewing[0].text : 'Interviewing',
				ml_Earned: earned.length > 0 ? earned[0].text : 'Earned',
				ml_NotHired: notHired.length > 0 ? notHired[0].text : 'Not Hired',
				ml_YourEarnedTotal:
					yourEarnedTotal.length > 0
						? yourEarnedTotal[0].text
						: 'Your Earned Total reflects referral bonuses that are past the waiting period.',
				ml_SelfReferred:
					selfReferred.length > 0 ? selfReferred[0].text : 'Self Referred',
				ml_Declined: declined.length > 0 ? declined[0].text : 'Declined',
				ml_ReferSomeone:
					referSomeone.length > 0 ? referSomeone[0].text : 'Refer Someone',
				ml_DoYouKnow:
					doYouKnow.length > 0
						? doYouKnow[0].text
						: 'Do you know someone who would thrive at',
				ml_SubmitThem:
					submitThem.length > 0
						? submitThem[0].text
						: 'Submit them to be considered for new opportunities!',
				ml_EnterReferral:
					enterReferral.length > 0
						? enterReferral[0].text
						: "Enter a referral and we'll send them information to apply.",
				ml_EnterReferralInformation:
					enterReferralInformation.length > 0
						? enterReferralInformation[0].text
						: 'Enter Referral Information',
				ml_FirstName: firstName.length > 0 ? firstName[0].text : 'First Name',
				ml_LastName: lastName.length > 0 ? lastName[0].text : 'Last Name',
				ml_Email: email.length > 0 ? email[0].text : 'Email',
				ml_Text: textPlaceHolder.length > 0 ? textPlaceHolder[0].text : 'Text',
				ml_Or: or.length > 0 ? or[0].text : 'or',
				ml_ClickHere: clickHere.length > 0 ? clickHere[0].text : 'click here',
				ml_ToAddExistingContact:
					toAddExistingContact.length > 0
						? toAddExistingContact[0].text
						: 'to add an existing contact',
				ml_IncludeMessageToContact:
					includeMessageToContact.length > 0
						? includeMessageToContact[0].text
						: 'Include a message to your contact',
				ml_Optional: optional.length > 0 ? optional[0].text : 'optional',
				ml_PersonalizeMessage:
					personalizeMessage.length > 0
						? personalizeMessage[0].text
						: 'Personalize the message to your referral',
				ml_MessageHiringContact:
					messageHiringContact.length > 0
						? messageHiringContact[0].text
						: 'Message the Recruiter',
				ml_HowKnowThem:
					howKnowThem.length > 0
						? howKnowThem[0].text
						: 'How do you know them, why are they a good fit, etc.',
				ml_Clickhere:
					clickhereResume.length > 0 ? clickhereResume[0].text : 'Click here',
				ml_AttachResume:
					attachResume.length > 0 ? attachResume[0].text : 'to attach a resume',
				ml_SubmitReferral:
					submitReferral.length > 0
						? submitReferral[0].text
						: 'Submit Referral',
				ml_BonusDetails: get(bonusDetail, 'text', 'Bonus Details'),
				ml_Job: get(job, 'text', 'Job'),
				ml_ReferredCandidate: get(
					referredCandidate,
					'text',
					'Referred Candidate'
				),
				ml_TotalBonus: get(totalBonus, 'text', 'Total Bonus'),
				ml_HiredDate: get(hiredDate, 'text', 'Hired Date'),
				ml_StartDate: get(startDate, 'text', 'Start Date'),
				ml_BonusPayments: get(bonusPayment, 'text', 'Bonus Payments'),
				ml_BonusNotes: get(bonusNote, 'text', 'Bonus Notes'),
				ml_Close: get(close, 'text', 'Close'),
				ml_ViewBonusDetails: get(viewBonusDetail, 'text', 'View Bonus Details'),
				ml_NoResponse: get(noResponse, 'text', 'No Response'),
				ml_InActive: get(inActive, 'text', 'Inactive'),
			});
		}
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.updateWindowDimensions);
	}

	async getCurrencyRate(currency) {
		const response = await fetch(
			`https://api.exchangeratesapi.io/latest?base=USD&symbols=${currency}`
		);
		const result = await response.json();
		if (result.error) {
			// Make this USD
			this.setState({
				currencyRate: 1,
				currencySymbol: '$',
			});
		} else {
			const rate = result.rates[currency].toFixed(5);
			const symbol = getSymbolFromCurrency(currency);
			this.props.setCurrentCurrencyRate(rate);
			this.props.setCurrentCurrencySymbol(symbol);
			this.setState({
				currencyRate: rate,
				currencySymbol: symbol,
			});
		}
	}

	getOnDeckReferrals = (contacts) => {
		const { onDeckContacts, currentUser } = this.props;
		const referrals = contacts ? contacts : onDeckContacts;
		const newReferrals = referrals.filter(
			(referral) =>
				referral.userId === currentUser.id &&
				referral.referrals.length === 0 &&
				(referral.onDeckStatus === 'onDeck' ||
					referral.onDeckStatus === 'offDeck')
		);

		for (const referral of newReferrals) {
			if (referral.onDeckStatus === 'offDeck') referral.status = 'notHired';
			if (referral.onDeckStatus === 'onDeck') referral.status = 'referred';
			referral.contact = referral;
			referral.referralDate = referral.onDeckDate;
		}

		return newReferrals;
	};

	async getUserDataById(policy = 'network-only') {
		const { client, currentUser } = this.props;
		try {
			const userId = get(currentUser, 'id', null);
			if (userId !== null) {
				const { data } = await client.query({
					query: gql(getUserById),
					variables: {
						id: userId,
					},
					fetchPolicy: policy,
				});
				const result = get(data, 'getUser', null);
				this.setState({
					resultData: result,
				});
			}
		} catch (error) {
			console.log(error);
		}
	}

	setQueryToState = (value) => {
		this.setState({ searchQuery: value });
	};

	handleReferralStatusFilter = (value) => {
		if (value.length === 0) {
			this.setState({ filteredReferrals: this.props.referrals });
			return;
		}

		const results = [];
		for (const record of this.props.referrals) {
			if (value.includes(record.status)) {
				results.push(record);
			}

			if (value === 'accepted' && record.status !== 'hired') {
				results.push(record);
			}
		}

		this.setState({ filteredReferrals: results });
	};

	removeReferral = (notificationId) => {
		const notificationList = [...this.state.notifications];
		const notificationToDelete = notificationList.findIndex(
			(note) => note.id === notificationId
		);
		notificationList.splice(0, notificationToDelete);
		this.setState({ notifications: notificationList });
	};

	updateWindowDimensions = () => {
		this.setState({ width: window.innerWidth, height: window.innerHeight });
	};

	render() {
		const { currentUser, requestingNotifications } = this.props;
		const {
			company,
			contacts,
			searchQuery,
			width,
			loaded,
			notifications,
			currencyRate,
			currencySymbol,
			errorImageSrc,
			ml_SubmitGeneralReferral,
			ml_FilterByStatus,
			ml_SearchByName,
			ml_Status,
			ml_Referred,
			ml_Accepted,
			ml_Hired,
			ml_ReferredOn,
			ml_Total,
			ml_Interviewing,
			ml_Earned,
			ml_NotHired,
			ml_YourEarnedTotal,
			ml_SelfReferred,
			ml_Declined,
			ml_ReferSomeone,
			ml_DoYouKnow,
			ml_SubmitThem,
			ml_EnterReferral,
			ml_EnterReferralInformation,
			ml_FirstName,
			ml_LastName,
			ml_Email,
			ml_Text,
			ml_Or,
			ml_ClickHere,
			ml_ToAddExistingContact,
			ml_IncludeMessageToContact,
			ml_Optional,
			ml_PersonalizeMessage,
			ml_MessageHiringContact,
			ml_HowKnowThem,
			ml_Clickhere,
			ml_AttachResume,
			ml_SubmitReferral,
			ml_BonusDetails,
			ml_Job,
			ml_ReferredCandidate,
			ml_TotalBonus,
			ml_HiredDate,
			ml_StartDate,
			ml_BonusPayments,
			ml_BonusNotes,
			ml_Close,
			ml_ViewBonusDetails,
			ml_NoResponse,
			ml_InActive,
		} = this.state;
		const whiteLabel = get(this.state, 'company.whiteLabel');
		let filteredReferrals = [];
		if (this.state.filteredReferrals && this.state.onDeckReferrals) {
			filteredReferrals = [
				...this.state.filteredReferrals,
				...this.state.onDeckReferrals,
			];
		} else if (this.state.onDeckReferrals) {
			filteredReferrals = this.state.onDeckReferrals;
		} else if (this.state.filteredReferrals) {
			filteredReferrals = this.state.filteredReferrals;
		}

		if (!filteredReferrals) {
			return <Spinner company={company} />;
		}

		let referralNotifications = [];
		if (notifications) {
			referralNotifications = notifications.filter(
				(notification) =>
					get(notification, 'type') === 'referralRequested' &&
					(get(notification, 'referralRequestedStatus') === 'requested' ||
						get(notification, 'referralRequestedStatus') === null)
			);
		}

		let referralRequestingNotifications = [];
		if (requestingNotifications) {
			referralRequestingNotifications = requestingNotifications.filter(
				(notification) =>
					get(notification, 'type') === 'referralRequested' &&
					(get(notification, 'referralRequestedStatus') === 'requested' ||
						get(notification, 'referralRequestedStatus') === null ||
						get(notification, 'referralRequestedStatus') === 'declined')
			);
		}

		filteredReferrals = [
			...filteredReferrals,
			...referralRequestingNotifications,
		];

		const searchedReferrals = searchReferralData(
			['firstName', 'lastName'],
			searchQuery,
			filteredReferrals
		);

		const referralData = searchedReferrals;
		if (!searchedReferrals) {
			return <Spinner company={company} />;
		}

		if (this.state.loaded !== true) {
			setTimeout(() => {
				this.setState({ loaded: true });
			}, 2000);
		}

		const sortedReferrals = _.sortBy(referralData, function (referral) {
			return referral.referralDate;
		}).reverse();

		const sortedNotifications = _.sortBy(
			referralNotifications,
			function (notification) {
				return notification.dateCreated;
			}
		).reverse();

		let userGroupCurrency = get(currentUser, 'userGroup.currency');
		if (userGroupCurrency === null) userGroupCurrency = 'USD';
		return (
			<main className={ReferralsDashboardContainer}>
				<Row
					type="flex"
					justify="space-between"
					className={ToolBar}
					gutter={10}
				>
					<Col md={7} xs={24}>
						<span style={{ height: 42, display: 'block' }}>
							<OnDeckReferralModal
								contacts={contacts}
								jobData={this.props.job}
								submitGeneralReferralText={ml_SubmitGeneralReferral}
								referSomeoneText={ml_ReferSomeone}
								doYouKnowText={ml_DoYouKnow}
								submitThemText={ml_SubmitThem}
								enterReferralText={ml_EnterReferral}
								enterReferralInformationText={ml_EnterReferralInformation}
								firstNameText={ml_FirstName}
								lastNameText={ml_LastName}
								emailText={ml_Email}
								textPlaceHolderText={ml_Text}
								orText={ml_Or}
								clickHereText={ml_ClickHere}
								toAddExistingContactText={ml_ToAddExistingContact}
								includeMessageToContactText={ml_IncludeMessageToContact}
								optionalText={ml_Optional}
								personalizeMessageText={ml_PersonalizeMessage}
								messageHiringContactText={ml_MessageHiringContact}
								howKnowThemText={ml_HowKnowThem}
								clickHereResumeText={ml_Clickhere}
								attachResumeText={ml_AttachResume}
								submitReferralText={ml_SubmitReferral}
								onDeckContacts={this.props.onDeckContacts}
								onCreateContact={this.props.onCreateContact}
								onUpdateContact={this.props.onUpdateContact}
							/>
						</span>
					</Col>
					<Col md={6} xs={24}>
						<SearchComponent
							searchQuery={searchQuery}
							setQueryToState={this.setQueryToState}
							placeholder={ml_SearchByName}
						/>
					</Col>
				</Row>
				<section className={SectionStyles}>
					{referralNotifications.length > 0 ? (
						<List
							dataSource={sortedNotifications}
							itemLayout="horizontal"
							pagination={{ pageSize: 10, showSizeChanger: false }}
							renderItem={(notification) => (
								<List.Item>
									<ReferralNotification
										key={
											notification.id ||
											Math.floor(Math.random() * Math.floor(1_203_869_142))
										}
										notification={notification}
										currentUser={currentUser}
										referrals={sortedReferrals}
										removeRefer={this.removeReferral}
										onUpdateNotification={this.props.onUpdateNotification}
										onCreateReferral={this.props.onCreateReferral}
										onDeckContacts={this.props.onDeckContacts}
										onCreateContact={this.props.ImportedCreateContact}
										onUpdateContact={this.props.onUpdateContact}
									/>
								</List.Item>
							)}
						/>
					) : null}
				</section>
				<section className={SectionStyles}>
					{sortedReferrals && sortedReferrals.length > 0 ? (
						<List
							grid={{ gutter: 14, xs: 1, sm: 1, md: 2, lg: 2, xl: 2, xxl: 3 }}
							dataSource={sortedReferrals}
							itemLayout="horizontal"
							pagination={{ pageSize: 20, showSizeChanger: false }}
							renderItem={(referral) => (
								<List.Item>
									<ReferralCard
										key={referral.id}
										referral={referral}
										employee={this.state.employeeData}
										referralType={referral.referralType}
										currentCurrencyRate={currencyRate}
										currentCurrencySymbol={currencySymbol}
										referredByText="Referred By"
										referredOnText={ml_ReferredOn}
										statusText={ml_Status}
										bonusDetailsText={ml_BonusDetails}
										jobText={ml_Job}
										referredCandidateText={ml_ReferredCandidate}
										totalBonusText={ml_TotalBonus}
										hiredDateText={ml_HiredDate}
										startDateText={ml_StartDate}
										bonusPaymentsText={ml_BonusPayments}
										bonusNotesText={ml_BonusNotes}
										closeText={ml_Close}
										viewBonusDetailsText={ml_ViewBonusDetails}
										currentUser={this.props.currentUser}
										referredText={ml_Referred}
										acceptedText={ml_Accepted}
										interviewingText={ml_Interviewing}
										hiredText={ml_Hired}
										notHiredText={ml_NotHired}
										noResponseText={ml_NoResponse}
										inActiveText={ml_InActive}
									/>
								</List.Item>
							)}
						/>
					) : loaded ? (
						<div>
							{whiteLabel ? (
								<p className={noReferrals}>
									<img src={errorImageSrc} alt="error image" width="150px" />
								</p>
							) : (
								<p className={noReferrals}>
									<img alt="erin-logo" width={150} src={Logo} />
								</p>
							)}
							<p
								className={noReferralsText}
								style={{ fontSize: 16, fontWeight: 400 }}
							>
								You haven't been referred to any positions yet.
							</p>
						</div>
					) : (
						<Spinner company={company} />
					)}
				</section>
			</main>
		);
	}
}
export default withApollo(MyReferralsExtendedComponent);
