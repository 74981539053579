import { Modal } from 'antd';
import get from 'lodash/get';
import { Component } from 'react';
import { USER_ROLES } from 'src/_shared/constants/';
import OnDeckReferralFormAdmin from './OnDeckReferralFormAdminComponent.jsx';
import OnDeckReferralForm from './OnDeckReferralFormComponent.jsx';

class SubmitGeneralReferralModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			autoCompleteResult: [],
			isSubmitting: false,
			newContact: !props.contact,
			error: false,
			buttonState: '',
			theme: JSON.parse(get(props, 'currentUser.company.theme', '{}')),
		};
	}

	componentDidMount() {
		if (this.props.matchFound) {
			this.setState({ autoCompleteResult: [this.props.matchFound] });
		}

		if (this.props.contact) {
			this.setState({ autoCompleteResult: [this.props.contact] });
		}
	}

	handleContactChange = (value) => {
		let autoCompleteResult = [];
		if (value) {
			for (const record of get(this.props, 'contacts', [])) {
				const { firstName, lastName, emailAddress, phoneNumber } = record;
				const isFirstNameMatch =
					firstName && firstName.toLowerCase().includes(value.toLowerCase());
				const isLastNameMatch =
					lastName && lastName.toLowerCase().includes(value.toLowerCase());
				const isEmailMatch =
					emailAddress &&
					emailAddress.toLowerCase().includes(value.toLowerCase());
				const isPhoneMatch =
					phoneNumber &&
					phoneNumber.toLowerCase().includes(value.toLowerCase());

				if (
					isFirstNameMatch ||
					isLastNameMatch ||
					isEmailMatch ||
					isPhoneMatch
				) {
					autoCompleteResult.push(record);
				}
			}
		} else {
			autoCompleteResult = [];
		}

		this.setState({ autoCompleteResult });
	};

	handleError = () => {
		this.setState({ error: true });
		this.setState({ buttonState: 'error' });
	};

	handleNewContact = () => {
		this.setState((prevState) => ({ newContact: !prevState.newContact }));
	};

	toggleIsSubmitting = () => {
		this.setState({ buttonState: 'loading' });
		this.setState((prevState) => ({ isSubmitting: !prevState.isSubmitting }));
		setTimeout(() => {
			this.setState({ buttonState: 'success' });
		}, 5400);
	};

	render() {
		const {
			contacts,
			onDeckContacts,
			referrals,
			onCreateContact,
			onCreateReferral,
			onUpdateContact,
			currentUser,
			contact,
			onUpdateNotification,
			visible,
			referSomeoneText,
			doYouKnowText,
			submitThemText,
			enterReferralText,
			enterReferralInformationText,
			firstNameText,
			lastNameText,
			emailText,
			textPlaceHolderText,
			orText,
			clickHereText,
			toAddExistingContactText,
			includeMessageToContactText,
			optionalText,
			requiredText,
			personalizeMessageText,
			messageHiringContactText,
			howKnowThemText,
			clickHereResumeText,
			attachResumeText,
			submitReferralText,
			allMultiLingualData,
		} = this.props;
		const { autoCompleteResult, newContact, isSubmitting, error, buttonState } =
			this.state;

		const contactName =
			get(contact, 'firstName') && get(contact, 'lastName')
				? get(contact, 'firstName') + ' ' + get(contact, 'lastName')
				: 'this candidate';
		return (
			visible && (
				<Modal
					open={visible}
					footer={null}
					width={600}
					className="referral-modal"
					title={
						currentUser.displayAs === USER_ROLES.ADMIN &&
						window.location.pathname !== '/myreferrals'
							? 'Add General Referral'
							: referSomeoneText
					}
					onCancel={this.props.handleCancel}
				>
					{currentUser.displayAs === USER_ROLES.ADMIN &&
					window.location.pathname !== '/myreferrals' ? (
						<>
							<p className="text-center">
								Would {contactName} thrive at {currentUser.company.name}? Submit
								a General Referral for them to be considered for open
								opportunities.
							</p>

							<OnDeckReferralFormAdmin
								contacts={contacts}
								handleContactChange={this.handleContactChange}
								autoCompleteResult={autoCompleteResult}
								isSubmitting={isSubmitting}
								toggleIsSubmitting={this.toggleIsSubmitting}
								error={error}
								handleError={this.handleError}
								handleCancel={this.props.handleCancel}
								currentUser={currentUser}
								matchFound={this.props.matchFound}
								contact={contact}
								notification={this.props.notification}
								referrals={referrals}
								visible={visible}
								buttonState={buttonState}
								onDeckContacts={onDeckContacts}
								onCreateContact={onCreateContact}
								onCreateReferral={onCreateReferral}
								onUpdateContact={onUpdateContact}
								onUpdateNotification={onUpdateNotification}
							/>
						</>
					) : (
						<>
							<p className="text-center">
								{doYouKnowText} {currentUser.company.name}? {submitThemText}
							</p>
							<OnDeckReferralForm
								newContact={newContact}
								handleNewContact={this.handleNewContact}
								contacts={contacts}
								handleContactChange={this.handleContactChange}
								autoCompleteResult={autoCompleteResult}
								isSubmitting={isSubmitting}
								toggleIsSubmitting={this.toggleIsSubmitting}
								error={error}
								handleError={this.handleError}
								handleCancel={this.props.handleCancel}
								currentUser={currentUser}
								matchFound={this.props.matchFound}
								contact={contact}
								notification={this.props.notification}
								referrals={referrals}
								visible={visible}
								buttonState={buttonState}
								enterReferralText={enterReferralText}
								enterReferralInformationText={enterReferralInformationText}
								firstNameText={firstNameText}
								lastNameText={lastNameText}
								emailText={emailText}
								textPlaceHolderText={textPlaceHolderText}
								orText={orText}
								clickHereText={clickHereText}
								toAddExistingContactText={toAddExistingContactText}
								includeMessageToContactText={includeMessageToContactText}
								optionalText={optionalText}
								requiredText={requiredText}
								personalizeMessageText={personalizeMessageText}
								messageHiringContactText={messageHiringContactText}
								howKnowThemText={howKnowThemText}
								clickHereResumeText={clickHereResumeText}
								attachResumeText={attachResumeText}
								submitReferralText={submitReferralText}
								allMultiLingualData={allMultiLingualData}
								onDeckContacts={onDeckContacts}
								onCreateContact={onCreateContact}
								onCreateReferral={onCreateReferral}
								onUpdateContact={onUpdateContact}
								onUpdateNotification={onUpdateNotification}
							/>
						</>
					)}
				</Modal>
			)
		);
	}
}

export default SubmitGeneralReferralModal;
