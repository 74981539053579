import { css } from 'emotion';
import get from 'lodash/get';
import { COLORS } from '../../_shared/styles/colors';

export const Card = css({
	fontFamily: '"Open Sans", sans-serif',
	boxShadow: '1px 1px 15px rgba(0,0,0,0.25)',
	borderRadius: 4,
	backgroundColor: 'white',
	padding: 30,
	marginTop: 10,
	display: 'flex',
	width: '85%',
	maxWidth: 625,
});

export const InputStyles2 = css({
	fontFamily: '"Open Sans", sans-serif',
	fontSize: 16,
	'& .ant-input': {
		height: 50,
		fontFamily: '"Open Sans", sans-serif',
		fontSize: 16,
	},
	'& .ant-form-explain': {
		fontSize: 12,
	},
});

export const LabelStyles = css({
	fontFamily: '"Open Sans", sans-serif',
	fontSize: '18px !important',
	fontWeight: 600,
});

export const FlexContainer = css({
	display: 'flex',
	justifyContent: 'space-between',
});

export const TitleStyles = css({
	marginBottom: 0,
	textAlign: 'center',
	fontSize: 32,
});

export const SubTitleStyles = css({
	marginBottom: 0,
	textAlign: 'center',
});

export const FormStyles = css({
	marginTop: 20,
});

export const SubmitBtnContainer = (theme) => {
	let buttonColor = COLORS.blue;
	if (get(theme, 'enabled')) {
		buttonColor = get(theme, 'buttonColor', buttonColor);
	}

	return css({
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		marginBottom: 0,
		'& .ant-form-item-control-wrapper': {
			'@media (max-width: 575px)': {
				width: 'auto',
			},
		},
		'& .ant-btn:hover': {
			backgroundColor: buttonColor,
			border: 'none',
			color: COLORS.white,
		},
		'& .ant-btn:focus': {
			backgroundColor: buttonColor,
			border: 'none',
			color: COLORS.white,
		},
		'& .ant-legacy-form-item-control': {
			display: 'flex',
			justifyContent: 'center',
		},
	});
};

export const SubmitBtn = (theme) => {
	let buttonColor = COLORS.blue;
	if (get(theme, 'enabled')) {
		buttonColor = get(theme, 'buttonColor', buttonColor);
	}

	return css({
		fontFamily: '"Open Sans", sans-serif',
		backgroundColor: buttonColor,
		color: 'white',
		fontSize: 20,
		fontWeight: 300,
		padding: '10px 30px',
		height: 'auto',
		border: 'none',
		marginBottom: 0,
		width: 200,
	});
};

export const AddLocationBtn = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 200,
	color: COLORS.blue,
	background: COLORS.white,
	fontSize: 14,
	height: 35,
	border: 0,
	lineHeight: '25px',
	'> span': {
		color: '#018dd3!important',
	},
	'&:hover > span': {
		color: '#018dd3!important',
	},
});

export const btnText = css({
	paddingBottom: 3,
	color: '#fff!important',
});

export const inputContainer = (width) =>
	css({
		width,
	});

export const autocompleteDropdownContainer = css({
	position: 'absolute',
	width: 350,
	zIndex: 1000,
});

export const typeaheadRow = (active) => ({
	backgroundColor: active ? COLORS.red : COLORS.lightGray2,
});

export const stateAndRemoteContainer = css({
	marginLeft: 195,
	marginBottom: 20,
});

export const CheckIcon = css({
	borderRadius: 100,
	border: '1px solid white',
	padding: 2,
});

export const MiscText = css({
	textAlign: 'left',
	fontStyle: 'italic',
});

export const SelectContainer = css({
	display: 'block',
	position: 'relative',
});

export const AlertError = css({
	marginBottom: 20,
});

export const errorStyles = css({
	fontSize: 12,
	color: COLORS.red,
	fontWeight: 400,
	lineHeight: '20px !important',
});
