import { Popconfirm } from 'antd';

export function RecipientItem(props) {
	const {
		referralBonusNotificationRecipients,
		company,
		onUpdateCompany,
		handleRecipientError,
		updateRecipientListState,
	} = props;

	const inactivateRecipient = (recipient) => {
		if (recipient === 'Other') {
			handleRecipientError();
			return;
		}

		if (referralBonusNotificationRecipients) {
			let newRecipients = referralBonusNotificationRecipients.split(',');

			const recipientIndex = newRecipients.indexOf(recipient);
			if (recipientIndex > -1) {
				newRecipients.splice(recipientIndex, 1);
			}

			newRecipients = newRecipients.join(',');
			if (newRecipients === '') {
				newRecipients = ' ';
			}

			updateRecipientListState(newRecipients);

			onUpdateCompany({
				input: {
					id: company.id,
					referralBonusNotificationRecipients: newRecipients,
				},
			});
		}
	};

	return (
		<ul className="tag-wrap">
			{referralBonusNotificationRecipients &&
				referralBonusNotificationRecipients
					.split(',')
					.map((recipient, index) => {
						return (
							<li key={index} className="tag green">
								<span className="tag-name"> {recipient} </span>

								<Popconfirm
									placement="left"
									title="Are you sure？"
									okText="Delete"
									cancelText="Cancel"
									onConfirm={() => inactivateRecipient(recipient)}
								>
									<i className="tag-close icon-cross" />
								</Popconfirm>
							</li>
						);
					})}
		</ul>
	);
}
