import { useEffect, useState } from 'react';

export function Categories(props) {
	const [categories, setCategories] = useState([]);

	useEffect(() => {
		const categories = [];
		if (props?.stages)
			for (const [i, item] of props?.stages.entries()) {
				categories.push(item['stage' + (i + 1)]);
			}

		setCategories(categories);
	}, [props.stages]);
	return (
		<>
			{categories.map((category, index) => {
				return (
					<li key={index}>
						<label>{category} :</label>
						<span>
							{props.statusWiseReferrals[category]
								? props.statusWiseReferrals[category]
										.toString()
										.replaceAll(/\B(?=(\d{3})+(?!\d))/g, ',')
								: 0}{' '}
						</span>
					</li>
				);
			})}
		</>
	);
}
