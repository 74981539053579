import { Popconfirm } from 'antd';

export function UserGroupItem(props) {
	const { userGroups, onUpdateUserGroup, handleUserGroupError } = props;
	const inactivateUserGroup = (userGroupId, userGroupName) => {
		if (userGroupName === 'Default') {
			handleUserGroupError();
			return;
		}

		onUpdateUserGroup({
			input: {
				id: userGroupId,
				active: false,
			},
		});
	};

	return (
		<ul className="tag-wrap">
			{userGroups
				? userGroups.map((userGroup) => {
						return (
							<li
								key={userGroup.id}
								className="tag green"
								onClick={() => (e) => e.stopPropagation()}
							>
								<span className="tag-name"> {userGroup.name} </span>
								<Popconfirm
									placement="left"
									title="are you sure？"
									okText="delete"
									cancelText="cancel"
									onConfirm={(e) =>
										inactivateUserGroup(userGroup.id, userGroup.name)
									}
								>
									<i className="tag-close icon-cross" />
								</Popconfirm>
							</li>
						);
					})
				: null}
		</ul>
	);
}
