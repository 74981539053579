import {
	Avatar,
	CardStyles,
	EditProfile,
	FlexContainer,
	NoPicture,
} from '../editUserProfileStyles.js';
import EditUserProfileForm from './EditUserProfileFormComponent.jsx';

function EditUserProfileCard(props) {
	const {
		selectedUser,
		currentUser,
		onUpdate,
		departments,
		updateUserProfile,
		handleError,
		error,
	} = props;

	const { firstName, lastName, avatar } = selectedUser;
	return (
		<div className={CardStyles}>
			<div className={FlexContainer}>
				{avatar === null || avatar === '' ? (
					<div className={NoPicture}>
						<h3>
							{firstName[0]} {lastName[0]}
						</h3>
					</div>
				) : (
					<img
						className={Avatar}
						src={avatar}
						alt={`${firstName} ${lastName}`}
					/>
				)}
			</div>

			<h1 className={EditProfile}>
				Edit {firstName} {lastName}
				&#39;s Profile
			</h1>
			<EditUserProfileForm
				user={selectedUser}
				currentUser={currentUser}
				error={error}
				handleError={handleError}
				updateUserProfile={updateUserProfile}
				departments={departments}
				onUpdate={onUpdate}
			/>
		</div>
	);
}

export default EditUserProfileCard;
