export const createUserDepartment = `mutation CreateUserDepartment($input: CreateUserDepartmentInput!) {
  createUserDepartment(input: $input) {
    id
    userId
    user {
      id
      cognitoId
      companyId
      emailAddress
      firstName
      lastName
      title
      departmentId
      lastLogin
      lastNotificationCheck
      contacts {
        id
        firstName
        lastName
        emailAddress
        socialMediaAccounts
        userId
        companyId
        phoneNumber
        jobHistory
        fullContactData
        dateCreated
      }
      totalReferrals
      webNotifications {
        id
        userId
        requestingUserId
        contactId
        referralId
        jobId
        matches
        message
        dateCreated
      }
      connectedApps
      active
      createdById
      dateCreated
      
      
      ytdReferralCount
    }
    departmentId
    department {
      id
      companyId
      name
      active
      totalUsers
      userDepartments {
        id
        userId
        departmentId
      }
    }
  }
}
`;
