import gql from 'graphql-tag';

export const GetJobLevel = gql`
	query GetJobLevel($id: ID!) {
		getJobLevel(id: $id) {
			id
			active
			name
			companyId
		}
	}
`;

export const queryJobLevelByCompanyIdIndex = `
  query QueryJobLevelByCompanyIdIndex($companyId: ID!
    $first: Int
    $after: String) {
    queryJobLevelByCompanyIdIndex(
      companyId: $companyId
      first: $first
      after: $after
    ) {
      items { 
        id
        active
        name
        companyId
      }
    }
  }
`;
