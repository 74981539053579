/**
 *
 *  Base64 encode / decode
 *  http://www.webtoolkit.info/
 *
 **/
// this library conforms to RFC 4648
var Base64 = {
	// Private property
	_keyStr: 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=',

	// Public method for encoding
	encode(input) {
		let output = '';
		let chr1;
		let chr2;
		let chr3;
		let enc1;
		let enc2;
		let enc3;
		let enc4;
		let i = 0;

		input = Base64._utf8_encode(input);

		while (i < input.length) {
			chr1 = input.charCodeAt(i++);
			chr2 = input.charCodeAt(i++);
			chr3 = input.charCodeAt(i++);

			enc1 = chr1 >> 2;
			enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
			enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
			enc4 = chr3 & 63;

			if (isNaN(chr2)) {
				enc3 = enc4 = 64;
			} else if (isNaN(chr3)) {
				enc4 = 64;
			}

			output =
				output +
				this._keyStr.charAt(enc1) +
				this._keyStr.charAt(enc2) +
				this._keyStr.charAt(enc3) +
				this._keyStr.charAt(enc4);
		}

		return output;
	},

	// Public method for decoding
	decode(input) {
		let output = '';
		let chr1;
		let chr2;
		let chr3;
		let enc1;
		let enc2;
		let enc3;
		let enc4;
		let i = 0;

		input = input.replaceAll(/[^A-Za-z\d+/=]/g, '');

		while (i < input.length) {
			enc1 = this._keyStr.indexOf(input.charAt(i++));
			enc2 = this._keyStr.indexOf(input.charAt(i++));
			enc3 = this._keyStr.indexOf(input.charAt(i++));
			enc4 = this._keyStr.indexOf(input.charAt(i++));

			chr1 = (enc1 << 2) | (enc2 >> 4);
			chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
			chr3 = ((enc3 & 3) << 6) | enc4;

			output += String.fromCharCode(chr1);

			if (enc3 != 64) {
				output += String.fromCharCode(chr2);
			}

			if (enc4 != 64) {
				output += String.fromCharCode(chr3);
			}
		}

		output = Base64._utf8_decode(output);

		return output;
	},

	/// encode an ArrayBuffer
	encodeArray(buffer) {
		let binary = '';
		const bytes = new Uint8Array(buffer);
		const length_ = bytes.byteLength;
		for (let i = 0; i < length_; i++) {
			binary += String.fromCharCode(bytes[i]);
		}

		return window.btoa(binary);
	},

	// Private method for UTF-8 encoding
	_utf8_encode(string) {
		let utftext = '';

		for (let n = 0; n < string.length; n++) {
			const c = string.charCodeAt(n);

			if (c < 128) {
				utftext += String.fromCharCode(c);
			} else if (c > 127 && c < 2048) {
				utftext += String.fromCharCode((c >> 6) | 192);
				utftext += String.fromCharCode((c & 63) | 128);
			} else {
				utftext += String.fromCharCode((c >> 12) | 224);
				utftext += String.fromCharCode(((c >> 6) & 63) | 128);
				utftext += String.fromCharCode((c & 63) | 128);
			}
		}

		return utftext;
	},

	// Private method for UTF-8 decoding
	_utf8_decode(utftext) {
		let string = '';
		let i = 0;
		let c = (c1 = c2 = 0);

		while (i < utftext.length) {
			c = utftext.charCodeAt(i);

			if (c < 128) {
				string += String.fromCharCode(c);
				i++;
			} else if (c > 191 && c < 224) {
				c2 = utftext.charCodeAt(i + 1);
				string += String.fromCharCode(((c & 31) << 6) | (c2 & 63));
				i += 2;
			} else {
				c2 = utftext.charCodeAt(i + 1);
				c3 = utftext.charCodeAt(i + 2);
				string += String.fromCharCode(
					((c & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63)
				);
				i += 3;
			}
		}

		return string;
	},
};

export default Base64;
