export const queryContactsByUserIdIndexMinimal = `query QueryContactsByUserIdIndex(
  $userId: ID!
  $first: Int
  $after: String
) {
  queryContactsByUserIdIndex(
    userId: $userId
    first: $first
    after: $after
  ) {
    items {
      id
      firstName
      lastName
    }
    nextToken
  }
}
`;
