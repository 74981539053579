import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
	Alert,
	Button,
	Collapse,
	DatePicker,
	Input,
	Popconfirm,
	Select,
	message,
} from 'antd';
import dayjs from 'dayjs';
import { EditorState } from 'draft-js';
import gql from 'graphql-tag';
import Cookies from 'js-cookie';
import _, { get } from 'lodash';
import { Component } from 'react';
import { withApollo } from 'react-apollo';
import { CSVLink } from 'react-csv';
import ReactGA from 'react-ga';
import RichTextEditor from 'react-rte';
import { configMode } from 'src/_shared/api/';
import { queryCampaignsByCompanyIdIndex } from 'src/_shared/api/graphql/custom/bonus-campaign/';
import {
	queryJobsByCampaignIdIndex,
	queryJobsByCompanyIdStatusIndex,
} from 'src/_shared/api/graphql/custom/jobs/';
import { querySubCompanyByCompanyIdIndex } from 'src/_shared/api/graphql/custom/sub-company/';
import { getUserById } from 'src/_shared/api/graphql/custom/users/';
import Spinner from 'src/_shared/components/spinner/SpinnerComponent.jsx';
import {
	getSetErrorImageURL,
	formatDate,
	lambda,
	logout,
	ml,
} from 'src/_shared/services/utils.js';
import { COLORS } from 'src/_shared/styles/colors.js';
import fileIcon from '../_shared/assets/erin_lightgray.png';

const { Option } = Select;

class BonusCampaignsComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			messageRaw: RichTextEditor.createEmptyValue(),
			editorState: EditorState.createEmpty(),
			theme: JSON.parse(get(props, 'currentUser.company.theme', '{}')),
			filteredDepartments: [],
			filteredUserGroups: [],
			filteredJobs: [],
			allJobs: [],
			filteredUsers: [],
			usersCount: 0,
			subject: '',
			message: '',
			lengthError: false,
			loaded: false,
			jobId: '',
			jobTitle: '',
			jobLocation: '',
			loadedJobs: false,
			jobDefaultValue: 'None',
			buttonState: '',
			isTestEmailDone: '',
			isEmailAllUsers: '',
			isSubjectEmpty: false,
			isMessageEmpty: false,
			filteredSubCompanies: [],
			ml_SendAMessage: '',
			ml_SelectEmployees: '',
			ml_WhoWill: '',
			ml_InGroup: '',
			ml_AndInDept: '',
			ml_All: '',
			ml_None: '',
			ml_EmpSelected: '',
			ml_Job: '',
			ml_Optional: '',
			ml_DoYouWant: '',
			ml_MessageCenter_SelectJob: '',
			ml_Message: '',
			ml_TheMessage: '',
			ml_Subject: '',
			ml_SendMe: '',
			ml_MessageCenter_Email: '',
			ml_Employees: '',
			ml_Location: 'Location',
			ml_withIn: 'within',
			ml_of: 'of',
			ml_Location_Note:
				'Note: Location filter will only send messages to users with a set location.',
			ml_miles: 'Miles',
			ml_city: 'City',
			ml_state: 'State',
			location: {
				city: '',
				state: '',
				lat: '',
				lng: '',
				address: '',
			},
			filteredDistance: '0',
			ml_AllLocations: 'All Locations',
			isEmployee: true,
			isCandidate: false,
			dateRangeFilterValue: [dayjs().startOf('month'), null],
			filteredCandidateData: [],
			filteredCount: 0,
			totalReferralsData: [],
			totalReferralsCount: 0,
			countLoaded: true,
			isEmailAllCandidates: '',
			ml_MessageCenter_SelectBrand: '',
			subCompanies: [],
			subCompany: {},
			sendPush: false,
			bonuses: get(props, 'bonuses', []),
			jobsCount: 0,
			disabled: true,
			selectedJobsByUser: [],
			onlySelectedJobs: [],
			defaultSubCompaniesValue: undefined,
			allCampaignsData: get(props, 'campaigns', []),
			allCampaigns: [],
			success: false,
			edit: false,
			editCampaignId: null,
			editedJob: [],
			editTieredBonusId: null,
			editDateRangeFilterValue: [dayjs().startOf('month'), null],
			btnDisabled: false,
			jobError: false,
			editJobReq: false,
			editTieredBonusReq: false,
			editBonusScheduleReq: false,
			markHot: false,
			makeHotJob: false,
			errorImageURL: '',
		};
		this.loading = true;
	}

	async componentDidMount() {
		const host = window.location.hostname;
		if (host === 'referrals.aus.com') {
			ReactGA.initialize('UA-128630809-2');
			ReactGA.pageview(window.location.pathname + window.location.search);
		} else if (host === 'app.erinapp.com') {
			ReactGA.initialize('UA-128630809-3');
			ReactGA.pageview(window.location.pathname + window.location.search);
		}

		await this.getUserDataById();
		const jwt = Cookies.get('jwt');
		const { resultData } = this.state;
		if (resultData !== '' && resultData !== undefined) {
			const expirationDoneByToken = get(
				resultData,
				'expirationDoneByToken',
				null
			);
			const expires = get(resultData, 'expires');
			if (jwt !== undefined && jwt !== expirationDoneByToken && expires) {
				logout(this.props.client);
			}
		}

		let endpoint = '';
		endpoint = configMode === 'DEV' ? 'dev-campaigns' : 'prod-campaigns';
		const companyId = get(this.props.currentUser, 'companyId');
		await lambda({ endpoint, variables: { companyId } });
		await this.getAllCampaignsByCompany();
		this.loading = false;
		await this.getJobs();
		await this.getSubCompaniesByCompany();

		const { currentUser } = this.props;
		const errorImageURL = await getSetErrorImageURL(
			currentUser?.company?.errorImage?.key
		);

		if (errorImageURL) {
			this.setState({ errorImageURL });
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.bonuses !== this.props.bonuses) {
			const updatedBonuses = get(this.props, 'bonuses', []);
			this.setState({
				bonuses: updatedBonuses,
			});
		}
	}

	getAllCampaignsByCompany = async (
		policy = 'network-only',
		nextToken = null,
		allCampaigns = []
	) => {
		const { client, currentUser } = this.props;
		try {
			const { data } = await client.query({
				query: gql(queryCampaignsByCompanyIdIndex),
				variables: {
					companyId: get(currentUser, 'companyId'),
					after: nextToken,
				},
				fetchPolicy: policy,
			});
			const allCampaignsData = [...data.queryCampaignsByCompanyIdIndex.items];
			const token = get(data, 'queryCampaignsByCompanyIdIndex.nextToken', null);
			allCampaigns = [...allCampaigns, ...allCampaignsData];
			this.setState({
				allCampaigns,
			});

			if (token) {
				await this.getAllCampaignsByCompany(policy, token, allCampaigns);
			}
		} catch (error) {
			console.log(error);
		}
	};

	async getJobs(policy = 'network-only', nextToken = null, allJobs = []) {
		const { client, currentUser } = this.props;
		try {
			const { data } = await client.query({
				query: gql(queryJobsByCompanyIdStatusIndex),
				variables: {
					companyId: get(currentUser, 'companyId'),
					after: nextToken,
					status: 'open',
				},
				fetchPolicy: policy,
			});
			const jobs = [...data.queryJobsByCompanyIdStatusIndex.items];
			const token = get(
				data,
				'queryJobsByCompanyIdStatusIndex.nextToken',
				null
			);
			allJobs = [...allJobs, ...jobs];
			this.setState({
				allJobs,
				loadedJobs: false,
			});
			if (token) {
				this.getJobs(policy, token, allJobs);
			} else {
				this.setState({ loadedJobs: true });
			}
		} catch (error) {
			console.log(error);
		}
	}

	getJobsCSVData = () => {
		const tableData = get(this.state, 'selectedJobsByUser', []);
		const newData = [];
		if (tableData.length > 0) {
			for (const item of tableData) {
				const newItem = {
					title: get(item, 'title', ''),
					department: get(item, 'department.name', ''),
					subcompany: get(item, 'subCompany.name', ''),
					status: get(item, 'status'),
				};
				newData.push(newItem);
			}
		}

		return newData;
	};

	getSubCompaniesByCompany = async (
		policy = 'network-only',
		nextToken = null,
		subCompanies = []
	) => {
		const { client, currentUser } = this.props;
		try {
			const { data } = await client.query({
				query: gql(querySubCompanyByCompanyIdIndex),
				variables: {
					companyId: get(currentUser, 'companyId'),
					after: nextToken,
				},
				fetchPolicy: policy,
			});
			const SubCompanyByCompanies = [
				...data.querySubCompanyByCompanyIdIndex.items,
			];
			const token = get(
				data,
				'querySubCompanyByCompanyIdIndex.nextToken',
				null
			);
			subCompanies = [...subCompanies, ...SubCompanyByCompanies];
			this.setState({
				subCompanies,
				loadedJobs: false,
			});

			if (token) {
				this.getSubCompaniesByCompany(policy, token, subCompanies);
			} else {
				this.setState({ loadedJobs: true });
			}
		} catch (error) {
			console.log(error);
		}
	};

	async getUserDataById(policy = 'network-only') {
		const { client, currentUser } = this.props;
		try {
			const userId = get(currentUser, 'id', null);
			if (userId !== null) {
				const { data } = await client.query({
					query: gql(getUserById),
					variables: {
						id: userId,
					},
					fetchPolicy: policy,
				});
				const result = get(data, 'getUser', null);
				this.setState({
					resultData: result,
				});
			}
		} catch (error) {
			console.log(error);
		}
	}

	JobItem = (campaignId, tieredBonusId, multiSelectDefaultValue) => {
		const removeJob = (jobId, jobTitle) => {
			const job = {
				value: jobId,
				label: jobTitle,
			};
			this.updateCampaign(
				campaignId,
				tieredBonusId,
				job,
				multiSelectDefaultValue
			);
		};

		return (
			<>
				{multiSelectDefaultValue
					? multiSelectDefaultValue.map((job) => {
							return (
								<li key={job.value + '-' + job.label} className="tag green">
									<span className="tag-name"> {job.label} </span>
									<Popconfirm
										placement="left"
										title="Are you sure？"
										okText="Delete"
										cancelText="Cancel"
										onConfirm={() => removeJob(job.value, job.label)}
									>
										<i className="tag-close icon-cross" />
									</Popconfirm>
								</li>
							);
						})
					: null}
			</>
		);
	};

	JobsCsvheader = [
		{ label: 'Job Title', key: 'title' },
		{ label: 'Department', key: 'department' },
		{ label: 'Subcompany', key: 'subcompany' },
		{ label: 'Status', key: 'status' },
	];

	bonusSchedule = () => {
		const FormItem = Form.Item;
		const { bonuses } = this.state;
		const { currentUser, allMultiLingualData } = this.props;
		const { getFieldDecorator } = this.props.form;
		const { RangePicker } = DatePicker;
		const sortedBonuses = _.sortBy(bonuses, ['name']);
		const bonusOptions = sortedBonuses.map((bonus) => {
			return { value: bonus.id, label: bonus.name };
		});
		return (
			<Form>
				<h4 className="campaign-title">
					Step 2. {ml('Bonus Schedule', currentUser, allMultiLingualData)}
				</h4>
				<div className="campaign-form-wrap">
					<div className="bs-form">
						<div className="custom-form-group">
							<label className="bs-form-label">
								{ml('Schedule Name', currentUser, allMultiLingualData)}:
							</label>
							<FormItem>
								{getFieldDecorator('scheduleName', {
									rules: [
										{
											required: true,
											message: 'Please input Schedule Name',
										},
									],
								})(<Input className="custom-input" />)}
							</FormItem>
						</div>
						<div className="custom-form-group">
							<label className="bs-form-label">
								{ml('Temporary Bonus', currentUser, allMultiLingualData)}:
							</label>
							<FormItem>
								{getFieldDecorator('tieredBonus', {
									rules: [
										{
											required: true,
											message: 'Please select Tiered Bonus',
										},
									],
								})(
									<Select
										filterOption={(input, option) => {
											return option.label
												.toLowerCase()
												.includes(input.toLowerCase());
										}}
										className="custom-input"
										placeholder={ml(
											'Choose a Bonus',
											currentUser,
											allMultiLingualData
										)}
										popupClassName="input-tag"
										options={bonusOptions}
									/>
								)}
							</FormItem>
						</div>
						<div className="custom-form-group">
							<label className="bs-form-label">
								{ml(
									'Temporary Bonus Schedule',
									currentUser,
									allMultiLingualData
								)}
								{': '}
							</label>
							<FormItem>
								{getFieldDecorator('bonusSchedule', {
									rules: [
										{
											required: true,
											message: 'Please select Bonus Schedule',
										},
									],
								})(
									<RangePicker
										className="ant-picker-480px-mw"
										separator={<span>~</span>}
										format="MM-DD-YYYY"
										startdateText={ml(
											'Start date',
											currentUser,
											allMultiLingualData
										)}
										enddateText={ml(
											'End date',
											currentUser,
											allMultiLingualData
										)}
										maxDate={dayjs()}
										disabledDate={this.disabledDate}
										onChange={(value) => this.handleDateRangeFilter(value)}
									/>
								)}
							</FormItem>
						</div>
					</div>
				</div>
			</Form>
		);
	};

	cancelEdit = () => {
		message.error('Edit Cancelled');
		this.setState({
			edit: false,
			editJobReq: false,
			editBonusScheduleReq: false,
		});
	};

	comparer(otherArray) {
		return function (current) {
			return (
				otherArray.filter(function (other) {
					return other.id === current.id;
				}).length === 0
			);
		};
	}

	createCampaign = async (e) => {
		e.preventDefault();
		const { onCreateCampaign, currentUser, onUpdateJob } = this.props;
		const {
			jobsCount,
			dateRangeFilterValue,
			selectedJobsByUser,
			filteredJobs,
		} = this.state;

		if (jobsCount.length === 0) {
			this.setState({
				jobError: true,
			});
		} else {
			this.setState({
				jobError: false,
			});
		}

		const errors = [];
		this.props.form.validateFields(async (error, values) => {
			if (error || this.state.jobError) {
				return;
			}

			if (values.scheduleName && values.scheduleName.length > 60) {
				const nameLengthError = new Error(
					'Schedule Name cannot be more than 60 characters'
				);
				errors.push(nameLengthError);
			}

			this.setState({
				btnDisabled: true,
			});
			const jobIds = filteredJobs;
			let scheduleName = '';
			let tieredBonusId = '';
			let startDate = '';
			let endDate = '';

			if (get(values, 'scheduleName')) {
				scheduleName = get(values, 'scheduleName');
			}

			if (get(values, 'tieredBonus')) {
				tieredBonusId = get(values, 'tieredBonus');
			}

			if (dateRangeFilterValue.length > 0 && dateRangeFilterValue[1] !== null) {
				startDate = dateRangeFilterValue[0];
				endDate = dateRangeFilterValue[1];
			}

			const campaignInput = {
				input: {
					companyId: get(currentUser, 'companyId'),
					endDate,
					name: scheduleName,
					startDate,
					tieredBonusId,
					makeHotJob: this.state.markHot,
				},
			};
			const jobs = [];

			if (jobIds.length > 0) {
				for (const item of jobIds) {
					const job = selectedJobsByUser.find((element) => {
						return element.id === item;
					});
					jobs.push({ id: item, title: job.title });
				}

				campaignInput.input.jobIds = JSON.stringify(jobs);
			}

			onCreateCampaign(campaignInput).then((res) => {
				const campaignId = get(res, 'data.createCampaign.id');
				for (const item of jobIds) {
					onUpdateJob({
						id: item,
						campaignId,
						isHotJob: Boolean(this.state.markHot),
					})
						.then((res) => console.log(`${item} is updated.`))
						.catch((error_) =>
							console.error('Update Job Campaign Error:', error_)
						);
				}

				this.setState({
					success: true,
					jobsCount: 0,
					btnDisabled: false,
					jobError: false,
					selectedJobsByUser: [],
					filteredJobs: [],
					filteredDepartments: [],
					filteredSubCompanies: [],
				});
				setTimeout(() => {
					this.setState({
						success: false,
					});
				}, 3000);
				message.success('Campaign is created successfully.', 5);
				this.getAllCampaignsByCompany();
				this.props.form.resetFields();
			});
		});
	};

	disabledDate(current) {
		// Can not select days before today and today
		return current && current < dayjs().startOf('day');
	}

	editInfo = (campaignId, tieredBonusId) => {
		const { allCampaigns } = this.state;
		const jobs = [];
		const selectCampaigns = allCampaigns.find((item) => item.id === campaignId);
		const jobsData = JSON.parse(selectCampaigns.jobIds);
		if (jobsData) {
			for (const item of jobsData) {
				jobs.push({ value: item.id, label: item.title });
			}
		}

		this.setState({
			edit: true,
			editCampaignId: campaignId,
			editTieredBonusId: tieredBonusId,
			editedJob: jobs,
			editJobReq: false,
			editBonusScheduleReq: false,
			makeHotJob: get(selectCampaigns, 'makeHotJob'),
		});
	};

	endCampaign = async (campaignId, tieredBonusId, multiSelectDefaultValue) => {
		const { onUpdateCampaign, onUpdateJob } = this.props;
		const campaignInput = {
			id: campaignId,
			companyId: get(this.props.currentUser, 'companyId'),
			tieredBonusId,
			archived: true,
			makeHotJob: false,
		};
		onUpdateCampaign(campaignInput).then(async (res) => {
			if (multiSelectDefaultValue.length > 0) {
				for (const item of multiSelectDefaultValue) {
					onUpdateJob({
						id: item.value,
						campaignId: null,
						isHotJob: false,
					})
						.then((res) => console.log(`${item} is updated.`))
						.catch((error) =>
							console.error('Update Job Campaign Error:', error)
						);
				}
			}

			this.setState(
				{
					edit: false,
				},
				() => {
					message.success('Campaign Ended');
					this.getAllCampaignsByCompany();
				}
			);
		});
	};

	handleDateRangeFilter(value) {
		this.setState({
			dateRangeFilterValue: value,
		});
	}

	handleEditDateRangeFilter(value) {
		this.setState({
			editDateRangeFilterValue: value,
		});
	}

	handleFilterDepartment = (department) => {
		const { filteredJobs, filteredDepartments, filteredSubCompanies } =
			this.state;
		if (filteredDepartments.includes(department.value)) return;
		const updated = [...filteredDepartments, department.value];
		this.updateFilterJobs(filteredJobs, filteredSubCompanies, updated);
		this.setState({
			filteredDepartments: updated,
		});
	};

	// Puts each selected job into an array with job id
	handleFilterJobs = async (jobOption) => {
		const { filteredJobs, selectedJobsByUser } = this.state;
		if (filteredJobs.includes(jobOption.value)) return;
		const selectedJobs = get(this.state, 'allJobs', []).filter(
			(item) => item.id === jobOption.value
		);
		const updated = [...selectedJobsByUser, ...selectedJobs];
		const id = selectedJobs.map((item) => {
			return item.id;
		});
		const ids = [...filteredJobs, ...id];
		this.setState({
			filteredJobs: ids,
			selectedJobsByUser: updated,
			onlySelectedJobs: updated,
			jobsCount: updated.length,
			disabled: false,
			jobError: false,
		});
	};

	handleFilterSubCompany = (subCompany) => {
		const { filteredJobs, filteredDepartments, filteredSubCompanies } =
			this.state;
		if (filteredSubCompanies.includes(subCompany)) return;
		const updated = [...filteredSubCompanies, subCompany];
		this.updateFilterJobs(filteredJobs, updated, filteredDepartments);
		this.setState({
			filteredSubCompanies: updated,
		});
	};

	handleHotJobs = (value) => {
		this.setState({
			markHot: value.target.checked,
		});
	};

	handleRemoveFilter = (department) => {
		const { filteredJobs, filteredDepartments, filteredSubCompanies } =
			this.state;
		const index = filteredDepartments.indexOf(department);
		const filterDepartments = (item) => item !== filteredDepartments[index];
		const departments =
			filteredDepartments.length === 1
				? []
				: filteredDepartments.filter(filterDepartments);
		this.updateFilterJobs(filteredJobs, filteredSubCompanies, departments);
		this.setState({
			filteredDepartments: departments,
		});
	};

	handleRemoveJobsFilter = (jobOption) => {
		const {
			selectedJobsByUser,
			filteredJobs,
			filteredDepartments,
			filteredSubCompanies,
		} = this.state;
		const index = filteredJobs.indexOf(jobOption.value);
		const filterJobs = (item) => item !== filteredJobs[index];
		const jobs =
			filteredJobs.length === 1 ? [] : filteredJobs.filter(filterJobs);
		const allSelectedJobs = selectedJobsByUser.filter(
			(item) => item.id !== jobOption.value
		);
		this.setState(
			{
				selectedJobsByUser: allSelectedJobs,
				onlySelectedJobs: jobs,
			},
			() => {
				this.updateFilterJobs(jobs, filteredSubCompanies, filteredDepartments);
			}
		);
		this.setState({
			filteredJobs: jobs,
			disabled: filteredJobs.length === 1,
		});
	};

	handleRemoveSelect = (jobOption) => {
		const { editedJob } = this.state;
		const values = editedJob.filter(
			(item) => !item.value || item.value !== jobOption.value
		);
		this.setState({
			editedJob: values,
		});
	};

	handleRemoveSubCompanyFilter = (subCompany) => {
		const { filteredJobs, filteredDepartments, filteredSubCompanies } =
			this.state;
		const index = filteredSubCompanies.indexOf(subCompany);
		const filterSubCompanies = (item) => item !== filteredSubCompanies[index];
		const subCompanies =
			filteredSubCompanies.length === 1
				? []
				: filteredSubCompanies.filter(filterSubCompanies);

		this.updateFilterJobs(filteredJobs, subCompanies, filteredDepartments);

		this.setState({
			filteredSubCompanies: subCompanies,
		});
	};

	handleSelect = (jobOption) => {
		const { editedJob } = this.state;
		const editedJobReduced = editedJob.reduce((acc, item) => {
			if (
				item.value &&
				item.label &&
				!acc.some((el) => el.value === item.value)
			) {
				acc.push(item);
			}

			return acc;
		}, []);

		const values = [...editedJobReduced, jobOption];
		this.setState({ editedJob: values });
	};

	handleSelectEditTieredBonus = (bonusOption) => {
		this.setState({
			editTieredBonusId: bonusOption.value,
		});
	};

	headers = [
		{ label: 'First Name', key: 'firstName' },
		{ label: 'Last Name', key: 'lastName' },
		{ label: 'Candidate Email', key: 'candidateEmail' },
	];

	selectJobs = () => {
		const { getFieldDecorator } = this.props.form;
		const { departments } = this.props;
		const { allJobs, subCompanies, bonuses, selectedJobsByUser } = this.state;
		const { currentUser, allMultiLingualData } = this.props;

		const sortedJobs = _.sortBy(allJobs, function (job) {
			return job.title;
		});

		const sortedDepartments = _.sortBy(departments, ['name']);
		const departmentOptions = sortedDepartments.map((department) => {
			return { value: department.id, label: department.name };
		});

		const jobsOptions = sortedJobs.map((job) => {
			return {
				value: job.id,
				label: `${job.title}, (req: ${
					job.externalJobId ? job.externalJobId : 'N/A'
				})`,
			};
		});
		const brandsOptions = [];
		brandsOptions.push({ value: 1, label: 'Main Logo (Default)' });
		let sortedSubCompanies = [];
		if (subCompanies.length > 0) {
			sortedSubCompanies = _.sortBy(subCompanies, ['name']);
		}

		sortedSubCompanies.map((brand) => {
			brandsOptions.push({ value: brand.id, label: brand.name });
		});

		const subCompanyOptions = sortedSubCompanies.map((subCompany) => {
			return { value: subCompany.id, label: subCompany.name };
		});

		return (
			<Form>
				<h4 className="campaign-title">
					Step 1. {ml('Select Jobs', currentUser, allMultiLingualData)}
				</h4>
				<p className="campaign-subtitle">
					{ml(
						'Include jobs in the email and have the option to change their bonuses in the next step',
						currentUser,
						allMultiLingualData
					)}
				</p>
				<div className="campaign-form-wrap">
					<div className="bs-form">
						<div className="custom-form-group">
							<label className="bs-form-label">
								{ml(
									'Select Job(s)',
									currentUser,
									allMultiLingualData,
									'Bonus Campaign'
								)}
								:
							</label>
							<div className="custom-form-green-select">
								{getFieldDecorator('jobIds', {
									rules: [],
								})(
									<Select
										labelInValue
										showSearch
										dropdownMatchSelectWidth
										className="custom-input"
										filterOption={(input, option) => {
											return option.label
												?.toLowerCase()
												?.includes(input.toLowerCase());
										}}
										placeholder="None"
										mode="multiple"
										popupClassName="input-tag"
										options={jobsOptions}
										onSelect={(jobOption) => this.handleFilterJobs(jobOption)}
										onDeselect={(jobOption) =>
											this.handleRemoveJobsFilter(jobOption)
										}
									/>
								)}
							</div>
							{this.state.jobError && (
								<div className="input-error">
									Please Select Job(s) Or bulk select job with Subcompany and
									Department filters
								</div>
							)}
						</div>
						<div className="jobs-selected-wrap">
							<div className="jobs-selected">
								{this.state.loaded ? (
									<Spinner height={50} width={50} color={COLORS.darkBlue2} />
								) : (
									<span style={{ fontWeight: 700 }}>
										{this.state.jobsCount}&nbsp;
									</span>
								)}
								{ml('Jobs Selected', currentUser, allMultiLingualData)}
							</div>
							<CSVLink
								filename="jobs.csv"
								data={this.getJobsCSVData()}
								headers={this.JobsCsvheader}
								className="download-link"
								style={{ fontWeight: 700 }}
							>
								{ml('Download List', currentUser, allMultiLingualData)}
							</CSVLink>
						</div>
						<p>
							{ml(
								'or bulk select jobs with these filters',
								currentUser,
								allMultiLingualData
							)}
							:
						</p>
						<div className="custom-form-group">
							<label className="bs-form-label">
								{ml('Jobs in Subcompany', currentUser, allMultiLingualData)}:
							</label>
							<div className="custom-form-green-select">
								{getFieldDecorator('subComp', {
									rules: [],
								})(
									<Select
										labelInValue
										filterOption={(input, option) => {
											return option.label
												.toLowerCase()
												.includes(input.toLowerCase());
										}}
										className="custom-input"
										mode="multiple"
										placeholder={ml('All', currentUser, allMultiLingualData)}
										popupClassName="input-tag"
										options={subCompanyOptions}
										onSelect={(subCompany) =>
											this.handleFilterSubCompany(subCompany)
										}
										onDeselect={(subCompany) =>
											this.handleRemoveSubCompanyFilter(subCompany)
										}
									/>
								)}
							</div>
						</div>
						<div className="custom-form-group">
							<label className="bs-form-label">
								{ml('and in Departments', currentUser, allMultiLingualData)}:
							</label>
							<div className="custom-form-green-select">
								{getFieldDecorator('dept', {
									rules: [],
								})(
									<Select
										labelInValue
										filterOption={(input, option) => {
											return option.label
												.toLowerCase()
												.includes(input.toLowerCase());
										}}
										className="custom-input"
										mode="multiple"
										placeholder={ml('All', currentUser, allMultiLingualData)}
										popupClassName="input-tag"
										options={departmentOptions}
										onSelect={(department) =>
											this.handleFilterDepartment(department)
										}
										onDeselect={(department) =>
											this.handleRemoveFilter(department)
										}
									/>
								)}
							</div>
						</div>
						<div className="custom-form-group custom-checkbox">
							<input
								type="checkbox"
								id="markJobs"
								onChange={this.handleHotJobs}
							/>
							<label className="bs-form-label" htmlFor="markJobs">
								{ml(
									'Mark Job(s) As Hot Job(s)',
									currentUser,
									allMultiLingualData,
									'Bonus Campaign'
								)}
								:
							</label>
						</div>
					</div>
				</div>
			</Form>
		);
	};

	updateCampaign = async (
		campaignId = null,
		tieredBonusId = null,
		job = null,
		multiSelectDefaultValue = null
	) => {
		const {
			editedJob,
			editCampaignId,
			editTieredBonusId,
			editDateRangeFilterValue,
		} = this.state;
		const errors = [];
		const { onUpdateCampaign, onUpdateJob, client } = this.props;
		const campaignInput = {
			id: editCampaignId === null ? campaignId : editCampaignId,
			companyId: get(this.props.currentUser, 'companyId'),
			tieredBonusId:
				editTieredBonusId === null ? tieredBonusId : editTieredBonusId,
			archived: false,
		};
		const jobs = [];
		let jobData = [];
		let selectedValues = [];
		if (job !== null) {
			selectedValues = multiSelectDefaultValue.filter(
				(item) => item.value !== job.value
			);
			if (selectedValues.length > 0) {
				for (const item of selectedValues) {
					jobs.push({ id: item.value, title: item.label });
				}

				campaignInput.jobIds = JSON.stringify(jobs);
			}
		} else if (editedJob.length > 0) {
			for (const item of editedJob) {
				jobs.push({ id: item.value, title: item.label });
			}

			campaignInput.jobIds = JSON.stringify(jobs);
		} else {
			errors.push('Please select Job(s).');
			this.setState({
				editJobReq: true,
			});
		}

		if (!editDateRangeFilterValue?.length) {
			errors.push('Please select Bonus Schedule.');
			this.setState({
				editBonusScheduleReq: true,
			});
		}

		if (errors.length > 0) {
			return;
		}

		this.setState({
			editJobReq: false,
			editBonusScheduleReq: false,
		});

		if (
			editDateRangeFilterValue?.length > 0 &&
			editDateRangeFilterValue[1] !== null
		) {
			const startDate = editDateRangeFilterValue[0];
			const endDate = editDateRangeFilterValue[1];
			campaignInput.startDate = startDate;
			campaignInput.endDate = endDate;
		}

		onUpdateCampaign(campaignInput).then(async (res) => {
			try {
				const { data } = await client.query({
					query: gql(queryJobsByCampaignIdIndex),
					variables: {
						campaignId: editCampaignId === null ? campaignId : editCampaignId,
					},
					fetchPolicy: 'network-only',
				});
				jobData = get(data, 'queryJobsByCampaignIdIndex.items', []);
			} catch (error) {
				console.log(error);
			}

			const newJobs = jobs.filter(this.comparer(jobData));
			const jobsToUpdate = jobData.filter(this.comparer(jobs));
			let makeHotJob = false;
			jobData.forEach(async (item) => {
				makeHotJob = item.isHotJob;
				await onUpdateJob({
					id: item.id,
					isHotJob: Boolean(makeHotJob),
				})
					.then((res) => console.log(`${item} is updated.`))
					.catch((error) => console.error('Update Job Campaign Error:', error));
			});
			if (newJobs.length > 0) {
				for (const item of newJobs) {
					onUpdateJob({
						id: item.id,
						campaignId: editCampaignId === null ? campaignId : editCampaignId,
						isHotJob: Boolean(makeHotJob),
					})
						.then((res) => console.log(`${item} is updated.`))
						.catch((error) =>
							console.error('Update Job Campaign Error:', error)
						);
				}
			}

			if (jobsToUpdate.length > 0) {
				for (const item of jobsToUpdate) {
					onUpdateJob({
						id: item.id,
						campaignId: null,
						isHotJob: false,
					})
						.then((res) => console.log(`${item} is updated.`))
						.catch((error) =>
							console.error('Update Job Campaign Error:', error)
						);
				}
			}

			this.setState(
				{
					edit: false,
				},
				() => {
					message.success('Campaign Edited');
					this.getAllCampaignsByCompany();
				}
			);
		});
	};

	updateFilterJobs(SelectedJobs, SelectedSubCompanies, SelectedDepartments) {
		let filtered = [];
		let myFilteredJobs = [];
		const { onlySelectedJobs } = this.state;
		const sortedJobs = _.sortBy(get(this.state, 'allJobs', []), ['title']);
		if (SelectedSubCompanies.length === 0 && SelectedDepartments.length === 0) {
			filtered = onlySelectedJobs;
		} else {
			filtered = sortedJobs.filter((element) => {
				const subCompanyCheck =
					SelectedSubCompanies.length > 0
						? SelectedSubCompanies.includes(element.subCompanyId)
						: true;
				const DeptCheck =
					SelectedDepartments.length > 0
						? SelectedDepartments.includes(element.departmentId)
						: true;
				return subCompanyCheck && DeptCheck;
			});
		}

		myFilteredJobs = filtered.map((item) => {
			return item.id;
		});
		this.setState({
			filteredJobs: myFilteredJobs,
			selectedJobsByUser: filtered,
			jobsCount: filtered.length,
		});
	}

	renderBonusCampaigns = () => {
		const { allCampaigns, edit, allJobs, editCampaignId, bonuses } = this.state;
		const { allMultiLingualData, currentUser } = this.props;
		const whiteLabel = get(currentUser, 'company.whiteLabel');
		const sortedJobs = _.sortBy(allJobs, function (job) {
			return job.title;
		});
		const jobsOptions = sortedJobs.map((job) => {
			return {
				value: job.id,
				label: `${job.title}, (req: ${
					job.externalJobId ? job.externalJobId : 'N/A'
				})`,
			};
		});
		const sortedBonuses = _.sortBy(bonuses, ['name']);
		const bonusOptions = sortedBonuses.map((bonus) => {
			return { value: bonus.id, label: bonus.name };
		});
		const { RangePicker } = DatePicker;
		const FormItem = Form.Item;
		let editJobSelect = '';
		const okButton = (
			<div className="campaign-confirmation-btn">
				<Popconfirm
					title="Confirm Updates?"
					placement="left"
					okText="Yes"
					cancelText="No"
					onConfirm={() => this.updateCampaign()}
				>
					<Button block type="primary" size="large">
						Save
					</Button>
				</Popconfirm>
				<Popconfirm
					title="Discard edits?"
					placement="left"
					okText="Yes"
					cancelText="No"
					onConfirm={this.cancelEdit}
				>
					<Button ghost block type="primary" size="large">
						Cancel
					</Button>
				</Popconfirm>
			</div>
		);
		if (allCampaigns.length > 0) {
			return allCampaigns.map((campaign) => {
				const { name, jobIds, tieredBonusId, startDate, endDate } = campaign;
				let jobTitle = '';
				let temporaryBonus = '';
				let showStartDate = '';
				let showEndDate = '';
				const multiSelectDefaultValue = [];
				if (!name) return;
				const jobs = JSON.parse(jobIds);
				if (jobs) {
					jobs.map((item) => {
						jobTitle = `${item.title}, (req: ${
							item.externalJobId ? item.externalJobId : 'N/A'
						})`;
						multiSelectDefaultValue.push({
							value: item.id,
							label: item.title,
						});
					});
				}

				if (editCampaignId !== null && editCampaignId === campaign.id) {
					editJobSelect = (
						<>
							<div className="custom-form-green-select">
								<FormItem>
									<Select
										showSearch
										labelInValue
										dropdownMatchSelectWidth
										filterOption={(input, option) => {
											return option.label
												?.toLowerCase()
												?.includes(input.toLowerCase());
										}}
										defaultValue={multiSelectDefaultValue}
										showArrow={false}
										className="custom-input"
										maxTagCount={4}
										maxTagTextLength={10}
										placeholder="None"
										mode="multiple"
										options={jobsOptions}
										onSelect={(jobOption) => this.handleSelect(jobOption)}
										onDeselect={(jobOption) =>
											this.handleRemoveSelect(jobOption)
										}
									/>
								</FormItem>
							</div>
							{this.state.editJobReq && (
								<span className="input-error">Please Select Job(s)</span>
							)}
						</>
					);
				}

				let editTemporaryBonusSelect = '';
				if (tieredBonusId) {
					const tieredBonusName = campaign?.tieredBonus.name ?? '';
					const selectedValue = {
						value: tieredBonusId,
						label: tieredBonusName,
					};
					editTemporaryBonusSelect = (
						<FormItem>
							<Select
								labelInValue
								dropdownMatchSelectWidth
								filterOption={(input, option) => {
									return option.label
										.toLowerCase()
										.includes(input.toLowerCase());
								}}
								className="custom-input"
								defaultValue={selectedValue}
								showArrow={false}
								placeholder="Choose a Bonus"
								options={bonusOptions}
								onSelect={(bonusOption) =>
									this.handleSelectEditTieredBonus(bonusOption)
								}
							/>
						</FormItem>
					);
				}

				const editBonusRange = (
					<>
						<FormItem>
							<RangePicker
								className="ant-picker-480px-mw-no-bg"
								separator={<span>~</span>}
								format="MM-DD-YYYY"
								startdateText="Start date"
								enddateText="End date"
								maxDate={dayjs()}
								defaultValue={[dayjs(startDate), dayjs(endDate)]}
								onChange={(value) => this.handleEditDateRangeFilter(value)}
							/>
						</FormItem>
						{this.state.editBonusScheduleReq && (
							<span className="input-error">Please Select Bonus Schedule</span>
						)}
					</>
				);
				const languageCode = get(currentUser, 'languageCode');
				const dateFormat = get(currentUser, 'dateFormat');
				jobTitle = jobTitle.replace(/,\s*$/, '');
				temporaryBonus = get(campaign, 'tieredBonus.name');
				const stDate = get(campaign, 'startDate');
				showStartDate = formatDate(stDate, languageCode, dateFormat);
				const edDate = get(campaign, 'endDate');
				showEndDate = formatDate(edDate, languageCode, dateFormat);
				const archived = get(campaign, 'archived', false);
				const bonusRange = `${showStartDate}` + `${' - '}` + `${showEndDate}`;
				const today = dayjs();
				const now = dayjs().startOf('day').format('YYYYMMDDHHmmss');
				const x = dayjs(now, 'YYYYMMDDHHmmss');
				const dt = x.format();
				let isActive = '';
				if (today.isBetween(stDate, edDate, 'day', '[]')) {
					isActive = true;
				} else if (dt < stDate && dt < edDate) {
					isActive = true;
				} else {
					isActive = false;
				}

				if (archived) {
					isActive = false;
				}

				return (
					<Collapse
						key={campaign.id + campaign.name}
						bordered={false}
						className="bonus-collapse"
						expandIconPosition="end"
					>
						<Collapse.Panel
							key={campaign.id}
							className="bonus-collapse-card"
							extra={
								<>
									<div className="ac-header-right">
										{isActive && (
											<span className="header-right-text active">
												{ml('Active', currentUser, allMultiLingualData)}
											</span>
										)}
										{!isActive && (
											<span className="header-right-text">
												{ml('Complete', currentUser, allMultiLingualData)}
											</span>
										)}
									</div>
									<div className="bonus-collapse-icon-wrap">
										<i className="icon-arrow-down bonus-collapse-icon" />
									</div>
								</>
							}
							header={<h4>{campaign.name}</h4>}
						>
							<div className="campaign-details">
								<div className="campaign-left">
									{edit === true &&
									editCampaignId !== null &&
									editCampaignId === campaign.id ? (
										<div className="custom-form-group">
											<label>
												{ml('Jobs', currentUser, allMultiLingualData)}:
											</label>

											{editJobSelect}
										</div>
									) : (
										<div className="campaign-tag">
											<p className="tag-label">
												{ml('Jobs', currentUser, allMultiLingualData)}:
											</p>
											<ul className="tag-wrap">
												{this.JobItem(
													campaign.id,
													campaign.tieredBonusId,
													multiSelectDefaultValue
												)}
											</ul>
										</div>
									)}
									{edit === true &&
									editCampaignId !== null &&
									editCampaignId === campaign.id ? (
										<div className="custom-form-group">
											<label>
												{ml(
													'Temporary Bonus',
													currentUser,
													allMultiLingualData
												)}
												:
											</label>
											{editTemporaryBonusSelect}
										</div>
									) : (
										<p className="cl-details secondary">
											<span className="label">
												{ml(
													'Temporary Bonus',
													currentUser,
													allMultiLingualData
												)}
												:
											</span>
											{temporaryBonus}
										</p>
									)}
									{edit === true &&
									editCampaignId !== null &&
									editCampaignId === campaign.id ? (
										<div className="custom-form-group">
											<label>
												{ml(
													'Temporary Bonus Schedule',
													currentUser,
													allMultiLingualData
												)}
												:
											</label>
											{editBonusRange}
										</div>
									) : (
										<p className="cl-details secondary">
											<span className="label">
												{ml(
													'Temporary Bonus Schedule',
													currentUser,
													allMultiLingualData
												)}
												:
											</span>
											{bonusRange}
										</p>
									)}
									{campaign.archived ? null : (
										<Popconfirm
											title="Are you sure?"
											placement="left"
											okText="Yes"
											cancelText="No"
											onConfirm={() =>
												this.endCampaign(
													campaign.id,
													campaign.tieredBonusId,
													multiSelectDefaultValue
												)
											}
										>
											<Button type="danger" disabled={this.state.btnDisabled}>
												{ml('End Campaign', currentUser, allMultiLingualData)}
											</Button>
										</Popconfirm>
									)}
								</div>
								<div className="profile-actions">
									{edit === true &&
									editCampaignId !== null &&
									editCampaignId === campaign.id ? (
										okButton
									) : (
										<Button
											type="primary"
											onClick={() =>
												this.editInfo(campaign.id, campaign.tieredBonusId)
											}
										>
											{ml('Edit', currentUser, allMultiLingualData)}
										</Button>
									)}
								</div>
							</div>
						</Collapse.Panel>
					</Collapse>
				);
			});
		}

		if (this.loading) {
			return (
				<div className="no-content">
					<Spinner unstyled />
				</div>
			);
		}

		return (
			<div className="no-content">
				{whiteLabel ? (
					<img
						className="no-content-icon"
						src={this.state.errorImageURL}
						alt="error image"
					/>
				) : (
					<img className="no-content-icon" alt="erin-logo" src={fileIcon} />
				)}
				<p className="no-content-text">You do not have any bonus campaigns.</p>
			</div>
		);
	};

	render() {
		const { allMultiLingualData, currentUser } = this.props;
		const { subCompanies } = this.state;
		if (!get(currentUser, 'company')) return <Spinner />;
		const brandsOptions = [];
		brandsOptions.push({ value: '1', label: 'Main Logo (Default)' });
		let sortedSubCompanies = [];
		if (subCompanies.length > 0) {
			sortedSubCompanies = _.sortBy(subCompanies, ['name']);
		}

		sortedSubCompanies.map((brand) => {
			brandsOptions.push({ value: brand.id, label: brand.name });
		});

		return (
			<main>
				<div className="page-title">
					<h2 className="page-heading">
						{ml('Create A Bonus Campaign', currentUser, allMultiLingualData)}
					</h2>
				</div>
				<div className="create-bonus-campaigns">
					<div className="row">
						<div className="col-md-6">{this.selectJobs()}</div>
						<div className="col-md-6">{this.bonusSchedule()}</div>
					</div>
					<div className="row">
						<div className="col-md-12">
							{this.state.success ? (
								<Alert
									message="Campaign created successfully."
									type="success"
								/>
							) : null}
							<Button
								type="primary"
								size="large"
								disabled={this.state.btnDisabled}
								onClick={this.createCampaign}
							>
								{ml('Create Campaign', currentUser, allMultiLingualData)}
							</Button>
						</div>
					</div>
				</div>
				<h3 className="bonus-heading">
					{ml('Bonus Campaigns', currentUser, allMultiLingualData)}
				</h3>
				<div className="bonus-campaigns">{this.renderBonusCampaigns()}</div>
			</main>
		);
	}
}

export default withApollo(Form.create()(BonusCampaignsComponent));
