import { message } from 'antd';
import { Component } from 'react';
import { WebNotifications } from '../../../../web-notifications';
import { CompanyName } from './CompanyNameComponent.jsx';
import { Menu } from './MenuComponent.jsx';

class UserProfile extends Component {
	constructor(props) {
		super(props);
		this.state = {
			currentUser: props.currentUser,
		};
	}

	onSelectLanguage = async (countryCode) => {
		const { onUpdate, updateCurrentUserLanguage } = this.props;
		onUpdate({
			input: {
				id: this.state.currentUser.id,
				languageCode: countryCode,
			},
		});
		const updatedCurrentUser = {
			...this.state.currentUser,
			languageCode: countryCode,
		};
		await updateCurrentUserLanguage(updatedCurrentUser);
		message.success('Language set successfully');
	};

	handleClearCache = () => {
		const { client } = this.props;
		client.clearStore();
	};

	handleSignOut = () => {
		this.props.history.push('/logout');
	};

	render() {
		const { currentUser, allMultiLingualData } = this.props;

		const functions = {
			handleSignOut: this.handleSignOut,
			onSelectLanguage: this.onSelectLanguage,
		};

		return (
			<div className="header-right">
				<WebNotifications />
				<CompanyName currentUser={currentUser} />
				<Menu
					currentUser={currentUser}
					allMultiLingualData={allMultiLingualData}
					functions={functions}
				/>
			</div>
		);
	}
}

export default UserProfile;
