import { connect } from 'react-redux';
import { withListReferralQuestions } from 'src/_shared/api/components/referral-questions/';
import { dashboardActions } from 'src/actions.js';
import { USER_ROLES } from '../_shared/constants';
import { compose } from '../_shared/services/utils.js';
import FormBuilderComponent from './FormbuilderComponent.jsx';

const mapStateToProperties = (state) => {
	const { allMultiLingualData } = state.dashboard;
	return {
		currentUser: state.user.currentUser,
		companyId: state.user.currentUser.companyId,
		allMultiLingualData,
		filter: {
			companyId: { eq: state.user.currentUser.companyId },
			role: { eq: USER_ROLES.EMPLOYEE },
		},
		nextToken: null,
		limit: 10_000,
	};
};

const mapDispatchToProperties = (dispatch) => {
	return {
		setMultiLingualData(data) {
			dispatch(dashboardActions.createSetMultiLingualData(data));
		},
	};
};

export const FormBuilderWithApi = compose(withListReferralQuestions)(
	FormBuilderComponent
);

export const FormBuilder = connect(
	mapStateToProperties,
	mapDispatchToProperties
)(FormBuilderWithApi);
