export const queryBonusByUserIdIndex = `query QueryBonusByUserIdIndex(
    $userId: ID!
    $first: Int
    $after: String
  ) {
    queryBonusByUserIdIndex(
      userId: $userId
      first: $first
      after: $after
    ) {
        items {
            id
            amountDue
            companyId
            contactId
            userId
            jobId
            referralId
            hireDate
            startDate
            earnedDate
            payment
            recipientType
            bonusStatus
            notes
          }
          nextToken
    }
  }
  `;
