import { Modal } from 'antd';
import OrganizationModalForm from './GetOrganizationModalPageOneComponent.jsx';

function GetOrganizationModal(props) {
	const { error, theme, visible, page, closeModal, setPageTwo } = props;
	return (
		<Modal
			destroyOnClose
			title="Enter Company Code"
			footer={null}
			open={visible}
			width={500}
			onCancel={closeModal}
			onOk={null}
		>
			{page === 1 && (
				<OrganizationModalForm
					error={error}
					theme={theme}
					setPageTwo={setPageTwo}
					closeModal={closeModal}
					page={page}
				/>
			)}
		</Modal>
	);
}

export default GetOrganizationModal;
