import { css } from 'emotion';
import { COLORS } from 'src/_shared/styles/colors';

export const SelectStyles = css({
	width: '100%',
	'& .ant-select-selection__choice': {
		backgroundColor: COLORS.lightGreen,
		color: COLORS.green,
	},
	'& .ant-select-selection--single': {
		height: 40,
		paddingTop: 4,
		zIndex: 2,
		backgroundColor: 'transparent',
		marginRight: -12,
	},
});
export const SelectRoleStyles = css({
	fontSize: 16,
	height: 40,
	'& .ant-select-selection--single': {
		height: 40,
		paddingTop: 4,
		backgroundColor: 'transparent',
	},
	'& .ant-select': {
		fontFamily: '"Open Sans", sans-serif',
	},
});

export const FlexContainer = css({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	width: '100%',
	color: COLORS.lightGray,
});

export const Container = css({
	display: 'block',
});

export const SortDown = css({});

export const DropDown = css({
	fontFamily: '"Open Sans", sans-serif',
});

export const SelectContainer = css({
	display: 'flex',
	'& svg': {
		transform: 'translate(-30px, 15px)',
	},
	height: 40,
});
