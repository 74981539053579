import getSymbolFromCurrency from 'currency-symbol-map';
import get from 'lodash/get';
import { Component } from 'react';
import { JobLocation } from 'src/_shared/index.js';

class JobCard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			currencyRate: 1,
			currencySymbol: '$',
			job: props.jobs,
			translatedTitle: '',
		};
	}

	async componentDidMount() {
		const currency = get(this.props, 'data.user.currency', 'USD');
		this.getCurrencyRate(currency);
		const { job, currentUser } = this.props;
		const jobTitle = get(job, 'title');
		const newTitle = jobTitle;

		this.setState({
			translatedTitle: newTitle,
		});
	}

	componentDidUpdate(prevProps) {
		if (
			prevProps.currentUser.languageCode !== this.props.currentUser.languageCode
		) {
			const { job, currentUser } = this.props;
			const jobTitle = get(job, 'title');
			const newTitle = jobTitle;
			this.setState({
				translatedTitle: newTitle,
			});
		}
	}

	async getCurrencyRate(currency) {
		const response = await fetch(
			`https://api.exchangeratesapi.io/latest?base=USD&symbols=${currency}`
		);
		const result = await response.json();
		if (result.error) {
			// Make this USD
			this.setState({
				currencyRate: 1,
				currencySymbol: '$',
			});
		} else {
			const rate = result.rates[currency].toFixed(5);
			const symbol = getSymbolFromCurrency(currency);
			this.setState({
				currencyRate: rate ? rate : 1,
				currencySymbol: symbol ? symbol : '$',
			});
		}
	}

	selectJob = () => {
		const { job } = this.props;
		this.props.onSelectJob(job);
	};

	render() {
		const { currentTieredBonus, job, bonusText } = this.props;
		const department = get(job, 'department');
		const title = get(job, 'title');
		const location = get(job, 'location');
		const locations = get(job, 'locations');
		const { translatedTitle } = this.state;
		// Some jobs are null, some jobs have no referral bonus, and some jobs have no amount
		// Only access amount if there is a job and the job has an amount
		let validJob;
		if (
			job &&
			(job.referralBonus || job.tieredBonus) &&
			((job.referralBonus && job.referralBonus.amount) ||
				(job.tieredBonus && job.tieredBonus.name))
		) {
			validJob = job;
		}

		return (
			<div className="select-job-card" onClick={this.selectJob}>
				<h4 className="select-job-title">{translatedTitle}</h4>
				<ul className="select-job-info">
					{department && (
						<li>
							<i className="icon-folder" />
							<span>{department.name}</span>
						</li>
					)}
					<JobLocation
						allMultiLingualData={this.props.allMultiLingualData}
						currentUser={this.props.currentUser}
						location={location}
						locations={locations}
					/>
				</ul>
				<p className="select-job-bonus">
					{bonusText}:
					{/* If there is a current TieredBonus, display that, else display the bonus amount */}
					{currentTieredBonus ? (
						<span className="text-green">
							{this.props.currentTieredBonus.name}
						</span>
					) : (
						<span className="text-green">
							{validJob &&
								(validJob.referralBonus.amount
									? validJob.referralBonus.amount
									: validJob.tieredBonus.name)}
						</span>
					)}
				</p>
			</div>
		);
	}
}

export default JobCard;
