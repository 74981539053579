import gql from 'graphql-tag';
import get from 'lodash/get';
import { compose, graphql } from 'react-apollo';
import {
	GetCompanyPointsData,
	updateCompany,
} from '../../graphql/custom/company';

export const withGetCompanyPointsData = (Component, variables) => {
	return compose(
		graphql(gql(updateCompany), {
			props: (props) => ({
				onUpdateCompany(input) {
					try {
						return props.mutate({
							variables: input,
							refetchQueries: [
								{
									query: GetCompanyPointsData,
									variables: {
										id: get(props, 'ownProps.currentUser.companyId'),
									},
								},
							],
						});
					} catch (error) {
						console.log(error);
					}
				},
			}),
		})
	)(Component);
};
