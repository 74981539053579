import gql from 'graphql-tag';

export const GetCompanySettings = gql`
	query GetCompany($id: ID!) {
		getCompany(id: $id) {
			id
			name
			defaultBonusAmount
			contactIncentiveBonus
			brandColor
			websiteUrl
			sendAdminNotificationsOnReferral
			sendAdminNotificationsOnInternalCandidatesFound
			referralBonusWaitingPeriod
			referralBonusNotificationRecipients
			dashboardReferralPolicyText
			dashboardSearchBG {
				bucket
				region
				key
				__typename
			}
			bonusEarnedNote
			allowSelfReferrals
			allowInternalMobility
			internalMobilityImage {
				bucket
				region
				key
			}
			confirmContactEmails
			employeeDashboard
			employeeDashboardRows
			loginFormPickerSettings
			loginFormSettings
			ignoreSubcompany
			enableGenericReferralEmail
			enableLead
			hideSubcompanyOnSignup
			hideSubcompanyOnJobs
			enableAppReview
			sendReferralColor
			applyInternallyColor
			generalReferralColor
			referralLeadColor
			logo {
				bucket
				region
				key
				__typename
			}
			shareLogo {
				bucket
				region
				key
				__typename
			}
			disableSmartReferrals
			disableSAMLLogin
			disableClaimYourAccountLogin
			emailSettings
			confirmCompliance
			ssoGoogleDomain
			theme
			symbol {
				bucket
				region
				key
				__typename
			}
			background {
				bucket
				region
				key
				__typename
			}
			errorImage {
				bucket
				region
				key
				__typename
			}
			socialImage {
				bucket
				region
				key
				__typename
			}
			whiteLabel
			userEditSettings
			showPoweredByErin
			enableDashboardSearch
			hideJobsPage
			hideRecognitionSocialShare
			hideRecognitionLeaderboard
			enableGeneralReferrals
			enableProspectCreation
			enableAcceptedReferralReminder
			referralStatus
			referralCustomStatuses
			requireBonusChangeReason
			helpUrl
			privacyUrl
			recognitionCustomTitle
			requireBadgeSelection
			termsUrl
			linkUrl
			linkName
			allowSelfReferralsInternalLink
			accountType
			automationSettings
			ownershipSettings
			bonusReportSetting
			jobNotificationFrequency
			jobNotificationSetting
			disableExtendedUserJobsView
			eligibiltyChecks
			enableExtendedNetwork
			enableCareerProfile
			enableJobMatching
			enableJobNotifications
			enableRetroactiveBonuses
			enableRecognition
			disableShareLink
			disableNewReferralSMSNotification
			externalUserSignUp
			hideLoginForm
			disableReferrals
			disableManagerPermissions
			disableManagerPermissionsByType
			sftpFolderName
			otherAPIKey
			senderEmailAddress
			sendNewJobsToAccountClaims
			whiteLabelServiceName
			hideBonus
			hideDateCreatedJobDetails
			includeAdminInRanking
			appStoreUrls {
				ios
				android
			}
			disableSite
			hideInterestedForGroup
			hideShareLinkForDepartment
			hideShareLinkNoPublicUrl
			hideDateCreatedJobDetails
			hideBonusFilterOnBrowseJobs
			subCompanyLabel
			labelDepartment
			labelSocialShare
			isOptoutReferralUpdates
			stages
			defaultLocation
			labelEmployeeID
			favicon {
				bucket
				region
				key
				__typename
			}
			faviconTitle
			selectedAdmins
			publicLeaderboard
			platformConfig
			hideReferralsRanking
			enableCustomPage
			customPageTitle
			enableCustomPage2
			customPageTitle2
			enableCustomPage3
			customPageTitle3
			pointsSettings
			popupTitle
			popupTitleContent
			hideWhatsApp
			hideRecruiterInfo
			hideRecruiterInfoJobDetails
			hideMessageRecruiter
			hidePaymentInfoJobCard
			resetPopup
			defaultToSubcompany
			referredReportTo
			internalMobilityCustomTitle
			onboarding
			partnerClientId
			partnerHostId
			whiteLabelSettings
			partnerHost {
				id
				name
				partnerBillingPolicy
				brandColor
			}
			payBonusesWithPoints
			enableReferralNetworkShare
			referralNetworkShareOptions
			bonusReportFrequency
			employeeReportFrequency
			referralsReportFrequency
			referralsReportDateRange
			host
			countryCode
			customText
		}
	}
`;
