import { itemChildren, subTitleStyle } from './pagesStyles.js';

export function PageItem(props) {
	const { title, subtitle, children, optional } = props;
	return (
		<div>
			{optional && <span className={subTitleStyle}> (optional) </span>}
			{subtitle && <span className={subTitleStyle}> {subtitle} </span>}
			<div className={itemChildren}> {children} </div>
		</div>
	);
}
