import gql from 'graphql-tag';

export const GetJobForMatch = gql`
	query GetJob($id: ID!) {
		getJob(id: $id) {
			id
			companyId
			company {
				id
				name
			}
			subCompanyId
			subCompany {
				id
				name
			}
			title
			location
			locations
			departmentId
			department {
				id
				name
			}
			hideImInterested
			internalJobLink
			isGeneralReferral
			status
			referrals {
				id
				companyId
				company {
					id
					name
				}
				contactId
				userId
				jobId
				status
			}
		}
	}
`;
