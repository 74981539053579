import axios from 'axios';
import { Cache } from 'aws-amplify';
import Cookies from 'js-cookie';
import { createContext, useContext } from 'react';

const APIServiceContext = createContext(null);

export const RnRAPIServiceProvider = (props) => {
	const { REACT_APP_RNR_BASE_PATH } = process.env;
	const jwt = Cache.getItem('federatedToken')
		? Cache.getItem('federatedToken')?.access_token
		: Cookies.get('jwt');

	// axios.interceptors.response.use(
	//   (response) => {
	//     return response;
	//   },
	//   (error) => {
	//     if (error.response) {
	//       return Promise.reject(error);
	//     } else {
	//       error.response = {
	//         data: {
	//           errorMessage: error.message,
	//           isError: true,
	//         },
	//       };
	//       return Promise.reject(error);
	//     }
	//   }
	// );

	const get = async (url, body) => {
		try {
			const response = await axios.get(REACT_APP_RNR_BASE_PATH + url, {
				params: body,
				headers: { Authorization: `Bearer ${jwt}` },
			});
			return await Promise.resolve(response ? response.data : response);
		} catch (error) {
			return error
				? error.response
					? error.response.data
					: error.response
				: error;
		}
	};

	const post = async (url, body) => {
		try {
			const response = await axios.post(REACT_APP_RNR_BASE_PATH + url, body, {
				headers: { Authorization: `Bearer ${jwt}` },
			});
			return await Promise.resolve(response ? response.data : response);
		} catch (error) {
			return error
				? error.response
					? error.response.data
					: error.response
				: error;
		}
	};

	const put = async (url, body) => {
		try {
			const response = await axios.put(REACT_APP_RNR_BASE_PATH + url, body, {
				headers: { Authorization: `Bearer ${jwt}` },
			});
			return await Promise.resolve(response ? response.data : response);
		} catch (error) {
			return error
				? error.response
					? error.response.data
					: error.response
				: error;
		}
	};

	const remove = async (url, body) => {
		try {
			const response = await axios.delete(
				REACT_APP_RNR_BASE_PATH + url,
				{ data: body },
				{ headers: { Authorization: `Bearer ${jwt}` } }
			);
			return response;
		} catch (error) {
			return error.response;
		}
	};

	const postFormData = async (url, body) => {
		try {
			const response = await axios.post(REACT_APP_RNR_BASE_PATH + url, body, {
				headers: {
					Authorization: `Bearer ${jwt}`,
					'content-type': 'multipart/form-data',
				},
			});
			return await Promise.resolve(response ? response.data : response);
		} catch (error) {
			return error
				? error.response
					? error.response.data
					: error.response
				: error;
		}
	};

	const putFormData = async (url, body) => {
		try {
			const response = await axios.put(REACT_APP_RNR_BASE_PATH + url, body, {
				headers: {
					Authorization: `Bearer ${jwt}`,
					'content-type': 'multipart/form-data',
				},
			});
			return await Promise.resolve(response ? response.data : response);
		} catch (error) {
			return error
				? error.response
					? error.response.data
					: error.response
				: error;
		}
	};

	const ApiOperations = {
		get,
		post,
		put,
		remove,
		postFormData,
		putFormData,
	};

	return (
		<APIServiceContext.Provider value={ApiOperations}>
			{props.children}
		</APIServiceContext.Provider>
	);
};

export const useRnRAPIService = () => {
	return useContext(APIServiceContext);
};
