import { Avatar } from 'antd';
import _ from 'lodash';
import get from 'lodash/get';
import { Component } from 'react';
import Spinner from 'src/_shared/components/spinner/SpinnerComponent.jsx';
import { downloadFromS3Signed } from 'src/_shared/services/utils.js';
import WebNotificationList from '../web-notification-list/WebNotificationListComponent.jsx';
import { Bold } from '../webNotificationsStyles.js';

class WebNotificationsPageComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			company: get(props, 'currentUser.company'),
			userNotifications: props.userNotifications,
		};
	}

	async componentDidMount() {
		const avatarSource = await downloadFromS3Signed(
			this.props.currentUser?.avatar?.key
		);
		this.setState({ avatarSrc: avatarSource });
	}

	componentDidUpdate(prevProps) {
		if (prevProps.userNotifications !== this.props.userNotifications) {
			this.setState({ userNotifications: this.props.userNotifications });
		}
	}

	newNotificationCount = () => {
		const { userNotifications } = this.state;
		const { currentUser } = this.props;
		let count = 0;
		if (userNotifications) {
			for (const notification of userNotifications) {
				if (!currentUser.lastNotificationCheck) {
					count = userNotifications.length;
				} else if (
					notification.dateCreated > currentUser.lastNotificationCheck
				) {
					count += 1;
				}
			}

			return count;
		}

		return count;
	};

	render() {
		const { avatar, firstName, lastName } = this.props.currentUser;
		const { avatarSrc, company, userNotifications } = this.state;
		if (!userNotifications) {
			return <Spinner company={company} />;
		}

		const sortedNotifications = _.sortBy(
			userNotifications,
			'dateCreated'
		).reverse();

		return (
			<div className="notification-wrap">
				<div className="notification-header">
					{!avatar || avatar === '' ? (
						<div className="custom-image-wrap">
							{firstName && firstName[0]}
							{firstName && lastName[0]}
						</div>
					) : (
						<div className="custom-image-wrap">
							<Avatar
								src={avatar ? avatarSrc : null}
								shape="circle"
								className="custom-img"
							/>
						</div>
					)}
					<div className="notification-header-text">
						<h2 className="nht-name">
							{firstName && firstName} {firstName && lastName}
						</h2>
						<p className="nht-count">
							You have{' '}
							<span className={Bold}>{this.newNotificationCount()}</span> new
							notification(s)
						</p>
					</div>
				</div>
				<WebNotificationList
					currentUser={this.props.currentUser}
					sortedNotifications={sortedNotifications}
					dropDown={false}
				/>
			</div>
		);
	}
}

export default WebNotificationsPageComponent;
