import { CloseCircleOutlined } from '@ant-design/icons';
import { Popconfirm, Row } from 'antd';
import {
	RecipientsContainer,
	closeIcon,
	recipientContainer,
	recipientText,
} from './subCompaniesListStyles.js';

export function ListItems(props) {
	const { data, deleteItem } = props;

	return (
		<div className={RecipientsContainer}>
			{(data || []).map((item, index) => {
				return (
					<div key={index}>
						<Row className={recipientContainer}>
							<span className={recipientText}> {item} </span>
							<Popconfirm
								placement="left"
								title="Are you sure？"
								okText="Delete"
								cancelText="Cancel"
								onConfirm={() => deleteItem(item)}
							>
								<CloseCircleOutlined className={closeIcon} />
							</Popconfirm>
						</Row>
					</div>
				);
			})}
		</div>
	);
}
