import { createAction } from '../../helpers';

export const FETCH_REFERRALS = 'Referral/FETCH_REFERRALS';
export const SET_CURRENT_REFERRAL = 'Referral/SET_CURRENT_REFERRAL';
export const UPDATE_REFERRAL = 'Referral/UPDATE_REFERRAL';
export const UPDATE_REFERRALS_NOT_HIRED = 'Referral/UPDATE_REFERRALS_NOT_HIRED';

export const fetchReferrals = createAction(FETCH_REFERRALS);
export const selectReferral = createAction(SET_CURRENT_REFERRAL);
export const updateReferral = createAction(UPDATE_REFERRAL);
export const updateReferralsNotHired = createAction(UPDATE_REFERRALS_NOT_HIRED);
