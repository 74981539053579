import { css } from 'emotion';
import { COLORS } from '../../../_shared/styles/colors';

export const headerClass = css({
	textTransform: 'uppercase',
	fontSize: 16,
	letterSpacing: 2,
	fontWeight: 300,
	color: `${COLORS.heading} !important`,
	paddingBottom: 5,
});

export const showingLess = css({
	position: 'relative',
	maxHeight: 260,
	overflow: 'hidden',
});

export const textFade = css({
	position: 'absolute',
	right: 0,
	bottom: 0,
	left: 0,
	height: 60,
	background: 'linear-gradient(rgba(255,255,255,0),white)',
	borderRadius: '0 0 10px 10px',
});
