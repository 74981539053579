import { Message, message } from 'antd';
import { useEffect, useState } from 'react';
import DashboardPresentation from '../../presentation/dashboardPresentation/index.js';
import { useHomeService } from '../../service/home.services.jsx';

import useGetFeedList from '../../container/hooks/useGetFeedList.js';

const DashboardContainer = () => {
	const [isOpenRewardModel, setIsOpenRewardModel] = useState(false);
	const [sendrewardObject, setSendRewardObject] = useState({});
	const [isShoutOutSend, setIsShoutOutSend] = useState(false);
	const [isEmployeeSelected, setIsEMployeeSelected] = useState(false);
	const [pageLimit] = useState(10);

	const [isDataRefresh, setIsDataReferash] = useState(false);

	const homeAPIService = useHomeService();

	const {
		getFeedData,
		isLoadMoreCalled,
		hasMoreData,
		feedListData,
		setFeedListData,
		isFeedLoading,
		setIsFeedLoading,
		currentPage,
		setCurrentPage,
	} = useGetFeedList({ myBranch: false });

	const {
		getFeedData: getMyBranchFeedData,
		isLoadMoreCalled: isMyBranchLoadMoreCalled,
		hasMoreData: myBranchHasMoreData,
		feedListData: myBranchFeedListData,
		setFeedListData: setMyBranchFeedListData,
		isFeedLoading: isMyBranchFeedLoading,
		setIsFeedLoading: setIsMyBranchFeedLoading,
		currentPage: myBranchcurrentPage,
		setCurrentPage: setMyBranchCurrentPage,
	} = useGetFeedList({ myBranch: true });

	const {
		getFeedData: getTopFeedData,
		isLoadMoreCalled: isTopLoadMoreCalled,
		hasMoreData: topHasMoreData,
		feedListData: topFeedListData,
		setFeedListData: setTopFeedListData,
		isFeedLoading: isTopFeedLoading,
		setIsFeedLoading: setIsTopFeedLoading,
		currentPage: topcurrentPage,
		setCurrentPage: setTopCurrentPage,
	} = useGetFeedList({ top: true });

	const fetchInitialData = (isNewPost = false) => {
		setIsFeedLoading(true);
		setIsMyBranchFeedLoading(true);
		setIsTopFeedLoading(true);
		setCurrentPage(1);
		getFeedData(1, pageLimit, isNewPost);
		getMyBranchFeedData(1, pageLimit, isNewPost);
		getTopFeedData(1, pageLimit, isNewPost);
	};

	useEffect(() => {
		getTemplateData();
		fetchInitialData();
	}, []);

	const fetchMoreData = () => {
		setCurrentPage(currentPage + 1);
		getFeedData(currentPage + 1, pageLimit);
	};

	const fetchMyBranchMoreData = () => {
		setMyBranchCurrentPage && setMyBranchCurrentPage(myBranchcurrentPage + 1);
		getMyBranchFeedData(myBranchcurrentPage + 1, pageLimit);
	};

	const fetchTopMoreData = () => {
		setTopCurrentPage && setTopCurrentPage(topcurrentPage + 1);
		getTopFeedData(topcurrentPage + 1, pageLimit);
	};

	const getTemplateData = () => {
		homeAPIService
			.getRecentActivityProperties({ property: 'template' })
			.then((response) => {
				if (response.code === 200) {
					let data = response?.data;
					let recentActivityTemplateData = JSON.stringify(data);
					localStorage.setItem('template', recentActivityTemplateData);
				}
			})
			.catch((error) => {
				console.error('Error fetching template data:', error);
			});
	};

	const handleSendShoutout = async () => {
		if (
			sendrewardObject?.employeeDetail === undefined ||
			sendrewardObject?.employeeDetail === null
		) {
			setIsEMployeeSelected(true);
			return;
		}
		if (
			(!sendrewardObject?.content ||
				sendrewardObject?.content?.message?.trim().length === 0) &&
			!sendrewardObject?.rewardtype
		) {
			Message.error('Please add badge or message to assign.');
			return;
		}
		const sendShouOut = {
			badgeId: sendrewardObject?.rewardtype,
			employeeId: sendrewardObject?.employeeDetail,
			content: sendrewardObject?.content,
		};
		setIsShoutOutSend(true);

		homeAPIService
			.createBadgeAssignment(sendShouOut)
			.then((response) => {
				if (response.code === 201) {
					setIsShoutOutSend(false);
					Message.success(response?.message);
					setSendRewardObject(null);
					fetchInitialData(true);
					setIsDataReferash(true);
				} else {
					setIsShoutOutSend(false);
					setSendRewardObject(null);
				}
			})
			.catch((error) => {
				message.error('Failed to create badge! Try again later.');
			});
	};

	return (
		<div>
			<DashboardPresentation
				sendrewardObject={sendrewardObject}
				setSendRewardObject={setSendRewardObject}
				isOpenRewardModel={isOpenRewardModel}
				setIsOpenRewardModel={setIsOpenRewardModel}
				isShoutOutSend={isShoutOutSend}
				setIsShoutOutSend={setIsShoutOutSend}
				handleSendShoutout={handleSendShoutout}
				isEmployeeSelected={isEmployeeSelected}
				setIsEMployeeSelected={setIsEMployeeSelected}
				feedListData={feedListData}
				isFeedLoading={isFeedLoading}
				setFeedListData={setFeedListData}
				fetchMoreData={fetchMoreData}
				isLoadMoreCalled={isLoadMoreCalled}
				hasMoreData={hasMoreData}
				getTemplateData={getTemplateData}
				isDataRefresh={isDataRefresh}
				setIsDataReferash={setIsDataReferash}
				isMyBranchLoadMoreCalled={isMyBranchLoadMoreCalled}
				myBranchHasMoreData={myBranchHasMoreData}
				myBranchFeedListData={myBranchFeedListData}
				isMyBranchFeedLoading={isMyBranchFeedLoading}
				setMyBranchFeedListData={setMyBranchFeedListData}
				fetchMyBranchMoreData={fetchMyBranchMoreData}
				isTopLoadMoreCalled={isTopLoadMoreCalled}
				topHasMoreData={topHasMoreData}
				topFeedListData={topFeedListData}
				isTopFeedLoading={isTopFeedLoading}
				setTopFeedListData={setTopFeedListData}
				fetchTopMoreData={fetchTopMoreData}
			/>
		</div>
	);
};
export default DashboardContainer;
