import gql from 'graphql-tag';
import get from 'lodash/get';
import { compose, graphql } from 'react-apollo';
import { queryReferralsByUserIdIndex } from '../../graphql/custom/referrals';

export const withReferralCount = (Component) => {
	return compose(
		graphql(gql(queryReferralsByUserIdIndex), {
			options: (props) => ({
				context: {
					headers: {
						'x-frame-options': 'deny', // This header will reach the server
					},
				},
				variables: {
					userId: get(props, 'userId'),
				},
				fetchPolicy: 'cache-and-network',
			}),
			props(response, previous) {
				const referrals = get(
					response,
					['data', 'queryReferralsByUserIdIndex', 'items'],
					get(previous, 'referrals', [])
				);
				const referralCount = referrals.length;

				return { referralCount };
			},
		})
	)(Component);
};
