export const queryAnnouncementsByCompanyIdIndex = `query QueryAnnouncementsByCompanyIdIndex(
    $companyId: ID!
    $first: Int
    $after: String
  ) {
    queryAnnouncementsByCompanyIdIndex(
      companyId: $companyId
      first: $first
      after: $after
    ) {
        items {
            id
            companyId
            title
            content
            dateCreated
            hide
            userGroups
            displayGroupSelect
          }
          nextToken
    }
  }
  `;
