import { Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import WebIcon from 'src/_shared/components/WebIconComponent.jsx';
import {
	downloadFromS3Signed,
	formatPhoneNumber,
} from 'src/_shared/services/utils.js';

function SocialMedia(props) {
	const { email, socialMedia, phone, message, contactResume } = props;
	const [resumeUrl, setResumeUrl] = useState('');
	const [formattedPhoneNumber, setFormattedPhoneNumber] = useState(null);

	useEffect(() => {
		let active = true;

		const fetchData = async () => {
			if (contactResume) {
				const presignedUrl = await downloadFromS3Signed(
					contactResume.key,
					contactResume.bucket
				);
				if (active) {
					setResumeUrl(presignedUrl);
				}
			}
		};

		fetchData();
		return () => {
			active = false;
		};
	}, [contactResume]);

	useEffect(() => {
		if (phone) {
			setFormattedPhoneNumber(formatPhoneNumber(phone));
		}
	}, [phone]);

	const renderSocialMedia = () => {
		// TODO: remove JSON.parse when socialMedia data is fixed
		return JSON.parse(socialMedia).map((record) => {
			if (record.network === 'linkedin' && record.link !== null) {
				return (
					<a
						key={record.profile}
						href={`${record.profile}`}
						target="_blank"
						rel="noopener noreferrer"
					>
						<WebIcon name="linked-in" size={props.size || 14} />
					</a>
				);
			}

			if (record.network === 'facebook' && record.link !== null) {
				return (
					<a
						key={record.profile}
						href={`${record.profile}`}
						target="_blank"
						rel="noopener noreferrer"
					>
						<WebIcon name="facebook-logo" size={props.size || 14} />
					</a>
				);
			}

			if (record.network === 'twitter' && record.profile !== null) {
				return (
					<a
						key={record.profile}
						href={`${record.profile}`}
						target="_blank"
						rel="noopener noreferrer"
					>
						<WebIcon name="twitter" size={props.size || 14} />
					</a>
				);
			}

			return null;
		});
	};

	return (
		<div className="social-wrap">
			{email ? (
				<a href={`mailto:${email}`}>
					<i className="icon-envelope-outline" style={{ fontSize: 18 }} />
				</a>
			) : null}
			{socialMedia ? renderSocialMedia() : null}
			{formattedPhoneNumber ? (
				<Tooltip
					arrowPointAtCenter
					placement="top"
					title={formattedPhoneNumber}
					style={{ fontWeight: 1000 }}
				>
					<i className="icon-telephone-outline" style={{ fontSize: 18 }} />
				</Tooltip>
			) : null}
			{message ? (
				<Tooltip placement="top" title={message} style={{ fontWeight: 1000 }}>
					<i className="icon-message-outline" style={{ fontSize: 18 }} />
				</Tooltip>
			) : null}
			{contactResume && contactResume.key !== null ? (
				<Tooltip
					placement="top"
					title="Download Resume"
					style={{ fontWeight: 1000 }}
				>
					<a target="_blank" href={resumeUrl} rel="noreferrer">
						<i className="icon-application" />
					</a>
				</Tooltip>
			) : null}
		</div>
	);
}

export default SocialMedia;
