import { PureComponent, createRef } from 'react';
import { withApollo } from 'react-apollo';

class DashboardNew extends PureComponent {
	render() {
		return <></>;
	}
}

export default withApollo(DashboardNew);
