import gql from 'graphql-tag';
import get from 'lodash/get';
import { compose, graphql } from 'react-apollo';
import uuid from 'uuid/v4';
import {
	createContact,
	deleteContact,
	listOnDeckContacts,
	updateContact,
} from '../../graphql/custom/contacts';

export const withListMyReferralsContacts = (Component) => {
	return compose(
		graphql(gql(listOnDeckContacts), {
			options: (props) => ({
				context: {
					headers: {
						'x-frame-options': 'deny', // This header will reach the server
					},
				},
				variables: {
					filter: {
						companyId: { eq: props.currentUser.companyId },
						onDeckStatus: { gt: 0 },
						userId: { eq: props.currentUser.id },
					},
					nextToken: props.onDeckNextToken ? props.onDeckNextToken : null,
				},
				fetchPolicy: 'cache-and-network',
			}),
			props(response, props) {
				const onDeckContacts = get(
					response,
					['data', 'listOnDeckContacts', 'items'],
					get(props, 'contacts', [])
				);
				const onDeckNextToken = get(
					response,
					['data', 'listOnDeckContacts', 'nextToken'],
					null
				);
				const onFetchMoreOnDeckContacts = makeOnFetchMoreOnDeckContacts(
					response.data.fetchMore,
					onDeckNextToken
				);

				return { onDeckContacts, onFetchMoreOnDeckContacts, onDeckNextToken };
			},
		}),
		graphql(gql(createContact), {
			props: (props) => ({
				ImportedCreateContact(input) {
					const optimisticResponseData = {
						id: uuid(),
						...input.input,
						referrals: null,
						__typename: 'Contact',
					};
					return props.mutate({
						context: {
							headers: {
								'x-frame-options': 'deny', // This header will reach the server
							},
						},
						variables: input,
						optimisticResponse: {
							__typename: 'Mutation',
							createContact: {
								__typename: 'createContact',
								...optimisticResponseData,
							},
						},
						update(proxy, { data: { createContact } }) {
							const data = proxy.readQuery({
								query: gql(listOnDeckContacts),
								variables: {
									filter: {
										companyId: { eq: props.ownProps.currentUser.companyId },
										onDeckStatus: { gt: 0 },
									},
									limit: 10_000,
									nextToken: get(props, 'ownProps.onDeckNextToken', null),
								},
								fetchPolicy: 'cache-and-network',
							});
							if (
								!data.listOnDeckContacts.items.find(
									(contact) => contact.id === createContact.id
								)
							) {
								data.listOnDeckContacts.items.push(createContact);
							}

							proxy.writeQuery({
								query: gql(listOnDeckContacts),
								context: {
									headers: {
										'x-frame-options': 'deny', // This header will reach the server
									},
								},
								variables: {
									filter: {
										companyId: { eq: props.ownProps.currentUser.companyId },
										onDeckStatus: { gt: 0 },
									},
									limit: 10_000,
									nextToken: get(props, 'ownProps.onDeckNextToken', null),
								},
								fetchPolicy: 'cache-and-network',
								data,
							});
						},
					});
				},
			}),
		}),
		graphql(gql(updateContact), {
			props: (props) => ({
				onUpdateContact(input) {
					const optimisticResponseData = {
						...input,
						__typename: 'Contact',
					};
					return props.mutate({
						context: {
							headers: {
								'x-frame-options': 'deny', // This header will reach the server
							},
						},
						variables: { input: { ...input } },
						optimisticResponse: {
							__typename: 'Mutation',
							updateContact: {
								__typename: 'updateContact',
								...optimisticResponseData,
							},
						},
						update(proxy, { data: { updateContact } }) {
							const data = proxy.readQuery({
								query: gql(listOnDeckContacts),
								context: {
									headers: {
										'x-frame-options': 'deny', // This header will reach the server
									},
								},
								variables: {
									filter: {
										companyId: {
											eq: get(
												props,
												['ownProps', 'currentUser', 'companyId'],
												get(props, ['ownProps', 'currentUser', 'company', 'id'])
											),
										},
										onDeckStatus: { gt: 0 },
									},
									limit: 10_000,
									nextToken: get(props, 'ownProps.onDeckNextToken', null),
								},
								fetchPolicy: 'cache-and-network',
							});
							proxy.writeQuery({
								query: gql(listOnDeckContacts),
								context: {
									headers: {
										'x-frame-options': 'deny', // This header will reach the server
									},
								},
								variables: {
									filter: {
										companyId: {
											eq: get(
												props,
												['ownProps', 'currentUser', 'companyId'],
												get(props, ['ownProps', 'currentUser', 'company', 'id'])
											),
										},
										onDeckStatus: { gt: 0 },
									},
									nextToken: get(props, 'ownProps.onDeckNextToken', null),
								},
								fetchPolicy: 'cache-and-network',
								data,
							});
						},
					});
				},
			}),
		}),
		graphql(gql(deleteContact), {
			props: (props) => ({
				onDeleteContact(input) {
					const optimisticResponseData = {
						...props.contact,
						...input.input,
					};
					props.mutate({
						variables: input,
						optimisticResponse: {
							__typeName: 'Mutation',
							deleteContact: {
								...optimisticResponseData,
							},
						},
						update(proxy, { data: { deleteContact } }) {
							const data = proxy.readQuery({
								query: gql(listOnDeckContacts),
								context: {
									headers: {
										'x-frame-options': 'deny', // This header will reach the server
									},
								},
								variables: {
									companyId: props.ownProps.currentUser.company.id,
									first: 2000,
									after: '',
								},
							});
							data.listOnDeckContacts.items =
								data.listOnDeckContacts.items.filter(
									(contact) => contact.id !== deleteContact.id
								);
							proxy.writeQuery({
								query: gql(listOnDeckContacts),
								data,
								context: {
									headers: {
										'x-frame-options': 'deny', // This header will reach the server
									},
								},
								variables: {
									companyId: props.ownProps.currentUser.company.id,
									first: 2000,
									after: '',
								},
							});
						},
					});
				},
			}),
		})
	)(Component);
};

const makeOnFetchMoreOnDeckContacts = (fetchMore, nextToken) => {
	if (!nextToken) {
		return null;
	}

	return () => {
		fetchMore({
			variables: { nextToken },
			updateQuery(previous, { fetchMoreResult }) {
				if (!fetchMoreResult) {
					return previous;
				}

				return {
					...previous,
					loading: false,
					listOnDeckContacts: {
						...previous.listOnDeckContacts,
						...fetchMoreResult.listOnDeckContacts,
						items: [
							...previous.listOnDeckContacts.items,
							...fetchMoreResult.listOnDeckContacts.items,
						],
					},
				};
			},
		});
	};
};
