import { Card, Skeleton } from 'antd';

export function JobCardSkeleton({
    isHotJob = false,
}) {

	return (
		<Card
				bordered={false}
				bodyStyle={{ padding: 0, height: '100%' }}
				className="job-card"
				style={isHotJob ? { borderColor: '#f7a34d' } : {}}
			>
				<div className="job-card-inner">
                    <div className="job-card-header" style={{ marginBottom: 0 }}>
                        <div className="job-title-wrap">
                            <Skeleton className="job-title" active={true} paragraph={{ rows: 1 }} />
                            <ul className="actions">
                                <li>
                                    <Skeleton.Avatar active={true} size={'small'} shape={'circle'} />
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="job-card-footer">
                        <Skeleton.Button active={true} block={true} shape={'round'} size={'large'} />
                        <Skeleton.Button active={true} block={true} shape={'round'} size={'large'} />
                    </div>
                </div>
        </Card>
	);
}
