import dayjs from 'dayjs';
import get from 'lodash/get';
import { Component } from 'react';
import {
	containerClass,
	textEmphasisClass,
	tierItemClass,
	tieredListClass,
} from './tieredBonusStyles.js';

export default class TieredBonusComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			referral: props.referral,
		};
	}

	componentDidUpdate(prevProps) {
		const { referral } = this.props;
		if (prevProps.referral !== referral) {
			this.setState({ referral });
		}
	}

	render() {
		const {
			currentTieredBonus,
			status,
			userGroup,
			currentCurrencyRate,
			currentCurrencySymbol,
		} = this.props;
		const { referral } = this.state;
		const symbol = currentCurrencySymbol ? currentCurrencySymbol : '$';
		const rate = currentCurrencyRate ? currentCurrencyRate : 1;
		if (!currentTieredBonus) return null;
		let bonusAmount = 0;
		const referralStatus = get(referral, 'status');
		const bonuses = get(referral, 'bonuses', []).sort(function (a, b) {
			return a.earnedDate < b.earnedDate
				? -1
				: a.earnedDate > b.earnedDate
					? 1
					: 0;
		});
		const items = bonuses.map((bonus) => {
			bonusAmount += Number.parseInt(get(bonus, 'amountDue', 0) * rate, 10);
			const earnedDate = get(bonus, 'earnedDate');
			const showEmphasis = dayjs().isAfter(earnedDate);
			const item = referralStatus === 'hired' && (
				<li key={get(bonus, 'id')} className={tierItemClass}>
					<span className={textEmphasisClass(showEmphasis, true)}>
						{`${symbol}`}
						{Number.parseInt(get(bonus, 'amountDue') * rate)}
					</span>
					<span className={textEmphasisClass(showEmphasis)}>
						{get(bonus, 'recipientType')}
					</span>
					<span className={textEmphasisClass(showEmphasis)}>
						{dayjs(earnedDate).format('MM/DD/YYYY')}
					</span>
				</li>
			);

			return item;
		});

		const groupTiers = get(currentTieredBonus, 'tiers').filter((tier) => {
			let checkTier;
			if (typeof tier === 'string') checkTier = JSON.parse(tier);
			return checkTier.userGroup === userGroup;
		});
		const tierCount = groupTiers.length;
		const paddingLeft = referralStatus === 'hired' ? 0 : 20;
		const marginTop =
			referralStatus !== 'hired' || get(referral, 'bonuses', []).length === 0
				? 20
				: 0;
		function BonusSummary() {
			return (
				<div
					style={{
						paddingLeft,
						marginTop,
					}}
				>
					<span className={textEmphasisClass(true)}>Tiered Bonus:</span>
					{get(referral, 'bonuses', []).length > 0 &&
					get(referral, 'status') === 'hired' ? (
						<div>{`${symbol}${bonusAmount} / ${tierCount} Payments`}</div>
					) : (
						<span style={{ fontStyle: 'italic', marginLeft: 10 }}>
							{get(currentTieredBonus, 'name')}
						</span>
					)}
				</div>
			);
		}

		return (
			<div className={containerClass}>
				<BonusSummary />
				<ul className={tieredListClass}>{items}</ul>
			</div>
		);
	}
}
