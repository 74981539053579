import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import { Button } from 'antd';
import { get } from 'lodash';
import { useState } from 'react';
import { configMode } from 'src/_shared/api/';
import { decryptUsingAES256 } from 'src/_shared/api/settings';
import { StripeForm } from 'src/_shared/components/stripe/';
import { ONBOARDING_SCREENS } from '../constants';
import {
	buttonContainerStyle,
	buttonStyle,
	containerStyle,
	noteStyle,
	subtitleStyle,
	textBodyStyle,
	titleContainerStyle,
	titleStyle,
} from './onboardingStyles.js';

loadStripe.setLoadParameters({ advancedFraudSignals: false });
const stripePromise = loadStripe(
	decryptUsingAES256(process.env[`REACT_APP_${configMode}_STRIPEPUBLISHABLEKEY`])
);

function GiftCardPayouts(props) {
	const {
		company,
		completeOnboardingPage,
		uncompleteOnboardingPage,
		prevPage,
		nextPage,
		navToScreen,
	} = props;
	const [complete, setComplete] = useState(false);
	const partnerHost = get(company, 'partnerHost');

	const next = (e) => {
		if (complete) {
			completeOnboardingPage(ONBOARDING_SCREENS.GIFT_CARD_PAYOUTS, {
				status: complete ? 'complete' : 'skipped',
			});
			navToScreen(ONBOARDING_SCREENS.BONUS_REPORTING);
		} else if (
			confirm(
				'Are you sure you want to skip adding funds at this time? If there are no funds added to your account your employees will not be able to cash in their earned bonuses for rewards'
			)
		) {
			completeOnboardingPage(ONBOARDING_SCREENS.GIFT_CARD_PAYOUTS, {
				status: complete ? 'complete' : 'skipped',
			});
			navToScreen(ONBOARDING_SCREENS.BONUS_REPORTING);
		}
	};

	const back = (e) => {
		uncompleteOnboardingPage(ONBOARDING_SCREENS.GIFT_CARD_PAYOUTS);
		navToScreen(ONBOARDING_SCREENS.HOW_TO_PAY);
	};

	const buttonText = complete ? 'Next' : 'Skip';

	return (
		<div className={containerStyle}>
			<div className={titleContainerStyle(partnerHost)}>
				<h1 className={titleStyle}>Fund Your Gift Card Account</h1>
				<div
					className={textBodyStyle}
					style={{
						display: 'flex',
						flexDirection: 'column',
					}}
				>
					<div className={subtitleStyle} style={{ marginBottom: 20 }}>
						Add funds to your account, this will allow your employees to start
						cashing in their earned bonuses when they are eligible.
					</div>
					<div style={{ marginTop: 30 }}>
						<Elements stripe={stripePromise}>
							<StripeForm {...props} callback={setComplete} />
						</Elements>
					</div>

					<div
						className={noteStyle}
						style={{
							width: '70%',
							textAlign: 'center',
							marginTop: 50,
							alignSelf: 'center',
						}}
					>
						NOTE: If you do not add any store funds, your employees will not be
						able to cash in their earned points for rewards.
					</div>
					<div className={buttonContainerStyle} style={{ marginTop: 30 }}>
						<Button
							type="primary"
							shape="round"
							size="large"
							className={buttonStyle}
							style={{ marginRight: 30 }}
							onClick={back}
						>
							Back
						</Button>
						<Button
							type="primary"
							shape="round"
							size="large"
							className={buttonStyle}
							onClick={next}
						>
							{buttonText}
						</Button>
					</div>
					<div style={{ height: 40 }} />
				</div>
			</div>
		</div>
	);
}

export default GiftCardPayouts;
