export const HOME_API_ENDPOINTS = {
    GET_LEADERBOARD: 'api/v1/badge/assignments/leaderboard',
    GET_REWARD: 'api/v1/badge/assignments/rewards',
    CREATE_BADGE_ASSIGNMENT: 'api/v1/badge/assignments',
    GET_BADGES_DASHBOARD: 'api/v1/badges/assign',
    GET_EMPLOYEE_LIST: 'api/v1/employees',
    GET_ISSUED_BADGE_DASHBOARD: 'api/v1/badge/assignments/issued-badge/',
    GET_EMPLOYEE_REWARDS: 'api/v1/badge/assignments',
    GET_RECENT_ACTIVITY: 'api/v1/badge/assignments/recent-activity',
    CREATE_FEED_INTERACTION: 'api/v1/feed-interactions',
    GET_FEED_INTERACTION: 'api/v1/badge/assignments/feeds',
    GET_RECENT_ACTIVITY_PROPERTIES: 'api/v1/common-configuration/get-properties'
}

export const BADGE_TYPE_SEARCH = {
    PEER_TO_PEER: 'On-the-Spot'
}