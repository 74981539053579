import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { updateContact } from '../../graphql/custom/contacts';

export const withUpdateContact = (Component) => {
	return graphql(gql(updateContact), {
		props: (props) => ({
			onUpdate(input) {
				const optimisticResponseData = {
					...props.contact,
					...input.input,
				};
				props.mutate({
					context: {
						headers: {
							'x-frame-options': 'deny', // This header will reach the server
						},
					},
					variables: { input: { ...input } },
					optimisticResponse: {
						__typename: 'Mutation',
						updateContact: {
							...optimisticResponseData,
							__typeName: 'updateContact',
						},
					},
				});
			},
		}),
	})(Component);
};
