export const parseJsonFields = (fields = [], object) => {
	const parsedObject = {};
	for (const field of fields) {
		if (typeof object[field] === 'string') {
			parsedObject[field] = JSON.parse(object[field]);
		}
	}

	return parsedObject;
};
