import { LeftOutlined } from '@ant-design/icons';
import { BackIcon, BackLink, titleCancel } from './addContactsModalStyles.js';

const modalNavigation = (props) => {
	const { contactsChoice } = props;
	return (
		<div style={{ border: 'none' }}>
			<div className={BackLink} onClick={() => contactsChoice(0)}>
				<LeftOutlined className={BackIcon} />
				Back
			</div>
			<span className={titleCancel}> Close </span>
		</div>
	);
};

export default modalNavigation;
