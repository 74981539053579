import { Component } from 'react';

class JobTitleComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			currentTieredBonus: this.props.currentTieredBonus
				? this.props.currentTieredBonus
				: 0,
			color: null,
			translatedTitle: '',
		};
	}

	async componentDidMount() {
		const { jobTitle, currentUser } = this.props;
		const newTitle = jobTitle;

		this.setState({
			translatedTitle: newTitle,
		});
	}

	componentDidUpdate(prevProps) {
		if (
			prevProps.currentUser?.languageCode !==
			this.props.currentUser?.languageCode
		) {
			const { jobTitle } = this.props;
			const newTitle = jobTitle;
			this.setState({
				translatedTitle: newTitle,
			});
		}
	}

	render() {
		return <>{this.state.translatedTitle}</>;
	}
}

export default JobTitleComponent;
