import gql from 'graphql-tag';
import { get } from 'lodash';
import { compose, graphql } from 'react-apollo';
import { queryReferralsByCompanyIdIndex } from '../../graphql/custom/referrals';

export const withQueryReferralsByCompanyId = (Component) => {
	return compose(
		graphql(gql(queryReferralsByCompanyIdIndex), {
			options(props) {
				return {
					variables: {
						companyId: props.currentUser.companyId,
					},
					fetchPolicy: 'cache-and-network',
				};
			},
			props(response, previous) {
				const referrals = get(
					response,
					['data', 'queryReferralsByCompanyIdIndex', 'items'],
					get(previous, 'referrals', [])
				);

				const referralsNextToken = get(
					response,
					['data', 'queryReferralsByCompanyIdIndex', 'nextToken'],
					null
				);

				const onFetchMoreReferrals = makeOnFetchMoreReferrals(
					response.data.fetchMore,
					referralsNextToken
				);
				return { referrals, onFetchMoreReferrals, referralsNextToken };
			},
		})
	)(Component);
};

const makeOnFetchMoreReferrals = (fetchMore, nextToken) => {
	if (!nextToken) {
		return null;
	}

	return () => {
		fetchMore({
			variables: { after: nextToken },
			updateQuery(previous, { fetchMoreResult }) {
				if (!fetchMoreResult) {
					return previous;
				}

				return {
					...previous,
					loading: false,
					queryReferralsByCompanyIdIndex: {
						...previous.queryReferralsByCompanyIdIndex,
						...fetchMoreResult.queryReferralsByCompanyIdIndex,
						items: [
							...previous.queryReferralsByCompanyIdIndex.items,
							...fetchMoreResult.queryReferralsByCompanyIdIndex.items,
						],
					},
				};
			},
		});
	};
};
