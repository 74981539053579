export const updateInternalMobility = `mutation updateInternalMobility($input: UpdateInternalMobilityInput!) {
    updateInternalMobility(input: $input) {
        id
        companyId
        contentFirst
        contentMiddle
        contentLast
      dateCreated
    }
  }
  `;
