import { useState, useEffect } from 'react';
import defaultLoader from 'src/_shared/assets/default-loader.svg';
import defaultLoaderGif from 'src/_shared/assets/default-loader.gif';

function DefaultSpinner(props) {
	const supportsSVGLS = localStorage.getItem('supportsSVG');


	// Check if browser supports animated svg so we can fallback to gif
	const [supportsSVG, setSupportsSVG] = useState(supportsSVGLS === 'true');
	useEffect(() => {
		const checkSupportsAnimatedSVG = () => {
			const SVGCheck = document.createElementNS(
				'http://www.w3.org/2000/svg',
				'svg'
			);
			const animate = document.createElementNS(
				'http://www.w3.org/2000/svg',
				'animate'
			);
			SVGCheck.append(animate);
			return animate instanceof SVGAnimateElement;
		};
		if (supportsSVGLS === 'true' || supportsSVGLS === 'false') return;
		let testSVG;
		if (checkSupportsAnimatedSVG()) {
			testSVG = 'true';
		} else {
			testSVG = 'false';
		}
		localStorage.setItem('supportsSVG', testSVG);
		setSupportsSVG(checkSupportsAnimatedSVG());
	}, []);

	return (
		<div style={{width: "100%", height: "100%", backgroundColor: "#1b365c"}}>
		<div className="custom-loader">
				<>
					{supportsSVG ? (
						<img src={defaultLoader} alt="Loading" style={{ width: 100 }} />
					) : (
						<img src={defaultLoaderGif} alt="Loading" style={{ width: 100 }} />
					)}
				</>
		</div>
		</div>
	);
}

export default DefaultSpinner;
