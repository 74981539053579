import { useLocation } from 'react-router-dom';

export function withQueryParams(Component) {
	return function WrappedComponent(props) {
		const location = useLocation();
		const queryParams = new URLSearchParams(location.search);

		return <Component {...props} queryParams={queryParams} />;
	};
}
