import { compose, graphql } from 'react-apollo';
import { ListPointsLog } from '../../graphql/custom/points';

const current = new Date(); // Get current date
const first = current.getDate() - current.getDay() + 1; // First day is the day of the month - the day of the week
const last = first + 6; // Last day is the first day + 6

const firstday = new Date(current.setDate(first)).toISOString();
const lastday = new Date(current.setDate(last)).toISOString();
export const ListPointsLogData = (Component, variables) => {
	return compose(
		graphql(ListPointsLog, {
			options: (props) => ({
				variables: {
					filter: {
						companyId: {
							eq: props.currentUser.companyId,
						},
						userId: {
							eq: props.currentUser.id,
						},
						weeklyLastLogin: { between: [firstday, lastday] },
					},
				},
				fetchPolicy: 'cache-and-network',
			}),
			props(props) {
				return {
					pointsLogWithDate: props.data.listPointsLog
						? props.data.listPointsLog.items
						: undefined,
				};
			},
		})
	)(Component);
};
