export const listDepartments = `query ListDepartments(
  $filter: TableDepartmentFilterInput
  $limit: Int
  $nextToken: String
) {
  listDepartments(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      companyId
      name
      active
      totalUsers
      userDepartments {
        id
        userId
        user {
          id
          cognitoId
          companyId
          emailAddress
          firstName
          lastName
          title
          departmentId
          lastLogin
          lastNotificationCheck
          contacts {
            id
            firstName
            lastName
            emailAddress
            socialMediaAccounts
            userId
            companyId
            phoneNumber
            jobHistory
            fullContactData
            dateCreated
          }
          totalReferrals
          webNotifications {
            id
            userId
            requestingUserId
            contactId
            referralId
            jobId
            matches
            message
            dateCreated
          }
          connectedApps
          active
          createdById
          dateCreated
          
          
          ytdReferralCount
        }
        departmentId
        department {
          id
          companyId
          name
          active
          totalUsers
          userDepartments {
            id
            userId
            departmentId
          }
        }
      }
    }
    nextToken
  }
}
`;
