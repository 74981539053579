import { connect } from 'react-redux';
import { withListDepartment } from 'src/_shared/api/components/departments/';
import { withQueryReferralsByCompanyId } from 'src/_shared/api/components/referralsDashboard/queryReferralsByCompanyId';
import { withListUserInvites } from 'src/_shared/api/components/userInvites/';
import { dashboardActions } from 'src/actions.js';
import { compose } from '../_shared/services/utils.js';
import OpenToNewRoleComponent from './OpenToNewRolesComponent.jsx';

const mapStateToProperties = (state) => {
	const { allMultiLingualData } = state.dashboard;
	return {
		currentUser: state.user.currentUser,
		companyId: state.user.currentUser.companyId,
		allMultiLingualData,
		filter: {
			companyId: { eq: state.user.currentUser.companyId },
		},
		nextToken: null,
		limit: 10_000,
	};
};

const mapDispatchToProperties = (dispatch) => {
	return {
		setMultiLingualData(data) {
			dispatch(dashboardActions.createSetMultiLingualData(data));
		},
	};
};

export const OpenToNewRoleWithApi = compose(
	withListDepartment,
	withListUserInvites,
	withQueryReferralsByCompanyId
)(OpenToNewRoleComponent);

export const OpenToNewRole = connect(
	mapStateToProperties,
	mapDispatchToProperties
)(OpenToNewRoleWithApi);
