import gql from 'graphql-tag';

export const GetMultiLingualDataByModule = gql`
	query GetMultiLingualDataByModule($module: String!) {
		getMultiLingualDataByModule(module: $module) {
			items {
				id
				key
				languageCode
				matchKey
				module
				text
			}
			nextToken
		}
	}
`;
