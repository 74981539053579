import { css } from 'emotion';

export const SelectStyles = css({
	fontFamily: '"Open Sans", sans-serif',
	height: 50,
	zIndex: 1,
	'& .ant-select-selection': {
		backgroundColor: 'transparent',
		height: 50,
		fontSize: 16,
	},
	'& .ant-select-selection__rendered': {
		lineHeight: '50px !important',
	},
	'& .ant-select-selector': {
		height: '50px !important',
	},
	'& .ant-select-selection-search-input': {
		height: '50px !important',
		fontSize: 16,
	},
	'& .ant-select-selection-item': {
		margin: 'auto 0',
	},
	'& .ant-select-selection-placeholder': {
		padding: '9px 0px !important',
	},
});

export const SortDown = css({
	display: 'flex',
	'& svg': {
		transform: 'translate(-40px, 8px)',
	},
});

export const DropDown = css({
	fontFamily: '"Open Sans", sans-serif',
});

export const SelectContainer = css({
	display: 'block',
});
