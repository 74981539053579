import get from 'lodash/get';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import { withCreateWebNotification } from 'src/_shared/api/components/network/';
import { withCreateReferralNoUpdate as withCreateReferral } from 'src/_shared/api/components/referrals/';
import { withGetCompanyData } from 'src/_shared/api/components/settings/';
import { withGetTieredBonus } from 'src/_shared/api/components/tiered-bonuses/';
import { withGetUserNotifications } from 'src/_shared/api/components/users/';
import { userActions } from 'src/actions.js';
import { compose } from '../services/utils';
import ReferralModalWrapper from './ReferralModalWrapper.jsx';

const mapStateToProperties = (state, ownProperties) => {
	const { currentUser } = state.user;
	const { allMultiLingualData } = state.dashboard;
	const showConfetti = state.browseJobs;
	let id = '';
	let referralBonus = get(
		ownProperties,
		['jobData', 'referralBonus'],
		get(ownProperties, ['job', 'referralBonus'], 'skip')
	);
	if (typeof referralBonus === 'string' && referralBonus !== 'skip')
		referralBonus = JSON.parse(referralBonus);

	let isActive = false;
	let archived;
	const campaignId = get(ownProperties, ['job', 'campaignId'], null);
	const campaign = get(ownProperties, ['job', 'campaign'], null);

	if (campaignId) {
		const today = dayjs();
		const startDate = get(campaign, 'startDate');
		const endDate = get(campaign, 'endDate');
		archived = get(campaign, 'archived', false);
		isActive = Boolean(today.isBetween(startDate, endDate, 'day', '[]'));
	}

	if (archived) {
		isActive = false;
	}

	id = isActive
		? get(ownProperties, 'job.campaign.tieredBonusId', 'skip')
		: get(referralBonus, 'tieredBonusId', 'skip');
	return {
		currentUser,
		allMultiLingualData,
		filter: { userId: { eq: currentUser.id } },
		limit: 1000,
		tieredBonusQuery: {
			companyId: get(currentUser, 'companyId', null),
			id,
		},
		companyId: currentUser.companyId,
		showConfetti,
		...ownProperties,
	};
};

const mapDispatchToProperties = (dispatch) => {
	return {
		setCurrentUser(user) {
			dispatch(userActions.createSetCurrentUserAction(user));
		},
	};
};

const ReferralModalWithApi = compose(
	withCreateReferral,
	withGetTieredBonus,
	withGetCompanyData,
	withCreateWebNotification,
	withGetUserNotifications
)(ReferralModalWrapper);

export const ReferralModal = connect(
	mapStateToProperties,
	mapDispatchToProperties
)(ReferralModalWithApi);
