import gql from 'graphql-tag';
import get from 'lodash/get';
import { compose, graphql } from 'react-apollo';
import dayjs from 'dayjs';
import uuid from 'uuid/v4';
import {
	createContact,
	listContacts,
	queryContactsByUserIdIndex,
	updateContact,
} from '../../graphql/custom/contacts';
import { createReferral } from '../../graphql/custom/referrals';
import { GetJob } from '../../graphql/custom/jobs';

export const withCreateContactReferral = (Component) => {
	return compose(
		graphql(gql(queryContactsByUserIdIndex), {
			options: (props) => ({
				context: {
					headers: {
						'x-frame-options': 'deny', // This header will reach the server
					},
				},
				variables: {
					userId: props.currentUser.id,
				},
				fetchPolicy: 'cache-and-network',
			}),
			props(response, previous) {
				const contacts = get(
					response,
					['data', 'queryContactsByUserIdIndex', 'items'],
					get(previous, 'contacts', [])
				);

				const contactsNextToken = get(
					response,
					['data', 'queryContactsByUserIdIndex', 'nextToken'],
					null
				);
				const onFetchMoreContacts = makeOnFetchMoreContacts(
					response.data.fetchMore,
					contactsNextToken
				);

				return { contacts, onFetchMoreContacts, contactsNextToken };
			},
		}),
		graphql(gql(createContact), {
			props: (props) => ({
				ImportedCreateContact(input) {
					const optimisticResponseData = {
						id: uuid(),
						...input.input,
						referrals: null,
						__typename: 'Contact',
					};
					return props.mutate({
						variables: input,
						optimisticResponse: {
							__typename: 'Mutation',
							createContact: {
								__typename: 'createContact',
								...optimisticResponseData,
							},
						},
						update(proxy, { data: { createContact } }) {
							const data = proxy.readQuery({
								query: gql(listContacts),
								variables: {
									filter: {
										userId: { eq: props.ownProps.currentUser.id },
									},
									limit: 5000,
									nextToken: null,
								},
							});
							if (
								!data.listContacts.items.find(
									(contact) => contact.id === createContact.id
								)
							) {
								data.listContacts.items.push(createContact);
							}

							proxy.writeQuery({
								query: gql(listContacts),
								variables: {
									filter: {
										userId: { eq: props.ownProps.currentUser.id },
									},
									limit: 5000,
									nextToken: null,
								},
								data,
							});
						},
					});
				},
			}),
		}),
		graphql(gql(updateContact), {
			props: (props) => ({
				onUpdateContactDeckStatus(input) {
					const optimisticResponseData = {
						...input.input,
						__typename: 'Contact',
					};
					props.mutate({
						variables: { input: { ...input } },
						optimisticResponse: {
							__typename: 'Mutation',
							updateContact: {
								__typename: 'updateContact',
								...optimisticResponseData,
							},
						},
						refetchQueries: [
							{
								query: gql(queryContactsByUserIdIndex),
								variables: { userId: props.ownProps.currentUser.id },
							},
						],
					});
				},
			}),
		}),
		graphql(gql(createReferral), {
			props: (props) => ({
				onCreateReferral(input) {
					const optimisticResponseData = {
						...input,
						id: uuid(),
						referralDate: dayjs(),
					};
					props.mutate({
						context: {
							headers: {
								'x-frame-options': 'deny', // This header will reach the server
							},
						},
						variables: input,
						optimisticResponse: {
							__typeName: 'Mutation',
							createReferral: {
								__typeName: 'createReferral',
								...optimisticResponseData,
							},
						},
						update(proxy, { data: { createReferral: newReferral } }) {
							if (
								props.ownProps.navLocation &&
								props.ownProps.navLocation === '/browsejobs'
							) {
								return;
							}

							console.log('ownProps =', get(props, 'ownProps'));
							console.log('ownProps.jobData =', get(props, 'ownProps.jobData'));
							const data = proxy.readQuery({
								query: GetJob,
								variables: {
									id: get(props, 'ownProps.jobData.id'),
								},
							});

							if (data.getJob) {
								const referrals = get(data, 'getJob.referrals', []);
								const hasReferralBeenCached = referrals.find((referral) => {
									return newReferral ? referral.id === newReferral.id : false;
								});

								if (!hasReferralBeenCached) {
									referrals.push(newReferral);

									proxy.writeQuery({
										query: GetJob,
										context: {
											headers: {
												'x-frame-options': 'deny', // This header will reach the server
											},
										},
										variables: {
											filter: {
												id: { eq: props.ownProps.jobData.id },
											},
										},
										data,
									});
								}
							}
						},
					});
				},
			}),
		})
	)(Component);
};

const makeOnFetchMoreContacts = (fetchMore, nextToken) => {
	if (!nextToken) {
		return null;
	}

	return () => {
		fetchMore({
			variables: { after: nextToken },
			updateQuery(previous, { fetchMoreResult }) {
				if (!fetchMoreResult) {
					return previous;
				}

				return {
					...previous,
					loading: false,
					queryContactsByUserIdIndex: {
						...previous.queryContactsByUserIdIndex,
						...fetchMoreResult.queryContactsByUserIdIndex,
						items: [
							...previous.queryContactsByUserIdIndex.items,
							...fetchMoreResult.queryContactsByUserIdIndex.items,
						],
					},
				};
			},
		});
	};
};
