import { Alert, Button, Form, Input } from 'antd';
import { Auth } from 'aws-amplify';
import get from 'lodash/get';
import { Component } from 'react';

class PageOne extends Component {
	constructor(props) {
		super(props);

		this.state = {
			theme: JSON.parse(get(props, 'job.theme', '{}')),
			cognitoError: false,
			errorMessage: '',
		};
	}

	handleSubmit = (values) => {
		Auth.forgotPassword(values.email.toLowerCase())
			.then(() => {
				this.props.setPageTwo();
				this.props.setUserEmail(values.email.toLowerCase().trim());
			})
			.catch((error) => {
				// Added this code here to fix PEN test issue: User Enumeration Possible
				this.props.setPageTwo();
				this.props.setUserEmail(values.email.toLowerCase().trim());
			});
	};

	onFinishFailed = (error) => {
		console.error(error);
	};

	render() {
		const { cognitoError, errorMessage } = this.state;
		return (
			<Form onFinish={this.handleSubmit} onFinishFailed={this.onFinishFailed}>
				<Form.Item
					name="email"
					rules={[
						{
							type: 'email',
							message: 'Not a valid email address.',
						},
						{
							required: true,
							message: 'An email address is required.',
						},
					]}
				>
					<div className="custom-form-group">
						<label className="custom-label">Email Address</label>
						<Input className="custom-input" />
					</div>
				</Form.Item>
				{cognitoError && <Alert type="success" message={errorMessage} />}
				<div className="modal-footer-btn">
					<Button
						type="primary"
						size="large"
						className="btn-min-width"
						htmlType="submit"
					>
						Next
					</Button>
				</div>
			</Form>
		);
	}
}

export default PageOne;
