import { css } from 'emotion';
import { COLORS } from '../../../_shared/styles/colors';

export const container = (role) => css({});

export const headerClass = css({
	fontSize: 16,
	textTransform: 'uppercase',
	letterSpacing: 0.96,
	fontWeight: 300,
	color: `${COLORS.heading} !important`,
	marginBottom: 16,
});

export const opacity = css({
	opacity: '1',
});

export const buttonActive = css({
	border: 'none',
	background: COLORS.dashboardBlue,
	width: '20px',
	height: '10px',
	borderRadius: '4px',
	paddingBottom: 0,
});

export const buttonBusiness = css({
	border: 'none',
	background: COLORS.lightGray8,
	width: '20px',
	height: '10px',
	borderRadius: '4px',
	paddingBottom: 0,
});

export const buttonFirst = css({
	borderColor: COLORS.dashboardLightOrange,
	background: COLORS.dashboardLightOrange,
	width: '10px',
	height: '10px',
	borderRadius: '35%',
	paddingBottom: 0,
});

export const donutChart = css({
	position: 'absolute',
	top: '-36px',
	left: '20px',
});

export const chartTotal = css({
	position: 'absolute',
	top: '29%',
	left: '23.5%',
	background: COLORS.white,
	flexWrap: 'wrap',
	width: '75px',
});

export const numTotal = css({
	fontSize: 20,
	fontWeight: 800,
	marginBottom: -5,
});

export const middleContainer = css({
	height: 120,
	width: 180,
	margin: 'auto',
	marginTop: 40,
	marginBottom: 0,
});

export const middleColumn = css({
	textAlign: 'center',
	width: 85,
});

export const bigNumbers = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 800,
	color: COLORS.black,
	fontSize: 28,
});

export const bigNumbersOpen = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 800,
	color: COLORS.dashboardDarkOrange,
	fontSize: 28,
});

export const numberSubtitles = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 600,
	color: COLORS.white,
	fontSize: 14,
});

export const numberSubtitlesOpen = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 600,
	color: COLORS.lightGray,
	fontSize: 14,
	width: 70,
	margin: 'auto',
});

export const matchBox = css({
	textAlign: 'center',
	width: 80,
	backgroundColor: COLORS.dashboardLightOrange,
	borderRadius: '3%',
});

export const smallIcons = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 600,
	color: COLORS.subHeading,
	fontSize: 14,
	opacity: 0.7,
});

export const divider = css({
	height: 1,
	backgroundColor: COLORS.subHeading,
	opacity: 0.4,
});

export const bottomContainer = css({
	marginTop: 24,
	marginBottom: 20,
});

export const bottomSectionText = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 600,
	color: COLORS.subHeading,
	fontSize: 14,
	marginLeft: 5,
});

export const bottomSectionValue = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 800,
	color: COLORS.black,
	fontSize: 14,
	float: 'right',
});

export const bottomSectionValuewithCenter = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 400,
	fontSize: 14,
	fontWeight: 800,
});

export const bold = css({
	color: COLORS.black,
});

export const bottomContainers = css({
	height: 20,
	marginTop: 0,
	marginBottom: 0,
});

export const colWrap = css({
	textAlign: 'center',
	padding: '20px 0',
	'& h6': {
		textAlign: 'left',
		marginBottom: 0,
	},
	'& P': {
		textAlign: 'left',
	},
});

export const icon = css({
	marginBottom: 20,
	'& svg': {
		fill: '#999',
	},
});

export const roundButton = css({
	fontFamily: '"Open Sans",sans-serif',
	color: COLORS.white,
	background: COLORS.blue,
	margin: 5,
	padding: '8px 30px',
	fontSize: 14,
	height: 40,
	minWidth: '70%',
	borderRadius: 40,
	border: 'none',
	outline: 'none !important',
});
