export const referralCardKeys = {
	BonusDetails: 'ml_BonusDetails',
	Job: 'ml_Job',
	ReferredCandidate: 'ml_ReferredCandidate',
	TotalBonus: 'ml_TotalBonus',
	HiredDate: 'ml_HiredDate',
	StartDate: 'ml_StartDate',
	BonusPayments: 'ml_BonusPayments',
	BonusNotes: 'ml_BonusNotes',
	Close: 'ml_Close',
	ViewBonusDetails: 'ml_ViewBonusDetails',
};
