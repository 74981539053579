import React from 'react';
import pointsToSendIcon from './../../../assets/svg/pointstosend.svg';
import bonusTwoIcon from './../../../assets/svg/bonus2.svg';

import './style.scss';
import { addCommas } from '../../../rewardsAndRecognitionHelper/utils.js';

const RewardPointsAndBonus = (props) => {
	const { rewardPointsBonus } = props;
	return (
		<div className="home">
			<div className="rewards-points d-card height-equal">
				<div className="d-card-head">
					<h4 className="dashboard-title">{"EARNED POINTS" + (props?.enableBonusesVerbiage ? " AND BONUSES" : "")}</h4>
				</div>
				<div className="reward-point-boxes home-reward-boxes">
					<div>
						<div className="send-points custom-cards">
							<div className="points-detail ">
								<div className="reward-point-img">
									<img src={pointsToSendIcon} alt="img" className="" />
								</div>
								<div className="about-points">
									<div className="point-amount pt-1 pt-md-0">
										{addCommas(rewardPointsBonus?.totalEarned)}
									</div>
									<div className="point-info">Total Points</div>
								</div>
							</div>
						</div>
					</div>
					{props?.enableBonusesVerbiage ? (
					<div className="reward-bonus custom-cards">
						<div className="points-detail points-bonus">
							<div className="reward-point-img">
								<img src={bonusTwoIcon} alt="img" />
							</div>
							<div className="about-points">
								<div className="point-amount pt-1 pt-md-0">
									{addCommas(rewardPointsBonus?.rewardBonus)}
								</div>
								<div className="point-info">Bonuses</div>
							</div>
						</div>
					</div>
						) : null}
				</div>
			</div>
		</div>
	);
};

export default RewardPointsAndBonus;
