export const createCustomPage = `mutation CreateCustomPage($input: CreateCustomPageInput!) {
    createCustomPage(input: $input) {
      id
      companyId
      content
      dateCreated
      userId
      pageNumber
    }
  }
  `;
