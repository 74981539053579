import gql from 'graphql-tag';

export const GetUserData = gql`
	query GetUserData($userId: ID!) {
		getUserData(userId: $userId) {
			id
			cognitoId
			companyId
			company {
				id
				name
				whiteLabel
				logo {
					bucket
					region
					key
				}
				shareLogo {
					bucket
					region
					key
				}
				theme
			}
			emailAddress
			role
			firstName
			lastName
			title
			languageCode
			accountClaimId
			accountClaim {
				id
				atsId
			}
		}
	}
`;
