import gql from 'graphql-tag';

export const GetWebNotification = gql`
	query GetWebNotification($id: ID!) {
		getWebNotification(id: $id) {
			id
			type
			userId
			status
			companyId
			user {
				id
				cognitoId
				companyId
				company {
					id
				}
				emailAddress
				firstName
				lastName
				title
				departmentId
				lastLogin
				lastNotificationCheck
				contacts {
					id
					firstName
					lastName
					emailAddress
					socialMediaAccounts
					userId
					phoneNumber
					jobHistory
					fullContactData
					dateCreated
				}
				totalReferrals
				webNotifications {
					id
					userId
					requestingUserId
					contactId
					referralId
					jobId
					matches
					message
					dateCreated
					questionsData
				}
				accountClaimId
				accountClaim {
					id
					atsId
				}
				connectedApps
				active
				createdById
				dateCreated
			}
			referralDevice
			referralRequestedStatus
			referralType
			requestingUserId
			requestingUser {
				id
				cognitoId
				companyId
				emailAddress
				firstName
				lastName
				title
				departmentId
				lastLogin
				lastNotificationCheck
				contacts {
					id
					firstName
					lastName
					emailAddress
					socialMediaAccounts
					userId
					phoneNumber
					jobHistory
					fullContactData
					dateCreated
				}
				totalReferrals
				webNotifications {
					id
					userId
					requestingUserId
					contactId
					referralId
					jobId
					matches
					message
					dateCreated
				}
				connectedApps
				active
				createdById
				dateCreated
			}
			contactId
			contact {
				id
				firstName
				lastName
				emailAddress
				socialMediaAccounts
				userId
				phoneNumber
				jobHistory
				fullContactData
				dateCreated
			}
			referralId
			referral {
				id
				companyId
				contactId
				userId
				jobId
				note
				message
				referralDate
				hireDate
			}
			jobId
			job {
				id
				jobType
				departmentId
				department {
					id
					name
				}
				companyId
				company {
					# referralQuestions {
					#   id
					#   companyId
					#   questions
					#   active
					#   isCandidate
					#   sortOrder
					#   isInterested
					# }
					name
					referralBonusWaitingPeriod
					logo {
						bucket
						region
						key
					}
					symbol {
						bucket
						region
						key
					}
					background {
						bucket
						region
						key
					}
					errorImage {
						bucket
						region
						key
					}
					socialImage {
						bucket
						region
						key
					}
					websiteUrl
					brandColor
					whiteLabel
					theme
					hideRecruiterInfo
					hideRecruiterInfoJobDetails
				}
				title
				description
				publicLink
				internalJobLink
				hideImInterested
				isGeneralReferral
				salary
				jobLevelIds
				jobLevels {
					id
					active
					name
					companyId
				}
				location
				locations
				createdById
				createdBy {
					id
					firstName
					lastName
				}
				hiringManagerId
				hiringManager {
					id
					emailAddress
					firstName
					lastName
				}
				referralBonus
				notificationType
				status
				shares
				views
				externalJobId
				externalSource
				isHotJob
				subCompanyId
				subCompany {
					id
					name
					logo {
						bucket
						key
						region
					}
				}
				campaignId
				campaign {
					id
					name
					startDate
					endDate
					archived
					tieredBonusId
				}
			}
			matches
			message
			dateCreated
			questionsData
		}
	}
`;
