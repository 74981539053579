import { message } from 'antd';
import _, { get } from 'lodash';
import mixpanel from 'mixpanel-browser';
import { Component } from 'react';
import { lowerCase } from '../../_shared/services/utils.js';
import queryReferralsByUserIdReferralTypeIndex from '../../my-applications/listReferralsByUserIdReferralTypeGraphql.js';
import { ResumeInfo } from './resume-info-components';

class CareerProfileCard extends Component {
	constructor(props) {
		super(props);

		const editedFirstName = get(props, 'currentUser.firstName', '');
		const editedLastName = get(props, 'currentUser.lastName', '');
		const editedDepartment = {
			key: get(props, 'currentUser.departmentId'),
			label: get(
				props,
				'currentUser.deparment.name',
				get(props, 'currentUser.departmentId')
			),
		};
		let editedCity = JSON.parse(get(props, 'currentUserser.location', '{}'));
		editedCity = get(editedCity, 'city');

		let editedState = JSON.parse(get(props, 'currentUser.location', '{}'));
		editedState = get(editedState, 'state');

		let editedCountry = JSON.parse(get(props, 'currentUser.location', '{}'));
		editedCountry = get(editedCountry, 'country');

		this.state = {
			currentUser: get(props, 'currentUser'),
			editedBio: 'Bio Placeholder',
			editedFirstName,
			editedLastName,
			editedDepartment,
			editedPosition: get(props, 'currentUser.title'),
			avatarFile: undefined,
			editedAvatar: get(props, 'currentUser.avatar'),
			uploadedAvatar: undefined,
			edit: false,
			departments: get(props, 'departments'),
			visible: false,
			visibleModalIndex: null,
			editedLocation: get(props, 'currentUser.location'),
			editedCity,
			editedCountry,
			editedState,
			editedCurrency: get(props, 'currentUser.currency'),
			theme: JSON.parse(get(props, 'currentUser.company.theme', '{}')),
			editedDescription: '',
			editedStartDate: '',
			editedEndDate: '',
			editedOrg: '',
			editedResumePosition: '',
			addPosition: false,
			inputSkill: '',
			selfReferrals: null,
			skillModal: false,
			careerProfile: JSON.parse(get(props, 'currentUser.careerProfile', '{}')),
			openToNewRole: props?.currentUser?.openToNewRole ?? false,
			isAlreadyComplete: false,
		};
		this.handleChange = this.handleChange.bind(this);
	}

	async componentDidMount() {
		await this.getReferralsOfCurrentUser();
		if (this.getCompletionPercentage() == '100%') {
			this.setState({ isAlreadyComplete: true });
		}
	}

	setModalIndex = (index) => {
		this.setState({ visibleModalIndex: index });
	};

	addUserSkill = async (skill) => {
		const { careerProfile, currentUser } = this.state;
		const { onUpdate } = this.props;
		const percentage = this.getCompletionPercentage('skill');
		const currentSkills = get(careerProfile, 'skills', []);
		const currentProfile = careerProfile ? careerProfile : {};
		if (currentSkills.includes(lowerCase(skill))) {
			message.error('Skill already exists');
			return;
		}

		if (skill.trim() === '') {
			message.error('Skill can not be empty');
			return;
		}

		currentSkills.push(lowerCase(skill));
		currentProfile.skills = currentSkills;
		const formattedProfile = JSON.stringify(currentProfile);
		const userInput = {
			input: {
				careerProfile: formattedProfile,
				id: get(currentUser, 'id'),
			},
		};
		this.handleUpdateUser(userInput.input);
		await onUpdate(userInput);
		message.success('Skill Added');
		mixpanel.track('Skill Added', { 'IM Profile Completion': percentage });
		if (percentage == '100%' && !this.state.isAlreadyComplete) {
			mixpanel.track('IM Profile Completed');
			this.setState({ isAlreadyComplete: true });
		}

		this.setState({
			careerProfile: currentProfile,
		});
	};

	autofillResumeInfo = async (parsedResume = {}, skillsArray = []) => {
		const { onUpdate } = this.props;
		const { careerProfile = {} } = this.state; // Merge resume on upload
		const resumeFile = get(this.state, 'careerProfile.resumeFile');
		if (resumeFile) careerProfile.resumeFile = resumeFile;
		const sovrenResume = get(this.state, 'careerProfile.sovrenResume');
		if (sovrenResume) careerProfile.sovrenResume = sovrenResume;
		const parsedEmploymentArray = get(
			parsedResume,
			'EmploymentHistory.EmployerOrg',
			[]
		);
		const updatedCareerProfile = careerProfile;
		const newEmployment = [];
		if (parsedEmploymentArray.length > 0) {
			parsedEmploymentArray.map((org) => {
				const positions = get(org, 'PositionHistory', []);
				for (const positionDetails of positions) {
					const employmentObject = {};
					const startDate = Object.keys(
						get(positionDetails, 'StartDate', {})
					).includes('YearMonth')
						? // For some reason the date rolls back one day so must be set to the second to have the right month
							new Date(get(positionDetails, 'StartDate.YearMonth') + '-02')
						: new Date();
					const endDate = Object.keys(
						get(positionDetails, 'EndDate', {})
					).includes('YearMonth')
						? new Date(get(positionDetails, 'EndDate.YearMonth') + '-02')
						: new Date();
					employmentObject.title = get(positionDetails, 'Title');
					employmentObject.description = get(positionDetails, 'Description');
					employmentObject.name = get(
						positionDetails,
						'OrgName.OrganizationName'
					);
					if (startDate !== 'Invalid Date') {
						employmentObject.start = {
							month: startDate.getMonth(),
							year: startDate.getFullYear(),
							monthName: startDate.toLocaleString('default', { month: 'long' }),
							fullString: this.formatDate(startDate),
						};
					}

					if (endDate !== 'Invalid Date') {
						employmentObject.end = {
							month: endDate.getMonth(),
							year: endDate.getFullYear(),
							monthName: endDate.toLocaleString('default', { month: 'long' }),
							fullString: this.formatDate(endDate),
							current: Object.values(
								get(positionDetails, 'EndDate', {})
							).includes('current'),
						};
					}

					newEmployment.push(employmentObject);
					continue;
				}
			});
		}

		const careerProfileEmployment = get(careerProfile, 'employment', []);

		const updatedEmployment = [];
		for (const job of careerProfileEmployment) {
			if (
				!updatedEmployment.find(
					(newJob) =>
						get(newJob, 'title') == get(job, 'title') &&
						get(newJob, 'start.fullString') == get(job, 'start.fullString')
				)
			) {
				updatedEmployment.push(job);
			}
		}

		for (const newJob of newEmployment) {
			const index = careerProfileEmployment.findIndex((job) => {
				return (
					get(newJob, 'title') == get(job, 'title') &&
					get(newJob, 'start.fullString') == get(job, 'start.fullString')
				);
			});
			if (index >= 0) updatedEmployment.splice(index, 1, newJob);
			if (index < 0) updatedEmployment.push(newJob);
		}

		const resumeSkills = get(careerProfile, 'skills', []);
		for (const category of skillsArray) {
			const subtaxonomies = get(category, 'sov:Subtaxonomy', []);
			for (const subtaxonomy of subtaxonomies) {
				const skills = get(subtaxonomy, 'sov:Skill', []);
				for (const skill of skills) {
					const childSkills = get(skill, 'sov:ChildSkill');
					if (childSkills) {
						childSkills.forEach((childSkill = []) => {
							const lowerCaseSkill = lowerCase(get(childSkill, '@name'));
							if (!resumeSkills.includes(lowerCaseSkill)) {
								resumeSkills.push(lowerCaseSkill);
							}
						});
					} else {
						const lowerCaseSkill = lowerCase(get(skill, '@name'));
						if (!resumeSkills.includes(lowerCaseSkill)) {
							resumeSkills.push(lowerCaseSkill);
						}
					}
				}
			}
		}

		updatedCareerProfile.employment = updatedEmployment;
		updatedCareerProfile.skills = resumeSkills;
		const formattedEntry = JSON.stringify(updatedCareerProfile);
		const userInput = {
			input: {
				careerProfile: formattedEntry,
				id: get(this.state, 'currentUser.id'),
			},
		};
		this.handleUpdateUser(userInput.input);
		await onUpdate(userInput);
		message.success('Autofilled Resume Info');
		this.setState({
			careerProfile: updatedCareerProfile,
		});
	};

	avatarRequest = ({ file, onSuccess }) => {
		setTimeout(() => {
			onSuccess('ok');
		}, 0);
		const addToList = {
			bucket: 'erin-avatars',
			key: `user-pic/${this.props.currentUser.id}/${file.name}`,
			region: 'us-east-2',
		};
		this.setState({
			editedAvatar: addToList,
		});
	};

	cancelEdit = () => {
		message.error('Edit Cancelled');

		let city = JSON.parse(get(this.props, 'currentUser.location', '{}'));
		city = get(city, 'city');

		let province = JSON.parse(get(this.props, 'currentUser.location', '{}'));
		province = get(province, 'state');

		let country = JSON.parse(get(this.props, 'currentUser.location', '{}'));
		country = get(country, 'country');

		const currency = get(this.props, 'currentUser.currency', '');
		this.updateCityProfile(city);
		this.updateStateProfile(province);
		this.updateCountryProfile(country);
		this.updateCurrentCurrency(currency);
		this.setState({
			edit: false,
			editedFirstName: get(this.props, 'currentUser.firstName'),
			editedLastName: get(this.props, 'currentUser.lastName'),
			editedDepartment: {
				key: get(this.props, 'currentUser.departmentId'),
				label: get(this.props, 'currentUser.department.name'),
			},
			editedPosition: get(this.props, 'currentUser.title'),
			editedAvatar: get(this.props, 'currentUser.avatar'),
			departments: get(this.props, 'departments'),
			editedLocation: get(this.props, 'currentUser.location'),
			updateCurrentUser: get(this.props, 'updateCurrentUser'),
		});
	};

	cancelModal = () => {
		this.setState({
			careerProfile: JSON.parse(
				get(this.props, 'currentUser.careerProfile', '{}')
			),
			visibleModalIndex: null,
		});
	};

	confirmRemove = () => {
		this.setState({
			editedAvatar: this.props.currentUser.avatar,
			avatarFile: undefined,
			uploadedAvatar: undefined,
		});
	};

	deleteAllUserJobs = async () => {
		const { careerProfile, currentUser } = this.state;
		const { onUpdate } = this.props;
		const currentProfile = careerProfile;
		currentProfile.employment = [];
		currentProfile.resumeFile = {};
		currentProfile.sovrenResume = {};
		const formattedProfile = JSON.stringify(currentProfile);
		const userInput = {
			input: {
				careerProfile: formattedProfile,
				id: currentUser.id,
			},
		};
		this.handleUpdateUser(userInput.input);
		await onUpdate(userInput);
		this.setState({
			careerProfile: currentProfile,
			visibleModalIndex: null,
		});
		message.success('Work History Removed');
	};

	deleteAllUserSkills = async () => {
		const { careerProfile, currentUser } = this.state;
		const { onUpdate } = this.props;
		const currentProfile = careerProfile;
		currentProfile.skills = [];
		const formattedProfile = JSON.stringify(currentProfile);
		const userInput = {
			input: {
				careerProfile: formattedProfile,
				id: currentUser.id,
			},
		};
		this.handleUpdateUser(userInput.input);
		await onUpdate(userInput);
		message.success('Skills Removed');
		this.setState({
			careerProfile: currentProfile,
		});
	};

	deleteUserJob = async (index) => {
		const { careerProfile, currentUser } = this.state;
		const { onUpdate } = this.props;
		const currentEmployment = careerProfile.employment;
		const currentProfile = careerProfile;
		currentEmployment.splice(index, 1);
		currentProfile.employment = currentEmployment;
		const formattedProfile = JSON.stringify(currentProfile);
		const userInput = {
			input: {
				careerProfile: formattedProfile,
				id: currentUser.id,
			},
		};
		this.handleUpdateUser(userInput.input);
		await onUpdate(userInput);
		this.setState({
			careerProfile: currentProfile,
			visibleModalIndex: null,
		});
		message.success('Position Removed');
	};

	deleteUserSkill = async (index) => {
		const { careerProfile, currentUser } = this.state;
		const { onUpdate } = this.props;
		const currentSkills = get(careerProfile, 'skills');
		const currentProfile = careerProfile;
		currentSkills.splice(index, 1);
		currentProfile.skills = currentSkills;
		const formattedProfile = JSON.stringify(currentProfile);
		const userInput = {
			input: {
				careerProfile: formattedProfile,
				id: currentUser.id,
			},
		};
		this.handleUpdateUser(userInput.input);
		await onUpdate(userInput);
		message.success('Skill Removed');
		this.setState({
			careerProfile: currentProfile,
		});
	};

	editInfo = () => {
		this.setState({ edit: true });
	};

	flipSkillModal = (boolValue) => {
		this.setState({ skillModal: boolValue });
	};

	formatDate = (date) => {
		const d = new Date(date);
		let month = String(d.getMonth() + 1);
		let day = String(d.getDate());
		const year = d.getFullYear();

		if (month.length < 2) month = '0' + month;
		if (day.length < 2) day = '0' + day;

		return [year, month, day].join('-');
	};

	getCompletionPercentage = (value) => {
		let percentage = 0;

		if (this.state?.selfReferrals?.length > 0) {
			percentage += 25;
		}

		if (this.state.careerProfile?.skills?.length > 0 || value == 'skill') {
			percentage += 25;
		}

		if (
			this.state.careerProfile?.employment?.length > 0 ||
			value == 'experience'
		) {
			percentage += 25;
		}

		if (this.state.currentUser?.openToNewRole || value == 'roles') {
			percentage += 25;
		}

		return `${percentage}%`;
	};

	async getReferralsOfCurrentUser(
		policy = 'network-only',
		nextToken = null,
		selfReferrals = []
	) {
		const { client, currentUser } = this.props;
		try {
			const userId = get(currentUser, 'id', null);
			if (userId !== null) {
				const { data } = await client.query({
					query: queryReferralsByUserIdReferralTypeIndex,
					variables: {
						userId,
						referralType: 'self',
						nextToken,
					},
					fetchPolicy: policy,
				});
				const referrals = [
					...data.queryReferralsByUserIdReferralTypeIndex.items,
				];
				const token = get(
					data,
					'queryReferralsByUserIdReferralTypeIndex.nextToken',
					null
				);
				selfReferrals = [...selfReferrals, ...referrals];
				let allSelfReferrals = [];

				if (token) {
					await this.getReferralsOfCurrentUser(policy, token, selfReferrals);
				}

				allSelfReferrals = _.sortBy(selfReferrals, ['referralDate']).reverse();
				this.setState({
					selfReferrals: allSelfReferrals.filter((referral) => referral.job),
				});
			}
		} catch (error) {
			console.log(error);
		}
	}

	handleCancel = () => {
		this.setState({
			editedPosition: '',
			editedOrg: '',
			editedStartDate: '',
			editedEndDate: '',
			editedDescription: '',
		});
	};

	handleChange = (name) => (event) => {
		this.setState({
			[name]: event.target.value,
		});
	};

	handleJobEdit = (name, index) => (event) => {
		const { careerProfile } = this.state;
		const editedJobs = careerProfile.employment;
		const job = { ...editedJobs[index] };
		if (name === 'start' || name === 'end') {
			const date = new Date(event.target.value);
			// Increment the date one because it rolls back one day for some reason
			date.setDate(date.getDate() + 1);
			const todaysDate = new Date();
			const dateObject = {
				month: date.getMonth(),
				year: date.getFullYear(),
				fullString: event.target.value,
				monthName: date.toLocaleString('default', { month: 'long' }),
				current: date.setHours(0, 0, 0, 0) === todaysDate.setHours(0, 0, 0, 0),
			};
			job[`${name}`] = dateObject;
		} else {
			job[`${name}`] = event.target.value;
		}

		editedJobs[index] = job;
		// 5. Set the state to our new copy
		careerProfile.employment = editedJobs;
		this.setState({ careerProfile });
	};

	handleNewRole = async (value) => {
		const { currentUser } = this.state;
		const { onUpdate } = this.props;
		const percentage = this.getCompletionPercentage('roles');
		const userInput = {
			input: {
				openToNewRole: value,
				id: get(currentUser, 'id'),
			},
		};
		this.handleUpdateUser(userInput.input);
		await onUpdate(userInput);

		message.success('Request is submitted successfully!');
		this.setState({
			openToNewRole: value,
		});
		if (value) {
			mixpanel.track('Open to New Roles Enabled', {
				'IM Profile Completion': percentage,
			});
			if (percentage == '100%' && !this.state.isAlreadyComplete) {
				mixpanel.track('IM Profile Completed');
				this.setState({ isAlreadyComplete: true });
			}
		}
	};

	handlePasswordCancel = () => {
		this.setState({ visible: false });
	};

	handleSelect = (value) => {
		this.setState({ editedDepartment: value });
	};

	handleSkillChange = (value) => {
		this.setState({ inputSkill: value });
	};

	handleUpdateSovrenResume = (file = {}, resume) => {
		const { careerProfile, currentUser } = this.state;
		const cp = careerProfile ? careerProfile : {};
		const updatedProfile = cp;
		updatedProfile.sovrenResume = JSON.parse(resume);
		updatedProfile.resumeFile = {
			name: file.name,
			lastModified: file.lastModified,
			size: file.size,
			type: file.type,
		};
		const formattedProfile = JSON.stringify(updatedProfile);
		const userInput = {
			input: {
				careerProfile: formattedProfile,
				id: get(currentUser, 'id'),
			},
		};
		this.handleUpdateUser(userInput.input);
		this.setState({
			careerProfile: updatedProfile,
			visibleModalIndex: null,
		});
	};

	handleUpdateUser = (input = {}) => {
		const { updateCurrentUser } = this.props;
		const { currentUser } = this.state;
		const updatedUser = {
			...currentUser,
			...input,
		};
		if (
			updatedUser.careerProfile &&
			typeof updatedUser?.careerProfile !== 'string'
		) {
			updatedUser.careerProfile = JSON.stringify(updatedUser.careerProfile);
		}

		updateCurrentUser({
			...updatedUser,
		});
	};

	showModal = () => {
		this.setState({ visible: true });
	};

	submitJobEdit = async () => {
		const { onUpdate } = this.props;
		const currentCareerProfile = this.state.careerProfile;
		const formattedProfile = JSON.stringify(currentCareerProfile);
		const userInput = {
			input: {
				id: this.props.currentUser.id,
				careerProfile: formattedProfile,
			},
		};
		this.handleUpdateUser(userInput.input);
		await onUpdate(userInput);
		this.setState({ visibleModalIndex: null });
		message.success('Job Edited');
	};

	submitNewPosition = async () => {
		const {
			editedResumePosition,
			editedOrg,
			editedStartDate,
			editedEndDate,
			editedDescription,
			careerProfile,
			currentUser,
		} = this.state;

		this.setState({
			editedResumePosition: '',
			editedOrg: '',
			editedStartDate: '',
			editedEndDate: '',
			editedDescription: '',
		});

		const { onUpdate } = this.props;
		const percentage = this.getCompletionPercentage('experience');
		const newJob = {};
		newJob.current = true;
		const endDate = new Date(editedEndDate);
		// Increment the date one because it rolls back one day for some reason
		endDate.setDate(endDate.getDate() + 1);
		const startDate = new Date(editedStartDate);
		startDate.setDate(startDate.getDate() + 1);
		const todaysDate = new Date();
		if (editedResumePosition !== '') {
			newJob.title = editedResumePosition;
		}

		if (editedOrg !== '') {
			newJob.name = editedOrg;
		}

		if (editedDescription !== '') {
			newJob.description = editedDescription;
		}

		if (endDate !== 'Invalid Date') {
			newJob.end = {
				month: endDate.getMonth(),
				year: endDate.getFullYear(),
				fullString: editedEndDate,
				monthName: endDate.toLocaleString('default', { month: 'long' }),
				current:
					endDate.setHours(0, 0, 0, 0) === todaysDate.setHours(0, 0, 0, 0),
			};
		}

		if (startDate !== 'Invalid Date') {
			newJob.start = {
				month: startDate.getMonth(),
				year: startDate.getFullYear(),
				fullString: editedStartDate,
				monthName: startDate.toLocaleString('default', { month: 'long' }),
			};
		}

		const fullCareerProfile = careerProfile ? careerProfile : {};
		if (
			currentUser.careerProfile &&
			get(fullCareerProfile, 'employment', []).length > 0
		) {
			fullCareerProfile.employment.push(newJob);
		} else {
			fullCareerProfile.employment = [];
			fullCareerProfile.employment.push(newJob);
		}

		const formattedEntry = JSON.stringify(fullCareerProfile);
		const userInput = {
			input: {
				careerProfile: formattedEntry,
				id: currentUser.id,
			},
		};

		this.handleUpdateUser(userInput.input);
		message.success('Job History Added');
		mixpanel.track('Work Experience Added', {
			'IM Profile Completion': percentage,
		});
		if (percentage == '100%' && !this.state.isAlreadyComplete) {
			mixpanel.track('IM Profile Completed');
			this.setState({ isAlreadyComplete: true });
		}

		this.setState({
			addPosition: false,
			careerProfile: fullCareerProfile,
		});
		await onUpdate(userInput);
	};

	toggleNewPosition = () => {
		this.setState({
			addPosition: !this.state.addPosition,
		});
	};

	updateCityProfile = (city) => {
		this.setState({
			editedCity: city,
		});
	};

	updateCountryProfile = (country) => {
		this.setState({
			editedCountry: country,
		});
	};

	updateCurrentCurrency = (currency) => {
		this.setState({
			editedCurrency: currency,
		});
	};

	updateStateProfile = (state) => {
		this.setState({
			editedState: state,
		});
	};

	render() {
		return (
			<ResumeInfo
				allMultiLingualData={this.props.allMultiLingualData}
				currentUser={this.props.currentUser}
				handleUpdateSovrenResume={this.handleUpdateSovrenResume}
				handleChange={this.handleChange}
				bio={this.state.bio}
				editedDescription={this.state.editedDescription}
				editedStartDate={this.state.editedStartDate}
				editedEndDate={this.state.editedEndDate}
				editedOrg={this.state.editedOrg}
				editedResumePosition={this.state.editedResumePosition}
				addPosition={this.state.addPosition}
				toggleNewPosition={this.toggleNewPosition}
				edit={this.state.edit}
				submitNewPosition={this.submitNewPosition}
				careerProfile={this.state.careerProfile}
				handleJobEdit={this.handleJobEdit}
				deleteUserSkill={this.deleteUserSkill}
				deleteAllUserSkills={this.deleteAllUserSkills}
				deleteUserJob={this.deleteUserJob}
				deleteAllUserJobs={this.deleteAllUserJobs}
				addUserSkill={this.addUserSkill}
				inputSkill={this.state.inputSkill}
				handleSkillChange={this.handleSkillChange}
				autofillResumeInfo={this.autofillResumeInfo}
				submitJobEdit={this.submitJobEdit}
				setModalIndex={this.setModalIndex}
				visibleModalIndex={this.state.visibleModalIndex}
				cancelModal={this.cancelModal}
				skillModal={this.state.skillModal}
				flipSkillModal={this.flipSkillModal}
				handleNewRole={this.handleNewRole}
				openToNewRole={this.state.openToNewRole}
				handleCancel={this.handleCancel}
			/>
		);
	}
}

export default CareerProfileCard;
