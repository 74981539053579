import { Button, Input, message } from 'antd';
import _ from 'lodash';
import get from 'lodash/get';
import { Component } from 'react';
import WebIcon from 'src/_shared/components/WebIconComponent.jsx';
import { lowerCase, sanitize } from '../../_shared/services/utils.js';
import { COLORS } from '../../_shared/styles/colors';

class AddContactsEmail extends Component {
	constructor(props) {
		super(props);
		this.state = {
			contacts: [{}, {}, {}],
		};
	}

	// Triggers import button, which calls submit contact with props
	handleSubmit = () => {
		const { contacts } = this.state;
		// Will not submit empty contact row as a contact to DynamoDB
		const filteredContacts = contacts.filter(
			(contact) => contact.email || contact.first || contact.last
		);
		if (filteredContacts.length === 0) {
		}
		// Validates the fields, so that handleSubmit button only works if email, first, and last is full
		else if (
			filteredContacts.some(
				(contact) => !contact.email || !contact.first || !contact.last
			)
		) {
			return message.error(
				'Please enter missing fields, or remove contact altogether'
			);
		} else if (
			// If two emails are duplicates, returns a message
			filteredContacts.some((contact, i) =>
				filteredContacts.some(
					(input, j) => input.email && i != j && contact.email === input.email
				)
			)
		) {
			message.error('Please remove duplicate emails');
		} else {
			const { currentUser, handleCancel, contactsChoice, companyData } =
				this.props;
			const { contacts } = this.state;
			let contactSmartReferralStatus;
			contactSmartReferralStatus = Boolean(
				companyData !== undefined && companyData.disableSmartReferrals
			);
			if (companyData === undefined) {
				contactSmartReferralStatus = Boolean(
					this.props.currentUser.company.disableSmartReferrals
				);
			}

			for (const contact of filteredContacts) {
				let { first } = contact;
				first &&= sanitize(first);
				let { last } = contact;
				last &&= sanitize(last);
				let email = get(contact, 'email');
				email &&= sanitize(email);
				const input = {
					input: {
						firstName: first,
						lastName: last,
						emailAddress: lowerCase(email),
						userId: currentUser.id,
						importMethod: 'email',
						companyId: currentUser.companyId,
						socialMediaAccounts: null,
						jobHistory: null,
						phoneNumber: null,
						disableSmartReferrals: contactSmartReferralStatus,
						inviteStatus: 'pending',
					},
				};

				this.submitContact(currentUser.contacts, input);
			}

			this.setState({
				filteredContacts: [{}],
			});
			contactsChoice(0);
			handleCancel();
			message.success('Contacts Imported');
			this.props.updateUserIncentiveEligibility(true);
		}
	};

	submitContact = (currentContacts, selectedContact) => {
		// Checks Dynamo to make sure not a duplicate email
		const { onCreateContact } = this.props;
		const isDuplicate = _.some(currentContacts, {
			emailAddress: lowerCase(get(selectedContact, 'input.emailAddress', '')),
		});
		if (isDuplicate === true) {
			message.error(selectedContact.input.emailAddress + ' Already Imported');
		} else {
			onCreateContact(selectedContact);
		}
	};

	render() {
		const { contacts } = this.state;
		return (
			<>
				<p className="text-center">
					Input the contacts you'd like to save to your account
				</p>
				<div>
					<table style={{ width: '100%' }} cellPadding="4">
						<tbody>
							{/* validates inputs on Form.Item usng validateStatus feature
              then makes input outline red if not valid */}
							{contacts.map((contact, index) => {
								const empty = !contact.email && !contact.first && !contact.last;
								const emailValidateStatus =
									contact.email || empty ? '' : 'error';
								const firstValidateStatus =
									contact.first || empty ? '' : 'error';
								const lastValidateStatus = contact.last || empty ? '' : 'error';
								// Errors duplicate email inputs
								const isDuplicateInput = contacts.some(
									(other, j) =>
										other.email && index !== j && contact.email === other.email
								)
									? 'error'
									: '';

								return (
									<tr key={index}>
										<td>
											<div
												className={`custom-form-group ${firstValidateStatus}`}
												style={{ marginBottom: 8 }}
											>
												<Input
													type="text"
													value={contact.first || ''}
													placeholder="First Name"
													className="custom-input"
													onChange={(e) => {
														this.setState({
															contacts: [
																...contacts.slice(0, index),
																{ ...contact, first: e.target.value },
																...contacts.slice(index + 1),
															],
														});
													}}
												/>
											</div>
										</td>

										<td>
											<div
												className={`custom-form-group ${lastValidateStatus}`}
												style={{ marginBottom: 8 }}
											>
												<Input
													type="text"
													className="custom-input"
													value={contact.last || ''}
													placeholder="Last Name"
													onChange={(e) => {
														this.setState({
															contacts: [
																...contacts.slice(0, index),
																{ ...contact, last: e.target.value },
																...contacts.slice(index + 1),
															],
														});
													}}
												/>
											</div>
										</td>
										<td>
											<div
												className={`custom-form-group ${
													emailValidateStatus || isDuplicateInput
												}`}
												style={{ marginBottom: 8 }}
											>
												<Input
													type="email"
													className="custom-input"
													value={contact.email || ''}
													placeholder="email"
													// Updates the state {contacts} each letter press
													onChange={(e) => {
														this.setState({
															contacts: [
																...contacts.slice(0, index),
																{
																	...contact,
																	email: lowerCase(e.target.value),
																},
																...contacts.slice(index + 1),
															],
														});
													}}
												/>
											</div>
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
					{/* button uses antd icons and primary button, 
          and adds more lines for additional contacts */}
					<Button
						type="primary"
						shape="circle"
						style={{ padding: '8px 8px' }}
						onClick={() => {
							this.setState(({ contacts }) => ({
								contacts: [...contacts, {}],
							}));
						}}
					>
						<WebIcon name="plus-symbol" size={14} color={COLORS.white} />
					</Button>
				</div>
				<div className="modal-footer-btn">
					<Button
						size="large"
						className="btn-min-width"
						onClick={() => this.props.contactsChoice(0)}
					>
						Back
					</Button>
					<Button
						key="importBtn"
						className="btn-min-width"
						type="primary"
						size="large"
						onClick={this.handleSubmit}
					>
						Invite Contacts
					</Button>
				</div>
			</>
		);
	}
}
export default AddContactsEmail;
