import Feed from './../../presentation/feed/Feed.jsx';

import useClapCount from '../hooks/useClapCount.js';

const FeedContainer = (props) => {
	const {
		hasMoreData,
		feedListData,
		isFeedLoading,
		setFeedListData,
		fetchMoreData,
		isLoadMoreCalled,

		isMyBranchLoadMoreCalled,
		myBranchHasMoreData,
		myBranchFeedListData,
		isMyBranchFeedLoading,
		setMyBranchFeedListData,
		fetchMyBranchMoreData,

		isTopLoadMoreCalled,
		topHasMoreData,
		topFeedListData,
		isTopFeedLoading,
		setTopFeedListData,
		fetchTopMoreData
	} = props;

	const {
		handleClapCount,
		clapCount,
		setClapCount
	} = useClapCount({
		setFeedListData,
		feedListData
	})

	const {
		handleClapCount: handleMyBranchClapCount,
		clapCount: myBranchclapCount,
		setClapCount: setMyBranchClapCount
	} = useClapCount({
		setFeedListData: setMyBranchFeedListData,
		feedListData: myBranchFeedListData
	})

	const {
		handleClapCount: handleTopClapCount,
		clapCount: topclapCount,
		setClapCount: setTopClapCount
	} = useClapCount({
		setFeedListData: setTopFeedListData,
		feedListData: topFeedListData
	})

	return (
		<div style={{ height: '100%' }}>
			<Feed
				fetchMoreData={fetchMoreData}
				handleClapCount={handleClapCount}
				feedListData={feedListData}
				setClapCount={setClapCount}
				clapCount={clapCount}
				isFeedLoading={isFeedLoading}
				isLoadMoreCalled={isLoadMoreCalled}
				hasMoreData={hasMoreData}

				isMyBranchLoadMoreCalled={isMyBranchLoadMoreCalled}
				myBranchHasMoreData={myBranchHasMoreData}
				myBranchFeedListData={myBranchFeedListData}
				isMyBranchFeedLoading={isMyBranchFeedLoading}
				handleMyBranchClapCount={handleMyBranchClapCount}
				myBranchclapCount={myBranchclapCount}
				setMyBranchClapCount={setMyBranchClapCount}
				fetchMyBranchMoreData={fetchMyBranchMoreData}

				isTopLoadMoreCalled={isTopLoadMoreCalled}
				topHasMoreData={topHasMoreData}
				topFeedListData={topFeedListData}
				isTopFeedLoading={isTopFeedLoading}
				setTopFeedListData={setTopFeedListData}
				fetchTopMoreData={fetchTopMoreData}
				handleTopClapCount={handleTopClapCount}
				topclapCount={topclapCount}
				setTopClapCount={setTopClapCount}
			/>
		</div>
	);
};

export default FeedContainer;
