import gql from 'graphql-tag';

export const GetOpenJobsCountByCompany = gql`
	query getOpenJobsCountByCompany($companyId: ID!) {
		getOpenJobsCountByCompany(companyId: $companyId) {
			bonusJobs
			bonusReferral
			bonuses
			dashboardLastUpdated
			employees
			openJobs
			referralContact
			referralsThisYear
			referralsThisMonth
			referralsLastMonth
			acceptedThisMonth
			acceptedLastMonth
		}
	}
`;
