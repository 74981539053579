import { menuColorStyles } from './userProfileStyles.js';

export function CompanyLinkName({ currentUser }) {
	const {
		company: { linkName, linkUrl },
	} = currentUser;

	const getFormattedLink = (linkUrl) => {
		if (!linkUrl) return null;
		const formattedLink = `http://${linkUrl}`;
		return linkUrl.startsWith('http://') || linkUrl.startsWith('https://')
			? linkUrl
			: formattedLink;
	};

	return (
		<>
			{linkName !== null &&
			linkName !== '' &&
			linkUrl !== null &&
			linkUrl !== '' ? (
				<a
					className={menuColorStyles}
					href={getFormattedLink(linkUrl)}
					target="_blank"
					rel="noreferrer"
				>
					{linkName}
				</a>
			) : null}
		</>
	);
}
