import gql from 'graphql-tag';
import { compose, graphql } from 'react-apollo';
import uuid from 'uuid/v4';
import {
	createReferralQuestions,
	deleteReferralQuestion,
} from '../../graphql/custom/referral-questions';

export const withListReferralQuestions = (Component) => {
	return compose(
		graphql(gql(createReferralQuestions), {
			props: (props) => ({
				onAddQuestion(input) {
					const variables = {
						...input,
						id: uuid(),
					};
					props.mutate({
						variables,
					});
				},
			}),
		}),
		graphql(gql(deleteReferralQuestion), {
			props: (props) => ({
				onDeleteQuestion(input) {
					const variables = {
						...input,
					};
					props.mutate({
						context: {
							headers: {
								'x-frame-options': 'deny', // This header will reach the server
							},
						},
						variables,
					});
				},
			}),
		})
	)(Component);
};
