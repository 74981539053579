import { Component } from 'react';
import get from 'lodash/get';
import getSymbolFromCurrency from 'currency-symbol-map';
import {
	calculateReferralBonusAllReferrals,
	isCurrentCampaign,
} from '../../_shared/services/utils.js';

class BonusValueComponent extends Component {
	constructor(props) {
		super(props);

		this.state = {
			currentTieredBonus: this.props.currentTieredBonus
				? this.props.currentTieredBonus
				: 0,
			color: '#1c861d',
			bonusString: '',
		};
	}

	componentDidMount() {
		this.parseBonus(this.props.referral);
	}

	componentDidUpdate(prevProps) {
		const prevPropsToCompare = {
			currentTieredBonus: prevProps.currentTieredBonus,
			referral: prevProps.referral,
		};
		const propsToCompare = {
			currentTieredBonus: this.props.currentTieredBonus,
			referral: this.props.referral,
		};
		let shouldParseBonus = false;

		for (const key in propsToCompare) {
			if (propsToCompare[key] !== prevPropsToCompare[key]) {
				shouldParseBonus = true;
			}
		}

		if (shouldParseBonus) {
			this.parseBonus(this.props.referral);
		}
	}

	parseBonus = (referral) => {
		const { contactIncentiveBonus, currentTieredBonus, currentUser } =
			this.props;
		const campaign = get(referral, 'campaign');
		const referralDate = get(referral, 'referralDate');
		let bonusName = get(
			currentTieredBonus,
			'name',
			get(referral, 'job.tieredBonus.name')
		);
		if (campaign && isCurrentCampaign(campaign, referralDate))
			bonusName = get(campaign, 'tieredBonus.name');
		if (
			get(currentUser, 'company.enableRetroactiveBonuses') &&
			get(referral, 'tieredBonus.id')
		)
			bonusName = get(referral, 'tieredBonus.name');
		const referralBonus =
			typeof get(referral, 'job.referralBonus') === 'string'
				? JSON.parse(get(referral, 'job.referralBonus', '{}'))
				: get(referral, 'job.referralBonus');
		const amount = get(referralBonus, 'amount', get(this.props, 'amount'));
		const currencyCode = get(referral, 'user.userGroup.currency', 'USD');
		const symbol = getSymbolFromCurrency(currencyCode);

		const bonus = `${symbol}${calculateReferralBonusAllReferrals(
			contactIncentiveBonus,
			amount,
			get(referral, ['user', 'incentiveEligible']),
			currentTieredBonus,
			'employee',
			get(referral, ['user', 'userGroupId']),
			get(referral, 'hireDate')
		)}`.replaceAll(/\B(?=(\d{3})+(?!\d))/g, ',');
		if (
			get(referral, 'referralType') === 'self' &&
			this.props?.overrideSelfReferrals
		) {
			this.setState({ color: '#ffb461', bonusString: 'Self Referred' });
			return;
		}

		if (bonusName === undefined || bonusName === '') {
			this.setState({ bonusString: bonus });
		} else {
			this.setState({ bonusString: bonusName });
		}
	};

	render() {
		return (
			<span style={{ color: this.state.color }}>{this.state.bonusString}</span>
		);
	}
}

export default BonusValueComponent;
