import { Button } from 'antd';
import ProgressButton from 'react-progress-button';
import { ml } from 'src/_shared/services/utils.js';

export function AddJobsFooter(props) {
	const {
		allMultiLingualData,
		currentUser,
		handleNext,
		handleBack,
		handleSubmit,
		page,
		isNew,
		buttonState,
		theme,
	} = props;
	return (
		<div className="modal-footer-btn">
			{page !== 0 && (
				<Button
					key="back"
					type="ghost"
					size="large"
					className="btn-min-width"
					onClick={handleBack}
				>
					{ml('Back', currentUser, allMultiLingualData)}
				</Button>
			)}
			{page !== 3 && (
				<Button
					key="next"
					type="primary"
					size="large"
					className="btn-min-width"
					onClick={handleNext}
				>
					{ml('Next', currentUser, allMultiLingualData)}
				</Button>
			)}
			{page === 3 && (
				<ProgressButton
					key="submit"
					style={{ maxWidth: 180 }}
					durationSuccess={10_000}
					state={buttonState}
					onClick={handleSubmit}
				>
					{isNew ? 'Create Job' : 'Edit Job'}
				</ProgressButton>
			)}
		</div>
	);
}
