import { InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { COLORS } from '../../styles/colors';

function OwnershipPeriodTooltip({ ownershipSettings }) {
	if (ownershipSettings?.enabled == true && ownershipSettings?.expiryDate) {
		const numberOfDays = ownershipSettings.days;

		return (
			<Tooltip
				placement="top"
				title={`Your company's policy assigns credit to the first referring employee for ${numberOfDays} day${
					numberOfDays > 1 ? 's' : ''
				}. This candidate will be eligible to be referred by a different employee on ${
					ownershipSettings.expiryDate
				}.`}
				style={{ fontWeight: 1000 }}
			>
				<InfoCircleOutlined className="mr-2" style={{ color: COLORS.blue }} />
			</Tooltip>
		);
	}

	return null;
}

export default OwnershipPeriodTooltip;
