import { connect } from 'react-redux';
import { withGetUserNotifications } from 'src/_shared/api/components/users/';
import { userActions } from 'src/actions.js';
import { compose } from '../../_shared/services/utils.js';
import WebNotificationsComponent from './WebNotificationsComponent.jsx';

const mapStateToProperties = (state) => {
	const { currentUser } = state.user;
	return {
		currentUser,
	};
};

const mapDispatchToProperties = (dispatch) => {
	return {
		updateCurrentUserLastNotificationCheck(lastNotificationCheck) {
			dispatch(
				userActions.updateUserLastNotificationCheck(lastNotificationCheck)
			);
		},
	};
};

const WebNotificationsWithApi = compose(withGetUserNotifications)(
	WebNotificationsComponent
);

export const WebNotifications = connect(
	mapStateToProperties,
	mapDispatchToProperties
)(WebNotificationsWithApi);
