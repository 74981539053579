import gql from 'graphql-tag';

export const GetTopReferrers = gql`
	query GetTopReferrers($companyId: ID!) {
		getTopReferrers(companyId: $companyId) {
			id
			admin
			firstName
			lastName
			role
			userGroupId
			totalReferrals
			dateCreated
			subCompanyId
			__typename
		}
	}
`;
