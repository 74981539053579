import get from 'lodash/get';
import { Component } from 'react';
import { calculateReferralBonus } from '../../_shared/services/utils.js';

class BonusValueComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			currentTieredBonus: this.props.currentTieredBonus
				? this.props.currentTieredBonus
				: 0,
			color: null,
		};
	}

	parseBonus = (referral) => {
		const {
			contactIncentiveBonus,
			currentTieredBonus,
			currentCurrencyRate,
			currentCurrencySymbol,
		} = this.props;
		const symbol = currentCurrencySymbol ? currentCurrencySymbol : '$';
		const bonusObject =
			typeof referral.job.referralBonus === 'object'
				? referral.job.referralBonus
				: JSON.parse(referral.job.referralBonus);
		const { amount } = bonusObject;
		const bonus = `${symbol}${calculateReferralBonus(
			contactIncentiveBonus,
			amount,
			get(referral, ['user', 'incentiveEligible']),
			currentTieredBonus,
			'employee',
			get(referral, ['user', 'userGroupId']),
			currentCurrencyRate
		)}`.replaceAll(/\B(?=(\d{3})+(?!\d))/g, ',');

		if (referral.referralType === 'self') {
			if (this.state.color !== '#ffb461') {
				this.setState({ color: '#ffb461' });
			}

			return 'Self Referred';
		}

		if (this.state.color !== '#1c861d') {
			this.setState({ color: '#1c861d' });
		}

		return bonus;
	};

	render() {
		return (
			<div style={{ color: this.state.color }}>
				{this.parseBonus(this.props.referral)}
			</div>
		);
	}
}

export default BonusValueComponent;
