export const requiredFields = [
	{ key: 'jobType', message: 'Please select a Job Type', page: 0 },
	{
		key: 'location',
		message: 'Please select a location or Remote Location',
		page: 0,
	},
	{ key: 'title', message: 'Please enter a Title', page: 1 },
	{ key: 'department', message: 'Please select a Department', page: 1 },
	{ key: 'hiringManager', message: 'Please select a Recruiter', page: 2 },
	{
		key: 'description',
		message: 'Please enter a Description',
		page: 1,
	},
];

export const miscFieldErrors = [
	{
		key: 'salary',
		message: 'Max salary must be equal to or higher than minimum salary',
		page: 0,
	},
];

export const referralBonusErrors = [
	{
		key: 'referralBonus',
		message: 'Please enter a bonus amount or select "Do Not Pay a Bonus"',
		page: 2,
	},
];
