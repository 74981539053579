/**
 * <ReactFormBuilder />
 */

import { Component } from 'react';
import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import ReactFormGenerator from './Form.jsx';
import Preview from './Preview.jsx';
import Toolbar from './Toolbar.jsx';
import store from './stores/store.js';

class ReactFormBuilder extends Component {
	constructor(props) {
		super(props);

		this.state = {
			editMode: false,
			editElement: null,
		};
	}

	editModeOn(data, e) {
		e.preventDefault();
		e.stopPropagation();
		if (this.state.editMode) {
			this.setState({ editMode: !this.state.editMode, editElement: null });
		} else {
			this.setState({ editMode: !this.state.editMode, editElement: data });
		}
	}

	manualEditModeOff() {
		if (this.state.editMode) {
			this.setState({
				editMode: false,
				editElement: null,
			});
		}
	}

	render() {
		const toolbarProperties = {
			showDescription: this.props.show_description,
		};
		if (this.props.toolbarItems) {
			toolbarProperties.items = this.props.toolbarItems;
		}

		return (
			<DndProvider backend={HTML5Backend}>
				<>
					<div className="sm-form-builder">
						<div className="fb-left">
							<Preview
								files={this.props.files}
								manualEditModeOff={this.manualEditModeOff.bind(this)}
								showCorrectColumn={this.props.showCorrectColumn}
								parent={this}
								data={this.props.data}
								url={this.props.url}
								saveUrl={this.props.saveUrl}
								editModeOn={this.editModeOn}
								editMode={this.state.editMode}
								variables={this.props.variables}
								editElement={this.state.editElement}
								onLoad={this.props.onLoad}
								onPost={this.props.onPost}
							/>
						</div>
						<div className="fb-right">
							<Toolbar {...toolbarProperties} />
						</div>
					</div>
				</>
			</DndProvider>
		);
	}
}

const FormBuilders = {};
FormBuilders.ReactFormBuilder = ReactFormBuilder;
FormBuilders.ReactFormGenerator = ReactFormGenerator;
FormBuilders.ElementStore = store;

export default FormBuilders;

export { default as ReactFormGenerator } from './Form.jsx';
export { default as ElementStore } from './stores/store.js';
export { ReactFormBuilder };
