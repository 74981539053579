import { css } from 'emotion';
import get from 'lodash/get';
import { COLORS } from '../styles/colors';

export const SubTitle = css({
	fontSize: 16,
	fontWeight: 510,
	color: COLORS.black,
});

export const NameStyles = css({
	color: COLORS.blue,
	fontSize: 16,
	fontWeight: '600 !important',
});

export const TextStyle = css({
	fontSize: 16,
	fontWeight: 375,
	color: COLORS.black,
});

export const ReferralBtn = (theme) => {
	let buttonColor = COLORS.blue;
	if (get(theme, 'enabled')) {
		buttonColor = get(theme, 'buttonColor', buttonColor);
	}

	return css({
		fontFamily: '"Open Sans", sans-serif',
		textAlign: 'center',
		fontWeight: 200,
		color: `${COLORS.white} !important`,
		background: `${buttonColor} !important`,
		margin: 5,
		padding: 8,
		fontSize: 14,
		display: 'flex',
		width: '40%',
		height: 40,
		borderRadius: '10px',
		border: `2px solid ${buttonColor}`,
		'&:hover': {
			color: buttonColor,
			borderColor: buttonColor,
		},
		'&:focus': {
			color: buttonColor,
			borderColor: buttonColor,
		},
	});
};

export const CancelBtn = (theme) => {
	let buttonColor = COLORS.blue;
	if (get(theme, 'enabled')) {
		buttonColor = get(theme, 'buttonColor', buttonColor);
	}

	return css({
		background: 'COLORS.white',
		color: '#404447',
		display: 'flex',
		fontFamily: '"Open Sans", sans-serif',
		fontWeight: 200,
		fontSize: 14,
		height: 40,
		padding: 8,
		margin: 5,
		textAlign: 'center',
		width: '40%',
		border: '1.5px solid #8d99a3',
		borderRadius: '10px',
		'&:hover': {
			color: buttonColor,
			borderColor: buttonColor,
		},
		'&:focus': {
			color: buttonColor,
			borderColor: buttonColor,
		},
	});
};

export const RippleButton = css({
	display: 'inline !important',
	cursor: 'pointer',
	width: '100%',
});

export const dropdownMenuItem = css({
	borderWidth: '2px',
	borderColor: COLORS.blue,
	color: COLORS.blue,
	'&:hover': {
		borderWidth: '2px',
		backgroundColor: COLORS.white,
		color: COLORS.blue,
		borderColor: COLORS.blue,
	},
});

export const dropdownButton = css({
	marginLeft: 8,
	borderColor: COLORS.blue,
	color: COLORS.blue,
	'&:hover': {
		backgroundColor: COLORS.white,
		color: COLORS.blue,
		borderColor: COLORS.blue,
	},
	'&:focus': {
		backgroundColor: COLORS.white,
		color: COLORS.blue,
		borderColor: COLORS.white,
	},
});

export const ModalStyles = css({
	'& .ant-modal-content': {
		borderRadius: 10,
		borderWidth: '1px',
		borderStyle: 'solid',
		borderColor: COLORS.blue,
	},
	'& .ant-modal-close': {
		display: 'none',
	},
	fontFamily: 'Open Sans',
	width: 620,
});

export const LinkStyles = css({
	fontFamily: '"Open Sans", sans-serif',
	color: COLORS.blue,
	height: 30,
	border: 'none',
	boxShadow: 'none',
	alignItems: 'center',
});

export const ModalTitle = css({
	color: COLORS.black,
	marginTop: -6,
	marginBottom: 20,
	marginLeft: 'auto',
	marginRight: 'auto',
	fontSize: 24,
	fontWeight: 510,
});

export const FormItemStyles = css({
	marginBottom: 0,
	width: 310,
	margin: 'auto',
});

export const CheckBoxStyles = css({
	margin: '0 auto',
	' & span': {
		fontSize: '16px !important',
	},
	'& .ant-checkbox-checked .ant-checkbox-inner': {
		borderColor: COLORS.green,
		backgroundColor: COLORS.green,
	},
});

export const SubmitBtnContainer = css({
	width: '100%',
	display: 'flex',
	justifyContent: 'center',
	padding: 20,
	marginBottom: 0,
	'& .ant-form-item-control-wrapper': {
		'@media (max-width: 575px)': {
			width: 'auto',
		},
	},
	'& .ant-btn: hover': {
		color: COLORS.blue,
		fontWeight: 300,
		border: `2px solid ${COLORS.blue}`,
	},
});

export const CheckIcon = css({
	borderRadius: 100,
	border: '1px solid white',
	padding: 2,
});

export const NotHired = css({
	fontWeight: 600,
});

export const JobInfoContainer = css({
	padding: 5,
	paddingTop: 10,
	paddingBottom: 2,
	textAlign: 'center',
	backgroundColor: COLORS.lightGreen,
	borderRadius: 8,
	marginBottom: 15,
});

export const noteStyles = css({
	color: COLORS.gray2,
	fontWeight: 'lighter',
});

export const LabelStyles = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 600,
	'& span': {
		fontWeight: 300,
		fontSize: 14,
	},
});

export const Heading = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 600,
	color: '#444444',
	paddingTop: 10,
	marginBottom: 0,
});

export const SectionTitle = css({
	fontSize: 18,
});

export const myReferralsTextEmphasisClass = (emphasize, isCurrency) =>
	css({
		color: isCurrency ? COLORS.green : COLORS.gray2,
		fontWeight: isCurrency ? 600 : 'lighter',
		textTransform: 'capitalize',
	});

export const filterPayment = css({
	padding: 0,
	border: 'none',
	backgroundColor: 'transparent',
	fontSize: 16,
	height: 16,
	color: '#018dd3',
	fontWeight: 600,
});
