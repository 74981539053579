import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Table, message } from 'antd';
import gql from 'graphql-tag';
import _ from 'lodash';
import get from 'lodash/get';
import { Component, createRef } from 'react';
import { withApollo } from 'react-apollo';
import { updateUser } from 'src/_shared/api/graphql/custom/users/';
import { lambda } from 'src/_shared/services/utils.js';
import { ImportButton, contactContainer } from './addContactsModalStyles.js';

const contactImportAPI = (selectedContacts, currentContacts) => {
	const nonDuplicateContacts = selectedContacts.filter(
		(o) => !currentContacts.find((o2) => o.emailAddress === o2.emailAddress)
	);
	if (nonDuplicateContacts.length > 0) {
		const formattedContacts = JSON.stringify(nonDuplicateContacts);
		lambda({
			endpoint: 'bulkContactImport',
			variables: formattedContacts,
		});
		message.success(
			'Contacts being processed, we will notify you when finished'
		);
	} else {
		message.error('All Contacts previously imported');
	}
};

class ContactChecklist extends Component {
	constructor(props) {
		super(props);
		this.tableRef = createRef();
		this.state = {
			checkAll: false,
			importedContacts: this.props.importedContacts,
			searchText: '',
			selectedRows: [],
			selectedRowKeys: [],
			loading: true,
		};
		switch (this.props.importMethod) {
			case 'microsoft': {
				this.columns = [
					{
						title: 'Contacts',
						dataIndex: 'displayName',
						colSpan: 2,
						key: 'displayName',
						render(text, contact) {
							return (
								<div>
									<p>
										{contact.displayName ? contact.displayName + ' - ' : ''}
										{contact.emailAddresses[0] !== undefined &&
											contact.emailAddresses[0].address}
									</p>
								</div>
							);
						},
						filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
							<div style={{ padding: 8 }}>
								<Input
									ref={(node) => {
										this.searchInput = node;
									}}
									placeholder="Search"
									value={selectedKeys[0]}
									style={{ width: 188, marginBottom: 8, display: 'block' }}
									onChange={(e) =>
										setSelectedKeys(e.target.value ? [e.target.value] : [])
									}
									onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
								/>
								<Button
									type="primary"
									icon={<SearchOutlined />}
									size="small"
									style={{ width: 90, marginRight: 8 }}
									onClick={() => this.handleSearch(selectedKeys, confirm)}
								>
									Search
								</Button>
							</div>
						),
						onFilter(value, record) {
							try {
								return (
									_.get(record, 'displayName') ||
									_.get(record, 'emailAddresses[0].address')
								)
									.toLowerCase()
									.includes(value.toLowerCase());
							} catch (error) {
								console.error(error);
							}
						},
						filterIcon: (filtered) => (
							<SearchOutlined
								style={{ color: filtered ? '#1890ff' : undefined }}
							/>
						),
					},
				];
				break;
			}

			case 'google': {
				this.columns = [
					{
						title: 'Contacts',
						dataIndex: 'title',
						render(text, contact) {
							let title = _.get(contact, 'title.$t');
							title = title ? title + ' - ' : '';
							const info =
								title +
								(_.get(contact, 'gd$email[0].address') ||
									_.get(contact, 'emailAddresses[0].value') ||
									_.get(contact, 'phoneNumbers[0].value') ||
									_.get(contact, 'gd$phoneNumber[0].$t'));

							return (
								<div>{info ? <span>{info}</span> : <span>loading</span>}</div>
							);
						},
						filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
							<div style={{ padding: 8 }}>
								<Input
									ref={(node) => {
										this.searchInput = node;
									}}
									placeholder="Search"
									value={selectedKeys[0]}
									style={{ width: 188, marginBottom: 8, display: 'block' }}
									onChange={(e) =>
										setSelectedKeys(e.target.value ? [e.target.value] : [])
									}
									onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
								/>
								<Button
									type="primary"
									icon={<SearchOutlined />}
									size="small"
									style={{ width: 90, marginRight: 8 }}
									onClick={() => this.handleSearch(selectedKeys, confirm)}
								>
									Search
								</Button>
							</div>
						),
						onFilter(value, record) {
							try {
								return (
									_.get(record, 'title.$t') ||
									_.get(record, 'names[0].displayName') ||
									_.get(record, 'gd$email[0].address') ||
									_.get(record, 'emailAddresses[0].value') ||
									_.get(record, 'phoneNumbers[0].value') ||
									_.get(record, 'gd$phoneNumber[0].$t')
								)
									.toLowerCase()
									.includes(value.toLowerCase());
							} catch (error) {
								console.error(error);
							}
						},
						filterIcon: (filtered) => (
							<SearchOutlined
								style={{ color: filtered ? '#1890ff' : undefined }}
							/>
						),
					},
				];
				break;
			}

			default: {
				break;
			}
		}
	}

	componentDidUpdate() {
		const { importedContacts } = this.props;
		const currentContacts = this.state.importedContacts;
		if (importedContacts.length !== currentContacts.length) {
			this.setState({
				importedContacts: this.props.importedContacts,
				loading: false,
			});
		}
	}

	onSelectAll = (isSelected) => {
		if (isSelected) {
			const allSelected = this.tableRef.current.props.dataSource.map(
				(row) =>
					_.get(row, 'id.$t') || _.get(row, 'resourceName') || _.get(row, 'id')
			);
			this.setState({
				selectedRowKeys: allSelected,
				selectedRows: this.props.importedContacts,
				checkAll: true,
			});
		} else {
			this.setState({ selectedRowKeys: [], selectedRows: [], checkAll: false });
		}
	};

	onSelectChange = (selectedRowKeys, selectedRows) => {
		this.setState({ selectedRows, selectedRowKeys });
	};

	handleSearch = (selectedKeys, confirm) => {
		confirm();
		this.setState({ searchText: selectedKeys[0] });
	};

	handleSubmit = () => {
		const {
			onCreateContact,
			currentUser,
			importMethod,
			handleCancel,
			contactsChoice,
			accountUsed,
		} = this.props;
		const { selectedRows } = this.state;
		// Check to see if user logged in with connectedApps, if not add them
		const { connectedApps } = currentUser;
		const thisConnection = {
			name: importMethod,
			date: new Date().toJSON().slice(0, 10),
			account: accountUsed,
		};
		const connected =
			connectedApps !== null && connectedApps !== undefined
				? JSON.parse(connectedApps)
				: [];
		const indexOfConnection = connected.findIndex(
			(i) => i.name === importMethod
		);
		if (indexOfConnection === -1) {
			connected.push(thisConnection);
		} else {
			connected[indexOfConnection] = thisConnection;
		}

		const contactToImport = [];

		switch (importMethod) {
			case 'microsoft': {
				for (const imported of selectedRows) {
					const email = imported.emailAddresses[0].address;
					const input = {
						input: {
							firstName: imported.givenName
								? imported.givenName
								: imported.displayName,
							lastName: imported.surname
								? imported.surname
								: imported.displayName,
							emailAddress: email.toLowerCase(),
							userId: currentUser.id,
							companyId: currentUser.companyId,
							importMethod: 'microsoft',
							socialMediaAccounts: null,
							inviteStatus: 'pending',
						},
					};
					contactToImport.push(input.input);
					if (this.state.checkAll === false) {
						this.submitContact(currentUser.contacts, input, onCreateContact);
					}
				}

				if (this.state.checkAll === true) {
					contactImportAPI(contactToImport, currentUser.contacts);
				} else {
					message.success('Contacts Imported');
				}

				this.setState({
					selectedRows: [],
					checkAll: false,
				});
				this.props.client.mutate({
					mutation: gql(updateUser),
					variables: {
						input: {
							id: currentUser.id,
							connectedApps: JSON.stringify(connected),
						},
					},
				});
				contactsChoice(0);
				handleCancel();
				message.success('Contacts Imported');
				break;
			}

			case 'google': {
				for (const imported of selectedRows) {
					const input = {
						input: {
							firstName:
								_.get(imported, 'gd$name.gd$givenName.$t') ||
								_.get(imported, 'names[0].givenName') ||
								null,
							lastName:
								_.get(imported, 'gd$name.gd$familyName.$t') ||
								_.get(imported, 'names[0].familyName') ||
								null,
							emailAddress:
								_.get(imported, 'gd$email[0].address').toLowerCase() ||
								_.get(imported, 'emailAddresses[0].value').toLowerCase() ||
								undefined,
							userId: currentUser.id,
							companyId: currentUser.companyId,
							phoneNumber:
								_.get(imported, 'gd$phoneNumber[0].$t') ||
								_.get(imported, 'phoneNumbers[0].value') ||
								null,
							importMethod: 'google',
							socialMediaAccounts: null,
							inviteStatus: 'pending',
						},
					};
					if (
						this.validateEmail(input.input.emailAddress) !== true ||
						input.input.emailAddress.length > 40
					) {
						input.input.emailAddress = undefined;
					}

					if (this.validatePhone(input.input.phoneNumber) !== true) {
						input.input.phoneNumber = null;
					}

					contactToImport.push(input.input);
					if (this.state.checkAll === false) {
						this.submitContact(currentUser.contacts, input, onCreateContact);
					}
				}

				if (this.state.checkAll === true) {
					contactImportAPI(contactToImport, currentUser.contacts);
				} else {
					message.success('Contacts Imported');
				}

				this.setState({
					selectedRows: [],
					checkAll: false,
				});
				this.props.client.mutate({
					mutation: gql(updateUser),
					variables: {
						input: {
							id: currentUser.id,
							connectedApps: JSON.stringify(connected),
						},
					},
				});
				contactsChoice(0);
				handleCancel();
				break;
			}

			case 'linkedin': {
				break;
			}

			default: {
				break;
			}
		}
	};

	submitContact = (currentContacts, selectedContact, onCreateContact) => {
		const isDuplicate = _.some(currentContacts, {
			emailAddress: get(
				selectedContact,
				'input.emailAddress',
				''
			).toLowerCase(),
		});
		if (isDuplicate === true) {
			message.error(selectedContact.input.emailAddress + ' Already Imported');
		} else {
			onCreateContact(selectedContact);
			this.props.updateUserIncentiveEligibility(true);
		}
	};

	validateEmail = (email) => {
		const re =
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[(?:\d{1,3}\.){3}\d{1,3}])|(([a-zA-Z\-\d]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(email).toLowerCase());
	};

	validatePhone = (phone) => {
		const re =
			/^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?(\d{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/;
		return re.test(String(phone).toLowerCase());
	};

	render() {
		const { importedContacts, selectedRows, selectedRowKeys } = this.state;
		const templates = [];
		const rowSelection = {
			selectedRowKeys,
			selectedRows,
			onChange: this.onSelectChange,
			onSelectAll: this.onSelectAll,
		};
		const importSource = (source) => {
			switch (source) {
				case 'microsoft': {
					templates.push(
						<div key="ContactContainer" className={contactContainer}>
							<Table
								ref={this.tableRef}
								rowSelection={rowSelection}
								rowKey="id"
								expandIconAsCell={false}
								expandIconColumnIndex={-1}
								columns={this.columns}
								loading={importedContacts.length < 0}
								pagination={{ pageSize: 50, showSizeChanger: false }}
								dataSource={this.state.importedContacts}
								scroll={{ y: 240 }}
							/>
						</div>
					);

					break;
				}

				case 'google': {
					templates.push(
						<div key="ContactContainer" className={contactContainer}>
							<Table
								ref={this.tableRef}
								rowSelection={rowSelection}
								rowKey={(record) =>
									_.get(record, 'id.$t') || _.get(record, 'resourceName')
								}
								columns={this.columns}
								loading={importedContacts.length < 0}
								pagination={{ pageSize: 50, showSizeChanger: false }}
								dataSource={this.state.importedContacts}
								scroll={{ y: 240 }}
							/>
						</div>
					);

					break;
				}

				default: {
					break;
				}
			}
		};

		if (this.state.importedContacts) {
			templates.push(
				<div key="importedContacts">
					{importSource(this.props.importMethod)}
				</div>
			);
		}

		if (this.state.selectedRows.length > 0) {
			templates.push(
				<Button
					key="importBtn"
					className={ImportButton}
					onClick={this.handleSubmit}
				>
					Import &nbsp;{selectedRows.length}&nbsp; Contacts
				</Button>
			);
		}

		return <div>{templates}</div>;
	}
}

export default withApollo(ContactChecklist);
