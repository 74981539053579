import React from 'react';
import recipientIcon from './../.../../../../assets/svg/recipient.svg';
import clapIcon from './../.../../../../assets/svg/clap.svg';
import { CoreBadge } from '../../../RnRCommonComponent';
import formatDate from './../../models/commonDateUtils.jsx';
import clapBlue from './../../../assets/svg/clap_blue.svg';
import { ReloadOutlined } from '@ant-design/icons';
import { Popover, Spin } from 'antd';
import uuid from 'uuid/v4';
import { addCommas } from '../../../rewardsAndRecognitionHelper/utils.js';

const NewestFeed = (props) => {
	const {
		feedListData,
		handleClapCount,
		fetchMoreData,
		isLoadMoreCalled,
		hasMoreData,
	} = props;



	const reactionDetails = (itemInteraction) => {
		const listNames = itemInteraction?.positiveInteractionNames || [];

		const additionalCounts = itemInteraction?.totalCounts > 1
			? itemInteraction.totalCounts - 1
			: null;

		const content = () => {
			const renderedList = listNames?.map((item) => {
				return (
					<div className="feed-author">
						<div className="feed-img" style={{display: 'inline-block', margin: '0 4px 4px 0'}}>
							<img src={recipientIcon} alt="img" />
						</div>
						<span key={uuid()}>{item}</span>
					</div>
				);
			});

			return (
				<div style={{
					maxHeight: '300px',
					overflowY: 'scroll',
					textOverflow: 'ellipsis',
					width: '130px',
				}}>
					{renderedList}
				</div>
			)
		};

		return (
			<Popover overlayInnerStyle={{width: '100%'}} trigger="hover" content={additionalCounts > 0 ? content : null}>
				<span style={{cursor: 'default'}}>
					{(itemInteraction?.click ? 'You' : listNames[0]) + (additionalCounts ? ` and ${additionalCounts} others` : '') + (!additionalCounts && itemInteraction?.click ? ' clapped' : '')}
				</span>
			</Popover>
	)};

	return (
		<>
			{feedListData &&
				feedListData?.map((item, index) => {
					return (
						<div key={index}>
							<div className="feed-card" key={index}>
								<div
									className="about-feed"
									style={{
										display: 'flex',
										justifyContent: 'space-between',
										flexDirection: 'column',
										rowGap: '10px',
									}}
								>
									{item.employee != null && (
										<div style={{ display: 'flex', alignItems: 'center' }}>
											<div className="feed-author">
												<div className="feed-img">
													<img src={recipientIcon} alt="img" />
												</div>
												<div className="feed-name">
													{item?.employee.firstName} {item.employee.lastName}
												</div>
											</div>
											{item?.badge?.badgeType?.code ? (
												<div className="feed-reward-logo">
													<CoreBadge
														useTilt={false}
														badgeType={item?.badge?.badgeType?.code}
														badgeIcon={item?.badge?.icon?.code}
														label={item?.badge?.label}
														color={item?.badge?.color}
														gradientColor={item?.badge?.gradientColor || item?.badge?.gradientcolor}
													/>
												</div>
											) : (
												<></>
											)}
											{item?.points && item?.points === 0 ? (
												<></>
											) : (
												item?.points > 0 && (
													<div className="feed-points">
														+{addCommas(item?.points)} points
													</div>
												)
											)}
										</div>
									)}
								</div>

								{item?.content?.message && (
									<div
										className="feed-reply py-2"
										style={{ wordWrap: 'break-word' }}
									>
										{item?.content?.message}
									</div>
								)}

								{item?.content?.gifURL && (
									<div className="feed-image my-2">
										<img
											src={item?.content?.gifURL}
											style={{ height: 236, width: '100%' }}
										/>
									</div>
								)}
								<div className="send-by d-flex align-items-center justify-content-end">
									<div className="send-by-text">Sent by</div>
									<div className="send-by-name">
										{item?.sentBy?.firstName} {item?.sentBy?.lastName}
									</div>
								</div>
							</div>
							<div className="d-flex align-items-center justify-content-between card-detail">
								<div className="d-flex align-items-center">
									<img
										src={
											item?.feedInteraction?.click === true
												? clapBlue
												: clapIcon
										}
										onClick={() => {
											handleClapCount(
												item.id,
												!item?.feedInteraction?.click,
												item.feedInteraction.id
											);
										}}
										style={{ cursor: 'pointer' }}
										className="clap-icon pr-1"
									/>
									<div>{item?.feedInteraction?.totalCounts > 0 ? reactionDetails(item.feedInteraction) : item?.feedInteraction?.totalCounts }</div>
								</div>
								<div
									style={{ color: '#b1b1b1' }}
									className="d-flex align-items-center"
								>
									{formatDate(item?.createdAt, true)}
								</div>
							</div>
						</div>
					);
				})}
			{hasMoreData ? (
				<div>
					{isLoadMoreCalled ? (
						<Spin className="d-flex align-items-center justify-content-center w-100 h-100" />
					) : (
						<div
							className="cursor-pointer d-flex align-items-center justify-content-center"
							onClick={() => {
								fetchMoreData();
							}}
						>
							<ReloadOutlined className="mr-1" />
							Load More
						</div>
					)}
				</div>
			) : (
				<></>
			)}
		</>
	);
};

export default NewestFeed;
