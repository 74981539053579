/** Packages */
import { Button } from 'antd';

/** Components & Styles */
import ResetProfilePasswordModal from '../../_shared/components/reset-password-modal/ResetProfilePasswordModalComponent.jsx';

export function PasswordLink(props) {
	const {
		userId,
		visible,
		showModal,
		handlePasswordCancel,
		passwordText,
		resetPasswordText,
		theme,
	} = props;
	return (
		<div className="link-wrap">
			<h4 className="profile-heading">{passwordText}</h4>
			<Button type="link" to="#" onClick={showModal}>
				{resetPasswordText}
			</Button>
			<ResetProfilePasswordModal
				theme={theme}
				visible={visible}
				handlePasswordCancel={handlePasswordCancel}
				userId={userId}
			/>
		</div>
	);
}
