import React, { useState } from "react";
import PhysicalRewardsListTable from "../../presentation/PhysicalRewardsListTable/PhysicalRewardsListTable.jsx";
import { message } from 'antd';
import useCommonHooks from '../../../home/container/hooks/useCommonHooks.js';
import ViewRewardModal from "../../../RnRCommonComponent/ViewRewardModal/ViewRewardModal.jsx";
import { useRnRCommonService } from "../../../RnRCommonComponent/service/RnRCommon.service.jsx";
import { usePhysicalRewardsService } from "../../service/physicalRewards.service.jsx";

const PhysicalRewardsListContainer = () => {

  const [isViewRewardModalVisible, setIsViewRewardModalVisible] = useState(false);
  const [rewardDetails, setRewardDetails] = useState();
  const [isModalLoaderVisible, setIsModalLoaderVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const physicalRewardsServiceAPI = usePhysicalRewardsService();
  const { isLoading, data } = useCommonHooks(
    physicalRewardsServiceAPI.getPhysicalRewardsList({ page: currentPage })
  );
  const commonServiceAPI = useRnRCommonService();

  const handleOpenViewReward = async (id) => {
    try {
      setIsViewRewardModalVisible(true)
      setIsModalLoaderVisible(true);
      const response = await commonServiceAPI.getAdminIssuedBadge(id);
      setIsModalLoaderVisible(false);
      if (response?.code === 200) {
        if (response?.data) {
          setRewardDetails(response.data);
        }
      } else {
        message.error('Failed to fetch badge details! Try again later.');
      }
    } catch (error) {
      setIsModalLoaderVisible(false);
      console.log(error);
      message.error('Failed to fetch badge details! Try again later.');
    }
  }

  const handleCloseViewReward = () => { setIsViewRewardModalVisible(false) }

  const handleOnPageChange = (page) => {
    setCurrentPage(page.current);
  }

  return <>
    <ViewRewardModal
      isViewRewardModalVisible={isViewRewardModalVisible}
      handleCloseViewReward={handleCloseViewReward}
      rewardDetails={rewardDetails}
      isModalLoaderVisible={isModalLoaderVisible}
      isSharingVisible={false}
    />
    <PhysicalRewardsListTable
      physicalRewardsList={data?.records}
      handleOpenViewReward={handleOpenViewReward}
      handleOnPageChange={handleOnPageChange}
      isTableLoaderVisible={isLoading}
    />
  </>;
};

export default PhysicalRewardsListContainer;
