import { Button, Modal, Row, Table } from 'antd';
import { get } from 'lodash';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Spinner from 'src/_shared/components/spinner/SpinnerComponent.jsx';
import { ml, downloadFromS3Signed } from 'src/_shared/services/utils.js';
import { ConditionalWrapper } from 'src/helpers';

function InternalMobilityCard({
	allMultiLingualData,
	currentUser,
	matches,
	loading,
}) {
	const [selfReferrals, setSelfReferrals] = useState([...matches]);
	const [visible, setVisible] = useState(false);

	useEffect(() => {
		const fetchAvatarURLs = async () => {
			const selfReferralsWithAvatarURLs = await Promise.all(
				matches.map(async (record) => {
					const avatarSrc = record?.avatar?.key
						? await downloadFromS3Signed(record.avatar.key)
						: '';

					return {
						...record,
						avatarSrc,
					};
				})
			);
			setSelfReferrals([...selfReferralsWithAvatarURLs]);
		};

		fetchAvatarURLs();
	}, [matches]);

	const columns = [
		{
			title: '',
			key: (user) => `avatar${get(user, 'id')}`,
			width: 70,
			visible: true,
			render(user) {
				return (
					<div className="custom-image-wrap">
						{user.avatar ? (
							<img
								src={user.avatarSrc}
								className="custom-img"
								alt={`${user.firstName} ${user.lastName}`}
							/>
						) : (
							<>
								{user.firstName && user.lastName ? (
									<>
										{user.firstName[0]}
										{user.lastName[0]}
									</>
								) : null}
							</>
						)}
					</div>
				);
			},
		},
		{
			title: ml('Name', currentUser, allMultiLingualData),
			visible: true,
			key: (user) => `name${get(user, 'id')}`,
			render(user) {
				return user.firstName && user.lastName && user.cognitoId ? (
					<Link
						className="table-link"
						to={{
							pathname: `/openDetails/${user.id}`,
							state: { currentUser: user.currentUser },
						}}
					>
						{user.firstName} {user.lastName}
					</Link>
				) : user.firstName && user.lastName && !user.cognitoId ? (
					<>
						{user.firstName} {user.lastName}
					</>
				) : null;
			},
			showSorterTooltip: false,
			sorter: (a, b) =>
				sort(
					`${get(a, 'firstName')}
              ${get(a, 'lastName')}`,
					`${get(b, 'firstName')}
              ${get(b, 'lastName')}`
				),
		},
		{
			title: ml('Email Address', currentUser, allMultiLingualData),
			visible: false,
			key: (user) => `email${get(user, 'id')}`,
			width: '20%',
			render: (user) =>
				user.emailAddress && user.cognitoId ? (
					<Link
						className="table-link"
						to={{
							pathname: `/openDetails/${user.id}`,
							state: { currentUser: user.currentUser },
						}}
					>
						{user.emailAddress}
					</Link>
				) : (
					<>{user.emailAddress}</>
				),
			showSorterTooltip: false,
			sorter: (a, b) => sort(a.emailAddress, b.emailAddress),
		},
		{
			title: ml('Job Title', currentUser, allMultiLingualData),
			visible: true,
			key: (user) => `title${get(user, 'id')}`,
			render: (user) => (get(user, 'title') ? <>{get(user, 'title')}</> : null),
			sorter(a, b) {
				return sort(get(a, 'title'), get(b, 'title'));
			},
		},
		{
			title: ml('Department', currentUser, allMultiLingualData),
			visible: true,
			key: (user) => `department${get(user, 'id')}`,
			render: (user) => <>{get(user, 'department.name')}</>,
			sorter: (a, b) =>
				sort(get(a, 'department.name', 'z'), get(b, 'department.name', 'z')),
		},
	];

	const locale = {
		emptyText: <Spinner company={get(currentUser, 'company')} />,
	};

	return (
		<div className="mj-referral-card">
			<div className="mjr-card-head">
				<p className="mjr-summary-title">
					{ml('Internal Mobility Matches', currentUser, allMultiLingualData)}
				</p>
			</div>
			<div className="mjr-card-body centered">
				<ConditionalWrapper
					condition={selfReferrals.length}
					wrapper={(children) => (
						<>
							<Button
								className="mjr-card-button"
								onClick={() => setVisible(true)}
							>
								{children}
							</Button>
							<Modal
								centered
								title="Internal Candidate Matches"
								open={visible}
								width="fit-content"
								onOk={() => setVisible(false)}
								onCancel={() => setVisible(false)}
							>
								<div className="table-card table-modal">
									<Table
										pagination={{ pageSize: 50, showSizeChanger: false }}
										rowKey={(record) => record.id}
										dataSource={selfReferrals}
										columns={columns}
										locale={locale}
										scroll={{ x: 656 }}
									/>
								</div>
							</Modal>
						</>
					)}
				>
					<h4>{selfReferrals.length}</h4>
				</ConditionalWrapper>
			</div>
			<Row className="justify-content-center">Employees Matched</Row>
		</div>
	);
}

export default InternalMobilityCard;
