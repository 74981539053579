import { Col, Row } from 'antd';
import DonutChart from 'react-donut-chart';
import { borderedTile } from '../../../_shared/components/bordered-tile';
import { COLORS } from '../../../_shared/styles/colors';
import {
	bottomContainer,
	bottomSectionText,
	bottomSectionValue,
	buttonActive,
	buttonBusiness,
	chartTotal,
	divider,
	donutChart,
	headerClass,
	middleColumn,
	middleContainer,
	numTotal as numberTotal,
	opacity,
} from './businessNetworkStyles.js';

function BaseNetworkCard(props) {
	const {
		onClick,
		title,
		activeEmployees,
		inNetworkConnections,
		peopleText,
		activeEmployeesText,
		inNetworkContacts,
	} = props;
	return (
		<div onClick={onClick}>
			<h2 className={headerClass}>{title}</h2>
			<Row className={middleContainer}>
				<Col span={24} className={middleColumn}>
					<div className={donutChart}>
						<div className={chartTotal}>
							<div className={numberTotal}>
								{activeEmployees + inNetworkConnections - 1}
							</div>
							<div style={{ paddingBottom: 10 }}>{peopleText}</div>
						</div>
						<DonutChart
							height={153}
							width={140}
							colors={[COLORS.dashboardGreen, COLORS.dashboardBlue]}
							strokeColor=""
							legend={false}
							clickToggle={false}
							data={[
								{
									label: 'business',
									value: Number.parseFloat(inNetworkConnections),
									className: opacity,
								},
								{
									label: 'active',
									value: Number.parseFloat(activeEmployees),
									className: opacity,
								},
							]}
						/>
					</div>
				</Col>
			</Row>
			<div className={divider} />
			<Row className={bottomContainer}>
				<Row>
					<button className={buttonActive} />
					<span className={bottomSectionText}> {activeEmployeesText}: </span>
					<span className={bottomSectionValue}> {activeEmployees - 1} </span>
				</Row>
				<Row>
					<button className={buttonBusiness} />
					<span className={bottomSectionText}> {inNetworkContacts}: </span>
					<span className={bottomSectionValue}> {inNetworkConnections}</span>
				</Row>
			</Row>
		</div>
	);
}

export const NetworkCard = borderedTile(BaseNetworkCard);
