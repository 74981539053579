import { Radio } from 'antd';
import { useState } from 'react';
import { Tab } from './TabComponent.jsx';
import { TabContent } from './TabContentComponent.jsx';

/**
 * A set of tabs, used to display different content. Keyboard accessible.
 * @component
 * @param {object} props - The label for the TabList and an array of tabValues
 * @param {string} props.label - The label for the entire TabList, used internally for ids, classes, keys, & accessibility
 * @param {Array<{title: string, child: React.Component, ref: React.Ref}>} props.tabValues
 * @param {React.CSSProperties} props.tabStyles - Styles used for the Tab component
 * @param {React.CSSProperties} props.contentStyles - Styles used for the TabContent component
 * @param {string} props.tabValues.title - The title of a specific tab, used internally for ids, classes, keys & accessibility
 * @param {React.Component} props.tabValues.child - The component to be rendered when that tab is selected
 * @param {React.Ref} ref - The ref for this tab, used to control focus
 * @returns {React.Component}
 */
export function TabList({
	label,
	tabValues,
	tabStyles,
	contentStyles,
	className,
}) {
	const [selectedTab, setSelectedTab] = useState(0);

	const handleClick = (index) => setSelectedTab(index);

	const handleNextTab = (startingTab, nextTab, endingTab) => {
		const tabToSelect = selectedTab === endingTab ? startingTab : nextTab;
		setSelectedTab(tabToSelect);
		tabValues[tabToSelect].ref.current.focus();
	};

	const handleKeyPress = (e) => {
		const tabCount = tabValues.length - 1;

		if (e.key === 'ArrowLeft') {
			handleNextTab(tabCount, selectedTab - 1, 0);
		}

		if (e.key === 'ArrowRight') {
			handleNextTab(0, selectedTab + 1, tabCount);
		}
	};

	return (
		<section className={className}>
			<Radio.Group
				className="tab-list"
				role="tablist"
				aria-label={label}
				buttonStyle="solid"
				defaultValue={0}
				onKeyDown={handleKeyPress}
			>
				{tabValues.map((value, index) => (
					<Tab
						key={`${label}-tab-${value.title}`}
						id={`${label}-tab-${value.title}`}
						index={index}
						selectedTab={selectedTab}
						tabPanelId={`${label}-tab-panel-${value.title}`}
						title={value.title}
						handleChange={handleClick}
						tabRef={value.ref}
						style={tabStyles}
					/>
				))}
			</Radio.Group>

			{tabValues.map((value, index) => (
				<TabContent
					key={`${label}-tab-panel-${value.title}`}
					id={`${label}-tab-panel-${value.title}`}
					selectedTab={selectedTab}
					tabId={`${label}-tab-${value.title}`}
					tabIndex={index}
					style={contentStyles}
				>
					{value.child}
				</TabContent>
			))}
		</section>
	);
}
