import gql from 'graphql-tag';
import get from 'lodash/get';
import { compose, graphql } from 'react-apollo';
import { JobMatchesByJobId } from '../../graphql/custom/jobMatch';
import { updateJobMatch } from '../../graphql/default';

export const withJobMatchByJobId = (Component, variables) => {
	return compose(
		graphql(JobMatchesByJobId, {
			options: {
				context: {
					headers: {
						'x-frame-options': 'deny', // This header will reach the server
					},
				},
				variables,
				fetchPolicy: 'cache-and-network',
			},
			props(response, props) {
				const matchById = get(
					response,
					'data.queryJobMatchesByJobIdIndex.items',
					get(props, 'jobMatches', [])
				);
				const filteredMatches = matchById.filter(
					(match) => match.userId === response.ownProps.currentUser.id
				);
				return {
					jobMatches: filteredMatches,
				};
			},
		}),
		graphql(gql(updateJobMatch), {
			props: (props) => ({
				onUpdateMatch(input) {
					const optimisticResponseData = {
						...props.jobMatch,
						...input.input,
					};
					props.mutate({
						variables: input,
						optimisticResponse: {
							__typename: 'Mutation',
							updateJobMatch: {
								...optimisticResponseData,
								__typeName: 'updateJobMatch',
							},
						},
						update(proxy) {
							const data = proxy.readQuery({
								query: JobMatchesByJobId,
								variables,
							});
							proxy.writeQuery({
								query: JobMatchesByJobId,
								data,
								variables,
							});
						},
					});
				},
			}),
		})
	)(Component);
};
