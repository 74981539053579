export const updateCustomPage = `mutation UpdateCustomPage($input: UpdateCustomPageInput!) {
    updateCustomPage(input: $input) {
      id
      companyId
      content
      dateCreated
      userId
      pageNumber
    }
  }
  `;
