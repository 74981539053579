import { CheckOutlined, InboxOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Input, Select, Upload, message } from 'antd';
import { cx } from 'emotion';
import get from 'lodash/get';
import { Component } from 'react';
import WebIcon from 'src/_shared/components/WebIconComponent.jsx';
import { USER_ROLES } from 'src/_shared/constants/';
import { COLORS } from '../../_shared/styles/colors';
import {
	CheckIcon,
	FormItemStyles,
	FormStyles,
	InputStyles,
	LabelStyles,
	SubmitBtn as SubmitButton,
	SubmitBtnContainer as SubmitButtonContainer,
} from './editUserProfileFormStyles.js';
import { SelectContainer, SelectStyles, SortDown } from './selectStyles.js';

class EditUserProfileForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedDepartment: {
				key: props.user.departmentId,
				label: props.user.department ? props.user.department.name : null,
			},
		};
	}

	handleSelect = (value) => {
		this.setState({ selectedDepartment: value });
	};

	handleSubmit = (e) => {
		e.preventDefault();
		const { user } = this.props;
		this.props.form.validateFields((error, values) => {
			if (error) {
				this.props.handleError();
				return;
			}

			this.props.onUpdate({
				input: {
					avatar: user.avatar,
					firstName: values.firstName,
					lastName: values.lastName,
					title: values.title,
					departmentId: this.state.selectedDepartment.key,
					emailAddress: get(values, 'email', '').toLowerCase(),
					companyId: user.companyId,
					role: user.role,
					active: user.active,
					id: user.id,
				},
			});
		});
	};

	render() {
		const { user, currentUser, departments } = this.props;
		const { selectedDepartment } = this.state;
		const { Option } = Select;
		const options = [];
		for (const department of departments) {
			options.push(<Option key={department.id}>{department.name}</Option>);
		}

		const { getFieldDecorator } = this.props.form;
		const FormItem = Form.Item;
		const disabled = currentUser.displayAs !== USER_ROLES.ADMIN;
		const props = {
			name: 'file',
			multiple: false,
			action: '//jsonplaceholder.typicode.com/posts/',
			onChange(info) {
				const { status } = info.file;
				if (status === 'done') {
					message.success(`${info.file.name} file uploaded successfully.`);
				} else if (status === 'error') {
					message.error(`${info.file.name} file upload failed.`);
				}
			},
		};
		return (
			<Form className={{ FormStyles }}>
				<FormItem className={FormItemStyles}>
					<div>
						{getFieldDecorator('Avatar', {
							valuePropName: 'avatar',
							getValueFromEvent: this.normFile,
						})(
							<label className={LabelStyles}>
								New Profile Picture:
								<Upload.Dragger className={InputStyles} {...props}>
									<p>
										<InboxOutlined />
									</p>
									<p>
										Click here to upload new profile picture, or drag a file to
										this area to upload.
									</p>
								</Upload.Dragger>
							</label>
						)}
					</div>
				</FormItem>
				<FormItem className={cx(InputStyles, FormItemStyles)}>
					<label className={LabelStyles}>First Name:</label>
					{getFieldDecorator('firstName', {
						initialValue: `${user.firstName}`,
						rules: [{ required: true, message: 'Please input First Name!' }],
					})(<Input placeholder="First Name" />)}
				</FormItem>
				<FormItem className={cx(InputStyles, FormItemStyles)}>
					<label className={LabelStyles}>Last Name:</label>
					{getFieldDecorator('lastName', {
						initialValue: `${user.lastName}`,
						rules: [{ required: true, message: 'Please input Last Name!' }],
					})(<Input placeholder="Last Name" />)}
				</FormItem>
				<FormItem className={cx(InputStyles, FormItemStyles)}>
					<label className={LabelStyles}>Department:</label>
					<div className={SelectContainer}>
						<Select
							labelInValue
							defaultValue={{
								key: user.departmentId,
								label: user.department ? user.department.name : null,
							}}
							className={SelectStyles}
							value={{
								key: selectedDepartment.key,
								label: selectedDepartment.label,
							}}
							showArrow={false}
							placeholder={
								<p style={{ fontSize: 16, margin: 0 }}>Add a Department</p>
							}
							onSelect={(value) => this.handleSelect(value)}
						>
							{options}
						</Select>
						<WebIcon
							className={SortDown}
							color={COLORS.darkGray}
							size={14}
							name="sort-down"
						/>
					</div>
				</FormItem>
				<FormItem className={cx(InputStyles, FormItemStyles)}>
					<label className={LabelStyles}>Job Title:</label>
					{getFieldDecorator('title', {
						initialValue: `${user.title}`,
						rules: [
							{ required: true, message: 'Please input your job title!' },
						],
					})(<Input placeholder="Job Title" />)}
				</FormItem>
				<FormItem className={cx(InputStyles, FormItemStyles)}>
					<label className={LabelStyles}>Email:</label>
					{getFieldDecorator('email', {
						initialValue: `${user.emailAddress}`,
						rules: [
							{ required: true, message: 'Please enter your email address!' },
						],
					})(<Input disabled={disabled} placeholder="Email" />)}
				</FormItem>
				<FormItem className={SubmitButtonContainer}>
					<Button
						className={SubmitButton}
						htmlType="submit"
						onClick={this.handleSubmit}
					>
						Submit
						<CheckOutlined className={CheckIcon} />
					</Button>
				</FormItem>
			</Form>
		);
	}
}

export default Form.create()(EditUserProfileForm);
