import { css } from 'emotion';
import get from 'lodash/get';
import LoginBackground from '../_shared/assets/Erin_login_background.jpg';
import { downloadFromS3Signed } from '../_shared/services/utils.js';

export const LandingPageContainer = async (company) => {
	const backgroundUrl = get(company, 'background.key');
	const whiteLabel = get(company, 'whiteLabel');
	let image;
	if (!whiteLabel) {
		image = `url(${LoginBackground})`;
	} else if (whiteLabel && backgroundUrl) {
		image = `url(${await downloadFromS3Signed(backgroundUrl, 'erin-images')})`;
	}

	let brandColor = '#f26d6e';
	if (get(company, 'whiteLabel')) {
		brandColor = get(company, 'brandColor', brandColor);
	}

	return css({
		backgroundColor: brandColor,
		backgroundImage: image,
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		minHeight: '100vh',
		height: '100%',
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		overflow: 'hidden',
	});
};

export const Logo = css({
	width: '22em',
});

export const Card = css({
	fontFamily: '"Open Sans", sans-serif',
	boxShadow: '1px 1px 15px rgba(0,0,0,0.25)',
	borderRadius: 4,
	backgroundColor: 'white',
	padding: 30,
	marginTop: 10,
	display: 'flex',
	width: '85%',
	maxWidth: 625,
});

export const NavBar = css({
	marginTop: 15,
	fontSize: 16,
	fontWeight: '600 !important',
	color: 'white !important',
	'& a': {
		textDecoration: 'underline',
		fontSize: 16,
		fontWeight: '600 !important',
		color: 'white !important',
	},
	'& :hover': {
		color: 'white',
	},
});

export const TagLine = css({
	marginTop: 0,
	fontSize: 18,
	fontWeight: '300 !important',
	color: 'white !important',
	'& a': {
		textDecoration: 'none',
		fontSize: 16,
		fontWeight: '600 !important',
		color: 'white !important',
	},
	'& :hover': {
		color: 'white',
	},
});

export const TitleStyles = css({
	marginBottom: 0,
	textAlign: 'center',
	fontSize: 32,
});

export const UnauthTextStyles = css({
	marginBottom: 0,
	textAlign: 'center',
	fontSize: 26,
});
