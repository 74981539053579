import React, { useState } from 'react';
import { useEffect } from 'react';
import { message } from 'antd';
import RewardsLeaderBoard from './../../presentation/rewardsLeaderBoard/RewardsLeaderBoard.jsx';
import { useHomeService } from './../../service/home.services.jsx'

const RewardsLeaderBoardContainer = () => {
	const [leaderboardData, setLeaderBoardData] = useState();
	const homeService = useHomeService()

	useEffect(() => {
		getRewardLeaderBoardData();
	}, []);

	const getRewardLeaderBoardData = () => {
		homeService
			.getLeaderBoardData()
			.then((rewardLeaderBoardResponse) => {
				if (rewardLeaderBoardResponse?.data) {
					setLeaderBoardData(rewardLeaderBoardResponse?.data);
				} else {
					message.error('Failed to load leaderboard list');
				}
			})
			.catch((error) => {
				console.log(error);
				message.error('Failed to load leaderboard list');
			});
	};

	return (
		<div>
			<RewardsLeaderBoard leaderboardData={leaderboardData} />
		</div>
	);
};

export default RewardsLeaderBoardContainer;
