import { AutoComplete, Button, Input, Spin, message } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useState } from 'react';
import SendRewardSlider from './SendRewardSlider.jsx';
import { BADGE_TYPE_SEARCH } from '../../constant/homeApi.constant.js';
import { addCommas } from '../../../rewardsAndRecognitionHelper/utils.js';

const SendRewardModal = (props) => {
	const {
		sendrewardObject,
		setSendRewardObject,
		setIsOpenRewardModel,
		badgeData,
		isBadgeLoading,
		errorObject,
		setErrorObject,
		rewardsAvailable,
	} = props;
	const [inputValue, setInputValue] = useState('');
	const [selectedPage, setSelectedPage] = useState(null);

	const rewardList = badgeData?.map((badge) => ({
		value: badge.id,
		label: (
			<span>
      {badge?.name}{' '}
				<span className="badge-count">{`(${addCommas(badge?.points)} points)`}</span>
    </span>
		),
		name: badge?.name,
	}));
	const handleSearch = async (value) => {
		setInputValue(value);
		setSendRewardObject({
			...sendrewardObject,
			rewardtype: null,
			selectedBadge: null,
		});
		setSelectedPage(0);
	};
	return (
		<div className="send-reward-modal">
			<div className="select-recipient-text">
				Select a badge to send and recognize your colleague’s achievements.
			</div>

			<div>
				{isBadgeLoading ? (
					<Spin className="spinner" />
				) : (
					<div>
						<div
							className="custom-form-group mt-4"
							style={{padding: '0 72px'}}
						>
							<AutoComplete
								allowClear
								value={inputValue}
								className="w-100 custom-input search-recognitions"
								options={rewardList}
								filterOption={(inputValue, option) =>
									option?.name.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
								}
								onChange={handleSearch}
								onSelect={(value, option) => {
									const selectedReward = badgeData?.find(
										(badge) => badge.id === value
									);
									const selectedIndex = badgeData?.findIndex(
										(badge) => badge.id === value
									);
									const selectedPage = Math.floor(selectedIndex / 3) * 3;
									setSendRewardObject({
										...sendrewardObject,
										rewardtype: value,
										selectedBadge: selectedReward,
									});
									setSelectedPage(selectedPage);
									setInputValue(selectedReward?.name || '');
									setErrorObject({
										...errorObject,
										rewardtype: false,
									});
								}}
								placeholder={
									<>
										<SearchOutlined />
										<span> Search all recognitions</span>
									</>
								}
							/>
							{errorObject?.rewardtype === true ? (
								<div class="ant-form-explain mt-1">Please add badge</div>
							) : (
								<></>
							)}
						</div>

						{rewardsAvailable ? (
							<SendRewardSlider
								badgeData={badgeData}
								selectedPage={selectedPage}
								setSendRewardObject={setSendRewardObject}
								sendrewardObject={sendrewardObject}
							/>
						) : (
							<div className="d-flex alig-items-center justify-items-center">
								No badges available
							</div>
						)}
					</div>
				)}
			</div>

			<div
				className="d-flex justify-content-center pt-4"
				style={{marginTop: '20px'}}
			>
				<Button
					className="blue-bg-btn"
					onClick={() => {
						if (
							sendrewardObject?.rewardtype !== undefined &&
							sendrewardObject?.rewardtype !== null
						) {
							message.success('Your reward has been saved');
						}
						setIsOpenRewardModel(false);
					}}
					disabled={sendrewardObject?.rewardtype === undefined ||
						sendrewardObject?.rewardtype === null}
				>
					Select
				</Button>
			</div>
		</div>
	);
};
export default SendRewardModal;
