import { Button, Modal, Select, message } from 'antd';
import { Component } from 'react';
import {
	downloadFromS3Signed,
	ml,
	uploadToS3Multipart,
} from 'src/_shared/services/utils.js';
import Spinner from 'src/_shared/components/spinner/SpinnerComponent.jsx';
import { USER_ROLES } from '../../_shared/constants';

export default class EmployeesAccountClaimModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			value: null,
			filename: null,
			role: USER_ROLES.EMPLOYEE,
			submitting: false,
			importfilename: null,
			disableValue: 'Do not disable',
			sampleCSVFileURL: '',
		};
	}

	async componentDidMount() {
		const presignedURL = await downloadFromS3Signed(
			'erin_employee_account_claim_import.csv',
			'erin-sample-csv'
		);
		this.setState({ sampleCSVFileURL: presignedURL });
	}

	onDisableSelectChange = (value) => {
		this.setState({ disableValue: value });
	};

	onFileInputChange = (e) => {
		if (e.target.files && e.target.files.length > 0) {
			this.setState({ filename: e.target.files[0].name });
		}
	};

	onImportFileInputChange = (e) => {
		if (e.target.files && e.target.files.length > 0) {
			this.setState({ importfilename: e.target.files[0].name });
		}
	};

	onTextAreaBlur = (e) => {
		this.setState({ value: e.target.value });
	};

	showHideModal = () => {
		this.props.handleImportCancel();
		this.props.showModal();
	};

	submit = async (e) => {
		e.preventDefault();
		if (this.state.submitting) return;
		try {
			let submitError = false;
			let hasFiles = false;

			hasFiles = this.uploadInput.files && this.uploadInput.files.length;

			if (!hasFiles) {
				submitError = true;
				message.info('Please choose a file to import');
				return;
			}

			this.setState({ submitting: true });

			const file = this.uploadInput.files[0];

			const timestamp = new Date().toISOString().replace(/[-:.]/g, '');

			const newFilename = `${timestamp}.csv`;

			const s3Key = `${this.props.currentUser.companyId}/${newFilename}`;
			const bucketName = 'account-claim-import';

			await uploadToS3Multipart(file, s3Key, bucketName);

			const s3Url = `https://${bucketName}.s3.us-east-2.amazonaws.com/${s3Key}`;

			const payload = {
				s3FileUrl: s3Url,
				createdById: this.props.currentUser.id,
				companyId: this.props.currentUser.companyId,
				disableValue: this.state.disableValue,
				endpointToForward: 'account-claim-import-prod',
			};

			this.props
				.handleImportSubmit(payload)
				.then(() => {
					message.success('Account Claim Import request submitted');
					this.props.handleImportCancel();
					this.setState({
						submitting: false,
						importfilename: null,
						disableValue: 'Do not disable',
					});
				})
				.catch((error) => {
					console.error(error);
					message.error(
						'There was an issue submitting Account Claim Import request'
					);
					this.props.handleImportCancel();
					this.setState({ submitting: false, disableValue: 'Do not disable' });
				});
		} catch (error) {
			this.props.handleImportError(error);
			this.props.handleImportCancel();
			this.setState({ submitting: false });
		}
	};

	renderRoleOptions() {
		return (
			<Select
				className="custom-input"
				defaultValue={USER_ROLES.EMPLOYEE}
				onChange={this.onDisableSelectChange}
			>
				<Select.Option key={USER_ROLES.EMPLOYEE} value={USER_ROLES.EMPLOYEE}>
					Employee
				</Select.Option>
				<Select.Option key={USER_ROLES.ADMIN} value={USER_ROLES.ADMIN}>
					Administrator
				</Select.Option>
			</Select>
		);
	}

	renderUploadFileOptions() {
		return (
			<Select
				className="custom-input"
				defaultValue={this.state.disableValue}
				onChange={this.onDisableSelectChange}
			>
				<Select.Option key="Do_not_disable_key" value="Do not disable">
					Do not disable
				</Select.Option>
				<Select.Option key="Disable_accounts_key" value="Disable accounts">
					Disable accounts
				</Select.Option>
			</Select>
		);
	}

	render() {
		const {
			currentUser,
			allMultiLingualData,
			company,
			visible,
			handleImportCancel,
			handleImportError,
		} = this.props;
		const { sampleCSVFileURL } = this.state;

		return (
			<Modal
				destroyOnClose
				open={visible}
				footer={null}
				handleImportError={handleImportError}
				width={680}
				title="Upload Claim Your Account Users"
				onCancel={handleImportCancel}
			>
				{this.state.submitting ? (
					<Spinner company={company} />
				) : (
					<>
						<p>
							{ml(
								'Add users to be able to claim and create an account using their employee number and date of birth.',
								currentUser,
								allMultiLingualData
							)}
						</p>

						<p className="text-center">
							<a href={sampleCSVFileURL}>
								{ml(
									'Download A Sample CSV File',
									currentUser,
									allMultiLingualData
								)}
							</a>
						</p>
						<form onSubmit={this.submit}>
							<ul className="label-data" style={{ margin: '40px 0' }}>
								<li>
									<label>
										{ml(
											'Upload Your CSV File:',
											currentUser,
											allMultiLingualData
										)}
									</label>
									<div className="custom-input-file">
										<label htmlFor="file">
											{ml('Upload File', currentUser, allMultiLingualData)}
										</label>
										<input
											ref={(ref) => {
												this.uploadInput = ref;
											}}
											type="file"
											id="file"
											name="file"
											onChange={this.onImportFileInputChange}
										/>
									</div>
									{this.state.importfilename && this.state.importfilename}
								</li>
								{this.props.error && this.props.error.message && (
									<div className="input-error">{this.props.error.message}</div>
								)}
								<li>
									<label className="label-block">
										{ml(
											'Would you like to disable all accounts that are not in this file?',
											currentUser,
											allMultiLingualData
										)}
									</label>
									<div className="custom-form-group" style={{ width: '100%' }}>
										{this.renderUploadFileOptions()}
									</div>
								</li>
							</ul>

							<div className="modal-footer-btn space-between">
								<p style={{ margin: 0 }}>
									{ml('or', currentUser, allMultiLingualData) && ' '}
									<a href="javascript:void(0)" onClick={this.showHideModal}>
										{ml(
											'Invite Users By Email',
											currentUser,
											allMultiLingualData
										)}
									</a>
								</p>
								<Button
									className="btn-min-width"
									size="large"
									hidden={this.state.submitting}
									type="primary"
									onClick={this.submit}
								>
									{ml('Submit', currentUser, allMultiLingualData)}
								</Button>
							</div>
						</form>
					</>
				)}
			</Modal>
		);
	}
}
