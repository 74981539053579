import { css } from 'emotion';
import get from 'lodash/get';
import { COLORS } from '../../styles/colors';

export const shareBtn = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 200,
	color: '#404447',
	background: 'COLORS.white',
	fontSize: 14,
	height: 40,
	lineHeight: '25px',
	width: '100%',
	border: '1.5px solid #8d99a3',
	borderRadius: '10px',
});

export const btnIcon = css({
	fontSize: 20,
	position: 'absolute',
	paddingTop: 3,
	marginLeft: -5,
});

export const btnText = css({
	paddingBottom: 3,
	paddingLeft: 3,
});

export const BtnContainer = (theme) => {
	let buttonColor = COLORS.blue;
	if (get(theme, 'enabled')) {
		buttonColor = get(theme, 'buttonColor', buttonColor);
	}

	return css({
		overflow: 'hidden',
		'& .ant-btn: hover:enabled': {
			color: buttonColor,
			borderColor: buttonColor,
		},
		'& .ant-btn: disabled': {
			color: '#CCCCCC',
			border: 'none',
			backgroundColor: '#F0F0F0',
		},
		'& button:hover > div > svg > path': {
			fill: COLORS.buttonColor,
		},
		'& button:focus > div > svg > path': {
			fill: COLORS.buttonColor,
		},
	});
};

export const NotInterestedBtnContainer = css({
	overflow: 'hidden',
	'& .ant-btn: hover:enabled': {
		color: COLORS.red,
		borderColor: COLORS.red,
	},
	'& .ant-btn: disabled': {
		color: '#CCCCCC',
		border: 'none',
		backgroundColor: '#F0F0F0',
	},
	'& button:hover > div > svg > path': {
		fill: COLORS.red,
		backgroundColor: COLORS.red,
	},
	'& button:focus > div > svg > path': {
		fill: COLORS.red,
		backgroundColor: COLORS.red,
	},
});

export const btnContainer = css({
	height: '100%',
	display: 'flex',
});

export const ModalStyles = css({
	'& .ant-modal-content': {
		borderRadius: 20,
	},
	fontFamily: 'Open Sans',
	'& .ant-modal-close-x': {
		fontSize: 30,
		paddingTop: 10,
		color: COLORS.lightGray,
	},
});

export const ModalStylesSubmitting = css({
	'& .ant-modal-content': {
		borderRadius: 20,
		height: 750,
		width: 520,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	fontFamily: 'Open Sans',
	'& .ant-modal-close-x': {
		color: 'white',
	},
});

export const ModalTitle = (theme) => {
	let buttonColor = COLORS.red;
	if (get(theme, 'enabled')) {
		buttonColor = get(theme, 'buttonColor', buttonColor);
	}

	return css({
		width: '100%',
		textAlign: 'center',
		color: buttonColor,
		fontSize: 36,
		marginBottom: 0,
		fontWeight: 600,
		paddingBottom: 20,
	});
};

export const AppliedModalTitle = (theme) => {
	let buttonColor = COLORS.green;
	if (get(theme, 'enabled')) {
		buttonColor = get(theme, 'buttonColor', buttonColor);
	}

	return css({
		width: '100%',
		textAlign: 'center',
		color: buttonColor,
		fontSize: 36,
		marginBottom: 0,
		fontWeight: 600,
		paddingBottom: 20,
	});
};

export const ModalText = css({
	fontSize: 18,
	fontWeight: 'normal',
	width: '100%',
	textAlign: 'center',
	paddingBottom: 20,
});

export const SubmitBtn = css({
	fontFamily: 'Open Sans',
	backgroundColor: COLORS.red,
	color: 'white',
	fontSize: 20,
	fontWeight: 300,
	padding: '10px 30px',
	height: 'auto',
	border: 'none',
	marginBottom: 0,
});

export const SubmitBtnContainer = css({
	width: '100%',
	display: 'flex',
	justifyContent: 'center',
	marginBottom: 0,
	'& .ant-form-item-control-wrapper': {
		'@media (max-width: 575px)': {
			width: 'auto',
		},
	},
});

export const linkStyles = css({
	fontWeight: '600 !important',
	color: COLORS.hyperLink,
});

export const footerTextStyles = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 400,
	color: COLORS.heading,
	fontSize: '1.25em',
});

export const hiringManagerLinkStyles = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 400,
	color: COLORS.blue,
	fontSize: '1.25em',
	marginLeft: 5,
});

export const checkCircle = css({
	fontSize: 150,
	fontWeight: 800,
	color: '#1abb4b',
	paddingBottom: 20,
	display: 'inline-block',
});

export const fileWrap = css`
	display: inline-block;
	position: relative;
	font-weight: bold;
	color: ${COLORS.blue};
	label {
		cursor: pointer;
	}
	input {
		opacity: 0;
		width: 0.1px;
	}
`;
export const disableButton = css({
	pointerEvents: 'none',
	opacity: 0.4,
});

export const userEmail = css({
	color: COLORS.red,
	fontWeight: 600,
});

export const reqNumberContainer = css({
	fontSize: 12,
	marginTop: 5,
	marginBottom: 0,
});

export const reqNumber = css({
	color: COLORS.gray3,
	fontWeight: 500,
	padding: '0 2px',
});

export const locationStyles = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 400,

	color: COLORS.darkGray,
	fontSize: 14,
	paddingRight: 5,
});

export const Department = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 400,
	color: COLORS.darkGray,
	fontSize: 14,
	paddingRight: 5,
});
