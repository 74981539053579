import { css } from 'emotion';
import { COLORS } from '../_shared/styles/colors';

const breakpoints = [576, 767, 991, 1200, 375];

const mq = breakpoints.map((bp) => `@media (max-width: ${bp}px)`);

export const ReferralsDashboardContainer = css({
	width: '100%',
	height: '100%',
	padding: 20,
	[mq[2]]: {
		padding: '20px 5px',
	},
});
export const searchComponent = css({
	width: '33%',
	margin: '4px 0 24px 0',
	[mq[1]]: {
		width: '100%',
		margin: '0 0 10px 0',
	},
});

export const StyledHeading = css({
	fontFamily: '"Open Sans", sans-serif',
	color: '#444444',
	fontWeight: 600,
});

export const SortDown = css({
	zIndex: 99,
});

export const styleFont = css({
	fontFamily: '"Open Sans", sans-serif',
});

export const tableHeader = css({
	fontFamily: '"Open Sans", sans-serif !important',
	fontWeight: '600 !important',
});

export const tableHeaderContainer = css({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'left',
	position: 'relative',
});

export const ToolBar = css({
	marginBottom: 20,
});

export const StatusFilter = css({
	display: 'flex',
	marginLeft: 15,
	padding: 0,
	'& h3': {
		marginRight: 10,
		fontSize: 16,
		lineHeight: '32px',
		marginBottom: 0,
	},
	'& .ant-btn': {
		lineHeight: '12px',
	},
	[mq[0]]: {
		'& h3': {
			marginRight: 5,
			fontSize: 12,
		},
	},
	[mq[1]]: {
		marginLeft: 0,
	},
	marginTop: 10,
});

export const FilterContainer = css({
	display: 'flex',
	alignItems: 'center',
	marginRight: 10,
	[mq[1]]: {
		flexDirection: 'column',
		alignItems: 'flex-start',
		width: '100%',
		'& > div': {
			marginBottom: 10,
		},
	},
	marginTop: 10,
});

export const TableContainer = css({
	borderRadius: 10,
	backgroundColor: 'white',
	'& thead > tr > th': {
		backgroundColor: 'white',
		fontWeight: 600,
	},
	'& tbody > tr > td': {
		paddingTop: 0,
		paddingBottom: 0,
		height: 65,
	},
});

export const searchbarStyle = css({
	fontFamily: '"Open Sans", sans-serif',
	width: 300,
	'& input': {
		fontFamily: '"Open Sans", sans-serif',
		borderRadius: 20,
		height: 32,
	},
	'& .ant-input:not(:first-child)': {
		paddingLeft: 35,
	},
});

export const SelectStyles = css({
	fontFamily: '"Open Sans", sans-serif',
	marginLeft: 15,
	width: 300,
	'& .ant-select-selection__choice': {
		backgroundColor: COLORS.lightGreen,
		color: COLORS.green,
	},
});

export const FormItemStyles = css({
	marginBottom: '0 !important',
});

export const ReferralButtonStyles = (selected) =>
	css({
		marginRight: 10,
		backgroundColor: selected ? '#828e98' : COLORS.white,
		color: selected ? COLORS.white : COLORS.subHeading,
		'&:hover': {
			backgroundColor: COLORS.blue,
			color: COLORS.white,
			borderColor: 'transparent',
		},
		'&:focus': {
			backgroundColor: '#828e98',
			color: COLORS.white,
			borderColor: 'transparent',
		},
		height: 30,
		width: 'auto',
	});

export const AllButtonStyles = css({
	backgroundColor: COLORS.red,
	width: 50,
	height: 30,
	color: 'white',
	border: 'none',
	'&:hover': {
		backgroundColor: 'white',
		color: COLORS.hyperLink,
		border: `1px solid ${COLORS.hyperLink}`,
	},
	'&:focus': {
		backgroundColor: COLORS.red,
		color: 'white',
		border: 'none',
	},
});

export const SelectContainer = css({
	float: 'right',
	marginLeft: 15,
	position: 'relative',
	[mq[1]]: {
		margin: '0 0 10px !important',
		width: '100%',
	},

	'& > svg': {
		right: '10px',
		top: '10px',
		pointerEvents: 'none',
		position: 'absolute',
	},
});
export const iconContainer = css({
	'& svg': {
		transform: 'translate(-40px, 8px)',
	},
});

export const noData = css({
	width: '100%',
	textAlign: 'center',
	marginTop: 40,
});

export const noDataText = css({
	width: '100%',
	textAlign: 'center',
});

export const rangePicker = css({
	padding: 0,
	width: 260,
	[mq[4]]: {
		width: 220,
	},
});
export const DropDown = css({
	fontFamily: '"Open Sans", sans-serif',
});

export const iconContainerDark = css({
	top: '-1px',
	right: 8,
	position: 'absolute',
	zIndex: 1,
});
export const SelectStylesNewActive = (length = 1) =>
	css({
		fontFamily: '"Open Sans", sans-serif',
		width: length ? 280 : 240,
		maxWidth: 480,
		'& .ant-select-selection': {
			background: COLORS.inputActiveBackground,
			color: COLORS.inputActiveColor,
			borderRadius: 32,
			padding: '0 10px',
			borderColor: COLORS.inputActiveBackground,
			boxShadow: 'none',
		},
		'& .ant-select-selection__placeholder': {
			color: COLORS.inputActiveColor,
		},
		'& .ant-select-search__field__placeholder': {
			color: COLORS.inputActiveColor,
		},
		'& .ant-select-selection__choice': {
			backgroundColor: 'rgba(143, 143, 143, 0.2)',
			color: COLORS.inputActiveColor,
		},
		'& .ant-select-focused .ant-select-selection': {
			borderColor: COLORS.inputActiveColor,
			boxShadow: '0 0 0 2px rgba(143, 143, 143, 0.2)',
		},
		'& .ant-select-selection__choice__remove': {
			color: COLORS.inputActiveColor,
		},
		'& .ant-select-selection:hover': {
			borderColor: COLORS.inputActiveBackground,
			boxShadow: '0 0 0 2px rgba(143, 143, 143, 0.2)',
		},
		'& .ant-select-selection:focus': {
			borderColor: COLORS.inputActiveBackground,
			boxShadow: '0 0 0 2px rgba(143, 143, 143, 0.2)',
		},
		'& .ant-select-selection:active': {
			borderColor: COLORS.inputActiveBackground,
			boxShadow: '0 0 0 2px rgba(143, 143, 143, 0.2)',
		},
		[mq[1]]: {
			width: '100%',
			marginLeft: 0,
			maxWidth: 'none',
		},
	});
export const SelectStylesNew = css({
	fontFamily: '"Open Sans", sans-serif',
	width: 240,
	'& .ant-select-selection': {
		background: COLORS.inputDisableBackground,
		color: COLORS.inputDisableColor,
		borderRadius: 32,
		padding: '0 10px',
		borderColor: COLORS.inputDisableBackground,
		boxShadow: 'none',
	},
	'& .ant-select-selection__placeholder': {
		color: COLORS.inputDisableColor,
	},
	'& .ant-select-search__field__placeholder': {
		color: COLORS.inputDisableColor,
	},
	'& .ant-select-selection__choice': {
		backgroundColor: COLORS.lightGreen,
		color: COLORS.green,
	},
	'& .ant-select-focused .ant-select-selection': {
		borderColor: COLORS.inputDisableBackground,
		boxShadow: '0 0 0 2px rgba(143, 143, 143, 0.2)',
	},
	'& .ant-select-selection:hover': {
		borderColor: COLORS.inputActiveBackground,
		boxShadow: '0 0 0 2px rgba(143, 143, 143, 0.2)',
	},
	'& .ant-select-selection:focus': {
		borderColor: COLORS.inputActiveBackground,
		boxShadow: '0 0 0 2px rgba(143, 143, 143, 0.2)',
	},
	'& .ant-select-selection:active': {
		borderColor: COLORS.inputActiveBackground,
		boxShadow: '0 0 0 2px rgba(143, 143, 143, 0.2)',
	},
	[mq[1]]: {
		width: '100%',
		marginLeft: 0,
	},
});

export const filterRow = css({
	display: 'flex',
	flexWrap: 'wrap',
	width: '100%',
	alignItems: 'center',
	marginTop: 10,
	[mq[1]]: {
		marginTop: 0,
	},
});

export const addButtonText = css({
	fontSize: 18,
	fontWeight: 600,
	color: COLORS.black,
});
