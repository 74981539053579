export const FREQUENCY_TYPE = {
	DAILY: 'DAILY',
	WEEKLY: 'WEEKLY',
	MONTHLY: 'MONTHLY',
};

export const FREQUENCY_VALUE = {
	DAILY: 'DAILY',
	MONDAYS: 'MONDAYS',
	TUESDAYS: 'TUESDAYS',
	WEDNESDAYS: 'WEDNESDAYS',
	THURSDAYS: 'THURSDAYS',
	FRIDAYS: 'FRIDAYS',
	SATURDAYS: 'SATURDAYS',
	SUNDAYS: 'SUNDAYS',
	FIRST: 'FIRST',
	FIFTEENTH: 'FIFTEENTH',
};

export const FREQUENCY_MAP = {
	DAILY: {
		type: FREQUENCY_TYPE.DAILY,
		key: 'Daily',
		value: FREQUENCY_VALUE.DAILY,
	},
	MONDAYS: {
		type: FREQUENCY_TYPE.WEEKLY,
		key: 'Monday',
		value: FREQUENCY_VALUE.MONDAYS,
	},
	TUESDAYS: {
		type: FREQUENCY_TYPE.WEEKLY,
		key: 'Tuesday',
		value: FREQUENCY_VALUE.TUESDAYS,
	},
	WEDNESDAYS: {
		type: FREQUENCY_TYPE.WEEKLY,
		key: 'Wednesday',
		value: FREQUENCY_VALUE.WEDNESDAYS,
	},
	THURSDAYS: {
		type: FREQUENCY_TYPE.WEEKLY,
		key: 'Thursday',
		value: FREQUENCY_VALUE.THURSDAYS,
	},
	FRIDAYS: {
		type: FREQUENCY_TYPE.WEEKLY,
		key: 'Friday',
		value: FREQUENCY_VALUE.FRIDAYS,
	},
	SATURDAYS: {
		type: FREQUENCY_TYPE.WEEKLY,
		key: 'Saturday',
		value: FREQUENCY_VALUE.SATURDAYS,
	},
	SUNDAYS: {
		type: FREQUENCY_TYPE.WEEKLY,
		key: 'Sunday',
		value: FREQUENCY_VALUE.SUNDAYS,
	},
	FIRST: {
		type: FREQUENCY_TYPE.MONTHLY,
		key: 'First',
		value: FREQUENCY_VALUE.FIRST,
	},
	FIFTEENTH: {
		type: FREQUENCY_TYPE.MONTHLY,
		key: 'Fifteenth',
		value: FREQUENCY_VALUE.FIFTEENTH,
	},
};

export const REPORT_DATE_RANGE = {
	DAY: 'DAY',
	WEEK: 'WEEK',
	MONTH: 'MONTH',
	SIX_MONTHS: 'SIX_MONTHS',
	YEAR: 'YEAR',
	TWO_YEARS: 'TWO_YEARS',
};
