import gql from 'graphql-tag';
import _ from 'lodash';
import get from 'lodash/get';
import { compose, graphql } from 'react-apollo';
import { ListCompanies } from '../../graphql/custom/company-invite';
import { createCompany, createUser } from '../../graphql/default';

export const withListCompanies = (Component, variables) => {
	return compose(
		graphql(ListCompanies, {
			options: {
				variables: { limit: 1000 },
				fetchPolicy: 'cache-and-network',
			},
			props(response, previous) {
				const companies = _.orderBy(
					get(response, 'data.listCompanies.items'),
					['dateCreated'],
					['desc']
				);
				const companiesNextToken = get(
					response,
					'data.listCompanies.nextToken'
				);
				const onFetchMore = makeOnFetchMore(
					response.data.fetchMore,
					companiesNextToken
				);
				return { companies, onFetchMore, companiesNextToken };
			},
		}),
		graphql(gql(createUser), {
			props: (props) => ({
				onCreateUser(input) {
					props.mutate({
						variables: input,
					});
				},
			}),
		}),
		graphql(gql(createCompany), {
			props: (props) => ({
				onCreateCompany(input) {
					return props.mutate({
						variables: input,
						update(proxy, { data: { createCompany } }) {
							const data = proxy.readQuery({
								query: ListCompanies,
								variables: {
									limit: 1000,
									fetchPolicy: 'cache-and-network',
								},
							});
							createCompany.accountType = get(createCompany, 'accountType')
								? createCompany.accountType
								: null;
							createCompany.disableSite = get(createCompany, 'disableSite')
								? createCompany.disableSite
								: false;
							if (
								!data.listCompanies.items.find(
									(company) => company.id === createCompany.id
								)
							) {
								data.listCompanies.items.push(createCompany);
							}

							proxy.writeQuery({
								query: ListCompanies,
								variables: {
									limit: 1000,
									fetchPolicy: 'cache-and-network',
								},
								data,
							});
						},
					});
				},
			}),
		})
	)(Component);
};

const makeOnFetchMore = (fetchMore, nextToken) => {
	if (!nextToken) {
		return null;
	}

	return () => {
		fetchMore({
			variables: { nextToken },
			updateQuery(previous, { fetchMoreResult }) {
				if (!fetchMoreResult) {
					return previous;
				}

				return {
					...previous,
					loading: false,
					listCompanies: {
						...previous.listCompanies,
						...fetchMoreResult.listCompanies,
						items: [
							...previous.listCompanies.items,
							...fetchMoreResult.listCompanies.items,
						],
					},
				};
			},
		});
	};
};
