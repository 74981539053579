import { useParams } from 'react-router-dom'
import SelectWinnerPresentation from '../../presentation/SelectWinnerPresentation'
import useNomineeWinnerList from '../../hooks/useNomineeWinnerList.js'
import useRewardDetails from '../../../RnRCommonComponent/RewardList/hooks/useRewardDetails.js';
import { useSelector } from "react-redux";
import { Spinner } from "../../../_shared/index.js";

const SelectWinnerContainer = () => {

    const { id } = useParams();

    const {
        nomineeList,
        nominationConfig,
        loading,
        nomineeVoterList,
        selectWinner,
        getNomineeVoterListById,
        getNominationCSV
    } = useNomineeWinnerList(id)

    const { handleOpenViewReward, rewardDetails } = useRewardDetails()

    const company = useSelector((state) => state.user.currentUser.company)
    if (!company) return <Spinner />
    return (
        <SelectWinnerPresentation
            selectWinner={nomineeList}
            nomineeVoterList={nomineeVoterList}
            getNomineeVoterListById={getNomineeVoterListById}
            loading={loading}
            selectWinnerFunc={selectWinner}
            nominationConfig={nominationConfig}
            getNominationCSV={getNominationCSV}
            handleOpenViewReward={handleOpenViewReward}
            rewardDetails={rewardDetails}
        />
    )
}

export default SelectWinnerContainer