import { css } from 'emotion';
import { COLORS } from '../../_shared/styles/colors';

export const ReferralContainer = css({
	backgroundColor: '#E4F7FE',
	borderRadius: 8,
	padding: 20,
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	width: '100%',
	marginBottom: 10,
	marginTop: 5,
});

export const Title = css({
	color: COLORS.heading,
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 400,
	fontSize: '18',
	marginBottom: 0,
});

export const MiscText = css({
	color: COLORS.lightGray,
	fontSize: 16,
	marginBottom: 5,
});

export const TitleName = css({
	fontWeight: 600,
});

export const ContactName = css({
	color: COLORS.heading,
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 600,
	fontSize: '18',
	marginBottom: 0,
});

export const JobTitle = css({
	color: 'COLORS.blue !important',
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: '600 !important',
	fontSize: '18',
	marginBottom: 0,
});

export const BonusAmount = css({
	color: COLORS.green,
	textAlign: 'right',
	fontSize: 18,
});

export const ReferralBonus = css({
	fontSize: 16,
	color: COLORS.lightGray,
});

export const MiddleContainer = css({
	display: 'flex',
	flex: 1,
	margin: 'auto',
});

export const ReferBtn = css({
	width: 170,
	height: 50,
	justifyContent: 'right',
	alignItems: 'right',
	backgroundColor: COLORS.red,
	color: 'white',
	margin: 5,
	fontSize: 16,
	paddingTop: 3,
});

export const CancelBtn = css({
	width: 170,
	height: 40,
	background: '#018dd3',
	color: `${COLORS.white} !important`,
	marginTop: 5,
	fontSize: 14,
	fontFamily: "'Open Sans',sans-serif",
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	borderRadius: '10px',
	border: '2px solid #018dd3 !important',
	backgroundColor: `${COLORS.blue} !important`,
	fontWeight: 200,
});

export const BtnText = css({});

export const BtnContainer = css({
	display: 'flex',
	width: 170,
	flexDirection: 'column',
	justifyContent: 'center',
	height: '100%',
	alignItems: 'right',
	alignSelf: 'right',
	marginRight: 36,
});
