const withUserLoaded = (WrappedComponent) => {
    return (props) => {
        const { currentUser } = props;

        if (!currentUser) {
            // Render null or a loading spinner until currentUser is loaded
            return null;
        }

        return <WrappedComponent {...props} />;
    };
};

export default withUserLoaded;