import { useCallback, useEffect, useRef, useState } from 'react';
import ContentEditable from 'react-contenteditable';
import sanitizeHtml from 'sanitize-html';
import { Tooltip } from 'antd';

const useReferenceCallback = (value) => {
	const ref = useRef(value);
	useEffect(() => {
		ref.current = value;
	}, [value]);

	return useCallback((...args) => {
		ref.current?.(...args);
	});
};

const sanitizeConfig = {
	allowedTags: [],
	allowedAttributes: [],
};

export function Editable({ value, handleEdit, dragging }) {
	const [text, setText] = useState(value.displayName);
	const [isDragging, setIsDragging] = useState(false);
	const [tooltipVisible, setTooltipVisible] = useState(false);
	const [isDefaultName, setIsDefaultName] = useState(
		value.displayName === value.default
	);

	/** Reset text when config is deleted */
	useEffect(() => {
		setText(value.displayName);
	}, [value]);
	useEffect(() => {
		setIsDragging(dragging);
	}, [dragging]);

	const handleChange = useReferenceCallback((e) => {
		const sanitizedContent = sanitizeHtml(
			e.currentTarget.innerHTML,
			sanitizeConfig
		);
		setText(sanitizedContent);
		handleEdit({
			item: value,
			property: 'displayName',
			newValue: sanitizedContent,
		});
	}, []);

	const handleBlur = useReferenceCallback(
		(e) => {
			const sanitizedContent = sanitizeHtml(
				e.currentTarget.innerHTML,
				sanitizeConfig
			);
			if (sanitizedContent !== '') {
				if (sanitizedContent === value.default) {
					setIsDefaultName(true);
				} else {
					setIsDefaultName(false);
				}

				return;
			}

			// Reset field to default if it is blank when focus leaves
			setText(value.default);
			setIsDefaultName(true);
			handleEdit({
				item: value,
				property: 'displayName',
				newValue: value.default,
			});
		},
		[text]
	);

	/** Prevent newlines and handle 'Enter' as 'submit' */
	const preventNewLine = (e) => {
		if (e.key !== 'Enter') {
			return;
		}

		e.target.blur();
	};

	const renderDefaultValue = isDefaultName ? null : (
		<div className="report-builder-default">{value.default}</div>
	);

	return (
		<div>
			<Tooltip
				autoDestroy
				placement="right"
				title="Click to change field name"
				open={tooltipVisible}
			>
				<div>
					<ContentEditable
						className="report-builder-editable"
						html={text}
						spellCheck="false"
						onBlur={handleBlur}
						onChange={handleChange}
						onKeyDown={preventNewLine}
						onMouseDown={() => setTooltipVisible(false)}
						onMouseOver={() => !isDragging && setTooltipVisible(true)}
						onMouseOut={() => setTooltipVisible(false)}
					/>
				</div>
			</Tooltip>
			{renderDefaultValue}
		</div>
	);
}
