import { Tooltip, Avatar } from 'antd';
import PayoutDaysDisplay from 'src/_shared/components/index';
import note from 'src/_shared/assets/note.png';

const getBonusTagColor = (bonusStatus) => {
	const bonusStatusColors = {
		pending: 'var(--tan-hide)',
		needsReview: 'var(--mellow-yellow)',
		earned: 'var(--fern)',
		ineligible: 'var(--sunset-orange)',
		paid: 'var(--forest-green)',
	};
	return bonusStatusColors[bonusStatus] || 'default';
};

const BonusInfo = ({
	referral,
	myReferralBonus,
	totalBonus,
	currentTieredBonus,
	currentUser,
}) => (
	<div className="bonus-info">
		{referral.adminNote && (
			<Tooltip
				arrowPointAtCenter
				placement="topLeft"
				title={referral.adminNote}
				key={`${referral.id}-admin-note-2`}
			>
				<img className="admin-note-icon" alt="admin-note" src={note} />
			</Tooltip>
		)}
		{referral.status !== 'ineligible' && referral.status !== 'notHired' && (
			<>
				{myReferralBonus.length > 0 ? (
					<>
						<Avatar.Group>
							{myReferralBonus
								.sort((a, b) => new Date(a.earnedDate) - new Date(b.earnedDate))
								.map((bonus, index) => (
									<Tooltip
										key={index}
										title={`${bonus.bonusStatus.charAt(0).toUpperCase()}${bonus.bonusStatus.slice(
											1
										)}: Payment ${bonus.payment}`}
									>
										<Avatar
											style={{
												backgroundColor: getBonusTagColor(bonus.bonusStatus),
											}}
										>
											{bonus.bonusStatus.charAt(0).toUpperCase()}
										</Avatar>
									</Tooltip>
								))}
						</Avatar.Group>

						{totalBonus > 0 && (
							<span className="total-bonus">
								${totalBonus.toLocaleString()}
							</span>
						)}
					</>
				) : (
					<PayoutDaysDisplay
						currentUser={currentUser}
						tiers={
							currentTieredBonus?.tiers ??
							currentTieredBonus?.tieredBonus?.tiers ??
							[]
						}
					/>
				)}
			</>
		)}
	</div>
);

export default BonusInfo;
