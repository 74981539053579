import { css } from 'emotion';
import { COLORS } from 'src/_shared/styles/colors';

export const NameStyles = css({
	color: COLORS.blue,
	fontSize: 16,
	fontWeight: '600 !important',
});

export const LinkStyles = css({
	color: COLORS.blue,
	fontWeight: '600 !important',
	fontSize: 14,
});

export const IconStyles = css({
	width: 23,
	paddingRight: 3,
	opacity: 0.7,
});

export const clearFilterButton = css({
	paddingLeft: 0,
	paddingRight: 0,
	marginTop: 5,
	textAlign: 'center',
	backgroundColor: 'transparent',
	border: 'none',
	fontSize: 12,
	height: 20,
	color: '#018dd3',
	boxShadow: 'none',
	fontWeight: 510,
});

export const filterPayment = css({
	padding: 0,
	border: 'none',
	backgroundColor: 'transparent',
	marginLeft: 2,
	fontSize: 14,
	height: 14,
	color: '#018dd3',
	fontWeight: 600,
});

export const popoverStyles = css({
	width: 200,
	'& .ant-popover-placement': {
		paddingBottom: 0,
	},
	'& .ant-popover-placement-top': {
		paddingBottom: 0,
	},
	'& .ant-popover-buttons': {
		textAlign: 'center',
	},
	'& .ant-popover-arrow': {
		display: 'none !important',
	},
});

export const TableStyles = css({
	backgroundColor: 'white !important',
	'& .ant-table': {
		fontFamily: '"Open Sans", sans-serif',
		fontWeight: 400,
		fontSize: 14,
		backgroundColor: 'white !important',
	},
	'& .ant-pagination': {
		textAlign: 'center',
		marginLeft: 'auto',
		marginRight: 'auto',
		float: 'none',
	},
	'& .ant-pagination-options': {
		float: 'left',
	},
	borderRadius: 10,
});

export const StatusStyles = css({
	paddingTop: 16,
});

export const StatusBold = css({
	fontWeight: 700,
});

export const StatusItalic = css({
	fontStyle: 'italic',
});

export const noData = css({
	width: '100%',
	textAlign: 'center',
	marginTop: 40,
});

export const dropdownMenuItem = css({
	borderColor: 'transparent',
	color: COLORS.blue,
	'&:hover': {
		backgroundColor: COLORS.white,
		color: COLORS.blue,
		borderColor: 'transparent',
	},
});

export const dropdownButton = (width) =>
	css({
		marginLeft: 0,
		paddingLeft: 0,
		paddingRight: 0,
		borderColor: 'transparent',
		color: COLORS.blue,
		'&:hover': {
			backgroundColor: COLORS.white,
			color: COLORS.blue,
			borderColor: 'transparent',
		},
		'&:focus': {
			backgroundColor: COLORS.white,
			color: COLORS.blue,
			borderColor: COLORS.white,
		},
		width,
	});
