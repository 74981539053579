import { CloseCircleOutlined } from '@ant-design/icons';
import { Row } from 'antd';
import {
	DepartmentsContainer,
	closeIcon,
	departmentContainer,
	departmentText,
} from './departmentItemStyles.js';

function DepartmentItem(props) {
	const { managedDepartments, onDeleteDepartment } = props;

	const handleDeleteDepartment = (id) => {
		onDeleteDepartment({
			input: {
				id,
			},
		});
	};

	return (
		<div className={DepartmentsContainer}>
			{managedDepartments.map((item) => {
				return (
					<div key={item.id} onClick={() => handleDeleteDepartment(item.id)}>
						<Row className={departmentContainer}>
							<span className={departmentText}>
								{item.department ? item.department.name : null}
							</span>
							<CloseCircleOutlined className={closeIcon} />
						</Row>
					</div>
				);
			})}
		</div>
	);
}

export default DepartmentItem;
