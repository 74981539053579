import { DownOutlined } from '@ant-design/icons';
import { Button, Dropdown, Popconfirm, Table, Tooltip } from 'antd';
import dayjs from 'dayjs';
import get from 'lodash/get';
import { useEffect, useState } from 'react';
import { withApollo } from 'react-apollo';
import { Link } from 'react-router-dom';
import Spinner from 'src/_shared/components/spinner/SpinnerComponent.jsx';
import { USER_ROLES } from '../constants';
import { ReferralHiredModal } from '../referral-hired-modal';
import {
	conditionalStatusStyle,
	formatDate,
	lowerCase,
	mapReferralStatus,
	ml,
} from '../services/utils';
import JobTitleComponent from './JobTitleComponent.jsx';

const InternalApplicantsTable = (props) => {
	const [hiredModalVisible, setHiredModalVisible] = useState(false);
	const [status, setStatus] = useState('');
	const [selectedReferral, setSelectedReferral] = useState();
	const [dropdownVisibility, setDropdownVisibility] = useState({});
	const [headers, setHeaders] = useState(undefined);

	useEffect(() => {
		const { currentUser, allMultiLingualData } = props;
		setHeaders({
			candidate: ml('Candidate', currentUser, allMultiLingualData),
			job: ml('Job', currentUser, allMultiLingualData),
			date: ml('Date', currentUser, allMultiLingualData),
			status: ml('Status', currentUser, allMultiLingualData),
			dateHired: ml(
				`Date ${mapReferralStatus('hired', company)}`,
				currentUser,
				allMultiLingualData
			),
		});
	}, [props.currentUser, props.allMultiLingualData, props.company]);

	useEffect(() => {
		getExportData(props.filteredData);
	}, [
		props.filteredData,
		props.currentUser,
		props.allMultiLingualData,
		props.company,
		props.languageCode,
		headers,
	]);

	const getExportData = (data) => {
		const { company, currentUser, setExportData, languageCode } = props;
		if (!headers) return;

		const parseDate = (record) =>
			formatDate(
				record.referralDate,
				currentUser?.languageCode,
				currentUser?.dateFormat
			);
		const parseDateHired = (record) =>
			record.hireDate ? formatDate(record.hireDate, languageCode) : '';

		const exportData = data.map((record) => ({
			[headers.candidate]: `${record.contact.firstName} ${record.contact.lastName}`,
			[headers.job]: `${record.job.title}`,
			[headers.date]: parseDate(record),
			[headers.status]: `${mapReferralStatus(record, company)}`,
			[headers.dateHired]: parseDateHired(record),
		}));
		setExportData(exportData);
	};

	const requestIdHover = (jobTitleInfo, externalJobId) => {
		externalJobId &&= 'Req Id: ' + externalJobId;
		return (
			<Tooltip
				placement="top"
				title={externalJobId}
				style={{ fontWeight: 1000 }}
			>
				{jobTitleInfo}
			</Tooltip>
		);
	};

	const hideHiredModal = () => {
		setHiredModalVisible(false);
	};

	const handleHired = () => {
		setStatus('hired');
	};

	const handleTableChange = (event) => {
		if (event.action === 'sort') {
			getExportData(event.currentDataSource);
		}
	};

	const handleVisibleChange = (key, flag) => {
		setDropdownVisibility((prevState) => ({
			...prevState,
			[key]: flag,
		}));
	};

	const {
		allMultiLingualData,
		currentUser,
		filteredData,
		sortByAlph,
		company,
		onUpdateReferral,
		updateReferralState,
	} = props;

	const handleUpdateStatus = async (referral, status) => {
		if (status === 'hired') {
			setSelectedReferral(referral);
			setHiredModalVisible(true);
		} else {
			setStatus(status);
			let input = {};
			const customStatuses = [
				'stage1',
				'stage2',
				'stage3',
				'stage4',
				'stage5',
				'stage6',
			];
			if (customStatuses.includes(status)) {
				const customStages = get(props.currentUser, 'company.stages');
				if (customStages) {
					const stages = JSON.parse(customStages);
					const stageObject = stages.find((value) => value[status]);
					const customStage = stageObject[status];
					input = {
						id: get(referral, 'id'),
						contactId: get(referral, 'contactId'),
						userId: get(referral, 'user.id'),
						jobId: get(referral, 'jobId'),
						status: 'interviewing',
						bonusStatus: 'ineligible',
						customStatus: customStage,
					};
				}
			} else {
				input = {
					id: get(referral, 'id'),
					contactId: get(referral, 'contactId'),
					userId: get(referral, 'user.id'),
					jobId: get(referral, 'jobId'),
					status,
					bonusStatus: 'ineligible',
				};
				if (status === 'interviewing') {
					input.customStatus = get(
						props.currentUser,
						'company.referralStatus',
						'Interviewing'
					);
				} else {
					// Explicitly set customStatus null or else the
					// referral retains the old customStatus
					input.customStatus = null;
				}
			}

			onUpdateReferral({ input });
			updateReferralState(input);
		}
	};

	const isManagerPermissionDisabled = get(
		props.currentUser,
		'company.disableManagerPermissions',
		false
	);
	const displayAs = get(props.currentUser, 'displayAs');
	const isStatusDropdownDisabled = Boolean(
		isManagerPermissionDisabled && displayAs === USER_ROLES.MANAGER
	);
	const confirmMessage =
		status === 'hired'
			? 'Confirm Updates? Associated bonuses will be deleted.'
			: 'Confirm Updates?';
	const languageCode = get(props, 'languageCode', 'US');

	let REFERRAL_STATUS = {
		referred: 'Referred',
		accepted: 'Accepted',
		interviewing: 'Interviewing',
	};
	const customStages = get(props.currentUser, 'company.stages');
	if (customStages) {
		const stages = JSON.parse(customStages);
		if (stages.length > 0) {
			REFERRAL_STATUS = Object.assign(REFERRAL_STATUS, ...stages);
		}
	}

	const remainingStatuses = {
		hired: 'Hired',
		ineligible: 'Ineligible',
		notHired: 'Not Hired',
		declined: 'Declined',
		transferred: 'Transferred',
		inactive: 'Inactive',
		noresponse: 'No Response',
	};
	REFERRAL_STATUS = { ...REFERRAL_STATUS, ...remainingStatuses };
	const columns = [
		{
			title: headers?.candidate,
			dataIndex: 'contact',
			key: 'name',
			showSorterTooltip: false,
			sorter: (a, b) =>
				sortByAlph(
					get(a, 'contact.firstName', '') + get(a, 'contact.lastName', ''),
					get(b, 'contact.firstName', '') + get(b, 'contact.lastName', '')
				),
			render(record, data) {
				let result = null;
				if (get(data, 'type') === 'gdprReferralCreated') {
					result = (
						<span className="text-gray">
							{ml('Pending Acceptance', currentUser, allMultiLingualData)}
						</span>
					);
				} else {
					result = (
						<Link to={`/referrals/${data.id}`} className="table-link">
							{get(record, 'firstName')} {get(record, 'lastName')}
						</Link>
					);
				}

				return result;
			},
		},

		{
			title: headers?.job,
			dataIndex: 'job',
			width: '34%',
			key: 'job',
			showSorterTooltip: false,
			sorter: (a, b) => sortByAlph(a.job.title, b.job.title),
			render: (job) =>
				job === null
					? null
					: requestIdHover(
							<Link className="table-link" to={`/jobs/${job.id}`}>
								<JobTitleComponent
									jobTitle={job.title}
									currentUser={get(props, 'currentUser')}
								/>
							</Link>,
							job.externalJobId
						),
		},
		{
			title: headers?.date,
			dataIndex: 'referralDate',
			width: '14%',
			key: 'date',
			showSorterTooltip: false,
			sorter: (a, b) =>
				sortByAlph(dayjs(b.referralDate), dayjs(a.referralDate)),
			render: (referralDate) => formatDate(referralDate, languageCode),
		},
		{
			title: headers?.status,
			width: '14%',
			key: 'status',
			showSorterTooltip: false,
			sorter(a, b) {
				a =
					a?.customStatus && a.status === 'interviewing'
						? lowerCase(a.customStatus)
						: lowerCase(a.status);
				b =
					b?.customStatus && b.status === 'interviewing'
						? lowerCase(b.customStatus)
						: lowerCase(b.status);
				return sortByAlph(a, b);
			},
			render(referral) {
				const disabled = Boolean(
					isStatusDropdownDisabled ||
						get(referral, 'type') === 'gdprReferralCreated'
				);
				return (
					<Dropdown
						trigger={['click']}
						menu={{
							items: Object.keys(REFERRAL_STATUS).map((key) => {
								if (key === 'hired') {
									return {
										key,
										label: (
											<div
												style={conditionalStatusStyle(key)}
												onClick={() => handleUpdateStatus(referral, key)}
											>
												{mapReferralStatus(key, company)}
											</div>
										),
									};
								}

								return {
									key,
									label: (
										<Popconfirm
											key={`popconfirm-${key}`}
											title={confirmMessage}
											placement="top"
											okText={ml('Yes', currentUser, allMultiLingualData)}
											cancelText={ml('No', currentUser, allMultiLingualData)}
											onConfirm={() => {
												handleVisibleChange(referral.id, false);
												handleUpdateStatus(referral, key);
											}}
										>
											<div style={conditionalStatusStyle(key)}>
												{mapReferralStatus(key, company)}
											</div>
										</Popconfirm>
									),
								};
							}),
						}}
						disabled={disabled}
						open={dropdownVisibility[referral.id]}
						onOpenChange={(flag) => handleVisibleChange(referral.id, flag)}
					>
						<Button
							style={conditionalStatusStyle(referral.status)}
							type="link"
							onClick={(e) => e.stopPropagation()}
						>
							{mapReferralStatus(referral, company)}
							<DownOutlined />
						</Button>
					</Dropdown>
				);
			},
		},
		{
			title: headers?.dateHired,
			dataIndex: 'hireDate',
			width: '14%',
			key: 'hiredate',
			showSorterTooltip: false,
			sorter: (a, b) => sortByAlph(b.hireDate, a?.hireDate),
			render: (hireDate) => formatDate(hireDate, languageCode),
		},
	];

	const locale = {
		emptyText: <Spinner company={company} />,
	};
	return (
		<>
			{headers && (
				<>
					<Table
						pagination={{ pageSize: 25, showSizeChanger: false }}
						rowKey={(record) => record.id}
						dataSource={filteredData}
						columns={columns}
						locale={locale}
						scroll={{ x: 656 }}
						onChange={(_pagination, _filters, _sorter, extra) =>
							handleTableChange(extra)
						}
					/>
					<ReferralHiredModal
						updateReferralState={updateReferralState}
						referral={selectedReferral}
						visible={hiredModalVisible}
						handleCancel={hideHiredModal}
						handleHired={handleHired}
						onUpdateJob={props.onUpdateJob}
						onUpdateReferral={props.onUpdateReferral}
					/>
				</>
			)}
		</>
	);
}

export default withApollo(InternalApplicantsTable);
