import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { ArrowRightOutlined, EnvironmentOutlined } from '@ant-design/icons';
import { Alert, Button, Col, Input, Modal, Row, Select } from 'antd';
import { Auth } from 'aws-amplify';
import dayjs from 'dayjs';
import gql from 'graphql-tag';
import Cookies from 'js-cookie';
import _ from 'lodash';
import get from 'lodash/get';
import mixpanel from 'mixpanel-browser';
import { Component } from 'react';
import { withApollo } from 'react-apollo';
import { geolocated } from 'react-geolocated';
import PlacesAutocomplete from 'src/_shared/components/location/PlacesAutocomplete.jsx';
import { withRouter } from 'react-router-dom';
import {
	GetUserByCognitoId,
	updateUser,
} from 'src/_shared/api/graphql/custom/users/';
import Spinner from 'src/_shared/components/spinner/SpinnerComponent.jsx';
import uuid from 'uuid/v4';
import { RouterContext } from '../../_shared/contexts';
import { getLocation, graphql } from '../../_shared/services/utils.js';
import { requiredFields } from '../errorHandlersExtended';
import { newUserkeys } from '../newUserKeys.js';
import { PageItem } from './AddLocationPageItemComponent.jsx';
import { USStates } from './copy.js';
import { ExtendedLoginModal } from './extended-user-merge';
import {
	AddLocationBtn as AddLocationButton,
	AlertError,
	Card,
	CheckIcon,
	FormStyles,
	InputStyles2,
	LabelStyles,
	MiscText,
	SubTitleStyles,
	SubmitBtn as SubmitButton,
	SubmitBtnContainer as SubmitButtonContainer,
	TitleStyles,
	btnText as buttonText,
	errorStyles,
	typeaheadRow,
} from './newUserFormStyles.js';

class NewExtendedUserForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			address: '',
			geolocationAllowed: false,
			serverError: false,
			lengthError: false,
			showMergeAccountModal: false,
			department: '',
			requiredErrors: {},
			tokenData: this.props.tokenData,
			loading:
				get(this.props, 'tokenData.identities[0].providerName', null) ===
				'Rush',
			redirectURL: this.props.redirectURL,
			submitting: false,
			currencyAbbrev: 'USD',
			multiLingualData: '',
			ml_LetsStarted: '',
			ml_FirstComplete: '',
			ml_FirstName: '',
			ml_LastName: '',
			ml_Department: '',
			ml_Location: '',
			ml_AutofillLocation: '',
			ml_Email: '',
			ml_NotValid: '',
			ml_ByCreating: '',
			ml_Terms: '',
			ml_And: '',
			ml_Privacy: '',
			ml_Agree: '',
			ml_Problem: '',
			ml_GetStarted: '',
		};
	}

	async componentDidMount() {
		const permissions = await navigator.permissions.query({
			name: 'geolocation',
		});
		const geolocationAllowed = permissions?.state === 'granted';
		if (geolocationAllowed) this.getCurrentLocation();
		this.setState({ geolocationAllowed });
		const accountExists = get(this.props, 'contact.extendedUserId');
		if (accountExists) {
			this.props.history.push({
				pathname: '/login',
				state: {
					warningMessage:
						'This account has already been registered. Please login using your credentials.',
				},
			});
		}

		await this.getMultiLingualData();
		const { multiLingualData } = this.state;
		if (multiLingualData.length > 0) {
			const languageCode = 'US';
			const filteredData = multiLingualData.filter(
				(item) => item.languageCode === languageCode
			);
			const letsStarted = filteredData.find(
				(item) => item.key === newUserkeys.LetsStarted
			);
			const firstComplete = filteredData.find(
				(item) => item.key === newUserkeys.FirstComplete
			);
			const firstName = filteredData.find(
				(item) => item.key === newUserkeys.FirstName
			);
			const lastName = filteredData.find(
				(item) => item.key === newUserkeys.LastName
			);
			const department = filteredData.find(
				(item) => item.key === newUserkeys.Department
			);

			const location = filteredData.find(
				(item) => item.key === newUserkeys.Location
			);
			const autofillLocation = filteredData.find(
				(item) => item.key === newUserkeys.AutofillLocation
			);
			const email = filteredData.find((item) => item.key === newUserkeys.Email);
			const notValid = filteredData.find(
				(item) => item.key === newUserkeys.NotValid
			);
			const byCreating = filteredData.find(
				(item) => item.key === newUserkeys.ByCreating
			);
			const terms = filteredData.find((item) => item.key === newUserkeys.Terms);
			const and = filteredData.find((item) => item.key === newUserkeys.And);
			const privacy = filteredData.find(
				(item) => item.key === newUserkeys.Privacy
			);
			const agree = filteredData.find((item) => item.key === newUserkeys.Agree);
			const problem = filteredData.find(
				(item) => item.key === newUserkeys.Problem
			);
			const getStarted = filteredData.find(
				(item) => item.key === newUserkeys.GetStarted
			);
			this.setState({
				ml_LetsStarted: get(letsStarted, 'text', "Let's Get Started"),
				ml_FirstComplete: get(
					firstComplete,
					'text',
					'First complete your profile'
				),
				ml_FirstName: get(firstName, 'text', 'First Name'),
				ml_LastName: get(lastName, 'text', 'Last Name'),
				ml_Department: get(department, 'text', 'Department'),
				ml_Location: get(location, 'text', 'Location'),
				ml_AutofillLocation: get(autofillLocation, 'text', 'Autofill Location'),
				ml_Email: get(email, 'text', 'Email Address'),
				ml_NotValid: get(notValid, 'text', 'Not a valid email address.'),
				ml_ByCreating: get(
					byCreating,
					'text',
					'By creating a profile you agree to the ERIN'
				),
				ml_Terms: get(terms, 'text', 'Terms of Use'),
				ml_And: get(and, 'text', 'and'),
				ml_Privacy: get(privacy, 'text', 'Privacy Policy,'),
				ml_Agree: get(
					agree,
					'text',
					'and agree that we can send you information about jobs and referrals at your company. You can opt out at any time.'
				),
				ml_Problem: get(
					problem,
					'text',
					'There was a problem creating your account. Please try again.'
				),
				ml_GetStarted: get(getStarted, 'text', 'Get Started'),
			});
		} else {
			this.setState({
				ml_LetsStarted: "Let's Get Started",
				ml_FirstComplete: 'First complete your profile',
				ml_FirstName: 'First Name',
				ml_LastName: 'Last Name',
				ml_Department: 'Department',
				ml_Location: 'Location',
				ml_AutofillLocation: 'Autofill Location',
				ml_Email: 'Email Address',
				ml_NotValid: 'Not a valid email address.',
				ml_ByCreating: 'By creating a profile you agree to the ERIN',
				ml_Terms: 'Terms of Use',
				ml_And: 'and',
				ml_Privacy: 'Privacy Policy,',
				ml_Agree:
					'and agree that we can send you information about jobs and referrals at your company. You can opt out at any time.',
				ml_Problem:
					'There was a problem creating your account. Please try again.',
				ml_GetStarted: 'Get Started',
			});
		}

		const provider = get(
			this.props,
			'tokenData.identities[0].providerName',
			null
		);
		if (provider === 'Rush') {
			this.handleSubmit();
		}
	}

	componentDidUpdate() {
		const { submitting } = this.state;
		const accountExists = get(this.props, 'contact.extendedUserId');
		if (accountExists && !submitting) {
			this.props.history.push({
				pathname: '/login',
				state: {
					warningMessage:
						'This account has already been registered. Please login using your credentials.',
				},
			});
		}
	}

	onAuthentication = (authToken, currentUser) => {
		try {
			const host = window.location.hostname;
			if (host === 'localhost') {
				Cookies.set('jwt', authToken);
			} else {
				Cookies.set('jwt', authToken, { secure: true, httpOnly: false });
			}

			this.setState({
				auth: true,
			});
		} catch (error) {
			console.log(error);
		}
	};

	onChange = (event_) => {
		const { value } = event_.target;
		this.setState({ currencyAbbrev: value });
	};

	getCurrentLocation = () => {
		if (navigator.geolocation) {
			// Timeout at 60000 milliseconds (60 seconds)
			const options = { timeout: 60_000 };
			navigator.geolocation.getCurrentPosition(
				this.showLocation,
				this.errorHandler,
				options
			);
		} else {
			console.log('Sorry, browser does not support geolocation!');
		}
	};

	async getMultiLingualData() {
		try {
			let multiLingualData = await graphql({
				input: { module: 'Registration' },
				query: 'queryMultiLingualByModuleIndex',
			});
			multiLingualData = multiLingualData?.items || multiLingualData;
			this.setState({
				multiLingualData,
			});
		} catch (error) {
			console.log(error);
		}
	}

	addLocation = async () => {
		if (this.props.isGeolocationAvailable) {
			const lat = this.props?.coords?.latitude;
			const lon = this.props?.coords?.longitude;
			const location = await getLocation({ lat, lon }, 'apiKey');
			this.setState({ address: location?.address, location });
		} else {
			console.log('Location is not available');
		}
	};

	errorHandler = (error) => {
		if (error.code == 1) {
			console.log('Error: Access is denied!');
		} else if (error.code == 2) {
			console.log('Error: Position is unavailable!');
		}
	};

	handleChangeLocation = (address) => {
		this.setState({ address });
	};

	handleSelectDepartment = (department) => {
		this.setState({ department });
	};

	handleSelectLocation = async (location) => {
		this.setState({ address: location?.address, location });
	};

	handleSubmit = (e) => {
		e.preventDefault();
		const { contact, onCreate, onUpdateContact, setCurrentUser } = this.props;
		this.setState({ serverError: false, pageIsValid: true });
		this.props.form.validateFields(async (error, values) => {
			if (error) {
				this.setState({ loading: false, serverError: true });
			} else if (values.password && values.password.length < 8) {
				this.setState({ lengthError: true, loading: false });
				return;
			}

			if (this.pageIsValid(values)) {
				const userEmail = get(values, 'emailAddress', '')
					.split(' ')
					.join('')
					.toLowerCase();
				const existingUser = await graphql({
					input: { emailAddress: userEmail },
					query: 'getUserByEmailAddress',
				});
				if (existingUser) {
					this.setState({ emailError: true });
					this.setState({ loading: false, showMergeAccountModal: true });
				} else {
					this.setState({ submitting: true });
					Auth.signUp({
						username: uuid(),
						password: values.password,
						attributes: {
							email: userEmail,
						},
					}).then((data) => {
						const whiteLabel = get(this.props, 'whiteLabel');
						const companyId = whiteLabel
							? get(this.props, 'contact.companyId')
							: 'd5a17493-1e50-4a38-afa5-99623951d72e'; // Extended Network Company
						const input = {
							cognitoId: get(data, 'user.username'),
							companyId,
							emailAddress: userEmail,
							extendedCompanies: JSON.stringify([get(contact, 'companyId')]),
							extendedContactIds: JSON.stringify([get(contact, 'id')]),
							inviteStatus: 'accepted',
							role: 'extendedUser',
							firstName: get(values, 'firstName'),
							lastName: get(values, 'lastName'),

							departmentId: 'f5da3fd2-2ae5-41c3-8c8c-b901b7ec8eb0',
							avatar: null,
							lastLogin: null,
							active: true,
							createdById: get(contact, 'userId'),
							userGroupId: '3391aa35-275b-47ee-a505-f59cbef37b42',
							currency: get(this.state, 'currencyAbbrev', 'USD'),
							languageCode: null,
						};
						if (this.state?.location)
							input.location = JSON.stringify(this.state.location);
						Auth.signIn(
							values.emailAddress.toLowerCase().trim(),
							values.password.trim()
						).then(async (user) => {
							onCreate({
								input,
							})
								.then((response) => {
									const extendedUserId = get(response, 'data.createUser.id');
									onUpdateContact({
										input: {
											id: get(contact, 'id'),
											inviteStatus: 'accepted',
											extendedUserId,
										},
									});
								})
								.then(async () => {
									const { data } = await this.props.client.query({
										query: GetUserByCognitoId,
										variables: { cognitoId: user.username },
									});
									const currentUser = {
										...data.getUserByCognitoId,
										authMethod: 'credentials',
										lastLogin: dayjs().toISOString(),
									};
									if (currentUser && !currentUser.active) {
										const error = new Error('User Disabled');
										error.code = 'UserDisabledException';
										throw error;
									} else {
										setCurrentUser(currentUser);
										await this.props.client.mutate({
											mutation: gql(updateUser),
											variables: {
												input: {
													id: data.getUserByCognitoId.id,
													authMethod: 'credentials',
													lastLogin: dayjs().toISOString(),
													accessToken:
														user.signInUserSession.accessToken.jwtToken,
													expires: false,
												},
											},
										});
										mixpanel.identify(currentUser.id);
										mixpanel.register({
											'Company Name': currentUser?.company?.name,
											'Company ID': currentUser?.company?.id,
										});
										mixpanel.track('Account Created');
										mixpanel.track('Logged In');
										localStorage.setItem('mixpanel_user_identified', 'true');
										this.onAuthentication(
											user.signInUserSession.accessToken.jwtToken,
											currentUser
										);
										window.location.href = '/dashboard';
									}
								});
						});
					});
				}
			}
		});
	};

	pageIsValid = (values) => {
		let isValid = true;
		const requiredErrors = {};
		for (const { key, message } of requiredFields) {
			if (!values[key]) {
				requiredErrors[key] = message;
				isValid = false;
			}
		}

		this.setState({
			requiredErrors,
		});

		return isValid;
	};

	showLocation = async (position) => {
		const lat = position?.coords?.latitude;
		const lon = position?.coords?.longitude;
		const location = await getLocation({ lat, lon }, 'apiKey');
		this.setState({ address: location?.address, location });
	};

	renderLocation = () => {
		const { ml_Location, ml_AutofillLocation } = this.state;
		const { Option } = Select;
		const options = [];
		Object.keys(USStates).map((key) =>
			options.push(<Option key={key}>{USStates[key]}</Option>)
		);
		return (
			<div>
				<PageItem>
					<Col md={24} xs={48}>
						<label className={LabelStyles}>{ml_Location}</label>
						{this.state.geolocationAllowed && (
							<Button className={AddLocationButton} onClick={this.addLocation}>
								<EnvironmentOutlined />
								<span className={buttonText}>{ml_AutofillLocation}</span>
							</Button>
						)}
					</Col>
					<Col md={24} xs={16}>
						<div>
							<PlacesAutocomplete
								address={this.state.address}
								auth="apiKey"
								setAddress={this.handleChangeLocation.bind(this)}
								onSelect={this.handleSelectLocation.bind(this)}
							/>
						</div>
					</Col>
				</PageItem>
			</div>
		);
	};

	render() {
		const { getFieldDecorator } = this.props.form;
		const {
			lengthError,
			loading,
			serverError,
			requiredErrors,
			showMergeAccountModal,
			submitting,
			ml_LetsStarted,
			ml_FirstComplete,
			ml_FirstName,
			ml_LastName,
			ml_Email,
			ml_NotValid,
			ml_ByCreating,
			ml_Terms,
			ml_And,
			ml_Privacy,
			ml_Agree,
			ml_Problem,
			ml_GetStarted,
		} = this.state;
		const FormItem = Form.Item;
		const theme = null;
		const contact = get(this.props, 'contact');
		const firstName = get(contact, 'firstName', '');
		const lastName = get(contact, 'lastName', '');
		const emailAddress = get(contact, 'emailAddress', '');
		const Title1 = contact ? ml_LetsStarted : `We're Sorry`;
		const Title2 = contact ? ml_FirstComplete : 'This invitation has expired.';
		const whiteLabel = get(this.props, 'whiteLabel');
		if (submitting) {
			return (
				<div className={Card}>
					<div style={{ width: '100%', height: 608 }}>
						<Spinner />
					</div>
				</div>
			);
		}

		return (
			<div className={Card}>
				<div style={{ width: '100%' }}>
					{contact === undefined ? (
						<Row
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								height: 76,
							}}
						>
							<Spinner />
						</Row>
					) : (
						<div>
							<Row
								style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
								}}
							>
								<h1 className={TitleStyles}>{Title1}</h1>
							</Row>
							<Row
								style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
								}}
							>
								<h2 className={SubTitleStyles}>{Title2}</h2>
							</Row>
						</div>
					)}
					{contact && (
						<Form className={FormStyles}>
							<Row>
								<Col md={11} xs={24}>
									<FormItem className={InputStyles2}>
										<label className={LabelStyles}>{ml_FirstName}</label>
										{getFieldDecorator('firstName', {
											initialValue: firstName ? firstName : '',
										})(<Input />)}
										{requiredErrors && requiredErrors.firstName ? (
											<div>
												<p className={errorStyles}>
													{' '}
													{requiredErrors.firstName}{' '}
												</p>
											</div>
										) : null}
									</FormItem>
								</Col>
								<Col md={{ span: 12, offset: 1 }} xs={24}>
									<FormItem className={InputStyles2}>
										<label className={LabelStyles}>{ml_LastName}</label>
										{getFieldDecorator('lastName', {
											initialValue: lastName ? lastName : '',
										})(<Input />)}
										{requiredErrors && requiredErrors.lastName ? (
											<div>
												<p className={errorStyles}>
													{' '}
													{requiredErrors.lastName}{' '}
												</p>
											</div>
										) : null}
									</FormItem>
								</Col>
							</Row>

							<Row>{this.renderLocation()}</Row>
							<FormItem className={InputStyles2}>
								<label className={LabelStyles}>{ml_Email}</label>
								{getFieldDecorator('emailAddress', {
									initialValue: emailAddress ? emailAddress : '',
									rules: [
										{
											type: 'email',
											message: ml_NotValid,
										},
									],
								})(<Input disabled={false} />)}
							</FormItem>
							<FormItem className={InputStyles2}>
								<label className={LabelStyles}>Create Password</label>
								{getFieldDecorator('password', {
									rules: [
										{
											required: true,
											message: 'Password Required',
										},
									],
								})(
									<Input.Password className={InputStyles2} autoComplete="on" />
								)}
								{lengthError ? (
									<Alert
										className={AlertError}
										type="error"
										message="Password must be at least 8 characters long."
									/>
								) : null}
								{requiredErrors && requiredErrors.password ? (
									<div>
										<p className={errorStyles}> {requiredErrors.password} </p>
									</div>
								) : null}
							</FormItem>
							{!whiteLabel && (
								<p className={MiscText}>
									{ml_ByCreating}{' '}
									<a
										href="http://erinapp.com/terms-of-use"
										rel="noopener noreferrer"
										target="_blank"
									>
										{ml_Terms}
									</a>{' '}
									{ml_And}{' '}
									<a
										href="https://erinapp.com/privacy-policy"
										rel="noopener noreferrer"
										target="_blank"
									>
										{ml_Privacy}
									</a>{' '}
									{ml_Agree}
								</p>
							)}
							{serverError ? (
								<Alert
									type="error"
									className={AlertError}
									message={ml_Problem}
								/>
							) : null}

							<FormItem className={SubmitButtonContainer(theme)}>
								<Button
									className={SubmitButton(theme)}
									onClick={_.debounce(this.handleSubmit, 300, {
										leading: true,
										trailing: false,
									})}
								>
									{ml_GetStarted}
									<ArrowRightOutlined className={CheckIcon} />
								</Button>
							</FormItem>
						</Form>
					)}
					<Modal
						cancelButtonProps={{ style: { display: 'none' } }}
						open={loading}
						footer={null}
						closable={false}
						width="100%"
						bodyStyle={{
							backgroundColor: '#242e3f',
							height: window.innerHeight,
						}}
						style={{
							top: '0px',
						}}
					>
						<div
							style={{
								marginTop: '10%',
							}}
						>
							<Spinner />
						</div>
					</Modal>
					<RouterContext.Consumer>
						{({ onAuthentication }) => (
							<ExtendedLoginModal
								visible={showMergeAccountModal}
								email={get(contact, 'emailAddress')}
								contact={contact}
								closeModal={() =>
									this.setState({ showMergeAccountModal: false })
								}
								client={this.props.client}
								onAuthentication={onAuthentication}
								onUpdateContact={this.props.onUpdateContact}
							/>
						)}
					</RouterContext.Consumer>
				</div>
			</div>
		);
	}
}

export default withApollo(
	withRouter(
		Form.create()(
			geolocated({
				positionOptions: {
					enableHighAccuracy: true,
				},
				userDecisionTimeout: 60_000,
			})(NewExtendedUserForm)
		)
	)
);
