import { Select } from 'antd';
import _ from 'lodash';

function SelectDepartments(props) {
	const { id, onAddDepartment, managedDepartments, departments = [] } = props;
	const { Option } = Select;

	const handleAddDepartment = (value) => {
		const found = managedDepartments.some((item) => {
			return item.departmentId === value.key;
		});
		if (!found) {
			onAddDepartment({
				userId: id,
				departmentId: value.key,
			});
		}
	};

	return (
		<Select
			labelInValue={true}
			showArrow={false}
			showSearch={true}
			className="custom-input"
			filterOption={(input, option) => {
				return (
					option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
				);
			}}
			dropdownMatchSelectWidth={false}
			placeholder={<>Add a Department</>}
			onChange={(value) => handleAddDepartment(value)}
		>
			{_.sortBy(departments, [
				(department) => {
					return department.name.toLowerCase();
				},
			]).map((department) => {
				return <Option key={department.id}>{department.name}</Option>;
			})}
		</Select>
	);
}

export default SelectDepartments;
