import React from "react";

import AllActivityPresentation from "../../presentation/AllActivityPresentation";

const AllActivityContainer = ({ allActivitiesInfo }) => {

    return (
        <AllActivityPresentation allActivity={allActivitiesInfo} />
    );
};

export default AllActivityContainer;

