import '@ant-design/compatible/assets/index.css';
import { DeleteOutlined } from '@ant-design/icons';
import { Alert, AutoComplete, Form, Input, Row, Select } from 'antd';
import get from 'lodash/get';
import { Component, createRef } from 'react';
import ReactDOM from 'react-dom';
import ProgressButton from 'react-progress-button';
import { ml } from 'src/_shared/services/utils.js';
import FormElements from 'src/form-builder/FormElements.jsx';
import uuid from 'uuid/v4';
import * as ModalStyles from '../referralRequestModalStyles.js';

class ReferralRequestForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			alreadyRequestedError: false,
			buttonState: '',
			selectConnection: {
				id:
					get(props, 'extendedNetworkUsers[0].id', '').length === 1
						? get(props, 'extendedNetworkUsers[0].id')
						: '',
			},
			filename: null,
			filetype: null,
			errors: [],
			originalFile: [],
			theme: JSON.parse(get(props, 'currentUser.company.theme', '{}')),
			data: [],
			refErrors: [],
			uniqueId: uuid(),
		};
		this.formRef = createRef();
	}

	onChangeEmployee = (value) => {
		this.setState({
			selectConnection: {
				id: value.key,
			},
			alreadyRequestedError: false,
			buttonState: '',
		});
	};

	onDelete = () => {
		this.setState({ originalFile: [] });
		this.setState({ filename: null });
		this.setState({ filetype: null });
		this.setState({ errors: [] });
	};

	onFileInputChange = (e) => {
		if (e.target.files && e.target.files.length > 0) {
			const errors = [];
			const isDocOrDocsOrPdf =
				e.target.files[0].type === 'application/msword' ||
				e.target.files[0].type ===
					'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
				e.target.files[0].type === 'application/pdf';
			if (!isDocOrDocsOrPdf) {
				errors.push('You can only upload doc, docx, pdf files! ');
			}

			const isLt2M = e.target.files[0].size / 1024 / 1024 < 2;
			if (!isLt2M) {
				errors.push('File size must smaller than 2MB!');
			}

			if (errors.length > 0) {
				this.setState({ filename: e.target.files[0].name });
				this.setState({ errors });
				return errors;
			}

			this.setState({
				filename: e.target.files[0].name,
				filetype: e.target.files[0].type,
				originalFile: e.target.files[0],
			});

			this.setState({ errors: [] });
			return isDocOrDocsOrPdf && isLt2M;
		}
	};

	getInputElement(item) {
		const Input = FormElements[item.element];
		return (
			<div>
				<Input
					ref={(c) => (this.inputs[item.field_name] = c)}
					key={`form_${item.id}`}
					mutable
					handleChange={this.handleChange}
					data={item}
					read_only={this.props.read_only}
				/>
			</div>
		);
	}

	getSimpleElement(item) {
		const Element = FormElements[item.element];
		return <Element key={`form_${item.id}`} mutable data={item} />;
	}

	_collect(item) {
		const errors = [];
		const itemData = { name: item.field_name };
		const ref = this.inputs[item.field_name];
		if (item.element === 'Checkboxes' || item.element === 'RadioButtons') {
			const checked_options = [];
			for (const option of item.options) {
				const $option = ReactDOM.findDOMNode(
					ref.options[`child_ref_${option.key}`]
				);
				if ($option.checked) {
					checked_options.push(option.key);
				}
			}

			itemData.value = checked_options;
		} else {
			if (!ref) return null;
			itemData.value = this._getItemValue(item, ref).value;

			itemData.question = item.label;
			itemData.text =
				this._getItemValue(item, ref).text === undefined
					? ''
					: this._getItemValue(item, ref).text;
			if (itemData.value === '' && itemData.text === '') {
				errors.push(`${item.label} is required.`);
			}
		}

		const object = {
			itemData,
			errors,
		};
		return object;
	}

	_collectFormData(data) {
		const formData = [];
		let errors = '';
		for (const item of data) {
			const data = this._collect(item);
			const item_data =
				this._collect(item) === null ? null : this._collect(item).itemData;
			errors += this._collect(item) === null ? '' : this._collect(item).errors;
			if (item_data) {
				formData.push(item_data);
			}
		}

		const object = {
			formData,
			errors,
		};
		return object;
	}

	_getItemValue(item, ref) {
		let $item = {
			element: item.element,
			value: '',
			text: '',
		};
		switch (item.element) {
			case 'Rating': {
				$item.value = ref.inputField.current.state.rating;

				break;
			}

			case 'Tags': {
				$item.value = ref.inputField.current.state.value;

				break;
			}

			case 'DatePicker': {
				$item.value = ref.state.value;

				break;
			}

			case 'Camera': {
				$item.value = ref.state.img
					? ref.state.img.replace('data:image/png;base64,', '')
					: '';

				break;
			}

			default: {
				if (ref && ref.inputField) {
					$item = ReactDOM.findDOMNode(ref.inputField.current);
					if (typeof $item.value === 'string') {
						$item.value = $item.value.trim();
						if (item.element === 'Dropdown') {
							$item.text = $item.selectedOptions[0].innerText;
						}
					}
				}
			}
		}

		return $item;
	}

	alreadyRequested = (request) => {
		const requestingNotifications = get(
			this.props,
			'requestingNotifications',
			[]
		);

		const exists = requestingNotifications.find(
			(notification) =>
				get(notification, 'type') === 'referralRequested' &&
				get(notification, 'jobId') === get(request, 'jobId') &&
				get(notification, 'user.id') === get(request, 'userId')
		);
		if (exists) this.setState({ alreadyRequestedError: true });
		return exists;
	};

	handleSubmit = (values) => {
		const { onCreateWebNotification } = this.props;
		this.setState({ buttonState: 'loading' });

		const questionsData = this._collectFormData(
			this.props.referralQuestions
		).formData;
		const customFieldErrors = this._collectFormData(
			this.props.referralQuestions
		).errors;

		if (customFieldErrors.length > 0) {
			this.setState({
				buttonState: 'error',
			});
			setTimeout(() => {
				this.setState({
					buttonState: '',
				});
			}, 1500);
			return;
		}

		const user = get(this.props, 'extendedNetworkUsers', []).find(
			(user) => get(user, 'id') === get(this.state, 'selectConnection.id')
		);
		const contact = get(this.props, 'currentUser.extendedContactData', []).find(
			(contact) =>
				get(contact, 'userId') === get(user, 'userId') ||
				get(contact, 'user.userId') === get(user, 'userId')
		);

		const { message } = values;

		const input = {
			contactId: get(contact, 'id'),
			dateCreated: new Date().toISOString(),
			jobId: get(this.props, 'job.id'),
			referralRequestedStatus: 'requested',
			requestingUserId: get(this.props, 'currentUser.id'),
			type: 'referralRequested',
			userId: get(user, 'userId') ?? get(user, 'id'),
			message,
			questionsData: JSON.stringify(questionsData),
		};

		if (user && contact && !this.alreadyRequested(input)) {
			onCreateWebNotification({ input }).then((res) => {
				this.setState({ buttonState: 'success' });
				// Because Apollo won't give result without refreshing,
				// for now, adding new webNotification to component state to check if "Ask For Referral" button should be disabled
				this.props.addNewWebNotification(input);
				setTimeout(() => {
					this.props.handleCancel();
				}, 300);
			});
		} else {
			this.setState({ buttonState: 'error' });
		}
	};

	onFinishFailed = (error) => {
		this.setState({ buttonState: 'error' });
		console.error(error);
	};

	inputs = {};

	showAnimation = () => {
		const { toggleIsSubmitting, handleCancelAll } = this.props;
		return new Promise((resolve, reject) => {
			setTimeout(() => {
				try {
					toggleIsSubmitting();
					handleCancelAll();
					resolve();
				} catch (error) {
					reject(error);
				}
			}, 5700);
		});
	};

	render() {
		if (!this.props.visible) {
			this.formRef.current.resetFields();
		}

		const { filename, errors, refErrors, uniqueId } = this.state;

		const {
			allMultiLingualData,
			autoCompleteResult,
			currentUser,
			error,
			resumeAttachData,
		} = this.props;
		const resumeData = {};
		let isResumeOptional = false;
		let isResumeRequired = false;
		if (resumeAttachData.length > 0) {
			Object.assign(resumeData, ...resumeAttachData);
			const resumeAttachmentData = JSON.parse(get(resumeData, 'questions'));
			for (const key in resumeAttachmentData) {
				if (resumeAttachmentData.hasOwnProperty(key)) {
					if (key === 'Resume required') {
						isResumeRequired = resumeAttachmentData[key];
					} else {
						isResumeOptional = resumeAttachmentData[key];
					}
				}
			}
		}

		const { TextArea } = Input;
		const FormItem = Form.Item;
		const AutoCompleteOption = AutoComplete.Option;
		const ContactOptions = autoCompleteResult
			.filter((person) => person.firstName && person.lastName)
			.map((person) => {
				const firstName = get(person, 'firstName', '');
				const lastName = get(person, 'lastName', '');
				const content = `${firstName} ${lastName}`;
				return (
					<AutoCompleteOption
						key={person.id}
						id={person.id}
						firstName={firstName}
						lastName={lastName}
						emailAddress={get(person, 'emailAddress', '')}
						phoneNumber={get(person, 'phoneNumber', '')}
					>
						{content}
					</AutoCompleteOption>
				);
			});

		const data_items = this.props.referralQuestions;

		const items = data_items.map((item) => {
			if (!item) return null;
			switch (item.element) {
				case 'TextInput':
				case 'NumberInput':
				case 'TextArea':
				case 'Dropdown':
				case 'DatePicker':
				case 'RadioButtons':
				case 'Rating':
				case 'Tags':
				case 'Range': {
					return this.getInputElement(item);
				}

				default: {
					return this.getSimpleElement(item);
				}
			}
		});

		const extensionNetworkUserOptions = get(
			this.props,
			'extendedNetworkUsers',
			[]
		).sort(function (a, b) {
			const nameA = get(a, 'firstName', '').toUpperCase();
			const nameB = get(b, 'firstName', '').toUpperCase();
			if (nameA < nameB) {
				return -1;
			}

			if (nameA > nameB) {
				return 1;
			}

			return 0;
		});

		return (
			<Form
				ref={this.formRef}
				className={ModalStyles.ModalStyles}
				initialValues={{ connection: this.state.selectConnection.id }}
				onFinish={this.handleSubmit}
				onFinishFailed={this.onFinishFailed}
			>
				<div className={ModalStyles.LabelStyle}>
					<h3 className={ModalStyles.LabelTitle}>Choose Employee</h3>
					<FormItem
						name="connection"
						rules={[
							{
								required: true,
							},
							{
								validator: (_, value) =>
									value
										? this.state.alreadyRequestedError
											? Promise.reject(
													new Error(
														'You have already requested a referral from this connection.'
													)
												)
											: Promise.resolve()
										: Promise.reject(new Error('Please Choose Employee.')),
							},
						]}
					>
						<Select
							showSearch
							labelInValue
							style={{ width: '100%', marginTop: '5px' }}
							optionFilterProp="children"
							placeholder="Ex. James Smith"
							filterOption={(input, option) =>
								option.props.children
									.join('')
									.toLowerCase()
									.includes(input.toLowerCase())
							}
							onSelect={(value) => this.onChangeEmployee(value)}
							onSearch={(value) => this.props.searchExtendedNetworkUsers(value)}
						>
							{extensionNetworkUserOptions.map((emp) => (
								<Select.Option key={emp.id}>
									{emp.user?.firstName ?? emp.firstName}{' '}
									{emp.user?.lastName ?? emp.lastName}
								</Select.Option>
							))}
						</Select>
					</FormItem>
				</div>
				<FormItem
					className={ModalStyles.FormItemStyles}
					style={{ marginBottom: 15 }}
					name="message"
				>
					<label className={ModalStyles.LabelStyles} style={{ width: '100%' }}>
						{ml(
							'Include a message to your connection',
							currentUser,
							allMultiLingualData
						)}{' '}
						<span className="label-optional">
							{ml('(optional)', currentUser, allMultiLingualData)}
						</span>
						<TextArea
							className={ModalStyles.InputStyles}
							placeholder={ml(
								'Personalize the message to your connection',
								currentUser,
								allMultiLingualData
							)}
							rows={4}
							style={{ fontSize: 14 }}
						/>
					</label>
				</FormItem>
				<div>{items}</div>
				{isResumeOptional ? (
					<FormItem
						className="App"
						style={{ marginBottom: '30px' }}
						rules={[
							{
								required: isResumeRequired,
								message: 'Please upload your resume.',
							},
						]}
					>
						<span className={ModalStyles.fileWrap}>
							<label htmlFor={'file-' + uniqueId}>
								{ml('Click here', currentUser, allMultiLingualData)}{' '}
							</label>
							<input
								ref={(ref) => {
									this.uploadInput = ref;
								}}
								hidden
								type="file"
								accept=".doc,.docx,application/msword,.pdf,application/pdf"
								id={'file-' + uniqueId}
								name="file"
								onClick={() => {
									this.setState({
										filename: null,
										filetype: null,
										originalFile: [],
									});
									this.uploadInput = null;
								}}
								onChange={this.onFileInputChange}
							/>
						</span>{' '}
						{ml('to attach a resume', currentUser, allMultiLingualData)}{' '}
						<span className="label-optional">
							{isResumeRequired
								? ml('(required)', currentUser, allMultiLingualData)
								: ml('(optional)', currentUser, allMultiLingualData)}
						</span>
						{filename && (
							<p>
								{filename}
								<DeleteOutlined
									style={{ margin: '0px 0px 4px 10px', color: '#ef3c3e' }}
									onClick={this.onDelete}
								/>
							</p>
						)}
						{errors && errors.length > 0 ? (
							<div className={ModalStyles.fieldErrors}>
								<span className={ModalStyles.errorText}> {errors} </span>
							</div>
						) : null}
					</FormItem>
				) : null}
				{error || (errors.length > 0 && refErrors.length > 0) ? (
					<Alert
						className={ModalStyles.SubmitError}
						message={
							this.props.errorMessage ??
							ml(
								'We are having trouble submitting the form. Please make sure the form is filled out correctly and try again.',
								currentUser,
								allMultiLingualData
							)
						}
						type="error"
					/>
				) : null}

				<Row>
					<div style={{ margin: '0 auto' }}>
						{get(this.state, 'alreadyRequestedError') && (
							<span style={{ color: 'red' }}>
								You have already requested a referral from this connection.
							</span>
						)}
					</div>
				</Row>

				<div>
					<ProgressButton
						controlled
						style={{ marginTop: 15 }}
						durationSuccess={10_000}
						durationError={100_000}
						state={this.state.buttonState}
						shouldAllowClickOnLoading={false}
						type="submit"
					>
						{ml('Submit Request', currentUser, allMultiLingualData)}
					</ProgressButton>
				</div>
			</Form>
		);
	}
}

export default ReferralRequestForm;
