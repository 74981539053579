export const queryReferralsByJobIdIndex = `query QueryReferralsByJobIdIndex(
    $jobId: ID!
    $first: Int
    $after: String
  ) {
    queryReferralsByJobIdIndex(
      jobId: $jobId
      first: $first
      after: $after
    ) {
        items {
            id
            bonuses {
              id
              amountDue
              bonusStatus
              companyId
              contactId
              earnedDate
              hireDate
              jobId
              payment
              recipientType
              referralId
              startDate
              userId
              notes
            }
            companyId
            contactId
            contact {
              id
              emailAddress
              lastName
              firstName
              socialMediaAccounts
              phoneNumber
            }
            userId
            user {
              id
              firstName
              lastName
              incentiveEligible
              userGroupId
            }
            jobId
            job {
              id
              title
              departmentId
              department {
                id
                name
              }
              referralBonus
            }
            ownershipEndDate
            note
            message
            hireDate
            referralDate
            referralType
            status
            bonusStatus
            contactResume {
              bucket
              region
              key
            }
          }
          nextToken
    }
  }
  `;
