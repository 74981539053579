import gql from 'graphql-tag';
import get from 'lodash/get';
import { compose, graphql } from 'react-apollo';
import {
	createUserJobShare,
	queryUserJobShareByUserIdIndex,
	updateUserJobShare,
} from '../../graphql/custom/users';

export const withCreateUserJobShare = (Component) => {
	return compose(
		graphql(gql(queryUserJobShareByUserIdIndex), {
			options(props) {
				return {
					context: {
						headers: {
							'x-frame-options': 'deny', // This header will reach the server
						},
					},
					variables: {
						userId: props.currentUser.id,
					},
					fetchPolicy: 'cache-and-network',
				};
			},
			props(response, previous) {
				const userJobShares = get(
					response,
					['data', 'queryUserJobShareByUserIdIndex', 'items'],
					get(previous, 'userJobShares', [])
				);
				const userJobSharesNextToken = get(
					response,
					['data', 'queryUserJobShareByUserIdIndex', 'nextToken'],
					null
				);
				const onFetchMoreUserJobShares = makeOnFetchMoreUserJobShares(
					response.data.fetchMore,
					userJobSharesNextToken
				);

				if (onFetchMoreUserJobShares) {
					onFetchMoreUserJobShares();
				}

				return {
					userJobShares,
					onFetchMoreUserJobShares,
					userJobSharesNextToken,
				};
			},
		}),
		graphql(gql(createUserJobShare), {
			props: (props) => ({
				onCreateUserJobShare(input) {
					const optimisticResponseData = {
						...input,
					};
					return props.mutate({
						variables: { input },
						optimisticResponse: {
							__typename: 'Mutation',
							createUserJobShare: {
								...optimisticResponseData,
								__typename: 'UserJobShare',
							},
						},
						refetchQueries: [
							{
								query: gql(queryUserJobShareByUserIdIndex),
								variables: { userId: props.ownProps.currentUser.id },
							},
						],
					});
				},
			}),
		}),
		graphql(gql(updateUserJobShare), {
			props: (props) => ({
				onUpdateUserJobShare(input) {
					const optimisticResponseData = {
						...input,
					};
					return props.mutate({
						variables: { input },
						optimisticResponse: {
							__typename: 'Mutation',
							updateUserJobShare: {
								...optimisticResponseData,
								__typename: 'UserJobShare',
							},
						},
						refetchQueries: [
							{
								query: gql(queryUserJobShareByUserIdIndex),
								variables: { userId: props.ownProps.currentUser.id },
							},
						],
					});
				},
			}),
		})
	)(Component);
};

const makeOnFetchMoreUserJobShares = (fetchMore, nextToken) => {
	if (!nextToken) {
		return null;
	}

	return () => {
		fetchMore({
			variables: { after: nextToken },
			updateQuery(previous, { fetchMoreResult }) {
				if (!fetchMoreResult) {
					return previous;
				}

				return {
					...previous,
					loading: false,
					queryUserJobShareByUserIdIndex: {
						...previous.queryUserJobShareByUserIdIndex,
						...fetchMoreResult.queryUserJobShareByUserIdIndex,
						items: [
							...previous.queryUserJobShareByUserIdIndex.items,
							...fetchMoreResult.queryUserJobShareByUserIdIndex.items,
						],
					},
				};
			},
		});
	};
};
