import { useEffect, useState } from 'react';

/** Packages */
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Popconfirm, Upload, message } from 'antd';
import ImgCrop from 'antd-img-crop';
import get from 'lodash/get';

/** Shared Utils */
import {
	downloadFromS3Signed,
	ml,
	uploadToS3Multipart,
} from '../../_shared/services/utils.js';

export function Avatar(props) {
	const {
		allMultiLingualData,
		currentAvatar,
		currentUser,
		file,
		handleChange,
		isEditing,
		uploadedAvatar,
		avatarRemoved,
		setAvatarRemoved,
	} = props;

	const [avatarSource, setAvatarSource] = useState('');
	const [fileSource, setFileSource] = useState('');

	useEffect(() => {
		const fetchURLs = async () => {
			const avatar = uploadedAvatar || currentAvatar;

			if (!avatar?.key) return;
			const presignedAvatarURL = await downloadFromS3Signed(avatar?.key);
			setAvatarSource(presignedAvatarURL);
			if (!uploadedAvatar?.key) return;
			const presignedFileURL = await downloadFromS3Signed(uploadedAvatar?.key);
			setFileSource(presignedFileURL);
		};

		fetchURLs();
	}, [file, uploadedAvatar, currentAvatar, avatarRemoved]);

	const setAvatar = ({ file, onSuccess }) => {
		setTimeout(() => {
			onSuccess('ok');
		}, 0);
		handleChange({
			editedAvatar: {
				bucket: 'erin-avatars',
				key: `user-pic/${currentUser.id}/${file.name}`,
				region: 'us-east-2',
			},
		});
	};

	const uploadAvatar = async (files) => {
		try {
			const file = files.file.originFileObj;
			const key = `user-pic/${currentUser.id}/${file.name}`;
			const bucket = 'erin-avatars';

			await uploadToS3Multipart(file, key, bucket);

			handleChange({
				avatarFile: files.file,
				uploadedAvatar: {
					bucket,
					key,
					region: 'us-east-2',
				},
			});
			setAvatarRemoved(false);
		} catch (error) {
			console.error(error);
		}
	};

	const verifyFile = (file) => {
		const correctType = file.type === 'image/png' || file.type === 'image/jpeg';
		if (!correctType) {
			message.error(
				ml(
					'You can only upload PNG or JPEG files!',
					currentUser,
					allMultiLingualData
				)
			);
		}

		const isLt2M = file.size / 1024 / 1024 < 2;
		if (!isLt2M) {
			message.error(
				ml('Image must be smaller than 2MB!', currentUser, allMultiLingualData)
			);
		}

		return correctType && isLt2M;
	};

	const fileList = uploadedAvatar
		? [
				{
					uid: '-1',
					name: uploadedAvatar.key,
					status: 'done',
					url: fileSource,
					thumbUrl: fileSource,
				},
			]
		: [];

	const confirmRemove = () => {
		handleChange({
			editedAvatar: null,
			avatarFile: undefined,
			uploadedAvatar: undefined,
		});
		setAvatarRemoved(true);
		setAvatarSource('');
		setFileSource('');
		message.success('Avatar removed successfully');
	};

	return (
		<div>
			<div className="custom-image-wrap">
				{file === null || file === '' || avatarRemoved ? (
					<>
						{get(currentUser.firstName, '[0]', '')}{' '}
						{get(currentUser.lastName, '[0]', '')}
					</>
				) : (
					<img
						className="custom-img"
						src={avatarSource}
						alt={`${currentUser.firstName} ${currentUser.lastName}`}
					/>
				)}
			</div>
			{isEditing && file && (
				<Popconfirm
					title="Are you sure？"
					placement="right"
					icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
					onConfirm={() => confirmRemove()}
				>
					<span className="cancel-btn">
						<i className="icon-bin" />
						Remove Avatar
					</span>
				</Popconfirm>
			)}
			{isEditing && (
				<div className="po-details-edit custom-form-group profile-upload">
					<div className="upload-picture-wrap">
						<ImgCrop>
							<Upload
								name="avatar"
								listType="picture-card"
								customRequest={(request) => setAvatar(request)}
								beforeUpload={(file) => verifyFile(file)}
								multiple={false}
								disabled={Boolean(fileList.length > 0 || !isEditing)}
								fileList={fileList}
								accept=".png, .jpg"
								onChange={async (files) => uploadAvatar(files)}
							>
								<i className="icon-plus" />
								<span style={{ fontSize: 12 }}>
									{ml('Upload Profile', currentUser, allMultiLingualData)}{' '}
									<br /> {ml('Picture', currentUser, allMultiLingualData)}
								</span>
							</Upload>
						</ImgCrop>
					</div>
				</div>
			)}
		</div>
	);
}
