import { Col, Row } from 'antd';
import { Component } from 'react';
import DonutChart from 'react-donut-chart';
import { ml } from '../../../_shared/services/utils.js';
import { COLORS } from '../../../_shared/styles/colors';
import {
	chartTotal,
	donutChart,
	middleColumn,
	middleContainer,
	numTotal as numberTotal,
	opacity,
} from './employeesCardStyles.js';

class BaseEmployeesCard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			totalSmartReferrals: 0,
		};
	}

	render() {
		const {
			allMultiLingualData,
			currentUser,
			onClick,
			title,
			totalEmployeeCount = 0,
			employeesWithoutReferralCount,
			employeesWithReferralsCount,
			newEmployeesThisMonthCount,
			inactiveEmployeesCount,
		} = this.props;

		return (
			<div className="d-card height-equal" onClick={onClick}>
				<div className="d-card-head">
					<h4 className="d-title">{title}</h4>
				</div>
				<Row className={middleContainer}>
					<Col span={24} className={middleColumn}>
						<div className={donutChart}>
							<div className={chartTotal}>
								<div className={numberTotal}>
									{Number.parseInt(totalEmployeeCount).toLocaleString('en-US')}
								</div>
								<div style={{ paddingBottom: 10 }}>
									{ml('Total', currentUser, allMultiLingualData)}
								</div>
							</div>
							{employeesWithoutReferralCount && employeesWithReferralsCount && (
								<DonutChart
									height={153}
									width={140}
									colors={[COLORS.purple]}
									strokeColor={COLORS.purple}
									emptyOffset={0.19}
									legend={false}
									clickToggle={false}
									data={[
										{
											value: Number.parseFloat(employeesWithoutReferralCount),
											className: opacity,
											isEmpty: true,
										},
										{
											value: Number.parseFloat(employeesWithReferralsCount),
											className: opacity,
										},
									]}
								/>
							)}
						</div>
					</Col>
				</Row>
				<div className="donut-chart-labels" style={{ marginTop: 12 }}>
					<label> {ml('Active', currentUser, allMultiLingualData)}:</label>
					<span>
						{Number.parseInt(
							totalEmployeeCount - inactiveEmployeesCount
						).toLocaleString('en-US')}
					</span>
					<label>{ml('Inactive', currentUser, allMultiLingualData)}:</label>
					<span>
						{Number.parseInt(inactiveEmployeesCount).toLocaleString('en-US')}
					</span>
				</div>
				<div className="divider" />
				<ul className="list-data-wrap">
					<li>
						<label>
							<button className="without-refferals" />
							{ml(
								'All-Time Employees without Referrals',
								currentUser,
								allMultiLingualData
							)}
							:
						</label>
						<span>
							{Number.parseInt(employeesWithoutReferralCount).toLocaleString(
								'en-US'
							)}
						</span>
					</li>
					<li>
						<label>
							<button className="with-refferals" />
							{ml(
								'All-Time Employees with Referrals',
								currentUser,
								allMultiLingualData
							)}
							:
						</label>
						<span>
							{Number.parseInt(employeesWithReferralsCount).toLocaleString(
								'en-US'
							)}
						</span>
					</li>
				</ul>

				<ul className="list-data-wrap" style={{ marginTop: 12 }}>
					<li className="centered">
						<label>
							{' '}
							{ml('New Employees This Month', currentUser, allMultiLingualData)}
							:
						</label>
						<span>
							{Number.parseInt(newEmployeesThisMonthCount).toLocaleString(
								'en-US'
							)}
						</span>{' '}
					</li>
				</ul>
			</div>
		);
	}
}

export const EmployeesCard = BaseEmployeesCard;
