import { css } from 'emotion';
import get from 'lodash/get';
import { COLORS } from '../_shared/styles/colors';

const breakpoints = [576, 767, 991, 1200];

const mq = breakpoints.map((bp) => `@media (max-width: ${bp}px)`);

export const SettingsContainer = css({
	fontFamily: '"Open Sans", sans-serif',
	width: '100%',
	height: '100%',
	padding: 20,
	backgroundColor: COLORS.lightGray2,
	margin: 0,
	[mq[2]]: {
		padding: '10px 5px',
	},
});

export const Card = css({
	boxShadow: '1px 1px 15px rgba(0,0,0,0.25)',
	borderRadius: 10,
	padding: 40,
	backgroundColor: 'white',
	height: 'auto',
	'& button:focus': {
		outline: 'none',
	},
	maxWidth: 1270,
	[mq[1]]: {
		padding: 10,
	},
});

export const FormItemContainer = css({
	display: 'flex',
	flexDirection: 'row',
	fontSize: '14px',
	color: '#222222',
	'& label': {
		display: 'block',
		fontSize: 14,
		color: COLORS.lightGray,
		lineHeight: '40px',
		marginRight: 10,
	},
	'& .ant-form-item': {
		marginBottom: 0,
	},
	[mq[0]]: {
		flexDirection: 'column',
		'& .ant-form-item': {
			marginBottom: 0,
		},
	},
});

export const FormItemPickerContainer = css({
	display: 'flex',
	'& label': {
		display: 'block',
		fontSize: 14,
		color: COLORS.lightGray,
		lineHeight: '40px',
		marginRight: 10,
	},
	'& .ant-form-item': {
		marginBottom: 0,
	},
	[mq[0]]: {
		flexDirection: 'column',
		'& .ant-form-item': {
			marginBottom: 0,
		},
	},
});

export const FormStyles = css({
	'& .ant-form-item': {},
});

export const InputStyles = css({
	fontSize: 15,
	height: 30,
	width: 200,
});

export const InputStyleSettings = css({
	width: 300,
});

export const InputStyleDays = css({
	fontSize: 18,
	height: 40,
	marginBottom: 10,
	width: 50,
	paddingRight: 10,
});

export const WebsiteStyles = css({
	color: COLORS.blue,
});

export const BonusStyles = css({
	color: COLORS.green,
});

export const MiscTextStyles = css({
	fontSize: 'italic',
});

export const SubmitBtn = (theme) => {
	let buttonColor = COLORS.blue;
	if (get(theme, 'enabled')) {
		buttonColor = get(theme, 'buttonColor', buttonColor);
	}

	return css({
		fontFamily: '"Open Sans", sans-serif',
		textAlign: 'center',
		fontWeight: 200,
		color: `${COLORS.white} !important`,
		background: `${buttonColor} !important`,
		margin: 5,
		padding: 8,
		fontSize: 14,
		display: 'flex',
		height: 40,
		borderRadius: 5,
		border: `2px solid ${buttonColor} !important`,
		'&:hover': {
			color: buttonColor,
			borderColor: buttonColor,
		},
		'&:focus': {
			color: buttonColor,
			borderColor: buttonColor,
		},
	});
};

export const SubmitBtnContainer = css({
	width: '100%',
	display: 'flex',
	justifyContent: 'center',
	padding: 10,
	marginTop: 20,
	'& .ant-btn: hover': {
		color: COLORS.blue,
		fontWeight: 300,
		border: `2px solid ${COLORS.blue}`,
	},
});

export const SubmitBtnContainerSettings = (theme) => {
	let buttonColor = COLORS.blue;
	if (get(theme, 'enabled')) {
		buttonColor = get(theme, 'buttonColor', buttonColor);
	}

	return css({
		justifyContent: 'center',
		'& .ant-btn: hover': {
			color: buttonColor,
			fontWeight: 300,
			border: `2px solid ${buttonColor}`,
		},
	});
};

export const CheckIcon = css({
	borderRadius: 100,
	border: '1px solid white',
	padding: 2,
});

export const miscText = css({
	maxWidth: 600,
	fontStyle: 'italic',
});

export const fieldErrors = css({
	width: 500,
	marginLeft: '5%',
});

export const errorText = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 600,
	color: COLORS.red,
	fontSize: 12,
	left: -30,
	marginBottom: 0,
	lineHeight: '12px',
});

export const avatarUploader = css({
	width: 128,
	height: 128,
});
export const integrationSelector = css({
	maxWidth: 300,
});
export const saveButton = css({
	display: 'flex',
	width: 90,
	height: 32,
	borderRadius: 2,
	justifyContent: 'center',
	background: '#018dd3',
	border: '1px solid #018dd3',
	color: '#fff',
	marginTop: 15,
	marginBottom: 10,
});

export const SelectContainer = css({
	display: 'flex',
	height: 40,
	marginTop: 17,
	'& .ant-select': {
		flexBasis: 300,
	},
	[mq[1]]: {
		height: 'auto',
		flexDirection: 'column',
		'& .ant-form-item': {
			marginBottom: 0,
		},
	},
});

export const iconContainer = css({
	'& svg': {
		transform: 'translate(-40px, 8px)',
	},
});

export const inputContainer = (width) =>
	css({
		width,
	});

export const inputContainerSetting = css({
	width: '300px !important',
	[mq[1]]: {
		marginLeft: -10,
	},
});

export const regularButton = (selected) =>
	css({
		marginLeft: 8,
		backgroundColor: selected ? COLORS.subHeading : COLORS.white,
		color: selected ? COLORS.white : COLORS.subHeading,
		'&:hover': {
			backgroundColor: COLORS.red,
			color: COLORS.white,
			borderColor: selected ? 'transparent' : COLORS.subHeading,
		},
		'&:focus': {
			backgroundColor: selected ? COLORS.subheading : COLORS.white,
			color: selected ? COLORS.white : COLORS.subHeading,
			borderColor: selected ? 'transparent' : COLORS.subheading,
		},
	});

export const loadExternalJobs = css({
	display: 'flex',
	width: 185,
	height: 32,
	borderRadius: 2,
	justifyContent: 'center',
	background: '#19bb4b',
	border: '1px solid #689c2b',
	color: '#fff',
	marginTop: 15,
	cursor: 'pointer',
	[mq[0]]: {
		width: '100%',
		margin: '10px 0 !important',
	},
});

export const disableExternalJobs = css({
	display: 'flex',
	width: 185,
	height: 32,
	borderRadius: 2,
	justifyContent: 'center',
	background: '#a9a9a9',
	border: '1px solid #a9a9a9',
	color: '#fff',
	marginTop: 15,
	cursor: 'not-allowed',
});

export const loadExternalJobsNonUS = css({
	display: 'flex',
	width: 260,
	height: 32,
	borderRadius: 2,
	justifyContent: 'center',
	background: '#19bb4b',
	border: '1px solid #689c2b',
	color: '#fff',
	marginTop: 15,
	cursor: 'pointer',
});

export const disableExternalJobsNonUS = css({
	display: 'flex',
	width: 260,
	height: 32,
	borderRadius: 2,
	justifyContent: 'center',
	background: '#a9a9a9',
	border: '1px solid #a9a9a9',
	color: '#fff',
	marginTop: 15,
	cursor: 'not-allowed',
});

export const hideButton = css({
	display: 'none',
});

export const importExternalDepartments = css({
	display: 'flex',
	width: 185,
	height: 32,
	borderRadius: 2,
	justifyContent: 'center',
	background: '#19bb4b',
	border: '1px solid #689c2b',
	color: '#fff',
	marginTop: 15,
	cursor: 'pointer',
});

export const disabledExternalDepartments = css({
	display: 'flex',
	width: 185,
	height: 32,
	borderRadius: 2,
	justifyContent: 'center',
	background: '#19bb4b',
	border: '1px solid #689c2b',
	color: '#fff',
	marginTop: 15,
	cursor: 'not-allowed',
});

export const loadDisableExternalJobs = css({
	display: 'flex',
	width: 185,
	height: 32,
	borderRadius: 2,
	justifyContent: 'center',
	background: '#19bb4b',
	border: '1px solid #689c2b',
	color: '#fff',
	marginTop: 15,
	cursor: 'not-allowed',
});

export const greenLabel = css({
	minWidth: 150,
	display: 'inline-block',
	marginRight: 15,
});

export const greenOuterDiv = css({
	display: 'flex',
	justifyContent: 'start',
	alignItems: 'stretch',
	padding: '25px 20px',
});

export const greenInnerDiv = css({
	borderRadius: 3,
	background: '#fff',
	padding: 15,
	boxShadow: '0 1px 8px 0 rgba(0,0,0,0.12)',
});

export const tableHeader = css({
	fontFamily: '"Open Sans", sans-serif !important',
	fontWeight: '600 !important',
});

export const InputStylesForUrls = css({
	fontSize: 18,
	height: 40,
	marginBottom: 10,
	width: 500,
});

export const buttonWrap = css({
	display: 'flex',
	[mq[0]]: {
		flexDirection: 'column',
	},
});

export const h1Style = css({
	fontSize: '16px',
});

export const recipientContainer = css({
	justifyContent: 'center',
	alignItems: 'center',
	backgroundColor: COLORS.lightGreen,
	width: 'auto',
	borderRadius: 4,
	display: 'inline-block',
	borderColor: COLORS.lightGreen,
	wordBreak: 'break-all',
	flex: '0, 0, auto',
	margin: 3,
	marginTop: 10,
	padding: 5,
});

export const closeIcon = css({
	fontSize: '14',
	color: COLORS.green,
	marginRight: 5,
});

export const recipientText = css({
	color: COLORS.green,
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 400,
	fontSize: 14,
});

export const RecipientsContainer = css({
	display: 'flex',
	flexWrap: 'wrap',
});

export const titleStyle = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 500,
	color: COLORS.heading,
	fontSize: '1.3em',
});

export const cancelContainer = css({
	textAlign: 'center',
	boxShadow: '0 2px 7px 0 rgba(0,0,0,0.22)',
	padding: '1.5rem',
	margin: '1rem 0',
	borderRadius: '0.25rem',
	background: '#fffafa',
});

export const cancelModalButton = css({
	marginLeft: 8,
	backgroundColor: COLORS.white,
	color: COLORS.blue,
	borderColor: COLORS.blue,
	'&:hover': {
		backgroundColor: COLORS.white,
		color: COLORS.blue,
		border: `2px solid ${COLORS.blue}`,
	},
	'&:focus': {
		backgroundColor: COLORS.white,
		color: COLORS.blue,
		borderColor: COLORS.blue,
	},
});

export const keepModalOpenButton = css({
	marginLeft: 8,
	backgroundColor: COLORS.blue,
	color: COLORS.white,
	borderColor: COLORS.blue,
	'&:hover': {
		backgroundColor: COLORS.blue,
		color: COLORS.white,
		border: `2px solid ${COLORS.darkBlue}`,
	},
	'&:focus': {
		backgroundColor: COLORS.blue,
		color: COLORS.white,
		borderColor: COLORS.blue,
	},
});

export const groupRow = css({
	display: 'flex',
	minWidth: 150,
});

export const filterGroupSpace = css({
	marginBottom: 15,
	fontFamily: '"Open Sans", sans-serif',
});

export const radioButton = (theme) => {
	let buttonColor = COLORS.red;
	if (get(theme, 'buttonColor')) {
		buttonColor = get(theme, 'buttonColor', buttonColor);
	}

	return css({
		fontSize: '1.25em',
		color: COLORS.black,
		'& .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)':
			{
				backgroundColor: COLORS.red,
			},
		'& .ant-radio-button & .ant-radio-button-checked': {
			backgroundColor: COLORS.red,
		},
		'&:selected': {
			color: buttonColor,
			':hover': {
				color: COLORS.white,
			},
		},
	});
};
