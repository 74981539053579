import './style.scss';
import { useHistory } from 'react-router-dom';
import RewardsProgressIndicator from 'src/_shared/components/rewards-progress-indicator/RewardsProgressIndicator.jsx';

function RewardsProgress({ tiers, currentUser }) {
	let history = useHistory();

	return (
		<div className="home">
			<div className="rewards-progress d-card height-equal">
				<div className="d-card-head rewards-progress-header">
					<h4 className="dashboard-title">REWARDS PROGRESS</h4>
					<div
						className="rewards-progress-view-rewards"
						onClick={() => {
							history.push('/my-recognitions');
						}}
					>
						<strong>View Rewards</strong>
					</div>
				</div>
				<div className="rewards-progress-description">
					Unlock rewards with every recognition you receive. Choose a gift at
					each level!
				</div>
				<br />

				<RewardsProgressIndicator
					currentUser={currentUser}
					tiers={tiers}
					status="claimed"
				/>
			</div>
		</div>
	);
}

export default RewardsProgress;
