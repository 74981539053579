import { createContext, useContext } from 'react';
import { useRnRAPIService } from '../../rewardsAndRecognitionHelper/service/RnRAPI.service.jsx';
import { APPROVAL_BONUS_ENDPOINTS } from '../constants/ApprovalBonus.constants.js';

const ApprovalBonusServiceContext = createContext(null);

export const ApprovalBonusServiceProvider = (props) => {
  const APIService = useRnRAPIService();

  const getApprovalBonusesList = () => {
    return APIService.get(APPROVAL_BONUS_ENDPOINTS.GET_APPROVAL_BONUS_EMPLOYEE);
  }

  const getApprovalRewardsCount = () => {
    return APIService.get(APPROVAL_BONUS_ENDPOINTS.GET_APPROVAL_REWARDS_COUNTS_EMPLOYEE);
  }

  const ApprovalBonusServiceOperations = {
    getApprovalBonusesList,
    getApprovalRewardsCount
  };

  return (
    <ApprovalBonusServiceContext.Provider
      value={ApprovalBonusServiceOperations}
    >
      {props.children}
    </ApprovalBonusServiceContext.Provider>
  );
};

export const useApprovalBonusService = () => {
  return useContext(ApprovalBonusServiceContext);
};