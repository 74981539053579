import { Button } from 'antd';
import gql from 'graphql-tag';
import _, { get } from 'lodash';
import { Component } from 'react';
import { withApollo } from 'react-apollo';
import { queryReferralQuestionsByCompanyId } from 'src/_shared/api/graphql/custom/referral-questions/';
import ReactFormGenerator from './Form.jsx';
import { ElementStore } from './ReactFormBuilder.jsx';

class Demobar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			previewVisible: false,
		};

		const update = this._onChange.bind(this);
		ElementStore.subscribe((state) => update(state.data));
	}

	async getReferralQuestions(
		policy = 'network-only',
		nextToken = null,
		allQuestions = []
	) {
		const { client, currentUser } = this.props;
		try {
			const { data } = await client.query({
				query: gql(queryReferralQuestionsByCompanyId),
				variables: {
					companyId: get(currentUser, 'companyId'),
					after: nextToken,
				},
				fetchPolicy: policy,
			});
			const questions = [...data.queryReferralQuestionsByCompanyId.items];
			const token = get(data, 'queryJobsByCompanyIdDateIndex.nextToken', null);
			allQuestions = [...allQuestions, ...questions];
			const allQuestionsSort = _.sortBy(allQuestions, ['sortOrder']);
			const questionsData = [];
			if (allQuestionsSort.length > 0) {
				allQuestionsSort.map((element) => {
					questionsData.push(JSON.parse(element.questions));
				});
			}

			this.setState({
				data: questionsData,
			});
			if (token) {
				this.getReferralQuestions(policy, token, allQuestions);
			}
		} catch (error) {
			console.log(error);
		}
	}

	_onChange(data) {
		this.setState({
			data,
		});
	}

	closePreview() {
		this.setState({
			previewVisible: false,
		});
	}

	showPreview() {
		this.setState({
			previewVisible: true,
		});
	}

	render() {
		const { data } = this.state;
		const {
			onAddQuestion,
			currentUser,
			onDeleteQuestion,
			isReferral,
			isAcceptance,
			checked,
			onCheckChange,
			checkedAcceptance,
			checkedRequired,
			checkedAcceptanceRequired,
			isInterested,
			checkedInterested,
			checkedInterestedEmail,
			checkedInterestedRequired,
			onInterestedCheckChange,
			isGeneral,
			checkedGeneral,
			checkedGeneralRequired,
		} = this.props;
		let modalClass = 'modal';
		if (this.state.previewVisible) {
			modalClass += ' show d-block';
		}

		let formTile = '';
		if (isReferral && !isAcceptance && !isInterested && !isGeneral) {
			formTile = 'Referral Form Questions';
		} else if (!isReferral && isAcceptance && !isInterested && !isGeneral) {
			formTile = 'Acceptance Form Questions';
		} else if (!isReferral && !isAcceptance && isInterested && !isGeneral) {
			formTile = "I'm Interested Form Questions";
		} else if (!isReferral && !isAcceptance && !isInterested && isGeneral) {
			formTile = 'General or Lead Form Questions';
		}

		return (
			<div className="sm-demo-bar">
				<h4 className="sm-heading">{formTile}</h4>
				<Button
					type="primary"
					size="large"
					onClick={this.showPreview.bind(this)}
				>
					Preview & Save
				</Button>

				{this.state.previewVisible && (
					<div className="rfq-modal">
						<div className="rfq-modal-dialog">
							<div className="rfq-mmodal-content">
								<div className="rfq-modal-header">
									<i
										className="icon-cross rfq-close"
										onClick={this.closePreview.bind(this)}
									/>
								</div>
								<ReactFormGenerator
									read_only
									download_path=""
									answer_data={{}}
									variables={this.props.variables}
									currentUser={currentUser}
									isReferral={isReferral}
									isAcceptance={isAcceptance}
									checked={checked}
									checkedAcceptance={checkedAcceptance}
									checkedRequired={checkedRequired}
									isInterested={isInterested}
									checkedInterested={checkedInterested}
									checkedInterestedEmail={checkedInterestedEmail}
									checkedAcceptanceRequired={checkedAcceptanceRequired}
									checkedInterestedRequired={checkedInterestedRequired}
									isGeneral={isGeneral}
									checkedGeneral={checkedGeneral}
									checkedGeneralRequired={checkedGeneralRequired}
									data={data}
									onDeleteQuestion={onDeleteQuestion}
									onCheckChange={onCheckChange}
									onAddQuestion={onAddQuestion}
								/>
							</div>
						</div>
					</div>
				)}
			</div>
		);
	}
}
export default withApollo(Demobar);
