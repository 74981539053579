import { css } from 'emotion';
import { COLORS } from '../../_shared/styles/colors';

export const addJobMainContainer = css({
	justifyContent: 'center',
	alignItems: 'center',
});

export const jobCardMain = css({
	padding: 10,
	borderRadius: 10,
	'& ant-card-head-title': {
		margin: 0,
	},
	'&:hover': {
		backgroundColor: '#f0f6fb',
	},
});

export const topContainer = css({
	marginTop: 10,
});

export const jobInfoContainer = css({
	height: 'auto',
});

export const bonusContainer = css({
	height: 'auto',
	marginRight: 15,
});

export const jobDetailRow = css({
	marginTop: 7,
	display: 'flex',
	color: COLORS.darkGray,
	flexWrap: 'wrap',
});

export const titleContainer = css({
	display: 'flex',
	justifyContent: 'space-between',
	width: '100%',
	height: 'auto',
});

export const middleContainer = css({
	display: 'flex',
	justifyContent: 'center',
	width: '100%',
	marginBottom: 20,
});

export const divider = css({
	height: 1,
	backgroundColor: COLORS.subHeading,
	opacity: 0.4,
});

export const bottomContainer = css({
	paddingTop: 25,
	paddingBottom: 5,
});

export const bigNumbers = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 800,
	color: COLORS.darkGray,
	fontSize: 36,
	marginBottom: 0,
});

export const numberSubtitles = css({
	fontFamily: '"Open Sans", sans-serif semibold',
	fontWeight: 400,
	color: COLORS.darkGray,
	fontSize: 14,
});

export const bonusAmount = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 600,
	color: COLORS.green,
	fontSize: 16,
});

export const jobTitleText = (theme) =>
	css({
		fontFamily: '"Open Sans", sans-serif',
		fontWeight: '600 !important',
		color: theme?.jobCardTitleColor || COLORS.hyperLink,
		fontSize: 16,
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		display: '-webkit-box',
		lineHeight: '22px',
		maxHeight: '44px',
		'-webkit-line-clamp': 2,
		'-webkit-box-orient': 'vertical',
	});

export const Department = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 400,
	color: COLORS.darkGray,
	fontSize: 14,
	marginLeft: 5,
	marginRight: 5,
});
export const locationStyles = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 400,
	color: COLORS.darkGray,
	fontSize: 14,
	marginRight: 5,
});

export const middleColumn = css({
	textAlign: 'center',
	display: 'flex',
	padding: 5,
	flexDirection: 'column',
});

export const matchBox = css({
	textAlign: 'center',
	backgroundColor: '#f1f1f1',
	margin: 5,
	borderRadius: 10,
});

export const bottomSectionText = css({
	fontFamily: '"Open Sans", sans-serif semibold',
	fontWeight: 500,
	color: COLORS.heading,
	fontSize: 16,
	marginLeft: 5,
	opacity: 0.7,
});

export const bottomSectionValue = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 800,
	color: COLORS.darkGray,
	fontSize: 16,
	float: 'right',
});

export const managerTextStyles = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 400,
	color: COLORS.heading,
	fontSize: 14,
});

export const managerLinkStyles = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: '600 !important',
	color: COLORS.blue,
	fontSize: 14,
	marginLeft: 5,
});

export const reqNumber = css({
	color: COLORS.gray3,
	fontWeight: 500,
	padding: '0 5px',
});

export const reqNumberContainer = css({
	fontSize: 12,
	width: '100%',
	marginTop: 5,
});

export const topContainerBorder = css({
	padding: 8,
	borderRadius: 10,
	'& ant-card-head-title': {
		margin: 0,
	},
	'&:hover': {
		backgroundColor: '#f0f6fb',
	},
	border: '2px solid #ffa850',
});

export const generalContainer = css({
	justifyContent: 'center',
	alignItems: 'center',
	backgroundColor: COLORS.lightGreen,
	width: 'auto',
	borderRadius: 4,
	display: 'inline-block',
	borderColor: COLORS.lightGreen,
	flex: '0, 0, auto',
	margin: 3,
	paddingBottom: 2,
});

export const generalText = css({
	color: COLORS.green,
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 600,
	fontSize: 14,
	margin: 5,
});
