import React from 'react';
import { addCommas } from '../../../rewardsAndRecognitionHelper/utils.js';

const ApprovalRewards = (props) => {
	const { approvalCountData } = props;

	const SummaryStatBox = ({ color = '', statName, label, total, title }) => (
		<div className="color-card" style={{ width: '33.33333%', background: `var(${color})` }}>
			<h4>
				{' '}
				{title === 'Bonuses' && `$`} {addCommas(total)}
			</h4>
			<p>{label ? label : statName === 'Total Rewards' ? `Total` : statName}</p>
		</div>
	);

	return (
		<div className="contact-card-wrap">
			<div className="contact-card">
				<div className="contact-card-inner">
					<div className="contact-card-title">Recognition Approval Status</div>
					<div className="contact-color-card">
						{approvalCountData?.approvalStatusCounts.length > 0 &&
							approvalCountData?.approvalStatusCounts.map((reward, index) => (
								<SummaryStatBox
									key={index}
									color={reward.color}
									statName={reward.name}
									total={reward.totals}
								/>
							))}
					</div>
				</div>
			</div>
			<div className="contact-card">
				<div className="contact-card-inner">
					<div className="contact-card-title">Bonuses</div>

					<div className="contact-color-card">
						{approvalCountData?.rewardPointsValues.length > 0 &&
							approvalCountData?.rewardPointsValues.map((reward, index) => (
								<SummaryStatBox
									key={index}
									color={reward.color}
									statName={reward.name}
									total={`$${reward.totals}`}
								/>
							))}
					</div>
				</div>
			</div>
		</div>
	);
};

export default ApprovalRewards;
