import { useEffect, useState } from 'react';
import { message } from 'antd';
import { useRnRCommonService } from '../../service/RnRCommon.service.jsx';

const useBadgeList = (badgeType, shouldRefresh, setShouldRefresh, getMyBadge = false) => {
    const [isLoading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [badgeList, setBadgeList] = useState([]);
    const [hasMore, setHasMore] = useState(false);
    const [pageLimit] = useState(18);
    const commonAPIService = useRnRCommonService();

    const getAllBadgeList = async (page, limit, refresh = false) => {
        try {
            setIsLoading(true);
            const response = await commonAPIService.getBadgesList({ page: page, limit: limit, visibility: false, type: badgeType });
            if (response?.code === 200) {
                const responseData = response.data;
                setShouldRefresh(false);
                setHasMore(responseData?.pagination?.hasNextPage);
                setBadgeList(refresh ? responseData.records : prevData => [...prevData, ...responseData.records]);
            } else {
                message.error('Failed to fetch badges list! Try again later.');
            }
        } catch (error) {
            console.error(error);
            message.error('Failed to fetch badges list! Try again later.');
        } finally {
            setIsLoading(false);
        }
    };
    const getMyBadgeList = async () => {
        setIsLoading(true);
        try {
            const response = await commonAPIService.getEmployeeRewardsList({ assignmentType: 'badgeAssignment' })
            if (response?.code === 200) {
                setBadgeList(response?.data?.records || []);
            } else {
                message.error('Failed to fetch reward list');
            }
        } catch (error) {
            console.error(error);
            message.error('Failed to fetch reward list');
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (shouldRefresh) {
            setCurrentPage(1);
            getAllBadgeList(1, pageLimit, true);
        }
    }, [shouldRefresh]);

    useEffect(() => {
        if (getMyBadge) {
            getMyBadgeList();
        } else {
            getAllBadgeList(currentPage, pageLimit);
        }
    }, [currentPage, pageLimit, getMyBadge]);

    const fetchMoreData = () => {
        setCurrentPage(prevPage => prevPage + 1);
    };

    return { isLoading, badgeList, hasMore, fetchMoreData };
};

export default useBadgeList;
