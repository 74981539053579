import {
	forwardRef,
	memo,
	useEffect,
	useState,
	useRef,
	useCallback,
} from 'react';
import { Input, Tooltip } from 'antd';
import sanitizeHtml from 'sanitize-html';
import { Editable, Handle } from './components';

const useReferenceCallback = (value) => {
	const ref = useRef(value);
	useEffect(() => {
		ref.current = value;
	}, [value]);

	return useCallback((...args) => {
		ref.current?.(...args);
	});
};

const sanitizeConfig = {
	allowedTags: [],
	allowedAttributes: [],
};

export const Item = memo(
	forwardRef(
		(
			{
				color,
				dragOverlay,
				dragging,
				disabled,
				fadeIn,
				handle,
				handleProps,
				height,
				index,
				listeners,
				onRemove,
				renderItem,
				sorting,
				style,
				transition,
				transform,
				value,
				wrapperStyle,
				handleEdit,
				...props
			},
			ref
		) => {
			const [text, setText] = useState(value.value ?? null);
			const [tooltipVisible, setTooltipVisible] = useState(false);

			useEffect(() => {
				if (!dragOverlay) {
					return;
				}

				document.body.style.cursor = 'grabbing';

				return () => {
					document.body.style.cursor = '';
				};
			}, [dragOverlay]);

			const handleChange = useReferenceCallback((e) => {
				const sanitizedContent = sanitizeHtml(e.target.value, sanitizeConfig);
				setText(sanitizedContent);
				handleEdit({
					item: value,
					property: 'value',
					newValue: sanitizedContent,
				});
			}, []);

			/** Prevent newlines and handle 'Enter' as 'submit' */
			const preventNewLine = (e) => {
				if (e.key !== 'Enter') {
					return;
				}

				e.target.blur();
			};

			return renderItem ? (
				renderItem({
					dragOverlay: Boolean(dragOverlay),
					dragging: Boolean(dragging),
					sorting: Boolean(sorting),
					index,
					fadeIn: Boolean(fadeIn),
					listeners,
					ref,
					style,
					transform,
					transition,
					value,
				})
			) : (
				<li
					ref={ref}
					className={`report-builder-wrapper ${fadeIn ? 'fadeIn' : ''} ${
						sorting ? 'sorting' : ''
					} ${dragOverlay ? 'dragOverlay' : ''}`}
					style={{
						...wrapperStyle,
						transition: [transition, wrapperStyle?.transition]
							.filter(Boolean)
							.join(', '),
						'--translate-x': transform
							? `${Math.round(transform.x)}px`
							: undefined,
						'--translate-y': transform
							? `${Math.round(transform.y)}px`
							: undefined,
						'--scale-x': transform?.scaleX ? `${transform.scaleX}` : undefined,
						'--scale-y': transform?.scaleY ? `${transform.scaleY}` : undefined,
						'--index': index,
						'--color': color,
					}}
				>
					<div
						className={`report-builder-item ${dragging ? 'dragging' : ''} ${
							handle ? 'withHandle' : ''
						} ${dragOverlay ? 'dragOverlay' : ''} ${color ? 'color' : 'color'}`}
						style={{
							...style,
						}}
						{...(handle ? undefined : listeners)}
						{...props}
						tabIndex={handle ? undefined : 0}
					>
						<div className="report-builder-header-and-actions">
							<Editable
								value={value}
								handleEdit={handleEdit}
								dragging={dragging}
							/>
							<span className="report-builder-actions">
								{handle ? <Handle {...handleProps} {...listeners} /> : null}
							</span>
						</div>
						{value.type === 'STATIC' && (
							<Tooltip
								autoDestroy
								placement="right"
								title="Enter static value or leave blank"
								open={tooltipVisible}
							>
								<div className="report-builder-label-and-custom-value">
									<label htmlFor={`custom-value-${value.id}`}>
										Custom Value:
									</label>
									<Input
										name={`custom-value-${value.id}`}
										size="small"
										placeholder=""
										value={text}
										onMouseDown={() => setTooltipVisible(false)}
										onMouseOver={() => !dragging && setTooltipVisible(true)}
										onMouseOut={() => setTooltipVisible(false)}
										onChange={handleChange}
										onKeyDown={preventNewLine}
									/>
								</div>
							</Tooltip>
						)}
					</div>
				</li>
			);
		}
	)
);
